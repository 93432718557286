import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ContentBox } from '../Index';
import { Input } from '../../../components/genericComponents/Input';
import properties from '../../../properties/properties';
import GenerateURL from '../../../util/APIUrlProvider';
import InvokeApi, { PostData } from '../../../util/apiInvoker';
import { Redirect, Link } from 'react-router-dom';
import { ValidateDataSet, VALIDATION_TYPE_REQUIRED, VALIDATION_TYPE_SMALLCAP_NUM_HIGHFEN_ONLY } from '../../../util/Validator';
import { getCommonFunctions } from '../add/ci_flow/SourceDetails';
import { makeStyles } from '@material-ui/styles';
import { Loading } from '../../utils/Loading';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { GetPermitedURLs } from '../../../util/security';
import { showErrorHandlerUpdated } from '../../../util/util';
import AlertStrip from '../../../components/AlertStrips';
const AddServiceFrom = props => {
    const classes = useStyles();
    const inherits = props.inherits ? props.inherits : {};
    const prev_state = props.prev_state ? props.prev_state : null;
    const [showLoading, setShowLoading] = useState(false);
    const [canRedirect, setRedirect] = useState(false);
    const application_id = props.application_id;
    const edit = props.edit;
    const [state, setState] = useState({
        data: {
            build_strategy: "EVERY",
        },
        error_permission:null,
        business_fn_list: [],
        error: {},
        validations: {
            name: [VALIDATION_TYPE_REQUIRED, VALIDATION_TYPE_SMALLCAP_NUM_HIGHFEN_ONLY],
            build_strategy: [VALIDATION_TYPE_REQUIRED],
        },
        availableBuildStrategies: [
            {
                label: "Build once and promote",
                value: "ONCE"
            },
            {
                label: "Build for every environment",
                value: "EVERY"
            }
        ],
        service_id: null

    })
    const commonFunctions = getCommonFunctions(state, setState, inherits);



    function fetchSystemSettingsData() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.system_settings),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, fetchSystemSettingsDataSuccess, fetchSystemSettingsDataFailed);

        setShowLoading(true);

    }

    function fetchSystemSettingsDataSuccess(data) {
        var business_fn_key = data.find(item => item.key == "BUSINESS FUNCTION REQUIRED FOR SERVICES"); // BUSINESS_FUNCTION
        console.log(business_fn_key, "business_fn_key")
        if (business_fn_key && business_fn_key.value && business_fn_key.value == "true") {
            getBussinessFnList()
        }
        setState(new_state => ({
            ...new_state,
            business_fn_key: business_fn_key,
        }));
        setShowLoading(false);
    }
    function fetchSystemSettingsDataFailed(error) {

        setState(new_state => ({
            ...new_state,
            error: error,
            data_loading: false
        }))
    }



    function validateAndSave() {

        // var temp_validation = { ...state.validations };

        var result = ValidateDataSet(state.data, state.validations);
        var post_url = GenerateURL({ application_id: application_id }, properties.api.service_basic_details_post_url)
        if (props.component_id) {
            post_url = GenerateURL({ service_id: props.component_id }, properties.api.service_basic_details_get_url)
        }
        if (result.valid) {
            var post_data = {
                project_id: Number(application_id),
                ...state.data
            }
            if (state.data.external_name) {
                post_data.external_name = state.data.external_name
            } else {
                post_data.external_name = null
            }
            console.log(state.data, "Fdsjajjas")
            post_data = {
                ...post_data,
                repo_url: "NA"
            }
            if(state.business_fn_key && state.business_fn_key.value && state.business_fn_key.value == "true" ){

            }else{
                post_data.business_function =[]
            }
            setShowLoading(true);
            PostData(post_url, post_data, postSuccess, postFail);
        }
        else {
            setState(new_state => ({
                ...new_state,
                error: result.error
            }))
        }
    }
    function postSuccess(response) {
        setShowLoading(false);
        console.log(response, "pipi")
        setState(new_state => ({
            ...new_state,
            service_id: response.id,
            error_permission:''
        }))
        GetPermitedURLs();
        setRedirect(true);
    }
    function postFail(response) {
        var error_msg = null;
        if(response  ){
           
            if(response.error && response.error == "You do not have permission to perform this action."){
              
                error_msg = showErrorHandlerUpdated(response)
            }
            
        }
        setShowLoading(false);
        setState(new_state => ({
            ...new_state,
            error: response,
            error_permission: error_msg
        }))
    }

    useEffect(() => {
        fetchSystemSettingsData()

        if (props.component_id) {
            getService();
        }


    }, []);

    function getService() {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: props.component_id }, properties.api.service_basic_details_get_url),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        };
        setShowLoading(true);
        InvokeApi(requestInfo, handleGetServiceSuccess, () => { setShowLoading(false); });
    }
    const handleGetServiceSuccess = (data) => {
        setShowLoading(false);
        let business_fn_list = data.business_function.map(item => { return item.name })
        setState(new_state => ({
            ...new_state,
            ...data,
            data: {
                name: data.name,
                build_strategy: data.build_strategy,
                external_name: data.external_name ? data.external_name : "",
                business_function: business_fn_list
            }
        }))
    }

    console.log(state, 'st_plm')
    function getBussinessFnList() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.bussiness_fn + "?all=true"),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        };
        setShowLoading(true);
        InvokeApi(requestInfo, getBussinessFnListSuccess, getBussinessFnListFailed);
    }
    const getBussinessFnListSuccess = (data) => {
        let list = data.results.map(item => { return { label: item.name, id: item.id } })
        setShowLoading(false);
        setState(new_state => ({
            ...new_state,
            business_fn_list: list
        }))
    }
    const getBussinessFnListFailed = (data) => {

        setShowLoading(false);
    }
    const onKeyValueHandler = (e) => {
        var k = e.target.name;
        let v = e.target.value;
        console.log(k, v, e, 'st_plm___vavsaastq')
        if (Array.isArray(v) && v.length > 0) {
            v[0] = v[0].replace(" (New Label)", "");
        }
        setState(new_state => ({
            ...new_state,
            data: {
                ...new_state.data,
                [k]: v
            },
            error: { ...new_state.error, [k]: "" }
        }));
    }
    console.log(state.data, 'st_plm___vavsaas')
    return (
        <div className="mt-50" style={{ width: '100%' }}>
            <div className='d-flex align-center space-between'>
                <div className="service-form-heading-section  heading-div">
                    {
                        props.component_id ?
                            <p className="heading-primary">Edit Service</p> :
                            <p className="heading-primary">Create a New Service</p>
                    }

                    <p className="sub-heading-primary">Microservice are used to group pipeline and other setting together</p>
                </div>
                <IconButton className="ml-auto d-block">
                    {edit ?
                        <Link to={"/application/" + application_id + "/service/" + props.component_id + "/detail"}>  <CloseIcon fontSize="large" /></Link> :
                        <Link to="/applicationDashboard">  <CloseIcon fontSize="large" /></Link>
                    }
                </IconButton>
            </div>

            {
                canRedirect ?
                    props.component_id ? <Redirect to={"/application/" + application_id + "/service/" + state.service_id + "/detail"} /> :
                        <Redirect to={"/application/" + application_id + "/service/" + state.service_id + "/env/add"} /> : null
            }
            {
                showLoading ?
                    <Loading /> : null
            }

            <div className={classes.formSec}>
                {
                    typeof(state.error_permission) == 'string' ? 
                    <AlertStrip variant="error" message={state.error_permission} /> : null
                }
                
                <ContentBox>
                    <div style={{ paddingBottom: (state?.error?.name != undefined && state?.error?.name != '') ? '20px' : '0px' }} className="">
                        <Input
                            type="text"
                            placeholder="demo-app"
                            mandatorySign
                            name="name"
                            label="Give a name to your service"
                            subHeading="AlphaNumeric with hyphen"
                            error={state.error}
                            onChangeHandler={commonFunctions.onChangeHandlerSmallInput}
                            data={state.data} />
                    </div>
                    <div className="">
                        <Input
                            type="text"
                            placeholder="Sample-Service"
                            // mandatorySign
                            name="external_name"
                            label="External Name"
                            // subHeading="AlphaNumeric with hyphen"
                            error={state.error}
                            onChangeHandler={commonFunctions.onChangeHandlerSmallInput}
                            data={state.data} />
                    </div>
                    {
                        state.business_fn_key && state.business_fn_key.value && state.business_fn_key.value == "true" ?
                            <div className="auto-complete-dropdown auto-complete-dropdown-42">
                                <Input
                                    type="auto-complete-freesolo" // "select"
                                    label="Business Funtions"
                                    id={"businessfn1"}
                                    name="business_function"
                                    list={state.business_fn_list}
                                    freeSolo={true}
                                    placeholder="Enter Business Function and press enter"
                                    getOptionLabel={(option) => option.label}
                                    error={state.error}
                                    data={state.data}
                                    onChangeHandler={onKeyValueHandler}
                                />
                            </div>
                            : null
                    }

                    <div className="">
                        <Input
                            type="radio"
                            mandatorySign
                            name="build_strategy"
                            list={state.availableBuildStrategies}
                            label="How do you build"
                            error={state.error}
                            data={state.data}
                            onChangeHandler={commonFunctions.onChangeHandler} />
                    </div>
                    <div className="d-flex align-center space-between">
                        {edit ?
                            <Link to={"/application/" + application_id + "/service/" + props.component_id + "/detail"}> <button className="btn btn-outline-grey">Cancel</button></Link> :
                            <Link to="/applicationDashboard"> <button className="btn btn-outline-grey">Cancel</button></Link>
                        }
                        <button className="btn btn-submit" onClick={validateAndSave}>Save &amp; Continue</button>
                    </div>

                </ContentBox>
            </div>
        </div>
    )
}
AddServiceFrom.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export default AddServiceFrom;


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        height: '100vh',
        '& .heading-div': {
            padding: '16px 16px 0px'
        }
    },
    grid: {
        height: '100%'
    },
    leftContainer: {
        background: "linear-gradient(40.64deg, #07192A 24.63%, #030F1B 70.72%, #001A33 90.66%)",
        height: '100%',
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'center',
        '& .logo': {
            '& img': {
                display: 'block',
                margin: 'auto'
            }
        }
    },
    readable: {
        fontSize: '42px',
        letterSpacing: '3px',
        lineHeight: '52px',
        color: '#ffffff',
        fontWeight: 400,
        textAlign: 'center',
        marginTop: '3rem'
    },
    formSec: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '16px'
    }
}));

