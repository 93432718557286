import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { getCommonFunctions } from '../../views/serviceRevamp/add/ci_flow/SourceDetails';
import { Input } from './Input';

const WeekDaysCheckbox = (props) => {
  const inherits = props.inherits ? props.inherits : {}
  const prev_state = props.prev_state ? props.prev_state : null;
  const [state, setstate] = useState(prev_state ? defaultWeekdaysState(prev_state) : defaultWeekdaysState());

  const commonFunctions = getCommonFunctions(state, setstate, inherits);
  function UpdateState(key) {
    setstate(new_state => ({
      ...new_state,
      data: {
        ...new_state.data,
        // [key]: !new_state.data.[key] 
      }
    }));
  }
  return (
    <>
      {
        props.variant == "disabled_state" ?
          <div className="icon-section mt-5 ">
            <div className="btn-group m-auto" data-toggle="buttons">
              <lable className="label-for-multiselect">
                <span className={state.data.monday ? "count-chip count-chip-primary count-chip-lg count-chip-lg-fix-round" : "count-chip count-chip-grey-outline count-chip-lg count-chip-lg-fix-round"} style={{ position: 'relative' }}>
                  <Input
                    type="simple-checkbox"
                    lable=""
                    name={"monday"}
                    data={state.data}
                    error={state.error}
                    onChangeHandler={() => { }}
                  />
                  <span className="text-of-chip" >M</span>
                </span>
              </lable>
              <lable className="label-for-multiselect">
                <span className={state.data.tuesday ? "count-chip count-chip-primary count-chip-lg count-chip-lg-fix-round" : "count-chip count-chip-grey-outline count-chip-lg count-chip-lg-fix-round"} style={{ position: 'relative' }}>
                  <Input
                    type="simple-checkbox"
                    lable=""
                    name="tuesday"
                    data={state.data}
                    error={state.error}
                    onChangeHandler={() => { }}
                  />
                  <span className="text-of-chip">T</span>
                </span>
              </lable>
              <lable className="label-for-multiselect">
                <span className={state.data.wednesday ? "count-chip count-chip-primary count-chip-lg count-chip-lg-fix-round" : "count-chip count-chip-grey-outline count-chip-lg count-chip-lg-fix-round"} style={{ position: 'relative' }}>
                  <Input
                    type="simple-checkbox"
                    lable=""
                    name="wednesday"
                    data={state.data}
                    error={state.error}
                    onChangeHandler={() => { }}
                  />
                  <span className="text-of-chip">W</span>
                </span>
              </lable>
              <lable className="label-for-multiselect">
                <span className={state.data.thursday ? "count-chip count-chip-primary count-chip-lg count-chip-lg-fix-round" : "count-chip count-chip-grey-outline count-chip-lg count-chip-lg-fix-round"} style={{ position: 'relative' }}>
                  <Input
                    type="simple-checkbox"
                    lable=""
                    name="thursday"
                    data={state.data}
                    error={state.error}
                    onChangeHandler={() => { }}
                  />
                  <span className="text-of-chip">T</span>
                </span>
              </lable>
              <lable className="label-for-multiselect">
                <span className={state.data.friday ? "count-chip count-chip-primary count-chip-lg count-chip-lg-fix-round" : "count-chip count-chip-grey-outline count-chip-lg count-chip-lg-fix-round"} style={{ position: 'relative' }}>
                  <Input
                    type="simple-checkbox"
                    lable=""
                    name="friday"
                    data={state.data}
                    error={state.error}
                    onChangeHandler={() => { }}
                  />
                  <span className="text-of-chip">F</span>
                </span>
              </lable>
              <lable className="label-for-multiselect">
                <span className={state.data.saturday ? "count-chip count-chip-primary count-chip-lg count-chip-lg-fix-round" : "count-chip count-chip-grey-outline count-chip-lg count-chip-lg-fix-round"} style={{ position: 'relative' }}>
                  <Input
                    type="simple-checkbox"
                    lable=""
                    name="saturday"
                    data={state.data}
                    error={state.error}
                    onChangeHandler={() => { }}
                  />
                  <span className="text-of-chip">S</span>
                </span>
              </lable>
              <lable className="label-for-multiselect">
                <span className={state.data.sunday ? "count-chip count-chip-primary count-chip-lg count-chip-lg-fix-round" : "count-chip count-chip-grey-outline count-chip-lg count-chip-lg-fix-round"} style={{ position: 'relative' }}>
                  <Input
                    type="simple-checkbox"
                    lable=""
                    name="sunday"
                    data={state.data}
                    error={state.error}
                    onChangeHandler={() => { }}
                  />
                  <span className="text-of-chip">S</span>
                </span>
              </lable>
            </div>
          </div> :
          <div className="icon-section mt-5 ">
            <div className="btn-group m-auto" data-toggle="buttons">
              <Input
                type="round-checkbox"
                label="M"
                name={"monday"}
                data={state.data}
                error={state.error}
                onChangeHandler={commonFunctions.onChangeHandler}
              />
              <Input
                type="round-checkbox"
                label="T"
                name="tuesday"
                data={state.data}
                error={state.error}
                onChangeHandler={commonFunctions.onChangeHandler}
              />

              <Input
                type="round-checkbox"
                label="W"
                name="wednesday"
                data={state.data}
                error={state.error}
                onChangeHandler={commonFunctions.onChangeHandler}
              />
              <Input
                type="round-checkbox"
                label="T"
                name="thursday"
                data={state.data}
                error={state.error}
                onChangeHandler={commonFunctions.onChangeHandler}
              />
              <Input
                type="round-checkbox"
                label="F"
                name="friday"
                data={state.data}
                error={state.error}
                onChangeHandler={commonFunctions.onChangeHandler}
              />
              <Input
                type="round-checkbox"
                label="S"
                name="saturday"
                data={state.data}
                error={state.error}
                onChangeHandler={commonFunctions.onChangeHandler}
              />
              <Input
                type="round-checkbox"
                label="S"
                name="sunday"
                data={state.data}
                error={state.error}
                onChangeHandler={commonFunctions.onChangeHandler}
              />
            </div>
          </div>

      }

    </>
  )
}


WeekDaysCheckbox.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}


export default WeekDaysCheckbox;

export function defaultWeekdaysState(prev_state) {
  return {
    data: prev_state ? {
      monday: prev_state.includes('monday') ? true : false,
      tuesday: prev_state.includes('tuesday') ? true : false,
      wednesday: prev_state.includes('wednesday') ? true : false,
      thursday: prev_state.includes('thursday') ? true : false,
      friday: prev_state.includes('friday') ? true : false,
      saturday: prev_state.includes('saturday') ? true : false,
      sunday: prev_state.includes('sunday') ? true : false
    } : {},
    error: {},
    validations: {
    },
  }
}
