import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import SquareAvatar from '../../components/genericComponents/AvatarSquare';
import { Link } from 'react-router-dom';
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Tooltip } from '@material-ui/core';
import SingleDeployDetails from './SingleDeployDetails';
import GenerateURL from '../../util/APIUrlProvider';
import properties from '../../properties/properties';
import InvokeApi from '../../util/apiInvoker';
import { getDuration } from '../service/listing/components/History';
import moment from 'moment';
import Pagination from '../../components/Pagination';
import { Loading } from '../utils/Loading';
import Checkbox from '@material-ui/core/Checkbox';
import EnvSelectorNew from '../../components/genericComponents/EnvSelectorNew';
import SelectWithInsideLabel from '../../components/genericComponents/inputs/SelectWithInsideLabel';

const SingleServiceCard = (props) => {
    const classes = useStyles();
    const service_data = props.service_data;
    const open_by_default = props.open_by_default
    // const execution_data = props.execution_data;
    const handleSaveSelectedRollback = props.handleSaveSelectedRollback;
    const selected_rollbacks = props.selected_rollbacks;
    const handleRemoveRollbackPoint = props.handleRemoveRollbackPoint;
    const service_name = props.service_name

    const [state, setState] = useState({ sectionOpen: open_by_default, singleDeployOpen: false, selected_services: [] });

    const toggleSectionOpen = () => {
        setState({
            ...state,
            sectionOpen: !state.sectionOpen
        })
    }

    console.log(service_data,"dsbchjbsdchjbsdhc")

    useEffect(() => {
        setState((new_state) => ({
            ...new_state,
            service_selected_env_master: getServiceSelectedEnvMaster(),
        }))
    }, [service_data])


    useEffect(() => {
        setState((new_state) => ({
            ...new_state,
            service_selected_env: getServiceSelectedEnv(),
        }))
    }, [state.service_selected_env_master])

    useEffect(() => {
        setState((new_state) => ({
            ...new_state,
            selected_deployment_name: getSelectedDeploymentName("deployment_name"),
            selected_env_cd_detaild_id: getSelectedDeploymentName("cd_id")
        }))
    }, [state.service_selected_env])



    useEffect(() => {
        if (state.selected_env_cd_detaild_id) {
            getRollbackPoints();
        }
    }, [state.selected_env_cd_detaild_id])

    console.log(state.selected_env_cd_detaild_id,"shgdvghcsvdgcsdvcsgd")

    useEffect(() => {
        getDeploymentNameList()
    },[state.sub_env_data])

    function getServiceSelectedEnvMaster(type) {
        if (type == "envTabList") {
            var master_envs = Object.keys(service_data)
            var tab_list = []
            master_envs.forEach((item) => {
                var obj = {
                    order: item,
                    tabName: item,
                    // total_sub_env: master_envs.length
                }
                tab_list.push(obj);
            })
            return tab_list
        } else {
            var master_envs = Object.keys(service_data)
            //{master_env: master_envs[0],sub_env: subkssk,deployment_name: func}
            return master_envs[0]
        }
    }

    function getServiceSelectedEnv(type) {
        if (type == "componentEnvTabList") {
            var first_env = state.service_selected_env_master && state.service_selected_env_master
            var env_master_data = service_data[first_env]
            var env_list = env_master_data && Object.keys(env_master_data)
            var option_list = []
            if (env_list) {
                env_list.forEach((item) => {
                    var obj = {
                        order: item,
                        tabName: item
                    }
                    option_list.push(obj)
                })
            }
            return option_list;
        } else {
            var first_env = state.service_selected_env_master && state.service_selected_env_master
            var env_master_data = service_data[first_env]
            var env_list = env_master_data && Object.keys(env_master_data)
            if (env_list) {
                return env_list[0];
            }
        }
    }


    function getSelectedDeploymentName(type) {
        if (state.sub_env_change) {
            let master_env = state.service_selected_env_master && state.service_selected_env_master
            let master_env_data = service_data[master_env]
            
            let sub_env = state.service_selected_env

            let sub_env_data = master_env_data[sub_env]

            console.log(sub_env_data,"sdbcjhsbdghcvsdghvc")

            setState((new_state) => ({
                ...new_state,
                sub_env_data: sub_env_data
            }))

            if (type == "deployment_name" && sub_env_data) {
                return sub_env_data[0].deployment_name
            }
            if (type == "cd_id") {
                return sub_env_data[0].env_cd_detail_id
            }

        } else {
            var first_env = state.service_selected_env_master && state.service_selected_env_master
            var env_master_data = service_data[first_env]
            var env_list = env_master_data && Object.keys(env_master_data)

            var first_env = env_list && env_list[0]
            if (env_master_data && first_env) {
                var sub_env_data = env_master_data[first_env]
            }
            setState((new_state) => ({
                ...new_state,
                sub_env_data: sub_env_data
            }))
            if (type == "deployment_name" && sub_env_data && sub_env_data[0]) {
                return sub_env_data[0].deployment_name
            }
            if (type == "cd_id" && sub_env_data && sub_env_data[0]) {
                return sub_env_data[0].env_cd_detail_id
            }
        }

    }

    function getDeploymentNameList() {
        var option_list = []

        state.sub_env_data && state.sub_env_data.forEach((item) => {
            var option_obj = {
                id: item.deployment_name,
                label: item.deployment_name
            }

            option_list.push(option_obj)
        })

        return option_list;
    }

    function getRollbackPoints() {
        var requestInfo = {
            endPoint: GenerateURL({ cd_id: state.selected_env_cd_detaild_id }, properties.api.rollback_points),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, fetchRollbackPointsSuccess, fetchRollbackPointsFailure);
        setState((new_state) => ({
            ...new_state,
            loading: true
        }))
    }

    function fetchRollbackPointsSuccess(response) {
        setState((new_state) => ({
            ...new_state,
            loading: false,
            rollbackPoints: response.results,
            count: response.count,
            next: response.next ? properties.api.baseURL + response.next : null,
            previous: response.previous ? properties.api.baseURL + response.previous : null,
            error_msg: null,
            total_page: Number.isInteger(Number(response.count) / 10)
                ? (Number(response.count) / 10).toFixed(0)
                : (Number(response.count) / 10 + 1).toFixed(0) > Number(response.count) / 10 + 1
                    ? (Number(response.count) / 10 + 1).toFixed(0) - 1
                    : (Number(response.count) / 10 + 1).toFixed(0),
            curr_page: 1,
        }))
    }

    function fetchRollbackPointsFailure(error) {
        setState((new_state) => ({
            ...new_state,
            loading: false,
            error: error
        }))
    }

    //prev function 

    function fetchPrevRollbackPoints(data, url) {
        var requestInfo = {
            endPoint: GenerateURL({ cd_id: service_data.env_cd_detail_id }, properties.api.rollback_points),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        if (data) {
            requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
        }

        if (url) {
            requestInfo.endPoint = url;
        }

        InvokeApi(
            requestInfo,
            fetchPrevRollbackPointsSuccess,
            fetchPrevRollbackPointsFailure
        );
        setState((new_state) => ({
            ...new_state,
            loading: true,
        }));
    }

    function fetchPrevRollbackPointsSuccess(response) {
        setState(new_state => ({
            ...new_state,
            rollbackPoints: response.results,
            loading: false,
            count: response.count,
            next: response.next ? properties.api.baseURL + response.next : null,
            previous: response.previous ? properties.api.baseURL + response.previous : null,
            error_msg: null,
            total_page: Number.isInteger(Number(response.count) / 10)
                ? (Number(response.count) / 10).toFixed(0)
                : (Number(response.count) / 10 + 1).toFixed(0) > Number(response.count) / 10 + 1
                    ? (Number(response.count) / 10 + 1).toFixed(0) - 1
                    : (Number(response.count) / 10 + 1).toFixed(0),
            curr_page: Number(new_state.curr_page - 1),
        }));
    }

    function fetchPrevRollbackPointsFailure(error) {
        setState((new_state) => ({
            ...new_state,
            loading: false,
            error: error
        }))
    }

    //next function

    function fetchNextRollbackPoints(data, url) {
        var requestInfo = {
            endPoint: GenerateURL({ cd_id: service_data.env_cd_detail_id }, properties.api.rollback_points),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        if (data) {
            requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
        }

        if (url) {
            requestInfo.endPoint = url;
        }
        setState((new_state) => ({
            ...new_state,
            loading: true,
        }));
        InvokeApi(
            requestInfo,
            fetchNextRollbackPointsSuccess,
            fetchNextRollbackPointsFailure
        );
    }

    function fetchNextRollbackPointsSuccess(response) {
        setState(new_state => ({
            ...new_state,
            rollbackPoints: response.results,
            loading: false,
            count: response.count,
            next: response.next ? properties.api.baseURL + response.next : null,
            previous: response.previous ? properties.api.baseURL + response.previous : null,
            error_msg: null,
            total_page: Number.isInteger(Number(response.count) / 10)
                ? (Number(response.count) / 10).toFixed(0)
                : (Number(response.count) / 10 + 1).toFixed(0) > Number(response.count) / 10 + 1
                    ? (Number(response.count) / 10 + 1).toFixed(0) - 1
                    : (Number(response.count) / 10 + 1).toFixed(0),
            curr_page: Number(new_state.curr_page + 1),
        }));
    }

    function fetchNextRollbackPointsFailure(error) {
        setState((new_state) => ({
            ...new_state,
            loading: false,
            error: error
        }))
    }

    //page function

    function fetchPageRollbackInfo(enteredPageNumber) {
        var requestInfo = {
            endPoint: GenerateURL({ cd_id: service_data.env_cd_detail_id }, properties.api.rollback_points),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        if (enteredPageNumber > 1) {
            requestInfo.endPoint =
                requestInfo.endPoint +
                "?limit=10&offset=" +
                (enteredPageNumber - 1) * 10;
        }

        var current_page = enteredPageNumber;

        setState((new_state) => ({
            ...new_state,
            loading: true,
        }));
        InvokeApi(
            requestInfo,
            fetchPageRollbackInfoSuccess,
            fetchPageRollbackInfoFailure
        );
    }

    function fetchPageRollbackInfoSuccess(response) {
        setState(new_state => ({
            ...new_state,
            rollbackPoints: response.results,
            loading: false,
            count: response.count,
            next: response.next ? properties.api.baseURL + response.next : null,
            previous: response.previous ? properties.api.baseURL + response.previous : null,
            error_msg: null,
            total_page: Number.isInteger(Number(response.count) / 10)
                ? (Number(response.count) / 10).toFixed(0)
                : (Number(response.count) / 10 + 1).toFixed(0) > Number(response.count) / 10 + 1
                    ? (Number(response.count) / 10 + 1).toFixed(0) - 1
                    : (Number(response.count) / 10 + 1).toFixed(0),
            curr_page: Number(count),
        }));
    }

    function fetchPageRollbackInfoFailure(error) {
        setState((new_state) => ({
            ...new_state,
            loading: false,
            error: error
        }))
    }

    const handleSingleDeployOpen = (id, cd_id, data) => {
        setState({
            ...state,
            singleDeployOpen: !state.singleDeployOpen,
            selectedRollbackId: id,
            rollbackCdId: cd_id,
            selectedRollBackData: data
        })
    }


    const handleRollbackSelect = (rollback_data) => {

        var new_obj = {}
        new_obj.rollback_id = rollback_data.id
        new_obj.last_deploy_id = service_data.last_deploy_detail.id
        new_obj.service_id = service_data.id
        new_obj.cd_id = rollback_data.env_cd_detail_id

        var rollback_name_obj = {}
        rollback_name_obj.service_name = service_data.name
        rollback_name_obj.deploy_name = `Deploy_id ${rollback_data.id}`
        handleSaveSelectedRollback(new_obj, rollback_name_obj)
        handleServiceRollbackSelect(service_data.name)
    }

    function handleServiceRollbackSelect(service_name) {
        var arr = [service_name]

        if (!state.selected_services.includes(service_name)) {
            setState((new_state) => ({
                ...new_state,
                selected_services: [...new_state.selected_services, ...arr],
            }))
        }
    }

    const handleClickOnRemove = (rollback_data) => {
        var rollback_name_obj = {}
        rollback_name_obj.service_name = service_data.name
        rollback_name_obj.deploy_name = `Deploy_id ${rollback_data.id}`

        handleRemoveRollbackPoint(rollback_data, rollback_name_obj);
        handleServiceRollbackRemove();
    }

    function handleServiceRollbackRemove() {
        var updated_selected_services = []

        state.selected_services.forEach((item) => {
            if (item != service_data.name) {
                updated_selected_services.push(item);
            }
        })

        setState((new_state) => ({
            ...new_state,
            selected_services: [...updated_selected_services],
        }))

    }

    function updateSelectedMasterEnv(env) {
        console.log(env,"sbdhbhjsbdhjvs")
        setState((new_state) => ({
            ...new_state,
            service_selected_env_master : env,
            sub_env_change : false,
        }))
    }

    function updateComponentEnv(name) {
        setState((new_state) => ({
            ...new_state,
            service_selected_env: name,
            sub_env_change: new_state.service_selected_env !== name ? true : false
        }))
    }

    function selectDeployment(deployment_name) {
        setState((new_state) => ({
            ...new_state,
            selected_deployment_name: deployment_name,
            selected_env_cd_detaild_id: getUpdatedCdDetailID(deployment_name)
        }))
    }

    function getUpdatedCdDetailID(deployment_name) {
        var cd_id = state.sub_env_data.find((ele) => ele.deployment_name == deployment_name)

        return cd_id.env_cd_detail_id
    }


    return (
        <div className={classes.serviceCard} style={state.sectionOpen ? { padding: "12px 12px 20px 12px" } : { padding: "10px 24px 10px 12px" }}>
            <div className="service-container-head">
                <div className="left-content">
                    <HeadContent service_data={service_data} service_name={service_name} />
                </div>
                <div className="right-content" >
                    {
                        state.sectionOpen &&
                        <div style={{ width: "330px" }}>
                            <EnvSelectorNew envTabList={getServiceSelectedEnvMaster("envTabList")} selectedEnvTab={state.service_selected_env_master} envChangeHandler={updateSelectedMasterEnv} componentEnvTabList={getServiceSelectedEnv("componentEnvTabList")} selectedComponentEnv={state.service_selected_env} componentEnvChangeHandler={updateComponentEnv} />
                        </div>
                    }
                    {
                        state.sectionOpen &&
                        <div style={{ width: "214px", marginLeft: "20px" }}>
                            <SelectWithInsideLabel list={getDeploymentNameList()} onChangeHandler={selectDeployment} label={"Deployment: "} SelectedDeployment={state.selected_deployment_name} optionsWidth={214} />
                        </div>
                    }
                    <IconButton>
                        {
                            state.sectionOpen ?

                                <ExpandLessIcon style={{ fontSize: '24px', color: '#666', lineHeight: 1, cursor: 'pointer' }} onClick={toggleSectionOpen} />
                                :
                                <ExpandMoreIcon style={{ fontSize: '24px', color: '#666', lineHeight: 1, cursor: 'pointer' }} onClick={toggleSectionOpen} />
                        }
                    </IconButton>

                </div>

            </div>
            {
                state.sectionOpen ?
                    <Body handleSingleDeployOpen={handleSingleDeployOpen} service_data={service_data} state={state} rollback_points={state.rollbackPoints} fetchPrevRollbackPoints={fetchPrevRollbackPoints} fetchNextRollbackPoints={fetchNextRollbackPoints} fetchPageRollbackInfo={fetchPageRollbackInfo} handleRollbackSelect={handleRollbackSelect} selected_rollbacks={selected_rollbacks} handleClickOnRemove={handleClickOnRemove} /> : null
            }

        </div>
    )
}

SingleServiceCard.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };

const useStyles = makeStyles((theme) => ({
    serviceCard: {


        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0px 8px 24px 0px #0000000A, 0px 4px 4px 0px #00000014",
        marginBottom: "2rem",
        overflow: "hidden",

        "& .service-container": {
        },

        "& .service-container-head": {
            display: "flex",
            justifyContent: "space-between",
            // padding: "20px",
            alignItems: "center",
        }

    },
    body: {
        background: "#FFFFFF",
        "& .divider-line": {
            height: "1px",
            backgroundColor: "#E2E2E2"
        },

        "& .current-deployment": {
            margin: "24px 0",

            "& .current-deployment-header": {
                color: "var(--CONTENT-TERTIARY, #787878)",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
            },

            "& .current-deployment-details": {
                display: "grid",
                gridTemplateColumns: "20% 20% 20% 20% 20%",
                // height: "69px",
                padding: "14px 12px",
                borderRadius: "6px",
                border: "1px solid #EBEBEB",
                marginTop: "12px",

                "& .single-details-div": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                },

                "& .details-type": {
                    color: '#787878',
                    fontSize: "12px",
                    
                    fontWeight: '600',
                },

                "& .details-data": {
                    color: '#505050',
                    fontSize: "12px",
                    
                    fontWeight: '500',
                    marginTop: "4.5px",
                },

                "& .deploy-status": {
                    display: "flex",
                    // justifyContent: "space-between",

                    "& .deploy-number": {
                        color: 'black',
                        fontSize: "14px",
                        
                        fontWeight: '600',
                        wordWrap: 'break-word'
                    },
                },

                "& .artifact-details": {

                }
            }
        },

        "& .rollback-points": {

            "& .rollback-header": {
                color: '#2F2F2F',
                fontSize: "16px",
                
                fontWeight: '600',
                marginTop: "24px",
            },

            "& .rollback-list": {
                borderRadius: "6px",
                border: "1px solid var(--GREY-SCALE-GREY-200, #E6E6E6)",
                marginTop: "16px",

                "& .rollback-points-header": {
                    display: "grid",
                    gridTemplateColumns: "20% 20% 20% 20% 20%",
                    backgroundColor: "#FAFAFA",
                    padding: "12px 20px",

                    "& .header-name": {
                        color: '#787878',
                        fontSize: "12px",
                        
                        fontWeight: '600',
                    }
                },

                "& .rollback-points-strip": {
                    display: "grid",
                    gridTemplateColumns: "20% 20% 20% 20% 20%",
                    padding: "20px",
                    backgroundColor: '#ffffff',
                    borderBottom: "1px solid var(--GREY-SCALE-GREY-200, #E6E6E6)",

                    "& .info-heading": {
                        fontSize: "12px",
                        fontWeight: "500",
                        lineHeight: "15px",
                        color: "#2F2F2F",
                    },

                    "& .info": {
                        fontSize: "12px",
                        fontWeight: "500",
                        lineHeight: "14.63px",
                        color: "#787878",
                    },

                    "& .deploy-id": {
                        display: "flex",

                        "& .deploy-icon": {
                            backgroundColor: '#F5FAFF',
                            borderRadius: "4px",
                            border: '1px #DFEDFF solid',
                            display: "flex",
                            justifyContent: 'center',
                            alignItems: "center",
                            padding: "10px",
                            width: "44px",
                            height: "44px"
                        }
                    },

                    "& .view-icon": {
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "6px",
                        border: "1px solid #DBDBDB",
                        height: "38px",
                        width: "38px",
                        justifyContent: "center",
                    }
                }
            },
        }
    }
}));


const Body = (props) => {
    const classes = useStyles();
    const handleSingleDeployOpen = props.handleSingleDeployOpen;
    const service_data = props.service_data;
    const rollback_points = props.rollback_points;
    const handleRollbackSelect = props.handleRollbackSelect;
    const selected_rollbacks = props.selected_rollbacks;
    const handleClickOnRemove = props.handleClickOnRemove;

    return (
        <div className={classes.body}>
            <div style={{ height: '1px', marginTop: "10px", backgroundColor: '#E2E2E2' }}></div>
            <div className='current-deployment'>
                <p className='current-deployment-header'>Current Deployment Details</p>
                <div className='current-deployment-details'>
                    <div className='deploy-status'>
                        <div>
                            <p className='deploy-number'>Deploy #7</p>
                            <div className='new-status-chip new-success' style={{ marginTop: "12px" }}>
                                SUCCESS
                            </div>
                        </div>
                        <div style={{ width: "1px", backgroundColor: '#E6E6E6', marginLeft: "135px" }}></div>
                    </div>
                    <div className='artifact-details single-details-div' >
                        <div>
                            <p className='details-type'>Artifact</p>
                            <p className='details-data'>5-20231209T1149</p>
                        </div>
                    </div>
                    <div className='deploy-time single-details-div'>
                        <div>
                            <p className='details-type'>Deployed On:</p>
                            <p className='details-data'>2024-02-19 / 09:00:00</p>

                        </div>
                    </div>
                    <div className='deploy-user single-details-div'>
                        <div>
                            <p className='details-type'>Deployed By:</p>
                            <p className='details-data'>Abhishek Verma</p>
                        </div>
                    </div>
                    <div className='deploy-user single-details-div'>
                        <div>
                            <p className='details-type'>Duration</p>
                            <p className='details-data'>12m</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='rollback-points'>
                <p className='rollback-header'>SELECT ROLLBACK</p>
                <RollbackPoints />
            </div>
            {/* <Grid container>
                <Grid item lg={3} md={2} style={{ backgroundColor: "#F8F8F8", borderRight: "1px solid #DDDDDD", }}>
                    <CurrentDeployment service_data={service_data} />
                </Grid>
                <Grid item lg={9} md={10}>
                    <RollbackPoints handleSingleDeployOpen={handleSingleDeployOpen} service_data={service_data} rollback_points={rollback_points} fetchPageRollbackInfo={props.fetchPageRollbackInfo} fetchNextRollbackPoints={props.fetchNextRollbackPoints} fetchPrevRollbackPoints={props.fetchPrevRollbackPoints} state={props.state} handleRollbackSelect={handleRollbackSelect} selected_rollbacks={selected_rollbacks} handleClickOnRemove={handleClickOnRemove} />
                </Grid>
            </Grid> */}
        </div>
    );
}

Body.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };

const CurrentDeployment = (props) => {
    const service_data = props.service_data;
    const currentDeployData = service_data.last_deploy_detail;

    function getCreatedAt(time) {

        var time = time;

        var data = time.split("T")

        const date = data[0];

        const time_data = data[1];

        const updated_time = time_data.split(".")

        const updated_str = date + `/${updated_time[0]}`;

        return updated_str;

    }

    return (
        <div className='current-deployment'>
            <div className='deployment-header'>
                <p>Current Deployment</p>
            </div>
            <div className='deployment-body'>
                <div className='deployment-status'>
                    <p>Deploy #{currentDeployData?.deploy_number}</p>
                    <div>
                        <p style={{  fontStyle: "normal", color: "#404040" }}>Status</p>
                        <div style={{ display: "flex", alignItems: "center", height: "22px", background: currentDeployData.status == "SUCCESS" ? "#82CD47" : "#FE5B5B", borderRadius: "50px", padding: "0 5px" }}>
                            <p style={{  fontStyle: "normal", fontWeight: "400", fontSize: "10px", lineHeight: "15px", color: "#FFFFFF" }}>{currentDeployData.status}</p>
                        </div>
                    </div>
                </div>
                <div className='deployment-artifact'>
                    <p style={{ color: "#404040" }}>Artifact</p>
                    <div style={{ display: "flex", alignItems: "center", height: "22px", width: "fit-content", background: "#124D9B", borderRadius: "5px", padding: "0 5px" }}>
                        <p style={{  fontStyle: "normal", fontWeight: "600", fontSize: "10px", lineHeight: "15px", color: "#FFFFFF" }}>{currentDeployData.deploy_tag}</p>
                    </div>
                </div>
                <div className='deployment-duration-time'>
                    <div style={{ marginRight: "35px" }}>
                        <p style={{ color: "#404040" }}>Duration</p>
                        <p style={{ color: "#666666" }}>
                            {
                                currentDeployData.activity_status && currentDeployData.activity_status.start_time && currentDeployData.activity_status.end_time ?

                                    getDuration(new Date(currentDeployData.activity_status.start_time), new Date(currentDeployData.activity_status.end_time)) : "NA"
                            }
                        </p>
                    </div>
                    <div>
                        <p style={{ color: "#404040" }}>Deployed On:</p>
                        <p style={{ color: "#666666" }}>{getCreatedAt(currentDeployData.activity_status.created_at)}</p>
                    </div>
                </div>
                <div className='deployment-basic-details'>
                    {/* <p>Deployed On: <span>{currentDeployData.activity_status && currentDeployData.activity_status.start_time ? moment(currentDeployData.activity_status.start_time).fromNow() : ""}</span></p> */}
                    <p style={{ marginTop: "22px" }}>Deployed By: <span>{currentDeployData.deploy_by_user}</span></p>
                    {/* <p style={{ marginTop: "22px" }}>Uptime: <span>N/A</span></p> */}
                </div>
            </div>
        </div>
    )
}

CurrentDeployment.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };


const RollbackPoints = (props) => {
    const handleSingleDeployOpen = props.handleSingleDeployOpen;
    const service_data = props.service_data;
    const rollback_points = props.rollback_points;
    const state = props.state;
    const fetchPrevRollbackPoints = props.fetchPrevRollbackPoints
    const fetchNextRollbackPoints = props.fetchNextRollbackPoints
    const fetchPageRollbackInfo = props.fetchPageRollbackInfo
    const handleRollbackSelect = props.handleRollbackSelect
    const selected_rollbacks = props.selected_rollbacks
    const handleClickOnRemove = props.handleClickOnRemove

    const [checked, setChecked] = React.useState(true);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    function isRollbackSelected(selected_id) {
        if (selected_rollbacks.length > 0) {
            var clicked_rollback = selected_rollbacks.find((item) => {
                return item.rollback_id == selected_id
            })

            if (clicked_rollback) {
                return true;
            }
        }
    }

    function isSelectedServices() {
        if (state.selected_services.includes(service_data.name)) {
            return true;
        } else {
            return false;
        }
    }


    return (
        <div className='rollback-list'>
            <div className='rollback-points-header'>
                <p className='header-name'>Deploy ID / Duration</p>
                <p className="header-name">Deployed By</p>
                <p className='header-name'>Artifact ID</p>
                <p className='header-name'>Branch</p>
                <p></p>
            </div>
            <div className='rollback-points-strip'>
                <div className='deploy-id'>
                    <div className='deploy-icon'>
                        <span className='ri-rocket-2-line' style={{ color: "#0086FF", fontSize: "24px", lineHeight: "1" }}></span>
                    </div>
                    <div style={{ marginLeft: "22px" }}>
                        <p className='info-heading'>Deploy #3</p>
                        <div className='d-flex align-center'>
                            <span className='ri-time-line font-16' style={{ color: "#787878" }}></span>
                            <p className='info'>12m</p>
                        </div>
                    </div>
                </div>
                <div className='deployed-by'>
                    <p className='info-heading'>Hitesh bidhane</p>
                    <p className='info' style={{ marginTop: "4px" }}>14-Mar 2023</p>
                </div>
                <div className='artifact'>
                    <p className='info-heading'>5-20231209T1149</p>
                </div>
                <div className='branch'>
                    <p className='info-heading'>Merge_branch,juno-212</p>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div className='view-icon'>
                        <span className='ri-eye-line' style={{ color: "#124D9B", fontSize: "20px", lineHeight: "1" }}></span>
                    </div>
                </div>
            </div>
            <div className='rollback-points-strip'>
                <div className='deploy-id'>
                    <div className='deploy-icon'>
                        <span className='ri-rocket-2-line' style={{ color: "#0086FF", fontSize: "24px", lineHeight: "1" }}></span>
                    </div>
                    <div style={{ marginLeft: "22px" }}>
                        <p className='info-heading'>Deploy #3</p>
                        <div className='d-flex align-center'>
                            <span className='ri-time-line font-16' style={{ color: "#787878" }}></span>
                            <p className='info'>12m</p>
                        </div>
                    </div>
                </div>
                <div className='deployed-by'>
                    <p className='info-heading'>Hitesh bidhane</p>
                    <p className='info' style={{ marginTop: "4px" }}>14-Mar 2023</p>
                </div>
                <div className='artifact'>
                    <p className='info-heading'>5-20231209T1149</p>
                </div>
                <div className='branch'>
                    <p className='info-heading'>Merge_branch,juno-212</p>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div className='view-icon'>
                        <span className='ri-eye-line' style={{ color: "#124D9B", fontSize: "20px", lineHeight: "1" }}></span>
                    </div>
                </div>
            </div>
            {/* <div className='rollback-header'>
                <div>
                    <p>These are the available rollback point</p>
                    <p style={{ color: "#9D9D9D", fontSize: "10px" }}>You can select any deployment to rollback</p>
                </div>
            </div>
            <div className='rollback-body'>
                {
                    state.loading ?
                        <div style={{ minHeight: '420px', display: 'flex' }}>
                            <Loading varient="light" />
                        </div>
                        :
                        <>
                            <div>
                                <Pagination
                                    total_count={state.total_page}
                                    current_page_count={state.curr_page}
                                    next={state.next}
                                    previous={state.previous}
                                    on_previous_click={() => fetchPrevRollbackPoints(null, state.previous)}
                                    on_next_click={() => fetchNextRollbackPoints(null, state.next)}
                                    on_pageNumber_click={(pageNumber) => fetchPageRollbackInfo(pageNumber)}
                                />
                            </div>
                            {
                                rollback_points && rollback_points.length > 0 && rollback_points.map((single_rollback) =>
                                (
                                    <div className='single-rollback-points' style={isRollbackSelected(single_rollback.id) ? { border: "1px solid #007EFF" } : {}}>
                                        <div className='single-info' style={{ display: "flex", alignItems: "center" }}>
                                            <div className='logo'>
                                                <img src='/images/rollback_point_logo.jpg' style={{ height: "29px", width: "26px" }} alt='..' />
                                            </div>
                                            <div style={{ marginLeft: "10px" }}>
                                                <div>
                                                    <div className='title'>Deployment Id</div>
                                                    <div className='value'>
                                                        <Tooltip title={single_rollback.deploy_number}>
                                                            <p className='text-ellipsis-150'>Deploy #{single_rollback.deploy_number}</p>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                                <div style={{ marginTop: "34px" }}>
                                                    <div className='title'>Artifact Id</div>
                                                    <div className='value'>
                                                        <Tooltip title={single_rollback.deploy_tag}>
                                                            <p className='text-ellipsis-150'>{single_rollback.deploy_tag}</p>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='single-info' style={{ alignSelf: "start" }}>
                                            <div>
                                                <div className='title'>Status</div>
                                                <div className={single_rollback.status == "SUCCESS" ? 'success-chip' : "failure-chip"}>
                                                    {single_rollback.status}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='single-info'>
                                            <div>
                                                <div className='title'>Deployment time</div>
                                                <div className='value'>
                                                    {single_rollback.activity_status && single_rollback.activity_status.start_time ? moment(single_rollback.activity_status.start_time).fromNow() : ""}
                                                </div>
                                            </div>
                                            <div style={{ marginTop: "34px" }}>
                                                <div className='title'>Deploy Duration</div>
                                                <div className='value'>
                                                    {
                                                        single_rollback.activity_status && single_rollback.activity_status.start_time && single_rollback.activity_status.end_time ?

                                                            getDuration(new Date(single_rollback.activity_status.start_time), new Date(single_rollback.activity_status.end_time)) : "NA"
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className='single-info'>
                                            <div>
                                                <div className='title'>Deployed by</div>
                                                <div className='value'>
                                                    <Tooltip title={single_rollback.deploy_by_user}>
                                                        <p className='text-ellipsis-150'>{single_rollback.deploy_by_user}</p>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <div style={{ marginTop: "34px" }}>
                                                <div className='title'>Commit id</div>
                                                <div className='value'>
                                                    <Tooltip title={single_rollback.commit_details && single_rollback.commit_details.commit_id ? single_rollback.commit_details.commit_id : "NA"}>
                                                        <p className='text-ellipsis-150'>{single_rollback.commit_details && single_rollback.commit_details.commit_id ? single_rollback.commit_details.commit_id : "NA"}</p>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='single-info'>
                                            <div>
                                                <div className='title'>Commit msg </div>
                                                <div className='value'>
                                                    <Tooltip title={single_rollback.commit_details && single_rollback.commit_details.commit_sha ? single_rollback.commit_details.commit_sha : "NA"}>
                                                        <p className='text-ellipsis-150'>{single_rollback.commit_details && single_rollback.commit_details.commit_sha ? single_rollback.commit_details.commit_sha : "NA"}</p>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <div style={{ marginTop: "34px" }}>
                                                <div className='title'>Branch</div>
                                                <div className='value'>
                                                    Master
                                                </div>
                                            </div>
                                        </div>
                                        <div className='single-info' style={{ display: "flex" }}>
                                            <button onClick={() => handleSingleDeployOpen(single_rollback.id, single_rollback.env_cd_detail_id, single_rollback)}>View</button>
                                            {
                                                isRollbackSelected(single_rollback.id) ?
                                                    <div style={{ marginLeft: "10px" }}>
                                                        <Checkbox
                                                            checked={checked}
                                                            color="primary"
                                                            onChange={() => handleClickOnRemove(single_rollback)}
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        />

                                                    </div>
                                                    :
                                                    isSelectedServices() ?
                                                        <button style={{ color: "#DDDDDD", marginLeft: "10px" }} onClick={() => { }}>Select</button>
                                                        :
                                                        <button style={{ marginLeft: "10px" }} onClick={() => handleRollbackSelect(single_rollback)}>Select</button>
                                            }
                                        </div>
                                    </div>
                                )
                                )
                            }
                        </>
                }
            </div> */}
        </div>
    )
}

RollbackPoints.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };


const HeadContent = (props) => {
    const service_name = props.service_name;
    const environments = props.environments;
    const service_id = props.service_id
    // const all_envs = Object.keys(environments);
    const env_id = props.env_id;
    const application_id = props.application_id;
    const service_data = props.service_data;

    return (
        

            <>
                <SquareAvatar varient="double" name={service_name.toUpperCase()} />
                <div className="" style={{ marginLeft: "12px", }}>
                    <div className="service-name">
                        <Link to={"/application/" + props.application_id + "/service/" + props.service_id + "/detail"}>
                            {service_name.toUpperCase()}
                        </Link>
                    </div>
                </div>
            </>
        
    );
}

HeadContent.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };

export default SingleServiceCard;