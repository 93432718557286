import { makeStyles, Tooltip } from '@material-ui/core'
import React, { memo, useEffect, useState } from 'react'
import GenericSkeleton from '../../../../components/genericComponents/Skeletons/GenericSkeleton';
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import properties from '../../../../properties/properties';
import { Input } from '../../../../components/genericComponents/Input';

const SubEnvBlock = ({service,addServiceData,selectedMasterEnv}) => {
    const classes = styles()
    const [hoveredSubEnv, setHoveredSubEnv] = useState(null); 
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [subEnvList, setSubEnvList] = useState([]);
    const [error, setError] = useState(null);
    const serviceName = service.component_name;
    const component_env_id = service?.component_env_id;

    const loadSubEnvs = () => {
        let requestInfo = {
            endPoint: GenerateURL({}, properties.api.load_sub_env + `?service=${serviceName}&env_master=${selectedMasterEnv}`),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        
        setLoading(true);
        InvokeApi(requestInfo, loadSubEnvsSuccess, loadSubEnvsFailed);
    }

    const loadSubEnvsSuccess = (response) => {
        setLoading(false);
        if(response.length <= 0){
            let error_msg = 'No sub env found';
            setError((error) => ({
                ...error,
                mainError: error_msg,
            }));
        }else{
            let subEnvList = response.map(item => {
                return { id: item.component_env_id, label: item.project_env_name };
            });
            setData((prevData) => ({
                ...prevData,
                component_env_id: component_env_id? component_env_id : '',
                component_env_name: component_env_id? data.component_env_name : '',
            }));
            setError((error) => ({
                ...error,
                component_env_id: ''
            }));
            setSubEnvList(subEnvList);
        }
        
    }

    const loadSubEnvsFailed = (error) => {
        const error_msg = error.toString();
        setError((error) => ({
            ...error,
            mainError: error_msg,
        }));
        setLoading(false);
    }

    const onKeyValueHandler = (k, v) => {
        let selectedSubEnv = subEnvList.find((env) => env.id == v);
        setData((prevData) => ({
            ...prevData,
            [k]: v,
            component_env_name: selectedSubEnv?.label,
        }));
        setError((prevError)=>({
            ...prevError,
           [k]: '',
        }))
    }

    useEffect(() => {
        addServiceData(service, data);
    }, [data]);

    useEffect(()=>{
        setData({})
        setSubEnvList([]);
        setError(null);
        //addServiceData(service,data);
    },[selectedMasterEnv])
    useEffect(()=>{
        setError((prevError)=>({
            ...prevError,
            ...service?.errors
        }))
        
    },[service?.errors])
    console.log('shdssh',data,subEnvList);

    return (
        <div className='d-flex align-center' style={{gap:"10px",position:'relative'}}
            onMouseEnter={() => setHoveredSubEnv(true)}
            onMouseLeave={() => setHoveredSubEnv(false)}
        >
            {error && error.mainError ? 
                <div className={classes.errorBox}>
                    <span>{error.mainError}</span>
                    <span className='ri-close-fill font-20 cursor-pointer' style={{ lineHeight: '1' }} onClick={()=>setError(null)}></span>
                </div>
            :
            subEnvList.length > 0 ? 
                <div className={classes.autoInput} style={{ width: '90%' }}>
                    <Input
                        type="auto-complete-dropdown"
                        id={"git_urlsZ"}
                        name="component_env_id"
                        list={subEnvList.length > 0 ? subEnvList : []}
                        getOptionLabel={(option) => option.label}
                        error={error}
                        data={data}
                        onChangeHandler={onKeyValueHandler}
                    />
                </div>
                :
                loading ? 
                    <GenericSkeleton width={'90%'} height={37} style={{ borderRadius: '4px' }} />
                :
                <Tooltip title='Select Sub Environment' arrow>
                    <>
                    <div className={classes.defaultBox}>
                        <span className='font-14 font-weight-400 color-value text-ellipsis'>{ service.component_env_name || 'Select Sub Environment'}</span>
                        {hoveredSubEnv && 
                            <span className='ri-edit-2-line font-20 color-key-78 cursor-pointer' style={{ lineHeight: '1' }} onClick={() => loadSubEnvs()}></span>
                        }
                    </div>
                    {error?.component_env_id && 
                         <span className={classes.error}>{error?.component_env_id}</span>
                    }
                    </>
                </Tooltip>
                
            }
        </div>
        
    )
}

export default memo(SubEnvBlock);

const styles = makeStyles((theme)=>({
    defaultBox:{
        width:'90%',
        padding:'8px 12px 8px 12px',
        borderRadius:'4px',
        backgroundColor:'#FAFAFA',
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
        textWrap:'nowrap',
    },
    errorBox: {
        width:'90%',
        padding:'8px 12px 8px 12px',
        borderRadius:'4px',
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
        color: "#BA0000",
        border: '1px solid #FBE6E6',
        background: '#FFF3F3',
    },
    autoInput:{
        '& .input-component .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
            padding: "9.5px 4px !important",
        },
        '& .input-component':{
            marginBottom: '0px !important',
        },
          
        '& .input-component .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
            padding: "10.2px 4px !important",
        },
        '& .input-component .MuiAutocomplete-endAdornment':{
            top:'calc(50% - 12px)',
            position:'absolute !important',
            right: '0 !important'
        },
        '& .input-component .error-message':{
            position: 'absolute !important',
            bottom:'-15px',
        }
    },
    error:{
        color: '#cc0033',
        display: 'inline-block',
        fontSize: '12px',
        position: 'absolute',
        lineHeight: '15px',
        margin: '5px 0 0',
        bottom:'-15px',
    }
}))