import { Popover, Tooltip, makeStyles } from '@material-ui/core'
import React, { useEffect, useState, useRef } from 'react'

const InputWithDropdownJobTemplate = (props) => {
    const rootRef = useRef(null);
    const { jobTemplateList, jobTemplateChangeHandler, selectedJobTemplateId,selectedJobTemplateVersion, label, variant, popupWidth,name,error } = props;
    const classes = useStyles({name,error});
    
    const selectedJobTemplateObj = jobTemplateList.find((obj) => obj.id == selectedJobTemplateId);
    const selectedJobTemplateVersionObj = jobTemplateList.find((obj) => obj.template_version == selectedJobTemplateVersion);
   
    const [anchorEl, setAnchorEl] = useState(null);
    const [state, setState] = useState({
        search: "",
        jobTemplateList: jobTemplateList ? jobTemplateList : [],
    })

    useEffect(() => {
        setState(prevState => ({
            ...prevState,
            jobTemplateList: jobTemplateList
        }));
    }, [jobTemplateList]);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);



    function onJobTemplateClick(order, data) {
        console.log(order, "fdsanfdsalfnas")
        jobTemplateChangeHandler(order, data);
        handleClose();
    }

    console.log("jhdjkwshd",jobTemplateList)

    return (
        <div>
            <p style={{
                color: "#2f2f2f",
                fontSize: "14px",
                fontWeight: "500"
            }}>

                {label ? label : null}
            </p>
            <div ref={rootRef} className={ error && error[name]?.length > 0 ?`${classes.root} error` : `${classes.root}`} onClick={handleClick} style={open ? { borderColor: "#0086FF" } : {}} >
                <div className='selected-label'>
                    <span 
                    className='d-flex align-center font-14 font-weight-500' 
                    style={{ color: '#2f2f2f', lineHeight: 2 }}>
                        {variant === "version" ? selectedJobTemplateVersionObj && selectedJobTemplateVersionObj.name ? null : "Please Select" :  selectedJobTemplateObj && selectedJobTemplateObj.name ? null : "Please Select"}</span>
                    {
                        variant === "version" ?
                            <div className='selected-env font-14 d-flex align-center avtar' style={{ color: '#2f2f2f', lineHeight: 2 }}>{selectedJobTemplateVersionObj ? selectedJobTemplateVersionObj.name + "-" + selectedJobTemplateVersionObj.template_version : null}</div>
                            :
                            <div className='selected-env font-14 d-flex align-center avtar' style={{ color: '#2f2f2f', lineHeight: 2 }}>{selectedJobTemplateObj ? selectedJobTemplateObj.name : null}</div>
                    }

                    {selectedJobTemplateObj && selectedJobTemplateObj.default ? <span className='default-template-chip'>Default Template</span> : null}
                    {variant === "version" ? selectedJobTemplateVersionObj && selectedJobTemplateVersionObj.active ? <span className='default-template-chip-green'>Active</span> : null : null}

                </div>
                {open ? <span className='ri-arrow-up-s-line font-20' style={{ color: '#787878' }}></span> : <span className='ri-arrow-down-s-line font-20' style={{ color: '#787878' }}></span>}
            </div>
            {error && error[name]?.length > 0 ? <span className='error-message'>{error[name]}</span> : null}
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{ style: { overflow: 'visible', borderRadius: "4px", }}}
            >
                <div className={classes.popover} style={{width: rootRef.current ? rootRef.current.clientWidth : 'auto'  } }>
                    <div className='env-list'>
                        {
                            state?.jobTemplateList.length > 0 ?
                                <>
                                <div
                                    className={variant=="versions" ? 
                                        selectedJobTemplateVersion == null?'env-item font-14 selected-item' : 'env-item font-14'
                                        :
                                        selectedJobTemplateId == null ? 
                                        'env-item font-14 selected-item' : 
                                        'env-item font-14'
                                    }
                                    onClick={() => onJobTemplateClick(null,null)}>
                                    <span className='d-flex align-center space-between'>
                                        <span className='component-env-name'>Please Select</span>
                                    </span>    
                                </div>

                                {state.jobTemplateList.map((ele, i) => {

                                    return <div
                                        key={ele.id}
                                        className={
                                            variant=="versions" ?
                                            selectedJobTemplateVersion == ele.template_version ? 
                                            'env-item font-14 selected-item' : 
                                            'env-item font-14'
                                            :
                                            selectedJobTemplateId == ele.id ? 
                                            'env-item font-14 selected-item' : 
                                            'env-item font-14'}
                                        onClick={
                                            variant === "version" ?
                                                () => onJobTemplateClick(ele.template_version, ele.template_version) :
                                                () => onJobTemplateClick(ele.id, ele.name)}>
                                        <span className='d-flex align-center space-between'>
                                            <span className='component-env-name'>{variant === "version" ? ele.name + " - " + ele.template_version : ele.name}</span>
                                            {ele.default ? <span className='default-template-chip' style={{whiteSpace:'nowrap'}}>Default Template</span> : null}
                                            {!ele.project_id ? <span className='component-env-name'>&nbsp;(G)</span> : null}
                                            {variant === "version" ? ele.active ? <span className='default-template-chip-green'>Active</span> : null : null}
                                        </span>
                                        {console.log(selectedJobTemplateVersionObj,"selectedJobTemplateVersionObj____dsds", ele.template_version)}
                                        {
                                            variant === "version" ?
                                            ele.template_version == selectedJobTemplateVersion ?
                                            <span className='ri-checkbox-circle-fill font-17' style={{ color: "#0086FF" }}></span> 
                                        : null:
                                        ele.id == selectedJobTemplateId ?
                                            <span className='ri-checkbox-circle-fill font-17' style={{ color: "#0086FF" }}></span> 
                                        : null}
                                            
                                        </div>
                                })}
                                </>
                                :
                                <div className='d-flex align-center justify-center width-full mt-20' >No Result Found</div>
                        }
                    </div>
                </div>
            </Popover>
        </div>

    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#fff",
        display: "flex",
        padding: "8px 10px 8px 16px",
        alignItems: "center",
        gap: "12px",
        alignSelf: "stretch",
        borderRadius: "4px",
        border: "1px solid #b7b7b7",
        cursor: "pointer",
        '& .selected-label': {
            display: "flex",
            alignItems: "center",
            gap: "3px",
            width: "100%"
        },
        '& .selected-env': {
            display: "inline-block",
            fontWeight: "500",
            color: "#2F2F2F",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
        '& .env-type': {
            display: "inline-flex",
            padding: "4px 8px",
            alignItems: "flex-start",
            borderRadius: "4px",
            gap: "10px",
            //fontSize: "10px",
            fontWeight: "700",
            color: "#FFF",
            width: "fit-content",
        },

        '&:hover': {
            borderColor: "#b7b7b7",
        },
        '& .default-template-chip': {
            backgroundColor: '#EBF5FF',
            border: '1px solid #dfedff',
            color: '#0086ff',
            fontSize: '11px',
            fontWeight: '600',
            padding: '3px 6px',
            textTransform: 'uppercase',
            borderRadius: '4px',
            marginLeft: '5px'
        },
        '& .default-template-chip-green': {
            backgroundColor: '#E6FBEA',
            border: '1px solid #C6E9D9',
            color: '#129E5B',
            fontSize: '11px',
            fontWeight: '600',
            padding: '3px 6px',
            textTransform: 'uppercase',
            borderRadius: '4px',
            marginLeft: '5px'
        }
    },
    popover: {
        //width: "100%",
        display: "flex",
        width: "540px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "5px",
        borderRadius: "6px",
        border: "1px solid #F4F4F4",
        background: "#FFF",
        boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.08)",
        '& .search-container': {
            display: "flex",
            padding: "10px 12px",
            justifyContent: "space-between",
            alignItems: "center",
            alignSelf: "stretch",
            width: "100%",
            height: "40px",
            borderBottom: "1px solid #E6E6E6",
            '& .search-input': {
                border: "none",
                padding: "0",

                '&:focus': {
                    border: "none !important",
                    outline: "none !important",
                }
            },
        },
        '& .list-heading': {
            display: "flex",
            padding: "6px 12px 0px 12px",
            alignItems: "flex-start",
            gap: "10px",
            alignSelf: "stretch",
            textTransform: "uppercase",
            fontSize: "12px",
            fontWeight: "600",
            color: "#949494",
        },
        '& .env-type-list': {
            display: "flex",
            padding: "6px 12px 0px 12px",
            //flexDirection: "column",
            alignItems: "flex-start",
            gap: "10px",
            alignSelf: "stretch",
            '& .env-tab': {
                display: "flex",
                padding: "6px 10px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                borderRadius: "6px",
                border: "1px solid #E6E6E6",
                background: "#FFF",
                //fontSize: "12px",
                fontWeight: "500",
                cursor: "pointer",
                '&:hover': {
                    background: "#f4f4f4",
                }
            },
            '& .selected-env-tab': {
                background: "#0086FF",
                color: "#FFF",
                border: "none",
                '&:hover': {
                    background: "#0086FF !important",
                }
            }
        },
        '& .env-list': {
            display: "flex",
            paddingBottom: "8px",
            flexDirection: "column",
            alignItems: "flex-start",
            alignSelf: "stretch",
            height: "180px",
            overflowY: "scroll",
            '& .env-item': {
                display: "flex",
                padding: "8px 12px",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                cursor: "pointer",
                '&:hover': {
                    background: "#f4f4f4",
                },
                '& .component-env-name': {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                }
            },
            '& .selected-item': {
                background: "#F5FAFF",
            },
            '& .default-template-chip': {
                backgroundColor: '#EBF5FF',
                border: '1px solid #dfedff',
                color: '#0086ff',
                fontSize: '11px',
                fontWeight: '600',
                padding: '3px 6px',
                textTransform: 'uppercase',
                borderRadius: '4px',
                marginLeft: '5px',

            },
            '& .default-template-chip-green': {
                backgroundColor: '#E6FBEA',
                border: '1px solid #C6E9D9',
                color: '#129E5B',
                fontSize: '11px',
                fontWeight: '600',
                padding: '3px 6px',
                textTransform: 'uppercase',
                borderRadius: '4px',
                marginLeft: '5px'
            }
        }
    }
}))

InputWithDropdownJobTemplate.defaultProps = {
    jobTemplateList: [],
    jobTemplateChangeHandler: () => { },
    popupWidth: null,
    selectedJobTemplateId:null, 
    label:null, 
    variant: null
}

export default InputWithDropdownJobTemplate