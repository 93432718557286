import React, { useState } from 'react'
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import BorderColorIcon from '@material-ui/icons/BorderColor';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
const FileUploadAccessLevelStrip = (props) => {
    const access_level_data = props.access_level_data ? props.access_level_data : null;
    console.log(access_level_data, "access_level_data")
    const handleEditData = props.handleEditData ? props.handleEditData : ()=>{}
    const handleDeleteRow = props.handleDeleteRow ?props.handleDeleteRow : ()=>{}
  return (
    <div className='single-row-access-level'>
    <div className="single-row-item">
      <div className="title-heading">
        Name
      </div>
      <div className='heading-value'>
      {
                  access_level_data &&  access_level_data.data ?
                  access_level_data.data.name ? access_level_data.data.name : "N/A" : "N/A"
                }
      </div>
    </div>
    <div className="single-row-item">
      <div className="title-heading">
        Uploaded File
      </div>
      <div className='heading-value'>
      {
                  access_level_data && access_level_data.data ? access_level_data.data.uploaded_file?
                  access_level_data.data.uploaded_file : 
                    access_level_data.fileUpload ?
                      access_level_data.fileUpload.files && access_level_data.fileUpload.files.length > 0 ?
                  access_level_data.fileUpload.files[0].name ? access_level_data.fileUpload.files[0].name : "N/A" : "N/A" : "N/A" :"N/A"
                }
      </div>
    </div>
    <div className="single-row-item">
      <div className="title-heading">
        Protocol
      </div>
      <div className='heading-value'>
      {
                  access_level_data &&  access_level_data.data ?
                  access_level_data.data.protocol ? access_level_data.data.protocol : "N/A" : "N/A"
                }
      </div>
    </div>
    <div className="single-row-item">
      <div className="title-heading">
        Port
      </div>
      <div className='heading-value'>
        {access_level_data &&  access_level_data.data ?
                  access_level_data.data.port ?access_level_data.data.port : "N/A" : "N/A"}
      </div>
    </div>
    <div className="single-row-item">
      
    </div>
   
    <div className="single-row-item">
      
    </div>
    <div className="single-row-item">
    <SimpleDialogAccessLevel 
    handleEditData = {handleEditData } 
    handleDeleteRow = {handleDeleteRow} />
    </div>
  </div>
  )
}
FileUploadAccessLevelStrip.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}
export default FileUploadAccessLevelStrip;


function SimpleDialogAccessLevel(props) {

    const ITEM_HEIGHT = 48;
    const [openDialogue, setOpenDialogue] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const handleEditData = props.handleEditData ? props.handleEditData : ()=>{}
    const handleDeleteRow = props.handleDeleteRow ? props.handleDeleteRow : ()=>{}
    const handleClickOpenDialogue = () => {
        setOpenDialogue(true);
    };
    const handleCloseDialogue = () => {
        setOpenDialogue(false);
    };
    const options = [
        {
            icon: <BorderColorIcon fontSize="default" style={{color:"#33abe2"}} />,
            label: 'Edit Access Level',
            link: ""
        },
    ]
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    function deleteSucess(){
        setRefresh(true)
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
        setAnchorEl(null);
    };
  
  
  
    return (
        <>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className=" d-flex align-center">
                   <MoreVertIcon fontSize="large" className="mr-5" />
                {/* <SettingsIcon fontSize="large" className="mr-5" /> Settings <KeyboardArrowDownIcon fontSize="large" className="ml-5" /> */}
            </IconButton>
          
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
               
               <MenuItem className="menu-item font-12 text-grey-83" onClick={handleEditData}>
                    <BorderColorIcon fontSize="default" className='text-anchor-blue' /> &nbsp;Edit
                </MenuItem>
                <MenuItem className="menu-item font-12 text-grey-83" onClick = {handleDeleteRow}>
                    <DeleteIcon fontSize="default" style={{color:'rgb(255, 137, 105)'}} /> &nbsp;Delete
                </MenuItem>
            </Menu>
        </>
    );
  }
  SimpleDialogAccessLevel.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }