import React from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import WatchLaterOutlinedIcon from '@material-ui/icons/WatchLaterOutlined';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import StopIcon from '@material-ui/icons/Stop';
const Stepper = (props) => {
    const steps=props.steps;

    console.log("steps",steps);
    
    function getIcon(status){
        switch(status){
            case "success":
                return(
                    <div className="md-step-circle success">
                        <CheckIcon style={{fontSize:'18px!important', color:'#62E187'}} />
                    </div>
                );
            case "skipped":
                return(
                    <div className="md-step-circle skipped">
                         <CallSplitIcon style={{fontSize:'18px!important', color:'#52bfe7'}} />
                    </div>
                );
            case "failed":
                return(
                    <div className="md-step-circle fail">
                        <WarningIcon style={{fontSize:'18px!important', color:'#ff8969'}} />
                    </div>
                );
            case "progress":
                return(
                    <div className="md-step-circle yellow">
                        <WatchLaterOutlinedIcon style={{fontSize:'18px!important', color:'#ffc436'}} />
                    </div>
                );
            case "pending":
                return(
                    <div className="md-step-circle ">
                        <ThumbUpIcon style={{fontSize:'18px!important', color:'#c294f1'}} />
                    </div>
                );
            case "waiting":
                return(
                    <div className="md-step-circle ">
                        <PauseCircleFilledIcon style={{fontSize:'18px!important', color:'#0086ff'}} />
                    </div>
                );
            case "disapproved":
                return(
                    <div className="md-step-circle fail">
                        <ThumbDownIcon style={{fontSize:'18px!important', color:'rgb(241, 136, 104)'}} />
                    </div>
                );
            case "suspended":
                return(
                    <div className="md-step-circle " style={{border:'3px solid rgb(142,142,142)'}}>
                        <AssignmentLateIcon style={{fontSize:'18px!important', color:'rgb(142,142,142)'}} />
                    </div>
                );
            case "stopped":
                return(
                    <div className="md-step-circle " style={{border:'3px solid #e9797e'}}>
                        <StopIcon style={{fontSize:'18px!important', color:'#e9797e'}} />
                    </div>
                );
            default:
                return(
                    <div className="md-step-circle ">
                        <span style={{fontSize:'13px', color:'#000', textAlign:'center', position:'relative', bottom:'6px'}}> </span>
                    </div>
                );
        }
    }

    function getLeftBar(status){
        switch(status){
            case "success":
                return(
                    <div className="md-step-bar-left success"></div>
                );
            case "skipped":
                return(
                    <div className="md-step-bar-left skipped"></div>
                );
            case "failed":
                return(
                    <div className="md-step-bar-left fail"></div>
                );
            case "progress":
                return(
                    <div className="md-step-bar-left yellow"></div>
                );
            case "pending":
                return(
                    <div className="md-step-bar-left purple"></div>
                );
            case "waiting":
                return(
                    <div className="md-step-bar-left blue"></div>
                );
            case "disapproved":
                return(
                    <div className="md-step-bar-left fail"></div>
                );
            case "suspended":
                return(
                    <div className="md-step-bar-left" style={{borderTopColor:'rgb(142, 142, 142)'}}></div>
                );
            default:
                return(
                    <div className="md-step-bar-left "></div>
                );
        }
    }

    function getRightBar(status){
        switch(status){
            case "success":
                return(
                    <div className="md-step-bar-right success"></div>
                );
            case "skipped":
                return(
                    <div className="md-step-bar-right skipped"></div>
                );
            case "failed":
                return(
                    <div className="md-step-bar-right fail"></div>
                );
            case "progress":
                return(
                    <div className="md-step-bar-right yellow"></div>
                );
            case "pending":
                return(
                    <div className="md-step-bar-right purple"></div>
                );
            case "waiting":
                return(
                    <div className="md-step-bar-right blue"></div>
                );
            case "disapproved":
                return(
                    <div className="md-step-bar-right fail"></div>
                );
            case "suspended":
                return(
                    <div className="md-step-bar-right" style={{borderTopColor:'rgb(142, 142, 142)'}}></div>
                );
            default:
                return(
                    <div className="md-step-bar-right "></div>
                );
        }
    }
    
    return(
        <div className="md-stepper-horizontal " >
            {
                steps.map(step =>(
                    <div className="md-step active " >
                        {getIcon(step.status)}
                        <div className="md-step-title">
                            {step.main_text}<br />
                            <span>{step.sub_text}</span>
                        </div>
                        {getLeftBar(step.status)}
                        {getRightBar(step.status)}
                    </div>
                ))
            } 
        </div>
    )
}

Stepper.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default  Stepper ;
