import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SquareAvatar from '../../../../components/genericComponents/AvatarSquare';

import { trigger_type_code_maping } from '..';
import SaveIcon from '@material-ui/icons/Save';

import BorderColorIcon from '@material-ui/icons/BorderColor';
import { IconButton } from '@material-ui/core';
const Heading = (props) => {
    const pipeline = props.pipeline;
    const application = props.application;
    const saveStages = props.saveStages;
    const trigger = GetTriggerCodeFromId(pipeline.trigger_type_id);
    const [state, setState] = useState();
    const handleBasicInfoFormOpen = props.handleBasicInfoFormOpen;
    const show_run_pipeline_button = props.show_run_pipeline_button;
    const active_service_list = [];

    application.components.forEach(service => {
        if(pipeline.components.includes(service.id)){
            active_service_list.push({name:service.name, part_of_execution:service.part_of_execution ? true:false});
        }
    });
  
    return(
        
        <div className="general-head-data">
            <SquareAvatar name={pipeline.name} />
            <div className="pipeline-data">
                <div className='d-flex align-center'>
                <p className="pipeline-name">{pipeline.name}</p>
                <IconButton onClick={handleBasicInfoFormOpen}>
                <BorderColorIcon fontSize="" className="vertical-bottom text-anchor-blue" />
                </IconButton>
                
                </div>
               
                <div className="data-frequency-retention">
                    <div className="text-box-pipeline-head">
                        <span className="main-text-dark">Trigger:</span>
                        <span className="main-text-lite">&nbsp;{trigger}</span>
                    </div>
                    {
                        pipeline.trigger != "MANUAL" ?
                        <div className="text-box-pipeline-head">
                            <span className="main-text-dark">Frequency:</span>
                            <span className="main-text-lite">&nbsp;{pipeline.cron_regex}</span>
                        </div>:null
                    }
                    <div className="text-box-pipeline-head">
                        <span className="main-text-dark">Retention:</span>
                        <span className="main-text-lite">&nbsp;{pipeline.retention_execution_count}</span>
                    </div>
                </div>
            </div>
            {console.log(active_service_list,"fsdfdsafk")}
            {/* <div className="pipeline-service-data">
               <p className="main-text-dark mb-5">Services for the pipeline to run on</p> 
               <div className="service-name-chip-div">
                    
                    <ExpandableServiceList list={active_service_list}/>
                    
               </div>
            </div>
            {
                pipeline.tags && pipeline.tags.length > 0 ?
                <div className="pipeline-tags-data">
                    <p className="main-text-dark mb-5">Tags</p> 
                    <div className="service-name-chip-div">
                            <ExpandableTagList list={pipeline.tags}/>
                    </div>
                </div>:null
            } */}
            <div className="ml-auto">
                <button className="btn-save ml-3 btn-save-primary btn-save-lg sv-workflow" onClick={saveStages}><SaveIcon fontSize="large" className="vertical-bottom" /> Save Workflow</button>
            </div>            
        </div>
        
    )
}


Heading.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };

export default Heading;

export function GetTriggerCodeFromId(id){
    const keys = Object.keys(trigger_type_code_maping);
    var name="N/A";
    keys.forEach(key=>{
        if(trigger_type_code_maping[key].id == id){
            name = trigger_type_code_maping[key].name;
        }
    });
    return name;
}