import React from 'react';

const SelectWithDropdown = ({ name, remove_default_value,select_default_value, data, list, error, onChangeHandler }) => {
  return (
    <div className="select-with-dropdown">
      <div className="select-container">
        <select
          name={name}
          value={data[name]}
          className={!error[name] || error[name].length === 0 ? 'select' : 'select error'}
          onChange={onChangeHandler}
        >
             {/* {remove_default_value ? null : <option value="">{select_default_value ? select_default_value : "Please Select"}</option>} */}
          {list.map((option) => (
            <option key={option.id} value={option.id}>
              {option.label}
            </option>
          ))}
        </select>
        <div className="thumbnail-container">
          {data[name] && (
            <img
              src={list.find((option) => option.id === data[name])?.imgUrl || ''}
              alt={`${data[name]} thumbnail`}
              className="thumbnail"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectWithDropdown;
