import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const CanaryListingHeader = props=>{
    const classes = useStyles();
    const application_id = props.application_id
    return(
        <div className="d-flex align-center space-between">
            <div className="main-div">
                <p className="heading-primary">Canary Templates</p>
                <p className="sub-heading">
                    To understand more about Canary Templates please read the <a href="https://www.opstree.com/buildpiper/documentation/" target="_blank" className="text-anchor-blue">Documentation</a>
                </p>
            </div>
            <Link to={"/application/" + application_id + "/canary-template/add"} className="btn btn-primary">New Canary Templates</Link>
        </div>
    )
}

CanaryListingHeader.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };

const useStyles = makeStyles({
  
});

export default CanaryListingHeader;