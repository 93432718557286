import React, { useState, useEffect, useMemo, version } from 'react';
import { Input } from '../../../../components/genericComponents/Input';
import { VALIDATION_TYPE_REQUIRED, ValidateDataSet } from '../../../../util/Validator';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { EDIT_JOB } from './GlobalStageForm';
import { RemoveFromArray, GetCommonEnvsOfServices, getListableArray, getRunConditionsAndJiraRefList, showErrorHandlerUpdated } from '../../../../util/util';
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import properties from '../../../../properties/properties';
import { getSelectedEnv } from '../../../serviceRevamp/add/cd_flow/CdInfo';
import ConditionsDialog from './ConditionsDialog';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import CreateTicket from './CreateTicket';
import ChangeStatus from './ChangeStatus';
import AddComment from './AddComments'; ''
import RollBack from './RollBack';
import { Loading } from '../../../utils/Loading';
import AlertStrip from '../../../../components/AlertStrips';
import AdditionalFiledDialog from './AdditionalFiledDialog';
import { getJiraRefid } from './QuestionnaireDialog';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Tooltip } from '@material-ui/core';
import AddVersionThreeJobs from './AddVersionThreeJobs';
import { styled } from '@material-ui/core/styles';
import VersionLogo from '../../../../components/genericComponents/VersionLogo';
import AddIcon from '@material-ui/icons/Add';
import AdditionalFieldsDialogAdvance from './AdditionalFieldsDialogAdvance';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Zoom from '@material-ui/core/Zoom';
import InputWithDropdownJobTemplate from '../../../../components/genericComponents/InputWithDropdown';
import InputWithDropdownVersions from '../../../../components/genericComponents/InputWithDropdownVersions';
import CheckJiraIds from '../../../pipeline/add/components/CheckJiraIds';
import CheckConflicts from '../../../pipeline/add/components/CheckConflicts';
import CreatePR from '../../../pipeline/add/components/CreatePR';
import CreateSnowTicket from '../../../pipeline/add/components/CreateSnowTicket';
import SnowAddNotes from '../../../pipeline/add/components/SnowAddNotes';
import DownloadDocuments from '../../../pipeline/add/components/DownloadDocuments';
import UploadDocuments from '../../../pipeline/add/components/UploadDocuments';
import UploadRelease from '../../../pipeline/add/components/UploadRelease';
import UpdateSnowTicketStatus from '../../../pipeline/add/components/UpdateSnowTicketStatus';
import UpdateSnowTicket from '../../../pipeline/add/components/UpdateSnowTicket';



const defaultIndependentJobData = {

    "JIRA_INTEGRATION": {
        "1": [
            {
                key: "PROJECT_NAME",
                value: "T1",
                is_value_from_output_var: false,
                is_overridden: true
            },
            {
                key: "SERVER_URL",
                value: "T2",
                is_value_from_output_var: false,
                is_overridden: true
            },
            {
                key: "EMAIL",
                value: "T3",
                is_value_from_output_var: false,
                is_overridden: true
            },
            {
                key: "AUTH_TOKEN",
                value: "T4",
                is_value_from_output_var: false,
                is_overridden: true
            },
            {
                key: "JIRA_ISSUE_TYPE",
                value: "T4",
                is_value_from_output_var: false,
                is_overridden: true
            },
            {
                key: "JIRA_FIELDS",
                value: "T5",
                is_value_from_output_var: false,
                is_overridden: true
            }
        ]
    }
}

const task_type_list = [
    {
        id: "1",
        label: "Build",
        job_code: "build_job"
    },
    {
        id: "2",
        label: "Deploy",
        job_code: "deploy_job"
    },
    {
        id: "3",
        label: "Promote",
        job_code: "promote_job"
    },
    {
        id: "5",
        label: "API Call"
    },
    {
        id: "6",
        label: "Config Maps"
    },
    {
        id: "7",
        label: "Deploy Secrets"
    },
    {
        id: "10",
        label: "Jira Ticket",
        job_code: "jira_integration_job"
    },
    {
        id: "11",
        label: "Rollback"
    },
    {
        id: "12",
        label: "Integration Testing"
    },
    {
        id: "13",
        label: "Trigger Pipeline"
    },
    {
        id: "16",
        label: "ServiceNow Ticket",
        job_code: "snow_integration_job"
    },
    {
        id: "17",
        label: "Attach Documents",
        job_code: 'attach_documents',
    }
]

const deploy_option = [
    {
        id: "LATEST",
        label: "Latest",
    },
    {
        id: "GENERATED",
        label: "Generated",
    },
    {
        id: "TAG",
        label: "Specify Tag",
    },
];

const api_call_options = [
    {
        id: "GET",
        label: "GET",
    },
    {
        id: "POST",
        label: "POST",
    }
];

export const GlobalPipelineJobForm = (props) => {
    const stage_index = (props.stage_index || props.stage_index == 0) ? props.stage_index : 0;
    const job_index = (props.job_index || props.job_index == 0) ? props.job_index : 0;
    let get_job_index = props.get_job_index;
    const application = props.application;
    const pipeline = props.pipeline;
    const pipelineVersion = pipeline.version;
    const stages = props.stages;
    const addOrUpdateStage = props.addOrUpdateStageCallback;
    const showWorkflow = props.showWorkflow;
    const varient = props.varient;
    const present_stage_name = props.present_stage_name
    console.log(present_stage_name, stages, "jobform_stages")
    const inherits = {}

    const default_data = {
        task_type: "",
        skip_component: [],
        components: [],
        all_templates: [],
        env: "",
        loading_data: false,
        service_loading: false,
        template_loading: false,
        on_failure_exit: true,
        on_success_exit: false,
        //set_branch: false,
        jira_key: "PRIMARY",
        artifact_source: "",
        deployment_type: "ROLLING_UPDATE",
        additional_field_key_value: []
    };


    const data = (props.job_index || props.job_index == 0) ? stages[stage_index].task[job_index] : default_data;

    console.log(data, 'data_022323232323')
    console.log(pipelineVersion, 'data_001_23323');

    function getDataForEditVersion3Form(version) {
        if (version === 'v3') {

            return { ...data, task_type: data.job_code ? data.job_code : data.task_type, job_template_version: 'v3' }
        }
        else
            return {}
    }

    const [state, setState] = useState({
        data: data,
        current_stage_jobs: [],
        error: {

        },
        jira_data_types: [],
        version_three_form_error: {

        },
        child_inherits: {
            create: {
                "validateForm": () => { return { valid: true }; },
                "getState": () => { return {}; },
                "getData": () => { return {}; }
            },
            status_update: {
                "validateForm": () => { return { valid: true }; },
                "getState": () => { return {}; },
                "getData": () => { return {}; }
            },
            add_comment: {
                "validateForm": () => { return { valid: true }; },
                "getState": () => { return {}; },
                "getData": () => { return {}; }
            },
            check_jira_ids: {
                "validateForm": () => { return { valid: true }; },
                "getState": () => { return {}; },
                "getData": () => { return {}; }
            },
            check_conflicts: {
                "validateForm": () => { return { valid: true }; },
                "getState": () => { return {}; },
                "getData": () => { return {}; }
            },
            create_pr: {
                "validateForm": () => { return { valid: true }; },
                "getState": () => { return {}; },
                "getData": () => { return {}; }
            },
            version_three_form: {
                "validateForm": () => { return { valid: true } },
                "getState": () => { return {} },
                "getData": () => { return {} }
            },
            snow_create: {
                "validateForm": () => { return { valid: true } },
                "getState": () => { return {} },
                "getData": () => { return {} }
            },
            snow_update_status: {
                "validateForm": () => { return { valid: true } },
                "getState": () => { return {} },
                "getData": () => { return {} }
            },
            snow_add_notes: {
                "validateForm": () => { return { valid: true } },
                "getState": () => { return {} },
                "getData": () => { return {} }
            },
            snow_update: {
                "validateForm": () => { return { valid: true } },
                "getState": () => { return {} },
                "getData": () => { return {} }
            },
            download_documents: {
                "validateForm": () => { return { valid: true } },
                "getState": () => { return {} },
                "getData": () => { return {} }
            },
            upload_documents: {
                "validateForm": () => { return { valid: true } },
                "getState": () => { return {} },
                "getData": () => { return {} }
            },
            upload_release: {
                "validateForm": () => { return { valid: true } },
                "getState": () => { return {} },
                "getData": () => { return {} }
            }
        },

        validations: {
            skip_component: [],
            components: [VALIDATION_TYPE_REQUIRED],
            pipelines: [VALIDATION_TYPE_REQUIRED],
            task_type: [VALIDATION_TYPE_REQUIRED],
            env: [VALIDATION_TYPE_REQUIRED],
            tag: [VALIDATION_TYPE_REQUIRED],
            operation: [VALIDATION_TYPE_REQUIRED],
            latest_enable: [],
            artifact_source: [],
            method: [],
            url: [],
            request_timeout: [],
            target_env: [],
            summary: [VALIDATION_TYPE_REQUIRED],
            issue_key: [VALIDATION_TYPE_REQUIRED],
            // assignee_name : [VALIDATION_TYPE_REQUIRED]
            jira_status: [VALIDATION_TYPE_REQUIRED],
        },
        app_environment_list: [],// props.all_project_envs,
        all_services: [],
        all_templates: [],
        all_pipelines: [],
        all_projects: [],
        isRunTimeParamsFormActive: false,
        runTimeParamsFormState: null,
        editResetCount: 0,
        job_json: null,
        independentJobData: {
            data: {},
            error: {

            }
        },
        isJiraAditionalActive: null,
        selected_job_type: "",
        // jiraIssueKeys: [],
        // pipelineVars: []
        dynamicValidationForm: {
        },
        dynamicValidationFormError: {
            // issue_key:true
        }
    });

    const [flipState, setFlipState] = useState(false)
    console.log(stages, 'services_0232323');

    const [pipelineVars, setPipelineVars] = useState()
    const [jiraIssueKeys, setJiraIssueKeys] = useState()
    const [jobStepOrderOutputVars, setJobStepOrderOutputVars] = useState()


    useEffect(() => {
        fetchAllApplications()
        if (data.project_id) {
            getEnvsOfSelectedApp(data.project_id)
        }
    }, [])




    useEffect(() => {
        console.log(pipeline, 'data_001_23323');
        const jiraIssueKeys = getJobRunConditions()?.jira_id_reference_keys
        let jiraIssueKeyList = []
        let pipelineVars = []
        let outputVariables = []
        if (jiraIssueKeys) {
            jiraIssueKeyList = jiraIssueKeys
        }
        if (pipeline && pipeline?.pipeline_vars) {
            const updatedPipelineVars = pipeline?.pipeline_vars?.map((variable) => {
                return { id: variable.key, label: variable.key }
            })
            pipelineVars = updatedPipelineVars
        }

        setPipelineVars(pipelineVars)
        setJiraIssueKeys(jiraIssueKeyList)
        // setState(prevState => ({
        //     ...prevState,
        //     pipelineVars: pipelineVars,
        //     jiraIssueKeys: jiraIssueKeyList
        // }))
        console.log(jiraIssueKeyList, pipelineVars, 'jstbnv')

    }, [pipeline])

    useEffect(() => {
        if (state.default_job_template && state.data.task_type != "") {
            getOutputJobVariables()
        }
    }, [state.default_job_template, state.data.task_type])

    console.log(jobStepOrderOutputVars, 'jbcdfb')

    const getJobOutputVarsOfAJob = (stage, job, jobIndex) => {
        // write for components job
        const jobCode = task_type_list?.find(jobData => job.task_type_id == jobData.id)?.job_code
        let outputVariableKeys = []
        let mainKey = `stage.${stage.name}.task.${jobIndex}`
        const steps = state.default_job_template?.job_template?.jobs?.find(job => job.job_code == jobCode)?.steps
        if (steps) {
            steps.forEach(step => {
                if (step.output_variables) {
                    const stepOrder = step.order
                    const stepCode = step.step_code
                    step.output_variables?.forEach(outputVar => {
                        let stepOutputVarKey = mainKey + `.${stepCode}.` + outputVar.name + ".step_order." + stepOrder;
                        let stepOutputVarLabel = `job_${jobIndex} of ${stage.name} and output variable ${outputVar.name} of step ${stepCode}`;
                        outputVariableKeys.push({ id: stepOutputVarKey, label: stepOutputVarLabel })
                    })
                }
            })
        }
        return outputVariableKeys
    }

    const getOutputJobVariables = () => {
        let allOutputVariables = []
        var current_job_index = job_index
        if (varient != "EDIT_JOB" && stages[stage_index].task) {
            current_job_index = stages[stage_index].task.length
        }
        console.log(stages, 'stages_fgvbnm')
        stages?.forEach((stage, stageindex) => {
            if (stageindex < stage_index) {
                stage?.task?.forEach((job, jobIndex) => {
                    let outputVars = getJobOutputVarsOfAJob(stage, job, jobIndex + 1)
                    allOutputVariables = [...allOutputVariables, ...outputVars]
                })
            }
            else if (stageindex == stageindex) {
                stage?.task?.forEach((job, jobIndex) => {
                    if ((jobIndex < current_job_index)) {
                        console.log(job, 'jb_023p')
                        let outputVars = getJobOutputVarsOfAJob(stage, job, jobIndex + 1)
                        console.log(outputVars, 'out_pwereww-')
                        allOutputVariables = [...allOutputVariables, ...outputVars]
                    }
                })
            }
        })
        console.log(allOutputVariables, 'all_data_0s0d')
        setJobStepOrderOutputVars(allOutputVariables)
    }

    console.log(state, 'services_0232323');
    const commonFunctions = getCommonFunctions(state, setState, inherits)

    const classes = useStylesDialog();

    var child_states_of_form = inherits.getState ? inherits.getState() : null;

    console.log('child_form_verw322323', child_states_of_form)

    function getCorrectVersion(data) {
        let version = 'v2'
        if (data?.project_job_template?.version) {
            version = data?.project_job_template?.version
        }
        if (data.job_template_version) {
            version = data.job_template_version
        }
        return version;
    }


    useEffect(() => {
        const data = (props.job_index || props.job_index == 0) ? stages[stage_index].task[job_index] : default_data;
        console.log(data, "sdbhcjbsdhcbsdhbc")

        setState({
            ...state,
            version_three_form_data: getDataForEditVersion3Form(pipelineVersion),
            data: {
                ...default_data,
                ...data,
                job_template_version: getCorrectVersion(data),
                is_job_template_switch_active: data?.project_job_template != undefined ? true : false
            },
            job_json: data.job_json ? data.job_json : {
                "is_dependent": false,
                "dependent_entity": "SERVICE",
                "entity_resource_data": {
                    "SERVICE": [],
                    "is_overridden": false
                }
            }
        });
        if (data.task_type == "10" && data.operation == "create_subtask") {
            fetchJobTemplatesList(true, data.assignee_name, data.project_job_template_id ? data.project_job_template_id : null)
        }
        else {
            fetchJobTemplatesList(false)
        }
        fetchPipelinesForSelectedProject()
        if (data.env && !data.target_env) {
            fetchSingleEnvData(data.env)
            fetchServicesForSelectedProject(data.env);
        }
        if (data.target_env && data.task_type_id == 3) {
            fetchSingleEnvData(data.target_env)
            fetchServicesForSelectedProject(data.target_env, "promote");
        }
        if (data.task_type && data.task_type == 6) {
            fetchConfigForSelectedEnv(data.env)
        }
        if (data.task_type && data.task_type == 7) {
            fetchSecretsForSelectedEnv(data.env)
        }
        fetchSystemSetting()
    }, [stage_index, job_index, varient]);

    useEffect(() => {
        const data = (props.job_index || props.job_index == 0) ? stages[stage_index].task[job_index] : default_data;
        if (data.task_type == 10 && data.operation == "create_release") {
            fetchJiraTicketStatus();
        }
    }, [state.default_job_template, state.data.project_job_template_id])

    useEffect(() => {
        evaluateSelectedStageJobsCount()
    }, [present_stage_name, stages])

    const evaluateSelectedStageJobsCount = () => {
        var present_stage_jobs = []
        stages.forEach((item) => {
            if (present_stage_name && item.name == present_stage_name) {
                present_stage_jobs = item.task ? item.task : []
            }
        })

        console.log(present_stage_jobs, "sbdbsjdbcjsdbc")

        setState((prevState) => ({
            ...prevState,
            current_stage_jobs: present_stage_jobs
        }))
    }

    console.log(state.current_stage_jobs, "dbhsdbbcfsjdbc")

    const setRunTimeParamFormState = (formState) => {
        console.log('frmr2323', formState)
        const data = { ...formState }
        if (formState)
            setState(prevState => ({
                ...prevState,
                runtimeFormState: data,
                editResetCount: prevState.editResetCount + 1,
                isRunTimeParamsFormActive: false
            }))
        else
            setState(prevState => ({
                ...prevState,
                runtimeFormState: null,
                editResetCount: prevState.editResetCount + 1,
                isRunTimeParamsFormActive: false
            }))
    }

    const onlyIncreaseResetCount = (count) => {
        setState(prevState => ({
            ...prevState,
            runtimeFormState: {
                ...prevState.runtimeFormState,
                isAdvanceRunTimeParamsForm: false,
                resetCount: count

            }
        }))
    }
    function fetchSystemSetting() {

        console.log('hshnsdnssdhsd')
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.system_settings),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, (response) => {

            console.log(response, 'resdsds')
            if (response) {
                const isJiraAditionalActive = response?.find(data => data.key == 'ENABLE_JIRA_CUSTOM_ADDITIONAL_FIELDS')?.value
                console.log(typeof (isJiraAditionalActive), 'is_pwpepew')
                if (isJiraAditionalActive == 'true') {
                    setState(prevState => ({
                        ...prevState,
                        isJiraAditionalActive: 1
                    }))
                }
                else {
                    setState(prevState => ({
                        ...prevState,
                        isJiraAditionalActive: 0
                    }))
                }
            }
        }, (error) => {

        });

    }

    function updateOperation(e) {
        state.child_inherits = {
            create: {
                "validateForm": () => { return { valid: true }; },
                "getState": () => { return {}; },
                "getData": () => { return {}; }
            },
            status_update: {
                "validateForm": () => { return { valid: true }; },
                "getState": () => { return {}; },
                "getData": () => { return {}; }
            },
            add_comment: {
                "validateForm": () => { return { valid: true }; },
                "getState": () => { return {}; },
                "getData": () => { return {}; }
            },
            check_jira_ids: {
                "validateForm": () => { return { valid: true }; },
                "getState": () => { return {}; },
                "getData": () => { return {}; }
            },
            check_conflicts: {
                "validateForm": () => { return { valid: true }; },
                "getState": () => { return {}; },
                "getData": () => { return {}; }
            },
            create_pr: {
                "validateForm": () => { return { valid: true }; },
                "getState": () => { return {}; },
                "getData": () => { return {}; }
            },
            snow_create: {
                "validateForm": () => { return { valid: true } },
                "getState": () => { return {} },
                "getData": () => { return {} }
            },
            snow_update_status: {
                "validateForm": () => { return { valid: true } },
                "getState": () => { return {} },
                "getData": () => { return {} }
            },
            snow_add_notes: {
                "validateForm": () => { return { valid: true } },
                "getState": () => { return {} },
                "getData": () => { return {} }
            },
            snow_update: {
                "validateForm": () => { return { valid: true } },
                "getState": () => { return {} },
                "getData": () => { return {} }
            },
            download_documents: {
                "validateForm": () => { return { valid: true } },
                "getState": () => { return {} },
                "getData": () => { return {} }
            },
            upload_documents: {
                "validateForm": () => { return { valid: true } },
                "getState": () => { return {} },
                "getData": () => { return {} }
            },
            upload_release: {
                "validateForm": () => { return { valid: true } },
                "getState": () => { return {} },
                "getData": () => { return {} }
            }
        }
        onChangeHandler(e)
    }


    function onChangeHandler(e, load_on_target_env) {
        var key = e.target.name;
        var value = e.target.value;
        var selectedEnv = null
        var selectedEnvId = null
        var envData = {}
        var selected_task;
        console.log(key, value, "skdjfncdhsb")
        if (key == "project_id") {
            getEnvsOfSelectedApp(value)
        }
        if (key == "task_type") {
            task_type_list.forEach((item) => {
                if (item.id == value) {
                    selected_task = item.label
                }
            })
            setState((new_state) => ({
                ...new_state,
                selected_job_type: selected_task.toLowerCase(),
                data: {
                    ...new_state.data,
                    env: "",
                },
                all_services: []
            }))
        }


        console.log(load_on_target_env, "load_on_target_env")
        if (key == "env" && value != "") {
            selectedEnv = getSelectedEnv(value, state.app_environment_list)
            envData = { ...envData, project_env: selectedEnv }
            if (load_on_target_env) {
                console.log(load_on_target_env, "load_on_target_env")
            } else {
                fetchSingleEnvData(value);
                fetchServicesForSelectedProject(value);
                fetchConfigForSelectedEnv(value);
                fetchSecretsForSelectedEnv(value);
            }


        }
        if (load_on_target_env) {
            console.log(load_on_target_env, "load_on_target_env")
        } else {
            if (key == "env" && value == "") {
                setState((new_state) => ({
                    ...new_state,
                    data: {
                        ...new_state.data,
                        env: "",
                    },
                    all_services: []
                }))
            }
        }


        if (key == "target_env" && value != "") {

            selectedEnv = getSelectedEnv(value, state.app_environment_list)
            envData = { ...envData, target_project_env: selectedEnv }
            fetchSingleEnvData(value);
            fetchServicesForSelectedProject(value, "promote");
            fetchConfigForSelectedEnv(value);
            fetchSecretsForSelectedEnv(value);
        }

        if (key == "target_env" && value == "") {
            setState((new_state) => ({
                ...new_state,
                data: {
                    ...new_state.data,
                    env: "",
                },
                all_services: []
            }))
        }

        if (key == "request_timeout") {
            value = Number(value) ? Number(value) : "";
        }

        if (key == "operation" && value == "create_release") {
            fetchJiraTicketStatus();
        }

        var switch_key = [
            "latest_enable",
            "pipeline_suspend",
            "response_update_context_param",
            "docker_cache",
            "on_failure_exit",
            "on_success_exit",
            "suspend_pipeline",
            "deployment_status_check",
            "skip_task",
            "mark_success_upon_skip",
            "is_linked_issue"
        ]

        if (switch_key.includes(key)) {
            value = !state.data[key];
        }

        setState((new_state) => ({
            ...new_state,
            data: {
                ...new_state.data,
                [key]: value,
                ...envData
            },
            error: {
                ...new_state.error,
                [key]: null,
            },
        }));
    }
    console.log(state, "dkhbcbsdhchjsd")
    function onChangeForTemplates(e) {
        var key = e.target.name;
        var value = e.target.value;
        var selected_template = state.all_templates.find(item => item.id == value)
        // delete state.data.project_sub_task
        // delete state.data.assignee_name
        // delete state.data.search_text
        setState({
            ...state,
            user_object: {},
            data: {
                ...state.data,
                [key]: value,
                project_sub_task: null,
                assignee_name: null,
                search_text: "",
                project_job_template: selected_template,
            },
            default_template_name: selected_template.name && selected_template.name,
            show_template_input: false,
            error: {
                ...state.error,
                [key]: null,
            }
        });
        // fetchSubtaskIssueType(value)
    }

    function onChangeHandlerForKeyValue(key, value) {
        if (key == "components" || key == "pipelines" || key == "env_namespace_configmap" || key == "env_namespace_secret") {
            value.forEach((id, index) => {
                value[index] = Number(id);
            });
        }
        setState({
            ...state,
            data: {
                ...state.data,
                [key]: value
            },
            error: {
                ...state.error,
                [key]: null,
            }
        });
    }
    function fetchJiraDatatypeList() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.jira_field_type),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, fetchJiraDatatypeListSuccess, fetchJiraDatatypeListFailure);
        setState(new_state => ({
            ...new_state,
            loading_data: true
        }));
    }

    function fetchJiraDatatypeListSuccess(response) {
        console.log(response, "fdsafdsafsafa")
        var jira_datatype_fileds = response.data_type.map(item => { return { id: item, label: item } });

        setState(new_state => ({
            ...new_state,
            jira_data_types: jira_datatype_fileds,
            loading_data: false
        }));

    }
    function fetchJiraDatatypeListFailure(error) {
        var error_msg = error.toString()
        setState(new_state => ({
            ...new_state,
            error: error_msg,
            loading_data: false
        }));
    }

    function onChangeHandlerForKeyValueJira(key, value) {
        console.log("sjdbsh", key, value);
        const jira_status_original = state.jira_status_list;
        console.log("rhuhnsd", jira_status_original);
        if (key == "components" || key == "pipelines" || key == "env_namespace_configmap" || key == "env_namespace_secret" || key == "jira_status") {
            value.forEach((id, index) => {
                value[index] = id;
            });
        }
        setState({
            ...state,
            data: {
                ...state.data,
                [key]: value
            },
            error: {
                ...state.error,
                [key]: null,
            }
        });

        console.log("jsbdhs", state);
    }



    function fetchJobTemplatesList(callApi, name, template_id) {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.get_global_job_template),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, (response) => fetchJobTemplatesListSuccess(response, callApi, name, template_id), fetchJobTemplatesListFailure);
        setState(new_state => ({
            ...new_state,
            loading_data: true
        }));
    }

    function fetchJobTemplatesListSuccess(response, callApi, name, template_id) {
        var find_default_job_template = response.results.filter(item => item.default == true);
        if (state.data.task_type == 10) {
            fetchJiraDatatypeList()
        }
        if (callApi) {
            console.log(callApi, "bcbcbsdhbcshbdhc")
            // fetchSubtaskIssueType(template_id ? template_id : find_default_job_template[0]?.id);
            fetchJiraUserList(template_id ? template_id : find_default_job_template[0]?.id, name)
        }
        setState(new_state => ({
            ...new_state,
            all_templates: response.results,
            default_job_template: find_default_job_template[0] ? find_default_job_template[0] : null,
            default_template_name: find_default_job_template[0] && find_default_job_template[0].name ? find_default_job_template[0].name : null,
            loading_data: false
        }));

    }
    function fetchJobTemplatesListFailure(error) {
        var error_msg = error.toString()
        setState(new_state => ({
            ...new_state,
            error: error_msg,
            loading_data: false
        }));
    }

    function fetchSubtaskIssueType(id) {
        console.log(id, "sdkbcbsdjbsd")
        var requestInfo = {
            endPoint: GenerateURL({ job_template_id: id ? id : state.data.project_job_template_id ? state.data.project_job_template_id : state.default_job_template.id }, properties.api.get_subtask_issue_types),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, subtaskIssueTypeSuccess, subtaskIssueTypeFailure);
        setState(new_state => ({
            ...new_state,
            loading_subtask: true
        }));
    }

    function subtaskIssueTypeSuccess(response) {
        console.log(response, "sjdbvsvcgsvdgvcsd")
        setState((new_state) => ({
            ...new_state,
            loading_subtask: false,
            sub_task_issue_list: response.sub_task_issue_types
        }))
    }

    function subtaskIssueTypeFailure(error) {
        setState((new_state) => ({
            ...new_state,
            loading_subtask: false,
        }))
    }
    const getEnvsOfSelectedApp = (id) => {
        var requestInfo = {
            endPoint: GenerateURL({ application_id: id }, properties.api.env_listing_all_true),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, getEnvsOfSelectedAppSuccess, getEnvsOfSelectedAppFailed);
        setState(new_state => ({
            ...new_state,
            loading_env_data: true
        }));
    }

    const getEnvsOfSelectedAppSuccess = (response) => {

        let data = response.results
        setState(new_state => ({
            ...new_state,
            app_environment_list: data,
            loading_env_data: false
        }));
    }

    const getEnvsOfSelectedAppFailed = (error) => {
        let error_msg = showErrorHandlerUpdated(error)
        setState(new_state => ({
            ...new_state,
            loading_env_data: false,
            error_msg: error_msg
        }));
    }

    function fetchAllApplications() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.side_panel_data),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, allAppsFetchSuccess, allAppsFetchFailed);
        setState(new_state => ({
            ...new_state,
            loading_data: true
        }));
    }

    function allAppsFetchSuccess(response, type) {
        let data = response.map(item => {
            return { label: item.name, id: item.id }
        })
        setState(new_state => ({
            ...new_state,
            all_projects: data,
            loading_data: false
        }));


    }
    function allAppsFetchFailed(error, type) {
        var error_msg = error.toString()
        setState(new_state => ({
            ...new_state,
        }));

    }


    function fetchSingleEnvData(id) {
        var requestInfo = {
            endPoint: GenerateURL({ env_id: id }, properties.api.get_env),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, SingleEnvDataSuccess, SingleEnvDataSuccessFailed);
        setState(new_state => ({
            ...new_state,
            loading_data: true
        }));
    }

    function SingleEnvDataSuccess(response, type) {
        setState(new_state => ({
            ...new_state,
            current_env: response,
            cluster_id: response.cluster_id,
            namespace_name: response.project_env_namespace.namespace.name
        }));


    }
    function SingleEnvDataSuccessFailed(error, type) {
        var error_msg = error.toString()
        setState(new_state => ({
            ...new_state,
        }));

    }
    function fetchPipelinesForSelectedProject() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.all_global_pipelines_last_execution + "?all=true"),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, fetchPipelinesSuccess, fetchpipelinesFailure);
    }

    function fetchPipelinesSuccess(response) {
        var all_pipelines_arr = []

        response.results.map(item => {
            if (pipeline && pipeline.name) {
                if (item.name != pipeline.name) {
                    all_pipelines_arr.push({ label: item.name, id: item.id })
                }
            }
        })
        setState(new_state => ({
            ...new_state,
            all_pipelines: all_pipelines_arr && all_pipelines_arr.length > 0 ? all_pipelines_arr : [],
        }));

    }
    function fetchpipelinesFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            error: true
        }));
    }

    function fetchServicesForSelectedProject(project_env_id, type) {
        var requestInfo = {
            endPoint: GenerateURL({ project_env_id: project_env_id, job_type: state.selected_job_type }, properties.api.get_services_for_projectEnvV2),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        if (type == "promote") {
            requestInfo.endPoint = GenerateURL({ project_env_id: project_env_id, job_type: task_type_list.find((obj) => obj.id == state.data?.task_type)?.label.toLowerCase(), source_env: state.data.env }, properties.api.get_services_for_projectEnvV2_promote)
        }
        InvokeApi(requestInfo, fetchServicesSuccess, fetchServicesFailure);
        setState(new_state => ({
            ...new_state,
            service_loading: true,
        }));
    }

    function fetchServicesSuccess(response) {
        fetchJobTemplatesList()
        console.log(response.results, "results__")
        if (response.results && response.results.length > 0) {
            setState(new_state => ({
                ...new_state,
                all_services: response.results,
                no_service_configured: false,
                service_loading: false
            }));
        } else {
            console.log(response.results, "results__")
            setState(new_state => ({
                ...new_state,
                all_services: [],
                no_service_configured: true,
                service_loading: false
            }));
        }

    }
    function fetchServicesFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            error: true
        }));
    }

    function fetchConfigForSelectedEnv(selectedEnvId) {

        var requestInfo = {
            endPoint: GenerateURL({ env_id: selectedEnvId }, properties.api.save_config),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, fetchSuccessConfigListing, fetchFailConfigListing);

    }

    function fetchSuccessConfigListing(response) {
        if (response.results && response.results.length > 0) {
            setState(new_state => ({
                ...new_state,
                all_configmaps: response.results,
                no_configmaps_configured: false
            }));
        } else {
            setState(new_state => ({
                ...new_state,
                all_configmaps: [],
                no_configmaps_configured: true
            }));
        }
    }

    function fetchFailConfigListing() {

    }

    function fetchSecretsForSelectedEnv(selectedEnvId) {
        var requestInfo = {
            endPoint: GenerateURL({ env_id: selectedEnvId }, properties.api.save_secret),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, fetchSuccessSecretListing, fetchFailSecretListing);
    }

    function fetchSuccessSecretListing(response) {
        if (response.results && response.results.length > 0) {
            setState(new_state => ({
                ...new_state,
                all_secrets: response.results,
                no_secrets_configured: false
            }));
        } else {
            setState(new_state => ({
                ...new_state,
                all_secrets: [],
                no_secrets_configured: true
            }));
        }
    }

    function fetchFailSecretListing() {

    }

    const getJobRunConditions = () => {
        var current_job_index = job_index
        if (varient != "EDIT_JOB" && stages[stage_index].task) {
            current_job_index = stages[stage_index].task.length
        }
        console.log(current_job_index, "skbcshbdchjbsdcdbb")
        return getRunConditionsAndJiraRefList(current_job_index, stages, stage_index, varient)
    }

    function getConditionsList(current_job_index, stages, stage_index) {
        var conditions = []
        var jira_id_reference_keys = []
        if (stages) {
            for (let loop_stage_index = 0; loop_stage_index <= stage_index; loop_stage_index++) {
                var stage = stages[loop_stage_index]
                if (loop_stage_index == stage_index && varient == EDIT_JOB) {
                    for (let loop_job_index = 0; loop_job_index < job_index; loop_job_index++) {
                        addConditionsAndJiraReferenceKey(loop_job_index, stage, conditions, jira_id_reference_keys)
                    }
                } else {
                    if (stage.task) {
                        for (let job_index = 0; job_index < stage.task.length; job_index++) {
                            addConditionsAndJiraReferenceKey(job_index, stage, conditions, jira_id_reference_keys)
                        }
                    }
                }
            }
        }
        return { conditions: conditions, jira_id_reference_keys: jira_id_reference_keys };
    }

    function getJobName(job_index) {
        return "job_" + (job_index + 1)
    }


    function addConditionsAndJiraReferenceKey(job_index, stage, conditions, jira_id_reference_keys) {
        var job_name = stage.task[job_index].name ? stage.task[job_index].name : getJobName(job_index)
        var condition_display = "Was Job " + job_name + " of Stage " + stage.name + " Successful"
        var condition = "stage." + stage.name + ".job." + job_name + ".success"
        conditions.push({ label: condition_display, id: condition })
        if (stage.task && stage.task[job_index].task_type == 10 && stage.task[job_index].operation == "create") {//create is task type for create JIRA
            var jira_reference_key = "stage." + stage.name + ".job." + job_name + ".jira_id.key"
            jira_id_reference_keys.push({ label: jira_reference_key, id: jira_reference_key })
        }
    }

    function getJiraReferenceIdForCreateTicket() {
        var stage = stages[stage_index]
        var current_job_index = job_index
        if (varient != EDIT_JOB) {
            current_job_index = stage.task ? stage.task.length : 0
        }

        var jira_reference_key = "stage." + stage.name + ".job." + getJobName(current_job_index) + ".jira_id.key"
        return jira_reference_key
    }

    function getSnowReferenceForCreateTicket() {
        var stage = stages[stage_index];
        var current_job_index = job_index;
        const jobCode = "snow_integration_job";
        if (varient != EDIT_JOB) {
            current_job_index = stage.task ? stage.task.length : 1
        }
        console.log("hrunjdwn", current_job_index)
        current_job_index = current_job_index == 0 ? 1 : current_job_index;
        let mainKey = `stage.${stage.name}.task.${current_job_index}`
        const steps = state.default_job_template?.job_template?.jobs?.find(job => job.job_code == jobCode)?.steps
        console.log("kdjnsj", steps);
        let outputVariableKeys = []
        if (steps) {
            steps.forEach(step => {
                if (step.output_variables) {
                    const stepOrder = step.order
                    const stepCode = step.step_code
                    step.output_variables?.forEach(outputVar => {
                        let stepOutputVarKey = mainKey + `.${stepCode}.` + outputVar.name + ".step_order." + stepOrder
                        outputVariableKeys.push({ id: stepOutputVarKey, label: stepOutputVarKey })
                    })
                }
            })
        }
        //`stage.${stage.name}.task.${jobIndex}`
        console.log("khdsjnjnrkjkjkk", outputVariableKeys);
        return outputVariableKeys[0];
    }

    var jira_operations_list = [
        { label: "Create Ticket", id: "create" },
        { label: "Change Status", id: "status_update" },
        { label: "Add Comment", id: "add_comment" },
        { label: "Create Subtask", id: "create_subtask" },
        { label: "Fetch Services from JIRA", id: "set_service" },
        { label: "Fetch JIRA IDs for a release", id: "create_release" },
        { label: "Check Jira IDs for Approval", id: "check_jira_ids" },
        { label: "Check Merge Conflicts", id: "check_conflicts" },
        { label: "Create PR against JIRA release ticket", id: "create_pr" }
    ]

    var snow_operations_list = [
        { label: "Create Ticket", id: "snow_create" },
        { label: "Update Ticket State", id: "snow_update_status" },
        { label: "Add Notes", id: "snow_add_notes" },
        { label: "Update Snow Ticket",id: "snow_update"}
    ]
    var attach_operation_list = [
        {label: "Download Documents",id:"download_documents"},
        {label: "Upload Documents",id:"upload_documents"},
        {label: "Upload Release Notes",id:"upload_release"}
    ]

    const deployment_type_list = [
        {
            value: "ROLLING_UPDATE",
            label: "Rolling"
        },
        {
            value: "CANARY",
            label: "Canary"
        }
    ]
    const hideBottomSaveContainer = (value) => {
        setState(prevState => ({
            ...prevState,
            isRunTimeParamsFormActive: value
        }))
    }

    const jira_operation_info = {
        create: "This operation will create a new jira ticket.",
        status_update: "This operation will change the status of already created jira ticket.",
        add_comment: "This operation will add a comment to an already created jira ticket.",
        set_service: "This operation will fetch commits from jira.",
        create_release: "This operation will fetch jira ids for a release",
        check_jira_ids: "This operation will validate jira ids",
        check_conflicts: "This step check of merge conflicts regarding target branch by valid jira tickets list",
        create_pr: "This step will raise pull reques against release jira",
    }

    // function getJiraOperationInfo(operation){
    //     switch(operation){
    //         case("create") :
    //             return jira_operation_info[operation];
    //         case("status_update") :
    //             return jira_operation_info[operation];   
    //         case("add_comment") :
    //             return jira_operation_info[operation];
    //         case("set_service") :
    //             return jira_operation_info[operation]; 
    //     }
    // }

    function getAllowedPromoteEnvs() {
        var currentEnvironment = null
        var promote_possible_env_list = JSON.parse(localStorage.getItem("allow_promote_data"));
        promote_possible_env_list = Object.keys(promote_possible_env_list).length > 0 ? promote_possible_env_list : null;
        if (promote_possible_env_list) {
            state.app_environment_list.forEach((env, key) => {
                if (env.id == state.data.env) {
                    currentEnvironment = env
                }
            })
            var possible_promote_list = promote_possible_env_list[currentEnvironment?.environment_master?.name]
            var list = [];
            state.app_environment_list.forEach((env, key) => {
                if (possible_promote_list && possible_promote_list.includes(env.environment_master.name)) {
                    list.push({ id: env.id, label: env.name })
                }
            })
        }

        return list;
    }

    const onKeyValueChangeHandler = (k, v) => {
        var services_list = getListableArray(state.all_services);

        setState({
            ...state, data: { ...state.data, [k]: v },
            error: { ...state.error, [k]: null }
        });
    }

    function getListableConfigmaps(arrayList) {
        var listableElements = [];
        if (arrayList && arrayList.length != 0) {
            arrayList.forEach((element) => {
                listableElements.push({
                    id: element.id,
                    label: element.name
                });
            });
        }
        return listableElements;
    }

    function getListableSecrets(arrayList) {
        var listableElements = [];
        if (arrayList && arrayList.length != 0) {
            arrayList.forEach((element) => {
                listableElements.push({
                    id: element.id,
                    label: element.name
                });
            });
        }
        return listableElements;
    }
    const [issueType, setIssueType] = useState('');
    const getIssueType = (data) => {
        console.log(data, "fdsfasdfusandfkna");
        if (data != null && data != undefined && data != "") {
            setIssueType(data)
        }
    }

    const fetchJiraTicketStatus = () => {
        var requestInfo = {
            endPoint: GenerateURL({ job_template_id: state?.data?.project_job_template_id ? state?.data?.project_job_template_id : state?.default_job_template?.id }, properties.api.get_jira_ticket_status) + "?job_template_id=" + state?.default_job_template?.id,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, fetchJiraTicketStatusSuccess, fetchJiraTicketStatusFailure);
        setState(new_state => ({
            ...new_state,
            loading_data: true
        }));
    }

    const fetchJiraTicketStatusSuccess = (response) => {
        console.log("jrnrjjr", response);
        if (response && response.detail.length > 0) {
            setState(new_state => ({
                ...new_state,
                jira_status_list: response.detail,
                loading_data: false,
            }));
        }
    }

    const fetchJiraTicketStatusFailure = () => {
        setState(new_state => ({
            ...new_state,
            error: true,
            loading_data: false,
        }));
    }

    const setJobJson = (data) => {

        setState(prevState => ({
            ...prevState,
            job_json: { ...data }
        }))
    }
    console.log(stage_index, get_job_index, state.current_stage_jobs, "skbdhbshjbsdbcsd")
    const jira_reference_key_list_for_subtask = getJiraRefid(pipeline);
    const issue_keys_list_for_subtask = [...getJobRunConditions().jira_id_reference_keys];
    jira_reference_key_list_for_subtask.forEach(element => {
        issue_keys_list_for_subtask.push({ label: element.label, id: element.label })
    });


    // const flipAndOpenAdditionalFiledsForm = () => {

    //     setFlipState(true);
    // }
    // const flipCloseAndRetainState = () => {
    //     setFlipState(false);
    // }


    const onChangeHandlerSearchInput = e => {
        const k = e.target.name;
        const v = e.target.value;

        commonFunctions.onChangeHandler(e)

    }

    const onEnterKeyPress = (e, id) => {
        if (e.key === 'Enter') {
            fetchJiraUserList(id, state.data[e.target.name])
        }
    }


    const fetchJiraUserList = (id, user) => {
        console.log(id, user, "sdbcjgvgsdvc");

        if (user) {
            if (user.length > 0) {
                if (user.length > 2) {
                    var requestInfo = {
                        endPoint: GenerateURL({ template_id: id ? id : state.data.project_job_template_id ? state.data.project_job_template_id : state.default_job_template.id, user_query: user, issue_key: state.data.issue_key }, properties.api.jira_users),
                        httpMethod: "GET",
                        httpHeaders: { "Content-Type": "application/json" }
                    }
                    InvokeApi(requestInfo, (data) => handleJiraUserListSuccess(data), (error) => handleJiraUserListFailed(error));
                    setState(new_state => ({
                        ...new_state,
                        user_loading: true,
                    }));
                } else {

                    setState(state => ({
                        ...state,

                        error: {
                            ...state.error,
                            message: "Please enter atleast first three characters of name"
                        }
                    }))

                }
            } else {
                setState(state => ({
                    ...state,

                    error: {
                        ...state.error,
                        message: "Please enter atleast first three characters of name"
                    }
                }))
            }


        } else {
            setState(new_state => ({
                ...new_state,
                error: {
                    ...new_state.error,
                    message: "please enter atleast first three characters of name"
                }
            }));
        }


    }

    console.log(state, "sbvjhdsbcbsdhb")

    function handleJiraUserListSuccess(data) {

        console.log(data, 'var_001wd');
        var jira_user_list = data.user;
        if (jira_user_list.length > 0) {
            setState(new_state => ({
                ...new_state,
                user_loading: false,
                user_object: { ...jira_user_list },
                error: {}
            }));
        } else {
            setState(new_state => ({
                ...new_state,
                user_loading: false,
                user_object: {},
                error: {
                    ...new_state.error,
                    message: "No user found with these details"
                }
            }));
        }

    }

    function handleJiraUserListFailed(error) {
        setState(new_state => ({
            ...new_state,
            user_loading: false,
            user_object: {},
            error: {
                ...new_state.error,
                message: error
            }
        }))
    }

    function getListForUsers() {
        const arr = []
        if (state.user_object) {
            Object.keys(state.user_object).forEach((item, index) => {
                const obj = { id: state.user_object[item], label: state.user_object[item] }
                arr.push(obj)
            })
        }

        console.log(arr, "dkbcbhsdsdbhjcbdsbjhc")

        return arr;
    }


    const selectJiraAssigneeHandler = e => {
        const k = e.target.name;
        const v = e.target.value;


        setState((new_state) => ({
            ...new_state,
            data: {
                ...new_state.data,
                [k]: v,
                selected_username: v
            }
        }))
    }


    function selectSubTaskHandler(e) {
        const k = e.target.name;
        const v = e.target.value;

        const sub_task_name = state.sub_task_issue_list[v]

        console.log(k, v, sub_task_name, "dbchbdsjbcsdbcb")

        setState((new_state) => ({
            ...new_state,
            data: {
                ...new_state.data,
                [k]: v,
                selected_sub_task_name: sub_task_name
            }
        }))
    }
    console.log(state, "sdbcjsbbcsd")


    function getSubTaskIssueList() {
        var arr = []
        state.sub_task_issue_list.forEach((item, index) => {
            var obj = { id: item, label: item }
            arr.push(obj)
        })

        return arr;
    }

    const updateJobTemplateVersionfn = (order, version) => {

        setState((new_state) => ({
            ...new_state,
            selected_job_template_version_taborder: order,
            selected_job_template_version: version,
            ...new_state,
            data: {
                ...new_state.data,
                template_version: version
            },
        }))
    }
    const updateJobTemplatefn = (order, name) => {

        setState((new_state) => ({
            ...new_state,
            data: {
                ...new_state.data,
                project_job_template_id: order,
                project_job_template: {
                    ...new_state.data.project_job_template,
                    name: name
                }
            },
            selected_job_template_taborder: order,
            selected_job_template_name: name,
            selected_job_template_version_taborder: null,
            selected_job_template_version: null
        }))
        if(order){
            fetchJobTemplateVersions(order);
        }
    }
    function fetchJobTemplateVersions(id) {
        let myEndPoint = GenerateURL({ template_id: id },
            properties.api.get_global_template_versions + "?all=true"
        );

        var requestInfo = {
            endPoint: myEndPoint,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };

        InvokeApi(
            requestInfo,
            jobTemplateVersionsFetchSuccess,
            jobTemplateVersionsFetchFailure
        );
        setState((new_state) => ({
            ...new_state,
            template_version_loading: true,
        }));
    }

    function jobTemplateVersionsFetchSuccess(data) {
        console.log(data, "fdlsfsdlfklas")
        let activeVersion = data.filter((item)=>{
            if(item.active){
                return item;
            }
        });
        setState((new_state) => ({
            ...new_state,
            template_version_loading: false,
            job_template_versions: data,
            selected_job_template_version_taborder: activeVersion[0].template_version,
            selected_job_template_version: activeVersion[0].template_version,
            data: {
                ...new_state.data,
                template_version: activeVersion[0].template_version,
            },
        }));
    }
    function jobTemplateVersionsFetchFailure(error, exception) {
        setState((new_state) => ({
            ...new_state,
            template_version_loading: false,
            error: error,
        }));
    }

    function getRequestedFormInputs() {
        switch (state.data?.task_type?.toString()) {
            case "2":
                return (
                    <>
                        <Input
                            type="select"
                            mandatorySign
                            name="project_id"
                            label="All Applications"
                            list={state.all_projects}
                            onChangeHandler={onChangeHandler}
                            data={state.data}
                            error={state.error} />
                        {
                            state.loading_env_data ?
                                <div >
                                    <Loading varient="light" />
                                </div>
                                :
                                <Input
                                    type="select"
                                    mandatorySign
                                    name="env"
                                    list={getListableArray(state.app_environment_list)}
                                    label="To Environment"
                                    onChangeHandler={onChangeHandler}
                                    data={state.data}
                                    error={state.error} />
                        }


                        {state.service_loading ?
                            <Loading varient="light" /> :
                            getListableArray(state.all_services).length > 0 &&
                            <div className={classes.rooter}>
                                <Input
                                    type="checkbox"
                                    name="components"
                                    label="Services"
                                    mandatorySign
                                    varient="inner_component"
                                    data={state.data}
                                    error={state.error}
                                    list={getListableArray(state.all_services)}
                                    onChangeHandler={state.all_services ? onChangeHandlerForKeyValue : () => { }}
                                />
                            </div>

                        }
                        {
                            state.data.task_type && state.data.task_type != '' && state.no_service_configured &&
                            <div style={{ width: '100%' }}>
                                <AlertStrip variant="p-v3-alert" message={`Services are not configured in this env. Please select a different env.`} />
                            </div>
                        }
                        <Input
                            type="select"
                            name="artifact_source"
                            list={deploy_option}
                            label="Artifact Source"
                            onChangeHandler={onChangeHandler}
                            data={state.data}
                            error={state.error} />

                        {
                            state.data.artifact_source == "TAG" ?
                                <Input
                                    type="text"
                                    name="tag"
                                    mandatorySign
                                    placeholder="Add Tag"
                                    label="Specify tag"
                                    onChangeHandler={onChangeHandler}
                                    data={state.data}
                                    error={state.error} /> : null
                        }
                        {
                            state.current_env && state.current_env.project_env_namespace ?
                                state.current_env.project_env_namespace && state.current_env.project_env_namespace.namespace ?
                                    state.current_env.project_env_namespace.namespace.istio_enabled ?
                                        <Input
                                            type="radio"
                                            name="deployment_type"
                                            list={deployment_type_list}
                                            label="Deployment Strategy"
                                            onChangeHandler={onChangeHandler}
                                            data={state.data}
                                            error={state.error} />
                                        : null : null : null
                        }
                        <div className='job-template-dropdown input-mb-0' style={{
                            border: '1px solid #dedede',
                            marginBottom: '25px',
                            overflow: 'hidden',
                            borderRadius: '8px'
                        }}>
                            <div className={classes.conditions_div_header} style={state?.data?.is_job_template_switch_active ? { borderBottom: '1px solid #dedede' } : { borderBottom: 'none' }}>
                                <div className="heading  font-weight-500 font-14 color-value">Do you want to specify a specific job template to execute this job for all selected services</div>
                                <div className="switch-input-wrapper">
                                    <Input
                                        type="only-switch"
                                        name="is_job_template_switch_active"
                                        data={state.data}
                                        error={state.error}
                                        onChangeHandler={commonFunctions.toggleState}
                                    />
                                </div>
                            </div>
                            {
                                state?.data?.is_job_template_switch_active &&
                                <div className='pd-10'>
                                    {
                                        state.all_templates && state.all_templates.length > 0 ?
                                            <>
                                                {state.show_template_input ?
                                                    <div className='d-grid' style={{ gridTemplateColumns: '1fr 1fr', gap: '8px' }} >
                                                        <InputWithDropdownJobTemplate
                                                            jobTemplateList={state.all_templates?.filter(({ version }) => version === 'v2')}
                                                            selectedJobTemplateId={state.data.project_job_template_id}
                                                            jobTemplateChangeHandler={updateJobTemplatefn}
                                                            label={"Select Job Template"}
                                                            popupWidth="407px"
                                                        />
                                                        {
                                                            state.template_version_loading ?
                                                                <Loading varient="light" />
                                                                :
                                                                <InputWithDropdownVersions
                                                                    jobTemplateVersionsList={state.job_template_versions}
                                                                    selectedJobTemplateVersion={state.data.template_version}
                                                                    jobTemplateChangeHandler={updateJobTemplateVersionfn}
                                                                    variant="version"
                                                                    popupWidth="407px"
                                                                    label={"Select Version of the job template"} />
                                                        }
                                                    </div>
                                                    :
                                                    <div style={{ border: '1px solid #dedede' }} className='d-flex align-center space-between pd-10 mt-20 mb-20'>
                                                        <p className='font-12 ' style={{ color: '#828282' }}>
                                                            {
                                                                state?.data?.project_job_template ? state?.data?.project_job_template.name : state.default_template_name ? state.default_template_name : 'Default Job Template'
                                                            }
                                                        </p>
                                                        <IconButton className='' onClick={toggleChooseTemplateInput}>
                                                            <BorderColorIcon className="text-anchor-blue" />
                                                        </IconButton>
                                                    </div>
                                                }
                                            </>
                                            :
                                            <AlertStrip message="Please add job templates first" vairant="info" />
                                    }
                                </div>

                            }
                        </div>
                        {
                            stage_index == 0 && get_job_index == 0 && state.current_stage_jobs?.length == 0 ?
                                <></>
                                :
                                stage_index == 0 && (get_job_index > 0 || state.current_stage_jobs?.length > 0) ?
                                    <ConditionsDialog selected_conditions={state.data.task_run_condition} get_run_conditions_function={getJobRunConditions} add_conditions_function={addConditionsToJobFunction} />
                                    :
                                    <ConditionsDialog selected_conditions={state.data.task_run_condition} get_run_conditions_function={getJobRunConditions} add_conditions_function={addConditionsToJobFunction} />
                        }



                        <Grid className='mb-5' container alignItems="center">
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top"}>
                                    <Input
                                        type="switch"
                                        name="deployment_status_check"
                                        label="Validate Deployment Success"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="on_success_exit"
                                        label="Terminate Pipeline on Success?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="on_failure_exit"
                                        label="Terminate Pipeline on Failure?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="skip_task"
                                        label="Skip Job?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="mark_success_upon_skip"
                                        label="Mark success on skip job?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                        </Grid>
                    </>
                );
            case "3":
                return (
                    <>
                        <Input
                            type="select"
                            mandatorySign
                            name="project_id"
                            label="All Applications"
                            list={state.all_projects}
                            onChangeHandler={onChangeHandler}
                            data={state.data}
                            error={state.error} />
                        {
                            state.loading_env_data ?
                                <div >
                                    <Loading varient="light" />
                                </div>
                                :
                                <Input
                                    type="select"
                                    mandatorySign
                                    name="env"
                                    list={getListableArray(state.app_environment_list)}
                                    label="From Environment"
                                    onChangeHandler={e => { onChangeHandler(e, true) }}
                                    data={state.data}
                                    error={state.error} />
                        }


                        {state.data.env &&

                            <Input
                                type="select"
                                mandatorySign
                                name="target_env"
                                list={getAllowedPromoteEnvs().length > 0 ? getAllowedPromoteEnvs() : getListableArray(state.app_environment_list)}
                                label="Target Environment"
                                onChangeHandler={e => { onChangeHandler(e, true) }}
                                data={state.data}
                                error={state.error} />
                        }

                        {state.service_loading ?
                            <Loading varient="light" /> :
                            getListableArray(state.all_services).length > 0 &&
                            <div className={classes.rooter}>
                                <Input
                                    type="checkbox"
                                    name="components"
                                    label="Services"
                                    mandatorySign
                                    varient="inner_component"
                                    data={state.data}
                                    error={state.error}
                                    list={getListableArray(state.all_services)}
                                    onChangeHandler={state.all_services ? onChangeHandlerForKeyValue : () => { }}
                                />
                            </div>
                        }
                        {
                            state.data.task_type && state.data.task_type != '' && state.no_service_configured &&
                            <div style={{ width: '100%' }}>
                                <AlertStrip variant="p-v3-alert" message={`Services are not configured in this env. Please select a different env.`} />
                            </div>
                        }
                        <Input
                            type="select"
                            name="artifact_source"
                            list={deploy_option}
                            label="Artifact Source"
                            onChangeHandler={onChangeHandler}
                            data={state.data}
                            error={state.error} />

                        {
                            state.data.artifact_source == "TAG" ?
                                <Input
                                    type="text"
                                    name="tag"
                                    mandatorySign
                                    placeholder="Add Tag"
                                    label="Specify tag"
                                    onChangeHandler={onChangeHandler}
                                    data={state.data}
                                    error={state.error} /> : null
                        }

                        <div className='job-template-dropdown input-mb-0' style={{
                            border: '1px solid #dedede',
                            marginBottom: '25px',
                            overflow: 'hidden',
                            borderRadius: '8px'
                        }}>
                            <div className={classes.conditions_div_header} style={state?.data?.is_job_template_switch_active ? { borderBottom: '1px solid #dedede' } : { borderBottom: 'none' }}>
                                <div className="heading  font-weight-500 font-14 color-value">Do you want to specify a specific job template to execute this job for all selected services</div>
                                <div className="switch-input-wrapper">
                                    <Input
                                        type="only-switch"
                                        name="is_job_template_switch_active"
                                        data={state.data}
                                        error={state.error}
                                        onChangeHandler={commonFunctions.toggleState}
                                    />
                                </div>
                            </div>
                            {
                                state?.data?.is_job_template_switch_active &&
                                <div className='pd-10'>
                                    {
                                        state.all_templates && state.all_templates.length > 0 ?
                                            <>
                                                {state.show_template_input ?
                                                    <div className='d-grid' style={{ gridTemplateColumns: '1fr 1fr', gap: '8px' }} >
                                                        <InputWithDropdownJobTemplate
                                                            jobTemplateList={state.all_templates?.filter(({ version }) => version === 'v2')}
                                                            selectedJobTemplateId={state.data.project_job_template_id}
                                                            jobTemplateChangeHandler={updateJobTemplatefn}
                                                            label={"Select Job Template"}
                                                            popupWidth="407px"
                                                        />
                                                        {
                                                            state.template_version_loading ?
                                                                <Loading varient="light" />
                                                                :
                                                                <InputWithDropdownVersions
                                                                    jobTemplateVersionsList={state.job_template_versions}
                                                                    selectedJobTemplateVersion={state.data.template_version}
                                                                    jobTemplateChangeHandler={updateJobTemplateVersionfn}
                                                                    variant="version"
                                                                    popupWidth="407px"
                                                                    label={"Select Version of the job template"} />
                                                        }
                                                    </div>
                                                    :
                                                    <div style={{ border: '1px solid #dedede' }} className='d-flex align-center space-between pd-10 mt-20 mb-20'>
                                                        <p className='font-12 ' style={{ color: '#828282' }}>
                                                            {
                                                                state?.data?.project_job_template ? state?.data?.project_job_template.name : state.default_template_name ? state.default_template_name : 'Default Job Template'
                                                            }
                                                        </p>
                                                        <IconButton className='' onClick={toggleChooseTemplateInput}>
                                                            <BorderColorIcon className="text-anchor-blue" />
                                                        </IconButton>
                                                    </div>
                                                }
                                            </>
                                            :
                                            <AlertStrip message="Please add job templates first" vairant="info" />
                                    }
                                </div>

                            }
                        </div>
                        {
                            stage_index == 0 && get_job_index == 0 && state.current_stage_jobs?.length == 0 ?
                                <></>
                                :
                                stage_index == 0 && (get_job_index > 0 || state.current_stage_jobs?.length > 0) ?
                                    <ConditionsDialog selected_conditions={state.data.task_run_condition} get_run_conditions_function={getJobRunConditions} add_conditions_function={addConditionsToJobFunction} />
                                    :
                                    <ConditionsDialog selected_conditions={state.data.task_run_condition} get_run_conditions_function={getJobRunConditions} add_conditions_function={addConditionsToJobFunction} />
                        }

                        <Grid className='mb-5' container alignItems="center">
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="on_success_exit"
                                        label="Terminate Pipeline on Success?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>

                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="on_failure_exit"
                                        label="Terminate Pipeline on Failure?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="skip_task"
                                        label="Skip Job?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="mark_success_upon_skip"
                                        label="Mark success on skip job?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                        </Grid>
                    </>
                );
            case "1":
               
                return (
                    <>
                        <Input
                            type="select"
                            mandatorySign
                            name="project_id"
                            label="All Applications"
                            list={state.all_projects}
                            onChangeHandler={onChangeHandler}
                            data={state.data}
                            error={state.error} />

                        {
                            state.loading_env_data ?
                                <div >
                                    <Loading varient="light" />
                                </div>
                                :
                                <Input
                                    type="select"
                                    mandatorySign
                                    name="env"
                                    list={getListableArray(state.app_environment_list)}
                                    label="To Environment"
                                    onChangeHandler={onChangeHandler}
                                    data={state.data}
                                    error={state.error} />
                        }


                        {
                            state.service_loading ?
                                <Loading varient="light" /> :
                                getListableArray(state.all_services).length > 0 &&
                                <div className={classes.rooter}>
                                    <Input
                                        type="checkbox"
                                        name="components"
                                        label="Services"
                                        mandatorySign
                                        varient="inner_component"
                                        data={state.data}
                                        error={state.error}
                                        list={getListableArray(state.all_services)}
                                        onChangeHandler={state.all_services ? onChangeHandlerForKeyValue : () => { }}
                                    />
                                </div>
                        }
                        {
                            state.data.task_type && state.data.task_type != '' && state.no_service_configured &&
                            <div style={{ width: '100%' }}>
                                <AlertStrip variant="p-v3-alert" message={`Services are not configured in this env. Please select a different env.`} />
                            </div>
                        }
                        {/* <Input
                            type="select"
                            name="project_ci_scope_id"
                            list={getCiList(application.ci_list)}
                            label="CI Scope"
                            onChangeHandler={onChangeHandler}
                            data={state.data}
                            error={state.error} /> */}

                        <Input
                            type="text"
                            name="tag"
                            placeholder="Add Tag"
                            label="Tag"
                            onChangeHandler={onChangeHandler}
                            data={state.data}
                            error={state.error} />
                         <div className='job-template-dropdown input-mb-0' style={{
                            border: '1px solid #dedede',
                            marginBottom: '25px',
                            overflow: 'hidden',
                            borderRadius: '8px'
                        }}>
                            <div className={classes.conditions_div_header} style={state?.data?.is_job_template_switch_active ? { borderBottom: '1px solid #dedede' } : { borderBottom: 'none' }}>
                                <div className="heading  font-weight-500 font-14 color-value">Do you want to specify a specific job template to execute this job for all selected services</div>
                                <div className="switch-input-wrapper">
                                    <Input
                                        type="only-switch"
                                        name="is_job_template_switch_active"
                                        data={state.data}
                                        error={state.error}
                                        onChangeHandler={commonFunctions.toggleState}
                                    />
                                </div>
                            </div>
                            {
                                state?.data?.is_job_template_switch_active &&
                                <div className='pd-10'>
                                    {
                                        state.all_templates && state.all_templates.length > 0 ?
                                            <>
                                                {state.show_template_input ?
                                                    <div className='d-grid' style={{ gridTemplateColumns: '1fr 1fr', gap: '8px' }} >
                                                        <InputWithDropdownJobTemplate
                                                            jobTemplateList={state.all_templates?.filter(({ version }) => version === 'v2')}
                                                            selectedJobTemplateId={state.data.project_job_template_id}
                                                            jobTemplateChangeHandler={updateJobTemplatefn}
                                                            label={"Select Job Template"}
                                                            popupWidth="407px"
                                                        />
                                                        {
                                                            state.template_version_loading ?
                                                                <Loading varient="light" />
                                                                :
                                                                <InputWithDropdownVersions
                                                                    jobTemplateVersionsList={state.job_template_versions}
                                                                    selectedJobTemplateVersion={state.data.template_version}
                                                                    jobTemplateChangeHandler={updateJobTemplateVersionfn}
                                                                    variant="version"
                                                                    popupWidth="407px"
                                                                    label={"Select Version of the job template"} />
                                                        }
                                                    </div>
                                                    :
                                                    <div style={{ border: '1px solid #dedede' }} className='d-flex align-center space-between pd-10 mt-20 mb-20'>
                                                        <p className='font-12 ' style={{ color: '#828282' }}>
                                                            {
                                                                state?.data?.project_job_template ? state?.data?.project_job_template.name : state.default_template_name ? state.default_template_name : 'Default Job Template'
                                                            }
                                                        </p>
                                                        <IconButton className='' onClick={toggleChooseTemplateInput}>
                                                            <BorderColorIcon className="text-anchor-blue" />
                                                        </IconButton>
                                                    </div>
                                                }
                                            </>
                                            :
                                            <AlertStrip message="Please add job templates first" vairant="info" />
                                    }
                                </div>

                            }
                        </div>
                        {
                            stage_index == 0 && get_job_index == 0 && state.current_stage_jobs?.length == 0 ?
                                <></>
                                :
                                stage_index == 0 && (get_job_index > 0 || state.current_stage_jobs?.length > 0) ?
                                    <ConditionsDialog
                                        title="Jobs"
                                        selected_conditions={state.data.task_run_condition}
                                        get_run_conditions_function={getJobRunConditions}
                                        add_conditions_function={addConditionsToJobFunction}
                                    />
                                    :
                                    <ConditionsDialog
                                        title="Jobs"
                                        selected_conditions={state.data.task_run_condition}
                                        get_run_conditions_function={getJobRunConditions}
                                        add_conditions_function={addConditionsToJobFunction}
                                    />
                        }
                        <Grid className='mb-5' container alignItems="center">
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input disable-enable-switch"} style={{ width: '100%' }}>
                                    <Input
                                        type="enabled-disabled-switch"
                                        name="docker_cache"
                                        label="Docker Cache"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="latest_enable"
                                        label="Duplicate Image as latest?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="on_success_exit"
                                        label="Terminate Pipeline on Success?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="on_failure_exit"
                                        label="Terminate Pipeline on Failure?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="skip_task"
                                        label="Skip Job?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="mark_success_upon_skip"
                                        label="Mark success on skip job?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                        </Grid>
                    </>
                );
            case "6":
                return (
                    <>
                        <Input
                            type="select"
                            mandatorySign
                            name="project_id"
                            label="All Applications"
                            list={state.all_projects}
                            onChangeHandler={onChangeHandler}
                            data={state.data}
                            error={state.error} />
                        {
                            state.loading_env_data ?
                                <div >
                                    <Loading varient="light" />
                                </div>
                                :
                                <Input
                                    type="select"
                                    mandatorySign
                                    name="env"
                                    list={getListableArray(state.app_environment_list)}
                                    label="To Environment"
                                    onChangeHandler={onChangeHandler}
                                    data={state.data}
                                    error={state.error} />
                        }

                        {getListableConfigmaps(state.all_configmaps).length > 0 &&
                            <div className={classes.rooter}>
                                <Input
                                    type="checkbox"
                                    name="env_namespace_configmap"
                                    label="Configmaps"
                                    mandatorySign
                                    varient="inner_component"
                                    data={state.data}
                                    error={state.error}
                                    list={getListableConfigmaps(state.all_configmaps)}
                                    onChangeHandler={state.all_configmaps ? onChangeHandlerForKeyValue : () => { }}
                                />
                            </div>
                        }
                        {
                            state.data.task_type && state.data.task_type != '' && state.no_configmaps_configured &&
                            <div style={{ width: '100%' }}>
                                <AlertStrip variant="p-v3-alert" message={`Configmaps are not configured in this env. Please select a different env.`} />
                            </div>
                        }
                        {/* <Input
                                type="select"
                                name="project_ci_scope_id"
                                list={getCiList(application.ci_list)}
                                label="CI Scope"
                                onChangeHandler={onChangeHandler}
                                data={state.data}
                                error={state.error} /> */}

                        {/* <Input
                            type="text"
                            name="tag"
                            placeholder="Add Tag"
                            label="Tag"
                            onChangeHandler={onChangeHandler}
                            data={state.data}
                            error={state.error} /> */}


<div className='job-template-dropdown input-mb-0' style={{
                            border: '1px solid #dedede',
                            marginBottom: '25px',
                            overflow: 'hidden',
                            borderRadius: '8px'
                        }}>
                            <div className={classes.conditions_div_header} style={state?.data?.is_job_template_switch_active ? { borderBottom: '1px solid #dedede' } : { borderBottom: 'none' }}>
                                <div className="heading  font-weight-500 font-14 color-value">Do you want to specify a specific job template to execute this job for all selected services</div>
                                <div className="switch-input-wrapper">
                                    <Input
                                        type="only-switch"
                                        name="is_job_template_switch_active"
                                        data={state.data}
                                        error={state.error}
                                        onChangeHandler={commonFunctions.toggleState}
                                    />
                                </div>
                            </div>
                            {
                                state?.data?.is_job_template_switch_active &&
                                <div className='pd-10'>
                                    {
                                        state.all_templates && state.all_templates.length > 0 ?
                                            <>
                                                {state.show_template_input ?
                                                    <div className='d-grid' style={{ gridTemplateColumns: '1fr 1fr', gap: '8px' }} >
                                                        <InputWithDropdownJobTemplate
                                                            jobTemplateList={state.all_templates?.filter(({ version }) => version === 'v2')}
                                                            selectedJobTemplateId={state.data.project_job_template_id}
                                                            jobTemplateChangeHandler={updateJobTemplatefn}
                                                            label={"Select Job Template"}
                                                            popupWidth="407px"
                                                        />
                                                        {
                                                            state.template_version_loading ?
                                                                <Loading varient="light" />
                                                                :
                                                                <InputWithDropdownVersions
                                                                    jobTemplateVersionsList={state.job_template_versions}
                                                                    selectedJobTemplateVersion={state.data.template_version}
                                                                    jobTemplateChangeHandler={updateJobTemplateVersionfn}
                                                                    variant="version"
                                                                    popupWidth="407px"
                                                                    label={"Select Version of the job template"} />
                                                        }
                                                    </div>
                                                    :
                                                    <div style={{ border: '1px solid #dedede' }} className='d-flex align-center space-between pd-10 mt-20 mb-20'>
                                                        <p className='font-12 ' style={{ color: '#828282' }}>
                                                            {
                                                                state?.data?.project_job_template ? state?.data?.project_job_template.name : state.default_template_name ? state.default_template_name : 'Default Job Template'
                                                            }
                                                        </p>
                                                        <IconButton className='' onClick={toggleChooseTemplateInput}>
                                                            <BorderColorIcon className="text-anchor-blue" />
                                                        </IconButton>
                                                    </div>
                                                }
                                            </>
                                            :
                                            <AlertStrip message="Please add job templates first" vairant="info" />
                                    }
                                </div>

                            }
                        </div>
                        {
                            stage_index == 0 && get_job_index == 0 && state.current_stage_jobs?.length == 0 ?
                                <></>
                                :
                                stage_index == 0 && (get_job_index > 0 || state.current_stage_jobs?.length > 0) ?
                                    <ConditionsDialog selected_conditions={state.data.task_run_condition} get_run_conditions_function={getJobRunConditions} add_conditions_function={addConditionsToJobFunction} />
                                    :
                                    <ConditionsDialog selected_conditions={state.data.task_run_condition} get_run_conditions_function={getJobRunConditions} add_conditions_function={addConditionsToJobFunction} />
                        }


                        <Grid className='mb-5' container alignItems="center">


                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="on_success_exit"
                                        label="Terminate Pipeline on Success?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="on_failure_exit"
                                        label="Terminate Pipeline on Failure?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="skip_task"
                                        label="Skip Job?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="mark_success_upon_skip"
                                        label="Mark success on skip job?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                        </Grid>
                    </>
                );
            case "7":
                return (
                    <>
                        <Input
                            type="select"
                            mandatorySign
                            name="project_id"
                            label="All Applications"
                            list={state.all_projects}
                            onChangeHandler={onChangeHandler}
                            data={state.data}
                            error={state.error} />
                        {
                            state.loading_env_data ?
                                <div >
                                    <Loading varient="light" />
                                </div>
                                :
                                <Input
                                    type="select"
                                    mandatorySign
                                    name="env"
                                    list={getListableArray(state.app_environment_list)}
                                    label="To Environment"
                                    onChangeHandler={onChangeHandler}
                                    data={state.data}
                                    error={state.error} />
                        }

                        {getListableSecrets(state.all_secrets).length > 0 &&
                            <div className={classes.rooter}>
                                <Input
                                    type="checkbox"
                                    name="env_namespace_secret"
                                    label="Secrets"
                                    mandatorySign
                                    varient="inner_component"
                                    data={state.data}
                                    error={state.error}
                                    list={getListableSecrets(state.all_secrets)}
                                    onChangeHandler={state.all_secrets ? onChangeHandlerForKeyValue : () => { }}
                                />
                            </div>
                        }
                        {
                            state.data.task_type && state.data.task_type != '' && state.no_secrets_configured &&
                            <div style={{ width: '100%' }}>
                                <AlertStrip variant="p-v3-alert" message={`Secrets are not configured in this env. Please select a different env.`} />
                            </div>
                        }
                        {/* <Input
                                type="select"
                                name="project_ci_scope_id"
                                list={getCiList(application.ci_list)}
                                label="CI Scope"
                                onChangeHandler={onChangeHandler}
                                data={state.data}
                                error={state.error} /> */}
 <div className='job-template-dropdown input-mb-0' style={{
                            border: '1px solid #dedede',
                            marginBottom: '25px',
                            overflow: 'hidden',
                            borderRadius: '8px'
                        }}>
                            <div className={classes.conditions_div_header} style={state?.data?.is_job_template_switch_active ? { borderBottom: '1px solid #dedede' } : { borderBottom: 'none' }}>
                                <div className="heading  font-weight-500 font-14 color-value">Do you want to specify a specific job template to execute this job for all selected services</div>
                                <div className="switch-input-wrapper">
                                    <Input
                                        type="only-switch"
                                        name="is_job_template_switch_active"
                                        data={state.data}
                                        error={state.error}
                                        onChangeHandler={commonFunctions.toggleState}
                                    />
                                </div>
                            </div>
                            {
                                state?.data?.is_job_template_switch_active &&
                                <div className='pd-10'>
                                    {
                                        state.all_templates && state.all_templates.length > 0 ?
                                            <>
                                                {state.show_template_input ?
                                                    <div className='d-grid' style={{ gridTemplateColumns: '1fr 1fr', gap: '8px' }} >
                                                        <InputWithDropdownJobTemplate
                                                            jobTemplateList={state.all_templates?.filter(({ version }) => version === 'v2')}
                                                            selectedJobTemplateId={state.data.project_job_template_id}
                                                            jobTemplateChangeHandler={updateJobTemplatefn}
                                                            label={"Select Job Template"}
                                                            popupWidth="407px"
                                                        />
                                                        {
                                                            state.template_version_loading ?
                                                                <Loading varient="light" />
                                                                :
                                                                <InputWithDropdownVersions
                                                                    jobTemplateVersionsList={state.job_template_versions}
                                                                    selectedJobTemplateVersion={state.data.template_version}
                                                                    jobTemplateChangeHandler={updateJobTemplateVersionfn}
                                                                    variant="version"
                                                                    popupWidth="407px"
                                                                    label={"Select Version of the job template"} />
                                                        }
                                                    </div>
                                                    :
                                                    <div style={{ border: '1px solid #dedede' }} className='d-flex align-center space-between pd-10 mt-20 mb-20'>
                                                        <p className='font-12 ' style={{ color: '#828282' }}>
                                                            {
                                                                state?.data?.project_job_template ? state?.data?.project_job_template.name : state.default_template_name ? state.default_template_name : 'Default Job Template'
                                                            }
                                                        </p>
                                                        <IconButton className='' onClick={toggleChooseTemplateInput}>
                                                            <BorderColorIcon className="text-anchor-blue" />
                                                        </IconButton>
                                                    </div>
                                                }
                                            </>
                                            :
                                            <AlertStrip message="Please add job templates first" vairant="info" />
                                    }
                                </div>

                            }
                        </div>
                        {
                            stage_index == 0 && get_job_index == 0 && state.current_stage_jobs?.length == 0 ?
                                <></>
                                :
                                stage_index == 0 && (get_job_index > 0 || state.current_stage_jobs?.length > 0) ?
                                    <ConditionsDialog selected_conditions={state.data.task_run_condition} get_run_conditions_function={getJobRunConditions} add_conditions_function={addConditionsToJobFunction} />
                                    :
                                    <ConditionsDialog selected_conditions={state.data.task_run_condition} get_run_conditions_function={getJobRunConditions} add_conditions_function={addConditionsToJobFunction} />
                        }


                        <Grid className='mb-5' container alignItems="center">


                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="on_success_exit"
                                        label="Terminate Pipeline on Success?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="on_failure_exit"
                                        label="Terminate Pipeline on Failure?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="skip_task"
                                        label="Skip Job?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="mark_success_upon_skip"
                                        label="Mark success on skip job?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                        </Grid>
                    </>
                );
            case "5":
                return (
                    <>
                        <Input
                            type="select"
                            name="method"
                            mandatorySign
                            list={api_call_options}
                            label="Method"
                            onChangeHandler={onChangeHandler}
                            data={state.data}
                            error={state.error} />

                        <Input
                            type="text"
                            mandatorySign
                            name="url"
                            label="URL"
                            onChangeHandler={onChangeHandler}
                            data={state.data}
                            error={state.error} />

                        <Input
                            type="text"
                            name="headers_key_value_pairs"
                            label="Headers"
                            subHeading="Enter Json"
                            onChangeHandler={onChangeHandler}
                            data={state.data}
                            error={state.error} />

                        <Input
                            type="text"
                            mandatorySign
                            name="request_timeout"
                            label="Request Time Out"
                            subHeading="Wait for time(in seconds) for response"
                            onChangeHandler={onChangeHandler}
                            data={state.data}
                            error={state.error} />
                         <div className='job-template-dropdown input-mb-0' style={{
                            border: '1px solid #dedede',
                            marginBottom: '25px',
                            overflow: 'hidden',
                            borderRadius: '8px'
                        }}>
                            <div className={classes.conditions_div_header} style={state?.data?.is_job_template_switch_active ? { borderBottom: '1px solid #dedede' } : { borderBottom: 'none' }}>
                                <div className="heading  font-weight-500 font-14 color-value">Do you want to specify a specific job template to execute this job for all selected services</div>
                                <div className="switch-input-wrapper">
                                    <Input
                                        type="only-switch"
                                        name="is_job_template_switch_active"
                                        data={state.data}
                                        error={state.error}
                                        onChangeHandler={commonFunctions.toggleState}
                                    />
                                </div>
                            </div>
                            {
                                state?.data?.is_job_template_switch_active &&
                                <div className='pd-10'>
                                    {
                                        state.all_templates && state.all_templates.length > 0 ?
                                            <>
                                                {state.show_template_input ?
                                                    <div className='d-grid' style={{ gridTemplateColumns: '1fr 1fr', gap: '8px' }} >
                                                        <InputWithDropdownJobTemplate
                                                            jobTemplateList={state.all_templates?.filter(({ version }) => version === 'v2')}
                                                            selectedJobTemplateId={state.data.project_job_template_id}
                                                            jobTemplateChangeHandler={updateJobTemplatefn}
                                                            label={"Select Job Template"}
                                                            popupWidth="407px"
                                                        />
                                                        {
                                                            state.template_version_loading ?
                                                                <Loading varient="light" />
                                                                :
                                                                <InputWithDropdownVersions
                                                                    jobTemplateVersionsList={state.job_template_versions}
                                                                    selectedJobTemplateVersion={state.data.template_version}
                                                                    jobTemplateChangeHandler={updateJobTemplateVersionfn}
                                                                    variant="version"
                                                                    popupWidth="407px"
                                                                    label={"Select Version of the job template"} />
                                                        }
                                                    </div>
                                                    :
                                                    <div style={{ border: '1px solid #dedede' }} className='d-flex align-center space-between pd-10 mt-20 mb-20'>
                                                        <p className='font-12 ' style={{ color: '#828282' }}>
                                                            {
                                                                state?.data?.project_job_template ? state?.data?.project_job_template.name : state.default_template_name ? state.default_template_name : 'Default Job Template'
                                                            }
                                                        </p>
                                                        <IconButton className='' onClick={toggleChooseTemplateInput}>
                                                            <BorderColorIcon className="text-anchor-blue" />
                                                        </IconButton>
                                                    </div>
                                                }
                                            </>
                                            :
                                            <AlertStrip message="Please add job templates first" vairant="info" />
                                    }
                                </div>

                            }
                        </div>

                        {
                            stage_index == 0 && get_job_index == 0 && state.current_stage_jobs?.length == 0 ?
                                <></>
                                :
                                stage_index == 0 && (get_job_index > 0 || state.current_stage_jobs?.length > 0) ?
                                    <ConditionsDialog selected_conditions={state.data.task_run_condition} get_run_conditions_function={getJobRunConditions} add_conditions_function={addConditionsToJobFunction} />
                                    :
                                    <ConditionsDialog selected_conditions={state.data.task_run_condition} get_run_conditions_function={getJobRunConditions} add_conditions_function={addConditionsToJobFunction} />
                        }

                        <Grid className='mb-5' container alignItems="center">
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="pipeline_suspend"
                                        mandatorySign
                                        label="Suspend Pipeline?"
                                        subHeading="test"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        mandatorySign
                                        name="response_update_context_param"
                                        label="Should update the context params?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="on_success_exit"
                                        label="Terminate Pipeline on Success?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div></Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="on_failure_exit"
                                        label="Terminate Pipeline on Failure?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="skip_task"
                                        label="Skip Job?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="mark_success_upon_skip"
                                        label="Mark success on skip job?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                        </Grid>
                    </>
                );
            case "10":
                return (
                    <>
                        <div className=""> {/*  bg-f8-grey border-top */}
                            <Input
                                type="select"
                                name="operation"
                                mandatorySign
                                list={jira_operations_list}
                                label="Jira Operations"
                                onChangeHandler={updateOperation}
                                data={state.data}
                                error={state.error}
                                jiraOperation={true}
                                jiraOperationInfo={state.data.operation ? jira_operation_info[state.data.operation] : "Please Select an Option"}
                            />
                            {
                                state.data.operation === "create" &&
                                <>
                                    <CreateTicket
                                        prev_state={state.data}
                                        inherits={state.child_inherits.create}
                                        default_job_template={state.default_job_template ? state.default_job_template : null}
                                        jira_id_reference_key={getJiraReferenceIdForCreateTicket()}
                                        getIssueType={getIssueType} />
                                </>
                            }
                            {
                                state.data.operation === "status_update" &&
                                <>
                                    <ChangeStatus
                                        prev_state={state.data}
                                        jira_reference_key_list={getJiraRefid(pipeline)}
                                        inherits={state.child_inherits.status_update}
                                        get_run_conditions_function={getJobRunConditions} />
                                </>
                            }
                            {
                                state.data.operation === "add_comment" &&
                                <>
                                    <AddComment
                                        jiraIssueKeys={jiraIssueKeys}
                                        pipelineVars={pipelineVars}
                                        jobStepOrderOutputVars={jobStepOrderOutputVars}
                                        pipeline={pipeline}
                                        prev_state={state.data}
                                        jira_reference_key_list={getJiraRefid(pipeline)}
                                        default_job_template={state.default_job_template ? state.default_job_template : null}
                                        inherits={state.child_inherits.add_comment}
                                        get_run_conditions_function={getJobRunConditions} />
                                </>
                            }
                            {
                                state.data.operation === "set_service" &&
                                <>
                                    <div className='job-template-dropdown input-mb-0' style={{
                                        border: '1px solid #dedede',
                                        marginBottom: '25px',
                                        overflow: 'hidden',
                                        borderRadius: '8px'
                                    }}>
                                        <div className={classes.conditions_div_header} style={state?.data?.is_job_template_switch_active ? { borderBottom: '1px solid #dedede' } : { borderBottom: 'none' }}>
                                            <div className="heading" style={{ display: "flex", alignItems: "center" }}>Fetch branches for services from JIRA
                                                <Tooltip TransitionComponent={Zoom} title="INFO WILL COME HERE !!!!!!" placement="right">
                                                    <InfoOutlinedIcon style={{ color: '#828282', marginLeft: '4px' }} />
                                                </Tooltip>
                                            </div>
                                            <div className="switch-input-wrapper">
                                                <Input
                                                    type="only-switch"
                                                    name="set_branch"
                                                    data={state.data}
                                                    error={state.error}
                                                    onChangeHandler={commonFunctions.toggleState}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>

                            }
                            {
                                state.data.operation === "create_subtask" &&
                                <>
                                    <Input
                                        type="select"
                                        name="parent_jira_id"
                                        list={issue_keys_list_for_subtask}  // get_run_conditions_function().jira_id_reference_keys
                                        label="Select Jira Id Refrence key"
                                        placeholder="Jira id refrence key"
                                        // mandatorySign
                                        onChangeHandler={commonFunctions.onChangeHandler}
                                        data={state.data}
                                        error={state.error} />
                                    {
                                        state.loading_subtask ?
                                            <div style={{ margin: "20px" }}>
                                                <Loading varient="light" />
                                            </div>
                                            :
                                            <Input
                                                type="select"
                                                name="project_sub_task"
                                                mandatorySign
                                                list={state.sub_task_issue_list ? getSubTaskIssueList() : []}
                                                label="Subtask Issue Type"
                                                placeholder="Issue Type"
                                                onChangeHandler={commonFunctions.onChangeHandler}
                                                data={state.data}
                                                error={state.error}
                                            />
                                    }
                                    <Input
                                        type="text"
                                        name="issue_key"
                                        label="Issue Key"
                                        placeholder="Please enter issue key here"
                                        mandatorySign
                                        onChangeHandler={commonFunctions.onChangeHandler}
                                        data={state.data}
                                        error={state.error}
                                    />
                                    <Input
                                        type="text"
                                        name="summary"
                                        label="Summary"
                                        placeholder="Subtask Summary"
                                        mandatorySign
                                        onChangeHandler={commonFunctions.onChangeHandler}
                                        data={state.data}
                                        error={state.error}
                                    />
                                    <Input
                                        type="text"
                                        name="description"
                                        label="Description"
                                        placeholder="description for the subtask"
                                        onChangeHandler={commonFunctions.onChangeHandler}
                                        data={state.data}
                                        error={state.error}
                                    />
                                    <Grid item lg={12}>
                                        <span style={{ fontSize: '12px', color: '#828282' }}>
                                            Search the user here.</span><span style={{ fontSize: '11px', color: '#828282' }}>&nbsp;(Please enter atleast first three characters of name)</span>
                                        <div className={classes.searchBar}>

                                            <Input
                                                type="text"
                                                varient="handle-keys"
                                                placeholder="Search User"
                                                name="search_text"
                                                data={state.data}
                                                error={state.error}
                                                onChangeHandler={onChangeHandlerSearchInput}
                                                onKeyPress={(e) => { onEnterKeyPress(e, state.data.project_job_template_id ? state.data.project_job_template_id : state.default_job_template.id) }}
                                            />
                                            <button className='btn btn-searchbar' onClick={() => { fetchJiraUserList(state.data.project_job_template_id ? state.data.project_job_template_id : state.default_job_template.id, state.data.search_text) }}>
                                                <span class="material-icons material-symbols-outlined">
                                                    search
                                                </span>
                                            </button>
                                        </div>
                                    </Grid>
                                    {
                                        state.user_loading ?
                                            <div style={{ margin: "20px" }}>
                                                <Loading varient="light" />
                                            </div>
                                            :
                                            state.error && state.error.message ?
                                                <AlertStrip
                                                    message={state.error.message}
                                                    variant="error"
                                                />
                                                :
                                                state.user_object && Object.keys(state.user_object).length ?
                                                    <Grid item lg={12}>
                                                        <Input
                                                            type="select"
                                                            name="assignee_name"
                                                            mandatorySign
                                                            list={getListForUsers()}
                                                            label="Available Jira Users"
                                                            placeholder="Jira Users"
                                                            onChangeHandler={commonFunctions.onChangeHandler}
                                                            data={state.data}
                                                            error={state.error} />
                                                    </Grid>
                                                    :
                                                    <Grid item lg={12}>
                                                        <Input
                                                            type="select"
                                                            name="assignee_name"
                                                            mandatorySign
                                                            list={[]}
                                                            label="Available Jira Users"
                                                            placeholder="Jira Users"
                                                            onChangeHandler={null}
                                                            data={state.data}
                                                            error={state.error} />
                                                    </Grid>
                                    }

                                </>
                            }
                            {
                                state.data.operation === "create_release" &&
                                <>
                                    <Input
                                        type="text"
                                        name="release_name"
                                        label="Release Name"
                                        placeholder="release name"
                                        onChangeHandler={commonFunctions.onChangeHandler}
                                        data={state.data}
                                        error={state.error} />

                                    <Input
                                        type="checkbox"
                                        name="jira_status"
                                        label="Valid JIRA's statuses"
                                        mandatorySign
                                        varient="inner_component"
                                        data={state.data}
                                        error={state.error}
                                        list={state.jira_status_list?.length > 0 ? state.jira_status_list.map((item) => ({id: item.name,label: item.name})) : []}
                                        jira_status={true}
                                        onChangeHandler={state.jira_status_list ? onChangeHandlerForKeyValueJira : () => { }}
                                    />
                                    {/* onChangeHandlerForKeyValue */}
                                </>
                            }
                            {
                                state.data.operation === "check_jira_ids" &&
                                <CheckJiraIds
                                    jiraIssueKeys={jiraIssueKeys}
                                    pipelineVars={pipelineVars}
                                    jobStepOrderOutputVars={jobStepOrderOutputVars}
                                    pipeline={pipeline}
                                    prev_state={state.data}
                                    jira_reference_key_list={getJiraRefid(pipeline)}
                                    default_job_template={state.default_job_template ? state.default_job_template : null}
                                    inherits={state.child_inherits.check_jira_ids}
                                    get_run_conditions_function={getJobRunConditions}
                                    setParentState={setState}
                                    parentState={state}
                                    // dynamicValidationForm={state.dynamicValidationForm}
                                    dynamicValidationFormError={state.dynamicValidationFormError}
                                />
                            }
                            {
                                state.data.operation === "check_conflicts" &&
                                <CheckConflicts
                                    jiraIssueKeys={jiraIssueKeys}
                                    pipelineVars={pipelineVars}
                                    jobStepOrderOutputVars={jobStepOrderOutputVars}
                                    pipeline={pipeline}
                                    prev_state={state.data}
                                    jira_reference_key_list={getJiraRefid(pipeline)}
                                    default_job_template={state.default_job_template ? state.default_job_template : null}
                                    inherits={state.child_inherits.check_conflicts}
                                    get_run_conditions_function={getJobRunConditions}
                                    setParentState={setState}
                                    parentState={state}
                                    // dynamicValidationForm={state.dynamicValidationForm}
                                    dynamicValidationFormError={state.dynamicValidationFormError}
                                />
                            }
                            {
                                state.data.operation === "create_pr" &&
                                <CreatePR
                                    jiraIssueKeys={jiraIssueKeys}
                                    pipelineVars={pipelineVars}
                                    jobStepOrderOutputVars={jobStepOrderOutputVars}
                                    pipeline={pipeline}
                                    prev_state={state.data}
                                    jira_reference_key_list={getJiraRefid(pipeline)}
                                    default_job_template={state.default_job_template ? state.default_job_template : null}
                                    inherits={state.child_inherits.create_pr}
                                    get_run_conditions_function={getJobRunConditions}
                                    setParentState={setState}
                                    parentState={state}
                                    // dynamicValidationForm={state.dynamicValidationForm}
                                    dynamicValidationFormError={state.dynamicValidationFormError}
                                />
                            }
                        </div>
                        <> {
                            state.data.operation === "create" || state.data.operation === "status_update" ?
                                <>
                                    <>{
                                        state.isJiraAditionalActive == 1 &&

                                        <div className='mb-20'>
                                            <div class="bg-f8-grey border-top border-right border-left overflow-none" style={{ borderRadius: 8, overflow: 'hidden' }}>
                                                <div class="card-header with-btn" style={{ borderBottom: 'none !important' }}>
                                                    <div class="heading">Add Custom Fields</div>
                                                    <button
                                                        className="btn btn-transparent text-anchor-blue d-flex align-center"
                                                        onClick={flipAndOpenAdditionalFiledsForm}>
                                                        <AddIcon className="text-anchor-blue" />
                                                        {state.data.additional_field_key_value && state.data.additional_field_key_value.length > 0 ? "Edit or Add Custom Field" : "Add Custom Field"}
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="condition-grid border-left border-right" style={{ borderBottom: '1px solid #dedede', borderRadius: '0px 0px 4px 4px' }} >

                                                {state.data.additional_field_key_value && state.data.additional_field_key_value.length > 0 ?
                                                    <div className=''>
                                                        <p className='font-12 custion_fields '>
                                                            <span className='key'>Field Name</span>
                                                            <span>Value</span>
                                                            <span>Over-Ridable</span>
                                                            <span>Mandatory</span>
                                                        </p>

                                                        {
                                                            state.data.additional_field_key_value.map(item => (
                                                                <p className="custion_fields font-12  pd-3">
                                                                    <span className=" key">{item.key} </span>
                                                                    <span className="key">{
                                                                        item.value ?
                                                                            typeof (item.value) == "string" ?
                                                                                item.value :
                                                                                typeof (item.value) == "object" ?

                                                                                    (Array.isArray(item.value) && item.value.length > 0) ?
                                                                                        item.value.map((single_val, index) => {
                                                                                            return (index === item.value.length - 1) ? single_val : single_val + ', ';
                                                                                        })
                                                                                        : null : null : null
                                                                    }</span>
                                                                    <span className="text-grey-83 key">{item["over-ridable"] ? "true" : "false"} </span>
                                                                    <span className="val">{item["mandaroty"] ? "true" : "false"}</span>
                                                                </p>
                                                            ))
                                                        }
                                                    </div>

                                                    : null
                                                }

                                            </div>
                                        </div>

                                    }</>

                                    <>{
                                        state.isJiraAditionalActive == 0 &&
                                        <div className='mb-20'>
                                            <AdditionalFiledDialog
                                                custom_fields_key_value={state.data.additional_field_key_value}
                                                add_custom_fields={addCustomFiledsToJob} />
                                            <div className="condition-grid">
                                                {state.data.additional_field_key_value && Object.keys(state.data.additional_field_key_value).map(key => (
                                                    <>
                                                        <p className="font-12 text-grey-83 pd-3">
                                                            <span className="text-grey-83 key">{key} </span>
                                                            <span className="val">{state.data.additional_field_key_value[key]
                                                            }</span></p>
                                                    </>))}</div>
                                        </div>

                                    }
                                    </>

                                </> : null

                        }</>

<div className='job-template-dropdown input-mb-0' style={{
                            border: '1px solid #dedede',
                            marginBottom: '25px',
                            overflow: 'hidden',
                            borderRadius: '8px'
                        }}>
                            <div className={classes.conditions_div_header} style={state?.data?.is_job_template_switch_active ? { borderBottom: '1px solid #dedede' } : { borderBottom: 'none' }}>
                                <div className="heading  font-weight-500 font-14 color-value">Do you want to specify a specific job template to execute this job for all selected services</div>
                                <div className="switch-input-wrapper">
                                    <Input
                                        type="only-switch"
                                        name="is_job_template_switch_active"
                                        data={state.data}
                                        error={state.error}
                                        onChangeHandler={commonFunctions.toggleState}
                                    />
                                </div>
                            </div>
                            {
                                state?.data?.is_job_template_switch_active &&
                                <div className='pd-10'>
                                    {
                                        state.all_templates && state.all_templates.length > 0 ?
                                            <>
                                                {state.show_template_input ?
                                                    <div className='d-grid' style={{ gridTemplateColumns: '1fr 1fr', gap: '8px' }} >
                                                        <InputWithDropdownJobTemplate
                                                            jobTemplateList={state.all_templates?.filter(({ version }) => version === 'v2')}
                                                            selectedJobTemplateId={state.data.project_job_template_id}
                                                            jobTemplateChangeHandler={updateJobTemplatefn}
                                                            label={"Select Job Template"}
                                                            popupWidth="407px"
                                                        />
                                                        {
                                                            state.template_version_loading ?
                                                                <Loading varient="light" />
                                                                :
                                                                <InputWithDropdownVersions
                                                                    jobTemplateVersionsList={state.job_template_versions}
                                                                    selectedJobTemplateVersion={state.data.template_version}
                                                                    jobTemplateChangeHandler={updateJobTemplateVersionfn}
                                                                    variant="version"
                                                                    popupWidth="407px"
                                                                    label={"Select Version of the job template"} />
                                                        }
                                                    </div>
                                                    :
                                                    <div style={{ border: '1px solid #dedede' }} className='d-flex align-center space-between pd-10 mt-20 mb-20'>
                                                        <p className='font-12 ' style={{ color: '#828282' }}>
                                                            {
                                                                state?.data?.project_job_template ? state?.data?.project_job_template.name : state.default_template_name ? state.default_template_name : 'Default Job Template'
                                                            }
                                                        </p>
                                                        <IconButton className='' onClick={toggleChooseTemplateInput}>
                                                            <BorderColorIcon className="text-anchor-blue" />
                                                        </IconButton>
                                                    </div>
                                                }
                                            </>
                                            :
                                            <AlertStrip message="Please add job templates first" vairant="info" />
                                    }
                                </div>

                            }
                        </div>
                        {
                            stage_index == 0 && get_job_index == 0 && state.current_stage_jobs?.length == 0 ?
                                <></>
                                :
                                stage_index == 0 && (get_job_index > 0 || state.current_stage_jobs?.length > 0) ?
                                    <ConditionsDialog selected_conditions={state.data.task_run_condition} get_run_conditions_function={getJobRunConditions} add_conditions_function={addConditionsToJobFunction} />
                                    :
                                    <ConditionsDialog selected_conditions={state.data.task_run_condition} get_run_conditions_function={getJobRunConditions} add_conditions_function={addConditionsToJobFunction} />
                        }

                        <Grid className='mb-5' container alignItems="center">
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="on_success_exit"
                                        label="Terminate Pipeline on Success?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="is_linked_issue"
                                        label="Map Jira linked issue ref key in create flow"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="on_failure_exit"
                                        label="Terminate Pipeline on Failure?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="skip_task"
                                        label="Skip Job?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="mark_success_upon_skip"
                                        label="Mark success on skip job?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                        </Grid>
                    </>

                );
            case "11":
                return (
                    <>
                        <Input
                            type="select"
                            mandatorySign
                            name="project_id"
                            label="All Applications"
                            list={state.all_projects}
                            onChangeHandler={onChangeHandler}
                            data={state.data}
                            error={state.error} />
                        {
                            state.loading_env_data ?
                                <div >
                                    <Loading varient="light" />
                                </div>
                                :
                                <Input
                                    type="select"
                                    mandatorySign
                                    name="env"
                                    list={getListableArray(state.app_environment_list)}
                                    label="To Environment"
                                    onChangeHandler={onChangeHandler}
                                    data={state.data}
                                    error={state.error} />
                        }
                        {
                            state.service_loading ?
                                <Loading varient="light" /> : getListableArray(state.all_services).length > 0 &&
                                <div className={classes.rooter}>
                                    <Input
                                        type="checkbox"
                                        name="components"
                                        label="Services"
                                        mandatorySign
                                        varient="inner_component"
                                        data={state.data}
                                        error={state.error}
                                        list={getListableArray(state.all_services)}
                                        onChangeHandler={state.all_services ? onChangeHandlerForKeyValue : () => { }}
                                    />
                                </div>
                        }
                        {
                            state.data.task_type && state.data.task_type != '' && state.no_service_configured &&
                            <div style={{ width: '100%' }}>
                                <AlertStrip variant="p-v3-alert" message={`Services are not configured in this env. Please select a different env.`} />
                            </div>
                        }
                        {/* <Input
                            type="switch"
                            name="deployment_status_check"
                            label="Validate Rollback Success"
                            onChangeHandler={onChangeHandler}
                            error={state.error}
                            data={state.data} /> */}
                        <Input
                            type="select"
                            name="rollback_version"
                            list={[{ id: "-1", label: "-1" }, { id: "-2", label: "-2" }, { id: "-3", label: "-3" }]}
                            label="Version"
                            onChangeHandler={onChangeHandler}
                            data={state.data}
                            error={state.error} />
                         <div className='job-template-dropdown input-mb-0' style={{
                            border: '1px solid #dedede',
                            marginBottom: '25px',
                            overflow: 'hidden',
                            borderRadius: '8px'
                        }}>
                            <div className={classes.conditions_div_header} style={state?.data?.is_job_template_switch_active ? { borderBottom: '1px solid #dedede' } : { borderBottom: 'none' }}>
                                <div className="heading  font-weight-500 font-14 color-value">Do you want to specify a specific job template to execute this job for all selected services</div>
                                <div className="switch-input-wrapper">
                                    <Input
                                        type="only-switch"
                                        name="is_job_template_switch_active"
                                        data={state.data}
                                        error={state.error}
                                        onChangeHandler={commonFunctions.toggleState}
                                    />
                                </div>
                            </div>
                            {
                                state?.data?.is_job_template_switch_active &&
                                <div className='pd-10'>
                                    {
                                        state.all_templates && state.all_templates.length > 0 ?
                                            <>
                                                {state.show_template_input ?
                                                    <div className='d-grid' style={{ gridTemplateColumns: '1fr 1fr', gap: '8px' }} >
                                                        <InputWithDropdownJobTemplate
                                                            jobTemplateList={state.all_templates?.filter(({ version }) => version === 'v2')}
                                                            selectedJobTemplateId={state.data.project_job_template_id}
                                                            jobTemplateChangeHandler={updateJobTemplatefn}
                                                            label={"Select Job Template"}
                                                            popupWidth="407px"
                                                        />
                                                        {
                                                            state.template_version_loading ?
                                                                <Loading varient="light" />
                                                                :
                                                                <InputWithDropdownVersions
                                                                    jobTemplateVersionsList={state.job_template_versions}
                                                                    selectedJobTemplateVersion={state.data.template_version}
                                                                    jobTemplateChangeHandler={updateJobTemplateVersionfn}
                                                                    variant="version"
                                                                    popupWidth="407px"
                                                                    label={"Select Version of the job template"} />
                                                        }
                                                    </div>
                                                    :
                                                    <div style={{ border: '1px solid #dedede' }} className='d-flex align-center space-between pd-10 mt-20 mb-20'>
                                                        <p className='font-12 ' style={{ color: '#828282' }}>
                                                            {
                                                                state?.data?.project_job_template ? state?.data?.project_job_template.name : state.default_template_name ? state.default_template_name : 'Default Job Template'
                                                            }
                                                        </p>
                                                        <IconButton className='' onClick={toggleChooseTemplateInput}>
                                                            <BorderColorIcon className="text-anchor-blue" />
                                                        </IconButton>
                                                    </div>
                                                }
                                            </>
                                            :
                                            <AlertStrip message="Please add job templates first" vairant="info" />
                                    }
                                </div>

                            }
                        </div>
                        {
                            stage_index == 0 && get_job_index == 0 && state.current_stage_jobs?.length == 0 ?
                                <></>
                                :
                                stage_index == 0 && (get_job_index > 0 || state.current_stage_jobs?.length > 0) ?
                                    <ConditionsDialog selected_conditions={state.data.task_run_condition} get_run_conditions_function={getJobRunConditions} add_conditions_function={addConditionsToJobFunction} />
                                    :
                                    <ConditionsDialog selected_conditions={state.data.task_run_condition} get_run_conditions_function={getJobRunConditions} add_conditions_function={addConditionsToJobFunction} />
                        }

                        <Grid className='mb-5' container alignItems="center">
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="on_success_exit"
                                        label="Terminate Pipeline on Success?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="on_failure_exit"
                                        label="Terminate Pipeline on Failure?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="skip_task"
                                        label="Skip Job?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="mark_success_upon_skip"
                                        label="Mark success on skip job?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                        </Grid>
                    </>
                );
            case "12":
                return (
                    <>
                        <Input
                            type="select"
                            mandatorySign
                            name="project_id"
                            label="All Applications"
                            list={state.all_projects}
                            onChangeHandler={onChangeHandler}
                            data={state.data}
                            error={state.error} />
                        {
                            state.loading_env_data ?
                                <div >
                                    <Loading varient="light" />
                                </div>
                                :
                                <Input
                                    type="select"
                                    mandatorySign
                                    name="env"
                                    list={getListableArray(state.app_environment_list)}
                                    label="To Environment"
                                    onChangeHandler={onChangeHandler}
                                    data={state.data}
                                    error={state.error} />
                        }


                        {state.service_loading ?
                            <Loading varient="light" /> :
                            getListableArray(state.all_services).length > 0 &&
                            <div className={classes.rooter}>
                                <Input
                                    type="checkbox"
                                    name="components"
                                    label="Services"
                                    mandatorySign
                                    varient="inner_component"
                                    data={state.data}
                                    error={state.error}
                                    list={getListableArray(state.all_services)}
                                    onChangeHandler={state.all_services ? onChangeHandlerForKeyValue : () => { }}
                                />
                            </div>
                        }
                        {
                            state.data.task_type && state.data.task_type != '' && state.no_service_configured &&
                            <div style={{ width: '100%' }}>
                                <AlertStrip variant="p-v3-alert" message={`Services are not configured in this env. Please select a different env.`} />
                            </div>
                        }
                         <div className='job-template-dropdown input-mb-0' style={{
                            border: '1px solid #dedede',
                            marginBottom: '25px',
                            overflow: 'hidden',
                            borderRadius: '8px'
                        }}>
                            <div className={classes.conditions_div_header} style={state?.data?.is_job_template_switch_active ? { borderBottom: '1px solid #dedede' } : { borderBottom: 'none' }}>
                                <div className="heading  font-weight-500 font-14 color-value">Do you want to specify a specific job template to execute this job for all selected services</div>
                                <div className="switch-input-wrapper">
                                    <Input
                                        type="only-switch"
                                        name="is_job_template_switch_active"
                                        data={state.data}
                                        error={state.error}
                                        onChangeHandler={commonFunctions.toggleState}
                                    />
                                </div>
                            </div>
                            {
                                state?.data?.is_job_template_switch_active &&
                                <div className='pd-10'>
                                    {
                                        state.all_templates && state.all_templates.length > 0 ?
                                            <>
                                                {state.show_template_input ?
                                                    <div className='d-grid' style={{ gridTemplateColumns: '1fr 1fr', gap: '8px' }} >
                                                        <InputWithDropdownJobTemplate
                                                            jobTemplateList={state.all_templates?.filter(({ version }) => version === 'v2')}
                                                            selectedJobTemplateId={state.data.project_job_template_id}
                                                            jobTemplateChangeHandler={updateJobTemplatefn}
                                                            label={"Select Job Template"}
                                                            popupWidth="407px"
                                                        />
                                                        {
                                                            state.template_version_loading ?
                                                                <Loading varient="light" />
                                                                :
                                                                <InputWithDropdownVersions
                                                                    jobTemplateVersionsList={state.job_template_versions}
                                                                    selectedJobTemplateVersion={state.data.template_version}
                                                                    jobTemplateChangeHandler={updateJobTemplateVersionfn}
                                                                    variant="version"
                                                                    popupWidth="407px"
                                                                    label={"Select Version of the job template"} />
                                                        }
                                                    </div>
                                                    :
                                                    <div style={{ border: '1px solid #dedede' }} className='d-flex align-center space-between pd-10 mt-20 mb-20'>
                                                        <p className='font-12 ' style={{ color: '#828282' }}>
                                                            {
                                                                state?.data?.project_job_template ? state?.data?.project_job_template.name : state.default_template_name ? state.default_template_name : 'Default Job Template'
                                                            }
                                                        </p>
                                                        <IconButton className='' onClick={toggleChooseTemplateInput}>
                                                            <BorderColorIcon className="text-anchor-blue" />
                                                        </IconButton>
                                                    </div>
                                                }
                                            </>
                                            :
                                            <AlertStrip message="Please add job templates first" vairant="info" />
                                    }
                                </div>

                            }
                        </div>
                        {
                            stage_index == 0 && get_job_index == 0 && state.current_stage_jobs?.length == 0 ?
                                <></>
                                :
                                stage_index == 0 && (get_job_index > 0 || state.current_stage_jobs?.length > 0) ?
                                    <ConditionsDialog selected_conditions={state.data.task_run_condition} get_run_conditions_function={getJobRunConditions} add_conditions_function={addConditionsToJobFunction} />
                                    :
                                    <ConditionsDialog selected_conditions={state.data.task_run_condition} get_run_conditions_function={getJobRunConditions} add_conditions_function={addConditionsToJobFunction} />
                        }

                        <Grid className='mb-5' container alignItems="center">
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="on_success_exit"
                                        label="Terminate Pipeline on Success?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="on_failure_exit"
                                        label="Terminate Pipeline on Failure?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="skip_task"
                                        label="Skip Job?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="mark_success_upon_skip"
                                        label="Mark success on skip job?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                        </Grid>
                    </>
                );
            case "13":
                return (
                    <>
                        {state.all_pipelines.length > 0 &&
                            <Input
                                type="checkbox"
                                name="pipelines"
                                mandatorySign
                                varient="inner_component"
                                label="Pipelines"
                                list={state.all_pipelines}
                                onChangeHandler={state.all_pipelines.length > 0 ? onChangeHandlerForKeyValue : () => { }}
                                data={state.data}
                                error={state.error}
                            />
                        }

                        <div className="bg-f8-grey border-top switch-input-wrapper inner-switch-input">
                            <Input
                                type="switch"
                                name="suspend_pipeline"
                                label="Suspend Pipeline?"
                                onChangeHandler={onChangeHandler}
                                error={state.error}
                                data={state.data} />
                        </div>
                        <div className='job-template-dropdown input-mb-0' style={{
                            border: '1px solid #dedede',
                            marginBottom: '25px',
                            overflow: 'hidden',
                            borderRadius: '8px'
                        }}>
                            <div className={classes.conditions_div_header} style={state?.data?.is_job_template_switch_active ? { borderBottom: '1px solid #dedede' } : { borderBottom: 'none' }}>
                                <div className="heading  font-weight-500 font-14 color-value">Do you want to specify a specific job template to execute this job for all selected services</div>
                                <div className="switch-input-wrapper">
                                    <Input
                                        type="only-switch"
                                        name="is_job_template_switch_active"
                                        data={state.data}
                                        error={state.error}
                                        onChangeHandler={commonFunctions.toggleState}
                                    />
                                </div>
                            </div>
                            {
                                state?.data?.is_job_template_switch_active &&
                                <div className='pd-10'>
                                    {
                                        state.all_templates && state.all_templates.length > 0 ?
                                            <>
                                                {state.show_template_input ?
                                                    <div className='d-grid' style={{ gridTemplateColumns: '1fr 1fr', gap: '8px' }} >
                                                        <InputWithDropdownJobTemplate
                                                            jobTemplateList={state.all_templates?.filter(({ version }) => version === 'v2')}
                                                            selectedJobTemplateId={state.data.project_job_template_id}
                                                            jobTemplateChangeHandler={updateJobTemplatefn}
                                                            label={"Select Job Template"}
                                                            popupWidth="407px"
                                                        />
                                                        {
                                                            state.template_version_loading ?
                                                                <Loading varient="light" />
                                                                :
                                                                <InputWithDropdownVersions
                                                                    jobTemplateVersionsList={state.job_template_versions}
                                                                    selectedJobTemplateVersion={state.data.template_version}
                                                                    jobTemplateChangeHandler={updateJobTemplateVersionfn}
                                                                    variant="version"
                                                                    popupWidth="407px"
                                                                    label={"Select Version of the job template"} />
                                                        }
                                                    </div>
                                                    :
                                                    <div style={{ border: '1px solid #dedede' }} className='d-flex align-center space-between pd-10 mt-20 mb-20'>
                                                        <p className='font-12 ' style={{ color: '#828282' }}>
                                                            {
                                                                state?.data?.project_job_template ? state?.data?.project_job_template.name : state.default_template_name ? state.default_template_name : 'Default Job Template'
                                                            }
                                                        </p>
                                                        <IconButton className='' onClick={toggleChooseTemplateInput}>
                                                            <BorderColorIcon className="text-anchor-blue" />
                                                        </IconButton>
                                                    </div>
                                                }
                                            </>
                                            :
                                            <AlertStrip message="Please add job templates first" vairant="info" />
                                    }
                                </div>

                            }
                        </div>
                        {
                            stage_index == 0 && get_job_index == 0 && state.current_stage_jobs?.length == 0 ?
                                <></>
                                :
                                stage_index == 0 && (get_job_index > 0 || state.current_stage_jobs?.length > 0) ?
                                    <ConditionsDialog selected_conditions={state.data.task_run_condition} get_run_conditions_function={getJobRunConditions} add_conditions_function={addConditionsToJobFunction} />
                                    :
                                    <ConditionsDialog selected_conditions={state.data.task_run_condition} get_run_conditions_function={getJobRunConditions} add_conditions_function={addConditionsToJobFunction} />
                        }

                        <Grid className='mb-5' container alignItems="center">
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="on_success_exit"
                                        label="Terminate Pipeline on Success?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="on_failure_exit"
                                        label="Terminate Pipeline on Failure?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top"}>
                                    <Input
                                        type="switch"
                                        name="skip_task"
                                        label="Skip Job?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top"}>
                                    <Input
                                        type="switch"
                                        name="mark_success_upon_skip"
                                        label="Mark success on skip job?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                        </Grid>
                    </>
                );

            case "16":
                return (
                    <>
                        <div>
                            <Input
                                type="select"
                                name="snow_operation"
                                mandatorySign
                                list={snow_operations_list}
                                label="Snow Operations"
                                onChangeHandler={updateOperation}
                                data={state.data}
                                error={state.error}
                            />
                            {
                                state.data.snow_operation == "snow_create" &&
                                <CreateSnowTicket
                                    // jiraIssueKeys={jiraIssueKeys}
                                    // pipelineVars={pipelineVars}
                                    // jobStepOrderOutputVars={jobStepOrderOutputVars}
                                    pipeline={pipeline}
                                    prev_state={state.data}
                                    inherits={state.child_inherits.snow_create}
                                    default_job_template={state.data.project_job_template ? state.data.project_job_template.id : state.default_job_template ? state.default_job_template.id : null}
                                    // jira_id_reference_key={getJiraReferenceIdForCreateTicket()}
                                    // getIssueType={getIssueType} 
                                    snow_refrence_key={getSnowReferenceForCreateTicket()}
                                />
                            }
                            {
                                state.data.snow_operation == "snow_update_status" &&
                                <UpdateSnowTicketStatus
                                    jiraIssueKeys={jiraIssueKeys}
                                    pipelineVars={pipelineVars}
                                    jobStepOrderOutputVars={jobStepOrderOutputVars}
                                    jira_reference_key_list={getJiraRefid(pipeline)}
                                    pipeline={pipeline}
                                    prev_state={state.data}
                                    inherits={state.child_inherits.snow_update_status}
                                    default_job_template={state.data.project_job_template ? state.data.project_job_template.id : state.default_job_template ? state.default_job_template.id : null}
                                    get_run_conditions_function={getJobRunConditions}
                                    setParentState={setState}
                                    parentState={state}
                                    // dynamicValidationForm={state.dynamicValidationForm}
                                    dynamicValidationFormError={state.dynamicValidationFormError}

                                />
                            }
                            {
                                state.data.snow_operation == "snow_add_notes" &&
                                <SnowAddNotes
                                    jiraIssueKeys={jiraIssueKeys}
                                    pipelineVars={pipelineVars}
                                    jobStepOrderOutputVars={jobStepOrderOutputVars}
                                    jira_reference_key_list={getJiraRefid(pipeline)}
                                    pipeline={pipeline}
                                    prev_state={state.data}
                                    inherits={state.child_inherits.snow_add_notes}
                                    default_job_template={state.data.project_job_template ? state.data.project_job_template.id : state.default_job_template ? state.default_job_template.id : null}
                                    get_run_conditions_function={getJobRunConditions}
                                    setParentState={setState}
                                    parentState={state}
                                    // dynamicValidationForm={state.dynamicValidationForm}
                                    dynamicValidationFormError={state.dynamicValidationFormError}
                                />
                            }
                            {
                                state.data.snow_operation == "snow_update" &&
                                <UpdateSnowTicket
                                    jiraIssueKeys={jiraIssueKeys}
                                    pipelineVars={pipelineVars}
                                    jobStepOrderOutputVars={jobStepOrderOutputVars}
                                    jira_reference_key_list={getJiraRefid(pipeline)}
                                    pipeline={pipeline}
                                    prev_state={state.data}
                                    inherits={state.child_inherits.snow_update}
                                    default_job_template={state.data.project_job_template ? state.data.project_job_template.id : state.default_job_template ? state.default_job_template.id : null}
                                    get_run_conditions_function={getJobRunConditions}
                                    setParentState={setState}
                                    parentState={state}
                                    // dynamicValidationForm={state.dynamicValidationForm}
                                    dynamicValidationFormError={state.dynamicValidationFormError}

                                />
                            }
                        </div>

                        <div className='job-template-dropdown input-mb-0' style={{
                            border: '1px solid #dedede',
                            marginBottom: '25px',
                            overflow: 'hidden',
                            borderRadius: '8px'
                        }}>
                            <div className={classes.conditions_div_header} style={state?.data?.is_job_template_switch_active ? { borderBottom: '1px solid #dedede' } : { borderBottom: 'none' }}>
                                <div className="heading font-family-v1 font-weight-500 font-14 color-value">Do you want to specify a specific job template to execute this job for all selected services</div>
                                <div className="switch-input-wrapper">
                                    <Input
                                        type="only-switch"
                                        name="is_job_template_switch_active"
                                        data={state.data}
                                        error={state.error}
                                        onChangeHandler={commonFunctions.toggleState}
                                    />
                                </div>
                            </div>
                            {
                                state?.data?.is_job_template_switch_active &&
                                <div className='pd-10'>
                                    {
                                        state.all_templates && state.all_templates.length > 0 ?
                                            <>
                                                {state.show_template_input ?
                                                    <div className='d-grid' style={{ gridTemplateColumns: '1fr 1fr', gap: '8px' }} >
                                                        <InputWithDropdownJobTemplate
                                                            jobTemplateList={state.all_templates?.filter(({ version }) => version === 'v2')}
                                                            selectedJobTemplateId={state.data.project_job_template_id}
                                                            jobTemplateChangeHandler={updateJobTemplatefn}
                                                            label={"Select Job Template"}
                                                            popupWidth="407px"
                                                        />
                                                        {
                                                            state.template_version_loading ?
                                                                <Loading varient="light" />
                                                                :
                                                                <InputWithDropdownVersions
                                                                    jobTemplateVersionsList={state.job_template_versions}
                                                                    selectedJobTemplateVersion={state.data.template_version}
                                                                    jobTemplateChangeHandler={updateJobTemplateVersionfn}
                                                                    variant="version"
                                                                    popupWidth="407px"
                                                                    label={"Select Version of the job template"} />
                                                        }
                                                    </div>
                                                    :
                                                    <div style={{ border: '1px solid #dedede' }} className='d-flex align-center space-between pd-10 mt-20 mb-20'>
                                                        <p className='font-12 ' style={{ color: '#828282' }}>
                                                            {
                                                                state?.data?.project_job_template ? state?.data?.project_job_template.name : state.default_template_name ? state.default_template_name : 'Default Job Template'
                                                            }
                                                        </p>
                                                        <IconButton className='' onClick={toggleChooseTemplateInput}>
                                                            <BorderColorIcon className="text-anchor-blue" />
                                                        </IconButton>
                                                    </div>
                                                }
                                            </>
                                            :
                                            <AlertStrip message="Please add job templates first" vairant="info" />
                                    }
                                </div>

                            }
                        </div>
                        {
                            stage_index == 0 && get_job_index == 0 && state.current_stage_jobs?.length == 0 ?
                                <></>
                                :
                                <ConditionsDialog selected_conditions={state.data.task_run_condition} get_run_conditions_function={getJobRunConditions} add_conditions_function={addConditionsToJobFunction} />
                        }

                        <Grid className='mb-5' container alignItems="center">
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="on_success_exit"
                                        label="Terminate Pipeline on Success?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            {/* <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="is_linked_issue"
                                        label="Map Jira linked issue ref key in create flow"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid> */}
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="on_failure_exit"
                                        label="Terminate Pipeline on Failure?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="skip_task"
                                        label="Skip Job?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="mark_success_upon_skip"
                                        label="Mark success on skip job?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                        </Grid >
                    </>
                );
            case "17":
                return (
                    <>
                        <Input
                                type="select"
                                name="attach_operation"
                                mandatorySign
                                list={attach_operation_list}
                                label="Attach Documents Operations"
                                onChangeHandler={updateOperation}
                                data={state.data}
                                error={state.error}
                            />

                        {state.data.attach_operation == "download_documents" && 
                            <DownloadDocuments
                                jiraIssueKeys={jiraIssueKeys}
                                pipelineVars={pipelineVars}
                                jobStepOrderOutputVars={jobStepOrderOutputVars}
                                jira_reference_key_list={getJiraRefid(pipeline)}
                                pipeline={pipeline}
                                prev_state={state.data}
                                inherits={state.child_inherits.download_documents}
                                default_job_template={state.data.project_job_template ? state.data.project_job_template.id : state.default_job_template ? state.default_job_template.id : null}
                                get_run_conditions_function={getJobRunConditions}
                                setParentState={setState}
                                parentState={state}
                                //dynamicValidationForm={state.dynamicValidationForm}
                                dynamicValidationFormError={state.dynamicValidationFormError}
                            />
                        }
                        {state.data.attach_operation == "upload_documents" && 
                            <UploadDocuments
                                jiraIssueKeys={jiraIssueKeys}
                                pipelineVars={pipelineVars}
                                jobStepOrderOutputVars={jobStepOrderOutputVars}
                                jira_reference_key_list={getJiraRefid(pipeline)}
                                pipeline={pipeline}
                                prev_state={state.data}
                                inherits={state.child_inherits.upload_documents}
                                default_job_template={state.data.project_job_template ? state.data.project_job_template.id : state.default_job_template ? state.default_job_template.id : null}
                                get_run_conditions_function={getJobRunConditions}
                                setParentState={setState}
                                parentState={state}
                                //dynamicValidationForm={state.dynamicValidationForm}
                                dynamicValidationFormError={state.dynamicValidationFormError}
                            />
                        }
                        {state.data.attach_operation == "upload_release" && 
                            <UploadRelease
                                jiraIssueKeys={jiraIssueKeys}
                                pipelineVars={pipelineVars}
                                jobStepOrderOutputVars={jobStepOrderOutputVars}
                                jira_reference_key_list={getJiraRefid(pipeline)}
                                pipeline={pipeline}
                                prev_state={state.data}
                                inherits={state.child_inherits.upload_release}
                                default_job_template={state.data.project_job_template ? state.data.project_job_template.id : state.default_job_template ? state.default_job_template.id : null}
                                get_run_conditions_function={getJobRunConditions}
                                setParentState={setState}
                                parentState={state}
                                //dynamicValidationForm={state.dynamicValidationForm}
                                dynamicValidationFormError={state.dynamicValidationFormError}
                            />
                        }

                        <div className='job-template-dropdown input-mb-0' style={{
                            border: '1px solid #dedede',
                            marginBottom: '25px',
                            overflow: 'hidden',
                            borderRadius: '8px'
                        }}>
                            <div className={classes.conditions_div_header} style={state?.data?.is_job_template_switch_active ? { borderBottom: '1px solid #dedede' } : { borderBottom: 'none' }}>
                                <div className="heading font-family-v1 font-weight-500 font-14 color-value">Do you want to specify a specific job template to execute this job for all selected services</div>
                                <div className="switch-input-wrapper">
                                    <Input
                                        type="only-switch"
                                        name="is_job_template_switch_active"
                                        data={state.data}
                                        error={state.error}
                                        onChangeHandler={commonFunctions.toggleState}
                                    />
                                </div>
                            </div>
                            {
                                state?.data?.is_job_template_switch_active &&
                                <div className='pd-10'>
                                    {
                                        state.all_templates && state.all_templates.length > 0 ?
                                            <>
                                                {state.show_template_input ?
                                                    <div className='d-grid' style={{ gridTemplateColumns: '1fr 1fr', gap: '8px' }} >
                                                        <InputWithDropdownJobTemplate
                                                            jobTemplateList={state.all_templates?.filter(({ version }) => version === 'v2')}
                                                            selectedJobTemplateId={state.data.project_job_template_id}
                                                            jobTemplateChangeHandler={updateJobTemplatefn}
                                                            label={"Select Job Template"}
                                                            popupWidth="407px"
                                                        />
                                                        {
                                                            state.template_version_loading ?
                                                                <Loading varient="light" />
                                                                :
                                                                <InputWithDropdownVersions
                                                                    jobTemplateVersionsList={state.job_template_versions}
                                                                    selectedJobTemplateVersion={state.data.template_version}
                                                                    jobTemplateChangeHandler={updateJobTemplateVersionfn}
                                                                    variant="version"
                                                                    popupWidth="407px"
                                                                    label={"Select Version of the job template"} />
                                                        }
                                                    </div>
                                                    :
                                                    <div style={{ border: '1px solid #dedede' }} className='d-flex align-center space-between pd-10 mt-20 mb-20'>
                                                        <p className='font-12 ' style={{ color: '#828282' }}>
                                                            {
                                                                state?.data?.project_job_template ? state?.data?.project_job_template.name : state.default_template_name ? state.default_template_name : 'Default Job Template'
                                                            }
                                                        </p>
                                                        <IconButton className='' onClick={toggleChooseTemplateInput}>
                                                            <BorderColorIcon className="text-anchor-blue" />
                                                        </IconButton>
                                                    </div>
                                                }
                                            </>
                                            :
                                            <AlertStrip message="Please add job templates first" vairant="info" />
                                    }
                                </div>

                            }
                        </div>
                        {
                            stage_index == 0 && get_job_index == 0 && state.current_stage_jobs?.length == 0 ?
                                <></>
                                :
                                <ConditionsDialog selected_conditions={state.data.task_run_condition} get_run_conditions_function={getJobRunConditions} add_conditions_function={addConditionsToJobFunction} />
                        }

                        <Grid className='mb-5' container alignItems="center">
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="on_success_exit"
                                        label="Terminate Pipeline on Success?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            {/* <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="is_linked_issue"
                                        label="Map Jira linked issue ref key in create flow"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid> */}
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="on_failure_exit"
                                        label="Terminate Pipeline on Failure?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="skip_task"
                                        label="Skip Job?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top switch-input-wrapper inner-switch-input"}>
                                    <Input
                                        type="switch"
                                        name="mark_success_upon_skip"
                                        label="Mark success on skip job?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                        </Grid >
                    </>
                )
            default:
                return null


        }
    }
    console.log('my_break')

    const checkIsAllRequiredFilledOrMadeOverridable = (dependentJobData) => {
        console.log(dependentJobData, 'dtfggg')
        const jobData = { ...dependentJobData }
        let foundBadConfiguration = false
        Object.keys(jobData)?.forEach(stepCodeKey => {
            const stepOrderDataObj = jobData[stepCodeKey]
            Object.keys(stepOrderDataObj)?.forEach(stepOrder => {
                const stepData = stepOrderDataObj[stepOrder]

                Object.keys(stepData)?.forEach(envKey => {
                    const envData = stepData[envKey]
                    const isRequired = envData.is_required
                    const isOverridden = envData.is_overridden
                    if (isRequired && !isOverridden && (envData.value == "" || envData.value == null)) {
                        foundBadConfiguration = true
                        envData.error = {
                            [envData.key]: `Its required. either provide value or make it overridable`
                        }
                    }
                    else {
                        envData.error = {
                            [envData.key]: null
                        }
                    }
                })
            })
        })

        if (foundBadConfiguration) {
            setState(prevState => ({
                ...prevState,
                independentJobData: {
                    data: { ...jobData }
                }
            }))
            return true
        }
        return false

    }

    function validateAndSaveJobForVersion3() {
        var child_states_of_form = inherits.getState ? inherits.getState() : null;
        console.log(child_states_of_form, 'inherites_0023023eeeee');
        const result = ValidateDataSet(child_states_of_form.version_three_form.data, child_states_of_form.version_three_form.validations);
        console.log(result, 'result_00023323')
        if (!result.valid) {
            setState(new_state => ({
                ...new_state,
                version_three_form_error: result.error
            }));
        }
        else {
            console.log(child_states_of_form?.version_three_form, 'child_states_of_forms_00023');
            let version3Data = child_states_of_form.version_three_form?.data
            const runTimeParamsFormState = state?.runtimeFormState
            const job_json = state.job_json
            const isDependentJob = job_json?.is_dependent
            let job_step_json = {}
            console.log(job_json, 'job_json_00')
            console.log(state.runtimeFormState, 'runtime_swwewewew')
            version3Data.job_template_version = 'v3'
            const stage = stages[stage_index];
            stage.task = stage.task ? stage.task : [];
            delete version3Data.deployment_type
            delete version3Data.jira_key
            delete version3Data.artifact_source
            if (runTimeParamsFormState && isDependentJob) { // will write this code to maintain
                const runtimeFormState = runTimeParamsFormState?.varFormStateAccordingToStepsAndServices
                const allServicesData = child_states_of_form.version_three_form?.all_services
                const componentIdNameObject = child_states_of_form?.version_three_form?.runTimeParamsFormState?.componentIdNameObject
                const selectedServices = child_states_of_form.version_three_form.data?.components
                const entity_resource_data = selectedServices?.map(serviceId => {
                    if (componentIdNameObject[serviceId] != undefined) {
                        const serviceData = componentIdNameObject[serviceId]
                        let updatedData = { id: serviceData.id, name: serviceData.label }
                        return updatedData
                    }
                })
                let output_var_form = {}
                job_json.entity_resource_data['SERVICE'] = entity_resource_data
                let serviceIdLabelObj = {}
                console.log(runtimeFormState, 'runtime_form_state_4555')
                //creating data for backend api 
                Object.keys(runtimeFormState)?.map(serviceKey => {

                    // finding name of service 
                    let serviceLabel = allServicesData?.find(service => service.id == serviceKey)?.label
                    if (serviceKey == '00') {
                        serviceLabel = 'all_0'
                    }
                    serviceIdLabelObj[serviceKey] = serviceLabel
                    let serviceData = runtimeFormState[serviceKey]
                    // changing data for service data
                    Object.keys(serviceData)?.forEach(stepCodeKeys => {
                        // if output_var false 
                        Object.keys(serviceData[stepCodeKeys])?.forEach(envStepKey => {
                            if (serviceData[stepCodeKeys][envStepKey]['is_value_from_output_var'] == false) {
                                let finalValue = serviceData[stepCodeKeys][envStepKey][envStepKey]
                                serviceData[stepCodeKeys][envStepKey] = {
                                    is_value_from_output_var: false,
                                    value: finalValue
                                }
                            }
                            else {
                                let outputVarValues = serviceData[stepCodeKeys][envStepKey]?.outputForm
                                let stage = outputVarValues?.stage
                                let task = outputVarValues?.previous_job
                                let service = serviceLabel
                                let outputVariable = outputVarValues?.output_var
                                let stepCode = outputVarValues?.step
                                let stepOrder = outputVarValues.step_order
                                //"value":"stage.16.task.18.service3.BUILD_DOCKER_IMAGE.output_var_1.step_order2",
                                // creating value path 
                                let value = `stage.${stage}.task.${task}.${service}.${stepCode}.${outputVariable}.${stepOrder}`
                                serviceData[stepCodeKeys][envStepKey] = {
                                    is_value_from_output_var: true,
                                    value: value
                                }
                            }
                        })

                    })

                })
                console.log(serviceIdLabelObj, 'rse3232323')
                Object.keys(runtimeFormState)?.forEach(serviceKeys => {
                    const dataOfService = runtimeFormState[serviceKeys]
                    const serviceLabel = serviceIdLabelObj[serviceKeys]
                    if (serviceLabel)
                        output_var_form[serviceLabel] = dataOfService
                })

                console.log('d233342434343', output_var_form)
                version3Data.output_variable_mapping = output_var_form
                // setting data for runtime screen on runtime params---> 

                if (version3Data) {
                    Object.keys(output_var_form)?.forEach((serviceName) => {
                        console.log(allServicesData, serviceName, 'ggg_0ds')
                        if (serviceName !== 'all_0') {
                            let serviceId = allServicesData?.find(service => service.label == serviceName)?.id
                            let serviceData = output_var_form[serviceName]
                            let serviceObj = {}
                            Object.keys(serviceData)?.forEach(stepKey => {
                                const stepData = serviceData[stepKey]
                                const stepSplitArray = stepKey.split('_')
                                const stepOrder = stepSplitArray[stepSplitArray?.length - 1]
                                stepSplitArray.splice(stepSplitArray?.length - 1, 1)
                                let stepName = stepSplitArray.join('_');
                                let arrayOfEnvVarData = Object.keys(stepData)?.map(envVarKey => {
                                    let envData = stepData[envVarKey]
                                    const envObject = {
                                        key: envVarKey,
                                        value: envData?.value,
                                        is_value_from_output_var: envData?.is_value_from_output_var,
                                        is_overridden: true
                                    }
                                    return envObject
                                })
                                if (serviceObj[stepName] != undefined) {
                                    serviceObj[stepName] = { ...serviceObj[stepName], [stepOrder]: arrayOfEnvVarData }
                                }
                                else {
                                    serviceObj[stepName] = { [stepOrder]: arrayOfEnvVarData }
                                }
                            })
                            job_step_json[serviceId] = serviceObj

                        }
                    })
                }

            }
            if (!isDependentJob) {
                // will have data for not dependent job
                job_step_json = {}
                console.log(state.independentJobData.data, 'data_here_for_finsssdss')
                const hasError = checkIsAllRequiredFilledOrMadeOverridable(state.independentJobData.data)
                if (!hasError)
                    Object.keys(state.independentJobData.data)?.forEach(stepCodeKey => {
                        job_step_json[stepCodeKey] = {}
                        const stepOrderObject = state.independentJobData.data[stepCodeKey]

                        Object.keys(stepOrderObject)?.forEach(stepOrder => {
                            const stepDataKeys = stepOrderObject[stepOrder]
                            const envVarDataArray = Object.keys(stepDataKeys)?.map(envVarKey => {
                                const stepData = stepDataKeys[envVarKey]
                                return stepData
                            })
                            job_step_json[stepCodeKey][stepOrder] = envVarDataArray
                        })
                    })
                else
                    return false
            }

            console.log(job_step_json, 'job_jd_res')
            version3Data.job_json = job_json
            version3Data.job_step_json = job_step_json
            if (varient == EDIT_JOB) {
                if (job_index || job_index == 0) {
                    stage.task[job_index] = version3Data;
                } else {
                    stage.task.push(version3Data);
                }
            } else {
                if (job_index) {
                    stage.task[job_index] = version3Data;
                } else {
                    stage.task.push(version3Data);
                }
            }
            console.log(version3Data, 'data_for_saving_001==>');

            console.log('stage==>_v3_sdsdsd', stage, 'stage_index==>', stage_index);
            addOrUpdateStage(stage, stage_index);
        }

    }

    const getValidDynamicFeildData = (dynamicObj) => {
        let updatedDynamicFields = {}
        if (dynamicObj) {
            Object.keys(dynamicObj)?.forEach((field) => {
                const fieldData = dynamicObj[field]
                if (fieldData.source && fieldData.value) {
                    updatedDynamicFields[field] = fieldData
                }
            })
        }
        return updatedDynamicFields
    }

    function validateAndSaveJob() {
        var child_states_of_form = inherits.getState ? inherits.getState() : null;

        console.log(child_states_of_form, 'child_states_of_forms_00023');

        const stage = stages[stage_index];
        stage.task = stage.task ? stage.task : [];

        var data = { ...state.data };

        if(state.data.task_type == 1 || state.data.task_type == 2 || state.data.task_type == 3 || state.data.task_type == 11 ){
            if(state.data.is_job_template_switch_active){
                if( state.data.project_job_template_id ){
                 data['project_job_template'] = null;
                 data['project_job_template_id'] = state.data.project_job_template_id 
                 data['template_version'] = null
                }else{
                 let find_default_job_template_id_by_name  = state.all_templates.find(item => item.name == state.default_template_name)
                 data['project_job_template'] = null;
                 data['project_job_template_id'] = find_default_job_template_id_by_name.id
                 data['template_version'] = null
                }
             }else{
                 data['project_job_template'] = null;
                 data['project_job_template_id'] = null
                 data['template_version'] = null
             }
        }else{
            if(state.data.is_job_template_switch_active){
                if( state.data.project_job_template_id ){
                 data['project_job_template'] = null;
                 data['project_job_template_id'] = state.data.project_job_template_id 
                 data['template_version'] = null
                }else{
                 let find_default_job_template_id_by_name  = state.all_templates.find(item => item.name == state.default_template_name)
                 data['project_job_template'] = null;
                 data['project_job_template_id'] = find_default_job_template_id_by_name.id
                 data['template_version'] = null
                }
             }else{
                let find_default_job_template_id_by_name  = state.all_templates.find(item => item.name == state.default_template_name)
                 data['project_job_template'] = null;
                 data['project_job_template_id'] = find_default_job_template_id_by_name.id
                 data['template_version'] = null
             }
        }
        data.components = data.components ? data.components.length > 0 ? data.components : null : null;
        var validation_result = { valid: true }
        var validations = { ...state.validations };

        if (data.task_type) {
            if (data.task_type != 16) {
                RemoveFromArray(validations.snow_operation, VALIDATION_TYPE_REQUIRED);
            }
            if (data.task_type != 10 && data.task_type != 16) {
                RemoveFromArray(validations.operation, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(validations.summary, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(validations.issue_key, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(validations.jira_status, VALIDATION_TYPE_REQUIRED);
            }
            if (data.task_type != "1" && data.artifact_source == "TAG") {
                if (!validations.tag.includes(VALIDATION_TYPE_REQUIRED)) {
                    validations.tag.push(VALIDATION_TYPE_REQUIRED);
                }
            } else {
                validations.tag = [];
                if (data.task_type != "3") {
                    validations.target_env = [];
                }
                if (data.task_type != "1") {
                    if (data.task_type != "13") {
                        data.tag = data.artifact_source.toLowerCase();
                    }

                    delete data.docker_cache;
                }

            }
            if (data.task_type != "1" && data.task_type != "5") {
                if (!validations.artifact_source.includes(VALIDATION_TYPE_REQUIRED)) {
                    validations.artifact_source.push(VALIDATION_TYPE_REQUIRED);
                }
            }
            if (data.task_type == "3") {
                if (!validations.target_env.includes(VALIDATION_TYPE_REQUIRED)) {
                    validations.target_env.push(VALIDATION_TYPE_REQUIRED);
                }
            }

            if (data.task_type == "5") {
                if (!validations.url.includes(VALIDATION_TYPE_REQUIRED)) {
                    validations.url.push(VALIDATION_TYPE_REQUIRED);
                }

                if (!validations.method.includes(VALIDATION_TYPE_REQUIRED)) {
                    validations.method.push(VALIDATION_TYPE_REQUIRED);
                }

                if (!validations.request_timeout.includes(VALIDATION_TYPE_REQUIRED)) {
                    validations.request_timeout.push(VALIDATION_TYPE_REQUIRED);
                }

                RemoveFromArray(validations.components, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(validations.env, VALIDATION_TYPE_REQUIRED);
            }

            if (data.task_type == 12) {
                RemoveFromArray(validations.artifact_source, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(validations.tag, VALIDATION_TYPE_REQUIRED);

            }
            if (data.task_type == 1) {
                if (Number(data.project_ci_scope_id)) {
                    data.project_ci_scope_id = Number(data.project_ci_scope_id);
                } else {
                    delete data.project_ci_scope_id;
                }
            }
            if (data.task_type == 1) {
                if (Number(data.project_image_scan_scope_id)) {
                    data.project_image_scan_scope_id = Number(data.project_image_scan_scope_id);
                } else {
                    delete data.project_image_scan_scope_id;
                }
            }
            if (data.task_type != "2") {
                delete data.deployment_status_check
            }
            if (data.task_type == "13") {
                RemoveFromArray(validations.artifact_source, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(validations.tag, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(validations.components, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(validations.env, VALIDATION_TYPE_REQUIRED);
                data.components = [];
                delete data.env;
                delete data.project_env_id;
                delete data.tag;
                delete data.artifact_source;
            }
            if (data.task_type == 10) {
                RemoveFromArray(validations.artifact_source, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(validations.components, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(validations.env, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(state.validations.tag, VALIDATION_TYPE_REQUIRED);

                RemoveFromArray(state.validations.pipelines, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(state.validations.issue_key, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(state.validations.summary, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(state.validations.jira_status, VALIDATION_TYPE_REQUIRED);
                validation_result = inherits.validateForm ? inherits.validateForm() ? inherits.validateForm() : { valid: true } : { valid: true };
                var child_states = inherits.getState ? inherits.getState() ? inherits.getState() : null : null;
                if (validation_result.valid) {

                    var current_jira_operation = state.data.operation;
                    var jira_operation_data_obj = child_states[current_jira_operation];
                    if (jira_operation_data_obj != "create" && jira_operation_data_obj?.data?.jira_key == "primary_key") {
                        jira_operation_data_obj.data.summary = null;
                    }
                    if (jira_operation_data_obj != "create" && jira_operation_data_obj?.data?.jira_key == "OTHER") {
                        data.primary_key = null;
                        jira_operation_data_obj.data.summary = null;
                    }
                    if (jira_operation_data_obj == "create" && jira_operation_data_obj?.data?.issue_name == "") {
                        jira_operation_data_obj.data.issue_name = null;

                    }
                    console.log(jira_operation_data_obj, 'create_for_mind')

                    const dynamic_fields = jira_operation_data_obj?.dynamic_fields;
                    let updatedDynamicFields = getValidDynamicFeildData(dynamic_fields)
                    data.dynamic_fields = updatedDynamicFields
                    console.log(updatedDynamicFields, 'ghbnmmd')
                    if (jira_operation_data_obj?.linked_issue_list) {
                        if (jira_operation_data_obj.linked_issue_list.issue_link &&
                            jira_operation_data_obj.linked_issue_list.issue_link.length > 0) {
                            data = {
                                ...data,
                                ...jira_operation_data_obj.data,
                                issue_name: jira_operation_data_obj.data.issue_name != "" && jira_operation_data_obj.data.issue_name != null ?
                                    jira_operation_data_obj.data.issue_name : null,
                                issue_link: jira_operation_data_obj.linked_issue_list.issue_link
                            }
                        } else {
                            data = {
                                ...data,
                                ...jira_operation_data_obj.data,
                                issue_name: jira_operation_data_obj.data.issue_name != "" && jira_operation_data_obj.data.issue_name != null ?
                                    jira_operation_data_obj.data.issue_name : null,
                            }
                        }
                    } else {
                        data = {
                            ...data, ...jira_operation_data_obj?.data,
                            issue_name: jira_operation_data_obj?.data?.issue_name != "" && jira_operation_data_obj?.data?.issue_name != null ?
                                jira_operation_data_obj?.data?.issue_name : null,
                        }
                    }


                }

            }

            if (data.task_type == 16) {
                console.log("sjabjbc", validations, data);
                RemoveFromArray(validations.artifact_source, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(validations.components, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(validations.env, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(validations.summary, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(validations.issue_key, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(validations.operation, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(validations.pipelines, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(validations.jira_status, VALIDATION_TYPE_REQUIRED);

                RemoveFromArray(state.validations.tag, VALIDATION_TYPE_REQUIRED);

                validation_result = inherits.validateForm ? inherits.validateForm() ? inherits.validateForm() : { valid: true } : { valid: true };
                var child_states = inherits.getState ? inherits.getState() ? inherits.getState() : null : null;

                console.log("bjdsbjsb", validation_result);
                if (validation_result.valid) {
                    let current_snow_operation = state.data.snow_operation;
                    let snow_operation_data_obj = child_states[current_snow_operation];
                    const dynamic_fields = snow_operation_data_obj?.dynamic_fields;
                    console.log("esdjxnsjsd", dynamic_fields);
                    let updatedDynamicFields = getValidDynamicFeildData(dynamic_fields)
                    data.dynamic_fields = updatedDynamicFields
                    console.log("bjdsbjsb", snow_operation_data_obj, updatedDynamicFields);
                    data = {
                        ...data,
                        ...snow_operation_data_obj.data,
                    }
                }
            }
            if(data.task_type == 17){
                console.log("sjabjbc", validations, data);
                console.log("sjabjbc", validations, data);
                RemoveFromArray(validations.artifact_source, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(validations.components, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(validations.env, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(validations.summary, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(validations.issue_key, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(validations.operation, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(validations.pipelines, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(validations.jira_status, VALIDATION_TYPE_REQUIRED);

                RemoveFromArray(state.validations.tag, VALIDATION_TYPE_REQUIRED);

                validation_result = inherits.validateForm ? inherits.validateForm() ? inherits.validateForm() : { valid: true } : { valid: true };
                var child_states = inherits.getState ? inherits.getState() ? inherits.getState() : null : null;
                console.log("bjdsbjsb", validation_result);
                if(validation_result.valid){
                    const attachOperation = state.data.attach_operation;
                    let download_document_data_obj = child_states[attachOperation];
                    const dynamic_fields = download_document_data_obj?.dynamic_fields;
                    let updatedDynamicFields = getValidDynamicFeildData(dynamic_fields)
                    data.dynamic_fields = updatedDynamicFields;
                    console.log("jkjdkajka",download_document_data_obj,updatedDynamicFields);
                    data = {
                        ...data,
                        ...download_document_data_obj.data,
                    }
                }
            }
        }
        if (data.task_type != 13) {
            RemoveFromArray(validations.pipelines, VALIDATION_TYPE_REQUIRED)
        }
        if (data.task_type == 11) {
            RemoveFromArray(validations.artifact_source, VALIDATION_TYPE_REQUIRED);
            RemoveFromArray(validations.tag, VALIDATION_TYPE_REQUIRED);
            delete data.tag;
        }
        if (data.task_type == 12) {
            RemoveFromArray(validations.artifact_source, VALIDATION_TYPE_REQUIRED);
            RemoveFromArray(validations.tag, VALIDATION_TYPE_REQUIRED);
            delete data.tag;
        }
        if (data.task_type == 6) {
            RemoveFromArray(validations.artifact_source, VALIDATION_TYPE_REQUIRED);
            RemoveFromArray(validations.components, VALIDATION_TYPE_REQUIRED);
            RemoveFromArray(validations.tag, VALIDATION_TYPE_REQUIRED);
            delete data.tag;
            delete data.artifact_source;
            data.components = [];
        }
        if (data.task_type == 7) {
            RemoveFromArray(validations.artifact_source, VALIDATION_TYPE_REQUIRED);
            RemoveFromArray(validations.components, VALIDATION_TYPE_REQUIRED);
            RemoveFromArray(validations.tag, VALIDATION_TYPE_REQUIRED);
            delete data.tag;
            delete data.artifact_source;
            data.components = [];
        }
        if (data.task_type != 10) {
            validation_result = ValidateDataSet(data, validations);
        }

        if (validation_result.valid) {
            if (varient == EDIT_JOB) {
                if (job_index || job_index == 0) {
                    stage.task[job_index] = data;
                } else {
                    stage.task.push(data);
                }
            } else {
                if (job_index) {
                    stage.task[job_index] = data;
                } else {
                    stage.task.push(data);
                }
            }

            console.log('stage==>_v2_2323', stage, 'stage_index==>', stage_index);
            addOrUpdateStage(stage, stage_index);

        } else {
            if (data.task_type != 10) {
                setState({
                    ...state,
                    error: validation_result.error,
                });
            }
        }

    }
    const addConditionsToJobFunction = (conditions) => {
        if (conditions && conditions.length > 0) {

            let all_conditions_obj = {};

            for (const item of conditions) {
                all_conditions_obj[item.condition_key] = item.condition_value;
            }
            console.log(all_conditions_obj, "conditions___1")
            setState(new_state => ({
                ...new_state,
                data: {
                    ...new_state.data,
                    task_run_condition: all_conditions_obj
                }
            }));
        } else {
            setState(new_state => ({
                ...new_state,
                data: {
                    ...new_state.data,
                    task_run_condition: {}
                }
            }));
        }

    }

    const addCustomFiledsToJob = (custom_field) => {
        console.log(custom_field, "fsfdsafsafa___1");
        var updateData = custom_field.sort((a, b) => (a.key > b.key ? 1 : -1));
        console.log(updateData, "fsfdsafsafa___1");
        setState(new_state => ({
            ...new_state,
            data: { ...new_state.data, additional_field_key_value: custom_field }
        }));
    }
    const addCustomFiledsToJobAdvanceJira = (custom_field) => {
        console.log(custom_field, "fsfdsafsafa___1");
        var updateData = custom_field.sort((a, b) => (a.key > b.key ? 1 : -1));
        console.log(updateData, "fsfdsafsafa___1");
        setState(new_state => ({
            ...new_state,
            data: { ...new_state.data, additional_field_key_value: custom_field }
        }));
    }
    const toggleChooseTemplateInput = () => {
        if (state?.data?.project_job_template_id) {
            fetchJobTemplateVersions(state.data.project_job_template_id)
        }
        setState(new_state => ({
            ...new_state,
            show_template_input: !state.show_template_input
        }));
    }
    const template_option = [
        { label: "Job Template", value: 'v2' },
        { label: "Job Template", value: 'v3' }
    ]
    console.log(state.all_templates, 'template_list_001');
    const flipAndOpenAdditionalFiledsForm = () => {

        console.log(state, 'outer_form_022323231');
        console.log(state, state.all_templates, 'template_list_001');

        setFlipState(true);
    }
    const flipCloseAndRetainState = () => {
        setFlipState(false);
    }
    console.log(state.jira_data_types, "state.jira_data_types")
    return (
        <>
            <div style={{ display: 'block' }} className="fill-details-card">
                <div className="fill-details-head d-flex align-center space-between"
                    style={{ backgroundColor: 'rgb(18, 77, 155)', padding: 14 }}>
                    {
                        varient == EDIT_JOB ?
                            <p className='color-white'>Job-{job_index + 1} of {stages[stage_index].name}</p> :
                            <p className='color-white'>Add Job for {stages[stage_index].name}</p>
                    }
                    <IconButton edge="start" color="inherit" onClick={props.handleClickCloseDialog} aria-label="close">
                        <CloseIcon className="color-white" fontSize="large" />
                    </IconButton>
                </div>

                <div style={{ padding: '20px', backgroundColor: '#ededed', minHeight: 'calc(100% - 120px)' }}>
                    <div className={classes.borderRadius} style={flipState ? { display: 'none' } : null} >
                        <div style={{ backgroundColor: '#fff', borderTopLeftRadius: 'inherit', borderTopRightRadius: 'inherit' }}>
                            <div className={`d-flex align-center border-bottom`} style={{ height: '50px', backgroundColor: '#fafafa', padding: '15px', borderTopLeftRadius: 'inherit', borderTopRightRadius: 'inherit' }}>
                                <label style={{ color: '#6d6a6a' }} className='font-14 mr-5'>Add jobs from Job Template</label>
                                <VersionLogo version={pipelineVersion}>{pipelineVersion}</VersionLogo>
                            </div>
                            <div style={{ padding: '15px', minHeight: '400px' }}>
                                {
                                    state.loading_data ?
                                        <div className='' style={{ height: '80vh' }}>
                                            <Loading varient="light" />
                                        </div>
                                        : state.error_msg ?
                                            <AlertStrip message={state.error_msg} variant="error" />
                                            :
                                            <>
                                                {
                                                    pipelineVersion === 'v3' ?

                                                        <AddVersionThreeJobs
                                                            independentJobData={state.independentJobData}
                                                            setParentState={setState}
                                                            jobJsonData={state.job_json}
                                                            editFlowJobStepJson={data}
                                                            setJobJson={setJobJson}
                                                            onSaveParamsForm={setRunTimeParamFormState}
                                                            stages={stages}
                                                            onlyIncreaseResetCount={onlyIncreaseResetCount}
                                                            editRuntimeFormState={state.runtimeFormState}
                                                            editResetCount={state.editResetCount}
                                                            stage_index={stage_index}
                                                            job_index={job_index}
                                                            validateAndSaveJobForVersion3={validateAndSaveJobForVersion3}
                                                            hideBottomSaveContainer={hideBottomSaveContainer}
                                                            classes={classes}
                                                            prevState={state.version_three_form_data}
                                                            job_templates={state.all_templates}
                                                            prev_state={state.data}
                                                            env_list={state.app_environment_list}
                                                            errors={state.version_three_form_error}
                                                            inherits={state.child_inherits.version_three_form}
                                                        />
                                                        :
                                                        <>
                                                            <Input
                                                                type="select"
                                                                mandatorySign
                                                                name="task_type"
                                                                label="Job Type"
                                                                list={task_type_list}
                                                                onChangeHandler={onChangeHandler}
                                                                data={state.data}
                                                                error={state.error} />


                                                            {
                                                                getRequestedFormInputs()
                                                            }

                                                        </>
                                                }

                                            </>
                                }
                            </div>
                        </div>

                    </div>
                    {
                        !state.isRunTimeParamsFormActive &&
                        <div style={{ widht: '100%', borderBottomLeftRadius: 'inherit', borderBottomRightRadius: 'inherit' }} className="fill-details-footer border-top">
                            <div className="btn btn-add" onClick={
                                pipelineVersion === 'v3' ?
                                    validateAndSaveJobForVersion3 :
                                    validateAndSaveJob
                            } >{varient == EDIT_JOB ? "UPDATE" : "ADD"}</div>
                        </div>
                    }
                </div>

                <div>{
                    flipState ?
                        <>
                            < AdditionalFieldsDialogAdvance
                                custom_fields_key_value={state.data.additional_field_key_value}
                                add_custom_fields={addCustomFiledsToJobAdvanceJira}
                                showAdditionalField={flipState}
                                default_job_template={state.default_job_template ? state.default_job_template : null}
                                flipAndOpenAdditionalFiledsForm={flipAndOpenAdditionalFiledsForm}
                                flipCloseAndRetainState={flipCloseAndRetainState}
                                issueType={issueType}
                                all_templates={state.all_templates}
                                handleClose={flipCloseAndRetainState}
                                jira_data_types={state.jira_data_types && state.jira_data_types.length > 0 ? state.jira_data_types : []}
                                job_template_id={state.data['project_job_template_id']}
                            />
                        </> : null

                }
                </div>

            </div>
        </>
    );
}

function getServicesList(services, selected_service_ids) {
    var serviceList = [];
    if (services && services.length != 0) {
        services.forEach(service => {
            if (selected_service_ids.includes(service.id)) {
                serviceList.push({
                    id: service.id,
                    label: service.name
                });
            }
        });
    }

    return serviceList;
}

//see where it is calling api and change api name from ci to ci_all
export function getCiList(ci_list) {
    const new_ci_list = [];
    ci_list.forEach(ci => {
        new_ci_list.push({
            id: ci.id,
            label: ci.name
        })
    });
    return new_ci_list;
}


export function getNameinIdList(ci_list) {
    const new_ci_list = [];
    ci_list.forEach(ci => {
        new_ci_list.push({
            id: ci.name,
            label: ci.name
        })
    });
    return new_ci_list;
}


const useStylesDialog = makeStyles(() => ({

    borderRadius: {
        borderRadius: '5px'
    },

    searchBar: {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '1fr 60px',
        border: '1px solid #b7b7b7',
        marginBottom: '15px',
        borderRadius: '4px',
        '& .input-component': {
            marginBottom: '0!important',
            borderRight: '1px solid #b7b7b7',
            '& input': {
                border: 'none',
                '&:focus': {
                    outline: 'none',
                    border: '0px!important'
                },
                '&:disabled': {
                    cursor: 'not-allowed'
                }
            }
        },
        '& .btn': {
            marginRight: 0,
            padding: '.3rem 1rem'
        }
    },
    card_shadow: {
        boxShadow: ' 0 3px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.1)',
        color: '#6e6e6e',
        borderRadius: '25px',
        fontSize: '13px',
        width: '450px',
        marginTop: '25px',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        border: 'none',
        "& .card-header": {
            width: '100%',
            borderRadius: '25px',
            border: 'none!important',
        },
    },
    conditions_div_header: {
        backgroundColor: "#fafafa",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '50px',
        borderBottom: '1px solid #dedede',
        padding: '10px',
        '& .heading': {
            lineHeight: '1.5',
            fontSize: '13px',
            color: '#000'
        },
    },

    condition_block: {
        display: 'flex', alignItems: 'center', borderBottom: "1px solid #a09e9e", borderTop: '1px solid #a09e9e', padding: "8px", backgroundColor: '#f4f4f4', justifyContent: 'space-between',
    }
}));


