import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InvokeApi from "../../../../util/apiInvoker";
import GenerateURL from "../../../../util/APIUrlProvider";
import properties from "../../../../properties/properties";

import Editor from '@monaco-editor/react';
import { Loading } from "../../../utils/Loading";
import CommonHorizontalTab from "../../../../components/genericComponents/CommonHorizontalTab";
import CheckIcon from '@material-ui/icons/Check';

const ManifestView = (props) => {
    const classes = useStyles();
    var project_env_id = props.extraProps.project_env_id;
    var service_name = props.extraProps.service_name;
    var deployment_name = props.extraProps.selected_deployment_name;
    console.log(props.extraProps,"jfajdjksa")
    const YAML = require('json-to-pretty-yaml');
    const [state, setState] = useState({
        data: {},
        error: {},
    })

    useEffect(() => {
        fetchServiceManifests();
    }, [project_env_id,deployment_name]);

    console.log(props.extraProps,"rtydfvjagfd ============>")

    function fetchServiceManifests(){
        var requestInfo = {
            endPoint: GenerateURL({component_env_id: project_env_id}, properties.api.manifest_view_list),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        requestInfo.endPoint = requestInfo.endPoint + `?entity_type=${'service'}&entity_name=${service_name}&deployment_name=${deployment_name}`
        InvokeApi(requestInfo, ManifestInfoFetchSuccess, ManifestInfoFetchFailure);
        setState(new_state => ({
            ...new_state,
            data_loading: "LOADING",
        }));
    }

    function ManifestInfoFetchSuccess(response){
        var manifest_file_names = getManifestFileNames(response);
        var selectedTab = manifest_file_names.length > 0 ? manifest_file_names[0].order : null
        setState(new_state => ({
            ...new_state,
            manifests: response,
            manifest_file_names : manifest_file_names,
            data_loading: "SUCCESS",
            selectedManifest: selectedTab
        }))
    }

    

    function getManifestFileNames(response){
        var manifest_file_data = [];
        if(response){
                Object.entries(response).forEach((manifest, key) => {
                    manifest_file_data.push(getManifestTabElement(manifest,key))
               
                })            
        }
        
        manifest_file_data = RemoveNullManifests(manifest_file_data)
        return manifest_file_data
    }

    function RemoveNullManifests(manifests){
        var manifests_having_data = [];
        manifests.map(manifest => {
            var obj_length = Object.keys(manifest.value).length;
            if(obj_length != 0 && manifest.value != null  ){
                manifests_having_data.push(manifest)
            }
        })
        return manifests_having_data
    }

    function getManifestTabElement(manifest,key){
        
        if(typeof(manifest[1]) != Array )
            var manifest_tab_element= {
                order: key,
                tabName: <span className=""><CheckIcon className="color-success mr-5 v-align-sub" />{manifest[0]}</span>,
                value: manifest[1] 
            }
        return manifest_tab_element
       
       
    }

    function ManifestInfoFetchFailure(error){
        setState(new_state => ({
            data_loading: "ERROR",
        }))
    }

    const updateSelectedManifest = (selectedManifest) => {
        setState(new_state => ({
            ...new_state,
            selectedManifest: selectedManifest,

        }));
    }

    function getManifestFileData(tab){
        var data = null;
        state.manifest_file_names.map(manifest_file => {
            if(manifest_file.order == tab ){
                data =  manifest_file.value
            }
        })
        return data
    }

    return(
        <>
        {
            state.data_loading === 'LOADING' ?  <div className="light-loading-container"><Loading varient="light" /></div> :
            <div className={classes.root}>
            <div className="card-body">
                {
                    state.manifests === undefined ? 
                    <div className="main-container-error" style={{ height: "30vh", margin: 'auto' }}>
                            <div className="svg">
                                <div className="servicePanel">
                                    <div className="blank-div">
                                        <div className="blank-div-text">No Manifest File has been generated.</div>
                                    </div>
                                </div>
                            </div>
                    </div> : 
                    <div className="d-grid template-aside-article" >
                    <div className="aside-panel manifest-name-list">
                        <div className="pods-list">
                            <div className="side-panel-pods">
                                <CommonHorizontalTab
                                    tabList={state.manifest_file_names}
                                    selectedTabOrder={state.selectedManifest}
                                    variant = "vertical_tab"
                                    updateSelectedTab={updateSelectedManifest}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="article-panel">
                            {
                                <div className="right-panel-pod-details ">
                                    < Editor
                                        height="460px"
                                        width="100%"
                                        language="yaml"
                                        value={
                                            YAML.stringify(getManifestFileData(state.selectedManifest))
                                        }
                                    />
                                </div>
                            }
                    </div>
                </div>
                }
                                   
            </div>
        </div>
        }
        </>
    )
}

ManifestView.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default ManifestView;


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: '0px 0px 0px 0px',
        '& .manifest-name-list':{
            height: '460px' ,
            overflowY: 'scroll', 
            backgroundColor: '#fcfcfc'
        },
        '& .inner-card-body':{
                height: 450,
                overflowY:'scroll'
        },
        '& .pods-list': {
            backgroundColor: '#fcfcfc',
            borderRight: '1px solid #dedede',

        },
        '& .header-manifest':{
            margin: '0px 32px 0px 16px',
        },
        '& .manifest':{
            color: '#004eff',
            fontWeight: 'bold'
        },
        '& .yamlName1':{
            color: '#8b969c'
        },
        '& .yamlName':{
            fontSize: 8,
            backgroundColor: '#fcfcfc'
        },
        '& .monaco-editor .margin':{
            backgroundColor: '#e4e4e4',
        },
        '& .template-aside-article':{
            gridTemplateColumns: '23% 1fr'
        },
        '& .side-panel-pods': {
            '& .headerul': {
                display: 'block',
                '& li': {
                    borderBottom: '1px solid #dedede',
                    fontSize: '12px',
                    padding: 12,
                    textTransform: 'lowercase',
                    '&:last-child': {
                        borderBottom: 'none',
                    }
                }
            },
        },
    },
    
}))