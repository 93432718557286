import { makeStyles } from '@material-ui/core'
import React, { memo, useEffect, useRef, useState } from 'react'
import properties from '../../../../properties/properties';
import GenericSkeleton from '../../../../components/genericComponents/Skeletons/GenericSkeleton';
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import { Input } from '../../../../components/genericComponents/Input';

const BranchBlock = ({ service, addServiceData,selectedMasterEnv }) => {
    const classes = styles();
    const [hoveredBranch, setHoveredBranch] = useState(null); 
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [branchList, setBranchList] = useState([]);
    const [error, setError] = useState(null);
    const serviceName = service.component_name;
    const serviceBranch = service.branch;
    const serviceRepoId = service.repo_id;

    const loadComponentBranches = () => {
        let requestInfo = {
            endPoint: GenerateURL({}, properties.api.load_component_branches + `?component_env_id=${service.component_env_id}`),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        setLoading(true);
        InvokeApi(requestInfo, loadComponentBranchesSuccess, loadComponentBranchesFailed);
    }

    const loadComponentBranchesSuccess = (response) => {
        setLoading(false);
        let branchList = response.branch_list.map(item => {
            return { id: item, label: item };
        });
        setData((prevData) => ({
            ...prevData,
            default_branch: false,
            repo_id:  serviceRepoId ? serviceRepoId : response.repo_id,
            branch: serviceBranch ? serviceBranch :response.default_branch,
        }));
        setError((error) => ({
            ...error,
            branch: ''
        }));
        setBranchList(branchList);
    }

    const loadComponentBranchesFailed = (error) => {
        const error_msg = error.toString();
        setError((error) => ({
            ...error,
            mainError: error_msg,
        }));
        setLoading(false);
    }

    const onKeyValueHandler = (k, v) => {
        setData((prevData) => ({
            ...prevData,
            [k]: v,
            default_branch: false,
        }));
    }

    useEffect(() => {
        addServiceData(service, data);
    }, [data]);

    useEffect(()=>{
        setData({})
        setBranchList([]);
        setError(null);
    },[selectedMasterEnv])
    useEffect(()=>{
        if(service.branch && service.repo_id){
            setData((prevData)=>({
                ...prevData,
                branch:service.branch,
                repo_id: service.repo_id,
            }))
        }
    },[service.branch])


    console.log("sdshdjshjds",data,service);
    return (
        <div className='d-flex align-center' style={{ gap: "10px" }}
            onMouseEnter={() => setHoveredBranch(true)}
            onMouseLeave={() => setHoveredBranch(false)}
        >   
            {error && error.mainError ? 
                <div className={classes.errorBox}>
                    <span>{error.mainError}</span>
                    <span className='ri-close-fill font-20 cursor-pointer' style={{ lineHeight: '1' }} onClick={()=>setError(null)}></span>
                </div>
            :
            branchList.length > 0 ? 
                <div className={classes.autoInput} style={{ width: '90%' }}>
                    <Input
                        type="auto-complete-dropdown"
                        id={"git_urlsZ"}
                        name="branch"
                        list={branchList.length > 0 ? branchList : []}
                        getOptionLabel={(option) => option.label}
                        error={error}
                        data={data}
                        onChangeHandler={onKeyValueHandler}
                    />
                </div>
                :
                loading ? 
                    <GenericSkeleton width={'90%'} height={37} style={{ borderRadius: '4px' }} />
                :
                <div className={classes.defaultBox}>
                    <span className='font-14 font-weight-400 color-value'>{service.branch || 'Use default Branch'}</span>
                    {service.component_env_id && hoveredBranch ?
                        <span className='ri-edit-2-line font-20 color-key-78 cursor-pointer' style={{ lineHeight: '1' }} onClick={() => loadComponentBranches()}></span>
                        : null
                    }
                </div>
            }
        </div>
    );
}

export default memo(BranchBlock);

const styles = makeStyles((theme)=>({
    defaultBox:{
        width:'90%',
        padding:'8px 12px 8px 12px',
        borderRadius:'4px',
        backgroundColor:'#FAFAFA',
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
    },
    errorBox: {
        width:'90%',
        padding:'8px 12px 8px 12px',
        borderRadius:'4px',
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
        color: "#BA0000",
        border: '1px solid #FBE6E6',
        background: '#FFF3F3',
    },
    autoInput:{
        '& .input-component .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
            padding: "9.5px 4px !important",
        },
        '& .input-component':{
            marginBottom: '0px !important',
        },
          
        '& .input-component .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
            padding: "10.2px 4px !important",
        },
        '& .input-component .MuiAutocomplete-endAdornment':{
            top:'calc(50% - 12px)',
            position:'absolute !important',
            right: '0 !important'
        }
    }
}))