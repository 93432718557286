import React, { useEffect, useState, createContext, useRef } from 'react';
import PropTypes from 'prop-types';
import ExecutionCard from './components/ExecutionCard';
import { LoadingContainer } from '../../utils/Loading';
import { ErrorComponent } from '../../utils/Error';
import GenerateURL from '../../../util/APIUrlProvider';
import properties from '../../../properties/properties';
import InvokeApi, { UnsubscribeToApi, SubscribeToApi } from '../../../util/apiInvoker';

export const TabContext = createContext();

const PipelineExecution = (props) => {
    const { application_id, pipeline_id, pipeline_instance_id } = props.match.params;
    const [state, setState] = useState({
        loaded: false,
        counter: 0,
        is_tab_hidden: false,
        closeSseForManageCanary : false,
        eventSource: null,
    });


    const eventSourceRef = useRef(null);

    useEffect(() => {
        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange)
        }
    }, [])

    useEffect(() => {
        console.log(state.is_tab_hidden, 'tabs_data_001')
        if (state.is_tab_hidden || state.closeSseForManageCanary) { // only for closing
            //closed sse 
            eventSourceRef.current?.close();
        }
        else {
            // re start sse if not available 
            if (!eventSourceRef.current || eventSourceRef.current.readyState === 2) {

                loadRealTimeStageData()
            }
        }
        return () => {
            console.log('cleanup............', eventSourceRef.current);
            eventSourceRef.current?.close();
        };

        // alert('closed sse')
    }, [state.is_tab_hidden])


    const handleVisibilityChange = () => {
        setState(prevState => ({
            ...prevState,
            is_tab_hidden: document.hidden
        }))
    }

    function requestInstanceData(data, counter) {
        console.log(data, counter, "fsjkfsajkajsf")
        var requestInfo = {
            endPoint: GenerateURL({ pipeline_id: pipeline_id, pipeline_instance_id: pipeline_instance_id }, properties.api.pipeline_execution_detail),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, (res) => { handleResponse(res, counter) }, handleError);
    }


    function handleResponse(data, counter) {
        console.log(counter, "ldssaffs")
        if (data.status == "IN_QUEUE" || data.status == "RUNNING" || data.status == "PENDING_APPROVAL" || (counter >= 1 && data.status == "FAILED")) {
            if (counter > 1) {
                console.log(counter, "ldssaffs")
                setInterval(requestInstanceData, 5000);
            } else {
                setTimeout(requestInstanceData, 5000);
            }

        }


        setState((new_state) => ({
            ...new_state,
            loaded: true,
            data_: data
        }));
    }

    function handleError(error) {
        setState((new_state) => ({
            ...new_state,
            loaded: true,
            error: error
        }));
    }

    function loadRealTimeStageData() {

        let url = GenerateURL({
            pipeline_id: pipeline_id,
            pipeline_instance_id: pipeline_instance_id,

        }, properties.api.pipeline_execution_detail_sse)

        console.log(eventSourceRef, 'data_0023_evm');
        if (eventSourceRef?.current?.readyState != 1) {
            const eventSource = new EventSource(url)
            eventSourceRef.current = eventSource;

            eventSource.addEventListener("PIPELINE_DATA", handlePipelineData)
        }
    }


    const handlePipelineData = (e) => {
        let data = JSON.parse(e.data)
        console.log('stage_data_0w3s', data)
        let pipelineStatus = data?.pipeline_data?.status

        console.log(pipelineStatus, 'perp_)0sdp')
        setState(prevState => ({
            ...prevState,
            loaded: true,
            data_: data?.pipeline_data,
            pipelineStatus: pipelineStatus
        }))
        if (eventSourceRef && eventSourceRef?.current && eventSourceRef?.current.readyState == 1 && ['FAILED', 'SUCCESS'].includes(pipelineStatus)) {
            // close sse
            console.log('closed_00023', eventSourceRef)
            eventSourceRef.current.close();
        }
    }

    function closeSseForManageCanary(){
        setState((new_state) => ({
            ...new_state,
            closeSseForManageCanary : !new_state.closeSseForManageCanary
        }))
    }


    console.log(state.data_, 'data_00p_fgbmn')

    return (
        <>
            {
                !state.loaded ?
                    <LoadingContainer /> : state.error ? <ErrorComponent error={state.error} variant='error-box' /> : null
            }
            {
                state.loaded ?
                    <TabContext.Provider value={{ is_tab_hidden: state.is_tab_hidden }}>
                        <ExecutionCard
                            application_id={application_id}
                            pipeline_id={pipeline_id}
                            requestInstanceData={loadRealTimeStageData}
                            pipeline_instance={state.data_}
                            closeSseForManageCanary={closeSseForManageCanary}
                        />
                    </TabContext.Provider>

                    : null
            }

        </>
    )
}

PipelineExecution.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};

export default PipelineExecution;