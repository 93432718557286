import React, { useEffect, useState } from 'react';
import { Popover, Tooltip, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import GenerateURL from '../../../util/APIUrlProvider';
import { GetAuth } from '../../../util/security';
import properties from '../../../properties/properties';
import { CopyToClipboard } from '../../../util/util';
import useFetchPermissions from '../../../components/customHooks/useFetchPermissions';


const DeploySectionNew = ({ isDynamicJobsExist, ...props }) => {
    const deploy_details = props.deploy_details;
    var promote_details = props.promote_details;
    const service_id = props.service_id;
    const env_id = props.env_id;
    const service_data = props.service_data;
    const deploy_id = props.deploy_id
    console.log(deploy_id, service_data, deploy_details, env_id, "deploy_iddeploy_id")
    var number = "N/A";
    var artifact = "N/A";
    var status = "N/A";
    var rollout_status = "N/A";
    var manifest_applied = "N/A"
    var deploy_on = "N/A";
    var deploy_by = "N/A";
    var trigger_type = null;
    var trigger_by = null;
    var uptime = props.uptime ? moment(props.uptime).fromNow() : "N/A";
    var restart = props.restart ? props.restart : "N/A";
    let deploymentSelector = props.deploymentSelector ? props.deploymentSelector : null;
    const selectedEnvName = service_data.component_env.find((envObj) => envObj.id == env_id)
    const [state, setState] = useState({
        action_card: "",
    });
    const params = useParams();

    const classes = useStylesForCD();
    useEffect(() => {
        setState({
            ...state,
            action_card: "",
        })
    }, []);
    if (deploy_details) {
        console.log(deploy_details)
        number = deploy_details.deploy_number;
        artifact = deploy_details.deploy_tag;
        rollout_status = deploy_details.deployment_status ?
            deploy_details.deployment_status : "FAILED";
        status = deploy_details.activity_status && deploy_details.activity_status.status ?
            deploy_details.activity_status.status : "N/A";
        manifest_applied = deploy_details.activity_status && deploy_details.activity_status.status ?
            deploy_details.activity_status.status : null;
        // deployment_status = deploy_details.deployment_status?deploy_details.deployment_status: null
        deploy_on = deploy_details.activity_status && deploy_details.activity_status.start_time ? moment(deploy_details.activity_status.start_time).fromNow() : "N/A"
        // deploy_details.deploy_by_pipeline && deploy_details.deploy_by_pipeline.pipeline.name ? "Pipeline " + deploy_details.deploy_by_pipeline.pipeline.name + ", " +
        //     deploy_details.deploy_by_pipeline.trigger_type.name + " by " + (deploy_details.deploy_by_pipeline.trigger_by ? deploy_details.deploy_by_pipeline.trigger_by : "System") :
        deploy_by = deploy_details.deploy_by_user ? deploy_details.deploy_by_user : "User Name";
        if (deploy_details.deploy_by_pipeline) {
            trigger_type = deploy_details.deploy_by_pipeline && deploy_details.deploy_by_pipeline.pipeline.name ? "Pipeline - " + deploy_details.deploy_by_pipeline.pipeline.name + " / " + deploy_details.deploy_by_pipeline.trigger_type.name : "N/A";
            trigger_by = deploy_details.deploy_by_pipeline.trigger_by ? deploy_details.deploy_by_pipeline.trigger_by : "System";
        }

    }
    function getStyleForStatus(status) {
        switch (status) {
            case "RUNNING":
                return { background: "#F5FAFF", color: "#0086FF", border: "1px solid #DFEDFF" }
            case "IN_QUEUE":
                return { background: "#FCF6E1", color: "#FEA111", border: "1px solid #FCF6E1" }
            case "FAILED":
                return { background: "#FFF3F3", color: "#BA0000", border: "1px solid #FBE6E6" }
            case "SUCCESS":
                return { background: "#EFFFF3", color: "#129E5B", border: "1px solid #DFEDFF" }
            case "REVOKED":
                return { background: "#F4F4F4", color: "#626262", border: "1px solid #E6E6E6" }
            default:
                return { background: "#EFFFF3", color: "#129E5B", border: "1px solid #DFEDFF" }
        }
    }
    function getDeployStatus(deploy_details) {
        let statusObj = {};
        if (!deploy_details) {
            return null
        }
        if (deploy_details?.activity_status?.status == "REVOKED") {
            statusObj.status = "REVOKED";
            statusObj.style = getStyleForStatus(deploy_details.activity_status.status);
            return statusObj;
        }
        if (deploy_details?.activity_status?.status == "IN_QUEUE") {
            statusObj.status = "IN QUEUE";
            statusObj.style = getStyleForStatus(deploy_details.activity_status.status);
            return statusObj;
        }

        let manifest_applied = deploy_details?.activity_status?.sub_task_status ? deploy_details?.activity_status?.sub_task_status.find((obj) => obj.activity_sub_task_code == "K8S_DEPLOYMENT_MANIFEST_APPLY") : null;
        manifest_applied = manifest_applied?.status;
        if (manifest_applied) {
            if (manifest_applied == "RUNNING") {
                statusObj.status = "Applying Manifest";
                statusObj.style = getStyleForStatus(manifest_applied);
                return statusObj
            } else if (manifest_applied == "FAILED") {
                statusObj.status = "Manifest Apply Failed";
                statusObj.style = getStyleForStatus(manifest_applied);
                return statusObj
            } else {
                if (deploy_details.deployment_status) {
                    if (deploy_details.deployment_status == "RUNNING") {
                        statusObj.status = "Rollout In Progress";
                        statusObj.style = getStyleForStatus(deploy_details.deployment_status);
                        return statusObj
                    } else if (deploy_details.deployment_status == "FAILED") {
                        statusObj.status = "FAILED";
                        statusObj.style = getStyleForStatus(deploy_details.deployment_status);
                        return statusObj
                    } else {
                        statusObj.status = "SUCCESS";
                        statusObj.style = getStyleForStatus(deploy_details.deployment_status);
                        return statusObj
                    }
                } else {
                    statusObj.status = "Manifest Applied";
                    statusObj.style = getStyleForStatus(manifest_applied);
                    return statusObj
                }
            }
        } else {
            return null;
        }

    }
    function toggleTriggerActionCard(type) {
        switch (type) {
            default:
                setState(fresh_state => ({
                    ...fresh_state,
                    action_card: !fresh_state.action_card ? type : fresh_state.action_card == type ? "" : type,
                }));
        }
    }
    function handleClose() {
        setState({
            ...state,
            trigger: null
        });
    }
    const no_deployment_triggered = false
    var url_cd = GenerateURL({ service_id: service_id, component_env_id: env_id, cd_id: deploy_id }, properties.api.save_cd, true);
    const is_deploy_permited = useFetchPermissions()?.POST.includes(url_cd);
    console.log("ispermited-cd", url_cd, is_deploy_permited)
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClickForCD = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseForCD = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    let final_deployment_status = getDeployStatus(deploy_details);
    console.log("dkfnksnd", final_deployment_status);
    return (
        <div style={{ width: isDynamicJobsExist ? '520px' : '98%', flex: isDynamicJobsExist ? '0 0 auto' : 'auto' }} className={classes.root}>
            <div className="heading">

                <div className='font-12 font-weight-600 color-icon-secondary d-flex align-center'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M12.919 16.6651C12.3677 17.9837 11.3193 19.0321 10.0007 19.5834C8.68205 19.0321 7.63362 17.9837 7.08232 16.6651H8.96565C9.23732 17.0726 9.58815 17.4251 10.0007 17.7009C10.4132 17.4259 10.764 17.0726 11.0365 16.6651H12.919ZM15.0007 12.3359L16.6673 14.2259V15.8318H3.33398V14.2259L5.00065 12.3359L5.00065 7.49844C5.00065 4.59594 7.08732 2.12594 10.0007 1.21094C12.914 2.12594 15.0007 4.59594 15.0007 7.49844V12.3359ZM14.3923 14.1651L13.334 12.9651V7.49844C13.334 5.56677 12.0257 3.80677 10.0007 2.98177C7.97565 3.80677 6.66732 5.56594 6.66732 7.49844L6.66732 12.9651L5.60898 14.1651H14.3923ZM10.0007 9.1651C9.55862 9.1651 9.1347 8.98951 8.82214 8.67695C8.50958 8.36439 8.33398 7.94047 8.33398 7.49844C8.33398 7.05641 8.50958 6.63249 8.82214 6.31993C9.1347 6.00737 9.55862 5.83177 10.0007 5.83177C10.4427 5.83177 10.8666 6.00737 11.1792 6.31993C11.4917 6.63249 11.6673 7.05641 11.6673 7.49844C11.6673 7.94047 11.4917 8.36439 11.1792 8.67695C10.8666 8.98951 10.4427 9.1651 10.0007 9.1651Z" fill="#787878" />
                    </svg>
                    <span className='ml-4 avtar'>{deploy_details ? "DEPLOY STATUS" : "Trigger first deployment"}</span></div>
                {deploymentSelector}
            </div>
            {/*build_details*/}
            {/* promote_details */}
            {deploy_details ?

                <>
                    <div className='info-box'>
                        <div className='top-part'>
                            <div className='d-flex align-center'>
                                <Link
                                    to={"/logs?global_task_id=" + deploy_details.global_task_id +
                                        "&tab_id=" + deploy_id +
                                        "&service_name=" + service_data.name +
                                        "&service_env=" + selectedEnvName.project_env.name +
                                        "&tab_name=DEPLOY" + "&num=" + deploy_details.deploy_number +
                                        "&service_id=" + service_id +
                                        "&env_id=" + env_id +
                                        "&cd_id=" + deploy_id
                                    }

                                    target="_blank">
                                    <span className="font-14 font-weight-600" style={{ color: "#0086FF" }}>Deploy #{number} </span>
                                </Link>


                                {final_deployment_status ?

                                    <span className="status-chip-new font-12" style={final_deployment_status.style} >{final_deployment_status.status}</span>

                                    :
                                    manifest_applied == "N/A" ? "" :
                                        manifest_applied == "FAILED" ?
                                            <span className="status-chip-new font-12" style={{ background: "#FFF3F3", color: "#BA0000", border: "1px solid #FBE6E6" }} >Failed</span> :
                                            manifest_applied == "IN_QUEUE" ?
                                                <div className="status-chip-new font-12" style={{ background: "#FCF6E1", color: "#FEA111", border: "1px solid #FCF6E1" }}>In queue</div> :
                                                manifest_applied == "RUNNING" ?
                                                    <div className="status-chip-new font-12" style={{ background: "#F5FAFF", color: "#0086FF", border: "1px solid #DFEDFF" }}>Running</div> :
                                                    manifest_applied == "REVOKED" ?
                                                        <span className="status-chip-new font-12" style={{ background: "#F4F4F4", color: "#626262", border: "1px solid #E6E6E6" }} >Revoked</span> :
                                                        <span className="status-chip-new font-12" style={{ background: "#EFFFF3", color: "#129E5B", border: "1px solid #DFEDFF" }}>{manifest_applied == 'IN_QUEUE' ? 'IN QUEUE' : manifest_applied}</span>

                                }

                            </div>
                            <div className='d-flex align-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                    <path d="M8.00065 15.1693C4.31865 15.1693 1.33398 12.1846 1.33398 8.5026C1.33398 4.8206 4.31865 1.83594 8.00065 1.83594C11.6827 1.83594 14.6673 4.8206 14.6673 8.5026C14.6673 12.1846 11.6827 15.1693 8.00065 15.1693ZM8.00065 13.8359C9.41514 13.8359 10.7717 13.274 11.7719 12.2738C12.7721 11.2736 13.334 9.91709 13.334 8.5026C13.334 7.08812 12.7721 5.73156 11.7719 4.73137C10.7717 3.73117 9.41514 3.16927 8.00065 3.16927C6.58616 3.16927 5.22961 3.73117 4.22941 4.73137C3.22922 5.73156 2.66732 7.08812 2.66732 8.5026C2.66732 9.91709 3.22922 11.2736 4.22941 12.2738C5.22961 13.274 6.58616 13.8359 8.00065 13.8359ZM8.66732 8.5026H11.334V9.83594H7.33398V5.16927H8.66732V8.5026Z" fill="#787878" />
                                </svg>
                                <span className='font-12 font-weight-500 color-key-78 ml-4'>{deploy_on}</span>
                            </div>
                        </div>
                        <div className='bottom-part'>
                            <div className='bottom-info'>
                                <span className='font-12 font-weight-500 color-key-78'>Artifact:</span>
                                <div className='d-flex align-center'>
                                    <span className="font-12 font-weight-600 color-icon-secondary mr-4" >{artifact}</span>
                                    {artifact && <CopyToClipboard data={artifact} />}

                                </div>
                            </div>
                            <div>
                                <span className='font-12 font-weight-500 more-btn' onClick={handleClickForCD}>more Details</span>
                            </div>
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleCloseForCD}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                PaperProps={{ style: { overflow: 'visible' } }}
                            >
                                <div className={classes.popCI}>

                                    {deploy_details.deploy_by_pipeline ?
                                        <>
                                            <div className='d-flex align-center'>
                                                <span className='font-12 font-weight-500 color-key-78 '>Trigger Type: &nbsp;</span>
                                                <span className=" font-12 font-weight-500 color-value">{trigger_type}</span>
                                            </div>
                                            <div className='d-flex align-center'>
                                                <span className='font-12 font-weight-500 color-key-78 '>Triggered By: &nbsp;</span>
                                                <span className=" font-12 font-weight-500 color-value">{trigger_by}</span>
                                            </div>
                                        </>
                                        :
                                        <div className='d-flex align-center'>
                                            <span className='font-12 font-weight-500 color-key-78 '>Deployed By: &nbsp;</span>
                                            <span className=" font-12 font-weight-500 color-value">{deploy_by}</span>
                                        </div>
                                    }

                                    <div className='d-flex align-center'>
                                        <span className='font-12 font-weight-500 color-key-78 '>Restart: &nbsp;</span>
                                        <Tooltip title={restart} placement="bottom">
                                            <span className=" font-12 font-weight-500 color-value text-wrap">{restart} </span>
                                        </Tooltip>
                                    </div>
                                    <div className='d-flex align-center'>
                                        <span className='font-12 font-weight-500 color-key-78 '>Uptime: &nbsp;</span>
                                        <Tooltip title={restart} placement="bottom">
                                            <span className=" font-12 font-weight-500 color-value text-wrap">{uptime} </span>
                                        </Tooltip>
                                    </div>
                                </div>
                            </Popover>
                        </div>
                    </div>
                </>

                :
                props.manual_deploy && is_deploy_permited ?
                    <div className={classes.tiggerCase}>
                        <div className="real-card">
                            <button className="btn-primary" onClick={props.show_deploy_card}>
                                <span className="ri-upload-2-line font-weight-500 font-20"></span>
                                <span className=''>Trigger</span>
                            </button>
                            {/* <p className="font-12 mb-10 text-center color-key-78  font-weight-500">
                        Deploy Details are configured!! Go ahead and trigger deployment for this environment!!
                    </p> */}
                        </div>
                    </div> :
                    <div className={classes.tiggerCase} >
                        <div className="real-card">
                            <button className="btn-primary btn-disable  cursor-not-allowed" >
                                <span className="ri-upload-2-line font-weight-500 font-20"></span>
                                <span className=''>Trigger</span>
                            </button>
                            {/* <p className="font-12 mb-10 text-center color-key-78  font-weight-500">
                        Deploy Details are configured!! but it seems you don't have permission to trigger deployment. please contact to project admin.
                    </p> */}
                        </div>
                    </div>
            }

        </div>
    )
}

const useStylesForCD = makeStyles((theme) => ({
    root: {
        width: "520px",
        height: '196px',
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        borderRadius: "6px",
        border: "1px solid #E6E6E6",
        flex: '0 0 auto',
        '& .heading': {
            display: "flex",
            width: "100%",
            minHeight: "48px",
            padding: "12px 8px 12px 16px",
            alignItems: "center",
            gap: "10px",
            justifyContent: "space-between",
        },
        '& .info-box': {
            display: "flex",
            width: "100%",
            padding: "8px 16px 16px 16px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "20px",
            '& .top-part': {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                '& .status-chip-new': {
                    display: "flex",
                    fontSize: "12px",
                    padding: "4px 6px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    borderRadius: "4px",
                    background: "#EFFFF3",
                    fontWeight: "700",
                    textTransform: "uppercase",
                    marginLeft: "12px",
                },

            },
            '& .bottom-part': {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                alignSelf: "stretch",
                '& .bottom-info': {
                    display: "flex",
                    flexDirection: "column",
                    width: "285px",
                    //alignItems: "center",
                    //gap: "16px",
                },
                '& .more-btn': {
                    textTransform: "uppercase",
                    color: "#0086FF",
                    cursor: "pointer",
                    '&:hover': {
                        textDecoration: "underline",
                    }
                }
            },
        }
    },
    popCI: {
        display: "inline-flex",
        padding: "12px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "8px",
        borderRadius: "6px",
        border: "1px solid #E6E6E6",
        background: "#FFF",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    },
    tiggerCase: {
        display: "flex",
        padding: "8px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "10px",
        alignSelf: "stretch",
        paddingTop: "0px",
        height: "100%",
        '& .real-card': {
            display: "flex",
            padding: "0px 16px",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
            alignSelf: "stretch",
            borderRadius: "6px",
            border: "1px solid #F4F4F4",
            //background: "#FAFAFA",
            paddingTop: "10px",
            height: "100%",
            justifyContent: "center",
            '& button': {
                display: "flex",
                height: "32px",
                padding: "11px 16px 11px 12px",
                justifyContent: "center",
                alignItems: "center",
                gap: "6px",
                borderRadius: "6px",
                border: "1px solid #F4F4F4",
                background: "##0086FF",
                textTransform: "uppercase",
            },
            '& p': {
                width: "85%",
            }
        }
    }
}))

export default DeploySectionNew