import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AddGlobalCustomJobTemplate from './components/AddGlobalCustomJobTemplate';


const AddGlobalJobTemplate = (props) => {
    return(
        <div className='d-grid' style={{gridTemplateColumns:'365px 1fr', height:'100vh'}}>
            <StaticPanel />
            <AddGlobalCustomJobTemplate />
        </div>
    )
}

export default AddGlobalJobTemplate;

const StaticPanel = () => {
  const classes = useStyles();
  return (
    <div className={classes.staticwrap} style={{ backgroundColor: '#051939', height: '100%' }}>
      <div className='staticwrap-inner'>
        <h1 className='h1'>Global Job Templates</h1>
        <p>
         BuildPiper supports following jobs - Build, Deploy, Promote, Rollback, API call,
         Jira Integration, Deploy, Config Maps, Deploy Secrets. Each job has default set of steps
         via which the jobs are configured.
         By adding or deleting the steps or their order of execution you can change the behaviour of jobs adn as a result
         behaviour of Product.
         
          <br></br> <br></br>
          adding a Job to the Job template <br />
          please read <a href='' style={{ color: '#dd8e15' }}>Documentation</a>
        </p>
      </div>
    </div>
  )
}

const useStyles = makeStyles({
  staticwrap: {
    '& .staticwrap-inner': {
      padding: '215px 30px 0 40px',
      color: '#fff',
      '& p': {
        fontSize: '13px',
        lineHeight: '23px',
        marginTop: '30px',
      }
    },
    '& .h1': {
      fontSize: '25px',
      lineHeight: '36px',
      color: '#ffffff',
      fontWeight: '300',
      position: 'relative',

      '&:before': {
        content: '""',
        borderBottom: '1px solid #000',
        position: 'absolute',
        borderLeft: 'none',
        bottom: '0',
        left: 0,
        display: 'inline-block',
        width: '40px',
        height: '4px',
        background: '#2e2af3',
      }
    },
  }
})

