import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import { JiraTicket } from './JiraTicket';
import { Input } from '../../../components/genericComponents/Input';
import { getCommonFunctions } from '../../serviceRevamp/add/ci_flow/SourceDetails';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { ValidateDataSet, VALIDATION_TYPE_REQUIRED } from '../../../util/Validator';

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: "24px",
        '& .MuiAccordionSummary-root': {

        },
        '& .MuiAccordionSummary-content': {
            alignItems: 'center',
            display: 'grid',
            gridTemplateColumns: '20px 1fr',
            gap: '20px'

        },
        '& .wrapper-row-jira': {
            gridTemplateColumns: '20px 1fr 250px',
            gap: '20px',
            alignItems: 'center',
            borderBottom: '1px solid rgb(230, 230, 230)',
            '&:last-child': {
                borderBottom: 'none'
            }
        },
        '& .d-grid': {
            padding: '10px 0px',
            '& .container-item': {
                borderBottom: 'none !important',
                justifyContent: 'flex-start',
                '& .wrapper-class-jira': {
                    width: 'auto !important'
                }
            },
            '& .input-component': {
                marginBottom: '0px !important'
            }
        }
    },
    checkbox: {
        width: '20px',
        height: '20px',
        borderRadius: '4px',
        backgroundColor: '#fff',
        border: '1px solid #e6e6e6',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f4f4f4',

        },
        '&.Mui-checked': {
            backgroundColor: '#0086ff',
            color: '#fff!important',
            border: '1px solid #0086ff',
            '& .MuiSvgIcon-root': {
                fontSize: '16px'
            },
            '&:hover': {
                backgroundColor: '#03234D !important',
                color: '#fff!important',
                borderColor: '#03234D !important'
            }
        },
        '&.MuiCheckbox-indeterminate': {
            backgroundColor: '#0086ff',
            color: '#fff!important',
            border: '1px solid #0086ff',
            '& .MuiSvgIcon-root': {
                fontSize: '16px'
            },
            '&:hover': {
                backgroundColor: '#03234D !important',
                color: '#fff!important',
                borderColor: '#03234D !important'
            }
        }
    },
    customIcon: {
        fontSize: '16px'
    }
}));

const JiraStoriesAccordion = ({ title, itemList, openByDefault, sprint_list, sendDataToPost, path, sprint_name, variant, handleClickOpenDialog, uncheck_all_tickets, resetUncheckAllTickets }) => {
    const classes = useStyles();
    const [state, setState] = useState({
        data: {},
        error: {},
    });
    const handleClickOpen = handleClickOpenDialog

    const [selectedItems, setSelectedItems] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);


    useEffect(() => {
        console.log(uncheck_all_tickets, "uncheck_all_tickets")
        if (uncheck_all_tickets) {
            setSelectedItems([]);
            setIsAllSelected(false);
            setIndeterminate(false);
            resetUncheckAllTickets();
        }
    }, [uncheck_all_tickets]);
    useEffect(() => {
        setIsAllSelected(itemList.length > 0 && selectedItems.length === itemList.length);
        setIndeterminate(selectedItems.length > 0 && selectedItems.length < itemList.length);
    }, [selectedItems, itemList]);

    const handleSelectAll = () => {
        if (isAllSelected) {
            setSelectedItems([]);
            setIsAllSelected(false);
            setIndeterminate(false);
        } else {
            setSelectedItems(itemList.map(item => item.id));
            setIsAllSelected(true);
            setIndeterminate(false);
            handleClickOpen();
        }
    };
    console.log(selectedItems, "selectedItemsselectedItems", isAllSelected)

    const handleItemCheck = (itemId) => {
        const updatedSelectedItems = selectedItems.includes(itemId)
            ? selectedItems.filter(id => id !== itemId)
            : [...selectedItems, itemId];

        setSelectedItems(updatedSelectedItems);
    };

    const commonFunctions = getCommonFunctions(state, setState, {});

    function formatPostData(data) {
        const result = {
            backlog: {},
            sprints: {}
        };

        Object.keys(data).forEach(key => {
            if (data[key] === 'backlog') {
                result.backlog[key] = data[key];
            } else {
                result.sprints[key] = data[key];
            }
        });

        return result;
    }

    const validateAndSaveData = () => {
        var result = ValidateDataSet(state.data, state.validations);
        if (result.valid) {
            const final_obj = formatPostData(state.data)
            sendDataToPost(final_obj)
        } else {
            setState(new_state => ({
                ...new_state,
                error: {
                    ...new_state.error,
                    ...result.error
                }
            }));
        }
    }

    return (
        <div className={classes.root}>
            <Accordion defaultExpanded={openByDefault}>
                <AccordionSummary
                    style={variant !== "in_completed" ? { padding: '0px!improtant' } : {}}
                    expandIcon={<ExpandMoreIcon className='font-24' />}
                    onClick={(event) => event.stopPropagation()} // Prevent toggle on summary click
                >
                    {variant === "in_completed" ?
                      itemList.length >  0 ?
                        <Checkbox
                            indeterminate={indeterminate}
                            checked={isAllSelected}
                            onChange={handleSelectAll}
                            onClick={(event) => event.stopPropagation()}
                            className={classes.checkbox}
                            icon={<span style={{ width: '20px', height: '20px' }} />}
                            checkedIcon={<span className={`ri-check-fill ${classes.customIcon}`}></span>}
                            indeterminateIcon={<span className={`ri-subtract-line ${classes.customIcon}`}></span>}
                        />
                        : <div></div>:<div></div>}
                    <p className='font-14 font-weight-600'>{title}</p>
                </AccordionSummary>
                <AccordionDetails className='d-block'>
                    {
                        itemList.length > 0 ?
                            itemList.map((item) => (
                                <div key={item.id} className='d-grid wrapper-row-jira'>
                                    {variant === "in_completed" &&
                                        selectedItems.includes(item.id) ?
                                        <Checkbox
                                            checked={selectedItems.includes(item.id)}
                                            onChange={() => handleItemCheck(item.id)}
                                            className={classes.checkbox}
                                            icon={<span style={{ width: '20px', height: '20px' }} />}
                                            checkedIcon={<span className={`ri-check-fill ${classes.customIcon}`}></span>}
                                        />
                                        : <div></div>}
                                    <JiraTicket
                                        jiraObject={item}
                                        onClick={() => { }}
                                        onDelete={() => { }}
                                    />
                                    {
                                        variant === "in_completed" &&
                                        <div className="ml-auto" style={{ width: '250px' }}>
                                            <Input
                                                type="select"
                                                list={sprint_list.map(item => {
                                                    return { label: item.name, id: item.path }
                                                })}
                                                label=""
                                                name={item.id}
                                                data={state.data}
                                                error={state.error}
                                                onChangeHandler={commonFunctions.onChangeHandler}
                                            />
                                        </div>
                                    }
                                </div>
                            ))
                            :
                            <div className=''>
                                {
                                    variant === "in_completed"?
                                    <>
                                     <img src="/images/logos/tick-icon-ticketing-system.svg" className='d-block m-auto' alt=".." />
                                     <p className='font-16 text-center'>No incomplete Work Item left in the sprint. The sprint can be finalized now!!</p>
                                    </>
                                    :
                                    <>
                                    <p className='font-16 text-center'>There are currently no tickets in completed state.</p>
                                    </>
                                }
                               
                            </div>
                    }

                </AccordionDetails>

            </Accordion>
            {
                variant === "in_completed" && itemList.length > 0 &&
                <div className='d-flex align-center justify-end' style={{ paddingTop: '10px' }}>
                    <Link to={"/sprint/scope?sprint="+sprint_name}>
                        <button className='btn btn-transparent'>Cancel</button>
                    </Link>

                   
                    <button className='btn btn-primary-orange mr-0'
                        onClick={validateAndSaveData}
                    >Move Selected</button>
                </div>
            }
        </div>
    );
}

export default JiraStoriesAccordion;
