import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from "react-router-dom";
import { styled } from '@material-ui/core/styles';


const Conditions = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const nodeConditions = props.nodeInfo;
    const isNegativeCondition = props.conditions_negative;
    function getConditionStatus(status, type) {
        if (!isNegativeCondition) {
            if (status == "True") {
                return <>
                    <span className="bullet green p-relative"></span>
                    <span className="msg">True</span></>
            }
            else {
                return <>
                    <span className="bullet red p-relative"></span>
                    <span className="msg">False</span></>
            }
        }
        else {
            if (type == "Ready") {
                if (status == "True") {
                    return <>
                        <span className="bullet green p-relative"></span>
                        <span className="msg">True</span></>
                }
                else {
                    return <>
                        <span className="bullet red p-relative"></span>
                        <span className="msg">False</span></>
                }
            } else {
                if (status == "False") {
                    return <>
                        <span className="bullet green p-relative"></span>
                        <span className="msg">False</span></>
                }
                else {
                    return <>
                        <span className="bullet red p-relative"></span>
                        <span className="msg">True</span></>
                }
            }
        }
    }
    return (
        <React.Fragment>
            <div className="head" style={{ margin: '10px 0px' }}>
                <div className="heading-section-service">
                    <div className="main-heading">Conditions</div>
                    <div className="sub-heading-text">Displaying realtime view of conditions</div>
                </div>
            </div>
            <TempHeader>
                <span>Type</span>
                <span>Status</span>
                <span>Reason</span>
                <span>Message</span>
                <span>Last Heartbeat Time</span>
                <span>Last Transition Time</span>
            </TempHeader>
            <Body>
                {
                    nodeConditions.length > 0 ?
                        nodeConditions.map(condition => (
                            <TempBodyRow>
                                <div className="name">{condition.type}</div>
                                <span className="status-point" >
                                    {getConditionStatus(condition.status, condition.type)}
                                </span>
                                <div className="msg">
                                    {condition.reason ? condition.reason : "No Reason Found"}
                                </div>
                                <div className="msg">
                                    {condition.message ? condition.message : "No Message Found"}
                                </div>
                                <div className="msg">
                                    {condition.lastHeartbeatTime ? (new Date(condition.lastHeartbeatTime)).toString().substring(4, 21) + " IST" : "-"}
                                </div>
                                <div className="msg">
                                    {condition.lastTransitionTime ? (new Date(condition.lastTransitionTime)).toString().substring(4, 21) + " IST" : "-"}
                                </div>
                            </TempBodyRow>
                        ))
                        : null
                }

            </Body>
        </React.Fragment>
    )
}
Conditions.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

const ITEM_HEIGHT = 48;

export function LongMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const application_id = props.application_id;
    return (
        <div>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon style={{ color: '#0096db', fontSize: '2rem' }} />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: 'fit-content',
                    },
                }}
            >
                <MenuItem selected={Link === 'Dashboard'} onClick={handleClose} style={{ display: "grid" }}>

                    <Link to="/namespaces">
                        <span className="hover-content-link" ><AddIcon style={{ color: '#0096db', fontSize: '16px' }} />&nbsp;Add NameSpaces</span>
                    </Link>
                </MenuItem>
            </Menu>
        </div>
    );
}
LongMenu.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default Conditions;

const Body = styled('div')({
    height: '250px',
    overflowY: 'scroll',
})
const TempHeader = styled('div')({
    display: 'grid',
    gridTemplateColumns: '10% 8% 15% 25% 17% 20%',
    fontSize: '11px',
    gap: '10px',
    padding: '10px',
    borderBottom: '2px solid #000',
    alignItems: 'center',
    '& span': {
        padding: '0px 2px'
    }
})
const TempBodyRow = styled('div')({
    display: 'grid',
    gridTemplateColumns: '10% 8% 15% 25% 17% 20% ',
    fontSize: '11px',
    padding: '10px',
    gap: '10px',
    borderBottom: '1px solid #dedede',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '& .name': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    '&:last-child': {
        border: 'none'
    },
    '& .createdat': {
        fontSize: '10px',
        color: '#878787'
    },
    '& .fre-text': {
        fontSize: '11px',
        color: '#000',
        '& .uses-text': {
            fontSize: '9px',
            color: '#878787'
        }
    },
    '& .status-font': {
        color: '#fff',
        width: '50%',
        '& .flaticon-circle-with-check-symbol': {
            '&:before': {
                fontSize: '14px!important'
            }
        }
    },
    '& div': {
        padding: '0px 2px',
        color: '#9e9e9e'
    },
    '& .url': {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    }
})
