import { makeStyles, Snackbar } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import SearchBar from '../../../../components/SearchBar';
import GenerateURL, { GenerateSearchURL } from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import InvokeApi from '../../../../util/apiInvoker';
import Pagination from '../../../../components/Pagination';
import clsx from 'clsx';
import GenericSkeleton from '../../../../components/genericComponents/Skeletons/GenericSkeleton';
import MuiAlert from '@material-ui/lab/Alert';
import ReleasePackageOverview from '../../../pipeline/listing/releasePackageComponents/ReleasePackageOverview';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PipelineReleasePackage = ({pipeline_id,toggleDismiss,openedReleasePackage,setOpenedReleasePackage,triggerPipeline}) => {
    const classes = styles();
    const [state,setState] = useState({
        loading:false,
        listPresent:false,
        total_page: "",
        curr_page: "",
        refresh_count: 1,
        data:{
          releasePackageList:[],
          selectedReleasePackage: -1,
        },
        error:{},
    });

    useEffect(()=>{
        getReleasePackageList();
    },[])

    function validateAndTriggerPipeline(){
      let doesReleasePackageSelected = state.data.selectedReleasePackage != -1 || openedReleasePackage;
      console.log("ksdjksjdksj",doesReleasePackageSelected,state.data.selectedReleasePackage,openedReleasePackage )
      if(doesReleasePackageSelected){
         triggerPipeline(pipeline_id,{
          release_pack_id: openedReleasePackage ? openedReleasePackage : state.data.selectedReleasePackage != -1 ? state.data.selectedReleasePackage : null 
         });
      }else{
        setState((prevState)=>({
          ...prevState,
          error:{
            ...prevState.error,
            validation_error:'Please Select Atleast One Release Package',
          }
        }))
      }
    }

    const getReleasePackageList = (data)=>{
        var requestInfo = {
            endPoint: GenerateURL({ }, properties.api.release_package_listing_pipeline),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
          }
          if(data){
            requestInfo.endPoint = GenerateSearchURL(data,requestInfo.endPoint)+"&release_type=global_package";
          }else {
            requestInfo.endPoint += "?release_type=global_package";
          }
          InvokeApi(requestInfo, fetchReleasePackagesSuccess,fetchReleasePackagesFailure);
          setState((prevState)=>({
            ...prevState,
            loading:true,
          }))
    }

    function fetchReleasePackagesSuccess(response){
        console.log("dhsjasjs",response);
        let listPresent = response.results.length <= 0;
         setState((prevState)=>({
          ...prevState,
          loading: false,
          listPresent: listPresent,
          count: response.count,
          next: response.next ? properties.api.baseURL + response.next : null,
          previous: response.previous
            ? properties.api.baseURL + response.previous
            : null,
          data:{
            ...prevState.data,
            releasePackageList: response?.results,
          },
          total_page: Number.isInteger(Number(response.count) / 10)
            ? (Number(response.count) / 10).toFixed(0)
            : (Number(response.count) / 10 + 1).toFixed(0) >
              Number(response.count) / 10 + 1
              ? (Number(response.count) / 10 + 1).toFixed(0) - 1
              : (Number(response.count) / 10 + 1).toFixed(0),
          curr_page: 1,
        }))
      }
      function fetchReleasePackagesFailure(error){
        console.log("dhsjasjs",error);
         let error_msg = error.detail ? error.detail : JSON.stringify(error);
        setState((prevState)=>({
          ...prevState,
          loading: false,
          error: error_msg,
        }))
      }
    
      function fetchNextReleasePackages(data,url){
        var requestInfo = {
          endPoint: GenerateURL({ }, properties.api.release_package_listing_pipeline),
          httpMethod: "GET",
          httpHeaders: { "Content-Type": "application/json" }
        }
        if (data) {
          requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
        }
    
        if (url) {
          requestInfo.endPoint = url;
        }
        InvokeApi(requestInfo, fetchNextReleasePackagesSuccess,fetchReleasePackagesFailure);
        setState((prevState)=>({
          ...prevState,
          loading:true,
        }))
      }
    
      function fetchNextReleasePackagesSuccess(response){
        console.log("dhsjasjs",response);
        let listPresent = response.results.length <= 0;
         setState((prevState)=>({
          ...prevState,
          loading: false,
          listPresent: listPresent,
          count: response.count,
          next: response.next ? properties.api.baseURL + response.next : null,
          previous: response.previous
            ? properties.api.baseURL + response.previous
            : null,
          data:{
            ...prevState.data,
            releasePackageList: response?.results,
          },
          total_page: Number.isInteger(Number(response.count) / 10)
            ? (Number(response.count) / 10).toFixed(0)
            : (Number(response.count) / 10 + 1).toFixed(0) >
              Number(response.count) / 10 + 1
              ? (Number(response.count) / 10 + 1).toFixed(0) - 1
              : (Number(response.count) / 10 + 1).toFixed(0),
          curr_page: Number(new_state.curr_page + 1),
        }))
      }
    
      function fetchPrevReleasePackages(data,url){
        var requestInfo = {
          endPoint: GenerateURL({ }, properties.api.release_package_listing_pipeline),
          httpMethod: "GET",
          httpHeaders: { "Content-Type": "application/json" }
        }
        if (data) {
          requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
        }
    
        if (url) {
          requestInfo.endPoint = url;
        }
        InvokeApi(requestInfo, fetchPrevReleasePackagesSuccess,fetchReleasePackagesFailure);
        setState((prevState)=>({
          ...prevState,
          loading:true,
        }))
      }
      function fetchPrevReleasePackagesSuccess(response){
        console.log("dhsjasjs",response);
        let listPresent = response.results.length <= 0;
         setState((prevState)=>({
          ...prevState,
          loading: false,
          listPresent: listPresent,
          count: response.count,
          next: response.next ? properties.api.baseURL + response.next : null,
          previous: response.previous
            ? properties.api.baseURL + response.previous
            : null,
          data:{
            ...prevState.data,
            releasePackageList: response?.results,
          },
          total_page: Number.isInteger(Number(response.count) / 10)
            ? (Number(response.count) / 10).toFixed(0)
            : (Number(response.count) / 10 + 1).toFixed(0) >
              Number(response.count) / 10 + 1
              ? (Number(response.count) / 10 + 1).toFixed(0) - 1
              : (Number(response.count) / 10 + 1).toFixed(0),
          curr_page: Number(new_state.curr_page - 1),
        }))
      }
    
      function fetchPageReleasePackages(enteredPageNumber){
        var requestInfo = {
          endPoint: GenerateURL({ }, properties.api.release_package_listing_pipeline),
          httpMethod: "GET",
          httpHeaders: { "Content-Type": "application/json" }
        }
        
        if (enteredPageNumber > 1) {
          requestInfo.endPoint =
            requestInfo.endPoint +
            "?limit=10&offset=" +
            (enteredPageNumber - 1) * 10;
        }
    
        var current_page = enteredPageNumber;
    
        InvokeApi(requestInfo,(response)=>{fetchPageReleasePackagesSuccess(response, current_page)},fetchReleasePackagesFailure);
        setState((prevState)=>({
          ...prevState,
          loading:true,
        }))
      }
    
      function fetchPageReleasePackagesSuccess(response, count){
        console.log("dhsjasjs",response);
        let listPresent = response.results.length <= 0;
         setState((prevState)=>({
          ...prevState,
          loading: false,
          listPresent: listPresent,
          count: response.count,
          next: response.next ? properties.api.baseURL + response.next : null,
          previous: response.previous
            ? properties.api.baseURL + response.previous
            : null,
          data:{
            ...prevState.data,
            releasePackageList: response?.results,
          },
          total_page: Number.isInteger(Number(response.count) / 10)
            ? (Number(response.count) / 10).toFixed(0)
            : (Number(response.count) / 10 + 1).toFixed(0) >
              Number(response.count) / 10 + 1
              ? (Number(response.count) / 10 + 1).toFixed(0) - 1
              : (Number(response.count) / 10 + 1).toFixed(0),
          curr_page: Number(count),
        }))
      }

      const selectReleasePackage = (id)=>{
        if(state.data.selectedReleasePackage == id){
            setState((prevState)=>({
                ...prevState,
                data: {
                    ...prevState.data,
                    selectedReleasePackage: -1,
                }
            }))
        }else{
            setState((prevState)=>({
                ...prevState,
                data: {
                    ...prevState.data,
                    selectedReleasePackage: id,
                }
            }))
        }
      }

    function handleClosePipelineTrigger(){
      setState((prevState)=>({
        ...prevState,
        error:{
          ...prevState.error,
          validation_error: null,
        }
      }))
    }

    const invokeSearch = (data) => {
      console.log("invokeSearch", data);
      let normalizedData = {...data,release_version:data.release_version.toLowerCase()}
      getReleasePackageList(normalizedData);
    };

    return (
      <>
         {openedReleasePackage ? 
        
              <ReleasePackageOverview selectedReleasePackage={openedReleasePackage} setSelectedReleasePackage={setOpenedReleasePackage}/>
            :
            <>
              <div className={classes.root}>
                  <span className='font-18 font-weight-600 color-value'>Select Release Package</span>
                  <div className='d-flex f-direction-column' style={{gap:'16px'}}>
                      <div className='d-flex align-center'>
                          <div style={{width:'60%'}}>
                              <SearchBar 
                                  search_data={{}}
                                  default_filter={{ name: "release_version", label: "Search Package" }}
                                  search_call_back={invokeSearch}
                                  clear_search_callback={getReleasePackageList}
                                  backgrounColor={"#FAFAFA"}
                                  border={"1px solid #E6E6E6"}
                              />
                          </div>
                          <div style={{width:'40%'}}>
                              <Pagination
                                  total_count={state.total_page}
                                  current_page_count={state.curr_page}
                                  next={state.next}
                                  previous={state.previous}
                                  on_previous_click={() => {
                                      fetchPrevReleasePackages(null, state.previous);
                                  }}
                                  on_next_click={() => {
                                      fetchNextReleasePackages(null, state.next);
                                  }}
                                  on_pageNumber_click={(pageNumber) => {
                                      fetchPageReleasePackages(pageNumber);
                                  }}
                                  skeleton={state.loading}
                              />
                          </div>
                      </div>
                          {state.loading ? 
                              Array.from({length : 3}).map((_,index)=>{
                                  return <ReleasePackageSkeleton key={index} />
                              })
                          : 
                              state.data.releasePackageList.length <= 0 ? 
                                  <div className='d-flex align-center justify-center' style={{height:"40vh"}}>
                                      <div className='d-flex align-center f-direction-column' style={{gap:"12px"}}>
                                          <div className={classes.iconContainer}>
                                              <span className='ri-inbox-line font-28'></span>
                                          </div>
                                          <span className='font-18 font-weight-600 color-value'>No Release package Present</span>
                                      </div>
                                  </div>
                              : 
                                  <div className={classes.packageList}>
                                      {state.data.releasePackageList.map((item,key)=>{
                                          const isSelected = item.id == state.data.selectedReleasePackage;
                                          return (
                                              <div className={clsx(classes.releasePackage, { 'selected': isSelected })} key={key} onClick={()=>selectReleasePackage(item.id)}>
                                                  <div className={clsx(classes.iconContainer,{ 'selectedIcon': isSelected})}>
                                                      <span className='ri-inbox-line font-28'></span>
                                                  </div>
                                                  <div className='d-flex f-direction-column'>
                                                      <span className={`${classes.releaseVersion} font-16 font-weight-600 color-value text-ellipsis`} onClick={(e)=>{e.stopPropagation();setOpenedReleasePackage(item.id)}}>{item.release_version}</span>
                                                      <span className='font-14 font-weight-600 color-key-78 text-ellipsis'>{`${item.change_set.length} Services`}</span>
                                                  </div>
                                                  <div className={classes.description}>
                                                      <span className='font-12 font-weight-500 color-icon-secondary'>
                                                          {item.release_description}
                                                      </span>
                                                  </div>
                                              </div>
                                          );
                                      })}
                                  </div>
                              
                          
                          }
                  </div>
              </div>
            </>
          }
          <div className={classes.snackbar}>
            <Snackbar
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={
                state.error?.validation_error
                }
                onClose={handleClosePipelineTrigger}
                autoHideDuration={3000}
              >
                
                  <Alert severity="error">
                    
                      {state.error?.validation_error}
                    
                  </Alert>
              </Snackbar>
          </div>
          
          <div className={classes.footer}>
              <div className='transparent-btn cursor-pointer' onClick={toggleDismiss}>Cancel</div>
              <button className="btn btn-primary" onClick={validateAndTriggerPipeline} >Trigger Pipeline</button>
          </div>
      </>    
    )
}

export default PipelineReleasePackage

const ReleasePackageSkeleton = ()=>{
    const classes = styles();
    return (
        <div className={classes.releasePackage}>
            <GenericSkeleton width={56} height={56} style={{borderRadius: "8px"}}/>
            <div className='d-flex f-direction-column' style={{gap:'4px'}}>
                <GenericSkeleton height={24} />
                <GenericSkeleton height={21} />
            </div>
            <GenericSkeleton height={56} />
        </div>
    )
}

const styles = makeStyles((theme)=>({
    root:{
        marginTop:'12px',
        paddingLeft: '10px',
        display:'flex',
        flexDirection:'column',
        gap:'16px',
    },
    packageList: {
        display:'flex',
        flexDirection:'column',
        gap:'10px',
        height:"50vh",
        // borderTop:"1px solid #DFDEDE",
        // borderBottom:"1px solid #DFDEDE",
        overflow:"auto",
    },
    releasePackage:{
        padding:'12px',
        border:'1px solid #E6E6E6',
        borderRadius:'6px',
        backgrounColor:'#FFFFFF',
        display:'grid',
        alignItems:'center',
        gridTemplateColumns:'56px 10% 1fr',
        gap:'16px',
        cursor:'pointer',
        "&.selected": {
            backgroundColor: '#F5FAFF',
            border: '1px solid #0086FF',
        },
    },
    releaseVersion:{
        '&:hover':{
            cursor:'pointer',
            color:'#0086FF !important',
            textDecoration: 'underline !important',
        }
    },
    iconContainer:{
        width:"56px",
        height:"56px",
        borderRadius: "8px",
        backgroundColor:"rgba(0, 134, 255, 0.08)",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        color:"#0086FF",
        "&.selectedIcon": {
            backgroundColor: '#0086FF',
            color:'#FFFFFF',
        }
    },
    description: {
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3, 
        textOverflow: 'ellipsis',
    },
    footer:{
        background:"#FFFFFF",
        display:"flex",
        justifyContent:"flex-end",
        gap:"12px",
        "& .transparent-btn":{
            padding:"8px 16px",
            textTransform:"uppercase",
            height:"40px",
            fontSize:"12px",
            fontWeight:"600",
            color:"#2F2F2F",
            borderRadius:"6px",
            transition:"all 0.4s ease-in-out",
            "&:hover":{
                backgroundColor:"#f2f0f0",
            }
        },
        "& .back-btn":{
            padding:"8px 16px",
            textTransform:"uppercase",
            height:"40px",
            fontSize:"12px",
            fontWeight:"600",
            color:"#124D9B",
            borderRadius:"6px",
            border:"1px solid #9DC0EE",
            backgroundColor:"#ffffff",
            transition:"all 0.4s ease-in-out",

            "&:hover":{
                backgroundColor:"#0086FF",
                color:"#ffffff",
                borderColor:"#0086FF"
            }
        }
    },
    snackbar:{
      '& .MuiPaper-root':{
        height:'fit-content !important',
        backgroundColor:'#e53935 !important',
      }
    }
}))