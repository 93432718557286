import React, { useState, useEffect } from "react";
import { Dialog, Grid, Snackbar } from "@material-ui/core";
import InvokeApi, { PostData } from "../../../util/apiInvoker";
import { Link, Redirect } from "react-router-dom";
import {VALIDATION_TYPE_REQUIRED, ValidateDataSet } from "../../../util/Validator";
import { makeStyles } from "@material-ui/styles";
import { styled } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Loading } from "../../utils/Loading";
import { ErrorComponent } from "../../utils/Error";
import AddIcon from "@material-ui/icons/Add";
import AlertStrip from "../../../components/AlertStrips";
import { getListableArray } from "../../../util/util";
import { Input } from "../../../components/genericComponents/Input";
import properties from "../../../properties/properties";
import GenerateURL, { GenerateSearchURL } from "../../../util/APIUrlProvider";
import { getCommonFunctions } from "../../serviceRevamp/add/ci_flow/SourceDetails";
import { Alert } from "@material-ui/lab";

const AddEnvToMS = (props) => {
    // const { application_id, component_id } = props.match.params;
    const application_id = props.application_id ? props.application_id : null;
    const componentData = props.componentData ? props.componentData : null;
    console.log(componentData,"componentDatacomponentData")
    const component_id = componentData.id ? componentData.id : null
    const component_id_number = Number(component_id);
    const classes = useStyles();
    const inherits = props.inherits ? props.inherits : {};
    const [canRedirect, setRedirect] = useState(false);
    const [serviceId, setServiceId] = useState("1");
    const handleClose = props.handleClose ? props.handleClose : () => { };
    const refreshState = props.refreshState ? props.refreshState : () => { };
    const [showLoading, setShowLoading] = useState(false);


    const edit = props.location
        ? props.location.state
            ? props.location.state.edit
            : false
        : false;
    const env_id = props.location
        ? props.location.state
            ? props.location.state.env_id
            : null
        : null;

    const [state, setState] = useState({
        data: {
            roles: [],
            manual_build: "false",
            manual_deploy: "false",
            hyperbuild: 'false',
            job_template_version: 'v2'
        },
        error_msg: null,
        data_loading: false,
        canRedirect: false,
        option_list: [
            { label: "Yes", value: "true" },
            { label: "No", value: "false" },
        ],
        template_option: [
            { label: "Job Template Version 2", value: 'v2' },
            { label: "Job Template Version 3", value: 'v3' },
        ],
        error: {},
        validations: {
            env_id: [VALIDATION_TYPE_REQUIRED],
            // roles: [VALIDATION_TYPE_REQUIRED],
            // image_name: [VALIDATION_TYPE_REQUIRED],
            // registry_id: [VALIDATION_TYPE_REQUIRED]
        },
        job_template_list: [],
        env_list: [],
        fetching_env_list: true,
        roles_list: [],
        fileterdByVersionTemplates: []
    });

    useEffect(() => {

        if (state.job_template_list?.length > 0) {
            let defaultTempalteId = state.job_template_list?.find(jobTemplate => {
                if (jobTemplate.default) {
                    return jobTemplate
                }
            })?.id

            setState(prevState => ({
                ...prevState,
                data: { ...prevState.data, project_job_template_id: defaultTempalteId }
            }))
        }
    }, [state.job_template_list])

    const [showInput, setShowInput] = useState(false);
    const [data, setData] = useState({ data: {}, error: {} })
    const showInfoSection = JSON.parse(localStorage.getItem('show_ms_env_other_info'));

    useEffect(() => {
        if (state?.job_template_list?.length > 0 && state.data.job_template_version) {
            if (state.data.job_template_version === 'v2') {
                const v2Templates = state.job_template_list.filter(tempalte => tempalte.version === 'v2')
                setState(prevState => ({
                    ...prevState,
                    fileterdByVersionTemplates: v2Templates
                }))
            }
            else if (state.data.job_template_version === 'v3') {
                const v3Templates = state.job_template_list.filter(tempalte => tempalte.version === 'v3')

                setState(prevState => ({
                    ...prevState,
                    fileterdByVersionTemplates: v3Templates
                }))
            }
        }

    }, [state.job_template_list, state.data.job_template_version])


    useEffect(() => {

        if (env_id !== undefined && state.job_template_list !== undefined && state.data?.project_job_template_id != undefined) {
            const version = state.job_template_list?.find(template => template.id === state?.data?.project_job_template_id)?.version
            if (version) {
                setState(prevState => ({
                    ...prevState,
                    data: {
                        ...prevState.data,
                        job_template_version: version
                    }
                }))
            }
        }
    }, [env_id, state.job_template_list, state.data?.project_job_template_id])

    useEffect(() => {
        if (component_id) {
            getService();
        }

        fetchClusterInfo();
        fetchAssociatedVMInfo();
    }, []);

    // associated_vm_data

    function fetchAssociatedVMInfo() {
        var requestInfo = {
            endPoint: GenerateURL({ project_id: application_id }, properties.api.associated_vm_data),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, handlefetchAssociatedVMSuccessApiHit, handlefetchAssociatedVMFailApiHit);
        setState((new_state) => ({
            ...new_state,
            data_loading: true,
        }));
    }
    const handlefetchAssociatedVMSuccessApiHit = (response) => {

        setState(new_state => ({
            ...new_state,
            data_loading: false,
            response_data: response,
        }));
    }

    const handlefetchAssociatedVMFailApiHit = (error) => {
        setState(new_state => ({
            ...new_state,
            data_loading: false,
        }));
    }

    function fetchClusterInfo() {
        var requestInfo = {
            endPoint: GenerateURL({ project_id: application_id }, properties.api.env_form_data),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, handleSuccessApiHit, handleFailApiHit);
        setState((new_state) => ({
            ...new_state,
            data_loading: true,
        }));
    }
    const handleSuccessApiHit = (response) => {

        setState(new_state => ({
            ...new_state,
            data_loading: false,
            response_data: response,
        }));
    }

    const handleFailApiHit = (error) => {
        setState(new_state => ({
            ...new_state,
            data_loading: false,
        }));
    }

    function getService() {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: component_id }, properties.api.service_basic_details_get_url),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        };
        setState((new_state) => ({
            ...new_state,
            data_loading: true,
        }));
        InvokeApi(requestInfo, handleGetServiceSuccess, handleGetServiceFailed);
    }
    const handleGetServiceSuccess = (data) => {
        fetchSystemSettingsData()
        setState((new_state) => ({
            ...new_state,
            data_loading: false,
            service_name: data && data.name
        }));
    }
    const handleGetServiceFailed = (data) => {
        setState((new_state) => ({
            ...new_state,
            data_loading: false,
            service_name: null
        }));
    }
    const commonFunctions = getCommonFunctions(state, setState, inherits);

    function validateAndSave() {
        console.log(state.data, "dsjbjsdbchsbch")
        var result = ValidateDataSet(state.data, state.validations);

        var post_url = GenerateURL(
            { service_id: component_id_number },
            properties.api.env_data_post_url
        );
        if (env_id) {
            post_url = GenerateURL(
                { service_id: component_id_number, env_id: Number(env_id) },
                properties.api.env_data_update_url
            );
        }
        if (result.valid) {
            // var env_role_Access = getAssignedAccess(
            //     state.data.roles,
            //     state.roles_list
            // );
            var env_role_Access = []
            var post_data = {
                component_id: component_id_number,
                project_env_id: state.data.env_id,
                image_name: state.data&& state.data.image_name?state.data.image_name : null,
                env_role_access: env_role_Access,
                manual_build: state.data.manual_build == "true" ? true : false,
                manual_deploy: state.data.manual_deploy == "true" ? true : false,
                hyperbuild: state.data.hyperbuild == "true" ? true : false,
                registry_id: state.data && state.data.registry_id ? state.data.registry_id : null,
                artifact_name: state.data && state.data.artifact_name ? state.data.artifact_name : null,
                project_job_template_id: state.data.project_job_template_id
                    ? state.data.project_job_template_id
                    : null,
            };
            setState((new_state) => ({
                ...new_state,
                data_loading: true,
                error_msg: null,
            }));
            PostData(post_url, post_data, postSuccess, postFail);
        } else {
            setState((new_state) => ({
                ...new_state,
                error: result.error,
            }));
        }
    }

    function postSuccess(response) {
        setServiceId(response.component_id);
        setState((new_state) => ({
            ...new_state,
            new_env_id: response.id,
            data_loading: false,
            error_msg: null,
            canRedirect: true,
        }));
    }

    function postFail(response) {
        setState((new_state) => ({
            ...new_state,
            data_loading: false,
            error_msg: response.toString(),
        }));
    }


    const handleFailedApiHit = (error) => {
        var error_msg = error.toString();
        setState((new_state) => ({
            ...new_state,
            data_loading: false,
            error_msg: "Unable to fetch data due to following reasons: " + error_msg,
        }));
    };
    const handleSuccessRoleApiHit = (response) => {
        var ui_roles = mapRoles(response);
        setState((new_state) => ({
            ...new_state,
            data_loading: false,
            roles_list: ui_roles,
        }));
    };


    function getProjectRoles() {
        var requestInfo = {
            endPoint: GenerateURL(
                { application_id: application_id },
                properties.api.application_role_detail_url
            ),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };

        InvokeApi(requestInfo, handleSuccessRoleApiHit, handleFailedApiHit);
        setState((new_state) => ({
            ...new_state,
            data_loading: true,
        }));
    }

    function getEnvList() {
        setState((new_state) => ({
            ...new_state,
            data_loading: true,
            fetching_env_list: true,
        }));
        var url_temp = GenerateURL(
            { application_id: application_id },
            properties.api.env_listing
        );
        var requestInfo = {
            endPoint: GenerateSearchURL({ all: true }, url_temp),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };
        InvokeApi(requestInfo, fetchSuccessEnvListing, fetchFailEnvListing);

    }

    function fetchSuccessEnvListing(response) {
        getProjectRoles();
        fetchJobTemplatesList();
        const EnvNames = [];
        response.results.forEach((element) => {

            EnvNames.push({
                label: element.name,
                id: element.id,
                manual_build: element.manual_build,
                manual_deploy: element.manual_deploy,
                hyperbuild: element.hyperbuild,
                type: element.environment_master && element.environment_master.name ? element.environment_master.name : null,
                cluster_available: element.cluster_id ? element.cluster_id : null,
                vm_group_available: element.vm_group_id ? element.vm_group_id : null,
            });
        });
        if (env_id) {
            getComponentEnvData();
        }
        setState((new_state) => ({
            ...new_state,
            env_list: EnvNames,
            env_data: response.results,
            data_loading: false,
            fetching_env_list: false,
        }));
    }

    function fetchFailEnvListing(error) {
        setState((new_state) => ({
            ...new_state,
            error_msg: error.toString(),
            data_loading: false,
            fetching_env_list: false,
        }));
    }

    useEffect(() => {
        getEnvList();

    }, []);

    useEffect(() => {
        if (state.data.env_id) {
            getNamespaceId()
        }
    }, [state.data.env_id])

    console.log(state, "svbgshvchjsdbvhjbsdvj")
    useEffect(() => {
        if (state.selected_namespace_id) {
            getRegistryList()
        }
    }, [state.selected_namespace_id])

    useEffect(() => {
        if (state.available_registries) {
            getRegistryListOptions()
        }
    }, [state.available_registries])


    function getRegistryList() {
        console.log(state.response_data, state.selected_namespace_id, "zcvghdvcscvsdhvc")
        var available_registries = []
        let selected_data = state.response_data && state.response_data.length > 0 && state.response_data.find((item) => {
            if (item.project_cluster_namespace && item.project_cluster_namespace.length > 0) {
                return item.project_cluster_namespace.find((single_item) =>
                    single_item.namespace_id == state.selected_namespace_id
                )
            }
        })
        console.log(selected_data, "selected_dataselected_data")
        let selected_project_cluster_namespace = selected_data && selected_data.project_cluster_namespace
        console.log(selected_project_cluster_namespace, "sbvhjbhjvbfvjfd")
        // state.response_data && state.response_data[0] && state.response_data[0].project_cluster_namespace.forEach((item) => {
        //     if (item.namespace_id == state.selected_namespace_id) {
        //         available_registries = item.registries
        //     }
        // })
        selected_project_cluster_namespace && selected_project_cluster_namespace.forEach((item) => {
            if (item.namespace_id == state.selected_namespace_id) {
                available_registries = item.registries
            }
        })
        setState((new_state) => ({
            ...new_state,
            available_registries: available_registries ? available_registries : []
        }))
    }

    function getRegistryListOptions() {
        var registry_list = []
        state.available_registries.forEach((item) => {
            if (state.selected_registry_id && state.selected_registry_id.includes(item.id)) {
                registry_list.push(item)
            }
        })
        console.log(registry_list, "sbhvcscdfgvd")
        setState((new_state) => ({
            ...new_state,
            registry_option_list: getListableArray(registry_list),
            data: {
                ...new_state.data,
                registry_id: registry_list.length == 1 ? registry_list[0].id : null,
            }
        }))
    }

    function getComponentEnvData() {
        var url_temp = GenerateURL(
            { service_id: component_id_number, env_id: Number(env_id) },
            properties.api.env_data_update_url
        );
        var requestInfo = {
            endPoint: url_temp,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };
        InvokeApi(requestInfo, getEnvDataSuccess, getEnvDataFail);
        setState((new_state) => ({
            ...new_state,
            data_loading: true,
        }));
    }

    function getEnvDataSuccess(response) {
        console.log(response, "sdbchsbjsbs")
        setState((new_state) => ({
            ...new_state,
            // ...response,
            data: {
                ...new_state.data,
                env_id: response.project_env_id,
                roles: getRolesEdit(response.env_role_access),
                project_job_template_id: response.project_job_template_id,
                image_name: response.image_name,
                manual_build:
                    response.project_env && response.project_env.manual_build
                        ? "true"
                        : "false",
                manual_deploy:
                    response.project_env && response.project_env.manual_deploy
                        ? "true"
                        : "false",
                hyperbuild: response.hyperbuild ? "true" : "false",
                selectedEnv: getSelectedEnv(
                    response.project_env.id,
                    new_state.env_list
                ),
                registry_id: response.registry_id,
                artifact_name: response.artifact_name
            },
            data_loading: false,
        }));
    }

    function getSelectedRegistries() {

    }

    function getRolesEdit(data) {
        var roles = new Array();

        for (let i = 0; i < data.length; i++) {
            if (data[i].access) {
                roles = [...roles, properties.roles[i].label];
            }
        }
        return roles;
    }

    function setErrorNull(setState) {
        setTimeout(() => {
            setState(prevState => ({
                ...prevState,
                error_null: null
            }))
        }, 3500);
    }

    useEffect(() => {
        if (state.error_null) {
            setErrorNull(setState);
        }
    }, [state.error_null]);

    function getEnvDataFail(error) {
        setState((new_state) => ({
            ...new_state,
            error_msg: error.toString(),
            data_loading: false,
        }));
    }

    function onChangeSelectHandler(e) {
        if (state.env_list.length > 0) {
            var selectedEnv = getSelectedEnv(e.target.value, state.env_list);
            var new_data_elements = {
                [e.target.name]: e.target.value,
                manual_build: selectedEnv?.manual_build ? "true" : "false",
                manual_deploy: selectedEnv?.manual_deploy ? "true" : "false",
                selectedEnv: selectedEnv,
                cluster_available: selectedEnv.cluster_available,
                vm_group_available: selectedEnv.vm_group_available
            };
            commonFunctions.updateMultipleData(new_data_elements, e.target.name);
            setServiceName(selectedEnv, e.target.value)
        }
    }
    const setServiceName = (selectedEnv, env_type) => {
        setState((new_state) => ({
            ...new_state,
            data: {
                ...new_state.data,
                image_name: new_state?.service_name + "/" + selectedEnv?.type + "/" + selectedEnv?.label
            }
        }));
    }
    function getSelectedEnv(env_id, env_list) {
        var selected_env = null;
        env_list.forEach((env) => {
            if (env.id == env_id) {
                selected_env = env;
            }
        });
        return selected_env;
    }



    function fetchJobTemplatesList() {
        var requestInfo = {
            endPoint: GenerateURL(
                { application_id: application_id },
                properties.api.get_all_job_template + "?all=true"
            ),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };
        InvokeApi(
            requestInfo,
            fetchJobTemplatesListSuccess,
            fetchJobTemplatesListFailure
        );
        setState((new_state) => ({
            ...new_state,
            data_loading: true,
        }));
    }
    function fetchJobTemplatesListSuccess(response) {
        setState((new_state) => ({
            ...new_state,
            job_template_list: response?.results ? response.results : [],
            data_loading: false,
        }));
    }
    function fetchJobTemplatesListFailure(error) {
        var error_msg = error.toString();
        setState((new_state) => ({
            ...new_state,
            error: error_msg,
            data_loading: false,
        }));
    }

    function fetchSystemSettingsData() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.system_settings),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, fetchSystemSettingsDataSuccess, fetchSystemSettingsDataFailed);

        setState(new_state => ({
            ...new_state,
            data_loading: true
        }))

    }

    function fetchSystemSettingsDataSuccess(data) {
        var hyper_build_flag = data.filter(item => item.key == "HYPERBUILD_ENABLE_FLAG");
        setState(new_state => ({
            ...new_state,
            hyper_build_flag: hyper_build_flag,
        }));

    }
    function fetchSystemSettingsDataFailed(error) {

        setState(new_state => ({
            ...new_state,
            error: error,
            data_loading: false
        }))
    }
    const onChangeHandlerRadio = (e) => {
        const key = e.target.name;
        var value = e.target.value;
        updateData(key, value);
    }
    function updateData(k, v) {
        setState(new_state => ({
            ...new_state,
            data: {
                ...new_state.data,
                [k]: v
            },
            error: {
                ...new_state.error,
                [k]: ""
            }
        }))
    }

    if (state.data_loading || state.fetching_env_list) {
        return (
            <div className="" style={{ height: "500px" }}>
                <Loading varient="light" />
            </div>
        );
    }

    function getNamespaceId() {
        var selected_env_id = state.data.env_id
        var selected_env_data;
        var supported_registeries = []

        state.env_data.forEach((item) => {
            if (item.id == Number(selected_env_id)) {
                selected_env_data = item
            }
        })

        setState((new_state) => ({
            ...new_state,
            selected_namespace_id: selected_env_data && selected_env_data.project_env_namespace ? selected_env_data.project_env_namespace.namespace_id : null,
            selected_registry_id: selected_env_data && selected_env_data.registry ? selected_env_data.registry : null
        }))

        // return supported_registeries;
    }

    console.log(state, "sdcbsbjbd")


    function onChangeHandlerForKeyValue(key, value) {
        console.log(key, value, "sdcsjdcsbcbsdhc")
        if (key == "registry_id") {
            value.forEach((id, index) => {
                value[index] = Number(id);
            });
        }
        setState((new_state) => ({
            ...new_state,
            data: {
                ...new_state.data,
                [key]: value
            },
            error: {
                ...new_state.error,
                [key]: null,
            }
        }))
    }

    console.log(state, "sjdbcdbsdcsdb")
    const handleCloseLeftStip = () => {
        if (data?.data?.dont_show_again) {
            setDataToLocalStorage()
        }
        setState(new_state => ({
            ...new_state,
            showLeftPanel: false
        }));
    }
    function setDataToLocalStorage() {
        localStorage.setItem('show_ms_other_info', data.data.dont_show_again);
    }
    function onChangeDoNotshow(e) {
        var key = e.target.name;
        var value = e.target.value;
        if (key == "dont_show_again") {
            value = !data.data.dont_show_again;
        }
        setData((prevState) => ({
            ...prevState,
            data: {
                ...prevState.data,
                [key]: value,
            },
            error: {
                ...prevState.error,
                [key]: '', // Reset error on change
            },
        }));
    }

    const handleCloseAlert = () => {
        setState({ ...state, show_save_msg: null, });
    };


    return (
        <>
            {
                canRedirect ?
                    component_id ? <Redirect to={"/application/" + application_id + "/service/" + component_id + "/detail"} /> :
                        <Redirect to={"/application/" + application_id + "/service/" + state.service_id + "/env/add"} /> : null
            }
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
                className={`${classes.root} dialog-align-corner`}
                aria-labelledby="max-width-dialog-title"
            >
                <div className='d-grid ml-auto dialog-sub-component' style={showInfoSection ? { gridTemplateColumns: '650px' } : { gridTemplateColumns: '396px 650px' }}>
                    {
                        !showInfoSection &&

                        <div className={state.showLeftPanel ? 'left-panel-dialog bg-white position-relative' : 'left-panel-dialog-down'}>
                            <div className={'d-flex align-center space-between left-panel-header pd-10'} style={{ padding: '10px 20px' }} >
                                <p>Information</p>
                                <button
                                    className='btn btn-icon-only'
                                    onClick={handleCloseLeftStip}
                                >
                                    <span className='ri-close-line color-icon-secondary'></span>
                                </button>
                            </div>
                            <div className='pd-10' style={{ padding: '10px 20px' }}>
                                <p className='font-16 font-weight-600 color-primary mb-10'>Create a Environment
                                </p>
                                <p style={{ color: "#fff", paddingTop: "25px" }}>
                                    A Environment aims to mitigate such scenarios by encouraging
                                    automation and documentation, there by increasing communication. In
                                    this environment, every release is committed in an automated fashion,
                                    enabling the rapid building, testing and deployment of every project.
                                    <br /> <br />
                                    To learn more about how to setup a Microservice please read{" "}
                                    <span style={{ color: "#e89317" }}>
                                        Documentation{" "}
                                    </span>
                                </p>
                            </div>
                            <div className='checkbox-only-divi' style={{ padding: '10px 20px' }}>
                                <Input
                                    type="simple-checkbox"
                                    name="dont_show_again"
                                    label="Dont show this again"
                                    mandatorySign
                                    data={data.data}
                                    error={data.error}
                                    onChangeHandler={onChangeDoNotshow}
                                />
                            </div>
                        </div>
                    }
                    <div className='right-panel-dialog bg-white'>
                        <>
                            <div className='font-18 font-weight-600 color-white d-flex align-center space-between' style={{ backgroundColor: '#0086ff', padding: '13.5px 20px' }}>
                                <p>{component_id ? "Edit" : "Add"} Micro Service 11</p>
                                <button
                                    className='btn float-cancel-button'
                                    style={showInfoSection ? { left: '396px' } : state.showLeftPanel ? {} : { left: '396px' }}
                                    onClick={handleClose}
                                >
                                    <span className='ri-close-line'></span>
                                </button>
                            </div>
                            {
                                state.error_msg ? <AlertStrip message={state.error_msg} /> : null
                            }
                            {
                                showLoading ?
                                    <Loading varient="light" />
                                    :
                                    <>
                                        <div className='' style={{ padding: '10px 16px' }}>
                                        <div className="d-flex align-center justify-flexend">
                                                        <Link
                                                            className="text-anchor-blue"
                                                            style={{ display: "flex" }}
                                                            to={"/application/" + application_id + "/env/add"}
                                                        >
                                                            {/* <AddIcon /> */}
                                                            <span>Add Environment</span>
                                                        </Link>
                                                    </div>
                                                    <div className="">
                                                        <Input
                                                            type="select"
                                                            mandatorySign
                                                            name="env_id"
                                                            label="Select Environment"
                                                            list={state.env_list}
                                                            error={state.error}
                                                            onChangeHandler={onChangeSelectHandler}
                                                            data={state.data}
                                                        />
                                                    </div>
                                                    { state.data.selectedEnv && state.data.selectedEnv.cluster_available ?
                                                     state.data.env_id ?
                                                        <Grid item lg={12} className="" style={{ marginTop: "20px" }}>
                                                            <Input
                                                                type="select"
                                                                name="registry_id"
                                                                mandatorySign
                                                                list={state.registry_option_list ? state.registry_option_list : []}
                                                                label="Select Registry"
                                                                onChangeHandler={commonFunctions.onChangeHandler}
                                                                data={state.data}
                                                                error={state.error} />

                                                        </Grid> 
                                                        :null
                                                        :null
                                                    }
                                                    {state.data.selectedEnv && state.data.selectedEnv.cluster_available ?
                                                    <Grid item lg={12} style={{ marginTop: "20px" }}>
                                                        <Input
                                                            type="text"
                                                            placeholder="imagename"
                                                            name="image_name"
                                                            mandatorySign
                                                            label="Image Name"
                                                            error={state.error}
                                                            onChangeHandler={commonFunctions.onChangeHandler}
                                                            data={state.data}
                                                        />
                                                    </Grid>
                                                    :
                                                    <Grid item lg={12} style={{ marginTop: "20px" }}>
                                                        <Input
                                                            type="text"
                                                            placeholder="Enter Artifact Name"
                                                            name="artifact_name"
                                                            mandatorySign
                                                            label="Artifact Name"
                                                            error={state.error}
                                                            onChangeHandler={commonFunctions.onChangeHandler}
                                                            data={state.data}
                                                        />
                                                    </Grid>}
                                                    <div
                                                        className="d-grid align-center space-between radio-cont"
                                                        style={{ gridTemplateColumns: "1fr 1fr", gap: 20 }}
                                                    >
                                                        {state.data.selectedEnv ? (
                                                            state.data.selectedEnv.manual_build ? (
                                                                <Input
                                                                    type="radio"
                                                                    list={state.option_list}
                                                                    label={"Allow Manual Build"}
                                                                    name="manual_build"
                                                                    mandatorySign
                                                                    onChangeHandler={
                                                                        commonFunctions.onChangeHandler
                                                                    }
                                                                    data={state.data}
                                                                    error={state.error}
                                                                />
                                                            ) : (
                                                                <div style={{ marginTop: '12px' }}>
                                                                    <AlertStrip
                                                                        variant="info"
                                                                        message="Manual Build Not Allowed"
                                                                    />
                                                                </div>

                                                            )
                                                        ) : null}

                                                        {state.data.selectedEnv ? (
                                                            state.data.selectedEnv.manual_deploy ? (
                                                                <Input
                                                                    type="radio"
                                                                    list={state.option_list}
                                                                    label="Allow Manual Deploy"
                                                                    name="manual_deploy"
                                                                    mandatorySign
                                                                    onChangeHandler={
                                                                        commonFunctions.onChangeHandler
                                                                    }
                                                                    data={state.data}
                                                                    error={state.error}
                                                                />
                                                            ) : (
                                                                <div style={{ marginTop: '12px' }}>
                                                                    <AlertStrip
                                                                        variant="info"
                                                                        message="Manual Deploy Not Allowed"
                                                                    />
                                                                </div>
                                                            )
                                                        ) : null}
                                                    </div>

                                                    <div className="" style={{ marginTop: '30px' }}>

                                                        <div className="version_change">
                                                            <Input
                                                                type="radio"
                                                                list={state.template_option}
                                                                label="Select Job Template"
                                                                name="job_template_version"
                                                                mandatorySign
                                                                onChangeHandler={
                                                                    commonFunctions.onChangeHandler
                                                                }
                                                                data={state.data}
                                                                error={state.error}
                                                            />

                                                        </div>
                                                        <div className="select_version">
                                                            <Input
                                                                type="select"
                                                                name="project_job_template_id"
                                                                label={state?.data?.job_template_version === 'v2' ? 'Select Job Template From Version 2' : 'Select Job Template From Version 3'}
                                                                list={getListableArray(state.fileterdByVersionTemplates)}
                                                                error={state.error}
                                                                mandatorySign
                                                                onChangeHandler={commonFunctions.onChangeHandler}
                                                                data={state.data}
                                                            />
                                                            <div className="version-description font-12">
                                                                {
                                                                    state?.data?.job_template_version === 'v2' ?
                                                                        <p>
                                                                            Automate Build &amp; Deployment process by using
                                                                            pre-configured Job Templates
                                                                        </p> :
                                                                        <p>
                                                                            veriosn 3 Job Templates are more flexible templates
                                                                            and allows you to configure
                                                                        </p>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>

                                        </div>
                                        <div className='footer-right-panel d-flex align-center justify-end'>
                                            <button className='btn btn-outline-grey font-12 font-weight-500 d-flex align-center justify-center' onClick={handleClose}>Cancel</button>
                                            <button className='btn btn-primary font-12 font-weight-500 d-flex align-center justify-center' onClick={validateAndSave}>Save &amp; Continue</button>
                                        </div>  
                                    </>
                            }

                        </>


                    </div>
                </div>
            </Dialog>
           
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={state.show_save_msg === "success" || state.show_save_msg === "failed"}
                onClose={handleCloseAlert}
                autoHideDuration={3000}>
                {
                    state.show_save_msg === "success" ?
                        <>
                            <Alert severity="success">
                                {state.success_msg}
                            </Alert>
                        </> : state.show_save_msg === "failed" ?
                            <Alert severity="error">
                                {state.error_in_save}
                            </Alert>

                            : null
                }
            </Snackbar>
        </>
    )
}

export default AddEnvToMS


const useStyles = makeStyles((theme) => ({
    root: {
        '&.dialog-align-corner': {
            '& .MuiPaper-root': {
                maxWidth: '1100px'
            }

        },
        '& .input-with-icon': {
            position: 'relative',
            '& .cent-icon': {
                width: '40px',
                height: '44px',
                backgroundColor: '#fafafa',
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '14px',
                fontWeight: 500,
                top: '22px',
                right: '1px',
                borderRadius: '0px 4px 4px 0px',
                borderLeft: '1px solid #b7b7b7'
            }
        },
        '& .left-panel-dialog': {
            width: '396px',
            transition: 'width 5s',

            '& .left-panel-header': {
                borderBottom: '1px solid #f1f1f1'
            },
            '& .checkbox-only-divi': {
                position: 'absolute',
                bottom: '10px'
            },
        },
        '& .left-panel-dialog-down': {
            width: '0px',
            overflow: 'hidden',
            transition: `'width 5s', 'overflow 1s'`,

        },
        '& .footer-right-panel': {
            backgroundColor: '#fafafa',
            padding: '20px',
            position: 'absolute',
            bottom: '0px',
            width: '650px',

            // width:'100%',
            '& button': {
                // marginLeft: 'auto'
            }
        }
    }
}));