import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import PublishIcon from '@material-ui/icons/Publish';

import {Link} from 'react-router-dom';
const AddClusterFirstScreen = () => {
    const classes = useStyles();
    const [spacing, setSpacing] = React.useState(2);
    return (
        <div className={classes.root}>
            <div className="head" style={{ }}>
                <div className="heading-section-service">
                    <div className="main-heading">Add Cluster</div>
                    <div className="sub-heading-text">Please follow the process to add a cluster</div>
                </div>
                <div className="icon-section"></div>
            </div>
            <div className={classes.container}>

                <div className={classes.card} >
                    <div className={classes.cardHeader} >
                        <PublishIcon style={{ fontSize: '48px', color: '#fea23a', margin: 'auto' }} />
                        <p className="mainheading">Onboard Your cluster</p>
                    </div>
                    <div className={classes.cardBody}>
                        <p>Onboarding an existing cluster involves registering the existing cluster with BuildPiper. To register the cluster we need the kube-config Yaml file along with the ability to communicate with the cluster.
                            </p>
                    </div>
                    <div  className={classes.cardFooter}>
                    <Link to="/cluster/new/onboard" className="btn btn-full-with btn-green  m-auto">Continue with cluster Onboarding</Link>
                    </div>
                   
                </div>
                 <div className={classes.card}>
                    <div className='coming-soon-chip'>Coming soon</div>
                    <div className={classes.cardHeader}>
                    <img src="/images/logos/aws.png" className="img-icon" alt=".." />
                        <p className="mainheading">Create New Cluster in AWS</p>
                    </div>
                    <div className={classes.cardBody}>
                        <p>Use this option to setup Production grade BuildPiper Managed Kubernetes in AWS. Once infra detail and the cluster details are provided, BuildPiper will Setup the necessary infra and completely functional Kubernetes Cluster in you AWS account.
                        </p>
                    </div>
                    <div  className={classes.cardFooter}>
                        <Link to="#" className="btn btn-full-with btn-green m-auto cursor-not-allowed">Coming Soon</Link>
                    </div>
                </div> 
                <div className={classes.card}>
                <div className='coming-soon-chip'>Coming soon</div>
                    <div className={classes.cardHeader}>
                        <img src="/images/logos/ldc_icon.png" className="img-icon" alt=".." />
                        <p className="mainheading">Create New Cluster in LDC</p>
                    </div>
                    <div className={classes.cardBody}>
                        <p>Use this option to setup Production grade BuildPiper Managed Kubernetes in Local Data Center. We will need the VM details, Networking Details and Cluster details to setup a completely functional Kubernetes Cluster in your LDC.
                            </p>
                    </div>
                    <div  className={classes.cardFooter}>
                        <Link to="#" className="btn btn-full-with btn-green m-auto cursor-not-allowed" >Coming Soon</Link>
                    </div>
                </div> 
            </div>
        </div>
    )
}
export default AddClusterFirstScreen;

const useStyles = makeStyles(theme => ({
    root: {
        padding: '20px',
        '&.d-flex':{
            '&.align-center':{
                height:'100%'
            }
        }
    },
    container: {
        display: 'grid',
        gap: '20px',
        gridTemplateColumns:'30% 30% 30%',
        height: '100%',
        width: '100%',
        marginTop: '13rem',
        justifyContent:'center'
    },
    card: {
        borderRadius: '4px',
        boxShadow: 'rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px',
        background: '#fff',
        border: '1px solid #d5d5d5',
        overflow: 'hidden',
        maxWidth: '340px',
        position:'relative',
        '& .coming-soon-chip':{
            display:'inline-block',
            position:'absolute',
            padding:'3px 5px',
            color:'#fff',
            fontSize:'12px',
            right:'5px',
            top:'5px',
            borderRadius:'12px',
            backgroundColor:'#0086ff !important',
            lineHeight: 1.2
        },
        '& .btn-full-with': {
            width: '100%!important',

        },
        '& .btn-green': {
            backgroundColor: '#0db79b!important',
            color: '#fff!important',
            maxWidth: '250px!important',
        },
        '& .img-icon':{
            height:'45px!important',
            display:'inline-block',
            margin:'auto',
            width:'auto'
        }
    },
    cardHeader: {
        borderRadius: '8px 8px 0px 0px',
        display: 'flex',
        height:'116px',
        textAlign: 'center',
        flexDirection: 'column',
        padding: '20px 20px',
        justifyContent: 'space-between',
        backgroundColor: "#fff",
        fontSize: '13px',
        '& .input-component': {
            minWidth: '120px',
            marginBottom: '0px'
        },
        '& .mainheading': {
            fontSize: '18px',
            lineHeight: '1.6'
        },
    },
    cardBody: {
        padding: '10px 15px',
        backgroundColor: '#fff',
        height:'120px',
        '& .input-component': {
            marginBottom: '15px'
        },
        '& p': {
            fontSize: '13px',
            lineHeight: 1.4,
            color: '#666',
        },
        '& .with-dropdown': {
            display: 'grid',
            gridTemplateColumns: 'auto 120px',
            gap: '10px',
            alignItems: 'center',

        }
    },
    cardFooter: {
        backgroundColor: '#fff',
        borderRadius: ' 0px 0px 8px 8px',
        display: 'flex',
        padding: '20px 15px',
        justifyContent: 'flex-end',
        '& .btn-submit': {
            backgroundColor: '#3696db',
            color: '#fff',
            marginLeft: 'auto'
        }
    }
}));