import React from 'react';
import PropTypes from 'prop-types';
import { StageMenu, JobMenu } from './Canvas';
import { styled } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

const BlankDiv = styled('div')({
    display:'flex',
    margin:"auto",
    flexDirection: 'column',
    alignItems: 'center',
    height:'86%',
    justifyContent: 'center',
    '& p':{
        fontSize:'11px',
        lineHeight:1.5
    },
    '& .blank-div-text':{
        padding:'5px 15px'
    }
})

export const Workflow = props =>{
    const stages = props.stages;
    const showAddJobForm=props.showAddJobForm;
    const showAddStageForm = props.showAddStageForm;
    const deleteStage = props.deleteStage;
    const editStage = props.editStage;
    const editJob = props.editJob;
    const deleteJob = props.deleteJob;
    
    
    
    return(
        <>
        <div className="workflow-heading">
                <span >Workflow</span>
                <span></span>
        </div>
        <>
        {
                    stages.map((stage,key)=>(
                        <>
                        <StageComponent index={key} stage={stage} editStage={editStage} deleteStage={deleteStage} stage_index={key} showAddJobForm={showAddJobForm} />
                        <JobWorkflow
                        stage_index ={key}
                        editJob={editJob}
                        deleteJob={deleteJob} jobs={stage.task}/>
                        </>
                    ))
                }
        </>
        {
            stages.length == 0 ?
            <BlankDiv>

                <div className="blank-div">
                    <div className="blank-div-text">
                    No Stages Found
                    </div>
                    <button onClick={showAddStageForm}>
                        <AddIcon />Add Stage
                    </button>
                </div>
                
            </BlankDiv>:null
        }
        </>
    )
}

Workflow.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };

const StageComponent = props =>{
    const key=props.index;
    const stage = props.stage;
    const editStage = props.editStage;
    const deleteStage = props.deleteStage;
    const showAddJobForm = props.showAddJobForm;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    return(
        <div className="stage-view">          
            <span className="flaticon-expand-arrow"></span>
            <span className="pipeline-stage-name-data">
            <span className="stage-alignment">Stage{key+1}:&nbsp;</span>
                <span className="build-name">{stage.name}</span>
            </span>
            <spna className="flaticon-show-more-button-with-three-dots" aria-controls="menu-stage-view" aria-haspopup="true" onClick={handleClick}></spna>
            <StageMenu editStage={editStage} deleteStage={deleteStage} stage_index={key} showAddJobForm={showAddJobForm} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
        </div>
    );
}

StageComponent.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };

const JobWorkflow = props =>{
    const jobs = props.jobs ? props.jobs : [];
    const stage_index = props.stage_index;
    const editJob = props.editJob;
    const showAddJobForm =  props.showAddJobForm;
    const deleteJob = props.deleteJob;
    
    

    return(
        <>
        {
            jobs.map((job,key)=>(
                <JobComponent stage_index={stage_index} editJob={editJob} deleteJob={deleteJob} job={job} index={key}/>
            ))
        }
        </>
    )
}

JobWorkflow.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };

const JobComponent=(props)=>{
    const stage_index = props.stage_index;
    const editJob = props.editJob;
    const deleteJob = props.deleteJob;
    const key = props.index;
    const job = props.job;
    const [anchorEl, setAnchorEl] = React.useState(null);

    function getTaskType(job){
        const task_type = job.task_type ? job.task_type : job.task_type_id;
        const result = task_type == "1" ? "Build" : task_type == "2" ? "Deploy" : task_type == "12" ? "Integration" : task_type == "3" ? "Promote" : task_type == "10" ? "Jira" : task_type == "11" ? "Rollback": task_type == "9" ? "Env Down" : task_type == "8" ? "Env Up": task_type == "13" ? "Trigger Pipeline" :"Api call";
        return result;
    }
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    return(
        <div className="job-view">
                    <span className="empty-division"></span>
                    <span className="pipeline-stage-name-data">
                        <span className="stage-alignment">Job{key+1}:&nbsp;</span>
                        <span className="build-name">{job.type}</span>
                        <span className="env-chip bg-dark-blue">{getTaskType(job)}</span>
                    </span>
                    <span className="flaticon-show-more-button-with-three-dots" aria-controls="menu-stage-view" aria-haspopup="true" onKeyDown={()=>{}} onClick={handleClick} role="button" tabIndex={0}></span>
                    <JobMenu task_type={getTaskType(job)} key={"job-"+key} editJob={editJob} deleteJob={deleteJob} stage_index={stage_index} job_index={key+""} from={"workflow"} anchorEl={anchorEl} setAnchorEl={setAnchorEl}/>
                </div>
    );

}
JobComponent.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };