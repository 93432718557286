import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Hidden, IconButton } from '@material-ui/core';



import { SimpleMenu } from './SimpleMenu';
import properties from '../../properties/properties';



const Topbar = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  //const companyName = properties.company_name;
  const companyLogo = properties.company_logo;
  const logo_width = properties.logo_width;
  // var expand = () => {
  //   setOpen(!open);
  // } 
  return (
    <AppBar
      className={classes.root}
      style={{ backgroundColor: '#124d9b' }}
    >
      <Toolbar className={classes.newHeight}>
        <RouterLink to="/" style={{ display: 'flex' }}>
          <img
            alt="Logo"
            style={{width: logo_width}}
            className={classes.logo}
            src={companyLogo ?companyLogo:  "/images/logos/buildpiperlogo.png"}
            width="80%"
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <IconButton
            color="inherit"
          >
            <div className={classes.notificationsCount} ></div>
          </IconButton>
          {/* <OnGoingJob /> */}
          <SimpleMenu />
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;




const useStyles = makeStyles(theme => ({
  root: {
    '& .simple-menu': {
      width: '250px',
      '& .MuiMenu-list':{
        paddingBottom: '0px!important'
      },
      '& .MuiListItem-root':{
        '& a:visited':{
            color:'#0086ff'
        },
      }
    }
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsCount: {
    fontSize: '9px',
    backgroundColor: '#ff5252',
    padding: '.25rem',
    borderRadius: '50%',
    position: 'absolute',
    bottom: '30px',
    right: '0px',
    height: '10px',
    width: '10px'
  },
  MuiAvatarColorDefault: {
    color: ' #fff',
    backgroundColor: '#6d84a1 ',
    fontWeight: '600'
  },
  bellIcon: {
    backgroundColor: '#6d84a1',
    height: '30px',
    width: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
  },
  newHeight: {
    minHeight: '50px!important'
  },
  logo: {
    width: '70px'
  },
  avatarColor: {
    color: '#fff',

  },
  avatarRoot: {
    width: "30px",
    height: "30px",
    display: "flex",
    overflow: "hidden",
    position: "relative",
    fontWeight: 600,
    backgroundColor: '#0086ff',
    fontSize: "1.15rem",
    alignItems: "center",
    flexhSrink: "0",
    lineHeight: "1",
    userSelect: "none",
    borderRadius: "4px",
    justifyContent: "center",
  },
  userDetails: {
    padding: '15px',
    textAlign: 'center',
    '& .username': {
      fontSize: '16px',
      lineHeight: '22px',
      color: '#000',
      // marginBottom: '10px',
      fontWeight: '400'
    },
    '& .other-details': {
      fontSize: '12px',
      width: '250px'
    },
    '& .text-lite-gray': {
      color: '#9d9d9d'
    }
  },
  footer:{
    padding: '8px',
    textAlign: 'center'
  },
  heading: {
    backgroundColor: '#284060',
    color: '#fff',
    fontSize: '12px',
    lineHeight: '1.4',
    padding: '10px'
  },
  dialogBody:{
    padding: '25px'
  }
}));