import React, { useEffect, useState } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import MonitoringSourceComponent from './components/MonitoringSourceComponent';
import AddMonitoringSource from './AddMonitoringSource';
import GenerateURL from '../../util/APIUrlProvider';
import properties from '../../properties/properties';
import InvokeApi from '../../util/apiInvoker';
import { Loading } from '../utils/Loading';
import { Grid } from '@material-ui/core';
import Pagination from '../../components/Pagination';
import AdvanceSearchFilterCombo from '../../components/genericComponents/AdvanceSearchFilter/AdvanceSearchFilterCombo';
import { useHistory, useLocation } from 'react-router';

const MonitoringSourceList = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const application_id = ""
    const [state, setstate] = useState({
        list: [], total_page: "",
        curr_page: "",
        refresh_count: 0,
        moreAdvFilterList: [],
        advFilters: {
            status: [],
            name: [],
            tag: []
        },
        resetCount: 0
    });
    const [data, setData] = useState(null);
    const moreFilterData = [
        { label: "Source", value: "source" },
    ]
    const defaultFilters = ["source"]
    const history = useHistory();
    const resetFilterData = {
        source: [],
    }

    useEffect(() => {
        resetAdvFilter();
    }, [])
    const resetAdvFilter = () => {

        addFiltersToUrl('all_delete')
        setstate(prevState => ({
            ...prevState,
            moreAdvFilterList: defaultFilters,
            advFilters: resetFilterData,
            resetCount: prevState.resetCount + 1
        }))

        // will call here normal function
    }
    const handleClickOpen = () => {
        setOpen(true);
        setData(null)
    };
    const onUpdateHandle = (uniqueId, updatedList) => {

        let updatedKey = ''
        if (uniqueId === 'more-button-adv-0') {
            addFiltersToUrl('adv_search', updatedList)
            if (updatedList?.length == 0) {
                resetAdvFilter()
            }
            else {
                setstate(prevState => ({
                    ...prevState,
                    moreAdvFilterList: updatedList,
                }))

            }
        }
        else {
            if (uniqueId == 'name_adv_1') {
                updatedKey = 'source'
            }

            setstate(prevState => ({
                ...prevState,
                advFilters: {
                    ...prevState.advFilters,
                    [updatedKey]: updatedList
                }
            }))
            addFiltersToUrl(updatedKey, updatedList)
            const advFilters = state.advFilters
            advFilters[updatedKey] = updatedList
            fetchAllMonitoringSourceForListFilter(advFilters)
        }
    }

    function generateFilterContainURL(baseURL, listDict, activeFilters) {
        var searchParams = ''
        const filtersKeyArray = Object.keys(listDict)
        let i = 0
        let advFilterApplied = false
        const activeFilterList = activeFilters ? activeFilters : state.moreAdvFilterList
        filtersKeyArray.forEach(key => {

            const arrayFilterValues = listDict[key];
            if (arrayFilterValues.length > 0 && isFilterActive(key, activeFilterList)) {
                if (i != 0) {
                    searchParams += '&'
                }
                advFilterApplied = true
                var arrayString = arrayFilterValues;
                const stringKey = `${key}=${arrayString}`
                searchParams += stringKey
                i++;
            }
        })

        const finalUrl = baseURL + '?' + searchParams.toString()

        return finalUrl
    }

    const isFilterActive = (filterKey, list) => {

        const isFound = list?.find(name => filterKey == name)

        return isFound;
    }


    function fetchAllMonitoringSourceForListFilter(listFilterData, activeFilterList) {

        let baseURL = GenerateURL({}, properties.api.monitoring_resource_get)

        const resultURL = generateFilterContainURL(baseURL, listFilterData, activeFilterList);

        let requestInfo = {
            endPoint: resultURL,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };
        setstate((new_state) => ({
            ...new_state,
            loaded: false,
        }));
        InvokeApi(requestInfo, fetchAllMonitoringSourceFetchSuccess, fetchAllMonitoringSourceFetchFailure);
    }

    function fetchAllMonitoringSourceFetchSuccess(response) {
        var result = filterData(response.results);

        var filtered_result = [];
        if (state.is_showing_pending) {
            filtered_result = result.pending;
        }
        setstate((new_state) => ({
            ...new_state,
            ...result,
            loaded: true,
            count: response.count,
            next: response.next ? properties.api.baseURL + response.next : null,
            previous: response.previous
                ? properties.api.baseURL + response.previous
                : null,
            filtered_result: filtered_result.length > 0 ? filtered_result : null,
            total_page:
                response.count != 0
                    ? Number.isInteger(Number(response.count) / 10)
                        ? (Number(response.count) / 10).toFixed(0)
                        : (Number(response.count) / 10 + 1).toFixed(0) >
                            Number(response.count) / 10 + 1
                            ? (Number(response.count) / 10 + 1).toFixed(0) - 1
                            : (Number(response.count) / 10 + 1).toFixed(0)
                    : 1,
            curr_page: 1,
        }));
    }

    function fetchAllMonitoringSourceFetchFailure(error) {
        setstate((new_state) => ({
            ...new_state,
            error: error,
            loaded: true,
        }));
    }

    const handleClose = () => {
        console.log("ccdsacasnkjd")
        setOpen(false);
    };
    useEffect(() => {
        fetchSource()
        console.log("useEffcecewecaew")
    }, [])
    const addFiltersToUrl = (filterName, filterValue) => {

        let urlSearchParams = new URLSearchParams(location.search);
        const allFilters = [
            "Source"
        ]

        if (filterName == 'all_delete') {
            urlSearchParams = ''
        } else {
            if (filterName == 'adv_search') {
                allFilters.forEach(value => {
                    if (!filterValue.includes(value)) {
                        urlSearchParams.delete(value)
                    }
                })
            }

            if (filterValue?.length == 0) {
                urlSearchParams.delete(filterName)
            }
            else {
                if (urlSearchParams.has(filterName)) {
                    urlSearchParams.set(filterName, filterValue.join(','));
                } else {
                    urlSearchParams.append(filterName, filterValue.join(','));
                }
            }
        }

        history.replace({ pathname: location.pathname, search: urlSearchParams.toString() });
    }

    function fetchSource() {
        var requestInfo = {
            endPoint: GenerateURL(
                {},
                properties.api.monitoring_resource_get
            ),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };
        InvokeApi(
            requestInfo,
            fetchSourceDataSuccess,
            fetchSourceDataFailed
        );

        setstate((new_state) => ({
            ...new_state,
            save_data_loading: true,
        }));
    }
    function fetchSourceDataSuccess(response) {
        setstate((new_state) => ({
            ...new_state,
            save_data_loading: false,
            list: response.results,
            total_page: Number.isInteger(Number(response.count) / 10)
                ? (Number(response.count) / 10).toFixed(0)
                : (Number(response.count) / 10 + 1).toFixed(0) > Number(response.count) / 10 + 1
                    ? (Number(response.count) / 10 + 1).toFixed(0) - 1
                    : (Number(response.count) / 10 + 1).toFixed(0),
            curr_page: 1,
            count: response.count,
            next: response.next ? properties.api.baseURL + response.next : null,
            previous: response.previous ? properties.api.baseURL + response.previous : null,
        }));
    }
    function fetchSourceDataFailed(error) {
        setstate((new_state) => ({
            ...new_state,
            error: error,
            save_data_loading: false,
        }));
    }
    function fetchPrevPanelInfo(data, url) {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.monitoring_resource_get),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };

        if (data) {
            requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
        }

        if (url) {
            requestInfo.endPoint = url;
        }
        setstate((new_state) => ({
            ...new_state,
            save_data_loading: true,
        }));
        InvokeApi(
            requestInfo,
            PanelPrevInfoFetchSuccess,
            PanelPrevInfoFetchFailure
        );
    }

    function PanelPrevInfoFetchSuccess(response) {
        setstate((new_state) => ({
            ...new_state,
            save_data_loading: false,
            list: response.results,
            total_page: Number.isInteger(Number(response.count) / 10)
                ? (Number(response.count) / 10).toFixed(0)
                : (Number(response.count) / 10 + 1).toFixed(0) > Number(response.count) / 10 + 1
                    ? (Number(response.count) / 10 + 1).toFixed(0) - 1
                    : (Number(response.count) / 10 + 1).toFixed(0),
            curr_page: Number(new_state.curr_page - 1),
            count: response.count,
            next: response.next ? properties.api.baseURL + response.next : null,
            previous: response.previous ? properties.api.baseURL + response.previous : null,
        }));

    }

    function PanelPrevInfoFetchFailure(error, exception) {
        setstate((new_state) => ({
            ...new_state,
            error: error,
            save_data_loading: false,
        }));
    }

    function fetchNextPanelInfo(data, url) {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.monitoring_resource_get),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };

        if (data) {
            requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
        }

        if (url) {
            requestInfo.endPoint = url;
        }
        setstate((new_state) => ({
            ...new_state,
            save_data_loading: true,
        }));
        InvokeApi(
            requestInfo,
            PanelNextInfoFetchSuccess,
            PanelNextInfoFetchFailure
        );
    }

    function PanelNextInfoFetchSuccess(data) {
        setstate((new_state) => ({
            ...new_state,
            save_data_loading: false,
            list: data.results,
            count: data.count,
            next: data.next ? properties.api.baseURL + data.next : null,
            previous: data.previous ? properties.api.baseURL + data.previous : null,
            total_page: Number.isInteger(Number(data.count) / 10)
                ? (Number(data.count) / 10).toFixed(0)
                : (Number(data.count) / 10 + 1).toFixed(0) > Number(data.count) / 10 + 1
                    ? (Number(data.count) / 10 + 1).toFixed(0) - 1
                    : (Number(data.count) / 10 + 1).toFixed(0),
            curr_page: Number(new_state.curr_page + 1),
        }));
    }

    function PanelNextInfoFetchFailure(error, exception) {
        setstate((fresh_state) => ({
            ...fresh_state,
            loaded: false,
            error: error,
            exception: exception,
        }))
    }
    const editAndUpdateState = (data) => {
        console.log(data, "fsjsjfjsajfjdsaj")
        setData(data.id)
        setOpen(true)
    }
    const advanceFilterJson = {
        'source': {
            staticList: false,
            labelName: 'Source',
            uniqueId: 'name_adv_1',
            searchVariable: 'source',
            getFetchUrl: properties.api.monitoring_resource_get,
            searchUrl: properties.api.monitoring_resource_get,
            filterDataPraseFunction: filterDataParseForSource
        },

    }

    function filterDataParseForSource(data) {
        console.log(data, 'bcdhbhsdbchsbdbc')
        const updatedList = data?.map(item => {
            return { 'label': item.monitoring_url, 'value': item.resource, ...item }
        })

        return updatedList
    }


    return (
        <div className={classes.root}>
            <CommonHeaderHandler
                heading={"Monitoring Source Integration"}
                subheading={<><span>To understand more on Monitoring Sourc please read the </span><Link className="" to={"#"}>Documentation</Link></>}
                heading_icon={"/public/images/monitoring_resource_icon.svg"}
                btnLink={false}
                btnTitle={"Add Monitoring Source"}
                handleButtonAction={handleClickOpen}
            />
            <Grid container>
                {
                    state.moreAdvFilterList?.map(filterName => {
                        console.log(filterName, 'ftvbfd')
                        return (
                            <div key={filterName} className="ad-more-search mb-20">
                                <AdvanceSearchFilterCombo
                                    reset={state.resetCount}
                                    selectedCheckBoxes={state.advFilters[filterName]}
                                    searchVariable={advanceFilterJson[filterName]['searchVariable']}
                                    staticList={advanceFilterJson[filterName]['staticList']}
                                    uniqueId={advanceFilterJson[filterName]['uniqueId']}
                                    labelName={advanceFilterJson[filterName]['labelName']}
                                    searchUrl={advanceFilterJson[filterName]['searchUrl']}
                                    onUpdate={onUpdateHandle}
                                    getFetchUrl={advanceFilterJson[filterName]['getFetchUrl']}
                                    placeholder_name={advanceFilterJson[filterName]['placeholder_name']}
                                    placeholder_value={advanceFilterJson[filterName]['placeholder_value']}
                                    filterDataPraseFunction={advanceFilterJson[filterName]['filterDataPraseFunction']}
                                    showMoreNotRequired={advanceFilterJson[filterName]['showMoreNotRequired']}
                                />
                            </div>
                        )
                    })
                }
                <div className="ad-more-search mb-20">
                    <AdvanceSearchFilterCombo
                        selectedCheckBoxes={state.moreAdvFilterList}
                        reset={state.resetCount}
                        staticList={moreFilterData}
                        autoClosedAfterSelection={true}
                        onUpdate={onUpdateHandle}
                        variant='more-button'
                        uniqueId='more-button-adv-0'
                    />
                </div>

                <div onClick={resetAdvFilter} className="pl-15 ml-20 d-flex align-center justify-center cursor-pointer" style={{ borderLeft: '1px solid #dedede', height: '40px' }}>
                    <label style={{ color: '#595353' }} className="font-13 cursor-pointer">Reset</label>
                </div>
            </Grid>
            {
                state.save_data_loading ?
                    <div className='' style={{ marginTop: 400 }}>
                        <Loading varient="light" />
                    </div>
                    :
                    <>
                        {
                            state.list && state.list.length > 0 ?

                                <>
                                    <div className={classes.mainCard}>
                                        <p>
                                            Credential
                                        </p>
                                        <p>
                                            Url
                                        </p>
                                        <p>
                                            Connection Status
                                        </p>
                                        <p>
                                            Actions
                                        </p>
                                        {
                                            state.list.map(item => {
                                                return <MonitoringSourceComponent data={item} setDataToParent={editAndUpdateState} refreshFun={fetchSource} />
                                            })
                                        }

                                    </div>
                                    <Grid container spacing={2} style={{ margin: '20px 0px' }}>
                                        <Grid item lg={9}>
                                        </Grid>
                                        <Grid item lg={3} sm={12} xl={3} xs={12}>
                                            <Pagination
                                                total_count={state.total_page}
                                                current_page_count={state.curr_page}
                                                next={state.next}
                                                previous={state.previous}
                                                on_previous_click={() => {
                                                    fetchPrevPanelInfo(null, state.previous);
                                                }}
                                                on_next_click={() => {
                                                    fetchNextPanelInfo(null, state.next);
                                                }}
                                                on_pageNumber_click={(pageNumber) => {
                                                    fetchPagePanelInfo(pageNumber);
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                </>
                                :
                                <div className='m-auto' style={{ marginTop: '20em', width: '300px', height: 'auto', fontSize: '13px', backgroundColor: '#fff', padding: '20px', textAlign: 'center', border: '1px solid #ededed', borderRadius: "4px", overflow: 'hidden' }}>
                                    <p className='mb-20'>Monitoring Sources are not added yet!!</p>
                                    <button onClick={handleClickOpen} className='btn btn-primary font-12 font-weight-500 d-flex align-center justify-center m-auto'>
                                        <span class="ri-add-line font-18"></span> Add Monitoring Source
                                    </button>
                                </div>
                        }
                    </>
            }


            {
                open &&
                <AddMonitoringSource
                    open={open}
                    handleClose={handleClose}
                    edit_id={data}
                    refreshFn={fetchSource}//
                />
            }

        </div>
    )
}

export default MonitoringSourceList;


const useStyles = makeStyles((theme) => ({
    root: {
        margin: 20
    },
    mainCard: {
        '&>p': {
            borderBottom: '1px solid #e6e6e6',
            padding: '10px 20px',
        },
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 120px',
        backgroundColor: '#fff',
        border: '1px solid #e6e6e6',
        borderBottom: '0px',
        borderRadius: '6px',
        overflow: 'hidden',
        '& .mainCardItem': {
            alignSelf: 'center',
            display: 'flex',
            alignItems: 'center',
            padding: '10px 20px',
            borderBottom: '1px solid #e6e6e6',
            minHeight: '81px',
            '& .success-chip': {
                backgroundColor: '#E6FBEA',
                color: '#129E5B',
                width: 'fit-content',
                padding: '4px 8px',
                borderRadius: '24px'
            },
            '& .not-success-chip': {
                backgroundColor: '#FCF6E1',
                color: '#E1941D',
                width: 'fit-content',
                padding: '4px 8px',
                borderRadius: '24px'
            },
        },
        '& .item1': {
            '& .thumbnail_outer': {
                boxShadow: '0px 2.2px 2.2px 0px #00000014',
                height: '52px',
                width: '52px',
                borderRadius: 26,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            },
            '& .logo_thumbnail': {
                // boxShadow: '0px 2.2px 2.2px 0px #00000014',
                height: '48px',
                width: '48px',
                borderRadius: 24,
                border: '2px solid #f4f4f4',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'

            }
        }
    }
}));

export const CommonHeaderHandler = props => {
    const classes = useStylesCommonHeader();
    const heading = props.heading ? props.heading : null;
    const subheading = props.subheading ? props.subheading : null;
    const heading_icon = props.heading_icon ? props.heading_icon : null;
    const bg_color = props.bg_color ? props.bg_color : classes.iconBg;
    const btnLink = props.btnLink ? props.btnLink : null;
    const btnTitle = props.btnTitle ? props.btnTitle : null;
    const handleButtonAction = props.handleButtonAction ? props.handleButtonAction : () => { }
    return (
        <div className={classes.root + " d-flex align-center space-between mb-20"}>
            <div className='d-flex align-center'>
                {
                    heading_icon ? <div className={`${bg_color} bg-icon d-flex align-center justify-center`}><img src={'/images/monitoring_resource_icon.svg'} alt=".." /></div> : null
                }
                <div className="ml-5">
                    <p className='font-18 font-weight-500 color-content-dark'>{heading}</p>
                    <p className='sub-heading-primary'>{subheading}</p>
                </div>
            </div>
            {
                btnLink ?
                    <Link >
                        <button className='btn btn-primary font-12 font-weight-500 d-flex align-center justify-center'><span class="ri-add-line font-18"></span> {btnTitle}</button>
                    </Link>
                    :
                    <button onClick={handleButtonAction} className='btn btn-primary font-12 font-weight-500 d-flex align-center justify-center'>
                        <span class="ri-add-line font-18"></span> {btnTitle}
                    </button>
            }
        </div>
    )
}



const useStylesCommonHeader = makeStyles((theme) => ({
    root: {
        '& .bg-icon': {
            height: 48,
            width: 48,
            borderRadius: 24,
            boxShadow: '0 4px 4px 0px rgba(0, 134, 255, 0.12)'
        }
    },
    iconBg: {
        backgroundColor: '#0086ff'
    }
}));