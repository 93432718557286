import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MultiSelectInput(props) {
  console.log(props)
  const classes = useStyles();
  const list = props.list ? props.list : [];
  const name = props.name;
  const onChangeHandler = props.onChangeHandler;
  const value = props.value ? props.value : [];
  const selected_checkboxes = props.selected_checkboxes ? props.selected_checkboxes : [];

  const handleChange = (e, newValue) => {
    console.log(e,newValue)
    onChangeHandler(name, newValue);
  };

  console.log('pp_inside_kiisdsd',list,value);
  return (
    <div className={classes.root + ' ' + 'multi-select-checkbox'}>

      <Autocomplete
        multiple
        limitTags={-1}
        options={list}
        getOptionLabel={(option) => option.label}
        value={value}
        onChange={handleChange}
        getOptionSelected={(option, value) => option.label === value.label}
        renderOption={(option, { selected }) => (
          <div>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected || selected_checkboxes.includes(option.id)}
              value={option.id}
              name={name}
            />
            {option.label}
          </div>
        )}
        renderInput={(params) => (
        
             <TextField
              {...params}
              variant="outlined"
              label=""
              placeholder={props.placeholder ? props.placeholder : 'Enter Issue Name'}
            />
          
        )}
      />

    </div>
  );
}

MultiSelectInput.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}