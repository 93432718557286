import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Delete from '../../../../../../../components/genericComponents/Delete';

import WeekDaysCheckbox from '../../../../../../../components/genericComponents/WeekDaysCheckbox';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { getCommonFunctions } from '../../../../../../serviceRevamp/add/ci_flow/SourceDetails';
import ConfirmationDialog from './ConfirmationDialog';
import AddAutoScalingForm from './AddAutoScalingForm';
import GenerateURL from '../../../../../../../util/APIUrlProvider';
import properties from '../../../../../../../properties/properties';

import { makeStyles } from '@material-ui/core/styles';
import SendForApprovalConfirmationDialog from '../../../auditHistory/SendForApprovalConfirmationDialog';
const OverviewCardAutoScaling = (props) => {
    const classes = useStyles();
    const inherits = props.inherits ? props.inherits : {}
    const extraProps = props.extraProps ? props.extraProps : null;
    const refreshFn = props.refreshFn ? props.refreshFn : null;
    const cd_data = props.cd_data ? props.cd_data : null;
    const cd_id = cd_data[0].id
    const validateAndApplyHPA = props.validateAndApplyHPA
    const [state, setstate] = useState({
        data: {},
        error: {},
        hpa_deployment: null,
        error_msg: null
    })
    const commonFunctions = getCommonFunctions(state, setstate, inherits);
    const hpa_data = props.hpa_data;
    function handleCloseDeployment() {

        setstate({
            ...state,
            hpa_deployment: null,
            open: false
        });
    }

    console.log(cd_data, "rtyuiogh =================>")

    return (
        
            <div className="card box-shadow-none border-radius ">
                <div className="card-header font-12 border-bottom bg-fa-grey border-top-none pd-10 " style={{ paddingBottom: '10px' }}>
                    <div className="d-flex align-center input-mb-0">
                        <p className="font-12 ">{hpa_data.name}</p>
                        <span className="text-grey-5a">&nbsp;Start Time:&nbsp;</span>
                        {
                            hpa_data.time_pattern && hpa_data.time_pattern.start_time ?
                                <span className="text-grey-9e">{hpa_data.time_pattern.start_time}&nbsp;</span>
                                : null
                        }
                        <span className="text-grey-5a">&nbsp;Hours:&nbsp;</span>
                        {
                            hpa_data.time_pattern && hpa_data.time_pattern.hours ?
                                <span className="text-grey-9e">{hpa_data.time_pattern.hours}</span> : null}
                    </div>
                    <div className="sm-checkbox-wrapper">
                        <WeekDaysCheckbox
                            variant="disabled_state"
                            prev_state={
                                hpa_data.time_pattern && hpa_data.time_pattern.days ?
                                    hpa_data.time_pattern.days : null
                            } inherits={{}} />
                    </div>
                </div>
                <div className="card-body font-12" >
                    <div className="d-grid pd-10 bg-fa-grey border-bottom text-grey-5a" style={{ gridTemplateColumns: '1fr 1fr', gap: 20 }}>
                        <p>PODs Replication</p>
                        <p>Resource Utilization</p>
                    </div>
                    <div className="d-grid pd-10" style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 20 }}>
                        <p className="">
                            <div>Min Replication Count </div>
                            <div className="text-grey-83 font-36 font-weight-300">{hpa_data.minimum_replication}</div>
                        </p>
                        <p className="">
                            <div>Max Replication Count </div>
                            <div className="text-grey-83 font-36 font-weight-300">{hpa_data.maximum_replication}</div>
                        </p>
                        <p className="">
                            <div>CPU Threshold </div>
                            <div className="text-grey-83 font-36 font-weight-300">{hpa_data.cpu_threshold}</div>
                        </p>
                        <p className="">
                            <div>Memory Threshold </div>
                            <div className="text-grey-83 font-36 font-weight-300">{hpa_data.ram_threshold}</div>
                        </p>
                    </div>
                </div>
                <div className="border-top pd-10 d-flex align-center space-between">
                    <div className="deployed-status font-12 d-flex align-center veri">
                        <CheckCircleIcon className={hpa_data.applied == 1 ? "color-success font-18" : "text-grey-9e font-18"} />
                        <span className="text-grey-9e">&nbsp;Deployed to Kubernetes</span>
                        {
                            hpa_data.status &&
                            <div className={classes.rootCard}>
                                <p>Status :<span className={hpa_data.status == "DRAFT" || hpa_data.status == "DRAFT_PENDING" ? "font-10 chip chip-yellow ml-5" : hpa_data.status == "APPROVAL_REQUESTED_PENDING" || hpa_data.status == "APPROVAL_REQUESTED" ? "font-10 chip chip-dark-grey ml-5" : "font-10 chip chip-green ml-5"}>{hpa_data.status == "DRAFT" ? "IN DRAFT" : hpa_data.status == "DRAFT_PENDING" ? "Sending for draft" : hpa_data.status == "APPROVAL_REQUESTED_PENDING" ? "sending for approval" : hpa_data.status == "APPROVAL_REQUESTED" ? "APPROVAL_REQUESTED" : hpa_data.status == "APPROVED_PENDING" ? "Approval in progress" : "IN USE"}</span></p>
                                {hpa_data.status == "DRAFT" ? <div style={{ marginLeft: "10px" }}><SendForApprovalConfirmationDialog audit_card_data={hpa_data} sendForApproval={props.sendForApproval} /> </div> : hpa_data.status == "APPROVAL_REQUESTED" ? <div style={{ marginLeft: "10px" }}><SendForApprovalConfirmationDialog audit_card_data={hpa_data} sendForApproval={props.sendForApproval} confirmApproval="confirmApproval" confirmForApproval={props.confirmForApproval} /> </div> : ""}
                            </div>
                        }
                    </div>
                    <div className="btn-group btn-icon-group ml-auto d-inline-block">
                        <ConfirmationDialog
                            hpa_data={hpa_data}
                            extraProps={extraProps}
                            validateAndApplyHPA={() => { validateAndApplyHPA(hpa_data.id) }}
                            refreshFn={refreshFn}
                        />
                        <AddAutoScalingForm
                            extraProps={extraProps}
                            refreshFn={refreshFn}
                            variant="EDIT_MODE"
                            prev_state={hpa_data}
                            cd_data={cd_data}
                        />
                        <Delete
                            display_data_name={hpa_data.name}
                            variant={"hpa_delete"}
                            varient={"IconButton"}
                            fontSize="font-20"
                            data={{ entity_id: hpa_data.id, name: "env_cd_scale" }}
                            refresh={refreshFn}
                            api_link={GenerateURL({ service_id: extraProps.service_id, component_env_id: extraProps.component_env_id, cd_id: cd_id, id: hpa_data.id }, properties.api.edit_autoscaling)}
                        />
                    </div>
                </div>
            </div>

        
    )
}

OverviewCardAutoScaling.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }


export default OverviewCardAutoScaling

const useStyles = makeStyles((theme) => ({
    rootCard: {
        marginLeft: "15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        // width : "215px",

        '& .chip': {
            padding: '3px!important',
            display: 'inline-block'
        },
        '& .chip-yellow': {
            borderRadius: '3px',
            backgroundColor: '#ffc000',
            color: '#fff',
        },
        '& .chip-dark-grey': {
            borderRadius: '3px',
            backgroundColor: '#656565',
            color: '#fff',
        },
        '& .chip-green': {
            borderRadius: '3px',
            backgroundColor: '#69df9b',
            color: '#fff',
        },
    }
}))
