import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import properties from '../../../properties/properties';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none',
    borderBottom:"1px solid #E6E6E6",
  },
  newHeight:{
    minHeight:'50px!important',
    display:"flex",
    justifyContent:"space-between",
  },
  logo:{
    height:'80%!important'
  },
  companyLogo: {
    display: "flex",
    width: "135px",
    height: "38px",
    justifyContent: "center",
    alignContent: "center",
    borderRadius: "6px",
    border: "2px solid #F4F4F4",
    background: "#FFF",
    flexWrap: "wrap",
},
}));

const TopBar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const companyLogo = properties.company_logo;
  const company_logo_width = properties.logo_width;
  const company_logo_height = properties.logo_height;

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="#FFFFFF"
      position="fixed"
    >
      <Toolbar className={classes.newHeight}>
      <RouterLink to="/" style={{display:'flex'}}>
      <img
        alt="Logo"
        className={classes.logo}
        src="/images/clients_logos/new-buildpiper-logo.svg"
        width="100%"
      />
    </RouterLink>
    {companyLogo ?
                <div className={classes.logoCover}>
                    <div className={classes.companyLogo}>
                        <img src={companyLogo} style={company_logo_width && company_logo_height ? { height: company_logo_height, width: company_logo_width } : company_logo_width ? { height: "18px", width: company_logo_width } : {height: '18px' , width: '103px'}} />
                    </div>
                </div>
                : null}
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
