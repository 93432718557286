import React, { useState } from "react";
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import MobileStepper from '@material-ui/core/MobileStepper';
import { IconButton } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function PodStatus(props) {
    const classes = useStyles();

    var deployment_data = props.deployment_data;
    var new_replicaset_pods = props.chart_data ? props.chart_data.replicasets ? props.chart_data.replicasets[props.chart_data.replicasets.length - 1] ? props.chart_data.replicasets[props.chart_data.replicasets.length - 1].pods : "0" : null : null
    var previous_replicaset_pods = props.chart_data ? props.chart_data.replicasets ? props.chart_data.replicasets.length > 1 ? props.chart_data.replicasets[props.chart_data.replicasets.length - 2].pods : null : null : null
    const maxSteps = previous_replicaset_pods ? previous_replicaset_pods.length : 0;
    const [activeStep, setActiveStep] = useState(0);
    const [newReplicaActiveStep, setNewReplicaActiveStep] = useState(0);
    const maxSteps2 = new_replicaset_pods ? new_replicaset_pods.length : 0;

    const handleNext = () => {
        setActiveStep((currentActiveStep) => currentActiveStep < maxSteps ? currentActiveStep + 1 : currentActiveStep);
    };

    const handleBack = () => {
        setActiveStep((currentActiveStep) => currentActiveStep > 0 ? currentActiveStep - 1 : 0);
    };

    const handleNext1 = () => {
        setNewReplicaActiveStep((prevActiveStep1) => prevActiveStep1 + 1);
    };

    const handleBack1 = () => {
        setNewReplicaActiveStep((prevActiveStep1) => prevActiveStep1 - 1);
    };
    return (
        <div>
            {/* --------------------Code for single chart data without slider----------------------- */}
            {!previous_replicaset_pods   ?
                new_replicaset_pods? 
                <div className={classes.singleChart}>
                    <PodWithContainerTable variant = "SINGLE" title="Current" data={new_replicaset_pods} />
                </div> :
                        <div className="text-center" style={{height: 200, display:'flex', alignItems:'center', justifyContent:'center', width:'100%'}}>
                        <p className="font-13 text-grey-85">No Deployments initiated for this service yet!!</p>
                    </div>
                       
                    : 
                    null
            }

            {/* --------------------Code for single chart data without slider----------------------- */}
            {/* ------Code for multi chart data----------- */}
            {
                previous_replicaset_pods && new_replicaset_pods ?
                    <div className={"d-grid" + " " + classes.root}>
                        <div >
                            <PodWithContainerTable title="Old"  data={previous_replicaset_pods} />
                        </div>
                        <div >
                            <PodWithContainerTable title="New" data={new_replicaset_pods} />
                        </div>
                    </div>
                    : null}
            {/* ------Code end for multi chart data------- */}
        </div>
    )
}

PodStatus.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

const PodWithContainerTable= props =>{
    const data = props.data? props.data:[]
    const title = props.title?props.title:null;
    const variant = props.variant? props.variant : ""
    console.log(data,"jfsakjjdfsajk")
    return(
        <>
        <div className="pd-10 border-bottom">
             <p className="font-18 " style={{fontWeight: 300}}>
                <span >{title} Replica Set </span>
                 <span className="text-grey-83"> &gt; </span>
                 <span className="text-grey-83"> Container Status</span>
             </p>
        </div>
        <table className="table table-striped table-default border-grid table-responsive pd-10">
            <tr>
                <th>Pods Status</th>
                {variant == "SINGLE" ? <th>Pod Message</th>: null}
                <th className="cont-th-db">
                    <table className="table border-none table-responsive table-layout-fixed fixed-four">
                        <th>Container </th>
                        <th>Container Status </th>
                        <th>Restart </th>
                        <th>Message </th>         
                    </table>         
                </th>
            </tr>
            <tbody>
            {
               typeof(data) == "object" && data.length > 0 ? data.map(pods_data => (
                    <tr>
                        <td>
                            {pods_data.pod_status.status ?
                                pods_data.pod_status.status == "Pending"?
                                <span className="font-11 text-grey-9e"><CheckCircleIcon className="color-pending v-align-sub" /> { pods_data.pod_status.status}</span>:
                                pods_data.pod_status.status == "Ready"?
                                <span className="font-11 text-grey-9e"><CheckCircleIcon className="color-success v-align-sub" /> { pods_data.pod_status.status}</span>:
                                pods_data.pod_status.status == "Terminating"?
                                <span className="font-11 text-grey-9e"><CheckCircleIcon className="color-success v-align-sub" /> { pods_data.pod_status.status}</span>:
                                pods_data.pod_status.status == "Failed"?
                                <span className="font-11 text-grey-9e"><CheckCircleIcon className="color-Danger v-align-sub" /> { pods_data.pod_status.status}</span>:
                                pods_data.pod_status.status == "Succeeded"?
                                <span className="font-11 text-grey-9e"><CheckCircleIcon className="color-success v-align-sub" /> { pods_data.pod_status.status}</span>:
                                pods_data.pod_status.status == "Unknown"?
                                <span className="font-11 text-grey-9e"><CheckCircleIcon className="v-align-sub" style={{color:'#c294f1'}} /> { pods_data.pod_status.status}</span>:
                                pods_data.pod_status.status == "Containers_Not_Ready"?
                                <span className="font-11 text-grey-9e"><CheckCircleIcon className="v-align-sub" style={{color:'#FFCB2E'}} /> { pods_data.pod_status.status}</span>:
                                <span className="font-11 text-grey-9e"><CancelIcon className="v-align-sub" style={{color:'#FF6961'}} /> { pods_data.pod_status.status}</span>
                                : "N/A"}
                        </td>
                        {variant == "SINGLE" ?
                        <td>
                            {pods_data.pod_status? pods_data.pod_status.message: "N/A"}
                        </td>
                        :null}
                        <td className="pd-0" style={{padding:0}}>
                            <table className="table border-none table-responsive table-layout-fixed fixed-four">
                                
                {pods_data.pod_status && pods_data.pod_status.containers_status ? Object.keys(pods_data.pod_status.containers_status).map(key => (
                    <>{key != "error_found" && 
                        <tr>
                            <td>
                                {key? key : "--"}
                            </td>
                            <Tooltip title={pods_data.pod_status.containers_status ? pods_data.pod_status.containers_status[key].status : "N/A"}>
                            <td className="text-width-300 ">
                                {pods_data.pod_status.containers_status ? pods_data.pod_status.containers_status[key].status : "N/A"}
                            </td>
                            </Tooltip>
                            <td>
                                {pods_data.pod_status.containers_status[key].restart_count ? pods_data.pod_status.containers_status[key].restart_count : "--"}
                            </td>
                            <Tooltip title={pods_data.pod_status.containers_status ? pods_data.pod_status.containers_status[key].message : "N/A"}>
                            <td className="text-width-300 ">
                              
                              {pods_data.pod_status.containers_status ? pods_data.pod_status.containers_status[key].message : "N/A"}
                          </td>
                            </Tooltip>
                            
                        </tr>
                        }</>
                )) : <tr>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                    </tr>}
                    </table>
                        </td>
                    </tr>
                ))
                : 
<tr>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                    </tr>
            }
            </tbody>
        </table>
        </>
    )
}

PodWithContainerTable.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

const TableRow = props => {
    const title = props.title ? props.title : ""
    const data = props.data ? props.data : <>No Data</>;
    const steps = props.steps;
    const activeStep = props.activeStep;
    const handleNext = props.handleNext;
    const handleBack = props.handleBack;
    const theme = useTheme();
    return (
        <>
            <div className="pd-10 border-bottom">
                <p className="font-11 d-flex align-center space-between">
                    <div>
                        <p className="font-13 ">
                            {title}
                        </p>
                        <div className="d-flex align-center">
                            <p className="font-11 mr-5">
                                <span className="color-dark font-11">Pod Status: </span>
                                <span className="text-grey-83">{data.pod_status ? data.pod_status.status : "N/A"}</span>
                            </p>
                            <p className="font-11">
                                <span className="color-dark font-11">Message: </span>
                                <span className="text-grey-83 text-width-300"><span className="text-grey-83">{data.pod_status ? data.pod_status.message : 'N/A'}</span></span>
                            </p>
                        </div>


                    </div>
                </p>
            </div>
            <table className="table table-responsive pd-10">
                <tr>
                    <th className="text-grey-83">Container Name</th>
                    <th className="text-grey-83">Error Code</th>
                    <th className="text-grey-83">Status</th>
                    <th className="text-grey-83">Restart Count</th>
                    <th className="text-grey-83">Message</th>
                </tr>
                {data.pod_status && data.pod_status.containers_status ? Object.keys(data.pod_status.containers_status).map(key => (
                    <>{key != "error_found" && 
                        <tr>
                            <td>
                                {key}
                            </td>
                            <td>
                                {data.pod_status.containers_status[key].error_code ? data.pod_status.containers_status[key].error_code : "N/A"}
                            </td>
                            <td>
                                {data.pod_status.containers_status ? data.pod_status.containers_status[key].status : "N/A"}
                            </td>
                            <td>
                                {data.pod_status.containers_status[key].restart_count ? data.pod_status.containers_status[key].restart_count : "--"}
                            </td>
                            <td className="text-width-300 ">
                              
                                {data.pod_status.containers_status ? data.pod_status.containers_status[key].message : "N/A"}
                            </td>
                        </tr>}</>
                )) : <tr>
                        <td colSpan="5" className="text-center">No Contaniers Status Analysis is required or is not available!!</td>
                    </tr>}
            </table>
            <MobileStepper
                steps={steps}
                position="static"
                variant="dots"
                activeStep={activeStep}
                nextButton={
                    <IconButton size="small" onClick={handleNext} disabled={activeStep === steps - 1}>
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </IconButton>
                }
                backButton={
                    <IconButton size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                    </IconButton>
                }
            />
        </>
    )
}

TableRow.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        '& .MuiMobileStepper-root': {
            backgroundColor: '#fff'
        }
    },
    singleChart: {
        '& .MuiMobileStepper-root': {
            backgroundColor: '#fff'
        }
    }
}));
