import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Dialog, Slide } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import { getCommonFunctions } from '../../serviceRevamp/add/add_env/Index';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});
const PipelineVarsListing = ({ pipelineVars, openCard, flipAndOpenAdditionalFiledsForm, prevState, ...props }) => {
    const classes = useStyles();
    const inherits = props.inherits ? props.inherits : {};
    const [state, setState] = useState({})
    const commonFunctions = getCommonFunctions(state, setState, inherits);

    const changedValues = prevState

    console.log(changedValues, 'editeddd')

    return (

        <div className={classes.formCard} >
            {openCard ?

                <><div className="condition-grid " style={{}} >
                    <div className='' style={{ borderBottom: '1px solid #dedede' }}>
                        <div style={{ height: '50px' }} className='border-bottom d-flex space-between align-center'>
                            <label className='font-13 ml-12'>
                                Pipeline Variables
                            </label>
                            <button
                                className="btn btn-transparent text-anchor-blue d-flex align-center"
                                onClick={flipAndOpenAdditionalFiledsForm}>
                                <AddIcon className="text-anchor-blue" />
                                {"Update Pipeline Variables"}
                            </button>
                        </div>
                        <p className='font-12 custion_fields border-bottom' style={{ borderBottom: '1px solid #dedede' }}>
                            <span className='key'>Field Name</span>
                            <span>Value</span>
                            <span>Over-Ridable</span>
                            <span>Mandatory</span>
                        </p>
                    </div>
                    {
                        pipelineVars?.length > 0 ?

                            pipelineVars?.map((item) => {
                                const targetId = item.id
                                if (changedValues != undefined && changedValues[targetId] != undefined) {
                                    item.value = changedValues[targetId]
                                }

                                return (
                                    <>

                                        <p className="custion_fields font-12  pd-3">
                                            <span className=" key">{item.key} </span>
                                            <span className="key">{
                                                item.value ? item.value : 'NA'
                                            }</span>
                                            <span className="text-grey-83 key">{item["over-ridable"] ? "true" : "false"} </span>
                                            <span className="val">{item["mandatory"] ? "true" : "false"}</span>
                                        </p>
                                    </>
                                )
                            })
                            : <p className='font-12'>Pipeline Variables are not available</p>

                    }
                </div>
                </>

                : null
            }

        </div>
    )
}
export default PipelineVarsListing;



const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    root: {
        '& .h-300': {
            height: 350,
            overflowy: 'scroll'
        }
    },
    formCard: {
        backgroundColor: '#fff',
        margin: '10px',
        border: '1px solid #eaeaea'
        // '& .MuiTextField-root': {
        //     '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        //         padding: '0px 14px!important'
        //     }

        // }

    },

    cardHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #C5C5C5',
        fontSize: '14px',
        fontWeight: 400,
        color: '#fff',
        backgroundColor: 'rgb(18, 77, 155)',
        padding: '10px 20px'
    },
    cardfooter: {
        border: '1px solid #eaeaea',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px'
    },
    custom_css: {
        '& .custom-switch': {
            borderTop: '0px solid transparent!important',
            '& .card-header': {
                border: 'none!important',
                height: '49px',
                '& .card-header': {

                }
            }
        }
    }
}));
