import React, { useEffect, useState } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from '@material-ui/core';
import { Input } from '../../components/genericComponents/Input';
import { ValidateDataSet, VALIDATION_TYPE_REQUIRED } from '../../util/Validator';
import MultiRow from '../../components/genericComponents/MultiRow';
import { getCommonFunctions } from '../serviceRevamp/add/ci_flow/SourceDetails';
import AddSingleRowForm from './components/AddSingleRowForm';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CopyToClipboard } from '../../util/util';
import { Link } from 'react-router-dom';
import { Redirect, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import InvokeApi, { PostData } from '../../util/apiInvoker';
import GenerateURL from '../../util/APIUrlProvider';
import properties from '../../properties/properties';
const AddStabilityMatrix = () => {
    const classes = useStyles();
    const { stability_id } = useParams();
    const inherits = {}
    const [state, setState] = useState({
        data: {
        },
        error: {},
        validations: {
            group_name: [VALIDATION_TYPE_REQUIRED],
        },
        child_inherits: {
            add_more_matrix_child: {
                "validateForm": () => { return { valid: true }; },
                "getState": () => { return {}; },
                "getData": () => { return {}; }
            }
        },
        redirect: false
    });

    // 
    useEffect(() => {
        if (stability_id) {
            console.log(stability_id, "stability_id")
            fetchStabilityMatrix(stability_id)
        }
    }, [])
    const commonFunctions = getCommonFunctions(state, setState, inherits)
    const validateAndSubmit = () => {
        var result = ValidateDataSet(state.data, state.validations);
        var child_state_validation = inherits.validateForm && inherits.validateForm() ? inherits.validateForm() : { valid: true };
        var child_state = inherits.getState && inherits.getState() ? inherits.getState() : null;
        console.log("metricsData", child_state)
        if (result.valid && child_state_validation.valid) {
            const metrics_list = Object.keys(child_state.add_more_matrix_child.add_matrix.child_inherits).map((ele, index) => {
                return {
                    name: child_state.add_more_matrix_child.add_matrix[ele].data.name,
                    provider: child_state.add_more_matrix_child.add_matrix[ele].monitoring_resources[0]?.id,
                    invalid_data_strategy: "default",
                    query: child_state.add_more_matrix_child.add_matrix[ele].data.matrix_url
                };
            })
            const data = {
                name: child_state.data.group_name,
                metric_list: metrics_list
            }

            PostData(GenerateURL({}, properties.api.stability_matrix_url), data, handleSuccessForm, handleFailedForm)
        } else {
            setState((new_state) => ({
                ...new_state,
                error: {
                    ...new_state.error,
                    ...result.error
                }
            }));
        }
    }
    function handleSuccessForm(response) {
        setState((prevState) => ({
            ...prevState,
            data: response,
            redirect: true
        }))
    }
    function handleFailedForm(error) {
        console.log(error, "response")
    }

    function fetchStabilityMatrix(id) {
        var requestInfo = {
            endPoint: GenerateURL({ id: id }, properties.api.stability_matrix_url_single),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        console.log(requestInfo, stability_id, "requestInfo")
        InvokeApi(requestInfo, handleSuccessGetStability, onFail);
    }

    function handleSuccessGetStability(response) {
        console.log(response, "response")
        setState((prevState) => ({
            ...prevState,
            data: {
                group_name: response.name
            }
        }));
    }

    function onFail(error) {
        console.log(error, "error")
    }


    console.log(state.data, "state.data")
    return (
        <div className={classes.root}>
            {
                state.redirect && <Redirect to="/stability/matrix" />
            }

            <div className={classes.stabilityMatrix}>
                <h3>ADD STABILITY MATRIX GROUP</h3>
                <div className={classes.formCard}>
                    <Grid item lg={12}>
                        <Input
                            type="text"
                            placeholder="Enter Name"
                            label={"Group Name"}
                            name="group_name"
                            style={{
                                height: '40px',
                                color: '#787878',
                                fontSize: 14,
                            }}
                            error={state.error}
                            mandatorySign
                            onChangeHandler={commonFunctions.onChangeHandler}
                            data={state.data}
                        />
                    </Grid>
                    <Grid lg={12}>
                        <div>
                            <div className='d-flex align-center space-between mb-10'>
                                <p className=''>Add Matrix<span className='color-red'>*</span></p>
                                <VariableListDialogList />
                            </div>
                            <div className='font-12 d-grid mb-10 w-100' style={{
                                backgroundColor: '#F5FAFF',
                                color: '#787878',
                                padding: '6px 10px',
                                gridTemplateColumns: "20% 20% 20% 28% 12%",
                                gridColumnGap: "20px"
                            }}>
                                <p>NAME</p>
                                <p>SELECT PROVIDER</p>
                                <p>INVALID DATA STRATEGY</p>
                                <p>QUERY</p>
                            </div>
                            <AddSingleRowForm
                                prev_state={state.add_more_matrix_child}
                                inherits={state.child_inherits.add_more_matrix_child}
                                data={state.data}
                            />
                        </div>
                    </Grid>
                </div>
            </div>
            <div className='footer-bg d-flex align-center justify-end pd-20' style={{ backgroundColor: "#fafafa", gap: "10px" }}>
                <Link to={"/stability/matrix"}>
                    <button className='cancel-button'>Cancel</button>
                </Link>
                {stability_id ? <button className='add-group-btn' onClick={validateAndSubmit}>Update Group</button> :
                    <button className='add-group-btn' onClick={validateAndSubmit}>Add Group</button>}
            </div>
        </div>
    )
}

export default AddStabilityMatrix;


const VariableListDialogList = (props) => {
    const classes = useStylesDialog();
    const [open, setOpen] = React.useState(false);
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('md');
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <button className='variable-list-button' onClick={handleClickOpen}>Variable List</button>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <div className={classes.root}>
                    <div className='variable_list_header d-flex align-center space-between'>
                        <p>VARIABLE LIST</p>
                        <IconButton onClick={handleClose}>
                            <CloseIcon className='font-24' />
                        </IconButton>
                    </div>
                    <div className='variable_list_body pd-20'>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon fontSize='large' />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className='accordion_summary'
                            >
                                Kubernetes
                            </AccordionSummary>
                            <AccordionDetails className='Accordion_Details'>
                                <div className='table_accordion_detail'>
                                    <p>
                                        Name
                                    </p>
                                    <p>
                                        Code
                                    </p>
                                    <p>
                                        Description
                                    </p>
                                </div>

                                <div className='table_accordion_body'>
                                    <p>Service_Name</p>
                                    <p className='d-flex align-center line-with-copy'>{"${service_name}"}
                                        <div className='line-with-copy-hover'>
                                            <CopyToClipboard data={"${service_name}"} />
                                        </div>

                                    </p>
                                    <p>
                                        Displaying overall summary of pipelines associated with this app
                                    </p>
                                </div>

                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export function getAddMoreMountDetails() {
    return {
        step_mount_list: [],
        data: {
        },
        error: {},
        validations: {
            mountName: [VALIDATION_TYPE_REQUIRED]
        },

    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 20,
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0px 8px 24px 0px rgba(0, 0, 0, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.08)",
        '& .with-header': {
            display: 'none'
        },
        '& .transparent-btn-add-more': {
            fontSize: '12px',
            fontWeight: 500,
            color: '#0086FF',
        }
    },
    stabilityMatrix: {
        padding: "20px",
        '&>h3': {
            color: 'black',
            fontSize: 16,
            fontWeight: '600',
            textTransform: 'uppercase',
            wordWrap: 'break-word'
        }

    },
    formCard: {

        paddingTop: '24px',
        '& .MuiTextField-root': {
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
                padding: '0px 14px!important'
            }

        },
        '& .label-controller': {
            display: 'inline-block',
            marginBottom: '8px'
        }

    },
    mainCard: {
        '&>p': {
            borderBottom: '1px solid #e6e6e6',
            padding: '10px 20px',
        },
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 120px',
        backgroundColor: '#fff',
        border: '1px solid #e6e6e6',
        borderBottom: '0px',
        borderRadius: '6px',
        overflow: 'hidden',
        '& .mainCardItem': {
            alignSelf: 'center',
            display: 'flex',
            alignItems: 'center',
            padding: '10px 20px',
            borderBottom: '1px solid #e6e6e6',
            minHeight: '75px',
            '& .success-chip': {
                backgroundColor: '#E6FBEA',
                color: '#129E5B',
                width: 'fit-content',
                padding: '4px 8px',
                borderRadius: '24px'
            },
            '& .not-success-chip': {
                backgroundColor: '#FCF6E1',
                color: '#E1941D',
                width: 'fit-content',
                padding: '4px 8px',
                borderRadius: '24px'
            },
        },
        '& .item1': {
            '& .thumbnail_outer': {
                boxShadow: '0px 2.2px 2.2px 0px #00000014',
                height: '52px',
                width: '52px',
                borderRadius: 26,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            },
            '& .logo_thumbnail': {
                // boxShadow: '0px 2.2px 2.2px 0px #00000014',
                height: '48px',
                width: '48px',
                borderRadius: 24,
                border: '2px solid #f4f4f4',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'

            }
        }
    }
}));
const useStylesDialog = makeStyles((theme) => ({
    root: {
        '& .variable_list_header': {
            backgroundColor: '#fafafa',
            padding: '16px',
            color: '#2f2f2f',
            fontSize: '16px',
            fontWeight: 600
        },
        '& .accordion_summary': {
            background: ' linear-gradient(180deg, #FFF 0%, #F5F5F5 100%)'
        },
        '& .Accordion_Details': {
            padding: '10px',
            width: '100%',
            display: 'block',
            '& .table_accordion_detail': {
                width: '100%',
                borderRadius: '8px 8px 0px 0px',
                border: '1px solid  #E6E6E6',
                background: '#F5FAFF',
                display: 'grid',
                gridTemplateColumns: '180px 180px 1fr',
                gap: 20,
                padding: 10
            },
            '& .table_accordion_body': {
                width: '100%',
                borderRadius: '0px 0px 8px 8px',
                border: '1px solid  #E6E6E6',
                background: '#fff',
                display: 'grid',
                fontSize: '12px',
                color: '#787878',
                gridTemplateColumns: '180px 180px 1fr',
                gap: 20,
                padding: 10
            }
        }
    },

}));




