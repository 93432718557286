import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TopHeaderPremium from './TopHeaderPremium';
import { Grid } from '@material-ui/core';
import { getCommonFunctions } from '../../../../views/serviceRevamp/add/ci_flow/SourceDetails';
import { Input } from '../../Input';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import { PostData } from '../../../../util/apiInvoker';
import { VALIDATION_TYPE_REQUIRED, ValidateDataSet } from '../../../../util/Validator';
import { GetAuth } from '../../../../util/security';

const PremiumFeatureQueryForm = ({ onSubmit, onClose, onChangeVeiw }) => {

    const classes = useStyles();
    const userData = GetAuth()
    const [state, setState] = useState({
        data: {
            organisation_name: properties?.company_name,
            full_name: userData?.name ? userData?.name : userData?.email
        },
        error: {},
        formError: null,
        validations: {
            full_name: [VALIDATION_TYPE_REQUIRED],
            mobile_no: [VALIDATION_TYPE_REQUIRED],
            organisation_name: [VALIDATION_TYPE_REQUIRED],
            message: [VALIDATION_TYPE_REQUIRED]
        },
    })
    const commonFunctions = getCommonFunctions(state, setState, {})

    console.log(state.data, 'gmail_00pp')

    const onHandleSubmit = () => {
        const data = state?.data
        let result = ValidateDataSet(data, state.validations);
        console.log('res_00p_p', result)

        if (result?.valid) {

            const endPoint = GenerateURL({}, properties.api.premiumFeatureQuery);
            PostData(endPoint, data, (response) => {
                if (response) {
                    onSubmit(data)
                }
            },
                (error) => {
                    setState(prevState => ({
                        ...prevState,
                        formError: error
                    }))
                });
        }
        else {
            setState(prevState => ({
                ...prevState,
                error: result?.error
            }))
        }
    }


    return (

        <div className={classes.root}>
            <TopHeaderPremium onClose={onClose} />
            <div className='middle'>
                <Grid className='mb-5' spacing={2} container alignItems="center">
                    <Grid item lg={6}>
                        <div className='input-component-mb-0'>
                            <Input
                                mandatorySign
                                type="text"
                                name="full_name"
                                label="Full Name"
                                onChangeHandler={false}
                                error={state.error}
                                data={state.data} />
                        </div>
                    </Grid>
                    <Grid item lg={6}>
                        <div className='input-component-mb-0'>

                            <Input
                                mandatorySign
                                type="number"
                                name="mobile_no"
                                label="Mobile Number"
                                onChangeHandler={commonFunctions.onChangeHandler}
                                error={state.error}
                                data={state.data} />
                        </div>
                    </Grid>
                    <Grid item lg={12}>
                        <div className='input-component-mb-0'>

                            <Input
                                mandatorySign
                                type="text"
                                name="organisation_name"
                                label="Organisation Name"
                                onChangeHandler={false}
                                error={state.error}
                                data={state.data} />
                        </div>
                    </Grid>

                    <Grid item lg={12}>
                        <div className='input-component-mb-0'>
                            <Input
                                mandatorySign
                                type="textarea"
                                name="message"
                                label="Message"
                                onChangeHandler={commonFunctions.onChangeHandler}
                                error={state.error}
                                data={state.data} />
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className='bottom'>
                <button onClick={() => onChangeVeiw(1)} className='con-back'>
                    <div className='back-text'>
                        Back
                    </div>
                </button>
                <button onClick={onHandleSubmit} className='con-sales'>
                    <div className='con-text'>
                        Submit
                    </div>
                </button>
            </div>
        </div>
    )
}

export default PremiumFeatureQueryForm;

const useStyles = makeStyles({
    root: {
        width: '100%',
        boxShadow: '0px 8px 24px 0px #0000000A',
        background: '#FFFFFF',
        borderRadius: '12px',

        '& .middle': {
            padding: '20px',
            borderBottom: '1px solid #D0D0D0'
        },
        '& .bottom': {
            height: '72px',
            padding: '16px 20px 16px 20px',
            '& .con-sales': {
                float: 'right',
                width: '80px',
                height: '40px',
                borderRadius: '7px',
                background: '#0086FF',
                border: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            '& .con-back': {
                float: 'left',
                width: '80px',
                height: '40px',
                borderRadius: '7px',
                background: '#fff',
                border: '1px solid #dedede',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            '& .back-text': {
                color: 'gray',
                fontSize: '12px',
                fontWeight: '600',
                lineHeight: '14.63px',
                textAlign: 'left',
                textTransform: 'uppercase',
                marginLeft: '5px'
            },
            '& .con-text': {
                color: '#fff',
                fontSize: '12px',
                fontWeight: '600',
                lineHeight: '14.63px',
                textAlign: 'left',
                textTransform: 'uppercase',
                marginLeft: '5px'
            }
        },
        '& .premium-op': {
            color: '#FDAF36',
            fontSize: '15.59px',
            fontWeight: '700',
            lineHeight: '19px',
            textAlign: 'center',
            textTransform: 'uppercase'
        }
    }
});

