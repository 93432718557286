import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NoReposConfigured from './NoReposConfigured';
import SupportedRepos from './SupportedRepos';

const NoRepoIntegrated = () => {
    const classes = useStyles()
  return (
            <div className={classes.root}>
                <div>
                    <NoReposConfigured />
                </div>
                <div>
                    <SupportedRepos />
                </div>
            </div>
  )
}

const useStyles = makeStyles({
    root: {
        margin:'auto',
        marginTop: '35px',
        // border:'1px solid #eee',
        // boxShadow:'rgb(0 0 0 / 21%) 0px 4px 4.5px',
        overflow: 'hidden',
        borderRadius: '10px'
    }
});
export default NoRepoIntegrated;