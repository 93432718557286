import Base64 from 'base-64';
const ParseFile = (e,onParsingComplete,onError) => {
  try {
      var event_name = e.target.name;
      var file_to_load = e.target.files[0];
      var file_reader = new FileReader();
      var text_from_file_loaded = null;
      file_reader.onload = function(fileLoadedEvent){
        text_from_file_loaded = fileLoadedEvent.target.result;
        console.log("hello-0-0-99999",fileLoadedEvent.target)
        try {
          var content = Base64.encode(text_from_file_loaded);
        
        var file_data = {
          event_name:event_name,
          name:file_to_load.name,
          content: content
          };
          onParsingComplete(file_data);  
        } catch (error) {
          onError(error);
        }
        
      }
      file_reader.readAsText(file_to_load, "UTF-8"); 
  } catch (error) {
    onError(error);
  }
  e.target.value=null
}

export default ParseFile;