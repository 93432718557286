import { makeStyles, Tooltip } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import properties from '../../../../properties/properties';
import { ErrorComponent } from '../../../utils/Error';
import { JiraTicket } from '../../../releasePackage/add/components/AddJiraStories';
import GenericSkeleton from '../../../../components/genericComponents/Skeletons/GenericSkeleton';

const ReleasePackageOverview = ({selectedReleasePackage,setSelectedReleasePackage}) => {
    const [state,setState] = useState({
        loading:true,
        data: {},
        error: null,
    });
    const classes = styles();
    useEffect(()=>{
        getReleasePackage();
    },[selectedReleasePackage])
    function getReleasePackage(){
        let requestInfo = {
            endPoint: GenerateURL({ release_package_id:selectedReleasePackage }, properties.api.get_release_package),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        };
        InvokeApi(requestInfo, getReleasePackageSuccess,getReleasePackageFailure);
        setState((prevState)=>({
            ...prevState,
            loading:true,
        }))
    }

    function getAppWiseServices(change_set){
        let appWiseObj = {};
        for (let i of change_set) {
            let appName = i.app;
            if (!appWiseObj[appName]) {
                appWiseObj[appName] = { services: [] };
            }
            appWiseObj[appName].services.push(i.service);
        }
        return appWiseObj;
    }
    
    function getReleasePackageSuccess(response){
        console.log("sjdhjshdjs",response);
        const appWiseService = getAppWiseServices(response.change_set);
        console.log("dshjshjds",appWiseService);
        setState((prevState)=>({
            ...prevState,
            loading:false,
            data:{
               ...response,
               appWiseServices: appWiseService,
            }
        }))
    }

    function getReleasePackageFailure(error){
        console.log("sjdhjshdjs",error);
        let error_msg = error.toString();
        setState((prevState)=>({
            ...prevState,
            loading:false,
            error:error_msg
        }))
    }

    console.log("kdjsjkd",state);
    return (
        <>
            <div className={classes.backBtn} onClick={()=>setSelectedReleasePackage(null)}>
            <span className='ri-arrow-left-s-line font-20' style={{lineHeight:'1'}}></span><span className='font-12 font-weight-500 text-transform-uppercase back-text'>Back</span>
            </div>
            {state.error ? 
                <ErrorComponent error={state.error} variant={'error-box'} />
            : 
            state.loading ? 
               <ReleaseOverviewSkeleton />
            : 
            <div className={classes.root}>
                <div className='d-flex align-center' style={{gap:'12px'}}>
                    <div className={classes.iconContainer}>
                        <span className='ri-inbox-line font-28'></span>
                    </div>
                    <div className='d-flex f-direction-column'>
                        <span className={`font-16 font-weight-600 color-value text-ellipsis`}>{state.data.release_version}</span>
                        <span className='font-14 font-weight-600 color-key-78 text-ellipsis'>{`${state.data.change_set.length} Services`}</span>
                    </div>
                </div>
                <div className='d-flex f-direction-column' style={{gap: "8px"}}>
                    <span className='font-14 font-weight-600 color-value'>Release Description</span>
                    <span className='font-14 font-weight-500 color-icon-secondary'>
                        {state.data?.release_description || "NA"}
                    </span>
                </div>
                {state.data?.jira_stories && state.data?.jira_stories.length > 0 ? 
                    <div className='d-flex f-direction-column' style={{gap: "12px"}}>
                        <span className='font-14 font-weight-600 color-value'>Jira Stories</span>
                        <div className='d-flex f-direction-column' style={{gap: "8px"}}>
                            {
                                state.data?.jira_stories ?
                                    state.data.jira_stories.map((ticket)=>{
                                        return <JiraTicket jiraObject={ticket} isLink={true} isFinalise={true}/>
                                    })
                            : null
                            }
                        </div>
                    </div>
                : null} 
                {state.data?.appWiseServices ? 
                    <div className='d-flex f-direction-column' style={{gap: "12px"}}>
                        <span className='font-14 font-weight-600 color-value'>Services Details</span>
                        <div className={classes.addedServiceBox}>
                            {Object.keys(state.data.appWiseServices).map(key =>{
                                let appServiceList = state.data.appWiseServices[key].services;
                                return (
                                    <div className='single-app-box' >
                                    <Tooltip title={key} arrow>
                                    <div className='app-chip'>
                                        <span className='app-text font-12 font-weight-600'><span className='ri-apps-line font-16 mb-8 font-weight-100'></span>{key.length > 12 ? `${key.slice(0,13)}...` : key}</span>
                                    </div>
                                    </Tooltip>
                                    <div className='d-flex flex-wrap' style={{gap:'10px'}}>
                                        {appServiceList.map(serv => {
                                        return (
                                            <span className='service-chip' >{serv}</span>
                                        );
                                        })}
                                    </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                : null}
            </div>
            }
            
            
        </>
    )
}

export default ReleasePackageOverview;

const ReleaseOverviewSkeleton = ()=>{
    const classes = styles();
    return (
        <div className={classes.root}>
            <div className='d-flex align-center' style={{gap:'12px'}}>
                <GenericSkeleton width={56} height={56} style={{borderRadius: "8px"}} rootStyle={{flex:'0'}}/>
                <div className='d-flex f-direction-column' style={{gap:'4px',width:'40%'}}>
                    <GenericSkeleton height={24} />
                    <GenericSkeleton height={21} />
                </div>
            </div>
            <div className='d-flex f-direction-column' style={{gap: "8px"}}>
                <span className='font-14 font-weight-600 color-value'>Release Description</span>
                <GenericSkeleton height={50} style={{borderRadius:'6px'}} />
            </div>
            <div className='d-flex f-direction-column' style={{gap:'6px'}}>
                <span className='font-14 font-weight-600 color-value'>Jira Stories</span>
                <div className='d-flex f-direction-column' style={{gap: "8px"}}>
                    {Array.from({length:3}).map((_,index)=>{
                        return <GenericSkeleton height={48} style={{borderRadius:'6px'}} key={index}/>
                    })}
                </div>
            </div>
            <div className='d-flex f-direction-column' style={{gap: "12px"}}>
                <span className='font-14 font-weight-600 color-value'>Services Details</span>
                <GenericSkeleton height={200} style={{borderRadius:'6px'}}/>
            </div>
        </div>
    )
}

const styles = makeStyles((theme)=>({
    root:{
        display:'flex',
        flexDirection:'column',
        gap:'16px',
        marginTop:'24px',
        margin:'10px'
    },
    backBtn: {
        color:'#0086FF',
        display:'flex',
        alignItems:'center',
        gap:'2px',
        cursor:'pointer',
        '& .back-text':{
            "&:hover":{
                textDecoration:'underline',
            }
        }
    },
    addedServiceBox:{
        padding:'16px',
        border:'1px solid #E6E6E6',
        '& .single-app-box':{
          display:'flex',
          gap:'12px',
          padding:'15px 0px 15px 0px',
          borderBottom:'1px solid #E6E6E6',
          height:'165px'
        },
        '& > .single-app-box:first-child':{
          paddingTop:'0px',
        },
        '& > .single-app-box:last-child':{
          paddingBottom:'0px',
          borderBottom:'none',
        },
        '& .service-chip':{
          padding:'8px 12px 8px 12px',
          border:'1px solid #E6E6E6',
          borderRadius:'26px',
          fontSize:'12px',
          fontWeight:'600',
          color:'#2f2f2f',
          height: 'fit-content',
          flexWrap:'wrap',
          display:'flex',
          alignItems:'center',
          transition:'all 0.4s ease-in-out',
          cursor:'pointer',
          '&:hover':{
            borderColor:'#AEAEAE'
          }
        },
        '& .app-chip':{
          padding:'16px 4px 16px 4px',
          borderRadius:'4px',
          border:'1px solid #DFEDFF',
          backgroundColor:'#F5FAFF',
          color:'#787878',
          height:'100%',
          width:'24px',
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          '& .app-text':{
            transform:'rotate(180deg)',
            writingMode:'vertical-rl',
            textWrap:'nowrap',
          }
        }
    },
    iconContainer:{
        width:"56px",
        height:"56px",
        borderRadius: "8px",
        backgroundColor:"rgba(0, 134, 255, 0.08)",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        color:"#0086FF",
    },
     
}))