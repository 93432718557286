import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Delete from '../../../components/genericComponents/Delete';
import properties from '../../../properties/properties';
import GenerateURL from '../../../util/APIUrlProvider';
import SquareAvatar from '../../../components/genericComponents/AvatarSquare';

const MonitoringSourceComponent = ({data, setDataToParent}, props) => {
    const refreshFun = props.refreshFun ? props.refreshFun : ()=>{};
    console.log(setDataToParent,"message__")
    return (
        <>
            <div className='item1 mainCardItem'>
                {/* <div className='thumbnail_outer mr-5'>
                    <div className='logo_thumbnail'>
                        <img src='/images/sourceMonitoringIcons/new_relic.svg' alt=".." />
                    </div>
                </div> */}
                <SquareAvatar shape={'round'} name={data?.credential_id?.name||"N/A"} />
                <p className='font-13 font-weight-600 color-blue'>
                    {data?.credential_id?.name||"N/A"}
                </p>
            </div>
            <div className='item2 mainCardItem'>
                <p className='font-12 color-icon-secondary text-ellipsis-150'>{data?.monitoring_url||"N/A"}</p>
            </div>
            <div className='item3 mainCardItem'>
                <div className='d-flex align-center space-between' style={{ width: '220px' }}>
                    <div className='d-flex align-center success-chip'>
                        <span className='ri-checkbox-circle-fill font-20 mr-5'></span>
                        <span className='font-12'>CONNECTED</span>
                    </div>
                    <button className='btn btn-outline-primary font-weight-600'>
                        TEST
                    </button>
                </div>
            </div>
            <div className='item4 mainCardItem'>
                <div className='btn-icon-group-div'>
                    <button className='btn btn-icon-outline btn-icon-outline-primary' onClick={()=>{setDataToParent(data)}}>
                        <span className='font-20 ri-edit-line'></span>
                    </button>
                    <Delete
                    varient="newUI"
                    display_data_name={null}
                    data={{ entity_id: data?.id, name: "monitoring_source" }}
                    refresh={refreshFun} 
                    api_link={GenerateURL({ id: data?.id }, properties.api.monitoring_resource_update)}
                    />
                    
                </div>
            </div>
        </>
    )
}

export default MonitoringSourceComponent

