import React, { useEffect, useState } from 'react'
import GenerateURL from '../../../util/APIUrlProvider';
import properties from '../../../properties/properties';
import InvokeApi from '../../../util/apiInvoker';
import PropTypes from 'prop-types';
import { Snackbar, Tooltip, makeStyles } from '@material-ui/core';
import { GetAuth } from '../../../util/security';
import { Loading, LoadingText } from '../../utils/Loading';
import PromoteActionCard from '../detail/components/PromoteActionCard';
import DeployActionCardNew from '../detail/components/DeployActionCardNew';
import TriggerActionCardNew from './components/TriggerActionCardNew';
import MonitoringDialogue from '../detail/components/MonitoringDialogue';
import HistoryRevamp from './components/HistoryRevamp';
import { Link } from 'react-router-dom';
import JobCardServiceCardForDynamicJob from '../detail/components/servicesTemplates/jobCard/JobCardServiceCardForDynamicJob';
import DeploySectionNew from './DeploySectionNew';
import CISectionNew from './CISectionNew';
import { Alert } from '@material-ui/lab';
import GenericSkeleton from '../../../components/genericComponents/Skeletons/GenericSkeleton';
import useFetchPermissions from '../../../components/customHooks/useFetchPermissions';
const CICDSection = ({ dynamicJobs, selectedTemplate, configuredJobs, ...props }) => {
    const env_ci_cd_data = props.env_ci_cd_data;
    console.log(
        env_ci_cd_data, "env_ci_cd_data"
    )
    // ci_cd_data: response,
    //         build_details: response.last_build && response.last_build[0],
    //         deploy_details: find_selected_deployed_details,
    //         promote_details: response.last_promote,
    //         cd_configured: response.env_cd_detail,
    //         ci_configured: response.env_ci_detail,
    //         manual_build: response.manual_build,
    //         manual_deploy: response.manual_deploy,
    //         data_loading: false

    const [state, setState] = useState({
        loaded: false,
        action_card: "",
        data_loading: true
    });
    const classes = useStyles();
    const extraProps = props.extraProps;
    const application_id = props.application_id;
    const service_id = props.service_id ? props.service_id : null;
    const env_id = props.env_id ? props.env_id : null;
    const service_data = props.service_data;
    const count = props.count;
    const refresh = props.refresh;
    const selectedServiceEnv = props.selectedServiceEnv
    const masterLabel = props.masterLabel;
    const env_list = props.env_list;
    const deploy_id = props.deploy_id;
    const wideget_deployment_name = props.wideget_deployment_name;
    const service_env_name = env_list[0].tabName;
    const all_available_deployments = props.all_available_deployments
    const all_available_deployments_without_widget = props.all_available_deployments_without_widget
    const monitoringSectionBtn = props.monitoringSectionBtn
    console.log(all_available_deployments, all_available_deployments_without_widget,"sbdgvsghdvchgdsv")
    useEffect(() => {
        var find_selected_deployed_details = env_ci_cd_data.last_deploy && env_ci_cd_data.last_deploy.length > 0 ? env_ci_cd_data.last_deploy.find(item => item.env_cd_detail_id == deploy_id) : null
        fetchCIData()
        // fetchCdData()
        setState(new_state => ({
            ...new_state,
            loaded: true,
            ci_cd_data: env_ci_cd_data,
            build_details: env_ci_cd_data.last_build && env_ci_cd_data.last_build[0],
            deploy_details: find_selected_deployed_details,
            promote_details: env_ci_cd_data.last_promote,
            cd_configured: env_ci_cd_data.env_cd_detail,
            ci_configured: env_ci_cd_data.env_ci_detail,
            manual_build: env_ci_cd_data.manual_build,
            manual_deploy: env_ci_cd_data.manual_deploy,
            data_loading: false
        }));
    }, [env_ci_cd_data, env_id, count, deploy_id])


    // useEffect(() => {

    //     const placeholders = {
    //         application_id: application_id,
    //         service_id: service_id,
    //         env_id: env_id
    //     }
    //     var requestInfo = {
    //         endPoint: GenerateURL(placeholders, properties.api.env_ci_cd_data_url),
    //         httpMethod: "GET",
    //         httpHeaders: { "Content-Type": "application/json" }
    //     }
    //     InvokeApi(requestInfo, handleSuccessApiHit, handleFailedApiHit);
    //     setState(new_state => ({
    //         ...new_state,
    //         loaded: false,
    //         data_loading: true,
    //         action_card: ""
    //     }));


    // }, [env_id, count, deploy_id]); 

    useEffect(() => {

        if (state.multiple_build_enabled) {
            fetchLastParentCiData()
        }
    }, [state.multiple_build_enabled])


    const handleSuccessApiHit = (response) => {
        console.log(response)
        var find_selected_deployed_details = response.last_deploy && response.last_deploy.length > 0 ? response.last_deploy.find(item => item.env_cd_detail_id == deploy_id) : null
        fetchCIData()
        fetchCdData()
        setState(new_state => ({
            ...new_state,
            loaded: true,
            ci_cd_data: response,
            build_details: response.last_build && response.last_build[0],
            deploy_details: find_selected_deployed_details,
            promote_details: response.last_promote,
            cd_configured: response.env_cd_detail,
            ci_configured: response.env_ci_detail,
            manual_build: response.manual_build,
            manual_deploy: response.manual_deploy,
            data_loading: false
        }));
    }
    const handleFailedApiHit = (error) => {
        setState(new_state => ({
            ...new_state,
            error: error,
            data_loading: false
        }))
    }

    function fetchSystemSettingsData() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.system_settings),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, fetchSystemSettingsDataSuccess, fetchSystemSettingsDataFailed);

        setState(new_state => ({
            ...new_state,
            data_loading: false
        }))

    }

    function fetchSystemSettingsDataSuccess(data) {
        var filter_cd_versioning_data = data.filter(item => item.key == "VERSIONING_CD_ENABLE");
        var deploy_env_offline = data.filter(item => item.key == "DISABLE_DEPLOY_IF_ENV_OFFLINE");
        var disable_parallel_build = data.find(item => item.key === "DISABLE_PARALLEL_BUILD");
        var multiple_build_enabled = data.find(item => item.key === "MULTIPLE_BUILD_CONFIGURATION")
        setState(new_state => ({
            ...new_state,
            available_settings: data,
            cd_settings: filter_cd_versioning_data,
            deploy_env_offline_flag: deploy_env_offline,
            disable_parallel_build: disable_parallel_build,
            multiple_build_enabled: multiple_build_enabled?.value == "true" ? true : false,
            data_loading: false
        }));

    }
    function fetchSystemSettingsDataFailed(error) {

        setState(new_state => ({
            ...new_state,
            error: error,
            data_loading: false
        }))
    }



    function fetchCIData() {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: service_id, component_env_id: env_id }, properties.api.save_ci),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, onFetchCIDataSuccess, onFetchCIDataFail)
        setState(new_state => ({
            ...new_state,
            ci_data_loading: true
        }));
    }

    function onFetchCIDataSuccess(response) {
        fetchSystemSettingsData()
        setState(new_state => ({
            ...new_state,
            ci_response: response,
            ci_data_loading: false
        }));

    }
    function onFetchCIDataFail(response) {
        setState(new_state => ({
            ...new_state,
            ci_response: null,
            ci_data_loading: false
        }));
    }

    function fetchLastParentCiData() {
        var requestInfo = {
            endPoint: GenerateURL({ component_id: service_id, env_id: env_id }, properties.api.last_parent_ci),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, onFetchParentCIDataSuccess, onFetchParentCIDataFail)
        setState(new_state => ({
            ...new_state,
            ci_data_loading: true
        }));
    }

    function onFetchParentCIDataSuccess(response) {
        console.log("jxcbvcxh", response)
        setState(new_state => ({
            ...new_state,
            ci_data_loading: false,
            build_details: response,
        }));
    }

    function onFetchParentCIDataFail(error) {
        setState(new_state => ({
            ...new_state,
            ci_data_loading: false
        }));
    }

    function fetchCdData() {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: service_id, component_env_id: env_id }, properties.api.save_cd),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, onFetchCdDataSuccess, onFetchCdDataFail)
        setState(new_state => ({
            ...new_state,
            cd_data_loading: true
        }));
    }

    function onFetchCdDataSuccess(response) {
        if (response && response.length > 0) {
            setState(new_state => ({
                ...new_state,
                cd_response: response[0],
                cd_data_loading: false,
                all_deployment_list: response.map(item => { return { label: item.deployment_name, id: item.id } }),
            }));
        } else {
            setState(new_state => ({
                ...new_state,
                cd_response: null,
                cd_data_loading: false
            }));
        }
    }

    function onFetchCdDataFail(response) {
        setState(new_state => ({
            ...new_state,
            cd_response: null,
            cd_data_loading: false
        }));
    }

    function toggleTriggerActionCard() {

        setState(fresh_state => ({
            ...fresh_state,
            action_card: !fresh_state.action_card,
            deploy_action_card: false,
            promote_action_card: false
        }));
    }
    function toggleTriggerPromoteActionCard(type) {
        setState(fresh_state => ({
            ...fresh_state,
            promote_action_card: !fresh_state.promote_action_card,
            action_card: false,
            deploy_action_card: false,
        }));
    }
    function toggleTriggerDeployActionCard(type) {
        setState(fresh_state => ({
            ...fresh_state,
            deploy_action_card: !fresh_state.deploy_action_card,
            promote_action_card: false,
            action_card: false,
        }));
    }
    function closeBox() {
        console.log("print statement")
        setState(new_state => ({
            ...new_state,
            action_card: null,
            deploy_action_card: null,
            promote_action_card: null,
        }));
    }
    function handleClose() {
        setState(new_state => ({
            ...new_state,
            trigger: null
        }));
    }

    const permissions = useFetchPermissions();
    var url_ci = GenerateURL({ service_id: service_id, component_env_id: env_id }, properties.api.save_ci, true);
    const is_ci_permited = permissions?.POST.includes(url_ci);
    var url_cd = GenerateURL({ service_id: service_id, component_env_id: env_id }, properties.api.save_cd, true);

    const is_cd_permited = permissions?.POST.includes(url_cd);

    const styleForConf = ConfigureDiv();

    const isDynamicJobsExist = state?.configuredJobs?.length > 0;

    useEffect(() => {

        if (dynamicJobs && configuredJobs) {
            let configuredJobsList = dynamicJobs?.filter(job => {
                if (configuredJobs[job?.job_code] && job?.dependent_entity == "SERVICE") {
                    return job
                }
            })

            setState(prevState => ({
                ...prevState,
                configuredJobs: configuredJobsList
            }))

        }
    }, [dynamicJobs, configuredJobs])


    return (
        <>
            {
                state.loaded ?
                    <>
                        <div className={styleForConf.container}>
                            {
                                state.data_loading ?
                                    <GenericSkeleton variant={"rect"} width={"100%"} height={196} style={{ borderRadius: "6px" }} />
                                    :
                                    state.ci_configured && Object.keys(state.ci_configured).length > 0 ?
                                        <CISectionNew
                                            isDynamicJobsExist={isDynamicJobsExist}
                                            refresh={refresh}
                                            count={count}
                                            build_details={env_ci_cd_data.last_build}
                                            promote_details={state.promote_details}
                                            service_id={service_id}
                                            is_ci_permited={is_ci_permited}
                                            env_id={env_id}
                                            manual_build_flag={state.manual_build}
                                            show_build_card={toggleTriggerActionCard}
                                            service_data={service_data} />
                                        :
                                        is_ci_permited ?

                                            <div style={{ width: isDynamicJobsExist ? '520px' : '98%', flex: isDynamicJobsExist ? '0 0 auto' : 'auto' }} className={styleForConf.root}>
                                                <div className="heading">
                                                    <span className='ri-tools-line font-20 d-flex align-center color-key-78'></span>
                                                    <div className='font-12 font-weight-600 color-icon-secondary avtar'>Configure BUILD details</div>
                                                </div>
                                                <div className={styleForConf.tiggerCase}>
                                                    <div className="real-card">
                                                        <Link className="text-anchor-blue d-block text-center" to={"/application/" + application_id + "/service/" + service_id + "/detail?build_details=true&env_id=" + env_id}>
                                                            <button className="btn-primary conf-btn">
                                                                <span className="ri-settings-4-line font-weight-500 font-20"></span>
                                                                <span className='avtar '>Configure</span>
                                                            </button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>


                                            :
                                            <div style={{ width: isDynamicJobsExist ? '520px' : '98%', flex: isDynamicJobsExist ? '0 0 auto' : 'auto' }} className={styleForConf.root}>
                                                <div className="heading">
                                                    <span className='ri-tools-line font-20 d-flex align-center color-key-78'></span>
                                                    <div className='font-12 font-weight-600 color-icon-secondary avtar'>Configure BUILD details</div>
                                                </div>
                                                <div className={styleForConf.tiggerCase}>
                                                    <div className="real-card">
                                                        <div className='className="text-anchor-blue mb-15 d-flex align-center justify-center text-center"'>
                                                            <button className="btn-disable btn-primary cursor-not-allowed conf-btn">
                                                                <span className="ri-settings-4-line font-weight-500 font-20"></span>
                                                                <span className='avtar '>Configure</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                            }
                            <> {state.data_loading ?
                                <GenericSkeleton variant={"rect"} width={"100%"} height={196}  style={{borderRadius:"6px"}}/>
                                :
                                state.cd_configured && state.cd_configured.length > 0 ?

                                    <DeploySectionNew
                                        isDynamicJobsExist={isDynamicJobsExist}
                                        deploy_details={state.deploy_details}
                                        uptime={props.uptime}
                                        restart={props.restart}
                                        show_deploy_card={() => { toggleTriggerDeployActionCard("DEPLOY") }}
                                        promote_details={state.promote_details}
                                        service_id={service_id}
                                        env_id={env_id}
                                        deploy_id={Number(deploy_id)}
                                        manual_deploy={state.manual_deploy}
                                        service_data={service_data}
                                        deploymentSelector={props.deploymentSelector} />
                                    :
                                    is_cd_permited ?
                                        <div style={{ width: isDynamicJobsExist ? '520px' : '98%', flex: isDynamicJobsExist ? '0 0 auto' : 'auto' }} className={styleForConf.root}>
                                            <div className="heading">
                                                <span className='ri-rocket-2-line font-20 d-flex align-center color-key-78'></span>
                                                <div className='font-12 font-weight-600 color-icon-secondary avtar'>Configure DEPLOY details</div>
                                            </div>
                                            <div className={styleForConf.tiggerCase} >
                                                <div className="real-card">
                                                    <Link className="text-anchor-blue d-block text-center" to={"/application/" + application_id + "/service/" + service_id + "/detail?deploy_details=true&env_id=" + env_id} >
                                                        <button className="btn-primary conf-btn">
                                                            <span className="ri-settings-4-line font-weight-500 font-20"></span>
                                                            <span className='avtar '>Configure</span>
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>

                                        :
                                        <div style={{ width: isDynamicJobsExist ? '520px' : '98%', flex: isDynamicJobsExist ? '0 0 auto' : 'auto' }} className={styleForConf.root}>
                                            <div className="heading">
                                                <span className='ri-rocket-2-line font-20 d-flex align-center color-key-78'></span>
                                                <div className='font-12 font-weight-600 color-icon-secondary'>Configure DEPLOY details</div>
                                            </div>
                                            <div className={styleForConf.tiggerCase}>
                                                <div className="real-card">
                                                    <div className='className="text-anchor-blue d-flex align-center justify-center text-center"'>
                                                        <button className="btn-disable btn-primary  cursor-not-allowed conf-btn">
                                                            <span className="ri-settings-4-line font-weight-500 font-20"></span>
                                                            <span className='avtar '>Configure</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                            }</>

                            {
                                state?.configuredJobs?.length > 0 && state?.configuredJobs?.map((job, index) => {
                                    let job_code = job.job_code
                                    let metaInfo = { configured: configuredJobs && configuredJobs[job_code] }
                                    let isLastCard = dynamicJobs?.length - 1 == index
                                    return (
                                        <>
                                            {
                                                <JobCardServiceCardForDynamicJob
                                                    isLastCardastCard={isLastCard}
                                                    service_data={service_data}
                                                    wholeJob={job}
                                                    index={index}
                                                    setSnackBar={setState}
                                                    application_id={application_id}
                                                    selectedServiceEnv={selectedServiceEnv}
                                                    wideget_deployment_name={wideget_deployment_name}
                                                    masterLabel={masterLabel}
                                                    configuredJobs={configuredJobs}
                                                    component_env_id={env_id}
                                                    component_id={service_id}
                                                    sub_env_id={env_id}
                                                    showOnlyConfigured={true}
                                                    service_id={service_id}
                                                    env_id={env_id}
                                                    data={{ ...job, templateName: selectedTemplate?.name, ...metaInfo }} />
                                            }

                                        </>
                                    )
                                })
                            }

                        </div>
                        <div>
                            {
                                (state.ci_configured || state.cd_configured) ?
                                    <>
                                        <span className="" style={{ marginTop: '10px', display: "100%", justifyContent: "flex-end", width: "100%", marginLeft: "99%" }}>
                                            <div className='' style={{ display: "flex" }}>

                                                <span className="btn-group width-fitcontent ml-auto" style={{ gap: "4px" }}> {/* btn-icon-group */}

                                                    {
                                                        state.manual_build ?
                                                            state.ci_data_loading ?
                                                                <button className="btn btn-flaticon" >
                                                                    <div className='small-loading'></div></button>
                                                                :

                                                                <Tooltip title="Build">
                                                                    {/* <button className="btn btn-flaticon" onClick={toggleTriggerActionCard}><span className="flaticon-flash-on-indicator"></span></button> */}
                                                                    <button className="btn-sq-icon-primary d-flex align-center" onClick={toggleTriggerActionCard}><span className="ri-flashlight-line vertical-align-super"></span></button>
                                                                </Tooltip>
                                                            : null}
                                                    {
                                                        state.manual_deploy ?
                                                            state.cd_data_loading ?
                                                                <button className="btn btn-flaticon" >
                                                                    <div className='small-loading'></div></button> :

                                                                <Tooltip title="Deploy">
                                                                    {/* <button className="btn btn-flaticon" onClick={() => { toggleTriggerDeployActionCard("DEPLOY") }}> <span className="flaticon-upload-button"></span></button> */}
                                                                    <button className="btn-sq-icon-primary d-flex align-center" onClick={() => { toggleTriggerDeployActionCard("DEPLOY") }}><span className="ri-upload-2-line vertical-align-super"></span></button>
                                                                </Tooltip>
                                                            : null}

                                                    {
                                                        state.manual_build ?
                                                            state.ci_data_loading ?
                                                                <button className="btn btn-flaticon" >
                                                                    <div className='small-loading'></div></button>
                                                                :
                                                                <>
                                                                    <Tooltip title="Promote">
                                                                        {/* <button className="btn btn-flaticon" title="Promote" onClick={() => { toggleTriggerPromoteActionCard("PROMOTE") }} ><span className="flaticon-forward-arrow"></span></button> */}
                                                                        <button className="btn-sq-icon-primary d-flex align-center" onClick={() => { toggleTriggerPromoteActionCard("PROMOTE") }}><span className="ri-share-forward-line vertical-align-super"></span></button>
                                                                    </Tooltip>
                                                                </>
                                                            : null
                                                    }

                                                    <HistoryRevamp
                                                        service_id={service_id}
                                                        env_id={env_id}
                                                        service_data={service_data}
                                                        is_env_down={selectedServiceEnv?.project_env?.is_env_down}
                                                        deploy_env_offline_flag={state.deploy_env_offline_flag && state.deploy_env_offline_flag[0] ? state.deploy_env_offline_flag[0] : null}
                                                        env_name={selectedServiceEnv.project_env.name}
                                                        masterLabel={masterLabel}
                                                        env_details={selectedServiceEnv}
                                                    />
                                                    {/* <MonitoringDialogue
                                                        application_id={service_data.project_id}
                                                        type={state.action_card}
                                                        disable_parallel_build={state.disable_parallel_build ? state.disable_parallel_build : null}
                                                        multiple_build_enabled={state.multiple_build_enabled?.value == "true" ? true : false}
                                                        service_id={service_id}
                                                        env_id={env_id}
                                                        cd_id={wideget_deployment_name}    //{deploy_id}
                                                        all_available_deployments={all_available_deployments.length > 0 ? all_available_deployments : all_available_deployments_without_widget.length > 0 ? all_available_deployments_without_widget : []}
                                                        env_name={selectedServiceEnv.project_env.name}
                                                        service_data={{ name: service_data.name, type: masterLabel }}
                                                        env_details={selectedServiceEnv}
                                                    /> */}
                                                    <Link target="_blank" to={{
                                                        pathname : `/deployment-analytics`,
                                                        search: `application_id=${encodeURIComponent(service_data.project_id)}&service_id=${encodeURIComponent(service_id)}&env_id=${encodeURIComponent(env_id)}&cd_id=${encodeURIComponent(wideget_deployment_name)}&env_name${encodeURIComponent(selectedServiceEnv.project_env.name)}&service_data=${encodeURIComponent(JSON.stringify({ name: service_data.name, type: masterLabel }))}&all_available_deployments=${encodeURIComponent(JSON.stringify( all_available_deployments.length > 0 ? all_available_deployments : all_available_deployments_without_widget.length > 0 ? all_available_deployments_without_widget : [] ))}`
                                                    }} 
                                                    >
                                                        <button className="btn-sq-icon-primary d-flex align-center" >
                                                            <span className="ri-airplay-line vertical-align-super"></span>
                                                        </button>
                                                    </Link>
                                                    {
                                                        monitoringSectionBtn
                                                    }
                                                </span>
                                                {

                                                    state.action_card ?

                                                        <TriggerActionCardNew
                                                            service_data={service_data}
                                                            configuration_data={state.ci_cd_data}
                                                            closebox={closeBox}
                                                            variant={"ServiceCard"}
                                                            is_env_down={selectedServiceEnv?.project_env?.is_env_down}
                                                            deploy_env_offline_flag={state.deploy_env_offline_flag && state.deploy_env_offline_flag[0] ? state.deploy_env_offline_flag[0] : null}
                                                            cd_data={state.cd_response ? [state.cd_response] : null}
                                                            button_state={{ build_button: true, deploy_button: true }}
                                                            application_id={service_data.project_id}
                                                            type={state.action_card}
                                                            disable_parallel_build={state.disable_parallel_build ? state.disable_parallel_build : null}
                                                            service_id={service_id}
                                                            env_id={env_id}
                                                            ci_data={state.ci_response}
                                                            all_available_deployments={all_available_deployments_without_widget && all_available_deployments_without_widget.length > 0 ? all_available_deployments_without_widget : []}
                                                            service_env_name={service_env_name}
                                                            build_action_card={state.action_card}
                                                            handleCloseDialog={closeBox}

                                                        />
                                                        : null
                                                }
                                                {

                                                    state.deploy_action_card ?
                                                        <>
                                                            <DeployActionCardNew
                                                                service_data={service_data}
                                                                configuration_data={state.ci_cd_data}
                                                                closebox={closeBox}
                                                                cd_data={state.cd_response ? [state.cd_response] : null}
                                                                button_state={{ build_button: true, deploy_button: true }}
                                                                application_id={service_data.project_id}
                                                                type={state.action_card}
                                                                service_id={service_id}
                                                                env_id={env_id}
                                                                ci_data={state.ci_response}
                                                                env_name={selectedServiceEnv.project_env.name}
                                                                is_env_down={selectedServiceEnv?.project_env?.is_env_down}
                                                                deploy_env_offline_flag={state.deploy_env_offline_flag && state.deploy_env_offline_flag[0] ? state.deploy_env_offline_flag[0] : null}
                                                                masterLabel={masterLabel}
                                                                env_details={selectedServiceEnv}
                                                                service_env_name={service_env_name}
                                                                handleCloseDialog={closeBox}
                                                                system_settings={state.available_settings}
                                                                deploy_action_card={state.deploy_action_card}
                                                                all_available_deployments={all_available_deployments_without_widget && all_available_deployments_without_widget.length > 0 ? all_available_deployments_without_widget : []}
                                                            />
                                                        </>
                                                        : null
                                                }
                                                {
                                                    state.promote_action_card ?
                                                        <PromoteActionCard
                                                            service_data={service_data}
                                                            configuration_data={state.ci_cd_data}
                                                            closebox={closeBox}
                                                            cd_data={state.cd_response ? [state.cd_response] : null}
                                                            button_state={{ build_button: true, deploy_button: true }}
                                                            application_id={service_data.project_id}
                                                            type={state.action_card}
                                                            service_id={service_id}
                                                            env_id={env_id}
                                                            is_env_down={selectedServiceEnv?.project_env?.is_env_down}
                                                            deploy_env_offline_flag={state.deploy_env_offline_flag && state.deploy_env_offline_flag[0] ? state.deploy_env_offline_flag[0] : null}
                                                            ci_data={state.ci_response}
                                                            handleCloseDialog={closeBox}
                                                            all_available_deployments={all_available_deployments_without_widget && all_available_deployments_without_widget.length > 0 ?
                                                                all_available_deployments_without_widget : []}
                                                            promote_action_card={state.promote_action_card}
                                                        /> : null
                                                }
                                            </div>

                                        </span>

                                        <Snackbar
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={state.trigger}
                                            onClose={handleClose}
                                            autoHideDuration={3000}>
                                            <Alert severity="success">
                                                {"SUCCESSFULLY SCHEDULED"}
                                            </Alert>
                                        </Snackbar>

                                    </> : null
                            }

                        </div>

                    </>

                    : state.error ?
                        <p className="alert alert-primary">Error...</p> :
                        <>
                            <div class="card-loading" style={{ width: '100%', height: '206px' }}> <Loading varient="light" /> </div>
                        </>


            }
        </>

    );

}


CICDSection.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export default CICDSection;
const useStyles = makeStyles((theme) => ({
    rootCard: {
        // marginLeft: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        // width : "215px",

        "& p": {
            color: "#000",
            fontSize: "13px",
        },

        '& .chip': {
            padding: '3px!important',
            display: 'inline-block'
        },
        '& .chip-yellow': {
            borderRadius: '3px',
            backgroundColor: '#ffc000',
            color: '#fff',
        },
        '& .chip-dark-grey': {
            borderRadius: '3px',
            backgroundColor: '#656565',
            color: '#fff',
        },
        '& .chip-green': {
            borderRadius: '3px',
            backgroundColor: '#69df9b',
            color: '#fff',
        },
    },
}))


const ConfigureDiv = makeStyles((theme) => ({
    container: {

        display: 'flex',
        width: "100%",
        overflow: 'auto',
        gap: '10px',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
            width: '0',
        },
        '&::-webkit-scrollbar-thumb': {
            display: 'none',
        },
        '&::-webkit-scrollbar-track': {
            display: 'none', /* For hiding scrollbar track */
        },
    },
    root: {
        width: '520px',
        height: '196px',
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        borderRadius: "6px",
        border: "1px solid #E6E6E6",
        flex: '0 0 auto',
        '& .heading': {
            display: "flex",
            width: "100%",
            height: "48px",
            padding: "12px 16px",
            alignItems: "center",
            gap: "4px",
        },
    },
    tiggerCase: {
        display: "flex",
        padding: "8px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "10px",
        alignSelf: "stretch",
        height: "100%",
        paddingTop: "0px",
        '& .real-card': {
            display: "flex",
            padding: "0px 16px",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
            alignSelf: "stretch",
            borderRadius: "6px",
            border: "1px solid #F4F4F4",
            height: "100%",
            //background: "#FAFAFA",
            paddingTop: "10px",
            justifyContent: "center",
            '& .conf-btn': {
                display: "flex",
                height: "32px",
                padding: "11px 16px 11px 12px",
                justifyContent: "center",
                alignItems: "center",
                gap: "6px",
                borderRadius: "6px",
                border: "1px solid #F4F4F4",
                background: "#0086FF",
                textTransform: "uppercase",
            }
        }
    }
}))
