import React from 'react';
import '../assets/ErrorStyleSheet.scss';
import { makeStyles } from '@material-ui/core/styles';

import { Link } from 'react-router-dom';
const Exception = props => {

  const classes = useStyles();
  const authData = JSON.parse(localStorage.getItem('authData'));
  
  const is_super_user = authData.userDetails && authData.userDetails.is_superuser ? authData.userDetails.is_superuser : false
 
  return (

    <div className={classes.root}>
      <div className="svg">
        <div className="contant_box_404" style={{ paddingBottom: '30px' }}>
          <img src="./images/something_wrong.jpg" alt="" />
          <h3> Sorry! Something went wrong with BuildPiper </h3>
          <p>Sorry for the inconvenience but we're looking into the issue <br /> at the moment. we'll be back online shortly!</p>
          <Link className="btn btn-submit" to={is_super_user ? "/clusterdashboard":"/ApplicationDashboard"}>Go to Dashboard</Link>
          {/* <Button className="btn btn-submit" onClick={()=>{}}> Go to Dashboard </Button> */}
        
        </div>
      </div>


    </div>
  )
}

export default Exception;

const useStyles = makeStyles({
  root: {
    backgroundColor: 'transparent',
    height: '90vh',
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '& .svg': {
      width: '737px',
      borderRadius: '10px',
      backgroundColor: '#ffffff',
      top: '50%',
      left: '50%',
      position: 'absolute',
      boxShadow: '0px -2px 8px rgba(0,0,0,0.03)',
      transform: 'translate(-50%, -50%)',
      padding: '45px 0',
      border: 'none',
      '& h3': {
        fontSize: '25px',
        fontWeight: '300',
        textAlign: 'center',
        color: '#000',
        paddingBottom: '15px',
      },
      '& p': {
        fontSize: '14px',
        textAlign: 'center',
        color: '#878787',
        lineHeight: '21px',
        paddingBottom:'30px',
      }
    }


  }
});