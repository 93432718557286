import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { VALIDATION_TYPE_REQUIRED } from '../../../../../util/Validator';
import { getCommonFunctions } from '../../../../serviceRevamp/add/ci_flow/SourceDetails';
import { Input } from '../../../../../components/genericComponents/Input';
import properties from '../../../../../properties/properties';
import InvokeApi, { PostData } from '../../../../../util/apiInvoker';
import GenerateURL, { GenerateSearchURL } from '../../../../../util/APIUrlProvider';
import { Loading } from '../../../../utils/Loading';
// import { formValueSelector } from 'redux-form';

const StepEnvVariableForm = ({ extraProps, ...props }) => {

    console.log('p_ev_dssds', extraProps)
    const prev_state = props.prev_state ? props.prev_state : null;
    const [gitRepoFunctionality, setGitRepoFunctionality] = useState()
    const [state, setState] = useState({
        loading: false,
        data: {},
        error: {},
        validations: {},
        added_step_variables: [],
        branches: [],
        isBrachesLoading: false,
        getDefaultState:null
    })
    const [integrationTypeVarValues, setIntegrationTypeVarValues] = useState({})
    const inherits = props.inherits ? props.inherits : {};
    console.log(inherits, 'inherits_data_000233');
    const commonFunctions = getCommonFunctions(state, setState, inherits)
    const supportedEnvVariables = [
        { id: 1001, key: "BUILD_IMAGE_NAME", data_type: "build_detail", value_with_depth: "build_detail.repository.name" },
        { id: 1002, key: "BUILD_GIT_URL", data_type: "build_detail", value_with_depth: "git_repo.git_url" },
        { id: 1003, key: "BUILD_COMPONENT_NAME", data_type: "build_detail", value_with_depth: "component.name" },
        { id: 1004, key: "BUILD_COMPONENT_LANGUAGE", data_type: "build_detail", value_with_depth: "component.language" }
    ]

    // useEffect(()=>{
    //     inherits.validationForm=()=>{
    //         return {valid:true}
    //     }
    // },[state])

    // useEffect(() => {
    //     setGitRepoFunctionality({
    //         isBrachesLoading: false,
    //         credential_id: null,
    //         git_url: null,
    //         branches: [],
    //         first_time: true
    //     })
    // }, [extraProps])

    // useEffect(() => {
    //     console.log(gitRepoFunctionality, 'in_effect')
    //     if (extraProps.dataForEdit && gitRepoFunctionality && gitRepoFunctionality.first_time && (gitRepoFunctionality.credential_id || gitRepoFunctionality.git_url)) {

    //         console.log('called_for_edit')
    //         loadGitBranches()
    //     }
    // }, [gitRepoFunctionality])

    useEffect(() => {

        console.log('use Effect runned===>', state.data)

        if (state.data && state.data.integration_1) {

            // setState(prevState => ({
            //     ...prevState,
            //     validations: { ...prevState.validations, GIT_BRANCH: [VALIDATION_TYPE_REQUIRED] }
            // }))

            // getGitUrl(state.data.integration_1)
        }

        else {
            let validations = state.validations
            // delete state.validations.GIT_BRANCH
            setState(prevState => ({
                ...prevState,
                validations: { ...validations }
            }))
        }

    }, [state.data.integration_1, extraProps])

    useEffect(() => {

        extraProps && setState(getDefaultState(extraProps, prev_state))

    }, [extraProps, prev_state])

    useEffect(() => {

        setTimeout(() => setState(prevState => ({
            ...prevState,
            loading: false
        })), 500)
        if (state.integration_variables) {

            state.integration_variables.forEach(item => {
                getIntegrationTypeValues(item.integration)
            })
        }
    }, [state.integration_variables])


    useEffect(()=>{
        console.log(state.repos_for_getting_branches,'data_changing_3232323');

        if(state.repos_for_getting_branches?.length>0){
            state.repos_for_getting_branches.forEach(repoData=>{
                getGitUrl(repoData.repo_name,repoData.key)
            })
        }
    },[state.repos_for_getting_branches])

    

    function getIntegrationTypeValues(integrationType) {

        var requestInfo = {
            endPoint: GenerateURL({ integration_type: integrationType }, properties.api.getIntegrationTypeValues),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, handleResponseForIntegrationValues, handleResponseForIntegrationVarsError);
    }

    function handleResponseForIntegrationValues(response) {

        setIntegrationTypeVarValues(prevState => ({
            ...prevState,
            ...response
        }))
    }

    function handleResponseForIntegrationVarsError(error) {
        setState(new_state => ({
            ...new_state,
            error: "Error fetching Integration Types",
        }));
    }

    function getGitUrl(name, integrationKey) {
        if (name && name != '') {

            let apiEndpoint = GenerateURL({}, properties.api.fetch_all_repo)

            var requestInfo = {
                endPoint: GenerateSearchURL({ name: name }, apiEndpoint),
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }

            InvokeApi(requestInfo, (response) => {
                console.log(response, 'resp_3232sdsd_weewe');
                if (true) {
                    let gitData = response.results[0]
                    console.log(gitData, 'gitData_0023');
                    if (gitData) {
                        const credential_id = gitData.credential_id
                        const git_url = gitData.git_url
                        let post_data = { git_url: git_url }
                        if (credential_id != undefined) {
                            post_data = { ...post_data, credential_id: credential_id }
                        }
                        setState(prevState => ({
                            ...prevState,
                            isBrachesLoading: true
                        }))
                        PostData(GenerateURL({}, properties.api.git_test), post_data,
                            (response) => {
                                let { branches } = response
                                if (branches) {
                                    if (branches?.length > 0) {
                                        branches = branches.map(branch => {
                                            return { id: branch, label: branch }
                                        })
                                    }
                                    setState(prevState => ({
                                        ...prevState,
                                        git_branch_list_of_repos: { ...prevState.git_branch_list_of_repos, [integrationKey]: { branches: branches } },
                                        isBrachesLoading: false
                                    }))
                                }
                            } 
                            , handleResponseLoadGitBranchesError);
                    }
                }
            }, handleResponseForGitUrlError);
        }
    }


    // const updateGit = ()=> {
    //     console.log('updated_git',gitRepoFunctionality)
    // }

    function handleResponseForGitUrlError(error) {
        setState(new_state => ({
            ...new_state,
            error: "Error fetching Git url and credentials",
        }));
    }

    function loadGitBranches(repoName, integrationKey, buttonClicked) {
        getGitUrl(repoName, integrationKey)
        // let post_data = { git_url: gitRepoFunctionality.git_url }
        // if (gitRepoFunctionality.credential_id != undefined) {
        //     post_data = { ...post_data, credential_id: gitRepoFunctionality.credential_id }
        // }
        // setState(prevState => ({
        //     ...prevState,
        //     isBrachesLoading: true
        // }))
        // PostData(GenerateURL({}, properties.api.git_test), post_data,
        //     buttonClicked ? handleResponseLoadGitBranchesOnClicked :
        //         handleResponseLoadGitBranches, handleResponseLoadGitBranchesError);
    }

    // function handleResponseLoadGitBranchesOnClicked(response) {
    //     console.log('getting_branches', response)
    //     if (response) {
    //         const { branches } = response
    //         setState(prevState => ({
    //             ...prevState,
    //             branches: branches,
    //             isBrachesLoading: false
    //         }))
    //         setGitRepoFunctionality(prevState => ({
    //             ...prevState,
    //             first_time: false
    //         }))
    //     }
    // }

    function handleResponseLoadGitBranches(response) {

        console.log('getting_branches', response)

        if (response) {
            const { branches } = response

            setState(prevState => ({
                ...prevState,
                branches: branches,
                isBrachesLoading: false
            }))

            setGitRepoFunctionality(prevState => ({
                ...prevState,
                first_time: false
            }))
        }
    }

    function handleResponseLoadGitBranchesError(error) {

        setState(new_state => ({
            ...new_state,
            error: "Error fetching Git Branches",
            isBrachesLoading: false

        }));
    }

    const handlerForRepoOnly = (e) => {
        let key = e.target.name
        let value = e.target.value

        setState(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                [key]: value
            }
        }))
    }
    console.log(extraProps, 'exp_1')

    console.log(state, 'step_env_var_sddsdssd')

    console.log(prev_state, 'p_state')

    console.log(gitRepoFunctionality, 'git_r_fun')

    const handleForGitBranch = (e) => {
        const field = e.target.name
        const value = e.target.value

        setState(prevState => ({
            ...prevState,
            git_branches_data: {
                ...prevState.git_branches_data,
                [field]: value
            },
            git_branches_error: {
                ...prevState.git_branches_error,
                [field]: ''
            }
        }))
    }

    return (
        <div className='ci-v3-input-div' style={{
            padding: '40px 35px 40px 35px',
            // height: 'calc(100% - 157px)',
            minHeight: '300px',
            borderTop: '1px solid #dddddd',
            backgroundColor: '#f8f8f8',
        }}>
            {
                state && state.loading ?
                    <div style={{ display: 'flex' }}>
                        <Loading varient="light" />
                    </div>
                    :
                    <Grid container spacing={2}>
                        <>
                            {
                                state && (state.added_step_variables && state.added_step_variables.length === 0)
                                    && (state.integration_variables && state.integration_variables.length === 0) ?
                                    <div className='d-flex align-center justify-center' style={{ height: '218px', width: '100%' }}>
                                        <p1 style={{ fontSize: '14px' }}> No Env Vars</p1>
                                    </div> :
                                    <>

                                        {
                                            state && state.added_step_variables && state.added_step_variables.map((variable, index) => {
                                                return (
                                                    <Grid key={index} item xs={12}>
                                                        {
                                                            variable.input_type === 'text' ?
                                                                <Input
                                                                    type='dual-input'
                                                                    mandatorySign={variable.is_required}
                                                                    name={variable.key}
                                                                    list={supportedEnvVariables ? supportedEnvVariables.map(item => {

                                                                        return { id: item.key, label: item.key }
                                                                    }) : []}
                                                                    label={variable.key}
                                                                    placeholder=""
                                                                    onChangeHandler={commonFunctions.onChangeHandler}
                                                                    data={state.data}
                                                                    error={state.error}
                                                                />
                                                                :
                                                                <Input
                                                                    type={variable.input_type === 'toggle' ? 'select' : variable.input_type}
                                                                    mandatorySign={variable.is_required}
                                                                    name={variable.key}
                                                                    list={variable.input_type === 'select' || 'toggle' ? variable.select_dropdown_options ?

                                                                        variable.select_dropdown_options.split(',').map(item => ({

                                                                            id: item.trim(), label: item.trim()
                                                                        }))

                                                                        : [{ id: 'yes', label: 'Yes' }, { id: 'no', label: 'No' }]

                                                                        : []
                                                                    }
                                                                    label={variable.key}
                                                                    placeholder=""
                                                                    onChangeHandler={commonFunctions.onChangeHandler}
                                                                    data={state.data}
                                                                    error={state.error}
                                                                />
                                                        }
                                                    </Grid>
                                                )
                                            })
                                        }
                                        {
                                            state && state.integration_variables && state.integration_variables.map((intVariables, index) => {
                                                return (
                                                    <>
                                                        {
                                                            intVariables.integration === 'GIT_REPO' ?
                                                                <>
                                                                    <Grid key={index} item xs={12}>
                                                                        <Input
                                                                            type='select'
                                                                            name={intVariables.integration_key}
                                                                            mandatorySign={intVariables.is_required}
                                                                            list={integrationTypeVarValues[intVariables.integration] ?

                                                                                integrationTypeVarValues[intVariables.integration].map(item => {
                                                                                    return { label: item.name, id: item.name }
                                                                                }) :

                                                                                []
                                                                            }
                                                                            label={`Choose ${intVariables.integration} for ${intVariables.integration_key} (Integration)`}
                                                                            placeholder=""
                                                                            onChangeHandler={handlerForRepoOnly}
                                                                            data={state.data}
                                                                            error={state.error} />
                                                                    </Grid>
                                                                   
                                                                        {/* <Grid container spacing={2} alignItems="center"> */}
                                                                            <Grid item xs={extraProps.variant ? 3 : 2} style={extraProps.variant ?  {alignSelf: 'flex-end'}:{}}>
                                                                                {
                                                                                    state.isBrachesLoading ?
                                                                                        <div style={{ marginTop: '10px' }}>
                                                                                            <Loading varient="light" />
                                                                                        </div>
                                                                                        :
                                                                                        <button className={extraProps.variant ? "btn btn-outline-primary mr-0 mb-15": "btn btn-submit mr-0"} style={{ width: '100%' }} onClick={() => loadGitBranches(state?.data[intVariables.integration_key], intVariables.integration_key, true)}>Load Branches </button>
                                                                                }
                                                                            </Grid>

                                                                            <Grid key={index} item xs={extraProps.variant ? 9 : 10}>
                                                                                <Input
                                                                                    type='select'
                                                                                    name={intVariables.integration_key}
                                                                                    mandatorySign
                                                                                    list={state.git_branch_list_of_repos[intVariables.integration_key] ? state.git_branch_list_of_repos[intVariables.integration_key]['branches'] : []}
                                                                                    label={`Choose GIT_BRANCH for ${intVariables.integration_key} (Integration)`}
                                                                                    placeholder=""
                                                                                    onChangeHandler={handleForGitBranch}
                                                                                    data={state.git_branches_data}
                                                                                    error={state.git_branches_error} />
                                                                            </Grid>
                                                                        
                                                                    
                                                                </>
                                                                :
                                                                <Grid key={index} item xs={12}>
                                                                    <Input
                                                                        type='select'
                                                                        name={intVariables.integration_key}
                                                                        mandatorySign={intVariables.is_required}
                                                                        list={integrationTypeVarValues[intVariables.integration] ?

                                                                            integrationTypeVarValues[intVariables.integration].map(item => {
                                                                                return { label: item.name, id: item.name }
                                                                            }) :

                                                                            []
                                                                        }
                                                                        label={`Choose ${intVariables.integration} for ${intVariables.integration_key} (Integration)`}
                                                                        placeholder=""
                                                                        onChangeHandler={commonFunctions.onChangeHandler}
                                                                        data={state.data}
                                                                        error={state.error} />
                                                                </Grid>
                                                        }
                                                    </>

                                                )
                                            })
                                        }
                                    </>
                            }

                        </>
                    </Grid>
            }
        </div>
    )
}

StepEnvVariableForm.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default StepEnvVariableForm;



function getDefaultState(arg, prev_state) {

    console.log('ergs_ev', arg)

    if (prev_state) {
        return prev_state
    }

    else {

        let { envVariables, apiFetchedStepEnvVariables, dataForEdit } = arg
        console.log(dataForEdit, 'data_for_edit')
        console.log(envVariables, 'ev_ssdsdsdsddsq2')
        console.log(apiFetchedStepEnvVariables, 'api_fetched_data')
        let data = {}
        let validation = {}
        let uniqueObjectOfVariables = {}
        let integrationVariables = []
        let onlyIntegrationVariables = {}
        let modifyVarKeys = {}
        let inputTypeRelatedDataOfEnvVars = {}   // like input type, default active or not, 
        const branches_data = {}
        const repos_for_getting_branches = []
        apiFetchedStepEnvVariables && apiFetchedStepEnvVariables.forEach(item => {          // for fetched Steps
            console.log(item, 'api_feteched_item')
            if (item.key) {
                modifyVarKeys[item.key] = item.is_trigger_time_modify ? true : false
                inputTypeRelatedDataOfEnvVars[item.key] = item
                if (item.is_required) {                 //adding validations if any env var is required
                    validation[item.key] = [VALIDATION_TYPE_REQUIRED]
                }
                data[item.key] = item.default_value ? item.default_value : ''
                uniqueObjectOfVariables[item.key] = item
            }
            else {
                modifyVarKeys[item.integration] = item.is_trigger_time_modify ? true : false

                if (item.is_required) {
                    validation[item.integration_key] = [VALIDATION_TYPE_REQUIRED]
                }
                data[item.integration_key] = ''
                onlyIntegrationVariables[item.integration_key] = item
                integrationVariables.push(item)
            }
        })
        envVariables = envVariables && envVariables.forEach((item) => {

            console.log(item, 'local_item')

            let { key, value, integration, integration_key,integration_values } = item

            if (key != undefined && value != undefined) {
                data[key] = value
            }

            let existVar = uniqueObjectOfVariables[key]

            if (!existVar && key != undefined) {
                let item = { does_default_active: 'N', input_type: 'text', key: key }
                uniqueObjectOfVariables[item.key] = item
            }

                if (integration) {
                   
                    // onlyIntegrationVariables[item.integration_key] = item
                    integration_values?.forEach(integrationKeyObject => {
                        const integrationKey = Object.keys(integrationKeyObject)[0]
                        const integrationDataArray = integrationKeyObject[integrationKey]
                       
                        integrationDataArray?.forEach(integration => {
                            if (data[integrationKey] == undefined) {
                                integrationVariables.push(integration)
                            }
                            const key = integration['key']
                            const value = integration['value']
                            if (key === 'GIT_BRANCH') {
                                branches_data[integrationKey] = value
                            }
                            else {
                                if (key === 'GIT_REPO') {
                                    // repos_for_getting_branches[integrationKey] = true
                                }
                                data[integrationKey] = value
                            }
                        })

                    })

                }

            })

        if (dataForEdit) {
            let editedEnvVariables = dataForEdit.environment_variables

            editedEnvVariables && editedEnvVariables.forEach(({ key, value, integration, integration_values }) => {
                if (key) {
                    data[key] = value
                }
                if (integration) {
                    integration_values?.forEach(integrationKeyObject => {
                        const integrationKey = Object.keys(integrationKeyObject)[0]
                        const integrationDataArray = integrationKeyObject[integrationKey]
                        integrationDataArray?.forEach(integration => {
                            const key = integration['key']
                            const value = integration['value']
                            if (key === 'GIT_BRANCH') {
                                branches_data[integrationKey] = value
                            }
                            else {
                                if (key === 'GIT_REPO') {
                                    repos_for_getting_branches.push ({
                                        key:integrationKey,
                                        repo_name:data[integrationKey]=value
                                    })
                                }
                                data[integrationKey] = value
                            }
                        })

                    })
                }
            })
        }

        const finalEnvVarArray = uniqueObjectOfVariables && Object.keys(uniqueObjectOfVariables).map(key => {

            return uniqueObjectOfVariables[key]
        })

        console.log(finalEnvVarArray, 'ev_01')

        return {
            integration_variables: integrationVariables,
            loading: true,
            onlyIntegrationVariables: onlyIntegrationVariables,   // for having a copy of integration varibales so we can differentiate b/w env vars and integrations on final post
            data: data,
            git_branches_data: branches_data,
            git_branches_error: {},
            git_branch_list_of_repos: {},
            repos_for_getting_branches: repos_for_getting_branches, // will update in edit mode and try to fetch branch list for them
            error: {},
            validations: validation,
            added_step_variables: finalEnvVarArray ? finalEnvVarArray : [],
            branches: [],
            modify_variables_keys: modifyVarKeys,
            input_related_data_of_env_vars: inputTypeRelatedDataOfEnvVars
        }
    }
}


// Json => 

// git_branch_list_of_repos = {
//     key: {
//         branches:[],
//         loading:true
//     }
// }