import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import AddIcon from '@material-ui/icons/Add';

import CloseIcon from '@material-ui/icons/Close';
import { IconButton, Tooltip } from '@material-ui/core';
import properties from '../../../../properties/properties';
import { GetAuth } from '../../../../util/security';
import GenerateURL from '../../../../util/APIUrlProvider';
import useFetchPermissions from '../../../../components/customHooks/useFetchPermissions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

export default function ReposIntegrationDialog(props) {
    const open_dialog = props.open_dialog ? props.open_dialog : false
    const [open, setOpen] = useState(open_dialog);
    const setOuterDialogClose = props.setOuterDialogClose?props.setOuterDialogClose : ()=>{}
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOuterDialogClose()
    };

    var url = GenerateURL({}, properties.api.fetch_all_repo, true);
    const is_permited = useFetchPermissions()?.POST.includes(url);

    return (
        <div>
            {
                is_permited ?
                    open_dialog ? 
                    <></>
                    :
                    <button 
                    onClick={handleClickOpen} 
                    className='btn btn-primary  mb-15 ml-auto mr-0 d-flex align-center justify-center text-center'
                    
                    > <AddIcon /> Add Repo</button>
                    
                    :
                    <Tooltip title="You are not allowed to perform this action">
                        <button 
                        className='btn btn-primary mb-15 ml-auto  d-flex align-center mr-0 justify-center text-center btn btn-disable cursor-not-allowed'
                        > <AddIcon /> Add Repo</button>
                    </Tooltip>
            }
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                className="integrations-dialog"
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <div className='integration-header-strip d-flex space-between align-center'>
                    <p className=''>
                        Code Repos Integrations
                    </p>
                    <div className='close'>
                        <IconButton onClick={handleClose}>
                            <CloseIcon style={{ color: "#fff" }} />
                        </IconButton>
                    </div>
                </div>
                <p className='integration-sub-header-strip'>
                    Select from the supported code repositories
                </p>
                <div className="integration-boxes">
                    <a href="/repo/bitbucket/add">
                        <div className='integration-single-box'>
                            <img src="/images/integrations/bitbucket.png" alt=".." />
                        </div>
                        <p className='font-12 text-center ' style={{ color: '#121212' }}>Bitbucket</p>
                    </a>
                    {/* <Link to="/repo/git/add">
                        <div className='integration-single-box'>
                        <img src="/images/integrations/git.png" alt=".." />
                        </div>
                        <p>Git</p>
                    </Link> */}
                    <a href="/repo/github/add">
                        <div className='integration-single-box'>
                            <img src="/images/integrations/github.png" alt=".." />
                        </div>
                        <p className='font-12 text-center ' style={{ color: '#121212' }}>GitHub</p>
                    </a>
                    <a href="/repo/gitlab/add">
                        <div className='integration-single-box'>
                            <img src="/images/integrations/gitlab.png" alt=".." />
                        </div>
                        <p className='font-12 text-center ' style={{ color: '#121212' }}>GitLab</p>
                    </a>
                </div>
            </Dialog>
        </div>
    );
}