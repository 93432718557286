import properties from '../properties/properties';
export const EnvImage = (env_id) =>{
    var envs = properties.envs;
    var img_src = "images/EnvType_icons/dev.png";
    envs.forEach(env => {
        if(env.id == env_id){
            img_src = env.src;
        }
    });
    return img_src;
}

export const ToolImage = (tool_name) =>{
    var tools = properties.tools;
    return tools[tool_name] ?  tools[tool_name].img : "/images/error_icon.png";
}