import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useParams } from 'react-router-dom';
import SquareAvatar from '../../../components/genericComponents/AvatarSquare';
import Delete from '../../../components/genericComponents/Delete';

import { Grid } from '@material-ui/core';

const CanaryTemplateCard = props => {
    const classes = useStyles();
    const data = props.data;
    const {application_id} = useParams();

    return(
        
        
        <div className={classes.root+" "+"card"}>
           <div className="card-body ">
           <div className="card-header bg-white border-bottom">
            <div className="left-content">
                <div className="left-content">
                    <SquareAvatar shape="sm-box" varient="double" name={data.name} />
                    <span style={{color:'#3696db', 'fontSize': 'larger', 'marginLeft': '10px'}}>
                        {data.name}
                    </span>
                </div>
            </div>
            </div>
            <ul className="mt-5">
                <Grid container spacing="2" className="border-bottom">
                    <Grid item lg={6}>
                        <b className='font-12 pl-10 pd-5'>No. of Canary Phases</b>
                    </Grid>
                    <Grid item lg={6}>
                        <span className='font-12 pl-10 pd-5'>{data.phase_release_template.length}</span>
                    </Grid>
                </Grid>
                {/* <Grid container spacing="2" className="border-bottom">
                    <Grid item lg={6}>
                        <b className='font-12 pl-10 pd-5'>Phases:</b>
                    </Grid>
                    <Grid item lg={6}>
                    <span className='font-12 pl-10 pd-5'>{data.phase_release_template.map(item=>(item.name))}</span>
                    </Grid>
                </Grid> */}
                <Grid container spacing="2" className="border-bottom">
                    <Grid item lg={6}>
                        <b className='font-12 pl-10 pd-5'>Manual Analysis:</b>
                    </Grid>
                    <Grid item lg={6}>
                        <span className='font-12 pl-10 pd-5'>True</span>
                    </Grid>
                </Grid>
            </ul>
        </div>
        <div className="card-footer bg-white space-between pd-10">
                <div className="d-flex align-center">
                    {data.user ? 
                    <span className="chip-default " style={{backgroundColor:"#788695"}}>{data.user.name}</span>: <span className="chip-default chip-chip-blue" >bp-official</span>    
                }
                </div>
                <div className="d-flex align-center" style={{gap: "8px"}}>
                <Delete
                  varient = "new_button"
                  display_data_name={data.name}
                  data={{ entity_id: data.id, name: "job" }}
                  refresh={()=>{}}
                  api_link={{}}/>
                  <Link to={`/application/${application_id}/canary-template/${data.id}/edit`} className="text-anchor-blue d-flex">
                    {/* <BorderColorIcon className="ml-10 vertical-bottom" /> */}
                    <button className='btn-sq-icon-primary mr-5'>
                        <span className='ri-edit-line vertical-align-super'></span>
                    </button>
                  </Link>
                </div>
           </div>
        </div>
        
    )
}


CanaryTemplateCard.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default CanaryTemplateCard;

const useStyles = makeStyles({
    root:{
        '& .grid-temp':{
            gridTemplateColumns: '60px 1fr'
        },
        '& .chip-sq':{
            fontSize:'12px',
            padding:'3px',
            border:'2px solid transparent',
            maxWidth: 90,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display:'inline-block',
            marginRight: 2,
            marginLeft:2, 
            '&:first-child':{
                marginLeft: 0
            },
            '&:last-child':{
                marginRight: 0
            },
            '&:hover':{
                cursor: 'pointer'
            }
        },
        '& .chip-sq-primary-outline':{
            borderColor: '#0086ff',
            color:'#0086ff',
            backgroundColor:'transparent'
        },
        '& .chip-default':{
            backgroundColor: '#48a3e3',
            color:'#fff!important',
            borderRadius:'4px!important'
        },
    }
});