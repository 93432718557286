import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Slide, IconButton, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import CloseIcon from '@material-ui/icons/Close';
import JobConfigureInfo from './JobConfigureInfo';
import BuildJobStepWizard from './BuildJobStepWizard'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})
const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiPaper-root': {
            width: 'auto',
        },
        '& .variant-v2': {
            '& .md-stepper-wrapper': {
                '& .md-stepper-horizontal': {
                    backgroundColor: '#fff',
                     width:'90%!important'
                }
            },
            '& .ci-v3-input-div':{
                backgroundColor:'#fff!important',
                borderTop: 'none!important',
                padding:'24px 16px !important',
                width:'954px',
                maxWidth: '954px'
            },
            '& .card-footer':{
                backgroundColor:'#fff',
                border:'none!important',
                justifyContent:'end!important',
                '& .btn-submit':{
                    marginRight:'0px!important'
                }
            }
        }

    }
}));

const JobConfigureDisplay = (props) => {

    let job_name = props?.selectedJobData?.job_name
    const classes = useStyles();
    const sendDatatoParentState = props.sendDatatoParentState ? props.sendDatatoParentState : () => { };
    const variant = props.variant ? props.variant : null;
    const handleCloseCurrentDialog = props.handleCloseCurrentDialog ? props.handleCloseCurrentDialog : () => { };
    const history = useHistory();
    const onClickCancel = () => {
        history.push('/load-balancer/list')
    }
    return (

        <Dialog
            fullScreen={variant ? false : true}
            maxWidth='lg'
            open={props.isOpen}
            onClose={() => { }}
            aria-labelledby="max-width-dialog-title"
            TransitionComponent={Transition}
            className={variant ? classes.root : ''}>
            <div className='d-grid build-input-div' style={variant ? { gridTemplateColumns: '252px 1fr' } : { gridTemplateColumns: '400px 1fr', height: '100%' }}>
                <JobConfigureInfo jobName={job_name} variant={variant} />
                <div style={variant ? { padding: '' } : { padding: '60px 32px 60px 32px' }}>
                    <div style={variant ? { display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '16px', background: '#fafafa' } : { display: 'flex' }} className="align-center space-between">
                        <div className='align-center' style={{ display: 'flex' }}>
                            <label className={variant ? 'font-16 font-weight-500 color-primary' : 'font-18 font-weight-500'}>{'Fill Up the Code details for '} </label>
                            <label style={variant ? { color: '#0086ff', marginLeft: '8px', marginRight: '8px' } : { color: '#3696db', marginLeft: '8px', marginRight: '8px' }} className={variant ? 'font-16 font-weight-500' : 'font-18 font-weight-700'}>{` ${job_name} `}</label>
                            <label className={variant ? 'font-16 font-weight-500 color-primary' : 'font-18 font-weight-500'}> {' Job Steps'}</label>
                        </div>
                        <IconButton className='mr-1' onClick={variant ? handleCloseCurrentDialog : () => props.setComponentView('jobListing')}>
                            <CloseIcon style={variant ? { fontSize: '20px', color: "#2f2f2f" } : { fontSize: '20px', color: "#607086" }} />
                        </IconButton>
                    </div>
                    <BuildJobStepWizard  
                    cancelButtonUrl={onClickCancel}
                    {...props}
                    sendDatatoParentState={sendDatatoParentState} 
                    variant={variant} />
                </div>
            </div>
        </Dialog>

    )
}

JobConfigureDisplay.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export default JobConfigureDisplay