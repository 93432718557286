import React from "react";
import PropTypes from 'prop-types';
import InfoTab from "../../../../../../components/genericComponents/InfoTabs";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import ViewEvent from "./ViewEvents";
import LogSection from './LogSection';
import ContainerSection from "./ContainerSection";
import { ErrorComponent } from "../../../../../utils/Error";

const info_list = {
    events:{
        label:"Events",
        icon:<NotificationsNoneIcon/>
    },
    logs:{
        label:"Logs",
        icon:<ViewWeekIcon/>
    },
    containers:{
        label:"Containers",
        icon:<ViewWeekIcon/>
    }
}

const TabSection = props => {
    const selected_pod_name = props.selected_pod_name;
    const selected_tab = props.selected_tab;
    const changeTab = props.changeTab;
    const refreshTab = props.refreshTab;
    const events = props.events;
    const logs = props.logs;
    const selected_pod = props.selected_pod;
    
    function getSelectedTabView(){
        switch(selected_tab){
            case "events":
                if(props.events_error){
                    return <ErrorComponent error={props.events_error}/>
                }
                return(<ViewEvent selected_pod_name={selected_pod_name} events={events} refreshTab={refreshTab} />);
            case "logs":
                if(props.logs_error){
                    return <ErrorComponent error={props.logs_error}/>
                }
                return(<LogSection selected_pod_name={selected_pod_name} logs={logs} refreshTab={refreshTab}  />);
            case "containers":
                return(<ContainerSection selected_pod_name={selected_pod_name} selected_pod={selected_pod} refreshTab={refreshTab}  />);
            default :
                return(<h1>Select a tab to load</h1>);
        }
    }

    return (
        <>
            <InfoTab info_list={info_list} selected_tab={selected_tab} onTabChange={changeTab} selectedPodName={props.selectedPodName} />
            
            <div>
                {getSelectedTabView()}
            </div>
        </>
    );
}

TabSection.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }


export default TabSection;

