import properties from '../properties/properties';
var api = properties.api;

const base_url = api.baseURL;

export const GET_Request_Info = (url) => {
    return {
        endPoint: url,
        httpMethod: "GET",
        httpHeaders: { "Content-Type": "application/json" }
    }
}

const GenerateURL = (palceholders, url, only_append) => {
    
    const keys = palceholders?Object.keys(palceholders):[];
    var generated_url = url;
    console.log('keys',palceholders,keys,generated_url);
    keys.forEach(key => {
        generated_url = generated_url.replace("${" + key + "}", palceholders[key]);
    });
    return only_append ? "/" + generated_url : base_url + generated_url;
}

export function GenerateSearchURL(data, url) {
    url += "?";
    const keys = Object.keys(data);
    keys.forEach((key, index) => {
        if (index != 0) {
            url += "&" + key + "=" + data[key];
        } else {
            url += key + "=" + data[key];
        }

    });
    return url;
}

export function GenerateURLForPins(data,url,addOn) {
    if(addOn){
        url += "&pins="
    }else{
        url += "?pins=";
    }
   
    data.forEach((key,index) => {
        if(index != data.length-1){
            url += key + ","
        }else{
            url += key
        }
    });
    return url;
}

export default GenerateURL;