import React, { useState, useEffect } from 'react';
import { styled } from '@material-ui/core/styles';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { FormatTime } from '../../../../util/util';
import { Link, useParams } from 'react-router-dom';
import LinkIcon from '@material-ui/icons/Link';

const InvokePipelineJobExecutionCard = props => {
    const rest_api_task_instace = props.rest_api_task_instace;
    const { pipeline_id, pipeline_instance_id, stage_instance_id, task_instance_id } = props;
    const selected_component_task_id = props.selected_component_task_id;
    const update_selected_component_task = props.update_selected_component_task;
    const open = selected_component_task_id == rest_api_task_instace.id;
    const showLogsCallback = props.showLogs;
    const stage = props.stage;
    const {application_id} = useParams();
    const execution_id = ""
    const start_time = new Date(rest_api_task_instace.created_at);
    const end_time = new Date(rest_api_task_instace.updated_at);
    const diff = end_time - start_time;
    const time_taken = FormatTime(diff);


    const toggleSectionOpen = () => {
        update_selected_component_task(!open ? rest_api_task_instace.id : null);
    }

    function getProgressStatusIcon() {
        switch (rest_api_task_instace.status) {
            case "SUCCESS":
                return (
                    <div className="circle-status circle-status-approved"><div class="flaticon-check-symbol"></div></div>
                );
            case "FAILED":
                return (
                    <div className="circle-status circle-status-failed"><div className="flaticon-round-info-button"></div></div>
                );
            case "RUNNING":
                return (
                    <div className="circle-status circle-status-pending"><div class="flaticon-clock-with-white-face"></div></div>
                );
            default:
                return (
                    <div className="circle-status circle-status-pending"><div class="flaticon-clock-with-white-face"></div></div>
                );
        }
    }

    function showLogs() {
        
        const path_arr = [stage? stage.name: "", props.task.name? props.task.name :"", rest_api_task_instace.task_type ? rest_api_task_instace.task_type.name :"",];
        showLogsCallback(rest_api_task_instace.id, path_arr,rest_api_task_instace.global_task_id );
    }

    return (
        <ServiceExecution>
            <div className="vertical-border-service-card" ></div>
            <div className="vertical-border-one" style={open ? { height: '11%' } : { height: '50%' }}></div>
            <span className="border-stage-card">
                {getProgressStatusIcon()}
            </span>
            <Header> 
                {/* <div className="flaticon-check-symbol"></div> */}
                <div className="service"> {rest_api_task_instace.pipeline && rest_api_task_instace.pipeline.pipeline_name ?
               rest_api_task_instace.pipeline.pipeline_name : ""
             }</div>
                {/* <a href="#viewFullLogs" title="view logs" onClick={showLogs} className="flaticon-google-drive-file"></a> */}
                <Link 
                to={"/logs?global_task_id=" + rest_api_task_instace.global_task_id +
                "&status=" + rest_api_task_instace.status +
                "&tab_id=" + rest_api_task_instace.id 
            } 
                title="view logs" 
                target="_blank"
                // onClick={showLogs} 
                className="flaticon-google-drive-file"></Link>
                <div className={rest_api_task_instace.status == "SUCCESS" ? "status-div green-text" : rest_api_task_instace.status == "FAILED" ? "status-div red-text" : "status-div yellow-text"}>&nbsp;{rest_api_task_instace.status}&nbsp;</div>
                <div className="duration"> {time_taken} </div>
                <div className='ml-auto'>
                    {rest_api_task_instace.pipeline_instance && rest_api_task_instace.pipeline_instance.id ?
                    rest_api_task_instace.pipeline&&rest_api_task_instace.pipeline.pipeline_id ?
                    <Link 
                    to={"/application/"+application_id+"/pipeline/"+rest_api_task_instace.pipeline.pipeline_id+"/execution/"+rest_api_task_instace.pipeline_instance.id} 
                    target="_blank" >
                    <LinkIcon className="" style={{verticalAlign: 'middle', color: '#fff', fontSize: 18, transform: 'rotate(-45deg)'}} />
                    </Link> : null :null
                }
                    
                
                </div> 
                {/* {
                    open ?
                        <div className="flaticon-expand-arrow" onClick={toggleSectionOpen}></div> :
                        <div className="flaticon-expand-button" onClick={toggleSectionOpen}></div>
                } */}
            </Header>

            {
                open ?
                <Body>
                    {
                        rest_api_task_instace.rest_api_task_details&&
                        <>
                        <div className="steps-list">
                    <div className="service">URL:</div>
                    <div className="status-div green-text text-ellipsis-200">&nbsp; {rest_api_task_instace.rest_api_task_details.url}</div>
                </div>
                {/* <div className="steps-list">
                    <div className="service">Headers:</div>
                    <div className="status-div green-text text-ellipsis-200">&nbsp; {rest_api_task_instace.rest_api_task_details.headers_key_value_pairs}</div>
                </div> */}
                <div className="steps-list">
                    <div className="service">Request Timeout:</div>
                    <div className="status-div green-text text-ellipsis-200">&nbsp; {rest_api_task_instace.rest_api_task_details.request_timeout}</div>
                </div>
                        </>
                    }
            </Body> : null
            }
        </ServiceExecution>
    )
}

export default InvokePipelineJobExecutionCard;



const ServiceExecution = styled('div')({
    width: '324px',
    backgroundColor: '#4652a2',
    borderRadius: '4px',
    display: 'block',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#4652a2',
    marginLeft: '5rem',
    marginRight: '4rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    position: 'relative',
    '&:last-child > .vertical-border-service-card': {
        display: 'none'
    },
    '&:only-child .main-vertical-div-pipeline > .vertical-border-pipeline': {

    },
    '&:last-child > .vertical-border-service-card:last-child': {
        display: 'none'
    },
    '& .vertical-border-service-card': {
        top: '24px',
        left: '-30px',
        width: '3px',
        height: '104%',
        position: 'absolute',
        backgroundColor: '#666'
    },
    '&:only-child > .vertical-border-one': {
        display: 'block!important',
        height: '40%',
    },
    '& .vertical-border-one': {
        top: '-11px',
        left: '-30px',
        width: '3px',
        height: '50%',
        position: 'absolute',
        backgroundColor: '#666',
    },
    '& .border-stage-card': {
        height: '3px',
        backgroundColor: '#666',
        width: '30px',
        position: 'absolute',
        left: '-28px',
        top: '18px'
    },
    '& .circle-status': {
        height: '32px',
        width: '32px',
        border: '3px solid #666',
        position: 'relative',
        left: '-20px',
        bottom: '19px',
        borderRadius: '50%',
        backgroundColor: '#fff',
        zIndex: 999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    '& .circle-status-approved': {
        border: '3px solid #61e187'
    },
    '& .circle-status-approved .flaticon-check-symbol:before': {
        fontSize: '12px!important',
        color: '#61e187'
    },
    '& .circle-status-pending': {
        border: '3px solid #ffbf00'
    },
    '&  .circle-status-pending .flaticon-clock-with-white-face:before': {
        fontSize: '12px!important',
        color: '#ffbf00'
    },
    '& .circle-status-failed': {
        border: '3px solid #ff8969'
    },
    '&  .circle-status-failed .flaticon-round-info-button:before': {
        fontSize: '16px!important',
        color: '#ff8969'
    },
})
const Header = styled('div')({
    display: 'flex',
    alignItems: 'center',
    padding: '5px 15px',
    "& .flaticon-google-drive-file": {
        color: '#fff',
        cursor: 'pointer',
        margin: '0px 8px'
    },
    '& .flaticon-schedule-button': {
        color: '#929ac9'
    },
    '& .running': {
        color: "#ffc514!important"
    },
    '& .flaticon-schedule-button:before': {
        fontSize: '16px!important'
    },
    '& .service': {
        color: '#b5bbd9',
        lineHeight: '18px',
        fontSize: '12px',
        color: '#fff',
        margin: '0px 5px',
        width: '140px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    '& .status-div': {
        fontSize: '12px',
    },
    '& .status-div.green': {
        backgroundColor: 'inherit',
        color: '#79e599'
    },
    '& .duration': {
        color: '#fff',
        fontSize: '12px',
        margin: '0px 5px',
        whiteSpace: 'nowrap'
    },
    '& .flaticon-expand-arrow': {
        justifySelf: 'flex-end',
        color: '#fff!important',
        cursor: 'pointer',
        marginLeft: 'auto',
        transition: 'all .3s ease',
    },
    '& .flaticon-expand-arrow:before, .flaticon-expand-button:before': {
        fontSize: '12px!important'
    },
    '& .flaticon-check-symbol': {
        color: '#79e599'
    },
    '& .flaticon-expand-button': {
        justifySelf: 'flex-end',
        color: '#fff!important',
        cursor: 'pointer',
        marginLeft: 'auto',
        color: '#fff',
        transition: 'all .3s ease',
    }
})

const Body = styled('div')({
    backgroundColor: '#5e6798',
    fontSize: '12px',
    color: '#fff',
    width: '100%',
    '& .steps-list': {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '10px 15px',
        borderBottom: '1px solid #6b74a8'
    },
    '& .flaticon-schedule-button': {
        color: '#929ac9'
    },
    '& .running': {
        color: "#ffc514!important"
    },
    '& .flaticon-schedule-button:before': {
        fontSize: '16px!important'
    },
    '& .service': {
        color: '#b5bbd9',
        lineHeight: '18px',
        fontSize: '12px',
        color: '#fff',
        margin: '0px 5px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    '& .status-div': {
        fontSize: '12px'
    },
    '& .status-div.green': {
        backgroundColor: 'inherit',
        color: '#79e599'
    },
    '& .duration': {
        color: '#fff',
        fontSize: '12px',
        margin: '0px 5px',
        whiteSpace: 'nowrap'


    },
    '& .flaticon-expand-arrow': {
        justifySelf: 'flex-end',
        color: '#fff!important',
        cursor: 'pointer',
        marginLeft: 'auto'
    },
    '& .flaticon-expand-arrow:before': {
        fontSize: '12px!important'
    },
    '& .flaticon-check-symbol': {
        color: '#79e599'
    }
})
const Footer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px',
    fontSize: '12px',
    color: '#fff',
    textAlign: 'center',
    justifyContent: 'center',
    '& .artifact-name': {
        color: '#b5bbd9',
        display: 'none'
    }
})
