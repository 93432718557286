import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import {CircularMeterList} from '../../../../../../components/genericComponents/CircularMeter';




const PodMatrix = props =>{
    const pod_matrix = getPodMatrix(props.pod_matrix);
    const list = [
        {
            label:'CPU',
            percentage:20,
            color:'#69e09c'
        },
        {
            label:'Memory',
            percentage:40,
            color:'#ffd62a'
        }
    ]
    function getPodMatrix(data) {

        const array = data ?data:[] ;
        const keys = Object.keys(array);
        
        let pod_matrix_data = [];

        for (let i = 0; i < keys.length; i++) {
            var element = array[keys[i]];
            const matrix_list = {
                label:keys[i],
                percentage: element.percentage,
                color: getColor(element.status)
                

            }
            pod_matrix_data.push(matrix_list);

        }

        return pod_matrix_data;
    }
    function getColor(percentage) {
        if (percentage == "normal") {
            return "#69e09c"
        }
        if (percentage  == "warning") {
            return "#fedf5c"
        }
        if (percentage  == "danger") {
            return "#ff8969"
        }
        return "#008000"
    }
    return(
        <MainDiv>
        <SectionTitle>Pod Matrix</SectionTitle>
        <div className="progress-container height-12">
            <CircularMeterList list={pod_matrix}/>
        </div>
        </MainDiv>
    )
}

PodMatrix.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default PodMatrix;




export const MainDiv = styled('div')({
    display:'block',
    margin:'0px 10px',
    '& .height-12':{
        height:'10rem'
    },
    '& .progress-container':{
        padding:'0px'
    }
})

export const SectionTitle = styled('p')({
    fontSize:'12px',
    color:'#878787',
    lineHeight:'14px'
})
