import { GetAuth, GetPermitedURLs, GetPermitedURLsPromisified, IsSuperUser } from "./security";
import { RegenerateRefreshAndAccessToken, IsAuthenticated, IsRefreshTokenValid } from "../util/security"
import { RemoveFromArray } from "./util";
import { UpdateClusterDataForNavigation, UpdateUserDataForNavigation } from "./GlobalVars";
import { createBrowserHistory } from 'history';


function InvokeApi(requestInfo, callBackFunction, errorCallBackFunction, doNotValidateToken, parseText) {
  console.log(requestInfo, "requestInfo___")
  if (doNotValidateToken) {
    invokeGetRequest(requestInfo, callBackFunction, errorCallBackFunction, parseText);
  } else {
    validateTokenAndInvokeGetAPI(requestInfo, callBackFunction, errorCallBackFunction, parseText);
  }
}

function validateTokenAndInvokeGetAPI(requestInfo, callBackFunction, errorCallBackFunction, parseText) {
  const callBackFunctionLocal = () => {
    invokeGetRequest(requestInfo, callBackFunction, errorCallBackFunction, parseText)
  }

  if (!IsAuthenticated()) {
    if (IsRefreshTokenValid()) {
      RegenerateRefreshAndAccessToken(callBackFunctionLocal, errorCallBackFunction);
    } else {
      errorCallBackFunction("Session expired. Login again");
    }
  } else {
    invokeGetRequest(requestInfo, callBackFunction, errorCallBackFunction, parseText)
  }
}

function validateTokenAndInvokePostAPI(url, data, callBackFunction, errorCallBackFunction) {
  const callBackFunctionLocal = () => {
    invokePostRequest(url, data, callBackFunction, errorCallBackFunction);
  }

  if (!IsAuthenticated()) {
    if (IsRefreshTokenValid()) {
      RegenerateRefreshAndAccessToken(callBackFunctionLocal, errorCallBackFunction);
    } else {
      errorCallBackFunction("Session expired. Login again");
    }
  } else {
    invokePostRequest(url, data, callBackFunction, errorCallBackFunction);
  }
}
function validateTokenAndInvokeDeleteAPI(url, data, callBackFunction, errorCallBackFunction) {
  const callBackFunctionLocal = () => {
    invokeDeleteRequest(url, data, callBackFunction, errorCallBackFunction);
  }

  if (!IsAuthenticated()) {
    if (IsRefreshTokenValid()) {
      RegenerateRefreshAndAccessToken(callBackFunctionLocal, errorCallBackFunction);
    } else {
      errorCallBackFunction("Session expired. Login again");
    }
  } else {
    invokeDeleteRequest(url, data, callBackFunction, errorCallBackFunction)
  }
}

export const PostData = (url, data, callBackFunction, errorCallBackFunction, doNotValidateToken) => {
  if (doNotValidateToken) {
    invokePostRequest(url, data, callBackFunction, errorCallBackFunction, true);
  } else {
    validateTokenAndInvokePostAPI(url, data, callBackFunction, errorCallBackFunction);
  }
}
export const DeleteData = (url, data, callBackFunction, errorCallBackFunction, doNotValidateToken) => {
  if (doNotValidateToken) {
    invokeDeleteRequest(url, data, callBackFunction, errorCallBackFunction, true);
  } else {
    validateTokenAndInvokeDeleteAPI(url, data, callBackFunction, errorCallBackFunction);
  }
}

function invokeGetRequest(requestInfo, callBackFunction, errorCallBackFunction, parseText) {
  var status = null;
  var bearerToken = "Bearer " + GetAuth().access;

  var fetchOptions = {
    method: requestInfo.httpMethod,
    headers: { ...requestInfo.httpHeaders, "Authorization": bearerToken },
  };

  console.log(requestInfo, 'requestInfo_212332232')
  fetch(requestInfo.endPoint, fetchOptions)
    .then(response => {
      // console.log(response.text(),'sbvjshbvhjsbvhdbhfd');
      console.log(parseText, 'pt_001')
      const contentType = response.headers.get("content-type")
      console.log(contentType, 'content_type_030243');
      if (contentType === 'application/zip' || contentType === 'application/zip; charset=UTF-8') {
        return response.blob();
      }
      status = response.status;

      var response_json = parseText ? response.text() : response.json();
      return response_json;
    })
    .then((data) => {
      console.log(data, status, ApiIsUnSubsribed(requestInfo.endPoint), "sbvjshbvhjsbvhdbhfd")
      if (!ApiIsUnSubsribed(requestInfo.endPoint)) {
        console.log(data, "sbvjshbvhjsbvhdbhfd")
        if (data.type === 'application/zip') {
          callBackFunction(data);
        }
        else if (status === 200) {
          callBackFunction(data);
        }
        else if (status == 403) {
          const error_status = resolveErrorStatus(status, data);
          errorCallBackFunction({ error: error_status, status: status });
        }
        else {
          var error_status = resolveErrorStatus(status, data);
          errorCallBackFunction(error_status);
        }
      }
    })
    .catch((exception) => {
      handleException();
    });
}

function resolveErrorStatus(status, data) {
  switch (status) {
    case 401:
      return data.detail;
    case 500:
      return data.detail;
    case 501:
      return data.error;
    case 404:
      return data.detail;
    case 405:
      return data.detail;
    case 403:
      return data.detail;
    case 400:
      if (data.non_field_errors) {
        return data.non_field_errors[0]
      }
      return data;
    default:
      return data;
  }
}

function invokePostRequest(url, data, callBackFunction, errorCallBackFunction, doNotRefreshPermission) {
  var status = null;
  var auth = GetAuth();
  var fetchOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", "Authorization": auth ? "Bearer " + GetAuth().access : null },
    body: JSON.stringify(data)
  };
  fetch(url, fetchOptions)
    .then(response => {
      status = response.status;
      var response_json = response.json();
      return response_json;
    })
    .then((data) => {
      if (!ApiIsUnSubsribed(url)) {
        if ((status == 200) || (status == 201) || (status == 204)) {
          if (!doNotRefreshPermission) {
            GetPermitedURLsPromisified()
              .then(() => callBackFunction(data))  // only call back when permissions successfully fetched 
              .catch((error) => { errorCallBackFunction(error) })
            if (IsSuperUser()) {
              UpdateClusterDataForNavigation();
            } else {
              UpdateUserDataForNavigation();
            }
          }
          else {
            callBackFunction(data);
          }
        }
        else if (status == 403) {
          const error_status = resolveErrorStatus(status, data);
          errorCallBackFunction({ error: error_status, status: status });
        }
        else {
          var error_status = resolveErrorStatus(status, data);
          handleErrorResponse();
          errorCallBackFunction(error_status);
        }
      }
    })
    .catch((exception) => {
      handleException();
      // errorCallBackFunction(exception,true);
    });
}

function invokeDeleteRequest(url, data, callBackFunction, errorCallBackFunction, doNotRefreshPermission) {
  var status = null;
  var auth = GetAuth();

  var fetchOptions = {
    method: "delete",
    headers: { "Content-Type": "application/json", "Authorization": auth ? "Bearer " + GetAuth().access : null },
    body: JSON.stringify(data)
  };


  fetch(url, fetchOptions)
    .then(response => {
      status = response.status;
      var response_json = response.json();
      return response_json;
    })
    .then((data) => {
      if (!ApiIsUnSubsribed(url)) {
        if (status == 200) {
          if (!doNotRefreshPermission) {
            GetPermitedURLs();
            if (IsSuperUser()) {
              UpdateClusterDataForNavigation();
            } else {
              UpdateUserDataForNavigation();
            }
          }
          callBackFunction(data);
        }
        else if (status == 403) {
          const error_status = resolveErrorStatus(status, data);
          errorCallBackFunction({ error: error_status, status: status });
        }
        else {
          var error_status = resolveErrorStatus(status, data);
          handleErrorResponse();
          errorCallBackFunction(error_status);
        }
      }
    }).catch((exception) => {
      handleException();
      // errorCallBackFunction(exception,true);
    });

}

export function UnsubscribeToApi(url) {
  var unsubscribed_api_ist = (window.localStorage.getItem("unsubscribed_api_ist"));
  unsubscribed_api_ist = unsubscribed_api_ist ? unsubscribed_api_ist.split(",") : [];

  if (!unsubscribed_api_ist.includes(url)) {
    unsubscribed_api_ist.push(url);
  }
  window.localStorage.setItem("unsubscribed_api_ist", unsubscribed_api_ist.toString());
}

export function SubscribeToApi(url) {
  var unsubscribed_api_ist = (window.localStorage.getItem("unsubscribed_api_ist"));
  unsubscribed_api_ist = unsubscribed_api_ist ? unsubscribed_api_ist.split(",") : [];

  if (unsubscribed_api_ist) {
    RemoveFromArray(unsubscribed_api_ist, url);
  }
  window.localStorage.setItem("unsubscribed_api_ist", unsubscribed_api_ist.toString());
}

export function ApiIsUnSubsribed(url) {
  var unsubscribed_api_ist = window.localStorage.getItem("unsubscribed_api_ist");
  unsubscribed_api_ist = unsubscribed_api_ist ? unsubscribed_api_ist.split(",") : [];

  return unsubscribed_api_ist.includes(url);
}

var handleErrorResponse = () => { return null };
var handleException = () => { return null };
var handlePermissionNotFoundResponse = () => {
  const history = createBrowserHistory();
  history.go("/permission-not-found")

}
export function SetHandleError(handle_error_callback) {
  handleErrorResponse = handle_error_callback;
}

export default InvokeApi;