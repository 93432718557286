import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import PremiumFeaturesStoped from "./FeaturesValidation/PremiumFeatureComponents/PremiumFeaturesStoped";
import { IsSuperUser } from "../../util/security";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const PremiumInfo = ({ featureName, remainingDays, premiumFeatureInfo, onClose, data, additionalDrawer }) => {
    const classes = useStyles();
    console.log(premiumFeatureInfo, 'dead_01')
    const history = useHistory();

    const handleCloseForForm = () => {
        const isSuperUser = IsSuperUser()
        console.log('is_spp_r', isSuperUser)
        const url = IsSuperUser() ? "/clusterdashboard" : "/applicationdashboard"
        history.push(url)
    }

    function formatDate() {
        const inputDate = data?.end_date
        // Define an array to map month numbers to month names
        const monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        // Split the input date into year, month, and day
        const dateParts = inputDate?.split('-');
        const year = dateParts && dateParts[0];
        const month = dateParts && dateParts[1];
        const day = dateParts && dateParts[2];
        // Convert the month number to the corresponding month name
        const monthName = monthNames[parseInt(month, 10) - 1];
        // Create the formatted date string
        const formattedDate = `${day}-${monthName}-${year}`;
        return formattedDate;
    }

    const formattedDate = formatDate()
    const isFormOpen = premiumFeatureInfo=='not-accessible'|| remainingDays < 0

    return (
        <>
            {
                isFormOpen ?
                    <PremiumFeaturesStoped open={isFormOpen} onClose={handleCloseForForm} featureName={featureName} />
                    :
                    <>
                        {
                            (remainingDays < 31 && premiumFeatureInfo == 'complimentry' || premiumFeatureInfo == 'purchased') &&
                            <>
                                {

                                    <div className={classes.root}>
                                        <div className="info d-flex align-center">

                                            <InfoIcon style={{
                                                fontSize: "24px",
                                                color: "#fff",
                                                marginRight: "10px",
                                            }} />
                                            <span style={{ color: '#FFFFFF' }} className="font-12 font-weight-500 mr-5">
                                                {`${featureName} is a premium feature and your ${premiumFeatureInfo == 'complimentry' ? 'free' : 'premium'} trial is valid till`}
                                                <span className="mr-5 ml-5 font-weight-600">
                                                    {`${formattedDate}`}
                                                </span>
                                            </span>
                                        </div>

                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className={additionalDrawer ? 'additionalMarginRight' : 'marginRight'}>
                                            <>
                                                {
                                                    remainingDays < 0 ?
                                                        <button
                                                            className="info-button"
                                                        >
                                                            EXPIRED
                                                        </button> : <button
                                                            className="info-button"
                                                        >
                                                            {`${remainingDays} `}DAYS LEFT
                                                        </button>

                                                }
                                            </>
                                            <IconButton onClick={onClose}>
                                                <CloseIcon style={{ color: "#fff", fontSize: "24px" }} />
                                            </IconButton>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                    </>
            }
        </>
    )
}

PremiumInfo.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export default PremiumInfo;

const useStyles = makeStyles((theme) => ({
    root: {

        padding: '10px 20px 10px 20px',
        background: '#0086FF',
        width: '100%',
        height: "50px",
        padding: "0px 20px",
        display: 'flex',
        alignItems: "center",
        justifyContent: 'space-between',
        color: '#FFFFFF',
        '& .info': {


        },
        '& .info-button': {
            fontSize: '12px',
            width: '106px',
            height: '23px',
            borderRadius: '6px',
            fontWeight: '600',
            lineHeight: '14.63px',
            textAlign: 'left',
            color: '#fff',
            background: '#e9edef4f',
            border: 'none',
            marginRight: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        '& .marginRight': {
            marginRight: "82px",
        },
        '& .additionalMarginRight': {
            marginRight: "252px",
            '@media screen and (max-width: 1440px)': {
                marginRight: "252px"
            },
            '@media screen and (min-width: 1440px) and (max-width: 1920px)': {
                marginRight: "288px"
            },
        }
    }
}));