import React from 'react';
import PropTypes from 'prop-types';
const NumberComponent = ({ number }) => {


    return <div style={
        {
            background: '#0086ff',
            borderRadius: '50px',
            color: '#fff',
            width:'43px',
            height: '26px'
        }
    } className="d-flex align-center justify-center" >
        <label className="font-14 font-weight-500">{`${number} +`}</label>
    </div >

}

NumberComponent.propTypes ={
    number: PropTypes.any,
}


export default NumberComponent