
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import properties from '../../../../../properties/properties';
import InvokeApi from '../../../../../util/apiInvoker';
import GenerateURL from '../../../../../util/APIUrlProvider';


import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { styled } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';

import EditIcon from '@material-ui/icons/Edit'
import Delete from '../../../../../components/genericComponents/Delete';

const IsitoAddonsData = props => {
    const cluster_id = props.cluster_id;
    const istio_id = props.istio_id;
    const [state, setState] = useState(
      {
        addon_data: []
      }
    )
    useEffect(() => {
          loadIstioAddonsData()
    
  }, [cluster_id, istio_id])

    function loadIstioAddonsData() {
      var requestInfo = {
          endPoint: GenerateURL({ cluster_id: cluster_id, istio_id: istio_id, }, properties.api.istio_addons),
          httpMethod: "GET",
          httpHeaders: { "Content-Type": "application/json" }
      }
      InvokeApi(requestInfo, handleResponse, handleError);
      setState(new_state => ({
          ...new_state,
          show_loading_icon: true,
        
      }));
  }

  function handleResponse(response) {
      setState(new_state => ({
          ...new_state,
          show_loading_icon: false,
          addon_data: response.results
      }));
  }
  function handleError(error) {
      setState(new_state => ({
          ...new_state,
          error: "Error fetching Istio for the given Istio ID " +istio_id +": "+error,
          show_loading_icon: false,
          data_load_failure: true
      }));
  }
    return(
      <>
      {
        state.addon_data &&  state.addon_data[0]? 
        <>
        <div className="sub-box-one-summary " style={{ width: '100%', borderRight:'0px'}}>
            <div className="sub-header-setup-summary">
              <p>Istio Add-ons</p>
              <div>
                {/* <Link to={"/cluster/" + cluster_id + "/istio/" + (state.addon_data[0].istio_id) + "/addons/"+state.addon_data[0].id+ "/edit"}><IconButton><EditIcon style={{ fontSize: '18px', color: '#0086ff' }} /></IconButton></Link> */}
                {/* <Delete
                  display_data_name="istio_addon"
                  data={{ entity_id: state.addon_data[0].id, name: "istio_addon" }}
                  refresh={loadIstioAddonsData}
                  api_link={GenerateURL({ cluster_id: cluster_id, istio_id:state.addon_data[0].istio_id, addons_id:state.addon_data[0].id    }, properties.api.istio_addons_edit)}
                /> */}
              </div>
            </div>
            <TempBodyRowTempTwo className="border-right">
            <div className="icon-box">
              <div className="public-icon-box icon-box-top" style={{ height: '100%', backgroundColor: '#ebf2f4' }}>
                <img src="/images/logos/Kiali_Logo.png" alt=".." />
              </div>
            </div>
            <div className="d-flex align-center">Kiali URL: &nbsp;<a href={state.addon_data[0].kiali_url} target="_blank" className="text-blue-anchor">{state.addon_data[0].kiali_url}</a></div>
          </TempBodyRowTempTwo>
          </div>
          <div className="sub-box-one-summary " style={{ width: '100%' }}>
          <div className="sub-header-setup-summary">
            <p></p>
            <div>
                <Link to={"/cluster/" + cluster_id + "/istio/" + (state.addon_data[0].istio_id) + "/addons/"+state.addon_data[0].id+ "/edit"}><IconButton><EditIcon style={{ fontSize: '18px', color: '#0086ff' }} /></IconButton></Link>
                <Delete
                  display_data_name="istio_addon"
                  data={{ entity_id: state.addon_data[0].id, name: "istio_addon" }}
                  refresh={loadIstioAddonsData}
                  api_link={GenerateURL({ cluster_id: cluster_id, istio_id:state.addon_data[0].istio_id, addons_id:state.addon_data[0].id    }, properties.api.istio_addons_edit)}
                />
              </div>
          </div>
          <TempBodyRowTempTwo>
            <div className="icon-box">
              <div className="public-icon-box icon-box-top" style={{ height: '100%', backgroundColor: '#ebf2f4' }}>
                <img src="/images/logos/Jaeger_Logo.png" alt=".." />
              </div>
            </div>
            <div className="d-flex align-center">Jaeger URL: &nbsp;<a href={state.addon_data[0].jaeger_url} target="_blank" className="text-blue-anchor">{state.addon_data[0].jaeger_url ? state.addon_data[0].jaeger_url :'N/A'}</a></div>
          </TempBodyRowTempTwo>
        </div>
          </>
        : 
        <>
        <div className="sub-box-one-summary border-right" style={{ width: '100%' }}>
          <div className="sub-header-setup-summary">
            <p>Kiali</p>
          </div>
          <TempBodyRowTempTwo>
            <div className="icon-box">
              <div className="public-icon-box icon-box-top" style={{ height: '100%', backgroundColor: '#ebf2f4' }}>
                <img src="/images/logos/Kiali_Logo.png" alt=".." />
              </div>
            </div>
            <div>
              <Link to={"/cluster/" + cluster_id + "/istio/" + istio_id + "/addons/add"} >
                <div className="text-blue-anchor" style={{ display: 'flex', alignItems: 'center' }}  ><AddIcon style={{ color: '#0086ff', fontSize: '16px' }} /> Configure Kiali</div>
              </Link>
            </div>
          </TempBodyRowTempTwo>
        </div>
        <div className="sub-box-one-summary " style={{ width: '100%' }}>
          <div className="sub-header-setup-summary">
            <p>Jaeger</p>
          </div>
          <TempBodyRowTempTwo>
            <div className="icon-box">
              <div className="public-icon-box icon-box-top" style={{ height: '100%', backgroundColor: '#ebf2f4' }}>
                <img src="/images/logos/Jaeger_Logo.png" alt=".." /> </div>
            </div>
            <div>
              <Link to={"/cluster/" + cluster_id + "/istio/" + istio_id + "/addons/add"} >
                <div className="text-blue-anchor" style={{ display: 'flex', alignItems: 'center' }}  ><AddIcon style={{ color: '#0086ff', fontSize: '16px' }} /> Configure Jaeger</div>
              </Link>
            </div>
          </TempBodyRowTempTwo>
        </div>
      </>
      }
      </>
     
    )
}
IsitoAddonsData.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export default IsitoAddonsData;


const TempBodyRowTempTwo = styled('div')({
    lineHeight: '1.5',
    fontSize: '12px',
    gap: '10px',
    padding: '30px 10px',
    alignItems: 'center',
    overflow: 'hidden',
    backgroundColor: '#fcfcfc',
    display: 'grid',
    gridTemplateColumns: '30% 1fr',
    height:' 152px',
    justifyContent: 'space-between',
    '& .public-icon-box': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    '& .text-gray': {
      color: '#9e9e9e'
    }
  })