import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../../../../../components/genericComponents/Input';
import properties from '../../../../../../properties/properties';
import { GetInfraProviderId } from '../../../../../../util/util';
import { ValidateDataSet, VALIDATION_TYPE_REQUIRED } from '../../../../../../util/Validator';
import { makeStyles } from '@material-ui/core/styles';
import GenerateURL, { GET_Request_Info } from '../../../../../../util/APIUrlProvider';
import InvokeApi from '../../../../../../util/apiInvoker';
import { Loading } from '../../../../../utils/Loading';
import { ErrorComponent } from '../../../../../utils/Error';
import Credential from '../../../../../../components/genericComponents/inputs/Credential';

function InfraSection(props) {
    const classes = useStyles();
    const cloud_provider = [
        {
            id: 1,
            label: 'AWS',
            value: 1,
            src: '/images/logos/aws.png'
        }, {
            id: 4,
            label: 'LDC',
            value: 4,
            src: '/images/logos/ldc_icon.png'
        },];
    const inherits = props.inherits;

    const detail_inherits = {};

    const data = props.data;
    const [state, setState] = useState(props.prev_state ?
        props.prev_state : {
            data: data ? data : {
                infra_provider_id: "AWS",
            },
            error: {

            }
        });
    console.log("state2============>", state, props);

    function onChangeHandler(e) {
        const k = e.target.name;
        const v = e.target.value;
        updateData(k, v);
    }

    function updateData(k, v) {
        setState(new_state => ({
            ...new_state,
            data: {
                ...new_state.data,
                [k]: v,
            },
            error: {
                ...new_state.error,
                [k]: "",
            }
        }));
    }

    // Defining Inherits
    inherits.validateForm = () => {
        const validations = {
            infra_provider_id: [VALIDATION_TYPE_REQUIRED]
        }

        const result = ValidateDataSet(state.data, validations);
        const detail_form_result = detail_inherits.validateForm();
        if (!result.valid) {
            setState(new_state => ({
                ...new_state,
                error: {
                    ...result.error
                }
            }))
        }
        return {
            valid: (result && detail_form_result.valid),
        };
    }


    inherits.getData = () => {
        const data = { ...state.data };
        data.infra_provider_id = GetInfraProviderId(data.infra_provider_id);
        return data;
    }

    inherits.getState = () => {
        return {
            ...state,
            detail_section: {
                ...detail_inherits.getState()
            },
        };
    }
    return (
        <div className={classes.formDiv}>
            {/* <div className="form-controll">
                <Input
                    type="imgCheckbox"
                    mandatorySign
                    name="infra_provider_id"
                    label="Choose Infra Provider"
                    list={cloud_provider}
                    
                    onChangeHandler={onChangeHandler}
                    data={state.data}
                    error={state.error} />
            </div> */}
            {
                state.data.infra_provider_id == "AWS" ?
                    <AWSSection prev_state={state.detail_section ? state.detail_section : null} inherits={detail_inherits} />
                    : null
            }
        </div>
    );
}
InfraSection.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

function AWSSection(props) {
    const inherits = props.inherits;
    const credential_inherits = {};
    const [state, setState] = useState(
        props.prev_state ?
            props.prev_state : {
                data: {
                    region: "",
                    zone: [],
                    ami_id: "",
                    ssh_key_id: ""
                },
                error: {

                }
            }
    );

    useEffect(() => {
        if (!props.prev_state) {
            fetchRegions();
        }
    }, []);


    function fetchRegions() {
        const request_info = GET_Request_Info(GenerateURL({}, properties.api.get_aws_regions));
        InvokeApi(request_info, onRegionFetchSuccess, onRegionFetchFail);
        setState(new_state => ({
            ...new_state,
            fetching_region: true,
        }));
    }

    function onRegionFetchSuccess(data) {
        setState(new_state => ({
            ...new_state,
            fetching_region: false,
            regions: data.Regions,
        }));
    }

    function onRegionFetchFail(error) {
        setState(new_state => ({
            ...new_state,
            fetching_region: false,
            region_fetch_error: error
        }));
    }

    function fetchZones(region) {
        const request_info = GET_Request_Info(GenerateURL({ region: region }, properties.api.get_aws_zones));
        InvokeApi(request_info, onZoneFetchSuccess, onZoneFetchFail);
    }

    function onZoneFetchSuccess(data) {
        setState(new_state => ({
            ...new_state,
            fetching_zones: false,
            zones: data.AvailabilityZones,
        }));
    }

    function onZoneFetchFail(error) {
        setState(new_state => ({
            ...new_state,
            fetching_zones: false,
            zone_fetch_error: error,
        }));
    }

    function onRegionChange(e) {
        const k = e.target.name;
        const v = e.target.value;

        fetchZones(v);
        setState(new_state => ({
            ...new_state,
            fetching_zones: true,
            data: {
                ...new_state.data,
                [k]: v,
            },
            error: {
                ...new_state.error,
                [k]: null
            }
        }));
    }

    function onChangeHandler(e) {
        var k = e.target.name;
        const v = e.target.value;
        if (k == "credential_id") {
            k = "ssh_key_id";
        }
        updateData(k, v);
    }

    function updateData(k, v) {
        setState(new_state => ({
            ...new_state,
            data: {
                ...new_state.data,
                [k]: v,
            },
            error: {
                ...new_state.error,
                [k]: "",
            }
        }));
    }


    // Overriding

    inherits.validateForm = () => {
        const validations = {
            region: [VALIDATION_TYPE_REQUIRED],
            zone: [VALIDATION_TYPE_REQUIRED],
            ami_id: [VALIDATION_TYPE_REQUIRED],
            ssh_key_id: [VALIDATION_TYPE_REQUIRED]
        }
        const result = ValidateDataSet(state.data, validations);
        if (!result.valid) {
            setState(new_state => ({
                ...new_state,
                error: {
                    ...result.error
                }
            }))
        }
        return result;
    }

    inherits.getState = () => {
        const result = {
            ...state,
            credential_state: {
                ...credential_inherits.getState()
            }
        }
        return result;
    }
    console.log("yamank---", state)
    return (
        <>
            {
                state.fetching_region ?
                    <Loading varient="light" />
                    :
                    <>
                        {
                            state.regions ?
                                <div className="form-controll">
                                    <Input
                                        type="select"
                                        name="region"
                                        label="Select Region"
                                        mandatorySign
                                        list={getRegionsList(state.regions)}
                                        onChangeHandler={onRegionChange}
                                        data={state.data}
                                        error={state.error}
                                    />
                                </div> :
                                <ErrorComponent error={state.region_fetch_error} />
                        }
                    </>
            }
            {
                state.fetching_zones ?
                    <Loading varient="light" />
                    :
                    <>
                        {
                            state.zones ?
                                <div className="form-controll">
                                    <Input
                                        type="checkbox"
                                        name="zone"
                                        label="Zone"
                                        mandatorySign
                                        list={getZoneList(state.zones)}
                                        data={state.data}
                                        error={state.error}
                                        onChangeHandler={updateData}
                                    />
                                </div>
                                :
                                null
                        }
                    </>
            }
            <div className="form-controll">
                <Input
                    type="text"
                    name="ami_id"
                    label="AMI ID"
                    subHeading="(Normally it's in the root)"
                    placeholder="Please Provide the docker file path"
                    mandatorySign
                    onChangeHandler={onChangeHandler}
                    data={state.data}
                    error={state.error}
                />
            </div>
            <div className="form-controll">
                <Credential
                    mandatorySign={true}
                    value={state.data.ssh_key_id}
                    error={state.error.ssh_key_id}
                    onChangeHandler={onChangeHandler}
                    label="SSH Key (Private) "
                    field_type="SSH_KEY"
                    inherits={credential_inherits}
                    prev_state={state.credential_state}
                    isSSH={true}
                />
            </div>
        </>
    );
}
AWSSection.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default InfraSection;

function getRegionsList(region_list) {
    const result = [];

    region_list.forEach(region => {
        result.push({
            id: region.RegionName,
            label: region.RegionName,
        });
    });

    return result;
}

function getZoneList(zone_list) {
    const result = [];

    zone_list.forEach(zone => {
        result.push({
            id: zone.ZoneName,
            label: zone.ZoneName,
        });
    });

    return result;
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: 32,
        paddingTop: '32px'
    },
    MainHeading: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#000',
        lineHeight: '24px'
    },
    SubHeading: {
        fontSize: '11px',
        color: '#9d9d9d',
        lineHeight: '15px'
    },
    mainBodyDiv: {
        backgroundColor: '#f6f8f8',
        padding: '15px 20px',
        borderTop: '1px solid#dedede',
    },
    subHeader: {
        backgroundColor: '#fcfcfc',
        fontSize: '12px',
        lineHeight: 2,
        padding: '5px 10px',
        borderBottom: '1px solid #dedede',
        color: '#9a9a9a'
    },
    formDiv: {
        padding: '30px 15px',
        '& .image-control': {
            height: '35px'
        }
    }
}))