import React, { memo, useCallback, useEffect, useState } from 'react'
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import { useReleasePackageContext } from './ReleasePackageContext';
import { makeStyles, Tooltip } from '@material-ui/core';
import { Input } from '../../../../components/genericComponents/Input';
import { StyledRadio } from './StylesRadio';
import SelectWithInsideLabel from '../../../../components/genericComponents/inputs/SelectWithInsideLabel';
import properties from '../../../../properties/properties';
import InvokeApi from '../../../../util/apiInvoker';
import GenerateURL from '../../../../util/APIUrlProvider';
import GenericSkeleton from '../../../../components/genericComponents/Skeletons/GenericSkeleton';
import BranchBlock from './BranchBlock';
import CommitIdBlock from './CommitIdBlock';
import ArtifactBlock from './ArtifactBlock';
import SubEnvBlock from './SubEnvBlock';
const ConfigureChangeSet = ({prev_state,...props}) => {
    const inherits = props.inherits ? props.inherits : null;
    const {setSkippable,configureChangeSet,setConfigureChangeSet} = useReleasePackageContext();
    useEffect(()=>{
        setSkippable(false);
    },[])
    const [state,setState] = useState(prev_state ? prev_state : getDefaultConfigureChangeSetState());
    const classes = useStyles({type: state.data.change_set_type})
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    const validateConfiguredChangeSet = () => {
        let isValid = true;
        const updatedConfiguredChangeSet = state.data.configuredChangeSet.map((service) => {
            let errors = {};
            if (!service.component_env_id) {
                errors.component_env_id = 'This is required';
                isValid = false;
            }
            if (state.data.change_set_type === 'pre_artifact' && !service.artifact) {
                errors.artifact = 'This is required';
                isValid = false;
            }
            return {
                ...service,
                errors: Object.keys(errors).length > 0 ? errors : undefined,
            };
        });

        setState((prev_state) => ({
            ...prev_state,
            data: {
                ...prev_state.data,
                configuredChangeSet: updatedConfiguredChangeSet,
            }
        }));
        return isValid;
    }
    inherits.validateForm = () => {
        let result = validateConfiguredChangeSet();
        return { valid : result};
    }
    const optionList =[{label:'Build & Deploy',value:'build_deploy',info:'In Buildpiper, this release strategy focuses on building and deploying microservices in a lower environment for a specific branch and promoting them to higher environments upon successful validation.'},
        {label:'Pre-Build Artifact',value:'pre_artifact',info:'A pre-build artifact is a artifact or collection of them that are prepared in advance before the main production deployment. These artifacts are already battle tested by client and how need to shipped to the production environment.'}
    ]
    useEffect(()=>{
        console.log("kdsjsjkdsj",configureChangeSet);
        let tranformedData = tranformDataForConfiguration(configureChangeSet);
        setState((prev_state)=>({
            ...prev_state,
            data:{
                ...prev_state.data,
                configuredChangeSet:tranformedData || [],
            }
        }))
    },[configureChangeSet])
    

    const tranformDataForConfiguration = (configureList)=>{
        console.log("jdfjdkdfjdk",configureList)
        if(!configureList || configureList.length ==0)
            return [];

        let updatedConfigurationList = configureList.map((service)=>{
            let doesServiceExist = state.data.configuredChangeSet.find((item)=>item.component_id == service.component_id);
            console.log("jdfjdkdfjdk",doesServiceExist)
            if(doesServiceExist){
                return {...doesServiceExist};
            }else{
                if(state.data.change_set_type == 'build_deploy'){
                    return {
                        ...service,
                        component_env_id: service.component_env_id ? service.component_env_id : null,
                        default_commit: service?.commit_id?.length>0 ? false : true,
                        default_branch: service?.branch?.length>0 ? false : true,
                    }
                }else{
                    const { branch, commit_id,ui_commit_id,repo_id,default_commit,default_branch, ...rest } = service;
                    return {
                        ...rest,
                        component_env_id: service.component_env_id ? service.component_env_id : null,
                        //default_artifact: service.default_artifact ? service.default_artifact : true,
                    }
                }
            }
        });
        return updatedConfigurationList;
    };

    const masterEnvList = [{id:'dev',label:'DEV'},{id:'qa',label:'QA'},{id:'uat',label:'UAT'},{id:'staging',label:'STAGING'},{id:'prod',label:'PROD'}];
    const resetServiceDataBasedOnType = (change_set_type) => {
        let updatedConfiguredChangeSet = state.data.configuredChangeSet.map((service) => {
            if (change_set_type === 'build_deploy') {
                return {
                    ...service,
                    component_env_id: null,
                    component_env_name: null,
                    branch: '',
                    commit_id: '',
                    ui_commit_id: null,
                    default_commit: true,
                    default_branch: true,
                };
            } else {
                return {
                    ...service,
                    component_env_id: null,
                    component_env_name: null,
                    artifact: '',
                };
            }
        });
        return updatedConfiguredChangeSet;
    };
    const onChangeMasterEnv = (masterEnv)=>{
        const resetServices = resetServiceDataBasedOnType(state.data.change_set_type);
        setState((prev_state) => ({
            ...prev_state,
            data: {
                ...prev_state.data,
                selectedMasterEnv: masterEnv,
                configuredChangeSet: resetServices,
            }
        }));
        setConfigureChangeSet(resetServices);
    }
    const addServiceData = (service, data) => {
        console.log("sjdkhsksjdf",service,data);
        let updatedConfiguredChangeSet = state.data.configuredChangeSet.map((serv) => {
            if (serv.component_name === service.component_name) {
                let {errors,...rest} = serv;
                return {
                    ...rest,
                    ...data,
                };
            } else {
                let {errors,...rest} = serv;
                return { ...rest };
            }
        });
    
        setState((prev_state) => ({
            ...prev_state,
            data: {
                ...prev_state.data,
                configuredChangeSet: updatedConfiguredChangeSet,
            }
        }));
        //setConfigureChangeSet(updatedConfiguredChangeSet);
    } 
    console.log("djshsjhdjs",state,prev_state);
    return (
        <div className={classes.root}>
            <span className='font-16 font-weight-600' >Configure your change set</span>
            <div className='d-flex f-direction-column' style={{gap:'12px'}}>
                <span className='font-14 font-weight-500 color-value'>Strategy for Release Package</span>
                <div style={{height:"34px"}}>
                    <Input
                        type={"custom-radio"}
                        name={"change_set_type"}
                        data={state.data}
                        error={state.error}
                        list={optionList}
                        customRadio={StyledRadio}
                        customLabel={CustomLabel}
                        mandatorySign
                        onChangeHandler={commonFunctions.onChangeHandler}
                    />
                </div>
            </div>
            
            <div style={{width:'201px'}}>
                <SelectWithInsideLabel list={masterEnvList} SelectedDeployment={state.data.selectedMasterEnv} onChangeHandler={onChangeMasterEnv} label={'Select Environment: '} optionsWidth={201} noWidth={true}/>
            </div>
            
            <div className={classes.tableContainer}>
              <div className='columns-heading'>
                <p>Services</p>
                <p>Applicatiion</p>
                <p>Sub Environment<span style={{color:'red'}}>*</span></p>
                {state.data.change_set_type == 'pre_artifact' ? 
                    <>
                     <p>Artifact</p>
                    </>
                    
                :
                    <>
                    <p>Branch</p>
                    <p>Commit Id</p>
                    </>
                }
                
              </div>
              <div className='data-body'>
                {state.data.configuredChangeSet.map((item,index)=>{
                  return (
                    <div className='data-tab'>
                      <p className='font-14 font-weight-600 color-icon-secondary'>
                        {item?.component_name || "NA"}
                      </p>
                      <div className='d-flex align-center' style={{gap:"10px"}}>
                        <span className='ri-apps-line font-16 color-key-78'></span>
                        <span className='font-14 font-weight-400 color-icon-secondary'>{item.app}</span>
                      </div>
                      <SubEnvBlock service={item} addServiceData={addServiceData} selectedMasterEnv={state.data.selectedMasterEnv}/>
                      {state.data.change_set_type == 'pre_artifact' ? 
                        <>
                            <ArtifactBlock service={item} addServiceData={addServiceData} selectedMasterEnv={state.data.selectedMasterEnv}/>
                        </>
                      :
                        <>
                            <BranchBlock service={item} addServiceData={addServiceData} selectedMasterEnv={state.data.selectedMasterEnv}/>
                            <CommitIdBlock service={item} addServiceData={addServiceData} selectedMasterEnv={state.data.selectedMasterEnv}/>
                        </>
                      }
                    </div>
                  )
                })}
                
              </div>
            </div>
        </div>
    )
}

export default memo(ConfigureChangeSet)

export const CustomLabel = React.memo(({ label, info }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span className='font-14 font-weight-500 color-icon-secondary'>{label}</span>
      <Tooltip title={info} placement="right" arrow>
        <span className='ri-information-line font-16 color-icon-secondary ml-4'></span>
        {/* <InfoIcon style={{ marginLeft: 4, fontSize: 16, cursor: 'pointer' }} /> */}
      </Tooltip>
    </div>
));

export function getDefaultConfigureChangeSetState(){
    return {
        data: {
            change_set_type:'build_deploy',
            selectedMasterEnv:'dev',
            configuredChangeSet: [],
        },
        error:{},
        validations:{},
        
    }
}

const useStyles = makeStyles((theme)=>({
    root:{
        display:'flex',
        flexDirection:'column',
        gap:'24px',

    },
    tableContainer:{
        // marginTop:"12px",
        '& .columns-heading':{
          display:"grid",
          gridTemplateColumns:(props)=>props.type == 'pre_artifact' ? "23% 23% 27% 27%" : "18% 18% 20% 22% 22%",
          padding:"12px 22px 12px 22px",
          paddingLeft:"0px",
          "& p":{
            color:"#787878",
            textTransform:"uppercase",
            fontSize:"12px",
            fontWeight:"600",
            lineHeight:"14px",
          } 
        },
        "& .data-body":{
          height:"400px",
          borderTop:"1px solid #DFDEDE",
          borderBottom:"1px solid #DFDEDE",
          overflow:"auto",
          "& .data-tab":{
            display:"grid",
            gridTemplateColumns:(props)=>props.type == 'pre_artifact' ? "23% 23% 27% 27%" : "18% 18% 20% 22% 22%",
            padding:"16px 16px 16px 12px",
            paddingLeft:"0px",
            alignItems:"center",
            '& .default-box':{
                width:'90%',
                padding:'8px 12px 8px 12px',
                borderRadius:'4px',
                backgroundColor:'#FAFAFA',
                display:'flex',
                alignItems:'center',
                justifyContent:'space-between'
            },
            '& .select-input':{
                border:'1px solid #E6E6E6',
                padding: '6px 12px 6px 12px',
                borderRadius:'4px',
                color:'#2F2F2F',
            }
          }
        },
      },
}))