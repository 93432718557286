import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';


const SidePanelApprovalQuestions = (props) => {
  const classes = useStyles();
  const variant = props.variant?props.variant :null
  const stage_instance_status = props.stage_instance_status ? props.stage_instance_status : null
  console.log(stage_instance_status,"stage_instance_status111")
  return (
    <div className={classes.staticwrap} style={{ backgroundColor: '#051939', height: '100%' }}>
      <div className='staticwrap-inner'>
          <h1 className='h1'>{
          stage_instance_status?
           stage_instance_status =="FAILED" ? 
            "The stage has failed!!" :
            stage_instance_status =="SUCCESS" ? 
            "The Stage is Approved!!" :
            stage_instance_status =="DISAPPROVED" ? 
            "The Stage is Disapproved!!" :
            stage_instance_status =="PENDING_APPROVAL" ? 
            "The Stage not approved yet!!" :
            "Stage Approval Questions":
              "Stage Approval Questions"}</h1>
        
        <p>
        {
            variant === "only-view"  ?
            " This stage requires your approval to proceed. In order to approve you will have to review the questions and provide appropriate answers.":
            "The stage was approved by the designated approver and he provided given inputs for approval."}
          <br></br> <br></br>
          
        </p>
      </div>
    </div>
  )
}

SidePanelApprovalQuestions.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
};
export default SidePanelApprovalQuestions
const useStyles = makeStyles({
  staticwrap: {
    '& .staticwrap-inner': {
      padding: '215px 30px 0 40px',
      color: '#fff',
      '& p': {
        fontSize: '13px',
        lineHeight: '23px',
        marginTop: '30px',
      }
    },
    '& .h1': {
      fontSize: '25px',
      lineHeight: '36px',
      color: '#ffffff',
      fontWeight: '300',
      position: 'relative',

      '&:before': {
        content: '""',
        borderBottom: '1px solid #000',
        position: 'absolute',
        borderLeft: 'none',
        bottom: '0',
        left: 0,
        display: 'inline-block',
        width: '40px',
        height: '4px',
        background: '#2e2af3',
      }
    },
  }
})

