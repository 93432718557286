import React from 'react';
import ServiceCard from './ServiceCard';
export const Body = (props) => {
    const services = props.data;
    let pinEnabled = props.pinEnabled;

    let pinnedServices = [];
    let UnPinnedServices = [];
    
    if(pinEnabled){
        let pinned_services = JSON.parse(localStorage.getItem("pinned_services"));
        if(!pinned_services){
            pinned_services = [];
        }
        console.log("njshejdhas",pinned_services);
        services.forEach((serv) => {
            if (pinned_services.includes(serv.id)) {
                pinnedServices.push(serv);
            } else {
                UnPinnedServices.push(serv);
            }
        });
    }
    console.log(pinEnabled,pinnedServices,UnPinnedServices,services,"bcdhbshdbh")
    return (
        !pinEnabled ? 
        <>
          {
            services.map((serv,index)=>(
                <ServiceCard open_by_default={index == 0} key={serv.id} refresh={props.refresh} service_data={serv} confirmForApproval={props.confirmForApproval} sendForApproval={props.sendForApproval} pinEnabled={false} />
            ))
          }
        </>
        
        :
        <>
            {pinnedServices.length > 0 ? 
                <>
                    <div className='color-key-78 font-12 font-weight-600 mb-12'>Pinned Services</div>
                    {
                        pinnedServices.map((service,index)=>(
                            <ServiceCard open_by_default={index == 0} key={service.id} refresh={props.refresh} service_data={service} confirmForApproval={props.confirmForApproval} sendForApproval={props.sendForApproval} pinned={true} pinEnabled={true}/>
                        ))
                    }
                </>
            : null
            }
            {
              UnPinnedServices.length > 0 ?
                <div style={pinnedServices.length >0 ?{marginTop: "24px"}:{}}>
                    { pinnedServices.length > 0 && <div className={'color-key-78 font-12 font-weight-600 mb-12'}>Unpinned Services</div>}
                    {
                        UnPinnedServices.map((service,index)=>(
                            <ServiceCard open_by_default={pinnedServices.length>0? false: index == 0} key={service.id} refresh={props.refresh} service_data={service} confirmForApproval={props.confirmForApproval} sendForApproval={props.sendForApproval} pinEnabled={true} />
                        ))
                    }
                </div>
                
              :null
            }
            
        </>
    );
}