import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { getCommonFunctions } from "../../../serviceRevamp/add/ci_flow/SourceDetails";
import InvokeApi, { PostData } from "../../../../util/apiInvoker";
import GenerateURL from "../../../../util/APIUrlProvider";
import properties from "../../../../properties/properties";
import ManifestOverview from "./ServiceRevampOverview/CdOverview/ManifestOverview";
import { Redirect } from "react-router-dom";
import { Grid, IconButton, Tooltip } from "@material-ui/core";
import AccessLevelOverview from "./ServiceRevampOverview/CdOverview/AccessLevelOverview";
import ResourceQuotaOverview from "./ServiceRevampOverview/CdOverview/ResourceQuotaOverview";
import ConfigSecretOverview from "./ServiceRevampOverview/CdOverview/ConfigSecretOverview";
import EnvVarOverview from "./ServiceRevampOverview/CdOverview/EnvVarOverview";
import AffinityAntiAffinityOverview from "./ServiceRevampOverview/CdOverview/AffinityAntiAffinityOverview";
import LivenessAndReadinessOverview from "./ServiceRevampOverview/CdOverview/LivenessAndReadinessOverview";
import LabelsAnnotationsOverview from "./ServiceRevampOverview/CdOverview/LabelsAnnotationsOverview";
import DeploymentStrategyOverview from "./ServiceRevampOverview/CdOverview/DeploymentStrategyOverview";
import VersioningDetailsOverview from "./ServiceRevampOverview/CdOverview/VersioningDetailsOverview";
import ServiceHooksOverview from "./ServiceRevampOverview/CdOverview/ServiceHooksOverview";
import { Loading } from "../../../utils/Loading";
import AuditCard from "./auditHistory/AuditCard";
import { StepWizard } from "../../../../components/Step Wizard/StepWizard";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { GetAuth } from "../../../../util/security";
import TolerationsAndPriorityClassOverview from "./ServiceRevampOverview/CdOverview/TolerationsAndPriorityClassOverview";
import CloneDeployment from "../../../serviceRevamp/add/components/CloneDeployment";
import MountConfigOverView from "./ServiceRevampOverview/CdOverview/MountConfigOverview";
import useFetchPermissions from "../../../../components/customHooks/useFetchPermissions";

const CdDetailsOverviewFlow = props => {
    const selectedEnvDetails = props.selectedEnvDetails;
    const service_env = props.service_env;
    const service_env_name = service_env ? service_env.project_env?.name : null
    const change_in_env_tab = props.change_in_env_tab;
    const cd_data = props.cd_data;
    const refresh_count = props.refresh_count ? props.refresh_count : null;
    const manifestFormEnabled = props.manifestFormEnabled
    const bpFormEnabled = props.bpFormEnabled
    const [showLoading, setShowLoading] = useState(false);
    const component_env_id = service_env.id;
    const inherits = props.inherits ? props.inherits : {};
    const ci_response = props.ci_response;
    const [state, setState] = useState({
        cd_data: [],
        show_overview_view: false,
        count: 1,
        show_audit_card: true,
        cdDeleteTriggered: false,
        multiple_deployment_list: []
    })
    const [isCloneVisible, setIsCloneVisible] = useState(false);
    const [cloneList, setcloneList] = useState([]);
    const [canRedirect, setRedirect] = useState(false);
    const [manifestRedirect, setManifestRedirect] = useState(false);
    const [type,setType] = useState(null)
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    const available_settings = props.available_settings ? props.available_settings : [];
    var url = GenerateURL({ service_id: selectedEnvDetails.service_id, component_env_id: component_env_id }, properties.api.save_cd, true);
    const is_permitted = useFetchPermissions()?.POST.includes(url);
    const all_deployment_list = props.all_deployment_list ? props.all_deployment_list : [];
    const multiple_build_enabled = props.multiple_build_enabled

    const refreshCallbackFn = props.refreshCallbackFn;
    // clone_env_id={state.clone_env_id ? state.clone_env_id : null}
    // clone_deploy_id = {state.clone_deploy_id ? state.clone_deploy_id : null}
    // env_cd_detail_id={state.cd_data.id}

    console.log(service_env, "data in cd tab ==========>")

    const panel_list = [
        {
            order: 1,
            mainText: 'Access Level',
            body: AccessLevelOverview,
        },
        {
            order: 2,
            mainText: 'Request Quota',
            body: ResourceQuotaOverview,
        },
        {
            order: 3,
            mainText: 'Configuration & Secrets',
            body: ConfigSecretOverview,
        },
        {
            order: 4,
            mainText: 'Env Variables',
            body: EnvVarOverview,
        },
        {
            order: 5,
            mainText: 'Node Affinity',
            body: AffinityAntiAffinityOverview,

        },
        {
            order: 6,
            mainText: 'Tolerations & Priority',
            body: TolerationsAndPriorityClassOverview,
        },
        {
            order: 7,
            mainText: 'Deployment Strategy',
            body: DeploymentStrategyOverview,
        },
        {
            order: 8,
            mainText: 'Liveness/Readiness',
            body: LivenessAndReadinessOverview,
        },
        {
            order: 9,
            mainText: 'Labels/Annotations',
            body: LabelsAnnotationsOverview,
        },
        {
            order: 10,
            mainText: 'Hooks',
            body: ServiceHooksOverview,
        },
        {
            order: 11,
            mainText: 'Versioning Details',
            body: VersioningDetailsOverview,
        }
    ]

    const panel_list_2 = [
        {
            order: 1,
            mainText: 'Access Level',
            body: AccessLevelOverview,
        },
        {
            order: 2,
            mainText: 'Request Quota',
            body: ResourceQuotaOverview,
        },
        {
            order: 3,
            mainText: 'Configuration & Secrets',
            body: ConfigSecretOverview,
        },
        {
            order: 4,
            mainText: 'Mount Details',
            body: MountConfigOverView,
        },
        {
            order: 5,
            mainText: 'Env Variables',
            body: EnvVarOverview,
        },
        {
            order: 6,
            mainText: 'Node Affinity',
            body: AffinityAntiAffinityOverview,

        },
        {
            order: 7,
            mainText: 'Tolerations & Priority',
            body: TolerationsAndPriorityClassOverview,
        },
        {
            order: 8,
            mainText: 'Deployment Strategy',
            body: DeploymentStrategyOverview,
        },
        {
            order: 9,
            mainText: 'Liveness/Readiness',
            body: LivenessAndReadinessOverview,
        },
        {
            order: 10,
            mainText: 'Labels/Annotations',
            body: LabelsAnnotationsOverview,
        },
        {
            order: 11,
            mainText: 'Hooks',
            body: ServiceHooksOverview,
        },
        // {
        //     order: 10,
        //     mainText: 'Versioning Details',
        //     body: VersioningDetailsOverview,
        // }
    ]

    const panel_list_sidecar = [
        {
            order: 1,
            mainText: 'Request Quota',
            body: ResourceQuotaOverview,
        },
        {
            order: 2,
            mainText: 'Configuration & Secrets',
            body: ConfigSecretOverview,
        },
        {

            order: 3,
            mainText: 'Mount Details',
            body: MountConfigOverView,

        },
        {
            order: 4,
            mainText: 'Env Variables',
            body: EnvVarOverview,
        },

    ]

    console.log(cd_data, "rtyuighjkrtyuhjk ===================>")

    useEffect(() => {

        if (cd_data && cd_data[0]) {
            setState(new_state => ({
                ...new_state,
                cd_data: cd_data,
                cd_configured: true
            }));
            setShowLoading(false)
            fetchSideCarsData(cd_data[0]);

        } else {
            setState(new_state => ({
                ...new_state,
                cd_data: [],
                cd_configured: false
            }));
            setShowLoading(false)
        }
    }, [cd_data, refresh_count])

    useEffect(() => {
        setState(new_state => ({
            ...new_state,
            selected_cd_for_display: cd_data && cd_data[0], //cd[0]

        }))
    }, [cd_data, change_in_env_tab,])

    console.log(state.cd_data, state.cd_configured, showLoading, "cd_data_001")


    function refreshState() {
        console.log("function running")
        refreshCallbackFn()
        setState(new_state => ({
            ...new_state,
            count: new_state.count + 1
        }));
    }

    useEffect(() => {
        // fetchCdData();

        if (change_in_env_tab) {
            setState(new_state => ({
                ...new_state,
                show_audit_card: true,
                show_overview_view: false
            }));
        }
    }, [service_env, state.cd_configured, component_env_id, state.count, state.sidecars_configured])

    function fetchSideCarsData(data) {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: selectedEnvDetails.service_id, component_env_id: component_env_id, cd_id: data.id ? data.id : null }, properties.api.save_side_cars),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        setShowLoading(true);
        InvokeApi(requestInfo, onFetchSideCarsDataSuccess, onFetchSideCarsDataFail)
    }

    function onFetchSideCarsDataSuccess(response) {
        console.log(response, "response ===========>")
        setShowLoading(false);
        setState(new_state => ({
            ...new_state,
            sidecars_configured: response && response.length > 0 ? true : false,
            sidecars_data: response && response.length > 0 ? response : null
        }));
    }

    function onFetchSideCarsDataFail(response) {
        setShowLoading(false);
        setState(new_state => ({
            ...new_state,
            sidecars_configured: false,
            manifest_meta_data: null,
            sidecars_data: null
        }));
    }


    function getCloneEnvList(MANIFEST_HANDLER) {
        console.log(MANIFEST_HANDLER, "onFetchCloneSuccess")
        var requestInfo = {
            endPoint: GenerateURL({ service_id: selectedEnvDetails.service_id }, properties.api.get_clone_env_list),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        setShowLoading(true);
        InvokeApi(requestInfo, typeof (MANIFEST_HANDLER) == "string" ? (data) => { onFetchCloneSuccess(data, MANIFEST_HANDLER) } : onFetchCloneSuccess, onFetchCloneFail)
    }
    function onFetchCloneFail() {
        setShowLoading(false)
    }
    function onFetchCloneSuccess(response, MANIFEST_HANDLER) {
        console.log("fn_running", response)
        setShowLoading(false)
        var list = [];
        response.forEach(env => {
            if (env.env_cd_detail) {
                list = [...list, {
                    id: env.id,
                    label: env.project_env.name
                }]
            }
        })
        console.log(list, response, "   ")
        if (list.length > 0) {
            if(MANIFEST_HANDLER){
                setType("manifest")
            }else{
                setType("bp-ui")
            }
            setcloneList(list)
            setIsCloneVisible(true);    
        }
        else {
            if (MANIFEST_HANDLER) {
                setManifestRedirect(true)
            } else {
                setRedirect(true);

            }

        }
    }

    function onCloneHandler(clone_env_id, clone_deploy_id, manifest_enabled, is_same_env) {
        console.log(clone_env_id, clone_deploy_id, manifest_enabled, is_same_env,"sdkjvbsdhbvjhsdbhj")
        if (clone_env_id && clone_deploy_id) {
            setState(prev_state => ({
                ...prev_state,
                clone_env_id: clone_env_id,
                clone_deploy_id: clone_deploy_id,
                manifest_enabled: manifest_enabled,
                is_same_env : is_same_env,
            }))
        }
        if (manifest_enabled) {
            setManifestRedirect(true)
        } else {
            setRedirect(true);
        }

    }
    function handleShowOverviewData(cd_id) {
        var selected_cd_overview = state.cd_data.filter(item => item.id == cd_id)
        console.log(cd_id, state.cd_data, "fdjksafjskajfjd")
        setState(new_state => ({
            ...new_state,
            show_overview_view: true,
            selected_cd_for_display: selected_cd_overview[0],
            show_audit_card: false
        }))
    }

    function handleShowSidecarOverview(side_car_id) {
        var selected_sidecar_data_overview = state.sidecars_data.find(item => item.id == side_car_id)

        setState(new_state => ({
            ...new_state,
            show_sidecar_overview: true,
            selected_sidecar_for_display: selected_sidecar_data_overview,
            show_audit_card: false
        }))
    }


    function handleShowAuditCard() {
        setState(new_state => ({
            ...new_state,
            show_overview_view: false,
            selected_cd_for_display: null,
            show_audit_card: true,
            show_sidecar_overview: false
        }))
    }
    function sendApprovalRequest(id) {
        var post_url = GenerateURL({}, properties.api.approval_request);

        var post_obj = {
            "name": "env_cd_detail",
            "entity_id": id
        }

        PostData(post_url, post_obj, sendApprovalRequestSuccess, sendApprovalRequestFailed);
        setState(new_state => ({
            ...new_state,
            loading: true,
            error_msg: null,
            open: true
        }));
    }

    function sendApprovalRequestSuccess(response) {
        refreshState()
        var msg = response.detail
        setState(new_state => ({
            ...new_state,
            approval_request_success_msg: msg,
            open: false
        }));

    }

    function sendApprovalRequestFailed(error) {
        var error = error.toString()
        setState(new_state => ({
            ...new_state,
            deployment_failed_msg: error
        }));

    }
    const handleClose = () => {
        setState({ ...state, approval_request_success_msg: null });
    };


    console.log(available_settings, "xghcfgh===========>")

    function confirmForApproval(id) {
        var post_url = GenerateURL({}, properties.api.confirm_approval);

        var post_obj = {
            "name": "env_cd_detail",
            "entity_id": id
        }

        PostData(post_url, post_obj, confirmApprovalRequestSuccess, confirmApprovalRequestFailed);
        setState(new_state => ({
            ...new_state,
            loading: true,
            error_msg: null,
            open: true
        }));
    }

    function confirmApprovalRequestSuccess(response) {
        refreshState()
        var msg = response.detail
        setState(new_state => ({
            ...new_state,
            approval_confirm_success_msg: msg,
        }));
    }

    function confirmApprovalRequestFailed(error) {
        var error = error.toString()
        setState(new_state => ({
            ...new_state,
            approval_failed_msg: error
        }));
    }

    console.log(cd_data, "rtyuiohjkl ===============>")

    function setCdDeleteInProgress() {
        setState((new_state) => ({
            ...new_state,
            cdDeleteTriggered: !new_state.cdDeleteTriggered,
        }))
    }

    console.log(props, "component called ==========>")


    return (
        <>
            {showLoading ?
                <div className="light-loading-container">
                    <Loading varient="light" />
                </div>
                :
                
                    <div className="section-service-overview" style={{ border: '0px', padding: '10px 0px 0px' }}>

                        {
                            state.selected_cd_for_display && state.show_overview_view &&
                            <div className="d-flex align-center space-between">
                                <p className="font-12 text-grey-83 ml-10">You are viewing deployment details with status: &nbsp;
                                    {state.selected_cd_for_display.status ?
                                        state.selected_cd_for_display.status == "APPROVAL_REQUESTED" ? "Approval Request" :
                                            state.selected_cd_for_display.status == "DRAFT" ? "Draft" : "Approved" : ""}</p>
                                {
                                    state.show_overview_view &&
                                    <IconButton onClick={handleShowAuditCard} className="ml-auto d-block"><CloseIcon className="font-18" /></IconButton>
                                }
                            </div>
                        }
                        {
                            state.show_sidecar_overview &&
                            <div className="d-flex align-center space-between">
                                <p className="font-12 text-grey-83 ml-10">You are viewing sidecar details with status: &nbsp;
                                    {state.selected_sidecar_for_display.status ?
                                        state.selected_sidecar_for_display.status == "APPROVAL_REQUESTED" ? "Approval Request" :
                                            state.selected_sidecar_for_display.status == "DRAFT" ? "Draft" : "Approved" : ""}</p>
                                {
                                    state.show_sidecar_overview &&
                                    <IconButton onClick={handleShowAuditCard} className="ml-auto d-block"><CloseIcon className="font-18" /></IconButton>
                                }
                            </div>
                        }

                        {state.cd_configured && state.cd_data.length > 0 ?
                            <>
                                {
                                    state.show_audit_card ?
                                        <div className="" >
                                            <Grid container spacing="2">
                                                {state.cd_data.map(item => (
                                                    <Grid item lg="12" className="pd-10 sidecar-init-container">
                                                        <AuditCard
                                                            audit_card_data={item}
                                                            cd_configured={state.cd_configured}
                                                            service_id={selectedEnvDetails.service_id}
                                                            component_env_id={component_env_id}
                                                            application_id={selectedEnvDetails.application_id}
                                                            refreshFn={refreshState}
                                                            sendForApproval={sendApprovalRequest}
                                                            confirmForApproval={confirmForApproval}
                                                            showOverviewView={() => { handleShowOverviewData(item.id) }}
                                                            service_env_details={service_env?.project_env}
                                                            clone_env_id={state.clone_env_id ? state.clone_env_id : null}
                                                            env_cd_detail_id={item.id}
                                                            sidecars_configured={state.sidecars_configured}
                                                            sidecars_data={state.sidecars_data}
                                                            showSideCarsOverview={handleShowSidecarOverview}
                                                            approvedCdData={item.status == "APPROVED" ? true : false}
                                                            isCdDeleteInProgress={item.status == "DELETE_REQUESTED" ? true : false}
                                                            setCdDeleteInProgress={setCdDeleteInProgress}
                                                            extraProps={props?.extraProps}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </div>
                                        : null
                                }
                                {state.show_overview_view ?
                                    state.selected_cd_for_display && (state.selected_cd_for_display.manifest_meta_data || state.selected_cd_for_display.helm_manifest_data) ?
                                        <ManifestOverview
                                            maifest_meta_data={state.selected_cd_for_display}
                                            application_id={selectedEnvDetails.application_id}
                                            service_id={selectedEnvDetails.service_id}
                                            audit_history_status={state.selected_cd_for_display.status ? state.selected_cd_for_display.status : null}
                                            component_env_id={component_env_id}
                                            clone_env_id={state.clone_env_id ? state.clone_env_id : null}
                                            clone_deploy_id={state.clone_deploy_id ? state.clone_deploy_id : null}
                                            cd_versioning={
                                                available_settings && available_settings[0] && available_settings[0].value && available_settings[0].value == "true" ?
                                                    true : false
                                            }
                                            extraProps={
                                                {
                                                    application_id: selectedEnvDetails.application_id,
                                                    service_id: selectedEnvDetails.service_id,
                                                    component_env_id: component_env_id,
                                                    ...props.extraProps
                                                }
                                            } />
                                        :
                                        
                                            <StepWizard
                                                extraProps={
                                                    {
                                                        application_id: selectedEnvDetails.application_id,
                                                        service_id: selectedEnvDetails.service_id,
                                                        component_env_id: component_env_id,
                                                        showEditInfoDialog: () => { },
                                                        selected_cd_data: state.selected_cd_for_display,
                                                        auto_approval_flag: available_settings && available_settings[0] && available_settings[0].value ? available_settings[0].value == "true" ? true : false : false,
                                                        ...props.extraProps
                                                    }
                                                }
                                                isListing={true}
                                                dataJson={available_settings.length > 0 ? available_settings[0].value && available_settings[0].value == "true" ?
                                                    panel_list : panel_list_2 : panel_list_2}
                                                currentTab={1}
                                                prev_state={
                                                    {
                                                        panels: getPanelWiseData(state.selected_cd_for_display),
                                                        selectedTabOrder: 1
                                                    }} />
                                        
                                    : null
                                }
                                {
                                    state.show_sidecar_overview &&
                                    
                                        <StepWizard
                                            extraProps={
                                                {
                                                    application_id: selectedEnvDetails.application_id,
                                                    service_id: selectedEnvDetails.service_id,
                                                    component_env_id: component_env_id,
                                                    showEditInfoDialog: () => { },
                                                    auto_approval_flag: available_settings && available_settings[0] && available_settings[0].value ? available_settings[0].value == "true" ? true : false : false,
                                                    show_sidecar_overview: state.show_sidecar_overview,
                                                    service_env_data: service_env?.project_env,
                                                    env_cd_id: state.cd_data[0]?.id,
                                                    ...props.extraProps
                                                }
                                            }
                                            isListing={true}
                                            dataJson={panel_list_sidecar}
                                            currentTab={1}
                                            prev_state={
                                                {
                                                    panels: getPanelWiseSideCarData(state.selected_sidecar_for_display),
                                                    selectedTabOrder: 1
                                                }} />
                                    
                                }

                            </>
                            :

                            <div className="overviewCard__body d-flex align-center justify-center" style={{ minHeight: '300px' }}>
                                
                                    {
                                        canRedirect && <Redirect to={{
                                            pathname: "/application/" + selectedEnvDetails.application_id + "/service/" + selectedEnvDetails.service_id + "/env/" + component_env_id + "/cd/add",
                                            state: {
                                                clone_env_id: state.clone_env_id ? state.clone_env_id : null,
                                                clone_deploy_id: state.clone_deploy_id ? state.clone_deploy_id : null
                                            }
                                        }}></Redirect>
                                    }
                                    {
                                        manifestRedirect && <Redirect to={{
                                            pathname: "/application/" + selectedEnvDetails.application_id + "/service/" + selectedEnvDetails.service_id + "/env/" + component_env_id + "/cd/manifest/add",
                                            state: {
                                                ...props.extraProps,
                                                clone_env_id: state.clone_env_id ? state.clone_env_id : null,
                                                clone_deploy_id: state.clone_deploy_id ? state.clone_deploy_id : null,
                                                is_same_env : state.is_same_env
                                            }
                                        }}></Redirect>
                                    }
                                    {!isCloneVisible ?
                                        is_permitted ?
                                            <div className="d-block">
                                                <div className="text-center alert alert-primary border-style-dashed bg-white m-auto" style={{ width: '400px', marginBottom: '20px' }}>
                                                    <div className="text-box">
                                                        <p className="main-text mb-10"> CD Details Not Configured</p>
                                                        <span className="sub-text mb-10">
                                                            <div>Please note to deploy a service CD is required. Please choose from options below to add CD.</div>
                                                        </span>
                                                    </div>
                                                </div>
                                                {
                                                    bpFormEnabled &&
                                                    <div className="d-grid align-center space-between" style={{ backgroundColor: '#fcfcfc', border: '1px solid #dedede', padding: '10px', gridTemplateColumns: '350px 120px', marginBottom: '20px', borderRadius: '4px' }}>
                                                        <div className="box-with-btn">
                                                            <span className="text-black font-13">BuildPiper-UI:&nbsp;</span>
                                                            <span className="text-color-dark-grey font-13">To have a very smooth configuration we strongly recommend BuildPiper UI.</span>

                                                        </div>
                                                        <button className="btn btn-submit" onClick={getCloneEnvList}>Continue</button>
                                                    </div>
                                                }

                                                {manifestFormEnabled &&
                                                    <div className="d-grid align-center space-between" style={{ backgroundColor: '#fcfcfc', border: '1px solid #dedede', padding: '10px', gridTemplateColumns: '350px 120px', marginBottom: '20px', borderRadius: '4px' }}>
                                                        <div className="box-with-btn">
                                                            <span className="text-black font-13">Custom Manifest file/GitOps:&nbsp;</span>
                                                            <span className="text-color-dark-grey font-13">you can also configure the CD by uploading your custom manifest file.</span>

                                                        </div>
                                                        <button className="btn btn-submit" onClick={() => { getCloneEnvList("MANIFEST_HANDLER") }}>Continue</button>
                                                        {/* <Link className="btn btn-submit" to={{
                                                            pathname: "/application/" + selectedEnvDetails.application_id + "/service/" + selectedEnvDetails.service_id + "/env/" + component_env_id + "/cd/manifest/add",
                                                            state: { ...props.extraProps }
                                                        }
                                                        }>
                                                            Continue
                                                        </Link> */}
                                                    </div>
                                                }

                                            </div>
                                            :
                                            <div className="d-block disabled-text-box">
                                                <div className="text-center alert alert-primary border-style-dashed bg-white m-auto" style={{ width: '400px', marginBottom: '20px' }}>
                                                    <div className="text-box">
                                                        <p className="main-text mb-10"> CD Details Not Configured</p>
                                                        <span className="sub-text mb-10">
                                                            <div>Please note to deploy a service CD is required. Please choose from options below to add CD.</div>

                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="d-grid align-center space-between" style={{ backgroundColor: '#fcfcfc', border: '1px solid #dedede', padding: '10px', gridTemplateColumns: '350px 120px', marginBottom: '20px', borderRadius: '4px' }}>
                                                    <div className="box-with-btn">
                                                        <span className="text-black font-13">BuildPiper-UI:&nbsp;</span>
                                                        <span className="text-color-dark-grey font-13">To have a very smooth configuration we strongly recommend BuildPiper UI.</span>

                                                    </div>
                                                    <Tooltip title="You are not allowed to perform this action">
                                                        <button className="btn  btn-disable cursor-not-allowed">Continue</button>
                                                    </Tooltip>
                                                </div>
                                                <div className="d-grid align-center space-between" style={{ backgroundColor: '#fcfcfc', border: '1px solid #dedede', padding: '10px', gridTemplateColumns: '350px 120px', marginBottom: '20px', borderRadius: '4px' }}>
                                                    <div className="box-with-btn">
                                                        <span className="text-black font-13">Custom Manifest file/GitOps:&nbsp;</span>
                                                        <span className="text-color-dark-grey font-13">you can also configure the CD by uploading your custom manifest file.</span>

                                                    </div>
                                                    <Tooltip title="You are not allowed to perform this action">
                                                        <button className="btn btn-disable cursor-not-allowed" >
                                                            Continue
                                                        </button>
                                                    </Tooltip>

                                                </div>

                                            </div>
                                        : null
                                    }
                                    {
                                        isCloneVisible &&
                                        <CloneDeployment
                                            list={cloneList}
                                            service_id={selectedEnvDetails.service_id}
                                            all_deployment_list={all_deployment_list}
                                            onCloneHandler={onCloneHandler}
                                            selected_service_env_name = {service_env_name}
                                            cd_type = {type}
                                        />
                                    }
                                


                            </div>}
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={state.approval_request_success_msg}
                            onClose={handleClose}
                            autoHideDuration={6000}>
                            <Alert severity="success">
                                {state.approval_request_success_msg}
                            </Alert>
                        </Snackbar>
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={state.cdDeleteTriggered}
                            onClose={handleClose}
                            autoHideDuration={6000}>
                            <Alert severity="success">
                                "Delete Triggered"
                            </Alert>
                        </Snackbar>
                    </div>
                
            }

        </>
    )
}

CdDetailsOverviewFlow.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };

export default CdDetailsOverviewFlow;

function getPanelWiseData(response) {
    return {
        1: response,
        2: response,
        3: response,
        4: response,
        5: response,
        6: response,
        7: response,
        8: response,
        9: response,
        10: response,
        11: response
    }

}

function getPanelWiseSideCarData(response) {
    return {
        1: response,
        2: response,
        3: response,
        4: response,
        5: response,
    }
}