import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { EnvLabel, EnvId } from '../../../../util/util';
import { styled } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CommonHorizontalTab from '../../../../components/genericComponents/CommonHorizontalTab';
import BasicInfo from '../BasicInfo';
import { Input } from '../../../../components/genericComponents/Input';
import ParseFile from '../../../../util/FileParser';
import Base64 from 'base-64';
import { ConvertPipelineObjtoYAML, ConvertPipelineYAMLtoObj, GetPipelineSpecificToPipeline, GetServiceSpecificToPipeline } from '../../../../controllers/PipelineController';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import PipelineUiViaYAML from './PipelineUiViaYAML';
import { ExpandableServiceList, ExpandableTagList } from '../../../../components/hoc/expandableList';
import SquareAvatar from '../../../../components/genericComponents/AvatarSquare';
import { Dialog, IconButton, Tooltip } from '@material-ui/core';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { GetTriggerCodeFromId } from './PipelineDetailHeading';
import SaveIcon from '@material-ui/icons/Save';
import InfoIcon from '@material-ui/icons/Info';
import properties from '../../../../properties/properties';
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import moment from 'moment';
import { getSnowTicketState } from '../../../pipeline/execution/components/SnowJobExecutionCard';

const HeadingSection = styled('div')({
    backgroundColor: '#fafafa',
    border: '1px solid #ebedee',
    borderLeft: 'none',
    borderRight: 'none',
    fontSize: '14px',
    color: '#2b2b2b',
    padding: '10px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    lineHeight: 2,
    '& .headerul': {
        margin: 'auto',
        '& li': {
            padding: '5px 15px!important',
            color: '#8b979d'
        }
    },
    '& .btn-icon': {
        margin: '0rem 5px',
        backgroundColor: ' #7881b4'
    },
    '& .btn-icon .flaticon-switch-to-full-screen-button': {
        color: '#fff'
    }
})

const EditBasicInfo = styled('div')({
    backgroundColor: '#f0f7f7',
})

const GlobalPipelineCanvas = (props) => {
    const [state, setState] = useState({ selectedTabOrder: 2, loaded: false, open: false, stages: [] });
    const [showDialog, setShowDialog] = useState({ open: false });
    const pipeline = props.pipeline;
    const stages = props.stages;
    const getConvetedStagesObj = props.getConvetedStagesObj;
    const count = props.count;
    const saveBasicInfoAndContinueToDetails = props.saveBasicInfoAndContinueToDetails;
    const application = props.application;
    const updateError = props.updateError;
    const showAddStageForm = props.showAddStageForm;
    const showAddJobForm = props.showAddJobForm;
    const deleteStage = props.deleteStage;
    const editStage = props.editStage;
    const updateStages = props.updateStages;
    const services = props.services;
    const editJob = props.editJob;
    const deleteJob = props.deleteJob;
    const all_project_envs = props.all_project_envs
    const trigger = GetTriggerCodeFromId(pipeline.trigger_type_id);
    const active_service_list = [];
    const handleBasicInfoFormOpen = props.handleBasicInfoFormOpen;
    const saveStages = props.saveStages;
    const saveStageViaYaml = props.saveStageViaYaml;
    const getYamlData = props.getYamlData;
    const pipeline_yaml_data = props.pipeline_yaml_data;
    const is_stage_form_dirty = props.is_stage_form_dirty;
    const error_in_saving_data = props.error_in_saving_data;
    console.log(error_in_saving_data, "error_in_saving_data")
    const formDirtyFlag = props.formDirtyFlag;
    const version = pipeline.version
    const [editorValue, setEditorValue] = useState('');

    console.log(all_project_envs, 'piplkdkndw')
    useEffect(() => {
        console.log(stages, "====> stages")
        if (stages && stages.length > 0) {
            setState(prev_state => (
                {
                    ...prev_state,
                    stages: stages
                }
            ));
        }

    }, [stages, count])

    const handleEditorChange = (newValue) => {
        console.log(newValue, "yaml_stage_data")
        setEditorValue(newValue);
    }

    application.components.forEach(service => {
        if (pipeline.components.includes(service.id)) {
            active_service_list.push({ name: service.name, part_of_execution: service.part_of_execution ? true : false });
        }
    });

    const panel_list = pipeline.version === 'v2' ? [
        {
            tabName: "Workflow",
            order: 2
        },
        // {
        //     tabName: "YAML",
        //     order: 3
        // }
    ] :
        [{
            tabName: "Workflow",
            order: 2
        }]

    const updateSelectedTab = (selectedTabOrder) => {
        console.log(state.stages, "selectedTabOrder11")
        if (state.selectedTabOrder === 2) {
            if (state.stages && state.stages.length > 0) {
                const obj_based_data_to_yaml = state.stages && state.stages.length > 0 ? ConvertPipelineObjtoYAML(state.stages, application.components, all_project_envs, application.pipelines) : null;
                console.log(obj_based_data_to_yaml, "update_stagesupdate_stages")
                setEditorValue(obj_based_data_to_yaml);
                setState({
                    ...state,
                    stages: stages,
                    selectedTabOrder: selectedTabOrder
                });
            } else {
                setState({
                    ...state,
                    stages: [],
                    selectedTabOrder: selectedTabOrder
                });
            }
        }
        if (state.selectedTabOrder === 3) {
            console.log(editorValue, "update_stagesupdate_stages")
            var updated_stages_data = ConvertPipelineYAMLtoObj(editorValue, application.components, all_project_envs);
            console.log(editorValue, "update_stagesupdate_stages")
            var error_in_convert_stage = updated_stages_data && updated_stages_data.errors ? updated_stages_data.errors : null;
            var update_stages = updated_stages_data && updated_stages_data.stages && updated_stages_data.stages.length > 0 ? updated_stages_data.stages : null;
            console.log(update_stages, updated_stages_data, "update_stagesupdate_stages");
            if (update_stages && update_stages.length > 0) {
                getConvetedStagesObj(update_stages);
            }

            if (update_stages && update_stages.length > 0) {
                setState({
                    ...state,
                    stages: update_stages,
                    selectedTabOrder: selectedTabOrder
                });
            } else {
                setState({
                    ...state,
                    stages: [],
                    selectedTabOrder: selectedTabOrder
                });
            }

            if (error_in_convert_stage && error_in_convert_stage.length > 0) {
                onError(error_in_convert_stage);
            }

        }



    }

    const yaml = pipeline?.version === 'v2' ? ConvertPipelineObjtoYAML(stages, application.components, all_project_envs, application.pipelines) : 'not supported in v3 yet.';
    // const yaml = ConvertPipelineObjtoYAML(stages, application.components, all_project_envs, application.pipelines) ;
    function downloadYaml() {
        var data = new Blob([yaml], { type: 'text' });
        var csvURL = window.URL.createObjectURL(data);
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', pipeline.name + '.yml');
        tempLink.click();
    }

    const onParseComplete = (file_data) => {
        const yaml = Base64.decode(file_data.content);

        var pipeline_services = GetServiceSpecificToPipeline(pipeline.components, application.components);
        // var pipeline_pipelines = GetPipelineSpecificToPipeline(pipeline.components, application.components);
        const result = ConvertPipelineYAMLtoObj(yaml, pipeline_services, all_project_envs);
        console.log(result, "jkfknjdsa")
        if (result) {

            updateStages(result.stages);
            if (result.errors && result.errors.length > 0) {
                onError(result.errors);
            }
        } else {
            onError("Invalid Yaml");
        }
    }


    function onError(error) {
        updateError(error);
    }

    function uploadYaml(e) {
        ParseFile(e, onParseComplete, onError)
    }
    const handleCloseDialog = () => {
        console.log(showDialog, "showDialogshowDialog")
        setShowDialog({ ...showDialog, open: false });
    }
    const saveStagesAndCloseDialog = () => {
        saveStages();
        handleCloseDialog();
        if (error_in_saving_data) {
        } else {
            if (state.selectedTabOrder === 2) {

                setState({ ...state, selectedTabOrder: 3 });
            }
            if (state.selectedTabOrder === 3) {
                setState({ ...state, selectedTabOrder: 2 });
            }
        }
    }
    const handleSaveClickYamlData = () => {
        saveStageViaYaml(editorValue);

    }
    console.log(state.stages, "stage_data_canvaas")
    return (
        <div className="pipeline-view"  >
            <HeadingSection>
                {/* <SquareAvatar name={pipeline.name} /> */}
                <div className="pipeline-data">
                    <div className='d-flex align-center'>
                        <p className="pipeline-name">{pipeline.name}</p>
                        <div className="" style={{ marginLeft: "3px" }}>
                            <button className="btn btn-link text-anchor-blue" onClick={handleBasicInfoFormOpen}>Edit Basic Info <BorderColorIcon fontSize="small" className="vertical-bottom" /></button>
                        </div>
                        {/* <IconButton onClick={handleBasicInfoFormOpen}>
                            <BorderColorIcon fontSize="" className="vertical-bottom text-anchor-blue" />
                        </IconButton> */}

                    </div>

                    <div className="data-frequency-retention">
                        <div className="text-box-pipeline-head">
                            <span className="main-text-dark">Trigger:</span>
                            <span className="main-text-lite">&nbsp;{trigger}</span>
                        </div>
                        {
                            pipeline.trigger != "MANUAL" ?
                                <div className="text-box-pipeline-head">
                                    <span className="main-text-dark">Frequency:</span>
                                    <span className="main-text-lite">&nbsp;{pipeline.cron_regex}</span>
                                </div> : null
                        }
                        <div className="text-box-pipeline-head">
                            <span className="main-text-dark">Retention:</span>
                            <span className="main-text-lite">&nbsp;{pipeline.retention_execution_count}</span>
                        </div>
                    </div>
                </div>
                {
                    active_service_list && active_service_list.length > 0 &&

                    <div className="pipeline-service-data">
                        <p className="main-text-dark mb-5">Services for the pipeline to run on</p>
                        <div className="service-name-chip-div">

                            <ExpandableServiceList list={active_service_list} />

                        </div>
                    </div>
                }
                {
                    pipeline.tags && pipeline.tags.length > 0 ?
                        <div className="pipeline-tags-data">
                            <p className="main-text-dark mb-5">Tags</p>
                            <div className="service-name-chip-div">
                                <ExpandableTagList list={pipeline.tags} />
                            </div>
                        </div> : null
                }
                <CommonHorizontalTab
                    tabList={panel_list}
                    variant="capsule"
                    selectedTabOrder={state.selectedTabOrder}
                    updateSelectedTab={updateSelectedTab}
                />
                <div className="d-flex align-center">
                    <>
                        <Tooltip title="download pipeline YAML">
                            <button onClick={downloadYaml} className="btn p-btn-icon">
                                <span class="material-icons material-symbols-outlined">
                                    download
                                </span>
                            </button>
                        </Tooltip>
                    </>
                    <>
                        <Tooltip title="upload pipeline YAML">
                            <label for="file-input" className="yaml-upload btn p-btn-icon">
                                <span className="material-icons material-symbols-outlined">
                                    upload
                                </span>

                                <input id="file-input" type="file" onChange={uploadYaml} />
                            </label>

                        </Tooltip>
                    </>

                    <div className="ml-auto">
                        <button
                            className="btn-save ml-3 btn-save-primary btn-save-lg d-flex align-center"
                            onClick={state.selectedTabOrder == 3 ? handleSaveClickYamlData : saveStages}>
                            <SaveIcon fontSize="" className="vertical-bottom" />&nbsp;Save Workflow</button>
                    </div>
                </div>
            </HeadingSection>
            {
                state.selectedTabOrder === 2 &&
                <div className="scrollable-view pd-20"
                >

                    {
                        stages.length > 0 || (state.stages && state.stages.length > 0) ?
                            <DNDArea
                                deleteJob={deleteJob}
                                version={version}
                                editJob={editJob}
                                editStage={editStage}
                                deleteStage={deleteStage}
                                updateStages={updateStages}
                                showAddStageForm={showAddStageForm}
                                showAddJobForm={showAddJobForm}
                                stages={state.stages}
                                services={services}
                                all_project_envs={all_project_envs} /> :
                            <>
                                <AddStage onClickHandler={showAddStageForm} />

                                <Input
                                    type="file"
                                    name="yaml"
                                    label="Upload yaml"
                                    onChangeHandler={uploadYaml}
                                    data={{}}
                                    error={{}} />
                            </>
                    }
                </div>
            }
            {
                state.selectedTabOrder === 3 &&
                <>
                    <PipelineUiViaYAML
                        stages={state.stages}
                        inherits={pipeline_yaml_data}
                        application={application}
                        getYamlData={getYamlData}
                        formDirtyFlag={formDirtyFlag}
                        all_project_envs={all_project_envs}
                        editorValue={editorValue}
                        onChange={handleEditorChange}
                    />
                </>

            }
            <SavePipelineFlowDialog
                handleClose={handleCloseDialog}
                open={showDialog.open}
                saveStages={saveStagesAndCloseDialog}
            />


        </div>
    );
}

export default GlobalPipelineCanvas;

const SavePipelineFlowDialog = (props) => {
    const handleClose = props.handleClose;
    const open = props.open;
    const saveStages = props.saveStages;
    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <div className="card ">
                <div className="card-body d-flex" style={{ height: 300 }}>
                    <div className="m-auto" style={{ width: '400px' }}>
                        <InfoIcon style={{ color: '#4783dc', fontSize: '60px' }} className="text-center mb-10 d-block m-auto" />
                        <div className="">

                            <p className="font-16 font-weight-bold text-center mb-5">
                                Please Note: A user can choose to setup pipeline using BP UI or can choose YAML editor.
                                Please Save the changes before Changing.
                            </p>


                        </div>
                    </div>
                </div>
                <div className="card-footer border-top pd-10 justify-end d-flex align-center">
                    <button className="btn btn-outline-dark-grey" onClick={handleClose}>
                        Cancel
                    </button>
                    <button className="btn btn-primary-v2" onClick={saveStages}>
                        Confirm
                    </button>
                </div>
            </div>
        </Dialog>
    )
}



const DNDArea = (props) => {
    const stages = props.stages;
    const showAddJobForm = props.showAddJobForm;
    const showAddStageForm = props.showAddStageForm;
    const deleteStage = props.deleteStage;
    const editStage = props.editStage;
    const editJob = props.editJob;
    const deleteJob = props.deleteJob;
    const updateStages = props.updateStages;
    const services = props.services;
    const version = props.version;
    const all_project_envs = props.all_project_envs;
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        if (result.type == "stage") {
            if (result.source.droppableId == result.destination.droppableId) {
                const stage_index = result.source.droppableId.split("-")[1];
                const stage = stages[stage_index];
                stage.task = stage.task ? stage.task : [];
                const new_tasks = reorder(stage.task, result.source.index, result.destination.index);
                stage.task = [...new_tasks];
                stages[stage_index] = stage;
                updateStages(stages);
            } else {
                const source_stage_index = result.source.droppableId.split("-")[1];
                const destination_stage_index = result.destination.droppableId.split("-")[1];

                const source_stage = stages[source_stage_index];
                const destination_stage = stages[destination_stage_index];


                const [removed] = source_stage.task.splice(result.source.index, 1);
                destination_stage.task = destination_stage.task ? destination_stage.task : [];
                destination_stage.task.splice(result.destination.index, 0, removed);
                updateStages(stages);
            }
        } else {
            const new_stages = reorder(stages, result.source.index, result.destination.index)
            updateStages(new_stages);
        }
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="pipeline" type="pipeline">
                {(provided) => (
                    <div ref={provided.innerRef} style={{ display: "flex" }} >
                        {
                            stages.map((stage, key) => (
                                <DraggableStageComponent editJob={editJob}
                                    deleteJob={deleteJob}
                                    editStage={editStage}
                                    deleteStage={deleteStage}
                                    stage={stage}
                                    index={key}
                                    key={key}
                                    showAddJobForm={showAddJobForm}
                                    services={services}
                                    all_project_envs={all_project_envs}
                                    version={version}
                                />
                            ))
                        }
                        <AddStage onClickHandler={showAddStageForm} />

                    </div>
                )}
            </Droppable>

        </DragDropContext>
    );
}

const AddJob = (props) => {
    const [open, setOpen] = useState(false);
    // const onClickHandler = () => setOpen(true);
    const handleClickClose = () => setOpen(false);
    const onClickHandler = props.onClickHandler;
    return (
        <>
            <div className="vertical-box" id={props.id}>
                <div className="pipeline-stage-card-border-left top-40"></div>
                <div className="parent-pipeline-box empty-box">
                    <button onClick={onClickHandler} className="btn d-block"><div className="flaticon-add-button-inside-black-circle"></div>
                        <div className="text-btn">Add New Job </div>
                    </button>
                </div>
            </div>
            <div class="pipeline-stage-card-border-vertical height-empty-box"></div>

        </>
    );
}

const AddStage = (props) => {
    const onClickHandler = props.onClickHandler;
    return (
        <div className="pipeline-stage-card-structure mr-0">
            <div className="pipeline-stage-card-head">
                <div className="pipeline-stage-card mr-0">
                    <div className="parent-pipeline-box empty-box">
                        <button onClick={onClickHandler} className="btn d-block"><div className="flaticon-add-button-inside-black-circle"></div>
                            <div className="text-btn">Add New Stage</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}


const StageHeading = (props) => {
    const stage = props.stage;
    const provided = props.provided;
    const showAddJobForm = props.showAddJobForm;
    const stage_index = props.stage_index;
    const deleteStage = props.deleteStage;
    const editStage = props.editStage;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    return (
        <>
            <div class="pipeline-stage-card-border-left top-26"></div>
            <div className="pipeline-stage-card-head">
                <div className="pipeline-stage-card">
                    <div className="parent-pipeline-box">
                        <div className="parent-pipeline-box-head-stage-name-card">
                            <p {...provided.dragHandleProps} className="task-type">
                                {stage.name}
                            </p>
                            <div className="more-option d-flex align-center">
                                {
                                    stage.stage_run_condition && Object.keys(stage.stage_run_condition).length > 0 ?
                                        <CallSplitIcon style={{ color: '#fff' }} className="fon-16" /> :
                                        <></>
                                }
                                <div className="if-Approval">
                                    <span>Approval: {stage.approval ? "Yes" : "No"}</span>
                                </div>
                                <span className="flaticon-show-more-button-with-three-dots" aria-controls="menu-stage-view" aria-haspopup="true" onClick={handleClick}></span>
                            </div>
                            <StageMenu editStage={editStage} deleteStage={deleteStage} stage_index={props.stage_index} showAddJobForm={showAddJobForm} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export const StageMenu = (props) => {
    const anchorEl = props.anchorEl;
    const setAnchorEl = props.setAnchorEl;
    const showAddJobForm = props.showAddJobForm;
    const stage_index = props.stage_index;
    const deleteStage = props.deleteStage;
    const editStage = props.editStage;
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Menu
            id="menu-stage-view"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className="menu-stage-view" >
            <MenuItem onClick={() => { editStage(stage_index); handleClose() }}><span className="flaticon-create-new-pencil-button"></span> Edit Stage</MenuItem>
            <MenuItem onClick={() => { showAddJobForm(stage_index); handleClose() }}><span className="flaticon-add-plus-button"></span> Add Job</MenuItem>
            <MenuItem onClick={() => { deleteStage(stage_index); handleClose() }}><span className="flaticon-rubbish-bin-delete-button"></span> Delete Stage</MenuItem>
            <MenuItem onClick={() => { deleteStage(stage_index, true); handleClose() }}><span className="flaticon-rubbish-bin-delete-button"></span> Delete All Jobs</MenuItem>
        </Menu>
    );
}

export const JobMenu = (props) => {
    const anchorEl = props.anchorEl;
    const setAnchorEl = props.setAnchorEl;
    const editJob = props.editJob;
    const task_type = props.task_type;
    const deleteJob = props.deleteJob;
    const stage_index = props.stage_index;
    const job_index = Number(props.job_index);
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Menu
            id="menu-stage-view"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className="menu-stage-view" >
            {task_type == 8 || task_type == 9 || task_type == "Env Down" || task_type == "Env Up" ? <MenuItem>Edit Not Available</MenuItem> : <MenuItem onClick={() => { editJob(stage_index, job_index); handleClose() }}><span className="flaticon-create-new-pencil-button"></span> Edit Job</MenuItem>}
            <MenuItem onClick={() => { deleteJob(stage_index, job_index); handleClose() }}><span className="flaticon-rubbish-bin-delete-button"></span> Delete Job</MenuItem>
        </Menu>
    );
}

const DraggableStageComponent = (props) => {
    const stage = props.stage;
    const key = props.index;
    const showAddJobForm = props.showAddJobForm;
    const deleteStage = props.deleteStage;
    const editStage = props.editStage;
    const editJob = props.editJob;
    const deleteJob = props.deleteJob;
    const services = props.services;
    const version = props.version;
    const all_project_envs = props.all_project_envs;
    return (
        <Draggable key={"stage-" + stage.name} draggableId={"stage-" + stage.name} index={key}>
            {(provided) => (
                <>
                    <div className="pipeline-stage-card-structure" ref={provided.innerRef} {...provided.draggableProps} >
                        <StageHeading editStage={editStage} deleteStage={deleteStage} stage_index={key} showAddJobForm={showAddJobForm} stage={stage} provided={provided} />

                        <div>

                            <Droppable droppableId={"stage-" + key} type="stage">

                                {(provided) => (
                                    <div ref={provided.innerRef}>
                                        {
                                            stage.task && stage.task.length > 0 ?

                                                stage.task.map((element, key2) => (
                                                    <DraggableJobComponent
                                                        editJob={editJob}
                                                        deleteJob={deleteJob}
                                                        stage_index={key}
                                                        job_index={key2}
                                                        job={element}
                                                        services={services}
                                                        all_project_envs={all_project_envs}
                                                        version={version}
                                                    />
                                                ))

                                                : <div style={{ height: "1vh" }}></div>
                                        }
                                    </div>
                                )}

                            </Droppable>

                            <AddJob onClickHandler={() => { showAddJobForm(key, stage.name) }} id={"stage-" + key} />

                        </div>
                    </div>
                    <div className="circular-arrow">
                        <span className="flaticon-right-arrow-forward"></span>
                    </div>
                </>
            )}
        </Draggable>
    );
}

const DraggableJobComponent = (props) => {
    const key = props.stage_index;
    const key2 = props.job_index;
    const job = props.job;
    const services = props.services;
    const editJob = props.editJob;
    const deleteJob = props.deleteJob;
    var task_type = job.task_type ? job.task_type : job.task_type_id;
    const [state, setState] = React.useState({ all_project_envs: [] })
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const all_project_envs = props.all_project_envs
    const version = props.version;
    const isDependentJob = job?.job_json?.is_dependent
    console.log(job, "drag_component_props")
    // let version = 'v2'
    // const v = props.version;
    // if (job.project_job_template) {
    //     version = job.project_job_template.version
    // }
    // else
    //     version = job.job_template_version === 'v3' ? 'v3' : 'v2'

    // if (version !== 'v3')
    //     task_type = task_type?.id ? task_type?.id : task_type;
    useEffect(() => {
        if (job.project_id) {
            // fetchApplicationEnvironment(job.project_id)
        }

    }, [job.project_id])
    function fetchApplicationEnvironment(id) {
        var requestInfo = {
            endPoint: GenerateURL({ application_id: id }, properties.api.env_listing + "?all=true"),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, AppEnvironmentFetchSuccess, AppEnvironmentFetchFailure);
        setLoading(true)
    }

    function AppEnvironmentFetchSuccess(data) {
        setState(new_state => ({
            ...new_state,
            all_project_envs: data.results,
        }));
        setLoading(false)
    }
    function AppEnvironmentFetchFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            error: true
        }));
        setLoading(false)
    }

    console.log(job, version, 'kdffff');
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    return (
        <>
            {
                version === 'v3' ?
                    <Draggable key={"stage-" + key + "task-" + key2} draggableId={"stage-" + key + "task-" + key2} index={key2}>
                        {(provided, snapshot) => (
                            <div className="vertical-box" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>

                                {
                                    !snapshot.isDragging ?
                                        <div className="pipeline-stage-card-border-left"></div> : null
                                }
                                <div className="parent-pipeline-box">
                                    <div className="parent-pipeline-box-head bg-light-purple">
                                        <div className="card-head-content">
                                            <span>Job Type:</span><span>&nbsp;{job.task_type ? job.task_type : job.name ? job.name : 'NA'}</span>
                                            {
                                                getSelectedAppEnv(job.env, state.all_project_envs) &&
                                                <span className="tag-under-card">{getSelectedAppEnv(job.env, state.all_project_envs).name}</span>
                                            }

                                        </div>
                                        <div className="d-flex align-center">
                                            {
                                                job.task_run_condition ?
                                                    <CallSplitIcon style={{ color: '#fff' }} className="fon-16" /> :
                                                    <></>
                                            }
                                            <div className="more-option">
                                                <span className="flaticon-show-more-button-with-three-dots" aria-controls="menu-job-canvasview" aria-haspopup="true" onClick={handleClick}></span>
                                            </div>


                                            <JobMenu task_type={task_type} editJob={editJob} deleteJob={deleteJob} stage_index={key} job_index={key2} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
                                        </div>
                                    </div>

                                </div>

                                <div className="parent-pipeline-box-body bg-dark-purple">
                                    <div className="tag-under-card bg-transparent m-0 ">
                                        <p className="task-name-pipeline line-height-1">Version: <span className="text-white">v3
                                        </span></p>
                                    </div>
                                    <div className="service-to-run-on">
                                        <span>Service to run on:</span>
                                        {
                                            isDependentJob ?
                                                <spna className="tag-under-card">{job.components.length}</spna>
                                                :
                                                <spna className="tag-under-card-non-dependent-job">Independent Job</spna>

                                        }
                                    </div>
                                </div>


                            </div>
                        )}
                    </Draggable>
                    :
                    <Draggable key={"stage-" + key + "task-" + key2} draggableId={"stage-" + key + "task-" + key2} index={key2}>
                        {(provided, snapshot) => (
                            <div className="vertical-box" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>

                                {
                                    !snapshot.isDragging ?
                                        <div className="pipeline-stage-card-border-left"></div> : null
                                }


                                <div className="parent-pipeline-box">
                                    <div className="parent-pipeline-box-head bg-light-purple">
                                        <div className="card-head-content">
                                            <span>Job Type:</span><span>&nbsp;{task_type == "1" ? "Build" : task_type == "2" ? "Deploy" : task_type == "12" ? "Integration" : task_type == "5" ? "API Call" : task_type == "10" ? "Jira Job" : task_type == "11" ? "Rollback" : task_type == "9" ? "Env Down" : task_type == "8" ? "Env Up" : task_type == "13" ? "Trigger Pipeline" : task_type == "6" ? "Configmaps" : task_type == "7" ? "Deploy Secrets" : "Promote"}</span>
                                            {console.log(job,all_project_envs, "jobjob")}
                                            {
                                                task_type != "5" || task_type != 10 ?
                                                    job.project_env && job.project_env.name ?
                                                        <span className="tag-under-card">{job.project_env.name}</span>
                                                        : null
                                                    : null
                                            }

                                            {/* {
                                                task_type == "3" ?
                                                    <>
                                                        <span className="flaticon-right-arrow-forward" style={{ color: '#fff' }}></span>

                                                        <span className="tag-under-card">{job.target_project_env && job.target_project_env.name ? job.target_project_env.name : "N/A"}</span>
                                                    </>
                                                    : null
                                            } */}

                                        </div>
                                        <div className="d-flex align-center">
                                            {
                                                job.task_run_condition && Object.keys(job.task_run_condition).length > 0 ?
                                                    <CallSplitIcon style={{ color: '#fff' }} className="fon-16" /> :
                                                    <></>
                                            }
                                            <div className="more-option">
                                                <span className="flaticon-show-more-button-with-three-dots" aria-controls="menu-job-canvasview" aria-haspopup="true" onClick={handleClick}></span>
                                            </div>


                                            <JobMenu task_type={task_type} editJob={editJob} deleteJob={deleteJob} stage_index={key} job_index={key2} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
                                        </div>
                                    </div>
                                    <div className="parent-pipeline-box-body bg-dark-purple">
                                        {task_type == "1" ?
                                            <>

                                                <p className="task-name-pipeline line-height-1 mb-5">Image-tag: <span className="text-white">{job.tag ? job.tag : "N/A"}
                                                </span></p>
                                                {/* <div className="tag-under-card bg-transparent m-0 ">
                                                   job.selected_project_env.project
                                                   
                                                </div> */}

                                                <p className="task-name-pipeline line-height-1">App Name: <span className="text-white">{
                                                    job.project_env && job.project_env.project ?
                                                        job.project_env.project.name :
                                                        job.selected_project_env && job.selected_project_env.project ?
                                                            job.selected_project_env.project.name
                                                            : "N/A"}
                                                </span></p>
                                                <div className="service-to-run-on">
                                                    <span>Service to run on:</span>
                                                    <spna className="tag-under-card">{job.components.length}</spna>
                                                </div>
                                            </>
                                            : <></>}
                                        {task_type == "2" ?
                                            <>
                                                {/* <div className="tag-under-card bg-transparent m-0 "> */}
                                                <p className="task-name-pipeline line-height-1">Input Artifact : <span className="text-white">
                                                    {console.log(job.artifact_source, "artifact_source")}
                                                    {job.artifact_source ? job.artifact_source.toLowerCase() : "N/A"}
                                                </span></p>
                                                <p className="task-name-pipeline line-height-1">App Name: <span className="text-white">{
                                                    job.project_env && job.project_env.project ?
                                                        job.project_env.project.name :
                                                        job.selected_project_env && job.selected_project_env.project ?
                                                            job.selected_project_env.project.name
                                                            : "N/A"}
                                                </span></p>
                                                {/* </div> */}
                                                <div className="service-to-run-on">
                                                    <span>Service to run on:</span>
                                                    <spna className="tag-under-card">{job.components.length}</spna>
                                                </div>
                                            </>
                                            : <></>}

                                        {task_type == "3" ?
                                            <>
                                                <div className='d-flex align-center mb-8'>
                                                    <span className="tag-under-card">{job.project_env.name}</span>
                                                    <span className="flaticon-right-arrow-forward" style={{ color: '#fff' }}></span>
                                                    <span className="tag-under-card">{job.target_project_env.name}</span>
                                                </div>
                                                {/* <div className="tag-under-card bg-transparent m-0 "> */}
                                                <p className="task-name-pipeline line-height-1">Input Artifact : <span className="text-white">
                                                    {job.artifact_source ? job.artifact_source != "latest" && job.artifact_source != "generated" ? job.tag :
                                                        job.artifact_source.toLowerCase() : "N/A"
                                                    }
                                                </span></p>
                                                <p className="task-name-pipeline line-height-1">App Name: <span className="text-white">{
                                                    job.project_env && job.project_env.project ?
                                                        job.project_env.project.name :
                                                        job.selected_project_env && job.selected_project_env.project ?
                                                            job.selected_project_env.project.name
                                                            : "N/A"}
                                                </span></p>
                                                {/* </div> */}
                                                <div className="service-to-run-on">
                                                    <span>Service to run on:</span>
                                                    <spna className="tag-under-card">{job.components.length}</spna>
                                                </div>
                                            </> : <></>}


                                        {task_type == "5" ?
                                            <>
                                                <div className="tag-under-card bg-transparent m-0 ">
                                                    <p className="task-name-pipeline line-height-1">Method : <span className="text-white">
                                                        {job.method}
                                                    </span></p>
                                                </div>

                                                <div className="tag-under-card bg-transparent m-0 ">
                                                    <p className="task-name-pipeline line-height-1">Suspend Pipeline : <span className="text-white">
                                                        {job.pipeline_suspend ? "Yes" : "No"}
                                                    </span></p>
                                                </div>

                                                <div className="tag-under-card bg-transparent m-0 ">
                                                    <p className="task-name-pipeline line-height-1">URL : <span className="text-white">
                                                        {job.url}
                                                    </span></p>
                                                </div>

                                            </> : <></>}
                                        {
                                            task_type == "11" &&
                                            <>
                                                {/* <div className="tag-under-card bg-transparent m-0 "> */}
                                                <p className="task-name-pipeline line-height-1">Verison: &nbsp;
                                                    <span className="text-white">
                                                        {job.rollback_version ? job.rollback_version : "N/A"}
                                                    </span>
                                                </p>
                                                <p className="task-name-pipeline line-height-1">App Name: <span className="text-white">{
                                                    job.project_env && job.project_env.project ?
                                                        job.project_env.project.name :
                                                        job.selected_project_env && job.selected_project_env.project ?
                                                            job.selected_project_env.project.name
                                                            : "N/A"}
                                                </span></p>
                                                {/* </div> */}
                                                <div className="service-to-run-on">
                                                    <span>Service to run on:</span>
                                                    <spna className="tag-under-card">{job.components.length}</spna>
                                                </div>
                                            </>
                                        }
                                        {
                                            task_type == "12" &&
                                            <>
                                                <p className="task-name-pipeline line-height-1">App Name: <span className="text-white">{
                                                    job.project_env && job.project_env.project ?
                                                        job.project_env.project.name :
                                                        job.selected_project_env && job.selected_project_env.project ?
                                                            job.selected_project_env.project.name
                                                            : "N/A"}
                                                </span></p>
                                                <div className="service-to-run-on">
                                                    <span>Service to run on:</span>
                                                    <spna className="tag-under-card">{job.components.length}</spna>
                                                </div>
                                            </>
                                        }
                                        {
                                            task_type == "13" &&
                                            <>
                                                <div className="tag-under-card bg-transparent m-0 ">
                                                    <p className="task-name-pipeline line-height-1">Suspend Pipeline: &nbsp;
                                                        <span className="text-white">
                                                            {job.suspend_pipeline ? "True" : "False"}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="service-to-run-on">
                                                    <span>Pipelines to run on:</span>
                                                    <spna className="tag-under-card">{job.pipelines.length}</spna>
                                                </div>
                                            </>
                                        }
                                        {task_type == "10" ?
                                            <>
                                                <div className="tag-under-card bg-transparent m-0 ">
                                                    <p className="task-name-pipeline line-height-1">Jira Operation Type: <span className="text-white">
                                                        {job.operation == "create" ? "Create Ticket" : job.operation == "status_update" ? "Change Status" : job.operation == "add_comment" ? "Add Comment" : job.operation == "set_service"? "Set Service" : job.operation == "create_release" ? "Create Release" : job.operation == "check_jira_ids" ? "Check Jira Ids" : job.operation == "check_conflicts"? "Check Merge Conflicts" : job.operation == "create_pr"? "Create PR" : "Create Subtask"}
                                                    </span></p>
                                                </div>
                                                {
                                                    job.operation == "create" ?
                                                        <div className="tag-under-card bg-transparent m-0 ">
                                                            <p className="task-name-pipeline line-height-1">Issue Type: &nbsp;
                                                                <span className="text-white">
                                                                    {job.issuetype ? job.issuetype : "N/A"}
                                                                </span>
                                                            </p>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    job.operation == "create" ?
                                                        <div className="tag-under-card bg-transparent m-0 ">
                                                            <p className="task-name-pipeline line-height-1">Priority: &nbsp;
                                                                <span className="text-white">
                                                                    {job.priority ? job.priority : "N/A"}
                                                                </span>
                                                            </p>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    job.operation == "status_update" ?
                                                        <>
                                                            <div className="tag-under-card bg-transparent m-0 ">
                                                                <p className="task-name-pipeline line-height-1">{job.jira_key == "primary_key" ? "Primary Key" : "Issue Key"}: &nbsp;
                                                                    <span className="text-white">
                                                                        {job.issue_key ? job.issue_key : job.jira_key == "primary_key" ? job.jira_key : "N/A"}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                            <div className="tag-under-card bg-transparent m-0 ">
                                                                <p className="task-name-pipeline line-height-1">Status Changes to: &nbsp;
                                                                    <span className="text-white">
                                                                        {job.status ? job.status : "N/A"}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </>
                                                        : null
                                                }
                                                {
                                                    job.operation == "add_comment" ?
                                                        <>
                                                            <div className="tag-under-card bg-transparent m-0 ">
                                                                <p className="task-name-pipeline line-height-1">Issue Key: &nbsp;
                                                                    <span className="text-white">
                                                                        {job.issue_key ? job.issue_key : "N/A"}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                            <div className="tag-under-card bg-transparent m-0 ">
                                                                <p className="task-name-pipeline line-height-1">Comment: &nbsp;
                                                                    <span className="text-white text-ellipsis-200">
                                                                        {job.comment ? job.comment : "N/A"}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </>
                                                        : null
                                                }
                                                {
                                                    job.operation == "create_subtask" ?
                                                        <div className="tag-under-card bg-transparent m-0 ">
                                                            <p className="task-name-pipeline line-height-1">Parent JIRA Id: &nbsp;
                                                                <span className="text-white text-ellipsis-200">
                                                                    {job.parent_jira_id ? job.parent_jira_id : "N/A"}
                                                                </span>
                                                            </p>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    job.operation == "create_release" ?
                                                    <div className="tag-under-card bg-transparent m-0 ">
                                                        <p className="task-name-pipeline line-height-1">Release Name: &nbsp;
                                                            <span className="text-white">
                                                                {job.release_name ? job.release_name : moment().format('DD-MM-YYYY')}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    : null
                                                }
                                                {
                                                    job.operation == "check_jira_ids" ?
                                                    <div className="tag-under-card bg-transparent m-0 ">
                                                        <p className="task-name-pipeline line-height-1">Comment Validator: &nbsp;
                                                            <span className="text-white">
                                                                {job.comment_validator ? job.comment_validator : "N/A"}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    : null
                                                }
                                                {
                                                    job.operation == "check_conflicts" ?
                                                    <div className="tag-under-card bg-transparent m-0 ">
                                                        <p className="task-name-pipeline line-height-1">Destination Branch: &nbsp;
                                                            <span className="text-white">
                                                                {job.destination_branch ? job.destination_branch : "N/A"}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    : null
                                                }
                                                {
                                                    job.operation == "create_pr" ?
                                                    <div className="tag-under-card bg-transparent m-0 ">
                                                        <p className="task-name-pipeline line-height-1">Provider: &nbsp;
                                                            <span className="text-white">
                                                                {job.provider ? job.provider : "N/A"}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    : null   
                                                }
                                            </> : <></>}
                                        {task_type == "6" ?
                                            <>
                                                <p className="task-name-pipeline line-height-1">App Name: <span className="text-white">{
                                                    job.project_env && job.project_env.project ?
                                                        job.project_env.project.name :
                                                        job.selected_project_env && job.selected_project_env.project ?
                                                            job.selected_project_env.project.name
                                                            : "N/A"}
                                                </span></p>
                                                <div className="service-to-run-on">
                                                    <span>Configmaps to run:</span>
                                                    <spna className="tag-under-card">{job.env_namespace_configmap &&
                                                        job.env_namespace_configmap.length > 0 ? job.env_namespace_configmap.length : "N/A"}</spna>
                                                </div>
                                            </>
                                            : <></>}
                                        {task_type == "7" ?
                                            <>
                                                <p className="task-name-pipeline line-height-1">App Name: <span className="text-white">{
                                                    job.project_env && job.project_env.project ?
                                                        job.project_env.project.name :
                                                        job.selected_project_env && job.selected_project_env.project ?
                                                            job.selected_project_env.project.name
                                                            : "N/A"}
                                                </span></p>
                                                <div className="service-to-run-on">
                                                    <span>Secrets to run:</span>
                                                    <spna className="tag-under-card">{job.env_namespace_secret && job.env_namespace_secret.length > 0 ? job.env_namespace_secret.length : "N/A"}</spna>
                                                </div>
                                            </>
                                            : <></>}

                                            {task_type == "16" ? 
                                                <>
                                                    <div className="tag-under-card bg-transparent m-0 ">
                                                        <p className="task-name-pipeline line-height-1">Snow Operation Type: <span className="text-white">
                                                            {job.snow_operation == "snow_create" ? "Create Ticket" : job.snow_operation == "snow_update" ? "Update Ticket State" : job.snow_operation == "snow_add_notes" ? "Add Notes" : "Snow Job"}
                                                        </span></p>
                                                    </div>
                                                    {
                                                                job.snow_operation == "snow_create" ?
                                                                    <div className="tag-under-card bg-transparent m-0 "><p className="task-name-pipeline line-height-1">Ticket Type: &nbsp;<span className="text-white">{job.snow_ticket_type ? job.snow_ticket_type : "N/A"}</span></p></div>
                                                                    : null
                                                            }
                                                    {
                                                        job.snow_operation == "snow_update" ?
                                                            <div className="tag-under-card bg-transparent m-0 "><p className="task-name-pipeline line-height-1">State: &nbsp;<span className="text-white">{job.change_state ? getSnowTicketState(job.change_state) : "N/A"}</span></p></div>
                                                        : null
                                                    }
                                                    {
                                                        job.snow_operation == "snow_add_notes" ?
                                                            <div className="tag-under-card bg-transparent m-0 "><p className="task-name-pipeline line-height-1">Note: &nbsp;<span className="text-white">{job.work_note ? job.work_note : "N/A"}</span></p></div>
                                                        :null
                                                    }
                                                </>

                                                : null
                                            }
                                            {task_type == "17" ? 
                                                <>
                                                    {job.attach_operation == "download_documents" &&
                                                        <>
                                                        <div className="tag-under-card bg-transparent m-0 "><p className="task-name-pipeline line-height-1">Operation: &nbsp;<span className="text-white">Download Documents</span></p></div>
                                                        <div className="tag-under-card bg-transparent m-0 "><p className="task-name-pipeline line-height-1">Ticketing Type: &nbsp;<span className="text-white">{job?.integration_type || "NA"}</span></p></div>
                                                        </>
                                                    }

                                                    {job.attach_operation == "upload_documents" &&
                                                        <>
                                                        <div className="tag-under-card bg-transparent m-0 "><p className="task-name-pipeline line-height-1">Operation: &nbsp;<span className="text-white">Upload Documents</span></p></div>
                                                        <div className="tag-under-card bg-transparent m-0 "><p className="task-name-pipeline line-height-1">Ticketing Type: &nbsp;<span className="text-white">{job?.integration_type || "NA"}</span></p></div>
                                                        </>
                                                    }
                                                    {job.attach_operation == "upload_release" &&
                                                        <>
                                                        <div className="tag-under-card bg-transparent m-0 "><p className="task-name-pipeline line-height-1">Operation: &nbsp;<span className="text-white">Upload Release Notes</span></p></div>
                                                        <div className="tag-under-card bg-transparent m-0 "><p className="task-name-pipeline line-height-1">Ticketing Type: &nbsp;<span className="text-white">{job?.integration_type || "NA"}</span></p></div>
                                                        </>
                                                    }
                                                </>        
                                            : null
                                            }
                                    </div>
                                </div>
                            </div>
                        )}
                    </Draggable>
            }

        </>

    );
}

function getSelectedAppEnv(env_id, app_environment_list) {
    console.log(env_id,app_environment_list,"sbhjfbdsbvhbf")
    var selectedAppEnv = null
    app_environment_list.forEach(app_environment => {
        console.log(env_id, app_environment_list, "getSelectedAppEnv")
        if (app_environment.id == Number(env_id)) {
            selectedAppEnv = app_environment
        }
    })
    console.log(selectedAppEnv,"hgbcdgsghcghd")
    return selectedAppEnv
}



