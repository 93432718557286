import React from 'react';
import PropTypes from 'prop-types';
import properties from '../../../../properties/properties';
import GenerateURL from '../../../../util/APIUrlProvider';

const SSEStatusHeader=(props)=>{
    var event_source = props.event_source
    var refresh_page_header = props.refresh_page_header
    var setEventSource = props.setEventSource
    var component_id = props.component_id ? props.component_id : null;
    var env_id = props.env_id ? props.env_id : null;
    var build_id = props.build_id ? props.build_id : null;
    var deploy_id = props.deploy_id ? props.deploy_id : null;
    var promote_id = props.promote_id ? props.promote_id : null;
    function handle_sub_task(e){
        refresh_page_header(e)
    }

    function handle_task_completed(e) {
        e.srcElement.close()
    }

    if(!event_source){
        if(build_id){
            var url = GenerateURL({component_id: component_id, env_id: env_id, build_id: build_id}, properties.api.sse_build_status);
        }
        if(deploy_id){
            var url = GenerateURL({component_id: component_id, env_id: env_id, deploy_id: deploy_id}, properties.api.sse_deploy_status);
        }
        if(promote_id){
            var url = GenerateURL({component_id: component_id, env_id: env_id, promote_id: promote_id}, properties.api.sse_promote_status);
        }
        if(!event_source){
            event_source = new EventSource(url)
        }
        event_source.addEventListener("ACTIVITY_STATUS", handle_sub_task)
        event_source.addEventListener("ACTIVITY_STATUS_CLOSE", handle_task_completed)
        setEventSource(event_source)
    }
    return(<></>)
}

SSEStatusHeader.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default  SSEStatusHeader