import React, { useState }  from 'react'
import PropTypes from 'prop-types';
import CanaryTemplate from '../../../views/service/detail/components/CanaryTemplate';
import { Input } from '../Input';
import { getCommonFunctions } from '../../../views/serviceRevamp/add/ci_flow/SourceDetails';
function CanaryDropdown(props) {
    const inherits = props.inherits? props.inherits : {};
    const prev_state = props.prev_state ? props.prev_state : null;
    const [state, setState] = useState(prev_state ? prev_state : getCanaryTemplateDefaultState());
    const commonFunctions = getCommonFunctions(state, setState, inherits)
   
    return (
    
        <div className="card box-shadow-none" style={{margin: 10}}>
            <Input
            type="switch"
            label="Canary Template"
            name="enable_canary"
            data={state.data}
            error={state.error}
            onChangeHandler={commonFunctions.onChangeHandler}
            />
            {
              state.data.enable_canary&&
              <div className="card-body">
                <CanaryTemplate 
                prev_state={state.canary_template} 
                variant = "list_only"
                inherits={state.child_inherits.canary_template}
                />
            </div>
            }
            
        </div>
        
    )
}

CanaryDropdown.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default CanaryDropdown


export function getCanaryTemplateDefaultState(){
    return {
            data:{
                enable_canary: false
            },
            error:{},
            validations:{},
            child_inherits:{
                canary_template:{
                    "validateForm": () => { return true },
                    "getState": () => { return {} },
                    "getData": () => { return {} }
                }
            },
        }

}