import React from 'react'

const NewReleaseStrip = () => {
  return (
    <>
       {/* <a href="https://www.buildpiper.io/release-notes/" target="_blank" style={{color : "white"}} className="badge badge-success fade-in-out">Release Features</a> */}
    </>
        
  )
}

export default NewReleaseStrip;