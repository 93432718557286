import React from 'react';
import SquareAvatar from '../../../../components/genericComponents/AvatarSquare';
import { ExpandableServiceList, ExpandableTagList } from '../../../../components/hoc/expandableList';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import { FormatTime } from '../../../../util/util';
import { getStageObjectWithIds } from './ExecutionCard'


const Header = props => {
    const classes = useStyles();
    const pipeline = props.pipeline;
    const pipeline_instance = props.pipeline_instance;
    if (pipeline.last_trigger && pipeline.last_trigger.components) {
        updatePartOfExecutionFlagInComponents(
            pipeline.components,
            pipeline.last_trigger.components
        );
    }

    function updatePartOfExecutionFlagInComponents(
        all_components,
        run_components
    ) {
        var components_map = {};
        if (all_components) {
            all_components.forEach((component) => {
                components_map[component.name] = component;
                //This line is required for pipeline history page
                component.part_of_execution = false;
            });

            if (run_components) {
                run_components.forEach((component) => {
                    if (components_map[component.name]) {
                        components_map[component.name].part_of_execution = true;
                    }
                });
            }
        }
    }
    const services = getServiceList(bringParticipatinServiceToTop(pipeline.components));
    function bringParticipatinServiceToTop(components) {
        var participating_services = []
        var non_participating_services = []
        if (components) {
            components.forEach(component => {
                if (component.part_of_execution) {
                    participating_services.push(component)
                } else {
                    non_participating_services.push(component)
                }
            })
        }
        participating_services = [...participating_services, ...non_participating_services]

        return participating_services
    }
    const tags = getTagList(pipeline.tags);
    const application_id = props.application_id;
    const stage_complete_status = pipeline_instance.stage_instance.length + "/" + pipeline.stages.length
    const start_time = new Date(pipeline_instance.created_at);
    const end_time = new Date(pipeline_instance.updated_at);
    const difference = end_time - start_time;
    const time_taken = FormatTime(difference);
    const trigger_by = pipeline_instance.trigger_by;
    const trigger_type = pipeline_instance.trigger_type.name;
    const stage_object_with_ids = getStageObjectWithIds(pipeline.stages, pipeline_instance.stage_instance);

    return (
        <>
            <SquareAvatar
                varient="double"
                name={pipeline.name}
            />
            <div className="text-icon-section">
                <div className="service-name">
                    {/* {
                    Object.keys(stage_object_with_ids).map((key,index)=>(
                        <>
                                {
                                    stage_object_with_ids[key].tasks.map(task=>(
                                        task.task_type ? 
                                            task.task_type.id=="8" || task.task_type.id=="9"?
                                            <p>{pipeline.name}</p>:
                                            <></>:
                                    ))
                                }
                        </>
                    ))
                } */}
                    <p>{pipeline.name}</p>
                </div>
                <div className="service-details">
                    <div className="owner-name">
                        <span className="color-key-78">Trigger:&nbsp;</span>  {/*<span className="mb-3 text-lite-purple pd-r-5 text-black flaticon-button-on"></span> */}
                        <span className="sub-text sub-text-margin color-value pd-r-5">{pipeline.trigger_type.name}</span>
                        {
                            pipeline.cron_regex ?
                                <>
                                    <span class="color-key-78">Frequency:&nbsp;</span>   {/*<GraphicEqIcon style={{color:"#5f6e9e", marginRight:'5px'}} /> */}
                                    <span className="sub-text sub-text-margin color-value  pd-r-5">{pipeline.cron_regex}</span>
                                </> : null
                        }


                        <span className="color-key-78">Retention:&nbsp;</span>  {/* <span className="mb-3 text-lite-purple flaticon-round-data-usage-symbol pd-r-5"></span> */}
                        <span className="sub-text sub-text-margin color-value pd-r-5">{pipeline.retention_execution_count} </span>
                        <span className="main-text color-key-78">Last Run:&nbsp;</span>  {/* <span className="mb-3 pd-r-5 text-lite-purple flaticon-clock-with-white-face"></span> */}
                        <span className="sub-text sub-text-margin color-value pd-r-5">{start_time.toString().substring(0, start_time.toString().indexOf("GMT"))}</span>
                        <span className="main-text color-key-78">Duration:</span>  {/* <span className="mb-3 pd-r-5 text-lite-purple flaticon-history-clock-button"></span> */}
                        <span className="sub-text sub-text-margin color-value pd-rl-5px">{time_taken}</span>
                    </div>
                </div>
            </div>
            {services && services.length > 0 ?
                <div className="pipeline-service-data" style={{ alignSelf: 'center' }}>
                    <p className="main-text-dark mb-5">Services for the pipeline to run on:</p>
                    <div className="service-name-chip-div">
                        <ExpandableServiceList iteration_count="2" list={services} />
                    </div>
                </div>
                :
                <></>
            }

            <div className="pipeline-tags-data" style={{ alignSelf: 'center' }}>
                {tags > 0 ?
                    <>
                        <p className="main-text-dark mb-5">Tags</p>
                        <div className="service-name-chip-div">
                            <ExpandableTagList list={tags} />
                        </div>
                    </>
                    : null}
            </div>
            {/*<div>
            <div className={classes.stageData}>
                <span className="main-text">Stages: </span>
                <span className="sub-text">{stage_complete_status + " complete"}</span>
                {
                    pipeline_instance.status == "FAILED" ? 
                    <span className="icon-with-text"><ErrorOutlineIcon style={{fontSize:'18px', marginRight:'.5rem', fill:'#ff6238'}} /> FAILED</span>:
                    <span className="success status-font"><span className="flaticon-circle-with-check-symbol"></span> {pipeline_instance.status} </span>
                }
            </div>
            <div className={classes.executionData}>
                <span className="main-text">Last Run: </span>
                <span className="sub-text">&nbsp; {start_time.toString().substring(0, start_time.toString().indexOf("GMT"))} &nbsp;</span>
                <span className="main-text">for</span>
                <span className="sub-text">&nbsp; {time_taken} &nbsp;</span>
            </div>
            <div className={classes.executionData}>
            <span className="main-text">by</span>
            <span className="sub-text">&nbsp; {trigger_by + " ( "+ trigger_type +" )"}  &nbsp;</span>
            </div>
            </div>*/}

        </>
    )
}

export default Header;


function getServiceList(services) {
    var service_list = [];
    services.forEach(service => {
        service_list.push({ name: service.name, part_of_execution: service.part_of_execution ? true : false });
    });
    return service_list;
}

function getTagList(tags) {
    var tag_list = [];
    tags.forEach(tag => {
        tag_list.push(tag.tag);
    });
    return tag_list;
}

const useStyles = makeStyles(theme => ({
    stageData: {
        display: 'flex',
        alignItems: 'center',
        lineHeight: 1.5,

        '& .main-text': {
            color: '#000',
            fontSize: '13px',
            marginRight: '0px'
        },
        '& .sub-text': {
            color: '#949494',
            fontSize: '13px',
        },
        '& .status-font': {
            fontSize: '12px',
            textTransform: 'uppercase'
        },
        '& .success': {
            color: '#62E187'
        },
        '& .success .flaticon-circle-with-check-symbol': {
            margin: '0px 4px',
            color: '#62E187'
        },
        '& .success .flaticon-circle-with-check-symbol:before': {
            fontSize: '14px!important',
            color: '#62E187'
        }
    },
    executionData: {
        display: 'flex',
        alignItems: 'center',
        lineHeight: 1,
        '& .main-text': {
            color: '#000',
            fontSize: '12px',
            marginRight: '0px'
        },
        '& .sub-text': {
            color: '#949494',
            fontSize: '12px'
        }
    },
    d_Flex: {
        display: 'flex',
        alignItems: 'center',
    }
}))
