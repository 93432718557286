import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import properties from '../../../properties/properties';
import GenerateURL, { GenerateSearchURL } from '../../../util/APIUrlProvider';
import InvokeApi from '../../../util/apiInvoker';

import ConditionalStepCardDetail from './ConditionalStepCardDetail'
import NonConditionStepCard from './NonConditionalStepCard'

const StepDetailShow = (props) => {

    const { stepData } = props
    const [state, setState] = useState()
    const [stepDetails, setStepDetails] = useState()
    const [comineEnvVariables, setCombineEnvVariables] = useState(null)
    const [conditionObject, setConditionObject] = useState({})


    useEffect(() => {

    }, [stepData])

    useEffect(() => {
        stepData && !stepData.is_conditional_step && getSteByStepCode(stepData.step_code, stepData.version)

        stepData && stepData.is_conditional_step && setDataForConditionStepView(stepData)
    }, [stepData])


    useEffect(() => {
        stepData && !stepData.is_conditional_step && stepDetails && setCombineEnvVaribalesAccordingToVersion(stepData.environment_variables, stepDetails.environment_variables, stepDetails.version, setCombineEnvVariables)
    }, [stepData, stepDetails])

    function getSteByStepCode(step_code, version) {

        let myendpoint = GenerateURL({}, properties.api.stepCatalogs)

        let requestInfo = {
            endPoint: GenerateSearchURL({ step_code: step_code, version: version }, myendpoint),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, onGetStepSuccess, onGetStepFailure);
    }

    function onGetStepSuccess(response) {

        const { results } = response
        const [step] = results
        step && setStepDetails(step)
    }

    function onGetStepFailure(error) {
        console.log('ee_e', error)
        setState(new_state => ({
            ...new_state,
            error: error,
        }))
    }

    function getStepDataForNonConditionalStepView(version, stepCode) {

        return new Promise(function (resolve, reject) {

            let myendpoint = GenerateURL({}, properties.api.stepCatalogs)

            let requestInfo = {
                endPoint: GenerateSearchURL({ step_code: stepCode, version: version }, myendpoint),
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }
            InvokeApi(requestInfo,

                (data) => {
                    const { results } = data
                    const [step] = results
                    resolve(step)
                },
                (error) => reject(error));
        })
    }

    function setDataForConditionStepView(stepData) {
        let version = stepData.version

        stepData && stepData.values_step_mapping.forEach(data => {

            let valueObject = { ...data }
            let steps = []

            setConditionObject(prevObject => ({
                ...prevObject,
                [data.value]: prevObject[data.value] ? [...prevObject[data.value]] : []
            }))

            data && data.steps && data.steps.forEach(mappingStep => {
                getStepDataForNonConditionalStepView(version, mappingStep.step_code)
                    .then((step) => {
                        let getCombinedEnvVars = setCombineEnvVaribalesAccordingToVersion(mappingStep.environment_variables, step.environment_variables, version, false)
                        let updatedStep = { ...mappingStep }
                        updatedStep['environment_variables'] = getCombinedEnvVars
                        setConditionObject(prevObject => ({
                            ...prevObject,
                            [data.value]: [...prevObject[data.value], updatedStep]
                        }))
                        steps.push(updatedStep)
                    })

            })
            return { ...valueObject, steps: steps }
        })
    }


    return (

        <>

            <div style={{ display: 'flex', flexDirection: 'column' }} className="align-center justify-center pt-30 pb-30">

                <img className="mb-12" src="/images/language_icons/clone_repo.png" alt='..' />

                <label className="mb-12" >{stepData.step_name}</label>

                <div className="mb-12" >
                    {!stepData.is_conditional_step && stepData.version === 'v2' &&

                        <button className="mr-10" style={{ backgroundColor: '#36c5f0', color: 'white', width: '150px', height: '30px', border: 'none', borderRadius: '5px' }}>{stepDetails && stepDetails.category && stepDetails.category.length > 0 && stepDetails.category[0].name ? stepDetails.category[0].name : ''}</button>
                    }
                    <button style={{ backgroundColor: '#48a3e3', color: 'white', width: '150px', height: '30px', border: 'none', borderRadius: '5px' }}>BP Official</button>
                </div>
                {!stepData.is_conditional_step &&
                    <div><button className='pl-5 pr-5' style={{ width: 'fit-content', height: '30px', backgroundColor: 'white', borderRadius: '5px' }}>{stepDetails && stepDetails.image_name_with_tag ? stepDetails.image_name_with_tag : 'Image-tag :- NA'}</button></div>
                }
            </div>

            <div className="pl-20 pr-20 mb-20 align-center justify-center" style={{ display: 'flex' }}>

                <label className='font-14 text-color-light-gray'>

                    {stepData.is_conditional_step ? 'This is a conditional step' : stepDetails ? stepDetails.description : ''}

                </label>
            </div>

            <div className="pb-30">

                {
                    stepData && stepData.is_conditional_step ?
                        <ConditionalStepCardDetail stepData={conditionObject} />
                        :
                        <NonConditionStepCard stepData={stepData} envVars={comineEnvVariables} />

                }
            </div>

        </>
    )
}

StepDetailShow.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};

export default StepDetailShow



function setCombineEnvVaribalesAccordingToVersion(envVariablesFromLocalstep, envVariables, version, setCombineEnvVariables) {


    console.log(envVariablesFromLocalstep, 'called_local')
    console.log(envVariables, 'api_fetcheddwsdsdsd')
    let stepEnvVars = []
    let uniqueStepData = []

    if (version === 'v2') {

        if (envVariablesFromLocalstep) {

            if (envVariablesFromLocalstep.length > 0) {
                console.log(envVariablesFromLocalstep,"envVarenvVarenvVar")
                stepEnvVars = envVariablesFromLocalstep && envVariablesFromLocalstep.map(envVar => {
                    let envVarValue =  envVar.integration_values && envVar.integration_values[0] ? envVar.integration_values[0].text &&  envVar.integration_values[0].text[0] ? envVar.integration_values[0].text[0].value : "N/A" : "N/A";
                    return envVar.integration ? { key: `${envVar.integration}(integration)`, value: envVarValue } :null

                })
            } else {
                console.log(envVariablesFromLocalstep,"envVarenvVarenvVar")
                stepEnvVars = envVariablesFromLocalstep && Object.keys(envVariablesFromLocalstep).map(key => {

                    return { key: key, value: envVariablesFromLocalstep[key] }

                })
            }



        }

        console.log('step_envVars_data', envVariables);


        envVariables && envVariables.forEach(stepData => {
            if (stepEnvVars?.length > 0) {
                const isFound = stepEnvVars?.find(localData => {
                    if (stepData.key === localData.key) {
                        return localData
                    }
                })

                if (!isFound) {
                    uniqueStepData.push({ key: stepData.key, value: stepData['default_value'] })
                }
            }

            else {
                uniqueStepData.push({ key: stepData.key, value: stepData['default_value'] })
            }
        })
        let envVarData;
        if (envVariablesFromLocalstep && envVariablesFromLocalstep.length > 0) {
            envVarData = [ ...stepEnvVars]

        }else{
            envVarData = [...uniqueStepData, ...stepEnvVars]

        }
        console.log(envVarData, 'envVarData_0001', uniqueStepData, stepEnvVars)
        if (!setCombineEnvVariables)
            return envVarData;
        setCombineEnvVariables(envVarData)


    }

    else if (version == 'v3') {
        if (envVariablesFromLocalstep) {

            stepEnvVars = envVariablesFromLocalstep && envVariablesFromLocalstep.map(envVar => {

                return envVar.integration ? { key: `${envVar.integration}(integration)`, value: envVar.value } : { key: envVar.key, value: envVar.value }

            })

        }

        console.log(envVariables, 'envVars_v30001');

        envVariables && envVariables.forEach(stepData => {

            console.log(stepData, 'step_data_001sdsdds')
            if (stepEnvVars?.length > 0) {
                const isFound = stepEnvVars?.find(localData => {
                    if (stepData.key === localData.key || `${stepData.integration}(integration)` === localData.key) {
                        return localData
                    }
                })

                if (!isFound) {
                    stepData.integration ? uniqueStepData.push({ key: `${stepData.integration}(integration)`, value: stepData['default_value'] }) : uniqueStepData.push({ key: stepData.key, value: stepData['default_value'] })
                }
            }
            else {
                stepData.integration ? uniqueStepData.push({ key: `${stepData.integration}(integration)`, value: stepData['default_value'] }) : uniqueStepData.push({ key: stepData.key, value: stepData['default_value'] })
            }
        })

        console.log(uniqueStepData, 'ut_awfdf');

        let envVarData = [...uniqueStepData, ...stepEnvVars]

        if (!setCombineEnvVariables)
            return envVarData;

        setCombineEnvVariables(envVarData)
        console.log(envVarData, 'envVarData_0001')
    }
}   