import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import CommonHorizontalTab from '../../components/genericComponents/CommonHorizontalTab';
import { DiffEditor } from '@monaco-editor/react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import GenerateURL from '../../util/APIUrlProvider';
import properties from '../../properties/properties';
import InvokeApi from '../../util/apiInvoker';
import { Loading } from '../utils/Loading';
import ManifestView from '../service/detail/components/ManifestView';
import { Input } from '../../components/genericComponents/Input';
import { getCommonFunctions } from '../serviceRevamp/add/ci_flow/SourceDetails';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    '.MuiPaper-root': {
        backgroundColor: '#0f7f7!important'
    },
    appBar: {
        position: 'relative',
        height: "70px",
        padding: "20px",

        "& .MuiSvgIcon-root": {
            height: "24px",
            width: "24px"
        },

        "& .details": {
            color: 'white',
            fontSize: "12px",
            
            fontWeight: '500',
            wordWrap: 'break-word',
            marginLeft: "6px",
        },

        "& .MuiToolbar-regular": {
            minHeight: "0"
        },

        "& .MuiToolbar-gutters": {
            padding: "0"
        }
    },
    title: {
        flex: 1,
        color: '#fff!important',

        "& p": {
            color: 'white',
            fontSize: "18px",
            
            fontWeight: '600',
            wordWrap: 'break-word'
        }
    },
    timeSection: {
        padding: '4px 10px',
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        borderRadius: '16px',
        fontSize: '12px',
        width: 'fit-content',
        position: 'relative',
        margin: 'auto',
        zIndex: 99
    },
    divider: {
        height: '1px',
        width: '100%',
        backgroundColor: '#ccc',
        top: '1.5rem',
        position: 'absolute',
        zIndex: 9
    },
    mainsec: {
        position: 'relative',
        margin: '32px 0px'
    },
    margin: {
        width: '32px',
        border: '2px solid rgb(0, 134, 255)',
        height: '32px',
        display: 'inline-block',
        borderRadius: '50%',
        '&.btn-filter': {
            marginRight: 5,
            marginLeft: 5
        }
    },

    root: {
        padding: theme.spacing(4),
        // marginTop: theme.spacing(4),

        "& .body": {

        }
    },
}));

const SingleDeployDetails = (props) => {
    const open = props.isOpen;
    const handleSingleDeployOpen = props.handleSingleDeployOpen;
    const classes = useStyles();
    const service_data = props.service_data
    const selectedRollbackId = props.selectedRollbackId
    const rollbackCdId = props.rollbackCdId;
    const selectedRollBackData = props.selectedRollBackData;
    const selected_service_name = props.selected_service_name;
    const env_master = props.env_master
    const env_project = props.env_project;
    const curr_deployment_data = props.curr_deployment_data
    const application_id = props.application_id
    const latest_deployment_data = props.latest_deployment_data


    const [state, setState] = useState({
        selected_tab: 2,
        selected_manifest_tab: 1,
        selected_config_tab: 1,
        selected_secret_tab: 1,
        files_changed: []
    })

    useEffect(() => {
        fetchSingleDeployDetails();
    }, [])

    function fetchSingleDeployDetails() {
        var requestInfo = {
            endPoint: GenerateURL({ service_name: selected_service_name, project_env: env_project, project: application_id, env_master_name: env_master, rollback_id: selectedRollbackId, current_deploy_id: curr_deployment_data.id, rollback_cd_id: rollbackCdId, current_cd_id: curr_deployment_data.env_cd_detail_id }, properties.api.rollback_details),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, fetchDeployDetailsSuccess, fetchDeployDetailsFailure);
        setState((new_state) => ({
            ...new_state,
            loading: true
        }))
    }

    function fetchDeployDetailsSuccess(response) {
        setState((new_state) => ({
            ...new_state,
            deployDetails: response,
            currentManifestData: getCurrentManifestData(state.selected_manifest_tab, response),
            previousManifestData: getPreviousManifestData(state.selected_manifest_tab, response),
            currentSecretData: getCurrentSecretData(state.selected_secret_tab, response),
            previousSecretData: getPreviousSecretData(state.selected_secret_tab, response),
            currentConfigData: getCurrentConfigData(state.selected_config_tab, response),
            previousConfigData: getPreviousConfigData(state.selected_config_tab, response),
            // filesChangedList: getFilesChanged(response)
        }))
        getListOfFileChanges(response);
    }

    function fetchDeployDetailsFailure(error) {
        console.log(error)
    }

    function getListOfFileChanges(response) {

        var output = [];

        if (response.file_changes.available) {
            if (response.file_changes.changes.added.length > 0) {
                response.file_changes.changes.added.forEach((item) => {
                    var obj = {};
                    obj.action = "added"
                    obj.fileName = item

                    output.push(obj);
                })
            }

            if (response.file_changes.changes.deleted.length > 0) {
                response.file_changes.changes.deleted.forEach((item) => {
                    var obj = {};
                    obj.action = "deleted"
                    obj.fileName = item

                    output.push(obj);
                })
            }

            if (response.file_changes.changes.renamed.length > 0) {
                response.file_changes.changes.renamed.forEach((item) => {
                    var obj = {};
                    obj.action = "renamed"
                    obj.fileName = item

                    output.push(obj);
                })
            }

            if (response.file_changes.changes.modified.length > 0) {
                response.file_changes.changes.modified.forEach((item) => {
                    var obj = {};
                    obj.action = "modified"
                    obj.fileName = item

                    output.push(obj);
                })
            }
        }

        setState((new_state) => ({
            ...new_state,
            files_changed: output,
            loading: false,
            // file_changes_loading: false
        }))

    }



    const updateSelectedTab = (tab_order) => {
        setState(new_state => ({
            ...new_state,
            selected_tab: tab_order,
        }));
    }

    const updateSelectedManifestTab = (tab_order) => {
        setState(new_state => ({
            ...new_state,
            selected_manifest_tab: tab_order,
            currentManifestData: getCurrentManifestData(tab_order, state.deployDetails),
            previousManifestData: getPreviousManifestData(tab_order, state.deployDetails),
        }));
    }

    function getCurrentManifestData(selected_tab, data) {
        var curr_manifest = {};
        data?.manifest_files?.current.forEach((item, index) => {
            if (index + 1 == selected_tab) {
                curr_manifest = item;
            }
        })


        var final_obj = { ...curr_manifest }
        return final_obj;
    }

    function getPreviousManifestData(selected_tab, data) {
        var prev_manifest = {};
        data?.manifest_files?.previous.forEach((item, index) => {
            if (index + 1 == selected_tab) {
                prev_manifest = item;
            }
        })
        var final_obj = { ...prev_manifest }
        return final_obj;
    }


    function getCurrentSecretData(selected_tab, data) {
        var curr_secret = {};
        data?.secrets?.current.forEach((item, index) => {
            if (index + 1 == selected_tab) {
                curr_secret = item;
            }
        })
        var final_obj = { ...curr_secret }
        return final_obj;
    }

    function getPreviousSecretData(selected_tab, data) {
        var prev_secret = {};
        data?.secrets?.previous.forEach((item, index) => {
            if (index + 1 == selected_tab) {
                prev_secret = item;
            }
        })
        var final_obj = { ...prev_secret }
        return final_obj;
    }

    const updateSelectedConfigTab = (tab_order) => {
        setState(new_state => ({
            ...new_state,
            selected_config_tab: tab_order,
            currentConfigData: getCurrentConfigData(tab_order, state.deployDetails),
            previousConfigData: getPreviousConfigData(tab_order, state.deployDetails),
        }));
    }

    function getCurrentConfigData(selected_tab, data) {
        var curr_config = {};
        data?.configmaps?.current.forEach((item, index) => {
            if (index + 1 == selected_tab) {
                curr_config = item;
            }
        })
        var final_obj = { ...curr_config }
        return final_obj;
    }

    function getPreviousConfigData(selected_tab, data) {
        var prev_config = {};
        data?.configmaps?.previous.forEach((item, index) => {
            if (index + 1 == selected_tab) {
                prev_config = item;
            }
        })
        var final_obj = { ...prev_config }
        return final_obj;
    }

    const updateSelectedSecretsTab = (tab_order) => {
        setState(new_state => ({
            ...new_state,
            selected_secret_tab: tab_order,
            currentSecretData: getCurrentSecretData(tab_order, state.deployDetails),
            previousSecretData: getPreviousSecretData(tab_order, state.deployDetails),
        }));
    }

    console.log(curr_deployment_data, "dhbchsdcvsdfjvsd")

    return (
        <div>
            <Dialog fullScreen open={open} /* onClose={handleClose} */ TransitionComponent={Transition} style={{ backgroundColor: '#f0f7f7' }}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            <div>
                                <p>Review details of Deployment #{selectedRollBackData.deploy_number}</p>
                                {/* <div style={{ display: "flex", alignItems: "center", marginTop: "12px" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <span className='ri-folder-settings-line'></span>
                                        <div>
                                            <p className='details'>Artifact ID: <span>{curr_deployment_data.deploy_tag ? curr_deployment_data.deploy_tag : "NA"}</span></p>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", marginLeft: "16px" }}>
                                        <span className='ri-git-branch-line'></span>
                                        <div>
                                            <p className='details'>Branch: <span>{curr_deployment_data.build_metadata.build_parameters.branch_name ? curr_deployment_data.build_metadata.build_parameters.branch_name : "NA"}</span></p>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", marginLeft: "16px" }}>
                                        <span className='ri-git-commit-line'></span>
                                        <div>
                                            <p className='details'>Commit ID: <span>{curr_deployment_data.build_metadata.commit_sha ? curr_deployment_data.build_metadata.commit_sha : "NA"}</span></p>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", marginLeft: "16px" }}>
                                        <span className='ri-message-2-line'></span>
                                        <div>
                                            <p className='details'>Commit Msg: <span>{curr_deployment_data.build_metadata.commit_message ? curr_deployment_data.build_metadata.commit_message : "NA"}</span></p>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </Typography>
                        <IconButton edge="end" color="inherit" onClick={handleSingleDeployOpen} aria-label="close">
                            <CloseIcon fontSize='lg' />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div>
                    <Body updateSelectedTab={updateSelectedTab} updateSelectedManifestTab={updateSelectedManifestTab} updateSelectedConfigTab={updateSelectedConfigTab} updateSelectedSecretsTab={updateSelectedSecretsTab} data={state} selectedRollBackData={selectedRollBackData} files_changed={state.files_changed} service_data={service_data} latest_deployment_data={latest_deployment_data} />
                </div>
            </Dialog>
        </div>
    )
}
SingleDeployDetails.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};
export default SingleDeployDetails


const bodyStyles = makeStyles((theme) => ({
    body: {

        "& .current-deployment": {
            margin: "24px 0",

            "& .current-deployment-header": {
                color: "var(--CONTENT-TERTIARY, #787878)",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
            },

            "& .current-deployment-details": {
                display: "grid",
                gridTemplateColumns: "20% 20% 20% 20% 20%",
                // height: "69px",
                padding: "14px 12px",
                borderRadius: "6px",
                border: "1px solid #EBEBEB",
                marginTop: "12px",
                backgroundColor: "#ffffff",

                "& .single-details-div": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                },

                "& .details-type": {
                    color: '#787878',
                    fontSize: "12px",
                    
                    fontWeight: '600',
                },

                "& .details-data": {
                    color: '#505050',
                    fontSize: "12px",
                    
                    fontWeight: '500',
                    marginTop: "4.5px",
                },

                "& .deploy-status": {
                    display: "flex",

                    "& .deploy-number": {
                        color: 'black',
                        fontSize: "14px",
                        
                        fontWeight: '600',
                        wordWrap: 'break-word'
                    },
                },

                "& .artifact-details": {

                }
            }
        },

        padding: "20px 20px 20px 18px",

        "& .header": {
            color: '#787878',
            fontSize: "12px",
            
            fontWeight: '600',
            textTransform: 'uppercase',
            wordWrap: 'break-word'
        },

        "& .left-panel": {

            height: "100vh",

            "& .panel-header": {
                height: "49px",
                borderBottom: "1px solid #DDDDDD",
                display: "flex",
                alignItems: "center",
                paddingLeft: "17px",

                "& p": {
                    
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "71%",
                    color: "#404040",
                },
            },

            "& .panel-body": {

                "& .single-detail": {

                    display: "flex",
                    marginTop: "25px",
                    marginLeft: "10px",

                    "& .detail-icon": {

                        "& img": {
                            width: "18px",
                            height: "18px"
                        }
                    },

                    "& .detail": {

                        marginLeft: "10px",

                        "& .title": {
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            color: "#404040",
                        },

                        "& .value": {

                            "& p": {
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "14px",
                                color: "#666666",
                            }
                        }
                    }

                }
            }

        },

        "& .deploy-details": {

            "& .details-header": {
                height: "49px",
                borderBottom: "1px solid #DDDDDD",
                display: "flex",
                alignItems: "center",
                paddingLeft: "17px",
                backgroundColor: "#F8F8F8",

                "& p": {
                    
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "71%",
                    color: "#404040",
                },
            },

            "& .details-body": {

                marginTop: "14px",

                "& .details-info": {
                    background: "#FFFFFF",
                    border: "1px solid #DDDDDD",
                    borderRadius: "5px",

                    "& .details-tab-bar": {
                        paddingLeft: "15px",
                        borderBottom: "1px solid #CFCFCF",

                        "& span": {
                            color: '#2F2F2F',
                            fontSize: "14px",
                            
                            fontWeight: '600',
                            textTransform: 'uppercase',
                            wordWrap: 'break-word'
                        }
                    },

                    "& .file-change-tab-data": {

                        padding: "0 25px 25px 25px",
                        minHeight: "400px",

                        "& .title": {
                            
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#595959",
                            marginTop: "25px",
                        },

                        "& .file-change-table": {

                            marginTop: "25px",

                            "& table": {
                                borderCollapse: "collapse",
                                width: "100%",
                                tableLayout: "fixed",
                                background: " #FFFFFF",
                                border: "1px solid #DDDDDD",

                                "& tbody": {
                                    "& tr:nth-child(even)": {
                                        backgroundColor: "#F4F4F4",
                                    },

                                    "& tr:last-child": {

                                        "& td": {
                                            borderBottom: "0"
                                        }

                                    }
                                }
                            },

                            "& td,th": {
                                borderBottom: "1px solid #D0D0D0",
                                textAlign: "left",
                                padding: "5px",
                            },

                            "& th": {
                                fontWeight: "500",
                                fontSize: "12px",
                                lineHeight: "24px",
                                color: "#000000",
                            },

                            "& td": {
                                fontWeight: "400",
                                fontSize: "12px",
                                lineHeight: "21px",
                                color: "#373737",
                            },

                            "& .details-btn": {
                                display: "flex",
                                alignItems: "center",
                                border: "0px",
                                color: "#0787E1",
                                fontSize: "12px",
                                backgroundColor: "transparent",
                                borderColor: "#fff",
                                marginRight: "25px"
                            }

                        },
                    },

                    "& .manifest-files-tab-data": {

                        padding: "25px",
                        minHeight: "400px",

                        "& .manifest-files-data": {

                            background: "#FFFFFF",
                            border: "1px solid #CCCCCC",
                            borderRadius: "3px",

                            "& .side-panel": {

                                "& .side-panel-header": {
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "15px",
                                    height: "59px",
                                    background: "#F6F6F6",
                                    borderBottom: "1px solid #CCCCCC",

                                    "& p": {
                                        
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        fontSize: "14px",
                                        lineHeight: "24px",
                                        letterSpacing: "0.01em",
                                        color: "#404040",
                                    }
                                },

                                "& .side-panel-tabs": {
                                    "& .headerul": {

                                        "& li.active": {
                                            borderRight: "1px solid #CCCCCC",
                                            borderLeft: "3px solid #0096db",
                                            background: "#F4F4F4",
                                            color: "#2F2F2F"
                                        }

                                    },

                                    "& span": {
                                        color: '#505050',
                                        fontSize: "14px",
                                        
                                        fontWeight: '600',
                                        wordWrap: 'break-word'
                                    }
                                }

                            },

                            "& .manifest-body": {

                                "& .manifest-body-header": {
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    paddingLeft: "15px",
                                    height: "59px",
                                    background: "#F6F6F6",
                                    borderBottom: "1px solid #CCCCCC",

                                    "& p": {
                                        
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                        letterSpacing: "0.01em",
                                        color: "#404040",
                                    },

                                    "& .compare-button": {

                                        marginRight: "10px",

                                        "& button": {
                                            
                                            fontStyle: "normal",
                                            fontWeight: "500",
                                            fontSize: "14px",
                                            lineHeight: "21px",
                                            letterSpacing: "0.01em",
                                            color: "rgba(0, 126, 255, 0.75)",
                                            border: "none",
                                            background: "none",
                                        }
                                    },

                                    "& .close-button": {
                                        marginRight: "10px",

                                        "& button": {

                                            border: "none",
                                            background: "none",
                                        },

                                        "& p": {
                                            
                                            fontStyle: "normal",
                                            fontWeight: "500",
                                            fontSize: "14px",
                                            lineHeight: "21px",
                                            letterSpacing: "0.01em",
                                            color: "#6C6C6C",
                                        },

                                        "& .MuiSvgIcon-root": {
                                            fontSize: "1.8rem !important",
                                        }
                                    }

                                },

                                "& .manifest-comparison-data": {

                                    // paddingTop: "15px"
                                },

                                "& .manifest-view-data": {

                                    "& .MuiGrid-item": {
                                        padding: "16px 0 0 20px",
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    },
}));


const Body = (props) => {
    const classes = bodyStyles();
    const handleSingleDeployOpen = props.handleSingleDeployOpen;
    const updateSelectedTab = props.updateSelectedTab;
    const selectedRollBackData = props.selectedRollBackData;
    const updateSelectedManifestTab = props.updateSelectedManifestTab
    const data = props.data
    const updateSelectedConfigTab = props.updateSelectedConfigTab
    const updateSelectedSecretsTab = props.updateSelectedSecretsTab
    const files_changed = props.files_changed
    const service_data = props.service_data
    const curr_deployment_data = props.latest_deployment_data

    console.log(curr_deployment_data,"dsvbbvhjfdvbfdhvf")

    function formatDate(created_at) {
        // Parse the provided created_at timestamp
        const date = new Date(created_at);

        // Extract year, month, and day
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');

        // Extract hours, minutes, and seconds
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        // Construct the formatted date string
        const formattedDate = `${year}-${month}-${day} / ${hours}:${minutes}:${seconds}`;

        return formattedDate;
    }

    function calculateDuration(startTime, endTime) {
        const start = new Date(startTime);
        const end = new Date(endTime);
        const durationInSeconds = (end - start) / 1000; // Convert milliseconds to seconds

        if (durationInSeconds < 60) {
            return `${Math.floor(durationInSeconds)}s`;
        } else if (durationInSeconds < 3600) {
            return `${Math.floor(durationInSeconds / 60)}m`;
        } else {
            return `${Math.floor(durationInSeconds / 3600)}h`;
        }
    }

    return (
        <div className={classes.body}>
            <div className='current-deployment'>
                <p className='current-deployment-header'>Current Deployment Details</p>
                <div className='current-deployment-details'>
                    <div className='deploy-status'>
                        <div>
                            <p className='deploy-number'>Deploy #{curr_deployment_data?.deploy_number}</p>
                            {
                                curr_deployment_data?.activity_status?.status == "SUCCESS" ?

                                    <div className='new-status-chip new-success' style={{ marginTop: "12px" }}>
                                        {curr_deployment_data && curr_deployment_data.activity_status && curr_deployment_data.activity_status.status}
                                    </div>
                                    :
                                    <div className='new-status-chip new-failed' style={{ marginTop: "12px" }}>
                                        {curr_deployment_data && curr_deployment_data.activity_status && curr_deployment_data.activity_status.status}
                                    </div>
                            }
                        </div>
                        <div style={{ width: "1px", backgroundColor: '#E6E6E6', marginLeft: "135px" }}></div>
                    </div>
                    <div className='artifact-details single-details-div' >
                        <div>
                            <p className='details-type'>Artifact</p>
                            <p className='details-data'>{curr_deployment_data && curr_deployment_data.deploy_tag}</p>
                        </div>
                    </div>
                    <div className='deploy-time single-details-div'>
                        <div>
                            <p className='details-type'>Commit ID:</p>
                            <p className='details-data'>{curr_deployment_data.build_metadata.commit_sha ? curr_deployment_data.build_metadata.commit_sha : "NA"}</p>
                        </div>
                    </div>
                    <div className='deploy-user single-details-div'>
                        <div>
                            <p className='details-type'>Commit Msg:</p>
                            <p className='details-data'>{curr_deployment_data.build_metadata.commit_message ? curr_deployment_data.build_metadata.commit_message : "NA"}</p>
                        </div>
                    </div>
                    <div className='deploy-user single-details-div'>
                        <div>
                            <p className='details-type'>Branch:</p>
                            <p className='details-data'>{curr_deployment_data.build_metadata.build_parameters.branch_name ? curr_deployment_data.build_metadata.build_parameters.branch_name : "NA"}</p>
                        </div>
                    </div>
                    {/* <div className='deploy-user single-details-div'>
                        <div>
                            <p className='details-type'>Duration</p>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <span className='ri-time-line font-16' style={{ lineHeight: "1", color: "#787878" }}></span>
                                <p className='details-data' style={{ marginLeft: "4px" }}>{calculateDuration(curr_deployment_data?.activity_status?.start_time, curr_deployment_data?.activity_status?.end_time)}</p>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <p className='header'>Deployment Details</p>
            {
                data.loading ?
                    <div style={{ minHeight: '420px', display: 'flex' }}>
                        <Loading varient="light" />
                    </div>
                    :
                    <DeploymentDetails data={data} handleSingleDeployOpen={handleSingleDeployOpen} updateSelectedTab={updateSelectedTab} updateSelectedManifestTab={updateSelectedManifestTab} updateSelectedConfigTab={updateSelectedConfigTab} updateSelectedSecretsTab={updateSelectedSecretsTab} selectedRollBackData={selectedRollBackData} files_changed={files_changed} service_data={service_data} />
            }
        </div>
    );
}

Body.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};

const LeftPanel = (props) => {
    const selectedRollBackData = props.selectedRollBackData;

    console.log(selectedRollBackData,"dbfhsdbfhbsdkhfjksd")

    return (
        <div className='left-panel'>
            <div className='panel-header'>
                <p>Code Details</p>
            </div>
            <div className='panel-body'>
                <div className='single-detail'>
                    <div className='detail-icon'>
                        <img src='/images/carbon_repo-artifact.jpg' alt='..'/>
                    </div>
                    <div className='detail'>
                        <div className='title'>Artifact Id</div>
                        <div className='value'>
                            <Tooltip title={selectedRollBackData.deploy_tag}>
                                <p className='text-ellipsis-150'>{selectedRollBackData.deploy_tag}</p>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className='single-detail'>
                    <div className='detail-icon'>
                        <img src="/images/mdi_source-branch.jpg" alt='..'/>
                    </div>
                    <div className='detail'>
                        <div className='title'>Branch</div>
                        <div className='value'>
                            <p>Master</p>
                        </div>
                    </div>
                </div>
                <div className='single-detail'>
                    <div className='detail-icon'>
                        <img src='/images/bx_git-commit.jpg' alt='..'/>
                    </div>
                    <div className='detail'>
                        <div className='title'>Commit Id</div>
                        <div className='value'>
                            <Tooltip title={selectedRollBackData.commit_details && selectedRollBackData.commit_details.commit_id ? selectedRollBackData.commit_details.commit_id : "NA"}>
                                <p className='text-ellipsis-150'>{selectedRollBackData.commit_details && selectedRollBackData.commit_details.commit_id ? selectedRollBackData.commit_details.commit_id : "NA"}</p>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className='single-detail'>
                    <div className='detail-icon'>
                        <img src='/images/mdi_message-processing-outline.png' alt='..'/>
                    </div>
                    <div className='detail'>
                        <div className='title'>Commit Message</div>
                        <div className='value'>
                            <Tooltip title={selectedRollBackData.commit_details && selectedRollBackData.commit_details.commit_sha ? selectedRollBackData.commit_details.commit_sha : "NA"}>
                                <p className='text-ellipsis-150'>{selectedRollBackData.commit_details && selectedRollBackData.commit_details.commit_sha ? selectedRollBackData.commit_details.commit_sha : "NA"}</p>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
LeftPanel.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};
const tabList = [
    // {
    //     order: 1,
    //     tabName: 'Code Changes',
    // },
    {
        order: 2,
        tabName: 'Config Maps',
    },
    {
        order: 3,
        tabName: 'Secrets',
    },
    {
        order: 4,
        tabName: 'Manifest files',
    },
]

const DeploymentDetails = (props) => {
    const updateSelectedTab = props.updateSelectedTab
    const data = props.data;
    const selectedRollBackData = props.selectedRollBackData
    const updateSelectedManifestTab = props.updateSelectedManifestTab
    const updateSelectedConfigTab = props.updateSelectedConfigTab
    const updateSelectedSecretsTab = props.updateSelectedSecretsTab
    const deployDetails = data.deployDetails
    const files_changed = props.files_changed
    const service_data = props.service_data

    const [compareView, setCompareView] = useState(false)

    const [state, setState] = useState({
        files_changed: [],
        data: {},
        error: {}
    })

    const inherits = props.inherits ? props.inherits : {};
    const commonFunctions = getCommonFunctions(state, setState, inherits);

    function handleCompareView() {
        setCompareView(!compareView)
    }

    function getManifestFilesTablist() {
        var manifest_files_tablist = []
        deployDetails?.manifest_files?.current.forEach((item, index) => {
            var option = {
                order: index + 1,
                tabName: item.name,
            }
            manifest_files_tablist.push(option)
        })

        return manifest_files_tablist;
    }

    function getSecretFilesTablist() {
        var secret_files_tablist = []
        deployDetails.secrets.current.forEach((item, index) => {
            var option = {
                order: index + 1,
                tabName: item.name,
            }
            secret_files_tablist.push(option)
        })

        return secret_files_tablist;
    }

    function getConfigFilesTablist() {
        var config_files_tablist = []
        deployDetails.configmaps.current.forEach((item, index) => {
            var option = {
                order: index + 1,
                tabName: item.name,
            }
            config_files_tablist.push(option)
        })

        return config_files_tablist;
    }

    function jsonToYaml(jsonData) {
        const yamlLines = [];

        // Helper function to convert JSON object to YAML format
        function convertToYaml(obj, indent = 0) {
            if (obj) {
                for (const [key, value] of Object.entries(obj)) {
                    if (typeof value === 'object' && value !== null) {
                        yamlLines.push(' '.repeat(indent) + key + ':');
                        convertToYaml(value, indent + 2);
                    } else {
                        yamlLines.push(' '.repeat(indent) + key + ': ' + value);
                    }
                }
            }
        }

        // Convert JSON data to YAML
        convertToYaml(jsonData);

        return yamlLines.join('\n');
    }


    return (
        <div className='deploy-details'>
            <div className='details-body'>
                <div className='details-info'>
                    <div className='details-tab-bar'>
                        <CommonHorizontalTab
                            tabList={tabList}
                            updateSelectedTab={updateSelectedTab}
                            selectedTabOrder={data.selected_tab}
                            variant="rollback-dashboard"
                        />
                    </div>
                    {
                        data.selected_tab == 1 &&
                        <div className='file-change-tab-data'>
                            {
                                files_changed && files_changed.length > 0 ?
                                    <>
                                        <div className='title'>Between commit id <span style={{ color: "#000000" }}>{selectedRollBackData?.commit_details?.commit_id}</span> (deployment#{selectedRollBackData.deploy_number}) and commid id <span style={{ color: "#000000" }}>{service_data?.last_deploy_detail?.commit_details?.commit_id}</span> (current deployment) following files have been changed</div>
                                        <div className='file-change-table'>
                                            <Grid container>
                                                <Grid item lg={12}>
                                                    <table style={{ width: "100%" }}>
                                                        <thead>
                                                            <tr style={{ backgroundColor: "#F4F4F4", height: "45px" }}>
                                                                <th>Action</th>
                                                                <th>File name with path</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                files_changed.map((item) => {
                                                                    return (
                                                                        <tr>
                                                                            <td># {item.action}</td>
                                                                            <td>{item.action == "renamed" ? item.fileName.new : item.fileName}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </>
                                    :
                                    <div className="blank-div">
                                        <div className="blank-div-text">
                                            No File Changes are there.
                                        </div>
                                    </div>
                            }
                        </div>
                    }
                    {
                        data.selected_tab == 4 &&
                        <div className="manifest-files-tab-data">
                            <div className='manifest-files-data'>
                                <Grid container>
                                    <Grid item lg={2} style={{ borderRight: "1px solid #CCCCCC" }}>
                                        <div className='side-panel'>
                                            <div className='side-panel-header'>
                                                <p>All Files</p>
                                            </div>
                                            <div className='side-panel-tabs'>
                                                <CommonHorizontalTab
                                                    tabList={getManifestFilesTablist()}
                                                    updateSelectedTab={updateSelectedManifestTab}
                                                    selectedTabOrder={data.selected_manifest_tab}
                                                    variant="vertical_tab"
                                                    inRollback={true}
                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item lg={10}>
                                        <div className='manifest-body'>
                                            <div className='manifest-body-header'>
                                                <p>Manifest file of deployment #{selectedRollBackData.deploy_number} {state.data.compare_view && `vs Manifest file of current deployment`} </p>
                                                <div className={state.data.compare_view ? "close-button" : 'compare-button'}>
                                                    <Input
                                                        type="switch"
                                                        name="compare_view"
                                                        label="Compare Mode"
                                                        data={state.data}
                                                        error={state.error}
                                                        onChangeHandler={commonFunctions.toggleState}
                                                    />
                                                </div>
                                            </div>
                                            <div className={state.data.compare_view ? 'manifest-comparison-data' : "manifest-view-data"}>
                                                <Grid container>
                                                    <Grid item lg={12} >
                                                        {state.data.compare_view ?
                                                            <div>
                                                                <DiffEditor original={jsonToYaml(data.previousManifestData)} height="580px" modified={jsonToYaml(data.currentManifestData)} originalLanguage="javascript" /* theme='vs-dark' */ modifiedLanguage='javascript' splitView={false} />
                                                            </div>
                                                            :
                                                            <SyntaxHighlighter id="viewFullScreen" language="json" style={a11yLight} >
                                                                {jsonToYaml(data.previousManifestData)}
                                                            </SyntaxHighlighter>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    }
                    {
                        data.selected_tab == 3 &&
                        <div className="manifest-files-tab-data">
                            {
                                deployDetails?.secrets?.current?.length > 0 ?
                                    <div className='manifest-files-data'>
                                        <Grid container>
                                            <Grid item lg={2} style={{ borderRight: "1px solid #CCCCCC" }}>
                                                <div className='side-panel'>
                                                    <div className='side-panel-header'>
                                                        <p>All Files</p>
                                                    </div>
                                                    <div className='side-panel-tabs'>
                                                        <CommonHorizontalTab
                                                            tabList={getSecretFilesTablist()}
                                                            updateSelectedTab={updateSelectedSecretsTab}
                                                            selectedTabOrder={data.selected_secret_tab}
                                                            variant="vertical_tab"
                                                        />
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item lg={10}>
                                                <div className='manifest-body'>
                                                    <div className='manifest-body-header'>
                                                        <p>Secret file 2 of deployment #{selectedRollBackData.deploy_number} </p>
                                                        <div className={state.data.compare_view ? "close-button" : 'compare-button'}>
                                                            <Input
                                                                type="switch"
                                                                name="compare_view"
                                                                label="Compare Mode"
                                                                data={state.data}
                                                                error={state.error}
                                                                onChangeHandler={commonFunctions.toggleState}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='manifest-comparison-data'>
                                                        <Grid container>
                                                            <Grid item lg={12} >
                                                                {state.data.compare_view ?
                                                                    <div>
                                                                        <DiffEditor original={jsonToYaml(data.previousSecretData)} height="580px" modified={jsonToYaml(data.currentSecretData)} originalLanguage="javascript" /* theme='vs-dark' */ modifiedLanguage='javascript' splitView={false} />
                                                                    </div>
                                                                    :
                                                                    <SyntaxHighlighter id="viewFullScreen" language="json" style={a11yLight} >
                                                                        {jsonToYaml(data.previousSecretData)}
                                                                    </SyntaxHighlighter>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    :
                                    <div className="blank-div">
                                        <div className="blank-div-text">
                                            No Secrets configured.
                                        </div>
                                    </div>
                            }

                        </div>
                    }
                    {
                        data.selected_tab == 2 &&
                        <div className="manifest-files-tab-data">
                            {
                                deployDetails?.configmaps?.previous?.length > 0 ?
                                    <div className='manifest-files-data'>
                                        <Grid container>
                                            <Grid item lg={2} style={{ borderRight: "1px solid #CCCCCC" }}>
                                                <div className='side-panel'>
                                                    <div className='side-panel-header'>
                                                        <p>All Files</p>
                                                    </div>
                                                    <div className='side-panel-tabs'>
                                                        <CommonHorizontalTab
                                                            tabList={getConfigFilesTablist()}
                                                            updateSelectedTab={updateSelectedConfigTab}
                                                            selectedTabOrder={data.selected_config_tab}
                                                            variant="vertical_tab"
                                                        />
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item lg={10}>
                                                <div className='manifest-body'>
                                                    <div className='manifest-body-header'>
                                                        <p>Config file 2 of deployment #{selectedRollBackData.deploy_number} </p>
                                                        <div className={state.data.compare_view ? "close-button" : 'compare-button'}>
                                                            <Input
                                                                type="switch"
                                                                name="compare_view"
                                                                label="Compare Mode"
                                                                data={state.data}
                                                                error={state.error}
                                                                onChangeHandler={commonFunctions.toggleState}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='manifest-comparison-data'>
                                                        <Grid container>
                                                            <Grid item lg={12} >
                                                                {state.data.compare_view ?
                                                                    <div>
                                                                        <DiffEditor original={jsonToYaml(data.previousConfigData)} height="580px" modified={jsonToYaml(data.currentConfigData)} originalLanguage="javascript" /* theme='vs-dark' */ modifiedLanguage='javascript' splitView={false} />
                                                                    </div>
                                                                    :
                                                                    <SyntaxHighlighter id="viewFullScreen" language="json" style={a11yLight} >
                                                                        {jsonToYaml(data.previousConfigData)}
                                                                    </SyntaxHighlighter>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    :
                                    <div className="blank-div">
                                        <div className="blank-div-text">
                                            No Configmaps configured.
                                        </div>
                                    </div>

                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
DeploymentDetails.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};