import React from "react";
import PropTypes from 'prop-types';
import { CircularMeterList } from "./CircularMeter";
import { CircularMeterListNew } from "./CircularMeterNew";

const HealthStatusRoundMeter = (props) => {
    const useNewMeter = props.useNewMeter ? props.useNewMeter : false;

    const data = getResourceQuotaHealth(props.data);


    function getColor(percentage) {
        if (percentage == "normal") {
            return "#2EBE79"
        }
        if (percentage  == "warning") {
            return "#E1941D"
        }
        if (percentage  == "danger") {
            return "#E53737"
        }
        return "#008000"
    }

    function getResourceQuotaHealth(data) {

        const array = data;
        const keys = Object.keys(array);
        let resourceQuotaList = [];
        for (let index = 0; index < keys.length; index++) {
            const element = array[keys[index]];
            const resourceQuota = {
                label:element.meter_label ? element.meter_label : keys[index].charAt(0).toUpperCase() + keys[index].slice(1),
                percentage: element.percentage,
                color: getColor(element.status),
                allocatedLabel: element.label ?  element.label : "Utilized/Allocated",
                allocatedValue: `${ element.allocated ? element.utilization +"/"+element.allocated : element.utilization }`,
                

            }
            resourceQuotaList.push(resourceQuota);

        }

        return resourceQuotaList;
    }


    return (
        <>
            {
                useNewMeter ? 
                    <CircularMeterListNew list={data} />    
                :
                
                    <CircularMeterList list={data} />
            }
        </>
        
        
    )
}

HealthStatusRoundMeter.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default HealthStatusRoundMeter;