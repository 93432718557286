import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { VALIDATION_TYPE_REQUIRED, ValidateDataSet } from '../../../../../../../util/Validator';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { Grid } from '@material-ui/core';
import { Input } from '../../../../../../../components/genericComponents/Input';
import { getCommonFunctions, ResetComponent } from '../../../../../../serviceRevamp/add/ci_flow/SourceDetails';
import HPAForm, { HpaFormDefaultState } from './HPAForm';
import SchedulerFrom, { HpaSchedulerDefaultState } from './SchedulerFrom';
import Dialog from '@material-ui/core/Dialog';
import NoDataAvailable from './NoAutoScaling';
import { PostData } from '../../../../../../../util/apiInvoker';
import GenerateURL from '../../../../../../../util/APIUrlProvider';
import properties from '../../../../../../../properties/properties';
import { Loading } from '../../../../../../utils/Loading';
import { ErrorComponent } from '../../../../../../utils/Error';
import ManifestHandler from '../../../../../../../components/genericComponents/Forms/ManifestHandler';

const AddAutoScalingForm = (props) => {
  const classes = useStyles();
  const inherits = props.inherits ? props.inherits : {};
  const extraProps = props.extraProps ? props.extraProps : null;
  const prev_state = props.prev_state ? props.prev_state : null;
  const manifest_handler_inherits = {}
  const cd_data = props.cd_data && props.cd_data[0] ? props.cd_data[0] : null;
  const [state, setState] = useState(prev_state ? HpaSettingsDefaultState(prev_state) : HpaSettingsDefaultState());
  const variant = props.variant ? props.variant : null
  const commonFunctions = getCommonFunctions(state, setState, inherits);
  
  console.log(cd_data,"rtyugh ===============>")

  const handleClickOpen = () => {
    setState({ ...state, open: true, add_default_hpa: true });
  };
  useEffect(() => {
    setState(new_state => ({ ...new_state, ...prev_state }))
  }, [prev_state])
  const handleClose = () => {
    props.refreshFn()
    setState({
      ...state,
      open: false,
      add_time_base_hpa: false
    });
  };
  const handleSaveDataAndCloseDialog = () => {
    setState({
      ...state,
      open: false,
      add_time_base_hpa: false
    })
  }
  const handleOpenTimeBaseHpaForm = () => {
    setState({
      ...state,
      open: true,
      add_time_base_hpa: true,
      default: false
    })
  }

  console.log(cd_data,"extraProps =================>")

  function onSubmitHPAData() {

    var result = ValidateDataSet(state.data, state.validations);

    const inheritResult = state.data.setup_strategy === "form_view"? false: manifest_handler_inherits.validateForm().valid;

    console.log('in_rt',inheritResult)

    var child_state_validation = inherits.validateForm ? inherits.validateForm() : { valid: true };

    var child_state = inherits.getState ? inherits.getState() : null;

    var hpa_data = child_state.hpa_form;

    var hpa_scheduler_data = child_state.scheduler_form;

    
    if (prev_state && prev_state.id) {
      console.log(cd_data,"ertyuighjk ==============>")
      var post_url = GenerateURL({ service_id: extraProps.service_id, component_env_id: extraProps.component_env_id, cd_id: cd_data.id, id: prev_state.id }, properties.api.edit_autoscaling);
    } else {
      var post_url = GenerateURL({ service_id: extraProps.service_id, component_env_id: extraProps.component_env_id, cd_id: cd_data.id }, properties.api.save_autoscaling);
    }

    if (result.valid && child_state_validation.valid) {
      var basic_details = {};
      basic_details = {
        ...state.data,
        ...hpa_data.data,
        name: state.data.name,
        default: state.default,
        env_cd_detail_id: cd_data && cd_data.id ? cd_data.id : "",
      }
      if (prev_state && prev_state.env_cd_detail_id) {
        basic_details.env_cd_detail_id = prev_state.env_cd_detail_id
      }

      if(prev_state && prev_state.id){
        basic_details.id = prev_state.id
      }

      if(prev_state && prev_state.status){
        basic_details.status = prev_state.status
      }

      if(inheritResult){
        basic_details['manifest_meta_data'] = manifest_handler_inherits.getData()
      }
      var weekDayArray = [];
      if (!state.default) {
        if (hpa_scheduler_data && hpa_scheduler_data.weekdaysinherits) {
          if (hpa_scheduler_data.weekdaysinherits.data) {
            if (hpa_scheduler_data.weekdaysinherits.data.monday) {
              weekDayArray.push("monday");
            }
            if (hpa_scheduler_data.weekdaysinherits.data.tuesday) {
              weekDayArray.push("tuesday");
            }
            if (hpa_scheduler_data.weekdaysinherits.data.wednesday) {
              weekDayArray.push("wednesday");
            }
            if (hpa_scheduler_data.weekdaysinherits.data.thursday) {
              weekDayArray.push("thursday");
            }
            if (hpa_scheduler_data.weekdaysinherits.data.friday) {
              weekDayArray.push("friday");
            }
            if (hpa_scheduler_data.weekdaysinherits.data.saturday) {
              weekDayArray.push("saturday");
            }
            if (hpa_scheduler_data.weekdaysinherits.data.sunday) {
              weekDayArray.push("sunday");
            }
          }
        }
        basic_details.time_pattern = {
          days: weekDayArray,
          start_time: hpa_scheduler_data.data.start_time_hrs + ':' + hpa_scheduler_data.data.start_time_mins,
          hours: hpa_scheduler_data.data.hours_hrs + ':' + hpa_scheduler_data.data.hours_mins
        };
        basic_details.time_zone = hpa_scheduler_data.data.time_zone;
      } else {
        basic_details.time_pattern = null
      }
      console.log(basic_details,'final_results')
      PostData(post_url, basic_details, onSaveSuccess, onSaveFail);
      setState(new_state => ({
        ...new_state,
        loading: true,
        error_msg: null,
        open: true
      }));
    }
  }
  function onSaveFail(error) {
    var error = error.toString()
    setState(new_state => ({
      ...new_state,
      loading: false,
      error_msg: "Unable to add HPA due to following reasons:" + " " + error,
      open: true
    }));
  }
  function onSaveSuccess(response) {
    props.refreshFn()
    setState(new_state => ({
      ...new_state,
      loading: false,
      error_msg: null,
      open: false
    }));
  }

  function handleClickLoadEditState() {
    setState(new_state => ({
      ...new_state,
      open: true,
      add_time_base_hpa: prev_state && prev_state.default ? false : true,
      loading: false,
      error_msg: null,
    }));
  }
  return (
    <>
      {
        variant == "DEFAULT_HPA" ?
          <NoDataAvailable addHpa={handleClickOpen} />
          :
          variant == "EDIT_MODE" ?
            <button className="btn btn-flaticon" onClick={handleClickLoadEditState}><BorderColorIcon className="font-14 text-anchor-blue" /></button> :
            <button className="btn btn-primary-v2" onClick={handleOpenTimeBaseHpaForm}>Add Time Based HPA</button>
      }

      <Dialog
        open={state.open}
        maxWidth="md"
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.root}>
          <div className="card-body">
            {
              state.loading ?
                <Loading varient="light" /> :
                state.error_msg ?
                  <ErrorComponent error={state.error_msg} variant="msg-box" /> :
                  <>
                    <Grid container>
                      {/* <Grid lg={12} item className="pd-10 border-bottom">
                        <Input
                          type="radio"
                          name="setup_strategy"
                          label="Choose HPA Setup Strategy"
                          list={[{ "label": "Guided Form(Recommended", value: "form_view" }, { "label": "Upload Custom Manifest File / GitOps", value: "manifest_view" }]}
                          mandatorySign
                          data={state.data}
                          error={state.error}
                          onChangeHandler={commonFunctions.onChangeHandler}
                        />
                      </Grid> */}
                      
                          <Grid lg={12} item className="pd-10 border-bottom">
                            <Input
                              type="text"
                              placeholder="HPA Name"
                              name="name"
                              label="HPA Name"
                              mandatorySign
                              data={state.data}
                              error={state.error}
                              onChangeHandler={commonFunctions.onChangeHandler}
                            />
                          </Grid>

                          {state.data.setup_strategy === "form_view" &&
                        
                         <HPAForm
                            prev_state={state.hpa_form}
                            inherits={state.child_inherits.hpa_form}
                          />
                        
                      }
                      {state.data.setup_strategy === "manifest_view" &&

                        <div style={{ width: '100%', padding:'20px' }}> 
                          {/* manifestformhere*/}
                          <ManifestHandler inherits={manifest_handler_inherits} />
                        </div>}
                    </Grid>
                    {
                      state.add_time_base_hpa ?
                        
                          <SchedulerFrom
                            prev_state={state.scheduler_form}
                            inherits={state.child_inherits.scheduler_form}
                          />

                        
                        : <ResetComponent inherits={state.child_inherits.scheduler_form} />
                    }
                  </>
            }

          </div>
          <div className="card-footer space-between">

            <button className="btn btn-outline-dark-grey" onClick={handleClose}>Cancel</button>
            <button className="btn btn-primary-v2" onClick={onSubmitHPAData}>Save</button>

          </div>
        </div>
      </Dialog>

    </>
  )
}

AddAutoScalingForm.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export default AddAutoScalingForm;

function HpaSettingsDefaultState(prev_state) {
  return {
    data: prev_state ? {
      name: prev_state.name,
      setup_strategy: "form_view"
    } : {
      setup_strategy: "form_view"
    },
    hpa_form: prev_state ? {
      ...HpaFormDefaultState(),
      data: {
        minimum_replication: prev_state.minimum_replication ? prev_state.minimum_replication : "",
        maximum_replication: prev_state.maximum_replication ? prev_state.maximum_replication : "",
        cpu_threshold: prev_state.cpu_threshold ? prev_state.cpu_threshold : 0,
        ram_threshold: prev_state.ram_threshold ? prev_state.ram_threshold : 0
      }
    } : null,
    scheduler_form: prev_state ?
      {
        ...HpaSchedulerDefaultState(),
        data: {
          time_zone: prev_state.time_zone ? prev_state.time_zone : "",
          start_time_hrs: prev_state.time_pattern && prev_state.time_pattern.start_time ? prev_state.time_pattern.start_time.split(':')[0] : null,
          start_time_mins: prev_state.time_pattern && prev_state.time_pattern.start_time ? prev_state.time_pattern.start_time.split(':')[1] : null,
          hours_hrs: prev_state.time_pattern && prev_state.time_pattern.hours ? prev_state.time_pattern.hours.split(':')[0] : null,
          hours_mins: prev_state.time_pattern && prev_state.time_pattern.hours ? prev_state.time_pattern.hours.split(':')[1] : null,
        },
        weekdaysinherits: prev_state.time_pattern && prev_state.time_pattern.days ? prev_state.time_pattern.days.length > 0 ? prev_state.time_pattern.days : [] : []
      } :
      null,
    add_time_base_hpa: false,
    open: false,
    default: true,
    error: {},
    error_msg: null,
    loading: false,
    validations: {
      name: [VALIDATION_TYPE_REQUIRED]
    },
    child_inherits: {
      scheduler_form: {
        "validateForm": () => { return { valid: true }; },
        "getState": () => { return {}; },
        "getData": () => { return {}; }
      },
      hpa_form: {
        "validateForm": () => { return { valid: true }; },
        "getState": () => { return {}; },
        "getData": () => { return {}; }
      }
    }
  }
}
const useStyles = makeStyles(theme => ({
  root: {
    border: '1px solid #dedede',
    borderRadius: '4px',
    '& .card-body': {
      height: '450px',
      overflowY: 'auto',
      '& .range-slider-input': {
        border: '0px solid #b7b7b7'
      }
    },
    '& .card': {
      boxShadow: 'none',
      '& .heading': {
        '& .card-header': {
          paddingBottom: 10,
          borderTop: 0,
          borderBottom: '1px solid #dedede'
        },
      }
    },
    '& .input-handler': {
      paddingLeft: '170px',
      paddingRight: '170px',
    },
    '& .input-controller': {
      float: 'left',
      '& .input-component': {
        "& input": {
          height: 72,
          width: 84,
          fontSize: 32,
          lineHeight: 1.2,
          color: '#8c8c8c',
          border: '2px solid #8c8c8c',
          textAlign: 'center'
        }
      }
    },
    padding: '0px',
    '& > .card-add-service': {
      borderRadius: '0px',
      border: '0px'
    },
    '& .card-add-service-head': {
      borderTop: '1px solid #e7e7eb',
      borderRadius: '0px',
    },
    '& > .card': {
      backgroundColor: '#f6f8f8',
      borderRadius: '8px',
      border: '1px',
      '& .card-footer': {
        backgroundColor: '#fff',
        justifyContent: 'flex-end'
      },
      '& .card ': {
        marginBottom: '15px',
        boxShadow: 'none',
        '& .input-component': {
          marginBottom: '0px'
        }
      },
    }
  }
}))


























