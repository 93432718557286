import properties from '../properties/properties';
import { PostData } from '../util/apiInvoker';
import GenerateURL from '../util/APIUrlProvider';
import InvokeApi from '../util/apiInvoker';
import {
    VALIDATION_TYPE_REQUIRED,
    VALIDATION_TYPE_SMALLCAP_NUM_HIGHFEN_ONLY,
    VALIDATION_TYPE_KEY_VALUE_REQUIRED
} from '../util/Validator';
import { REQUEST_TYPE_ADD_ENV } from '../views/service/add';

import { RemoveFromArray } from '../util/util';





// Declared default panel for service CRUD operations

export function getDefaultBasicInfoPanel() {
    return {
        validations: {
            cluster_id: [VALIDATION_TYPE_REQUIRED],
            namespace_id: [VALIDATION_TYPE_REQUIRED],
            branch_name: [VALIDATION_TYPE_REQUIRED],
            dockerfile_path: [VALIDATION_TYPE_REQUIRED],
            env_build_variable_enabled: [],
            env_build_variable: [],
            sonar_project_key: [],
            sonar_credential_id: [],
        },
        error: {},
        data: {
            cluster_id: "",
            namespace_id: "",
            branch_name: "",
            dockerfile_path: "",
            env_build_variable_enabled: false,
            env_build_variable: [],
        }
    }
}

export function getManifestPanel() {
    return {
        validations: {
            deployment_name:[VALIDATION_TYPE_REQUIRED]
        },
        error: {},
        data: {
            manifest: "MANIFESTGIT",
            git_url: "",
            branch_name: "",
            credential_id: "",
            file_paths: "",
            env_manifest: [], 
        }
    }
}

export function getDefaultServicePanel() {
    return {
        data: {
            name: "",
            type: "JAVA",
            repo_url: "",
            build_strategy: "EVERY",
        },
        error: {},
        validations: {
            name: [VALIDATION_TYPE_REQUIRED, VALIDATION_TYPE_SMALLCAP_NUM_HIGHFEN_ONLY],
            type: [VALIDATION_TYPE_REQUIRED],
            repo_url: [VALIDATION_TYPE_REQUIRED],
            build_strategy: [VALIDATION_TYPE_REQUIRED],
        }
    }
}

export function getDefaultPrequisitePanel() {
    return {
        validations: {
            env_master: [VALIDATION_TYPE_REQUIRED],
            env_name: [VALIDATION_TYPE_REQUIRED, VALIDATION_TYPE_SMALLCAP_NUM_HIGHFEN_ONLY]
        },
        error: {},
        data: {
            env_name: "",
            env_master: "DEV"
        }
    }
}

export function getDefaultNetworkingPanel() {
    return {
        validations: {
            custom_ingress_manifest: [],
            custom_ingress_enabled: [],
            default_port: [VALIDATION_TYPE_REQUIRED],
            expose_url: [VALIDATION_TYPE_REQUIRED],
            expose_path: [],
            tls_cert_enabled: [],
            access_level: [VALIDATION_TYPE_REQUIRED],
            tls_cert_detail_id: []
        },
        error: {},
        data: {
            custom_ingress_manifest: null,
            custom_ingress_enabled: false,
            default_port: 80,
            access_level: "PRIVATE",
            expose_url: "",
            expose_path: "",
            tls_cert_enabled: false,
            tls_cert_detail_id: ""
        }
    }
}

export function getDefaultResourceQuotaPanel() {
    return {
        validations: {
            desired_replication: [VALIDATION_TYPE_REQUIRED],
            default_cpu: [VALIDATION_TYPE_REQUIRED],
            default_ram: [VALIDATION_TYPE_REQUIRED],
        },
        error: {},
        data: {
            desired_replication: 1,
            default_cpu: 100,
            default_ram: 100,
        }
    }
}

export function getDefaultConfigAndAutoScalingPanel() {
    return {
        validations: {
            config_management: [],
            vcs: [],
            properties_files: [],
            config_repo_url: [],
            config_repo_branch: [],
            source_path_with_file_name: [],
            destination_path: [],
            env_scale: [],
            minimum_replication: [],
            maximum_replication: [],
            ram_threshold: [],
            cpu_threshold: [],

        },
        error: {},
        data: {
            config_management: false,
            vcs: false,
            properties_files: null,
            config_repo_url: "",
            config_repo_branch: "",
            source_path_with_file_name: "",
            destination_path: "",
            env_scale: false,
            minimum_replication: 1,
            maximum_replication: 5,
            ram_threshold: 55,
            cpu_threshold: 55,
        }
    }
}

export function getDefaultEnvPanel() {
    return {
        validations: {
            env_build_variable: [],
            env_deploy_variable: [],
            token: [],
            env_vault_variable: []
        },
        error: {},
        data: {
            env_deploy_variable_enabled: false,
            env_vault_variable_enabled: false,
            env_deploy_variable: null,
            token: "",
            env_vault_variable: null
        }
    }
}

export function getDefaultReadinessAndLivelinessPanel() {
    return {
        validations: {
            liveliness: [],
            liveliness_port: [],
            liveliness_url: [],
            liveliness_delay: [],
            liveliness_timeout: [],
            liveliness_failure: [],
            liveliness_period: [],
            readiness: [],
            readiness_port: [],
            readiness_url: [],
            readiness_delay: [],
            readiness_timeout: [],
            readiness_failure: [],
            readiness_period: [],
        },
        error: {},
        data: {
            liveliness: false,
            liveliness_port: "",
            liveliness_url: "",
            liveliness_delay: "",
            liveliness_timeout: "",
            liveliness_failure: "",
            liveliness_period: "",
            readiness: false,
            readiness_port: "",
            readiness_url: "",
            readiness_delay: "",
            readiness_timeout: "",
            readiness_failure: "",
            readiness_period: "",
        }
    }
}

export function getDefaultRole() {
    return {
        validations: {
            roles: [VALIDATION_TYPE_REQUIRED]
        },
        error: {},
        data: {
            roles: null
        }
    }
}




// CRUD related functions for calling APIs

// For Service summary
export const CreateOrUpdateServiceSummary = (service_data, successCallBack, failedCallBack) => {
    const data = service_data;
    data.name = data.name.toLowerCase();
    data.project_id = data.project_id ? data.project_id : data.application_id;
    var endPoint = data.id ?
         GenerateURL({ service_id: data.id }, properties.api.service_basic_details_update_url) :
         GenerateURL({ application_id: data.project_id }, properties.api.service_basic_details_post_url);
    PostData(endPoint, data, successCallBack, failedCallBack);
}

export const GetServiceSummary = (service_id, onSuccessCallback, onFailCallback) => {
    let requestInfo = {
        endPoint: GenerateURL({ service_id: service_id }, properties.api.service_basic_details_get_url),
        httpMethod: "GET",
        httpHeaders: { "Content-Type": "application/json" }
    }
    InvokeApi(requestInfo, onSuccessCallback, onFailCallback);
}

// For Enviroment of a Service
export const CreateOrUpdateServiceEnvFromPanel = (panel, successCallBack, failedCallBack, env_id, is_manifest) => {
    let env_data = convertEnvPanelToEnvData(panel, is_manifest);
    let endPoint = env_id ?
        GenerateURL({ service_id: env_data.component_id, env_id: env_id }, properties.api.env_data_update_url)
        : GenerateURL({ service_id: env_data.component_id }, properties.api.env_data_post_url);
    PostData(endPoint, env_data, successCallBack, failedCallBack);
}

export const GetEnvironmentDetails = (serviceId, envId, handleResponse, handleError) => {
    let requestInfo = {
        endPoint: GenerateURL({ service_id: serviceId, env_id: envId }, properties.api.env_data_url),
        httpMethod: "GET",
        httpHeaders: { "Content-Type": "application/json" }
    }
    InvokeApi(requestInfo, handleResponse, handleError)
}

// Transformers of data b/w API and UI 

const convertEnvPanelToEnvData = (panel, is_manifest) => {
    console.log("panel=", panel);

    const env_data = {
        component_id: panel[1].data.id,
        ...panel[2].data,
        ...panel[3].data,
        ...panel[4].data,
        ...panel[5].data,
        ...panel[7].data,
        ...panel[9].data,
        ...panel[4.5].data,
         branch_name:panel[3].data.branch_name
    };
    console.log("kokokokok",env_data)

    let env_master_id = GetEnvIdFromName(env_data.env_master);
    env_data.env_master_id = env_master_id;
    if (!panel[5].data.custom_ingress_enabled) {
        env_data.custom_ingress_manifest = null;
    }
    if (!panel[5].data.tls_cert_enabled) {
        env_data.tls_cert_detail_id = null;
    }
    let config_management_data = panel[6].data;
    if (config_management_data.config_management) {
        if (config_management_data.vcs) {
            env_data.env_config = {
                config_repo_url: config_management_data.config_repo_url,
                vcs: config_management_data.vcs,
                config_repo_branch: config_management_data.config_repo_branch,
                source_path_with_file_name: config_management_data.source_path_with_file_name,
                destination_path: config_management_data.destination_path,
            }
        } else {
            env_data.env_config = {
                vcs: config_management_data.vcs,
                properties_files: config_management_data.properties_files,
                destination_path: config_management_data.destination_path,
            }
        }
    } else {
        env_data.env_config = null;
    }
    if (config_management_data.env_scale) {
        env_data.env_scale = {
            minimum_replication: config_management_data.minimum_replication,
            maximum_replication: config_management_data.maximum_replication,
            ram_threshold: config_management_data.ram_threshold,
            cpu_threshold: config_management_data.cpu_threshold,
        }
    } else {
        env_data.env_scale = null;
    }

    if (env_data.ci_enabled) {
        env_data.sonar_credential_id = env_data.sonar_credential_id ? Number(env_data.sonar_credential_id) : null;
    } else {
        delete env_data.sonar_project_key;
        delete env_data.sonar_credential_id;
    }


    let liveliness_and_readiness_data = panel[8].data;
    if (liveliness_and_readiness_data.liveliness) {
        env_data.env_liveness_probe = {
            path: liveliness_and_readiness_data.liveliness_url,
            port: liveliness_and_readiness_data.liveliness_port,
            initial_delay_seconds: liveliness_and_readiness_data.liveliness_delay,
            period_seconds: liveliness_and_readiness_data.liveliness_period,
            timeout_seconds: liveliness_and_readiness_data.liveliness_timeout,
            failure_threshold: liveliness_and_readiness_data.liveliness_failure
        }
    } else {
        env_data.env_liveness_probe = null
    }
    if (liveliness_and_readiness_data.readiness) {
        env_data.env_readiness_probe = {
            path: liveliness_and_readiness_data.readiness_url,
            port: liveliness_and_readiness_data.readiness_port,
            initial_delay_seconds: liveliness_and_readiness_data.readiness_delay,
            period_seconds: liveliness_and_readiness_data.readiness_period,
            timeout_seconds: liveliness_and_readiness_data.readiness_timeout,
            failure_threshold: liveliness_and_readiness_data.readiness_failure
        }
    } else {
        env_data.env_readiness_probe = null
    }
    if (env_data.access_level == "PRIVATE") {
        env_data.expose_path = null;
    }
    if (!env_data.env_build_variable_enabled) {
        env_data.env_build_variable = []
    }
    if (!env_data.env_deploy_variable_enabled) {
        env_data.env_deploy_variable = []
    }
    if (!env_data.env_vault_variable_enabled) {
        env_data.env_vault_variable = []
    }
    if (!env_data.tls_cert_detail_id) {
        env_data.tls_cert_detail_id = null;
    }
    if (!env_data.token) {
        env_data.vault_access_token = null;
    } else {
        env_data.vault_access_token = env_data.token;
    }

    if (env_data.roles) {
        env_data.env_role_access = env_data.roles;
    } else {
        env_data.env_role_access = null;
    }

    delete env_data.roles;
    delete env_data.env_master;
    delete env_data.token;


    if (is_manifest) {
        console.log("=======>manifest data",panel[4.5].state);
        env_data.manifest_meta_data=panel[4.5].state;

    } else {
        env_data.manifest_meta_data = null;
    }

    if(!env_data.expose_url){
        delete env_data.expose_url;
    }
    console.log("env-data=", is_manifest, env_data);
    return env_data;
}

export const GetUIFormatedEnvData = (env_data, request_type) => {
    console.log("kokok121212",env_data)
    let env_panel = {
        1: getServiceDataPanel(env_data),
        2: getPrerequisitePanel(env_data),
        3: getBasicDetailsPanel(env_data, request_type),
        4: getResourceQuotaPanel(env_data),
        4.5: getLoadedManifestPanel(env_data),
        5: getNetworkingPanel(env_data),
        6: getConfigAndScalingPanel(env_data),
        7: getEnvVarPanel(env_data, request_type),
        8: getLivelinessAndReadinessDataPanel(env_data),
        9: getRolesPanel(env_data)
    }
    console.log("env_panel", env_panel);
    return env_panel;
}

function getLoadedManifestPanel(env_data) {
    
    console.log("====================> panel",panel);
    let panel = getManifestPanel();
    panel.state = env_data.manifest_meta_data;
    panel.data.deployment_name=env_data.deployment_name;
    return panel;
}

function getPrerequisitePanel(env_data) {
    let panel = getDefaultPrequisitePanel();
    panel.data.env_master = env_data.env_master.name.toUpperCase();
    panel.data.env_name = env_data.env_name;

    return panel;
}

function getServiceDataPanel(env_data) {
    let panel = getDefaultPrequisitePanel();
    panel.data = {
        ...env_data.component
    }
    return panel;
}

function getNetworkingPanel(env_data) {
    let panel = getDefaultNetworkingPanel();
    panel.data.expose_path = env_data.expose_path;
    panel.data.expose_url = env_data.expose_url;
    panel.data.default_port = env_data.default_port;
    panel.data.access_level = env_data.access_level.toUpperCase();
    if (panel.data.access_level != "PRIVATE") {
        panel.validations.expose_path.push(VALIDATION_TYPE_REQUIRED);
    }

    panel.validations.expose_url.push(VALIDATION_TYPE_REQUIRED);
    if (env_data.tls_cert_detail) {
        panel.data.tls_cert_enabled = true;
        panel.data.tls_cert_detail_id = env_data.tls_cert_detail.id;
        // panel.validations.tls_cert_detail_id.push(VALIDATION_TYPE_REQUIRED);
    }
    panel.data.custom_ingress_enabled = env_data.custom_ingress_manifest ? true : false;
    if (env_data.custom_ingress_manifest) {
        panel.data.custom_ingress_manifest = {
            name: env_data.custom_ingress_manifest.source_path_with_file_name
        }
    }
    return panel;
}

function getBasicDetailsPanel(env_data, request_type) {
    let panel = getDefaultBasicInfoPanel();
    if (env_data.env_build_variable.length > 0) {
        panel.data.env_build_variable_enabled = true;
        panel.data.env_build_variable = env_data.env_build_variable;
        if (request_type == REQUEST_TYPE_ADD_ENV) {
            panel.data.env_build_variable.forEach(element => {
                delete element.id;
            });
        }
        panel.validations.env_build_variable.push(VALIDATION_TYPE_REQUIRED);
    }
    panel.data.cluster_id = env_data.cluster.id;
    panel.data.namespace_id = env_data.namespace.id;
    panel.data.branch_name = env_data.branch_name;
    panel.data.dockerfile_path = env_data.dockerfile_path;
    panel.data.ci_enabled = env_data.sonar_project_key ? true : false;
    panel.data.sonar_project_key = env_data.sonar_project_key;
    panel.data.sonar_credential_id = env_data.sonar_credential ? env_data.sonar_credential.id : "";
    if (panel.data.ci_enabled) {
        if (!panel.validations.sonar_project_key.includes(VALIDATION_TYPE_REQUIRED)) {
            panel.validations.sonar_project_key.push(VALIDATION_TYPE_REQUIRED);
        }
        if (!panel.validations.sonar_credential_id.includes(VALIDATION_TYPE_REQUIRED)) {
            panel.validations.sonar_credential_id.push(VALIDATION_TYPE_REQUIRED);
        }
    } else {
        RemoveFromArray(panel.validations.sonar_project_key, VALIDATION_TYPE_REQUIRED);
        RemoveFromArray(panel.validations.sonar_credential_id, VALIDATION_TYPE_REQUIRED);
    }

    return panel;
}

function getResourceQuotaPanel(env_data) {
    let panel = getDefaultResourceQuotaPanel();
    panel.data.desired_replication = env_data.desired_replication;
    panel.data.default_port = env_data.default_port;
    panel.data.default_cpu = env_data.default_cpu;
    panel.data.default_ram = env_data.default_ram;
    return panel;
}

function getConfigAndScalingPanel(env_data) {
    let panel = getDefaultConfigAndAutoScalingPanel();
    if (env_data.env_config) {
        panel.data.config_management = true;
        panel.data.vcs = env_data.env_config.vcs;
        if (panel.data.vcs) {
            panel.data.config_repo_url = env_data.env_config.config_repo_url;
            panel.data.config_repo_branch = env_data.env_config.config_repo_branch;
            panel.data.source_path_with_file_name = env_data.env_config.source_path_with_file_name;
            panel.data.destination_path = env_data.env_config.destination_path;

            panel.validations.config_repo_url.push(VALIDATION_TYPE_REQUIRED);
            panel.validations.config_repo_branch.push(VALIDATION_TYPE_REQUIRED);
            panel.validations.source_path_with_file_name.push(VALIDATION_TYPE_REQUIRED);
        } else {
            panel.data.destination_path = env_data.env_config.destination_path;
            let prop_file_list = env_data.env_config.source_path_with_file_name.split(";");
            panel.data.properties_files = [];
            prop_file_list.forEach((file_name) => {
                panel.data.properties_files.push({
                    name: file_name
                });
            });
            panel.validations.properties_files.push(VALIDATION_TYPE_REQUIRED);
        }
        panel.validations.destination_path.push(VALIDATION_TYPE_REQUIRED);
    }
    if (env_data.env_scale) {
        panel.data.env_scale = true;
        panel.data = {
            ...panel.data,
            ...env_data.env_scale,
        }
        Object.keys(env_data.env_scale).forEach((key) => {
            if (key != "created_at" && key != "updated_at") {
                panel.validations[key].push(VALIDATION_TYPE_REQUIRED);
            }
        });
    }
    if (env_data.sonar_project_key) {
        panel.ci_enabled = true;
        panel.sonar_project_key = env_data.sonar_project_key;
    }
    return panel;
}

function getLivelinessAndReadinessDataPanel(env_data) {
    let panel = getDefaultReadinessAndLivelinessPanel();
    if (env_data.env_liveness_probe) {
        panel.data.liveliness = true;
        panel.data.liveliness_port = env_data.env_liveness_probe.port;
        panel.data.liveliness_url = env_data.env_liveness_probe.path;
        panel.data.liveliness_delay = env_data.env_liveness_probe.initial_delay_seconds;
        panel.data.liveliness_timeout = env_data.env_liveness_probe.timeout_seconds;
        panel.data.liveliness_failure = env_data.env_liveness_probe.failure_threshold;
        panel.data.liveliness_period = env_data.env_liveness_probe.period_seconds;

        panel.validations.liveliness_port.push(VALIDATION_TYPE_REQUIRED);
        panel.validations.liveliness_url.push(VALIDATION_TYPE_REQUIRED);
        panel.validations.liveliness_delay.push(VALIDATION_TYPE_REQUIRED);
        panel.validations.liveliness_timeout.push(VALIDATION_TYPE_REQUIRED);
        panel.validations.liveliness_failure.push(VALIDATION_TYPE_REQUIRED);
        panel.validations.liveliness_period.push(VALIDATION_TYPE_REQUIRED);

    }
    if (env_data.env_readiness_probe) {
        panel.data.readiness = true;
        panel.data.readiness_port = env_data.env_readiness_probe.port;
        panel.data.readiness_url = env_data.env_readiness_probe.path;
        panel.data.readiness_delay = env_data.env_readiness_probe.initial_delay_seconds;
        panel.data.readiness_timeout = env_data.env_readiness_probe.timeout_seconds;
        panel.data.readiness_failure = env_data.env_readiness_probe.failure_threshold;
        panel.data.readiness_period = env_data.env_readiness_probe.period_seconds;

        panel.validations.readiness_port.push(VALIDATION_TYPE_REQUIRED);
        panel.validations.readiness_url.push(VALIDATION_TYPE_REQUIRED);
        panel.validations.readiness_delay.push(VALIDATION_TYPE_REQUIRED);
        panel.validations.readiness_timeout.push(VALIDATION_TYPE_REQUIRED);
        panel.validations.readiness_failure.push(VALIDATION_TYPE_REQUIRED);
        panel.validations.readiness_period.push(VALIDATION_TYPE_REQUIRED);
    }
    return panel;
}

function getEnvVarPanel(env_data, request_type) {
    let panel = getDefaultEnvPanel();

    if (env_data.env_deploy_variable.length > 0) {
        panel.data.env_deploy_variable_enabled = true;
        panel.data.env_deploy_variable = env_data.env_deploy_variable;
        if (request_type == REQUEST_TYPE_ADD_ENV) {
            panel.data.env_deploy_variable.forEach(element => {
                delete element.id;
            });
        }
        panel.validations.env_deploy_variable.push(VALIDATION_TYPE_REQUIRED);
        panel.validations.env_deploy_variable.push(VALIDATION_TYPE_KEY_VALUE_REQUIRED);
    }
    if (env_data.env_vault_variable.length > 0) {
        panel.data.env_vault_variable_enabled = true;
        panel.data.env_vault_variable = env_data.env_vault_variable;
        panel.data.token = env_data.vault_access_token;
        if (request_type == REQUEST_TYPE_ADD_ENV) {
            panel.data.env_vault_variable.forEach(element => {
                delete element.id;
            });
        }
        panel.validations.token.push(VALIDATION_TYPE_REQUIRED);
        panel.validations.env_vault_variable.push(VALIDATION_TYPE_REQUIRED);
        panel.validations.env_vault_variable.push(VALIDATION_TYPE_KEY_VALUE_REQUIRED);
    }
    return panel;
}

function getRolesPanel(env_data) {
    let role_panel = getDefaultRole();
    role_panel.data.roles = env_data.env_role_access
    role_panel.data.roles.forEach(role => {
        delete role.id;
    });
    return role_panel;
}


// Extra Utils for generic logic.
export const GetLivelinessProbeDataElement = (envDetailsData) => {
    return envDetailsData.env_liveness_probe;
}

export const GetReadinessProbeDataElement = (envDetailsData) => {
    return envDetailsData.env_readiness_probe;
}
export const GetConfigDataElement = (envDetailsData) => {
    return envDetailsData.env_config;
}
export const GetScalingDataElement = (envDetailsData) => {
    return envDetailsData.env_scale;
}

export const GetMasterEnvsofService = (serviceData) => {
    const masterEnvList = {};
    Object.keys(serviceData.env).forEach((key) => {
        let env = serviceData.env[key];
        masterEnvList[env.env_master.id] = env.env_master;
    })
    return masterEnvList;
}

export const GetDefaultSubEnvironment = (envSubEnvDetails, selectedMasterEnvId) => {
    let subEnvIdKeys = [];

    let masterEnv = envSubEnvDetails[selectedMasterEnvId];
    let idKeys = Object.keys(masterEnv.sub_env);
    return masterEnv.sub_env[idKeys[0]].id;
}
export const GetEnvIdFromName = (env_name) => {
    let environments = properties.envs;
    let env_id = 1;
    environments.forEach((env) => {
        if (env.label == env_name) {
            env_id = env.id;
        }
    });
    return env_id;
}