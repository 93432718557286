import React from 'react'
import PropTypes from 'prop-types';

const ManifestAlert = props =>{
    const update_active_panel = props.update_active_panel;
    return(
        <>
        
        <div className="overlay" onKeyDown={()=>{}} onClick={()=>{update_active_panel(3)}} tabIndex={0} role='button'>
      </div>
      <div className="alert-card">
        <div className="content-alert-box">
        <div className="icon-alert">
          <span className="flaticon-round-info-button"></span>
      </div>
      <div className="alert-text-box">
          <div className="text">

            
            
          </div>
          <div className="main-text-with-option">Do you want to &nbsp;<button className="btn-alert" onClick={()=>{update_active_panel(4)}}>Build manifest with UI</button> &nbsp;or &nbsp;<button className="btn-alert" onClick={()=>{update_active_panel(4.5)}}>Provide a file</button></div>
      </div>
        </div>
  </div>
      
      </>
    )
}

ManifestAlert.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
};

export default ManifestAlert