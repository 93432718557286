/* eslint-disable no-use-before-define */
import React,{useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { ImportantDevices } from '@material-ui/icons';
import { Chip } from '@material-ui/core';

export default function AutoCompleteDropdown(props) {
  const style = props.style;
  const classes = useStyles();
  let list = props.list;
  const name = props.name;
  const freeSolo = props.freeSolo ? props.freeSolo : false
  const onChangeHandler=props.onChangeHandler;
  const value=props.value ?props.value :null;
  const default_css = props.default_css? props.default_css : false
  let filtered_item = {}
  if(list && list.length>0){
    filtered_item = list.find(item => item.id == value)
  }
  console.log(filtered_item,"filtered_item",value)
  const [default_value, set_default_value] = useState({ id: "please_select", label: 'Please Select' })

  useEffect(() => {
    if (list && list.length > 0) {

      if (value) {
        set_default_value(filtered_item)
      }

      else
        set_default_value({ id: "please_select", label: 'Please Select' })
    }

  }, [value, filtered_item])

  const handleChange = (e, newValue) => {
    set_default_value(newValue)
    onChangeHandler(name, newValue && newValue.id);
  }
  console.log("hsjkdhfjskjdh",list,filtered_item,value)
  return (

    <Autocomplete
      value={value && filtered_item && Object.keys(filtered_item).length != 0 ? filtered_item: default_value}
      onChange={handleChange}
      id={name}
      options={list}
      debug={true}
      deployTag
      disabled={props.disabled}
      filterSelectedOptions
      getOptionLabel={props.getOptionLabel}
      renderInput={(params) => <>
        {default_css ?
          <div className={classes.default_css}>
            <TextField {...params} name={name} variant="outlined" placeholder={props.placeholder} style={{ height: props.height ? props.height : 'auto', fontSize: "14px" , display: 'flex', alignItems: 'center', justifyContent : "center"}} />
          </div> :
          <TextField {...params} name={name} variant="outlined" placeholder={props.placeholder} />}
      </>}
    />
  );
}

AutoCompleteDropdown.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

const useStyles = makeStyles((theme) => ({
  default_css: {
    '& .MuiTextField-root': {
      backgroundColor: '#fff',
      fontSize: '14px !important',
    },
    '& .MuiOutlinedInput-root': {

      "& .MuiOutlinedInput-inputAdornedEnd": {
        fontSize: "14px"
      },

      padding: '0px!important',
      '& input': {
        height: 40,
        backgroundColor: '#fff'
      },
      '& input.MuiInputBase-input': {
        color: '#666 !important',

        padding: '0px 14px !important'
      }
    }

  }
}));