import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Input } from '../../../../components/genericComponents/Input';
import Grid from '@material-ui/core/Grid';
import { getCommonFunctions, ResetComponent } from '../ci_flow/SourceDetails';
import { VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import properties from '../../../../properties/properties';
import { getServicesList } from '../../../pipeline/add/BasicInfo';
import MultiRow from '../../../../components/genericComponents/MultiRow';

import { Loading } from '../../../utils/Loading';
import { ErrorOutline } from '@material-ui/icons';



const NodeAndServiceAffinity = props => {
  const classes = useStyles();
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const extraProps = props.extraProps
  const [state, setState] = useState(prev_state ? prev_state : getAffinityDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);
  useEffect(() => {
    setState(new_state => ({ ...new_state, ...prev_state }))
  }, [prev_state])


  return (
    <div className={`${classes.root} ci-cd-edit-input-div`}>
      <Grid container className="card-add-service">
        <Grid item lg={12} direction="row" className="card-add-service-head">
          <div className="formTag">
            <h4 className='mainHeading'>Node &amp; Service Affinity</h4>
          </div>
        </Grid>
        <div style={{ width: "100%" }}>
          <Grid container spacing="2">
            <Grid lg={12} item className="pd-10">
              <div className='card'>
                <div className='heading'>
                  <Input
                    type="switch"
                    name="nodeAffinityRequired"
                    label="Set Node Affinity Required"
                    onChangeHandler={commonFunctions.toggleState}
                    data={state.data}
                    error={state.error}
                    enabledComponent={<NodeAffinityRequired extraProps={extraProps} prev_state={state.NodeAffinityRequired_data} inherits={state.child_inherits.NodeAffinityRequired_data} />}
                    disabledComponent={<ResetComponent inherits={state.child_inherits.NodeAffinityRequired_data} />}
                  />
                </div>
              </div>
              <div className='card'>
                <div className='heading'>
                  <Input
                    type="switch"
                    name="nodeAffinityPreffered"
                    label="Set Node Affinity Preffered"
                    onChangeHandler={commonFunctions.toggleState}
                    data={state.data}
                    error={state.error}
                    enabledComponent={<NodeAffinityPreffered extraProps={extraProps} prev_state={state.NodeAffinityPreffered_data} inherits={state.child_inherits.NodeAffinityPreffered_data} />}
                    disabledComponent={<ResetComponent inherits={state.child_inherits.NodeAffinityPreffered_data} />}
                  />
                </div>
              </div>
              {/* <div className='card'>
              <div className='heading'>
                <Input
                  type="switch"
                  name="nodeAffinity"
                  label="Set Node Affinity"
                  onChangeHandler={commonFunctions.toggleState}
                  data={state.data}
                  error={state.error}
                  enabledComponent={<NodeAffinity extraProps={extraProps} prev_state={state.NodeAffinity_data} inherits={state.child_inherits.NodeAffinity_data} />}
                  disabledComponent={<ResetComponent inherits={state.child_inherits.NodeAffinity_data} />}
                />
              </div>
            </div> */}
              <div className='card'>
                <div className='heading'>
                  <Input
                    type="switch"
                    name="serviceAffinity"
                    label="Set Service Affinity"
                    onChangeHandler={commonFunctions.toggleState}
                    data={state.data}
                    error={state.error}
                    enabledComponent={<ServiceAffinity extraProps={extraProps} prev_state={state.ServiceAffinity_data} inherits={state.child_inherits.ServiceAffinity_data} />}
                    disabledComponent={<ResetComponent inherits={state.child_inherits.ServiceAffinity_data} />}
                  />
                </div>
              </div>

              <div className='card'>
                <div className='heading'>
                  <Input
                    type="switch"
                    name="serviceAntiAffinity"
                    label="Set Service Anti Affinity"
                    onChangeHandler={commonFunctions.toggleState}
                    data={state.data}
                    error={state.error}
                    enabledComponent={<ServiceAnitAffinity extraProps={extraProps} prev_state={state.ServiceAntiAffinity_data} inherits={state.child_inherits.ServiceAntiAffinity_data} />}
                    disabledComponent={<ResetComponent inherits={state.child_inherits.ServiceAntiAffinity_data} />}
                  />
                </div>
              </div>

            </Grid>
          </Grid>
        </div>
      </Grid>
    </div>

  )
}
NodeAndServiceAffinity.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export default NodeAndServiceAffinity;


export function getAffinityDefaultState() {
  return {
    data: {
      nodeAffinityRequired: false,
      nodeAffinityPreffered: false,
      nodeAffinity: false,
      serviceAffinity: false,
      serviceAntiAffinity: false,
      labels_enabled: false,
      annotations_enabled: false
    },
    error: {},
    validations: {},
    child_inherits: {
      NodeAffinityRequired_data: {
        "validateForm": () => { return { valid: true }; },
        "getState": () => { return {}; },
        "getData": () => { return {}; }
      },
      NodeAffinityPreffered_data: {
        "validateForm": () => { return { valid: true }; },
        "getState": () => { return {}; },
        "getData": () => { return {}; }
      },
      // NodeAffinity_data: {
      //   "validateForm": () => { return { valid: true }; },
      //   "getState": () => { return {}; },
      //   "getData": () => { return {}; }
      // },
      ServiceAffinity_data: {
        "validateForm": () => { return { valid: true }; },
        "getState": () => { return {}; },
        "getData": () => { return {}; }
      },
      ServiceAntiAffinity_data: {
        "validateForm": () => { return { valid: true }; },
        "getState": () => { return {}; },
        "getData": () => { return {}; }
      },
      annotations: {
        "validateForm": () => { return { valid: true }; },
        "getState": () => { return {}; },
        "getData": () => { return {}; }
      },
      labels: {
        "validateForm": () => { return { valid: true }; },
        "getState": () => { return {}; },
        "getData": () => { return {}; }
      }
    }
  };
}

function NodeAffinityPreffered(props) {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const [state, setState] = useState(
    prev_state ? prev_state : getNodeAffinityPrefferedDefaultState(),
    { selectedTabOrder: 1 }
  )
  const [showLoading, setShowLoading] = useState(false);
  const commonFunctions = getCommonFunctions(state, setState, inherits);
  function getNodeLabels() {

    var requestInfo = {
      endPoint: GenerateURL({ service_id: props.extraProps.service_id, component_env_id: props.extraProps.component_env_id }, properties.api.node_labels),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    setShowLoading(true);
    InvokeApi(requestInfo, fetchSuccessNodelabels, fetchFailNodelabels);
  }

  function fetchSuccessNodelabels(response) {
    var labels_data = parse_labels(response)
    setState(new_state => ({
      ...new_state,
      labels_data: labels_data
    }))
    setShowLoading(false);
  }

  function parse_labels(labels) {
    var grouped_labels = {} //{key:[{label:value, id:value}]}
    var label_keys = [] //[{label:key, id:key}]

    if (labels) {
      labels.forEach(label => {
        Object.keys(label).forEach(key => {
          label_keys.push({ label: key, id: key })
          if (!grouped_labels[key]) {
            grouped_labels[key] = [{ label: label[key], id: label[key] }]
          } else {
            var val = grouped_labels[key]
            grouped_labels[key].push({ label: label[key], id: label[key] })
          }
        })
      })
    }
    return { label_keys: label_keys, key_grouped_label_values: grouped_labels }
  }



  function fetchFailNodelabels(error) {
    setState(new_state => ({
      ...new_state,
      error: { ...new_state.error, node_label: error.detail }
    }))
    setShowLoading(false);
  }

  useEffect(() => {
    getNodeLabels()
  }, []);

  function onChangeHandlerForKeyValue(key, value) {
    if (key == "components") {
      value.forEach((id, index) => {
        value[index] = id;
      });
    }
    setState({
      ...state,
      data: {
        ...state.data,
        [key]: value
      },
      error: {
        ...state.error,
        [key]: null,
      }
    });
  }
  const updateSelectedTab = (selectedTabOrder) => {

    setState({
      ...state,
      selectedTabOrder: selectedTabOrder,

    });

  }
  return (
    <>
      <div className='card-body b-top' style={{ padding: '0px 0px 15px' }}>

        {
          state.labels_data && state.labels_data.label_keys.length > 0 ? <MultiRow repeatableComponentProps={{ labels_data: state.labels_data }}
            RepeatableComponent={NodeAffinityInputBodyPreferred} HeaderComponent={NodeAffinityHeader} prev_state={state.node_affinity_preferred} inherits={state.child_inherits.node_affinity_preferred} zeroRowsAllowed={true} /> : !showLoading ? <> <ErrorOutline></ErrorOutline>Not found</> : <></>
        }
        {
          showLoading && <Loading varient="light" />
        }

      </div>
    </>
  )
}
NodeAffinityPreffered.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

function NodeAffinityRequired(props) {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const [state, setState] = useState(
    prev_state ? prev_state : getNodeAffinityRequiredDefaultState(),
    { selectedTabOrder: 1 }
  )
  const [showLoading, setShowLoading] = useState(false);
  const commonFunctions = getCommonFunctions(state, setState, inherits);
  function getNodeLabels() {

    var requestInfo = {
      endPoint: GenerateURL({ service_id: props.extraProps.service_id, component_env_id: props.extraProps.component_env_id }, properties.api.node_labels),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    setShowLoading(true);
    InvokeApi(requestInfo, fetchSuccessNodelabels, fetchFailNodelabels);
  }

  function fetchSuccessNodelabels(response) {
    var labels_data = parse_labels(response)
    setState(new_state => ({
      ...new_state,
      labels_data: labels_data
    }))
    setShowLoading(false);
  }

  function parse_labels(labels) {
    var grouped_labels = {} //{key:[{label:value, id:value}]}
    var label_keys = [] //[{label:key, id:key}]

    if (labels) {
      labels.forEach(label => {
        Object.keys(label).forEach(key => {
          label_keys.push({ label: key, id: key })
          if (!grouped_labels[key]) {
            grouped_labels[key] = [{ label: label[key], id: label[key] }]
          } else {
            var val = grouped_labels[key]
            grouped_labels[key].push({ label: label[key], id: label[key] })
          }
        })
      })
    }
    return { label_keys: label_keys, key_grouped_label_values: grouped_labels }
  }



  function fetchFailNodelabels(error) {
    setState(new_state => ({
      ...new_state,
      error: { ...new_state.error, node_label: error.detail }
    }))
    setShowLoading(false);
  }

  useEffect(() => {
    getNodeLabels()
  }, []);

  function onChangeHandlerForKeyValue(key, value) {
    if (key == "components") {
      value.forEach((id, index) => {
        value[index] = id;
      });
    }
    setState({
      ...state,
      data: {
        ...state.data,
        [key]: value
      },
      error: {
        ...state.error,
        [key]: null,
      }
    });
  }
  const updateSelectedTab = (selectedTabOrder) => {

    setState({
      ...state,
      selectedTabOrder: selectedTabOrder,

    });

  }
  return (
    <>
      <div className='card-body b-top' style={{ padding: '0px 0px 15px' }}>
        {
          state.labels_data && state.labels_data.label_keys.length > 0 ? <MultiRow RepeatableComponent={NodeAffinityInputBody} HeaderComponent={NodeAffinityHeader} prev_state={state.node_affinity} inherits={state.child_inherits.node_affinity} zeroRowsAllowed={true}
            repeatableComponentProps={{ labels_data: state.labels_data }} /> : !showLoading ? <> <ErrorOutline></ErrorOutline>Not found</> : <></>
        }
        {
          showLoading && <Loading varient="light" />
        }

      </div>
    </>
  )
}
NodeAffinityRequired.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

function NodeAffinity(props) {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const [state, setState] = useState(
    prev_state ? prev_state : getNodeAffinityDefaultState(),
    { selectedTabOrder: 1 }
  )
  const [showLoading, setShowLoading] = useState(false);
  const commonFunctions = getCommonFunctions(state, setState, inherits);
  function getNodeLabels() {

    var requestInfo = {
      endPoint: GenerateURL({ service_id: props.extraProps.service_id, component_env_id: props.extraProps.component_env_id }, properties.api.node_labels),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    setShowLoading(true);
    InvokeApi(requestInfo, fetchSuccessNodelabels, fetchFailNodelabels);
  }

  function fetchSuccessNodelabels(response) {
    var labels_data = parse_labels(response)
    setState(new_state => ({
      ...new_state,
      labels_data: labels_data
    }))
    setShowLoading(false);
  }

  function parse_labels(labels) {
    var grouped_labels = {} //{key:[{label:value, id:value}]}
    var label_keys = [] //[{label:key, id:key}]

    if (labels) {
      labels.forEach(label => {
        Object.keys(label).forEach(key => {
          label_keys.push({ label: key, id: key })
          if (!grouped_labels[key]) {
            grouped_labels[key] = [{ label: label[key], id: label[key] }]
          } else {
            var val = grouped_labels[key]
            grouped_labels[key].push({ label: label[key], id: label[key] })
          }
        })
      })
    }
    return { label_keys: label_keys, key_grouped_label_values: grouped_labels }
  }



  function fetchFailNodelabels(error) {
    setState(new_state => ({
      ...new_state,
      error: { ...new_state.error, node_label: error.detail }
    }))
    setShowLoading(false);
  }

  useEffect(() => {
    getNodeLabels()
  }, []);

  function onChangeHandlerForKeyValue(key, value) {
    if (key == "components") {
      value.forEach((id, index) => {
        value[index] = id;
      });
    }
    setState({
      ...state,
      data: {
        ...state.data,
        [key]: value
      },
      error: {
        ...state.error,
        [key]: null,
      }
    });
  }
  const updateSelectedTab = (selectedTabOrder) => {

    setState({
      ...state,
      selectedTabOrder: selectedTabOrder,

    });

  }

  console.log(state.child_inherits.node_affinity, "olol")
  return (
    <>
      <Grid item lg={12} direction="row" className="card-add-service-head" style={{ borderTop: '1px solid #e7e7eb' }}>
        <div className="formTag">
          <h4 className='mainHeading'>Required</h4>
        </div>
      </Grid>
      <div className='card-body b-top' style={{ padding: '0px 0px 15px' }}>
        {
          state.labels_data && state.labels_data.label_keys.length > 0 ? <MultiRow RepeatableComponent={NodeAffinityInputBody} HeaderComponent={NodeAffinityHeader} prev_state={state.node_affinity} inherits={state.child_inherits.node_affinity} zeroRowsAllowed={true}
            repeatableComponentProps={{ labels_data: state.labels_data }} /> : !showLoading ? <> <ErrorOutline></ErrorOutline>Not found</> : <></>
        }
        {
          showLoading && <Loading varient="light" />
        }

      </div>
      <Grid item lg={12} direction="row" className="card-add-service-head" style={{ borderTop: '1px solid #e7e7eb' }}>
        <div className="formTag">
          <h4 className='mainHeading'>Preffered</h4>
        </div>
      </Grid>
      <div className='card-body b-top' style={{ padding: '0px 0px 15px' }}>

        {
          state.labels_data && state.labels_data.label_keys.length > 0 ? <MultiRow repeatableComponentProps={{ labels_data: state.labels_data }}
            RepeatableComponent={NodeAffinityInputBodyPreferred} HeaderComponent={NodeAffinityHeader} prev_state={state.node_affinity_preferred} inherits={state.child_inherits.node_affinity_preferred} zeroRowsAllowed={true} /> : !showLoading ? <> <ErrorOutline></ErrorOutline>Not found</> : <></>
        }
        {
          showLoading && <Loading varient="light" />
        }

      </div>
    </>
  )
}
NodeAffinity.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

const NodeAffinityInputBody = props => {
  const inherits = props.inherits ? props.inherits : {};
  var labels_data = props.extraProps.labels_data
  if (props.prev_state && props.prev_state.data.key) {
    if (!props.prev_state.value_list || props.prev_state.value_list.length == 0) {
      props.prev_state.value_list = labels_data.key_grouped_label_values[props.prev_state.data.key]
    }
  }
  const state_current = props.prev_state ? props.prev_state : getNodeAffinityInputBodyDefaultState();
  const [state, setState] = useState({ ...state_current })
  const commonFunctions = getCommonFunctions(state, setState, inherits);

  const operators = [
    {
      id: "In",
      label: '='
    },
    {
      id: "NotIn",
      label: '≠'
    },
    {
      id: "Lt",
      label: '<'
    },
    {
      id: "Gt",
      label: '>'
    },
    {
      id: "Exists",
      label: 'Exists'
    },
    {
      id: "DoesNotExist",
      label: 'Does Not Exist'
    },
  ]

  function onKeyChangeHandler(event) {
    commonFunctions.onChangeHandler(event)
    var value_list = labels_data.key_grouped_label_values[event.target.value]
    setState(new_state => ({ ...new_state, value_list: value_list }))
  }

  return (
    <>
      <Grid item lg={4}>
        <Input
          type="select"
          list={props.extraProps.labels_data.label_keys}
          placeholder="Key"
          name="key"
          error={state.error}
          onChangeHandler={onKeyChangeHandler}
          data={state.data} />
      </Grid>

      <Grid item lg={3}>
        <Input
          type="select"
          list={operators}
          name="operator"
          error={state.error}
          onChangeHandler={commonFunctions.onChangeHandler}
          data={state.data} />
      </Grid>

      <Grid item lg={4}>
        <Input
          type="select"
          list={state.value_list}
          placeholder="value"
          name="value"
          disabled
          error={state.error}
          onChangeHandler={state.data.operator == "DoesNotExist" || state.data.operator == "Exists" ? null : commonFunctions.onChangeHandler}
          data={state.data} />
      </Grid>

    </>
  )
}
NodeAffinityInputBody.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

const NodeAffinityInputBodyPreferred = props => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  var labels_data = props.extraProps.labels_data
  if (props.prev_state && props.prev_state.data.key) {
    if (!props.prev_state.value_list || props.prev_state.value_list.length == 0) {
      props.prev_state.value_list = labels_data.key_grouped_label_values[props.prev_state.data.key]
    }
  }
  const [state, setState] = useState(prev_state ? prev_state : NodeAffinityInputBodyPreferredDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);
  const operators = [
    {
      id: "In",
      label: '='
    },
    {
      id: "NotIn",
      label: '≠'
    },
    {
      id: "Lt",
      label: '<'
    },
    {
      id: "Gt",
      label: '>'
    },
    {
      id: "Exists",
      label: 'Exists'
    },
    {
      id: "DoesNotExist",
      label: 'Does Not Exist'
    },
  ]
  const onChangeHandler = (e) => {
    const k = e.target.name;
    const v = e.target.value;
    commonFunctions.updateData(k, v);
    var value_list = labels_data.key_grouped_label_values[e.target.value]
    setState(new_state => ({ ...new_state, value_list: value_list }))
  }
  return (
    <>
      <Grid item lg={4}>
        <Input
          type="select"
          list={props.extraProps.labels_data.label_keys}
          placeholder="Key"
          name="key"
          error={state.error}
          onChangeHandler={onChangeHandler}
          data={state.data} />
      </Grid>

      <Grid item lg={3}>
        <Input
          type="select"
          list={operators}
          name="operator"
          error={state.error}
          onChangeHandler={commonFunctions.onChangeHandler}
          data={state.data} />
      </Grid>
      <Grid item lg={4}>
        <Input
          type="select"
          list={state.value_list}
          placeholder="value"
          name="value"
          error={state.error}
          onChangeHandler={state.data.operator == "DoesNotExist" || state.data.operator == "Exists" ? null : commonFunctions.onChangeHandler}
          data={state.data} />
      </Grid>

    </>
  )
}
NodeAffinityInputBodyPreferred.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export function getNodeAffinityInputBodyDefaultState() {
  return {
    data: {},
    error: {},
    value_list: [],
    validations: {
      key: [VALIDATION_TYPE_REQUIRED],
      operator: [VALIDATION_TYPE_REQUIRED],
      value: [VALIDATION_TYPE_REQUIRED],
    },
  };
}
export function NodeAffinityInputBodyPreferredDefaultState() {
  return {
    data: {},
    error: {},
    value_list: [],
    validations: {
      key: [VALIDATION_TYPE_REQUIRED],
      operator: [VALIDATION_TYPE_REQUIRED],
      value: [VALIDATION_TYPE_REQUIRED],
    },
  };
}
function NodeAffinityHeader() {
  return (
    <>
      <Grid item lg={4}>
        Key
      </Grid>
      <Grid item lg={4}>
        Operator
      </Grid>
      <Grid item lg={3}>
        Value
      </Grid>
    </>
  )
}
export function getNodeAffinityDefaultState() {
  return {
    data: {
      components: [],
      deploy_stats: 'REQUIRED',
      node_schedule: 'run_pod_on_every'
    },
    error: {},
    validations: {

    },
    keys: [],
    values: [],
    child_inherits: {
      node_affinity: {
        "validateForm": () => { return { valid: true }; },
        "getState": () => { return {}; },
        "getData": () => { return {}; }
      },
      node_affinity_preferred: {
        "validateForm": () => { return { valid: true }; },
        "getState": () => { return {}; },
        "getData": () => { return {}; }
      }
    }
  };
}
export function getNodeAffinityPrefferedDefaultState() {
  return {
    data: {
      components: [],
      deploy_stats: 'REQUIRED',
      node_schedule: 'run_pod_on_every'
    },
    error: {},
    validations: {

    },
    keys: [],
    values: [],
    child_inherits: {
      node_affinity_preferred: {
        "validateForm": () => { return { valid: true }; },
        "getState": () => { return {}; },
        "getData": () => { return {}; }
      }
    }
  };
}
export function getNodeAffinityRequiredDefaultState() {
  return {
    data: {
      components: [],
      deploy_stats: 'REQUIRED',
      node_schedule: 'run_pod_on_every'
    },
    error: {},
    validations: {

    },
    keys: [],
    values: [],
    child_inherits: {
      node_affinity: {
        "validateForm": () => { return { valid: true }; },
        "getState": () => { return {}; },
        "getData": () => { return {}; }
      }
    }
  };
}
function ServiceAffinity(props) {
  const inherits = props.inherits ? props.inherits : {};
  const extraProps = props.extraProps ? props.extraProps : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const [state, setState] = useState(prev_state ? prev_state : getServiceAffinityDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);

  function fetchServices() {
    var requestInfo = {
      endPoint: GenerateURL({ project_env_id: props.extraProps.project_env_id }, properties.api.get_services_for_projectEnv),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    InvokeApi(requestInfo, handleSuccessApiHit, handleFailedApiHit);
  }

  const handleFailedApiHit = (error, exception) => {
    setState({
      ...state,
      error: error,
      exception: exception
    })
  }

  const handleSuccessApiHit = (response) => {
    setState(new_state => ({
      ...new_state,
      services: getServicesList(response.results, extraProps.service_id),
    }));
  }
  console.log(state, 'helo')
  function onChangeHandlerForKeyValue(key, value) {
    if (key == "required" || "preffered") {
      value.forEach((id, index) => {
        value[index] = Number(id);
      });
    }
    setState({
      ...state,
      data: {
        ...state.data,
        [key]: value
      },
      error: {
        ...state.error,
        [key]: null,
      }
    });
  }
  useEffect(() => {
    fetchServices()
  }, [])
  return (
    <>
      <Grid item lg={12} direction="row" className="card-add-service-head" style={{ borderTop: '0px solid #e7e7eb' }}>
        <div className="formTag">
          <h4 className='mainHeading'>Required</h4>
        </div>
      </Grid>
      <div className='card-body' style={{ padding: '10px 10px 15px' }}>
        <Grid container spacing={2}>

          <Grid lg={6} item direction="row">
            <div className="form-controll">
              <Input
                type="checkbox"
                name="required"
                label="Add tag to your service"
                subHeading="(You can add multiple)"
                mandatorySign
                list={state.services}
                data={state.data}
                error={state.error}
                onChangeHandler={onChangeHandlerForKeyValue}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <Grid item lg={12} direction="row" className="card-add-service-head" style={{ borderTop: '1px solid #e7e7eb' }}>
        <div className="formTag">
          <h4 className='mainHeading'>Preffered</h4>
        </div>
      </Grid>
      <div className='card-body' style={{ padding: '10px 10px 15px' }}>
        <Grid container spacing={2}>
          <Grid lg={6} item direction="row">
            <div className="form-controll">
              <Input
                type="checkbox"
                name="preffered"
                label="Add tag to your service"
                subHeading="(You can add multiple)"
                mandatorySign
                list={state.services}
                data={state.data}
                error={state.error}
                onChangeHandler={onChangeHandlerForKeyValue}
              />
            </div>
          </Grid>
        </Grid>
      </div>

    </>

  )
}
ServiceAffinity.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export function getServiceAffinityDefaultState() {
  return {
    data: {
      "required": [],
      "preffered": [],
      deploy_stats: 'REQUIRED',
    },
    error: {},
    services: [],
    validations: {

    },
    node_List: [
      {
        id: 1,
        label: 'Node-1'
      },
      {
        id: 2,
        label: 'Node-2'
      },
      {
        id: 3,
        label: 'Node-3'
      },
    ],
    option_list: [
      {
        label: 'Deploy in this group only',
        value: 'REQUIRED'
      },
      {
        label: 'Give priority to this group while development',
        value: 'PREFERRED'
      }
    ]
  };
}

function ServiceAnitAffinity(props) {
  const inherits = props.inherits ? props.inherits : {};
  const extraProps = props.extraProps ? props.extraProps : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const [state, setState] = useState(prev_state ? prev_state : getServiceAntiAffinityDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);

  function fetchServices() {
    var requestInfo = {
      endPoint: GenerateURL({ project_env_id: props.extraProps.project_env_id }, properties.api.get_services_for_projectEnv),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    InvokeApi(requestInfo, handleSuccessApiHit, handleFailedApiHit);
  }

  const handleFailedApiHit = (error, exception) => {
    setState({
      ...state,
      error: error,
      exception: exception
    })
  }

  const handleSuccessApiHit = (response) => {
    setState(new_state => ({
      ...new_state,
      services: getServicesList(response.results, extraProps.service_id),
    }));
  }

  function onChangeHandlerForKeyValue(key, value) {
    if (key == "required" || key == "preffered") {
      value.forEach((id, index) => {
        value[index] = Number(id);
      });
    }
    setState({
      ...state,
      data: {
        ...state.data,
        [key]: value
      },
      error: {
        ...state.error,
        [key]: null,
      }
    });
  }
  useEffect(() => {
    fetchServices()
  }, [])


  function onChangeHandlerForKeyValue(key, value) {
    if (key == "required" || key == "preffered") {
      value.forEach((id, index) => {
        value[index] = Number(id);
      });
    }
    setState({
      ...state,
      data: {
        ...state.data,
        [key]: value
      },
      error: {
        ...state.error,
        [key]: null,
      }
    });
  }
  return (
    <>
      <Grid item lg={12} direction="row" className="card-add-service-head" style={{ borderTop: '0px solid #e7e7eb' }}>
        <div className="formTag">
          <h4 className='mainHeading'>Required</h4>
        </div>
      </Grid>
      <div className='card-body' style={{ padding: '10px 10px 15px' }}>
        <Grid container spacing={2}>

          <Grid lg={6} item direction="row">
            <div className="form-controll">
              <Input
                type="checkbox"
                name="required"
                label="Add tag to your service"
                subHeading="(You can add multiple)"
                mandatorySign
                list={state.services}
                data={state.data}
                error={state.error}
                onChangeHandler={onChangeHandlerForKeyValue}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <Grid item lg={12} direction="row" className="card-add-service-head" style={{ borderTop: '1px solid #e7e7eb' }}>
        <div className="formTag">
          <h4 className='mainHeading'>Preffered</h4>
        </div>
      </Grid>
      <div className='card-body' style={{ padding: '10px 10px 15px' }}>
        <Grid container spacing={2}>
          <Grid lg={6} item direction="row">
            <div className="form-controll">
              <Input
                type="checkbox"
                name="preffered"
                label="Add tag to your service"
                subHeading="(You can add multiple)"
                mandatorySign
                list={state.services}
                data={state.data}
                error={state.error}
                onChangeHandler={onChangeHandlerForKeyValue}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </>

  )
}
ServiceAnitAffinity.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export function getServiceAntiAffinityDefaultState() {
  return {
    data: {
      required: [],
      preffered: [],
      deploy_stats: 'REQUIRED',
    },
    error: {},
    services: [],
    validations: {

    },
    node_List: [
      {
        id: 1,
        label: 'Node-1'
      },
      {
        id: 2,
        label: 'Node-2'
      },
      {
        id: 3,
        label: 'Node-3'
      },
    ],
    option_list: [
      {
        label: 'Deploy in this group only',
        value: 'REQUIRED'
      },
      {
        label: 'Give priority to this group while development',
        value: 'PREFERRED'
      }
    ]
  };
};


const useStyles = makeStyles(theme => ({
  root: {
    padding: '0px',
    '& > .card-add-service': {
      borderRadius: '0px',
      border: '0px'
    },
    '& .card-add-service-head': {
      borderTop: '1px solid #e7e7eb',
      borderRadius: '0px',
    },
    '& > .card': {
      backgroundColor: '#f6f8f8',
      borderRadius: '0px',
      '& .card-footer': {
        backgroundColor: '#fff',
        justifyContent: 'flex-end'
      },
      '& .card ': {
        marginBottom: '15px',
        boxShadow: 'none',
        '& .input-component': {
          marginBottom: '0px'
        }
      }
    }
  }
}))
