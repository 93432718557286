import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
const AlertStrip = props => {
    const variant = props.variant ? props.variant : null;
    const extraClasses = props.extraClasses ? props.extraClasses : null;
    const message = props.message ? props.message : null;
    console.log(message,"Fdsfsafsafsa")
    const dismissible = props.dismissible? true : false
    const showAlert = props.showAlert
    const toggleAlert = props.toggleAlert
    const newUpdate = props.newUpdate
    const [state, setState] = useState(
        {
            visible: true,
            dismissible: dismissible
        }
    );

    function handleToggle(){

        setState({
            ...state,
            visible: !state.visible,
          });

          
    }

    function getClassesAsPerVariant () {
        switch (variant) {
            case "info":
                return (
                    "alert-primary"
                );
            case "error":
                return (
                    "alert-danger"
                );
            case "primary":
                return (
                   "alert-primary"
                );
            case "warning":
                return (
                   "alert-warning"
                );
            case "success":
                return (
                   "alert-success"
                );
            case "p-v3-alert":
                return (
                    "alert-pipeline-v3"
                );
            default:
                return (
                   ""
                );
        }
    }
    return(
        <>
        {  newUpdate ? 
            showAlert && 
             <div 
                 className={"alert d-flex align-center space-between width-full "+getClassesAsPerVariant() +" "+ extraClasses} >
                     <p className="lh-12">
                         {message}
                     </p>
                    {
                     dismissible?
                     <IconButton onClick={()=>toggleAlert()}>
                         <CloseIcon style={{color:"#721c24"}} />
                     </IconButton>
                     :null}
             </div>
            :
           state.visible && 
            <div 
                className={"alert alert-dismissible d-flex align-center space-between width-full "+getClassesAsPerVariant() +" "+ extraClasses} >
                    <p className="lh-12">
                        {message}
                    </p>
                   {
                    dismissible?
                    <IconButton onClick={handleToggle}>
                        <CloseIcon style={{color:"#721c24"}} />
                    </IconButton>
                    :null}
            </div>
            
        }
        </>
    )
}

AlertStrip.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
        variant: PropTypes.any,
        extraClasses: PropTypes.any,
        message: PropTypes.any,
        dismissible: PropTypes.any,
        showAlert: PropTypes.any,
        toggleAlert: PropTypes.any,
        newUpdate: PropTypes.any
  };

export default AlertStrip;