import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { LoadingText } from '../../../../../utils/Loading';
import Tooltip from '@material-ui/core/Tooltip';
const ViewEvent = props => {
    const selected_pod_name = props.selected_pod_name;
    const events = props.events
    const refreshTab = props.refreshTab;
    console.log(events,'changeintab')
    return(
        <div style={{padding:'0px 10px 0px '}}>
            {
                events ? 
                <>
                <HeadingSection>
                    <div className="text-setion">
                        <div className="main-heading">
                            Pod Events
                        </div>
                        <div className="small-text">
                        Displaying all the events
                        </div>
                    </div>
                    <div className="count-chip-section">
                    <button style={{marginLeft:'auto', display:"block", marginTop:'10px' }} onClick={refreshTab} className="btn-round border-navy bg-clear-btn"><span className="flaticon-refresh-button-1"></span></button>
            
                    </div>

                </HeadingSection>
                    <div className="table-section">
                        <div className="table-head">
                            <div>Message</div>
                            <div>reason</div>
                            <div>Count</div>
                            <div>First Occurance</div>
                            <div>Last Occurance</div>
                        </div>
                        <div className="scrollable-section">
                        {events[selected_pod_name] ? events[selected_pod_name].map(event=>(
                            <div className="table-body">
                                <Tooltip title={event.message} placement="bottom">
                                    <div className="table-message">{event.message}</div>
                                </Tooltip>
                                <div>{event.reason}</div>
                                <div>{event.count}</div>
                                <div>{(new Date(event.first_timestamp)).toString().substring(0,(new Date(event.first_timestamp)).toString().indexOf("GMT"))}</div>
                                <div>{(new Date(event.last_timestamp)).toString().substring(0,(new Date(event.last_timestamp)).toString().indexOf("GMT"))}</div>
                            </div>
                        )) : <div className="table-body-error">
                                <p>No Data Available</p>
                            </div>}
                        </div>
                    </div>
                </>
                :<div className="table-section"><LoadingText /></div>
            }
        </div>
    )
}


ViewEvent.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default ViewEvent




const HeadingSection = styled('div')({
    margin:'10px 0px',
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    '& .main-heading':{
        fontSize:'18px',
        lineHeight:'24px',
        color:'#526174',
        fontWeight:300
    },
    '& .small-text':{
        fontSize:'11px',
        lineHeight:'13px',
        color:'#999'
    }, 
    '& .count-chip-section':{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    '& .chip-large':{
        borderRadius:'4px',
        padding:'5px',
        minWidth:'50px',
        margin:'5px',
        '& .count-chip-large':{
            fontSize:'14px',
            fontWeight:300,
            color:'#fff',
            textAlign:'center'
        },
        '& .text-chip-large':{
            fontSize:'11px',
            fontWeight:300,
            color:'#fff',
            textAlign:'center'
        },
    }

})