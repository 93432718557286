import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import { Input } from '../../../../components/genericComponents/Input';
import { VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
import properties from '../../../../properties/properties';
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import { Loading } from '../../../utils/Loading';

export default function ChangeStatus (props) {
    const inherits = props.inherits;
    const prev_state = props.prev_state;
    const jira_reference_key_list = props.jira_reference_key_list ? props.jira_reference_key_list : [];
    var update_jira_reference_key_list =  jira_reference_key_list.map(item => ({label: item.label, id: item.label}))
    const get_run_conditions_function=props.get_run_conditions_function;
    const issue_keys_list = [ ...get_run_conditions_function().jira_id_reference_keys, ...update_jira_reference_key_list];
    
    const [state, setState] = useState( getChangeStatusDefaultState(prev_state, issue_keys_list))
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    const list = [
        { label: 'Primary', value: 'primary_key' }, 
        { label: 'Other', value: 'OTHER' }
    ]
    useEffect(() => {
        fetchPossibleStatusList();
    }, []);
    function fetchPossibleStatusList() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.jiraPossiableFieldsValue),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, handleSuccessApiHit, handleFailedApiHit);
        setState(new_state => ({
            ...new_state,
            show_loading_icon: true,
            data_load_failure: false
            
        }));
    }
    function transformDataForDropdow (data){
        var list = [];
        if(data && data.length > 0){
            console.log(data,"fklsammfklsa")
            data.forEach(element => {
                list.push({id:element, label:element})
            });
        }else{
            list.push({id:"", label:"Unable to load values"})
        }
        return list;
    }
    function handleSuccessApiHit(response) {
        
        setState(new_state => ({
            ...new_state,
            show_loading_icon: false,
            data_load_failure: false,
            change_status_list:transformDataForDropdow(response)
        }));
    }
    function handleFailedApiHit(error) {
        setState(new_state => ({
            ...new_state,
            show_loading_icon: false,
            data_load_failure: true,
            error_msg: "Unable to fetch possible values" + error,
        }));
        
    }
    return(
        <>
            <Input
                type="select"
                name="issue_key"
                mandatorySign
                list={issue_keys_list}  // issue_keys_list
                label="Select Jira Id Refrence key"
                placeholder="Jira id refrence key"
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data}
                error={state.error} />

              <> {
                  state.show_loading_icon ? 
                  <div className="d-flex align-center justify-center" style={{height: 100}}>
                      <Loading varient="light" />
                  </div>
                  :
                  <Input
                  type="select"
                  name="status"
                  mandatorySign
                  list={state.change_status_list}
                  label="Change Status"
                  onChangeHandler={commonFunctions.onChangeHandler}
                  data={state.data}
                  error={state.error} />
              }
                   
                </>
        </>
    )
}

ChangeStatus.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

function getChangeStatusDefaultState (prev_state, issue_keys_list) {
    return{
        data: prev_state ?{
            issue_key: prev_state.issue_key,
            status: prev_state.status? prev_state.status: null
        }:{
            issue_key: issue_keys_list.length>0 ?issue_keys_list[0].id : null,
        },
        change_status_list: [],
        error: {},
        validations: {
            status:[VALIDATION_TYPE_REQUIRED]
        },
      };
}
