import React, { useState, useRef, useEffect } from 'react';
import Editor from '@monaco-editor/react'
import { ConvertPipelineObjtoYAML } from '../../../../controllers/PipelineController';
import SampleYamlDialog from './SampleYamlDialog';
import MonacoEditor from 'react-monaco-editor';
const stageTemplate = `
stages:
        name: stage-one
        approval: false
        approve_user: -
        stage_run_condition: -
        questionnaires: -
`;
const PipelineUiViaYAML = (props) => {


  const stages = props.stages ? props.stages : null;
  const application = props.application;
  const all_project_envs = props.all_project_envs
  
  const [value, setValue] = useState("");
  const [yamlData, setYamlData] = useState("");
  const editorValue =  props.editorValue ? props.editorValue : "stages: []";
  const onChange = props.onChange
  
  
  
  

 
  const handleClick = () => {

  }


  
  return (
    <div className='d-grid' style={{ gridTemplateColumns: '1fr 150px' }}>
      <div >
      <MonacoEditor
        height="580"
        language="yaml"
        theme="vs-dark"
        value={editorValue}
        onChange={onChange}
      />
      </div>

      <div className='' style={{ padding: '10px', backgroundColor: '#363636' }}>
        <p className='font-13 mb-15' style={{ color: '#fff' }} onClick={handleClick}>Check sample Yaml for jobs: </p>
        
        <SampleYamlDialog
          dialog_heading="Build Job Sample YAML"
          value={
`stages:
    name: stage-one
    approval: false
    approve_user: -
    stage_run_condition: -
    questionnaires: -
            
            `
          }
          handleCopy={handleClick}
          title="Stage Yaml" />
        <SampleYamlDialog
          dialog_heading="Build Job Sample YAML"
          value={
`- name: job_1
  type: BUILD
  task_run_condition: null
  on_success_exit: false
  on_failure_exit: true
  mark_success_upon_skip: false
  latest_enable: false
  skip_task: false
  project_job_template_id: ""
  services:
    - service-name
  env:
    - env-name
  tag: null
                `
          }
          title="Build Job" />
        <SampleYamlDialog
          dialog_heading="Deploy Job Sample YAML"
          value={
`- name: job_1
  type: DEPLOY
  task_run_condition: null
  on_success_exit: false
  on_failure_exit: true
  mark_success_upon_skip: false
  latest_enable: null
  skip_task: false
  project_job_template_id: ""
  services:
    - service-name
  env:
    - env-name
  deployment_type: null
  deployment_status_check: false
  artifact_source: generated
                `
          }
          title="Deploy Job" />
        <SampleYamlDialog
          dialog_heading="Promote Job Sample YAML"
          value={
`- name: job_1
  type: PROMOTE
  task_run_condition: null
  on_success_exit: false
  on_failure_exit: true
  mark_success_upon_skip: null
  latest_enable: null
  skip_task: null
  project_job_template_id: ""
  services:
    - service-one
    - service-two
  env:
    - dev-env
  target_env:
    - qa-env
  artifact_source: generated
                `
          }
          title="Promote Job" />
        <SampleYamlDialog
          dialog_heading="Integration Job Sample YAML"
          value={
`- name: job_1
  type: INTEGRATION
  task_run_condition: null
  on_success_exit: false
  on_failure_exit: true
  mark_success_upon_skip: false
  latest_enable: null
  skip_task: false
  project_job_template_id: ""
  services:
    - service-name
  env:
    - env-name
  tag: null
                `
          }
          title="Integration Job" />
        <SampleYamlDialog
          dialog_heading="API Call Job Sample YAML"
          value={
`- name: job_1
  type: API_CALL
  task_run_condition: null
  on_success_exit: false
  on_failure_exit: true
  mark_success_upon_skip: false
  latest_enable: null
  skip_task: false
  project_job_template_id: ""
  method: method-type
  url: url-name
  headers_key_value_pairs: null
  request_timeout: time
  pipeline_suspend: false
  response_update_context_param: false
                `
          }
          title="API Call Job" />
          <SampleYamlDialog
          dialog_heading="Jira Ticket Job Sample YAML"
          value={
`- name: job_1
  type: JIRA
  task_run_condition: null
  on_success_exit: false
  on_failure_exit: true
  mark_success_upon_skip: false
  latest_enable: null
  skip_task: false
  project_job_template_id: ""
  tag: null
  operation: operation-type
  issue_key: stage.stage-one.job.job_1.jira_id.key
  summary: summary-details
  description: null
  issuetype: issue-type
  priority: priority-type
  account_id: null
  issue_name: null
  epic_name: null
  additional_field_key_value: {}
                `
          }
          title="JIRA ticket" />
          <SampleYamlDialog
          dialog_heading="Rollback Job Sample YAML"
          value={ 
`- name: job_1
  type: ROLLBACK
  task_run_condition: null
  on_success_exit: false
  on_failure_exit: true
  mark_success_upon_skip: false
  latest_enable: null
  skip_task: false
  project_job_template_id: ""
  services:
    - service-name
  env:
    - env-name
  tag: null
  rollback_version: version-number
  deployment_status_check: false
                `
          }
          title="Rollback Job" />
          <SampleYamlDialog
          dialog_heading="Trigger Pipeline Job Sample YAML"
          value={
`- name: job_1
  type: PROMOTE
  task_run_condition: null
  on_success_exit: false
  on_failure_exit: true
  mark_success_upon_skip: false
  latest_enable: null
  skip_task: false
  project_job_template_id: ""
  services:
    - service-name
  env:
    - env-name
  target_env:
    - env-name
  artifact_source: latest
                `
          }
          title="Trigger Pipeline" />
      </div>
    </div>
  )
}

export default PipelineUiViaYAML