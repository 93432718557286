import React from 'react'
import PropTypes from 'prop-types';

const AlphaThumbnail = props =>{
    const alphabet = props.alphabet.toUpperCase()
    //const thumbnailChar = alphabet.charAt(0).toUpperCase();
    const thumbnailStatus = props.thumbnailStatus
    const smallSize = props.smallSize? props.smallSize : "";
    const variant = props.variant
    let label = alphabet[0];
    if(variant == "double"){
        let name_array = alphabet.split("-");
        if(name_array.length > 1){
            label=name_array[0][0] + name_array[1][0]; 
        }else{
            label=alphabet[0] + alphabet[1];
        }
    }
    return(
        <div className="avatar-with-bullet align-self-center">
            <div className= {smallSize == "small" ?"avatar-size-40 avatar" : "avatar"}>{label}
            <span className = { thumbnailStatus =="green" ? "green bullet-with-border alignment" : thumbnailStatus =="purple" ?"purple bullet-with-border alignment":  thumbnailStatus =="blue" ?"blue bullet-with-border alignment": "red  bullet-with-border alignment" }></span>
            </div>
        </div>
    )
}
AlphaThumbnail.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export default AlphaThumbnail