import React, { useState } from 'react';
import { styled } from '@material-ui/core/styles';


const Logsview = props => {
    const logs = props.logs;
    const component_task_ids = logs ? Object.keys(logs) : [];
    const closeLogs = props.closeLogs;
    const [state,setState] = useState({selected_component_id:props.selected_component_id});
    console.log("++",logs,state);

  
    function getFullScreenElement(){
        return document.fullscreenElement
        || document.webkitFullscreenElement
        || document.mozFullscreenElement
        || document.msFullscreenElement
    }
    function toggleFullScreen(){
        if(getFullScreenElement()){
            document.exitFullscreen();
        }else{
            document.getElementById("viewFullLogs").requestFullscreen().catch(console.log)
        }
    }

    return(
        <>
        {
            logs ? 
            <MainCard  id="viewFullLogs">
            {/*<div class='resizers'>
            <div class='resizer top-left flaticon-ellipsis'></div>
        </div>*/}
        
                <TabHeader>
                    <Tablist> 
                        
                        {
                            logs?
                            component_task_ids.map(component_task_id=>(
                                <Tab style={(state.selected_component_id == component_task_id) ?{backgroundColor:'#615e5e'}:{backgroundColor:'#000'}} onClick={()=>setState({selected_component_id:component_task_id})} >
                                    <div className="tab-name"> {logs[component_task_id].placeholder.path_arr[logs[component_task_id].placeholder.path_arr.length-1]} </div>
                                    {/*<div className="flaticon-close-button"></div> */}
                                </Tab>
                            ))
                            :null
                        }

                    </Tablist> 
                    <TabAction>
                    
                        <button><span className={!getFullScreenElement()? "flaticon-crop-button": 'flaticon-full-screen-exit'} onClick={toggleFullScreen} ></span></button>
                         <button><span className="flaticon-close-button" onClick={closeLogs}></span></button>
                    </TabAction>
                </TabHeader>
                <TabBody>
                <Breadcrumb>
                        {
                            logs[state.selected_component_id] ? logs[state.selected_component_id].placeholder.path_arr.map((path,key)=>(
                                <>
                                <span>{path}</span>
                                {
                                    key+1 != logs[state.selected_component_id].placeholder.path_arr.length ?
                                    <span className="flaticon-keyboard-right-arrow-button"></span>  
                                    :null
                                }
                                </>
                            )):null
                        }
                </Breadcrumb>
                
                    <div className=" logs-window main-body">
                  
                    {
                        logs[state.selected_component_id] ? 
                        logs[state.selected_component_id].content ? 
                        logs[state.selected_component_id].content.split('\n').map((line, index) => (
                            <p className="logs-text">{index + 1} :  {line} </p>
                            ))
                         :null: null
                    }
                    </div>
                </TabBody>
            </MainCard>:null
        }
        </>
        
    )
}

export default Logsview;

const MainCard = styled('div')({
    backgroundColor: '#000',
    borderRadius: '0px 0px 8px 8px',
    marginBottom:'50px',
    boxShadow: '0.877px 1.798px 14px 0px rgba(0, 0, 0, 0.15)',
    position:' absolute',
    width:'100%',
    
})

const TabHeader = styled('div')({
    height:'24px',
    backgroundColor:'#000',
    color:'#fff',
    fontSize:'12px',
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    borderBottom:'1px solid #888'
})
const Tablist = styled('div')({
    display:'flex',
    height:'100%',
    alignItems:'center',
    justifyContent:'space-between',
})
const Tab = styled('div')({
    display:'flex',
    alignItems:'center',
    height:'100%',
    justifyContent:'space-between',
    width:'140px',
    borderRight:'1px solid #000',
    backgroundColor:'#615e5e',
    padding:'0px 10px',
    cursor:'pointer',
    '& .active':{
        backgroundColor:'#888!important',
    },
    '& .tab-name': {
    maxWidth:'120px',
    color:'#fff',
    fontSize:'12px',
    whiteSpace: 'nowrap',
    overflow:'hidden',
    textOverflow:'ellipsis',
    },
    '& .tab-name .flaticon-close-button':{
        color:'#fff'
    },
    '& .flaticon-close-button:before':{
        fontSize:'11px!important'
    },
    
})


const TabAction = styled('div')({
    '& button ':{
        backgroundColor:'transparent',
        border:'none'
    },
    '& button span[ class^="flaticon" ]':{
        color:'#fff',
        
    },
    '& button span[ class^="flaticon" ]:before':{
        fontSize:'11px!important',
        
    }
})
const Breadcrumb = styled('div')({
    fontSize:'10px',
    color:'#fff',
    padding:'0px 10px',
    borderBottom:'1px solid #888',
    '& .flaticon-keyboard-right-arrow-button':{
        color:'#fff',
        '&:before':{
            fontSize:'11px!important'
        }
    },
})
const TabBody = styled('div')({
    '& .logs-window.main-body':{

    },
    '& .logs-window':{
        padding:'20px',
        overflowY:'scroll',
        overflowX:'hidden',
        width:'100%',
        height:'400px',
        maxHeight:'800px'
    },
    '& .main-body':{
    '& p':{
        fontSize:'11px',
        color:'#fff'
    }
    }
})
