import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { Input } from './genericComponents/Input';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ClearIcon from '@material-ui/icons/Clear';
import GenericSkeleton from './genericComponents/Skeletons/GenericSkeleton';

export const MULTIPLE = "MULTIPLE";

const SearchBar = ({skeleton,backgrounColor,border,...props}) => {
    if(skeleton){
        return (
            <GenericSkeleton variant={"rect"} height={"42px"} width={"100%"} style={{borderRadius:"6px"}} />
        );
    }
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen(!open);
    }
    const prev_state = props.prev_state ? props.prev_state : null;
    //const clear_state_from_parent = props.clear_state_from_parent ? props.clear_state_from_parent : () => { }
    const search_data = props.search_data;
    const varient = props.varient;
    const params_list = props.params_list;
    const getDefaultState = () => {
        return { data: search_data ? search_data : {} }
    };
    const [state, setState] = useState(getDefaultState());
    const default_filter = props.default_filter;
    const search_call_back = props.search_call_back;
    const clear_search_callback = props.clear_search_callback;


    function onChangeHandler(event) {
        const key = event.target.name;
        const value = event.target.value;

        setState(new_state => ({
            ...new_state,
            data: {
                ...new_state.data,
                [key]: value,
            }
        }));
    }

    function onKeyEnter(event) {
        if (event.key === 'Enter') {
            search_call_back(state.data);
        }
    }

    function clearState() {
        setState({ data: {} });
        setOpen(false);
        clear_search_callback();
    }

    useEffect(() => {
        if (prev_state && prev_state.clear_state) {
            clearState()
        } else {

        }
    }, [prev_state]);


    return (
        
        <OuterDiv backgroundColor={backgrounColor} border={border} className='wrapper-for-search'>
            <Mainbg className="search-bar">
                <div className='d-flex justify-center align-center'>
                    <span className='ri-search-2-line font-24 color-icon-primary'></span>
                    {/* <SearchIcon style={{color:'#939393', fontSize:'2rem'}} /> */}
                </div>
                <SearchInput>
                    <Input
                        type="text"
                        varient="handle-keys"
                        name={default_filter.name}
                        label=""
                        placeholder={default_filter.label}
                        data={state.data[default_filter.name] ? state.data : { [default_filter.name]: "" }}
                        error={{}}
                        onChangeHandler={onChangeHandler}
                        onKeyPress={onKeyEnter}
                    />
                </SearchInput>
                <div className='d-flex align-center' style={{justifyContent: 'space-around'}}>
               
                {
                    varient == MULTIPLE ?
                    <IconButton color="primary" aria-label="Search" onClick={handleToggle}>
                        {
                        !open ? 
                        <ExpandMoreIcon style={{color:'#b4b4b4', fontSize:'30px'}} />
                        : 
                        <ExpandLessIcon style={{color:'#b4b4b4', fontSize:'30px'}} />
                        }                        
                    </IconButton>
                    :
                     null
                }
                 <IconButton  color="primary" aria-label="close" onClick={clearState}>
                        <ClearIcon style={{color:'#939393', fontSize:'20px'}} /> 
                    </IconButton>
                </div>

            </Mainbg>
            <div>
                {
                    open ?
                        <MainFilterBody>
                            {
                                params_list.map(params => (
                                    <Input
                                        type="text"
                                        name={params.name}
                                        label={params.label}
                                        data={state.data[params.name] ? state.data : { [params.name]: "" }}
                                        error={{}}
                                        onChangeHandler={onChangeHandler}
                                    />
                                ))
                            }
                            <ButtonSection>
                                <button onClick={clearState} className="clear-filter-button">Clear Filter</button>
                                <button onClick={() => { search_call_back(state.data) }} className="search-filter-button">Search</button>
                            </ButtonSection>
                        </MainFilterBody>
                        : null
                }
            </div>
        </OuterDiv>
    )
}

SearchBar.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
    open: PropTypes.any,
    prev_state: PropTypes.any,
    search_data: PropTypes.any,
    variant: PropTypes.any,
    params_list: PropTypes.any,
    default_filter: PropTypes.any,
    search_call_back: PropTypes.any,
    clear_search_callback: PropTypes.any,
    backgroundColor: PropTypes.string,
    border: PropTypes.string,
}

export default SearchBar;

const OuterDiv = styled('div')(({ backgroundColor, border})=>({
    background: backgroundColor || '#fff',
    overflow:'hidden',
    transition: 'background 100ms ease-in,width 100ms ease-out',
    borderRadius: '6px',
    border: border || 'none',
}))
const ButtonSection = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0px 10px',
    '& button:first-child': {
        marginRight: '5px'
    }
})
const Mainbg = styled('div')({
    display: 'grid',
    justifyContent: 'space-between',
    position: 'relative',
    gridTemplateColumns:'6% 82% 1fr',
    paddingLeft: "8px",
    '&:focus':{
        border:'1px solid #4671C6'
    },
    '& .input-component': {
        marginBottom: '0px',
        width: '100%',
        height: '100%',
        '& input': {
            padding: '0px 12px',
            height: '42px',
            backgroundColor: 'transparent',
            border: 'none',
            '&:focus': {
                outline: 'none',
                border: 'none'
            },
            '&::placeholder': {
                color: '#fff'
            },

        }
    }
})

const MainFilterBody = styled('div')({
    backgroundColor: '#fff',
    padding: '20px 10px',
    '& .input-component': {
        display: 'grid',
        // gridTemplateColumns:'7% 1% 90%',
        alignItems: 'center'
    },

})
const SearchInput = styled('div')({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'center',
    '& .input-component': {
        '& input:focus': {
            border: 'none'
        }
    },
    '& .btn-clear-search': {
        position: 'absolute',
        right: '0px'
    }
})