import { Checkbox, FormControlLabel, LinearProgress, makeStyles, Popover } from '@material-ui/core'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getJiraTypeIcon } from '../../views/releasePackage/add/components/AddJiraStories';
import GenerateURL, { GenerateSearchURL } from '../../util/APIUrlProvider';
import InvokeApi from '../../util/apiInvoker';
import NumberComponent from './AdvanceSearchFilter/NumberComponent';

const FilterSearch = ({
    onlySingleValueSelectionAllowed,
    selectedCheckBoxes,
    filterLabel,
    filterItemList,
    variant,
    searchPlaceholder,
    noSideBorder,
    onCheckboxUpdate,
    selectFirstByDefault,
    searchUrl,
    filterQueryName,
    searchQueryName,
    parseResultItems,
    resultItemClick
}) => {
    const classes = useStyles({ noSideBorder });
    const dummyFilterOptions = [{ label: 'item1', value: 'item1' }, { label: 'item2', value: 'item2' }, { label: 'item3', value: 'item3' }]
    const [state, setState] = useState({
        selectedChecboxes: [],
        firstSelectedLabel: 'All',
        filterItemList: filterItemList || dummyFilterOptions,
        resultLoading: false,
        search_query: '',
        results: [],
    })
    const [open, setOpen] = useState(false);

    const handleFilterSetOpen = () => {
        setOpen(true);
    }
    const handleFilterClick = useCallback((event) => {
        const isInsideParentDiv = event.target.closest('.filter-box-01');
        if (!isInsideParentDiv) setOpen(false);
    }, []);
    useEffect(() => {
        if (selectedCheckBoxes) {
            let nameOfSelectedData = null;
            if (state.filterItemList && selectedCheckBoxes.length > 0) {
                nameOfSelectedData = state.filterItemList?.find(item => item.value == selectedCheckBoxes[0])
            }
            setState(prevState => ({
                ...prevState,
                selectedChecboxes: selectedCheckBoxes,
                firstSelectedLabel: nameOfSelectedData ? nameOfSelectedData.label : 'All'
            }))
        }
        else {
            setState(prevState => ({
                ...prevState,
                selectedChecboxes: [],

            }))
        }
    }, [selectedCheckBoxes])
    useEffect(() => {
        if (filterItemList.length > 0) {
            if(selectFirstByDefault){
                let firstItem = filterItemList[0];
                let updatedSelectedCheckboxes = [firstItem.value];
                setState((prevState) => ({
                    ...prevState,
                    selectedChecboxes: updatedSelectedCheckboxes,
                    firstSelectedLabel: firstItem.label,
                    filterItemList: filterItemList,
                }))
               // onCheckboxUpdate(updatedSelectedCheckboxes);
            }else{
                setState((prevState) => ({
                    ...prevState,
                    filterItemList: filterItemList
                }))
            }
            
        }
    }, [filterItemList])
    useEffect(() => {
        document.addEventListener('click', handleFilterClick);
        return () => document.removeEventListener('click', handleFilterClick);
    }, [handleFilterClick]);

    useEffect(() => {
        // if(state.search_query.length >=3){
        //     fetchSearchResults(state.search_query);
        // }
        // let timer;
        // if(state.search_query.length > 0){
        //     timer = setTimeout(()=>fetchSearchResults(state.search_query),500);
        // }
        // if(state.search_query.length == 0){
        //     setState((prevState)=>({
        //         ...prevState,
        //         results:[],
        //         api_called: false,
        //     }))
        // }
        // return () => {
        //     clearTimeout(timer)
        // };

        const timer = setTimeout(() => {
            if (state.search_query.length > 0) {
                fetchSearchResults(state.search_query);
            } else {
                setState((prevState) => ({
                    ...prevState,
                    results: [],
                    api_called: false,
                }));
            }
        }, 400);

        return () => clearTimeout(timer);
    }, [state.search_query])

    const checkForChecboxStatus = (name) => {
        if (state?.selectedChecboxes) {
            const found = state?.selectedChecboxes.find(item => item == name)
            if (found) {
                return true
            }
            return false
        }
    }

    const handleChangeForCheckBox = (e, label) => {
        const value = e.target.value
        const isChecked = e.target.checked
        console.log("checkinggg", e);
        let currentSelectedList = state.selectedChecboxes
        if (isChecked) {

            if (onlySingleValueSelectionAllowed) {
                currentSelectedList = []
                setOpen(false);
            }
            currentSelectedList.push(value);
            setState(prevState => ({
                ...prevState,
                selectedChecboxes: currentSelectedList,
                firstSelectedLabel: currentSelectedList?.length == 1 ? label : prevState.firstSelectedLabel,
                search_query: '',
                results:[],
                no_results: false,
                api_called: false
            }))
        }
        else {

            popElementByName(currentSelectedList, value)
            setState(prevState => ({
                ...prevState,
                selectedChecboxes: currentSelectedList,
                firstSelectedLabel: currentSelectedList?.length == 1 ? currentSelectedList[0].label : prevState.firstSelectedLabel,
                search_query: '',
                results:[],
                no_results: false,
                api_called: false
            }))
        }
        //onCheckboxUpdate(currentSelectedList);
    }

    const popElementByName = (arr, name) => {
        const index = arr.indexOf(name);
        if (index !== -1) {
            arr.splice(index, 1);
        }
    }

    function getClassAccordingToStatus(status) {
        let normalizedStatus = status.replace("_", '').toLowerCase();

        if (normalizedStatus == 'inbacklog' || normalizedStatus == 'inprogress' || normalizedStatus == 'qainprogress') {
            return 'greyStatus avtar';
        } else if (normalizedStatus == "won'tfix" || normalizedStatus == 'devdone' || normalizedStatus == 'qadone' || normalizedStatus == 'mergedtomaster' || normalizedStatus == 'closed') {
            return 'greenStatus avtar';
        }

    }
    
    function fetchSearchResults(searchText) {
        let requestInfo = {
            endPoint: GenerateURL({}, GenerateSearchURL({ [filterQueryName]: state.selectedChecboxes, [searchQueryName]: searchText }, searchUrl)),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };
        setState((prevState) => ({
            ...prevState,
            resultLoading: true,
        }));
        InvokeApi(requestInfo, fetchSearchResultsSuccess, fetchSearchResultsFailed);
    }
    function fetchSearchResultsSuccess(response) {
        console.log("shdjdhsjs", response);
        let resultList = parseResultItems(response);
        let no_results = resultList.length>0 ? false : true;
        
        setState((prevState) => ({
            ...prevState,
            resultLoading: false,
            results: resultList,
            no_results: no_results,
            api_called:true,
        }));
        
    }
    function fetchSearchResultsFailed(error) {
        console.log("shdjdhsjs", error);
        setState((prevState) => ({
            ...prevState,
            resultLoading: false,
            results:[],
            no_results:true,
            error: error.toString(),
        }));
    }
    function OnChanageForSearch(e) {
        let name = e.target.name;
        let value = e.target.value;
        setState((prevState) => ({
            ...prevState,
            [name]: value,
            no_results: false,
        }))
    }
    console.log("jsdjsksjk",state);
    const itemMainClick = (item)=>{
        resultItemClick(item);
        setState((prevState)=>({
            ...prevState,
            results: [],
            search_query: '',
            resultLoading: false,
        }))
    }
    return (
        <div style={{ position: 'relative' }}>
            <div className={classes.searchContainer}>
                <div className={`${classes.filter} filter-box-01`} onClick={handleFilterSetOpen}>
                    <div className='d-flex align-center' style={{ gap: "10px",width:'85%' }}>
                        <span className='ri-search-line font-16' style={{ color: "#787878", lineHeight: "1" }}></span>
                        <div className='mt-2 d-flex align-center full-width'>
                            <span className='font-14 font-weight-500 color-key-78 nowrap'>{`Search from ${filterLabel}: `}&nbsp;</span>
                            <span className='font-14 font-weight-500 color-header-new text-ellipsis' style={{display: 'inline-block' }}>{state.firstSelectedLabel}</span>
                            {
                                state.selectedChecboxes?.length > 1 ?
                                        <span className='font-14 font-weight-500 color-header-new'>{`+${state.selectedChecboxes?.length - 1}`}</span>
                                    : null
                            }
                        </div>
                    </div>
                    <span className='ri-arrow-down-s-line font-20 color-icon-secondary'></span>
                    <div className={`${classes.filterSetBox} ${open ? classes.filterSetBoxOpen : ''}`}>
                        {state.filterItemList.map(option => {
                            return (
                                <div className="pl-15">
                                    <FormControlLabel control={<Checkbox checked={checkForChecboxStatus(option.value)} onChange={(e) => handleChangeForCheckBox(e, option.label)} name={option.value} value={option.value} />} label={option.label} />
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className={classes.search}>
                    <input className='search-input' type='text' name='search_query' value={state.search_query} placeholder={searchPlaceholder || 'Search'} onChange={OnChanageForSearch} />
                </div>
            </div>
            {state.resultLoading &&
                <LinearProgress sx={{ backgroundColor: '#0086ff' }} />
            }

            {variant == 'jira' ?
                <div className={classes.resultContainer}>
                    {state.results.length > 0 ? state.results.map((story, index) => {
                        return (
                            <div className={classes.resultItem + " container-item"} key={index} onClick={()=>itemMainClick(story)}>
                                <div className='d-flex align-center' style={{ gap: "12px", width: "93%" }}>
                                    {getJiraTypeIcon(story.issue_type)}
                                    
                                    <div className='font-14 font-weight-500 text-ellipsis color-value' style={{ width: "85%" }}>
                                        {story.jira_story_name}
                                    </div>
                                    
                                </div>
                                <div className={'greyStatus avtar'}>
                                    {story.status}
                                </div>
                            </div>
                        );
                    })
                    :
                     state.no_results && <div className='d-flex align-center justify-center' style={{height:'150px'}}>
                        <span className='font-14 font-weight-500 color-value'>No Results Found</span>
                        </div>
                    }

                </div>
                :
                variant == 'change-set' ?
                    state.search_query.length >0 && state.api_called ? 
                        <div className={classes.services} style={{}}>
                            {state.results.length > 0 ? state.results.map((serv, index) => {
                                return (
                                    <div className='service-item' onClick={()=>itemMainClick(serv)}>
                                        <div className='d-flex f-direction-column '>
                                            <span className='font-12 font-weight-500 color-value'>
                                                {serv.component_name}
                                            </span>
                                            <div>
                                                <span className='ri-apps-line font-16 color-key-78'></span><span className='font-12 font-weight-500 color-key-78 ml-4'>{serv.app}</span>
                                            </div>
                                        </div>
                                        {serv.added &&
                                            <div className='greenStatus'>
                                                Added
                                            </div>
                                        }
                                    </div>
                                );
                            })
                            : 
                                state.no_results && <div className='d-flex align-center justify-center' style={{height:'150px'}}>
                                <span className='font-14 font-weight-500 color-value'>No Results Found</span>
                                </div>
                            }
                        </div>
                    : null:null}
        </div>
    )
}

export default FilterSearch


const useStyles = makeStyles((theme) => ({
    searchContainer: {
        padding: '10px 0px 10px 0px',
        height: "46px",
        border: "1px solid #E6E6E6",
        borderLeft: (props) => props.noSideBorder ? "none" : "",
        borderRight: (props) => props.noSideBorder ? "none" : "",
        backgroundColor: "#FAFAFA",
        width: "100%",
        display: "flex",
        alignItems: 'center',
        borderRadius: (props) => props.noSideBorder ? "0" : "6px",
        borderBottomLeftRadius: '0px !important',
        borderBottomRightRadius: '0px !important'
    },
    filter: {
        padding: "4px 10px 4px 10px",
        height: '46px',
        display: 'flex',
        alignItems: 'center',
        width: '35%',
        borderRight: '1px solid #E6E6E6',
        position: 'relative',
        justifyContent: 'space-between',
    },
    search: {
        padding: "4px 10px 4px 10px",
        height: '46px',
        width: '65%',
        display: 'flex',
        alignItems: 'center',
        "& input": {
            border: "none",
            background: "#FAFAFA",
            outline: "none",
            padding: "0 !important",
            "&::placeholder": {
                color: "#787878 !important",
                fontWeight: '500 !important',
            },
            "&:focus": {
                outline: "none !important",
                boxShadow: "none !important",
                border: "none !important",
            },
        }
    },
    filterSetBox: {
        top: '50px',
        position: 'absolute',
        height: '0px',
        transition: 'height 0.3s ease-in-out',
        width: '95%',
        zIndex: 999,
        background: '#fff',
        borderRadius: '5px',
        overflow: 'hidden',
        //transform: 'scaleY(0)',
        transformOrigin: 'top',
    },
    filterSetBoxOpen: {
        height: '300px',
        boxShadow: '0px 2px 10px 1px #00000026',
    },
    resultContainer: {
        "& > .container-item": {
            borderLeft: 'none',
            borderRight: 'none',
        },
        "& > .container-item:first-child": {
            borderTop: "none",
            borderRadius: "0px",
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
            borderBottom: 'none',
        },

        /* Style for the last child (direct child) */
        "& > .container-item:last-child": {
            borderBottom: "none",
            borderTop: "none",
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
        },
        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: "#0086FF !important",
        }
    },
    resultItem: {
        padding: "12px",
        border: "1px solid #E6E6E6",
        // borderLeft:"none",
        // borderRight:"none",
        display: "flex",
        gap: "12px",
        alignItems: "center",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#f2f2f2",
            borderColor: "#f2f2f2"
        },
        '& .greenStatus': {
            padding: '8px 10px 8px 10px',
            borderRadius: "6px",
            backgroundColor: '#E6FBEA',
            color: '#2EBE79',
            fontSize: '12px',
            fontWeight: '700',
            border: '1px solid #E6FBEA',
            textWrap: 'nowrap'
        },
        '& .greyStatus': {
            padding: '8px 10px 8px 10px',
            borderRadius: "6px",
            backgroundColor: '#F4F4F4',
            color: '#626262',
            fontSize: '12px',
            fontWeight: '700',
            border: '1px solid #E6E6E6',
            textWrap: 'nowrap'
        }
    },
    icon: {
        padding: "6px",
        borderRadius: "4px",
        color: "#ffffff",
        width: "24px",
        height: "24px",
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
    },
    services: {
        width: '100%',
        maxHeight: '183px',
        position: 'absolute',
        //top: '57px',
        padding: '14px 16px 14px 16px',
        borderTopLeftRadius:'0px',
        borderTopRightRadius:'0px',
        borderRadius: '6px',
        border: '1px solid #E6E6E6',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        backgroundColor: '#FFFFFF',
        zIndex: '999',
        overflow: 'auto',

        '& .service-item': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #E6E6E6',
            padding: '8px 0px 8px 0px',
            cursor: 'pointer',
        },
        '& > .service-item:last-child': {
            borderBottom: 'none',
            paddingBottom: '0px',
        },
        '& > .service-item:first-child': {
            paddingTop: '0px',
        },
        '& .greenStatus': {
            padding: '8px 10px 8px 10px',
            borderRadius: "6px",
            backgroundColor: '#E6FBEA',
            color: '#2EBE79',
            fontSize: '12px',
            fontWeight: '700',
            border: '1px solid #E6FBEA',
            textTransform: 'uppercase'
        },
    }
}))