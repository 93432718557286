import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import { useParams, Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import JobListingHeader from "./components/ListingHeader";
import JobListingCard from "./components/JobListingCard";
import GenerateURL, { GenerateSearchURL } from "../../util/APIUrlProvider";
import properties from "../../properties/properties";
import InvokeApi, { PostData } from "../../util/apiInvoker";
import { Loading } from "../utils/Loading";
import { ErrorComponent } from "../utils/Error";
import Pagination from "../../components/Pagination";
import SearchBar from "../../components/SearchBar";
import { getCommonFunctions } from "../serviceRevamp/add/ci_flow/SourceDetails";
import { ValidateDataSet } from "../../util/Validator";
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import SetAsDefaultDialog from "./components/SetAsDefaultDialog";
import JobListingCardNew from "./components/JobListingCardNew";


const JobTemplatesListing = (props) => {
  const classes = useStyles();
  const inherits = props.inherits ? props.inherits : {};
  const [filter, setFilter] = useState({ name: "" });
  const [defaultTemplate,setDefaultTemplate] = useState(null);
  const [state, setState] = useState({
    data_loading: "SUCCESS",
    count: 1,
    job_template_listing: [],
    total_page: "",
    curr_page: "",
    refresh_count: 1,
    child_inherits: {
      update_default_job_template: {
        validateForm: () => {
          return { valid: true };
        },
        getState: () => {
          return {};
        },
        getData: () => {
          return {};
        },
      },
    },
    default_template_created: false
  });
  const { application_id } = useParams();
  console.log("hjbjhbahfbes",defaultTemplate);
  useEffect(() => {
    fetchJobTemplatesInfo(true);
  }, [application_id, state.count, filter, state.refresh_count]);

  function refresh() {
    setState((new_state) => ({
      ...new_state,
      count: new_state.count + 1,
    }));
  }
  const commonFunctions = getCommonFunctions(state, setState, inherits);

  function fetchJobTemplatesInfo(isLoadingNeeded) {
    let myEndPoint = GenerateURL(
      { application_id: application_id },
      properties.api.add_job_template
    );

    var requestInfo = {
      endPoint: GenerateSearchURL(filter, myEndPoint),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    InvokeApi(
      requestInfo,
      jobTemplatesInfoFetchSuccess,
      jobTemplatesInfoFetchFailure
    );

    isLoadingNeeded && setState((new_state) => ({
      ...new_state,
      data_loading: "LOADING",
    }));
  }

  function jobTemplatesInfoFetchSuccess(data) {
    let default_template = data.results.find((template)=> template.default == true);
    setDefaultTemplate(default_template);
    setState((new_state) => ({
      ...new_state,
      data_loading: "SUCCESS",
      job_template_listing: data.results,
      next: data.next ? properties.api.baseURL + data.next : null,
      previous: data.previous ? properties.api.baseURL + data.previous : null,
      total_page: Math.ceil(Number(data.count / 10)),
      curr_page: 1,
    }));
  }
  function jobTemplatesInfoFetchFailure(error, exception) {
    setState((new_state) => ({
      ...new_state,
      data_loading: "FAILED",
      error: error,
    }));
  }

  function fetchPrevJobTemplateInfo(data, url) {
    var requestInfo = {
      endPoint: url,
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    setState((new_state) => ({
      ...new_state,
      current: requestInfo.endPoint,
      data_loading: "LOADING",
    }));
    InvokeApi(
      requestInfo,
      JobTemplatePrevInfoFetchSuccess,
      JobTemplatePrevInfoFetchFailure
    );
  }

  function JobTemplatePrevInfoFetchSuccess(data) {
    let default_template = data.results.find((template)=> template.default == true);
    setDefaultTemplate(default_template);
    setState((new_state) => ({
      ...new_state,
      data_loading: "SUCCESS",
      job_template_listing: data.results,
      next: data.next ? properties.api.baseURL + data.next : null,
      previous: data.previous ? properties.api.baseURL + data.previous : null,
      page_start: state.page_start - data.results.length,
      curr_page: Number(state.curr_page - 1),
    }));
  }

  function JobTemplatePrevInfoFetchFailure(error, exception) {
    setState((new_state) => ({
      ...new_state,
      data_loading: "FAILED",
      error: true,
    }));
  }

  //start of page code----------------------------------------------

  function fetchPageJobTemplateInfo(enteredPageNumber) {
    let myEndPoint = GenerateURL(
      { application_id: application_id },
      properties.api.add_job_template
    );

    var requestInfo = {
      endPoint: GenerateSearchURL(filter, myEndPoint),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    if (enteredPageNumber > 1) {
      requestInfo.endPoint =
        requestInfo.endPoint +
        "?limit=10&name=&offset=" +
        (enteredPageNumber - 1) * 10;
    }

    setState((new_state) => ({
      ...new_state,
      current: requestInfo.endPoint,
      data_loading: "LOADING",
    }));

    var current_page = enteredPageNumber;

    InvokeApi(
      requestInfo,
      (response) => {
        JobTemplatePageInfoFetchSuccess(response, current_page)
      }
      ,
      JobTemplatePageInfoFetchFailure
    );
  }

  function JobTemplatePageInfoFetchSuccess(data, count) {
    let default_template = data.results.find((template)=> template.default == true);
    setDefaultTemplate(default_template);
    setState((new_state) => ({
      ...new_state,
      data_loading: "SUCCESS",
      job_template_listing: data.results,
      next: data.next ? properties.api.baseURL + data.next : null,
      previous: data.previous ? properties.api.baseURL + data.previous : null,
      page_start: state.page_start - data.results.length,
      curr_page: Number(count)
    }));
  }

  function JobTemplatePageInfoFetchFailure(error, exception) {
    setState((new_state) => ({
      ...new_state,
      data_loading: "FAILED",
      error: true,
    }));
  }

  //end of page code------------------------------------------------

  function fetchNextJobTemplateInfo(data, url) {
    var requestInfo = {
      endPoint: url,
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    InvokeApi(
      requestInfo,
      JobTemplateNextInfoFetchSuccess,
      JobTemplateNextInfoFetchFailure
    );

    setState((new_state) => ({
      ...new_state,
      search_data: data,
      current: requestInfo.endPoint,
      data_loading: "LOADING",
    }));
  }

  function JobTemplateNextInfoFetchSuccess(data) {
    let default_template = data.results.find((template)=> template.default == true);
    setDefaultTemplate(default_template);
    setState((new_state) => ({
      ...new_state,
      data_loading: "SUCCESS",
      job_template_listing: data.results,
      next: data.next ? properties.api.baseURL + data.next : null,
      page_start: state.page_start + data.results.length,
      previous: data.previous ? properties.api.baseURL + data.previous : null,
      curr_page: Number(new_state.curr_page + 1),
    }));
  }
  function JobTemplateNextInfoFetchFailure(error, exception) {
    setState((new_state) => ({
      ...new_state,
      data_loading: "FAILED",
      error: true,
    }));
  }

  function ValidateAndSave(template_id) {
    var result = ValidateDataSet(state.data, state.validations);
    var child_states = inherits.getState ? inherits.getState() : null;
    var children_vaidation_result = inherits.validateForm
      ? inherits.validateForm()
      : { valid: true };
    var update_default_temp_child = child_states.update_default_job_template;
    var post_url = GenerateURL(
      { application_id: application_id, template_id: template_id },
      properties.api.edit_template
    );

    if (result.valid && children_vaidation_result) {

      var selected_job_object = state.job_template_listing?.find(job => job.id == template_id)

      var job_template_data = {
        ...selected_job_object,
        default: true,
      };

      PostData(post_url, job_template_data,(job_template_data)=>{postSuccess(job_template_data)}, postFail);

      setState((new_state) => ({
        ...new_state,
        //data_loading: "LOADING",
      }));
    }
  }

  function postSuccess(job_template_data) {
    //refresh();
    setDefaultTemplate(job_template_data);
    setState((new_state) => ({
      ...new_state,
      data_loading: "SUCCESS",
      canRedirect: true,
      error_msg: null,
    }));
  }

  function postFail(response) {
    setState((new_state) => ({
      ...new_state,
      error_msg: response.toString(),
      //data_loading: "FAILED",
    }));
  }

  console.log("js_s", state);

  const handleSearchChange = (filter_name) => {
    setFilter(filter_name);
    setState((new_state) => ({
      ...new_state,
      search_query_name: filter_name
        ? filter_name.name
          ? filter_name.name
          : ""
        : "",
    }));
  };

  const createDefaultTemplate = () => {
    const defaultTemplateUrl = GenerateURL({ application_id: application_id }, properties.api.create_default_job_template)

    PostData(defaultTemplateUrl, {},
      (response) => {
        console.log(response, 'create_response_jtb')
        setState(prevState => ({
          ...prevState,
          default_template_created: true
        }))
        fetchJobTemplatesInfo(false);
      },
      (error) => {
        console.log(error, 'error_jbt_ssd');
      }
    )
  }

  const onCloseAlert = () => {
    setState(prevState => ({
      ...prevState,
      default_template_created: false
    }))
  }

  function sendApprovalRequest(id) {
    var post_url = GenerateURL({}, properties.api.approval_request);

    var post_obj = {
      "name": "project_job_templates",
      "entity_id": id
    }

    PostData(post_url, post_obj, sendApprovalRequestSuccess, sendApprovalRequestFailed);
    setState(new_state => ({
      ...new_state,
      loading: true,
      error_msg: null,
      open: true
    }));
  }

  function sendApprovalRequestSuccess(response) {
    refreshState()
    var msg = response.detail
    setState(new_state => ({
      ...new_state,
      approval_request_success_msg: msg,
    }));

  }

  function sendApprovalRequestFailed(error) {
    var error = error.toString()
    setState(new_state => ({
      ...new_state,
      deployment_failed_msg: error
    }));

  }

  function confirmForApproval(id) {
    var post_url = GenerateURL({}, properties.api.confirm_approval);

    var post_obj = {
      "name": "project_job_templates",
      "entity_id": id
    }

    PostData(post_url, post_obj, confirmApprovalRequestSuccess, confirmApprovalRequestFailed);
    setState(new_state => ({
      ...new_state,
      loading: true,
      error_msg: null,
      open: true
    }));
  }

  function confirmApprovalRequestSuccess(response) {
    refreshState()
    var msg = response.detail
    setState(new_state => ({
      ...new_state,
      approval_confirm_success_msg: msg,
    }));
  }

  function confirmApprovalRequestFailed(error) {
    var error = error.toString()
    setState(new_state => ({
      ...new_state,
      approval_failed_msg: error
    }));
  }

  function refreshState() {
    setState(new_state => ({
      ...new_state,
      refresh_count: new_state.refresh_count + 1
    }));
  }

  const setDefault = (data) => {
    // state.job_template_listing
    console.log(data);
  }

  console.log(state.job_template_listing, 'temp_sta_001');
  return (
    <>
      {state.data_loading == "SUCCESS" ? (
        <div className={classes.root}>
          <JobListingHeader
            onCreateDefaultTemplate={createDefaultTemplate}
            changeDefaultJobTemplate={ValidateAndSave}
            application_id={application_id}
            inherits={state.child_inherits.update_default_job_template}
            template_list={
              state.job_template_listing ? state.job_template_listing : []
            }
          />
          {state.job_template_listing &&
            state.job_template_listing.length > 0 ? <>

            <div className="w-100 d-flex align-center space-between mb-24" style={{ height: "40px" }}>
              <div style={{ width: "37%", }}>
                <SearchBar
                  search_data={filter}
                  default_filter={{
                    name: "name",
                    label: "Search for job template",
                  }}
                  search_call_back={handleSearchChange}
                  clear_search_callback={() => setFilter({ name: "" })}
                />
              </div>
              <div>
                <SetAsDefaultDialog
                  varient={"jobtemplatelisting"}
                  onCreateDefaultTemplate={createDefaultTemplate}
                  application_id={application_id}
                  changeDefaultJobTemplate={ValidateAndSave}
                  inherits={state.child_inherits.update_default_job_template}
                  template_list={
                    state.job_template_listing ? state.job_template_listing : []
                  }
                />
              </div>
            </div>
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={state.default_template_created}
              onClose={onCloseAlert}
              autoHideDuration={2000}>
              <Alert severity="success">
                {"Default project job templates created if not exists"}
              </Alert>
            </Snackbar>
          </>
            : null}
          {state.job_template_listing &&
            state.job_template_listing.length > 0 ? (

            <div className="w-100" style={{
              gap: "16px", display: "grid",
              gridTemplateColumns: "1fr 1fr"
            }}>

              {/* -------here is the card of, i have mapped this card------- */}
              {state.job_template_listing.map((item, index) => (
                <JobListingCardNew data={item} refreshFn={refresh} sendForApproval={sendApprovalRequest} selected_job_template_id = {defaultTemplate?.id}
                  confirmForApproval={confirmForApproval} key={index} setDefault={setDefault} changeDefaultJobTemplate={ValidateAndSave} />
              ))}
            </div>

          ) : state.search_query_name ? (
            <div
              className="main-container-error"
              style={{ height: "60vh", margin: "auto" }}
            >
              <div className="svg">
                <div className="servicePanel">
                  <div className="blank-div">
                    <div className="blank-div-text">
                      No Templates found with this name :{" "}
                      {state.search_query_name}
                    </div>
                    <div>
                      <button
                        onClick={() => setFilter({ name: "" })}
                        className="btn btn-submit"
                      >
                        Refresh
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="main-container-error"
              style={{ height: "60vh", margin: "auto" }}
            >
              <div className="svg">
                <div className="servicePanel">
                  <div className="blank-div">
                    <div className="blank-div-text">
                      No Templates associated with this Application
                    </div>
                    <div>
                      <Link
                        to={
                          "/application/" + application_id + "/job-template/add"
                        }
                        className="btn btn-submit"
                      >
                        <div>Add Job Template</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : state.data_loading == "FAILED" ? (
        <ErrorComponent variant="error-box" error={state.error} />
      ) : (
        <Loading />
      )}

      {state.job_template_listing &&
        state.job_template_listing.length > 0 ?
        <div className="mb-24">
          <Pagination
            current_page={state.curr_page}
            current_page_count={state.curr_page}
            total_count={state.total_page}
            next={state.next}
            previous={state.previous}
            on_previous_click={() => {
              fetchPrevJobTemplateInfo(null, state.previous);
            }}
            on_next_click={() => {
              fetchNextJobTemplateInfo(null, state.next);
            }}
            on_pageNumber_click={(pageNumber) => {
              fetchPageJobTemplateInfo(pageNumber);
            }}
          />
        </div> : null}

    </>
  );
};

JobTemplatesListing.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
};

const useStyles = makeStyles({
  root: {
    position: "relative",
    margin: 20,
  },
});

export default JobTemplatesListing;
