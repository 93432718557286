import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Input } from '../../../../components/genericComponents/Input';
import Grid from '@material-ui/core/Grid';
import {  VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
import { getCommonFunctions } from '../ci_flow/SourceDetails';
import { RemoveFromArray } from '../../../../util/util';


const ResourceQuotaContainer = (props) => {
    const classes = useStyles();
    const inherits = props.inherits ? props.inherits : {};
    const prev_state = props.prev_state ? props.prev_state : null;
    const type = props.type ? props.type : null;
    const extraProps = props.extraProps ? props.extraProps : null;
    const child_build_data = props.child_build_data
    const [state, setState] = useState(prev_state ? prev_state : getResourceQuotaDefaultState(extraProps))
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    useEffect(() => {
        console.log(prev_state,"sdvgdsvhgsdvsf")
        if( prev_state && prev_state.data.env_ci_detail_id){
            setState((new_state) => ({
                ...new_state,
                open : true
            }))
        }else{
            setState(new_state => ({ ...new_state, ...prev_state }))
        }
        // setState(new_state => ({ ...new_state, ...prev_state }))
    }, [prev_state])

    useEffect(() => {
        if (!state.data.child_build_enabled) {
            setState((new_state) => ({
                ...new_state,
                data: {
                    ...new_state.data,
                    env_ci_detail_id: null
                }
            }))
        }
    }, [state.data.child_build_enabled])

    console.log(child_build_data, "ssdknhbcdhbzcs");
    console.log(state, "fdsfsafsafas")
    const onChangeToggleState = (e) => {
        var key = e.target.name;
        var value = e.target.value
        var switch_key = [
            "specify_limit_quota"
        ]
        if (switch_key.includes(key)) {
            value = !state.data[key];
        }
        commonFunctions.toggleState(e);
        if (value) {
            setState(new_state => ({
                ...new_state,
                validations: {
                    ...new_state.validations,
                    limits_memory_quota: [VALIDATION_TYPE_REQUIRED],
                    limits_cpu_quota: [VALIDATION_TYPE_REQUIRED]
                }
            }))
        } else {
            RemoveFromArray(state.validations.limits_memory_quota, VALIDATION_TYPE_REQUIRED)
            RemoveFromArray(state.validations.limits_cpu_quota, VALIDATION_TYPE_REQUIRED)
        }


    }
    console.log(state.data, "dsbchjbdscjhbsdhbcsd")

    function getChildBuildList(data) {
        var list_arr = []
        data.forEach(element => {
            var option = {
                id: element.id,
                label: element.type_label
            }
            list_arr.push(option)
        });
        console.log(list_arr, "ashbhasbchjbhasd")
        return list_arr;
    }

    function toggleState() {
        setState({
            ...state,
            open: !state.open,
        });
    }

    const imageNameInfo = `In the case of private repositories please provide the complete private registry URL along with the image name 
    \n Syntax: <REGISTRY URL>/<REPO_NAME>:<TAG> 
    \n Example: registry.buildpiper.io/bp:latest`;
    return (
        <>

            <div className={`${classes.root} ci-cd-edit-input-div`}>

                <>
                    <Grid item lg={12} direction="row" className="card-add-service-head">
                        <div className="formTag">
                            <h4 className='mainHeading'>Deployment Details</h4>
                        </div>
                    </Grid>
                    <Grid container direction="row" justifyContent="flex-start" className="card-add-service-body">
                        <Grid item lg={6}>
                            <div className='section'>
                                <Input
                                    type="text"
                                    placeholder="Give a name to your container"
                                    name="container_name"
                                    label="Container Name"
                                    mandatorySign
                                    error={state.error}
                                    onChangeHandler={commonFunctions.onChangeHandler}
                                    data={state.data} />
                            </div>
                        </Grid>
                        <Grid item lg={6}>
                            <div className='section'>
                                <Input
                                    type="select"
                                    name="container_type"
                                    label="Container Type"
                                    mandatorySign
                                    list={[{ label: 'Init Container', id: 'INIT' }, { label: 'Sidecar Container', id: 'SIDECAR' }]}
                                    error={state.error}
                                    onChangeHandler={commonFunctions.onChangeHandler}
                                    data={state.data} />
                            </div>
                        </Grid>
                        {/* {
                            state.data.container_type == "INIT" && child_build_data && child_build_data.length > 0 &&
                            <Grid item lg={12}>
                                <div className='card'>
                                    <Input
                                        type="switch"
                                        name="child_build_enabled"
                                        label="Do you want to deploy child build?"
                                        onChangeHandler={onChangeToggleState}
                                        data={state.data}
                                        error={state.error}
                                    />
                                    {
                                        state.data.child_build_enabled &&
                                        <div div className='card-body'>
                                            <Grid item lg={12}>
                                                <div className='section'>
                                                    <Input
                                                        type="select"
                                                        label="Select Child Build"
                                                        name="env_ci_detail_id"
                                                        placeholder="Child Build"
                                                        mandatorySign
                                                        list={getChildBuildList(child_build_data)}
                                                        error={state.error}
                                                        data={state.data}
                                                        onChangeHandler={commonFunctions.onChangeHandler}
                                                    />
                                                </div>
                                            </Grid>
                                        </div>
                                    }
                                </div>
                            </Grid>
                        } */}
                        {
                            state.data.container_type == "INIT" && child_build_data && child_build_data.length > 0 ?
                                <Grid item lg={state.open ? 12 : 6}>
                                    <div className='section'>
                                        <Input
                                            style={{ height: "41px", width: "100%" }}
                                            type={state.open ? "select" : "text"}
                                            name={state.open ? "env_ci_detail_id" : "image_name"}
                                            placeholder="Child Build"
                                            subHeading="(Optional)"
                                            getOptionLabel={(option) => option.label}
                                            list={getChildBuildList(child_build_data)}
                                            label="Image Name"
                                            data={state.data}
                                            error={state.error}
                                            onChangeHandler={commonFunctions.onChangeHandler}
                                            extraDiv={<p className="mr-0 cursor-pointer text-anchor-blue pr-0" onClick={toggleState}>{state.open ? "Enter Custom Image Name" : "Choose secondary build image name"}</p>}
                                        />
                                    </div>
                                </Grid>
                                :
                                <Grid item lg={6}>
                                    <div className='section'>
                                        <Input
                                            type="text"
                                            placeholder="Image Name"
                                            name="image_name"
                                            label="Image Name"
                                            mandatorySign
                                            error={state.error}
                                            onChangeHandler={commonFunctions.onChangeHandler}
                                            data={state.data}
                                            info={imageNameInfo}
                                        />
                                    </div>
                                </Grid>
                        }

                        {
                            !state.open &&
                            <Grid item lg={6}>
                                <div className='section'>
                                    <Input
                                        type="text"
                                        placeholder="Tag"
                                        name="tag"
                                        label="Enter tag"
                                        mandatorySign
                                        error={state.error}
                                        onChangeHandler={commonFunctions.onChangeHandler}
                                        data={state.data} />
                                </div>
                            </Grid>
                        }

                    </Grid>
                </>
                <Grid container className="card-add-service">
                    <Grid item lg={12} direction="row" className="card-add-service-head">
                        <div className="formTag">
                            <h4 className='mainHeading'>Set Resource Quota</h4>
                        </div>
                    </Grid>
                    <Grid container justify="center" className="card-add-service-body">
                        <Grid item lg={6}>
                            <div className='section'>
                                <Input
                                    type="text"
                                    placeholder="100"
                                    subHeading="(in Mi)"
                                    name="requests_memory_quota"
                                    label="Request Memory Quota"
                                    mandatorySign
                                    error={state.error}
                                    onChangeHandler={commonFunctions.onChangeHandlerNumber}
                                    data={state.data} />
                            </div>
                        </Grid>
                        <Grid item lg={6}>
                            <div className='section'>
                                <Input
                                    type="text"
                                    placeholder="100"
                                    name="requests_cpu_quota"
                                    mandatorySign
                                    subHeading="(in MiliCore)"
                                    label="Request CPU Quota"
                                    error={state.error}
                                    onChangeHandler={commonFunctions.onChangeHandlerNumber}
                                    data={state.data} />
                            </div>
                        </Grid>
                        <Grid item lg={12}>
                            <div className='card'>
                                <div className='heading'>
                                    <Input
                                        type="switch"
                                        name="specify_limit_quota"
                                        label="Specify limit quota else limit quota will be same as request quota"
                                        onChangeHandler={onChangeToggleState}
                                        data={state.data}
                                        error={state.error} />
                                    {
                                        state.data.specify_limit_quota &&
                                        <div className='card-body'>
                                            <Grid container spacing="2" >
                                                <Grid item lg={6}>
                                                    <div className='section'>
                                                        <Input
                                                            type="text"
                                                            subHeading="(in Mi)"
                                                            placeholder="100"
                                                            name="limits_memory_quota"
                                                            mandatorySign
                                                            label="Limit Memory Quota"
                                                            error={state.error}
                                                            onChangeHandler={commonFunctions.onChangeHandlerNumber}
                                                            data={state.data} />
                                                    </div>
                                                </Grid>
                                                <Grid item lg={6}>
                                                    <div className='section'>
                                                        <Input
                                                            type="text"
                                                            subHeading="(in MiliCore)"
                                                            placeholder="100"
                                                            name="limits_cpu_quota"
                                                            mandatorySign
                                                            label="Limit CPU Quota"
                                                            error={state.error}
                                                            onChangeHandler={commonFunctions.onChangeHandlerNumber}
                                                            data={state.data} />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    }
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>

            </div>

        </>


    )
}

ResourceQuotaContainer.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default ResourceQuotaContainer;


export function getResourceQuotaDefaultState(extraProps) {
    const service_name = extraProps ? extraProps.service_name : "";
    const env_master = extraProps ? extraProps.environment_master_name : "";
    const project_env_name = extraProps ? extraProps.project_env_name : "";
    return {
        data: {
            requests_memory_quota: 100,
            requests_cpu_quota: 100,
            limits_memory_quota: 500,
            limits_cpu_quota: 500,
            image_name: service_name ? (service_name + "/" + env_master + "/" + project_env_name) : "",
        },
        error: {},
        validations: {
            requests_memory_quota: [VALIDATION_TYPE_REQUIRED],
            requests_cpu_quota: [VALIDATION_TYPE_REQUIRED],
            container_name: [VALIDATION_TYPE_REQUIRED],
            container_type: [VALIDATION_TYPE_REQUIRED],
            image_name: [VALIDATION_TYPE_REQUIRED],
            tag: [VALIDATION_TYPE_REQUIRED],
            limits_memory_quota: [],
            limits_cpu_quota: []
        },

    };
}

const useStyles = makeStyles(theme => ({
    root: {
        '& .input-with-btn': {
            border: '0px'
        },
        padding: '0px',
        '& > .card-add-service': {
            borderRadius: '0px',
            border: '0px'
        },
        '& .card-add-service-head': {
            borderTop: '1px solid #e7e7eb',
            borderRadius: '0px',
        },
        '& > .card': {
            backgroundColor: '#f6f8f8',
            borderRadius: '8px',
            border: '1px',
            '& .card-footer': {
                backgroundColor: '#fff',
                justifyContent: 'flex-end'
            },
            '& .card ': {
                marginBottom: '15px',
                boxShadow: 'none',
                '& .input-component': {
                    marginBottom: '0px'
                }
            },

        }
    }
}))
