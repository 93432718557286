import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams, Link } from "react-router-dom";
import JobListingHeader from "./components/ListingHeader";
import GenerateURL, { GenerateSearchURL } from "../../util/APIUrlProvider";
import properties from "../../properties/properties";
import InvokeApi, { PostData } from "../../util/apiInvoker";
import { Loading, LoadingContainer } from "../utils/Loading";
import { ErrorComponent } from "../utils/Error";
import Pagination from "../../components/Pagination";
import SearchBar from "../../components/SearchBar";
import { getCommonFunctions } from "../serviceRevamp/add/ci_flow/SourceDetails";
import { ValidateDataSet } from "../../util/Validator";
import { Grid, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import SetAsDefaultDialog from "./components/SetAsDefaultDialog";
import JobListingCardNew from "./components/JobListingCardNew";
import CreateDefaultTemplate from "./components/CreateDefaultTemplate";
import { showErrorHandlerUpdated } from "../../util/util";
import AdvanceSearchFilterCombo from "../../components/genericComponents/AdvanceSearchFilter/AdvanceSearchFilterCombo";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const GlobalJobTemplates = (props) => {
  const classes = useStyles();
  const inherits = props.inherits ? props.inherits : {};
  const history = useHistory();
  const [filter, setFilter] = useState({ name: "" });
  const [defaultTemplate, setDefaultTemplate] = useState(null);
  const [state, setState] = useState({
    data_loading: "SUCCESS",
    count: 1,
    job_template_listing: [],
    total_page: "",
    curr_page: "",
    refresh_count: 1,
    child_inherits: {
      update_default_job_template: {
        validateForm: () => {
          return { valid: true };
        },
        getState: () => {
          return {};
        },
        getData: () => {
          return {};
        },
      },
    },
    moreAdvFilterList: [],
    advFilters: {
      name: [],
      job_template_tag: []
    },
    default_template_created: false
  });
  const defaultFilters = ["name", "job_template_tag"]
  
  const resetFilterData = {
    name: [],
    job_template_tag: []
  }
  

  const { application_id } = useParams();
  console.log("hjbjhbahfbes",defaultTemplate);
  useEffect(() => {
    fetchJobTemplatesInfo(true);
  }, [application_id, state.count, filter, state.refresh_count]);
  useEffect(() => {
    resetAdvFilter();
  }, [])
  
  useEffect(() => {
    isFiltersApplied()
  }, [state.advFilters])
  function isFiltersApplied() {
    let count = 0;
    Object.keys(state.advFilters).forEach((item) => {
      if (state.advFilters[item].length > 0) {
        count = count + 1;
      }
    })

    setState((new_state) => ({
      ...new_state,
      is_filters_applied: count > 0
    }))
  }


  function fetchSystemSettingsData() {
    var requestInfo = {
        endPoint: GenerateURL({}, properties.api.system_settings),
        httpMethod: "GET",
        httpHeaders: { "Content-Type": "application/json" }
    }
    InvokeApi(requestInfo, fetchSystemSettingsDataSuccess, fetchSystemSettingsDataFailed);

    setState(new_state => ({
        ...new_state,
        data_loading: "LOADING"
    }))

}

function fetchSystemSettingsDataSuccess(data) {
    let filter_version_setting = data.find(item => item.key == "VERSIONING_CD_ENABLE");
    
    setState(new_state => ({
        ...new_state,
        v3_allowed: "true" //filter_version_setting &&filter_version_setting.value,
    }));

}
function fetchSystemSettingsDataFailed(error) {
    let error_msg = showErrorHandlerUpdated(error)
    setState(new_state => ({
        ...new_state,
        error: error_msg,
        data_loading: "FAILED"
    }))
}
  const seprateByVersions=(arr, )=>{

  }


  function refresh() {
    setState((new_state) => ({
      ...new_state,
      count: new_state.count + 1,
    }));
  }
  const commonFunctions = getCommonFunctions(state, setState, inherits);

  function fetchJobTemplatesInfo(isLoadingNeeded) {
    console.log("checking if this fn calling")
    let myEndPoint = GenerateURL({},
      properties.api.get_global_job_template
    );

    var requestInfo = {
      endPoint: GenerateSearchURL(filter, myEndPoint), //GenerateURL({}, properties.api.get_global_job_template ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    InvokeApi(
      requestInfo,
      jobTemplatesInfoFetchSuccess,
      jobTemplatesInfoFetchFailure
    );

    isLoadingNeeded && setState((new_state) => ({
      ...new_state,
      data_loading: "LOADING",
    }));
  }

  function jobTemplatesInfoFetchSuccess(data) {
    let default_template = data.results.find((template) => template.default == true);
    setDefaultTemplate(default_template);
    let v2_templates = data.results.filter(item => item.version ==="v2");
    let v3_templates = data.results.filter(item => item.version ==="v3");
    console.log(v2_templates,"v2_templates", v3_templates)
    fetchSystemSettingsData() ;
   
    setState((new_state) => ({
      ...new_state,
      data_loading: "SUCCESS",
      job_template_listing: data.results,
      next: data.next ? properties.api.baseURL + data.next : null,
      previous: data.previous ? properties.api.baseURL + data.previous : null,
      total_page: Math.ceil(Number(data.count / 10)),
      curr_page: 1,
    }));
  }
  function jobTemplatesInfoFetchFailure(error, exception) {
    setState((new_state) => ({
      ...new_state,
      data_loading: "FAILED",
      error: error,
    }));
  }

  function fetchPrevJobTemplateInfo(data, url) {
    var requestInfo = {
      endPoint: url,
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    setState((new_state) => ({
      ...new_state,
      current: requestInfo.endPoint,
      data_loading: "LOADING",
    }));
    InvokeApi(
      requestInfo,
      JobTemplatePrevInfoFetchSuccess,
      JobTemplatePrevInfoFetchFailure
    );
  }

  function JobTemplatePrevInfoFetchSuccess(data) {
    let default_template = data.results.find((template) => template.default == true);
    setDefaultTemplate(default_template);
    setState((new_state) => ({
      ...new_state,
      data_loading: "SUCCESS",
      job_template_listing: data.results,
      next: data.next ? properties.api.baseURL + data.next : null,
      previous: data.previous ? properties.api.baseURL + data.previous : null,
      page_start: state.page_start - data.results.length,
      curr_page: Number(state.curr_page - 1),
    }));
  }

  function JobTemplatePrevInfoFetchFailure(error, exception) {
    setState((new_state) => ({
      ...new_state,
      data_loading: "FAILED",
      error: true,
    }));
  }

  //start of page code----------------------------------------------

  function fetchPageJobTemplateInfo(enteredPageNumber) {
    console.log("checking if this fn calling 1")
    let myEndPoint = GenerateURL(
      {},
      properties.api.get_global_job_template
    );

    var requestInfo = {
      endPoint: GenerateSearchURL(filter, myEndPoint),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    if (enteredPageNumber > 1) {
      requestInfo.endPoint =
        requestInfo.endPoint +
        "?limit=10&name=&offset=" +
        (enteredPageNumber - 1) * 10;
    }

    setState((new_state) => ({
      ...new_state,
      current: requestInfo.endPoint,
      data_loading: "LOADING",
    }));

    var current_page = enteredPageNumber;

    InvokeApi(
      requestInfo,
      (response) => {
        JobTemplatePageInfoFetchSuccess(response, current_page)
      }
      ,
      JobTemplatePageInfoFetchFailure
    );
  }

  function JobTemplatePageInfoFetchSuccess(data, count) {
    let default_template = data.results.find((template) => template.default == true);
    setDefaultTemplate(default_template);
    setState((new_state) => ({
      ...new_state,
      data_loading: "SUCCESS",
      job_template_listing: data.results,
      next: data.next ? properties.api.baseURL + data.next : null,
      previous: data.previous ? properties.api.baseURL + data.previous : null,
      page_start: state.page_start - data.results.length,
      curr_page: Number(count)
    }));
  }

  function JobTemplatePageInfoFetchFailure(error, exception) {
    setState((new_state) => ({
      ...new_state,
      data_loading: "FAILED",
      error: true,
    }));
  }


  function fetchNextJobTemplateInfo(data, url) {
    var requestInfo = {
      endPoint: url,
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    InvokeApi(
      requestInfo,
      JobTemplateNextInfoFetchSuccess,
      JobTemplateNextInfoFetchFailure
    );

    setState((new_state) => ({
      ...new_state,
      search_data: data,
      current: requestInfo.endPoint,
      data_loading: "LOADING",
    }));
  }

  function JobTemplateNextInfoFetchSuccess(data) {
    let default_template = data.results.find((template) => template.default == true);
    setDefaultTemplate(default_template);
    setState((new_state) => ({
      ...new_state,
      data_loading: "SUCCESS",
      job_template_listing: data.results,
      next: data.next ? properties.api.baseURL + data.next : null,
      page_start: state.page_start + data.results.length,
      previous: data.previous ? properties.api.baseURL + data.previous : null,
      curr_page: Number(new_state.curr_page + 1),
    }));
  }
  function JobTemplateNextInfoFetchFailure(error, exception) {
    setState((new_state) => ({
      ...new_state,
      data_loading: "FAILED",
      error: true,
    }));
  }

  function ValidateAndSave(template_id) {
    var result = ValidateDataSet(state.data, state.validations);
    var child_states = inherits.getState ? inherits.getState() : null;
    var children_vaidation_result = inherits.validateForm
      ? inherits.validateForm()
      : { valid: true };
    var update_default_temp_child = child_states.update_default_job_template;
    var post_url = GenerateURL(
      { template_id: template_id },
      properties.api.get_single_global_job_template
    );

    if (result.valid && children_vaidation_result) {

      var selected_job_object = state.job_template_listing?.find(job => job.id == template_id)

      var job_template_data = {
        ...selected_job_object,
        default: true,
      };

      PostData(post_url, job_template_data, (job_template_data) => { postSuccess(job_template_data) }, postFail);

      setState((new_state) => ({
        ...new_state,
        //data_loading: "LOADING",
      }));
    }
  }

  function postSuccess(job_template_data) {
    //refresh();
    setDefaultTemplate(job_template_data);
    setState((new_state) => ({
      ...new_state,
      data_loading: "SUCCESS",
      canRedirect: true,
      error_msg: null,
    }));
  }

  function postFail(response) {
    setState((new_state) => ({
      ...new_state,
      error_msg: response.toString(),
      //data_loading: "FAILED",
    }));
  }


  const handleSearchChange = (filter_name) => {
    setFilter(filter_name);
    setState((new_state) => ({
      ...new_state,
      search_query_name: filter_name
        ? filter_name.name
          ? filter_name.name
          : ""
        : "",
    }));
  };

  const createDefaultTemplate = () => {
    console.log("checking if this fn calling 2")
    const defaultTemplateUrl = GenerateURL({ }, properties.api.get_global_job_template)

    PostData(defaultTemplateUrl, {},
      (response) => {
        setState(prevState => ({
          ...prevState,
          default_template_created: true
        }))
        fetchJobTemplatesInfo(false);
      },
      (error) => {
        console.log(error, 'error_jbt_ssd');
      }
    )
  }

  const onCloseAlert = () => {
    setState(prevState => ({
      ...prevState,
      default_template_created: false
    }))
  }

  function sendApprovalRequest(id) {
    var post_url = GenerateURL({}, properties.api.approval_request);

    var post_obj = {
      "name": "project_job_templates",
      "entity_id": id
    }

    PostData(post_url, post_obj, sendApprovalRequestSuccess, sendApprovalRequestFailed);
    setState(new_state => ({
      ...new_state,
      loading: true,
      error_msg: null,
      open: true
    }));
  }

  function sendApprovalRequestSuccess(response) {
    refreshState()
    var msg = response.detail
    setState(new_state => ({
      ...new_state,
      approval_request_success_msg: msg,
    }));

  }

  function sendApprovalRequestFailed(error) {
    var error = error.toString()
    setState(new_state => ({
      ...new_state,
      deployment_failed_msg: error
    }));

  }

  function confirmForApproval(id) {
    var post_url = GenerateURL({}, properties.api.confirm_approval);

    var post_obj = {
      "name": "project_job_templates",
      "entity_id": id
    }

    PostData(post_url, post_obj, confirmApprovalRequestSuccess, confirmApprovalRequestFailed);
    setState(new_state => ({
      ...new_state,
      loading: true,
      error_msg: null,
      open: true
    }));
  }

  function confirmApprovalRequestSuccess(response) {
    refreshState()
    var msg = response.detail
    setState(new_state => ({
      ...new_state,
      approval_confirm_success_msg: msg,
    }));
  }

  function confirmApprovalRequestFailed(error) {
    var error = error.toString()
    setState(new_state => ({
      ...new_state,
      approval_failed_msg: error
    }));
  }

  function refreshState() {
    setState(new_state => ({
      ...new_state,
      refresh_count: new_state.refresh_count + 1
    }));
  }

  const setDefault = (data) => {
    // state.job_template_listing
  
  }


  const createDefaultJobTemplate = () => {
    let post_url = GenerateURL({}, properties.api.create_default_job_template);

    PostData(post_url, {}, createDefaultTemplateSuccess, createDefaultTemplateFailed);
    setState(new_state => ({
      ...new_state,
      loading: true,
      error_msg: null,
      open: true
    }));
  }

  const createDefaultTemplateSuccess = (response) => {
    refreshState()
    setState(new_state => ({
      ...new_state,
      default_template_created: true,
    }));
  }
  const createDefaultTemplateFailed = () => {
    setState(new_state => ({
      ...new_state,
      default_template_created: false,
    }));
  }
  const moreFilterData = [
    { label: "Name", value: "name" },
    { label: "Tag", value: "job_template_tag" },
  ]
  const addFiltersToUrl = (filterName, filterValue) => {

    let urlSearchParams = new URLSearchParams(location.search);
    const allFilters = [
      "Name",
      "Tag"
    ]

    if (filterName == 'all_delete') {
      urlSearchParams = ''
    } else {
      if (filterName == 'adv_search') {
        allFilters.forEach(value => {
          if (!filterValue.includes(value)) {
            urlSearchParams.delete(value)
          }
        })
      }

      if (filterValue?.length == 0) {
        urlSearchParams.delete(filterName)
      }
      else {
        if (urlSearchParams.has(filterName)) {
          urlSearchParams.set(filterName, filterValue.join(','));
        } else {
          urlSearchParams.append(filterName, filterValue.join(','));
        }
      }
    }

    history.replace({ pathname: location.pathname, search: urlSearchParams.toString() });
  }
  const isFilterActive = (filterKey, list) => {

    const isFound = list?.find(name => filterKey == name)

    return isFound;
  }
  function generateFilterContainURL(baseURL, listDict, activeFilters) {
    var searchParams = ''
    const filtersKeyArray = Object.keys(listDict)
    let i = 0
    const activeFilterList = activeFilters || state.moreAdvFilterList
    filtersKeyArray.forEach(key => {

      const arrayFilterValues = listDict[key];
      if (arrayFilterValues.length > 0 && isFilterActive(key, activeFilterList)) {
        if (i != 0) {
          searchParams += '&'
        }
        var arrayString = arrayFilterValues;
        const stringKey = `${key}=${arrayString}`
        searchParams += stringKey
        i++;
      }
    })

    const finalUrl = baseURL + '?' + searchParams.toString()

    return finalUrl
  }
  const fetchAllGlobalJobTemplateForListFilter = (listFilterData, activeFilterList, isLoadingNeeded) =>{
    console.log("checking if this fn calling 3")
    let baseURL = GenerateURL({}, properties.api.get_global_job_template)

    const resultURL = generateFilterContainURL(baseURL, listFilterData, activeFilterList)+"&job_type=true";
    let requestInfo = {
      endPoint: resultURL,
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };
    InvokeApi(
      requestInfo,
      jobTemplatesInfoFetchSuccess,
      jobTemplatesInfoFetchFailure
    );

    isLoadingNeeded && setState((new_state) => ({
      ...new_state,
      data_loading: "LOADING",
    }));

  }
  

  const onUpdateHandle = (uniqueId, updatedList) => {

    let updatedKey = ''
    if (uniqueId === 'more-button-adv-0') {
      addFiltersToUrl('adv_search', updatedList)
      if (updatedList?.length == 0) {
        resetAdvFilter()
      }
      else {
        setState(prevState => ({
          ...prevState,
          moreAdvFilterList: updatedList,
        }))

      }
    }
    else {
      if (uniqueId == 'name_adv_1') {
        updatedKey = 'name'
      }
      if (uniqueId == 'tag_adv_3') {
        updatedKey = 'job_template_tag'
      }

      setState(prevState => ({
        ...prevState,
        advFilters: {
          ...prevState.advFilters,
          [updatedKey]: updatedList
        }
      }))
      addFiltersToUrl(updatedKey, updatedList)
      const advFilters = state.advFilters
      advFilters[updatedKey] = updatedList
      fetchAllGlobalJobTemplateForListFilter(advFilters)
    }
  }

  const resetAdvFilter = () => {

    fetchAllGlobalJobTemplateForListFilter(resetFilterData)
    addFiltersToUrl('all_delete')
    setState(prevState => ({
      ...prevState,
      moreAdvFilterList: defaultFilters,
      advFilters: resetFilterData,
      resetCount: prevState.resetCount + 1
    }))

    // will call here normal function
  }
  console.log(state.job_template_listing, 'temp_sta_001');
  function filterDataParseForGlobalTemplateName(data) {

    const updatedList = data?.map(item => {
      return { 'label': item.name, 'value': item.name, ...item }
    })

    return updatedList
  }

  function filterDataParseForGlobalTemplateTags(data) {
    const tags_list = [];
    console.log(data,"fdaspfopaop")
    data?.forEach(item => {
      item.job_template_tag.forEach((single_tag) => {
        if (!tags_list.includes(single_tag)) {
          tags_list.push(single_tag)
        }
      })
    })

    const updatedList = tags_list?.map(item => {
      return { 'label': item, 'value': item, ...item }
    })

    return updatedList


  }
  
  const advanceFilterJson = {
    'name': {
      staticList: false,
      labelName: 'Name',
      uniqueId: 'name_adv_1',
      searchVariable: 'name',
      getFetchUrl: properties.api.get_global_job_template,
      searchUrl: properties.api.get_global_job_template,
      filterDataPraseFunction: filterDataParseForGlobalTemplateName
    },
    'job_template_tag': {
      staticList: false,
      labelName: 'Tag',
      uniqueId: 'tag_adv_3',
      searchVariable: 'job_template_tag',
      getFetchUrl: properties.api.get_global_job_template,
      searchUrl: properties.api.get_global_job_template,
      filterDataPraseFunction: filterDataParseForGlobalTemplateTags,
      showMoreNotRequired: true
    },
  }
  return (
    <>
    
    {state.data_loading == "SUCCESS" ? (
        <div className={classes.root}>
         <div className="d-flex align-center space-between">
         <JobListingHeader
            onCreateDefaultTemplate={createDefaultTemplate}
            changeDefaultJobTemplate={ValidateAndSave}
            application_id={application_id}
            inherits={state.child_inherits.update_default_job_template}
            template_list={
              state.job_template_listing ? state.job_template_listing : []
            }
          />
          {state.job_template_listing &&
            state.job_template_listing.length > 0 ? 
          <div className="d-flex align-center space-between">
              {
                defaultTemplate && defaultTemplate.default &&
                    <CreateDefaultTemplate
                      varient={"jobtemplatelisting"}
                      createDefaultJobTemplate={createDefaultJobTemplate}
                      template_list={
                        state.job_template_listing ? state.job_template_listing : []
                      }

                    />
              }
                <SetAsDefaultDialog
                  varient={"jobtemplatelisting"}
                  onCreateDefaultTemplate={createDefaultTemplate}
                  application_id={application_id}
                  changeDefaultJobTemplate={ValidateAndSave}
                  inherits={state.child_inherits.update_default_job_template}
                  template_list={
                    state.job_template_listing ? state.job_template_listing : []
                  }
                />
              </div> : <></>}
         </div>
         {state.job_template_listing &&
            state.job_template_listing.length > 0 ?
         <Grid container>
                  {
                    state.moreAdvFilterList?.map(filterName => {

                      return (
                        <div key={filterName} className="ad-more-search mb-20">
                          <AdvanceSearchFilterCombo
                            reset={state.resetCount}
                            selectedCheckBoxes={state.advFilters[filterName]}
                            searchVariable={advanceFilterJson[filterName]['searchVariable']}
                            staticList={advanceFilterJson[filterName]['staticList']}
                            uniqueId={advanceFilterJson[filterName]['uniqueId']}
                            labelName={advanceFilterJson[filterName]['labelName']}
                            searchUrl={advanceFilterJson[filterName]['searchUrl']}
                            onUpdate={onUpdateHandle}
                            getFetchUrl={advanceFilterJson[filterName]['getFetchUrl']}
                            placeholder_name={advanceFilterJson[filterName]['placeholder_name']}
                            placeholder_value={advanceFilterJson[filterName]['placeholder_value']}
                            filterDataPraseFunction={advanceFilterJson[filterName]['filterDataPraseFunction']}
                            showMoreNotRequired={advanceFilterJson[filterName]['showMoreNotRequired']}
                          />
                        </div>
                      )
                    })
                  }
                  <div className="ad-more-search mb-20">
                    <AdvanceSearchFilterCombo
                      selectedCheckBoxes={state.moreAdvFilterList}
                      reset={state.resetCount}
                      staticList={moreFilterData}
                      autoClosedAfterSelection={true}
                      onUpdate={onUpdateHandle}
                      variant='more-button'
                      uniqueId='more-button-adv-0'
                    />
                  </div>

                  <div onKeyDown={()=>{}} onClick={resetAdvFilter} className="pl-15 ml-20 d-flex align-center justify-center cursor-pointer" style={{ borderLeft: '1px solid #dedede', height: '40px' }} tabIndex={0} role="button">
                    <span style={{ color: '#595353' }} className="font-13 cursor-pointer">Reset</span>
                  </div>
                </Grid>: null}
          {state.job_template_listing &&
            state.job_template_listing.length > 0 ? <>

            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={state.default_template_created}
              onClose={onCloseAlert}
              autoHideDuration={2000}>
              <Alert severity="success">
                {"Default job templates will be created if not exists"}
              </Alert>
            </Snackbar>
          </>
            : null}
          {state.job_template_listing &&
            state.job_template_listing.length > 0 ? (

            <div className="w-100" style={{
              gap: "16px", display: "grid",
              gridTemplateColumns: "1fr 1fr"
            }}>

              {/* -------here is the card of, i have mapped this card------- */}
              {state.job_template_listing.map((item, index) => (
                <JobListingCardNew data={item} refreshFn={refresh} sendForApproval={sendApprovalRequest} selected_job_template_id={defaultTemplate?.id}
                  confirmForApproval={confirmForApproval} key={index} setDefault={setDefault} changeDefaultJobTemplate={ValidateAndSave} />
              ))}
            </div>

          ) : state.search_query_name ? (
            <div
              class="main-container-error"
              style={{ height: "60vh", margin: "auto" }}
            >
              <div class="svg">
                <div class="servicePanel">
                  <div class="blank-div">
                    <div class="blank-div-text">
                      No Templates found with this name :{" "}
                      {state.search_query_name}
                    </div>
                    <div>
                      <button
                        onClick={() => setFilter({ name: "" })}
                        className="btn btn-submit"
                      >
                        Refresh
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              class="main-container-error"
              style={{ height: "60vh", margin: "auto" }}
            >
              <div class="svg">
                <div class="servicePanel">
                  <div class="blank-div">
                    <div class="blank-div-text">
                      No Templates associated with this Application
                    </div>
                    <div>
                      <Link
                        to={
                          "/application/" + application_id + "/job-template/add"
                        }
                        className="btn btn-submit"
                      >
                        <div>Add Job Template</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : state.data_loading == "FAILED" ? (
        <ErrorComponent variant="error-box" error={state.error} />
      ) : (
        <LoadingContainer />
      )}

      {state.job_template_listing &&
        state.job_template_listing.length > 0 ?
        <div className="mb-24">
          <Pagination
            current_page={state.curr_page}
            current_page_count={state.curr_page}
            total_count={state.total_page}
            next={state.next}
            previous={state.previous}
            on_previous_click={() => {
              fetchPrevJobTemplateInfo(null, state.previous);
            }}
            on_next_click={() => {
              fetchNextJobTemplateInfo(null, state.next);
            }}
            on_pageNumber_click={(pageNumber) => {
              fetchPageJobTemplateInfo(pageNumber);
            }}
          />
        </div> : null}
    </>
  )}
const useStyles = makeStyles({
  root: {
    position: "relative",
    margin: 20,
  },
});

export default GlobalJobTemplates;
