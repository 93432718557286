import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import InfoIcon from '@material-ui/icons/Info';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import { Loading } from '../../../utils/Loading';
import { ErrorComponent } from '../../../utils/Error';
//import StopIcon from '@material-ui/icons/Stop';
import { Tooltip } from '@material-ui/core';




export default function DeleteDialog(props) {
    const classes = useStyles();
    const inherits = props.inherits ? props.inherits : {};
    const user_data = props.user_data ? props.user_data : null;
    console.log(user_data,"user_datauser_data")
    const [state, setstate] = useState({
        data: {},
        error: {},
        loading: false,

    })
    const commonFunctions = getCommonFunctions(state, setstate, inherits);
    const current_user_obj = JSON.parse(localStorage.getItem("authData"))  
    const current_user_email = current_user_obj.userDetails && current_user_obj.userDetails.email ?
                                    current_user_obj.userDetails.email : null;
    const handleClickOpen = () => {
        setstate({ ...state, open: true });
    };
    const handleClose = () => {
        setstate({ ...state, open: false, });
    };
    function handlePostAndCloseDialog() {
        console.log("fn calling")
        props.onClickHitApi();
        setstate({ ...state, open: false, });
    }
    function handleClickOpenReativeUser(){
        props.onClickHitApi();
        setstate({ ...state, open: false, });
    }
    return (
        <>
        {
            !user_data.is_active && !user_data.activated || !user_data.is_active && user_data.activated ?
            <Tooltip title="User is already disabled ">
              <span 
              role='button' tabIndex={0} 
                style={{ color: '#7d838f' }} 
                onKeyDown={()=>{}}
                onClick={handleClickOpen} 
                className="material-icons material-icons-outlined ">
            no_accounts
            </span>
            </Tooltip>
                :
                <span 
                role='button' tabIndex={0} 
                style={{ color: '#ff8969',position: "relative",
                top: '3px' }} 
                onKeyDown={()=>{}}
                onClick={handleClickOpen} 
                className="material-icons material-icons-outlined cursor-pointer">
            no_accounts
            </span>
        }
        
            {/* <button onClick={} className="btn btn-danger d-flex align-center"><StopIcon fontSize="large" className="mr-5" /> Stop Env.</button> */}
            <Dialog
                open={state.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {
                    state.loading ?
                        <div className="card-body d-flex" style={{ height: 300 }}>
                            <Loading varient="light" />
                        </div>
                        :
                        state.error_msg ? <ErrorComponent error={state.error_msg} variant="msg-box" />
                            :
                            <div className="card ">
                                <div className="card-body d-flex" style={{ height: 300 }}>
                                    <div className="m-auto" style={{ width: '400px' }}>
                                        {
                                            current_user_email == user_data.email ? 
                                            <>
                                        <InfoIcon style={{ color: '#4783dc', fontSize: '60px' }} className="text-center mb-10 d-block m-auto" />
                                        <div className="">
                                            <p className="font-16 font-weight-bold text-center mb-5">
                                                You cannot disable yourself. 
                                            </p>
                                        </div>
                                        </>: 
                                        !user_data.activated && user_data.is_active ?
                                        <>
                                        <InfoIcon style={{ color: '#4783dc', fontSize: '60px' }} className="text-center mb-10 d-block m-auto" />
                                        <div className="">
                                            <p className="font-16 font-weight-bold text-center mb-5">
                                            Are you sure you want to Inactivate the user
                                                
                                            </p>
                                            {/* <p className="font-14 color-danger text-center" style={{fontWeight: 300}}>Are you sure you want to inactive {user_data.name}?</p> */}
                                        </div>
                                        </>
                                        :
                                        !user_data.is_active && !user_data.activated ?
                                        <>
                                        <InfoIcon style={{ color: '#4783dc', fontSize: '60px' }} className="text-center mb-10 d-block m-auto" />
                                        <div className="">
                                            <p className="font-16 font-weight-bold text-center mb-5">
                                            This User is already disabled. 
                                            </p>
                                        </div>
                                        </>
                                        :
                                        !user_data.is_active && user_data.activated ? 
                                        <>
                                        <InfoIcon style={{ color: '#4783dc', fontSize: '60px' }} className="text-center mb-10 d-block m-auto" />
                                        <div className="">
                                            <p className="font-16 font-weight-bold text-center mb-5">
                                            This User is diasbled. Are you sure you want to enable this user.
                                            </p>
                                        </div>
                                        </>
                                        :
                                        <>
                                        <InfoIcon style={{ color: '#4783dc', fontSize: '60px' }} className="text-center mb-10 d-block m-auto" />
                                        <div className="">
                                            <p className="font-16  font-weight-bold text-center mb-5">
                                            Are you sure you want to Inactivate the user
                                            </p>
                                            {/* <p className="font-14 color-danger text-center">
                                                Inactivating will forbid a user to log in into the 
                                            </p> */}
                                        </div>
                                        </>
                                        }
                                        
                                    </div>
                                </div>
                                <div className="card-footer border-top pd-10 justify-end d-flex align-center">
                                {
                                            current_user_email == user_data.email ?  
                                            <button className="btn btn-outline-dark-grey" onClick={handleClose}>
                                        Close
                                    </button>:
                                    !user_data.activated && user_data.is_active ? 
                                    <>
                                    <button className="btn btn-outline-dark-grey" onClick={handleClose}>
                                        Cancel
                                    </button>
                                    <button className="btn btn-primary-v2" onClick={handlePostAndCloseDialog}>
                                        Confirm
                                    </button>
                                    </>
                                    :
                                     !user_data.is_active && user_data.activated ? 
                                     <>
                                    <button className="btn btn-outline-dark-grey" onClick={handleClose}>
                                        Cancel
                                    </button>
                                    <button className="btn btn-primary-v2" onClick={handleClickOpenReativeUser}>
                                        Confirm
                                    </button>
                                    </>
                                    :
                                    !user_data.is_active && !user_data.activated ?
                                    <button className="btn btn-outline-dark-grey" onClick={handleClose}>
                                    Cancel
                                </button>:
                                    <>
                                    <button className="btn btn-outline-dark-grey" onClick={handleClose}>
                                        Cancel
                                    </button>
                                    <button className="btn btn-primary-v2" onClick={handlePostAndCloseDialog}>
                                        Confirm
                                    </button>
                                    </>}
                                </div>
                            </div>
                }

            </Dialog>

        </>
    );
}
DeleteDialog.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

const useStyles = makeStyles(theme => ({
    anchorText: {
        fontSize: '11px',
        color: '#42a6e0',
        lineHeight: 1,
        cursor: 'pointer',
        marginLeft: '10px'
    }
}));