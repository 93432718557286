import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import { FormControlLabel, Grid, LinearProgress, RadioGroup, Tooltip, makeStyles } from '@material-ui/core';
import { StyledRadio } from './StylesRadio';
import properties from '../../../../properties/properties';
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi, { PostData } from '../../../../util/apiInvoker';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { ErrorComponent } from '../../../utils/Error';
import { VALIDATION_TYPE_REQUIRED, ValidateDataSet } from '../../../../util/Validator';
import { useReleasePackageContext } from './ReleasePackageContext';
import FilterSearch from '../../../../components/genericComponents/FilterSearch';
import TabWiseSearchSelector from '../../../../components/genericComponents/TabWiseSearchSelector';
import GenericSkeleton from '../../../../components/genericComponents/Skeletons/GenericSkeleton';
import { useDebounce } from '../../../../hooks/useDebounce';
import { CustomLabel } from './ConfigureChangeSet';
import { Input } from '../../../../components/genericComponents/Input';
import SearchJiraTicket from './SearchJiraTicket';
import AddedJiraTicket from './AddedJiraTicket';
import AlertStrip from '../../../../components/AlertStrips';

const AddJiraStories = (props) => {
  const inherits = props.inherits ? props.inherits : null;
  const prev_state = props.prev_state ? props.prev_state : null;
  const classes = useStyle();
  const [state,setState] = useState(prev_state ? prev_state : getAddJiraStoriesDefaultState());
  const {setSkippable} = useReleasePackageContext()
  const commonFunctions = getCommonFunctions(state, setState, inherits);
  const { selectedApp,typeTag } = useReleasePackageContext();
  const path = props.extraProps && props.extraProps.path ? props.extraProps.path : null;
  const optionList =[{label:'Release',value:'release',info:'Release'},
    {label:'Sprint',value:'sprint',info:'Sprint'},
    {label: 'All', value: 'all',info:"All"}
]
  inherits.validateForm = () => {
    // const result = ValidateDataSet(state.data, {selectedJiraTickets: [VALIDATION_TYPE_REQUIRED]})
    // if(!result.valid){
    //   setState((prevState)=>({
    //     ...prevState,
    //     error:{
    //       ...prevState.error,
    //       selectedJiraTickets: "Please Add Atleast One Jira Ticket",
    //     }
    //   }));
    // }
    return { valid : true }
  }
  useEffect(()=>{
    setSkippable(false);
    //fetchJiraIntegration();
    if(!(state.jiraReleaseList && state.jiraReleaseList.length > 0)){
      fetchReleaseList();
    }
  },[])

  function fetchReleaseList(){
    console.log("selectedApp",selectedApp);
    const requestInfo = {
      endPoint: GenerateURL({app_name: selectedApp?.label || null} , properties.api.get_releases_list),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    }

    setState((prev_state)=>({
          ...prev_state,
          loading: true,
    }));

    InvokeApi(requestInfo,fetchReleaseListSuccess,fetchReleaseListFailed);
  }

  function fetchReleaseListSuccess(response){
    console.log("sdjksjkds",response);
    // const releaseList = response.version_list.map((item) => {return {label:item,id:item}})
    let sprint_list = response?.version_list;
    let selected_sprint = sprint_list.find(item => item.path === path);
    setState((prev_state)=>({
      ...prev_state,
      loading: false,
      jiraReleaseList: sprint_list,
      isDefaultConf: response?.default || false,
      configuration: response.config,
      data:{
        ...prev_state.data,
        collection:  selected_sprint && selected_sprint.id
      }
    }));
  }

  function fetchReleaseListFailed(error){
    console.log("dskskjsd",error);
    const errorMsg = JSON.stringify(error);
    setState((prev_state)=>({
      ...prev_state,
      loading: false,
      error:{
        ...prev_state.error,
        selectedJiraTickets: errorMsg,
      }
    }));
  }


  // function fetchJiraIntegration(){
  //   const requestInfo = {
  //     endPoint: GenerateURL(
  //       {},
  //       properties.api.jira_integration_list
  //     ),
  //     httpMethod: "GET",
  //     httpHeaders: { "Content-Type": "application/json" },
  //   }
  //   setState((prev_state)=>({
  //     ...prev_state,
  //     loading: true,
  //   }))
  //   InvokeApi(requestInfo, fetchJiraIntegrationSuccess, fetchJiraIntegrationFailed);
  // }

  // function fetchJiraIntegrationSuccess(response){
  //   console.log("kjjdsh",response);
  //   let integrationTypesList = [
  //     ...new Map(response.map(item => [item.integration_type, {
  //         label: item.integration_type.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()),
  //         id: item.integration_type
  //     }])).values()
  //   ];

  //   let integrationList = response.map((item) =>{
  //     return {...item,label:item.integration_name};
  //   });
  //   setState((prev_state)=>({
  //     ...prev_state,
  //     integrationList: integrationList,
  //     integrationTypesList: integrationTypesList,
  //     loading: false,
  //     allIntegrationsList: integrationList,
  //     data:{
  //       ...prev_state.data,
  //       selectedTicketing: integrationList[0],
  //     }
  //   }))
  // }

  // function fetchJiraIntegrationFailed(error){
  //   console.log("sdjhdsj",error);
  //   setState((prev_state)=>({
  //     ...prev_state,
  //     loading: false,
  //   }))
  // }
  
  function onJiraTicketClick(jiraTicketObj) {
    setState((prevState) => {
      const isDuplicate = prevState.data.selectedJiraTickets.some(
        (ticket) => ticket.story_name === jiraTicketObj.story_name
      );
  
      if (isDuplicate) {
        return prevState;
      }
  
      return {
        ...prevState,
        addOpen: false,
        data: {
          ...prevState.data,
          selectedJiraTickets: [...prevState.data.selectedJiraTickets, jiraTicketObj],
        },
        error: {},
      };
    });
  }
  useEffect(() => {
    if (path && state.configuration&& state.data.collection) {
      fetchValidStories();
    }
  }, [path, state.data.collection, state.configuration])

  function fetchValidStories() {
    let postData = {
      release_name: state.data.collection,
      config_data: state.configuration,
      path: path
    }
    PostData(GenerateURL({}, properties.api.get_valid_stories), postData, handleSuccessApiHit, handleFailedApiHit);
    setState((prevState) => ({
      ...prevState,
      searching: true,
      search_applied: true,
    }));
  }
  const handleSuccessApiHit = (response) => {
    const filteredList = response.issues_list;
    console.log(filteredList, "filteredListfdsadfa")
    setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        selectedJiraTickets: filteredList
      },
    }))


  };

  const handleFailedApiHit = (error) => {
    const error_msg = error.detail ? error.detail.toString() : JSON.stringify(error);
    setState(prevState => ({
      ...prevState,
      error: error_msg,
      searching: false,
    }));
  };


  function onJiraTicketRemove(jiraTicketObj){
    console.log(jiraTicketObj,"jiraTicketObjjiraTicketObj")
    let updateTicketList = state.data.selectedJiraTickets.filter(
      (ticket) => ticket !== jiraTicketObj
    );
    console.log(jiraTicketObj,"jiraTicketObjjiraTicketObj",updateTicketList)
    setState((prevState)=>({
      ...prevState,
      data:{
        ...prevState.data,
        selectedJiraTickets: updateTicketList,
      }
    }))
  }

  const OnIntegrationTabClick = (id) => {
    if(id == state.selectedIntegrationTab){
      setState((prev_state)=>({
        ...prev_state,
        integrationList: prev_state.allIntegrationsList,
        selectedIntegrationTab: null,
      }))
    }else{
      let updatedIntegrationList = state.allIntegrationsList.filter((item) => item.integration_type == id);
      setState((prev_state)=>({
        ...prev_state,
        integrationList: updatedIntegrationList,
        selectedIntegrationTab: id,
      }))
    } 
  }

  const OnIntegrationItemClick = (id) => {
    const selectedTicketing = state.integrationList.find((item) => item.id == id);
    setState((prev_state)=>({
      ...prev_state,
      data: {
        ...prev_state.data,
        selectedTicketing: selectedTicketing,
      }
    })) 
  }
  console.log("sbdsjhjsd",state);
  const jiraStoriesInfo = 'The stories associated with a release package represent the specific tasks, features, bug fixes, and other work items that have been tracked and managed within the project management tool. Currently, the system supports integration with Service Now, Azure DevOps, and Jira.'
  return (
    <div className={classes.root}>
      <div className='d-flex align-center'>
        <span className='font-16 font-weight-600'>Add Stories to your release package</span>
          <Tooltip title={jiraStoriesInfo} placement='right' arrow>
            <span className='ri-information-line font-16 font-weight-600 color-value ml-4'></span>
          </Tooltip>
      </div>
      {/* <div className='d-flex f-direction-column' style={{gap:"24px",marginTop:"16px"}}>
        <div className='d-flex' style={{gap:"16px"}}>
          <div className='d-flex f-direction-column' style={{gap:"8px"}}>
            <span className='font-14 font-weight-500 color-value'>Add from user</span>
            {state.loading ? 
              <GenericSkeleton width={330} height={42} style={{borderRadius:'6px'}} />
            :
              <div style={{width:'330px'}}>
                <TabWiseSearchSelector ItemComponent={IntegrationItem} tabList={state.integrationTypesList} itemList={state.integrationList} tabClick={OnIntegrationTabClick} selectedTab={state.selectedIntegrationTab} itemClick={OnIntegrationItemClick} selectedItem={state.data.selectedTicketing}/>
              </div>
            }
          </div>
          
        </div>
        
        
      </div> */}
      {state.error?.selectedJiraTickets && 
        <ErrorComponent error={state.error.selectedJiraTickets}/>
      }
      <div className='d-flex f-direction-column' style={{gap:"24px",marginTop:"16px"}}>
        <div className='d-grid' style={{gap:"16px",gridTemplateColumns:'36%'}}>
          <div className='d-flex f-direction-column' style={{gap:"8px"}}>
          {state.loading ? 
              <GenericSkeleton width={330} height={42} style={{borderRadius:'6px'}} />
            :
              <Input 
                type="select"
                label="Select Release"
                name="collection"
                list={state.jiraReleaseList || []}
                data={state.data}
                error={state.error}
                mandatorySign
                disabled
                onChangeHandler={path ? null : commonFunctions.onChangeHandler}
              />
          }
          </div>
        </div>
      </div>
      {state.isDefaultConf && typeTag.value == "app_package"? 
        <AlertStrip variant="info" message={"Using Default Configuration , Please Contact your administrator to create application specific configuration"} dismissible={true} />
      : null}
      <div className='d-flex f-direction-column' style={{gap:"8px",marginTop:"16px"}}>
        <span className='font-14 font-weight-500 color-value'>
          Add Stories
        </span>
        <Grid container spacing={2}>
          <Grid item lg={6}>
            <SearchJiraTicket path={path} selectedTickets={state.data.selectedJiraTickets} onJiraTicketClick={onJiraTicketClick} onJiraTicketRemove={onJiraTicketRemove} selectedRelease={state.data.collection} inherits={state.child_inherits.search_jira_tickets} prev_state={state?.search_jira_tickets} configuration={state.configuration}/>
          </Grid>
          <Grid item lg={6}>
            <AddedJiraTicket selectedTickets={state.data.selectedJiraTickets} selectedTicketing={state.data.selectedTicketing} onJiraTicketClick={onJiraTicketClick} onJiraTicketRemove={onJiraTicketRemove} />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

AddJiraStories.propTypes = {
  prev_state: PropTypes.object, 
  inherits: PropTypes.any, 
};

const IntegrationItem = ({data = {integration_name: "Siddharth Gupta",integration_type: 'jira'},selected})=>{
  const classes = IntegrationItemStyles({selected});
  console.log("sdjdhjs",data);
  return (
    <div className={classes.root}>
      <span className='font-14 font-weight-500'>{data.integration_name}</span>
      <span className='type-tab'>
        <img src={`/integrations/${data.integration_type}.png`} alt={data.integration_type}/>
        <span className='font-12 font-weight-500' style={{textTransform:'capitalize'}}>{data.integration_type.replace("_"," ")}</span>
      </span>
    </div>
  );
}

const IntegrationItemStyles = makeStyles((theme)=>({
  root:{
    padding: (props)=>props.selected ? '0px' :'8px 12px 8px 12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: (props)=>props.selected ? 'none' :'space-between',
    gap: (props)=>props.selected ? '10px' : 'none',
    width:'100%',
    '& .type-tab':{
      display: 'flex',
      alignItems:'center',
      gap:'4px',
      padding:'5px 6px 5px 6px',
      border:'1px solid #DFEDFF',
      backgroundColor:'#F5FAFF',
      borderRadius:'6px',
    }
  }
}))

export function getAddJiraStoriesDefaultState(){
  return {
    loading: true,
    data:{
      selectedJiraTickets: [],
      release: null,
    },
    allIntegrationsList: [],
    integrationTypesList: [],
    integrationList: [],
    selectedIntegrationTab: null,
    error:{},
    validation:{},
    child_inherits: {
      search_jira_tickets: {
        "validateForm": () => { return { valid: true } },
        "getState": () => { return {} },
        "getData": () => { return {} }
      },

    }
  }
}
 
export const JiraTicket = ({jiraObject,onClick,onDelete,isLink,isFinalise,variant}) => {
  const classes = useStylesForJira({isFinalise});
  const {issue_type,story_name,link,status} = jiraObject
  return (
    variant == "chip"? 
      <Tooltip title={story_name || ""} arrow>
        <div className={classes.resultChip}>
          {getJiraTypeIcon(issue_type,"16px")}
          <span className='font-12 font-weight-600 color-value text-ellipsis'>{story_name}</span>
          <span className='ri-close-line color-value-78 font-20 cursor-pointer' style={{lineHeight:'1'}} onClick={(e)=>{e.stopPropagation(); onDelete(jiraObject)}}></span>
        </div>
      </Tooltip>
    
    :
        <div className={classes.resultItem +" container-item"} onClick={onDelete || isFinalise ? ()=>{} :()=>onClick(jiraObject)}>
           <div className='d-flex align-center' style={{gap:"12px",width:"93%"}}>
            {getJiraTypeIcon(issue_type)}
              {isLink ? 
                 <a href={link} target='_blank' className={`font-14 font-weight-500 text-ellipsis ${isFinalise ? 'color-icon-secondary':'color-value'}`}>
                  {story_name}
                </a>
              : 
              <div className='jira-summary font-14 font-weight-500 text-ellipsis color-value'>
                {story_name}
              </div>
              }
              {!isFinalise && 
                <div className={'greyStatus avtar'}>
                    {status}
                </div>
              }
             
           </div>
            {onDelete && 
              <span className='font-12 font-weight-500 color-key-primary' onClick={()=>onDelete(jiraObject)}>REMOVE</span>
            }
        </div>
    
  );
}

JiraTicket.propTypes = {
    jiraObject: PropTypes.shape({
      issue_type: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }).isRequired,
    onClick: PropTypes.func,
    onDelete: PropTypes.func, 
    isLink: PropTypes.bool
};

export default AddJiraStories

export function getJiraTypeIcon(issue_type,size){
  let normalisedIssuetype = issue_type.toLowerCase();
  //const classes = useStylesForJira();
  switch(normalisedIssuetype){
    case "bug":
      return( 
            <div className={'jira-icon'} style={{backgroundColor:"#d54a4a",width:size || '24px',height: size || '24px'}}>
              <span className='ri-circle-fill font-12' style={{lineHeight:"1"}}></span>
            </div>
      );
    case "story":
      return (
        <div className={'jira-icon'} style={{backgroundColor:"#2EBE79",width:size || '24px',height: size || '24px'}}>
              <span className='ri-bookmark-fill font-12' style={{lineHeight:"1"}}></span>
        </div>
      );
    case "task":
      return (
        <div className={'jira-icon'} style={{backgroundColor:"#67aeee",width:size || '24px',height: size || '24px'}}>
              <span className='ri-check-line font-14 font-weight-700' style={{lineHeight:"1"}}></span>
        </div>
      );
    case "epic":
      return (
        <div className={'jira-icon'} style={{backgroundColor:"#904EE2",width:size || '24px',height: size || '24px'}}>
              <span className='ri-flashlight-fill font-12 font-weight-700' style={{lineHeight:"1"}}></span>
        </div>
      );
    default:
      return (
        <div className={'jira-icon'} style={{backgroundColor:"#0086FF",width:size || '24px',height: size || '24px'}}>
              <span className='ri-picture-in-picture-fill font-12 font-weight-500' style={{lineHeight:"1"}}></span>
        </div>
      );
  }
}

const useStyle = makeStyles({
  root:{
    "& select":{
      borderColor:'#E6E6E6 !important',
      height:'40px !important',
      lineHeight:'1'
    },
    "& .add-story-btn":{
      textTransform:"uppercase",
      height:"32px",
      fontSize:"12px",
      fontWeight:"500",
      color:"#0086ff",
      transition:"all 0.4s ease-in-out",
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      gap:"2px",
      "&:hover":{
        '& .add-text':{
          textDecoration:'underline',
          cursor:'pointer',
        }
      }
    }
  },
  container:{
    padding:"10px 12px",
    borderRadius:"4px",
    border:"1px solid #CACACA",
    "& .add-story":{
      color:"#0086ff",
      cursor:"pointer",
      transition:"all 0.4s ease-in-out",
      textTransform:"uppercase",
      "&:hover":{
        textDecoration:"underline"
      }
    }
  },
  
})

const useStylesForJira = makeStyles((theme)=>({
  searchContainer: {
    borderRadius:"6px",
    border: "1px solid #E6E6E6",
    padding: '10px 12px 10px 12px',
    borderBottomLeftRadius: (props) => props.openSearch ? "0px" : "6px",
    borderBottomRightRadius: (props) => props.openSearch ? "0px" : "6px",
    borderBottom: (props) => props.openSearch ? "none" : "1px solid #E6E6E6",

    "& .heading":{
      padding:"10px 12px",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      display:"flex",
      justifyContent:"space-between",
      alignItems:"center",
      "& .input-component":{
        marginBottom:"0px !important",
      },
      "& .divider":{
        height:"30px",
        width:"1px",
        backgroundColor:"#CACACA",
        margin:"0px 8px",
      }
    },
    "& .search-container":{
      padding:"10px 12px",
      height:"40px",
      border: "1px solid #E6E6E6",
      borderLeft:"none",
      borderRight:"none",
      backgroundColor:"#FAFAFA",
      width:"100%",
      display:"flex",
      gap:"10px",
      "& input":{
        border: "none",
        background:"#FAFAFA",
        outline: "none", 
        padding:"0 !important",
        "&:focus": { 
          outline: "none !important", 
          boxShadow: "none !important",
          border: "none !important",
        },
      }
    }
  },
  inputWrapper: {
    padding:"10px 12px",
    height:"40px",
    border: "1px solid #E6E6E6",
    backgroundColor:"#FFFFFF",
    width:"100%",
    display:"flex",
    gap:"10px",
    "& input":{
      border: "none",
      background:"#FFFFFF",
      outline: "none", 
      padding:"0 !important",
      "&:focus": { 
        outline: "none !important", 
        boxShadow: "none !important",
        border: "none !important",
      },
    }
  },
  resultContainer:{
    // "& > .container-item": {
    //   borderLeft: 'none',
    //   borderRight: 'none',
    // },
    // "& > .container-item:first-child": {
    //   borderTop: "none",
    //   borderRadius: "0px",
    //   borderTopLeftRadius: "0px",
    //   borderTopRightRadius: "0px",
    // },
  
    // /* Style for the last child (direct child) */
    // "& > .container-item:last-child": {
    //   borderBottom: "none",
    //   borderTop: "none",
    //   borderTopLeftRadius: "0px",
    //   borderTopRightRadius: "0px",
    //   borderBottomLeftRadius: "4px",
    //   borderBottomRightRadius: "4px",
    // },
    "& > .container-item": {
      border:'1px solid #E6E6E6',
      borderTop: 'none',
      borderRadius:'0px',
      padding:'7px 8px 7px 8px',
    },
    "& > .container-item:first-child": {
      borderTop:'none',
      borderTopLeftRadius: "0px",
      borderTopRightRadius: "0px",
      borderBottomLeftRadius:'0px',
      borderBottomRightRadius:'0px'
    },
  
    /* Style for the last child (direct child) */
    "& > .container-item:last-child": {
      borderTopLeftRadius: "0px",
      borderTopRightRadius: "0px",
      borderBottomLeftRadius: "6px",
      borderBottomRightRadius: "6px",
    },
    '& .jira-summary':{
      fontSize:'12px !important',
      color:"#505050 !important",
    },
    "& .MuiLinearProgress-barColorPrimary":{
      backgroundColor:"#0086FF !important",
    }
  },
  resultItem:{
    padding:(props)=>props.isFinalise ? "12px 0px" :"12px",
    border:(props)=>props.isFinalise ? 'none' : "1px solid #E6E6E6",
    // borderLeft:"none",
    // borderRight:"none",
    display:"flex",
    gap:"12px",
    borderRadius:"8px",
    alignItems:"center",
    cursor:"pointer",
    justifyContent:'space-between',
    "&:hover":{
      backgroundColor:(props)=>props.isFinalise ? '#FFFFFF':"#f2f2f2",
      borderColor:(props)=>props.isFinalise ? '#FFFFFF':"#f2f2f2"
    },
    '& .greyStatus':{
      padding: '4px 6px 4px 6px',
      borderRadius: "6px",
      backgroundColor: '#F4F4F4',
      color: '#949494',
      fontSize: '12px',
      fontWeight: '700',
      border: '1px solid #E6E6E6',
      textWrap: 'nowrap'
    }
  },
  resultChip:{
    height:'28px',
    width:'162px',
    padding:'4px 8px 4px 8px',
    alignItems: 'center',
    display:'grid',
    gridTemplateColumns: '20px 1fr 16px',
    borderRadius: '24px',
    backgroundColor:'#F4F4F4'
  },
  icon:{
    padding:"6px",
    borderRadius:"4px",
    color:"#ffffff",
    width:"24px",
    height:"24px",
    display:"flex",
    alignItems:'center',
    justifyContent:"center",
  },
  selectedTicketsSection: {
    "& > .container-item": {
      borderBottom:'1px solid #E6E6E6',
      borderTop: 'none',
      borderRadius:'0px',
      padding:'7px 8px 7px 8px',
    },
    "& > .container-item:first-child": {
      borderTop:'none',
      borderTopLeftRadius: "0px",
      borderTopRightRadius: "0px",
      borderBottomLeftRadius:'0px',
      borderBottomRightRadius:'0px'
    },
  
    /* Style for the last child (direct child) */
    "& > .container-item:last-child": {
      borderTopLeftRadius: "0px",
      borderTopRightRadius: "0px",
      borderBottomLeftRadius: "6px",
      borderBottomRightRadius: "6px",
      borderBottom: 'none !important',
    },
    '& .jira-summary':{
      fontSize:'12px !important',
      color:"#505050 !important",
    },

    "& $resultItem": {
      borderLeft: "none !important",
      borderRight: "none !important",
    },
  }
}))