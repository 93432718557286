import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import CancelIcon from '@material-ui/icons/Cancel';
export default function SingleChart(props) {
  console.log(props.chart_data, "chart_data")
  const series_list = props.chart_data.series_list
  const inner_chart_status = props.chart_data.inner_chart_status;
  const replicaset = props.chart_data.replicasets ? props.chart_data.replicasets[0] : {}
  const options = {

    chart: {
      type: 'pie',
      renderTo: 'container',

    },
    colors: series_list[0] ? series_list[0].colors : ["#808088"],
    title: {
      verticalAlign: 'middle',
      floating: true,
      text: null,
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      pie: {
        size: 350,
        innerSize: '80%',
        dataLabels: {
          enabled: false
        }
      }
    },
    series: series_list[0] ? series_list[0].series : [{ data: [{ name: "No Pod Data Available", y: 1 }], name: 'pod' }]
  };
  return (
    
      <div className="p-relative">
        <HighchartsReact highcharts={Highcharts} options={options} />
        {
          inner_chart_status == "NOT_FOUND" ?
            <div className={"highcharts-center-data not-found-data p-absolute d-flex align-center justify-center"}>
              <div className="text-center pd-20 ">
                <div className="font-10 color-dark "></div>
                <CancelIcon className="text-grey-b6" style={{ fontSize: '64' }} />
                <p className="font-10 text-grey-83">Deployment not found</p>
              </div>
            </div> :
            <div className={"highcharts-center-data success-data p-absolute d-flex align-center justify-center"}>
              <div className="text-center pd-20 ">
                <div className="font-10 color-dark ">Deployment {replicaset.metadata && replicaset.metadata.annotations ? replicaset.metadata.annotations.deployment_number ? replicaset.metadata.annotations.deployment_number.replace("$", "#") : "" : null} </div>
                <div className="round-div m-auto pods-status processing-state mb-5">
                  {replicaset.status.readyReplicas ? replicaset.status.readyReplicas : 0}
                </div>
                <p className="font-12 text-grey-83">PODS READY</p>
              </div>
            </div>
        }

      </div>
    
  )
}


SingleChart.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}