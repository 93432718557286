import { Grid, Step } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Input } from "../../../components/genericComponents/Input";

const NonDependentJobStepsForm = ({ jobData, parentState, setParentState, stageTaskPath, selectedTabOrder, finalPostData }) => {
    const [state, setState] = useState({
        data: {},
        error: {},
    })
    // api call and local step data to create form here 
    console.log(parentState, selectedTabOrder, 'pdfll')

    const data = parentState[selectedTabOrder][stageTaskPath]?.data
    console.log(data, 'data_sdksdlsd')
    const handleChange = (e, stepCodeKey, stepOrder, targetValue) => {
        const targetKey = e.target.name
        const value = e.target.value
        setParentState(prevState => ({
            ...prevState,
            stageWiseData: {
                ...prevState.stageWiseData,
                [selectedTabOrder]: {
                    ...prevState.stageWiseData[selectedTabOrder],
                    [stageTaskPath]: {
                        ...prevState.stageWiseData[selectedTabOrder][stageTaskPath],
                        data: {
                            ...prevState.stageWiseData[selectedTabOrder][stageTaskPath].data,
                            [stepCodeKey]: {
                                ...prevState.stageWiseData[selectedTabOrder][stageTaskPath].data[stepCodeKey],
                                [stepOrder]: {
                                    ...prevState.stageWiseData[selectedTabOrder][stageTaskPath].data[stepCodeKey][stepOrder],
                                    [targetKey]: {
                                        ...prevState.stageWiseData[selectedTabOrder][stageTaskPath].data[stepCodeKey][stepOrder][targetKey],
                                        [targetValue]: value
                                    }
                                }
                            }
                        }

                    }
                }
            }

        }))
    }

    console.log(jobData, 'jbf023_op_pp')
    console.log(state.data, parentState, 'data_0sdsds')
    return (
        <div style={{ boxShadow: '0px 2px 8px #80808066', border: '1px solid #dedede', marginBottom: '20px', width: '100%' }}>
            <div>
                <div style={{ backgroundColor: '#faf9ff', height: '60px', paddingLeft: '15px', paddingRight: '15px' }} className='d-flex align-center space-between border-bottom-grid'>

                    <label className='font-15 font-weight-500 '>
                        Job Steps Environment Variables
                    </label>

                    <label className='font-13  font-weight-500' style={{ color: '#716d6d' }}>
                        Provide/Update Runtime Environment Variables
                    </label>
                </div>
                <>
                    <Grid className='pl-15 pr-15 pb-20' style={{ backgroundColor: '#ffffff', minHeight: '200px' }} container>

                        {
                            jobData && Object.keys(jobData.job_step_json)?.length > 0 ? Object.keys(jobData.job_step_json)?.map((stepCodeKey, index) => {
                                const stepOrdersObject = jobData.job_step_json[stepCodeKey]
                                return (
                                    <>
                                        {
                                            Object.keys(stepOrdersObject)?.map((stepOrder, order) => {
                                                const stepData = stepOrdersObject[stepOrder]
                                                console.log(stepData, 'step_00p')
                                                let isOverridableVarAvailable = stepData?.find(step => step.is_overridden == true)
                                                return (
                                                    <Grid key={order} style={{ paddingTop: '15px' }} item xs={12}>

                                                        <Grid style={{ height: '32px' }} key={order} item xs={12}>
                                                            <label className='font-13 mr-12'>Step Name</label>
                                                            <label style={{ color: 'gray' }} className='font-13'>{stepCodeKey}</label>
                                                        </Grid>

                                                        <>{
                                                            isOverridableVarAvailable ?

                                                                <div style={{
                                                                    paddingTop: '20px', paddingBottom: '15px', border: '1px solid #dedede',
                                                                    backgroundColor: '#f8f8f8', padding: '10px 20px 5px 20px',
                                                                    borderRadius: '5px'
                                                                }}>
                                                                    {
                                                                        stepData && stepData.map((variable, index) => {
                                                                            return (
                                                                                <>
                                                                                    {
                                                                                        variable?.is_overridden &&
                                                                                        <Grid key={index} item xs={12}>
                                                                                            {
                                                                                                variable.integration ?
                                                                                                    <>
                                                                                                        {
                                                                                                            variable.integration_values?.map(integrationObj => {

                                                                                                                return (<>

                                                                                                                    {
                                                                                                                        Object.keys(integrationObj)?.map(integrationKey => {
                                                                                                                            const integrationData = integrationObj[integrationKey]
                                                                                                                            console.log(integrationData, 'integ_2332323')
                                                                                                                            return (
                                                                                                                                <>
                                                                                                                                    {integrationData?.map(integration => {
                                                                                                                                        return (
                                                                                                                                            integration.key === 'GIT_BRANCH' ?
                                                                                                                                                <Input
                                                                                                                                                    type='text'
                                                                                                                                                    name={integrationKey}
                                                                                                                                                    label={`${integration.key} for ${integrationKey}(Integration)`}
                                                                                                                                                    placeholder=""
                                                                                                                                                    onChangeHandler={(e => handleChange(e, order))}
                                                                                                                                                    data={state.data && state.data[order] ? state.data[order] : {}}
                                                                                                                                                    error={state.error}
                                                                                                                                                /> : null
                                                                                                                                        )
                                                                                                                                    })}
                                                                                                                                </>
                                                                                                                            )
                                                                                                                        })
                                                                                                                    }
                                                                                                                </>)

                                                                                                            })
                                                                                                        }
                                                                                                    </>
                                                                                                    :
                                                                                                    variable.input_type === 'toggle' ?
                                                                                                        <Input
                                                                                                            type='env-var-toggle'
                                                                                                            name={variable.key}
                                                                                                            label={variable.key}
                                                                                                            placeholder=""
                                                                                                            onChangeHandler={(e => handleChange(e, order, 'toggle'))}
                                                                                                            data={state.data && state.data[order] ? state.data[order][variable.key] === 'no' ? false : true : {}}
                                                                                                            error={state.error}
                                                                                                        /> :
                                                                                                        variable.input_type == undefined || variable.input_type === 'text' || variable.input_type === 'json' ?
                                                                                                            <Input
                                                                                                                type='text'
                                                                                                                name={variable.key}
                                                                                                                label={variable.key}
                                                                                                                placeholder=""
                                                                                                                onChangeHandler={(e => handleChange(e, stepCodeKey, stepOrder, 'value'))}
                                                                                                                data={{
                                                                                                                    [variable.key]: (data
                                                                                                                        && data[stepCodeKey] &&
                                                                                                                        data[stepCodeKey][stepOrder] &&
                                                                                                                        data[stepCodeKey][stepOrder][variable.key]) ?
                                                                                                                        data[stepCodeKey][stepOrder][variable.key]['value'] : null
                                                                                                                }}
                                                                                                                mandatorySign={(data
                                                                                                                    && data[stepCodeKey] &&
                                                                                                                    data[stepCodeKey][stepOrder] &&
                                                                                                                    data[stepCodeKey][stepOrder][variable.key]) ?
                                                                                                                    data[stepCodeKey][stepOrder][variable.key]['is_required'] : false}
                                                                                                                error={state.error}
                                                                                                            />

                                                                                                            :
                                                                                                            <Input
                                                                                                                type={variable.input_type === 'toggle' ? 'select' : variable.input_type}

                                                                                                                name={variable.key}
                                                                                                                list={variable.input_type === 'select' || 'toggle' ? variable.select_dropdown_options ?

                                                                                                                    variable.select_dropdown_options.split(',').map(item => ({

                                                                                                                        id: item.trim(), label: item.trim()
                                                                                                                    }))

                                                                                                                    : [{ id: 'yes', label: 'Yes' }, { id: 'no', label: 'No' }]

                                                                                                                    : []
                                                                                                                }
                                                                                                                label={variable.key}
                                                                                                                placeholder=""
                                                                                                                onChangeHandler={(e => handleChange(e, order))}
                                                                                                                data={state.data && state.data[order] ? state.data[order] : {}}
                                                                                                                error={state.error}
                                                                                                            />
                                                                                            }
                                                                                        </Grid>

                                                                                    }
                                                                                </>
                                                                            )
                                                                        })
                                                                    }

                                                                </div>

                                                                :

                                                                <div className="d-flex align-center justify-center font-13" style={{
                                                                     border: '1px solid #dedede',
                                                                     height:'70px',
                                                                    backgroundColor: '#f8f8f8', 
                                                                    borderRadius: '5px',
                                                                    color:'gray'
                                                                }}>
                                                                    No overridable variables
                                                                </div>

                                                        }</>


                                                    </Grid>
                                                )

                                            })
                                        }
                                    </>
                                )
                            })
                                :

                                <div className="d-flex align-center justify-center font-12 full-width">No Runtime Environment Variables</div>
                        }

                    </Grid>
                </>
            </div>

        </div>
    )
}

export default NonDependentJobStepsForm;






