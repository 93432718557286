import React, { useEffect, useState } from 'react';
import properties from '../../../properties/properties';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import AppsIcon from '@material-ui/icons/Apps';
import SettingsIcon from '@material-ui/icons/Settings';

import GenerateURL, { GenerateSearchURL } from '../../../util/APIUrlProvider';
import InvokeApi from '../../../util/apiInvoker';
import { ApplicationDashboardDataParser } from '../../../util/APIParser';
import { CompactDataCard } from '../../../components/genericComponents/CompactDataCard';
import { LoadingContainer } from '../../utils/Loading';
import { ErrorComponent } from '../../utils/Error';
import { AppSummaryCard } from './AppSummaryCard';
import ApplicationSelector from '../components/ApplicationSelector';
import { GetAuth } from '../../../util/security';
import { Link, Redirect, withRouter } from 'react-router-dom';
import SearchBar from '../../../components/SearchBar';
import Pagination from '../../../components/Pagination';
import GenericSkeleton from '../../../components/genericComponents/Skeletons/GenericSkeleton';

const ApplicationListing = (props) => {
    const classes = useStyles();
    const [state, setState] = useState({
        loaded: false,
        refresh_count: 0,
        total_page: "",
        curr_page: "",
        data: []
    });
    const [raw_data, setRaw_data] = useState({});
    const auth = GetAuth();
    // var application_count = state.application ? Object.keys(state.application).length : "";
    // console.log("application_count", state)
    // var service_count = state.service ? Object.keys(state.service).length : "";
    // var pipeline_count = state.pipeline_count ? state.pipeline_count : "";
    // var cluster_count = state.cluster_count ? state.cluster_count : "";


    var application_count = raw_data.total_count ? raw_data.total_count.application_count : "";
    var service_count = raw_data.total_count ? raw_data.total_count.service_count : "";
    var pipeline_count = raw_data.total_count ? raw_data.total_count.pipline_count : "";
    var cluster_count = raw_data.total_count ? raw_data.total_count.cluster_count : "";


    useEffect(() => {
        handleApiHit();
    }, [state.refresh_count]);

    function handleApiHit(data, url) {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.project_dashboard), //application_data_url
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        if (data) {
            requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
        }

        if (url) {
            requestInfo.endPoint = url;
        }

        setState(new_state => ({
            ...new_state,
            search_data: data,
            search_query_name: data ? data.name ? data.name : "" : "",
        }))

        InvokeApi(requestInfo, handleSuccessApiHit, handleFailedApiHit);
    }

    // API Callbacks handler
    function handleSuccessApiHit(response) {
        console.log("bug=========>", response);
        // FetchClusterList();
        var noramalized_data = ApplicationDashboardDataParser(response.results)
        console.log("bug=========>1", noramalized_data);
        setState((fresh_state) => ({
            ...fresh_state,
            loaded: true,
            ...noramalized_data,
            total_page: Number.isInteger(Number(response.count) / 10)
                ? (Number(response.count) / 10).toFixed(0)
                : (Number(response.count) / 10 + 1).toFixed(0) > Number(response.count) / 10 + 1
                    ? (Number(response.count) / 10 + 1).toFixed(0) - 1
                    : (Number(response.count) / 10 + 1).toFixed(0),
            curr_page: 1,
            count: response.count,
            next: response.next ? properties.api.baseURL + response.next : null,
            previous: response.previous ? properties.api.baseURL + response.previous : null,
        }));
        setRaw_data(response)
    }

    console.log(raw_data,"rtygvxss ============>")

    function handleFailedApiHit(error, exception) {
        setState((fresh_state) => ({
            ...fresh_state,
            loaded: false,
            error: error,
            exception: exception,
        }));
    }


    function refresh() {
        setState(new_state => ({
            ...new_state,
            refresh_count: new_state.refresh_count + 1,
        }));
    }


    function fetchPrevPanelInfo(data, url) {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.project_dashboard),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };

        if (data) {
            requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
        }

        if (url) {
            requestInfo.endPoint = url;
        }
        setState((new_state) => ({
            ...new_state,
            loaded: false,
            search_data: data,
        }));
        InvokeApi(
            requestInfo,
            PanelPrevInfoFetchSuccess,
            PanelPrevInfoFetchFailure
        );
    }

    function PanelPrevInfoFetchSuccess(data) {
        var noramalized_data = ApplicationDashboardDataParser(data.results);
        setState((new_state) => ({
            ...new_state,
            loaded: true,
            count: data.count,
            next: data.next ? properties.api.baseURL + data.next : null,
            previous: data.previous ? properties.api.baseURL + data.previous : null,
            total_page: Number.isInteger(Number(data.count) / 10)
                ? (Number(data.count) / 10).toFixed(0)
                : (Number(data.count) / 10 + 1).toFixed(0) > Number(data.count) / 10 + 1
                    ? (Number(data.count) / 10 + 1).toFixed(0) - 1
                    : (Number(data.count) / 10 + 1).toFixed(0),
            curr_page: Number(new_state.curr_page - 1),
            ...noramalized_data,
        }));
    }

    function PanelPrevInfoFetchFailure(error, exception) {
        setState((fresh_state) => ({
            ...fresh_state,
            loaded: false,
            error: error,
            exception: exception,
        }))
    }

    function fetchNextPanelInfo(data, url) {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.project_dashboard),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };

        if (data) {
            requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
        }

        if (url) {
            requestInfo.endPoint = url;
        }
        setState((new_state) => ({
            ...new_state,
            loaded: false,
            search_data: data,
        }));
        InvokeApi(
            requestInfo,
            PanelNextInfoFetchSuccess,
            PanelNextInfoFetchFailure
        );
    }

    function PanelNextInfoFetchSuccess(data) {
        var noramalized_data = ApplicationDashboardDataParser(data.results);
        setState((new_state) => ({
            ...new_state,
            loaded: true,
            count: data.count,
            next: data.next ? properties.api.baseURL + data.next : null,
            previous: data.previous ? properties.api.baseURL + data.previous : null,
            total_page: Number.isInteger(Number(data.count) / 10)
                ? (Number(data.count) / 10).toFixed(0)
                : (Number(data.count) / 10 + 1).toFixed(0) > Number(data.count) / 10 + 1
                    ? (Number(data.count) / 10 + 1).toFixed(0) - 1
                    : (Number(data.count) / 10 + 1).toFixed(0),
            curr_page: Number(new_state.curr_page + 1),
            ...noramalized_data
        }));
    }

    function PanelNextInfoFetchFailure(error, exception) {
        setState((fresh_state) => ({
            ...fresh_state,
            loaded: false,
            error: error,
            exception: exception,
        }))
    }

    function fetchPagePanelInfo(enteredPageNumber) {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.project_dashboard),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };

        if (enteredPageNumber > 1) {
            requestInfo.endPoint =
                requestInfo.endPoint +
                "?limit=10&offset=" +
                (enteredPageNumber - 1) * 10;
        }
        setState((new_state) => ({
            ...new_state,
            loaded: false,
        }));
        var current_page = enteredPageNumber;

        InvokeApi(
            requestInfo,
            (response) => {
                PanelPageInfoFetchSuccess(response, current_page);
            },
            PanelPageInfoFetchFailure
        );
    }

    function PanelPageInfoFetchSuccess(data, count) {
        var noramalized_data = ApplicationDashboardDataParser(data.results);
        setState((new_state) => ({
            ...new_state,
            loaded: true,
            count: data.count,
            next: data.next ? properties.api.baseURL + data.next : null,
            previous: data.previous ? properties.api.baseURL + data.previous : null,
            total_page: Number.isInteger(Number(data.count) / 10)
                ? (Number(data.count) / 10).toFixed(0)
                : (Number(data.count) / 10 + 1).toFixed(0) > Number(data.count) / 10 + 1
                    ? (Number(data.count) / 10 + 1).toFixed(0) - 1
                    : (Number(data.count) / 10 + 1).toFixed(0),
            curr_page: Number(count),
            ...noramalized_data
        }));
    }

    function PanelPageInfoFetchFailure(error, exception) {
        setState((fresh_state) => ({
            ...fresh_state,
            loaded: false,
            error: error,
            exception: exception,
        }))
    }


    const invokeSearch = (data) => {
        handleApiHit(data)
    }

    // console.log(state.application.length, "appppp")

    function getPanel() {
        if (service_count == 0) {
            if (application_count == 1) {
                return (
                    <div className={classes.root} >
                        <Grid container spacing={4}>
                            <Grid item lg={3} sm={6} xl={3} xs={12}>
                                <CompactDataCard
                                    icon={<AppsIcon style={{ fontSize: '3rem', color: '#54b4f4' }} />}
                                    headContent={application_count > 9 ? application_count : "0" + application_count}
                                    subContent={"Applications"}
                                    color={"#54B4F4"} />
                            </Grid>
                            <Grid item lg={3} sm={6} xl={3} xs={12}>
                                <CompactDataCard
                                    icon={<SettingsIcon style={{ fontSize: '3rem', color: '#69e09c' }} />}
                                    headContent={service_count > 9 ? service_count : "0" + service_count}
                                    subContent={"Services"}
                                    color={"#69e09c"} />
                            </Grid>
                            <Grid item lg={3} sm={6} xl={3} xs={12}>
                                <CompactDataCard
                                    icon={<span className="flaticon-nfc-near-field-communication"></span>}
                                    headContent={cluster_count > 9 ? cluster_count : "0" + cluster_count}
                                    subContent={"Clusters"}
                                    color={"#9497ca"} />
                            </Grid>
                            <Grid item lg={3} sm={6} xl={3} xs={12}>
                                <CompactDataCard
                                    icon={<img src="/images/pipeline.png" alt=".." />}
                                    headContent={pipeline_count > 9 ? pipeline_count : "0" + pipeline_count}
                                    subContent={"Pipelines"}
                                    color={"#208ea2"} />
                            </Grid>

                        </Grid>
                        <Grid item lg={12} sm={12} xl={12} xs={12}>
                            <div className={classes.heading}>
                                <div className={classes.mainHeading}>
                                    Apps Summary
                                </div>
                                <div className={classes.subHeading}>
                                    Displaying summary of all the apps.
                                </div>
                            </div>
                        </Grid>
                        <Grid container spacing={4}>
                            {console.log("stateapplication", state)}
                            {
                                Object.keys(state.application).map((application_id) => (
                                    <Grid key={application_id} item lg={6} sm={12} xl={6} xs={12}>
                                        {
                                            raw_data ?
                                                <AppSummaryCard
                                                    raw_data={raw_data}
                                                    application_id={application_id}
                                                    data={state} />
                                                : null
                                        }

                                    </Grid>
                                ))
                            }
                        </Grid>


                    </div>
                );
            }
            else if (application_count > 1) {
                return (
                    <div className={classes.root}>
                        <Grid container spacing={4}>
                            <Grid item lg={12}>
                                <ApplicationSelector user_name={"User"} application={state.application} />
                            </Grid>
                        </Grid>
                    </div>
                );
            }
            else {

                return (
                    state.loaded ?
                    <div className={classes.root}>
                        <Grid container spacing={4}>
                            <Grid item lg={12}>
                                <div className="welcome-text">
                                    <div>
                                        <h1 className={classes.helloText}>To onboard a service you need to add a application first.</h1>
                                        <Link to={"add/project"} className={classes.btnblue + " " + "btn-blue-next"}>Add Application<span className="flaticon-keyboard-right-arrow-button"></span></Link>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    : null
                )
            }
        } else {
            //start here
            return (
                <div className={classes.root}>
                    <Grid container spacing={4}>

                        <Grid item lg={3} sm={6} xl={3} xs={12}>
                            <CompactDataCard
                                icon={<AppsIcon style={{ fontSize: '3rem', color: '#54b4f4' }} />}
                                headContent={application_count > 9 ? application_count : "0" + application_count}
                                subContent={"Applications"}
                                color={"#54B4F4"} 
                                skeleton={!state.loaded}
                                />
                        </Grid>
                        <Grid item lg={3} sm={6} xl={3} xs={12}>
                            <CompactDataCard
                                icon={<SettingsIcon style={{ fontSize: '3rem', color: '#69e09c' }} />}
                                headContent={service_count > 9 ? service_count : "0" + service_count}
                                subContent={"Services"}
                                color={"#69e09c"} 
                                skeleton={!state.loaded}
                                />
                        </Grid>
                        <Grid item lg={3} sm={6} xl={3} xs={12}>
                            <CompactDataCard
                                icon={<span className="flaticon-nfc-near-field-communication"></span>}
                                headContent={cluster_count > 9 ? cluster_count : "0" + cluster_count}
                                subContent={"Clusters"}
                                color={"#9497ca"} 
                                skeleton={!state.loaded}
                                />
                        </Grid>
                        <Grid item lg={3} sm={6} xl={3} xs={12}>
                            <CompactDataCard
                                icon={<img src="/images/pipeline.png" alt=".." />}
                                headContent={pipeline_count > 9 ? pipeline_count : "0" + pipeline_count}
                                subContent={"Pipelines"}
                                color={"#208ea2"} 
                                skeleton={!state.loaded}
                                />
                        </Grid>

                    </Grid>
                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                        {!state.loaded ?
                            <div className={classes.heading}>
                                <h1><GenericSkeleton variant={"text"} width={"30%"} style={{lineHeight:"1.5"}}/></h1>
                                <h3><GenericSkeleton variant={"text"} width={"25%"} style={{lineHeight:"1"}} /></h3>
                            </div>
                        :
                        <div className={classes.heading}>
                            <div className={classes.mainHeading}>
                                Apps Summary
                            </div>
                            <div className={classes.subHeading}>
                                Displaying summary of all the apps.
                            </div>
                        </div>
                        }
                        
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item lg={9}>
                            <SearchBar
                                search_data={state.search_data}
                                default_filter={{ name: "name", label: "Name" }}
                                search_call_back={invokeSearch}
                                clear_search_callback={handleApiHit}
                                skeleton={!state.loaded}
                            />
                        </Grid>

                        <Grid item lg={3} sm={12} xl={3} xs={12}>
                            <Pagination
                                total_count={state.total_page}
                                current_page_count={state.curr_page}
                                next={state.next}
                                previous={state.previous}
                                on_previous_click={() => {
                                    fetchPrevPanelInfo(null, state.previous);
                                }}
                                on_next_click={() => {
                                    fetchNextPanelInfo(null, state.next);
                                }}
                                on_pageNumber_click={(pageNumber) => {
                                    fetchPagePanelInfo(pageNumber);
                                }}
                                skeleton={!state.loaded}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        {
                            Object.keys(state.application).length > 0 ?
                                <>
                                    {
                                        Object.keys(state.application).map((application_id) => (
                                            <Grid key={application_id} item lg={6} sm={12} xl={6} xs={12}>
                                                {
                                                    raw_data ?
                                                        <AppSummaryCard
                                                            raw_data={raw_data}
                                                            refresh_state={refresh}
                                                            application_id={application_id}
                                                            data={state} 
                                                            skeleton={!state.loaded}/>
                                                        : null
                                                }

                                            </Grid>
                                        ))
                                    }
                                </>
                                :
                                state.search_query_name ?
                                    <div className="main-container-error" style={{ height: "60vh", margin: 'auto' }}>
                                        <div className="svg">
                                            <div className="servicePanel">
                                                <div className="blank-div">
                                                    <div className="blank-div-text">No Application found with the name : {state.search_query_name}</div>
                                                    <button className="btn btn-submit" onClick={handleApiHit}>Refresh</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <div className="main-container-error" style={{ height: '60vh', margin: 'auto' }}>
                                        <div className="svg">
                                            <div className="servicePanel">
                                                <div className="blank-div">
                                                    <div className="blank-div-text">
                                                        No Application Added
                                                    </div>
                                                    <div>
                                                        <Link to={"/project/add"} className="btn btn-submit">
                                                            <div >Add Applicaiton</div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        }

                    </Grid>
                </div>
            );
        }
    }

    function getSkeletonView(){
        //start here
        return (
            <div className={classes.root}>
                <Grid container spacing={4}>

                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <CompactDataCard
                            icon={<AppsIcon style={{ fontSize: '3rem', color: '#54b4f4' }} />}
                            headContent={application_count > 9 ? application_count : "0" + application_count}
                            subContent={"Applications"}
                            color={"#54B4F4"} 
                            skeleton={true}
                            />
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <CompactDataCard
                            icon={<SettingsIcon style={{ fontSize: '3rem', color: '#69e09c' }} />}
                            headContent={service_count > 9 ? service_count : "0" + service_count}
                            subContent={"Services"}
                            color={"#69e09c"} 
                            skeleton={true}
                            />
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <CompactDataCard
                            icon={<span className="flaticon-nfc-near-field-communication"></span>}
                            headContent={cluster_count > 9 ? cluster_count : "0" + cluster_count}
                            subContent={"Clusters"}
                            color={"#9497ca"} 
                            skeleton={true}
                            />
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <CompactDataCard
                            icon={<img src="/images/pipeline.png" alt=".." />}
                            headContent={pipeline_count > 9 ? pipeline_count : "0" + pipeline_count}
                            subContent={"Pipelines"}
                            color={"#208ea2"} 
                            skeleton={true}
                            />
                    </Grid>

                </Grid>
                <Grid item lg={12} sm={12} xl={12} xs={12}>
                    {true ?
                        <div className={classes.heading}>
                            <h1><GenericSkeleton variant={"text"} width={"30%"} style={{lineHeight:"1.5"}}/></h1>
                            <h3><GenericSkeleton variant={"text"} width={"25%"} style={{lineHeight:"1"}} /></h3>
                        </div>
                    :
                    <div className={classes.heading}>
                        <div className={classes.mainHeading}>
                            Apps Summary
                        </div>
                        <div className={classes.subHeading}>
                            Displaying summary of all the apps.
                        </div>
                    </div>
                    }
                    
                </Grid>
                <Grid container spacing={2}>
                    <Grid item lg={9}>
                        <SearchBar
                            search_data={state.search_data}
                            default_filter={{ name: "name", label: "Name" }}
                            search_call_back={invokeSearch}
                            clear_search_callback={handleApiHit}
                            skeleton={true}
                        />
                    </Grid>

                    <Grid item lg={3} sm={12} xl={3} xs={12}>
                        <Pagination
                            total_count={state.total_page}
                            current_page_count={state.curr_page}
                            next={state.next}
                            previous={state.previous}
                            on_previous_click={() => {
                                fetchPrevPanelInfo(null, state.previous);
                            }}
                            on_next_click={() => {
                                fetchNextPanelInfo(null, state.next);
                            }}
                            on_pageNumber_click={(pageNumber) => {
                                fetchPagePanelInfo(pageNumber);
                            }}
                            skeleton={true}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    
                        
                            
                                {
                                    Array.from({length:4}).map((_,index) => (
                                        <Grid key={index} item lg={6} sm={12} xl={6} xs={12}>
                                            {
                                               
                                                    <AppSummaryCard
                                                        raw_data={null}
                                                        refresh_state={refresh}
                                                        application_id={null}
                                                        data={state} 
                                                        skeleton={true}/>
                                                    
                                            }

                                        </Grid>
                                    ))
                                }
                            
                            

                </Grid>
            </div>
        );
    }

    return (
        <>
            {
                state.exception ?
                    <Redirect push={true} to="/exception" /> : null
            }
            {
                state.loaded ?
                getPanel()
                    : state.error ? <ErrorComponent /> : getSkeletonView()
            }
        </>
    );
}


export default withRouter(ApplicationListing);


const useStyles = makeStyles(theme => ({
    root: {
        
        margin: '20px',
        '& .welcome-text': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '600px'
        }
    },
    heading: {
        margin: '3.5rem 0rem'
    },
    mainHeading: {
        fontSize: '24px',
        fontWeight: 300,
        lineHeight: 1.5
    },
    subHeading: {
        fontSize: '12px',
        fontWeight: 300,
        color: '#666',
        lineHeight: 1
    },
    helloText: {
        fontSize: '36px',
        fontWeight: 900,
        color: '#5262c8 '
    },
    btnblue: {
        width: '240px',
        height: '60px',
        fontWeight: 900,
        marginTop: '1rem',
    },
    textPara: {
        fontSize: '20px',
        fontWeight: '700',
        lineHeight: '1.5',
        color: '#525a8c'
    }
}));

