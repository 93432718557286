import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Input } from '../../../../components/genericComponents/Input';
import { Grid } from '@material-ui/core';

import EditorKeyValues from '../../../../components/genericComponents/inputs/EditorKeyValues';
import { getCommonFunctions, ResetComponent } from '../ci_flow/SourceDetails';


const LabelsAnnotations = (props) => {
    const inherits = props.inherits ? props.inherits : {};
    const prev_state = props.prev_state ? props.prev_state : null;
    const [state, setState] = useState(prev_state ? prev_state : getDefaultLabelsAnnotationsState())
    useEffect(() => {
        setState(new_state => ({ ...new_state, ...prev_state }))
    }, [prev_state])
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    function updateData(k, v) {
        setState(new_state => ({
            ...new_state,
            data: {
                ...new_state.data,
                [k]: v,
            }
        }));
    }

    inherits.validateForm = () => {
        return { valid: true }
    }


    return (
        <div className="ci-cd-edit-input-div" style={{ backgroundColor: '#fff' }}>
            <div className="card-add-service-head" style={{ borderRadius: '0px', borderTop: '1px solid #dedede' }}>
                <div className="formTag">
                    <h4 className='mainHeading'>Labels and Annoations</h4>
                </div>
            </div>
            <div className=''>
                <Grid container >

                    <Grid lg={12}  >

                        <div className='card'>
                            <div className='heading'>
                                <Input
                                    type="switch"
                                    name="labels_enabled"
                                    label="Labels"
                                    onChangeHandler={commonFunctions.toggleState}
                                    data={state.data}
                                    error={state.error}
                                    enabledComponent={<EditorKeyValues isListing={false} inherits={state.child_inherits.labels} data={state.labels ? state.labels.length ? state.labels : [{ key: "", value: "", env_key: "", env_value: "" }] : [{ key: "", value: "", env_key: "", env_value: "" }]} update_data_callback={commonFunctions.updateData} field_name={"labels"} />}
                                    disabledComponent={<ResetComponent inherits={state.child_inherits.labels} />}
                                />
                            </div>
                        </div>
                        <div className='card'>
                            <div className='heading'>
                                <Input
                                    type="switch"
                                    name="label_selectors_enabled"
                                    label="Label Selectors"
                                    onChangeHandler={commonFunctions.toggleState}
                                    data={state.data}
                                    error={state.error}
                                    info={"Label Selctors are not editable"}
                                    enabledComponent={<EditorKeyValues isListing={state.label_selectors ? true : false} disableEditor={state.label_selectors ? true : false} inherits={state.child_inherits.label_selectors} data={state.label_selectors ? state.label_selectors.length ? state.label_selectors : [{ key: "", value: "", env_key: "", env_value: "" }] : [{ key: "", value: "", env_key: "", env_value: "" }]} update_data_callback={commonFunctions.updateData} field_name={"label_selectors"} />}
                                    disabledComponent={<ResetComponent inherits={state.child_inherits.label_selectors} />}
                                />
                            </div>
                        </div>
                        <div className='card'>
                            <div className='heading'>
                                <Input
                                    type="switch"
                                    name="annotations_enabled"
                                    label="Annotations"
                                    onChangeHandler={commonFunctions.toggleState}
                                    data={state.data}
                                    error={state.error}
                                    enabledComponent={<EditorKeyValues inherits={state.child_inherits.annotations} data={state.annotations ? state.annotations.length ? state.annotations : [{ key: "", value: "", env_key: "", env_value: "" }] : [{ key: "", value: "", env_key: "", env_value: "" }]} update_data_callback={commonFunctions.updateData} field_name={"annotations"} />}
                                    disabledComponent={<ResetComponent inherits={state.child_inherits.annotations} />}
                                />
                            </div>
                        </div>
                        <div className="card-body">

                        </div>

                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
LabelsAnnotations.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }


export default LabelsAnnotations;

export function getDefaultLabelsAnnotationsState() {
    return {
        data: {
            labels_enabled: false,
            annotations_enabled: false,
            label_selectors_enabled: false
        },
        error: {},
        env_variable: [],
        validations: {},
        child_inherits: {
            labels: {
                "validateForm": () => { return true },
                "getState": () => { return {} },
                "getData": () => { return {} }
            },
            label_selectors:{
                "validateForm": () => { return true },
                "getState": () => { return {} },
                "getData": () => { return {} }
            },
            annotations: {
                "validateForm": () => { return true },
                "getState": () => { return {} },
                "getData": () => { return {} }
            }
        }
    }
}

export function keyValueParser(data) {
    var final_array = new Array();
    Object.keys(data).forEach(key => (
        final_array = [...final_array, { env_key: data[key].env_key, env_value: data[key].env_value }]
    ))
    return final_array;
}
