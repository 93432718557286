import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import SquareAvatar from '../../../../components/genericComponents/AvatarSquare';
import { ClusterName } from '../../../../util/util';

const MainHeader = props => {
    return (
        <HeaderDiv>
            <SquareAvatar
                varient="double"
                name="Kube-master-8abf" />
            <div className="cluster-head-data">
                <div className="general-data">
                    <span className="cluster-name">{props.name}</span>
                </div>
                <div className="general-data">
                    <div className="inner-data">
                        <span className="text-color-black">Cluster: </span>
                        <span className="text-color-grey">{ClusterName(props.cluster_id)}</span>
                    </div>
                    <div className="inner-data">
                        <span className="text-color-black">Namespace : </span>
                        <span className="text-color-grey">{props.namespace_name}</span>
                    </div>

                </div>
            </div>
        </HeaderDiv>
    )
}

MainHeader.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default MainHeader;

const HeaderDiv = styled('div')({
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    '& .cluster-head-data': {
        marginLeft: '10px'
    },
    '& .cluster-name': {
        color: '#0086ff',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '22px'
    },
    '& .general-data': {
        display: 'flex',
        alignContent: 'center',
        justifyItems: 'space-between',
        fontSize: '12px',
        lineHeight: '18px',
        '& .inner-data': {
            marginRight: '6px'
        },
        '& .text-color-grey': {
            color: '#989898'
        }
    }

})