import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import GenerateURL from '../../util/APIUrlProvider';
import properties from '../../properties/properties';
import InvokeApi, { PostData } from '../../util/apiInvoker';
import { Input } from '../../components/genericComponents/Input';
import { getCommonFunctions, ResetComponent } from '../serviceRevamp/add/ci_flow/SourceDetails';
import { VALIDATION_TYPE_REQUIRED, ValidateDataSet } from '../../util/Validator';
import { Redirect } from 'react-router';
import { Loading } from '../utils/Loading';
import { ErrorComponent } from '../utils/Error';
import StepProgressDetailed from '../../components/genericComponents/StepProgressDetailed';
import LatestBuildDeploy from './components/LatestBuildDeploy';

import AlertStrip from '../../components/AlertStrips';
import DeployComponent from './components/DeployComponent';
import BuildComponent from './components/BuildComponent';
import Popover from '@material-ui/core/Popover';
import InfoIcon from '@material-ui/icons/Info';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import GenericSkeleton from '../../components/genericComponents/Skeletons/GenericSkeleton';
import InputSkeleton from '../../components/genericComponents/Skeletons/InputSkeleton';

const BuildDeploy = props => {
    const classes = useStyles();
    const { application_id } = useParams();
    const [state, setState] = useState(buildDeployDefaultState())
    const inherits = {};
    var commonFunctions = getCommonFunctions(state, setState, inherits)
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClickOpenPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };


    const handleClickClosePopover = () => {
        setAnchorEl(null);
    };

    const OpenPopover = Boolean(anchorEl);
    const id = OpenPopover ? 'simple-popover' : undefined;
    useEffect(() => {
        fetchAllEnvironments();
    }, [application_id])

    useEffect(() => {
        setState(prevState => ({
            ...prevState,
            error_msg: null
        }))
    }, [state.data.service_id])
    function fetchSystemSettingsData() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.system_settings),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, fetchSystemSettingsDataSuccess, fetchSystemSettingsDataFailed);

        setState(new_state => ({
            ...new_state,
            data_loading: "LOADING"
        }))

    }

    function fetchSystemSettingsDataSuccess(data) {
        console.log(data, 'dgfll')
        var filter_cd_versioning_data = data.filter(item => item.key == "VERSIONING_CD_ENABLE");
        var deploy_env_offline = data.filter(item => item.key == "DISABLE_DEPLOY_IF_ENV_OFFLINE");
        var branchRequired = data.find(item => item.key == "BRANCH_SELECTION_REQUIRED_FOR_EVERY_BUILD");
        console.log(branchRequired, "filter_cd_versioning_data")

        setState(new_state => ({
            ...new_state,
            available_settings: data,
            cd_settings: filter_cd_versioning_data,
            deploy_env_offline_flag: deploy_env_offline && deploy_env_offline[0],
            data_loading: "SUCCESS",
            branchRequired: branchRequired?.value == 'true' ? true : false
        }));

    }
    function fetchSystemSettingsDataFailed(error) {

        setState(new_state => ({
            ...new_state,
            error: error,
            data_loading: "FAILED"
        }))
    }
    function getServiceEnvComponentId(service_id) {
        var component_env_id = '0';
        state.service_Env_Data.map(service => {
            if (service.id === Number(service_id)) {
                service.component_env.map(component => {
                    if (component.project_env.id === Number(state.data.env_name)) {
                        component_env_id = component.id
                    }
                })
            }
        })
        return component_env_id
    }

    function getEnvironmentList(environment_info) {
        console.log("environmedsafsant_info", environment_info)
        var env_list = [];
        environment_info.map(env => {
            env_list.push({ id: env.id, label: env.name, manual_build: env.manual_build, manual_deploy: env.manual_deploy, is_env_down: env.is_env_down })
        })
        console.log("environmedsafsant_info", env_list)
        return env_list
    }

    function fetchAllEnvironments(data, url) {
        console.log("function_running")
        var requestInfo = {
            endPoint: GenerateURL({ application_id: application_id }, properties.api.env_listing_all_true),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, fetchAllEnvironmentsSuccess, fetchAllEnvironmentsFailure);
        setState(new_state => ({
            ...new_state,
            data_loading: "LOADING",
        }));

    }

    function fetchAllEnvironmentsSuccess(data) {
        console.log(data.results, "envs for use ==========>")
        fetchAllServices()
        setState(new_state => ({
            ...new_state,
            environment_list: getEnvironmentList(data.results),
            default_environment_list: data.results
        }));
    }
    function fetchAllEnvironmentsFailure(error, exception) {

        setState(new_state => ({
            ...new_state,
            data_loading: "FAILED",
            error: error
        }));
    }

    function fetchAllServices() {
        var requestInfo = {
            endPoint: GenerateURL({ application_id: application_id }, properties.api.service_dahboard_data_url_all),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, fetchAllServicesSuccess, fetchAllServicesFailure);
    }

    function fetchAllServicesSuccess(response) {
        fetchSystemSettingsData()
        setState(new_state => ({
            ...new_state,
            services: response.service_detail,
            service_Env_Data: response.results,
            data_loading: "SUCCESS"
        }));
    }

    console.log(state.service_Env_Data, "services for use ==========>")

    console.log(state.environment_list, "envs for use ==========>")

    function fetchAllServicesFailure(error) {
        setState(new_state => ({
            ...new_state,
            data_loading: "FAILED",
            error: error
        }))
    }

    function getSelectedServiceBranchList(data) {
        var branch_list = []
        data.branches.map(branch => {
            branch_list.push({ id: branch, label: branch })
        })
        return branch_list
    }

    function testAndGetBranches(url, credential_id) {
        const result = ValidateDataSet({
            git_url: url,
        },
            {
                git_url: [VALIDATION_TYPE_REQUIRED]
            }
        );

        if (result.valid) {
            const post_data = {
                git_url: url,
                credential_id: credential_id,
            };
            if (!post_data.credential_id) {
                delete post_data.credential_id;
            }
            PostData(GenerateURL({}, properties.api.git_test), post_data, onTestAndGetBranchesSuccess, onTestAndGetBranchesFail);
            setState(new_state => ({
                ...new_state,
                branch_load: "Loading"
            }));
        } else {
            setState(new_state => ({
                ...new_state,
                form_data: {
                    ...new_state.form_data,
                    error: {
                        ...new_state.form_data.error,
                        ...result.error,
                    }
                }
            }));
        }
    }

    function onTestAndGetBranchesSuccess(data) {
        console.log(data)
        setState(new_state => ({
            ...new_state,
            branch_data: {
                ...new_state.branch_data,
                branches: getSelectedServiceBranchList(data),
                branch_with_commit_sha: data.branch_with_commit_sha,
            },
            branch_load: "Success"
        }));
    }

    function onTestAndGetBranchesFail(error) {
        setState(new_state => ({
            ...new_state,
            branch_data: {
                ...new_state.branch_data,
                error: error,
            },
            branch_load: "Failed"
        }));
    }





    function fetchCiData(env_id, service_id) {
        var service_component_id = getServiceEnvComponentId(service_id)
        var requestInfo = {
            endPoint: GenerateURL({ service_id: Number(service_id), component_env_id: service_component_id }, properties.api.save_ci),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, onFetchCiDataSuccess, onFetchCiDataFail)
        setState(new_state => ({
            ...new_state,
            buildDeploy_data_loading: "Loading",
            data: {
                ...new_state.data,
                service_id: service_id
            }
        }))
    }

    function onFetchCiDataSuccess(response) {
        console.log(response, "fadsfjiinas")
        var approved_ci_details = response.find(item => item.status === "APPROVED")
        testAndGetBranches(approved_ci_details.git_repo.git_url, approved_ci_details.git_repo.credential_id);
        setState(new_state => ({
            ...new_state,
            buildDeploy_data_loading: "Success",
            ciData: approved_ci_details,
            ci_configured: true,
        }));
    }

    function onFetchCiDataFail(error) {
        setState(new_state => ({
            ...new_state,
            buildDeploy_data_loading: "Failed",
            ci_configured: false,
            ci_error: error,
            data: {
                ...new_state.data,
                build_deploy: 'Build'
            }
        }));
    }

    function getServicesforSelectedEnv(env_id) {
        var service_list = []
        console.log("lttkltktkkta", state.service_Env_Data)
        state.service_Env_Data.forEach(element => {
            var new_env_id = element.environments;
            if (new_env_id.find(arrValue => arrValue == Number(env_id))) {
                service_list.push({ id: element.id, label: element.name })
            }
        });
        return service_list;
    }

    function handleEnvChange(e) {
        var env_id = e.target.value;
        var env_name = e.target.name;
        var services_list = getServicesforSelectedEnv(env_id);
        var if_manual_build_allowed = state.environment_list.find(item => item.id === Number(env_id));
        var if_manual_deploy_allowed = state.environment_list.find(item => item.id === Number(env_id));
        var is_env_down = state.environment_list.find(item => item.id === Number(env_id))
        console.log(is_env_down, " state.is_env_down")
        setState(new_state => ({
            ...new_state,
            data: {
                ...new_state.data,
                [env_name]: env_id,
                build_deploy: "",
                service_id: ""

            },
            buildDeploy_data_loading: undefined,
            service_data: services_list,
            is_manual_build_allowed: if_manual_build_allowed.manual_build,
            is_manual_deploy_allowed: if_manual_deploy_allowed.manual_deploy,
            is_env_down: is_env_down.is_env_down
        }))
    }
    console.log(state.is_env_down, " state.is_env_down")
    function handleServiceChange(e) {
        var service_id = e.target.value;
        var service_name = e.target.name;
        fetchCiData(state.data.env_name, service_id);
        setState(new_state => ({
            ...new_state,
            data: {
                ...new_state.data,
                [service_name]: service_id,
                build_deploy: "Build",

            },
        }))


    }

    function handleBuildDeployChange(e) {
        if (state.data.env_name === '' || state.data.service_id === '') {
            var build_deploy_select = e.target.name;
            var build_deploy_selected_value = e.target.value;
            setState(new_state => ({
                ...new_state,
                data: {
                    ...new_state.data,
                    [build_deploy_select]: build_deploy_selected_value
                }
            }))
        } else if (e.target.value === "Deploy") {
            var build_deploy_select = e.target.name;
            var build_deploy_selected_value = e.target.value;
            setState(new_state => ({
                ...new_state,
                error_msg: null,
                data: {
                    ...new_state.data,
                    [build_deploy_select]: build_deploy_selected_value
                }
            }))
        } else {
            var build_deploy_select = e.target.name;
            var build_deploy_selected_value = e.target.value;
            setState(new_state => ({
                ...new_state,
                error_msg: null,
                data: {
                    ...new_state.data,
                    [build_deploy_select]: build_deploy_selected_value
                }
            }))
        }
    }

    function refresh() {
        setState(new_state => ({
            ...new_state,
            redirect: true
        }));
    }

    console.log(state)

    function getCommitSha(selected_branch_name) {
        // let parent_ci_info = ci_data.find((item) => {
        //     if (item.type_label == null) {
        //         return item
        //     }
        // })
        let parent_ci_info = state.ciData ? state.ciData : {}
        let default_branch_name = parent_ci_info?.branch_name
        let commit_sha_obj;
        if (selected_branch_name) {
            commit_sha_obj = state.branch_data.branch_with_commit_sha && state.branch_data.branch_with_commit_sha.find((item) => {
                if (item[selected_branch_name]) {
                    return item[selected_branch_name]
                }
            })
        } else {
            commit_sha_obj = state.branch_data.branch_with_commit_sha && state.branch_data.branch_with_commit_sha.find((item) => {
                if (item[default_branch_name]) {
                    return item[default_branch_name]
                }
            })
        }
        let commit_sha = commit_sha_obj && Object.values(commit_sha_obj)[0]

        return commit_sha;
    }

    function validateAndTriggerBuild() {
        console.log(state, "hchgcfcccfcf")
        let validations = { ...state.validations }

        var result = ValidateDataSet(state.data, validations);
        var children_vaidation_result = inherits.validateForm ? inherits.validateForm() ? inherits.validateForm() : { valid: true } : { valid: true };
        var child_states = inherits.getState ? inherits.getState() ? inherits.getState() : null : null
        var getBuildPostData = child_states.build_component;
        console.log(getBuildPostData, 'dftd', children_vaidation_result)
        if (state.data.env_name === "" || state.data.service_id === '' || getBuildPostData && getBuildPostData.data.branch === '') {
            setState(new_state => ({
                ...new_state,
                error: result.error
            }))
        } else {
            if (children_vaidation_result.valid) {
                if (getBuildPostData) {
                    console.log(getBuildPostData, "getBuildPostData")
                    if (getBuildPostData.data.branch !== undefined || getBuildPostData.data.branch !== null) {
                        var data = {
                            branch_name: getBuildPostData.data.branch,
                            no_cache: getBuildPostData.no_cache == 'on',
                            commit_sha: getCommitSha(getBuildPostData.data.branch)
                        }
                        if (getBuildPostData.open_custom) {
                            data.custom_tag = getBuildPostData.data.custom_tag && getBuildPostData.data.custom_tag !== "" ? getBuildPostData.data.custom_tag : null;
                        } else {
                            data.tag = getBuildPostData.data.tag && getBuildPostData.data.tag !== "" ? getBuildPostData.data.tag : null;
                        }
                        if (getBuildPostData.open_custom && data.tag && data.tag != "") {
                            delete data.custom_tag
                        }
                        if (!getBuildPostData.open_custom && data.custom_tag && data.custom_tag != "") {
                            delete data.tag
                        }
                        if (!data.tag) {
                            delete data.tag
                        }
                        if (!data.custom_tag) {
                            delete data.custom_tag;
                        }
                        if (!data.branch_name) {
                            delete data.branch_name;
                        }
                        if (!data.feature_tag) {
                            delete data.feature_tag
                        }
                    }
                }
                console.log(data, "post_mapper_data")
                var endPoint = GenerateURL({ service_id: Number(state.data.service_id), env_id: getServiceEnvComponentId(state.data.service_id) }, properties.api.env_build_request_url);
                PostData(endPoint, data, successBuildDeployTriggerCallBack, failedBuildDeployTriggerCallBack);
            }
        }
    }

    function validateAndTriggerDeploy() {
        var result = ValidateDataSet(state.data, state.validations);
        var children_vaidation_result = inherits.validateForm ? inherits.validateForm() : { valid: true };
        var child_states = inherits.getState ? inherits.getState() : null;
        var getDeployPostData = child_states.deploy_component;
        if (children_vaidation_result && getDeployPostData.data.deploy_tag !== undefined && getDeployPostData.data.deploy_tag !== null) {
            var data = {
                tag: getDeployPostData.data.deploy_tag,
                deployment_type: getDeployPostData.data.deployment_type == 2 ? "CANARY" : "ROLLING_UPDATE",
                deployment_status_check: getDeployPostData.data.deployment_status_check == "on" ? true : false,
                deployment_name: getDeployPostData.data.deployment_name
            }

            var endPoint = GenerateURL({ service_id: Number(state.data.service_id), env_id: getServiceEnvComponentId(state.data.service_id) }, properties.api.env_deploy_request_url);
            PostData(endPoint, data, successDeploy, failedBuildDeployTriggerCallBack); //successBuildDeployTriggerCallBack
        }
    }

    function successBuildDeployTriggerCallBack(response) {
        console.log(response, "sdbhbfvfvdfvgjsdvhgds")
        setState(new_state => ({
            ...new_state,
            task_id: response.global_task_id,
            build_deploy_success: true,
            error_msg: null
        }))
    }

    function successDeploy(response) {
        console.log("alamn", response);
        setState(new_state => ({
            ...new_state,
            task_id: response[0].global_task_id,
            build_deploy_success: true,
            error_msg: null
        }))
    }


    function failedBuildDeployTriggerCallBack(error) {
        var error_msg = error.detail
        setState({
            ...state,
            trigger: "failed",
            error_msg: error_msg
        });
    }

    function onTypeChangeHandler(e) {
        console.log(e.target.name, e.target.value, "sbfvhbfhdvbdfbvhfdbv")
        var name = e.target.name
        var value = e.target.value

        setState((new_state) => ({
            ...new_state,
            data: {
                ...new_state.data,
                [name]: value
            },
        }))
    }

    console.log('state=>>>>', state);
    // console.log('state.service_data>>>', state.service_data);
    return (
        <>
            {
                state.redirect ? <Redirect to={`/application/${application_id}/service/listing`} /> : ''
            }
            { // state.data_loading !== "SUCCESS"
                !state.build_deploy_success ?
                state.data_loading !== "SUCCESS"? state.data_loading == "FAILED" ? <ErrorComponent variant="error-box" error={state.error} /> :
                       <BuildDeploySkeleton />:
                        <div className={classes.root}>
                            <Heading_Section heading={"New"} application_id={application_id} />
                            <div className="card">
                                <div className="card-body pt-20 pl-16 pr-16">
                                    <Grid container spacing="2" style={{ alignItems: 'baseline' }}>
                                        <Grid item lg={12}>
                                            <Input
                                                type="select"
                                                name="env_name"
                                                label="Environment Name"
                                                mandatorySign={true}
                                                list={state.environment_list ? state.environment_list : []}
                                                data={state.data}
                                                error={state.error}
                                                onChangeHandler={handleEnvChange}
                                            />
                                        </Grid>
                                        {console.log(state.is_manual_build_allowed, state.is_manual_build_allowed)}
                                        {state.is_manual_build_allowed ? null :
                                            state.service_data && state.service_data.length === 0 ?

                                                <AlertStrip
                                                    variant="info"
                                                    dismissible={false}
                                                    message="No services associated with the selected Environment."
                                                    extraClasses="ml-10 mr-10" />
                                                : null
                                        }
                                        {
                                            !state.is_manual_build_allowed &&
                                            <AlertStrip
                                                variant="info"
                                                dismissible={false}
                                                message="Manual Build Not Allowed for this env"
                                                extraClasses="ml-10 mr-10" />

                                        }
                                        {
                                            !state.is_manual_deploy_allowed &&
                                            <AlertStrip
                                                variant="info"
                                                dismissible={false}
                                                message="Manual Deploy Not Allowed for this env"
                                                extraClasses="ml-10 mr-10" />

                                        }

                                        <Grid item lg={12}>
                                            <Input
                                                type="select"
                                                name="service_id"
                                                label="Service Name"
                                                mandatorySign={true}
                                                list={state.service_data ? state.service_data : []}
                                                data={state.data}
                                                error={state.error}
                                                onChangeHandler={(!state.data.env_name) ? false : state.service_data.length > 0 ? handleServiceChange : false} />
                                        </Grid>
                                        {
                                            !state.is_manual_build_allowed && !state.is_manual_deploy_allowed ? null :
                                                <div className='radio-buttons-build-deploy'>
                                                    <Grid item lg={12}>
                                                        <Input
                                                            type="radio"
                                                            mandatorySign
                                                            name="build_deploy"
                                                            list={state.operation_list}
                                                            label="Choose Operation"
                                                            error={state.error}
                                                            data={state.data}
                                                            onChangeHandler={
                                                                (state.buildDeploy_data_loading === 'Loading' || state.buildDeploy_data_loading === undefined)
                                                                    ? false : state.data.service_id ? onTypeChangeHandler : null
                                                            } />
                                                    </Grid>
                                                </div>

                                        }

                                        {
                                            state.is_manual_build_allowed ? state.branch_load === "Loading" ?
                                                <div style={{height:"97px" }} ><BuildComponent skeleton={true}/></div> :
                                                <>
                                                    {
                                                        state.branch_load === "Failed" && state.data.build_deploy === 'Build' ?
                                                            <AlertStrip
                                                                variant="error"
                                                                dismissible={false}
                                                                message={state.branch_data.error}
                                                                extraClasses="ml-10 mr-10" /> : null
                                                    }
                                                </>
                                                : null
                                        }

                                        {
                                            state.is_manual_build_allowed ?
                                                state.data.build_deploy === 'Build' ?
                                                    state.branch_load === undefined || state.branch_load === 'Success' ?
                                                        <>
                                                            <BuildComponent isBranchRequiredForBuild={state.branchRequired} ci_configured={state.ci_configured} branch_load={state.branch_load} branch_data={state.branch_data} inherits={state.child_inherits.build_component} />
                                                            <ResetComponent inherits={state.child_inherits.deploy_component} />

                                                            {
                                                                state.error_msg ?
                                                                    <Grid item lg={12}>
                                                                        <AlertStrip
                                                                            variant="error"
                                                                            dismissible={false}
                                                                            message={state.error_msg}
                                                                            extraClasses="" />
                                                                    </Grid>
                                                                    : null
                                                            }
                                                        </>
                                                        : null : null : null
                                        }
                                        {
                                            state.is_manual_deploy_allowed ?
                                                state.data.build_deploy === 'Deploy' ?
                                                    <>
                                                        <DeployComponent
                                                            application_id={application_id}
                                                            service_id={state.data.service_id}
                                                            check_if_template_selected={state.check_if_template_selected}
                                                            cd_data_loading={state.cd_data_loading}
                                                            getServiceEnvComponentId={getServiceEnvComponentId}
                                                            tag_loaded={state.tag_load}
                                                            deploy_tag_list={state.data.deploy_tags}
                                                            inherits={state.child_inherits.deploy_component}
                                                        />
                                                        <ResetComponent inherits={state.child_inherits.build_component} />
                                                        {
                                                            state.error_msg ?
                                                                <Grid item lg={12}>
                                                                    <AlertStrip
                                                                        variant="error"
                                                                        dismissible={false}
                                                                        message={state.error_msg}
                                                                        extraClasses="" />
                                                                </Grid>
                                                                : null
                                                        }
                                                    </>
                                                    : null : null
                                        }

                                    </Grid>
                                </div>

                                {
                                    state.data.build_deploy ?
                                        <div className="card-footer space-between" style={{ 'marginTop': "15px" }}>
                                            <button style={{ 'visibility': 'hidden' }}></button>
                                            {
                                                state.data.build_deploy && state.data.build_deploy == "Build" ?
                                                    state.is_manual_build_allowed ?
                                                        // state.deploy_env_offline_flag && state.deploy_env_offline_flag.value && state.deploy_env_offline_flag.value === "true" ?
                                                        // state.is_env_down ? 
                                                        // <button className="btn btn-submit"  onClick={handleClickOpenPopover}>Build</button>
                                                        //     :
                                                        <button className="btn btn-submit" onClick={validateAndTriggerBuild}>Build</button>
                                                        :
                                                        <button className="btn btn-disable cursor-not-allowed" >Build</button>
                                                    // :
                                                    // <button className="btn btn-disable cursor-not-allowed" >Build</button>
                                                    :
                                                    state.data.build_deploy && state.data.build_deploy == "Deploy" ?
                                                        state.is_manual_deploy_allowed ?
                                                            state.deploy_env_offline_flag && state.deploy_env_offline_flag.value && state.deploy_env_offline_flag.value === "true" ?
                                                                state.is_env_down ?
                                                                    <button className="btn btn-submit" onClick={handleClickOpenPopover}>Deploy</button>
                                                                    :
                                                                    <button className="btn btn-submit" onClick={validateAndTriggerDeploy}>Deploy</button>
                                                                :
                                                                <button className="btn btn-submit" onClick={validateAndTriggerDeploy}>Deploy</button>
                                                            :
                                                            <button className="btn  btn-disable cursor-not-allowed" style={{ backgroundColor: '' }}>Deploy</button>
                                                        :
                                                        null
                                            }
                                        </div> : null
                                }
                                <LatestBuildDeploy
                                    application_id={application_id}
                                    service_env_data={state.service_Env_Data}
                                    env_list={state.default_environment_list} />
                            </div>
                        </div> : <StepProgressDetailed task_id={state.task_id} placeholders={{ application_id: application_id, env_id: state.data.env_name }} refresh={refresh} triggerBuildDeploy={state.data.build_deploy === 'Build' ? validateAndTriggerBuild : validateAndTriggerDeploy} />
            }
            <Popover
                id={id}
                open={OpenPopover}
                anchorEl={anchorEl}
                onClose={handleClickClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className="popover-content" style={{ padding: '20px', width: '450px', }}>

                    <div className='' style={{ width: '100%', position: 'relative' }}>
                        <IconButton className='btn btn-icon' style={{ position: "absolute", right: '10px', top: '0px' }} onClick={handleClickClosePopover}><CloseIcon className='font-18' style={{ color: '#0086ff', }} /></IconButton>
                        <InfoIcon style={{ color: '#0086ff', width: '100%' }} className='font-48 mb-20 text-center' />
                        <p className='font-18 font-weight-bold text-center mb-5' style={{ color: '#0086ff', width: '100%' }}>ENVIRONMENT IS OFFLINE</p>
                        <p className='font-12 font-weight-400 text-center' style={{ color: '#787878', width: '100%' }}>Please note you cannot trigger build or deployment as the envrionment is offline now.
                            Please contact your team administrator to get the environment online.</p>
                    </div>

                </div>
            </Popover>
        </>
    )
}

const BuildDeploySkeleton = ()=>{
    const classes = useStyles();
    return(
        <div className={classes.root}>
            <Heading_Section skeleton={true} />
            <div className="card">
                <div className="card-body pt-20 pl-16 pr-16">
                    <Grid container spacing="2" style={{ alignItems: 'baseline' }}>
                        <Grid item lg={12}>
                            <InputSkeleton type={"text"} width={"100%"} style={{borderRadius:"4px"}} />
                        </Grid>
                        

                        <Grid item lg={12}>
                            <InputSkeleton type={"text"} width={"100%"} style={{borderRadius:"4px"}}/>
                        </Grid>
                        <Grid item lg={12}>
                            <GenericSkeleton variant={"text"} width={"12%"} />
                            <div className='d-flex align-center' style={{gap:"8px"}}>
                                {Array.from({length : 2}).map((_,index)=>{
                                    return (
                                        <InputSkeleton type={"radio"} width={"100%"} style={{borderRadius:"4px"}}/>
                                    );
                                    
                                })}
                            </div>
                            
                        </Grid>
                    </Grid>
                </div>

                
                        <div className="card-footer justify-end" style={{ 'marginTop': "15px" }}>
                            
                            <GenericSkeleton variant={"rect"} width={56} height={35} style={{borderRadius:"6px"}} rootStyle={{flex:0}} />
                        </div> 
                
            </div>
        </div>
);
}

export default BuildDeploy;


function buildDeployDefaultState() {

    return {
        data: { env_name: '', service_id: '', build_deploy: 'Build', branch: '' },
        error: {},
        is_manual_build_allowed: true,
        is_manual_deploy_allowed: true,
        is_env_down: false,
        service_Env_Data: [],
        build_deploy_success: false,
        check_if_template_selected: false,
        cd_data_loading: "Failed",
        build_only_list: [
            {
                label: "Build",
                value: "Build"
            },
        ],
        deploy_only_list: [
            {
                label: "Deploy",
                value: "Deploy"
            }
        ],
        operation_list: [
            {
                label: "Build",
                value: "Build"
            },
            {
                label: "Deploy",
                value: "Deploy"
            }
        ],
        validations: {
            env_name: [VALIDATION_TYPE_REQUIRED],
            service_id: [VALIDATION_TYPE_REQUIRED],
        },
        child_inherits: {
            deploy_component: {
                "validateForm": () => { return { valid: true } },
                "getState": () => { return {} },
                "getData": () => { return {} }
            },
            build_component: {
                "validateForm": () => { return { valid: true } },
                "getState": () => { return {} },
                "getData": () => { return {} }
            },
        }
    }
}

const useStyles = makeStyles({
    root: {
        margin: '20px',
        '& > .card': {
            borderRadius: 4,
            marginTop: '20px',
            overflowY: 'scroll'
        },
        '& .switch-build': {
            marginTop: '16px',
            '& .card-header': {
                backgroundColor: '#fff!important',
                borderBottom: '0px solid #fff!important',
            }
        },
        "& .radio-buttons-build-deploy":{
            "& .Mui-disabled":{
                backgroundColor : "#ffffff !important",
            }
        }
    },
    root1: {
        '& .main-div': {
            marginBottom: '-18px!important'
        }
    }
});

const Heading_Section = ({skeleton,...props}) => {
    const classes = useStyles();
    if(skeleton){
        return (
            <div className={classes.root1}>
                <Grid item lg={12}>
                    <div className="d-flex align-center space-between">
                        <div className="width-full">
                            <GenericSkeleton variant={"text"} height={25} width={"25%"} />
                            <GenericSkeleton variant={"text"} height={12} width={"65%"} />
                        </div>
                        <GenericSkeleton variant={"rect"} width={53} height={27} style={{borderRadius:"6px"}} />
                    </div>
                </Grid>
            </div>
        )
    }
    const application_id = props.application_id;
    return (
        <div className={classes.root1}>
            <Grid item lg={12}>
                <div className="d-flex align-center space-between">
                    <div className="">
                        <p className="heading-primary">Build &amp; Deploy</p>
                        <p className="sub-heading">Build or Deploy your services. to understand more about build and deploy please read the <a href="https://www.opstree.com/buildpiper/documentation/" target="_blank" className="text-anchor-blue">Documentation</a></p>
                    </div>
                    <Link to={"/application/" + application_id + "/service/listing"}><button className="btn btn-primary" >Back</button></Link>
                </div>
            </Grid>
        </div>
    )
}

Heading_Section.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}
