import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import { Loading } from '../../../utils/Loading';
import { Menu, MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Delete from '../../../../components/genericComponents/Delete';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { Tooltip } from '@material-ui/core';
import { GetAuth } from '../../../../util/security';
import GenericSkeleton from '../../../../components/genericComponents/Skeletons/GenericSkeleton';
import useFetchPermissions from '../../../../components/customHooks/useFetchPermissions';


export default function GitRepoCard({ skeleton, ...props }) {
    const classes = useStyles();
    if (skeleton) {
        return (
            <div className={`${classes.commonTable} d-grid align-center`} style={{ gridTemplateColumns: '170px 150px 210px 130px 1fr', gap: '10px' }}>
                <div className='manage-list d-flex align-center' style={{ display: 'grid', gridTemplateColumns: '50px auto', gap: '5px' }}>
                    <div className='manage-img'>
                        <GenericSkeleton variant={"circle"} width={50} height={50} />
                    </div>
                    <div className=''>
                        <div className='title'>
                            <GenericSkeleton variant={"text"} height={20} width={"100%"} />
                        </div>
                        {/* <div className={classes.valueDetails}>{title}</div> */}
                        <div>

                            <p className="text-ellipsis-150">
                                <GenericSkeleton variant={"text"} height={20} width={"100%"} />
                            </p>

                        </div>
                    </div>
                </div>
                <div className='manage-list'>
                    <div className='title'>
                        <GenericSkeleton variant={"text"} height={20} width={"100%"} />
                    </div>
                    {/* <div className={classes.valueDetails + " " + "text-anchor-blue cursor-pointer"}>{url}</div> */}
                    <div>

                        <p className="text-ellipsis-150 text-anchor-blue cursor-pointer">
                            <GenericSkeleton variant={"text"} height={18} width={"100%"} />
                        </p>

                    </div>
                </div>
                <div className='manage-list'>
                    <div className='title'><GenericSkeleton variant={"text"} height={20} width={"100%"} /></div>
                    <div className={classes.valueDetails}><GenericSkeleton variant={"text"} height={20} width={"100%"} /></div>
                </div>
                <div className='text connected text connected d-flex align-center'> <GenericSkeleton variant={"text"} height={20} width={125} /> </div>

                <div className='manage-update-icons'>
                    <div className='r-col7 repos-card-content'>
                        <GenericSkeleton variant={"rect"} width={5} height={20} />
                    </div>
                </div>
            </div>
        );
    }
    const { title, logo, url, apiUrl, type, id, refreshFun, git_provider_id, connected, credential_list, secret_id } = props
    const [state, setState] = useState({});
    var secret_name = credential_list.filter(item => item.id === secret_id)
    const data = props.data

    return (

        <div className={`${classes.commonTable} d-grid align-center`} style={{ gridTemplateColumns: '170px 150px 210px 130px 210px 1fr', gap: '10px' }}>
            <div className='d-flex align-center'>
                <div className='d-flex align-center justify-center' style={{ width: '48px', height: '48px', borderRadius: '24px', border: '1px solid #E6E5E5' }}>
                    <img src={logo} style={{ width: '80%' }} alt=".." />
                </div>
                <Tooltip title={title}>
                    <p className='font-12 ml-5 text-ellipsis-110'>{title}</p>
                </Tooltip>
            </div>
            <>
            {
                type ? 
                <Tooltip title={type}>
                <p className='font-12 text-ellipsis-110'>{type}</p>
            </Tooltip>: 
            "N/A"
            }
            {
                url ? 
                <Tooltip title={url}>
                <p className='font-12 text-ellipsis-200 text-anchor'>{url}</p>
            </Tooltip>: 
            "N/A"
            }
            {
                secret_name && secret_name[0] ? 
                <Tooltip title={secret_name[0] && secret_name[0].name ? secret_name[0].name : "N/A"}>
                <div className={"font-12 text-ellipsis-110 text-anchor"}>{secret_name[0] && secret_name[0].name ? secret_name[0].name : "N/A"}</div>
            </Tooltip>: 
            "N/A"
            }

            
            
           
            </>
            {
                state.loading_status ?
                    <Loading varient="light" /> :
                    connected ?
                        <div className='text connected text connected d-flex align-center'> <CheckCircleIcon style={{ color: '#69e09c' }} /><p className='title-heading'> Connected </p> </div>
                        :
                        <div className='text connected text connected d-flex align-center'> <CheckCircleIcon style={{ color: '#999999' }} /><p className='title-heading'> Connected </p> </div>
            }

            <div className='manage-update-icons'>
                <div className='r-col7 repos-card-content'>
                    <OptionList
                        data={{ id: id, name: title }}
                        git_provider_id={git_provider_id}
                        refreshFun={refreshFun}
                        apiUrl={apiUrl}
                    />
                </div>
            </div>
        </div>

    )
}

GitRepoCard.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};

const ITEM_HEIGHT = 48;
const OptionList = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const permissions = useFetchPermissions();
    const open = Boolean(anchorEl);
    const data = props.data ? props.data : {};
    const git_provider_id = props.git_provider_id ? props.git_provider_id : null;
    const edit_id = data.id ? data.id : null
    const name = data.name ? data.name : null
    const refreshFun = props.refreshFun ? props.refreshFun : () => { };
    const apiUrl = props.apiUrl ? props.apiUrl : ""
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    var url = GenerateURL({ edit_id: edit_id }, properties.api.fetch_single_repo_data, true);
    const is_permited = permissions?.POST.includes(url);
    const is_delete_permitted = permissions?.DELETE.includes(url);

    console.log(url, is_permited, "skfvbhbxhbvhbx")


    return (
        <>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <span className='ri-more-2-fill font-20'></span>
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: 'fit-content',
                    },
                }}
            >
                <MenuItem style={{ display: "grid" }}>
                    {
                        git_provider_id === 3000 ?
                            is_permited ?
                                <Link to={"/repo/bitbucket/" + edit_id + "/edit"} className="d-flex align-center space-between font-12 " style={{color:'#505050'}}>
                                    <span className='ri-edit-line'></span> Edit
                                </Link>
                                :
                                <Tooltip title="You are not allowed to perform this action">
                                    <span><span className='ri-edit-line'></span> Edit</span>
                                </Tooltip>
                            :
                            git_provider_id === 2000 ?
                                is_permited ?
                                    <Link to={"/repo/gitlab/" + edit_id + "/edit"} className="d-flex align-center space-between font-12 " style={{color:'#505050'}}>
                                        <span className='ri-edit-line'></span> Edit
                                    </Link>
                                    :
                                    <Tooltip title="You are not allowed to perform this action">
                                        <span><span className='ri-edit-line'></span> Edit</span>
                                    </Tooltip>
                                :
                                git_provider_id === 1000 ?
                                    is_permited ?
                                        <Link to={"/repo/github/" + edit_id + "/edit"} className="d-flex align-center space-between font-12 " style={{color:'#505050'}}>
                                           <span className='ri-edit-line'></span> Edit
                                        </Link>
                                        :
                                        <Tooltip title="You are not allowed to perform this action">
                                            <span><span className='ri-edit-line'></span> Edit</span>
                                        </Tooltip>

                                    :
                                    is_permited ?
                                        <Link to={"/repo/bitbucket/" + edit_id + "/edit"} className="d-flex align-center space-between font-12 " style={{color:'#505050'}}>
                                            <span className='ri-edit-line'></span> Edit
                                        </Link>
                                        :
                                        <Tooltip title="You are not allowed to perform this action">
                                           <span><span className='ri-edit-line'></span> Edit</span>
                                        </Tooltip>
                    }


                </MenuItem>
                <MenuItem style={{ display: "grid", justifyContent: 'center' }}>
                    <Delete 
                    display_data_name={name} 
                    default_hpa={false} 
                    varient="new_ui_versioning"
                    data={{ entity_id: edit_id, name: "git_repo" }} 
                    refresh={refreshFun} 
                    api_link={GenerateURL({ edit_id: edit_id }, apiUrl)} 
                    is_edit_permited={is_delete_permitted} />
                </MenuItem>
            </Menu>
        </>
    )
}

OptionList.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};

const useStyles = makeStyles({
    commonTable: {
        border: '1px solid #e6e6e6',
        borderTop: '0px',
        padding: '10px',
        '&:first-child': {
            borderTop: '1px solid #e6e6e6',
            borderRadius: '6px 6px 0px 0px'
        },
        '&:last-child': {
            borderRadius: '0px 0px 6px 6px',
        }
    }
})



