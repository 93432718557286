import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useHistory, useLocation } from 'react-router-dom';

import { ErrorComponent } from '../../utils/Error';
import { Loading } from '../../utils/Loading';
import properties from '../../../properties/properties';
import GenerateURL, { GenerateSearchURL } from '../../../util/APIUrlProvider';
import InvokeApi from '../../../util/apiInvoker';
import GitRepoCard from './components/GitRepoCard';

import NoRepoIntegrated from './components/NoRepoIntegrated';
import Pagination from '../../../components/Pagination';
import SearchBar from '../../../components/SearchBar';
import ReposHeader from './components/ReposHeader';
import { PostData } from '../../../util/apiInvoker';
import AdvanceSearchFilterCombo from '../../../components/genericComponents/AdvanceSearchFilter/AdvanceSearchFilterCombo';
import ListingPageHeaderSkeleton from '../../../components/genericComponents/Skeletons/ListingPageHeaderSkeleton';
import GenericSkeleton from '../../../components/genericComponents/Skeletons/GenericSkeleton';
import BlankPage from '../../../components/BlankPage';
import ReposIntegrationDialog from './components/ReposIntegrationDialog';
import PaginationTwo from '../../../components/PaginationTwo';


const ReposListing = () => {
    console.log('report listing>>')
    const classes = useStyles();
    const registry_logos = properties.repo_list;
    const location = useLocation();
    const apiUrlForUpdate = properties.api.fetch_single_repo_data;
    const [state, setState] = useState({

        data: {
        },
        credential_list: [],
        error: null,
        total_page: '',
        curr_page: '',
        moreAdvFilterList: [],
        advFilters: {
            provider: [],
            name: [],
            secret: [],
            url: [],
        },
        showLoadingIcon: false,
        git_acc_list: null,
        refresh_count: 1,
        resetCount: 0,
    })
    const history = useHistory()
    const defaultFilters = ["provider"] //"repo_name"
    useEffect(() => {
        fetchGitAccData()
    }, [state.refresh_count])

    useEffect(() => {
        resetAdvFilter();
    }, [])

    const resetFilterData = {
        provider: [],
        name: [],
        secret: [],
        url: [],
    }

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(location.search);
        console.log("jnjndjfnjer", urlSearchParams);
        if (urlSearchParams.size != 0) {
            let moreAdvFilter = defaultFilters
            let advFilters = {}
            urlSearchParams?.forEach((value, key) => {
                if (value) {
                    if (key == 'adv_search') {
                        let advValue = value
                        let updatedList = advValue.split(',')
                        updatedList = [...moreAdvFilter, ...updatedList]
                        moreAdvFilter = [...new Set(updatedList)]
                    }
                    else {
                        let filterValue = value
                        const updatedList = filterValue.split(',')
                        advFilters[key] = updatedList
                    }
                }
            })
            console.log("jnjndjfnjer", urlSearchParams, moreAdvFilter, advFilters);
            setState(prevState => ({
                ...prevState,
                moreAdvFilterList: moreAdvFilter,
                advFilters: { ...advFilters }
            }))
            fetchReposForListFilter(advFilters, moreAdvFilter)
        }
        else {
            fetchGitAccData()
        }

    }, [])



    const isFilterActive = (filterKey, list) => {

        const isFound = list?.find(name => filterKey == name)

        return isFound;
    }

    function generateFilterContainURL(baseURL, listDict, activeFilters) {
        var searchParams = ''
        const filtersKeyArray = Object.keys(listDict)
        let i = 0
        let advFilterApplied = false
        const activeFilterList = activeFilters ? activeFilters : state.moreAdvFilterList
        filtersKeyArray.forEach(key => {

            const arrayFilterValues = listDict[key];
            if (arrayFilterValues.length > 0 && isFilterActive(key, activeFilterList)) {
                if (i != 0) {
                    searchParams += '&'
                }
                advFilterApplied = true
                var arrayString = arrayFilterValues;
                const stringKey = `${key}=${arrayString}`
                searchParams += stringKey
                i++;
            }
        })

        const finalUrl = baseURL + '?' + searchParams.toString()

        return searchParams != '' ? finalUrl : baseURL
    }

    function fetchReposForListFilter(listFilterData, activeFilterList) {

        let baseURL = GenerateURL({}, properties.api.fetch_all_repo)

        const resultURL = generateFilterContainURL(baseURL, listFilterData, activeFilterList);

        let requestInfo = {
            endPoint: resultURL,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };
        setState((new_state) => ({
            ...new_state,
            showLoadingIcon: true,
        }));
        InvokeApi(requestInfo,
            (response) => {
                let git_acc = attachLogosToRegistryList(response.results)
                setState((new_state) => ({
                    ...new_state,
                    current: requestInfo.endPoint,
                    activity_id: response.id,
                    curr_page: 1,
                    error: false,
                    count: response.count,
                    git_acc_list: git_acc,// git_acc,

                    next: response.next ? properties.api.baseURL + response.next : null,
                    previous: response.previous
                        ? properties.api.baseURL + response.previous
                        : null,
                    showLoadingIcon: false,
                    total_page: Math.ceil(Number(response.count / 10)),
                }));
            },
            RepoFetchFailure);
    }

    function RepoFetchFailure(error) {
        setState((new_state) => ({
            ...new_state,
            error: error,
            showLoadingIcon: false,
        }));
    }

    function filterRepoName(data) {
        console.log("dnuehuhjnxwja", data);
        const updatedList = data?.map((item) => {
            return { label: item.name, value: item.name }
        });
        return updatedList;
    }

    function filterSecretName(data) {
        console.log('secret_listinhsgdhgabsh', data);
        const updatedList = data?.map((item) => {
            return { label: item.name, value: item.name }
        });
        return updatedList;
    }

    function filterRepoUrl(data) {
        console.log("uhruheuheu", data);
        const updatedList = data?.map((item) => {
            const urlPartList = item.git_url.split("/");
            const lastPart = urlPartList[urlPartList.length - 1]
            return { label: lastPart, value: item.git_url }
        });
        return updatedList;
    }

    const advanceFilterJson = {

        'provider': {
            staticList: [{ label: "GitHub", value: "github" },
            { label: "GitLab", value: "gitlab" },
            { label: "BitBucket", value: "bitbucket" }
            ],
            labelName: 'Provider',
            uniqueId: 'provider_adv_1',
            searchVariable: null,
            getFetchUrl: null,
            filterDataPraseFunction: null
        },
        'name': {
            staticList: false,
            labelName: 'Repository',
            uniqueId: 'repo_name_adv_1',
            searchVariable: 'repo',
            getFetchUrl: properties.api.fetch_all_repo,
            searchUrl: properties.api.fetch_all_repo,
            filterDataPraseFunction: filterRepoName,

        },
        'secret': {
            staticList: false,
            uniqueId: 'secret_name_adv_1',
            labelName: 'Secret',
            searchVariable: 'secret',
            getFetchUrl: properties.api.credential_all,
            searchUrl: properties.api.credential_all,
            filterDataPraseFunction: filterSecretName,

        },
        'url': {
            staticList: false,
            uniqueId: 'url_adv_1',
            labelName: 'URL',
            searchVariable: 'url',
            getFetchUrl: properties.api.fetch_all_repo,
            searchUrl: properties.api.fetch_all_repo,
            filterDataPraseFunction: filterRepoUrl,

        },

    }
    const moreFilterData = [
        { label: "Provider", value: "provider" },
        { label: "Repo Name", value: "name" },
        { label: "Secret Name", value: "secret" },
        { label: "Url", value: "url" },
    ]

    const addFiltersToUrl = (filterName, filterValue) => {
        console.log("thsjks", filterName, filterValue);
        let urlSearchParams = new URLSearchParams(location.search);
        const allFilters = [
            "env_master",
            "project_name",
            "job_type",
            "user_id",
            "status",
            "worker_name",
        ]

        if (filterName == 'all_delete') {
            urlSearchParams = ''
        } else {
            if (filterName == 'adv_search') {
                allFilters.forEach(value => {
                    if (!filterValue.includes(value)) {
                        urlSearchParams.delete(value)
                    }
                })
            }

            if (filterValue?.length == 0) {
                urlSearchParams.delete(filterName)
            }
            else {
                if (urlSearchParams.has(filterName)) {
                    urlSearchParams.set(filterName, filterValue.join(','));
                } else {
                    urlSearchParams.append(filterName, filterValue.join(','));
                }
            }
        }


        history.replace({ pathname: location.pathname, search: urlSearchParams.toString() });
    }

    const onUpdateHandle = (uniqueId, updatedList) => {

        let updatedKey = ''
        if (uniqueId === 'more-button-adv-0') {
            addFiltersToUrl('adv_search', updatedList)
            if (updatedList?.length == 0) {
                resetAdvFilter()
            }
            else {
                // const alreadyAddedFilters = state.advFilters
                // Object.keys(alreadyAddedFilters)?.forEach(filterName => {
                //   if (!updatedList.includes(filterName)) {
                //     alreadyAddedFilters[filterName] = []
                //   }
                // })
                setState(prevState => ({
                    ...prevState,
                    moreAdvFilterList: updatedList,
                    // advFilters: { ...alreadyAddedFilters }
                }))
                //fetchReposForListFilter(alreadyAddedFilters)
            }
        }
        else {
            if (uniqueId == 'provider_adv_1') {
                updatedKey = 'provider'

            }
            if (uniqueId == 'repo_name_adv_1') {
                updatedKey = 'name'
            }
            if (uniqueId == 'secret_name_adv_1') {
                updatedKey = 'secret'
            }
            if (uniqueId == 'url_adv_1') {
                updatedKey = 'url'
            }


            setState(prevState => ({
                ...prevState,
                advFilters: {
                    ...prevState.advFilters,
                    [updatedKey]: updatedList
                }
            }))
            addFiltersToUrl(updatedKey, updatedList)
            const advFilters = state.advFilters
            advFilters[updatedKey] = updatedList
            fetchReposForListFilter(advFilters)
        }
    }


    const resetAdvFilter = () => {

        fetchReposForListFilter(resetFilterData)
        addFiltersToUrl('all_delete')
        setState(prevState => ({
            ...prevState,
            moreAdvFilterList: defaultFilters,
            advFilters: resetFilterData,
            resetCount: prevState.resetCount + 1
        }))

        // will call here normal function
    }

    function fetchCredentials() {

        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.credential_all),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        requestInfo.endPoint = requestInfo.endPoint + "?all=true";

        InvokeApi(requestInfo, CredentialsFetchSuccess, CredentialsFetchFailure);
        setState(new_state => ({
            ...new_state,
            credentials_status: "LOADING",
        }));
    }

    function CredentialsFetchSuccess(data) {

        if (data && data.results) {
            setState(new_state => ({
                ...new_state,
                credential_list: data.results,
                credentials_status: "SUCCESS",

            }))
        } else {
            setState(new_state => ({
                ...new_state,
                credential_list: [],
                credentials_status: "SUCCESS",
            }));
        }

    }

    function CredentialsFetchFailure(error, exception) {

        setState(new_state => ({
            ...new_state,
            credentials_status: "FAILED",
            error_in_load_credentials: error
        }));

    }
    const fetchGitAccData = (data, url) => {

        const apiUrl = properties.api.fetch_all_repo

        let requestInfo = {
            endPoint: GenerateURL({}, apiUrl),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        if (data) {
            requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
        }


        if (url) {
            requestInfo.endPoint = url;
        }

        InvokeApi(requestInfo, handleSuccessApiHit, handleFailedApiHit);
        setState(newState => ({
            ...newState,
            showLoadingIcon: true,
            search_data: data,
            current: requestInfo.endPoint,
            data_loading: "LOADING",
            search_query_name: data ? data.name ? data.name : "" : "",
        }))

    }

    const handleSuccessApiHit = (data) => {
        fetchCredentials()
        let git_acc = attachLogosToRegistryList(data.results)

        setState(prevState => ({
            ...prevState,
            git_acc_list: git_acc.length !== 0 ? git_acc : null,
            showLoadingIcon: false,
            count: data.count,
            next: data.next ? properties.api.baseURL + data.next : null,
            previous: data.previous ? properties.api.baseURL + data.previous : null,
            total_page: Number.isInteger(Number(data.count) / 10) ? (Number(data.count) / 10).toFixed(0) : (Number(data.count) / 10 + 1).toFixed(0) > (Number(data.count) / 10 + 1) ? (Number(data.count) / 10 + 1).toFixed(0) - 1 : (Number(data.count) / 10 + 1).toFixed(0),
            curr_page: 1
        }));
    }

    const handleFailedApiHit = (error) => {
        setState(prevState => ({
            ...prevState,
            error: error,
            showLoadingIcon: false
        }))
    }

    const attachLogosToRegistryList = (list) => {

        if (list.length !== 0) {
            let registriesList = list && list.map(registry => {

                const registryObj = registry_logos && registry_logos.find(property => property.id === registry.git_provider_id)

                return {
                    ...registry,
                    logo: registryObj && registryObj.src ? registryObj.src : null,
                    registry_type_name: registryObj && registryObj.label ? registryObj.label : null
                }
            })

            return registriesList
        }

        return []
    }
    function fetchNextRegistriesInfo(data, url) {

        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.fetch_all_repo),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        if (data) {
            requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
        }


        if (url) {
            requestInfo.endPoint = url;
        }
        setState(new_state => ({
            ...new_state,
            search_data: data,
            current: requestInfo.endPoint,
            data_loading: "LOADING",
            showLoadingIcon: true,
        }));
        InvokeApi(requestInfo, RegistriesNextInfoFetchSuccess, RegistriesInfoFetchFailure);

    }

    function RegistriesNextInfoFetchSuccess(data) {
        let git_acc = attachLogosToRegistryList(data.results)
        setState(new_state => ({
            ...new_state,
            data_loading: "SUCCESS",
            showLoadingIcon: false,
            git_acc_list: git_acc.length !== 0 ? git_acc : null,
            count: data.count,
            next: data.next ? properties.api.baseURL + data.next : null,
            previous: data.previous ? properties.api.baseURL + data.previous : null,
            total_page: Number.isInteger(Number(data.count) / 10) ? (Number(data.count) / 10).toFixed(0) : (Number(data.count) / 10 + 1).toFixed(0) > (Number(data.count) / 10 + 1) ? (Number(data.count) / 10 + 1).toFixed(0) - 1 : (Number(data.count) / 10 + 1).toFixed(0),
            curr_page: new_state.curr_page + 1
        }));
    }
    function RegistriesInfoFetchFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            data_loading: "FAILED",
            showLoadingIcon: false,
            error: true
        }));
    }

    function fetchPrevRegistriesInfo(data, url) {

        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.fetch_all_repo),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        if (data) {
            requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
        }


        if (url) {
            requestInfo.endPoint = url;
        }
        setState(new_state => ({
            ...new_state,
            search_data: data,
            current: requestInfo.endPoint,
            data_loading: "LOADING",
            showLoadingIcon: true,
        }));
        InvokeApi(requestInfo, RegistriesPrevInfoFetchSuccess, RegistriesPrevInfoFetchFailure);

    }

    function RegistriesPrevInfoFetchSuccess(data) {
        let git_acc = attachLogosToRegistryList(data.results)
        setState(new_state => ({
            ...new_state,
            data_loading: "SUCCESS",
            showLoadingIcon: false,
            git_acc_list: git_acc.length !== 0 ? git_acc : null,
            count: data.count,
            next: data.next ? properties.api.baseURL + data.next : null,
            previous: data.previous ? properties.api.baseURL + data.previous : null,
            total_page: Number.isInteger(Number(data.count) / 10) ? (Number(data.count) / 10).toFixed(0) : (Number(data.count) / 10 + 1).toFixed(0) > (Number(data.count) / 10 + 1) ? (Number(data.count) / 10 + 1).toFixed(0) - 1 : (Number(data.count) / 10 + 1).toFixed(0),
            curr_page: new_state.curr_page - 1
        }));
    }
    function RegistriesPrevInfoFetchFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            data_loading: "FAILED",
            showLoadingIcon: false,
            error: true
        }));
    }

    function fetchPageRegistries(enteredPageNumber) {
        var requestInfo = {
            endPoint: GenerateURL(
                {},
                properties.api.fetch_all_repo
            ),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };

        if (enteredPageNumber > 1) {
            requestInfo.endPoint =
                requestInfo.endPoint +
                "?limit=10&offset=" +
                (enteredPageNumber - 1) * 10;
        }

        var current_page = enteredPageNumber;

        InvokeApi(
            requestInfo,
            (response) => {
                handlePageSuccessApiHit(response, current_page);
            },
            handlePageFailedApiHit
        );
        setState((new_state) => ({
            ...new_state,
            loaded: false,
        }));
    }

    const handlePageFailedApiHit = (error, exception) => {
        setState(new_state => ({
            ...new_state,
            data_loading: "FAILED",
            error: true
        }));
    };

    const handlePageSuccessApiHit = (response, count) => {
        console.log("handleSuccessApiHit", response);
        let git_acc = attachLogosToRegistryList(response.results)
        setState((new_state) => ({
            ...new_state,
            loaded: true,
            count: response.count,
            next: response.next ? properties.api.baseURL + response.next : null,
            previous: response.previous
                ? properties.api.baseURL + response.previous
                : null,
            git_acc_list: git_acc.length !== 0 ? git_acc : null,
            total_page: Number.isInteger(Number(response.count) / 10)
                ? (Number(response.count) / 10).toFixed(0)
                : (Number(response.count) / 10 + 1).toFixed(0) >
                    Number(response.count) / 10 + 1
                    ? (Number(response.count) / 10 + 1).toFixed(0) - 1
                    : (Number(response.count) / 10 + 1).toFixed(0),
            curr_page: Number(count)
        }));
    };

    const onActionHandler = (type, data) => {
        console.log('type>>', type, data)
        if (type === 'edit' && data) {
            history.push(`/registry/${data.id}/registry-edit`, data)
        }
    }

    const invokeSearch = (data) => {
        fetchGitAccData(data);
    }


    function sendApprovalRequest(id) {
        var post_url = GenerateURL({}, properties.api.approval_request);

        var post_obj = {
            "name": "git_repo",
            "entity_id": id
        }

        PostData(post_url, post_obj, sendApprovalRequestSuccess, sendApprovalRequestFailed);
        setState(new_state => ({
            ...new_state,
            loading: true,
            error_msg: null,
            open: true
        }));
    }

    function sendApprovalRequestSuccess(response) {
        refreshState()
        var msg = response.detail
        setState(new_state => ({
            ...new_state,
            approval_request_success_msg: msg,
        }));

    }

    function sendApprovalRequestFailed(error) {
        var error = error.toString()
        setState(new_state => ({
            ...new_state,
            deployment_failed_msg: error
        }));

    }

    function confirmForApproval(id) {
        var post_url = GenerateURL({}, properties.api.confirm_approval);

        var post_obj = {
            "name": "git_repo",
            "entity_id": id
        }

        PostData(post_url, post_obj, confirmApprovalRequestSuccess, confirmApprovalRequestFailed);
        setState(new_state => ({
            ...new_state,
            loading: true,
            error_msg: null,
            open: true
        }));
    }

    function confirmApprovalRequestSuccess(response) {
        refreshState()
        var msg = response.detail
        setState(new_state => ({
            ...new_state,
            approval_confirm_success_msg: msg,
        }));
    }

    function confirmApprovalRequestFailed(error) {
        var error = error.toString()
        setState(new_state => ({
            ...new_state,
            approval_failed_msg: error
        }));
    }

    function refreshState() {
        setState(new_state => ({
            ...new_state,
            refresh_count: new_state.refresh_count + 1
        }));
    }
    const [open, setOpen] = useState(false);
    const handleOpenDialog = () => {
        setOpen(true)
    }
    const handleCloseDialog = () => {
        setOpen(false)
    }

    return (
        <div className={classes.reposList}>

            {
                state.showLoadingIcon ?
                    <RepoListingPageSkeleton /> :
                    state.error ?
                        <ErrorComponent variant='error-box' error={state.error} />
                        :
                        state.git_acc_list && state.git_acc_list.length > 0 ?
                            <>
                                <ReposHeader
                                    list_length={state.git_acc_list && state.git_acc_list.length}
                                />
                                <Grid container spacing={2}>
                                    <Grid item lg={9} >
                                        <Grid container style={{ rowGap: "10px" }}>
                                            {
                                                state.moreAdvFilterList?.map(filterName => {
                                                    return (
                                                        <div key={filterName} className="ad-more-search">
                                                            <AdvanceSearchFilterCombo
                                                                reset={state.resetCount}
                                                                selectedCheckBoxes={state.advFilters[filterName]}
                                                                searchVariable={advanceFilterJson[filterName]['searchVariable']}
                                                                staticList={advanceFilterJson[filterName]['staticList']}
                                                                uniqueId={advanceFilterJson[filterName]['uniqueId']}
                                                                labelName={advanceFilterJson[filterName]['labelName']}
                                                                searchUrl={advanceFilterJson[filterName]['searchUrl']}
                                                                onUpdate={onUpdateHandle}
                                                                getFetchUrl={advanceFilterJson[filterName]['getFetchUrl']}
                                                                filterDataPraseFunction={advanceFilterJson[filterName]['filterDataPraseFunction']}
                                                                showMoreNotRequired={advanceFilterJson[filterName]['showMoreNotRequired']}
                                                            />
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className="ad-more-search">
                                                <AdvanceSearchFilterCombo
                                                    selectedCheckBoxes={state.moreAdvFilterList}
                                                    reset={state.resetCount}
                                                    staticList={moreFilterData}
                                                    autoClosedAfterSelection={true}
                                                    onUpdate={onUpdateHandle}
                                                    variant='more-button-new'
                                                    uniqueId='more-button-adv-0' />
                                            </div>
                                            <div className="middle-line mr-10" style={{ height: "40px", width: "1px", background: "#CACACA" }}></div>

                                            <div className="overflow-hidden d-inline-flex align-center justify-center gap-4 cursor-pointer" onClick={resetAdvFilter}>
                                                <span className="ri-filter-3-line font-20 blue-text" ></span>
                                                <div className="font-12 font-weight-500 text-transform-uppercase word-wrap-break text-align-center blue-text" >Clear Filters</div>
                                            </div>
                                        </Grid>
                                    </Grid>



                                    {state.git_acc_list && state.git_acc_list.length > 0 ?
                                        <Grid item lg={12} sm={12} xl={12} xs={12}>
                                            <div className="d-grid color-primary font-12 font-weight-600"
                                                style={{
                                                    gridTemplateColumns: '170px 150px 210px 130px 210px 1fr',
                                                    gap: '10px',
                                                    padding: '12px 12px'
                                                }}>
                                                <p> Name</p>
                                                <p> Type </p>
                                                <p> URL </p>
                                                <p> Credential </p>
                                                <p> Status </p>
                                                <p></p>
                                            </div>
                                            <div className="scrollable-div__p1">
                                                {state.git_acc_list.map((single_git_acc) => {
                                                    return (
                                                        <>
                                                        <GitRepoCard
                                                            data={single_git_acc}
                                                            key={single_git_acc.id}
                                                            git_provider_id={single_git_acc.git_provider_id}
                                                            apiUrl={apiUrlForUpdate}
                                                            refreshFun={fetchGitAccData}
                                                            username={single_git_acc.username}
                                                            type={single_git_acc.registry_type_name}
                                                            title={single_git_acc.name}
                                                            id={single_git_acc.id}
                                                            lastRefreshDate={single_git_acc.updated_at}
                                                            url={single_git_acc.git_url}
                                                            logo={single_git_acc.logo}
                                                            secret_id={single_git_acc.credential_id}
                                                            credential_list={state.credential_list.length > 0 ? state.credential_list : []}
                                                            connected={single_git_acc.is_last_connected}
                                                            onActionHandler={onActionHandler}
                                                            sendForApproval={sendApprovalRequest}
                                                            confirmForApproval={confirmForApproval}
                                                        />
                                                        </>
                                                    )

                                                })}
                                            </div>
                                        </Grid>

                                        : <NoRepoIntegrated />
                                    }
                                    {state.git_acc_list && state.git_acc_list.length > 0 ?
                                        <Grid item lg={12} sm={12} xl={12} xs={12}>
                                            <PaginationTwo
                                                 total_count={state.total_page}
                                                 next={state.next}
                                                 previous={state.previous}
                                                 count={state.count}
                                                 current_page_count={state.curr_page}
                                                on_previous_click={() => { fetchPrevRegistriesInfo(null, state.previous) }}
                                                on_next_click={() => { fetchNextRegistriesInfo(null, state.next) }}
                                                on_pageNumber_click={(pageNumber) => {
                                                    fetchPageRegistries(pageNumber);
                                                }}
                                            />
                                        </Grid>

                                        : null}
                                </Grid>
                            </>
                            : <BlankPage
                                text={"You have no repo added"}
                                pageIcon={<img src="/images/header_icons/k8s-logo.svg" />}
                                action="/cluster/add/firstpage"
                                btnLabel={"Add Repo"}
                                primaryButton={
                                    {
                                        actionType: 'button',
                                        action: handleOpenDialog,
                                        icon: <span className="ri-add-line font-14"></span>,
                                        text: 'Add Repo',
                                        buttonClass: 'btn-primary m-auto'
                                    }
                                }
                            />

            }

            {
                open &&
                <ReposIntegrationDialog
                open_dialog={open}
                setOuterDialogClose={handleCloseDialog}
                />
            }


        </div>

    )
}

export const RepoListingPageSkeleton = () => {
    return (
        <>
            <ListingPageHeaderSkeleton />
            <Grid container spacing={2}>
                <Grid item lg={9} >
                    <Grid container style={{ rowGap: "10px" }}>
                        <div className="ad-more-search">
                            <AdvanceSearchFilterCombo
                                skeleton={true}
                            />
                        </div>
                        <div className="middle-line mr-10 ml-10" style={{ height: "40px", width: "1px", background: "#CACACA" }}></div>

                        <div className="overflow-hidden d-inline-flex align-center justify-center gap-4 cursor-pointer">
                            <GenericSkeleton variant={"text"} width={80} />
                        </div>
                    </Grid>
                </Grid>


                <Grid item lg={3} sm={12} xl={3} xs={12}>
                    <Pagination
                        skeleton={true}
                    />
                </Grid>





                {Array.from({ length: 3 }).map((_, index) => {
                    return (
                        <Grid item lg={12} key={index}>
                            <GitRepoCard
                                skeleton={true}
                            />
                        </Grid>
                    )

                })}


            </Grid>
        </>
    );
}



const useStyles = makeStyles({
    reposList: {
        padding: '20px 20px 20px 20px',
        backgroundColor: '#fff',
        height: 'calc(100vh - 70px)',
        '& .reposcard': {
            padding: '10px',
            '& .reposcard-inner': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                background: '#fff',
                borderRadius: '5px',
                padding: '10px',
                marginTop: '10px',
                fontSize: '13px',
                filter: 'drop-shadow(0.877px 1.798px 13.5px rgba(0,0,0,0.05))',
                '& .r-col1': {

                    padding: '10px',
                    marginRight: '15px',
                    display: 'flex',
                },
                '& .repos-card-content': {
                    display: 'flex',
                    alignItems: 'center',
                    '& .img': {
                        width: '50px',
                        height: '50px',
                        backgroundColor: '#ededed',
                        borderRadius: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: '12px',
                        '& img': {
                            width: '60%',
                            height: '60%',
                        },
                    },

                },
                '& .text': {
                    '& .title-heading': {
                        fontWeight: '400',
                        marginBottom: '3px',
                        display: 'flex',
                        alignItems: 'center',
                    },
                    '& .title-name': {
                        color: '#999999',
                    },
                    '& .moreverticon': {
                        fontSize: '1.5em',
                        color: '#3d73eb',
                        cursor: 'pointer',
                    }
                },
                '& .text.connected': {
                    display: 'flex',
                    flexDirection: 'row',
                    '& svg': {
                        fontSize: '1.5em',
                        marginRight: '6px',
                    },
                    '& .title-heading': {
                        fontWeight: '500',
                        textTransform: 'uppercase',
                        color: '#999999',
                    }
                },
                ' & .conn': {
                    textTransform: 'uppercase',
                    fontWeight: '500',
                },
                '& p': {
                    color: '#121212',
                },
                '& b': {
                    fontWeight: '500',
                },
                '& .r-col7': {

                    marginRight: '15px',
                    '& .test-btn': {
                        border: '2px solid #3d73eb',
                        padding: '6px 15px',
                        borderRadius: '5px',
                        fontWeight: '500',
                        color: '#3d73eb',
                    }
                }
            }
        }


    }
});

export default ReposListing