import React from 'react';
import PropTypes from 'prop-types';
import Stepper from '../../../../components/genericComponents/Stepper';


const StageProgressDetail = (props) => {

    const pipeline = props.pipeline;
    const variant = props.variant;
    const pipeline_instance = props.pipeline_instance;
    const steps = generateStepsForStages();

    function generateStepsForStages() {
        const stages = pipeline.stages;
        const stage_instances = pipeline_instance.stage_instance;

        if (variant === 'pipeline-card') {

            const stage_object_with_ids = {};

            stages.forEach(stage => {
                stage_object_with_ids[stage.id] = {
                    main_text: stage.name,
                    sub_text: stage.task_count + " Jobs",
                };
            })

            var previous = "";
            stage_instances.forEach(instance => {
                var status = instance.status.toLowerCase();
                status = status == "pending_approval" ? "pending" : status;
                status = status == "skipped" ? "skipped" : status;
                status = status == "pending_resume" ? "waiting" : status;
                status = status == "running" ? "progress" : status;
                if (stage_object_with_ids[instance.stage_id] != undefined) {
                    stage_object_with_ids[instance.stage_id].status = status;
                    stage_object_with_ids[instance.stage_id].previuos = previous;
                    previous = status;
                }
                else {
                    console.log('this stage is not found==>', instance.stage_id)
                }
            })
            const steps = [];
            const keys = Object.keys(stage_object_with_ids);
            keys.forEach(key => {
                steps.push(stage_object_with_ids[key]);
            });

            return steps;
        }
        else {
            var previous = "";
            const steps = stage_instances.map(instance => {
                var status = instance.status.toLowerCase();
                status = status == "pending_approval" ? "pending" : status;
                status = status == "skipped" ? "skipped" : status;
                status = status == "pending_resume" ? "waiting" : status;
                status = status == "running" ? "progress" : status;
                let stage_data = {
                    main_text: instance.name,
                    sub_text: instance.task_instance_count + " Jobs",
                    status: status,
                    previous: previous,
                }
                previous = status
                return stage_data;
            })
            
            return steps;
        }
    }

    return(
        <Stepper  steps={steps} />
    );
}

StageProgressDetail.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default StageProgressDetail;