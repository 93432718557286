import { createTheme } from '@material-ui/core';
import palette from './palette';
import overrides from './overrides';

const theme = createTheme({
  palette,
  typography: {
    fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif', 
  },
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  }
});

export default theme;