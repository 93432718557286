import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
export default function NoReposConfigured() {
    const classes = useStyles()
    return (
        
            <div className={classes.norapos}>
                <div className='norepos-img'>
                    <img src='/images/integrations/repositories_img.png' alt='..'/>
                </div>
                <h3>There are no Code Repositories Integrated</h3>
                <p>Sorry for the inconvenience but we're looking into the issue at the moment. we'll be back online shortly!</p>
            </div>

        
    )
}

const useStyles = makeStyles({
    norapos: {
        background:'#fff',
        maxWidth: '735px',
        margin: '0 auto',
        textAlign: 'center',
        padding: '15px 90px',
        // borderBottom: '1px solid #eee',
       '& h3':{
           fontSize:'24px',
           fontWeight: 300
       },
       '& p':{
        fontSize:'14px',
        color: '#727272'
    }

    }
});


