import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Tooltip from '@material-ui/core/Tooltip';

import { styled } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import { Link, useParams } from "react-router-dom";

import MenuItem from '@material-ui/core/MenuItem';
import GenerateURL, { GenerateSearchURL } from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import properties from '../../../../properties/properties';

import { LoadingContainer } from '../../../utils/Loading';
import Annotations from './components/Annotations';
import BorderColorIcon from '@material-ui/icons/BorderColor';


import { Cancel } from '@material-ui/icons';
import ExandableKeyValues from '../../../../components/hoc/ExpanadleKeyValues';
import Delete from '../../../../components/genericComponents/Delete';
import { GetAuth } from '../../../../util/security';
import PageHeader from '../../../../components/PageHeader';
import BlankPage from '../../../../components/BlankPage';

const IngressList = props => {
  const classes = useStyles();

  const { cluster_id } = useParams();
  const [spacing, setSpacing] = React.useState(2);
  const dropdwn = [
    {
      id: 1,
      label: "show all"
    }
  ]
  const [showLoading, setShowLoading] = useState(false);
  const [state, setState] = useState({
    ingress: [],
    count: 1,
    delete_mark: false
  })

  useEffect(() => {
    fetchIngress();
  }, [state.count])


  function handleSuccessApiHit(data) {
    setState(new_state => ({
      ...new_state,
      ingress: data
    }));
    setShowLoading(false);

  }

  function handleFailedApiHit(error) {
    setState(new_state => ({
      ...new_state,
      error: error,
    }))
    setShowLoading(false);
  }

  function fetchIngress(data, url) {

    var requestInfo = {
      endPoint: GenerateURL({ cluster_id: cluster_id }, properties.api.get_ingress_listing),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }

    if (data) {
      requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
      console.log("search", requestInfo.endPoint)
    }


    if (url) {
      requestInfo.endPoint = url;
    }

    setState(new_state => ({
      ...new_state,
      search_data: data,
      current: requestInfo
    }))
    setShowLoading(true);
    InvokeApi(requestInfo, handleSuccessApiHit, handleFailedApiHit);
  }

  const invokeSearch = (data) => {
    console.log("invokeSearch", data);
    fetchIngress(data);
  }

  function refreshPage() {
    setState(new_state => ({
      ...new_state,
      // mark_fo_delete: true,
      count: new_state.count + 1
    }))
  }
  return (
    <div className={classes.root}>
      {
        showLoading ? <LoadingContainer /> : null
      }
      {
        state.ingress.length > 0 ?

          <>
            <PageHeader
              heading="Ingress List"
              subHeading="Displaying overall summary of ingress associated with this cluster"
              imgIcon="/images/header_icons/ingress-icon.svg"
              icon={null}
              commonDivMargin={true}
              primaryButton={{
                actionType: 'link',
                action: "/cluster/" + cluster_id + "/ingress/add",
                icon: <span className="ri-add-line font-14"></span>,
                text: 'Add Ingress',
                buttonClass: 'btn-primary'
              }}
            />
            {
              state.ingress.map(ingress => (
                <Grid item lg={6}>
                  <div className={classes.card}>
                    <TempHeader>
                      <span className="mainheading">{ingress.name} </span>
                      <div className="d-flex align-center">
                        <LongMenu cluster_id={cluster_id} delete_mark={state.delete_mark} ingress={ingress} refresh={refreshPage} />
                      </div>
                    </TempHeader>
                    <Body>
                      <TempBodyRow>
                        <div>
                          {ingress.ingress_type.code == "PROTECTED" ?
                            <div className="icon-box">
                              <div className="protected-icon-box icon-box-top">
                                <span className="flaticon-locked-padlock-outline"></span>
                              </div>
                              <div className="protected-icon-box icon-box-bottom">
                                Protected
                              </div>
                            </div> :
                            <div className="icon-box">
                              <div className="public-icon-box icon-box-top">
                                <span className="flaticon-unlocked-padlock"></span>
                              </div>
                              <div className="public-icon-box icon-box-bottom">
                                Public
                              </div>
                            </div>
                          }
                        </div>
                        <div>
                          <div className="d-flex"><span className="text-black">Status:&nbsp;</span>{ingress.status == "READY" ?
                            <><span className="text-gray">Ready</span><CheckCircleIcon style={{ color: '#69e09c' }} /> </> :
                            <><span className="text-gray">Not Ready</span><Cancel style={{ fontSize: '18px', color: '#ff8969' }} /></>}</div>
                          <div className="d-flex">
                            <span className="text-black">URL:&nbsp;</span>
                            <a className="text-blue-anchor text-ellipsis" href={ingress.url}>{ingress.url} </a>
                          </div>
                          <div className="d-flex">
                            <span className="text-black">Annotations:&nbsp;</span>
                            <span className="text-blue-anchor" ><Annotations annotations={ingress.k8s ? Object.keys(ingress.k8s.metadata.annotations) : null} anno_ui={ingress.annotations ? ingress.annotations : null} /></span>
                          </div>
                          <Tooltip title={ingress.acm_value ? ingress.acm_value : "NA"}>
                            <div className="d-flex">
                              <span className="text-black">ACM:&nbsp;</span>
                              <div className="text-gray text-ellipsis">{ingress.acm_value ? ingress.acm_value : "NA"}</div>
                            </div>
                          </Tooltip>
                          {ingress.oauth_client ?
                            <>
                              <div className="d-flex">
                                <span className="text-black ml-5">Auth Client:&nbsp;</span>
                                <span className="text-gray">{ingress.oauth_client.provider_code}</span>
                              </div>
                              <Tooltip title={ingress.oauth_client.client_id}>
                                <div className="d-flex" >
                                  <span className="text-black ml-5">Client ID:&nbsp;</span>
                                  <span className="text-gray">{ingress.oauth_client.client_id}</span>
                                </div>
                              </Tooltip>
                            </> : null
                          }

                        </div>
                        <div>
                          <div className="d-flex">
                            <span className="text-black ml-5">Namespace:&nbsp;</span>
                            <span className="text-gray text-ellipsis">{ingress.namespace.name}</span>
                          </div>
                          {ingress.ingress_type.code == "PROTECTED" ?
                            <div className="d-flex">
                              <span className="text-black ml-5">CIDR IPs:&nbsp;</span>
                              <span className="text-gray">{ingress.whitelisted_ip.length > 0 ? <Annotations data_ip={ingress.whitelisted_ip} ></Annotations> : "NA"}</span>
                            </div>
                            : null}

                          <div className="d-flex">
                            <span className="text-black ml-5">Image:&nbsp;</span>
                            <span className="text-gray text-ellipsis">{ingress.namespace.name}</span>
                          </div>
                        </div>
                      </TempBodyRow>
                      <div className="align-center " style={{ margin: '20px 0px', padding: '10px', height: '67px' }}>
                        <p className="text-black">Labels:&nbsp;</p>
                        <div className="d-flex d-flex-wrap">
                          <UI_ExpandComponent data={ingress.k8s ? ingress.k8s.metadata.labels : ""} data_ui={ingress.labels} />
                        </div>
                      </div>
                    </Body>
                  </div>
                </Grid>
              ))
            }

          </>
          :
          <>
            <BlankPage
              pageIcon={<img src="/images/header_icons/ingress-icon.svg" />}
              text={"You have no ingress added"}
              primaryButton={
                {
                    actionType: 'link',
                    action: "/cluster/" + cluster_id + "/ingress/add",
                    icon: <span className="ri-add-line font-14"></span>,
                    text: 'Add Ingress',
                    buttonClass: 'btn-primary m-auto'
                }
            }
            />
          </>
      }

    </div>
  )
}

export default IngressList;

function UI_ExpandComponent(props) {
  const data = props.data;
  const data_list = [];
  const iteration_count = 2;
  const data2 = props.data_ui ? props.data_ui : [];
  const list_2 = [];
  let labels_list = [];

  let labels_list2 = [];


  function get_list(labels, labels2, list_type) {
    labels_list = [];
    labels_list2 = [];
    if (labels) {
      Object.keys(labels).forEach(label => {
        labels_list.push(
          {
            key: label,
            value: labels[label]
          }
        );
        labels_list2.push(
          <Tooltip placement="bottom" title={label + "=" + labels[label]}>
            <div className="pod-lable two">
              {label + "=" + labels[label]}
            </div>
          </Tooltip>
        );
      });
    }
    if (labels2) {
      labels2.forEach(label => {
        labels_list.push(
          {
            key: label.env_key,
            value: label.env_value
          }
        );
        labels_list2.push(
          <Tooltip placement="bottom" title={label.env_key + "=" + label.env_value}>
            <div className="pod-lable one">
              {label.env_key + "=" + label.env_value}
            </div>
          </Tooltip>
        );
      });



    }
    if (list_type == "1") {
      return labels_list;

    }
    if (list_type == "2") {
      return labels_list2;

    }
    return [];
  }


  return (
    <>
      <ExandableKeyValues
        compoenent_list={get_list(data, data2, 2)}
        iteration_count={iteration_count}
        labels_list={get_list(data, data2, 1)}
        isLabel={true}
        expandable_component={
          <>
            +{labels_list.length - iteration_count}
          </>
        }
        blank_component_msg={<span className="text-grey" style={{ fontWeight: 400, textTransform: 'lowercase' }}>Lables Not Available</span>}
      />
    </>
  );
}
UI_ExpandComponent.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: '20px',
    backgroundColor: '#fff',
    height: 'calc( 100vh - 70px )',
    '& .loading-gif-container': {
      height: 'auto'
    },
    '& .loader-bg-card': {
      top: '37rem'
    },
    '& .blank-div': {
      '& a': {
        border: 'none',
        '&:hover': {
          background: 'transparent'
        }
      }
    }
  },
  card: {
    borderRadius: '4px',
    boxShadow: 'rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px',
    background: '#fff',
    border: '1px solid #d5d5d5',
    overflow: 'hidden',
    '& .labels-div': {
      marginTop: '20px'
    }
  },
  cardHeader: {
    borderRadius: '8px 8px 0px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: "#fff",
    fontSize: '13px',
    padding: '10px 15px',
    '& .input-component': {
      minWidth: '120px',
      marginBottom: '0px'
    },

  },
  '& .with-dropdown': {
    display: 'grid',
    gridTemplateColumns: 'auto 120px',
    gap: '10px',
    alignItems: 'center',

  },

  cardFooter: {
    backgroundColor: '#fff',
    borderRadius: ' 0px 0px 8px 8px',
    display: 'flex',
    padding: '10px 15px',
    borderTop: '1px solid #d5d5d5',
    justifyContent: 'flex-end',
    '& .btn-submit': {
      backgroundColor: '#3696db',
      color: '#fff',
      marginLeft: 'auto'
    }
  }
}));

const Body = styled('div')({
  lineHeight: '1.5',
  fontSize: '12px',
})
export const TempHeader = styled('div')({
  fontSize: '11px',
  padding: '15px 10px',
  gap: '10px',
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: '1px solid #dedede',
  alignItems: 'center',
  '& span': {
    padding: '0px 2px'
  },
  '& .mainheading': {
    fontSize: '18px',
    lineHeight: '1.2'
  },
})
const TempBodyRow = styled('div')({
  lineHeight: '1.5',
  fontSize: '12px',
  gap: '10px',
  padding: '30px 10px',
  borderBottom: '1px solid #dedede',
  alignItems: 'flex-start',
  overflow: 'hidden',
  backgroundColor: '#fcfcfc',
  display: 'grid',
  gridTemplateColumns: '20% 40% 1fr',
  justifyContent: 'space-between',
  height: '170px',
  '& .d-flex': {
    overflow: 'hidden',
  },
  '&:last-child': {
    border: 'none'
  },
  '& .bullet': {
    position: 'relative',
    top: '3px',
    '&.purple': {
      backgroundColor: '#245dff!important'
    }
  },
  '& .createdat': {
    fontSize: '10px',
    color: '#878787'
  },
  '& .fre-text': {
    fontSize: '11px',
    color: '#000',
    '& .uses-text': {
      fontSize: '9px',
      color: '#878787'
    }
  },
  '& .status-font': {
    color: '#fff',
    width: '40%',
    '& .flaticon-circle-with-check-symbol': {
      '&:before': {
        fontSize: '14px!important'
      }
    }
  },
  '& div': {
    overflow: 'hidden',
    color: '#9e9e9e'
  },
  '& .url': {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
})


const ITEM_HEIGHT = 48;
export function LongMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const cluster_id = props.cluster_id;
  const ingress = props.ingress;
  const refresh = props.refresh;
  const [state, setState] = useState({ delete_mark: props.delete_mark })
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const url = GenerateURL({ cluster_id: cluster_id, ingress_id: ingress.id }, properties.api.get_ingress,true);
  const is_edit_permitted = GetAuth().permission.DELETE.includes(url);
 
  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon style={{ color: '#0096db', fontSize: '2rem' }} />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 'fit-content',
          },
        }}
      >
        <MenuItem selected={Link === 'Dashboard'} onClick={handleClose} style={{ display: "grid" }}>

          <Link to={"/cluster/" + cluster_id + "/ingress/" + ingress.id + "/edit"} >
            <IconButton variant="outlined" >
              <BorderColorIcon style={{ color: '#0086ff' }} />&nbsp; Edit
            </IconButton>
          </Link>
          <Delete
            display_data_name={ingress.name}
            data={{ entity_id: ingress.id, name: "ingress" }}
            refresh={refresh}
            delete_flag={state.delete_mark}
            is_edit_permited={is_edit_permitted}
            api_link={GenerateURL({ cluster_id: cluster_id, ingress_id: ingress.id }, properties.api.get_ingress)}
          />
        </MenuItem>
      </Menu>
    </div>
  );
}
LongMenu.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

const BtnSection = styled('div')({
  display: "flex",
  alignItems: 'center'
})


const AddButton = styled('div')({
  backgroundImage: `-moz-linear-gradient( -90deg, rgb(0,150,219) 0%, rgb(20,107,236) 100%),-webkit-linear-gradient( -90deg, rgb(0,150,219) 0%, rgb(20,107,236) 100%),-ms-linear-gradient( -90deg, rgb(0,150,219) 0%, rgb(20,107,236) 100%),linear-gradient( -90deg, rgb(0,150,219) 0%, rgb(20,107,236) 100%)`,
  boxShadow: '0px 5px 16px 0px rgba(0, 0, 0, 0.1)',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  fontSize: '11px',
  padding: '5px 12px',
  color: '#fff',
  borderRadius: '4px',
  '& .btn': {
    backgroundColor: '#4fb2f1',
    color: '#fff',
    '& .flaticon-add-plus-button': {
      color: '#fff'
    }
  }
})