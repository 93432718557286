import { Popover, Tooltip, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

const EnvSelectorNew = (props) => {
    const classes = useStyles();
    const {envTabList,selectedEnvTab,envChangeHandler,componentEnvTabList,selectedComponentEnv,componentEnvChangeHandler} = props;
    console.log(envTabList,selectedEnvTab,"sbcgsdbvhgdfhvbfdhvb")
    const selectedEnvChip = envTabList.find((obj)=>obj.order == selectedEnvTab);
    console.log(selectedEnvChip,"sbvgfbvhfhjdf")
    const selectedComponentEnvObj = componentEnvTabList.find((obj)=>obj.order == selectedComponentEnv);
    //const copyComponentEnv = [...componentEnvChangeHandler]
    const [anchorEl, setAnchorEl] = useState(null);
    const [state,setState] = useState({
        search: "",
        componentEnvList: componentEnvTabList? componentEnvTabList : [],
    })
    // useEffect(() => {
        
    //         console.log("kdsnfn")
            
    //         fetchDataForListingOwnFunction(state?.search)
    // }, [state.search])
    useEffect(() => {
        if (state.search !== '') {
            console.log(state.search, 'ssdfdfdfdfdfddd')
            const list = componentEnvTabList;
            const searchResults = list.filter(item => item.tabName.toLowerCase().startsWith(state.search.toLowerCase()));
            setState(prevState => ({
                ...prevState,
                componentEnvList: searchResults
            }));
        } else {
            setState(prevState => ({
                ...prevState,
                componentEnvList: componentEnvTabList
            }));
        }
    }, [state.search, componentEnvTabList]);
    
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const open = Boolean(anchorEl);
    

    const handleSearchData = (e) => {
        const value = e.target.value;

        setState(prevState => ({
            ...prevState,
            search: value
        }))

        console.log("jriejiewjw",e.target.value,state.search);
    }

    //const [componentEnvList,setComponentEnvList] = useState(componentEnvTabList? componentEnvTabList : []);

    function onComponentEnvClick(order){
        componentEnvChangeHandler(order);
        setState((prevState)=>({
            ...prevState,
            search: "",
        }))
        handleClose();
    }

    function onEnvTabClick(order){
        envChangeHandler(order);
        setState(prevState => ({
            ...prevState,
            componentEnvList: props.componentEnvTabList,
        }));
    }

    const EnvBackgroundColor = {
        DEV: "#0086FF",
        QA: "#129E5B",
        UAT: "#FE9111",
        PROD: "#7B61FF",
        STAGING: "#EF5DBD",
    }
    const selectEnvColor = EnvBackgroundColor[selectedEnvChip?.tabName];
    console.log("bcjsbdjsan",envTabList,componentEnvTabList);

    console.log('ppp_pp_001',envTabList,selectedEnvTab,componentEnvTabList,selectedComponentEnv);

  return (
    <>
        <div className={classes.root} onClick={handleClick} style={ open ? {borderColor: "#0086FF"} : {}} >
            <div className='selected-label'>
                <span className='d-flex align-center font-12 font-weight-500 color-key-78' >Env:  </span>
                <div className='selected-env font-12 d-flex align-center avtar'>{selectedComponentEnvObj ? selectedComponentEnvObj.tabName : "NA"}</div>
            </div>
            
            <span className='env-type font-10' style={{backgroundColor : `${EnvBackgroundColor[selectedEnvChip?.tabName.toUpperCase()]}`}}>{selectedEnvChip ? selectedEnvChip.tabName.toUpperCase() : "NA"}</span>
        </div>

                        <Popover
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                            }}
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                            }}
                            PaperProps={{ style: { overflow: 'visible', marginTop: "10px",borderRadius: "6px", } }}
                        >
                            <div className={classes.popover}>
                                <div className='search-container'>
                                        <div>
                                            <input
                                                className='search-input'
                                                name='search'
                                                //autoFocus={true}
                                                placeholder='Search'
                                                value={state?.search}
                                                onChange={handleSearchData}
                                                disabled={false}
                                            />
                                        </div>
                                           
                                    <span className='ri-search-2-line font-20' style={{color: "#CACACA"}}></span>
                                </div>
                                <div className='env-type-list'>
                                    {
                                        envTabList && envTabList.map((ele,i)=>{
                                            return <div key={i} className={ selectedEnvTab == ele.order ? 'env-tab selected-env-tab font-12' : 'env-tab font-12' } onClick={()=>envChangeHandler(ele.order)}>{ele.tabName}</div>
                                        })
                                    }
                                </div>
                                <div className='list-heading'>environments</div>
                                <div className='env-list'>
                                    {
                                        state?.componentEnvList.length>0?
                                            state.componentEnvList.map((ele,i)=>{
                                                if(ele.tabName.length > 32){
                                                    return (
                                                        <>
                                                        <Tooltip title={ele.tabName}>
                                                            <div key={ele.order} className={selectedComponentEnv == ele.order ? 'env-item font-14 selected-item': 'env-item font-14'} onClick={()=> onComponentEnvClick(ele.order)}>
                                                                
                                                                    <span className='component-env-name'>{ele.tabName}</span> 
                                                                
                                                                {ele.order==selectedComponentEnv ? <span className='ri-checkbox-circle-fill font-17' style={{color: "#0086FF"}}></span> :null}</div>
                                                        </Tooltip>
                                                        </>
                                                    )
                                                }else{
                                                    return <div key={ele.order} className={selectedComponentEnv == ele.order ? 'env-item font-14 selected-item': 'env-item font-14'} onClick={()=> onComponentEnvClick(ele.order)}><span className='component-env-name'>{ele.tabName}</span> {ele.order==selectedComponentEnv ? <span className='ri-checkbox-circle-fill font-17' style={{color: "#0086FF"}}></span> :null}</div>
                                                }
                                                
                                            })
                                        :
                                            <div className='d-flex align-center justify-center width-full'>No Result Found</div>
                                    }
                                </div>
                            </div>
                        </Popover>
    </>
    
  )
}

const useStyles = makeStyles((theme)=>({
    root: {
        //background: "red",
        display: "flex",
        padding: "8px 10px 8px 16px",
        alignItems: "center",
        gap: "12px",
        alignSelf: "stretch",
        borderRadius: "6px",
        border : "1px solid #E6E6E6",
        cursor: "pointer",
        '& .selected-label':{
            display: "flex",
            alignItems: "center",
            gap: "3px",
            width: "80%"
        },
        '& .selected-env': {
            display: "inline-block",
            width: "100%",
            fontWeight: "500",
            color: "#2F2F2F",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
        '& .env-type': {
            display: "inline-flex",
            padding: "4px 8px",
            alignItems: "flex-start",
            borderRadius: "4px",
            gap: "10px",
            //fontSize: "10px",
            fontWeight: "700",
            color: "#FFF",
            width: "fit-content",
        },

        '&:hover' :{
            borderColor : "#bebebe",
        }
    },
    popover: {
        //width: "100%",
        display: "flex",
        width: "330px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "5px",
        borderRadius: "6px",
        border: "1px solid #F4F4F4",
        background: "#FFF",
        boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.08)",
        '& .search-container': {
            display: "flex",
            padding: "10px 12px",
            justifyContent: "space-between",
            alignItems: "center",
            alignSelf: "stretch",
            width: "100%",
            height: "40px",
            borderBottom: "1px solid #E6E6E6",
            '& .search-input': {
                border: "none",
                padding: "0",

                '&:focus': {
                    border: "none !important",
                    outline: "none !important",
                }
            },
        },
        '& .list-heading': {
            display: "flex",
            padding: "6px 12px 0px 12px",
            alignItems: "flex-start",
            gap: "10px",
            alignSelf: "stretch",
            textTransform: "uppercase",
            fontSize: "12px",
            fontWeight: "600",
            color: "#949494",
        },
        '& .env-type-list': {
            display: "flex",
            padding: "6px 12px 0px 12px",
            //flexDirection: "column",
            alignItems: "flex-start",
            gap: "10px",
            alignSelf: "stretch",
            '& .env-tab': {
                display: "flex",
                padding: "6px 10px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                borderRadius: "6px",
                border: "1px solid #E6E6E6",
                background: "#FFF",
                //fontSize: "12px",
                fontWeight: "500",
                cursor: "pointer",
                '&:hover': {
                    background: "#f4f4f4",
                }
            },
            '& .selected-env-tab': {
                background: "#0086FF",
                color: "#FFF",
                border: "none",
                '&:hover': {
                    background: "#0086FF !important",
                }
            }
        },
        '& .env-list': {
            display: "flex",
            paddingBottom: "8px",
            flexDirection: "column",
            alignItems: "flex-start",
            alignSelf: "stretch",
            height: "180px",
            overflowY: "scroll",
            '& .env-item': {
                display: "flex",
                padding: "8px 12px",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                cursor: "pointer",
                '&:hover': {
                    background: "#f4f4f4",
                },
                '& .component-env-name': {
                    width: "75%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                }
            },
            '& .selected-item': {
                background: "#F5FAFF",
            }
        }
    }
}))

EnvSelectorNew.defaultProps = {
    envTabList: [],
    selectedEnvTab: null,
    envChangeHandler: ()=> {},
    componentEnvTabList: [],
    selectedComponentEnv: null,
    componentEnvChangeHandler: ()=> {},
}

export default EnvSelectorNew