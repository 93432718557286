import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

export const CircularMeter = (props)=>{
    const label = props.label;
    const percentage = props.percentage;
    const max = props.max;
    const color = props.color;
    const allocatedLabel = props.allocatedLabel;
    const allocatedValue = props.allocatedValue;

    return (
        
        <div title={"max : "+max} className="progress">
            <div className="text-progress">
                <p className="progress-per">{percentage ? `${percentage}%` : '--'}</p>
                <p className="progress-label">{label}</p>
            </div>
                <CircularProgressbar value={percentage} strokeWidth={12} styles={buildStyles({
                pathTransitionDuration: 0.5,
                pathColor: `${color}`,
                trailColor: '#e3e6e8',
                backgroundColor: '#eee',
                })} />
                { allocatedLabel && allocatedValue ?  
                <p className="process-label-sec">
                    <div className="progress-label">{allocatedLabel}</div>
                    <div className="progress-label text-gray" >{allocatedValue}</div>
                </p>
                : null
            }
        </div>
        
        
    );
}

CircularMeter.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export const CircularMeterList = (props)=>{
    const list = props.list;
    
    return (
        <div className="progress-container">
            {
                list.map((element,key)=>(
                    <CircularMeter key={key} label={element.label} percentage={element.percentage} allocatedValue={element.allocatedValue} allocatedLabel={element.allocatedLabel} max={element.max} color={element.color} />
                ))
            }
           
        </div>
    );
}

CircularMeterList.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }