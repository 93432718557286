import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import InfoIcon from '@material-ui/icons/Info';
import ReplayIcon from "@material-ui/icons/Replay";
import { IconButton } from '@material-ui/core';
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ServiceHierarchy } from '../../execution/components/ExecutionCard';
import CloseIcon from '@material-ui/icons/Close';
import properties from '../../../../properties/properties';
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import { Loading } from '../../../utils/Loading';
import { Input } from '../../../../components/genericComponents/Input';
const RerunAfterFailure = (props) => {
    const data = props.data;
    
    const pipeline = props.pipeline;
    const last_trigger = pipeline && pipeline.last_trigger ? pipeline.last_trigger : null;
    const pipeline_context_param = last_trigger.pipeline_context_param ? last_trigger.pipeline_context_param : null
    console.log("Fdsadfsafsa", data)
    const classes = useStyles();
    const variant = props.variant ? props.variant : null;
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('md');
    const [state, setstate] = useState({
        data: {},
        error: {},
        loading: false,
        isPipelineRerunable: false

    })
    const rerunJob = props.rerunJob
    const validateAndSendData = () => {
        var post_data = state.data;
        rerunJob(post_data);
        handleClose()
    }

    const handleClickOpen = () => {
        setstate({ ...state, open: true });

    };

    const handleClose = () => {
        setstate({ ...state, open: false, });
    };
    const onKeyValueChangeHandler = (key, value) => {
        value.forEach((id, index) => {
            value[index] = id;
        });

        setstate({
            ...state,
            data: {
                ...state.data,
                [key]: value
            },
            error: {
                ...state.error,
                [key]: null,
            }
        });
    };
    return (
        <>
            {
                pipeline && pipeline.last_trigger && <Tooltip title="Replay Pipeline">
                    <button className="btn btn-primary-v2" onClick={handleClickOpen}>
                        Rerun Failed Job
                    </button>

                </Tooltip>
            }
            <Dialog
                open={state.open}
                onClose={handleClose}
                fullWidth={fullWidth}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="fill-details-head d-flex align-center space-between "
                    style={{ backgroundColor: 'rgb(18, 77, 155)', padding: 14 }}>
                    <p className='color-white'>Rerun Failed Job</p>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon className="color-white" fontSize="large" />
                    </IconButton>
                </div>
                <div className="card " style={{ border: 'none' }}>
                    {
                        state.loading ?
                            <div className='d-flex align-center justify-center' style={{ height: '280px' }}>
                                <Loading varient="light" />
                            </div>
                            : <>
                                <div className="card-body" style={{}}>
                                    <div className="m-auto" style={{ width: '400px' }}>
                                        <InfoIcon style={{ color: '#4783dc', fontSize: '60px' }} className="text-center mb-10 d-block m-auto" />
                                        <div className="">

                                            <p className="font-16 font-weight-600  text-center mb-5">
                                                You are about to rerun failed job in the pipeline.
                                            </p>
                                            {
                                                data && data[0] &&
                                                        data[0].task_type && data[0].task_type === "BUILD" || data[0].task_type && data[0].task_type === "DEPLOY"  || data[0].task_type && data[0].task_type === "PROMOTE" ?
                                                    <div className=''>
                                                        <p className='font-13'>Please choose the services for which you'd like to re-run the job</p>
                                                        <Input
                                                            type={"checkbox"}
                                                            name={"components"}
                                                            label={"Services"}
                                                            list={data.map(item => {
                                                                return {label: item.service_name, id: item.service_name}
                                                            })}
                                                            data={state.data}
                                                            error={state.error}
                                                            onChangeHandler={onKeyValueChangeHandler}
                                                        />
                                                    </div>
                                                    :null
                                            }
                                        </div>

                                    </div>


                                </div>
                                <div className="card-footer border-top pd-10 justify-end d-flex align-center">
                                    <button className="btn btn-outline-dark-grey" onClick={handleClose}>
                                        Cancel
                                    </button>

                                    <button className="btn btn-primary-v2" onClick={validateAndSendData}>
                                        Confirm
                                    </button>


                                </div>
                            </>
                    }

                </div>
            </Dialog>
        </>
    )
}

export default RerunAfterFailure;

const useStyles = makeStyles((theme) => ({
    stageData: {
        display: "flex",
        alignItems: "center",
        lineHeight: 1.5,
        "& .main-text": {
            color: "#000",
            fontSize: "13px",
            marginRight: "0px",
        },
        "& .sub-text": {
            color: "#949494",
            fontSize: "13px",
        },
        "& .status-font": {
            fontSize: "14px",
            textTransform: "uppercase",
        },
        "& .success": {
            color: "#62E187",
        },
        "& .success .flaticon-circle-with-check-symbol": {
            margin: "0px 4px",
            color: "#62E187",
        },
        "& .success .flaticon-circle-with-check-symbol:before": {
            fontSize: "14px!important",
            color: "#62E187",
        },
    },
    executionData: {
        display: "flex",
        alignItems: "center",
        lineHeight: 1,
        "& .main-text": {
            color: "#000",
            fontSize: "12px",
            marginRight: "0px",
        },
        "& .sub-text": {
            color: "#949494",
            fontSize: "12px",
        },
    },
    d_Flex: {
        display: "flex",
        alignItems: "center",
    },
}));

