import React from 'react';


const ServiceTypeLogo = ({ name }) => {

    const getColor = (name) => {

        switch (name) {
            case 'dev':
                return '#30b4a6'
            case 'qa':
                return '#82a479'
            case 'prod':
                return '#a47983'
        }
        return '#82a479'
    }
    return (
        <div style={{
            width: 'fit-content',
            padding: '10px 15px 10px 15px',
            textTransform: 'uppercase',
            color: 'white', backgroundColor: getColor(name),
            fontWeight: '500',
            fontSize: '14px',
            borderRadius: '6px',
        }}>
            {name}
        </div>
    )
}


export default ServiceTypeLogo;