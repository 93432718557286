import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import { Input } from '../../../../../../../components/genericComponents/Input';
import { getCommonFunctions } from '../../../../../../serviceRevamp/add/ci_flow/SourceDetails';
import InfoIcon from '@material-ui/icons/Info';

import { ErrorComponent } from '../../../../../../utils/Error';

import { Loading } from '../../../../../../utils/Loading';




export default function DeployPossibleHpa(props) {
    const classes = useStyles();
    const hpa_data = props.hpa_data ? props.hpa_data : {};
    const default_hpa = hpa_data.find(item => item.default == true)
    console.log(hpa_data,"___assasaa___")
    const error_msg = props.error_msg ? props.error_msg : null
    const extraProps = props.extraProps ? props.extraProps : null
    const inherits = props.inherits ? props.inherits : {}
    const [state, setState] = useState({
        data: {},
        error: {},
        error_msg_in_delete:null,
        loading: false,

    })


    useEffect(() => {
     if(error_msg){
        setState(prev_state => (
            { ...prev_state, 
                error_msg_in_delete: error_msg, }
            ));
     }
    }, [error_msg])
    



    const commonFunctions = getCommonFunctions(state, setState, inherits);
    const handleClickOpen = () => {
        setState({ ...state, open: true });
    };
    const handleClickOpenShowContentForDelete = () => {
        setState({ ...state, open: true, delete_hpa: true });
    };
    const handleClose = () => {
        setState({ ...state, open: false, });
    };
    useEffect(() => {
        var check_if_any_hpa_is_deployed = hpa_data.find(item => item.applied)
        if (check_if_any_hpa_is_deployed) {
            if (Object.keys(check_if_any_hpa_is_deployed).length > 0) {
                console.log("check_if_any_hpa_is_deployed", check_if_any_hpa_is_deployed)
                setState(
                    {
                        ...state,
                        data: {
                            ...state.data,
                            apply_possible_hpa: true
                        }
                    });
            } else {
                setState(
                    {
                        ...state,
                        data: {
                            ...state.data,
                            apply_possible_hpa: false
                        }
                    });
            }
        }



    }, [hpa_data])

    function onClickSwitchDeployNextPossibleHpaSettings(e) {
        console.log(state, e.target.name, "yyy")
        const name = e.target.name;
        handleClickOpen();
        setState(new_state => ({
            ...new_state,
            data: {
                ...new_state.data,
                apply_possible_hpa: false,
            }

        }));

    }
    function onClickSwitchDeleteHpaSettings(e) {
        console.log(state, e.target.name, "yyy")
        const name = e.target.name;
        handleClickOpenShowContentForDelete();
        setState(new_state => ({
            ...new_state,
            data: {
                ...new_state.data,
                apply_possible_hpa: true,
            }

        }));

    }
    function onConfirmDialog() {
        props.validateAndDeleteHpa(default_hpa.id)
        setState(new_state => ({
            ...new_state,
            throw_delete_inprogress_msg: true
        }));
    }
    console.log("jdnf",state);
    return (
        <>

            <Input
                type="only-switch"
                name="apply_possible_hpa"
                data={state.data}
                error={state.error}
                onChangeHandler={state.data.apply_possible_hpa ? (e) => { onClickSwitchDeleteHpaSettings(e) } : (e) => { onClickSwitchDeployNextPossibleHpaSettings(e) }}
            />
            <Dialog
                open={state.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="card ">
                    {
                        state.loading ?
                            <div className="card-body d-flex" style={{ height: 300 }}>
                                <Loading varient="light" />
                            </div>
                            :
                            state.error_msg ? <ErrorComponent error={state.error_msg} variant="msg-box" />
                                :

                                <div className="card-body d-flex" style={{ height: 300 }}>
                                    <div className="m-auto" style={{ width: '400px' }}>
                                        <InfoIcon style={{ color: '#4783dc', fontSize: '60px' }} className="text-center mb-10 d-block m-auto" />
                                        <div className="">
                                            {
                                                state.delete_hpa ?
                                                    <p className="font-16 font-weight-bold text-center mb-5">Are you sure you want delete and disable the HPA settings for the Service</p>
                                                    :
                                                    <p className="font-16 font-weight-bold text-center mb-5">Are you sure you want to enable the HPA settings as per the defined HPA configuration</p>
                                            }
                                            {
                                                state.throw_delete_inprogress_msg?
                                                <p className="font-16 font-weight-bold text-center mb-5">
                                                    Disabling HPA, It will take a while...
                                                </p>
                                                :null
                                            }
                                            {
                                                state.error_msg_in_delete?
                                                <p className="font-13  text-red text-center mb-5">
                                                    {state.error_msg_in_delete}
                                                </p>
                                                :null
                                            }
                                        </div>
                                    </div>
                                </div>

                    }
                    <div className="card-footer border-top pd-10 justify-end d-flex align-center">
                        <button className="btn btn-outline-dark-grey" onClick={handleClose}>
                            Cancel
                    </button>
                        {
                            state.delete_hpa ?
                            <button className="btn btn-danger" onClick={state.throw_delete_inprogress_msg? ()=>{} :onConfirmDialog}>
                                    Confirm
                            </button> :
                            <button className="btn btn-primary-v2" onClick={()=> {props.validateAndApplyHPA(hpa_data[0].id,true)}}>
                                    Confirm
                            </button>
                        }
                    </div>
                </div>
            </Dialog>

        </>
    );
}

DeployPossibleHpa.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

const useStyles = makeStyles(theme => ({
    anchorText: {
        fontSize: '11px',
        color: '#42a6e0',
        lineHeight: 1,
        cursor: 'pointer',
        marginLeft: '10px'
    }
}));