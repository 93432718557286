import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DeploymentStatusList from './DeploymentStatusList';
import SingleChart from './SingleChart';
import MultiChart from './MultiChart';

import CommonHorizontalTab from '../../../../../components/genericComponents/CommonHorizontalTab';

import DeploymentConditions from './DeploymentConditions';
import GenerateURL from '../../../../../util/APIUrlProvider';
import InvokeApi from '../../../../../util/apiInvoker';
import properties from '../../../../../properties/properties';
import { sortReplicasets, getPodStatus } from '../../../../../util/util';

import { Loading } from '../../../../utils/Loading';
import { makeStyles } from '@material-ui/core/styles';
import PodStatus from './PodStatus'; 


export default function PodChart(props) {
    const application_id = props.application_id
    const service_id = props.service_id
    const env_id = props.env_id
    const cd_id = props.cd_id
    console.log(cd_id, "cd_idcd_id") //DeploymentStatusList
    const deployment_data=props.deployment_data;
    const refresh_prop = props.refresh_prop;
    const expected_number_of_replicas = props.expected_number_of_replicas;
    const last_deployment_number = props.last_deployment_number
    var replicaset_data = null;
    const classes = useStyles();
    const count = props.count;
    const [state, setState] = useState({
        replicasets_data_loaded: false,
        replicasets_data_load_failure: false,
        replicaset_doesnot_exist: false,
        replicaset_data: null,
        pods_data_loaded: false,
        pods_data_load_failure: false,
        selectedTab: 1,
    });
    useEffect(() => {
        loadReplicasets();
        setState(new_state => (
            { ...new_state, pods_data_loaded: false }
        ))
    }, [refresh_prop, env_id, service_id,cd_id]);

    function loadReplicasets() {
        var requestInfo = {
            // endPoint: GenerateURL({ service_id: service_id, component_env_id: env_id, cd_id:cd_id }, properties.api.replicaset),
            endPoint: GenerateURL({ service_id: service_id, component_env_id: env_id}, properties.api.replicaset_widget+`?deployment_name=${cd_id}`),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, loadReplicasetsSuccess, loadReplicasetsFailed);
    }

    function loadReplicasetsSuccess(response) {
        console.log("pod data loaded", state, "iii")
        if (response && response.items && response.items.length > 0) {
            sortReplicasets(response.items)
            replicaset_data = response
            loadAllPods();
        }
    }

    function loadReplicasetsFailed(error) {
        var error_message = "Replicaset does not exist or there is temporary connectivity issue. This is the message we got: "
        error_message = error_message + error.toString()
        setState(new_state => ({
            ...new_state,
            replicasets_data_load_failure: true,
            replicaset_doesnot_exist: true,
            replicasets_data_loaded: false,
            replicaset_error_message: error_message,
            chart_data:getEmptyChartData()
        }));
    }

    function loadAllPods() {
        var requestInfo = {
            // endPoint: GenerateURL({ service_id: service_id, env_id: env_id,cd_id:cd_id }, properties.api.service_deployment_pods_details),
            endPoint: GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.service_deployment_pods_details_widget + `?deployment_name=${cd_id}`),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, handleLoadAllPodsSuccess, handleLoadAllPodsError);
    }

    function handleLoadAllPodsSuccess(response) {
        var chart_data = generateChartData(response.items, replicaset_data);
        setState(new_state => ({
            ...new_state,
            pods_data_loaded: true,
            pods_data_load_failure: false,
            pods_data: response.items,
            pod_error_message: null,
            chart_data: chart_data,
            replicaset_data: replicaset_data,
            replicasets_data_loaded: true,
            replicasets_data_load_failure: false,
            replicaset_doesnot_exist: false,
            replicaset_error_message: null
        }));
    }


    function handleLoadAllPodsError(error) {
        var error_message = "Pods do not exist or there is temporary connectivity issue. This is the message we got: "
        error_message = error_message + error.toString()
        setState(new_state => ({
            ...new_state,
            pods_data_loaded: true,
            pods_data_load_failure: true,
            pod_error_message: error_message
        }));
    }


    function areMultipleReplicaSetActive() {
        var replicasets_data = state.replicaset_data
        var active_replicaset_count = 0
        if (replicasets_data && replicasets_data.items) {
            replicasets_data.items.forEach(replicaset_data => {
                if (replicaset_data.pods) {
                    active_replicaset_count = active_replicaset_count + 1
                }
            })
        }
        return (active_replicaset_count > 1) ? true : false
    }

    function getErrorMessage(){
        if(state.replicasets_data_load_failure) return state.replicaset_error_message
        if(state.pods_data_load_failure) return state.pod_error_message
        return null
    }
    var list2 = [
        {
            order: 1,
            tabName: 'Conditions'
        },
        {
            order: 2,
            tabName: 'Container Status'
        },
        {
          order: 3,
          tabName: <span>Events<span className="Y6 ml-5 text-transform-lower">Coming Soon</span></span>
        }
    ]
    const updateSelectedTab = (selectedTab) => {
        setState(new_state => ({
            ...new_state,
            selectedTab: selectedTab,
        }));
    }
    var extra_data = {};
    var last_object_of_replicaset = state.replicaset_data ? 
                                    state.replicaset_data.items && 
                                    state.replicaset_data.items.length > 0 ?
                                    state.replicaset_data.items[state.replicaset_data.items.length - 1] : null : null
    var deployed_on = last_object_of_replicaset ? last_object_of_replicaset.metadata && last_object_of_replicaset.metadata.creationTimestamp ? last_object_of_replicaset.metadata.creationTimestamp : null : null;

    return (<>
        {state.chart_data ? 
        <>
            {state.chart_data.series_list.length == 1 ?
                <div className="card-body d-grid gird-template-single-monitoring border-bottom pb-0">
                    <div className="chart-section">
                        <SingleChart chart_data={state.chart_data}/>
                    </div>
                    <div className="right-panel-monitoring ">
                        <DeploymentStatusList deployed_on={deployed_on} last_deployment_number={last_deployment_number} cd_id={cd_id} application_id={application_id} count={count} service_id={service_id} env_id={env_id} deployment_data={deployment_data} error_message={getErrorMessage()} chart_data={state.chart_data}/>
                    </div>
                </div>
                : null}
            {state.chart_data.series_list.length > 1 ? <MultiChart chart_data={state.chart_data} /> : null}
        </> : <div className={classes.chart_div_loading+" "+"border-bottom"}><Loading varient="light" /></div>}
        <div className="bottom-panel">
                <CommonHorizontalTab
                    tabList={list2}
                    selectedTabOrder={state.selectedTab}
                    updateSelectedTab={updateSelectedTab}
                />
            </div>
            
            {
                state.selectedTab == 1 &&
              
                <DeploymentConditions deployment_yaml={deployment_data} />
                
            }
             {
                state.selectedTab == 2 &&
              
                <PodStatus chart_data={state.chart_data} deployment_data={deployment_data} />
                
            }
            {
                state.selectedTab == 3 &&
                <div style={{height: 200, display:'flex', alignItems:'center', justifyContent:'center', }}>
                    <p className="font-13 text-grey-83">Coming Soon...</p>
                </div>
            }   
    </>)
}

PodChart.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

function processPodsList(podList, replicasets_data) {
   
    var isPodListProcessed = false
    
    if (replicasets_data && replicasets_data.items) {
        replicasets_data.items.forEach(replicaset_data => {
            if (replicaset_data.pods) {
                isPodListProcessed = true
            }
        })
    }
    if (!isPodListProcessed) {
        if (podList && podList.length > 0) {
            podList.forEach(pod => {
                var replicaSetName = getReplicasetName(pod)
                getPodStatus(pod)
                var replicaSet = getReplicasetOfPod(replicaSetName, replicasets_data)
                if (replicaSet) {
                    initializeReplicaset(replicaSet)
                    replicaSet.pods = [...replicaSet.pods, pod]
                }
            })
           
        }
    }
    return replicasets_data
}

function getReplicasetName(pod) {
    var name = null
    pod.metadata.ownerReferences.forEach(owner_reference => {
        if (owner_reference.kind = "ReplicaSet") {
            name = owner_reference.name
        }
    })
    return name
}
function getPodName(pod){
    var podName = [];
    pod.forEach(element => {
        podName.push(element.metadata.name)
    });
    return podName
}
function getPodConditionForContionType(pod, conditionType) {
    var podCondition = null
    conditionType = conditionType ? conditionType : 'Ready'
    pod.status.conditions.forEach(condition => {
        if (condition.type == conditionType) {
            podCondition = condition
        }
    })
    return podCondition
}

function getReplicasetOfPod(replicaSetName, replicasets_data) {
    var selectedReplicaset = null
    replicasets_data.items.forEach(replicaset => {
        if (replicaset.metadata.name == replicaSetName) {
            selectedReplicaset = replicaset
        }
    })
    return selectedReplicaset
}

function initializeReplicaset(replicaSet) {
    if (!replicaSet.pods) {
        replicaSet.pods = []
    }
}

function generateChartData(pods, replicaset_data) {
    var chart_data = {
        series_list: [],
        replicasets:[],
        podNameList :[]
    }
    if (pods && replicaset_data) {
        var replicasets_data = processPodsList(pods, replicaset_data);
       console.log(replicasets_data, "Dsdsds-1")
        if (replicasets_data.items) {
            replicasets_data.items.forEach(replicaset_data => {
                if (replicaset_data.pods) {
                    var pods = replicaset_data.pods
                    var pod_data_array = []
                    var colors = []
                    pods.forEach(pod => {
                        var pod_chart_data = []
                        pod_chart_data.push(pod.pod_status.status + ": " + pod.pod_status.message )
                        pod_chart_data.push(1)
                        pod_data_array.push(pod_chart_data)
                        colors.push(getBackGroundColour(pod.pod_status.status))
                    })
                    var series = [{ data: pod_data_array,name:'Pod' }]

                    chart_data.series_list.push({ series: series, colors: colors })
                    chart_data.replicasets.push(replicaset_data)
                }else{
                    if(replicaset_data.latest_replicaset){
                        var series=[{data:[{name:"Zero Pods Available in Deployment", y:1}],name:'Pod'}]
                        chart_data.series_list.push({series:series,colors:["#808088"]})
                        chart_data.replicasets.push(replicaset_data)
                    }
                }
            })
        }

    }else{
        var series=[{data:[{name:"No Pod Data Available", y:1}],name:'Pod'}]
        chart_data.series_list.push({series:series,colors:["#808088"]})
        chart_data.inner_chart_status="NOT_FOUND"
        chart_data.replicasets.push([])
    }
    return chart_data
}

function getEmptyChartData(){
    var chart_data = {
        series_list: []
    }
    var series=[{data:[{name: "No Pod Data Available", y: 1 }], name: 'pod'}]
    chart_data.series_list.push({series:series,colors:["#b6b6b6"]})
    chart_data.inner_chart_status="NOT_FOUND"
    return chart_data
}

function getBackGroundColour(podStatus) {
    var backgroundColours = {
        Ready: "#51cbbb",
        Terminating: "#004085",
        Pending: "#fedf5c",
        Failed: "#ff8969",
        Succeeded: "#69e09c",
        Unknown: "#c294f1",
        Containers_Not_Ready: "#FFCB2E",
        Error_In_Container: "#FF6961"
    }

    return backgroundColours[podStatus]
}


function getDeploymentImageName(replicaset_data) {
    //Will fail in case of multiple containers : read side cars
    var image_path = replicaset_data.spec.template.spec.containers[0].image
    return image_path ? image_path.split(":")[1] : ""

}









const useStyles = makeStyles((theme) => ({
    chart_div_loading:{
        height:400,
        display:'flex',
        alignItems:'center',
        justifyContent: 'center'
    },
  }));
