import React from 'react';
import { styled } from '@material-ui/core/styles';

// const CustomStackedProgress = props =>{
//     return(
//         <ProgressContainer>
//         <TextBox>
//             <p>Replication:&nbsp;</p>
//             <div className="data-div">
//                 <p><span>Min: </span><span className="color-lite-grey">2 </span>&nbsp;|&nbsp;</p>
//                 <p><span>Max: </span><span className="color-lite-grey">2 </span>&nbsp;|&nbsp;</p>
//                 <p><span>Current: </span><span className="color-lite-grey">5 </span>&nbsp;</p>
//             </div>
//             <button>Update</button>
//         </TextBox>
//         <Progress multi>
//         <Progress bar color="success" value="15"></Progress>
//         <Progress bar color="success" value="15"></Progress>
//         <Progress bar color="success" value="15"></Progress>
//         <Progress bar color="success" value="15"></Progress>
//         <Progress bar color="warning" value="15"></Progress>
//         <Progress bar color="warning" value="15"></Progress>
//         <Progress bar color="warning" value="15"></Progress>
//         <Progress bar color="warning" value="15"></Progress>
//         <Progress bar  value="15"></Progress>
//         <Progress bar  value="15"></Progress>
//       </Progress>
//       </ProgressContainer>
//     )
// }


// export default CustomStackedProgress;

export const ProgressContainer = styled('div')({
    marginBottom:'0px',
    position:'relative',
'& .progress':{
    display:'flex',
    height:'10px',
    '& .progress-bar':{
        marginLeft:'5px',
        fontSize:'11px',
        borderRadius:'0px',
        backgroundColor:'#e4e4e4',
        '&:first-child':{
            marginLeft:'0px'
        }
    },
    '& .bg-success':{
        backgroundColor:'#69e09c'
    },
    '& .bg-warning':{
        backgroundColor:'#ffd62a'
    }
},
    '& button':{
        backgroundColor:'transparent',
        border:'none',
        fontSize:'11px',
        fontWeight:'bold',
        display:'inline-block',
        color:'#25a5e0',
        marginLeft:'10px',
        
    }
})

export  const TextBox= styled('div')({
    display:'flex',
    alignItems:'center',
    marginBottom:'.5rem',
    '& .data-div':{
        display:'flex',
        alignItems:'center',
    },
    '& p':{
        display:'flex',
        fontSize:'11px',
        '& .color-lite-grey':{
            backgroundColor:'transparent',
            color:'#b0b0b0'
        }
    },
    '& button':{
        backgroundColor:'transparent',
        border:'none',
        fontSize:'11px',
        fontWeight:'bold',
        color:'#25a5e0'
    },
    '& .btn-div':{
        display:'flex',
        justifyContent:'flex-end'
    }
})
