import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { getCommonFunctions } from '../../ci_flow/SourceDetails';
import { Grid } from '@material-ui/core';
import { Input } from '../../../../../components/genericComponents/Input';
import { VALIDATION_TYPE_REQUIRED, ValidateDataSet } from '../../../../../util/Validator';


const PrivateIngress = (props) => {
    //console.log("private ingress>>>>",props.clearForm);
    const inherits = props.inherits ? props.inherits : {};
    const prev_state = props.prev_state ? props.prev_state : null;
    console.log(prev_state,"Fsadfsdfaprev_state")
    const extraProps = props.extraProps ? props.extraProps : { service_name: "default", project_env_name: "default", environment_master_name: "default" };
    const [state, setState] = useState(prev_state ? getPrivateDefaultState(extraProps,prev_state) : getPrivateDefaultState(extraProps))
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    const protocol_list = [{ label: "TCP", id: "TCP" }, { label: "HTTP", id: "HTTP" }]
    const validateAndSaveAccessLevel = props.validateAndSaveAccessLevel ? props.validateAndSaveAccessLevel : () => {}
    const cancelForm = props.cancelForm ? props.cancelForm : ()=>{}
    useEffect(() => {
      if(prev_state){
        setState(new_state => ({ 
          ...new_state, 
          updateFnActive:true,
         data:{
           ...new_state.data, 
           ...prev_state,
           protocol : 'TCP'
         },
         validations:{
         }
       
       }));
      }else{
        clearForm()
      }
    
    }, [prev_state])

    const validateAndSavePrivateAccessLevel = () =>{
      let result = ValidateDataSet(state.data,{
        name: [VALIDATION_TYPE_REQUIRED],
        port: [VALIDATION_TYPE_REQUIRED],
        target_port: [VALIDATION_TYPE_REQUIRED]
      })
      if(result.valid){
        validateAndSaveAccessLevel();
        clearForm()
      }else{
        setState((prevState)=>({
          ...prevState,
          error:{
            ...prevState.error,
            ...result?.error
          }
        }))
      }
      
     }
    const clearForm = () => {
      console.log("close button working");
      setState(new_state => (
        { 
          ...new_state, 
          data:{
            ...new_state.data,
            name:"",
            protocol: "TCP",
            port: "",
            target_port:""
          }
           }))
      //cancelForm();
    }

 
    return (
        <>
        <Grid lg={6}>
            <div className='section'>
              <Input
                type="text"
                name="name"
                label="Name"
                maxLength = {15}
                mandatorySign
                placeholder="name"
                onChangeHandler={commonFunctions.onChangeHandlerWithHyphenDahs}
                data={state.data}
                error={state.error} />
            </div>
          </Grid>
          <Grid lg={6}>
            <div className='section'>
              <Input
                type="text"
                name="protocol"
                label="Select Protocol"
                mandatorySign
                placeholder="name"
                onChangeHandler={null}
                data={state.data}
                error={state.error} />
            </div>
          </Grid>
          <Grid lg={6}>
            <div className='section'>
              <Input
                type="text"
                name="port"
                label="Port"
                mandatorySign
                placeholder="4001"
                onChangeHandler={commonFunctions.onChangeHandlerNumber}
                data={state.data}
                error={state.error} />
            </div>
          </Grid>
          <Grid lg={6}>
            <div className='section'>
              <Input
                type="text"
                name="target_port"
                label="Target Port"
                mandatorySign
                placeholder="4001"
                onChangeHandler={commonFunctions.onChangeHandlerNumber}
                data={state.data}
                error={state.error} />
            </div>
          </Grid>
          <div className="d-flex align-center justify-end" style={{textAlign:'end', borderTop: '1px solid #dedede', padding: '10px 10px 0px 10px', width:'100%'}}>
                <button className='btn btn-v2-primary btn-sm' 
                style={{width: '65px'}}
                    onClick={validateAndSavePrivateAccessLevel}>{prev_state ? "Update" : "Add"}</button>
                    <button className='btn btn-outline-grey btn-sm' onClick={prev_state ?()=>{cancelForm();
                      console.log("close button working");
                    }: clearForm }>{prev_state ? "Cancel" : "Clear"}</button>
                </div>
        </> 
    )
}
PrivateIngress.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}
export default PrivateIngress;

export function getPrivateDefaultState(extraProps, prev_state){
    const service_name = extraProps ? extraProps.service_name : "";
    const env_master = extraProps ? extraProps.environment_master_name : "";
    const project_env_name = extraProps ? extraProps.project_env_name : "";
    return{
        data:prev_state? { 
            ...prev_state,
            expose_url: service_name ? (service_name + "/" + env_master + "/" + project_env_name) : "",
            access_level: "PRIVATE",
            protocol: "TCP"
        }:{
          expose_url: service_name ? (service_name + "/" + env_master + "/" + project_env_name) : "",
          access_level: "PRIVATE",
          protocol: "TCP"
        },
        error: {},
        validations: {
            // name: [VALIDATION_TYPE_REQUIRED],
            // port: [VALIDATION_TYPE_REQUIRED],
            // target_port: [VALIDATION_TYPE_REQUIRED]
          },
    }
}
