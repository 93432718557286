import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SquareAvatar from '../../../components/genericComponents/AvatarSquare';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LinearProgress from '@material-ui/core/LinearProgress';
import InvokeApi, { PostData } from '../../../util/apiInvoker';
import GenerateURL, { GenerateSearchURL } from '../../../util/APIUrlProvider';
import properties from '../../../properties/properties';
import { getDuration } from '../../service/listing/components/History';

import CancelIcon from '@material-ui/icons/Cancel';
import moment from 'moment';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import { Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import StopIcon from '@material-ui/icons/Stop';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ActivityDetails from '../../superAdmin/activityFramework/components/ActivityDetails';
import MonitoringDialogue from '../../service/detail/components/MonitoringDialogue';
const LatestBuildDeploy = (props) => {
    const classes = useStyles();
    const service_Env_Data = props.service_Env_Data
    const env_list = props.env_list
    var logs = "";
    const [viewLogs, setViewLogs] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [viewError, setViewLogError] = useState(false);
    const handleToggle = () => {
        setViewLogError(!viewError);
    }

    // const { activity_id } = props.match.params;

    const [state, setState] = useState({
        current_activity_list: [],
        activity_id: '',
        infiniteScrollInherits: {}
    });


    useEffect(() => {
        fetchActivitiesInfo();
    }, [state.refresh_count])

    function getRunningActivities(activities) {
        var runningActivities = []
        activities.map(activity => {
            if (activity.status === "RUNNING" || activity.status === "IN_QUEUE") {
                runningActivities.push(activity)
            }
        })
        return runningActivities
    }

    //fetch data for all the activities
    function fetchActivitiesInfo(data, url) {

        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.get_activities_details),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        if (data) {
            requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
        }
        if (url) {
            requestInfo.endPoint = url;
        }
        setState(new_state => ({
            ...new_state,
            search_data: data,
            current: requestInfo.endPoint,
        }));
        setShowLoading(true);
        InvokeApi(requestInfo, ActivityInfoFetchSuccess, ActivityInfoFetchFailure);
    }
    function ActivityInfoFetchSuccess(response) {
        console.log(response, "+++++>>>")
        setState(new_state => ({
            ...new_state,
            activity_list: getRunningActivities(response.results),
            activity_id: response.id,
            count: response.count,
            next: response.next,
            previous: response.previous,
        }));
        setShowLoading(false);
    }

    const invokeSearch = (activity_list) => {
        fetchActivitiesInfo(activity_list);
    }


    function ActivityInfoFetchFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            error: true
        }));
        setShowLoading(false);
    }

    //get activity id
    function getActivityCurrentInfo(activity_id) {

        console.log(activity_id, 'opop')
        var requestInfo = {
            endPoint: GenerateURL({ activity_id: activity_id }, properties.api.get_current_activity_status),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        setShowLoading(true);
        InvokeApi(requestInfo, getActivityCurrentInfoSuccess, getActivityCurrentInfoFailure);
    }

    function getActivityCurrentInfoSuccess(response) {
        if (response.results.length != 0) {
            state.activity_list.forEach(activity => {
                if (activity.id == response.results[0].activity_status_id) {
                    activity.activity_details = response.results
                    setState(new_state => ({
                        ...new_state,
                        activity_list: [...state.activity_list]
                    }));
                }
            })
        }
        // setState(new_state => ({
        //     ...new_state,
        // }));
        console.log(state.activity_list, "list_item")
        setShowLoading(false);


    }


    function getActivityCurrentInfoFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            error: true
        }));
        setShowLoading(false);
    }



    const list = [
        {
            icon: <VisibilityIcon />,
            text: 'View Logs',
            onClick: () => { }
        },
    ]

    //convert string into CamelCase
    const convertStringInCamelCase = str => {
        let newStr = str.replace(/_/g, " ");
        return newStr
            .replace(/\s(.)/g, function ($1) { return $1.toUpperCase(); })
            .replace(/\s/g, ' ')
            .replace(/^(.)/, function ($1) { return $1.toUpperCase(); });
    }


    //fetch entity data
    const parseEntityData = (entity) => {
        if (entity.name) {
            return <span className="text-color-black">{entity.name}</span>
        }
        else {
            for (let key in entity) {
                if (key != 'id')
                    return <span><br />{convertStringInCamelCase(key)}: <span className="text-color-black">{entity[key]}</span></span>
            }
        }
    }

    //sort activity detail names in reverse order

    function reverseActivityDetails(activityDetail) {
        console.log(activityDetail, 'ac_v001')
        var detailsList = activityDetail.activity_sub_task ? activityDetail.activity_sub_task.sub_task_name : activityDetail.job_step.name
        var listItems = []
        console.log(detailsList, 'detail_list==>')
        listItems.push(detailsList)
        return listItems;
    }
    const myRef = useRef(null)

    const executeScroll = () => myRef.current.scrollIntoView();

    function changeTab(newTabId) {
        setState(new_state => ({
            ...new_state,
            selected_tab_id: newTabId
        }))
    }
    function closeLogs() {
        setState(new_state => ({
            ...new_state, logMetadata: {}
        }))
        setViewLogs(false);
    }

    function closeTab(tabId) {
        var response = state.infiniteScrollInherits.closeTabFunction(tabId, state.logMetadata, closeLogs)
        if (response) {
            setState(new_state => ({
                ...new_state,
                logMetadata: response.new_tabs_data,
                selected_tab_id: response.selected_tab_id
            }))
        }
    }

    const removeArrData = (activity_status_id) => {
        state.activity_list.forEach(activity => {
            if (activity.id == activity_status_id) {
                activity.activity_details = null;
                setState(new_state => ({
                    ...new_state,
                    activity_list: [...state.activity_list],
                    activity_step_details: {
                        open_div: state.activity_list.length > 0 ? true : false
                    }
                }));
            }
        })

    }
    function onRevoke(activity_token) {
        var url_temp = GenerateURL({}, properties.api.revoke_activity);
        PostData(url_temp + "?task_id=" + activity_token, {}, handleSuccessRevoke, handleFailedRevoke);
        setState(new_state => ({
            ...new_state, history_info_status: "REQUESTED",

        }));
    }

    function refreshState() {
        setState(new_state => ({
            ...new_state,
            refresh_count: new_state.refresh_count + 1,
            status: []
        }));
        console.log("bdsfdbfs", state);
    }
    function handleSuccessRevoke(respone) {

        setState(new_state => ({
            ...new_state,
            history_info_status: "SUCCESS",
        }));

        refreshState()
    }
    function handleFailedRevoke(error) {
        setState(new_state => ({
            ...new_state,
            history_info_status: "FAILED"
        }));
    }



    function getDeployDurationForInfoMessage(date1, date2) {
        const diffInMs = Math.abs(date2 - date1);
        console.log("diffInMs", diffInMs)
        return diffInMs;
    }

    function getManualDeployState(env_name) {
        var single_service = service_Env_Data && service_Env_Data.find((single_data) => {
            if (single_data.name == env_name) {
                return single_data
            }
        })

        var env_details = {
            manual_deploy: single_service && single_service.component_env[0].manual_deploy
        }

        return env_details;
    }

    function getMasterLabel(env_name) {
        var single_env = env_list && env_list.find((single_item) => {
            if (single_item.name == env_name) {
                return single_item;
            }
        })

        var masterLabel = single_env && single_env.environment_master.name;

        return masterLabel;
    }

    console.log(state.activity_list, "activity_listactivity_list")
    return (
        <div className={classes.root}>
            {state.activity_list && state.activity_list.length > 0 ?

                <div className="card">
                    <div className="card-body">
                        <div className={classes.ongoingActivityCard}>
                            <div className={classes.ongoingActivityCardHeader}>
                                <p>Activity Type</p>
                                <p>Triggered by</p>
                                <p>Activity Status</p>
                                <p></p>
                                <p>Actions</p>
                            </div>
                            <div className={classes.ongoingActivityBody + " " + "ongoingActivityCardBody"}>
                                {state.activity_list.map(activity => (
                                    <>
                                        <div className='single-row-card'>
                                            <div className='single-row-item-one'>
                                                <div className="d-flex align-center" style={{ width: 200, overflow: 'hidden' }}>
                                                    <SquareAvatar varient="double" name={activity.activity_master.activity_name ? activity.activity_master.activity_name : null} />
                                                    <div className=" ml-5">
                                                        <span>Activity # </span><span className="text-color-grey" >{activity.id}</span>
                                                        <div>

                                                            <span>Type: </span>

                                                            {
                                                                activity.entity_detail && (activity.entity_detail.component && activity.entity_detail.component.name) && (activity.entity_detail.component_env && activity.entity_detail.component_env.name) ?
                                                                    <Link className="text-anchor-blue cursor-pointer"
                                                                        to={
                                                                            "/logs?global_task_id=" + activity.activity_token +
                                                                            "&num=" + activity.id +
                                                                            "&service_name=" + activity.entity_detail.component.name +
                                                                            "&service_env=" + activity.entity_detail.component_env.name
                                                                        } target="_blank">
                                                                        {activity.activity_master.activity_name ?
                                                                            <Tooltip title={activity.activity_master.activity_name}>
                                                                                <span
                                                                                    className="text-anchor-blue text-ellipsis-150 vertical-bottom d-inline-block"
                                                                                    style={{ width: '90px' }}>
                                                                                    {activity.activity_master.activity_name}
                                                                                </span>
                                                                            </Tooltip> : null}
                                                                    </Link> :
                                                                    <Link className="text-anchor-blue cursor-pointer"
                                                                        to={"/logs?global_task_id=" + activity.activity_token} target="_blank">
                                                                        {activity.activity_master.activity_name ?
                                                                            <Tooltip title={activity.activity_master.activity_name}>
                                                                                <span
                                                                                    className="text-anchor-blue text-ellipsis-150 vertical-bottom d-inline-block"
                                                                                    style={{ width: '90px' }}>
                                                                                    {activity.activity_master.activity_name}
                                                                                </span>
                                                                            </Tooltip> : null}
                                                                    </Link>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='single-row-item-two'>
                                                <div className="" >
                                                    <p>Triggred by: <Tooltip title={activity.user ? activity.user : ""}><span className="text-color-black text-ellipsis-150 vertical-bottom d-inline-block" style={{ width: '90px' }}>{activity.user ? activity.user : ""}</span></Tooltip></p>
                                                    <p>Started: <span className="text-color-black">{activity.start_time ? moment(activity.start_time).fromNow() : ""}</span></p>
                                                </div>
                                            </div>
                                            <div className='single-row-item-three' style={{ whiteSpace: 'wrap' }}>
                                                <div style={{ maxWidth: '220px', whiteSpace: 'normal' }}>
                                                    <span>
                                                        {

                                                            activity.entity_detail && activity.entity_detail.component || activity.entity_detail && activity.entity_detail.pipeline ?
                                                                Object.keys(activity.entity_detail).map((key, val) => (
                                                                    <>
                                                                        <span>{convertStringInCamelCase(key)}: </span> <span >{parseEntityData(activity.entity_detail[key])}</span>
                                                                        <br></br>
                                                                    </>
                                                                ))
                                                                :
                                                                activity.entity_detail && activity.entity_detail.config_map || activity.entity_detail && activity.entity_detail.project_env ?
                                                                    Object.keys(activity.entity_detail).map((key, val) => (
                                                                        <>
                                                                            <span>{key}: </span> <span className="text-color-black">{activity.entity_detail[key].name}</span>
                                                                            <br></br>
                                                                        </>
                                                                    )) :
                                                                    activity.entity_detail && activity.entity_detail.cluster || activity.entity_detail && activity.entity_detail.namespace ?
                                                                        Object.keys(activity.entity_detail).map((key, val) => (
                                                                            <>
                                                                                <span>{key}: </span> <span className="text-color-black">{activity.entity_detail[key].name}</span>
                                                                                <br></br>
                                                                            </>
                                                                        )) :
                                                                        activity.status == "FAILED" ?
                                                                            <div className="error-container">
                                                                                <span>Error: </span>
                                                                                <Tooltip placement="bottom" title={activity.description}>
                                                                                    <span className="color-danger text-ellipsis-multiline">{activity.description}</span>
                                                                                </Tooltip>
                                                                            </div>
                                                                            : null
                                                        }
                                                        {
                                                            activity.trigger_by_pipeline ?
                                                                <>
                                                                    <span>Pipeline: </span> <span className="text-color-black">{activity.trigger_by_pipeline}</span>
                                                                    <br></br>
                                                                </>
                                                                : null
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='single-row-item-four'>
                                                <div style={{ width: '100%' }}
                                                    className={activity.status == "IN_QUEUE" || activity.status == "RUNNING" ? "d-flex align-center" : null}>
                                                    <div>
                                                        <div className="d-flex align-center mb-5">
                                                            <span className="ml-5 d-flex align-center mr-5">
                                                                {
                                                                    activity.status == "FAILED" ? <> <CancelIcon fontSize="large" className="color-danger" /><span style={{ fontWeight: '500' }}>Failed</span></> :
                                                                        activity.status == "SUCCESS" ? <><CheckCircleIcon fontSize="large" className="color-success" /><span style={{ fontWeight: '500' }}>Success</span></> :
                                                                            activity.status == "RUNNING" ? <><TimelapseIcon fontSize="large" className="text-anchor-blue" /><span style={{ fontWeight: '500' }}>Running</span></> :
                                                                                activity.status == "REVOKED" ? <><StopIcon fontSize="large" className="color-revoked" /><span style={{ fontWeight: '500' }}>Revoked</span></> :
                                                                                    activity.status == "IN_QUEUE" ? <><ScheduleIcon fontSize="large" style={{ color: '#004085' }} /><span style={{ fontWeight: '500' }}>In queue</span></> : null}

                                                            </span>
                                                            <div className="ml-5">
                                                                {activity.status == "RUNNING" || activity.status == "IN_QUEUE" ?
                                                                    <>
                                                                        <span>Running Since: </span><span className="text-color-black">{activity.start_time && activity.end_time ? moment(activity.start_time).fromNow() : "NA"}</span>
                                                                    </>
                                                                    : activity.status == "SUCCESS" ? <>
                                                                        <span>Execution Duration: </span><span className="text-color-black">{activity.start_time && activity.end_time ? getDuration(new Date(activity.start_time), new Date(activity.end_time)) : "NA"}</span>
                                                                    </>
                                                                        : null
                                                                }
                                                                {
                                                                    activity.status == "FAILED" ?
                                                                        <div className="d-flex align-center">
                                                                            <button className="btn btn-transparent text-anchor-blue d-block mr-5" style={{ fontSize: '11px' }} onClick={handleToggle}>
                                                                                View Error Log
                                                                            </button>
                                                                            <span>Execution Duration: </span>
                                                                            <span className="text-color-black">
                                                                                {
                                                                                    activity.start_time && activity.end_time ?

                                                                                        getDuration(new Date(activity.start_time), new Date(activity.end_time)) : "NA"}</span>
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {activity.status == "FAILED" ? <BorderLinearProgressFailed variant="determinate" value={50} /> :
                                                                activity.status == "SUCCESS" ? <BorderLinearProgressSuccess variant="determinate" value={100} /> :
                                                                    activity.status == "RUNNING" ? <BorderLinearProgressRunning variant="indeterminate" value={50} /> :
                                                                        activity.status == "REVOKED" ? <BorderLinearProgressRevoked variant="determinate" value={50} /> :
                                                                            activity.status == "IN_QUEUE" ? <BorderLinearProgressInitiated variant="indeterminate" value={30} /> : null}
                                                        </div>
                                                    </div>




                                                    {
                                                        activity.status == "FAILED" && viewError ?
                                                            <div className="error-container">
                                                                <Tooltip placement="bottom" title={activity.description}>
                                                                    <span className="color-danger text-ellipsis-multiline">{activity.description}</span>
                                                                </Tooltip>
                                                            </div>
                                                            : null

                                                    }
                                                    {/* {
                                                         activity.status == "IN_QUEUE" || activity.status == "RUNNING" ?
                                                             // <button className="btn btn-with-icon btn-round border-2px-blue ml-5" title="Revoke" onClick={} ><StopIcon className="text-danger" /></button>
                                                             : null
                                                     } */}

                                                </div>
                                            </div>

                                            <ActivityDetails
                                                activity_id={activity.id}
                                                activity_master_id={activity.activity_master && activity.activity_master.id ? activity.activity_master.id : null}
                                                task_id={activity.activity_token}
                                                removeArrData={() => { removeArrData(activity.id) }}
                                                activity_status={activity.status}
                                                revokeActivity={() => { onRevoke(activity.activity_token) }}
                                                logUrl={"/logs?global_task_id=" + activity.activity_token}
                                                parameters={activity.payload_json ? activity.payload_json : null}
                                            />

                                        </div>
                                        {console.log(activity, "dsfjkdakjsd")}
                                        {
                                            activity.activity_master.activity_name == "Deploy stateless app" &&

                                            // <MonitoringDialogue
                                            //     // variant={activity.status == "RUNNING" &&
                                            //     //     getDeployDurationForInfoMessage(new Date(activity.start_time? activity.start_time : ""), new Date()) > 60000 ? "running_strip" :
                                            //     //     "success_running"
                                            //     // }
                                            //     application_id={activity.entity_detail.project.id}
                                            //     env_id={activity.entity_detail.component_env.id}
                                            //     service_id={activity.entity_detail.component.id}
                                            //     cd_id={activity.entity_detail.deployment_name.id}
                                            //     variant={"deploy-activity"}
                                            //     env_name={activity.entity_detail.component_env.name}
                                            //     service_data={{ name: activity.entity_detail.component.name, type: getMasterLabel(activity.entity_detail.component_env.name) }}
                                            //     env_details={getManualDeployState(activity.entity_detail.component_env.name)}
                                            // />

                                            <Link target="_blank" to={{
                                                pathname : `/deployment-analytics`,
                                                search: `application_id=${encodeURIComponent(activity.entity_detail.project.id)}&service_id=${encodeURIComponent(activity.entity_detail.component.id)}&env_id=${encodeURIComponent(activity.entity_detail.component_env.id)}&cd_id=${encodeURIComponent(activity.entity_detail.deployment_name.name)}&env_name${encodeURIComponent(activity.entity_detail.component_env.name)}&service_data=${encodeURIComponent(JSON.stringify({ name: activity.entity_detail.component.name, type: getMasterLabel(activity.entity_detail.component_env.name) }))}&all_available_deployments=${encodeURIComponent(JSON.stringify([]))}`
                                            }} 
                                            >
                                                <span style={{ color: '#000000f2',marginLeft:'8px' }} className="font-13">Want to review deployment status? Please see Deployment Analytics <span style={{ cursor: 'pointer', color: '#0086ff' }} className="font-13" tabIndex={0} role='button'>here</span>.</span>
                                            </Link>
                                        }
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div> : null}

        </div>
    )
}

LatestBuildDeploy.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}
export default LatestBuildDeploy;

const useStyles = makeStyles(theme => ({
    root: {
        '& .card': {
            overflow: 'hidden'
        },
        '& .loader-bg-card-2': {
            margin: '0px'
        },
        '& .btn-flaticon': {
            verticalAlign: 'middle',
            '& .material-icons': {
                verticalAlign: 'middle',
                color: '#0787e1'
            },

        },
        '& .btn-flaticon-disabled': {
            '& .material-icons': {
                verticalAlign: 'middle',
                color: '#b9b8b8!important'
            },
        }
    },
    ongoingActivityCard: {
        width: '100%',
        height: 'auto !important',
        '& .ongoingActivityCardBody': {
            height: 'auto !important',
            overflow: 'auto!important',

            "& .single-row-card": {
                display: 'grid',
                gridTemplateColumns: '208px 195px 180px 310px 156px',
            }
        }
    },
    ongoingActivityCardHeader: {
        borderBottom: '2px solid #e6e6e6!important',
        display: 'grid',
        gridTemplateColumns: '208px 195px 180px 310px 156px',
        '& p': {
            padding: '1.25rem',
            textAlign: 'left',
            fontSize: '11px',
            fontWeight: 400,
            color: '#9e9e9e'
        }
    },
    ongoingActivityCardbody: {
        height: 'auto !important'
    }
}));


const BorderLinearProgressSuccess = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 6,
            borderRadius: 3,
            width: '250px'
        },
        colorPrimary: {
            backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        bar: {
            borderRadius: 3,
            backgroundColor: '#69e09c',
        },
    }),
)(LinearProgress);

const BorderLinearProgressInitiated = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 6,
            borderRadius: 3,
            width: '250px'
        },
        colorPrimary: {
            backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        bar: {
            borderRadius: 3,
            backgroundColor: '#004085',
        },
    }),
)(LinearProgress);
const BorderLinearProgressFailed = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 6,
            borderRadius: 3,
            width: '250px'
        },
        colorPrimary: {
            backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        bar: {
            borderRadius: 3,
            backgroundColor: '#ff5252',
        },
    }),
)(LinearProgress);

const BorderLinearProgressRunning = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 6,
            borderRadius: 3,
            width: '250px'
        },
        colorPrimary: {
            backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        bar: {
            borderRadius: 3,
            backgroundColor: '#0086ff',
        },
    }),
)(LinearProgress);

const BorderLinearProgressRevoked = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 6,
            borderRadius: 3,
            width: '250px'
        },
        colorPrimary: {
            backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        bar: {
            borderRadius: 3,
            backgroundColor: '#c294f1',
        },
    }),
)(LinearProgress);

