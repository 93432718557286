import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import moment from 'moment'
import ReplicationToolBar from '../../../listing/components/ReplicationToolBar';
import { styled } from '@material-ui/core/styles';
import { ProgressContainer } from '../../../listing/components/CustomStackedProgress';
import { HorizontalMeter } from '../../../../../components/genericComponents/HorizontalMeter';
import { Loading } from '../../../../utils/Loading';
import { EnvMonitoringDataParser } from '../../../../../util/APIParser';
import properties from '../../../../../properties/properties';
import InvokeApi, { SubscribeToApi } from '../../../../../util/apiInvoker';
import GenerateURL from '../../../../../util/APIUrlProvider';


export default function DeploymentStatusList(props) {
    var application_id = props.application_id
    var error_message = props.error_message
    var deployment_data = props.deployment_data;
    var last_deployment_number = props.last_deployment_number
    var deployment_image = deployment_data && deployment_data.spec ? deployment_data.spec.template && deployment_data.spec.template.spec ? deployment_data.spec.template.spec.containers && deployment_data.spec.template.spec.containers[0] ? deployment_data.spec.template.spec.containers[0].image ? deployment_data.spec.template.spec.containers[0].image : null : null : null : null;
    var deployment_image_with_tag = deployment_image ? deployment_image.split(":") : null;
    var deployment_tag = deployment_image_with_tag && deployment_image_with_tag[1] ? deployment_image_with_tag[1] : null
    var deployed_on = props.deployed_on;
    var pods = props.chart_data ? props.chart_data.replicasets ? props.chart_data.replicasets[0].pods : null : null
    var deploment_numnber = deployment_data && deployment_data.metadata ? deployment_data.metadata.annotations && deployment_data.metadata.annotations.deployment_number ? deployment_data.metadata.annotations.deployment_number.replace('$', '#') : null : null
    var service_id = props.service_id;
    var env_id = props.env_id;
    var cd_id = props.cd_id;
    var count = props.count;
    var [state, setState] = useState({ data_loading: false, deployment_list: [], })


    useEffect(() => {
        if (state.deployment_list.length > 0 && cd_id) {
            let find_current_deployment = state.deployment_list.find(item => item.deployment_name == cd_id)
            var monitoring = EnvMonitoringDataParser(find_current_deployment);

            setState({
                ...state,
                loaded: true,
                monitoring: monitoring,
                health: find_current_deployment ? find_current_deployment.monitoring && find_current_deployment.monitoring.infra ? find_current_deployment.monitoring.infra : null : null
            });
        }

    }, [cd_id, state.deployment_list, env_id])

    const handleSuccessApiHit = (response) => {
        setState({
            ...state,
            loaded: true,
            deployment_list: response
        });
    }
    const handleFailedApiHit = (error) => {
        setState({
            ...state,
            error: error,
        })
    }

    useEffect(() => {
        const placeholders = {
            application_id: application_id,
            service_id: service_id,
            env_id: env_id
        }
        var requestInfo = {
            endPoint: GenerateURL(placeholders, properties.api.env_monitoring_data_url),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        SubscribeToApi(GenerateURL(placeholders, properties.api.env_monitoring_data_url));
        InvokeApi(requestInfo, handleSuccessApiHit, handleFailedApiHit);
        setState({
            ...state,
            loaded: false
        });
    }, [env_id, count]);

    function resolveReplicationStatus(current, max) {
        if (!max) {
            return "disabled";
        }
        const result = Math.round((current / max) * 100);
        if (result > 80) {
            return "danger";
        }
        if (result > 60) {
            return "warning";
        }
        return "success";
    }

    console.log("jsjsdkjdsk",)
    return (
        <>
            {error_message ? <p className="pd-10 font-11 color-grey-83 word-break">{error_message}</p>
                :
                <>
                    <MainDiv>
                        {
                            state.data_loading ?
                                <Loading varient="light" />
                                :
                                <ProgressContainer>
                                    <HorizontalMeter
                                        current={state.monitoring ? state.monitoring.replication_bar.current : null}
                                        max={state.monitoring ? state.monitoring.replication_bar.max ? state.monitoring.replication_bar.max : state.monitoring.replication_bar.current : ""}
                                        status={resolveReplicationStatus(state.monitoring ? state.monitoring.replication_bar.current : "", state.monitoring ? state.monitoring.replication_bar.max : "")}
                                    />
                                    {resolveReplicationStatus(state.monitoring ? state.monitoring.replication_bar.current : "", state.monitoring ? state.monitoring.replication_bar.max : "") == "disabled" ? <p className="auto-scale-disabled ">Auto Scaling Disabled</p> : null}
                                    <ReplicationToolBar service_id={service_id} env_id={env_id} cd_id={cd_id} replication_bar={state.monitoring ? state.monitoring.replication_bar : ""} autoScalingEnable={state.autoScalingEnable ? state.autoScalingEnable.env_cd_scale : null} />
                                </ProgressContainer>

                        }

                    </MainDiv>
                    {deployment_data ? 
                        <div className=" pd-20">

                            <div className="font-14 mb-10 d-flex align-center">
                                Current Deployment&nbsp;<span className="text-grey-b6"> {deploment_numnber ? deploment_numnber : "#" + last_deployment_number}</span>
                                <span className="d-flex align-center ml-5"> <CheckCircleIcon fontSize="large" className="color-success mr-5" /><span className="text-color-grey font-12">Success</span></span>
                            </div>

                            <p className="font-12">Artifact</p>
                            <span className="chip-default artifact-name-blue">
                                {deployment_tag}
                            </span>
                            <div className="font-12">
                                {deployment_data.deployment_status.reason}
                            </div>
                            {/* <p className="font-12">
                                <p className={"color-" + deployment_data && deployment_data.deployment_status ? deployment_data.deployment_status.status ? deployment_data.deployment_status.status.toLowerCase() : "" : ""}>
                                    {deployment_data.deployment_status.reason}
                                </p>
                            </p> */}
                            <p className="font-12">
                                <span className="color-dark font-12">Namespace: </span>
                                <span className="text-grey-83">{deployment_data.metadata.namespace}</span>
                            </p>
                            <p className="font-12">
                                <span className="color-dark font-12">Deployed On: </span>

                                <span className="text-grey-83">{moment(deployed_on).fromNow()}</span>
                            </p>
                        </div>

                     : <>"No Deployment Found"</>}</>}</>
    )
}

DeploymentStatusList.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }
const MainDiv = styled('div')({
    borderBottom: '1px solid #dedede',
    padding: 20,
    '& button': {
        backgroundColor: 'transparent',
        border: 'none',
        fontSize: '11px',
        fontWeight: 'bold',
        color: '#25a5e0',
        '&:first-child': {
            marginRight: 5,
        }
    },
    '& .progress-bar': {
        maxWidth: 250
    },
    '& .MuiSlider-root': {
        maxWidth: '250px!important',
        width: '250px!important'
    },
    '& .slider-with-input': {
        width: 320
    }
})