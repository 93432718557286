import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Popover } from '@material-ui/core';

const TabWiseSearchSelector = ({ItemComponent,tabList,itemList,tabClick,selectedTab,itemClick,selectedItem}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [state,setState] = useState({
        search: "",
        itemList: [],
    })
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (state.search !== '') {
            console.log(state.search, 'ssdfdfdfdfdfddd')
            const list = state.itemList;
            const searchResults = list.filter(item => item.label.toLowerCase().startsWith(state.search.toLowerCase()));
            setState(prevState => ({
                ...prevState,
                itemList: searchResults
            }));
        } else {
            setState(prevState => ({
                ...prevState,
                itemList: itemList
            }));
        }
    }, [state.search]);

    useEffect(()=>{
        setState((prevState)=>({
            ...prevState,
            itemList: itemList
        }))
    },[itemList])

    const handleSearchData = (e) => {
        const value = e.target.value;

        setState(prevState => ({
            ...prevState,
            search: value
        }))

    }
    return (
        <>
        <div className={classes.root} onClick={handleClick} style={ open ? {borderColor: "#0086FF"} : {}} >
            <span>{selectedItem ? <ItemComponent data={selectedItem} selected={true} /> : "Select User"}</span>
            <span className='ri-arrow-down-s-line line-height-normal font-20 color-icon=secondary'></span>
        </div>

                        <Popover
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                            }}
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                            }}
                            PaperProps={{ style: { overflow: 'visible', marginTop: "10px",borderRadius: "6px", } }}
                        >
                            <div className={classes.popover}>
                                <div className='search-container'>
                                        <div>
                                            <input
                                                className='search-input'
                                                name='search'
                                                //autoFocus={true}
                                                placeholder='Search'
                                                value={state?.search}
                                                onChange={handleSearchData}
                                                disabled={false}
                                            />
                                        </div>
                                        
                                    <span className='ri-search-2-line font-20' style={{color: "#CACACA"}}></span>
                                </div>
                                <div className='env-type-list'>
                                    {
                                       tabList.map((ele,i)=>{
                                            return <div key={i} className={ selectedTab == ele.id ? 'env-tab selected-env-tab font-12' : 'env-tab font-12' } onClick={()=>{tabClick(ele.id)}}>{ele.label}</div>
                                        })
                                    }
                                </div>
                                <div className='list-heading'>Titles</div>
                                <div className='env-list'>
                                    {
                                        state.itemList?.length > 0 ?
                                            state.itemList.map((ele,i)=>{
                                                
                                                return <div key={i} className={ele.id == selectedItem?.id? 'env-item font-14 selected-item': 'env-item font-14'} onClick={()=>{itemClick(ele.id); handleClose();}}>
                                                    <ItemComponent data={ele} />
                                                </div>
                                                
                                                
                                            })
                                        :
                                            <div className='d-flex align-center justify-center width-full'>No Result Found</div>
                                    }
                                </div>
                            </div>
                        </Popover>
    </>
    )
}

export default TabWiseSearchSelector;

TabWiseSearchSelector.propTypes = {
    prev_state: PropTypes.object, 
    inherits: PropTypes.any, 
};

const useStyles = makeStyles((theme)=>({
    root: {
        //background: "red",
        display: "flex",
        padding: "8px 10px 8px 16px",
        alignItems: "center",
        gap: "12px",
        justifyContent:'space-between',
        borderRadius: "6px",
        border : "1px solid #E6E6E6",
        cursor: "pointer",
        '& .selected-label':{
            display: "flex",
            alignItems: "center",
            gap: "3px",
            width: "80%"
        },
        '& .selected-env': {
            display: "inline-block",
            width: "100%",
            fontWeight: "500",
            color: "#2F2F2F",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
        '& .env-type': {
            display: "inline-flex",
            padding: "4px 8px",
            alignItems: "flex-start",
            borderRadius: "4px",
            gap: "10px",
            //fontSize: "10px",
            fontWeight: "700",
            color: "#FFF",
            width: "fit-content",
        },

        '&:hover' :{
            borderColor : "#bebebe",
        }
    },
    popover: {
        //width: "100%",
        display: "flex",
        width: "330px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "5px",
        borderRadius: "6px",
        border: "1px solid #F4F4F4",
        background: "#FFF",
        boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.08)",
        '& .search-container': {
            display: "flex",
            padding: "10px 12px",
            justifyContent: "space-between",
            alignItems: "center",
            alignSelf: "stretch",
            width: "100%",
            height: "40px",
            borderBottom: "1px solid #E6E6E6",
            '& .search-input': {
                border: "none",
                padding: "0",

                '&:focus': {
                    border: "none !important",
                    outline: "none !important",
                }
            },
        },
        '& .list-heading': {
            display: "flex",
            padding: "6px 12px 0px 12px",
            alignItems: "flex-start",
            gap: "10px",
            alignSelf: "stretch",
            textTransform: "uppercase",
            fontSize: "12px",
            fontWeight: "600",
            color: "#949494",
        },
        '& .env-type-list': {
            display: "flex",
            padding: "6px 12px 0px 12px",
            //flexDirection: "column",
            alignItems: "flex-start",
            gap: "10px",
            alignSelf: "stretch",
            '& .env-tab': {
                display: "flex",
                padding: "6px 10px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                borderRadius: "6px",
                border: "1px solid #E6E6E6",
                background: "#FFF",
                //fontSize: "12px",
                fontWeight: "500",
                cursor: "pointer",
                '&:hover': {
                    background: "#f4f4f4",
                }
            },
            '& .selected-env-tab': {
                background: "#0086FF",
                color: "#FFF",
                border: "none",
                '&:hover': {
                    background: "#0086FF !important",
                }
            }
        },
        '& .env-list': {
            display: "flex",
            paddingBottom: "8px",
            flexDirection: "column",
            alignItems: "flex-start",
            alignSelf: "stretch",
            height: "180px",
            overflowY: "scroll",
            '& .env-item': {
                display: "flex",
                //padding: "8px 12px",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                cursor: "pointer",
                '&:hover': {
                    background: "#f4f4f4",
                },
                '& .component-env-name': {
                    width: "75%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                }
            },
            '& .selected-item': {
                background: "#F5FAFF",
            }
        }
    }
}))