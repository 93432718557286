import { useEffect, useState } from "react";

const useSSEConnection = (is_tab_hidden, url, eventName, onUpdateSseFunction ) => {

    console.log(url,'url_view_123')
    const [eventSource, setEventSource] = useState(null)

    useEffect(() => {
        if (is_tab_hidden) { // closing connection
            eventSource?.close()
        }
        else{
            // createSSE();
        }
    }, [is_tab_hidden])

    useEffect(() => {
        if ((!eventSource || eventSource?.readyState == 2)) {
            loadSSE();
        }

        return () => {
            eventSource?.readyState == 1 && eventSource?.close()
        }
    }, [eventSource]);


    function loadSSE() {
        if (eventSource) {
            console.log("Closing existing eventSource before creating a new one");
            eventSource.close();
        }
        const celeryEventSource = new EventSource(url)
        setEventSource(celeryEventSource)
        celeryEventSource.addEventListener(eventName, handlePipelineData)
    }

    const handlePipelineData = (e) => {
        let data = JSON.parse(e.data)
        onUpdateSseFunction(data)
        console.log('data_ooopds', data)
    }

    const closeSSE = () => {
        if (eventSource?.readyState === 1) {
            eventSource.close();
            setEventSource(null);
        }
    }

    const createSSE = () => {
        if (!eventSource || eventSource?.readyState === 2) {
            loadSSE();
        }
    }

    return { closeSSE: closeSSE, createSSE: createSSE };

}

export default useSSEConnection;

