import React, { createContext, useContext, useState } from 'react'

export const ReleasePackageContext = createContext();


export const ReleasePackageContextProvider = ({children}) => {
    const [typeTag,setTypeTag] = useState({});
    const [skipable,setSkippable] = useState(false);
    const [selectedApp,setSelectedApp] = useState(null);
    const [appList,setAppList] = useState({});
    const [configureChangeSet,setConfigureChangeSet] = useState([]);
    return (
        <ReleasePackageContext.Provider value={{ typeTag,setTypeTag,skipable,setSkippable,configureChangeSet,setConfigureChangeSet,selectedApp,setSelectedApp,appList,setAppList}}>
            {children}
        </ReleasePackageContext.Provider>
    )
}

export const useReleasePackageContext = ()=>{
    return useContext(ReleasePackageContext);
}
