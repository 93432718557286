import React, { useState } from 'react'
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import GenerateURL from '../../util/APIUrlProvider';
import Delete from './Delete';
import { Input } from '../../components/genericComponents/Input';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import { ExandableComponentList } from '../hoc/expandableList';
import AvatarSquare from '../../components/genericComponents/AvatarSquare'
import DeleteDialog from '../../views/superAdmin/UserAndExternalRoles/components/DeleteDialog';

const EditableTable = (props) => {

  const classes = useStyles()

  const [state] = useState({
    data: {},
    error: {},
  })

  const { columnNames,
    whiteRow,
    actionButton,
    apiFetchedData,
    tableActionHandler,
    fetchAfterAction,
    endPointObj,
    apiUrl,
    variant,
    handleDeleteRow,
    fontSize,
    removeChipFromList,
    postRequestOnly,
    edit_button_enabled,
    checkCallingState
  } = props

  const getLabelFromSymbol = (symbol) => {
    console.log("fsfbahjfbhjabh", symbol)
    switch (symbol) {
      case '=':
        return 'Equal to ';
      case '≠':
        return 'Not Equal to';
      case '>':
        return 'Greater then';
      case '<':
        return 'Less then';
      default:
        return null;

    }
  }
  return (

    <>

      <div className={`pr-15 pl-15 border-bottom editable-table-wrapper ${classes.labelHeader}`} style={{ display: 'flex', justifyContent: 'space-between', minHeight: '40px' }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >

          {columnNames && columnNames.map((item, index) => {
            return (
              <div style={{ width: item.width }} key={index}>
                <label className={`label-style-wrapper ${classes.labelStyle}`}>{item.label}</label>
              </div>
            )

          })}

        </Grid>

        {

          actionButton &&

          <Grid container direction="row" alignItems="center" style={{ width: '5%' }}>



            <span className={classes.labelStyle}>

              Actions
            </span>

          </Grid>
        }

      </div>
      {
        apiFetchedData ? apiFetchedData.map((item, index) => {
          console.log('list_api_fetched', item["issue"])
          let tableClass = 'pr-15 pl-15 border-bottom'

          if (!whiteRow) {
            tableClass = (index + 1) % 2 === 0 ? `pr-15 pl-15 ${classes.stripList}` : 'pr-15 pl-15'
          }

          const handleInviteUser = (item) => checkCallingState('edit_user',item);

          return (
            <div className={tableClass} key={item.id} style={{ display: 'flex', justifyContent: 'space-between', minHeight: '40px' }}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >

                {columnNames && columnNames.map((label, index) => {
                  console.log('line 117', label, index);
                  let dataClass = label.grey ? `${classes.greyText} ${classes.tableFont}` : classes.tableFont
                  console.log(label, "viewDetailsFunctionality")
                  let dataColumnClass = ''
                  let checkboxFunctionality = label.checkbox
                  let chipFunctionality = label.chip
                  let viewDetailsFunctionality = label.view_details_button
                  let iteration_count = 3

                  let expandable_permissions = item["permission_url"] ? +(item["permission_url"].length - iteration_count) : null

                  return (
                    <div style={checkboxFunctionality ? { display: 'flex', alignItems: 'center', width: label.width } : { width: label.width }} key={index}>

                      {checkboxFunctionality &&
                        <div className='remove-margin-from-input'>

                          <Input
                            type="simple-checkbox"
                            label=""
                            name="is_conditional_step"
                            data={state.data}
                            error={state.error}
                            onChangeHandler={() => { }} />
                        </div>
                      }
                      {chipFunctionality ?
                        <>
                          {item && item["operator"] ?
                            <span
                              className='input-chip-checkbox chip chip-default'
                              style={{ transition: 'all 500ms ease', backgroundColor: '#0086ff', color: 'white', marginLeft: '5px', }}>{getLabelFromSymbol(item["operator"])}</span>
                            : null
                          }

                          {item["issue"] && item["issue"].map(nested_item => {
                            return <>
                              {nested_item.label ?
                                <span
                                  className='input-chip-checkbox chip chip-default'
                                  style={{ transition: 'all 500ms ease', backgroundColor: '#0086ff', color: 'white', marginLeft: '5px', paddingBottom: '2px' }}>{nested_item.label} <button className={classes.chipCrossButton} onClick={() => { removeChipFromList(nested_item.label, item.issue_link_type) }}><CloseIcon /></button></span>
                                :
                                <span
                                  className='input-chip-checkbox chip chip-default'
                                  style={{ transition: 'all 500ms ease', backgroundColor: '#0086ff', color: 'white', marginLeft: '5px', }}>{nested_item}</span>}</>
                          })}
                        </> :
                        viewDetailsFunctionality ?
                          <>
                            {item["permission_url"] ?
                              <>
                                {console.log(item["permission_url"], "hi cuteeeeee!!!!!")}
                                <button
                                  onClick={() => { props.hitApiOnClick(item) }}
                                  className='btn input-chip-checkbox chip chip-default'
                                  style={{ transition: 'all 500ms ease', backgroundColor: '#0086ff', color: 'white', marginLeft: '5px', paddingBottom: '2px' }}>View Details </button>
                                <div className="env-header d-flex d-flex-wrap">
                                  <ExandableComponentList
                                    compoenent_list={item["permission_url"]}
                                    variant="capsule_view"
                                    iteration_count={iteration_count}
                                    expandable_component={expandable_permissions}
                                  />

                                </div>
                              </> :
                              <>
                                <button
                                  onClick={() => { props.hitApiOnClick(item) }}
                                  className='btn input-chip-checkbox chip chip-default'
                                  style={{ transition: 'all 500ms ease', backgroundColor: '#0086ff', color: 'white', marginLeft: '5px', paddingBottom: '2px' }}>View Apps </button>
                                <div className="env-header d-flex d-flex-wrap">
                                  <ExandableComponentList
                                    compoenent_list={[]}
                                    variant="capsule_view"
                                    iteration_count={iteration_count}
                                    expandable_component={expandable_permissions}
                                  />
                                </div>
                              </>
                            }
                          </> :
                          label.label_with_initials ?
                            
                              <span className="d-flex align-center avatar-override">
                                <AvatarSquare
                                  shape="sm-round"
                                  name={item["name"]}
                                  varient="double" />
                                {/* <Link to={`/user-profile`}> */}
                                <label className={dataClass}>
                                  {item["name"]}
                                </label>
                                {/* </Link> */}
                              </span>
                             :
                            <label className={dataClass}>
                              {
                                label.name == "entity_type" ?
                                  <>{item[label.name].name}</> :
                                  label.name == "is_active" ?
                                    item["is_active"] ?
                                      <span><span className="status-bullet status-bullet-success"></span>Active</span> :
                                      <span><span className="status-bullet status-bullet-danger"></span>Inactive</span>
                                    :
                                    item[label.name] ?
                                      item[label.name] :
                                      item[label.alternateName]
                              }</label>
                      }

                    </div>
                  )

                })}

              </Grid>

              {

                actionButton &&

                <Grid direction="row" alignItems="center" style={{ width: '5%', display: 'flex', alignItems:'center' }}>

                  {
                    edit_button_enabled &&
                    <button className="transparent-btn nowrap" onClick={() => { tableActionHandler('edit', item) }}>
                      <EditIcon style={{ color: 'blue' }} />
                    </button>
                  }

                  <div>
                    {
                      variant == "static_delete" ?
                        <IconButton onClick={() => { handleDeleteRow(item) }}>
                          <DeleteIcon className={fontSize} style={{ color: '#ff8969' }} />
                        </IconButton>
                        :
                        variant == "only_post" ?
                        
                            <DeleteDialog 
                            user_data={item} 
                            onClickHitApi={() => { postRequestOnly(item.id, item.is_active) }}
                             />
                          
                          :
                        

                            <Delete
                              varient='IconButton'
                              display_data_name={item.name}
                              data={{ entity_id: item.id, name: props.delete_key ? props.delete_key : "project_level_key_replacement" }}
                              refresh={fetchAfterAction}
                              api_link={props.delete_key ?
                                GenerateURL({ id: item.id }, apiUrl)
                                :
                                GenerateURL({ ...endPointObj, id: item.id }, apiUrl)}
                            />
                          

                    }
                  </div>
                  {
                    props.userInvite && <div className="cursor-pointer"
                    onClick={()=>handleInviteUser(item)}
                    onKeyDown={()=>{}}
                     style={{
                      border: '2px solid #0086ff',
                      height: '24px',
                      width: '24px',
                      borderRadius: '12px',
                      display:'flex',
                      alignItems: 'center',
                      justifyContent: 'center'}}
                      role="button" 
                      tabIndex={0}>
                      <span className="material-icons material-symbols-sharp font-12" style={{color:'#0086ff'}} role='button' tabIndex={0}>
edit
</span>
                    {/* <EditSharpIcon  /> */}
                  </div>
                  }
                 

                </Grid>
              }

            </div>

          )
        }) : <Grid container direction="row" className='mt-15' justifyContent='center' alignItems="center">



          <span className='font-12'>No data</span>

        </Grid>

      }
    </>


  )
}


EditableTable.defaultProps = {
  columnNames: null,             //for label
  actionButton: false,        // send true if want action buttons
  apiFetchedData: null,        //for showing data
  tableActionHandler: () => { },
  fetchAfterAction: () => { },
  endPointObj: {},
  apiUrl: '',
  handleDeleteRow: () => { },
  fontSize: "",
  variant: null,
  removeChipFromList: () => { },
  label_with_initials: false,
  edit_button_enabled: true
}

EditableTable.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export default EditableTable

const useStyles = makeStyles(theme => ({

  labelHeader: {

    backgroundColor: '#f5f5f5'

  },

  stripList: {
    backgroundColor: '#f5f5f5b8',
    border: '1px solid #f5f5f5',

  },

  tableFont: {
    fontSize: '12px'
  },

  labelStyle: {
    fontWeight: '500',
    fontSize: '12px'
  },

  greyText: {
    color: '#7d838f'
  },
  chipCrossButton: {
    backgroundColor: '#fff',
    height: '20px',
    width: '20px',
    display: 'inline-block',
    verticalAlign: 'middle',
    borderRadius: '10px',
    border: 'none',
    outline: 'none',
    '& .MuiSvgIcon-root': {
      verticalAlign: 'middle',
      textAlign: 'center',
      margin: 'auto',
      display: 'block'
    }
  }

}))