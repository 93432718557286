import React from 'react';
import PropTypes from 'prop-types';
import SquareAvatar from '../../../../components/genericComponents/AvatarSquare';

const HeaderWithServiceName = props =>{
    
    return(
        <div className="service-name-section">
                <SquareAvatar name={props.service_name} />
                <span className="span-class">
                    <span className="service-name">{props.service_name}</span>
                    <span className="tagline">Follow these simple steps and you can onboard your service in just few minutes</span>
                </span>
        </div>
    )
}

HeaderWithServiceName.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};

export default HeaderWithServiceName