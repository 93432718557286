import React, { useState } from 'react';
import { SwitchToSuperAdminRole, SwitchToNormalUserRole, IsUserActualSuperUser, IsSuperUser, GetPermitedURLsAfterSwitch } from '../../util/security';
import { Redirect } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';

const SwitchUser = props => {
    const [state, setState] = useState({ redirect: false })
    function onClickSwitchToUserPortal() {
        console.log("dcssa")
        SwitchToNormalUserRole()
        GetPermitedURLsAfterSwitch()
        setState({ redirect: true })
    }

    function onClickSwitchToAdminPortal() {
        SwitchToSuperAdminRole()
        GetPermitedURLsAfterSwitch()
        setState({ redirect: true })
    }
    console.log(IsUserActualSuperUser(), IsSuperUser(), "ioio_____11")
    return (
        <div className=' font-family-v1 font-12 font-weight-500'>
            {state.redirect ? <Redirect to="/" />
                :
                <>
                    {
                        IsUserActualSuperUser() && IsSuperUser() ?
                            <div className='' style={{ color: '#0086ff', fontSize: '11px', fontWeight: 600, backgroundColor: '#EBF5FF', display: 'inline-block', borderRadius: '30px', padding: '4px 8px', marginBottom: '6px' }}>
                                Super Admin
                            </div>

                            :
                            <div className='' style={{ color: '#2EBE79', fontSize: '11px', fontWeight: 600, backgroundColor: '#E6FBEA', display: 'inline-block', borderRadius: '30px', padding: '4px 8px', marginBottom: '6px' }}>
                                User Portal
                            </div>
                    }
                    {
                        IsUserActualSuperUser() && IsSuperUser() ?
                            <button className='btn btn-transparent d-flex align-center' style={{ padding: '0px' }} onClick={onClickSwitchToUserPortal}  ><span className='ri-refresh-line font-20 color-primary'></span><span className='font-12 font-weight-500'> &nbsp;Switch to User Portal</span></button>
                            :
                            !IsUserActualSuperUser() && !IsSuperUser() ?
                                <Tooltip title="The user doesn't have super admin access.">
                                    <button
                                        className='btn btn-transparent d-flex align-center '
                                        
                                        style={{ padding: '0px', color:'#2f2f2f', border:'none', cursor:'not-allowed' }}
                                        onClick={() => { }} ><span className='ri-refresh-line font-20 color-primary'></span><span className='font-12 font-weight-500'> &nbsp;Switch to Super Admin</span></button>
                                </Tooltip>

                                :
                                <button
                                    className='btn btn-transparent d-flex align-center'
                                    style={{ padding: '0px' }}
                                    onClick={onClickSwitchToAdminPortal} ><span className='ri-refresh-line font-20 color-primary'></span><span className='font-12 font-weight-500'> &nbsp;Switch to Super Admin</span></button>

                    }

                </>
            }

        </div>
    )
}

export default SwitchUser;