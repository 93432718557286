import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/styles';

function NewReleaseFlag() {
    const [showFlag, setShowFlag] = useState(true);
    const classes = useStyles();
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowFlag(false);
        }, 15 * 24 * 60 * 60 * 1000);
        return () => clearTimeout(timer);
    }, []);

//     const [rotation, setRotation] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setRotation((prevRotation) => prevRotation + 1);
//     }, 60000);
//     return () => clearInterval(interval);
//   }, []);


    return (
        <>
            {showFlag &&
                <div className={`${classes.Notification_root} flag-strip`}>
                    <div className="icon-section" >
                        {/* <span className="material-icons material-symbols-outlined">
                            new_releases
                        </span> */}
                        <img src="/images/new_releases_icon-blue.svg" className={`${classes.notificationIcon} rotating`} alt="new release notification" />
                        <div id="flag" className="content-strip font-12">
                    to find what you get in this 
                    click on profile icon &gt; Release Notes &nbsp; &nbsp;
                    </div>
                    </div>
                    
                </div>
            }
        </>
    );
}

export default NewReleaseFlag;


const useStyles = makeStyles(theme => ({
    Notification_root: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#d7e9f7',
        position:'absolute',
        height: 'inherit',
        right: '0px',
        '& .icon-section':{
            padding: '8px 7px 7px',
            '&:hover':{
                cursor: 'pointer'   
            }
        },
        '& .content-strip':{
            width: '0px'
        }
    },
    notificationIcon:{
        height: 30,
        width: 30
    }
}))