import React, { useState } from 'react'
import PropTypes from 'prop-types';

import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import HorizontalTab from '../../../../components/wizardComponents/HorizontalTab';
import { IconButton, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Loading } from '../../../utils/Loading';

const ActivityStepDetails = (props) => {

    var activityDetail = props.activityDetail ? props.activityDetail : [];

    console.log(activityDetail, 'Activity_details_0001');

    const handleClose = props.handleClose;
    const [state, setState] = useState({ open_div: false });
    const tableGird = props.tableGird;
    const colSpan = props.colSpan ? props.colSpan : 5
    const logUrl = props.logUrl;
    const task_id = props.task_id ? props.task_id : null;
    const activity_id = props.activity_id ? props.activity_id : null
    const loading_step_status = props.loading_step_status;
    const type = props.type
    var event_source = null
    //sort activity detail names in reverse order
    function reverseActivityDetails(activityDetail) {
        var detailsList = activityDetail.activity_sub_task ? activityDetail.activity_sub_task.sub_task_name : activityDetail.job_step.name
        var listItems = []
        listItems.push(detailsList)
        // return listItems?.toLocaleString().length > 25 ? listItems?.toLocaleString()?.substring(0, 25) + "..." : listItems;
        return listItems;
    }
    function reverseActivityDetailsDuration(activityDetail) {
        var created_at_date = moment(activityDetail.created_at);
        var updated_at_date = moment(activityDetail.updated_at);
        var totalDuration = moment.duration(updated_at_date.diff(created_at_date))
        var seconds = totalDuration.seconds();
        var minutes = totalDuration.minutes();
        var durationList = [minutes + "m" + " " + seconds + "" + "s"]
        var listItems = []
        for (var i in durationList) {
            listItems.push(durationList[i])
        }
        return listItems;
    }
    console.log('activityDetail>>>', activityDetail);
    const transformDataforHorizontalTab = () => {

        let updated_arr = activityDetail && activityDetail.reverse().map((single_item, key) => {

            return (
                {
                    order: key,
                    mainText: <Link className="text-anchor-blue cursor-pointer"
                        to={logUrl + "&active_tab=" + key
                        } target="_blank" >
                        <div className='d-flex align-center' style={{ flexDirection: "column", gap: "5px", fontSize: "11px", lineHeight: "13.41px", fontWeight: "500" }}>
                            <Tooltip title={reverseActivityDetails(single_item)}>
                                <span className="mr-5" style={{ color: "#2F2F2F", fontSize: "11px" }}>{reverseActivityDetails(single_item).toLocaleString().length > 25 ? reverseActivityDetails(single_item)?.toLocaleString()?.substring(0, 25) + "..." : reverseActivityDetails(single_item)}</span>
                            </Tooltip>
                            <span className="" style={{ color: "#787878", }}>Duration: &nbsp;{reverseActivityDetailsDuration(single_item)}</span>
                        </div>
                    </Link>,
                    status: single_item.status
                }
            )
        });
        // updated_arr.reverse()
        return updated_arr;

    }
    const handleCloseCurrentTab = () => {
        props.removeArrData()
        setState(new_state => ({
            open_div: false
        }))
    }
    return (
        <>{
            <ActivityStepTab
                type={type}
                tableGird={tableGird}
                colSpan={colSpan}
                logUrl={logUrl}
                task_id={task_id}
                handleClose={handleClose ? handleClose : handleCloseCurrentTab}
                loading_step_status={loading_step_status}
                transformDataforHorizontalTab={transformDataforHorizontalTab}
                {...props} />
        }
        </>
    )
}
ActivityStepDetails.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }
export default ActivityStepDetails;

const ActivityStepTab = (props) => {
    const handleClose = props.handleClose;
    const tableGird = props.tableGird;
    const colSpan = props.colSpan ? props.colSpan : 5
    const logUrl = props.logUrl;
    const loading_step_status = props.loading_step_status;
    const transformDataforHorizontalTab = props.transformDataforHorizontalTab;
    console.log("activityDetail-1-1-1", transformDataforHorizontalTab)
    return (
        <>
            {
                props?.type === 'job-card-v3' ?
                    <span>
                        <div className="d-flex align-center space-between">
                            <p>
                                <span className="font-14 ml-5" style={{ color: "#3f4b4e" }}>Job Steps Status &nbsp;&nbsp;</span>
                                <Link to={logUrl} target="_blank" className="font-11 text-anchor-blue cursor-pointer">View Logs</Link>
                            </p>
                            <div>
                                <button style={{ width: '18px', height: '18px', display: 'flex', marginRight: '0px', marginLeft: 'auto' }} class="btn-round border-navy bg-clear-btn align-center justify-center" onClick={() => props.onRefresh()} ><span style={{ fontSize: '12px' }} className="flaticon-refresh-button-1"></span></button>
                            </div>
                        </div>
                        <HorizontalTab
                            tabDataList={transformDataforHorizontalTab()}
                            onClickHandler={() => { }}
                            variant="step_logs_static"
                            tabState={
                                {
                                    selectedTabOrder: 0
                                }
                            }
                        />
                        {loading_step_status && <Loading varient="light" />}
                    </span> :
                    tableGird ?
                        <tr>
                            <td colSpan={colSpan}>
                                <span className='d-flex f-direction-column' style={{ width: "940px", alignItems: "start",overflow:'hidden' }}>
                                    <div className="d-flex align-center space-between w-100">
                                        <p>
                                            <span className="font-14 " style={{
                                                color: "#787878",
                                                fontSize: "12px",
                                                fontStyle: "normal",
                                                fontWeight: 500,
                                                lineHeight: "normal",
                                                textTransform: "uppercase"
                                            }}>Activity Steps Status &nbsp;&nbsp;</span>
                                            <Link to={logUrl} target="_blank" className="font-11 text-view-log cursor-pointer">View Logs</Link>
                                        </p>
                                        <div>
                                            <IconButton onClick={() => { handleClose() }}>
                                                <CloseIcon fontSize="large" style={{ color: "#3f4b4e" }} />
                                            </IconButton>
                                        </div>
                                    </div>
                                    <div className='d-flex align-center horizontal-tab-scroll'>
                                        <HorizontalTab
                                            tabDataList={transformDataforHorizontalTab()}
                                            onClickHandler={() => { }}
                                            variant="step_logs_static_new"
                                            tabState={
                                                {
                                                    selectedTabOrder: 0
                                                }
                                            }
                                        />
                                    </div>
                                </span>
                            </td>
                        </tr>
                        : props.tableGridNew ?
                                    <span className='d-flex f-direction-column' style={{ width: "100%", alignItems: "center" }}>
                                        <div className="d-flex align-center space-between" style={{width : "100%"}}>
                                            <p>
                                                <span className="font-14 " style={{
                                                    color: "#787878",
                                                    fontSize: "12px",
                                                    fontStyle: "normal",
                                                    fontWeight: 500,
                                                    lineHeight: "normal",
                                                    textTransform: "uppercase"
                                                }}>Activity Steps Status &nbsp;&nbsp;</span>
                                                <Link to={logUrl} target="_blank" className="font-11 text-view-log cursor-pointer">View Logs</Link>
                                            </p>
                                            <div>
                                                <IconButton onClick={() => { handleClose() }}>
                                                    <CloseIcon fontSize="large" style={{ color: "#3f4b4e" }} />
                                                </IconButton>
                                            </div>
                                        </div>
                                        <div className='d-flex align-center' style={{width : "100%"}}>
                                            <HorizontalTab
                                                tabDataList={transformDataforHorizontalTab()}
                                                onClickHandler={() => { }}
                                                variant="step_logs_static_new"
                                                tabState={
                                                    {
                                                        selectedTabOrder: 0
                                                    }
                                                }
                                            />
                                        </div>
                                    </span>
                            :

                            <>
                                <span>
                                    <div className="d-flex align-center space-between">
                                        <p>
                                            <span className="font-14 ml-5" style={{ color: "#3f4b4e" }}>Activity Steps Status &nbsp;&nbsp;</span>
                                            <Link to={logUrl} target="_blank" className="font-11 text-anchor-blue cursor-pointer">View Logs</Link>
                                        </p>
                                        <div>
                                            <IconButton onClick={handleClose}>
                                                <CloseIcon fontSize="large" style={{ color: "#3f4b4e" }} />
                                            </IconButton>
                                        </div>
                                    </div>
                                    <HorizontalTab
                                        tabDataList={transformDataforHorizontalTab()}
                                        onClickHandler={() => { }}
                                        variant="step_logs_static"
                                        tabState={
                                            {
                                                selectedTabOrder: 0
                                            }
                                        }
                                    />
                                    {loading_step_status && <Loading varient="light" />}
                                </span>
                            </>
            }
        </>
    )
}
ActivityStepTab.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }