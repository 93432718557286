import React from 'react';
import PropTypes from 'prop-types';
import GenericSkeleton from './Skeletons/GenericSkeleton';


export const CompactDataCard = ({skeleton,...props}) =>{
    if(skeleton){
        return (
            <GenericSkeleton variant={"rect"}  height={"100px"} style={{borderRadius: "8px"}} />
        )
    }
    const icon = props.icon;
    const headContent = props.headContent;
    const subContent = props.subContent;
    const color = props.color;
    return(
        <div className="data-card" style={{backgroundColor:color}}>
            <div className="data-card-icon">
                {icon}
            </div>
            <div className="data-card-text">
                <h3>{headContent}</h3>
                <p>{subContent}</p>
            </div>
        </div>
    );  
}

CompactDataCard.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };