import React, { useEffect, useState } from "react";
import { Input } from "../../../../components/genericComponents/Input";
import { getCommonFunctions } from "../../../serviceRevamp/add/ci_flow/SourceDetails";
import AdvanceSearchFilterCombo from "../../../../components/genericComponents/AdvanceSearchFilter/AdvanceSearchFilterCombo";
import { Grid } from "@material-ui/core";
import { erase, error, keys } from "highcharts";
import { VALIDATION_TYPE_REQUIRED } from "../../../../util/Validator";

const supportedChoices = [
    { id: "pipeline_vars", label: "Pipeline Vars" },
    { id: "jira_key", label: "Jira Refrence Keys" },
    { id: "job_out_put_vars", label: "Job Output Vars" }
]
const DynamicFilterField = ({ label, children, pipelineVars, jiraIssueKeys, jobStepOrderOutputVars, setParentState, parentState, prev_state, ...props
}) => {

    const inherits = props.inherits ? props.inherits : {};
    const childrenProps = children.props
    const isRequiredField = childrenProps.mandatorySign
    console.log(prev_state, 'prev_state-)sdds')
    const [state, setState] = useState(
        getDefaultState(prev_state, childrenProps.name)
    );
    const commonFunctions = getCommonFunctions(state, setState, inherits);

    useEffect(() => {
        state && setParentData()
    }, [state])

    useEffect(() => {
        if (isRequiredField && parentState) {
            if (state.dynamicFilterView) {
                let parentStateVadiations = { ...parentState.validations }
                let parentStateErrors = {...parentState.error}
                delete parentStateVadiations[childrenProps.name]
                delete parentStateErrors[childrenProps.name]
                setParentState(prevState => ({
                    ...prevState,
                    validations: { ...parentStateVadiations },
                    error:{...parentStateErrors}
                }))
            }
            else {
                let parentStateVadiations = { ...parentState.validations }
                if (!parentStateVadiations[childrenProps.name]) {
                    parentStateVadiations[childrenProps.name] = [VALIDATION_TYPE_REQUIRED]
                    setParentState(prevState => ({
                        ...prevState,
                        validations: { ...parentStateVadiations }
                    }))
                }
            }
        }
    }, [state.dynamicFilterView, isRequiredField])

    useEffect(() => {
        if (pipelineVars && jiraIssueKeys && jobStepOrderOutputVars)
            setState(prevState => ({
                ...prevState,
                pipeline_vars: pipelineVars,
                jira_key: jiraIssueKeys,
                job_out_put_vars: jobStepOrderOutputVars
            }))

    }, [pipelineVars, jiraIssueKeys, jobStepOrderOutputVars])

    function setParentData() {

        console.log('maining')

        if (parentState.dynamic_fields[childrenProps.name]) {
            setParentState(prevState => ({
                ...prevState,
                dynamic_fields: {
                    ...prevState.dynamic_fields,
                    [childrenProps.name]: {
                        ...prevState.dynamic_fields[children.name],
                        source: state.data.source,
                        value: state.data[childrenProps.name]
                    }
                }
            }))
        }
        else
            setParentState(prevState => ({
                ...prevState,
                dynamic_fields: {
                    ...prevState.dynamic_fields,
                    [childrenProps.name]: {
                        source: state.data.source,
                        value: state.data[childrenProps.name]
                    }
                }
            }))
    }


    console.log(pipelineVars, jiraIssueKeys, 'children_data_0032')
    const toggleView = () => {
        setState(prevState => ({
            ...prevState,
            dynamicFilterView: !prevState.dynamicFilterView,
            data: {},
            validations: {}
        }))
    }

    const getListAccordingToVars = (type) => {
        if (type)
            return state[type]
        else
            return null
    }
    console.log(state, 'sthjkl')

    const onChangeHandlerForSource = (event) => {
        const key = event.target.name;
        const value = event.target.value;
        setState(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                [key]: value,
                [childrenProps.name]: ""
            },
            error: {
                ...prevState.error,
                [key]: null
            }
        }))
    }

    return (
        <>
            {
                state.dynamicFilterView ?
                    <Grid container>
                        <Grid item lg={5} style={{ padding: '0px' }}>
                            {/* <AdvanceSearchFilterCombo
                    reset={state.resetCount}
                    selectedCheckBoxes={state.selectedCheckBoxes}
                    staticList={state.staticList}
                    uniqueId={'name_234'}
                    labelName={'Choices'}
                    onUpdate={() => { }}

                /> */}
                            <div className="dynamic-input-left">
                                <div style={{ position: 'relative' }}>
                                    <label style={{ position: 'absolute', zIndex: 1, top: '33px', left: '10px' }} className="font-14">Source:</label>
                                    <Input
                                        type="select"
                                        // label="Choices"
                                        label={childrenProps.label}
                                        name="source"
                                        mandatorySign
                                        list={supportedChoices}
                                        data={state.data}
                                        error={state.error}
                                        onChangeHandler={onChangeHandlerForSource}
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid item lg={7} style={{ padding: '0px', paddingTop: '21px' }}>
                            <div style={{ position: 'relative' }}>
                                <DynamicText isDynamicView={state.dynamicFilterView} onClick={toggleView} />
                                <div className="dynamic-input-right">
                                    <label style={{ position: 'absolute', zIndex: 1, top: '12px', left: '10px' }} className="font-14">Key:</label>
                                    <Input
                                        type="select"
                                        label=''
                                        name={childrenProps.name}
                                        mandatorySign
                                        list={getListAccordingToVars(state.data?.source)}
                                        data={state.data}
                                        error={state.error}
                                        onChangeHandler={state.data?.source ? commonFunctions.onChangeHandler : null}
                                    />
                                </div>
                            </div>
                        </Grid>
                    </Grid> :
                    <div style={{ position: 'relative' }}>
                        <DynamicText isDynamicView={state.dynamicFilterView} onClick={toggleView} />
                        {children}
                    </div>
            }
        </>
    )
}

export default DynamicFilterField;

const DynamicText = ({ isDynamicView, onClick }) => {

    return (
        <label style={{ position: 'absolute', right: 0, cursor: 'pointer', zIndex: 1, top: isDynamicView ? '-25px' : '0px' }} onClick={onClick} className="font-12 text-anchor-blue">{isDynamicView ? "Provide Custom Values" : "Choose From Values"}</label>
    )
}

const getDefaultState = (prevState, fieldName) => {
    let data = {}
    console.log(prevState, 'prev_stare_sdsd')
    let dynamicFilterView = false
    if (prevState.dynamic_fields) {
        if (prevState.dynamic_fields[fieldName]) {
            data['source'] = prevState.dynamic_fields[fieldName]['source']
            data[fieldName] = prevState.dynamic_fields[fieldName]['value']
            dynamicFilterView = true
        }
    }
    return {
        resetCount: 0,
        selectedCheckBoxes: [],
        staticList: [],
        data: data,
        error: {},
        dynamicFilterView: dynamicFilterView,
        pipeline_vars: [],
        jira_key: [],
        job_out_put_vars: [],
        validations: {}
    }
}
