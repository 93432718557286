import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import { Link } from 'react-router-dom'

export default function JobConfigureInfo({ jobName, variant }) {
    const classes = useStyles();
    return (
        <>

            {

                variant ?
                    <div className={classes.lgStaticWrap} style={{ backgroundColor: '#FAFAFA', height: '100%' }}>
                        <div>
                            <span className='chip chip-primary font-weight-600 mb-12 d-inline-block' style={{backgroundColor:'#000'}}>ABOUT</span>
                        </div>
                        <div className='staticwrap-inner'>
                            <p className="font-14 font-weight-700 text-uppercase mb-16" style={{color:'#0086ff'}}>{`Configure ${jobName}`}</p>
                            <p className='font-12 font-weight-500 color-primary' style={{}}>A Environment aims to mitigate such scenarios by encouraging automation and documentation, there by increasing communication.
                                In this environment, every release is committed in an automated fashion, enabling the rapid building, testing and deployment of every project.
                                <br /> <br />
                                To learn more about how to setup a Microservice please read <span style={{ color: '#e89317' }}>Documentation </span></p>
                        </div>
                        <img className="float-image" src='/images/bp-watermark-lite.svg' alt="BP watermark" />
                    </div> :
                    <div className={classes.staticwrap} style={{ backgroundColor: '#051939', height: '100%' }}>
                        <div className='staticwrap-inner'>
                            <h1 className="h1">{`Configure ${jobName}`}</h1>
                            <p style={{ color: '#fff', paddingTop: '25px' }}>A Environment aims to mitigate such scenarios by encouraging automation and documentation, there by increasing communication.
                                In this environment, every release is committed in an automated fashion, enabling the rapid building, testing and deployment of every project.
                                <br /> <br />
                                To learn more about how to setup a Microservice please read <span style={{ color: '#e89317' }}>Documentation </span></p>
                        </div>
                    </div>
            }
        </>

    )

   
}

JobConfigureInfo.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

const useStyles = makeStyles({
    lgStaticWrap:{
        boxShadow: '-11px 0px 28px 0px #0000000A inset',
        padding: '24px',
        position:'relative',
        '& .float-image':{
            position:'absolute',
            left:'0px',
            bottom:'24px'
        }
    },
    staticwrap: {
        display: 'flex',
        // alignItems: 'center',
        '& .staticwrap-inner': {
            padding: '215px 30px 0 40px',
            color: '#fff',
            '& p': {
                fontSize: '13px',
                lineHeight: '23px',
                marginTop: '30px',
            }
        },
        '& .h1': {
            fontSize: '25px',
            lineHeight: '36px',
            color: '#ffffff',
            fontWeight: '300',
            position: 'relative',

            '&:before': {
                content: '""',
                borderBottom: '1px solid #000',
                position: 'absolute',
                borderLeft: 'none',
                bottom: '0',
                left: 0,
                display: 'inline-block',
                width: '40px',
                height: '4px',
                background: '#2e2af3',
            }
        },
        '& .info-container': {
            paddingLeft: '45px',
            paddingRight: '45px'
        },

        '& .less-bright': {
            filter: 'brightness(0.89)'
        }
    }
})














