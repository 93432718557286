import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Input } from '../../../../components/genericComponents/Input';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import { Grid } from '@material-ui/core';
import InvokeApi, { PostData } from '../../../../util/apiInvoker';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import MaxWidthDialog from '../../../settings/credentials/components/FormDialogue';
import { ValidateDataSet, VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { ErrorComponent } from '../../../utils/Error';
import { Loading } from '../../../utils/Loading';
import { validateJsonObjFormat } from '../../../../util/util';
import { withRouter, Redirect } from 'react-router-dom';

const IntegrationsTesting = (props) => {
    const classes = useStyles();
    const selectedEnvDetails = props.selectedEnvDetails;
    const service_env = props.service_env;
    console.log(service_env, "service_envservice_env")
    const component_env_id = service_env.id;
    const change_in_env_tab = props.change_in_env_tab
    const [state, setState] = useState(
        {
            open: false,
            show_data_loading: true,
            edit: false,
            data: {
                headers_json: "",
                key_value_pair: "",
                stopped: 2
            },
            error: {},
            validations: {
                url: [VALIDATION_TYPE_REQUIRED],
                stopped: [VALIDATION_TYPE_REQUIRED]
            },
            credentials: [],
            isRedirectToSecret: false
        });
    const commonFunctions = getCommonFunctions(state, setState, {})

    useEffect(() => {

        fetchIntegrationEditData();

    }, [component_env_id, service_env.project_env_id]);

    const handleClickToggle = () => {
        setState(new_state => ({
            ...new_state,
            open: !new_state.open
        }));
    }
    const handleClickEdit = () => {
        setState(new_state => ({
            ...new_state,
            edit: !new_state.edit
        }));
    }
    function fetchIntegrationEditData() {
        var endpt = GenerateURL({ component_id: selectedEnvDetails.service_id, component_env_id: component_env_id }, properties.api.integration_testing);

        var requestInfo = {
            endPoint: endpt,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, fetchIntegrationEditDataSuccess, fetchIntegrationEditDataFailure);
        fetchCredentials();
        setState(new_state => ({
            ...new_state,
            show_data_loading: true
        }));
    }



    function fetchIntegrationEditDataSuccess(data) {
        console.log(data, "fdkjnsajkfnjanfa")
        setState(new_state => ({
            ...new_state,
            open: true,
            show_data_loading: false,
            data: {
                //   ...new_state.data,
                ...data,
                stopped: data.stopped == true ? 1 : 2
            }
        }));
    }
    function fetchIntegrationEditDataFailure(error, exception) {

        if (error === "Not found.") {

            setState(new_state => ({
                ...new_state,
                show_data_loading: false,
                edit: true,
                not_added: true
            }));
        } else {
            setState(new_state => ({
                ...new_state,
                show_data_loading: false
            }));
        }
    }

    function fetchCredentials() {
        var endpt = GenerateURL({}, properties.api.credential_all);

        var requestInfo = {
            endPoint: endpt,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        requestInfo.endPoint = requestInfo.endPoint + "?all=true";

        InvokeApi(requestInfo, CredentialsFetchSuccess, CredentialsFetchFailure);
        setState(new_state => ({
            ...new_state,
            show_data_loading: true
        }));
    }
    function getCredentialsList(credentials) {
        const new_credential_list = [];
        if (credentials) {
            credentials.forEach(credential => {
                new_credential_list.push({
                    id: credential.id,
                    label: credential.name
                })
            });
        }

        return new_credential_list;
    }
    function CredentialsFetchSuccess(data) {
        var credentials = getCredentialsList(data.results);
        setState(new_state => ({
            ...new_state,
            credentials: credentials,
            credentials_status: "SUCCESS",
            show_data_loading: false
        }));
    }
    function CredentialsFetchFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            show_data_loading: false
        }));
    }

    function validateAndSave() {
        var result = ValidateDataSet(state.data, state.validations);
        var post_url = GenerateURL({ component_id: selectedEnvDetails.service_id, component_env_id: component_env_id }, properties.api.integration_testing);

        var json_header_is_valid = state.data.headers_json && state.data.headers_json.length > 0 ? validateJsonObjFormat(state.data.headers_json) : true
        var key_value_pair_is_valid = state.data.key_value_pair && state.data.key_value_pair.length > 0 ? validateJsonObjFormat(state.data.key_value_pair) : true
        console.log(json_header_is_valid, key_value_pair_is_valid, "fjkdsnfjnjsa")
        if (result.valid) {
            if (json_header_is_valid && key_value_pair_is_valid) {
                var data = {
                    ...state.data,
                    component_env_id: component_env_id
                }
                if (state.data.headers_json == "") {
                    data.headers_json = null
                }
                if (state.data.headers_json == "") {
                    data.key_value_pair = null
                }
                if (state.data.timeout == "") {
                    data.timeout = null
                }
                if (state.data.stopped == 1) {
                    data.stopped = true
                } else {
                    data.stopped = false
                }
                setState(new_state => ({
                    ...new_state,
                    show_data_loading: true
                }))
                PostData(post_url, data, postSuccess, postFail, true);

            } else {
                if (!json_header_is_valid) {
                    setState(new_state => ({
                        ...new_state,
                        show_data_loading: false,
                        error: {
                            ...new_state.error,
                            headers_json: "Please enter valid JSON header"
                        }
                    }))

                }
                if (!key_value_pair_is_valid) {
                    setState(new_state => ({
                        ...new_state,
                        show_data_loading: false,
                        error: {
                            ...new_state.error,
                            key_value_pair: "Please enter valid JSON header"
                        }
                    }))
                }
            }

        }
        else {
            setState(new_state => ({
                ...new_state,
                show_data_loading: false,
                error: {
                    ...new_state.error,
                    ...result.error
                }
            }))


        }
    }

    function postSuccess(response) {

        setState(new_state => ({
            ...new_state,
            show_data_loading: false,
            data_load_failure: false,
            post_success: true,
            edit: false
        }))

    }

    function postFail(response) {

        var error_msg = JSON.stringify(response)
        setState(new_state => ({
            ...new_state,
            error_msg: error_msg,
            show_data_loading: false,
            data_load_failure: true,
        }))
    }

    function handleClickRedirect() {
        setState((new_state) => ({
            ...new_state,
            isRedirectToSecret: true
        }))
    }

    return (
        <>
            {
                state.isRedirectToSecret &&
                <Redirect to="/secrets/add" />
            }
            < div className="section-service-overview" style={{ border: '0px', padding: '10px 0px 0px' }}>

                <div className="d-flex align-center space-between pd-10 border-bottom">
                    <div className="" >
                        <p className="font-14 font-weight-300 lh-12">
                            Integration Testing Configuration
                        </p>
                        <p className="font-11 text-grey-6e">
                            To know more about integration testing please read the <a href="https://www.opstree.com/buildpiper/documentation/" target="_blank">Documentation</a>
                        </p>
                    </div>
                    {

                        state.edit ?
                            <span role='button' tabIndex={0} className='cross-icon cursor-pointer' onKeyDown={() => { }} onClick={handleClickEdit} style={{ fontSize: '24px', color: '6e6e6e', fontWeight: 300, padding: '5px' }}>&times;</span> :
                            <span role='button' tabIndex={0} className='cross-icon cursor-pointer' onKeyDown={() => { }} onClick={handleClickEdit} style={{ fontSize: '24px', color: '6e6e6e', fontWeight: 300, padding: '5px' }}><BorderColorIcon style={{ color: '#0086ff' }} /></span>
                    }


                </div>
                <div className='pd-10' >
                    {
                        state.open ?
                            <>
                                {
                                    state.show_data_loading ?
                                        <div className='' style={{ height: '350px' }}>
                                            <Loading varient="light" />
                                        </div>
                                        :
                                        state.error_msg ?
                                            <ErrorComponent variant="msg-box" error={state.error_msg} /> :
                                            <>
                                                <Grid container spacing="2" justifyContent='center'>
                                                    <Grid item lg={5}>
                                                        <Input
                                                            type="text"
                                                            label="Integrations URL"
                                                            mandatorySign
                                                            name="url"
                                                            placeholder="application/1/service/1/detail"
                                                            data={state.data}
                                                            error={state.error}
                                                            onChangeHandler={state.edit ? commonFunctions.onChangeHandler : null}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={5}>
                                                        <Input
                                                            type="select"
                                                            name="credential_id"
                                                            list={state.credentials}
                                                            label="Select Credentials"
                                                            onChangeHandler={state.edit ? commonFunctions.onChangeHandler : null}
                                                            data={state.data}
                                                            error={state.error} />
                                                    </Grid>
                                                    <Grid item lg={2} style={{ alignSelf: 'center' }}>
                                                        {
                                                            <button className="btn btn-submit d-block" style={{ width: '100%' }} onClick={handleClickRedirect}>
                                                                {props.isSonar ? <>Add Token</> : <>Add Credential</>}
                                                            </button>
                                                        }
                                                        {/* <MaxWidthDialog refresh={fetchCredentials} /> */}
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing="2">
                                                    <Grid item lg={6}>
                                                        <Input
                                                            type="select"
                                                            label="Method"
                                                            name="method"
                                                            list={[{ label: "GET", id: "GET" }, { label: "POST", id: "POST" }]}
                                                            data={state.data}
                                                            error={state.error}
                                                            onChangeHandler={state.edit ? commonFunctions.onChangeHandler : null}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={6}>
                                                        <Input
                                                            type="text"
                                                            label="Timeout"
                                                            name="timeout"
                                                            placeholder="10"
                                                            data={state.data}
                                                            error={state.error}
                                                            onChangeHandler={state.edit ? commonFunctions.onChangeHandlerNumber : null}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={12}>
                                                        <Input
                                                            type="select"
                                                            label="Do you want to pause the pipeline execution once the integration URL is hit successfully?"
                                                            mandatorySign
                                                            name="stopped"
                                                            list={[{ label: 'True', id: 1 }, { label: 'False', id: 2 }]}
                                                            data={state.data}
                                                            error={state.error}
                                                            onChangeHandler={state.edit ? commonFunctions.onChangeHandler : null}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={12} className="textarea-resize-vertical-only">
                                                        <Input
                                                            type="textarea"
                                                            label="JSON Header"
                                                            name="headers_json"
                                                            placeholder="{'key':'value'}"
                                                            data={state.data}
                                                            error={state.error}
                                                            onChangeHandler={state.edit ? commonFunctions.onChangeHandler : null}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={12} className="textarea-resize-vertical-only">
                                                        <Input
                                                            type="textarea"
                                                            label="JSON Body"
                                                            name="key_value_pair"
                                                            placeholder="{'key':'value'}"
                                                            data={state.data}
                                                            error={state.error}
                                                            onChangeHandler={state.edit ? commonFunctions.onChangeHandler : null}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={12} className={state.edit ? "footer-card border-top" : "footer-card h-0"}>
                                                        <div className='d-flex align-center space-between  pd-10'>
                                                            <button className="btn btn-outline-grey" onClick={handleClickEdit} >Cancel</button>
                                                            <button className="btn btn-submit" onClick={validateAndSave}>Save</button>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </>
                                }

                            </>
                            :
                            <ErrorBox
                                message="Integrations testing Configuration "
                                handleClick={handleClickToggle}
                            />

                    }

                </div>

            </div >
        </>
    )
}

IntegrationsTesting.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export default IntegrationsTesting;

const ErrorBox = props => {
    return (
        <div className="d-flex align-center justify-center" style={{ height: 400, width: '100%' }}>
            <div
                className="text-center alert alert-primary border-style-dashed bg-white"
                style={{ width: '400px', marginBottom: '20px' }}>
                <div className="text-box">
                    <p className="main-text  font-14">{props.message}</p>
                    <button className="btn btn-submit" onClick={() => { props.handleClick() }}>Add Integration</button>
                </div>
            </div>
        </div>
    )
}
ErrorBox.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '0px 0px 8px 8px'
    }
}));