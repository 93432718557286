import React from 'react';
import PropTypes from 'prop-types';


const Footer = props => {

  return (
    <div>
      
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
