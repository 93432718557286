import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Link } from 'react-router-dom';
import properties from '../../properties/properties';
import GenerateURL, { GenerateSearchURL } from '../../util/APIUrlProvider';
import InvokeApi from '../../util/apiInvoker';
import { ErrorComponent } from '../../views/utils/Error';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Tooltip, Zoom } from '@material-ui/core';
// import ViewLogsInfiniteScroll1, { getNewLogTab } from '../../views/service/listing/components/ViewLogsInfiniteScroll1';
import RunningDotsComponent from '../../views/service/listing/components/componentLogsViewer/RunningDotsComponent';
const StepProgressDetailed = props => {

    const classes = useStyles();
    const newTypeClases = newTypeStyles();
    const placeholders = props.placeholders;
    const [progress] = React.useState(0);
    const [state, setState] = useState({
        activity_master: {},
        sub_task_status: [],
        status: "",
        activity_obj: {

        },
        history_status: "SUCCESS",
        showLoadingIcon: false,
        history_list: [],
        error: false,
        selected_tab: 1,
        logs: "",
        task_id: "",
        infiniteScrollInherits: {},
        endPoint: GenerateURL({ service_id: props.service_id, env_id: props.env_id }, properties.api.env_build_request_url)
    });
    let timer = null;
    const triggerBuildDeploy = props.triggerBuildDeploy;
    const task_id = props.task_id;
    const type = props.type;
    const hintText = props.hintText ? props.hintText : "Click on finish to complete the Setup";
    const btnLabel = props.btnLabel ? props.btnLabel : null;
    const skipButtonRequired = props.skipButtonRequired ? props.skipButtonRequired : false;
    const handleClickToSkip = props.handleClickToSkip ? props.handleClickToSkip : ()=>{}
    const [showProgressBar, setShowProgressBar] = useState(true);
    console.log("propsmdskm", props);
    React.useEffect(() => {
        if (task_id) {
            fetchActivityStatus();
        }
        return () => clearTimeout(timer);
    }, [task_id]);


    React.useEffect(() => {
        fetchActivitiesInfo();
    }, [])

    React.useEffect(() => {
        getTriggeredService();
    }, [state.activity_list])

    function getTriggeredService() {
        let selected_service;
        state.activity_list?.forEach(element => {
            if (element.activity_token == task_id) {
                selected_service = element;
            }
        });

        setState((new_state) => ({
            ...new_state,
            triggered_service: selected_service
        }))

        console.log(selected_service, "sjvcxdzgcvsdjcvgds")
    }

    function fetchActivitiesInfo(data, url) {

        let requestInfo = {
            endPoint: GenerateURL({}, properties.api.get_activities_details),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        if (data) {
            requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
        }
        if (url) {
            requestInfo.endPoint = url;
        }
        setState(new_state => ({
            ...new_state,
            search_data: data,
            current: requestInfo.endPoint,
        }));
        InvokeApi(requestInfo, ActivityInfoFetchSuccess, ActivityInfoFetchFailure);
    }

    function ActivityInfoFetchSuccess(response) {
        console.log(response, "+++++>>>")
        setState(new_state => ({
            ...new_state,
            activity_list: response.results,
            activity_id: response.id,
            count: response.count,
            next: response.next,
            previous: response.previous,
        }));
    }

    function ActivityInfoFetchFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            error: true
        }));
    }


    console.log("aaaaaaa", task_id)
    function fetchActivityStatus() {
        let url_temp = GenerateURL({}, properties.api.project_status);
        console.log("props", props)
        let requestInfo = {
            endPoint: GenerateSearchURL({ task_id: task_id }, url_temp),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, onStatusFetchSuccess, onStatusFetchFail)
    }

    function onStatusFetchSuccess(response) {
        console.log("response_status-djis", response);
        const all_activities = properties.activities;
        const activityCode = response.activity_master.code.toLowerCase();
        const activityObj = all_activities[activityCode];
        setState(new_state => ({
            ...new_state,
            response: response,
            loaded: false,
            activity_master: response.activity_master,
            sub_task_status: response.sub_task_status,
            status: response.status,
            description: response.description,
            activity_obj: activityObj
        })
        )
        timer = setTimeout(() => {
            if (response.status == "RUNNING" || response.status == "IN_QUEUE") {
                fetchActivityStatus();
            }
            else {
                setShowProgressBar(false);
            }

            // if(viewLogs){
            //     fetchLogs();
            // }
        }, 2000)

    }

    console.log(state, "sbvnxdvxnvxnvnbf")

    function onStatusFetchFail(response) {
        setState(new_state => ({
            ...new_state,
            error: JSON.stringify(response)
        })
        )
    }
    const handleRefresh = () => {
        // by calling this method react re-renders the component

        props.refresh();
        //window.location.reload(false);
    };
    const success_page = state.activity_obj?.success_page ? GenerateURL(placeholders ? placeholders : {}, state.activity_obj?.success_page, true) : "";
    //const fail_page = state.activity_obj?.fail_page ? GenerateURL(placeholders ? placeholders : {}, state.activity_obj?.fail_page, true) : "";
    console.log("success", success_page)


    //const [viewLogs, setViewLogs] = useState(false);

    // function closeLogs() {
    //     setViewLogs(false);
    // }

    // function handleSuccessApiHit(data) {
    //     setViewLogs(true);
    //     console.log("hello data898908900898908989", data)
    //     setState(new_state => ({
    //         ...new_state,
    //         logs: data.results
    //     }))
    //     console.log(state.logs, "helolo")
    // }

    // function handleFailedApiHit1(error) {
    //     setViewLogs(true);
    //     setState(new_state => ({
    //         ...new_state,
    //         logs: ["error loading logs data"]
    //     }))


    // }

    // function fetchLogs(data, url) {

    //     let requestInfo = {
    //         endPoint: GenerateURL({}, properties.api.get_backup_logs),
    //         httpMethod: "GET",
    //         httpHeaders: { "Content-Type": "application/json" }
    //     }

    //     requestInfo.endPoint = GenerateSearchURL({ task_id: task_id ? task_id : "" }, requestInfo.endPoint)

    //     InvokeApi(requestInfo, handleSuccessApiHit, handleFailedApiHit1, false);


    // }


    function changeTab(newTabId) {
        setState(new_state => ({
            ...new_state,
            selected_tab_id: newTabId
        }))
    }
    function closeLogs() {
        setState(new_state => ({
            ...new_state,
            view_logs: false,
            logMetadata: {}
        }))
    }

    function closeTab(tabId) {
        let response = state.infiniteScrollInherits.closeTabFunction(tabId, state.logMetadata, closeLogs)
        if (response) {
            setState(new_state => ({
                ...new_state,
                logMetadata: response.new_tabs_data,
                selected_tab_id: response.selected_tab_id
            }))
        }
    }
    console.log("bndjdbns", state);
    return (
        type == "new" ?
            <div className={classes.root}>
                <div className={newTypeClases.root}>
                    {
                        state.error ?
                            <ErrorComponent error={state.error} /> : null
                    }


                    <div className={newTypeClases.cardBody}>
                        <div className="d-flex justify-end width-full">
                            {/* {
                        state.status == "SUCCESS" ?
                            <div></div> :
                            <button className="btn btn-transparent" onClick={handleRefresh}>
                                <ChevronLeftIcon /> Back
                            </button>
                    } */}

                            {
                                props.variant == "SAME_PAGE_REDIRECT" ?
                                    <button className="btn btn-transparent" onClick={handleRefresh}>
                                        {/* Skip <ChevronRightIcon /> */}
                                        <span className='ri-close-fill font-20 color-value'></span>
                                    </button>
                                    :
                                    <Link to={success_page}>  <button className="btn btn-transparent">
                                        <span className='ri-close-fill font-20 color-value'></span>
                                    </button></Link>
                            }

                        </div>


                        {
                            showProgressBar || state.status == "RUNNING" || state.status == "IN_QUEUE" ?
                                <div className="main-text">
                                    {state.activity_obj?.header}
                                </div> : null
                        }

                        {
                            showProgressBar || state.status == "RUNNING" || state.status == "IN_QUEUE" ?
                                <>
                                    <div className="hint-text">
                                        Please wait this might take some time..
                                    </div>
                                    <div className="linear-progressbar mt-16">
                                        <LinearProgress variant={showProgressBar || state.status == "RUNNING" || state.status == "IN_QUEUE" ? "indeterminate" : "determinate"} value={progress} />
                                    </div>
                                </> :

                                <>
                                    {
                                        state.status == "FAILED" ?
                                            <>
                                                <div className="main-text">
                                                    {state.activity_obj?.fail_message}
                                                </div>
                                                <Tooltip title={state.description}>
                                                <div className="isa_info custom-box-new border-danger" style={{ marginTop: '30px', margin: 'auto', width : "100% !important",display : "flex", alignItems : "center", justifyContent : "center"  }}>
                                                        <span className="text-ellipsis-multiline color-E1941D" style={{ overflow: 'hidden', width : "70%"}} >{state.description}</span>
                                                    </div>
                                                </Tooltip>

                                            </> : null
                                    }
                                    {
                                        state.status == "SUCCESS" ?
                                            <>
                                                {/* <div className={classes.mainMsg}>
                                            {state?.activity_obj?.success_message}
                                        </div> */}
                                                <div className="main-text">
                                                    {state?.activity_obj?.success_message}
                                                </div>
                                                <div className="hint-text">
                                                    {
                                                        hintText 
                                                    }
                                                </div>
                                            </> : null
                                    }
                                </>
                        }


                        <div className={newTypeClases.contentbox}>
                            <div className='msg-box'>
                                {
                                    state.sub_task_status.map((task, index) => (
                                        <div className={"msg-div"} style={index == state.sub_task_status.length - 1 ? { borderBottom: "none" } : {}}>
                                            <div className='d-flex align-center' style={{ gap: "12px" }}>
                                                <div className={task.status == "SUCCESS" ? "success" : task.status == "FAILED" ? "failed" : "running"}>
                                                    {task.status == "SUCCESS" ? <span className="ri-check-fill font-16"></span>
                                                        : null}
                                                    {task.status == "FAILED" ? <span className="ri-error-warning-line color-E1941D font-16"></span>
                                                        : null}
                                                    {task.status == "RUNNING" || task.status == "IN_QUEUE" ? <span className="ri-settings-3-line font-16"></span>
                                                        : null}
                                                </div>
                                                <Tooltip title={task.activity_sub_task} TransitionComponent={Zoom}>
                                                    <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{task.activity_sub_task}</span>
                                                </Tooltip>

                                            </div>
                                            <>
                                                {task.status == "SUCCESS" ? <span className="color-success font-12 font-weight-600">Success</span>
                                                    : null}
                                                {task.status == "FAILED" ? <span className="color-danger font-12 font-weight-600">Failed</span>
                                                    : null}
                                                {task.status == "RUNNING" || task.status == "IN_QUEUE" ? <RunningDotsComponent isFromEventSidePannel={true} />
                                                    : null}
                                            </>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>


                    </div>
                    {showProgressBar ?
                        <div className={newTypeClases.cardFooter}>
                            <div className="btn-section d-flex align-center">
                            {
                                    skipButtonRequired ? 
                                    <button className='btn btn-outline-primary' onClick={handleClickToSkip}>Skip</button> : null
                                }
                                <a href={"/logs?global_task_id=" + task_id + "&num=" + state.triggered_service?.id + "&service_name=" + state.triggered_service?.entity_detail?.component?.name + "&service_env=" + state.triggered_service?.entity_detail?.component_env?.name} target="_blank" className="view-log-btn font-12 avtar">View Logs</a>
                            </div>
                        </div>
                        :
                        <>
                            {state.status == "SUCCESS" ?
                                <div className={newTypeClases.cardFooter}>
                                    {
                                        props.logs_on_new_tab ?
                                            <a href={"/logs?global_task_id=" + task_id + "&num=" + state.triggered_service?.id + "&service_name=" + state.triggered_service?.entity_detail?.component?.name + "&service_env=" + state.triggered_service?.entity_detail?.component_env?.name} target="_blank" className="view-log-btn font-12 avtar">View Logs</a> :
                                            <a href={"/logs?global_task_id=" + task_id + "&num=" + state.triggered_service?.id + "&service_name=" + state.triggered_service?.entity_detail?.component?.name + "&service_env=" + state.triggered_service?.entity_detail?.component_env?.name} target="_blank" className="view-log-btn font-12 avtar">View Logs</a>
                                        // <button className="btn btn-outline-default" onClick={()=>{onLog(state.activity_id,task_id,state.activity_master.activity_name+state.activity_id)}}>View Logs</button>
                                    }

                                    {
                                        props.variant == "SAME_PAGE_REDIRECT" ?
                                            <button className="btn btn-primary avtar font-weight-600" style={{ height: "40px" }} onClick={handleRefresh}>{btnLabel ? btnLabel : "Finish Setup"}</button>
                                            :
                                            <Link to={success_page}> <button className="btn btn-primary font-weight-600 avtar" style={{ height: "40px" }}>{btnLabel ? btnLabel : "Finish Setup"}</button></Link>
                                    }


                                </div> : null
                            }
                            {state.status == "FAILED" ?
                                <div className={newTypeClases.cardFooter}>
                                    <div className="d-flex" style={{gap : "12px"}}>
                                        <a href={"/logs?global_task_id=" + task_id + "&num=" + state.triggered_service?.id + "&service_name=" + state.triggered_service?.entity_detail?.component?.name + "&service_env=" + state.triggered_service?.entity_detail?.component_env?.name} target="_blank" className="view-log-btn font-12 avtar font-weight-600">View Logs</a>
                                        {/* <button className="btn btn-outline-default" onClick={fetchLogs}>View Logs</button> */}
                                        {
                                            props.variant == "cluster_onboarding" ?
                                                <button className="re-configure-btn font-12  font-weight-500 avtar" onClick={handleRefresh}>Re-configure</button>
                                                :
                                                <button onClick={triggerBuildDeploy} className="btn btn-primary">Retry</button> //111

                                        }
                                    </div>
                                    { state.sub_task_status[2]?.status === "FAILED" ?
                                  
                                        <Link to={{
                                            pathname: "/cluster-permissions",
                                            state: {
                                                cluster_id: state.triggered_service ? state.triggered_service.entity_id : null,
                                                task_id: task_id,
                                            }

                                        }}>
                                            <button className={"view-permisson-btn font-12 avtar "}>
                                                <span className=' font-weight-600'>
                                                    view permission
                                                </span>
                                            </button>
                                        </Link>: null
                                    }

                                </div> : null
                            }

                        </>
                    }
                    {/* {state.view_logs ? <ViewLogsInfiniteScroll1 selected_tab_id={state.selected_tab_id} closeLogs={closeLogs} closeTab={closeTab} changeTab={changeTab} tabsData={state.logMetadata} childInherits={state.infiniteScrollInherits} /> : null} */}


                </div>
            </div>
            :
            <div className={classes.root + " " + "manual-height"}>
                <div className={classes.card}>
                    {
                        state.error ?
                            <Error error={state.error} /> : null
                    }


                    <div className={classes.cardBody}>
                        <div className="d-flex space-between mb-50 mt-20">
                            {
                                state.status == "SUCCESS" ?
                                    <div></div> :
                                    <button className="btn btn-transparent" onClick={handleRefresh}>
                                        <ChevronLeftIcon /> Back
                                    </button>
                            }

                            {
                                props.variant == "SAME_PAGE_REDIRECT" ?
                                    <button className="btn btn-transparent" onClick={handleRefresh}>
                                        Skip <ChevronRightIcon />
                                    </button>
                                    :
                                    <Link to={success_page}>  <button className="btn btn-transparent">
                                        Skip <ChevronRightIcon />
                                    </button></Link>
                            }

                        </div>


                        {
                            showProgressBar || state.status == "RUNNING" || state.status == "IN_QUEUE" ?
                                <div className="main-text">
                                    {state.activity_obj?.header}
                                </div> : null
                        }


                        {
                            showProgressBar || state.status == "RUNNING" || state.status == "IN_QUEUE" ?
                                <>
                                    <div className="hint-text">
                                        Please wait this might take some time..
                                    </div>
                                    <div className="linear-progressbar mt-20 mb-50">
                                        <LinearProgress variant={showProgressBar || state.status == "RUNNING" || state.status == "IN_QUEUE" ? "indeterminate" : "determinate"} value={progress} />
                                    </div>
                                </> :

                                <>

                                    {
                                        state.status == "FAILED" ?
                                            <>
                                                <div className={classes.mainMsg} style={{ marginTop: '30px' }}>
                                                    {state.activity_obj.fail_message}
                                                </div>
                                                <Tooltip title={state.description}>
                                                    <div className="isa_info custom-box-new border-danger" style={{ marginTop: '30px', margin: 'auto', width : "100% !important",display : "flex", alignItems : "center", justifyContent : "center"  }}>
                                                        <span className="text-ellipsis-multiline color-E1941D" style={{ overflow: 'hidden', width : "70%", }} >{state.description}</span>
                                                    </div>
                                                </Tooltip>

                                            </> : null
                                    }
                                    {
                                        state.status == "SUCCESS" ?
                                            <>
                                                <div className={classes.mainMsg} style={{ marginTop: '30px' }}>
                                                    {state?.activity_obj?.success_message}
                                                </div>
                                            </> : null
                                    }
                                </>
                        }


                        <div className={classes.contentbox}>

                            {
                                state.sub_task_status.map(task => (
                                    <div className="msg-div">
                                        <div className="icon-circle success">
                                            {task.status == "SUCCESS" ? <span className="flaticon-check-symbol"></span>
                                                : null}
                                            {task.status == "FAILED" ? <span className=" ri-error-warning-line font-24"></span>
                                                : null}
                                            {task.status == "RUNNING" || task.status == "IN_QUEUE" ? <span className="flaticon-clock-with-white-face"></span>
                                                : null}
                                        </div>
                                        <Tooltip title={task.activity_sub_task} TransitionComponent={Zoom}>
                                            <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{task.activity_sub_task}</span>
                                        </Tooltip>
                                        <>
                                            {task.status == "SUCCESS" ? <span className="color-success">Success</span>
                                                : null}
                                            {task.status == "FAILED" ? <span className="color-danger">Failed</span>
                                                : null}
                                            {task.status == "RUNNING" || task.status == "IN_QUEUE" ? <span className="color-pending">Running</span>
                                                : null}
                                        </>
                                    </div>
                                ))
                            }

                        </div>


                    </div>
                    {showProgressBar ?
                        <div className={classes.cardFooter}>
                            <div className="btn-section">
                                {
                                    skipButtonRequired ? 
                                    <button className='btn btn-outline-primary' onClick={handleRefresh}>Skip</button> : null
                                }
                                <a href={"/logs?global_task_id=" + task_id + "&num=" + state.triggered_service?.id + "&service_name=" + state.triggered_service?.entity_detail?.component?.name + "&service_env=" + state.triggered_service?.entity_detail?.component_env?.name} target="_blank" className="btn btn-outline-default">View Logs</a>
                            </div>
                        </div>
                        :
                        <>
                            {state.status == "SUCCESS" ?
                                <div className={classes.cardFooter}>
                                    <div className="btn-section">
                                        {
                                            props.logs_on_new_tab ?
                                                <a href={"/logs?global_task_id=" + task_id + "&num=" + state.triggered_service?.id + "&service_name=" + state.triggered_service?.entity_detail?.component?.name + "&service_env=" + state.triggered_service?.entity_detail?.component_env?.name} target="_blank" className="btn btn-outline-default">View Logs</a> :
                                                <a href={"/logs?global_task_id=" + task_id + "&num=" + state.triggered_service?.id + "&service_name=" + state.triggered_service?.entity_detail?.component?.name + "&service_env=" + state.triggered_service?.entity_detail?.component_env?.name} target="_blank" className="btn btn-outline-default">View Logs</a>
                                            // <button className="btn btn-outline-default" onClick={()=>{onLog(state.activity_id,task_id,state.activity_master.activity_name+state.activity_id)}}>View Logs</button>
                                        }

                                        {
                                            props.variant == "SAME_PAGE_REDIRECT" ?
                                                <button className="btn btn-primary" onClick={handleRefresh}>Continue</button>
                                                :
                                                <Link to={success_page}> <button className="btn btn-primary">Continue</button></Link>
                                        }

                                    </div>
                                </div> : null
                            }
                            {state.status == "FAILED" ?
                                <div className={classes.cardFooter}>
                                    <div className="btn-section">
                                        {
                                            props.variant == "SAME_PAGE_REDIRECT" ?
                                                <button className="btn btn-outline-default" onClick={handleRefresh}>Continue</button>
                                                :
                                                <Link to={success_page}> <button className="btn btn-outline-default">Continue</button></Link>
                                        }

                                        <div className="d-flex">
                                            <a href={"/logs?global_task_id=" + task_id + "&num=" + state.triggered_service?.id + "&service_name=" + state.triggered_service?.entity_detail?.component?.name + "&service_env=" + state.triggered_service?.entity_detail?.component_env?.name} target="_blank" className="btn btn-outline-default">View Logs</a>
                                            {/* <button className="btn btn-outline-default" onClick={fetchLogs}>View Logs</button> */}
                                            {
                                                props.variant == "cluster_onboarding" ?
                                                    <button className="btn btn-primary" onClick={handleRefresh}>Retry</button>
                                                    :
                                                    <button onClick={triggerBuildDeploy} className="btn btn-primary">Retry</button>

                                            }
                                        </div>

                                    </div>
                                </div> : null
                            }


                        </>

                    }
                    {/* {state.view_logs ? <ViewLogsInfiniteScroll1 selected_tab_id={state.selected_tab_id} closeLogs={closeLogs} closeTab={closeTab} changeTab={changeTab} tabsData={state.logMetadata} childInherits={state.infiniteScrollInherits} /> : null} */}

                </div>
            </div>


    )
}

StepProgressDetailed.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default StepProgressDetailed;


const useStyles = makeStyles(theme => ({
    root: {
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        position: 'relative'
    },
    card: {
        borderRadius: '4px',
        border: '1px solid #e4e4e4',
        overflow: 'hidden',
        width: '65rem',
        margin: '16rem auto',
        background: '#fff',
        '& #viewFullLogs': {
            left: '0px',
            bottom: '-10rem'
        }
    },
    cardHeader: {
        borderBottom: '1px solid #e4e4e4',
        padding: '15px',
        backgroundColor: '#f9f9f9',
        fontSize: '16px',
    },
    cardBody: {
        '& .main-text': {
            fontSize: '32px',
            textAlign: 'center',
            fontWeight: 300,
            color: '#000'
        },
        '& .linear-progressbar': {
            padding: '0px 110px',
            '& .MuiLinearProgress-root': {
                height: '7px'
            },
            '& .MuiLinearProgress-colorPrimary': {
                backgroundColor: '#eaeaea',
                borderRadius: '4px'

            },
            '& .MuiLinearProgress-barColorPrimary': {
                backgroundColor: '#0db79b'
            }
        },
        '& .hint-text': {
            textAlign: 'center',
            display: 'block',
            marginBottom: '10px',
            fontSize: '12px'
        },
        '& .border-danger': {
            border: '2px solid #ff5252',
            color: '#ff5252',
            backgroundColor: '#ffe9e9'
        }
    },
    contentbox: {
        margin: '20px auto 0px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        borderTop: '1px solid #dedede',
        '& .msg-div': {
            display: 'grid',
            gridTemplateColumns: '40px 220px 80px',
            alignItems: 'center',
            fontSize: '13px',
            borderBottom: '1px solid #dedede',
            width: '100%',
            padding: '10px',
            justifyContent: 'center',
            '&:last-child': {
                borderBottom: 'none'
            }
        },
        '& .icon-circle': {
            height: '30px',
            width: '30px',
            margin: '0px 5px',
            border: '2px solid transparent',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& .flaticon-check-symbol': {
                color: '#0db79b',
                lineHeight: 1,
                '&:before': {
                    fontSize: '18px'
                }
            },
            '& .fa': {
                color: '#ff5252',
                lineHeight: 1,
                '&:before': {
                    fontSize: '22px'
                }
            },
            '& .flaticon-clock-with-white-face': {
                color: '#ffc002',
                lineHeight: 1,
                '&:before': {
                    fontSize: '22px'
                }
            },
            '&.success': {
                borderColor: 'transparent'
            },
            '&.danger': {
                borderColor: 'transparent',
                backgroundColor: 'transparent'
            },
            '&.pending': {
                color: '#ffc002',
                backgroundColor: 'transparent',
            }
        },
    },
    cardFooter: {
        borderTop: '1px solid #e4e4e4',
        padding: '15px',
        backgroundColor: '#f9f9f9',
        '& .btn-section': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '& .btn-primary': {
                backgroundColor: '#0db79b',
                color: '#fff',
                '& .flaticon-keyboard-right-arrow-button-1': {
                    color: '#fff',
                    '&:before': {
                        marginLeft: '0px!important'
                    }
                }
            },
            '& .btn-outline-default': {
                color: '#6c757d',
                border: '1px solid #cbcbcb!important',
                backgroundColor: '#f9f9f9',
                '&:hover': {
                    color: '#6c757d!important',
                    backgroundColor: '#f1f1f1',
                }
            }
        }
    },
    mainMsg: {
        fontSize: '24px',
        padding: '20px 0px',
        fontWeight: 300,
        lineHeight: 1.2,
        textAlign: 'center'
    },

}))

const newTypeStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        width: "655px",
        flexDirection: "column",
        alignItems: "flex-start",
        borderRadius: "8px",
        border: "1px solid #DFDFDF",
        background: "#FFF",
    },
    cardBody: {
        display: "flex",
        paddingTop: "12px",
        flexDirection: "column",
        alignItems: "center",
        alignSelf: "stretch",
        "& .main-text": {
            fontSize: "18px",
            fontWeight: "500",
            color: "#124D9B",
        },
        '& .hint-text': {
            fontSize: "14px",
            fontWeight: "400",
            color: "#2F2F2F",
        },
        '& .linear-progressbar': {
            padding: '0px 110px',
            width: "100%",
            '& .MuiLinearProgress-root': {
                height: '7px'
            },
            '& .MuiLinearProgress-colorPrimary': {
                backgroundColor: '#eaeaea',
                borderRadius: '4px'

            },
            '& .MuiLinearProgress-barColorPrimary': {
                backgroundColor: '#0086FF'
            }
        },
    },
    contentbox: {
        display: "flex",
        width: "100%",
        padding: "24px 20px",
        flexDirection: "column",
        alignItems: "center",
        gap: "16px",
        alignSelf: "stretch",
        '& .view-log-btn': {
            display: "flex",
            height: "28px",
            padding: "8px 16px",
            justifyContent: "center",
            alignItems: "center",
            gap: "6px",
            borderRadius: "6px",
            border: "1px solid #9DC0EE",
            background: "#FFF",
            color: "#0086FF",
            '&:hover': {
                background: "#0086FF",
                color: "#FFF",
            },
        },
    
        '& .msg-box': {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignSelf: "stretch",
            borderRadius: "10px",
            border: "1px solid #E6E6E6",
        },
        '& .first-msg-div': {

        },
        '& .last-msg-div': {

        },
        '& .msg-div': {
            display: "flex",
            padding: "10px 24px",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
            alignSelf: "stretch",
            borderBottom: "1px solid #E6E6E6",
            '& .success': {
                display: "flex",
                padding: "4px",
                alignItems: "center",
                gap: "10px",
                borderRadius: "50%",
                background: "#E6FBEA",
                color: "#129E5B",
                height: "26px",
            },
            '& .failed': {
                display: "flex",
                padding: "4px",
                alignItems: "center",
                gap: "10px",
                borderRadius: "50%",
                background: "#FCF6E1",
                color: "#C11212",
                height: "26px",
            },
            '& .running': {
                display: "flex",
                padding: "4px",
                alignItems: "center",
                gap: "10px",
                borderRadius: "50%",
                background: "#EBF5FF",
                color: "#0086FF",
                height: "26px",
            },
            '& .color-success': {
                display: "flex",
                padding: "5px 8px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                borderRadius: "6px",
                background: "#E6FBEA",
                color: "#129E5B",
            },
            '& .color-danger': {
                display: "flex",
                padding: "5px 8px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                borderRadius: "6px",
                background: "#FCF6E1",
                color: "#E1941D",
            }
        },
    },
    cardFooter: {
        display: "flex",
        padding: "16px",
        justifyContent: "flex-end",
        alignItems: "center",
        alignSelf: "stretch",
        background: "#FAFAFA",
        borderRadius: "8px",
        gap: "12px",
        '& .view-log-btn': {
            display: "flex",
            height: "40px",
            padding: "8px 16px",
            justifyContent: "center",
            alignItems: "center",
            gap: "6px",
            borderRadius: "6px",
            border: "1px solid #9DC0EE",
            background: "#FFF",
            color: "#0086FF",
            fontSize : "12px",
            '&:hover': {
                background: "#0086FF",
                color: "#FFF",
            }
        },
        '& .re-configure-btn': {
            display: "flex",
            height: "40px",
            padding: "11px 16px",
            justifyContent: "center",
            alignItems: "center",
            gap: "6px",
            borderRadius: "6px",
            background: "#FEA111",
            color: "#FFFFFF",
            fontSize: "12px",
            border: "none",
            marginLeft: "12px",
        },
        "& .view-permisson-btn" : {
            border : "none",
            display: "flex",
            height: "40px",
            padding: "8px 16px",
            justifyContent: "center",
            alignItems: "center",
            gap: "6px",
            borderRadius: "6px",
            background: "#0086FF",
            color: "#FFF",
            '&:hover': {
                background: "#03234D",
                color: "#FFF",
            },
        },
    }
}))