import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { ExpandableServiceList, ExpandableTagList } from '../../../../components/hoc/expandableList';

import SendForApprovalConfirmationDialog from '../../../service/detail/components/auditHistory/SendForApprovalConfirmationDialog';
import { makeStyles } from '@material-ui/core/styles';

const MainDiv = styled('div')({
    display: 'flex',
    alignItems: 'center',
    padding: '15px 0px 10px 0'

})

const ButtonNavigator = styled('div')({
    display: 'flex',

    alignItems: 'center',
    '& .btn-blue .flaticon-play-button-inside-a-circle': {
        color: '#fff',
        margin: '0px 5px'
    },
    '& .btn-blue .flaticon-play-button-inside-a-circle:before': {
        fontSize: '16px!important'
    },
    '& .btn-blue': {
        height: 'auto',
        paddingTop: '0px',
        paddingBottom: '0px',
        paddingLeft: '5px'
    }
})

const CardFooter = (props) => {
    const pipeline = props.pipeline
    console.log(pipeline, "rtyufgb ===============>")
    const services = getServiceList(bringParticipatinServiceToTop(props.components));
    console.log(services, "Fdsafsafsa")
    const tags = getTagList(props.tags);
    const classes = useStyles();

    function bringParticipatinServiceToTop(components) {

        console.log(components, "lfadsfas")
        var participating_services = []
        var non_participating_services = []
        if (components) {
            components.forEach(component => {
                if (component.part_of_execution) {
                    participating_services.push(component)
                } else {
                    non_participating_services.push(component)
                }
            })
        }
        participating_services = [...participating_services, ...non_participating_services]

        return participating_services
    }

    return (
        <MainDiv>
            <div className="pipeline-service-data">
                <p className="main-text-dark mb-5">Services for the pipeline to run on</p>
                <div className="service-name-chip-div">
                    <ExpandableServiceList iteration_count={4} list={services} />
                </div>
            </div>
            {tags.length > 0 ?
                <div className="pipeline-tags-data">
                    <p className="main-text-dark mb-5">Tags</p>
                    <div className="service-name-chip-div">
                        <ExpandableTagList iteration_count={4} list={tags} />
                    </div>
                </div>
                : null}
            {
                pipeline && pipeline.status &&
                <div className={classes.rootCard}>
                    <p>Status :<span className={pipeline.status == "DRAFT" || pipeline.status == "DRAFT_PENDING" ? "font-10 chip chip-yellow ml-5" : pipeline.status == "APPROVAL_REQUESTED_PENDING" || pipeline.status == "APPROVAL_REQUESTED" ? "font-10 chip chip-dark-grey ml-5" : "font-10 chip chip-green ml-5"}>{pipeline.status == "DRAFT" ? "IN DRAFT" : pipeline.status == "DRAFT_PENDING" ? "Sending for Draft" : pipeline.status == "APPROVAL_REQUESTED_PENDING" ? "Sending for Approval" : pipeline.status == "APPROVAL_REQUESTED" ? "APPROVAL_REQUESTED" : pipeline.status == "APPROVED_PENDING" ? "Approval Pending" : "IN USE"}</span></p>
                    {/* {pipeline.status == "DRAFT" || pipeline.status == "DRAFT_PENDING" ? <div style={{ marginLeft: "10px" }}><SendForApprovalConfirmationDialog audit_card_data={pipeline} sendForApproval={props.sendForApproval} /> </div>  : pipeline.status == "APPROVAL_REQUESTED_PENDING" || pipeline.status == "APPROVAL_REQUESTED" ? <div style={{ marginLeft: "10px" }}><SendForApprovalConfirmationDialog audit_card_data={pipeline} sendForApproval={props.sendForApproval} confirmApproval= "true" confirmForApproval={props.confirmForApproval} /> </div> : ""} */}
                    {pipeline.status == "DRAFT" || pipeline.status == "APPROVAL_REQUESTED" ? <div style={{ marginLeft: "10px" }}><SendForApprovalConfirmationDialog audit_card_data={pipeline} sendForApproval={props.sendForApproval} confirmForApproval={props.confirmForApproval} confirmApproval={pipeline.status == "DRAFT" || pipeline.status == "DRAFT_PENDING" ? false : true} /> </div> : <></>}
                </div>
            }
        </MainDiv>
    )
}


CardFooter.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }


export default CardFooter;

function getServiceList(services) {
    console.log(services, "Fasfdas")
    var service_list = [];
    services.forEach(service => {
        service_list.push({ name: service.name, part_of_execution: service.part_of_execution });
    });
    return service_list;
}

function getTagList(tags) {
    var tag_list = [];
    if (tags) {
        tags.forEach(tag => {
            tag_list.push(tag.tag);
        });
    }
    return tag_list;
}

const useStyles = makeStyles((theme) => ({
    rootCard: {
        marginLeft: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        // width : "215px",

        "& p": {
            color: "#000",
            fontSize: "13px",
        },

        '& .chip': {
            padding: '3px!important',
            display: 'inline-block'
        },
        '& .chip-yellow': {
            borderRadius: '3px',
            backgroundColor: '#ffc000',
            color: '#fff',
        },
        '& .chip-dark-grey': {
            borderRadius: '3px',
            backgroundColor: '#656565',
            color: '#fff',
        },
        '& .chip-green': {
            borderRadius: '3px',
            backgroundColor: '#69df9b',
            color: '#fff',
        },
    }
}))
