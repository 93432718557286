import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Grid } from '@material-ui/core';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import { TrainRounded } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import MultiRow, { getMultiRowDefaultState } from '../../../../components/genericComponents/MultiRow';
import { Input } from '../../../../components/genericComponents/Input';
import AddIcon from '@material-ui/icons/Add';
import { ValidateDataSet } from '../../../../util/Validator';
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import properties from '../../../../properties/properties';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Loading } from '../../../utils/Loading';

const AdditionalFieldsDialogAdvance = props => {
    const classes = useStyles();
    const inherits = props.inherits ? props.inherits : {};
    const custom_fields_key_value = props.custom_fields_key_value;
    const add_custom_fields = props.add_custom_fields;
    const issueType = props.issueType ? props.issueType : null;
    const default_job_template = props.default_job_template ? props.default_job_template : null;
    const jira_data_types = props.jira_data_types;
    const showAdditionalField = props.showAdditionalField;
    const flipAndOpenAdditionalFiledsForm = props.flipAndOpenAdditionalFiledsForm ? props.flipAndOpenAdditionalFiledsForm : () => { }
    const all_templates = props.all_templates;
    const handleClose = props.handleClose;
    const jobTemplateId = props.job_template_id;
    const [state, setState] = useState({
        open: false,
        fullWidth: true,
        maxWidth: 'md',
        data: {},
        error: {},
        jira_additional_fields: {},
        child_inherits: {
            custom_fileds: {
                "validateForm": () => { return { valid: true }; },
                "getState": () => { return {}; },
                "getData": () => { return {}; }
            }
        }
    })
    const commonFunctions = getCommonFunctions(state, setState, inherits);


    useEffect(() => {
        if (default_job_template && default_job_template.id && issueType) {
            fetchJiraIssueTypeList()

        }

    }, [default_job_template, issueType])

    function fetchJiraIssueTypeList() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.jira_additional_fields) + "?job_template_id=" + default_job_template.id + "&issue_type=" + issueType,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, fetchJiraIssueTypeListSuccess, fetchJiraIssueTypeListFailure);
        setState(new_state => ({
            ...new_state,
            loading_data: true
        }));
    }

    function fetchJiraIssueTypeListSuccess(response) {

        setState(new_state => ({
            ...new_state,
            jira_additional_fields: response.Addition_fields,
            loading_data: false
        }));

    }
    function fetchJiraIssueTypeListFailure(error) {
        var error_msg = error.toString()
        setState(new_state => ({
            ...new_state,
            error: error_msg,
            loading_data: false
        }));
    }


    const handleClickOpen = () => {
        setState(new_state => ({
            ...new_state,
            open: true,

        }));
    };

    const getTypeAndPushData = (data) => {
        var value = undefined;

        if (data) {
            if (typeof data === 'object') {
                if (Array.isArray(data) && data.length > 0 && typeof data[0] === 'object') {
                    // Check if it's an array of objects
                    console.log(data, "Array of objects");
                    let temp_data = data.map(item => {
                        return item.label
                    })
                    // You can process the array of objects here if needed.
                    value = temp_data;
                } else {
                    console.log(data, "Object");
                    // It's a single object
                    // You can process the object here if needed.
                    value = data;
                }
            } else if (typeof data === 'string') {
                console.log(data, "String");
                // It's a string
                // You can process the string here if needed.
                value = data;
            }
        }
        return value
    }

    function pushDataToArray() {
        var result = ValidateDataSet(state.data, state.validations);
        var children_vaidation_result = inherits.validateForm ? inherits.validateForm() : { valid: true };
        var child_states = inherits.getState ? inherits.getState() : null;
        var custom_fileds_data = child_states.custom_fileds;

        var totalCustomFields = [];
        if (custom_fileds_data) {
            Object.keys(custom_fileds_data).forEach(key => {
                if (key == "data" || key == "child_inherits" || key == "show_view" || key == "count") {

                } else {
                    console.log(custom_fileds_data[key].data, "custom_fields_key_value")
                    const custom_fields_key_value = custom_fileds_data[key].data;
                    custom_fields_key_value["over-ridable"] = custom_fileds_data[key].data["over-ridable"] ? true : false;
                    custom_fields_key_value["mandatory"] = custom_fileds_data[key].data["mandatory"] ? true : false;
                    custom_fields_key_value["value"] = getTypeAndPushData(custom_fileds_data[key].data["value"])
                    custom_fields_key_value["default_values"] = custom_fileds_data[key].data["default_values"]
                    console.log(custom_fields_key_value, "custom_fields_key_value")
                    totalCustomFields.push(custom_fields_key_value)


                }
            })
        }
        add_custom_fields(totalCustomFields);
        props.flipCloseAndRetainState();
    }
    function getMultiRowPreviousState() {
        var prev_state = null
        console.log(custom_fields_key_value, 'hggfbdbl');
        if (custom_fields_key_value && Array.isArray(custom_fields_key_value)) {
            prev_state = {
                data: {},
                child_inherits: {}
            }
            var count = 1

            custom_fields_key_value.forEach((item, key) => {

                prev_state[count] = {
                    ...getFromDefaultState(),
                    valuesList: [],
                    data: {
                        key: item.key,
                        value: typeof (item.value) == "string" ? item.value : typeof (item.value) == "object" ? item.value.map(sub_item => { return { id: sub_item, label: sub_item } }) : null,
                        "over-ridable": item["over-ridable"] ? true : false,
                        "mandatory": item["mandatory"] ? true : false,
                        default_values: item.default_values && item.default_values.length > 0 ? item.default_values : null,
                        id: item.id ? item.id : null,
                        data_type: item.data_type
                    }
                }
                prev_state.child_inherits[count] = {}
                count++
            })
        }
        return prev_state
    }

    console.log(state, 'ddsdsdnsdnsd)osk')
    return (
        <>{
            showAdditionalField &&
            <div className='additional_filed_cs' style={{ padding: '20px', backgroundColor: '#ededed', minHeight: 'calc(100% - 120px)', borderRadius: '5px', display: 'block' }}>
                <div style={{ backgroundColor: '#fff', borderTopLeftRadius: 'inherit', borderTopRightRadius: 'inherit' }}>
                    <div className={`d-flex align-center border-bottom space-between `} style={{ height: '50px', backgroundColor: '#fafafa', padding: '15px', borderTopLeftRadius: 'inherit', borderTopRightRadius: 'inherit' }}>
                        <label style={{ color: '#6d6a6a' }} className='font-14 mr-5'>Configure Additional Jira Fileds</label>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon style={{ color: 'rgb(109, 106, 106)' }} fontSize="large" />
                        </IconButton>
                    </div>
                    <div className={classes.root}>
                        <div className="pl-10 h-250 pr-10 " style={state.loading_data ? { minHeight: '128px', display: 'flex' } : null}>
                            {
                                state.loading_data ?
                                    <div className='m-auto'>
                                        <Loading varient="light" />
                                    </div> :
                                    <MultiRow
                                        show_view_component={false}
                                        RepeatableComponent={AdditionalField}
                                        prev_state={getMultiRowPreviousState()}
                                        HeaderComponent={HeaderSectionInitComands}
                                        inherits={state.child_inherits.custom_fileds}
                                        zeroRowsAllowed={true}
                                        repeatableComponentProps={{ input_list: state.jira_additional_fields, jira_data_types: jira_data_types, all_templates: all_templates, jobTemplateId: default_job_template }}
                                    />
                            }

                        </div>
                        {state.loading_data ?
                            null :
                            <div className="d-flex align-center justify-end pd-10 ">
                                <button className="btn btn-outline-grey" onClick={handleClose}>Cancel</button>
                                <button className="btn btn-submit" onClick={pushDataToArray}>Save</button>
                            </div>
                        }

                    </div>
                </div>
            </div>
        }
        </>
    )
}
export default AdditionalFieldsDialogAdvance;


function AdditionalField(props) {
    const inherits = props.inherits ? props.inherits : {};
    const prev_state = props.prev_state ? props.prev_state : null;
    console.log(prev_state, 'prev_state_23232')
    const [state, setState] = useState(prev_state ? prev_state : getFromDefaultState());
    console.log(state, "Fskjfnasfkjsajd")
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    const input_list = props.extraProps.input_list ? props.extraProps.input_list : [];
    const jira_data_types = props.extraProps.jira_data_types ? props.extraProps.jira_data_types : []
    const all_templates = props.extraProps.all_templates ? props.extraProps.all_templates : [];
    var input_labels = Object.keys(input_list);
    const jobTemplateId = props?.extraProps?.jobTemplateId?.id;



    useEffect(() => {
        if (prev_state) {
            if (input_labels && input_labels.length > 0) {
                if (prev_state.data && prev_state.data.key) {

                    console.log(input_labels, prev_state.data, input_list, "input_listinput_list")
                    var key_to_get = input_list[prev_state.data.key];
                    console.log(key_to_get, 'key_tedsdssd')
                    // var set_values_for_dropdown = key_to_get.values ? typeof(key_to_get.values) == "object" ? key_to_get.values.map(item => {return {id: item, label: item}}) : key_to_get.values ,
                    if (key_to_get?.type == 'group') {
                        getListForTypeGroup().then(valuesList => {
                            console.log(valuesList, 'jggndnd')
                            setState(new_state => ({
                                ...new_state,
                                input_type: key_to_get.type,
                                valuesList: valuesList ? valuesList.map(item => { return { id: item, label: item } }) : []
                            }))
                        })
                            .catch(error => {
                            })
                    }
                    if (key_to_get != undefined) {
                        setState(new_state => ({
                            ...new_state,
                            input_type: key_to_get.type,
                            valuesList: key_to_get.default_values && typeof (key_to_get.default_values) == "object" ? key_to_get.default_values.map(item => { return { id: item, label: item } }) : null,
                        }))
                    }
                }
            }
        }
    }, [prev_state])

    function getListForTypeGroup() {
        return new Promise(function (myResolve, myReject) {
            console.log('called_ksdksdsd', jobTemplateId)

            var requestInfo = {
                endPoint: GenerateURL({ job_template_id: jobTemplateId }, properties.api.get_jira_additional_group_list),
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }

            InvokeApi(requestInfo, (fetchedData) => {
                myResolve(fetchedData?.detail)
            }, (error) => { myReject(error) })

        });
    }

    const onChangeHandler = (e) => {
        const key = e.target.name;
        const value = e.target.value;
        var key_to_get = input_list[value];
        console.log(key_to_get, "fdsajnfsjak")
        if (key_to_get?.type == "group") {
            getListForTypeGroup().then(valuesList => {
                console.log(valuesList, 'jggndnd')
                setState(new_state => ({
                    ...new_state,
                    input_type: key_to_get.type,
                    valuesList: valuesList ? valuesList.map(item => { return { id: item, label: item } }) : [],
                    data: {
                        value: key_to_get.default_values ? key_to_get.default_values : [],
                        mandatory: key_to_get.required,
                        data_type: key_to_get.type,
                        default_values: key_to_get.default_values,
                        id: key_to_get.id,
                        key: value
                    }
                }))
            })
                .catch(error => {

                })
        }
        else {
            setState(new_state => ({
                ...new_state,
                input_type: key_to_get.type,
                valuesList: key_to_get.default_values && typeof (key_to_get.default_values) == "object" ? key_to_get.default_values.map(item => { return { id: item, label: item } }) : null,
                data: {
                    value: key_to_get.defaultValue ? key_to_get.defaultValue : [],
                    mandatory: key_to_get.required,
                    data_type: key_to_get.type,
                    default_values: key_to_get.default_values,
                    id: key_to_get.id,
                    key: value
                }
            }))
        }

        // commonFunctions.onChangeHandler(e)
    }

    const onKeyValueChangeHandler = (k, v) => {
        setState({
            ...state, data: { ...state.data, [k]: v },
            error: { ...state.error, [k]: null }
        });
    }
    return (
        <>
            <Grid item lg={3}>

                <Input
                    type="select"
                    list={input_labels.map(item => { return { id: item, label: item } })}
                    name="key"
                    label=""
                    placeholder="key"
                    data={state.data}
                    error={state.error}
                    onChangeHandler={onChangeHandler}
                />
            </Grid>

            {
                state.input_type === "text" ?
                    <Grid item lg={4}>
                        <Input
                            type={"text"}
                            placeholder="value"
                            label=""
                            name="value"
                            data={state.data}
                            error={state.error}
                            onChangeHandler={commonFunctions.onChangeHandler}
                        />
                    </Grid>
                    :
                    state.input_type === "select" ?
                        <Grid item lg={4}>
                            <Input
                                type="select"
                                name="value"
                                mandatorySign
                                data={state.data}
                                error={state.error}
                                list={state.valuesList}
                                onChangeHandler={commonFunctions.onChangeHandler}
                            />
                        </Grid>
                        :
                        state.input_type === "group" ?
                            <Grid item lg={4}>
                                <Input
                                    type="select"
                                    name="value"
                                    mandatorySign
                                    data={state.data}
                                    error={state.error}
                                    list={state.valuesList}
                                    onChangeHandler={commonFunctions.onChangeHandler}
                                />

                            </Grid>
                            :
                            state.input_type === "checkbox" ?
                                state.valuesList && state.valuesList.length > 0 ?
                                    <Grid item lg={4}>
                                        <div className='auto-dropdown-with-checkbox-wrapper'>
                                            <Input
                                                type="select-with-checkbox"
                                                name="value"
                                                mandatorySign
                                                data={state.data}
                                                error={state.error}
                                                list={state.valuesList}
                                                onChangeHandler={onKeyValueChangeHandler}
                                            />
                                        </div>
                                    </Grid>
                                    : null

                                : null
            }
            <Grid item lg={2}>
                <Input
                    type="only-switch-without-label"
                    label=""
                    placeholder="value"
                    name="over-ridable"
                    data={state.data}
                    error={state.error}
                    onChangeHandler={commonFunctions.toggleState}
                />
            </Grid>
            {
                state.data["over-ridable"] ?
                    <Grid item lg={2}>
                        <Input
                            type="only-switch-without-label"
                            label=""
                            placeholder="value"
                            name="mandatory"
                            data={state.data}
                            error={state.error}
                            onChangeHandler={commonFunctions.toggleState}
                        />
                    </Grid> :
                    <Grid item lg={2}>

                    </Grid>
            }
        </>
    )
}
function getFromDefaultState() {
    return {
        data: {
            value: []
        },
        valuesList: [{ id: "label", label: "label one" }],
        error: {}
    }
}
function HeaderSectionInitComands(props) {
    return (
        <>
            <Grid item lg={3}>
                <p className='font-12'>Select Custom Fields</p>
            </Grid>
            <Grid item lg={4}>
                <p className='font-12'>Value</p>
            </Grid>
            <Grid item lg={2}>
                <p className='font-12'>Is this field over-ridable</p>
            </Grid>
            <Grid item lg={2}>
                <p className='font-12'>Is this field Mandatory</p>
            </Grid>
        </>
    )
}



function getSelectedConditions(custom_fields_key_value) {
    var count = 1
    var data = {}
    var child_inherits = {}
    if (custom_fields_key_value) {
        Object.keys(custom_fields_key_value).forEach(key => {
            data = {
                ...data,

                key: key,
                value: custom_fields_key_value[key]
            }



        })
        return data;
    }

}
const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    root: {
        '& .h-300': {
            height: 350,
            overflowy: 'scroll'
        }
    }
}));


