import React, { useState, useEffect } from "react";
import {
  makeStyles,
  createStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import SquareAvatar from "../../../components/genericComponents/AvatarSquare";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import LinearProgress from "@material-ui/core/LinearProgress";

import Pagination from "../../../components/Pagination";

import InvokeApi, { PostData } from "../../../util/apiInvoker";
import GenerateURL, { GenerateSearchURL } from "../../../util/APIUrlProvider";
import properties from "../../../properties/properties";

import CancelIcon from "@material-ui/icons/Cancel";
import moment from "moment";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import { Tooltip, IconButton } from "@material-ui/core";
import StopIcon from "@material-ui/icons/Stop";
import { Loading } from "../../utils/Loading";
import { ErrorComponent } from "../../utils/Error";

import { getDuration } from "../../../util/util";
import RefreshIcon from "@material-ui/icons/Refresh";


import { Link } from "react-router-dom";
import ActivityDetails from "./components/ActivityDetails";

import { getCommonFunctions } from "../../serviceRevamp/add/ci_flow/SourceDetails";

import ScheduleIcon from "@material-ui/icons/Schedule";
import AdvanceSearchFilterCombo from "../../../components/genericComponents/AdvanceSearchFilter/AdvanceSearchFilterCombo";
import { useHistory, useLocation } from 'react-router';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Dialog } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

const OnGoingActivity = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const defaultFilters = ["job_type", "status"]
  var logs = "";
  const [viewLogs, setViewLogs] = useState(false);
  const [viewError, setViewLogError] = useState(false);
  const handleToggle = () => {
    setViewLogError(!viewError);
  };
  const sse_flag_enable = properties.sse_flag;
  var build_revoke_url = properties.api.env_build_revoke_url;
  var deploy_revoke_url = properties.api.env_deploy_revoke_url;
  var promote_revoke_url = properties.api.env_promote_revoke_url;
  // const { activity_id } = props.match.params;

  const [state, setState] = useState({
    current_activity_list: [],
    activity_id: "",
    data: {},
    error: null,
    loading: true,
    infiniteScrollInherits: {},
    refresh_count: 1,
    total_page: "",
    curr_page: 1,
    activity_list: [],
    open_div: false,
    show_refresh_button: false,
    no_activity_to_show: false,
    moreAdvFilterList: [],
    advFilters: {
      job_type: [],
      project_name: [],
      env_master: [],
      user_id: [],
      status: [],
      agent: []
    },
    resetCount: 0,
    selectedInQueueRunningJobs: [],
    checkedAllButton: false,
    openRevokeDialog: false,
    selected_category: "none",
    moreAdvFilterList: defaultFilters
  });

  const [openOptionMenu, setOpenOptionMenu] = useState(false);

  const resetFilterData = {
    job_type: [],
    project_name: [],
    env_master: [],
    user_id: [],
    status: [],
    agent: []
  }
  const commonFunction = getCommonFunctions(state, setState, {});

  useEffect(() => {

    console.log(window.innerWidth, "window inner-width")

    const urlSearchParams = new URLSearchParams(location.search);
    if (urlSearchParams.size != 0) {
      let moreAdvFilter = defaultFilters
      let advFilters = {}
      urlSearchParams?.forEach((value, key) => {
        if (value) {
          if (key == 'adv_search') {
            let advValue = value
            let updatedList = advValue.split(',')
            updatedList = [...moreAdvFilter, ...updatedList]
            moreAdvFilter = [...new Set(updatedList)]
          }
          else {
            let filterValue = value
            const updatedList = filterValue.split(',')
            advFilters[key] = updatedList
          }
        }
      })
      setState(prevState => ({
        ...prevState,
        moreAdvFilterList: moreAdvFilter,
        advFilters: { ...advFilters }
      }))
      fetchActivitiesForListFilter(advFilters, moreAdvFilter)
    }
    else {
      fetchActivitiesInfo()
    }

  }, [])

  useEffect(() => {
    isAllSelected();
    getSelectedCategory()
  }, [state.selectedInQueueRunningJobs])

  function getSelectedCategory() {
    console.log("running")
    var status_arr = []
    var selected_status;
    state.selectedInQueueRunningJobs.forEach((item) => {
      console.log(item, "bdfhsbfxhffhdh");
      status_arr.push(item.status);
    })

    // if(status_arr.includes("RUNNING") && status_arr.includes("IN_QUEUE")){
    //   selected_status = "Running/Inqueue Jobs"
    // }
    // if(status_arr.includes("RUNNING") || status_arr.includes("IN_QUEUE")){

    //   selected_status = "Running/Inqueue Jobs"
    // }

    var count_one = 0
    var count_two = 0

    status_arr.forEach((item) => {
      if (item == "RUNNING") {
        count_one = count_one + 1;
      }
      if (item == "IN_QUEUE") {
        count_two = count_two + 1;
      }
    })

    if (count_one > 0 && count_two > 0) {
      setState((new_state) => ({
        ...new_state,
        selected_category: "Running/Inqueue Jobs",
      }))
    }

    if (count_one == 0 && count_two > 0) {
      setState((new_state) => ({
        ...new_state,
        selected_category: "Inqueue Jobs",
      }))
    }

    if (count_one > 0 && count_two == 0) {
      setState((new_state) => ({
        ...new_state,
        selected_category: "Running Jobs",
      }))
    }

    if (count_one == 0 && count_two == 0) {
      setState((new_state) => ({
        ...new_state,
        selected_category: "None",
      }))
    }
  }


  function activity_status_sse(data, url) {
    var url = GenerateURL({}, properties.api.activity_page_sse);
    state.event_source = new EventSource(url);
    state.event_source.addEventListener(
      "ACTIVITY_STATUS_ALL",
      refresh_page_header_task_id
    );
    // state.event_source.close("ACTIVITY_STATUS_ALL", handle_task_completed_task_id)
  }
  function refresh_page_header_task_id(e) {
    var current_activity_data = JSON.parse(e.data);
    var all_activity_status = current_activity_data.activity_status_all;
    var status_count_matrix =
      current_activity_data.activity_status_count_matrix;
    setState((new_state) => ({
      ...new_state,
      activity_list: all_activity_status,
      no_activity_to_show: all_activity_status.length > 0 ? false : true,
      loading: false,
    }));
  }
  function handle_task_completed_task_id(e) {
    e.srcElement.close();
    setState((new_state) => ({
      ...new_state,
      loading: false,
    }));
  }

  function getUpdatedSelectedStatus(selectedStatus) {
    var str = selectedStatus.toString();
    var len = str.length;
    var ch;
    var initial = -1
    var k = 0;
    var updatedStatusArr = [];
    for (var i = 0; i < len; i++) {
      ch = str.charAt(i);
      if (ch == ",") {
        updatedStatusArr[k] = str.substring(initial + 1, i);
        initial = i;
        k++;
      }
      if (i == len - 1) {
        updatedStatusArr[k] = str.substring(initial + 1, len);
      }
    }
    return updatedStatusArr;
  }

  //fetch data for all the activities and after searchbar enter
  function fetchActivitiesInfo(data, url) {
    var requestInfo = {
      endPoint: GenerateURL({}, properties.api.get_activities_details),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    //new code

    const updateUrl = generateFilterContainURL(requestInfo.endPoint, state.advFilters, state.selectedStatus)
    requestInfo.endPoint = updateUrl;

    if (url) {
      requestInfo.endPoint = url;
    }

    setState((new_state) => ({
      ...new_state,
      search_data: data,
      current: requestInfo.endPoint,
      loading: true,
      search_query_name: data
        ? data.activity_name
          ? data.activity_name
          : ""
        : "",
      data: state.selectedStatus ? {
        ...new_state.data,
        status: [...updatedSelectedStatusArr]
      } :
        {
          ...new_state.data,
          status: []
        },
    }));

    InvokeApi(requestInfo,
      (response) => ActivityInfoFetchSuccess(response, data, url),
      ActivityInfoFetchFailure);
  }

  const isFilterActive = (filterKey, list) => {

    const isFound = list?.find(name => filterKey == name)

    return isFound;
  }

  function generateFilterContainURL(baseURL, listDict, activeFilters) {
    var searchParams = ''
    const filtersKeyArray = Object.keys(listDict)
    let i = 0
    let advFilterApplied = false
    const activeFilterList = activeFilters ? activeFilters : state.moreAdvFilterList
    filtersKeyArray.forEach(key => {

      const arrayFilterValues = listDict[key];
      if (arrayFilterValues.length > 0 && isFilterActive(key, activeFilterList)) {
        if (i != 0) {
          searchParams += '&'
        }
        advFilterApplied = true
        var arrayString = arrayFilterValues;
        const stringKey = `${key}=${arrayString}`
        searchParams += stringKey
        i++;
      }
    })

    const finalUrl = baseURL + '?' + searchParams.toString()

    return searchParams != '' ? finalUrl : baseURL
  }


  function fetchActivitiesForListFilter(listFilterData, activeFilterList) {

    let baseURL = GenerateURL({}, properties.api.get_activities_details)

    const resultURL = generateFilterContainURL(baseURL, listFilterData, activeFilterList);

    let requestInfo = {
      endPoint: resultURL,
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };
    setState((new_state) => ({
      ...new_state,
      loading: true,
    }));
    InvokeApi(requestInfo,
      (response) => {
        setState((new_state) => ({
          ...new_state,
          current: requestInfo.endPoint,
          activity_id: response.id,
          curr_page: 1,
          error: false,
          count: response.count,
          activity_list: response.results,
          next: response.next ? properties.api.baseURL + response.next : null,
          previous: response.previous
            ? properties.api.baseURL + response.previous
            : null,
          loading: false,
          total_page: Math.ceil(Number(response.count / 10)),
          no_activity_to_show:
            response.results && response.results.length > 0 ? false : true,
        }));
      },
      ActivityInfoFetchFailure);
  }

  // buttons function
  function fetchSearchedResultActivitiesInfo(data, url) {
    var requestInfo = {
      endPoint: GenerateURL({}, properties.api.get_activities_details),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    //new code
    if (data) {
      //will change here for
      const updatedUrl = generateFilterContainURL(requestInfo.endPoint, state.advFilters, data)
      requestInfo.endPoint = updatedUrl
    }

    if (url) {
      requestInfo.endPoint = url;
    }

    setState((new_state) => ({
      ...new_state,
      current: requestInfo.endPoint,
      loading: true,
      search_query_name: data
        ? data.activity_name
          ? data.activity_name
          : ""
        : "",
    }));
    InvokeApi(
      requestInfo,
      (response) => fetchSearchedResultActivitiesSuccess(response, data),
      ActivityInfoFetchFailure
    );
  }

  function fetchSearchedResultActivitiesSuccess(response, data) {
    setState((new_state) => ({
      ...new_state,
      activity_id: response.id,
      count: response.count,
      activity_list: response.results,
      next: response.next ? properties.api.baseURL + response.next : null,
      previous: response.previous
        ? properties.api.baseURL + response.previous
        : null,
      loading: false,
      error: false,
      total_page: Math.ceil(Number(response.count / 10)),
      no_activity_to_show:
        response.results && response.results.length > 0 ? false : true,
      selectedStatus: data ? data : null
    }));
    return () => {
      state.event_source && state.event_source.close();
    };
  }


  function ActivityInfoFetchSuccess(response, data, url) {
    if (sse_flag_enable) {
      activity_status_sse();
    }
    if (sse_flag_enable) {
      setState((new_state) => ({
        ...new_state,
        activity_id: response.id,
        count: response.count,
        // activity_list: response.results,
        next: response.next ? properties.api.baseURL + response.next : null,
        previous: response.previous
          ? properties.api.baseURL + response.previous
          : null,
        loading: false,
        no_activity_to_show:
          response.results && response.results.length > 0 ? false : true,
        total_page: Math.ceil(Number(response.count / 10)),
      }));
    } else {
      setState((new_state) => ({
        ...new_state,
        activity_id: response.id,
        count: response.count,
        activity_list: response.results,
        next: response.next ? properties.api.baseURL + response.next : null,
        previous: response.previous
          ? properties.api.baseURL + response.previous
          : null,
        loading: false,
        error: false,
        curr_page: url ? new_state.curr_page : 1,
        no_activity_to_show:
          response.results && response.results.length > 0 ? false : true,
        total_page: Math.ceil(Number(response.count / 10)),
      }));
    }
  }


  function ActivityInfoFetchFailure(error, exception) {
    setState((new_state) => ({
      ...new_state,
      error: error,
      loading: false,
    }));
  }

  const invokeSearch = (activity_list) => {
    fetchActivitiesInfo(activity_list);
  };

  function fetchPrevActivitiesInfo(data, url) {
    // console.log(url.split("=").pop(),"abcdef")
    if (url.split("/").pop() == "null") {
    } else {
      var requestInfo = {
        endPoint: GenerateURL({}, properties.api.activity_page_sse),
        httpMethod: "GET",
        httpHeaders: { "Content-Type": "application/json" },
      };
      if (data) {
        requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
      }
      if (url) {
        requestInfo.endPoint = url;
      }
      setState((new_state) => ({
        ...new_state,
        search_data: data,
        current: requestInfo.endPoint,
        loading: true,
      }));
      InvokeApi(
        requestInfo,
        PrevActivityInfoFetchSuccess,
        PrevActivityInfoFetchFailure
      );
    }
  }
  function PrevActivityInfoFetchSuccess(response) {
    const queryString = require("query-string");
    var parsedQueryString = queryString.parse(response.next);
    var offset = parsedQueryString.offset;
    var current_page_offset = offset - 10;

    setState((new_state) => ({
      ...new_state,
      activity_id: response.id,
      count: response.count,
      activity_list: response.results,
      no_activity_to_show:
        response.results && response.results.length > 0 ? false : true,
      next: response.next ? properties.api.baseURL + response.next : null,
      previous: response.previous
        ? properties.api.baseURL + response.previous
        : null,
      loading: false,
      total_page: Math.ceil(Number(response.count / 10)),
      curr_page: Number(new_state.curr_page - 1),
      show_refresh_button: true,
      error: false
    }));
  }

  function PrevActivityInfoFetchFailure(error, exception) {
    setState((new_state) => ({
      ...new_state,
      error: error,
      loading: false,
    }));
  }

  //start of page code

  const checkAnyQueryParamsAlreadyExistInUrl = (url) => {

    if (url) {
      const urlArray = url.split('?')
      if (urlArray?.length > 1) {
        return true
      }
    }
  }
  function fetchPageActivitiesInfo(enteredPageNumber) {
    var requestInfo = {
      endPoint: GenerateURL({}, properties.api.get_activities_details),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    const updateUrl = generateFilterContainURL(requestInfo.endPoint, state.advFilters, state.selectedStatus)

    requestInfo.endPoint = updateUrl

    if (enteredPageNumber > 1) {
      const isAlreadyQueryParamsAdded = checkAnyQueryParamsAlreadyExistInUrl(requestInfo.endPoint)
      let paginationString = "?limit=10&offset="
      if (isAlreadyQueryParamsAdded) {
        paginationString = "&limit=10&offset="
      }
      requestInfo.endPoint =
        requestInfo.endPoint +
        paginationString +
        (enteredPageNumber - 1) * 10;
    }

    setState((new_state) => ({
      ...new_state,
      current: requestInfo.endPoint,
      loading: true,
    }));

    var current_page = enteredPageNumber;

    InvokeApi(
      requestInfo,
      (response) => {
        PageActivityInfoFetchSuccess(response, current_page);
      },
      PageActivityInfoFetchFailure
    );
  }

  function PageActivityInfoFetchSuccess(response, count) {
    // console.log(count, "count in success");
    const queryString = require("query-string");
    var parsedQueryString = queryString.parse(response.next);
    var offset = parsedQueryString.offset;
    var current_page_offset = offset - 10;

    setState((new_state) => ({
      ...new_state,
      activity_id: response.id,
      count: response.count,
      activity_list: response.results,
      no_activity_to_show:
        response.results && response.results.length > 0 ? false : true,
      next: response.next ? properties.api.baseURL + response.next : null,
      previous: response.previous
        ? properties.api.baseURL + response.previous
        : null,
      loading: false,
      error: false,
      total_page: Math.ceil(Number(response.count / 10)),
      show_refresh_button: true,
      curr_page: Number(count),
    }));
  }

  function PageActivityInfoFetchFailure(error, exception) {
    setState((new_state) => ({
      ...new_state,
      error: error,
      loading: false,
    }));
  }

  //end of page code

  function fetchNextActivitiesInfo(data, url) {
    if (url.split("/").pop() == "null") {
    } else {
      var requestInfo = {
        endPoint: GenerateURL({}, properties.api.activity_page_sse),
        httpMethod: "GET",
        httpHeaders: { "Content-Type": "application/json" },
      };
      if (data) {
        requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
      }
      if (url) {
        requestInfo.endPoint = url;
      }
      setState((new_state) => ({
        ...new_state,
        search_data: data,
        current: requestInfo.endPoint,
        loading: true,
      }));
      InvokeApi(
        requestInfo,
        NextActivityInfoFetchSuccess,
        NextActivityInfoFetchFailure
      );
    }
  }
  function NextActivityInfoFetchSuccess(response) {
    const queryString = require("query-string");
    var parsedQueryString = queryString.parse(response.next);
    var offset = parsedQueryString.offset;
    var current_page_offset = offset - 10;
    setState((new_state) => ({
      ...new_state,
      activity_id: response.id,
      count: response.count,
      next: response.next ? properties.api.baseURL + response.next : null,
      previous: response.previous
        ? properties.api.baseURL + response.previous
        : null,
      loading: false,
      error: false,
      activity_list: response.results,
      no_activity_to_show:
        response.results && response.results.length > 0 ? false : true,
      total_page: Math.ceil(Number(response.count / 10)),
      curr_page: Number(new_state.curr_page + 1),
      show_refresh_button: true,
    }));
  }

  function NextActivityInfoFetchFailure(error, exception) {
    setState((new_state) => ({
      ...new_state,
      error: error,
      loading: false,
    }));
  }


  function fetchCurrentActivitiesInfo(data, url) {
    var requestInfo = {
      endPoint: GenerateURL({}, properties.api.activity_page_sse),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };
    if (data) {
      requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
    }
    if (url) {
      requestInfo.endPoint = url;
    }
    setState((new_state) => ({
      ...new_state,
      search_data: data,
      current: requestInfo.endPoint,
      loading: true,
    }));
    InvokeApi(
      requestInfo,
      CurrentActivityInfoFetchSuccess,
      CurrentActivityInfoFetchFailure
    );
  }
  function CurrentActivityInfoFetchSuccess(response) {
    const queryString = require("query-string");
    var parsedQueryString = queryString.parse(response.next);
    var offset = parsedQueryString.offset;
    var current_page_offset = offset - 10;


    setState((new_state) => ({
      ...new_state,
      activity_id: response.id,
      count: response.count,
      next: response.next ? properties.api.baseURL + response.next : null,
      previous: response.previous
        ? properties.api.baseURL + response.previous
        : null,
      loading: false,
      error: false,
      activity_list: response.results,
      no_activity_to_show:
        response.results && response.results.length > 0 ? false : true,
      total_page: Math.ceil(Number(response.count / 10)),
    }));
  }

  function CurrentActivityInfoFetchFailure(error, exception) {
    setState((new_state) => ({
      ...new_state,
      error: error,
      loading: false,
    }));
  }
  //get activity id

  const removeArrData = (activity_status_id) => {
    state.activity_list.forEach((activity) => {
      if (activity.id == activity_status_id) {
        activity.activity_details = null;
        setState((new_state) => ({
          ...new_state,
          activity_list: [...state.activity_list],
          activity_step_details: {
            open_div: state.activity_list.length > 0 ? true : false,
          },
        }));
      }
    });
  };

  //convert string into CamelCase
  const convertStringInCamelCase = (str) => {
    let newStr = str.replace(/_/g, " ");
    return newStr
      .replace(/\s(.)/g, function ($1) {
        return $1.toUpperCase();
      })
      .replace(/\s/g, " ")
      .replace(/^(.)/, function ($1) {
        return $1.toUpperCase();
      });
  };

  //fetch entity data
  const parseEntityData = (entity) => {
    console.log("urne", entity);
    if (entity.name) {
      if (entity.name.length > 25) {
        return <span className="text-color-black">{entity.name.substring(0, 25) + "..."}</span>;
      } else {
        return <span className="text-color-black">{entity.name}</span>;
      }
    } else {
      for (let key in entity) {
        if (key != "id")
          return (
            <span>
              <br />
              {convertStringInCamelCase(key)} :{" "}
              <span className="text-color-black">{entity[key]}</span>
            </span>
          );
      }
    }
  };

  function onRevoke(activity_token, code, entity_detail) {
    console.log("revokedetails>>", activity_token, "-", code, "-", entity_detail);
    if (code === 'PIPELINE_EXECUTING') {

      if (entity_detail?.pipeline?.id != undefined && entity_detail.pipeline_instance_id != undefined) {
        const urlForRevokePipeline = GenerateURL({ pipeline_id: entity_detail.pipeline.id, pipeline_instance_id: entity_detail.pipeline_instance_id }, properties.api.revoke_pipeline);

        PostData(
          urlForRevokePipeline,
          {},
          handleSuccessRevoke,
          handleFailedRevoke
        );

      }
      else {

        var url_temp = GenerateURL({ pipeline_id: null, pipeline_instance_id: null }, properties.api.revoke_activity);

        PostData(
          url_temp + "?task_id=" + activity_token,
          {},
          handleSuccessRevoke,
          handleFailedRevoke
        );

      }
    }

    else {
      var url_temp = GenerateURL({ pipeline_id: null, pipeline_instance_id: null }, properties.api.revoke_activity);
      const post_data = [{ task_id: activity_token, code: code }];
      PostData(
        url_temp,
        post_data,
        handleSuccessRevoke,
        handleFailedRevoke
      );

    }

    setState((new_state) => ({
      ...new_state,
      history_info_status: "REQUESTED",
    }));
  }



  function refreshState() {

    setState((new_state) => ({
      ...new_state,
      refresh_count: new_state.refresh_count + 1,
      status: [],
    }));

  }
  function handleSuccessRevoke(respone) {
    fetchActivitiesInfo(null, state.current)
    setState((new_state) => ({
      ...new_state,
      error: false,
      history_info_status: "SUCCESS",
    }));

  }
  function fetchActivitiesInfoAfterRevoke(url) {
    var requestInfo = {
      endPoint: GenerateURL({}, properties.api.get_activities_details),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    if (url) {
      requestInfo.endPoint = url;
    }

    setState((new_state) => ({
      ...new_state,
      // current: requestInfo.endPoint,
      loading: true,
    }));
    InvokeApi(
      requestInfo,
      fetchActivitiesInfoAfterRevokeSuccess,
      fetchActivitiesInfoAfterRevokeFailure
    );
  }
  function fetchActivitiesInfoAfterRevokeSuccess(response) {
    setState((new_state) => ({
      ...new_state,
      activity_list: response.results,
      // activity_id: response.id,
      // count: response.count,
      no_activity_to_show:
        response.results && response.results.length > 0 ? false : true,
      next: response.next ? properties.api.baseURL + response.next : null,
      previous: response.previous
        ? properties.api.baseURL + response.previous
        : null,
      loading: false,
      // total_page: Number.isInteger(Number(response.count) / 10) ? (Number(response.count) / 10).toFixed(0) : (Number(response.count) / 10 + 1).toFixed(0) > (Number(response.count) / 10 + 1) ? (Number(response.count) / 10 + 1).toFixed(0) - 1 : (Number(response.count) / 10 + 1).toFixed(0),
      // curr_page: 1
    }));
  }

  function fetchActivitiesInfoAfterRevokeFailure(error, exception) {
    setState((new_state) => ({
      ...new_state,
      error: error,
      loading: false,
    }));
  }
  function handleFailedRevoke(error) {
    setState((new_state) => ({
      ...new_state,
      history_info_status: "FAILED",
      error: error
    }));
  }
  const status_list = [
    {
      id: "SUCCESS",
      label: "Success",
      count: "S",
    },
    {
      id: "FAILED",
      label: "Failed",
      count: "F",
    },
    {
      id: "RUNNING",
      label: "Running",
      count: "R",
    },
    {
      id: "IN_QUEUE",
      label: "In queue",
      count: "I",
    },
    {
      id: "REVOKED",
      label: "Revoked",
      count: "R",
    },
  ];
  const filterDataParse = (list) => {
    console.log(list, 'list_022323_23lll')
    const updatedList = list?.map(item => {
      return { 'label': item.activity_name, 'value': item.code, ...item }
    })
    return updatedList
  }

  const filterDataParseForEnvMaster = (list) => {

    const updatedList = list?.map(item => {
      return { 'label': item.name, 'value': item.name, ...item }
    })
    return updatedList
  }

  const filterDataParseForApp = (list) => {
    console.log('lit_02332', list)
    const updatedList = list?.map(item => {
      return { 'label': item.name, 'value': item.name, ...item }
    })
    return updatedList
  }

  const filterDataParseForUsers = (data) => {
    console.log(data, 'data_2232322')
    if (data) {
      let list = []
      if (Array.isArray(data)) {
        list = data
      }
      else {
        list = data.results
      }
      const updatedList = list?.map(item => {
        return { 'label': item.name, 'value': item.id, ...item }
      })
      return updatedList
    }

  }
  const filterDataParseForAgents = (data) => {
    console.log(data, 'data_2232322')
    if (data) {
      let list = []
      if (Array.isArray(data)) {
        list = data
      }
      else {
        list = data.results
      }
      const updatedList = list?.map(item => {
        return { 'label': item, 'value': item }
      })
      return updatedList
    }

  }

  const advanceFilterJson = {

    'env_master': {
      staticList: false,
      labelName: 'Environment Type',
      uniqueId: 'env_master_adv_2',
      searchVariable: 'name',
      getFetchUrl: properties.api.env_master,
      filterDataPraseFunction: filterDataParseForEnvMaster
    },
    'job_type': {
      staticList: false,
      labelName: 'Job Type',
      uniqueId: 'job_type_adv_1',
      searchVariable: 'activity_own_name',
      getFetchUrl: properties.api.activity_master,
      filterDataPraseFunction: filterDataParse
    },
    'project_name': {
      staticList: false,
      uniqueId: 'project_name_adv_3',
      labelName: 'App',
      searchVariable: 'name',
      getFetchUrl: properties.api.side_panel_data,
      filterDataPraseFunction: filterDataParseForApp
    },
    'user_id': {
      staticList: false,
      uniqueId: 'user_id_adv_4',
      labelName: 'Users',
      searchVariable: 'name',
      getFetchUrl: properties.api.user_list,
      searchUrl: properties.api.user_list,
      filterDataPraseFunction: filterDataParseForUsers
    },
    'status': {
      staticList: [
        { label: "Success", value: "SUCCESS" },
        { label: "Failed", value: "FAILED" },
        { label: "Running", value: "RUNNING" },
        { label: "In Queue", value: "IN_QUEUE" },
        { label: "Revoked", value: "REVOKED" }
      ],
      uniqueId: 'status_id_adv_5',
      labelName: 'Status',
      searchVariable: null,
      getFetchUrl: null,
      filterDataPraseFunction: null
    },
    'worker_name': {
      staticList: false,
      uniqueId: 'agents_id_adv_6',
      labelName: 'Agents',
      searchVariable: 'worker_name',
      getFetchUrl: properties.api.get_all_Workers,
      searchUrl: null,
      filterDataPraseFunction: filterDataParseForAgents
    }
  }
  const moreFilterData = [
    { label: "Job Type", value: "job_type" },
    { label: "Enviornment Type", value: "env_master" },
    { label: "App", value: "project_name" },
    { label: "Users", value: "user_id" },
    { label: "Status", value: "status" },
    { label: "Agent", value: "worker_name" },
  ]


  const addFiltersToUrl = (filterName, filterValue) => {
    console.log("thsjks", filterName, filterValue);
    let urlSearchParams = new URLSearchParams(location.search);
    const allFilters = [
      "env_master",
      "project_name",
      "job_type",
      "user_id",
      "status",
      "worker_name",
    ]

    if (filterName == 'all_delete') {
      urlSearchParams = ''
    } else {
      if (filterName == 'adv_search') {
        allFilters.forEach(value => {
          if (!filterValue.includes(value)) {
            urlSearchParams.delete(value)
          }
        })
      }

      if (filterValue?.length == 0) {
        urlSearchParams.delete(filterName)
      }
      else {
        if (urlSearchParams.has(filterName)) {
          urlSearchParams.set(filterName, filterValue.join(','));
        } else {
          urlSearchParams.append(filterName, filterValue.join(','));
        }
      }
    }


    history.replace({ pathname: location.pathname, search: urlSearchParams.toString() });
  }

  const onUpdateHandle = (uniqueId, updatedList) => {

    let updatedKey = ''
    if (uniqueId === 'more-button-adv-0') {
      addFiltersToUrl('adv_search', updatedList)
      if (updatedList?.length == 0) {
        resetAdvFilter()
      }
      else {
        // const alreadyAddedFilters = state.advFilters
        // Object.keys(alreadyAddedFilters)?.forEach(filterName => {
        //   if (!updatedList.includes(filterName)) {
        //     alreadyAddedFilters[filterName] = []
        //   }
        // })
        setState(prevState => ({
          ...prevState,
          moreAdvFilterList: updatedList,
          // advFilters: { ...alreadyAddedFilters }
        }))
        // fetchActivitiesForListFilter(alreadyAddedFilters)
      }
    }
    else {
      if (uniqueId == 'job_type_adv_1') {
        updatedKey = 'job_type'

      }
      if (uniqueId == 'project_name_adv_3') {
        updatedKey = 'project_name'
      }
      if (uniqueId == 'env_master_adv_2') {
        updatedKey = 'env_master'
      }
      if (uniqueId == 'user_id_adv_4') {
        updatedKey = 'user_id'
      }
      if (uniqueId == 'status_id_adv_5') {
        updatedKey = 'status'
      }
      if (uniqueId == 'agents_id_adv_6') {
        updatedKey = 'worker_name'
      }

      setState(prevState => ({
        ...prevState,
        advFilters: {
          ...prevState.advFilters,
          [updatedKey]: updatedList
        }
      }))
      addFiltersToUrl(updatedKey, updatedList)
      const advFilters = state.advFilters
      advFilters[updatedKey] = updatedList
      fetchActivitiesForListFilter(advFilters)
    }
  }


  const resetAdvFilter = () => {

    fetchActivitiesForListFilter(resetFilterData)
    addFiltersToUrl('all_delete')
    setState(prevState => ({
      ...prevState,
      moreAdvFilterList: defaultFilters,
      advFilters: resetFilterData,
      resetCount: prevState.resetCount + 1
    }))

    // will call here normal function
  }

  const handleSelectAllRunningInqueueJobs = (event) => {
    // setState({ ...state, [event.target.name]: event.target.checked });
    var selectedJobs = []
    state.activity_list.forEach((activity) => {
      if (activity.status == "RUNNING" || activity.status == "IN_QUEUE") {
        selectedJobs.push(activity);
      }
    })
    setState((new_state) => ({
      ...new_state,
      selectedInQueueRunningJobs: [...new_state.selectedInQueueRunningJobs, ...selectedJobs],
      checkedAllButton: true,
      selected_category: "Running/Inqueue Jobs",
    }))
    setOpenOptionMenu(false);
  };

  console.log(state, "dksfkshfjsdhgfjg")

  function handleDeselectAllRunningInqueueJobs() {
    setState((new_state) => ({
      ...new_state,
      selectedInQueueRunningJobs: [],
      checkedAllButton: false,
      selected_category: "none",
    }))
  }

  function handleSelectAllRunningJobs() {
    var selectedJobs = []
    state.activity_list.forEach((activity) => {
      if (activity.status == "RUNNING") {
        selectedJobs.push(activity);
      }
    })
    setState((new_state) => ({
      ...new_state,
      selectedInQueueRunningJobs: [...selectedJobs],
      checkedAllButton: selectedJobs.length > 0 ? true : false,
      selected_category: "Running Jobs",
    }))
    setOpenOptionMenu(!openOptionMenu);
  }

  function handleSelectAllQueueJobs() {
    var selectedJobs = []
    state.activity_list.forEach((activity) => {
      if (activity.status == "IN_QUEUE") {
        selectedJobs.push(activity);
      }
    })
    setState((new_state) => ({
      ...new_state,
      selectedInQueueRunningJobs: [...selectedJobs],
      checkedAllButton: selectedJobs.length > 0 ? true : false,
      selected_category: "Inqueue Jobs",
    }))
    setOpenOptionMenu(!openOptionMenu);
  }

  function getCheckedStatus(activityToken) {
    var activity_token_arr = []
    state.selectedInQueueRunningJobs.length > 0 && state.selectedInQueueRunningJobs.forEach((item) => {
      activity_token_arr.push(item.activity_token)
    })

    if (activity_token_arr.includes(activityToken)) {
      return true
    }
    else {
      return false
    }

  }

  const isRunningInqueueJobsExisting = () => {
    var jobs = [];
    state.activity_list && state.activity_list.forEach((item) => {
      if (item.status == "IN_QUEUE" || item.status == "RUNNING") {
        jobs.push(item);
      }
    })

    if (jobs.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  const handleSingleJobSelect = (activity_token) => {
    // setState({ ...state, [event.target.name]: event.target.checked });
    console.log(activity_token, "sgdjsgfjsdhfhd")

    var selected_job = state.activity_list.find((item) => {
      if (item.activity_token == activity_token) {
        return item;
      }
    })

    console.log(selected_job, "sgdjsgfjsdhfhd");

    setState((new_state) => ({
      ...new_state,
      selectedInQueueRunningJobs: [...new_state.selectedInQueueRunningJobs, selected_job],
    }))

  }

  function handleSingleJobDeselect(activity_token) {
    // var selected_job = state.activity_list.find((item) => {
    //   if (item.activity_token == activity_token) {
    //     return item;
    //   }
    // })

    // console.log(selected_job, "sgdjsgfjsdhfhd");

    var updated_list = [];

    state.selectedInQueueRunningJobs.forEach((item) => {
      if (item.activity_token != activity_token) {
        updated_list.push(item);
      }
    })

    setState((new_state) => ({
      ...new_state,
      selectedInQueueRunningJobs: [...updated_list],
    }))
  }

  function handleOpenSelectionMenu() {
    setOpenOptionMenu(!openOptionMenu);
  }

  function handleOpenRevokeDialog() {
    setState((new_state) => ({
      ...new_state,
      openRevokeDialog: true,
    }))
  }

  function handleCloseRevokeDialog() {
    setState((new_state) => ({
      ...new_state,
      openRevokeDialog: false,
    }))
  }

  function handleSubmitMultipleRevoke() {
    let post_data = []

    state.selectedInQueueRunningJobs.forEach((item) => {
      // console.log(item,"sbdbjsfhjdfhgdsfjgfsd")
      var single_obj = {}

      single_obj.task_id = item.activity_token
      single_obj.code = item.activity_master.code

      if (item?.activity_master?.code == "PIPELINE_EXECUTING") {
        single_obj.pipeline_instance_id = item?.entity_detail?.pipeline_instance_id
      }

      post_data.push(single_obj);
    })

    var url_temp = GenerateURL({}, properties.api.revoke_activity);

    PostData(
      url_temp,
      post_data,
      handleSuccessMultipleRevoke,
      handleFailedMultipleRevoke
    );
  }

  function handleSuccessMultipleRevoke(response) {
    fetchActivitiesInfo(null, state.current)
    setState((new_state) => ({
      ...new_state,
      error: false,
      history_info_status: "SUCCESS",
      openRevokeDialog: false,
      selectedInQueueRunningJobs: [],
    }));
  }

  function handleFailedMultipleRevoke(error) {
    setState((new_state) => ({
      ...new_state,
      history_info_status: "FAILED",
      error: error,
      openRevokeDialog: false,
    }));
  }

  function isAllSelected() {
    console.log("function_running")
    var count = 0;

    state.activity_list && state.activity_list.forEach((item) => {
      if (item.status == "IN_QUEUE" || item.status == "RUNNING") {
        count++
      }
    })

    if (state.selectedInQueueRunningJobs && state.selectedInQueueRunningJobs.length == count) {
      setState((new_state) => ({
        ...new_state,
        selected_items: "all",
        checkedAllButton: true,
      }))
    }
    if (state.selectedInQueueRunningJobs && state.selectedInQueueRunningJobs.length < count && state.selectedInQueueRunningJobs.length > 0) {
      setState((new_state) => ({
        ...new_state,
        selected_items: "few",
      }))
    }
    if (state.selectedInQueueRunningJobs && state.selectedInQueueRunningJobs.length == 0) {
      setState((new_state) => ({
        ...new_state,
        selected_items: "none",
        checkedAllButton: false,
      }))
    }

  }


  return (
    <>
      {
        <Dialog className={classes.revokeDialog} onClose={handleCloseRevokeDialog} open={state.openRevokeDialog}>

          <div className='container'>
            <div className="header-box">
              <p>Revoke Activities</p>
              <CloseIcon style={{ color: "#ffffff", height: "20px", width: "20px" }} onClick={handleCloseRevokeDialog} />
            </div>
            <div className='content-box'>
              <img style={{ marginBottom: '24px', width: "90px", height: "78px" }} src="/images/revoke-dialog-icon.svg" alt="" />
              <div className='main-revoke-header'> Are you sure you want to Revoke all the selected {state.selectedInQueueRunningJobs && state.selectedInQueueRunningJobs.length} activities?</div>
              {/* <div className="sub-revoke-header">Lörem ipsum megavision defoni belig för att hypodade. Heterometer anteng såsom medelbel bidat. Niss ontotik trere. Sekynde resevis ablogi. Dekalir sper</div> */}
            </div>

            <div className='revoke-dialog-footer'>
              <button className="cancel-revoke-button" onClick={handleCloseRevokeDialog} >CANCEL</button>
              <button className="trigger-revoke-button" onClick={handleSubmitMultipleRevoke}><img src="/images/confirm-revoke-icon.svg" style={{ width: "20px", height: "20px", marginRight: "5px" }} alt='..' />REVOKE</button>
            </div>
          </div>
        </Dialog>
      }
      <div className={classes.mainbox}>
        <div className={classes.root}>
          <div
            className="head d-flex align-center space-between"
            style={{ margin: "10px 0px 32px" }}
          >
            <div className="heading-section-service">
              <div className="main-heading">Activities</div>
              <div className="sub-heading-text">
                Displaying overall summary of the activities
              </div>
            </div>
            <Grid
              item
              lg={4}
              sm={10}
              xl={3}
              xs={11}
              className="d-flex space-between align"
            >
              <Pagination
                total_count={state.total_page}
                next={state.next}
                previous={state.previous}
                current_page_count={state.curr_page}
                on_previous_click={() => {
                  fetchPrevActivitiesInfo(null, state.previous);
                }}
                on_next_click={() => {
                  fetchNextActivitiesInfo(null, state.next);
                }}
                on_pageNumber_click={(pageNumber) => {
                  fetchPageActivitiesInfo(pageNumber);
                }}
              />
              <button
                className="btn btn-with-icon btn-round ml-auto mt-5"
                onClick={() => fetchActivitiesInfo(null, state.current)}
              >
                <RefreshIcon className="font-16" />
              </button>
            </Grid>
          </div>
          <Grid className={classes.revampFeature}>

            <Grid container> {/* true case - { marginLeft: "20px" } */}
              {
                isRunningInqueueJobsExisting() &&
                <div className="select-multiple-box" style={{ marginRight: '12px' }}>
                  {
                    state.selected_items == "all" || state.selected_items == "none" ?
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={state.checkedAllButton}
                              onChange={state.selectedInQueueRunningJobs && state.selectedInQueueRunningJobs.length > 0 ? handleDeselectAllRunningInqueueJobs : handleSelectAllRunningInqueueJobs}
                              name="selectAllJobs"
                              color="primary"
                            />
                          }
                          label=""
                        />
                      </div>
                      :
                      <div>
                        <Checkbox
                          defaultChecked
                          indeterminate
                          inputProps={{ 'aria-label': 'indeterminate checkbox' }}
                        />
                      </div>

                  }
                  <div className="selected-category"><p>Selected:<span>{state.selected_category}</span></p></div>
                  <IconButton onClick={handleOpenSelectionMenu}>
                    <ExpandMoreIcon style={{ color: "#505050" }} />
                  </IconButton>
                  {
                    isRunningInqueueJobsExisting() && state.selectedInQueueRunningJobs.length > 0 &&
                    <div role='button' tabIndex={0}  className="revoke-multiple-box" onKeyDown={()=>{}} onClick={handleOpenRevokeDialog}>
                      <img src="/images/revoke-job-icon.svg" style={{ width: "18px", height: "18px" }} alt='..'/>
                    </div>

                  }
                  {
                    openOptionMenu &&
                    <div className="option-box">
                      <div className="buttons-div">
                        <div role='button' tabIndex={0} className="single-button" onKeyDown={()=>{}} onClick={handleSelectAllRunningJobs}>All Running</div>
                        <div role='button' tabIndex={0} className="single-button" onKeyDown={()=>{}} onClick={handleSelectAllQueueJobs}>All Queue</div>
                        <div role='button' tabIndex={0} className="single-button" onKeyDown={()=>{}} onClick={handleSelectAllRunningInqueueJobs}>All Running/Queue</div>
                      </div>
                    </div>
                  }
                </div>
              }
              {
                state.moreAdvFilterList?.map(filterName => {
                  return (
                    <div key={filterName} className="ad-more-search mb-20">
                      <AdvanceSearchFilterCombo
                        reset={state.resetCount}
                        selectedCheckBoxes={state.advFilters[filterName]}
                        searchVariable={advanceFilterJson[filterName]['searchVariable']}
                        staticList={advanceFilterJson[filterName]['staticList']}
                        uniqueId={advanceFilterJson[filterName]['uniqueId']}
                        labelName={advanceFilterJson[filterName]['labelName']}
                        searchUrl={advanceFilterJson[filterName]['searchUrl']}
                        onUpdate={onUpdateHandle}
                        getFetchUrl={advanceFilterJson[filterName]['getFetchUrl']}
                        filterDataPraseFunction={advanceFilterJson[filterName]['filterDataPraseFunction']}
                      />
                    </div>
                  )
                })
              }
              <div className="ad-more-search mb-20">
                <AdvanceSearchFilterCombo
                  selectedCheckBoxes={state.moreAdvFilterList}
                  reset={state.resetCount}
                  staticList={moreFilterData}
                  autoClosedAfterSelection={true}
                  onUpdate={onUpdateHandle}
                  variant='more-button'
                  uniqueId='more-button-adv-0' />
              </div>

              <div role='button' tabIndex={0} onKeyDown={()=>{}} onClick={resetAdvFilter} className="pl-15 ml-20 d-flex align-center justify-center cursor-pointer" style={{ borderLeft: '1px solid #dedede', height: '40px' }}>
                <span style={{ color: '#595353' }} className="font-13 cursor-pointer">Reset</span>
              </div>
            </Grid>
          </Grid>

          <div>
            {
              state.error ?
                <ErrorComponent variant="error-box" error={state.error} />
                :
                !state.loading ?
                  <>
                    {state.activity_list && state.activity_list.length > 0 ? (
                      <div className="card">
                        <div className="card-body">
                          <div className={classes.ongoingActivityCard}>
                            <div className={classes.ongoingActivityCardHeader}>
                              <p>Activity Type</p>
                              <p>Triggered by</p>
                              <p>Activity Status</p>
                              <p></p>
                              <p>Actions</p>
                            </div>
                            <div
                              className={
                                classes.ongoingActivityBody +
                                " " +
                                "ongoingActivityCardBody"
                              }
                            >
                              {state.activity_list.map((activity) => (
                                <>
                                  <div className="single-row-card">
                                    <div className="single-row-item-one">
                                      <div
                                        className="d-flex align-center"
                                        style={{ width: 200, overflow: "hidden" }}
                                      >
                                        {
                                          activity.status == "IN_QUEUE" ||
                                            activity.status == "RUNNING" ?
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={getCheckedStatus(activity.activity_token)}
                                                  onChange={getCheckedStatus(activity.activity_token) ? () => handleSingleJobDeselect(activity.activity_token) : () => handleSingleJobSelect(activity.activity_token)}
                                                  name="checkedB"
                                                  color="primary"
                                                />
                                              }
                                              label=""
                                            />
                                            :
                                            isRunningInqueueJobsExisting() ?
                                              <div style={{ marginLeft: "40px" }}></div>
                                              :
                                              <></>
                                        }
                                        <SquareAvatar
                                          varient="double"
                                          name={
                                            activity.activity_master.activity_name
                                              ? activity.activity_master.activity_name
                                              : null
                                          }
                                        />
                                        <div className=" ml-5">
                                          <span>Activity # </span>
                                          <span className="text-color-grey">
                                            {activity.id}
                                          </span>
                                          <div>
                                            <span>Type: </span>

                                            {activity.entity_detail &&
                                              activity.entity_detail.component &&
                                              activity.entity_detail.component.name &&
                                              activity.entity_detail.component_env &&
                                              activity.entity_detail.component_env.name ? (
                                              <Link
                                                className="text-anchor-blue cursor-pointer"
                                                to={
                                                  "/logs?global_task_id=" +
                                                  activity.activity_token +
                                                  "&num=" +
                                                  activity.id +
                                                  "&service_name=" +
                                                  activity.entity_detail.component
                                                    .name +
                                                  "&service_env=" +
                                                  activity.entity_detail.component_env
                                                    .name +
                                                  "&service_id=" + activity.entity_detail.component
                                                    .id +
                                                  "&env_id=" + activity.entity_detail.component_env
                                                    .id +
                                                  "&cd_id=" + activity?.entity_detail?.deployment_name?.id
                                                }
                                                target="_blank"
                                              >
                                                {activity.activity_master
                                                  .activity_name ? (
                                                  <Tooltip
                                                    title={
                                                      activity.activity_master
                                                        .activity_name
                                                    }
                                                  >
                                                    <span
                                                      className="text-anchor-blue text-ellipsis-150 vertical-bottom d-inline-block"
                                                      style={{ width: "90px" }}
                                                    >
                                                      {
                                                        activity.activity_master
                                                          .activity_name
                                                      }
                                                    </span>
                                                  </Tooltip>
                                                ) : null}
                                              </Link>
                                            ) : (
                                              <Link
                                                className="text-anchor-blue cursor-pointer"
                                                to={
                                                  "/logs?global_task_id=" +
                                                  activity.activity_token
                                                }
                                                target="_blank"
                                              >
                                                {activity.activity_master
                                                  .activity_name ? (
                                                  <Tooltip
                                                    title={
                                                      activity.activity_master
                                                        .activity_name
                                                    }
                                                  >
                                                    <span
                                                      className="text-anchor-blue text-ellipsis-150 vertical-bottom d-inline-block"
                                                      style={{ width: "90px" }}
                                                    >
                                                      {
                                                        activity.activity_master
                                                          .activity_name
                                                      }
                                                    </span>
                                                  </Tooltip>
                                                ) : null}
                                              </Link>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="single-row-item-two">
                                      <div className="">
                                        <p>
                                          Triggred by:{" "}
                                        </p>
                                        <Tooltip
                                          title={activity.user ? activity.user : ""}
                                        >
                                          <span
                                            className="text-color-black text-ellipsis-150 vertical-bottom d-inline-block"
                                            style={{ width: "90px" }}
                                          >
                                            {activity.user ? activity.user : ""}
                                          </span>
                                        </Tooltip>
                                        <p>
                                          Started:{" "}
                                        </p>
                                        <span className="text-color-black">
                                          {activity.start_time
                                            ? moment(activity.start_time).fromNow()
                                            : ""}
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      className="single-row-item-three"
                                      style={{ whiteSpace: "wrap" }}
                                    >
                                      <div
                                        style={{
                                          maxWidth: "220px",
                                          whiteSpace: "normal",
                                        }}
                                      >
                                        <span>
                                          {activity.entity_detail &&
                                            activity.entity_detail.component ? (
                                            Object.keys(activity.entity_detail).map(
                                              (key, val) => (
                                                <>
                                                  <span>
                                                    {convertStringInCamelCase(key)}:{" "}
                                                  </span>
                                                  {key == "component" ?

                                                    activity.entity_detail["project"] && activity.entity_detail["project"].id ?
                                                      activity.entity_detail[key].name && activity.entity_detail[key].name.length > 25 ?
                                                        <Tooltip title={activity.entity_detail[key].name}>
                                                          <Link
                                                            to={"/application/" + activity.entity_detail["project"].id + "/service/" + activity.entity_detail["component"].id + "/detail"}
                                                            className="text-anchor-blue"
                                                          >
                                                            {parseEntityData(
                                                              activity.entity_detail[key]
                                                            )}
                                                          </Link>
                                                        </Tooltip>
                                                        :
                                                        <Link
                                                          to={"/application/" + activity.entity_detail["project"].id + "/service/" + activity.entity_detail["component"].id + "/detail"}
                                                          className="text-anchor-blue"
                                                        >
                                                          {parseEntityData(
                                                            activity.entity_detail[key]
                                                          )}
                                                        </Link>
                                                      :
                                                      <Link
                                                        to={""}
                                                        className="text-anchor-blue"
                                                      >
                                                        {parseEntityData(
                                                          activity.entity_detail[key]
                                                        )}
                                                      </Link>

                                                    : (
                                                      <span>
                                                        {parseEntityData(
                                                          activity.entity_detail[key]
                                                        )}
                                                      </span>
                                                    )}

                                                  <br></br>
                                                </>
                                              )
                                            )
                                          ) : activity.entity_detail &&
                                            activity.entity_detail.pipeline ? (
                                            Object.keys(activity.entity_detail).map(
                                              (key, val) => (
                                                <>
                                                  <span>
                                                    {convertStringInCamelCase(key)}:{" "}
                                                  </span>
                                                  {key == "pipeline" ? (

                                                    <Link
                                                      to={
                                                        "/application/" + "/application/" +
                                                        activity.entity_detail[
                                                          "project"
                                                        ].id +
                                                        "/pipeline/" + activity.entity_detail[
                                                          "pipeline"
                                                        ].id + "/execution/" + activity.entity_detail[
                                                        "pipeline_instance_id"]
                                                      }
                                                      className="text-anchor-blue"
                                                    >
                                                      {parseEntityData(
                                                        activity.entity_detail[key]
                                                      )}
                                                    </Link>
                                                  ) : (
                                                    <span>
                                                      {parseEntityData(
                                                        activity.entity_detail[key]
                                                      )}
                                                    </span>
                                                  )}
                                                  <br></br>
                                                </>
                                              )
                                            )
                                          ) : 
                                          activity.entity_detail && activity.entity_detail.kind ? 
                                          Object.keys(activity.entity_detail).map(
                                            (key, val) => (
                                              <>
                                                <span>
                                                  {convertStringInCamelCase(key)}:{" "}
                                                </span>
                                                <span className="text-color-black">
                                                        {activity.entity_detail[key]}
                                                      </span>
                                                <br></br>
                                              </>
                                            )
                                          )
                                          :
                                          (activity.entity_detail &&
                                            activity.entity_detail.config_map) ||
                                            (activity.entity_detail &&
                                              activity.entity_detail.project_env) ? (
                                            Object.keys(activity.entity_detail).map(
                                              (key, val) => (
                                                <>
                                                  <span>{key}: </span>{" "}
                                                  <span className="text-color-black">
                                                    {activity.entity_detail[key].name}
                                                  </span>
                                                  <br></br>
                                                </>
                                              )
                                            )
                                          ) : (activity.entity_detail &&
                                            activity.entity_detail.cluster) ||
                                            (activity.entity_detail &&
                                              activity.entity_detail.namespace) ? (
                                            Object.keys(activity.entity_detail).map(
                                              (key, val) => (
                                                <>
                                                  <span>{key}: </span>{" "}
                                                  <span className="text-color-black">
                                                    {activity.entity_detail[key].name}
                                                  </span>
                                                  <br></br>
                                                </>
                                              )
                                            )
                                          ) : activity.status == "FAILED" ? (
                                            <div className="error-container">
                                              <span>Error: </span>
                                              <Tooltip
                                                placement="bottom"
                                                title={activity.description}
                                              >
                                                <span className="color-danger text-ellipsis-multiline">
                                                  {activity.description}
                                                </span>
                                              </Tooltip>
                                            </div>
                                          ) : null}
                                          {activity.trigger_by_pipeline ? (
                                            <>
                                              <span>Pipeline: </span>{" "}
                                              <span className="text-color-black">
                                                {activity.trigger_by_pipeline}
                                              </span>
                                              <br></br>
                                            </>
                                          ) : null}
                                          {activity.worker_name ? (
                                            <>
                                              <span>Executor: </span>{" "}
                                              <span className="text-color-black">
                                                {activity.worker_name.split('@')[1] ? activity.worker_name.split('@')[1] : "N/A"}
                                                {/*activity.task_executor*/}
                                              </span>
                                              <br></br>
                                            </>
                                          ) : null}
                                          {activity.queue_end_time ? 
                                            <>
                                            <span>
                                            In Queue Time:&nbsp; 
                                            </span>
                                            <span className="text-color-black">
                                              {getDuration(new Date(activity.created_at),new Date(activity.queue_end_time)) || '0s'}
                                            </span>
                                            <br></br>
                                            </>
                                          
                                          :null}
                                        </span>
                                        
                                      </div>
                                    </div>
                                    <div className="single-row-item-four">
                                      <div
                                        style={{ width: "100%" }}
                                        className={
                                          activity.status == "IN_QUEUE" ||
                                            activity.status == "RUNNING"
                                            ? "d-flex align-center"
                                            : null
                                        }
                                      >
                                        <div>
                                          <div className="d-flex align-center mb-5">
                                            <span className="ml-5 d-flex align-center mr-5">
                                              {activity.status == "FAILED" ? (
                                                <>
                                                  {" "}
                                                  <CancelIcon
                                                    fontSize="large"
                                                    className="color-danger"
                                                  />
                                                  <span style={{ fontWeight: "500" }}>
                                                    Failed
                                                  </span>
                                                </>
                                              ) : activity.status == "STOPPED" ? (
                                                <>
                                                  {" "}
                                                  <StopIcon
                                                    fontSize="large"
                                                    style={{ color: "#e9797e" }}
                                                  />
                                                  <span style={{ fontWeight: "500" }}>
                                                    Stopped
                                                  </span>
                                                </>
                                              ) : activity.status == "SUCCESS" ? (
                                                <>
                                                  <CheckCircleIcon
                                                    fontSize="large"
                                                    className="color-success"
                                                  />
                                                  <span style={{ fontWeight: "500" }}>
                                                    Success
                                                  </span>
                                                </>
                                              ) : activity.status == "RUNNING" ? (
                                                <>
                                                  <TimelapseIcon
                                                    fontSize="large"
                                                    className="text-anchor-blue"
                                                  />
                                                  <span style={{ fontWeight: "500" }}>
                                                    Running
                                                  </span>
                                                </>
                                              ) : activity.status == "REVOKED" ? (
                                                <>
                                                  <StopIcon
                                                    fontSize="large"
                                                    className="color-revoked"
                                                  />
                                                  <span style={{ fontWeight: "500" }}>
                                                    Revoked
                                                  </span>
                                                </>
                                              ) : activity.status == "IN_QUEUE" ? (
                                                <>
                                                  <ScheduleIcon
                                                    fontSize="large"
                                                    style={{ color: "#004085" }}
                                                  />
                                                  <span style={{ fontWeight: "500" }}>
                                                    In queue
                                                  </span>
                                                </>
                                              ) : null}
                                            </span>
                                          </div>

                                          <div className="ml-5">
                                            {activity.status == "RUNNING" ||
                                              activity.status == "IN_QUEUE" ? (
                                              <>
                                                <span>Running Since: </span>
                                                <span className="text-color-black">
                                                  {activity.start_time
                                                    ? moment(
                                                      activity.start_time
                                                    ).fromNow()
                                                    : "NA"}
                                                </span>
                                              </>
                                            ) : activity.status == "SUCCESS" ? (
                                              <>
                                                <span>Execution Duration: </span>
                                                <span className="text-color-black">
                                                  {activity.start_time &&
                                                    activity.end_time
                                                    ? getDuration(
                                                      new Date(activity.start_time),
                                                      new Date(activity.end_time)
                                                    )
                                                    : "NA"}
                                                </span>
                                              </>
                                            ) : null}
                                            {activity.status == "FAILED" ? (
                                              <div className="d-flex align-center">
                                                <button
                                                  className="btn btn-transparent text-anchor-blue d-block mr-5"
                                                  style={{ fontSize: "11px" }}
                                                  onClick={handleToggle}
                                                >
                                                  View Error Log
                                                </button>
                                                <span>Execution Duration: </span>
                                                <span className="text-color-black">
                                                  {activity.start_time &&
                                                    activity.end_time
                                                    ? getDuration(
                                                      new Date(activity.start_time),
                                                      new Date(activity.end_time)
                                                    )
                                                    : "NA"}
                                                </span>
                                              </div>
                                            ) : null}
                                          </div>
                                          <div>
                                            {activity.status == "FAILED" ? (
                                              <BorderLinearProgressFailed
                                                variant="determinate"
                                                value={50}
                                              />
                                            ) : activity.status == "STOPPED" ? (
                                              <BorderLinearProgressStopped
                                                variant="determinate"
                                                value={50}
                                              />
                                            ) : activity.status == "SUCCESS" ? (
                                              <BorderLinearProgressSuccess
                                                variant="determinate"
                                                value={100}
                                              />
                                            ) : activity.status == "RUNNING" ? (
                                              <BorderLinearProgressRunning
                                                variant="indeterminate"
                                                value={50}
                                              />
                                            ) : activity.status == "REVOKED" ? (
                                              <BorderLinearProgressRevoked
                                                variant="determinate"
                                                value={50}
                                              />
                                            ) : activity.status == "IN_QUEUE" ? (
                                              <BorderLinearProgressInitiated
                                                variant="indeterminate"
                                                value={30}
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                        <>
                                          {activity.status == "FAILED" && viewError ? (
                                            <div className="error-container">
                                              <Tooltip
                                                placement="bottom"
                                                title={activity.description}
                                              >
                                                <span className="color-danger text-ellipsis-multiline">
                                                  {activity.description}
                                                </span>
                                              </Tooltip>
                                            </div>
                                          ) : null}
                                          {/* {
                                                                        activity.status == "IN_QUEUE" || activity.status == "RUNNING" ?
                                                                            // <button className="btn btn-with-icon btn-round border-2px-blue ml-5" title="Revoke" onClick={} ><StopIcon className="text-danger" /></button>
                                                                            : null
                                                                    } */}
                                        </>
                                      </div>
                                    </div>

                                    <ActivityDetails
                                      activity_id={activity.id}
                                      activity_master_id={
                                        activity.activity_master &&
                                          activity.activity_master.id
                                          ? activity.activity_master.id
                                          : null
                                      }
                                      task_id={activity.activity_token}
                                      removeArrData={() => {
                                        removeArrData(activity.id);
                                      }}
                                      activity_status={activity.status}
                                      revokeActivity={() => {
                                        onRevoke(activity.activity_token, activity?.activity_master?.code, activity?.entity_detail);
                                      }}
                                      logUrl={
                                        "/logs?global_task_id=" +
                                        activity.activity_token
                                      }
                                    />
                                  </div>
                                </>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : state.search_query_name ? (
                      <div className="main-container-error" style={{ height: "70vh" }}>
                        <div className="svg">
                          <div className="servicePanel">
                            <div className="blank-div">
                              <div className="blank-div-text">
                                No Activity found with this
                                {state.search_query_name
                                  ? " name: " + state.search_query_name
                                  : ""}
                              </div>
                              <button
                                className="btn btn-submit"
                                onClick={() => {
                                  invokeSearch();
                                }}
                              >
                                Refresh
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : state.no_activity_to_show ? (
                      <div className="main-container-error" style={{ height: "70vh" }}>
                        <div className="svg">
                          <div className="servicePanel">
                            <div className="blank-div">
                              <div className="blank-div-text">
                                No Activity history found
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <Loading />
                    )}
                  </> :
                  <div style={{ height: '340px' }} className="card">
                    <Loading varient='light' />
                  </div>
            }
          </div>

        </div>
      </div>

    </>
  );
};
export default OnGoingActivity;
const SetpDetailsInHorizontalTab = (props) => {
  return <h1>hello</h1>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "32px",
    marginTop: "32px",
    position: "relative",
    "& .card-body": {},
    "& .btn-flaticon": {
      verticalAlign: "middle",
      "& .material-icons": {
        verticalAlign: "middle",
        color: "#0787e1",
      },
    },
    "& .btn-flaticon-disabled": {
      "& .material-icons": {
        verticalAlign: "middle",
        color: "#b9b8b8!important",
      },
    },

    "& .option-box": {
      width: "164px",
      height: "99px",
      borderRadius: "6px",
      backgroundColor: "#234361",
      position: "absolute",
      top: "40px",
      left: "90px",
      zIndex: 999,


      "& .buttons-div": {
        display: "flex",
        flexDirection: "column",

        "& .single-button": {
          display: "flex",
          alignItems: "center",
          paddingLeft: "10px",
          height: "33px",
          width: "100%",
          
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "17px",
          letterSpacing: "0em",
          textAlign: "left",
          color: "#FFFFFF"
        }
      }
    }
  },

  revokeDialog: {
    background: 'rgba(0,0,0, 0.3) !important',
    position: "relative",

    "& .MuiPaper-root": {
      height: "380px",
      width: "655px",
      borderRadius: "8px",
    },

    '& .container': {
      display: 'grid',
      width: '100%',
      gridTemplateRows: 'auto 70% auto',
      height: '300px',
    },

    "& .header-box": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "56px",
      backgroundColor: "#0086FF",
      padding: "0 10px",

      "& p": {
        
        fontSize: "18px",
        fontWeight: "600",
        lineHight: "22px",
        letterSpacing: "0em",
        textAlign: "left",
        color: "#ffffff",
      }
    },

    '& .content-box': {
      padding: '30px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& .main-header': {
        color: '#ff7b7b',
        marginBottom: '15px'
      },
      '& .info-text': {
        color: 'gray',
      },

      "& .main-revoke-header": {
        
        fontSize: "16px",
        fontWeight: "600",
        lineHeight: "20px",
        letterSpacing: "0em",
        textAlign: "left",

      },

      "& .sub-revoke-header": {
        marginTop: "8px",
        
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "21px",
        letterSpacing: "0em",
        textAlign: "center",
      }
    },

    "& .revoke-dialog-footer": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      height: "72px",
      width: "100%",
      backgroundColor: "#FAFAFA",
      position: "absolute",
      bottom: "0",
      marginTop: "20px",

      "& .cancel-revoke-button": {
        width: "83px",
        height: "40px",
        borderRadius: "6px",
        border: "1px solid #9DC0EE",
        backgroundColor: "#ffffff",
        
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "15px",
        letterSpacing: "0em",
        textAlign: "center",
        color: "#124D9B",
        marginRight: "20px",
      },

      "& .trigger-revoke-button": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "110px",
        height: "40px",
        borderRadius: "6px",
        border: "none",
        backgroundColor: "#E95454",
        
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "15px",
        letterSpacing: "0em",
        textAlign: "center",
        color: "#FFFFFF",
        marginRight: "20px",
      }
    }
  },


  mainbox: {
    position: "relative",
    "& #viewFullLogs": {
      bottom: "-210px",
    },
  },
  ongoingActivityCard: {
    height: "500px",
    width: "100%",

    "& .single-row-card": {

      "& .MuiFormControlLabel-root": {
        marginRight: "5px",
        marginLeft: "0px"
      }
    }
  },
  ongoingActivityCardHeader: {
    borderBottom: "2px solid #e6e6e6!important",
    display: "grid",
    gridTemplateColumns: "22% 12% 22% 26% 17%",
    "& p": {
      padding: "1.25rem",
      textAlign: "left",
      fontSize: "11px",
      fontWeight: 400,
      color: "#9e9e9e",
    },
  },
  revampFeature: {

    display: "flex",

    // "& .MuiGrid-container": {
    //   width: "60% !important"
    // },

    "& .select-multiple-box": {
      height: "47px",
      borderRadius: "6px",
      border: "1px solid #E6E6E6",
      display: "flex",
      alignItems: "center",
      position: "relative",
      backgroundColor: '#fff',
      "& .MuiFormControlLabel-root": {
        marginRight: "0px",
        marginLeft: "0px"
      },

      "& .selected-category": {

        "& p": {
          
          fontSize: "12px",
          fontWeight: "500",
          lineHeight: "15px",
          color: "#939393",
        },

        "& span": {
          
          fontSize: "12px",
          fontWeight: "500",
          lineHeight: "15px",
          color: "#000000",
        }

      }
    },

    "& .revoke-multiple-box": {
      width: "37px",
      height: "37px",
      borderRadius: "6px",
      border: "1px solid #E6E6E6",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "15px",
      marginRight: "5px",
    }

  },
  ongoingActivityCardbody: {},
}));

const BorderLinearProgressSuccess = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 6,
      borderRadius: 3,
      width: "250px",
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 3,
      backgroundColor: "#69e09c",
    },
  })
)(LinearProgress);

const BorderLinearProgressInitiated = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 6,
      borderRadius: 3,
      width: "250px",
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 3,
      backgroundColor: "#004085",
    },
  })
)(LinearProgress);

const BorderLinearProgressRevoked = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 6,
      borderRadius: 3,
      width: "250px",
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 3,
      backgroundColor: "#c294f1",
    },
  })
)(LinearProgress);

const BorderLinearProgressStopped = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 6,
      borderRadius: 3,
      width: "250px",
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 3,
      backgroundColor: "#e9797e",
    },
  })
)(LinearProgress);
const BorderLinearProgressFailed = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 6,
      borderRadius: 3,
      width: "250px",
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 3,
      backgroundColor: "#ff5252",
    },
  })
)(LinearProgress);

const BorderLinearProgressRunning = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 6,
      borderRadius: 3,
      width: "250px",
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 3,
      backgroundColor: "#0086ff",
    },
  })
)(LinearProgress);




const getCurrentUser = () => {
  var user = localStorage.getItem("authData")
  var authData = JSON.parse(user)
  return String(authData?.userDetails?.id)
}