import React from 'react';
import PropTypes from 'prop-types';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from "react-router-dom";



const ITEM_HEIGHT = 48;
export default function MoreOptionMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const OptionList = props.OptionList;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  //const application_id = props.application_id;
  const onClickHandler = (elem) => {
    if (elem.text == "View Logs") {
      elem.onClick(props.id,props.task_id,props.action_identifier)
    }
    else {
      elem.onClick(props.id)
    }
  }
  function performDelete(component) {
    const Delete = component;
    return Delete
  }
  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon style={{ color: '#0096db', fontSize: '2rem' }} />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 'fit-content',
          },
        }}
      >
        {OptionList.map(elem => (
          <MenuItem onClick={handleClose} style={{ display: "grid" }}>

            {elem.url && <Link className="hover-content-link-more-option d-flex align-center" to={elem.url ? props.name ? elem.url + props.name + "/edit" : elem.url : "#"}    >
              {elem.icon}&nbsp; &nbsp;{elem.text}
            </Link>}
            {
              elem.onClick && <div className="menu-cont" onKeyDown={()=>{}} onClick={() => { onClickHandler(elem) }} tabIndex={0} role="button">{elem.icon} &nbsp;{elem.text}</div>
            }
            {
              elem.text == "Delete" ?<> {elem.component}</>:null
              
              // performDelete(elem.component)
            }
          </MenuItem>
        ))}

      </Menu>
    </div>
  );
}

MoreOptionMenu.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}
