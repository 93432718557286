import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../../../../components/genericComponents/Input';
import { ValidateDataSet, VALIDATION_TYPE_REQUIRED, VALIDATION_TYPE_SMALLCAP_NUM_HIGHFEN_ONLY } from '../../../../../util/Validator';

function UI_BasicInfo(props) {
    const inherits = props.inherits;

    const data = props.data;
    const [state, setState] = useState({
        data: data ? data : {
            cluster_name: "",
        },
        error: {

        }
    });

    function onChangeHandler(e) {
        const k = e.target.name;
        const v = e.target.value;
        var format = /^[!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?]*$/;
        if(k == "cluster_name"){
           let new_v = v.replace(" ","-");
        //    if(new_v.match(format))
        //    {
        //        new_v=new_v.replace(new_v.charAt(new_v.length-1),"-")
        //    }
           updateData(k, new_v.toLowerCase());
        }else{
            updateData(k, v);
        }
        
    }

    function updateData(k, v) {
        setState(new_state => ({
            ...new_state,
            data: {
                ...new_state.data,
                [k]: v,
            },
            error: {
                ...new_state.error,
                [k]: "",
            }
        }));
    }

    // Defining Inherits
    inherits.validateForm = () => {
        const validations = {
            cluster_name: [VALIDATION_TYPE_REQUIRED, VALIDATION_TYPE_SMALLCAP_NUM_HIGHFEN_ONLY],
        }

        const result = ValidateDataSet(state.data, validations);
        if (!result.valid) {
            setState(new_state => ({
                ...new_state,
                error: {
                    ...result.error
                }
            }))
        }
        return result;
    }


    inherits.getData = () => {
        const data = { ...state.data };
        return data;
    }

    return (
       
                    <div className="form-controll">
                        <Input
                            type="text"
                            placeholder="Cluster Name"
                            mandatorySign
                            name="cluster_name"
                            label="Give a name to your cluster"
                            subHeading="AlphaNumeric with hyphen"
                            error={state.error}
                            onChangeHandler={onChangeHandler}
                            data={state.data} />
                    </div>
    );
}
UI_BasicInfo.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default UI_BasicInfo;