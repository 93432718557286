export default {
    timeZoneList: [
        {
          "label": "Europe/Andorra"
        },
        {
          "label": "Asia/Dubai",
        },
        {
          "label": "Asia/Kabul",
        },
        {
          "label": "America/Antigua",
        },
        {
          "label": "America/Anguilla",
        },
        {
          "label": "Europe/Tirane",
        },
        {
          "label": "Asia/Yerevan",
        },
        {
          "label": "Africa/Luanda",
        },
        {
          "label": "Antarctica/Casey",
        },
        {
          "label": "Antarctica/Davis",
        },
        {
          "label": "Antarctica/DumontDUrville",
        },
        {
          "label": "Antarctica/Mawson",
        },
        {
          "label": "Antarctica/McMurdo",
        },
        {
          "label": "Antarctica/Palmer",
        },
        {
          "label": "Antarctica/Rothera",
        },
        {
          "label": "Antarctica/Syowa",
        },
        {
          "label": "Antarctica/Troll",
        },
        {
          "label": "Antarctica/Vostok",
        },
        {
          "label": "America/Argentina/Buenos_Aires",
        },
        {
          "label": "America/Argentina/Catamarca",
        },
        {
          "label": "America/Argentina/Cordoba",
        },
        {
          "label": "America/Argentina/Jujuy",
        },
        {
          "label": "America/Argentina/La_Rioja",
        },
        {
          "label": "America/Argentina/Mendoza",
        },
        {
          "label": "America/Argentina/Rio_Gallegos",
        },
        {
          "label": "America/Argentina/Salta",
        },
        {
          "label": "America/Argentina/San_Juan",
        },
        {
          "label": "America/Argentina/San_Luis",
        },
        {
          "label": "America/Argentina/Tucuman",
        },
        {
          "label": "America/Argentina/Ushuaia",
        },
        {
          "label": "Pacific/Pago_Pago",
        },
        {
          "label": "Europe/Vienna",
        },
        {
          "label": "Antarctica/Macquarie",
        },
        {
          "label": "Australia/Adelaide",
        },
        {
          "label": "Australia/Brisbane",
        },
        {
          "label": "Australia/Broken_Hill",
        },
        {
          "label": "Australia/Darwin",
        },
        {
          "label": "Australia/Eucla",
        },
        {
          "label": "Australia/Hobart",
        },
        {
          "label": "Australia/Lindeman",
        },
        {
          "label": "Australia/Lord_Howe",
        },
        {
          "label": "Australia/Melbourne",
        },
        {
          "label": "Australia/Perth",
        },
        {
          "label": "Australia/Sydney",
        },
        {
          "label": "America/Aruba",
        },
        {
          "label": "Europe/Mariehamn",
        },
        {
          "label": "Asia/Baku",
        },
        {
          "label": "Europe/Sarajevo",
        },
        {
          "label": "America/Barbados",
        },
        {
          "label": "Asia/Dhaka",
        },
        {
          "label": "Europe/Brussels",
        },
        {
          "label": "Africa/Ouagadougou",
        },
        {
          "label": "Europe/Sofia",
        },
        {
          "label": "Asia/Bahrain",
        },
        {
          "label": "Africa/Bujumbura",
        },
        {
          "label": "Africa/Porto-Novo",
        },
        {
          "label": "America/St_Barthelemy",
        },
        {
          "label": "Atlantic/Bermuda",
        },
        {
          "label": "Asia/Brunei",
        },
        {
          "label": "America/La_Paz",
        },
        {
          "label": "America/Kralendijk",
        },
        {
          "label": "America/Araguaina",
        },
        {
          "label": "America/Bahia",
        },
        {
          "label": "America/Belem",
        },
        {
          "label": "America/Boa_Vista",
        },
        {
          "label": "America/Campo_Grande",
        },
        {
          "label": "America/Cuiaba",
        },
        {
          "label": "America/Eirunepe",
        },
        {
          "label": "America/Fortaleza",
        },
        {
          "label": "America/Maceio",
        },
        {
          "label": "America/Manaus",
        },
        {
          "label": "America/Noronha",
        },
        {
          "label": "America/Porto_Velho",
        },
        {
          "label": "America/Recife",
        },
        {
          "label": "America/Rio_Branco",
        },
        {
          "label": "America/Santarem",
        },
        {
          "label": "America/Sao_Paulo",
        },
        {
          "label": "America/Nassau",
        },
        {
          "label": "Asia/Thimphu",
        },
        {
          "label": "Africa/Gaborone",
        },
        {
          "label": "Europe/Minsk",
        },
        {
          "label": "America/Belize",
        },
        {
          "label": "America/Atikokan",
        },
        {
          "label": "America/Blanc-Sablon",
        },
        {
          "label": "America/Cambridge_Bay",
        },
        {
          "label": "America/Creston",
        },
        {
          "label": "America/Dawson",
        },
        {
          "label": "America/Dawson_Creek",
        },
        {
          "label": "America/Edmonton",
        },
        {
          "label": "America/Fort_Nelson",
        },
        {
          "label": "America/Glace_Bay",
        },
        {
          "label": "America/Goose_Bay",
        },
        {
          "label": "America/Halifax",
        },
        {
          "label": "America/Inuvik",
        },
        {
          "label": "America/Iqaluit",
        },
        {
          "label": "America/Moncton",
        },
        {
          "label": "America/Nipigon",
        },
        {
          "label": "America/Pangnirtung",
        },
        {
          "label": "America/Rainy_River",
        },
        {
          "label": "America/Rankin_Inlet",
        },
        {
          "label": "America/Regina",
        },
        {
          "label": "America/Resolute",
        },
        {
          "label": "America/St_Johns",
        },
        {
          "label": "America/Swift_Current",
        },
        {
          "label": "America/Thunder_Bay",
        },
        {
          "label": "America/Toronto",
        },
        {
          "label": "America/Vancouver",
        },
        {
          "label": "America/Whitehorse",
        },
        {
          "label": "America/Winnipeg",
        },
        {
          "label": "America/Yellowknife",
        },
        {
          "label": "Indian/Cocos",
        },
        {
          "label": "Africa/Kinshasa",
        },
        {
          "label": "Africa/Lubumbashi",
        },
        {
          "label": "Africa/Bangui",
        },
        {
          "label": "Africa/Brazzaville",
        },
        {
          "label": "Europe/Zurich",
        },
        {
          "label": "Africa/Abidjan",
        },
        {
          "label": "Pacific/Rarotonga",
        },
        {
          "label": "America/Punta_Arenas",
        },
        {
          "label": "America/Santiago",
        },
        {
          "label": "Pacific/Easter",
        },
        {
          "label": "Africa/Douala",
        },
        {
          "label": "Asia/Shanghai",
        },
        {
          "label": "Asia/Urumqi",
        },
        {
          "label": "America/Bogota",
        },
        {
          "label": "America/Costa_Rica",
        },
        {
          "label": "America/Havana",
        },
        {
          "label": "Atlantic/Cape_Verde",
        },
        {
          "label": "America/Curacao",
        },
        {
          "label": "Indian/Christmas",
        },
        {
          "label": "Asia/Famagusta",
        },
        {
          "label": "Asia/Nicosia",
        },
        {
          "label": "Europe/Prague",
        },
        {
          "label": "Europe/Berlin",
        },
        {
          "label": "Europe/Busingen",
        },
        {
          "label": "Africa/Djibouti",
        },
        {
          "label": "Europe/Copenhagen",
        },
        {
          "label": "America/Dominica",
        },
        {
          "label": "America/Santo_Domingo",
        },
        {
          "label": "Africa/Algiers",
        },
        {
          "label": "America/Guayaquil",
        },
        {
          "label": "Pacific/Galapagos",
        },
        {
          "label": "Europe/Tallinn",
        },
        {
          "label": "Africa/Cairo",
        },
        {
          "label": "Africa/El_Aaiun",
        },
        {
          "label": "Africa/Asmara",
        },
        {
          "label": "Africa/Ceuta",
        },
        {
          "label": "Atlantic/Canary",
        },
        {
          "label": "Europe/Madrid",
        },
        {
          "label": "Africa/Addis_Ababa",
        },
        {
          "label": "Europe/Helsinki",
        },
        {
          "label": "Pacific/Fiji",
        },
        {
          "label": "Atlantic/Stanley",
        },
        {
          "label": "Pacific/Chuuk",
        },
        {
          "label": "Pacific/Kosrae",
        },
        {
          "label": "Pacific/Pohnpei",
        },
        {
          "label": "Atlantic/Faroe",
        },
        {
          "label": "Europe/Paris",
        },
        {
          "label": "Africa/Libreville",
        },
        {
          "label": "Europe/London",
        },
        {
          "label": "America/Grenada",
        },
        {
          "label": "Asia/Tbilisi",
        },
        {
          "label": "America/Cayenne",
        },
        {
          "label": "Europe/Guernsey",
        },
        {
          "label": "Africa/Accra",
        },
        {
          "label": "Europe/Gibraltar",
        },
        {
          "label": "America/Danmarkshavn",
        },
        {
          "label": "America/Nuuk",
        },
        {
          "label": "America/Scoresbysund",
        },
        {
          "label": "America/Thule",
        },
        {
          "label": "Africa/Banjul",
        },
        {
          "label": "Africa/Conakry",
        },
        {
          "label": "America/Guadeloupe",
        },
        {
          "label": "Africa/Malabo",
        },
        {
          "label": "Europe/Athens",
        },
        {
          "label": "Atlantic/South_Georgia",
        },
        {
          "label": "America/Guatemala",
        },
        {
          "label": "Pacific/Guam",
        },
        {
          "label": "Africa/Bissau",
        },
        {
          "label": "America/Guyana",
        },
        {
          "label": "Asia/Hong_Kong",
        },
        {
          "label": "America/Tegucigalpa",
        },
        {
          "label": "Europe/Zagreb",
        },
        {
          "label": "America/Port-au-Prince",
        },
        {
          "label": "Europe/Budapest",
        },
        {
          "label": "Asia/Jakarta",
        },
        {
          "label": "Asia/Jayapura",
        },
        {
          "label": "Asia/Makassar",
        },
        {
          "label": "Asia/Pontianak",
        },
        {
          "label": "Europe/Dublin",
        },
        {
          "label": "Asia/Jerusalem",
        },
        {
          "label": "Europe/Isle_of_Man",
        },
        {
          "label": "Asia/Kolkata",
        },
        {
          "label": "Indian/Chagos",
        },
        {
          "label": "Asia/Baghdad",
        },
        {
          "label": "Asia/Tehran",
        },
        {
          "label": "Atlantic/Reykjavik",
        },
        {
          "label": "Europe/Rome",
        },
        {
          "label": "Europe/Jersey",
        },
        {
          "label": "America/Jamaica",
        },
        {
          "label": "Asia/Amman",
        },
        {
          "label": "Asia/Tokyo",
        },
        {
          "label": "Africa/Nairobi",
        },
        {
          "label": "Asia/Bishkek",
        },
        {
          "label": "Asia/Phnom_Penh",
        },
        {
          "label": "Pacific/Enderbury",
        },
        {
          "label": "Pacific/Kiritimati",
        },
        {
          "label": "Pacific/Tarawa",
        },
        {
          "label": "Indian/Comoro",
        },
        {
          "label": "America/St_Kitts",
        },
        {
          "label": "Asia/Pyongyang",
        },
        {
          "label": "Asia/Seoul",
        },
        {
          "label": "Asia/Kuwait",
        },
        {
          "label": "America/Cayman",
        },
        {
          "label": "Asia/Almaty",
        },
        {
          "label": "Asia/Aqtau",
        },
        {
          "label": "Asia/Aqtobe",
        },
        {
          "label": "Asia/Atyrau",
        },
        {
          "label": "Asia/Oral",
        },
        {
          "label": "Asia/Qostanay",
        },
        {
          "label": "Asia/Qyzylorda",
        },
        {
          "label": "Asia/Vientiane",
        },
        {
          "label": "Asia/Beirut",
        },
        {
          "label": "America/St_Lucia",
        },
        {
          "label": "Europe/Vaduz",
        },
        {
          "label": "Asia/Colombo",
        },
        {
          "label": "Africa/Monrovia",
        },
        {
          "label": "Africa/Maseru",
        },
        {
          "label": "Europe/Vilnius",
        },
        {
          "label": "Europe/Luxembourg",
        },
        {
          "label": "Europe/Riga",
        },
        {
          "label": "Africa/Tripoli",
        },
        {
          "label": "Africa/Casablanca",
        },
        {
          "label": "Europe/Monaco",
        },
        {
          "label": "Europe/Chisinau",
        },
        {
          "label": "Europe/Podgorica",
        },
        {
          "label": "America/Marigot",
        },
        {
          "label": "Indian/Antananarivo",
        },
        {
          "label": "Pacific/Kwajalein",
        },
        {
          "label": "Pacific/Majuro",
        },
        {
          "label": "Europe/Skopje",
        },
        {
          "label": "Africa/Bamako",
        },
        {
          "label": "Asia/Yangon",
        },
        {
          "label": "Asia/Choibalsan",
        },
        {
          "label": "Asia/Hovd",
        },
        {
          "label": "Asia/Ulaanbaatar",
        },
        {
          "label": "Asia/Macau",
        },
        {
          "label": "Pacific/Saipan",
        },
        {
          "label": "America/Martinique",
        },
        {
          "label": "Africa/Nouakchott",
        },
        {
          "label": "America/Montserrat",
        },
        {
          "label": "Europe/Malta",
        },
        {
          "label": "Indian/Mauritius",
        },
        {
          "label": "Indian/Maldives",
        },
        {
          "label": "Africa/Blantyre",
        },
        {
          "label": "America/Bahia_Banderas",
        },
        {
          "label": "America/Cancun",
        },
        {
          "label": "America/Chihuahua",
        },
        {
          "label": "America/Hermosillo",
        },
        {
          "label": "America/Matamoros",
        },
        {
          "label": "America/Mazatlan",
        },
        {
          "label": "America/Merida",
        },
        {
          "label": "America/Mexico_City",
        },
        {
          "label": "America/Monterrey",
        },
        {
          "label": "America/Ojinaga",
        },
        {
          "label": "America/Tijuana",
        },
        {
          "label": "Asia/Kuala_Lumpur",
        },
        {
          "label": "Asia/Kuching",
        },
        {
          "label": "Africa/Maputo",
        },
        {
          "label": "Africa/Windhoek",
        },
        {
          "label": "Pacific/Noumea",
        },
        {
          "label": "Africa/Niamey",
        },
        {
          "label": "Pacific/Norfolk",
        },
        {
          "label": "Africa/Lagos",
        },
        {
          "label": "America/Managua",
        },
        {
          "label": "Europe/Amsterdam",
        },
        {
          "label": "Europe/Oslo",
        },
        {
          "label": "Asia/Kathmandu",
        },
        {
          "label": "Pacific/Nauru",
        },
        {
          "label": "Pacific/Niue",
        },
        {
          "label": "Pacific/Auckland",
        },
        {
          "label": "Pacific/Chatham",
        },
        {
          "label": "Asia/Muscat",
        },
        {
          "label": "America/Panama",
        },
        {
          "label": "America/Lima",
        },
        {
          "label": "Pacific/Gambier",
        },
        {
          "label": "Pacific/Marquesas",
        },
        {
          "label": "Pacific/Tahiti",
        },
        {
          "label": "Pacific/Bougainville",
        },
        {
          "label": "Pacific/Port_Moresby",
        },
        {
          "label": "Asia/Manila",
        },
        {
          "label": "Asia/Karachi",
        },
        {
          "label": "Europe/Warsaw",
        },
        {
          "label": "America/Miquelon",
        },
        {
          "label": "Pacific/Pitcairn",
        },
        {
          "label": "America/Puerto_Rico",
        },
        {
          "label": "Asia/Gaza",
        },
        {
          "label": "Asia/Hebron",
        },
        {
          "label": "Atlantic/Azores",
        },
        {
          "label": "Atlantic/Madeira",
        },
        {
          "label": "Europe/Lisbon",
        },
        {
          "label": "Pacific/Palau",
        },
        {
          "label": "America/Asuncion",
        },
        {
          "label": "Asia/Qatar",
        },
        {
          "label": "Indian/Reunion",
        },
        {
          "label": "Europe/Bucharest",
        },
        {
          "label": "Europe/Belgrade",
        },
        {
          "label": "Asia/Anadyr",
        },
        {
          "label": "Asia/Barnaul",
        },
        {
          "label": "Asia/Chita",
        },
        {
          "label": "Asia/Irkutsk",
        },
        {
          "label": "Asia/Kamchatka",
        },
        {
          "label": "Asia/Khandyga",
        },
        {
          "label": "Asia/Krasnoyarsk",
        },
        {
          "label": "Asia/Magadan",
        },
        {
          "label": "Asia/Novokuznetsk",
        },
        {
          "label": "Asia/Novosibirsk",
        },
        {
          "label": "Asia/Omsk",
        },
        {
          "label": "Asia/Sakhalin",
        },
        {
          "label": "Asia/Srednekolymsk",
        },
        {
          "label": "Asia/Tomsk",
        },
        {
          "label": "Asia/Ust-Nera",
        },
        {
          "label": "Asia/Vladivostok",
        },
        {
          "label": "Asia/Yakutsk",
        },
        {
          "label": "Asia/Yekaterinburg",
        },
        {
          "label": "Europe/Astrakhan",
        },
        {
          "label": "Europe/Kaliningrad",
        },
        {
          "label": "Europe/Kirov",
        },
        {
          "label": "Europe/Moscow",
        },
        {
          "label": "Europe/Samara",
        },
        {
          "label": "Europe/Saratov",
        },
        {
          "label": "Europe/Ulyanovsk",
        },
        {
          "label": "Europe/Volgograd",
        },
        {
          "label": "Africa/Kigali",
        },
        {
          "label": "Asia/Riyadh",
        },
        {
          "label": "Pacific/Guadalcanal",
        },
        {
          "label": "Indian/Mahe",
        },
        {
          "label": "Africa/Khartoum",
        },
        {
          "label": "Europe/Stockholm",
        },
        {
          "label": "Asia/Singapore",
        },
        {
          "label": "Atlantic/St_Helena",
        },
        {
          "label": "Europe/Ljubljana",
        },
        {
          "label": "Arctic/Longyearbyen",
        },
        {
          "label": "Europe/Bratislava",
        },
        {
          "label": "Africa/Freetown",
        },
        {
          "label": "Europe/San_Marino",
        },
        {
          "label": "Africa/Dakar",
        },
        {
          "label": "Africa/Mogadishu",
        },
        {
          "label": "America/Paramaribo",
        },
        {
          "label": "Africa/Juba",
        },
        {
          "label": "Africa/Sao_Tome",
        },
        {
          "label": "America/El_Salvador",
        },
        {
          "label": "America/Lower_Princes",
        },
        {
          "label": "Asia/Damascus",
        },
        {
          "label": "Africa/Mbabane",
        },
        {
          "label": "America/Grand_Turk",
        },
        {
          "label": "Africa/Ndjamena",
        },
        {
          "label": "Indian/Kerguelen",
        },
        {
          "label": "Africa/Lome",
        },
        {
          "label": "Asia/Bangkok",
        },
        {
          "label": "Asia/Dushanbe",
        },
        {
          "label": "Pacific/Fakaofo",
        },
        {
          "label": "Asia/Dili",
        },
        {
          "label": "Asia/Ashgabat",
        },
        {
          "label": "Africa/Tunis",
        },
        {
          "label": "Pacific/Tongatapu",
        },
        {
          "label": "Europe/Istanbul",
        },
        {
          "label": "America/Port_of_Spain",
        },
        {
          "label": "Pacific/Funafuti",
        },
        {
          "label": "Asia/Taipei",
        },
        {
          "label": "Africa/Dar_es_Salaam",
        },
        {
          "label": "Europe/Kiev",
        },
        {
          "label": "Europe/Simferopol",
        },
        {
          "label": "Europe/Uzhgorod",
        },
        {
          "label": "Europe/Zaporozhye",
        },
        {
          "label": "Africa/Kampala",
        },
        {
          "label": "Pacific/Midway",
        },
        {
          "label": "Pacific/Wake",
        },
        {
          "label": "America/Adak",
        },
        {
          "label": "America/Anchorage",
        },
        {
          "label": "America/Boise",
        },
        {
          "label": "America/Chicago",
        },
        {
          "label": "America/Denver",
        },
        {
          "label": "America/Detroit",
        },
        {
          "label": "America/Indiana/Indianapolis",
        },
        {
          "label": "America/Indiana/Knox",
        },
        {
          "label": "America/Indiana/Marengo",
        },
        {
          "label": "America/Indiana/Petersburg",
        },
        {
          "label": "America/Indiana/Tell_City",
        },
        {
          "label": "America/Indiana/Vevay",
        },
        {
          "label": "America/Indiana/Vincennes",
        },
        {
          "label": "America/Indiana/Winamac",
        },
        {
          "label": "America/Juneau",
        },
        {
          "label": "America/Kentucky/Louisville",
        },
        {
          "label": "America/Kentucky/Monticello",
        },
        {
          "label": "America/Los_Angeles",
        },
        {
          "label": "America/Menominee",
        },
        {
          "label": "America/Metlakatla",
        },
        {
          "label": "America/New_York",
        },
        {
          "label": "America/Nome",
        },
        {
          "label": "America/North_Dakota/Beulah",
        },
        {
          "label": "America/North_Dakota/Center",
        },
        {
          "label": "America/North_Dakota/New_Salem",
        },
        {
          "label": "America/Phoenix",
        },
        {
          "label": "America/Sitka",
        },
        {
          "label": "America/Yakutat",
        },
        {
          "label": "Pacific/Honolulu",
        },
        {
          "label": "America/Montevideo",
        },
        {
          "label": "Asia/Samarkand",
        },
        {
          "label": "Asia/Tashkent"
        },
        {
          "label": "Europe/Vatican",
        },
        {
          "label": "America/St_Vincent",
        },
        {
          "label": "America/Caracas",
        },
        {
          "label": "America/Tortola",
        },
        {
          "label": "America/St_Thomas",
        },
        {
          "label": "Asia/Ho_Chi_Minh",
        },
        {
          "label": "Pacific/Efate",
        },
        {
          "label": "Pacific/Wallis",
        },
        {
          "label": "Pacific/Apia",
        },
        {
          "label": "Asia/Aden",
        },
        {
          "label": "Indian/Mayotte",
        },
        {
          "label": "Africa/Johannesburg",
        },
        {
          "label": "Africa/Lusaka",
        },
        {
          "label": "Africa/Harare",
        }
      ]
}