import React from 'react'
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import InfoIcon from '@material-ui/icons/Info';

const DeleteConfirmMessage = (props) => {

    const {openDialog, onConfirm, onCancel, message} = props
    
    return (
        
                       
            <Dialog
                open={openDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="card ">
                    <div className="card-body d-flex" style={{ height: 300 }}>
                        <div className="m-auto" style={{ width: '400px' }}>
                            <InfoIcon style={{ color: '#4783dc', fontSize: '60px' }} className="text-center mb-10 d-block m-auto" />
                            <div className="">
                               
                                    <p className="font-16 font-weight-bold text-center mb-5">
                                        {message?message : 'Are you sure you want to Delete.'}
                                    </p>
                              
                  
                            </div>
                        </div>
                    </div>
                    <div className="card-footer border-top pd-10 justify-end d-flex align-center">
                        <button className="btn btn-outline-dark-grey" onClick={() => onCancel()}>
                            Cancel
                        </button>
                        <button onClick={() => onConfirm()}className="btn btn-primary-v2" > 
                            Confirm
                        </button>
                    </div>
                </div>
            </Dialog>

        
    )
}

DeleteConfirmMessage.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };

export default DeleteConfirmMessage
