import React from "react";
import PropTypes from 'prop-types';
import '../../assets/style.css';
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

const BottomNavigator = (props) => {
    const hideBackButton = props.hideBackButton;
    const nextButtonText = props.nextButtonText;
    const backDisabled = props.backDisabled
    const nextDisabled = props.nextDisabled 
    const handleBackFormClick = props.backClickHandler;
    const handleNextFormClick = props.nextClickHandler;
    return(
        <div style={{backgroundColor:'#f9f9f9', padding:'12px', display:'grid', justifyContent:'space-between', gridTemplateColumns:'auto auto', border:'1px solid #ccc', borderRadius:'0px 0px 8px 8px'}}>
            {
                !hideBackButton ?
                <button className="btn-grey-navi hover-state" disabled={backDisabled} onClick={handleBackFormClick}><ChevronLeftIcon /> Back</button>
                : <div></div>
            }
            <button style={{width:'150px!important'}} className="btn-blue-navi hover-state" disabled={nextDisabled} onClick={handleNextFormClick}>
                {nextButtonText ? nextButtonText : "Continue"} <ChevronRightIcon />
            </button>
        </div> 
    );
}

BottomNavigator.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export default BottomNavigator;