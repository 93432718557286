import React from 'react';
import { Dialog, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TopHeaderPremium from './TopHeaderPremium';
import { useState } from 'react';
import PremiumFeatureQueryForm from './PremiumFeatureQueryForm';
import FinalStageQuery from './FinalStageQuery';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})

const premiumFeatureList = [
    {
        name: 'hyper-build',
        description: 'Drastic reduction in build time using Hyperbuild',
        currentPlan: false,
        premiumPlan: true
    },
    {
        name: 'downtime-scheduler',
        description: 'Costing Saving by Sleeping pod and Downtime Scheduler',
        currentPlan: false,
        premiumPlan: true
    },
    {
        name: 'maturity-insights',
        description: 'Maturity Insights',
        currentPlan: false,
        premiumPlan: true
    },
    {
        name: 'Velocity Insights',
        description: 'Velocity Insights',
        currentPlan: false,
        premiumPlan: true
    },
    {
        name: '24/7 Buildpiper team Support',
        description: '24/7 Buildpiper team Support',
        currentPlan: false,
        premiumPlan: true
    },

]

const PremiumFeaturesStoped = ({ open, onClose, featureName }) => {

    const classes = useStyles();
    const [state, setState] = useState({
        viewType: 1,
        open: open ? true : false
    })

    const handleSubmitForm = (data) => {

        console.log('data_00l', data)
        setState(prevState => ({
            ...prevState,
            viewType: 3
        }))
    }

    const handleClose = () => {
        onClose()
    }
    const handleChangeViewType=(viewType)=>{
        setState(prevState => ({
            ...prevState,
            viewType: viewType
        }))
    }   

    return (
        <Dialog
            fullWidth
            maxWidth='lg'
            open={open}
            onClose={() => { }}
            PaperProps={{
                className: classes.paper,
            }}
            aria-labelledby="max-width-dialog-title"
            style={{ overflow: "visible", }}
            TransitionComponent={Transition}
        >
            <>
                <div className={classes.topBanner}>
                    <div style={{width:'100%'}} className='d-flex align-center justify-center'>
                        <div className='caption mr-5'>{`${featureName}`}</div>
                        <span className='ri-vip-crown-line mr-5'></span>
                        <div className='caption'>is a Premium Feature</div>
                    </div>
                </div>
                {
                    state.viewType == 1 ?
                        <div className={classes.root}>
                            <TopHeaderPremium onClose={handleClose} />
                            <div className='middle'>
                                <table style={{
                                    width: '100%'
                                }}>
                                    <tr>
                                        <th className='font-16 font-weight-700' style={{ textAlign: 'left', textTransform: 'uppercase' }}>
                                            What you will get
                                        </th>
                                        <th className='font-16 font-weight-600'>
                                            {'Standard Plan (active)'}
                                        </th>
                                        <th>
                                            <div className='d-flex justify-center'>
                                                <img style={{ width: '37px', height: '18px', marginRight: '10px' }} src='/images/BP-new.svg' alt='buildpiper' />
                                                <span className='premium-op'>
                                                    premium
                                                </span>
                                            </div>
                                        </th>
                                    </tr>
                                    {
                                        premiumFeatureList?.map(item => {
                                            return (
                                                <tr style={{ height: '40px' }}>
                                                    <td>
                                                        {item.description}
                                                    </td>
                                                    <td style={{ textAlign: 'center' }}>
                                                        -
                                                    </td>
                                                    <td style={{ textAlign: 'center' }}>
                                                        <CheckCircleIcon style={{ color: '#124D9B', fontSize: '20px' }} />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </table>
                            </div>
                            <div className='bottom'>
                                <button onClick={() => setState(prevState => ({ ...prevState, viewType: 2 }))}  className='con-sales'>
                                    <span style={{ color: '#ffff', fontSize: '24px' }} className='ri-phone-line'>

                                    </span>
                                    <div className='con-text'>
                                        Contact Sales
                                    </div>
                                </button>
                            </div>
                        </div> :

                        state.viewType == 2 ?
                            < PremiumFeatureQueryForm onChangeVeiw={handleChangeViewType} onClose={handleClose} onSubmit={handleSubmitForm} />
                            :
                            state.viewType == 3 ?
                                <FinalStageQuery  onClose={handleClose} />
                                : null
                }
            </>
        </Dialog >
    )
}

export default PremiumFeaturesStoped;

const useStyles = makeStyles({
    topBanner: {
        width: '333px',
        height: '38px',
        top: '66px',
        padding: '9px 19px 12px 19px',
        borderRadius: '10px 10px 0px 0px',
        color: '#fff',
        boxShadow: '0px -8px 12px 0px #00000040 inset',
        background: '#a0a0a0',
        position: 'absolute',
        top: '-38px',
        '& .caption': {
            fontSize: '14px',
            fontWeight: '500',
            lineHeight: '17.07px',
            textAlign: 'center',
        }
    },
    root: {
        width: '100%',
        boxShadow: '0px 8px 24px 0px #0000000A',
        background: '#FFFFFF',
        borderRadius: '12px',

        '& .middle': {
            padding: '20px',
            borderBottom: '1px solid #D0D0D0'
        },
        '& .bottom': {
            height: '72px',
            padding: '16px 20px 16px 20px',
            '& .con-sales': {
                float: 'right',
                width: '160px',
                height: '40px',
                borderRadius: '7px',
                background: '#0086FF',
                border: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            '& .con-text': {
                color: '#fff',
                fontSize: '12px',
                fontWeight: '600',
                lineHeight: '14.63px',
                textAlign: 'left',
                textTransform: 'uppercase',
                marginLeft: '5px'
            }
        },
        '& .premium-op': {
            color: '#FDAF36',
            fontSize: '15.59px',
            fontWeight: '700',
            lineHeight: '19px',
            textAlign: 'center',
            textTransform: 'uppercase'
        }
    },
    paper: {
        margin: 'auto',
        width: '951px', // Set the desired width
        display: 'flex',
        justifyContent: 'center', // Center horizontally
        alignItems: 'center', // Center vertically,
        boxShadow: '0px 8px 24px 0px #0000000A',
        borderRadius: '12px',
        overflow: 'visible'
        // position: 'relative'

    }

});

