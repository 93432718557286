import React, { useState } from 'react';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import { Input } from '../../../../components/genericComponents/Input';
import { VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
import DynamicFilterField from './DynamicFilterField';
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import properties from '../../../../properties/properties';
import { Grid } from '@material-ui/core';
import { Loading } from '../../../utils/Loading';
import { makeStyles } from "@material-ui/core/styles";


const CheckConflicts = ({ pipeline, 
    pipelineVars, 
    jiraIssueKeys, 
    jobStepOrderOutputVars,
    dynamicValidationFormError,
    setParentState, 
    parentState,
    ...props }) => {
    const inherits = props.inherits ? props.inherits : {};
    const prev_state = props.prev_state;
    const jira_reference_key_list = props.jira_reference_key_list ? props.jira_reference_key_list : [];
    const get_run_conditions_function = props.get_run_conditions_function;
    const issue_keys_list = [...get_run_conditions_function().jira_id_reference_keys];
    const default_job_template = props.default_job_template;
    jira_reference_key_list.forEach(element => {
        issue_keys_list.push({ label: element.label, id: element.label })
    });
    const [state, setState] = useState(getCheckConflictsDefaultState(prev_state, issue_keys_list))
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    const provider_list = [
        {
            label: "GitHub",
            value: "GitHub"
        },
        {
            label: "BitBucket",
            value: "bitbucket"
        },
        {
            label: "Gitlab",
            value: "Gitlab",
        }
    ]

    const pick_stories_from = [
        {
            label:'Jira Stroies',
            value: 'jira_stories',
        },
        {
            label:'Release Package',
            value: 'release_package',
        },
    ]
    console.log("jcndjnjfr",state);
  return (
    <>
                                        <Input
                                            type="radio"
                                            name="provider"
                                            label="Select your provider"
                                            list={provider_list}
                                            mandatorySign
                                            onChangeHandler={commonFunctions.onChangeHandler}
                                            data={state.data}
                                            error={state.error} />
                                        <Input
                                            type="radio"
                                            name="pick_stories_from"
                                            label="Pick Stories From"
                                            list={pick_stories_from}
                                            mandatorySign
                                            onChangeHandler={commonFunctions.onChangeHandler}
                                            data={state.data}
                                            error={state.error} />
                                        <DynamicFilterField
                                            jiraIssueKeys={jiraIssueKeys}
                                            pipelineVars={pipelineVars}
                                            jobStepOrderOutputVars={jobStepOrderOutputVars}
                                            setParentState={setState}
                                            parentState={state}
                                            prev_state={prev_state}
                                            dynamicValidationFormError={dynamicValidationFormError}
                                            setTopParentState={setParentState}
                                            topParenState={parentState}
                                        >
                                            <Input
                                                type="select"
                                                name="jira_issue_list"
                                                list={issue_keys_list}  // get_run_conditions_function().jira_id_reference_keys
                                                label={state.data.pick_stories_from == "jira_stories" ? "Select Validated Jira List" : "Select Validated Branch List"}
                                                placeholder="Jira id refrence key"
                                                mandatorySign
                                                onChangeHandler={commonFunctions.onChangeHandler}
                                                data={state.data}
                                                error={state.error} />
                                        </DynamicFilterField>
                                        <Input          
                                        type="text"
                                        name="destination_branch"
                                        label={"Enter Destination Branch"}
                                        mandatorySign
                                        placeholder={"Destination Branch..."}
                                        onChangeHandler={commonFunctions.onChangeHandler}
                                        data={state.data}
                                        error={state.error} />
    </>
  )
}

function getCheckConflictsDefaultState(prev_state, issue_keys_list) {
    return {
        data: prev_state ? {
            //issue_key: prev_state.issue_key,
            jira_issue_list: prev_state.jira_issue_list,
            destination_branch: prev_state.destination_branch,
            provider: prev_state.provider ? prev_state.provider : "bitbucket",
            pick_stories_from: prev_state.pick_stories_from || 'jira_stories',
        } : {
            jira_issue_list: issue_keys_list.length > 0 ? issue_keys_list[0].id : null,
            provider: "bitbucket",
            pick_stories_from: 'jira_stories',
        },
        error: {},
        dynamic_fields:{},
        validations: {
            //issue_key: [VALIDATION_TYPE_REQUIRED],
            destination_branch: [VALIDATION_TYPE_REQUIRED],
            provider: [VALIDATION_TYPE_REQUIRED],
            pick_stories_from: [VALIDATION_TYPE_REQUIRED],
        },
    };
}

export default CheckConflicts