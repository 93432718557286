import React,{useState} from 'react';
import  {RemoveLoginCreds} from "../util/security";
import { Redirect } from 'react-router-dom';



export const LogOutView =(props)=>{
    const [state, setState]  = useState({});
    const handleLogOutResponse=(data)=>{
        // initializeRoutes();
        setState({
            ...state, logoutStatus:"done"
        })
    }
    const handleLogOutFailure=(data)=>{
        setState({
            ...state, logoutStatus:"failed"
        })
    }
    function initiateLogout(){
        RemoveLoginCreds(handleLogOutResponse,handleLogOutFailure);
    }
    
    
    return(
        <>
           {!state.logoutStatus? initiateLogout():state.logoutStatus=="done"?
           <p><Redirect to="/login"/> </p>
           :<h2>Logout Failed. Please Try again</h2>}
        </>
        
    );
}

