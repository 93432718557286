import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import BorderColorIcon from '@material-ui/icons/BorderColor';
import { getEnvDeployVarsWithCategories, getEnvDeployVarsWithCategoriesSidecars } from '../../../../../serviceRevamp/add/cd_flow/CdInfo';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Tooltip from '@material-ui/core/Tooltip';
import GenerateURL from '../../../../../../util/APIUrlProvider';
import properties from '../../../../../../properties/properties';
import { GetAuth } from '../../../../../../util/security';
import EditDetailsConfirmationDialog from './EditDetailsConfirmationDialog';
import useFetchPermissions from '../../../../../../components/customHooks/useFetchPermissions';

const EnvVarOverview = (props) => {
    const prev_state = props.prev_state;
    const cd_id = prev_state && prev_state.id ? prev_state.id : null
    const [showLoading, setShowLoading] = useState(false);

    const [state, setState] = useState({
        env_cd_deploy_variable: [],
        showValue: false,
        showConfigValue: false,
        showSecretValue: false
    });
    const extraProps = props.extraProps;
    const application_id = extraProps.application_id;
    const auto_approval_flag = extraProps.auto_approval_flag;
    const service_id = extraProps.service_id;
    const component_env_id = extraProps.component_env_id;
    const show_sidecar_overview = extraProps.show_sidecar_overview;
    const env_cd_id = extraProps.env_cd_id;
    const service_env_data = extraProps.service_env_data;
    var url = GenerateURL({ service_id: service_id, component_env_id: component_env_id }, properties.api.save_ci, true);
    const is_permitted = useFetchPermissions()?.POST.includes(url);
    useEffect(() => {
        setState(new_state => ({
            ...new_state, ...prev_state
        })
        )
    }, [prev_state])

    console.log(state, "rtfgvb========>")

    const handleToggle = () => {
        setState({
            ...state,
            showValue: !state.showValue,
        })
    }
    const handleToggleConfig = () => {
        setState({
            ...state,
            showConfigValue: !state.showConfigValue,
        })
    }
    const handleToggleSecret = () => {
        setState({
            ...state,
            showSecretValue: !state.showSecretValue
        })
    }
    var envVarCategories = getEnvDeployVarsWithCategories(state.env_cd_deploy_variable);
    var envVarCategoriesSidecars = getEnvDeployVarsWithCategoriesSidecars(state.env_cd_init_container_env_variable)
    console.log(state, "ffff")

    // const env_cd_secrets = getEnvCdSecrets()

    // function getEnvCdSecrets(){

    //     const arr = [];
    //     state.env_cd_secret.forEach((item) => {
    //         let obj = {id : }

    //     })
    // }


    return (
        <>
            {
                show_sidecar_overview ?
                    <div className="section-service-overview" style={{ border: '0px', padding: '10px' }}>
                        <div className="d-flex align-center space-between">
                            <div className="overview-heading">
                                Deploy Variables
                            </div>
                            {is_permitted ?

                                state.status == "DRAFT" ?
                                    <Link to={{
                                        pathname: "/application/" + application_id + "/service/" + service_id + "/env/" + component_env_id + "/sidecars/edit/?env_cd_detail_id=" + env_cd_id,
                                        query: {
                                            selectedTabOrder: 4,
                                            sidecar_id: state.id,
                                            env_cd_id: env_cd_id,
                                            service_env_data: service_env_data
                                        }
                                    }}>
                                        <BorderColorIcon style={{ color: '#0086ff' }} />
                                    </Link> :
                                    <EditDetailsConfirmationDialog
                                        application_id={application_id}
                                        service_id={service_id}
                                        auto_approval_flag={auto_approval_flag}
                                        component_env_id={component_env_id}
                                        selectedTabOrder={4}
                                        sidecar_id={state.id}
                                        env_cd_id={env_cd_id}
                                        service_env_data={service_env_data}
                                    />

                                : <Tooltip title="You are not allowed to perform this action" >
                                    <BorderColorIcon style={{ color: '#818078' }} />
                                </Tooltip>}

                        </div>
                        <div className="overview-card-section grid-template-3">
                            <div className="overview-single-card">
                                <div className="overview-card-heading-section">
                                    <p className="overview-card-heading">
                                        Raw Key Value Pair
                                    </p>
                                    
                                        {envVarCategoriesSidecars.keyVal && envVarCategoriesSidecars.keyVal.length > 0 ?
                                            <span className="ml-auto d-inline-block" onKeyDown={()=>{}} onClick={handleToggle} tabIndex={0} role='button'>{state.showValue ?
                                                <VisibilityIcon style={{ color: '#ccc', fontSize: '20px' }} /> :
                                                <VisibilityOffIcon style={{ color: '#ccc', fontSize: '20px' }} />}
                                            </span>
                                            : null}
                                    

                                </div>
                                <div className="overview-card-value-env-var">
                                    <div className="heading-key-value">
                                        <p>Key</p>
                                        <p>Value</p>
                                    </div>
                                    <div className="env-variable-box">

                                        {envVarCategoriesSidecars.keyVal && envVarCategoriesSidecars.keyVal.length > 0 ?
                                            <>
                                                {envVarCategoriesSidecars.keyVal.map(item => (
                                                    <div className="data-key-value-pair">
                                                        <p>{item.env_key}</p>
                                                        <p className={!state.showValue ? "text-security" : null}>

                                                            {item.env_value}


                                                        </p>
                                                    </div>
                                                ))}
                                            </> : <p className="no-data__msg-env-var" >No Data Available</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="overview-single-card">
                                <div className="overview-card-heading-section">
                                    <p className="overview-card-heading">
                                        From ConfigMap
                                    </p>

                                    
                                        {envVarCategoriesSidecars.configMaps.length > 0 ?
                                            <span className="ml-auto d-inline-block" onKeyDown={()=>{}} onClick={handleToggleConfig} tabIndex={0} role='button'>{state.showConfigValue ?
                                                <VisibilityIcon style={{ color: '#ccc', fontSize: '20px' }} /> :
                                                <VisibilityOffIcon style={{ color: '#ccc', fontSize: '20px' }} />}
                                            </span>
                                            : null}
                                    
                                </div>
                                <div className="overview-card-value-env-var">
                                    <div className="heading-key-value three-grd">
                                        <p>Service Key</p>
                                        <p>ConfigMap Name</p>
                                        <p>ConfigMap Key</p>
                                    </div>
                                    <div className="env-variable-box">
                                        {envVarCategoriesSidecars.configMaps.length > 0 || (state.env_cd_init_container_configmap && state.env_cd_init_container_configmap.length > 0) ?
                                        <>
                                            <>
                                            {
                                                envVarCategoriesSidecars.configMaps.map(item => (
                                                
                                                    <div className="data-key-value-pair three-grd">
                                                        <p>{item.configMap_service_key}</p>
                                                        <p>{item.configMap_name}</p>
                                                        <p className={!state.showConfigValue ? "text-security" : null}>{item.configMap_key}</p>
                                                    </div>
                                                
                                            ))
                                            }
                                            </>
                                            <>
                                            {
                                                state.env_cd_init_container_configmap.map(item => (
                                                    item.mount_path == null ? 
                                                    
                                                    <div className="data-key-value-pair three-grd">
                                                        <p>{item.name}</p>
                                                        <p></p>
                                                        <p className={!state.showConfigValue ? "text-security" : null}></p>
                                                    </div>
                                                
                                                :null
                                                ))
                                            }
                                            </> 
                                            </>
                                        : <p className="no-data__msg-env-var" >No Data Available</p>}


                                    </div>
                                </div>
                            </div>
                            <div className="overview-single-card">
                                <div className="overview-card-heading-section">
                                    <p className="overview-card-heading">
                                        Secrets
                                    </p>
                                    
                                        {envVarCategoriesSidecars.secrets.length > 0 ?
                                            <span className="ml-auto d-inline-block" onKeyDown={()=>{}} onClick={handleToggleSecret} tabIndex={0} role='button'>{state.showSecretValue ?
                                                <VisibilityIcon style={{ color: '#ccc', fontSize: '20px' }} /> :
                                                <VisibilityOffIcon style={{ color: '#ccc', fontSize: '20px' }} />}
                                            </span>
                                            : null}
                                    
                                </div>
                                <div className="overview-card-value-env-var">
                                    <div className="heading-key-value three-grd">
                                        <p>Service Key</p>
                                        <p>Secret Name</p>

                                        <p>Secret Key</p>
                                    </div>
                                    
                                    
                                    <div className="env-variable-box">
                                        {envVarCategoriesSidecars.secrets.length > 0 || (state.env_cd_init_container_secret && state.env_cd_init_container_secret.length > 0) ?
                                        <>
                                            <>
                                                {
                                                    envVarCategoriesSidecars.secrets.map(item => (
                                                
                                                    <div className="data-key-value-pair three-grd">
                                                        <p>{item.secret_service_key}</p>
                                                        <p>{item.secret_name}</p>
                                                        <p className={!state.showSecretValue ? "text-security" : null}>{item.secret_key}</p>
                                                    </div>
                                                
                                            ))
                                                }
                                            </>
                                            <>
                                            {
                                                state.env_cd_init_container_secret.map(item => (
                                                    item.mount_path == null ? 
                                                    
                                                    <div className="data-key-value-pair three-grd">
                                                        <p>{item.name}</p>
                                                        <p></p>
                                                        <p className={!state.showConfigValue ? "text-security" : null}></p>
                                                    </div>
                                                
                                                :null
                                                ))
                                            }
                                            </>
                                        </>
                                            

                                            : <p className="no-data__msg-env-var" >No Data Available</p>}


                                        {/* <p className="no-data__msg-env-var" >No Data Available</p> */}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="section-service-overview" style={{ border: '0px', padding: '10px' }}>
                        <div className="d-flex align-center space-between">
                            <div className="overview-heading">
                                Deploy Variables
                            </div>
                            {is_permitted ?

                                state.status == "DRAFT" ?
                                    <Link to={{
                                        pathname: "/application/" + application_id + "/service/" + service_id + "/env/" + component_env_id +  "/cd/"+cd_id+"/edit",
                                        query: { selectedTabOrder: 5, selected_cd_id : extraProps?.selected_cd_data?.id }
                                    }}>
                                        <BorderColorIcon style={{ color: '#0086ff' }} />
                                    </Link> :
                                    <EditDetailsConfirmationDialog
                                        application_id={application_id}
                                        service_id={service_id}
                                        auto_approval_flag={auto_approval_flag}
                                        component_env_id={component_env_id}
                                        selectedTabOrder={5}
                                        cd_data={prev_state}
                                        selected_cd_id = {extraProps?.selected_cd_data?.id}
                                    />

                                : <Tooltip title="You are not allowed to perform this action" >
                                    <BorderColorIcon style={{ color: '#818078' }} />
                                </Tooltip>}

                        </div>
                        <div className="overview-card-section grid-template-3">
                            <div className="overview-single-card">
                                <div className="overview-card-heading-section">
                                    <p className="overview-card-heading">
                                        Raw Key Value Pair
                                    </p>
                                    
                                        {state.env_cd_deploy_variable[0] ?
                                            <span className="ml-auto d-inline-block" onKeyDown={()=>{}} onClick={handleToggle} role='button' tabIndex={0}>{state.showValue ?
                                                <VisibilityIcon style={{ color: '#ccc', fontSize: '20px' }} /> :
                                                <VisibilityOffIcon style={{ color: '#ccc', fontSize: '20px' }} />}
                                            </span>
                                            : null}
                                    

                                </div>
                                <div className="overview-card-value-env-var">
                                    <div className="heading-key-value">
                                        <p>Key</p>
                                        <p>Value</p>
                                    </div>
                                    <div className="env-variable-box">

                                        {state.env_cd_deploy_variable[0] ?
                                            <>
                                                {state.env_cd_deploy_variable.map(item => (
                                                    <div className="data-key-value-pair">
                                                        <p>{item.env_key}</p>
                                                        <p className={!state.showValue ? "text-security" : null}>

                                                            {item.env_value}


                                                        </p>
                                                    </div>
                                                ))}
                                            </> : <p className="no-data__msg-env-var" >No Data Available</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="overview-single-card">
                                <div className="overview-card-heading-section">
                                    <p className="overview-card-heading">
                                        From ConfigMap
                                    </p>

                                    
                                        {envVarCategories.configMaps.length > 0 ?
                                            <span className="ml-auto d-inline-block" onKeyDown={()=>{}} onClick={handleToggleConfig} tabIndex={0} role='button'>{state.showConfigValue ?
                                                <VisibilityIcon style={{ color: '#ccc', fontSize: '20px' }} /> :
                                                <VisibilityOffIcon style={{ color: '#ccc', fontSize: '20px' }} />}
                                            </span>
                                           : null}
                                    
                                </div>
                                <div className="overview-card-value-env-var">
                                    <div className="heading-key-value three-grd">
                                        <p>Service Key</p>
                                        <p>ConfigMap Name</p>
                                        <p>ConfigMap Key</p>
                                    </div>
                                    <div className="env-variable-box">
                                        {envVarCategories.configMaps.length > 0 || (state.env_cd_configmap && state.env_cd_configmap.length > 0) ?
                                            <>
                                                <>
                                                    {
                                                        envVarCategories.configMaps.map(item => (
                                                            
                                                                <div className="data-key-value-pair three-grd">
                                                                    <p>{item.configMap_service_key}</p>
                                                                    <p>{item.configMap_name}</p>
                                                                    <p className={!state.showConfigValue ? "text-security" : null}>{item.configMap_key}</p>
                                                                </div>
                                                            
                                                        ))
                                                    }
                                                </>
                                                <>
                                                    {
                                                        state.env_cd_configmap.map(item => (
                                                            item.mount_path == null ?
                                                                
                                                                    <div className="data-key-value-pair three-grd">
                                                                        <p>{item.name}</p>
                                                                        <p></p>
                                                                        <p className={!state.showConfigValue ? "text-security" : null}></p>
                                                                    </div>
                                                                
                                                                : null
                                                        ))
                                                    }
                                                </>
                                            </>

                                            : <p className="no-data__msg-env-var" >No Data Available</p>}


                                    </div>
                                </div>
                            </div>
                            <div className="overview-single-card">
                                <div className="overview-card-heading-section">
                                    <p className="overview-card-heading">
                                        Secrets
                                    </p>
                                    
                                        {envVarCategories.secrets.length > 0 ?
                                            <span className="ml-auto d-inline-block" onKeyDown={()=>{}} onClick={handleToggleSecret} tabIndex={0} role='button'>{state.showSecretValue ?
                                                <VisibilityIcon style={{ color: '#ccc', fontSize: '20px' }} /> :
                                                <VisibilityOffIcon style={{ color: '#ccc', fontSize: '20px' }} />}
                                            </span>
                                            : null}
                                    
                                </div>
                                <div className="overview-card-value-env-var">
                                    <div className="heading-key-value three-grd">
                                        <p>Service Key</p>
                                        <p>Secret Name</p>

                                        <p>Secret Key</p>
                                    </div>
                                    <div className="env-variable-box">
                                        {envVarCategories.secrets.length > 0 || (state.env_cd_secret && state.env_cd_secret.length > 0) ?
                                            <>
                                                <>
                                                    {
                                                        envVarCategories.secrets.map(item => (
                                                            
                                                                <div className="data-key-value-pair three-grd">
                                                                    <p>{item.secret_service_key}</p>
                                                                    <p>{item.secret_name}</p>
                                                                    <p className={!state.showSecretValue ? "text-security" : null}>{item.secret_key}</p>
                                                                </div>
                                                            
                                                        ))
                                                    }
                                                </>
                                                <>
                                                    {
                                                        state.env_cd_secret.map(item => (
                                                            item.mount_path == null ?
                                                                
                                                                    <div className="data-key-value-pair three-grd">
                                                                        <p>{item.name}</p>
                                                                        <p></p>
                                                                        <p className={!state.showConfigValue ? "text-security" : null}></p>
                                                                    </div>
                                                                
                                                                : null
                                                        ))
                                                    }
                                                </>
                                            </>


                                            : <p className="no-data__msg-env-var" >No Data Available</p>}


                                        {/* <p className="no-data__msg-env-var" >No Data Available</p> */}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>


    )
}

EnvVarOverview.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }
export default EnvVarOverview;