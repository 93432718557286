import React from 'react';
import PropTypes from 'prop-types';
import KeyValues from '../genericComponents/inputs/KeyValues';
import Dialog from '@material-ui/core/Dialog';

import IconButton from'@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export const ExandableKeyValues = (props) =>{
    const [open, setOpen] = React.useState(false);
    const [fullWidth] = React.useState(true);
    const [maxWidth] = React.useState('md');
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const compoenent_list = props.compoenent_list;
    const iteration_count = props.iteration_count;
    const expandable_component = props.expandable_component;
    const blank_component_msg = props.blank_component_msg ? props.blank_component_msg : "";
    const extended_component_list = [];
    const labels_list=props.labels_list;
    let new_component_list = [];
    if(compoenent_list.length > 0 ){
        compoenent_list.forEach((component,key) => {
            if(key < iteration_count){
                new_component_list.push(component);
            }else{
                extended_component_list.push(component);
            }
        });
    }else{
        return blank_component_msg;
    }
    

    if(compoenent_list.length > iteration_count){
        new_component_list.push(
            <>
            <div role='button' tabIndex={0} className="chip-default chip-blue" style= {{height:'fit-content'}} onKeyDown={()=>{}} onClick={handleClickOpen}>
                {expandable_component}
            </div>
            <div className="">
            <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="controller-dialogue-lable"
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
          <div className="d-flex space-between dialogue-header align-center">
        <h1 className="title-dialogue">{props.isLabel?"Labels":"Annotations"}</h1>
            <IconButton onClick={handleClose}>
                <CloseIcon style={{fontSize:'20px'}} />
            </IconButton>
        </div>
            <div>
            <KeyValues data={labels_list} isListing={true} isLabel={props.isLabel}/>
            </div>
            </Dialog>
            </div>
            
             
          </>
        );
    }
    return(
        <>
        {
            new_component_list.map((component)=>{
                return component
            })
        }
        </>
    );
}

ExandableKeyValues.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default ExandableKeyValues;