import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Input } from '../../../../../../../components/genericComponents/Input';
import { getCommonFunctions } from '../../../../../../serviceRevamp/add/ci_flow/SourceDetails';
import WeekDaysCheckbox from '../../../../../../../components/genericComponents/WeekDaysCheckbox';
import TimeZoneList from '../../../../../../../components/genericComponents/TimeZoneList';
import { VALIDATION_TYPE_REQUIRED } from '../../../../../../../util/Validator';

const SchedulerFrom = (props) => {
    const inherits = props.inherits ? props.inherits : {};
    const prev_state = props.prev_state ? props.prev_state : null;
    const [state, setState] = useState(prev_state ? prev_state : HpaSchedulerDefaultState())
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    const list = TimeZoneList.timeZoneList;
    function convertList(list){
        var new_list = []
        
        list.forEach(item=>(
            new_list.push({label: item.label, id: item.label})
        ))
        return new_list
    }
    function handleHours(e) {
        var k = e.target.name;
        var v = e.target.value;
        if (v >= 0 && v <= 24) {
            commonFunctions.updateData(k,v)
        }
    }

    function handleMins(e) {
        var k = e.target.name;
        var v = e.target.value;
        if (v >= 10 && v <= 59) {
            commonFunctions.updateData(k,v)
        } else if ((v >= 0 && v <= 9)) {
            commonFunctions.updateData(k,v)
        }
    }
    return (
        <>
            <Grid className="border-bottom" container >
                <Grid item lg={6}>
                    <div className="pd-10" >
                        <p className="main-heading font-12 text-grey-83">Select Timezone</p>
                        <p className="sub-heading mb-10">
                            The HPA will trigger based on the timezone selected.
                    </p>
                    <div className="auto-complete-dropdown">
                    <Input
                 type="auto-complete-dropdown"
                 label=""
                 id={"time_zone_1"}
                 name="time_zone"
                 getOptionLabel ={(option) => option.label}
                 list={convertList(list)}
                 data={state.data}
                 error={state.error}
                 onChangeHandler={commonFunctions.onKeyValueChangeHandler}
                />
                    </div>
                    
                       
                    </div>
                </Grid>
                <Grid item lg={6}>
                    <div className="pd-10 pb-0">
                        <p className="main-heading font-12 text-grey-83">Select Days</p>
                        <p className="sub-heading ">
                            On which day of the week you want to trigger HPA.
                    </p>
                    </div>
                    <div className="inner-card-body pd-10 m-auto">
                        <WeekDaysCheckbox prev_state={state.weekdaysinherits} inherits={state.child_inherits.weekdaysinherits} />
                    </div>
                </Grid>
            </Grid>
            <Grid container className="input-handler">
                <Grid item lg={6} className="pd-10">
                    <p className="font-18 font-weight-300">HPA Start Time</p>
                    <p className="sub-heading">
                    When do you want this configuration to be<br />auto-deployed
                    </p>
                    <div style={{ 'marginTop': '10px' }}>
                        <div className="input-controller with-error">
                            <Input
                                type="number"
                                name="start_time_hrs"
                                label="Hours"
                                placeholder="HH"
                                data={state.data}
                                error={state.error}
                                // onChangeHandler={commonFunctions.onChangeHandler}
                                onChangeHandler={handleHours}
                                maxLength={5}
                            />
                        </div><div style={{ float: 'left', marginTop: '30px', fontSize: 32 }}>&nbsp;&nbsp;:&nbsp;&nbsp;</div>
                        <div className="input-controller  with-error">
                            <Input
                                type="number"
                                name="start_time_mins"
                                label="Mins"
                                placeholder="MM"
                                data={state.data}
                                error={state.error}
                                onChangeHandler={handleMins}
                                maxLength={5}
                            />
                        </div>
                    </div>
                </Grid>
                <Grid item lg={6} className="pd-10">
                    <p className="font-18 font-weight-300">Duration</p>
                    <p className="sub-heading">
                    Duration for which this configuration will be <br />effective
                    </p>
                    <div style={{ 'marginTop': '10px' }}>
                        <div className="input-controller  with-error">
                            <Input
                                type="number"
                                name="hours_hrs"
                                label="Hours"
                                placeholder="HH"
                                data={state.data}
                                error={state.error}
                                onChangeHandler={handleHours}
                                maxLength={2}
                            />
                        </div><div style={{ float: 'left', marginTop: '30px', fontSize: 32 }}>&nbsp;&nbsp;:&nbsp;&nbsp;</div>
                        <div className="input-controller  with-error">
                            <Input
                                type="number"
                                name="hours_mins"
                                label="Mins"
                                placeholder="MM"
                                data={state.data}
                                error={state.error}
                                onChangeHandler={handleMins}
                                maxLength={2}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

SchedulerFrom.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }


export default SchedulerFrom;

export function HpaSchedulerDefaultState() {
    return {
        data: {
            time_zone: "Asia/Kolkata"
        },
        error: {},
        validations: {
            hours_hrs: [VALIDATION_TYPE_REQUIRED],
            hours_mins: [VALIDATION_TYPE_REQUIRED],
            start_time_mins: [VALIDATION_TYPE_REQUIRED],
            start_time_hrs: [VALIDATION_TYPE_REQUIRED],
            time_zone: [VALIDATION_TYPE_REQUIRED]
        },
        child_inherits: {
            weekdaysinherits: {
                "validateForm": () => { return { valid: true }; },
                "getState": () => { return {}; },
                "getData": () => { return {}; }
            }
        }
    }
}
