import { Grid, Step } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Input } from "../../../../components/genericComponents/Input";
import InvokeApi from "../../../../util/apiInvoker";
import GenerateURL, { GenerateSearchURL } from "../../../../util/APIUrlProvider";
import properties from "../../../../properties/properties";
import { Checkbox } from "@material-ui/core";


const OverrideVariablesForNonDependentJobs = ({ jobData, setParentState, independentJobData, editFlowJobStepJson }) => {
    const [state, setState] = useState({
        data: {},
        error: {},
        steps: [],
        stepsData: {},
        hoveredItem: null
    })
    // api call and local step data to create form here 

    console.log(jobData, independentJobData, editFlowJobStepJson, 'jd)9o2323')

    const stepData = jobData?.steps

    useEffect(() => {
        if (jobData?.steps) {
            getDataForOverRidableView(stepData)
        }
    }, [jobData])

    useEffect(() => {
        console.log(editFlowJobStepJson, 'dfhhgg_osod')
        if (editFlowJobStepJson?.job_step_json != undefined) {
            const jobData = editFlowJobStepJson?.job_json
            const jobStepJson = editFlowJobStepJson.job_step_json
            if (jobData.is_dependent == false) {
                let data = {}
                if (jobStepJson) {
                    Object.keys(jobStepJson)?.forEach((stepCode) => {
                        data[stepCode] = {}
                        const stepOrderData = jobStepJson[stepCode]
                        stepOrderData && Object.keys(stepOrderData)?.forEach(stepOrder => {
                            data[stepCode][stepOrder] = {}
                            const stepsData = stepOrderData[stepOrder]
                            let stepKeyDataObject = {}
                            stepsData?.forEach(step => {
                                const stepInfo = step
                                stepInfo['error'] = {}
                                stepKeyDataObject[step.key] = stepInfo
                            })
                            data[stepCode][stepOrder] = stepKeyDataObject
                        })
                    })
                }
                console.log(data,'data_ppsese')
                setParentState(prevState => ({
                    ...prevState,
                    independentJobData: {
                        ...prevState.independentJobData,
                        data: { ...data }
                    }
                }))
            }
        }

    }, [editFlowJobStepJson])

    const getStepDataByCode = (code, order) => {

        let myendpoint = GenerateURL({}, properties.api.stepCatalogs)

        var requestInfo = {
            endPoint: GenerateSearchURL({ version: 'v3', step_code: code }, myendpoint),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, (data) => {
            console.log(data, order, 'dinbmbmb')
            if (state.stepsData[code]) {
                setState(prevState => ({
                    ...prevState,
                    stepsData: {
                        ...prevState.stepsData,
                        [code]: {
                            ...prevState.stepsData[code],
                            [order]: data?.results[0]
                        }
                    }
                }))
            }
            else {
                setState(prevState => ({
                    ...prevState,
                    stepsData: {
                        ...prevState.stepsData,
                        [code]: {
                            [order]: data?.results[0]
                        }
                    }
                }))

                const envVarObjects = {}

                data?.results[0].environment_variables.forEach(envVariable => {
                    const envVarObj = {
                        key: envVariable.key,
                        value: null,
                        is_value_from_output_var: false,
                        is_overridden: false,
                        is_required: envVariable.is_required
                    }
                    envVarObjects[envVariable.key] = envVarObj

                })
                if (editFlowJobStepJson?.job_step_json == undefined) {

                    setParentState(prevState => ({
                        ...prevState,
                        independentJobData: {
                            ...prevState.independentJobData,
                            data: {
                                ...prevState.independentJobData.data,
                                [code]: {
                                    [order]: {
                                        ...envVarObjects,
                                    }
                                }
                            }
                        }
                    }))
                }
            }

        })
    }

    const getDataForOverRidableView = (steps) => {

        steps?.forEach((step) => {
            getStepDataByCode(step.step_code, step.order)
        })
    }

    const handleChange = (e, stepCodeKey, stepOrder, targetValue) => {
        const targetKey = e.target.name
        const value = targetValue == 'is_overridden' ? e.target.checked : e.target.value

        // setState(prevState => ({
        //     ...prevState,
        //     data: {
        //         [stepCodeKey]: {
        //             ...prevState.data[stepCodeKey],
        //             [stepOrder]: {
        //                 ...prevState.data[stepCodeKey][stepOrder],
        //                 [targetKey]: {
        //                     ...prevState.data[stepCodeKey][stepOrder][targetKey],
        //                     [targetValue]: value
        //                 }
        //             }
        //         }
        //     }
        // }))
        setParentState(prevState => ({
            ...prevState,
            independentJobData: {
                ...prevState.independentJobData,
                data: {
                    ...prevState.independentJobData.data,
                    [stepCodeKey]: {
                        ...prevState.independentJobData.data[stepCodeKey],
                        [stepOrder]: {
                            ...prevState.independentJobData.data[stepCodeKey][stepOrder],
                            [targetKey]: {
                                ...prevState.independentJobData.data[stepCodeKey][stepOrder][targetKey],
                                [targetValue]: value,
                                error: { [targetKey]: null }
                            }
                        }
                    }
                }
            }

        }))
    }

    const handleParentHover = (item) => {
        setState(prevState => ({
            ...prevState,
            hoveredItem: item
        }))
    };

    console.log(state, 'step_data_001-->')

    return (
        <div style={{ boxShadow: '0px 2px 8px #80808066', border: '1px solid #dedede', marginBottom: '20px', width: '100%' }}>
            <div>
                <div style={{ backgroundColor: '#faf9ff', height: '60px', paddingLeft: '15px', paddingRight: '15px' }} className='d-flex align-center space-between border-bottom-grid'>

                    <label className='font-15 font-weight-500 '>
                        Job Steps Environment Variables
                    </label>

                    <label className='font-13  font-weight-500' style={{ color: '#716d6d' }}>
                        Provide/Update Runtime Environment Variables
                    </label>
                </div>
                <>
                    <Grid className='pl-15 pr-15 pb-20' style={{ backgroundColor: '#ffffff', minHeight: '200px' }} container>

                        {
                            Object.keys(state.stepsData)?.length > 0 ? Object.keys(state.stepsData)?.map((stepCodeKey, index) => {
                                const stepOrdersObject = state.stepsData[stepCodeKey]
                                console.log('dg_hii', index, stepOrdersObject)
                                return (
                                    <>
                                        {
                                            stepOrdersObject && Object.keys(stepOrdersObject)?.map((stepOrder, order) => {
                                                const stepData = stepOrdersObject[stepOrder]

                                                return (
                                                    <>
                                                        {stepData && <Grid key={`${stepCodeKey}'_'${index}}`} style={{ paddingTop: '15px' }} item xs={12}>

                                                            <Grid style={{ height: '32px' }} key={order} item xs={12}>
                                                                <label className='font-13 mr-12'>Step Name</label>
                                                                <label style={{ color: 'gray' }} className='font-13'>{stepData.name ? stepData.name : stepData.code}</label>
                                                            </Grid>

                                                            <div style={{
                                                                paddingTop: '20px', paddingBottom: '15px', border: '1px solid #dedede',
                                                                backgroundColor: '#f8f8f8', padding: '10px 20px 5px 20px',
                                                                borderRadius: '5px'
                                                            }}>
                                                                {
                                                                    stepData && stepData.environment_variables.map((variable, index) => {
                                                                        const uniqueId = `${stepCodeKey}_${stepOrder}_${index}`
                                                                        return (
                                                                            <Grid key={uniqueId} item xs={12}>
                                                                                {
                                                                                    variable.integration ?
                                                                                        <>
                                                                                            {
                                                                                                variable.integration_values?.map(integrationObj => {

                                                                                                    return (<>

                                                                                                        {
                                                                                                            Object.keys(integrationObj)?.map(integrationKey => {
                                                                                                                const integrationData = integrationObj[integrationKey]
                                                                                                                console.log(integrationData, 'integ_2332323')
                                                                                                                return (
                                                                                                                    <>
                                                                                                                        {integrationData?.map(integration => {
                                                                                                                            return (
                                                                                                                                integration.key === 'GIT_BRANCH' ?
                                                                                                                                    <Input
                                                                                                                                        type='text'
                                                                                                                                        name={integrationKey}
                                                                                                                                        label={`${integration.key} for ${integrationKey}(Integration)`}
                                                                                                                                        placeholder=""
                                                                                                                                        onChangeHandler={(e => handleChange(e, order))}
                                                                                                                                        data={state.data && state.data[order] ? state.data[order] : {}}
                                                                                                                                        error={state.error}
                                                                                                                                    /> : null
                                                                                                                            )
                                                                                                                        })}
                                                                                                                    </>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </>)

                                                                                                })
                                                                                            }
                                                                                        </>
                                                                                        :
                                                                                        variable.input_type === 'toggle' ?
                                                                                            <Input
                                                                                                type='env-var-toggle'
                                                                                                name={variable.key}
                                                                                                label={variable.key}
                                                                                                placeholder=""
                                                                                                onChangeHandler={(e => handleChange(e, order, 'toggle'))}
                                                                                                data={state.data && state.data[order] ? state.data[order][variable.key] === 'no' ? false : true : {}}
                                                                                                error={state.error}
                                                                                            /> :
                                                                                            variable.input_type == undefined || variable.input_type === 'text' || variable.input_type === 'json' ?
                                                                                                <div onMouseEnter={() => handleParentHover(uniqueId)}
                                                                                                    onMouseLeave={() => handleParentHover(null)}
                                                                                                    className="mt-20" style={{ position: 'relative' }}>
                                                                                                    <ExtraOverridableInput
                                                                                                        checked={
                                                                                                            independentJobData.data
                                                                                                            && independentJobData.data[stepCodeKey] &&
                                                                                                            independentJobData.data[stepCodeKey][stepOrder] &&
                                                                                                            independentJobData.data[stepCodeKey][stepOrder][variable.key] &&
                                                                                                            independentJobData.data[stepCodeKey][stepOrder][variable.key]['is_overridden']
                                                                                                        }
                                                                                                        name={variable.key}
                                                                                                        stepCodeKey={stepCodeKey}
                                                                                                        stepOrder={stepOrder}
                                                                                                        onChange={handleChange}
                                                                                                        show={((uniqueId === state.hoveredItem) ||
                                                                                                            independentJobData.data
                                                                                                            && independentJobData.data[stepCodeKey] &&
                                                                                                            independentJobData.data[stepCodeKey][stepOrder] &&
                                                                                                            independentJobData.data[stepCodeKey][stepOrder][variable.key] &&
                                                                                                            independentJobData.data[stepCodeKey][stepOrder][variable.key]['is_overridden']
                                                                                                        )}
                                                                                                    />
                                                                                                    <Input
                                                                                                        type='text'
                                                                                                        name={variable.key}
                                                                                                        label={variable.key}
                                                                                                        placeholder=""
                                                                                                        onChangeHandler={(e => handleChange(e, stepCodeKey, stepOrder, 'value'))}
                                                                                                        data={{
                                                                                                            [variable.key]: (independentJobData?.data
                                                                                                                && independentJobData?.data[stepCodeKey] &&
                                                                                                                independentJobData?.data[stepCodeKey][stepOrder] &&
                                                                                                                independentJobData?.data[stepCodeKey][stepOrder][variable.key]) ?
                                                                                                                independentJobData.data[stepCodeKey][stepOrder][variable.key]['value'] : null
                                                                                                        }}
                                                                                                        error={
                                                                                                            (independentJobData?.data
                                                                                                                && independentJobData?.data[stepCodeKey] &&
                                                                                                                independentJobData?.data[stepCodeKey][stepOrder] &&
                                                                                                                independentJobData?.data[stepCodeKey][stepOrder][variable.key] &&
                                                                                                                independentJobData?.data[stepCodeKey][stepOrder][variable.key]['error']
                                                                                                            ) ?

                                                                                                                independentJobData.data[stepCodeKey][stepOrder][variable.key]['error'] : {}
                                                                                                        }
                                                                                                        mandatorySign={(independentJobData?.data
                                                                                                            && independentJobData?.data[stepCodeKey] &&
                                                                                                            independentJobData?.data[stepCodeKey][stepOrder] &&
                                                                                                            independentJobData?.data[stepCodeKey][stepOrder][variable.key]) ?
                                                                                                            independentJobData.data[stepCodeKey][stepOrder][variable.key]['is_required'] : false}
                                                                                                    />
                                                                                                </div>
                                                                                                :
                                                                                                <Input
                                                                                                    type={variable.input_type === 'toggle' ? 'select' : variable.input_type}

                                                                                                    name={variable.key}
                                                                                                    list={variable.input_type === 'select' || 'toggle' ? variable.select_dropdown_options ?

                                                                                                        variable.select_dropdown_options.split(',').map(item => ({

                                                                                                            id: item.trim(), label: item.trim()
                                                                                                        }))

                                                                                                        : [{ id: 'yes', label: 'Yes' }, { id: 'no', label: 'No' }]

                                                                                                        : []
                                                                                                    }
                                                                                                    label={variable.key}
                                                                                                    placeholder=""
                                                                                                    onChangeHandler={(e => handleChange(e, order))}
                                                                                                    data={state.data && state.data[order] ? state.data[order] : {}}
                                                                                                    error={state.error}
                                                                                                />
                                                                                }
                                                                            </Grid>
                                                                        )
                                                                    })
                                                                }

                                                            </div>

                                                        </Grid>

                                                        }
                                                    </>
                                                )

                                            })
                                        }
                                    </>
                                )
                            })
                                :

                                <div className="d-flex align-center justify-center font-12 full-width">No Runtime Environment Variables</div>
                        }

                    </Grid>
                </>
            </div>

        </div>
    )
}

export default OverrideVariablesForNonDependentJobs;


const ExtraOverridableInput = ({ show, onChange, checked, name, stepCodeKey, stepOrder }) => {
    return (
        <>
            {
                show ?
                    <div style={{ position: 'absolute', right: 0, top: '-12px' }}>
                        <Checkbox checked={checked} onChange={(e) => onChange(e, stepCodeKey, stepOrder, 'is_overridden'
                        )} name={name} value={""} />
                        <label style={{ color: '#505050' }} className='font-12 font-weight-400'>Overridable at Runtime</label>
                    </div> : null
            }
        </>
    )
}


