import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RadioForms from '../RadioForms';
import FileUpload, { FileUploadDefaultState } from './FileUpload';
import GitRepo, { GitRepoDefaultState } from './GitRepo';
import HelmCharts, { FILE_UPLOAD, GIT_REPO, HelmChartsDefaultState } from './HelmCharts';

const MANIFEST_UPLOAD = "MANIFEST_UPLOAD";
const MANIFEST_GIT = "MANIFEST_GIT";
const MANIFEST_HELM = "MANIFEST_HELM";

const manifest_options = {
    MANIFEST_UPLOAD: {
        label: "Via Manifest File Upload",
        value: MANIFEST_UPLOAD,
        component: FileUpload
    },
    MANIFEST_GIT: {
        label: "Via Git URL",
        value: MANIFEST_GIT,
        component: GitRepo
    },
    MANIFEST_HELM: {
        label: "Via Helm Templates",
        value: MANIFEST_HELM,
        component: HelmCharts
    },
}

function ManifestHandler(props) {
    const inherits = props.inherits;
    const manifest_meta_data = props.manifest_meta_data?props.manifest_meta_data:null;
    
    const [state, setState] = useState(manifest_meta_data ? generateState(manifest_meta_data) : {
        radio_form_state: null
    });
    const manifest_inherits = {};
    useEffect(() => {
        if(manifest_meta_data  )
        setState(new_state => ({
            ...new_state,
            ...generateState(manifest_meta_data)
        }))
    }, [manifest_meta_data])

    inherits.validateForm = () => {
        return manifest_inherits.validateSelectedComponent&&manifest_inherits.validateSelectedComponent() ?manifest_inherits.validateSelectedComponent() : {valid:true};
    }

    inherits.getData = () => {
        const selected_stretagy = manifest_inherits.getSelectedOption();
        const data = {};
        switch (selected_stretagy) {
            case MANIFEST_UPLOAD:
                data.strategy = "UPLOADED_MANIFEST";
                data.manifest_file_paths = manifest_inherits.getSelectedComponentData().files;
                break;
            case MANIFEST_GIT:
                data.strategy = "GIT_MANIFEST";
                data.manifest_git_repo = manifest_inherits.getSelectedComponentData();
                data.manifest_file_paths = data.manifest_git_repo.file_paths.split(",");
                data.manifest_git_repo_id = Number(data.manifest_git_repo.git_url);
                data.manifest_git_repo_branch = data.manifest_git_repo.branch_name;
                break;
            case MANIFEST_HELM:
                const helm_data = manifest_inherits.getSelectedComponentData();
                
                data.helm_git_repo = helm_data.helm_template.form_data.data;
                data.helm_git_repo_id = Number(helm_data.helm_template.form_data.data.git_url)
                data.helm_git_repo_branch = helm_data.helm_template.form_data.data.branch_name;
                data.helm_file_paths = data.helm_git_repo.file_paths.split(",");
                data.release_template = helm_data.release_template;
                data.release_template_id = helm_data.release_template_id;
                data.helm_release_name = helm_data.helm_release_name.data.helm_release_name ? helm_data.helm_release_name.data.helm_release_name :null;
                console.log("post_data_final",helm_data.helm_template, data )
                // data.env_cd_deploy_variable = helm_data.env_cd_deploy_variable? helm_data.env_cd_deploy_variable: []
                if (helm_data.value_file.selected_option == "FILE_UPLOAD") {
                    data.strategy = "HELM_UPLOADED_VALUES_FILE";
                    data.value_file_paths = helm_data.value_file.component_states["FILE_UPLOAD"].form_data.data.files;
                } else {
                    data.strategy = "HELM_GIT_VALUES_FILE";
                    data.helm_value_repo = helm_data.value_file.component_states["GIT_REPO"].form_data.data;
                    data.helm_value_repo_branch = data.helm_value_repo.branch_name
                    data.helm_value_repo_id = Number(data.helm_value_repo.git_url)
                    let files= data.helm_value_repo.file_paths;
                    let single_item=[]
                    files.includes(",") ? single_item=files.split(","): single_item.push(files)
                    data.value_file_paths = single_item;
                }
                break;
        }
        return data;
    }

    return (
    

            <RadioForms
                options={manifest_options}
                inherits={manifest_inherits}
                state={state.radio_form_state}
            />
        
    );
}

ManifestHandler.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default ManifestHandler;

function generateState(manifest_meta_data) {
    const radio_form_state = {};
    const component_states = {};
    // const env_var_data = env_var_data_key_pair? env_var_data_key_pair : [];
    // const env_var_length = env_var_data.length > 0? true :false
    radio_form_state.component_states = component_states;
    function getHelmGitState() {
        let state = HelmChartsDefaultState();
        // var envVarCategories = getEnvDeployVarsWithCategories(env_var_data)
        state.value_file = {};
        state.helm_template.form_data.data = {  ...state.helm_template.form_data.data, 
                                                ...manifest_meta_data.helm_git_repo, 
                                                };
        state.helm_template.form_data.data.git_url = manifest_meta_data.helm_git_repo_id 
        state.helm_template.form_data.data.branch_name = manifest_meta_data.helm_git_repo_branch 
        state.release_name.data.helm_release_name = manifest_meta_data.helm_release_name 
        
        state.helm_template.form_data.data.credential_id = manifest_meta_data.helm_git_repo.credential ?
            manifest_meta_data.helm_git_repo.credential.id : state.helm_template.form_data.data.credential_id;
        delete state.helm_template.form_data.data.credential;
        state.helm_template.form_data.data.file_paths = manifest_meta_data.helm_file_paths ? manifest_meta_data.helm_file_paths.toString() : "";
        return state;
    }

    switch (manifest_meta_data.strategy) {
        case "UPLOADED_MANIFEST":
            radio_form_state.selected_option = MANIFEST_UPLOAD;
            var state = FileUploadDefaultState();
            if(manifest_meta_data.manifest_file_paths){
                manifest_meta_data.manifest_file_paths.forEach(file_name => {
                    if (typeof (file_name) == "string") {
                        state.form_data.data.files.push({
                            name: file_name
                        });
                    } else {
                        state.form_data.data.files.push(file_name);
                    }
                });
            }
            
            radio_form_state.component_states[MANIFEST_UPLOAD] = state;
            break;
        case "GIT_MANIFEST":
            radio_form_state.selected_option = MANIFEST_GIT;
            var state = GitRepoDefaultState();
            console.log(manifest_meta_data,"manifest_meta_datamanifest_meta_data")
            state.form_data.data = { ...state.form_data.data, ...manifest_meta_data.manifest_git_repo };
            state.form_data.data.git_url = manifest_meta_data.manifest_git_repo_id ? manifest_meta_data.manifest_git_repo_id : null;
            state.form_data.data.branch_name = manifest_meta_data.manifest_git_repo_branch ? manifest_meta_data.manifest_git_repo_branch : null;
            state.form_data.data.credential_id = manifest_meta_data.manifest_git_repo.credential ?
                manifest_meta_data.manifest_git_repo.credential.id : state.form_data.data.credential_id;
            state.form_data.data.file_paths = manifest_meta_data.manifest_file_paths ? manifest_meta_data.manifest_file_paths.toString() : "";
            delete state.form_data.data.credential;

            radio_form_state.component_states[MANIFEST_GIT] = state;
            break;
        case "HELM_UPLOADED_VALUES_FILE":
            radio_form_state.selected_option = MANIFEST_HELM;
            var state = getHelmGitState();

            state.value_file.selected_option = FILE_UPLOAD;
            state.value_file.component_states = {
                [FILE_UPLOAD]: FileUploadDefaultState()
            }

            manifest_meta_data.value_file_paths.forEach(file_name => {
                if (typeof (file_name) == "string") {
                    state.value_file.component_states[FILE_UPLOAD].form_data.data.files.push({
                        name: file_name
                    });
                } else {
                    state.value_file.component_states[FILE_UPLOAD].form_data.data.files.push(file_name);
                }
            });
            radio_form_state.component_states[MANIFEST_HELM] = state;
            break;
        case "HELM_GIT_VALUES_FILE":
            radio_form_state.selected_option = MANIFEST_HELM;
            var state = getHelmGitState();

            state.value_file.selected_option = GIT_REPO;
            state.value_file.component_states = {
                [GIT_REPO]: GitRepoDefaultState()
            }
            let value_file_paths=null
            if(manifest_meta_data.value_file_paths){
                manifest_meta_data.value_file_paths.forEach(value_file_path=>{
                    if(value_file_paths){
                        value_file_paths=value_file_paths+","+value_file_path
                    }else{
                        value_file_paths=value_file_path
                    }

                }) 
            }
            manifest_meta_data.helm_value_repo.credential_id = manifest_meta_data.helm_value_repo.credential ? manifest_meta_data.helm_value_repo.credential.id : manifest_meta_data.helm_value_repo.credential_id;
            delete manifest_meta_data.helm_value_repo.credential;
            state.value_file.component_states[GIT_REPO].form_data.data = { ...state.value_file.component_states[GIT_REPO].form_data.data, ...manifest_meta_data.helm_value_repo };
            state.value_file.component_states[GIT_REPO].form_data.data.git_url = manifest_meta_data.helm_value_repo_id;
            state.value_file.component_states[GIT_REPO].form_data.data.branch_name = manifest_meta_data.helm_value_repo_branch;
            state.value_file.component_states[GIT_REPO].form_data.data.file_paths = value_file_paths;
            radio_form_state.component_states[MANIFEST_HELM] = state;
            break;
    }
    return { radio_form_state: radio_form_state };
}
