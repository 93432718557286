import React from 'react';
import PropTypes from 'prop-types';

const PageHeader = props => {
        const title = props.title

    return(
        <>
       {/* { <div className="seprator"></div>} */}
      <div className="head-panel-section">
      <span className="font-18 panel-heading">{title}</span>
      <div className="service-basic-details">
          <div className="service-name">
            <div className="round-check-box">
              <span className="flaticon-check-symbol"></span>
            </div>
            <span className="text-section">
              <p className="s_name__heading">Service Name:</p>
              <span>{props.service_name}</span>
            </span>
          </div>
          <div className="service-name">
            <div className="round-check-box">
              <span className="flaticon-check-symbol"></span>
            </div>
            <span  className="text-section">
              <p>Environment:</p>
              <span className="sub-heading"><span className="color-dark">Name:</span><span>{props.sub_env_name}</span>/</span>
              <span className="sub-heading"><span className="color-dark">Type:</span><span>{props.env_name}</span></span>
            </span>
          </div>
      </div>
      </div>
        </>
    )
}

PageHeader.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
};

export default PageHeader