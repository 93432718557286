import React, { useEffect, useState } from 'react'
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import SquareAvatar from '../../components/genericComponents/AvatarSquare';
import { useParams, Link } from 'react-router-dom';
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Tooltip } from '@material-ui/core';
import SingleDeployDetails from './SingleDeployDetails';
import GenerateURL from '../../util/APIUrlProvider';
import properties from '../../properties/properties';
import InvokeApi from '../../util/apiInvoker';
import { getDuration } from '../service/listing/components/History';
import moment from 'moment';
import Pagination from '../../components/Pagination';
import { Loading } from '../utils/Loading';
import Checkbox from '@material-ui/core/Checkbox';
import EnvSelectorNew from '../../components/genericComponents/EnvSelectorNew';
import SelectWithInsideLabel from '../../components/genericComponents/inputs/SelectWithInsideLabel';

const RollbackServiceListing = (props) => {
    const service_names = props.service_names
    const rollback_data = props.rollback_data
    const classes = useStyles();
    // const service_data = props.service_data;
    const open_by_default = props.open_by_default
    // const execution_data = props.execution_data;
    const handleSaveSelectedRollback = props.handleSaveSelectedRollback;
    const selected_rollbacks = props.selected_rollbacks;
    const handleRemoveRollbackPoint = props.handleRemoveRollbackPoint;
    const service_name = props.service_name
    const application_id = props.application_id

    console.log(service_names, rollback_data, "sdgvchgdsvcghdsvcs")

    const [state, setState] = useState({ sectionOpen: open_by_default, singleDeployOpen: false, selected_services: [], openIndex: 0, service_data: rollback_data[service_names[0]], selected_service_name: service_names[0], singleDeployOpen: false });

    console.log(rollback_data, "shbdhjsdbhbsdhbcd")

    const toggleSectionOpen = (index, service_name) => {
        console.log(rollback_data, index, service_name, "gvdsjchjbsdvchsd")
        setState((new_state) => ({
            ...new_state,
            openIndex: index,
            service_data: rollback_data[service_name],
            selected_service_name: service_name,
            selected_env_cd_detaild_id: getDefaultSelectedId(index, service_name)
        }))
    }

    function getDefaultSelectedId(index, service_name) {
        let service_data = rollback_data[service_name]
        let master_envs = Object.keys(service_data)
        let first_master_env = master_envs[0]
        let master_env_data = service_data[first_master_env]
        let sub_envs = Object.keys(master_env_data)
        let first_sub_env = sub_envs[0]
        let first_sub_env_data = master_env_data[first_sub_env]
        return first_sub_env_data[0].env_cd_detail_id

        console.log(service_data, master_env_data, sub_envs, first_sub_env, "shbhjvsbhhjf")

        // var env_cd_detail_id = service_data[master_envs[0]][sub_envs[0]].

    }

    const toggleSectionClose = () => {
        setState((new_state) => ({
            ...new_state,
            openIndex: null
        }))
    }

    console.log(state?.service_data, "dsbchjbsdchjbsdhc")

    useEffect(() => {
        console.log("anunay")
        setState((new_state) => ({
            ...new_state,
            service_selected_env_master: getServiceSelectedEnvMaster(null, state.selected_service_name),
        }))
    }, [state?.service_data])


    useEffect(() => {
        setState((new_state) => ({
            ...new_state,
            service_selected_env: getServiceSelectedEnv(),
        }))
    }, [state.service_selected_env_master])

    useEffect(() => {
        setState((new_state) => ({
            ...new_state,
            selected_deployment_name: getSelectedDeploymentName("deployment_name"),
            selected_env_cd_detaild_id: getSelectedDeploymentName("cd_id")
        }))
    }, [state.service_selected_env])



    useEffect(() => {
        if (state.selected_env_cd_detaild_id) {
            getRollbackPoints();
        }
    }, [state.selected_env_cd_detaild_id])

    console.log(state.selected_env_cd_detaild_id, "shgdvghcsvdgcsdvcsgd")

    useEffect(() => {
        getDeploymentNameList()
    }, [state.sub_env_data])

    function getServiceSelectedEnvMaster(type, service_name) {
        console.log(type, service_name, "adhgvgjacgdavgdagc")
        if (type == "envTabList") {
            var master_envs = Object.keys(rollback_data[service_name])
            var tab_list = []
            master_envs.forEach((item) => {
                var obj = {
                    order: item,
                    tabName: item,
                    // total_sub_env: master_envs.length
                }
                tab_list.push(obj);
            })
            return tab_list
        } else {
            var master_envs = Object.keys(rollback_data[service_name])
            //{master_env: master_envs[0],sub_env: subkssk,deployment_name: func}
            return master_envs[0]
        }
    }

    function getServiceSelectedEnv(type, service_name) {
        if (type == "componentEnvTabList") {
            var first_env = state.service_selected_env_master && state.service_selected_env_master
            var env_master_data = rollback_data[service_name][first_env]
            var env_list = env_master_data && Object.keys(env_master_data)
            var option_list = []
            if (env_list) {
                env_list.forEach((item) => {
                    var obj = {
                        order: item,
                        tabName: item
                    }
                    option_list.push(obj)
                })
            }
            return option_list;
        } else {
            var first_env = state.service_selected_env_master && state.service_selected_env_master
            var env_master_data = state?.service_data[first_env]
            var env_list = env_master_data && Object.keys(env_master_data)
            if (env_list) {
                return env_list[0];
            }
        }
    }


    function getSelectedDeploymentName(type) {
        console.log(state.sub_env_change, "hadbcfhsdbchjbsd")
        if (state.sub_env_change) {
            let master_env = state.service_selected_env_master && state.service_selected_env_master
            let master_env_data = state?.service_data[master_env]

            let sub_env = state.service_selected_env

            let sub_env_data = master_env_data[sub_env]

            console.log(sub_env_data, "sdbcjhsbdghcvsdghvc")

            setState((new_state) => ({
                ...new_state,
                sub_env_data: sub_env_data
            }))

            if (type == "deployment_name" && sub_env_data) {
                return sub_env_data[0].deployment_name
            }
            if (type == "cd_id") {
                return sub_env_data[0].env_cd_detail_id
            }

        } else {
            console.log(state.sub_env_change, "hadbcfhsdbchjbsd")
            var first_env_master = state.service_selected_env_master && state.service_selected_env_master
            console.log(state.service_data, first_env_master, "hadbcfhsdbchjbsd")
            var env_master_data = state?.service_data[first_env_master]
            var env_list = env_master_data && Object.keys(env_master_data)
            console.log(env_master_data, "jhzcbvhjbxhjvcf")
            var first_env = env_list && env_list[0]
            console.log(first_env, "jhzcbvhjbxhjvcf")
            if (env_master_data && first_env) {
                var sub_env_data = env_master_data[first_env]
            }
            setState((new_state) => ({
                ...new_state,
                sub_env_data: sub_env_data
            }))
            console.log(first_env, "jhzcbvhjbxhjvcf")
            if (type == "deployment_name" && sub_env_data && sub_env_data[0]) {
                console.log(sub_env_data, "hadbcfhsdbchjbsd")
                return sub_env_data[0].deployment_name
            }
            if (type == "cd_id" && sub_env_data && sub_env_data[0]) {
                return sub_env_data[0].env_cd_detail_id
            }
        }

    }

    function getDeploymentNameList() {
        var option_list = []
        console.log(state.sub_env_data, "shbdbvjchbsdbcvsd")
        state.sub_env_data && state.sub_env_data.forEach((item) => {
            var option_obj = {
                id: item.deployment_name,
                label: item.deployment_name
            }

            option_list.push(option_obj)
        })

        return option_list;
    }

    function getRollbackPoints() {
        var requestInfo = {
            endPoint: GenerateURL({ cd_id: state.selected_env_cd_detaild_id }, properties.api.rollback_points),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, fetchRollbackPointsSuccess, fetchRollbackPointsFailure);
        setState((new_state) => ({
            ...new_state,
            loading: true
        }))
    }

    function fetchRollbackPointsSuccess(response) {
        setState((new_state) => ({
            ...new_state,
            loading: false,
            rollbackPoints: response.results,
            latest_deployment_data: response.results.length > 0 && response.results[0],
            count: response.count,
            next: response.next ? properties.api.baseURL + response.next : null,
            previous: response.previous ? properties.api.baseURL + response.previous : null,
            error_msg: null,
            total_page: Number.isInteger(Number(response.count) / 10)
                ? (Number(response.count) / 10).toFixed(0)
                : (Number(response.count) / 10 + 1).toFixed(0) > Number(response.count) / 10 + 1
                    ? (Number(response.count) / 10 + 1).toFixed(0) - 1
                    : (Number(response.count) / 10 + 1).toFixed(0),
            curr_page: 1,
        }))
    }

    function fetchRollbackPointsFailure(error) {
        setState((new_state) => ({
            ...new_state,
            loading: false,
            error: error
        }))
    }

    //prev function 

    function fetchPrevRollbackPoints(data, url) {
        var requestInfo = {
            endPoint: GenerateURL({ cd_id: state?.service_data.env_cd_detail_id }, properties.api.rollback_points),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        if (data) {
            requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
        }

        if (url) {
            requestInfo.endPoint = url;
        }

        InvokeApi(
            requestInfo,
            fetchPrevRollbackPointsSuccess,
            fetchPrevRollbackPointsFailure
        );
        setState((new_state) => ({
            ...new_state,
            loading: true,
        }));
    }

    function fetchPrevRollbackPointsSuccess(response) {
        setState(new_state => ({
            ...new_state,
            rollbackPoints: response.results,
            loading: false,
            count: response.count,
            next: response.next ? properties.api.baseURL + response.next : null,
            previous: response.previous ? properties.api.baseURL + response.previous : null,
            error_msg: null,
            total_page: Number.isInteger(Number(response.count) / 10)
                ? (Number(response.count) / 10).toFixed(0)
                : (Number(response.count) / 10 + 1).toFixed(0) > Number(response.count) / 10 + 1
                    ? (Number(response.count) / 10 + 1).toFixed(0) - 1
                    : (Number(response.count) / 10 + 1).toFixed(0),
            curr_page: Number(new_state.curr_page - 1),
        }));
    }

    function fetchPrevRollbackPointsFailure(error) {
        setState((new_state) => ({
            ...new_state,
            loading: false,
            error: error
        }))
    }

    //next function

    function fetchNextRollbackPoints(data, url) {
        var requestInfo = {
            endPoint: GenerateURL({ cd_id: state?.service_data.env_cd_detail_id }, properties.api.rollback_points),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        if (data) {
            requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
        }

        if (url) {
            requestInfo.endPoint = url;
        }
        setState((new_state) => ({
            ...new_state,
            loading: true,
        }));
        InvokeApi(
            requestInfo,
            fetchNextRollbackPointsSuccess,
            fetchNextRollbackPointsFailure
        );
    }

    function fetchNextRollbackPointsSuccess(response) {
        setState(new_state => ({
            ...new_state,
            rollbackPoints: response.results,
            loading: false,
            count: response.count,
            next: response.next ? properties.api.baseURL + response.next : null,
            previous: response.previous ? properties.api.baseURL + response.previous : null,
            error_msg: null,
            total_page: Number.isInteger(Number(response.count) / 10)
                ? (Number(response.count) / 10).toFixed(0)
                : (Number(response.count) / 10 + 1).toFixed(0) > Number(response.count) / 10 + 1
                    ? (Number(response.count) / 10 + 1).toFixed(0) - 1
                    : (Number(response.count) / 10 + 1).toFixed(0),
            curr_page: Number(new_state.curr_page + 1),
        }));
    }

    function fetchNextRollbackPointsFailure(error) {
        setState((new_state) => ({
            ...new_state,
            loading: false,
            error: error
        }))
    }

    //page function

    function fetchPageRollbackInfo(enteredPageNumber) {
        var requestInfo = {
            endPoint: GenerateURL({ cd_id: state?.service_data.env_cd_detail_id }, properties.api.rollback_points),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        if (enteredPageNumber > 1) {
            requestInfo.endPoint =
                requestInfo.endPoint +
                "?limit=10&offset=" +
                (enteredPageNumber - 1) * 10;
        }

        var current_page = enteredPageNumber;

        setState((new_state) => ({
            ...new_state,
            loading: true,
        }));
        InvokeApi(
            requestInfo,
            fetchPageRollbackInfoSuccess,
            fetchPageRollbackInfoFailure
        );
    }

    function fetchPageRollbackInfoSuccess(response) {
        console.log(response, "sjbvhjfhjbfhhfd")
        setState(new_state => ({
            ...new_state,
            rollbackPoints: response.results,
            loading: false,
            count: response.count,
            next: response.next ? properties.api.baseURL + response.next : null,
            previous: response.previous ? properties.api.baseURL + response.previous : null,
            error_msg: null,
            total_page: Number.isInteger(Number(response.count) / 10)
                ? (Number(response.count) / 10).toFixed(0)
                : (Number(response.count) / 10 + 1).toFixed(0) > Number(response.count) / 10 + 1
                    ? (Number(response.count) / 10 + 1).toFixed(0) - 1
                    : (Number(response.count) / 10 + 1).toFixed(0),
            curr_page: Number(count),
        }));
    }

    function fetchPageRollbackInfoFailure(error) {
        setState((new_state) => ({
            ...new_state,
            loading: false,
            error: error
        }))
    }

    const handleSingleDeployOpen = (id, cd_id, data, curr_deployment_data) => {
        setState({
            ...state,
            singleDeployOpen: !state.singleDeployOpen,
            selectedRollbackId: id,
            rollbackCdId: cd_id,
            selectedRollBackData: data,
            curr_deployment_data: curr_deployment_data
        })
    }


    const handleRollbackSelect = (rollback_data, service_name) => {
        console.log(rollback_data, service_name, "bdfvcgjdsvg")
        var new_obj = {}
        new_obj.rollback_id = rollback_data.id
        // new_obj.last_deploy_id = service_data.last_deploy_detail.id
        // new_obj.service_id = service_data.id
        new_obj.cd_id = rollback_data.env_cd_detail_id

        var rollback_name_obj = {}
        rollback_name_obj.cd_id = rollback_data.env_cd_detail_id
        rollback_name_obj.service_name = service_name
        rollback_name_obj.deployment_name = rollback_data.deploy_args_key_value && rollback_data.deploy_args_key_value.deployment_name
        rollback_name_obj.deploy_id = `Deploy_id ${rollback_data.id}`
        handleSaveSelectedRollback(new_obj, rollback_name_obj)
        // handleServiceRollbackSelect(service_data.name)
    }

    function handleServiceRollbackSelect(service_name) {
        var arr = [service_name]

        if (!state.selected_services.includes(service_name)) {
            setState((new_state) => ({
                ...new_state,
                selected_services: [...new_state.selected_services, ...arr],
            }))
        }
    }

    function updateSelectedMasterEnv(env) {
        console.log(env, "sbdhbhjsbdhjvs")
        setState((new_state) => ({
            ...new_state,
            service_selected_env_master: env,
            sub_env_change: false,
        }))
    }

    function updateComponentEnv(name) {
        setState((new_state) => ({
            ...new_state,
            service_selected_env: name,
            sub_env_change: new_state.service_selected_env !== name ? true : false
        }))
    }

    function selectDeployment(deployment_name) {
        setState((new_state) => ({
            ...new_state,
            selected_deployment_name: deployment_name,
            selected_env_cd_detaild_id: getUpdatedCdDetailID(deployment_name)
        }))
    }

    function getUpdatedCdDetailID(deployment_name) {
        var cd_id = state.sub_env_data.find((ele) => ele.deployment_name == deployment_name)

        return cd_id.env_cd_detail_id
    }

    function handleClickOnRemove() {

    }

    console.log(state.sub_env_data, "sbdfjvhhdsvbsfvb")


    return (
        < Grid container >
            {
                service_names &&
                service_names.map((service_name, index) => {
                    console.log(index, "sdnbcjhbdschjbsdh")
                    return (
                        <Grid item lg={12} sm={12} xl={12} xs={12} className="pd-tb body" style={{ marginBottom: "10px" }}>

                            <div className={classes.serviceCard} style={state.openIndex == index ? { padding: "12px 12px 20px 12px" } : { padding: "10px 24px 10px 12px" }}>
                                <div className="service-container-head">
                                    <div className="left-content">
                                        <HeadContent service_data={rollback_data[service_name]} service_name={service_name} />
                                    </div>
                                    <div className="right-content" >
                                        {
                                            state.openIndex == index &&
                                            <div style={{ width: "330px" }}>
                                                <EnvSelectorNew envTabList={getServiceSelectedEnvMaster("envTabList", service_name)} selectedEnvTab={state.service_selected_env_master} envChangeHandler={updateSelectedMasterEnv} componentEnvTabList={getServiceSelectedEnv("componentEnvTabList", service_name)} selectedComponentEnv={state.service_selected_env} componentEnvChangeHandler={updateComponentEnv} />
                                            </div>
                                        }
                                        {
                                            state.openIndex == index &&
                                            <div style={{ width: "214px", marginLeft: "20px" }}>
                                                <SelectWithInsideLabel list={getDeploymentNameList()} onChangeHandler={selectDeployment} label={"Deployment: "} SelectedDeployment={state.selected_deployment_name} optionsWidth={214} />
                                            </div>
                                        }
                                        <IconButton>
                                            {
                                                state.openIndex == index ?

                                                    <ExpandLessIcon style={{ fontSize: '24px', color: '#666', lineHeight: 1, cursor: 'pointer' }} onClick={toggleSectionClose} />
                                                    :
                                                    <ExpandMoreIcon style={{ fontSize: '24px', color: '#666', lineHeight: 1, cursor: 'pointer' }} onClick={() => toggleSectionOpen(index, service_name)} />
                                            }
                                        </IconButton>

                                    </div>

                                </div>
                                {
                                    state.openIndex == index ?
                                        <Body handleSingleDeployOpen={handleSingleDeployOpen} service_data={rollback_data[service_name]} state={state} rollback_points={state.rollbackPoints} fetchPrevRollbackPoints={fetchPrevRollbackPoints} fetchNextRollbackPoints={fetchNextRollbackPoints} fetchPageRollbackInfo={fetchPageRollbackInfo} handleRollbackSelect={(data) => handleRollbackSelect(data, service_name)} selected_rollbacks={selected_rollbacks} handleClickOnRemove={handleClickOnRemove} opened={state.openIndex == index ? true : false} latest_deployment_data={state.latest_deployment_data} previous={state.previous} /> : null
                                }
                                {
                                    state.openIndex == index &&
                                    <div style={{ marginTop: "15px", width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                        <Grid item lg={3} sm={12} xl={3} xs={12}>
                                            <Pagination
                                                variant="rollback"
                                                total_count={state.total_page} latest_deployment_data={state.latest_deployment_data}
                                                current_page_count={state.curr_page}
                                                next={state.next}
                                                previous={state.previous}
                                                on_previous_click={() => {
                                                    fetchPrevRollbackPoints(null, state.previous);
                                                }}
                                                on_next_click={() => {
                                                    fetchNextRollbackPoints(null, state.next);
                                                }}
                                                on_pageNumber_click={(pageNumber) => {
                                                    fetchPageRollbackInfo(pageNumber);
                                                }}
                                            />
                                        </Grid>
                                    </div>
                                }
                                {
                                    state.singleDeployOpen ?
                                        <SingleDeployDetails
                                            isOpen={state.singleDeployOpen}
                                            handleSingleDeployOpen={handleSingleDeployOpen}
                                            service_data={state.service_data}
                                            selectedRollbackId={state.selectedRollbackId}
                                            rollbackCdId={state.rollbackCdId}
                                            selectedRollBackData={state.selectedRollBackData}
                                            selected_service_name={state.selected_service_name}
                                            env_master={state.service_selected_env_master}
                                            env_project={state.service_selected_env}
                                            curr_deployment_data={state.curr_deployment_data}
                                            application_id={application_id}
                                            latest_deployment_data={state.latest_deployment_data}
                                        />
                                        :
                                        null
                                }
                            </div>
                        </Grid>
                    )
                })
            }
        </Grid >
    )
}

const useStyles = makeStyles((theme) => ({
    serviceCard: {


        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0px 8px 24px 0px #0000000A, 0px 4px 4px 0px #00000014",
        marginBottom: "2rem",
        overflow: "hidden",

        "& .service-container": {
        },

        "& .service-container-head": {
            display: "flex",
            justifyContent: "space-between",
            // padding: "20px",
            alignItems: "center",
        }

    },
    body: {
        background: "#FFFFFF",
        "& .divider-line": {
            height: "1px",
            backgroundColor: "#E2E2E2"
        },

        "& .current-deployment": {
            margin: "24px 0",

            "& .current-deployment-header": {
                color: "var(--CONTENT-TERTIARY, #787878)",
                
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
            },

            "& .current-deployment-details": {
                display: "grid",
                gridTemplateColumns: "20% 20% 20% 20% 20%",
                // height: "69px",
                padding: "14px 12px",
                borderRadius: "6px",
                border: "1px solid #EBEBEB",
                marginTop: "12px",

                "& .single-details-div": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                },

                "& .details-type": {
                    color: '#787878',
                    fontSize: "12px",
                    
                    fontWeight: '600',
                },

                "& .details-data": {
                    color: '#505050',
                    fontSize: "12px",
                    
                    fontWeight: '500',
                    marginTop: "4.5px",
                },

                "& .deploy-status": {
                    display: "flex",

                    "& .deploy-number": {
                        color: 'black',
                        fontSize: "14px",
                        
                        fontWeight: '600',
                        wordWrap: 'break-word'
                    },
                },

                "& .artifact-details": {

                }
            }
        },

        "& .rollback-points": {

            "& .rollback-header": {
                color: '#2F2F2F',
                fontSize: "16px",
                
                fontWeight: '600',
                marginTop: "24px",
            },

            "& .rollback-list": {
                borderRadius: "6px",
                border: "1px solid var(--GREY-SCALE-GREY-200, #E6E6E6)",
                marginTop: "16px",

                "& .rollback-points-header": {
                    display: "grid",
                    gridTemplateColumns: "20% 20% 20% 20% 20%",
                    backgroundColor: "#FAFAFA",
                    padding: "12px 20px",

                    "& .header-name": {
                        color: '#787878',
                        fontSize: "12px",
                        
                        fontWeight: '600',
                    }
                },

                "& .rollback-points-strip": {
                    display: "grid",
                    gridTemplateColumns: "20% 20% 20% 20% 20%",
                    padding: "20px",
                    backgroundColor: '#ffffff',
                    borderBottom: "1px solid var(--GREY-SCALE-GREY-200, #E6E6E6)",
                    cursor: "pointer",

                    "& .info-heading": {
                        
                        fontSize: "12px",
                        fontWeight: "500",
                        lineHeight: "15px",
                        color: "#2F2F2F",
                    },

                    "& .info": {
                        
                        fontSize: "12px",
                        fontWeight: "500",
                        lineHeight: "14.63px",
                        color: "#787878",
                    },

                    "& .deploy-id": {
                        display: "flex",
                        // justifyContent : "space-between",
                        // alignItems: "center",

                        "& .deploy-icon": {
                            backgroundColor: '#F5FAFF',
                            borderRadius: "4px",
                            border: '1px #DFEDFF solid',
                            display: "flex",
                            justifyContent: 'center',
                            alignItems: "center",
                            padding: "10px",
                            width: "44px",
                            height: "44px"
                        },

                        "& .selected-deploy-icon": {
                            backgroundColor: '#0086FF',
                            borderRadius: "4px",
                            border: '1px #DFEDFF solid',
                            display: "flex",
                            justifyContent: 'center',
                            alignItems: "center",
                            padding: "10px",
                            width: "44px",
                            height: "44px"
                        }
                    },

                    "& .view-icon": {
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "6px",
                        border: "1px solid #DBDBDB",
                        height: "38px",
                        width: "38px",
                        justifyContent: "center",
                    }
                }
            },
        },
    },
}));


const Body = (props) => {
    const classes = useStyles();
    const handleSingleDeployOpen = props.handleSingleDeployOpen;
    const service_data = props.service_data;
    const rollback_points = props.rollback_points;
    const handleRollbackSelect = props.handleRollbackSelect;
    const selected_rollbacks = props.selected_rollbacks;
    const handleClickOnRemove = props.handleClickOnRemove;
    const state = props.state
    const opened = props.opened;
    const latest_deployment_data = props.latest_deployment_data;

    console.log(rollback_points, "shbdchjdbhjcbdsc")

    let list = rollback_points

    let curr_deployment_data = latest_deployment_data;

    var rollback_list;

    if (props.previous) {
        rollback_list = list
    } else {
        rollback_list = list && list.slice(1);
    }

    console.log(curr_deployment_data, rollback_list, "sgdvgshvchzdhdcbzcbhdz")

    function formatDate(created_at) {
        // Parse the provided created_at timestamp
        const date = new Date(created_at);

        // Extract year, month, and day
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');

        // Extract hours, minutes, and seconds
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        // Construct the formatted date string
        const formattedDate = `${year}-${month}-${day} / ${hours}:${minutes}:${seconds}`;

        return formattedDate;
    }

    function calculateDuration(startTime, endTime) {
        const start = new Date(startTime);
        const end = new Date(endTime);
        const durationInSeconds = (end - start) / 1000; // Convert milliseconds to seconds

        if (durationInSeconds < 60) {
            return `${Math.floor(durationInSeconds)}s`;
        } else if (durationInSeconds < 3600) {
            return `${Math.floor(durationInSeconds / 60)}m`;
        } else {
            return `${Math.floor(durationInSeconds / 3600)}h`;
        }
    }

    return (
        <>
            {
                state.loading ?
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Loading varient="light" />
                    </div>
                    :
                    <div className={classes.body} style={opened ? { minHeight: "500px" } : {}}>
                        <div style={{ height: '1px', marginTop: "10px", backgroundColor: '#E2E2E2' }}></div>
                        <div className='current-deployment'>
                            <p className='current-deployment-header'>Current Deployment Details</p>
                            <div className='current-deployment-details'>
                                <div className='deploy-status'>
                                    <div>
                                        <p className='deploy-number'>Deploy #{curr_deployment_data?.deploy_number}</p>
                                        {
                                            curr_deployment_data?.activity_status?.status == "SUCCESS" ?

                                                <div className='new-status-chip new-success' style={{ marginTop: "12px" }}>
                                                    {curr_deployment_data && curr_deployment_data.activity_status && curr_deployment_data.activity_status.status}
                                                </div>
                                                :
                                                <div className='new-status-chip new-failed' style={{ marginTop: "12px" }}>
                                                    {curr_deployment_data && curr_deployment_data.activity_status && curr_deployment_data.activity_status.status}
                                                </div>
                                        }
                                    </div>
                                    <div style={{ width: "1px", backgroundColor: '#E6E6E6', marginLeft: "135px" }}></div>
                                </div>
                                <div className='artifact-details single-details-div' >
                                    <div>
                                        <p className='details-type'>Artifact</p>
                                        <p className='details-data'>{curr_deployment_data && curr_deployment_data.deploy_tag}</p>
                                    </div>
                                </div>
                                <div className='deploy-time single-details-div'>
                                    <div>
                                        <p className='details-type'>Deployed On:</p>
                                        <p className='details-data'>{formatDate(curr_deployment_data && curr_deployment_data.activity_status && curr_deployment_data.activity_status.created_at)}</p>
                                    </div>
                                </div>
                                <div className='deploy-user single-details-div'>
                                    <div>
                                        <p className='details-type'>Deployed By:</p>
                                        <p className='details-data'>{curr_deployment_data && curr_deployment_data.deploy_by_user ? curr_deployment_data.deploy_by_user : "NA"}</p>
                                    </div>
                                </div>
                                <div className='deploy-user single-details-div'>
                                    <div>
                                        <p className='details-type'>Duration</p>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <span className='ri-time-line font-16' style={{ lineHeight: "1", color: "#787878" }}></span>
                                            <p className='details-data' style={{ marginLeft: "4px" }}>{calculateDuration(curr_deployment_data?.activity_status?.start_time, curr_deployment_data?.activity_status?.end_time)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='rollback-points'>
                            <p className='rollback-header'>SELECT ROLLBACK</p>
                            <RollbackPoints handleSingleDeployOpen={(id, cd_id, data) => handleSingleDeployOpen(id, cd_id, data, curr_deployment_data)} rollback_list={rollback_list} handleRollbackSelect={handleRollbackSelect} selected_rollbacks={selected_rollbacks} />
                        </div>
                    </div>
            }

        </>
    );
}

const RollbackPoints = (props) => {
    const handleSingleDeployOpen = props.handleSingleDeployOpen;
    const service_data = props.service_data;
    const rollback_points = props.rollback_points;
    const state = props.state;
    const fetchPrevRollbackPoints = props.fetchPrevRollbackPoints
    const fetchNextRollbackPoints = props.fetchNextRollbackPoints
    const fetchPageRollbackInfo = props.fetchPageRollbackInfo
    const handleRollbackSelect = props.handleRollbackSelect
    const selected_rollbacks = props.selected_rollbacks
    const handleClickOnRemove = props.handleClickOnRemove
    const rollback_list = props.rollback_list

    console.log(rollback_list, "bsdhbchdschd")

    const [checked, setChecked] = React.useState(true);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    function isRollbackSelected(selected_id) {
        if (selected_rollbacks && selected_rollbacks.length > 0) {
            var clicked_rollback = selected_rollbacks.find((item) => {
                return item.rollback_id == selected_id
            })

            if (clicked_rollback) {
                return true;
            }
        }
    }

    function isSelectedServices() {
        if (state.selected_services.includes(service_data.name)) {
            return true;
        } else {
            return false;
        }
    }

    function calculateDuration(startTime, endTime) {
        const start = new Date(startTime);
        const end = new Date(endTime);
        const durationInSeconds = (end - start) / 1000; // Convert milliseconds to seconds

        if (durationInSeconds < 60) {
            return `${Math.floor(durationInSeconds)}s`;
        } else if (durationInSeconds < 3600) {
            return `${Math.floor(durationInSeconds / 60)}m`;
        } else {
            return `${Math.floor(durationInSeconds / 3600)}h`;
        }
    }

    function calculateTimeElapsed(startTime) {
        const start = new Date(startTime);
        const now = new Date();
        const elapsedMilliseconds = now - start;

        // Convert elapsed time to seconds, minutes, hours, days, or months
        const seconds = Math.floor(elapsedMilliseconds / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);

        if (months > 0) {
            return `${months} month${months > 1 ? 's' : ''} ago`;
        } else if (days > 0) {
            return `${days} day${days > 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        } else {
            return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
        }
    }

    function isRollbackSelected(selected_id) {
        if (selected_rollbacks.length > 0) {
            var clicked_rollback = selected_rollbacks.find((item) => {
                return item.rollback_id == selected_id
            })

            if (clicked_rollback) {
                return true;
            }
        }
    }


    return (
        <div className='rollback-list'>
            <div className='rollback-points-header'>
                <p className='header-name'>Deploy ID / Duration</p>
                <p className="header-name">Deployed By</p>
                <p className='header-name'>Artifact ID</p>
                <p className='header-name'>Branch</p>
                <p></p>
            </div>
            {
                rollback_list && rollback_list.length > 0 && rollback_list.map((rollback_point, index) => {
                    return (
                        <div key={index} className='rollback-points-strip' style={isRollbackSelected(rollback_point.id) ? { backgroundColor: "#F5FAFF" } : {}} onClick={() => handleRollbackSelect(rollback_point)}>
                            <div className='deploy-id'>
                                {
                                    isRollbackSelected(rollback_point.id) ?
                                        <div className='selected-deploy-icon'>
                                            <span className='ri-checkbox-circle-fill' style={{ color: "#FFFFFF", fontSize: "24px", lineHeight: "1" }}></span>
                                        </div>
                                        :
                                        <div className='deploy-icon'>
                                            <span className='ri-rocket-2-line' style={{ color: "#0086FF", fontSize: "24px", lineHeight: "1" }}></span>
                                        </div>
                                }
                                <div style={{ marginLeft: "22px" }}>
                                    <p className='info-heading'>Deploy #{rollback_point.deploy_number}</p>
                                    <div className='d-flex align-center'>
                                        <span className='ri-time-line font-16' style={{ color: "#787878" }}></span>
                                        <p className='info' style={{ marginLeft: "4px", marginTop: "4px" }}>{calculateDuration(rollback_point?.activity_status?.start_time, rollback_point?.activity_status?.end_time)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='deployed-by'>
                                <p className='info-heading'>{rollback_point.deploy_by_user ? rollback_point.deploy_by_user : "NA"}</p>
                                <p className='info'>{calculateTimeElapsed(rollback_point.activity_status.start_time)}</p>
                            </div>
                            <div className='artifact'>
                                <p className='info-heading'>{rollback_point.deploy_tag}</p>
                            </div>
                            <div className='branch'>
                                <p className='info-heading'>{rollback_point.build_metadata.build_parameters ? rollback_point.build_metadata.build_parameters.branch_name : "NA"}</p>
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Tooltip title="View Details">
                                    <div className='view-icon' onClick={(e) => { e.stopPropagation(); handleSingleDeployOpen(rollback_point.id, rollback_point.env_cd_detail_id, rollback_point) }}>
                                        <span className='ri-eye-line' style={{ color: "#124D9B", fontSize: "20px", lineHeight: "1" }}></span>
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}


const HeadContent = (props) => {
    const service_name = props.service_name;
    const environments = props.environments;
    const service_id = props.service_id
    // const all_envs = Object.keys(environments);
    const env_id = props.env_id;
    const application_id = props.application_id;
    const service_data = props.service_data;

    return (
        <>

            <>
                <SquareAvatar varient="double" name={service_name.toUpperCase()} />
                <div className="" style={{ marginLeft: "12px", }}>
                    <div className="service-name">
                        <Link to={"/application/" + props.application_id + "/service/" + props.service_id + "/detail"}>
                            {service_name.toUpperCase()}
                        </Link>
                    </div>
                </div>
            </>
        </>
    );
}

export default RollbackServiceListing;