import React, { useState, useEffect, useRef, version } from 'react';
import { styled } from '@material-ui/core/styles';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { FormatTime } from '../../../../util/util';
import { Loading } from '../../../utils/Loading';
import { Error } from '../../../utils/Error';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import InvokeApi, { PostData, SubscribeToApi, UnsubscribeToApi } from '../../../../util/apiInvoker';
import moment from 'moment';
import { Link } from 'react-router-dom';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import StopIcon from '@material-ui/icons/Stop';
import LinkIcon from '@material-ui/icons/Link';
import PipelineDeployStatus from './PipelineDeployStatus';
import MonitoringDialogue from '../../../service/detail/components/MonitoringDialogue';
import Popover from '@material-ui/core/Popover';
import MultipleDeploymentCard from './MultipleDeploymentCard';
const DeplayJobExecutionCard = props => {

    const delay_deploy_task_instance = props.delay_deploy_task_instance;
    const { pipeline_id, pipeline_instance_id, stage_instance_id, task_instance_id } = props;
    const selected_component_task_id = props.selected_component_task_id;
    const update_selected_component_task = props.update_selected_component_task;
    const open = selected_component_task_id == delay_deploy_task_instance.id;
    const showLogsCallback = props.showLogs;
    const stage = props.stage;

    const start_time = new Date(delay_deploy_task_instance.created_at);
    const end_time = new Date(delay_deploy_task_instance.updated_at);
    const diff = end_time - start_time;
    const time_taken = FormatTime(diff);


    const toggleSectionOpen = () => {
        update_selected_component_task(!open ? delay_deploy_task_instance.id : null);
    }


    function getProgressStatusIcon() {
        let statusForIcon = delay_deploy_task_instance?.activity_status ? delay_deploy_task_instance?.activity_status?.status : delay_deploy_task_instance?.status;
        switch (statusForIcon) { //component_task_instance.status
            case "SUCCESS":
                return (
                    <div className="circle-status circle-status-approved"><div class="flaticon-check-symbol"></div></div>
                );
            case "FAILED":
                return (
                    <div className="circle-status circle-status-failed"><div className="flaticon-round-info-button"></div></div>
                );
            case "RUNNING":
                return (
                    <div className="circle-status circle-status-pending"><div class="flaticon-clock-with-white-face"></div></div>
                );
            case "REVOKED":
                return (
                    <div className="circle-status circle-status-revoked"><TimelapseIcon fontSize="large" className="color-revoked" /></div>
                );
            case "STOPPED":
                return (
                    <div className="circle-status circle-status-stopped" ><StopIcon fontSize="large" className="color-stopped" /></div>
                );
            case "IN_QUEUE":
                return (
                    <div className="circle-status circle-status-inqueue"><div class="flaticon-clock-with-white-face"></div></div>
                );
            default:
                return (
                    <div className="circle-status circle-status-pending"><div class="flaticon-clock-with-white-face"></div></div>
                );
        }
    }


    return (
        <>
                <ServiceExecution>
                    <div className="vertical-border-service-card" ></div>
                    <div className="vertical-border-one" style={open ? { height: '11%' } : { height: '50%' }}></div>
                    <span className="border-stage-card">
                        {getProgressStatusIcon()}
                    </span>
                    <Header>
                        {/* <div className="flaticon-check-symbol"></div> */}
                        <div className="service"> 
                        Delay Job Execution
                        </div>
                        {/* <a href="#viewFullLogs" title="view logs" onClick={showLogs} className="flaticon-google-drive-file"></a> */}
                        <Link
                            to={"/logs?global_task_id=" + delay_deploy_task_instance.global_task_id +
                                "&status=" + delay_deploy_task_instance.status +
                                "&tab_id=" + delay_deploy_task_instance.id
                            }
                            title="view logs"
                            target="_blank"
                            // onClick={showLogs} 
                            className="flaticon-google-drive-file"></Link>
                        <div className={delay_deploy_task_instance.status == "SUCCESS" ? "status-div green-text" : delay_deploy_task_instance.status == "FAILED" ? "status-div red-text" : "status-div yellow-text"}>&nbsp;{delay_deploy_task_instance.status}&nbsp;</div>
                        <div className="duration">{time_taken} </div>
                        {
                            open ?
                                <div className="flaticon-expand-arrow" onClick={toggleSectionOpen}></div> :
                                <div className="flaticon-expand-button" onClick={toggleSectionOpen}></div>
                        }
                    </Header>

                    {
                        open ?
                            <Body>
                                {
                                    delay_deploy_task_instance.delay_task_detail &&
                                    <>
                                        <div className="steps-list" style={{display:'grid', gridTemplateColumns:'200px 1fr'}}>
                                            <div className="service">Deplay Duration:</div>
                                            <div className="status-div green-text">&nbsp; {delay_deploy_task_instance.delay_task_detail.time} Mins</div>
                                        </div>
                                    </>
                                }
                            </Body> : null
                    }
                </ServiceExecution>
        </>
    )
}

export default DeplayJobExecutionCard;


const usePopOverStyles = makeStyles(() => ({
    pop_over: {
        position: 'absolute',
        left: '320px',
        top: '-48px',
        zIndex: 999
    }
}));
const ServiceExecution = styled('div')({
    width: '324px',
    backgroundColor: '#4652a2',
    borderRadius: '4px',
    display: 'block',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#4652a2',
    marginLeft: '5rem',
    marginRight: '4rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    position: 'relative',
    '&:last-child > .vertical-border-service-card': {
        display: 'none'
    },
    '&:only-child .main-vertical-div-pipeline > .vertical-border-pipeline': {

    },
    '&:last-child > .vertical-border-service-card:last-child': {
        // display: 'none'
    },
    '& .vertical-border-service-card': {
        top: '24px',
        left: '-30px',
        width: '3px',
        height: '104%',
        position: 'absolute',
        backgroundColor: '#666'
    },
    '&:only-child > .vertical-border-one': {
        display: 'block!important',
        height: '40%',
    },
    '& .vertical-border-one': {
        top: '-11px',
        left: '-30px',
        width: '3px',
        height: '50%',
        position: 'absolute',
        backgroundColor: '#666',
    },
    '& .border-stage-card': {
        height: '3px',
        backgroundColor: '#666',
        width: '30px',
        position: 'absolute',
        left: '-28px',
        top: '18px'
    },
    '& .circle-status': {
        height: '32px',
        width: '32px',
        border: '3px solid #666',
        position: 'relative',
        left: '-20px',
        bottom: '19px',
        borderRadius: '50%',
        backgroundColor: '#fff',
        zIndex: 999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    '& .circle-status-approved': {
        border: '3px solid #61e187'
    },
    '& .circle-status-approved .flaticon-check-symbol:before': {
        fontSize: '12px!important',
        color: '#61e187'
    },
    '& .circle-status-pending': {
        border: '3px solid #ffbf00'
    },
    '& .circle-status-inqueue': {
        border: '3px solid #b1cde3 !important'
    },
    '& .circle-status-inqueue .flaticon-clock-with-white-face:before': {
        fontSize: '12px!important',
        color: '#b1cde3',
    },
    '& .circle-status-revoked': {
        border: '3px solid #c294f1'
    },
    '& .circle-status-revoked': {
        border: '3px solid #c294f1'
    },
    '& .circle-status-stopped': {
        border: '3px solid #e9797e'
    },
    '&  .circle-status-pending .flaticon-clock-with-white-face:before': {
        fontSize: '12px!important',
        color: '#ffbf00'
    },
    '& .circle-status-failed': {
        border: '3px solid #ff8969'
    },
    '&  .circle-status-failed .flaticon-round-info-button:before': {
        fontSize: '16px!important',
        color: '#ff8969'
    },
})
const Header = styled('div')({
    display: 'flex',
    alignItems: 'center',
    padding: '5px 15px',
    "& .flaticon-google-drive-file": {
        color: '#fff',
        cursor: 'pointer',
        margin: '0px 8px'
    },
    '& .flaticon-schedule-button': {
        color: '#929ac9'
    },
    '& .running': {
        color: "#ffc514!important"
    },
    '& .flaticon-schedule-button:before': {
        fontSize: '16px!important'
    },
    '& .service': {
        color: '#b5bbd9',
        lineHeight: '18px',
        fontSize: '12px',
        color: '#fff',
        margin: '0px 5px',
        width: '140px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    '& .service-des': {
        color: '#b5bbd9',
        lineHeight: '18px',
        fontSize: '12px',
        color: '#fff',
        margin: '0px 5px',
    },
    '& .status-div': {
        fontSize: '12px',
    },
    '& .status-div.green': {
        backgroundColor: 'inherit',
        color: '#79e599'
    },
    '& .duration': {
        color: '#fff',
        fontSize: '12px',
        margin: '0px 5px',
        whiteSpace: 'nowrap'
    },
    '& .flaticon-expand-arrow': {
        justifySelf: 'flex-end',
        color: '#fff!important',
        cursor: 'pointer',
        marginLeft: 'auto',
        transition: 'all .3s ease',
    },
    '& .flaticon-expand-arrow:before, .flaticon-expand-button:before': {
        fontSize: '12px!important'
    },
    '& .flaticon-check-symbol': {
        color: '#79e599'
    },
    '& .flaticon-expand-button': {
        justifySelf: 'flex-end',
        color: '#fff!important',
        cursor: 'pointer',
        marginLeft: 'auto',
        color: '#fff',
        transition: 'all .3s ease',
    }
})
const SubHeader = styled('div')({
    display: 'flex',
    alignItems: 'center',
    padding: '5px 15px',
    background: 'blue',

    '& .deployment-name': {
        color: '#b5bbd9',
        lineHeight: '18px',
        fontSize: '12px',
        color: '#fff',
        margin: '0px 5px',
        width: '140px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },

    '& .flaticon-expand-arrow': {
        justifySelf: 'flex-end',
        color: '#fff!important',
        cursor: 'pointer',
        marginLeft: 'auto',
        transition: 'all .3s ease',
    },
    '& .flaticon-expand-arrow:before, .flaticon-expand-button:before': {
        fontSize: '10px !important'
    },
    '& .flaticon-expand-button': {
        justifySelf: 'flex-end',
        color: '#fff!important',
        cursor: 'pointer',
        marginLeft: 'auto',
        color: '#fff',
        transition: 'all .3s ease',
    }
})

const Body = styled('div')({
    backgroundColor: '#5e6798',
    fontSize: '12px',
    color: '#fff',
    width: '100%',
    '& .steps-list': {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '10px 15px',
        borderBottom: '1px solid #6b74a8'
    },
    '& .flaticon-schedule-button': {
        color: '#929ac9'
    },
    '& .running': {
        color: "#ffc514!important"
    },
    '& .flaticon-schedule-button:before': {
        fontSize: '16px!important'
    },
    '& .service': {
        color: '#b5bbd9',
        lineHeight: '18px',
        fontSize: '12px',
        color: '#fff',
        margin: '0px 5px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    '& .service-des': {
        color: '#b5bbd9',
        lineHeight: '18px',
        fontSize: '12px',
        color: '#fff',
        margin: '0px 5px',
    },
    '& .status-div': {
        fontSize: '12px'
    },
    '& .status-div.green': {
        backgroundColor: 'inherit',
        color: '#79e599'
    },
    '& .duration': {
        color: '#fff',
        fontSize: '12px',
        margin: '0px 5px',
        whiteSpace: 'nowrap'


    },
    '& .flaticon-expand-arrow': {
        justifySelf: 'flex-end',
        color: '#fff!important',
        cursor: 'pointer',
        marginLeft: 'auto'
    },
    '& .flaticon-expand-arrow:before': {
        fontSize: '12px!important'
    },
    '& .flaticon-check-symbol': {
        color: '#79e599'
    }
})
const Footer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px',
    fontSize: '12px',
    color: '#fff',
    textAlign: 'center',
    justifyContent: 'center',
    '& .artifact-name': {
        color: '#b5bbd9',
        display: 'none'
    }
})