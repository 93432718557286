import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

import CommonHorizontalTab from "../../../../components/genericComponents/CommonHorizontalTab";
import { Grid } from "@material-ui/core";
import Autoscaling from "./ServiceRevampOverview/CdOverview/Autoscaling";

const OtherDeploymentDetails = (props) => {
    const selectedEnvDetails = props.selectedEnvDetails;
    const service_env = props.service_env;
    const component_env_id = service_env.id;
    var cd_data = props.cd_data ? props.cd_data : null;
    const complete_cd_data = props.complete_cd_data;
    const selected_deployment_name = props.selected_deployment_name;
    const [stableCdData, setStableCdData] = useState(null)
    console.log("extraProps___", cd_data)

    useEffect(() => {
        getApprovedCdData()
    }, [complete_cd_data, selected_deployment_name])

    function getApprovedCdData() {
        var selected_cd_data_list = []
        complete_cd_data && complete_cd_data.forEach((item) => {
            if (item.deployment_name == selected_deployment_name) {
                selected_cd_data_list.push(item)
            }
        })

        getStableCD(selected_cd_data_list);

        // selected_cd_data_list.forEach((item) => {
        //     if (item.status == "APPROVED") {
        //         setStableCdData(item)
        //     }
        // })
    }

    console.log(stableCdData, "rtyuioghjkxcvbnm ==============>")

    var approved_cd_data = [];

    function getStableCD(cd_list) {
        cd_list.forEach((item) => {
            if (item.status == "APPROVED") {
                console.log("running")
                approved_cd_data.push(item);
                setStableCdData(approved_cd_data)
            }
        })
    }

    console.log(stableCdData, "rtyuihjkl ==============>fghjkvbnmrf")

    const inherits = props.inherits ? props.inherits : {};
    const prev_state = props.prev_state ? props.prev_state : null;
    const extraProps = props.extraProps;
    const [state, setState] = useState(prev_state ? prev_state : {
        data: {
            choose_upload_type: 'guided_form'
        },
        selectedTab: 2,
        count: 0,
        error: {},
        validations: {
        },
    })

    function checkIfDataAvailable(data) {
        if (data) {
            return true
        } else
            return false
    }

    var tab_list = [
        // {
        //     order: 1,
        //     tabName: 'Access Level',
        //     data: false
        // },
        {
            order: 2,
            tabName: 'Auto Scaling',
            data: false
        }
    ]


    const updateSelectedTab = (selectedTabOrder) => {
        setState(new_state => ({
            ...new_state,
            selectedTab: selectedTabOrder
        }));
    }
    function refreshState() {
        setState(new_state => ({
            ...new_state,
            count: new_state.count + 1
        }));
    }
    console.log(complete_cd_data, "fdsjkafsaj ===========>")
    console.log(selected_deployment_name, "fdsjkafsaj ===========>")
    return (
        
            <div className="section-service-overview" style={{ border: '0px', padding: '10px 0px 0px' }}>
                
                    <div className="d-flex align-center space-between pd-10 border-bottom">
                        <div className="" >
                            <p className="font-14 font-weight-300 lh-12">
                                Other Deployment Details
                            </p>
                            <p className="font-11 text-grey-6e">
                                To know more about access levels please read the <a href="https://www.opstree.com/buildpiper/documentation/" target="_blank">Documentation</a>
                            </p>
                        </div>
                    </div>
                    <div className="d-grid" style={{ gridTemplateColumns: '20% 1fr' }}>
                        <div className="border-right tabs_only bg-fc-grey">
                            <CommonHorizontalTab
                                tabList={tab_list}
                                selectedTabOrder={state.selectedTab}
                                variant={"veritcal_tab_with_active_bar_without_check"}
                                updateSelectedTab={updateSelectedTab} />
                        </div>
                        <Grid container>

                            {
                                cd_data && cd_data.length > 0 ?
                                    stableCdData && stableCdData.length > 0 ?
                                        <>
                                            {
                                                state.selectedTab == 2 &&
                                                <Grid item lg={12} className="pd-10">
                                                    <Autoscaling
                                                        extraProps={
                                                            {
                                                                application_id: selectedEnvDetails.application_id,
                                                                service_id: selectedEnvDetails.service_id,
                                                                component_env_id: component_env_id,
                                                                ...props.extraProps
                                                            }
                                                        }
                                                        cd_data={stableCdData}
                                                        refresh={refreshState}
                                                    />
                                                </Grid>
                                            }
                                        </>
                                        :
                                        <ErrorBox message="Configured deployment details are not approved yet. wait for the approval or contact to project admin" />
                                    :
                                    <ErrorBox message=" Please configure deployment details to setup HPA(Horizontal Pod Autoscaling)" />
                            }
                        </Grid>
                    </div>
                
            </div>
        
    )
}

OtherDeploymentDetails.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default OtherDeploymentDetails;

const ErrorBox = (props) => {
    return (
        <div className="d-flex align-center justify-center" style={{ height: 400, width: '100%' }}>
            <div
                className="text-center alert alert-primary border-style-dashed bg-white"
                style={{ width: '400px', marginBottom: '20px' }}>
                <div className="text-box">
                    <p className="main-text  font-14">{props.message}</p>
                    {/* <span className="sub-text mb-10">
                        <div>Please note to deploy a service CD is required. Please choose from options below to add CD.</div>
                    </span> */}
                </div>
            </div>
        </div>
    )
}


ErrorBox.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }