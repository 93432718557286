import MuiButton from './MuiButton';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTypography from './MuiTypography';

export default {
  MuiButton,
  MuiIconButton,
  MuiPaper,
  MuiTableCell,
  MuiTableHead,
  MuiTypography,
  MuiTooltip: {
    tooltip: {
      fontSize: '12px !important', 
      backgroundColor: '#234361 !important',
      fontFamily: "'Montserrat', sans-serif !important",
      padding: "8px 10px 8px 10px !important",
    },
    arrow: {
      color: '#234361 !important',
    }
  },
};
