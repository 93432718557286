import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import BottomNavigator from '../../../components/genericComponents/BottomNavigator';
import ServiceDetail from './panels/ServiceDetail';
import { makeStyles } from '@material-ui/styles';
import CommonHorizontalTab from '../../../components/genericComponents/CommonHorizontalTab';
import { CreateOrUpdateServiceSummary, CreateOrUpdateServiceEnvFromPanel, GetServiceSummary, GetEnvironmentDetails, GetUIFormatedEnvData, getDefaultBasicInfoPanel, getDefaultServicePanel, getDefaultPrequisitePanel, getDefaultConfigAndAutoScalingPanel, getDefaultEnvPanel, getDefaultResourceQuotaPanel, getDefaultNetworkingPanel, getDefaultReadinessAndLivelinessPanel, getDefaultRole, getManifestPanel } from '../../../controllers/ServiceController';
import { ValidateDataSet, VALIDATION_TYPE_REQUIRED, VALIDATION_TYPE_KEY_VALUE_REQUIRED, VALIDATION_TYPE_START_WITH_SLASH, VALIDATION_TYPE_COMMA_SEPRATED_STRING } from '../../../util/Validator';
import { RemoveFromArray } from '../../../util/util';
import Review from './panels/Review';
import HeaderWithName from './Components/HeaderWithServiceName';
import Alert from './Components/Alert';
import Grid from '@material-ui/core/Grid';
import CDDetails from './panels/CDDetails';
import ManifestAlert from './Components/ManifestAlert';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
    }
}))

const panel_list = [
    {
        tabName: "Service Details",
        order: 1
    },
    {
        tabName: "CD Details",
        order: 2
    }
]
export const REQUEST_TYPE_ADD_SERVICE = "REQUEST_TYPE_ADD_SERVICE";
export const REQUEST_TYPE_EDIT_SERVICE = "REQUEST_TYPE_EDIT_SERVICE";
export const REQUEST_TYPE_ADD_ENV = "REQUEST_TYPE_ADD_ENV";
export const REQUEST_TYPE_EDIT_ENV = "REQUEST_TYPE_EDIT_ENV";


const ServiceAdd = (props) => {
    const classes = useStyles();
    const { application_id, service_id, env_id } = props.match.params;
    var request_type = REQUEST_TYPE_ADD_SERVICE;
    var active_panel_order = 1;
    resolveRequestType();
    const default_state = {
        active_panel_order: active_panel_order,
        panel: {
            1: getDefaultServicePanel(),
            2: getDefaultPrequisitePanel(),
            3: getDefaultBasicInfoPanel(),
            4: getDefaultResourceQuotaPanel(),
            4.5: getManifestPanel(),
            5: getDefaultNetworkingPanel(),
            6: getDefaultConfigAndAutoScalingPanel(),
            7: getDefaultEnvPanel(),
            8: getDefaultReadinessAndLivelinessPanel(),
            9: getDefaultRole(),
        },
    };
    const [state, setState] = useState(default_state);
    var env_name = state.panel[2].data.env_master ? state.panel[2].data.env_master.toLowerCase() : ""
    var build_strategy = state.panel[1].data.build_strategy;
    var service_name = state.panel[1].data.name;
    const inherits = {};

    useEffect(() => {
        if (request_type == REQUEST_TYPE_ADD_SERVICE) {
            setState(default_state);
        } else if (request_type == REQUEST_TYPE_EDIT_SERVICE) {
            GetServiceSummary(service_id, handleSuccesLoadServiceRequest, handleFailedLoadServiceRequest);
            setState({
                ...state,
                panel: {
                    ...state.panel,
                    1: {
                        ...state.panel[1],
                        status: "GET_REQUESTED",
                    }
                }
            });
        } else if (request_type == REQUEST_TYPE_EDIT_ENV) {
            GetEnvironmentDetails(service_id, env_id, handleSuccessLoadEnviromentRequest, handleFailedLoadEnviromentRequest);
            setState({
                ...state,
                panel: {
                    ...state.panel,

                    2: {
                        ...state.panel[2],
                        status: "GET_REQUESTED",
                    }
                }
            });
        } else if (request_type == REQUEST_TYPE_ADD_ENV) {
            GetServiceSummary(service_id, handleSuccesLoadServiceRequest, handleFailedLoadServiceRequest);
            setState({
                ...state,
                panel: {
                    ...state.panel,
                    1: {
                        ...state.panel[1],
                        data: {
                            id: Number(service_id)
                        }
                    },
                    2: {
                        ...state.panel[2],
                        status: "GET_RECEIVED",
                    }
                }
            });
        }
    }, [application_id, service_id, env_id, request_type]);


    // Call for API's

    const postServiceInfo = () => {
        const data = state.panel[1].data
        data.application_id = Number(application_id);
        data.credential_id = data.credential_id ? Number(data.credential_id) : null;

        CreateOrUpdateServiceSummary(data, handleSuccessPostForServiceInfo, handleFailedPostForServiceInfo);
    }

    const postServiceEnvInfo = (is_manifest) => {
        CreateOrUpdateServiceEnvFromPanel(state.panel, handleSuccessPostForServiceEnvInfo, handleFailedPostForServiceEnvInfo, env_id, is_manifest);
    }

    // Callbacks to receive response or errors.
    function handleSuccessLoadEnviromentRequest(response) {
        var env_panel = GetUIFormatedEnvData(response, request_type);
        env_panel[2].data.env_name = state.panel[2].data.env_name ? state.panel[2].data.env_name : env_panel[2].data.env_name;
        // env_panel[2].data.env_master = state.panel[2].data.env_master ? state.panel[2].data.env_master :env_panel[2].data.env_master;
        setState(new_state => ({
            ...new_state,
            panel: {
                ...new_state.panel,
                ...env_panel,
                2: {
                    ...env_panel[2],
                    status: "GET_RECEIVED",
                }
            },
            asked_for_manifest: response.manifest_meta_data ? true : false,
            is_manifest: response.manifest_meta_data ? true : false
        }));
    }

    function handleFailedLoadEnviromentRequest(error) {
        console.log(error);
    }

    function handleSuccesLoadServiceRequest(response) {
        setState(new_state => ({
            ...new_state,
            env_list: response.env,
            panel: {
                ...new_state.panel,
                1: {
                    ...new_state.panel[1],
                    status: "GET_SUCCESS",
                    data: {
                        ...response,
                        credential_id: response.credential ? response.credential.id : ""
                    }
                },
            }
        }));
    }

    function handleFailedLoadServiceRequest(response) {
        console.log("==== >", response);
    }

    const handleSuccessPostForServiceInfo = (response) => {

        var active_panel_order = state.active_panel_order;
        switch (request_type) {
            case REQUEST_TYPE_EDIT_SERVICE:
                active_panel_order = 10;
                break;
            default:
                active_panel_order = state.active_panel_order + 1;
                break;
        }
        setState({
            ...state,
            active_panel_order: active_panel_order,
            panel: {
                ...state.panel,
                1: {
                    ...state.panel[1],
                    status: "POST_SUCCESS",
                    data: response
                }
            }
        });
    }

    const handleFailedPostForServiceInfo = (error) => {
        setState({
            ...state,
            panel: {
                ...state.panel,
                1: {
                    ...state.panel[1],
                    status: "POST_FAILED",
                    error: error
                }
            }
        });
    }

    const handleSuccessPostForServiceEnvInfo = (response) => {
        setState((new_state) => ({
            ...new_state,
            active_panel_order: 10,
            save_service_env_info_status: "POST_SUCCESS"
        }));
    }

    const handleFailedPostForServiceEnvInfo = (error) => {
        setState({
            ...state,
            active_panel_order: active_panel_order + 1,
            save_service_env_info_status: "POST_FAILED"
        });
    }



    // form's input value change handlers
    const updateData = (key, value) => {
        const order = state.active_panel_order;
        var new_validations = validationReducer(key, value, state.panel[order].data, state.panel[order].validations);
        var new_error = errorReducer(key, value, state.panel[order].data, state.panel[order].error)
        if (key == "env_clone") {
            if (Number(value)) {
                GetEnvironmentDetails(service_id, Number(value), handleSuccessLoadEnviromentRequest, handleFailedLoadEnviromentRequest);
                setState({
                    ...state,
                    panel: {
                        ...state.panel,

                        2: {
                            ...state.panel[2],
                            status: "GET_REQUESTED",
                        }
                    }
                });
            }
        } else {
            setState(new_state => ({
                ...new_state,
                panel: {
                    ...new_state.panel,
                    [order]: {
                        ...new_state.panel[order],
                        data: {
                            ...new_state.panel[order].data,
                            [key]: value
                        },
                        error: {
                            ...new_error,
                            [key]: null,
                        },
                        validations: {
                            ...new_validations
                        }
                    }
                }
            }));
        }
    }

    const updateErrors = (error) => {
        const order = state.active_panel_order;
        setState({
            ...state,
            panel: {
                ...state.panel,
                [order]: {
                    ...state.panel[order],
                    error: error
                }
            }
        });
    }

    const validationReducer = (key, value, data, validations) => {
        var new_validations = validations;
        switch (key) {
            case "custom_ingress_enabled":
                var field_validations = validations.custom_ingress_manifest;
                if (value) {
                    if (!field_validations.includes(VALIDATION_TYPE_REQUIRED)) {
                        field_validations.push(VALIDATION_TYPE_REQUIRED);
                    }
                    RemoveFromArray(new_validations.expose_url, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.expose_path, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.tls_cert_detail_id, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.default_port, VALIDATION_TYPE_REQUIRED);
                } else {
                    RemoveFromArray(field_validations, VALIDATION_TYPE_REQUIRED);
                    if (data.access_level != "PRIVATE") {
                        if (!new_validations.expose_url.includes(VALIDATION_TYPE_REQUIRED)) {
                            new_validations.expose_url.push(VALIDATION_TYPE_REQUIRED);
                        }
                        if (!new_validations.expose_path.includes(VALIDATION_TYPE_REQUIRED)) {
                            new_validations.expose_path.push(VALIDATION_TYPE_REQUIRED);
                        }
                    } else {
                        RemoveFromArray(new_validations.expose_url, VALIDATION_TYPE_REQUIRED);
                        RemoveFromArray(new_validations.expose_path, VALIDATION_TYPE_REQUIRED);
                        RemoveFromArray(new_validations.tls_cert_detail_id, VALIDATION_TYPE_REQUIRED);
                    }

                }
                new_validations.custom_ingress_manifest = field_validations;
                break;
            case "access_level":
                if (value != "PRIVATE") {
                    if (!new_validations.expose_url.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.expose_url.push(VALIDATION_TYPE_REQUIRED);
                    }
                    if (!new_validations.expose_path.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.expose_path.push(VALIDATION_TYPE_REQUIRED);
                    }
                } else {
                    RemoveFromArray(new_validations.expose_url, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.expose_path, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.tls_cert_detail_id, VALIDATION_TYPE_REQUIRED);
                }
                break;
            case "tls_cert_enabled":
                var field_validations = validations.tls_cert_detail_id;
                if (value) {
                    if (!field_validations.includes(VALIDATION_TYPE_REQUIRED)) {
                        field_validations.push(VALIDATION_TYPE_REQUIRED);
                    }
                } else {
                    RemoveFromArray(field_validations, VALIDATION_TYPE_REQUIRED);
                }
                new_validations.tls_cert_detail_id = field_validations;
                break;
            case "config_management":
                if (value) {
                    if (data.vcs) {
                        if (!new_validations.config_repo_url.includes(VALIDATION_TYPE_REQUIRED)) {
                            new_validations.config_repo_url.push(VALIDATION_TYPE_REQUIRED);
                        }
                        if (!new_validations.config_repo_branch.includes(VALIDATION_TYPE_REQUIRED)) {
                            new_validations.config_repo_branch.push(VALIDATION_TYPE_REQUIRED);
                        }
                        if (!new_validations.source_path_with_file_name.includes(VALIDATION_TYPE_REQUIRED)) {
                            new_validations.source_path_with_file_name.push(VALIDATION_TYPE_REQUIRED);
                        }
                        RemoveFromArray(new_validations.properties_files, VALIDATION_TYPE_REQUIRED);
                    } else {
                        if (!new_validations.properties_files.includes(VALIDATION_TYPE_REQUIRED)) {
                            new_validations.properties_files.push(VALIDATION_TYPE_REQUIRED);
                        }
                        RemoveFromArray(new_validations.config_repo_url, VALIDATION_TYPE_REQUIRED);
                        RemoveFromArray(new_validations.config_repo_branch, VALIDATION_TYPE_REQUIRED);
                        RemoveFromArray(new_validations.source_path_with_file_name, VALIDATION_TYPE_REQUIRED);
                    }
                    if (!new_validations.destination_path.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.destination_path.push(VALIDATION_TYPE_REQUIRED);
                    }
                    if (!new_validations.destination_path.includes(VALIDATION_TYPE_START_WITH_SLASH)) {
                        new_validations.destination_path.push(VALIDATION_TYPE_START_WITH_SLASH);
                    }
                } else {
                    RemoveFromArray(new_validations.destination_path, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.destination_path, VALIDATION_TYPE_START_WITH_SLASH);
                    RemoveFromArray(new_validations.config_repo_url, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.config_repo_branch, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.source_path_with_file_name, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.properties_files, VALIDATION_TYPE_REQUIRED);
                }
                break;
            case "vcs":
                if (value) {
                    if (!new_validations.config_repo_url.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.config_repo_url.push(VALIDATION_TYPE_REQUIRED);
                    }
                    if (!new_validations.config_repo_branch.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.config_repo_branch.push(VALIDATION_TYPE_REQUIRED);
                    }
                    if (!new_validations.source_path_with_file_name.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.source_path_with_file_name.push(VALIDATION_TYPE_REQUIRED);
                    }
                    RemoveFromArray(new_validations.properties_files, VALIDATION_TYPE_REQUIRED);
                } else {
                    if (!new_validations.properties_files.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.properties_files.push(VALIDATION_TYPE_REQUIRED);
                    }
                    RemoveFromArray(new_validations.config_repo_url, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.config_repo_branch, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.source_path_with_file_name, VALIDATION_TYPE_REQUIRED);
                }
                if (!new_validations.destination_path.includes(VALIDATION_TYPE_REQUIRED)) {
                    new_validations.destination_path.push(VALIDATION_TYPE_REQUIRED);
                }
                if (!new_validations.destination_path.includes(VALIDATION_TYPE_START_WITH_SLASH)) {
                    new_validations.destination_path.push(VALIDATION_TYPE_START_WITH_SLASH);
                }
                break;
            case "ci_enabled":
                if (value) {
                    if (!new_validations.sonar_project_key.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.sonar_project_key.push(VALIDATION_TYPE_REQUIRED);
                    }
                    if (!new_validations.sonar_credential_id.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.sonar_credential_id.push(VALIDATION_TYPE_REQUIRED);
                    }
                } else {
                    RemoveFromArray(new_validations.sonar_project_key, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.sonar_credential_id, VALIDATION_TYPE_REQUIRED);
                }
                break;
            case "env_scale":
                if (value) {
                    if (!new_validations.minimum_replication.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.minimum_replication.push(VALIDATION_TYPE_REQUIRED);
                    }
                    if (!new_validations.maximum_replication.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.maximum_replication.push(VALIDATION_TYPE_REQUIRED);
                    }
                    if (!new_validations.cpu_threshold.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.cpu_threshold.push(VALIDATION_TYPE_REQUIRED);
                    }
                    if (!new_validations.ram_threshold.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.ram_threshold.push(VALIDATION_TYPE_REQUIRED);
                    }
                } else {
                    RemoveFromArray(new_validations.minimum_replication, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.maximum_replication, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.ram_threshold, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.cpu_threshold, VALIDATION_TYPE_REQUIRED);
                }
                break;
            case "liveliness":
                if (value) {
                    if (!new_validations.liveliness_url.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.liveliness_url.push(VALIDATION_TYPE_REQUIRED);
                    }
                    if (!new_validations.liveliness_delay.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.liveliness_delay.push(VALIDATION_TYPE_REQUIRED);
                    }
                    if (!new_validations.liveliness_timeout.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.liveliness_timeout.push(VALIDATION_TYPE_REQUIRED);
                    }
                    if (!new_validations.liveliness_failure.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.liveliness_failure.push(VALIDATION_TYPE_REQUIRED);
                    }
                    if (!new_validations.liveliness_period.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.liveliness_period.push(VALIDATION_TYPE_REQUIRED);
                    }
                    if (!new_validations.liveliness_port.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.liveliness_port.push(VALIDATION_TYPE_REQUIRED);
                    }
                } else {
                    RemoveFromArray(new_validations.liveliness_url, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.liveliness_delay, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.liveliness_timeout, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.liveliness_failure, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.liveliness_period, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.liveliness_port, VALIDATION_TYPE_REQUIRED);
                }
                break;
            case "readiness":
                if (value) {
                    if (!new_validations.readiness_url.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.readiness_url.push(VALIDATION_TYPE_REQUIRED);
                    }
                    if (!new_validations.readiness_delay.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.readiness_delay.push(VALIDATION_TYPE_REQUIRED);
                    }
                    if (!new_validations.readiness_timeout.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.readiness_timeout.push(VALIDATION_TYPE_REQUIRED);
                    }
                    if (!new_validations.readiness_failure.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.readiness_failure.push(VALIDATION_TYPE_REQUIRED);
                    }
                    if (!new_validations.readiness_period.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.readiness_period.push(VALIDATION_TYPE_REQUIRED);
                    }
                    if (!new_validations.readiness_port.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.readiness_port.push(VALIDATION_TYPE_REQUIRED);
                    }
                } else {
                    RemoveFromArray(new_validations.readiness_url, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.readiness_delay, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.readiness_timeout, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.readiness_failure, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.readiness_period, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.readiness_port, VALIDATION_TYPE_REQUIRED);
                }
                break;
            case "env_build_variable_enabled":
                if (value) {
                    if (!new_validations.env_build_variable.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.env_build_variable.push(VALIDATION_TYPE_REQUIRED);
                    }
                    // if(!new_validations.env_build_variable.includes(VALIDATION_TYPE_KEY_VALUE_REQUIRED)){
                    //     new_validations.env_build_variable.push(VALIDATION_TYPE_KEY_VALUE_REQUIRED);
                    // }
                } else {
                    RemoveFromArray(new_validations.env_build_variable, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.env_build_variable, VALIDATION_TYPE_KEY_VALUE_REQUIRED);
                }
                break;
            case "env_deploy_variable_enabled":
                if (value) {
                    if (!new_validations.env_deploy_variable.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.env_deploy_variable.push(VALIDATION_TYPE_REQUIRED);
                    }
                    if (!new_validations.env_deploy_variable.includes(VALIDATION_TYPE_KEY_VALUE_REQUIRED)) {
                        new_validations.env_deploy_variable.push(VALIDATION_TYPE_KEY_VALUE_REQUIRED);
                    }
                } else {
                    RemoveFromArray(new_validations.env_deploy_variable, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.env_deploy_variable, VALIDATION_TYPE_KEY_VALUE_REQUIRED);
                }
                break;
            case "env_vault_variable_enabled":
                if (value) {
                    if (!new_validations.env_vault_variable.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.env_vault_variable.push(VALIDATION_TYPE_REQUIRED);
                    }
                    if (!new_validations.env_vault_variable.includes(VALIDATION_TYPE_KEY_VALUE_REQUIRED)) {
                        new_validations.env_vault_variable.push(VALIDATION_TYPE_KEY_VALUE_REQUIRED);
                    }
                    if (!new_validations.token.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.token.push(VALIDATION_TYPE_REQUIRED);
                    }
                } else {
                    RemoveFromArray(new_validations.env_vault_variable, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.token, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.env_vault_variable, VALIDATION_TYPE_KEY_VALUE_REQUIRED);
                }
                break;
            case "manifest":
                if (value == "MANIFESTGIT") {
                    if (!new_validations.git_url.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.git_url.push(VALIDATION_TYPE_REQUIRED);
                    }
                    if (!new_validations.branch_name.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.branch_name.push(VALIDATION_TYPE_REQUIRED);
                    }
                    if (!new_validations.file_paths.includes(VALIDATION_TYPE_COMMA_SEPRATED_STRING)) {
                        new_validations.file_paths.push(VALIDATION_TYPE_COMMA_SEPRATED_STRING);
                    }
                    RemoveFromArray(new_validations.env_manifest, VALIDATION_TYPE_REQUIRED);
                    //   new_validations.deployment_name.push(VALIDATION_TYPE_REQUIRED)
                } else {
                    if (!new_validations.env_manifest.includes(VALIDATION_TYPE_REQUIRED)) {
                        new_validations.env_manifest.push(VALIDATION_TYPE_REQUIRED);
                    }
                    RemoveFromArray(new_validations.git_url, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.branch_name, VALIDATION_TYPE_REQUIRED);
                    RemoveFromArray(new_validations.file_paths, VALIDATION_TYPE_COMMA_SEPRATED_STRING);
                    // new_validations.deployment_name.push(VALIDATION_TYPE_REQUIRED)
                }
                // 
                break;
            default:
                break;
        }
        return new_validations;
    }

    const errorReducer = (key, value, data, error) => {
        var new_error = error;
        switch (key) {
            case "custom_ingress_enabled":
                var field_error = error.custom_ingress_manifest;
                if (!value) {
                    field_error = null
                }
                new_error.custom_ingress_manifest = field_error;
                break;
            case "access_level":
                if (value == "PRIVATE") {
                    new_error.expose_url = null;
                    new_error.expose_path = null;
                    new_error.tls_cert_detail_id = null;
                }
                break;
            case "tls_cert_enabled":
                var field_error = error.tls_cert_detail_id;
                if (!value) {
                    field_error = null
                }
                new_error.tls_cert_detail_id = field_error;
                break;
            case "config_management":
                new_error.properties_files = null;
                new_error.config_repo_url = null;
                new_error.config_repo_branch = null;
                new_error.source_path_with_file_name = null;
                new_error.destination_path = null;
                break;
            case "vcs":
                if (value) {
                    new_error.properties_files = null;
                } else {
                    new_error.config_repo_url = null;
                    new_error.config_repo_branch = null;
                    new_error.source_path_with_file_name = null;
                    new_error.destination_path = null;
                }
                break;
            case "liveliness":
                new_error.liveliness_url = null;
                new_error.liveliness_port = null;
                new_error.liveliness_timeout = null;
                new_error.liveliness_period = null;
                new_error.liveliness_delay = null;
                new_error.liveliness_failure = null;
                break;
            case "readiness":
                new_error.readiness_url = null;
                new_error.readiness_port = null;
                new_error.readiness_timeout = null;
                new_error.readiness_period = null;
                new_error.readiness_delay = null;
                new_error.readiness_failure = null;
                break;
            case "env_build_variable_enabled":
                if (!value) {
                    new_error.env_build_variable = null;
                }
                break;
            case "env_deploy_variable_enabled":
                if (!value) {
                    new_error.env_deploy_variable = null;
                }
                break;
            case "env_vault_variable_enabled":
                if (!value) {
                    new_error.token = null;
                    new_error.env_vault_variable = null;
                }
                break;
            case "env_scale":
                if (!value) {
                    new_error.minimum_replication = null;
                    new_error.maximum_replication = null;
                    new_error.cpu_threshold = null;
                    new_error.ram_threshold = null;
                }
                break;
            case "minimum_replication":
                if (value > data.maximum_replication) {
                    new_error.maximum_replication = "It cant be less than min";
                }
                else {
                    new_error.minimum_replication = ""
                    new_error.maximum_replication = ""

                }
                break;
            case "maximum_replication":
                if (value < data.minimum_replication) {
                    new_error.minimum_replication = "It cant be greater than max";
                }
                else {
                    new_error.maximum_replication = ""
                    new_error.minimum_replication = ""
                }
                break;
            default:
                break;
        }
        return new_error;
    }

    // UI Click handlers

    const updateActivePanel = (panel_id) => {
        setState({
            ...state,
            show_skip_alert: false,
            active_panel_order: panel_id
        })
    }

    const nextClickHandler = () => {
        var active_panel_order = state.active_panel_order;
        switch (true) {
            case (active_panel_order == 1):
                var result = ValidateDataSet(state.panel[state.active_panel_order].data, state.panel[state.active_panel_order].validations);
                if (!result.valid) {
                    updateErrors(result.error);
                } else {
                    postServiceInfo();
                    setState({
                        ...state,
                        panel: {
                            ...state.panel,
                            1: {
                                ...state.panel[1],
                                status: "POST_REQUESTED"
                            }
                        }
                    });
                }
                break;
            case (active_panel_order > 1 && active_panel_order < 10):
                var data = state.panel[state.active_panel_order].data;
                var validations = state.panel[state.active_panel_order].validations;

                console.log("data==000", data, validations);

                if (active_panel_order == 3) {
                    if (build_strategy == "ONCE" && env_name != "dev") {
                        validations.branch_name = [];
                        validations.dockerfile_path = [];
                    }
                }
            //     var t=false;
            // if(active_panel_order == 4.5)   { t = ValidateDataSet(data, state.panel[4.5].validations);}
                var result = active_panel_order == 4.5 ?

                    inherits.validateForm()&&ValidateDataSet(data, validations) : ValidateDataSet(data, validations)
                ;


                if (!result.valid) {
                    updateErrors(result.error);
                } else {
                    if (active_panel_order == 9) {
                        postServiceEnvInfo(state.is_manifest);
                        setState({
                            ...state,
                            save_service_env_info_status: "POST_REQUESTED",
                            panel: {
                                ...state.panel,
                                9: {
                                    ...state.panel[9],
                                    status: "POST_REQUESTED"
                                }
                            }

                        });
                    } else if (active_panel_order == 5) {
                        setState({
                            ...state,
                            show_skip_alert: true
                        });
                    } else if (active_panel_order == 3) {
                        if (state.asked_for_manifest) {
                            setState({
                                ...state,
                                active_panel_order: state.is_manifest ? 4.5 : 4,
                            });
                        } else {
                            if (request_type == REQUEST_TYPE_EDIT_ENV) {
                                setState({
                                    ...state,
                                    active_panel_order: state.is_manifest ? 4.5 : 4,
                                });
                            } else {
                                setState({
                                    ...state,
                                    show_manifest_alert: true
                                });
                            }
                        }
                    } else if (active_panel_order == 4.5) {

                        setState({
                            ...state,
                            panel: {
                                ...state.panel,
                                [4.5]: {
                                    ...state.panel[4.5],
                                    state: { ...inherits.getData() }
                                },
                            },
                            active_panel_order: 9,
                        });
                    }
                    else {
                        setState({
                            ...state,
                            active_panel_order: active_panel_order + 1,
                        });
                    }
                }
                break;
            case (active_panel_order == 10):
                console.log("==== wrong order");
                break;
            default:
                setState({
                    ...state,
                    active_panel_order: state.active_panel_order + 1,
                });
        }
    }

    const backClickHandler = () => {
        if (state.active_panel_order != 1) {
            if (state.is_manifest && state.active_panel_order == 9) {
                setState({
                    ...state,
                    active_panel_order: 4.5,
                });
            } else {
                setState({
                    ...state,
                    panel: active_panel_order == 4.5 ? state.panel : {
                        ...state.panel,
                        [4.5]: {
                            ...state.panel[4.5],
                            state: inherits.getData ? { ...inherits.getData() } : state.panel[4.5].state
                        },
                    },
                    active_panel_order: state.active_panel_order == 4.5 ? 3 : state.active_panel_order - 1,
                });
            }
        }
    }

    // utils
    function resolveRequestType() {
        if (service_id) {
            request_type = REQUEST_TYPE_EDIT_SERVICE;
            active_panel_order = 1;
        }
        var env_add_pattern = /env\/add/;
        if (env_add_pattern.test(window.location.href)) {
            request_type = REQUEST_TYPE_ADD_ENV;
            active_panel_order = 2;
        }
        if (env_id) {
            request_type = REQUEST_TYPE_EDIT_ENV;
            active_panel_order = 2
        }
    }

    // Helpers for rendering UI.
    const getActivePanel = () => {
        const order = state.active_panel_order;
        switch (true) {
            case (order == 1):
                return <ServiceDetail
                    data={state.panel[state.active_panel_order].data}
                    error={state.panel[state.active_panel_order].error}
                    update_data_callback={updateData}
                    request_type={request_type}
                    status={state.panel[state.active_panel_order].status} />;
            case (order > 1 && order < 10):
                return <CDDetails
                    application_id={application_id}
                    active_panel_order={state.active_panel_order}
                    data={state.panel[state.active_panel_order].data}
                    error={state.panel[state.active_panel_order].error}
                    update_data_callback={updateData}
                    status={state.panel[state.active_panel_order].status}
                    service_name={state.panel[1].data.name}
                    env_name={env_name}
                    sub_env_name={state.panel[2].data.env_name}
                    build_strategy={build_strategy}
                    request_type={request_type}
                    env_list={state.env_list}
                    is_manifest={state.is_manifest}
                    inherits={inherits}
                    state={state}
                />;
            case (order == 10):
                if (request_type == REQUEST_TYPE_ADD_SERVICE) {
                    return <Review service_name={service_name} env_name={env_name} build_strategy={build_strategy} />
                } else {
                    return <Redirect push={true} to={"/application/" + application_id + "/service/" + state.panel[1].data.id + "/detail"} />
                }
            default:
                return null;
        }
    }

    const getActiveHeadder = () => {
        switch (request_type) {
            case REQUEST_TYPE_EDIT_SERVICE:
                panel_list.splice(1, 1);
                return (
                    <div className="detailedcard-header">
                        <CommonHorizontalTab
                            tabList={panel_list}
                            selectedTabOrder={1}
                            updateSelectedTab={() => { }} />
                    </div>
                );
            case REQUEST_TYPE_EDIT_ENV:
                return (
                    <div className="detailedcard-header">
                        <CommonHorizontalTab
                            tabList={[{ tabName: "CD Details", order: 1 }]}
                            selectedTabOrder={1}
                            updateSelectedTab={() => { }} />
                    </div>
                );
            case REQUEST_TYPE_ADD_ENV:
                return (
                    <div className="detailedcard-header">
                        <CommonHorizontalTab
                            tabList={[{ tabName: "CD Details", order: 1 }]}
                            selectedTabOrder={1}
                            updateSelectedTab={() => { }} />
                    </div>
                );
            default:
                return (
                    <div className="detailedcard-header">
                        <CommonHorizontalTab
                            tabList={panel_list}
                            selectedTabOrder={state.active_panel_order > 2 ? 2 : state.active_panel_order}
                            updateSelectedTab={() => { }} />
                    </div>
                );
        }
    }

    const getActiveFooter = () => {
        switch (request_type) {
            case REQUEST_TYPE_EDIT_SERVICE:
                return (
                    <BottomNavigator
                        hideBackButton={true}
                        nextButtonText={"Update"}
                        nextClickHandler={nextClickHandler} />
                );
            case REQUEST_TYPE_EDIT_ENV:
                return (
                    <BottomNavigator
                        hideBackButton={state.active_panel_order == 2}
                        nextButtonText={state.active_panel_order == 9 ? "Submit" : null}
                        backClickHandler={backClickHandler}
                        nextClickHandler={nextClickHandler} />
                );
            case REQUEST_TYPE_ADD_ENV:
                return (
                    <BottomNavigator
                        hideBackButton={state.active_panel_order == 2}
                        nextButtonText={state.active_panel_order == 9 ? "Submit" : null}
                        backClickHandler={backClickHandler}
                        nextClickHandler={nextClickHandler} />
                );
            default:
                return (
                    <BottomNavigator
                        backClickHandler={backClickHandler}
                        nextButtonText={state.active_panel_order == 9 ? "Submit" : null}
                        nextClickHandler={nextClickHandler} />

                );
        }
    }

    const getActiveBaner = () => {
        const order = state.active_panel_order;
        switch (true) {
            case (order == 1):
                return (
                    <div className="service-form-heading-section">
                        <p className="service-form-heading">Setup Your Service</p>
                        <p className="hint-text-main-heading">Follow these simple steps and you can onboard your service in just few minutes</p>
                    </div>
                );
            case (order > 1 && order < 10):
                return (
                    <HeaderWithName service_name={state.panel[1].data.name} />
                );
            default:
                return null;
        }
    }

    function updateActivePanelForManifest(panel_id) {
        setState({
            ...state,
            show_manifest_alert: false,
            active_panel_order: panel_id,
            is_manifest: panel_id == 4.5,
            asked_for_manifest: true,
        });
    }

    console.log("service-panel=", state.panel);

    return (
        <div className={classes.root}>
            {state.show_skip_alert ?
                <Alert update_active_panel={updateActivePanel} />
                :
                null
            }
            {state.show_manifest_alert ?
                <ManifestAlert update_active_panel={updateActivePanelForManifest} />
                :
                null
            }
            <Grid container spacing={4}>
                <Grid item lg={12} xl={12}>
                    {getActiveBaner()}
                </Grid>
                <Grid item lg={12} sm={12} xl={12} xs={12}>
                    {
                        state.active_panel_order == 10 && request_type == REQUEST_TYPE_ADD_SERVICE ?
                            <Review service_id={state.panel[1].data.id} application_id={application_id} service_name={service_name} env_name={env_name} build_strategy={build_strategy} /> :
                            <div className="detailedcard">
                                {getActiveHeadder()}
                                {getActivePanel()}
                                {getActiveFooter()}
                            </div>
                    }
                </Grid>
            </Grid>
        </div>
    );
}

ServiceAdd.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };

export default ServiceAdd;