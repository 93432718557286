import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import properties from '../../../../../properties/properties';
import GenerateURL, { GET_Request_Info } from '../../../../../util/APIUrlProvider';
import { Loading } from '../../../../utils/Loading';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InvokeApi from '../../../../../util/apiInvoker';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { styled } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Cancel } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit'
import Delete from '../../../../../components/genericComponents/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

function LogMonitoringSection(props) {
  const cluster_id = props.cluster_id;

  const [state, setState] = useState({
    loaded: false,
    openDetailView:false
  });

  useEffect(() => {
    fetchMonitoringInfo();
  }, [cluster_id]);

  function fetchMonitoringInfo() {
    const request_info = GET_Request_Info(GenerateURL({ cluster_id: cluster_id }, properties.api.get_all_log_monitoring));
    InvokeApi(request_info, onMonitoringFetchSuccess, onMonitoringFetchFail);
  }

  function onMonitoringFetchSuccess(data) {
    setState(new_state => ({
      ...new_state,
      data: data,
      openDetailView: data?.length>0?true:false,
      loaded: true,
    }));
  }

  function onMonitoringFetchFail(error) {
    setState(new_state => ({
      ...new_state,
      loaded: true,
      error: error,
    }));
  }
  const toggleDetailView = () => {
    setState(prevState => ({
      ...prevState,
      openDetailView: !prevState.openDetailView
    }))
  }
  

  return (
    <>
      <div className="header-part d-flex align-center " style={{ borderBottom: '1px solid #dedede', justifyContent: 'space-between', padding: '0px 5px 0px 0px' }}>
        <div className="heading-area setup-summary d-flex align-center space-between width-full">
          <div>
          <p className="mainheading">Log Monitoring</p>
          <p className="subheading">Displaying list of configured Log Monitoring tools</p>
          </div>
         
          <div className="right-content" >
          <IconButton>
            {
              state.openDetailView ?

                <ExpandLessIcon style={{ fontSize: '24px', color: '#666', lineHeight: 1, cursor: 'pointer' }} onClick={toggleDetailView} />
                :
                <ExpandMoreIcon style={{ fontSize: '24px', color: '#666', lineHeight: 1, cursor: 'pointer' }} onClick={toggleDetailView} />
            }
          </IconButton>
        </div>
        </div> 
      </div>
      {
          state.openDetailView && <div className="d-flex align-center justify-end pr-20 border-bottom height-40">
           {
          state.loaded ?
            <>
              {
                state.data.length > 0 ?
                  <div>
                    <Link to={"/cluster/" + cluster_id + "/monitoring/log/" + (state.data[0] ? state.data[0].id : 1) + "/edit"}><IconButton><EditIcon style={{ fontSize: '18px', color: '#0086ff' }} /></IconButton></Link>
                    <Delete
                      display_data_name={state.data[0].name}
                      data={{ entity_id: state.data[0] ? state.data[0].id : 1, name: "log_monitoring" }}
                      refresh={fetchMonitoringInfo}
                      api_link={GenerateURL({ cluster_id: cluster_id, log_monitoring_id: state.data[0] ? state.data[0].id : 1 }, properties.api.get_log_monitoring)}
                    />
                  </div>
                  :
                  null
              }
            </>
            :
            null
        }
          </div>
        }
      {
        state.openDetailView && 
        <div className="d-flex align-center" style={{ display: 'grid', gridTemplateColumns: ' 33.3% 33.3% 1fr' }}>
        {
          !state.loaded ?
            <>
              <div className="loading-parent-div">
                <Loading varient="light" />
              </div>
            </>
            :
            <>
              {
                state.data.length > 0 ?
                  <>
                    {
                      state.data.map(tool => (
                        <>
                          <div className="sub-box-one-summary" style={{ width: '100%' }}>
                            <div className="sub-header-setup-summary">
                              <p>Kibana</p>
                            </div>
                            <TempBodyRowTempTwo>
                              <div className="icon-box">
                                <div className="public-icon-box icon-box-top" style={{ height: '100%', backgroundColor: '#ebf2f4' }}>
                                  <img src="/images/resources_logos/kibana_logo.png" alt=".." />
                                </div>
                              </div>
                              <div>
                                <div className="d-flex"><span className="text-black">Status:&nbsp;</span>
                                  <>{
                                    tool.status == "READY" ?
                                      <><span className="text-gray">Ready</span><CheckCircleIcon style={{ color: '#69e09c' }} /></>
                                      :
                                      <><span className="text-gray">Not Ready</span><Cancel style={{ fontSize: '18px', color: '#ff8969' }} /></>
                                  } </>
                                </div>
                                <div className="d-flex">
                                  <span className="text-black">URL:&nbsp;</span>
                                  <a className="text-blue-anchor text-ellipsis" target="_blank" href={tool.kibana_url}>{tool.kibana_url} </a>
                                </div>
                              </div>
                            </TempBodyRowTempTwo>
                          </div>
                          <div className="sub-box-one-summary border-right" style={{ width: '100%' }}>
                            <div className="sub-header-setup-summary">
                              <p>Elastic Search</p>
                            </div>
                            <TempBodyRowTempTwo>
                              <div className="icon-box">
                                <div className="public-icon-box icon-box-top" style={{ height: '100%', backgroundColor: '#ebf2f4' }}>
                                  <img src="/images/resources_logos/elasticsearch_logo.png" alt=".." />
                                </div>
                              </div>
                              <div>
                                <div className="d-flex"><span className="text-black">Status:&nbsp;</span>
                                  <>{
                                    tool.status == "READY" ?
                                      <><span className="text-gray">Ready</span><CheckCircleIcon style={{ color: '#69e09c' }} /></>
                                      :
                                      <><span className="text-gray">Not Ready</span><Cancel style={{ fontSize: '18px', color: '#ff8969' }} /></>
                                  } </>
                                </div>
                                <div className="d-flex">
                                  <span className="text-black">Username:&nbsp;</span>
                                  <span className="text-gray text-ellipsis">admin </span>
                                </div>
                                <div className="d-flex">
                                  <span className="text-black">Password:&nbsp;</span>
                                  <span className="text-gray" >*******</span>
                                </div>
                              </div>
                            </TempBodyRowTempTwo>
                          </div>
                        </>
                      ))
                    }
                  </>
                  :
                  <>
                    <div className="sub-box-one-summary" style={{ width: '100%' }}>
                      <div className="sub-header-setup-summary">
                        <p>Kibana</p>
                      </div>
                      <TempBodyRowTempTwo>
                        <div className="icon-box">
                          <div className="public-icon-box icon-box-top" style={{ height: '100%', backgroundColor: '#ebf2f4' }}>
                            <img src="/images/resources_logos/kibana_logo.png" alt=".." />
                          </div>
                        </div>
                        <div>
                          <Link to={"/cluster/" + cluster_id + "/monitoring/log/add"} >
                            <div className="text-blue-anchor" style={{ display: 'flex', alignItems: 'center' }}  ><AddIcon style={{ color: '#0086ff', fontSize: '16px' }} /> Configure Kibana Log Monitoring</div>
                          </Link>
                        </div>

                      </TempBodyRowTempTwo>
                    </div>
                    <div className="sub-box-one-summary border-right" style={{ width: '100%' }}>
                      <div className="sub-header-setup-summary">
                        <p>Elastic Search</p>
                      </div>
                      <TempBodyRowTempTwo>
                        <div className="icon-box">
                          <div className="public-icon-box icon-box-top" style={{ height: '100%', backgroundColor: '#ebf2f4' }}>
                            <img src="/images/resources_logos/elasticsearch_logo.png" alt=".." />
                          </div>
                        </div>
                        <div>
                          <Link to={"/cluster/" + cluster_id + "/monitoring/log/add"} >
                            <div className="text-blue-anchor" style={{ display: 'flex', alignItems: 'center' }}  ><AddIcon style={{ color: '#0086ff', fontSize: '16px' }} /> Configure Elastic Search Log Monitoring</div>
                          </Link>
                        </div>

                      </TempBodyRowTempTwo>
                    </div>
                  </>

              }
            </>
        }
      </div>
      }
     
    </>
  );
}
LogMonitoringSection.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export default LogMonitoringSection;



const ITEM_HEIGHT = 48;

export function MoreOption(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const application_id = props.application_id;
  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon style={{ color: '#0096db', fontSize: '2rem' }} />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 'fit-content',
          },
        }}
      >
        <MenuItem selected={Link === 'Dashboard'} onClick={handleClose} style={{ display: "grid" }}>

          <Link to="/namespaces">
            <span className="hover-content-link" ><AddIcon style={{ color: '#9e9e9e', fontSize: '16px' }} />&nbsp;Add Ingress</span>
          </Link>
        </MenuItem>
      </Menu>
    </div>
  );
}
MoreOption.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}


const TempBodyRowTempTwo = styled('div')({
  lineHeight: '1.5',
  fontSize: '12px',
  gap: '10px',
  padding: '30px 10px',
  alignItems: 'center',
  overflow: 'hidden',
  backgroundColor: '#fcfcfc',
  display: 'grid',
  gridTemplateColumns: '30% 1fr',
  justifyContent: 'space-between',
  '& .public-icon-box': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& .text-gray': {
    color: '#9e9e9e'
  }
})