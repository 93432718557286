import React, { useState, useRef } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import zIndex from '@material-ui/core/styles/zIndex';
const PipelineDeployStatus = (props) => {

    const handleClosePopover = props.handleClosePopover;
    const application_id = props.application_id;
    const service_id = props.service_id;
    const pipeline_id = props.pipeline_id;
    const execution_id = props.execution_id;
    const env_id = props.env_id;
    const env_name = props.env_name;
    const classes = useStylesDialog();
    const handleClickAndRedirect = props.handleClickAndRedirect;
    const cd_id = props.cd_id;
    // console.log('message>>>', application_id, service_id);
    console.log('line 17', pipeline_id, execution_id);
    return (
        <>
            {
                props.redirect && <Redirect to={"/application/" + application_id + 
                "/service/" + service_id + 
                "/detail?deployment_status=true&pipeline_id=" + pipeline_id + 
                "&execution_id=" + execution_id + "&env_id="+env_id+ "&env_name="+env_name+"&cd_id="+cd_id} target="_blank" />
            }
            {
                props.variant && props.variant === 'running_card_pipeline' ?
                    (<div className={classes.header} style={{height: '100px'}} >
                        <div className={classes.movement} style={{ position: 'relative', width: '28%', height: '100%', display: 'flex', justifyContent: 'center', backgroundColor: '#FFC30C', alignItems: 'center', marginRight: '10px', borderRadius: '12px' }}>
                            <span className="material-icons material-symbols-outlined" style={{ color: '#ffffff', fontSize: '50px' }}>
                                sentiment_neutral
                            </span>
                        </div>
                        <div className={classes.message}>
                            <p>Seems like the Deployment is taking longer than expected...</p>
                            <Link onClick={handleClickAndRedirect}>View the Possible Cause</Link>
                        </div>
                        <div style={{ width: '10%' }}>
                            <IconButton onClick={() => { handleClosePopover() }}>
                                <CloseIcon style={{ fontSize: 20, color: 'grey' }} />
                            </IconButton>
                        </div>
                    </div>) :
                    props.variant && props.variant === 'failed_card_pipeline' &&
                    (<div className={classes.header} style={{ height: '80px', }}>
                        <div className={classes.movement_one} style={{ position: 'relative', width: '28%', height: '100%', display: 'flex', justifyContent: 'center', backgroundColor: '#F64B3C', alignItems: 'center', marginRight: '10px', borderRadius: '12px', }}>
                            <span className="material-icons material-symbols-outlined" style={{ color: '#ffffff', fontSize: '50px' }}>
                                sentiment_dissatisfied
                            </span>
                        </div>
                        <div className={classes.message}>
                            {/* <p className={classes.err_status}>Error 500</p> */}
                            <p style={{fontSize : "14px"}}>Deployment Failed{/* HTTP <br /> probe failed with status code 500 */}</p>
                            <Link onClick={handleClickAndRedirect} style={{fontSize : "14px"}}>View the Possible Cause</Link>
                        </div>
                        <div style={{ width: '10%' }}>
                            <IconButton onClick={() => { handleClosePopover() }}>
                                <CloseIcon style={{ fontSize: 20, color: 'grey' }} />
                            </IconButton>
                        </div>
                    </div>)

            }
        </>
    )
}
export default PipelineDeployStatus
const useStylesDialog = makeStyles(() => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        border: '1px solid #C9C9C9',
        width: '316px',
        height: '80px',
        borderRadius: '15px',
        position: 'relative',
        backgroundColor: '#FFFFFF',
        fontSize: '13px',
        marginLeft: '20px',
        fontWeight: '500',
        visibility: 'visible',
    },
    message: {
        width: '70%',
        color: 'grey',
        marginTop: '10px',
    },
    movement: {
        '&::before': {
            content: '""',
            top: '20px',
            right: '80px',
            position: 'absolute',
            width: '0',
            height: '0',
            borderTop: '10px solid transparent',
            borderBottom: '10px solid transparent',
            borderRight: '10px solid #FFC30C',
        }
    },
    movement_one: {
        '&::before': {
            content: '""',
            top: '20px',
            right: '80px',
            position: 'absolute',
            width: '0',
            height: '0',
            borderTop: '10px solid transparent',
            borderBottom: '10px solid transparent',
            borderRight: '10px solid #F64B3C',
        }
    },
    err_status: {
        backgroundColor: '#F64B3C', color: '#fff', width: '35%', textAlign: 'center', fontWeight: '900', borderRadius: '5px'
    }
}));