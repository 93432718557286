import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { getCommonFunctions } from '../../serviceRevamp/add/ci_flow/SourceDetails';
import { Input } from '../../../components/genericComponents/Input';
import { VALIDATION_TYPE_REQUIRED } from '../../../util/Validator';

const PipelineVariableOverrideFields = ({ prevState, pipelineVars, handleClose, postFinalData, ...props }) => {
    console.log('custom filed form-->', prevState, props);
    const classes = useStyles();
    const inherits = props.inherits ? props.inherits : {};
    const variant = "";
    const [inputList, setInputList] = useState([

    ]);

    const [state, setState] = useState({
        data: {},
        error: {},
        validations: {}
    })
    const commonFunctions = getCommonFunctions(state, setState, inherits);

    useEffect(() => {
        if (pipelineVars && Array.isArray(pipelineVars) ) {
            console.log(pipelineVars, 'ppvf')
            let data = {}
            let validations={}
            pipelineVars?.forEach(field => {
                if (field?.over_ridable){
                    data[field.key] = field.value ? field.value : null
                    if(field?.mandatory)
                        validations[field.key]=[VALIDATION_TYPE_REQUIRED]
                }
                
            })
            setState(prevState => ({
                ...prevState,
                data: { ...data },
                validations:{...validations}
            }))
        }
    }, [pipelineVars])

    console.log(state, prevState, 'inside_form')

    console.log(inputList, 'indggg_ss', state.data)
    return (
        <>{
            <div className='additional_filed_cs' style={{ backgroundColor: '#ededed', borderRadius: '5px', display: 'block', border:"1px solid #dedede",padding:"10px", margin:'10px' }}>
                <div style={{ backgroundColor: '#fff', borderTopLeftRadius: 'inherit', borderTopRightRadius: 'inherit' }}>
                    <div className={`d-flex align-center border-bottom space-between `} style={{ height: '50px', backgroundColor: '#fafafa', padding: '15px', borderTopLeftRadius: 'inherit', borderTopRightRadius: 'inherit' }}>
                        <label style={{ color: '#6d6a6a' }} className='font-14 mr-5'>Overridden Pipeline Variables</label>
                       
                    </div>
                    <div className={classes.root}>
                        <div className="h-250" style={state.loading_data ? { minHeight: '128px', display: 'flex' } : null}>

                            <Grid container alignItems="center" className="">
                                {
                                    pipelineVars && pipelineVars.length > 0 ?
                                        <>
                                            <Grid className="bg-f5-grey d-grid pd-10 font-12  border-bottom" item xs={6}>
                                                <p>Key</p>
                                            </Grid>
                                            <Grid className="bg-f5-grey d-grid pd-10 font-12  border-bottom" item xs={6}>
                                                <p>Value</p>
                                            </Grid>
                                        </>
                                        :
                                        null}
                                <Grid className=" pd-10 font-12" item xs={12}>
                                    {
                                        pipelineVars && pipelineVars.length > 0 ? pipelineVars.map((variable, index) => {
                                            return (
                                                <>
                                                        <Grid key={index} container className="border-bottom">
                                                            <Grid key={index} item xs={6} className="d-flex align-center"
                                                                style={

                                                                    { height: '80px', padding: '10px' }}>
                                                                <p className="font-12">{variable.key}&nbsp;<span className="font-12" style={{ color: 'red' }}>{variable.mandatory ? '*' : ''}</span></p>
                                                            </Grid>
                                                            <Grid key={index} item xs={6} className=""
                                                                style={

                                                                    { height: '80px', padding: '10px' }}>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="Enter Value"
                                                                    name={variable.key}
                                                                    data={state.data}
                                                                    error={state.error}
                                                                    onChangeHandler={(variable?.over_ridable || variable['over-ridable']) ? commonFunctions.onChangeHandler : null}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                </>
                                            )
                                        }) : null
                                    }
                                </Grid>
                            </Grid>

                        </div>
                        {/* {state.loading_data ?
                            null :
                            <div className="d-flex align-center justify-end pd-10 ">
                                <button className="btn btn-outline-grey" onClick={handleClose}>Cancel</button>
                                <button className="btn btn-submit" onClick={pushDataToArray}>Save</button>
                            </div>
                        } */}
                    </div>
                </div>
            </div>
        }
        </>
    )
}
export default PipelineVariableOverrideFields;


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    root: {
        '& .h-300': {
            height: 350,
            overflowy: 'scroll'
        }
    }
}));


