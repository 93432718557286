import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import properties from '../../../properties/properties';
import InvokeApi from '../../../util/apiInvoker';
import GenerateURL, { GET_Request_Info } from '../../../util/APIUrlProvider';
import MaxWidthDialog from '../../../views/settings/credentials/components/FormDialogue';
import { ErrorComponent } from '../../../views/utils/Error';
import { Loading } from '../../../views/utils/Loading';
import { Input } from '../Input';


function Credential(props) {
    const value = props.value;
    const error = props.error;
    const onChangeHandler = props.onChangeHandler;
    const mandatorySign = props.mandatorySign;
    const label = props.label;
    const prev_state = props.prev_state;
    const inherits = props.inherits;
    const isSSH = props.isSSH;
    const field = props.field_type;
    const [state, setState] = useState(prev_state ? prev_state : {
        loaded: false,
    });

    useEffect(() => {
        if (!prev_state) {
            fetchCredentials(field);
        }
    }, []);

    function fetchCredentials(field_type) {
        const request_info = GET_Request_Info(GenerateURL({}, properties.api.credential_all));
        request_info.endPoint = field_type ? request_info.endPoint + "?credential_type=" + field_type : request_info.endPoint + "?all=true";
        InvokeApi(request_info, onFetchSuccess, onFetchFail);
        setState(new_state => ({
            ...new_state,
            loaded: false,
        }));
    }

    function onFetchSuccess(data) {
        setState(new_state => ({
            ...new_state,
            credentials_list: formatCredentialList(data.results),
            loaded: true,
        }));
    }

    function onFetchFail(error) {
        setState(new_state => ({
            ...new_state,
            error: error,
            loaded: true,
        }));
    }

    function formatCredentialList(data) {
        const result = [];

        data.forEach(credential => {
            result.push({
                id: credential.id,
                label: credential.name
            });
        });

        return result;
    }

    inherits.getState = () => {
        return state;
    }
    inherits.validateForm = () => {
        
        return { valid: true }
    }

    return (
        <div style={{ display: 'grid', gridTemplateColumns: '85% 14%', gap: '1%', alignItems: 'center' }}>
            {
                !state.loaded ?
                    <Loading varient="light" />
                    :
                    <>
                        {
                            state.error ?
                                <ErrorComponent error={state.error} />
                                :
                                <>
                                    <Input
                                        type="select"
                                        name="ssh_key_id"
                                        mandatorySign={mandatorySign}
                                        list={state.credentials_list}
                                        label={label}
                                        onChangeHandler={onChangeHandler}
                                        data={{ ssh_key_id: value }}
                                        error={{ ssh_key_id: error }} />

                                    <MaxWidthDialog refresh={fetchCredentials} isSSH={isSSH} />
                                </>
                        }
                    </>
            }
        </div>
    );
}

Credential.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default Credential;