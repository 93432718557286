import React, { useState } from 'react';
import PageHeader from '../../../../components/PageHeader';
const HelmIntegrationHeader = (props) => {
    
    const OpenDialogButton = props.OpenDialogButton ? props.OpenDialogButton : ()=>{}
    return (
        <>
            <div className={"d-flex align-center space-between mb-24"}>
                <PageHeader
                heading="Helm Integration"
                subHeading='Displaying overall summary of helm integration with BuildPiper'
                primaryButton={null}
                commonDivMargin={false}
                 />
                 {OpenDialogButton}
            </div>
        </>
    )
}



export default HelmIntegrationHeader;