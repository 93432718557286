import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Input } from "../Input";
import properties from "../../../properties/properties";
import { getCiList } from "../../../views/pipeline/add/components/JobForm";
import GenerateURL from "../../../util/APIUrlProvider";
import InvokeApi from "../../../util/apiInvoker";
import { getCommonFunctions } from "../../../views/serviceRevamp/add/ci_flow/SourceDetails";

import { VALIDATION_TYPE_REQUIRED } from "../../../util/Validator";


const SelectIstio = (props) => {
    const inherits = props.inherits ? props.inherits : {};
    const prev_state = props.prev_state ? props.prev_state : null;
    const [state, setState] = useState(prev_state ?prev_state: getIstioDefaultState())
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    const type = props.type;
    const cluster_id = props.cluster_id;
    const gateway_listing_url = properties.api.get_gateway_list
    useEffect(() => {
        fetchIsitoGateways()
        if(prev_state){
            setState(new_state => ({ 
                ...new_state,
                 ...prev_state
                }));
        }
    }, [type])

    function fetchIsitoGateways() {
        let requestInfo = {
            endPoint: GenerateURL({ cluster_id: cluster_id }, gateway_listing_url),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        requestInfo.endPoint = requestInfo.endPoint + "?gateway_type=" + type;
        InvokeApi(requestInfo, handleSuccessApiHit, handleFailedApiHit);
        setState(new_state => ({
            ...new_state,
            loading:true
        }));
    }

    function handleSuccessApiHit(data) {
        setState(new_state => ({
            ...new_state,
            gateway_lis: getCiList(data.results),
            loading:false
        }));
    }

    function handleFailedApiHit(error) {
        setState(new_state => ({
            ...new_state,
            error: error,
            loading:false
        }))
    }
   
    return (
        
        
            <Input
            type="select"
            name="gateway_id"
            mandatorySign
            label="Select Gateway"
            list={state.gateway_lis && state.gateway_lis.length> 0? state.gateway_lis : [] }
            onChangeHandler={commonFunctions.onChangeHandler}
            data={state.data}
            error={state.error} />
        
    )
}

SelectIstio.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default SelectIstio;

export function getIstioDefaultState(){
    return {
        gateway_lis: [],
        data:{},
        error:{},
        validations:{
            gateway_id:[VALIDATION_TYPE_REQUIRED]
        }
    }
}