import  { useEffect, useState } from "react";
import { getPermissionAsyncFun } from "../../util/security";

const useFetchPermissions = () => {

    const [permission, setPermission] = useState(null);

    useEffect(() => {
        const fetchPermission = async () => {
            try {
                const permissions = await getPermissionAsyncFun();
                setPermission(permissions)
            }

            catch (error) {
                console.log(error, 'error in fetching permission')
            }
        }
        fetchPermission();
    }, [])

    return permission;
}

export default useFetchPermissions;

