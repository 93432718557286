import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import CommonHorizontalTab from '../../../../components/genericComponents/CommonHorizontalTab';
import AppBarHistory from './HistoryPageComponents/AppBarHistory';
import BuildHistory from './HistoryPageComponents/BuildHistory';
import DeployHistory from './HistoryPageComponents/DeployHistory';
import PromoteHistory from './HistoryPageComponents/PromoteHistory';
import Tooltip from '@material-ui/core/Tooltip';
import SquareAvatar from '../../../../components/genericComponents/AvatarSquare';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const HistoryRevamp = props => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  var type = props.type;
  const service_id = props.service_id;
  const env_id = props.env_id;
  const service_data = props.service_data;
  const env_name = props.env_name;
  const masterLabel = props.masterLabel;
  const env_details = props.env_details;
  const is_env_down = props.is_env_down?props.is_env_down: null;
  const deploy_env_offline_flag = props.deploy_env_offline_flag? props.deploy_env_offline_flag : null;
  const serviceEnv = props.service_env;
  var service_env = service_data && service_data.service_env;
  service_env = service_env ? service_env.toLowerCase() : null
  console.log(service_data, "service_dataservice_data")
  var service_env_name = service_env ? service_env : getEnvNamefromId();
  function getEnvNamefromId() {
    var env_name = "";
    if (service_data && service_data.component_env) {
      service_data.component_env.map(item => {
        if (item.id == env_id) {
          env_name = item.project_env.name
        }
      })
    }
    return env_name;
  }
  console.log(service_env_name, "service_dataservice_data")
  const [state, setState] = useState({
    selected_tab: 1,
    refresh_count: 1,
  })
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const tabList = [
    {
      tabName: "Build",
      order: 1
    },
    {
      tabName: "Deploy",
      order: 2
    },
    {
      tabName: "Promote",
      order: 3
    }
  ]
  const updateSelectedTab = (tab_order) => {
    setState(new_state => ({
      ...new_state,
      selected_tab: tab_order,
    }));
  }
  const refreshComponent = props => {
    setState(new_state => ({
      ...new_state,
      refresh_count: new_state.refresh_count + 1,
    }));
  }

  function setSelectedDeploymentName(value){
    console.log(value,"setSelectedDeploymentName")
    setState(new_state => ({
      ...new_state,
      selectedDeploymentName: value
    }));
  }
  return (
    <div>
      
        <Tooltip title="History" >
          {/* <button onClick={handleClickOpen} className="btn btn-flaticon">
            <span className="flaticon-filter-results-button"></span>
          </button> */}
          <button onClick={handleClickOpen} className="btn-sq-icon-primary d-flex align-center">
            <span className="ri-filter-3-fill vertical-align-super"></span>
          </button>
        </Tooltip>
      
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        {/* <AppBarHistory
          handleClose={handleClose}
          env_id={env_id}
          service_data={service_data}
          service_env_name={service_env_name} /> */}
        <div style={{position: "relative"}}>

          
          <AppBarHistory
          handleClose={handleClose}
          env_id={env_id}
          service_data={service_data}
          service_env_name={service_env_name} 
          varient= "new"
          refresh={refreshComponent}
          />

          <div className={classes.maincontainer}>
            {/* <div className="">
              <CommonHorizontalTab
                tabList={tabList}
                selectedTabOrder={state.selected_tab}
                updateSelectedTab={updateSelectedTab}
                variant = 'round-corner-tab-bulky'
              />
              <button className="btn-round border-navy bg-clear-btn" onClick={refreshComponent}>
                <span class="flaticon-refresh-button-1"></span>
              </button>
            </div> */}
            {
              state.selected_tab == 1 &&
              <BuildHistory
                service_id={service_id}
                env_id={env_id}
                service_env_name={service_env_name}
                refresh_count={state.refresh_count}
                service_data={service_data}
                serviceEnv={serviceEnv}
                options_tabs = {
                  <CommonHorizontalTab
                    tabList={tabList}
                    selectedTabOrder={state.selected_tab}
                    updateSelectedTab={updateSelectedTab}
                    variant = 'round-corner-tab-bulky'
                  />
                }

              />
            }
            {
              state.selected_tab == 2 &&
              <DeployHistory
                service_id={service_id}
                env_id={env_id}
                refresh_count={state.refresh_count}
                service_data={service_data}
                env_name={env_name}
                is_env_down={is_env_down}
                masterLabel={masterLabel}
                service_env_name={service_env_name}
                deploy_env_offline_flag={deploy_env_offline_flag}
                env_details={env_details}
                setSelectedDeploymentName = {setSelectedDeploymentName}
                selectedDeploymentName = {state.selectedDeploymentName}
                options_tabs = {
                  <CommonHorizontalTab
                    tabList={tabList}
                    selectedTabOrder={state.selected_tab}
                    updateSelectedTab={updateSelectedTab}
                    variant = 'round-corner-tab-bulky'
                  />
                }
              />
            }
            {
              state.selected_tab == 3 &&
              <PromoteHistory
                service_id={service_id}
                env_id={env_id}
                refresh_count={state.refresh_count}
                service_data={service_data}
                options_tabs = {
                  <CommonHorizontalTab
                    tabList={tabList}
                    selectedTabOrder={state.selected_tab}
                    updateSelectedTab={updateSelectedTab}
                    variant = 'round-corner-tab-bulky'
                  />
                }
              />
            }
          </div>
        </div>
      </Dialog>
    </div>
  )
}

HistoryRevamp.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export default HistoryRevamp;

const useStyles = makeStyles((theme) => ({
  maincontainer: {
    margin: '20px',
    marginTop: "0px",
    //backgroundColor: '#fff',
    borderRadius: '8px',
    //overflow: 'hidden',
    //border: '1px solid #ebedee',
    '& #viewFullLogs': {
      marginLeft: -21
    },
    '& .search-bar': {
      border: '1px solid #DBDBDB',
      overflow: 'hidden',
      borderRadius: '6px',
      '& .input-component input':{
        height: "36px !important",
      },
      '& .input-component input:focus': {
        border: 'none!important'
      }
    },
    '& .headerul': {
      backgroundColor: '#f6f6f6',
      borderRadius: '4px 4px 0px 0px',
      borderBottom: '1px solid #ebedee'
    }
  },
  card: {
    '& .loading-gif-container-two': {
      height: '400px',
      '& .loader-bg-card-2': {
        position: 'relative',
        '& .loader': {
          top: '0px'
        }
      }
    },
    '& .loading-overlay': {
      position: 'static'
    }
  },
  containerBox: {
    padding: '20px 15px 15px',
    '& button.btn': {
      '&:hover': {
        color: '#0096db!important'
      }
    }
  },
}));