import { Grid, makeStyles, styled } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SquareAvatar from '../../../../components/genericComponents/AvatarSquare';
import properties from '../../../../properties/properties';
import InvokeApi from '../../../../util/apiInvoker';
import GenerateURL, { GET_Request_Info } from '../../../../util/APIUrlProvider';
import { Loading } from '../../../utils/Loading';
import { UI_InfraDetails } from '../Detail';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { ErrorComponent } from '../../../utils/Error';
import CancelIcon from '@material-ui/icons/Cancel';
import PageHeader from '../../../../components/PageHeader';
import Chip from '../../../../components/chip/Chip';
import BlankPage from '../../../../components/BlankPage';
import { RepoListingPageSkeleton } from '../../../integrations/repos/ReposListing';
function ClusterList(props) {
    const classes = useStyles();
    const [state, setState] = useState({ loaded: false });
    useEffect(() => {
        fetchClusters();

    }, []);


    function handleSuccessApiHit(data) {
        setState(new_state => ({
            ...new_state,
            clusters: data,
            loaded: true,
        }));

    }

    function handleFailedApiHit(error) {
        setState(new_state => ({
            ...new_state,
            error: error,
            loaded: true,
        }))
    }

    function fetchClusters() {

        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.get_all_clusters),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, handleSuccessApiHit, handleFailedApiHit);
    }
    return (
        <div className={classes.root}>
            {
                state.loaded ?
                    <>
                        {
                            state.clusters ?
                                state.clusters.length > 0 ?
                                    <>
                                        <PageHeader
                                            heading="Kubernetes Clusters"
                                            subHeading="Displaying overall summary of Infrastructure associated with your account"
                                            imgIcon={"/images/cluster-head.svg"}
                                            icon={null}
                                            primaryButton={{
                                                actionType: 'link',
                                                action: '/cluster/add/firstpage',
                                                icon: <span className="ri-add-line font-14"></span>,
                                                text: 'Add Cluster',
                                                buttonClass: 'btn-primary'
                                            }}
                                            commonDivMargin={true}
                                        />
                                        {

                                            state.clusters.map(cluster => (
                                                <UI_ClusterCard refresh={fetchClusters} cluster={cluster} />
                                            ))

                                        }
                                    </>
                                    :
                                    <>
                                        <BlankPage
                                            pageIcon={<img src="/images/cluster-head.svg" />}
                                            text={"You have no cluter onboarded"}
                                            primaryButton={
                                                {
                                                  actionType: 'link',
                                                  action: "/cluster/add/firstpage",
                                                  icon: <span className="ri-add-line font-14"></span>,
                                                  text: 'Onboard Cluster',
                                                  buttonClass: 'btn-primary m-auto'
                                                }
                                              }
                                        />

                                    </>
                                :
                                <ErrorComponent error={state.error} />
                        }
                    </>
                    :
                    <RepoListingPageSkeleton />
            }
        </div>
    );
}

export default ClusterList;


function UI_ClusterCard(props) {
    const classes = useStyles();
    const cluster = props.cluster;
    const [state, setState] = useState({
        open: false,
    });

    function toggleOpen() {
        setState(new_state => ({
            ...new_state,
            open: !new_state.open
        }))
    }

    return (
        <>
            <div className={classes.card}>
                <div className={classes.cardHeader}>
                    <UI_ClusterCardHeader refresh={props.refresh} data={cluster} />
                    <div style={{ alignSelf: 'center' }}>
                        <button className='btn btn-icon-only' onClick={toggleOpen}>
                            {
                                state.open ?
                                    <span className='ri-arrow-up-s-line font-24 color-tertiary'></span> :
                                    <span className='ri-arrow-down-s-line font-24 color-tertiary'></span>
                            }
                        </button>
                    </div>
                </div>
                {
                    state.open ?
                        <div className={classes.cardBody}>
                            <UI_ClusterCardDetail refresh={props.refresh} cluster_id={cluster.id} />
                        </div>
                        :
                        null
                }
            </div>
        </>
    );
}
UI_ClusterCard.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

function UI_ClusterCardStatus(props) {
    const cluster_id = props.cluster_id;
    const [state, setState] = useState({
        loaded: false
    })

    useEffect(() => {
        fetchControlManagerHealth();
    }, []);

    function fetchControlManagerHealth() {
        var requestInfo = {
            endPoint: GenerateURL({ cluster_id: cluster_id }, properties.api.get_cluster_status),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, handleSuccessApiHit, handleFailedApiHit);
        setState(new_state => ({
            ...new_state,
            loaded: false,
        }));
    }

    function handleSuccessApiHit(data) {
        setState(new_state => ({
            ...new_state,
            health: data.items,
            loaded: true

        }));
    }

    function handleFailedApiHit(error) {
        setState(new_state => ({
            ...new_state,
            error: error,
            loaded: true,
        }));
    }

    return (
        <>
            {
                !state.loaded ?
                    <div className="loading-controller" style={{ backgroundColor: '#fcfcfc', borderTop: '1px solid #d5d5d5', }}>
                        <Loading varient="light" />
                    </div>

                    :
                    <>
                        <div style={{ backgroundColor: '#fcfcfc', borderTop: '1px solid #d5d5d5', }}>

                            <div className="d-flex " >
                                {state.health ?
                                    state.health.map(h => (

                                        <div className="inner-data d-flex align-center nowrap mr-10 border-right-d5" style={{ padding: '10px', }}>
                                            <span className="text-color-black">{(h.metadata.name).charAt(0).toUpperCase() + (h.metadata.name).slice(1)}:&nbsp;</span>

                                            {
                                                h.conditions.map(c => (
                                                    c.type == "Healthy" ?
                                                        <span className="text-color-grey d-flex align-center">
                                                            {
                                                                c.status == "True" ? <span className="text-color-grey d-flex align-center "><CheckCircleIcon style={{ color: 'rgb(105, 224, 156)', fontSize: '18px' }} />&nbsp;<span className="text-gray" style={{ color: '#9e9e9e' }}>{c.status == "True" ? "Healthy" : "Unhealthy"}</span></span> :
                                                                    <span className="text-color-grey d-flex align-center "><CancelIcon style={{ fontSize: '18px', color: c.status == "True" ? '#69e09c' : '#ff8969' }} />&nbsp;<span className="text-gray" style={{ color: '#9e9e9e' }}>{c.status == "True" ? "Healthy" : "Unhealthy"}</span></span>
                                                            }
                                                        </span>
                                                        : null
                                                ))
                                            }
                                        </div>

                                    )) : <div className="inner-data d-flex align-center nowrap mr-10 border-right-d5" style={{ padding: '10px', }}><ErrorComponent error={"Cluster Unstable"} /></div>
                                }
                            </div>
                        </div>
                    </>
            }
        </>
    );
}
UI_ClusterCardStatus.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

function UI_ClusterCardDetail(props) {
    const cluster_id = props.cluster_id;

    const [state, setState] = useState({
        loaded: false,
    });

    useEffect(() => {
        fetchClusterSummary();
    }, [cluster_id]);

    function fetchClusterSummary() {
        const request_info = GET_Request_Info(GenerateURL({ cluster_id: cluster_id }, properties.api.cluster_summary));
        InvokeApi(request_info, onFetchSuccess, () => { });
        setState(new_state => ({
            ...new_state,
            loaded: false,
        }));
    }

    function onFetchSuccess(data) {
        setState(new_state => ({
            ...new_state,
            cluster_summary: data,
            loaded: true,
        }));
    }


    return (
        <>
            {
                state.loaded ?
                    <UI_InfraDetails refresh={props.refresh} cluster_id={cluster_id} data={state.cluster_summary} />
                    :
                    <InnerBox>
                        <Loading varient="light" />
                    </InnerBox>

            }
        </>
    );
}
UI_ClusterCardDetail.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}


function UI_ClusterCardHeader(props) {
    const data = props.data;
    return (
        <>
            <HeaderDiv>
                <SquareAvatar
                    varient="double"
                    name={data.name} />
                <div className="cluster-head-data">
                    <div className="d-flex align-center mb-5">
                        <Link to={"cluster/" + data.id + "/detail"} className="font-16 text-blue font-weight-600 mr-5">{data.name}</Link>
                        {
                            data.status == "READY" ?
                                <Chip variant='success' label="Connected" icon={<span className='ri-checkbox-circle-fill font-16'></span>} />
                                : <Chip variant='failed' label="Disconnected" icon={<span className='ri-close-circle-fill font-16'></span>} />
                        }

                    </div>
                    <div className='d-flex align-center'>
                        <span className="font-12 color-tertiary">
                            Kubernetes Version:&nbsp;
                        </span>
                        <span className="color-black font-12 mr-5">{data.kubernetes_version}</span>
                        <span className="font-12 color-tertiary">
                            Infra Provider:&nbsp;
                        </span>
                        <span className="color-black font-12">{data.infra_provider && data.infra_provider.name ? data.infra_provider.name : "N/A"}</span>
                    </div>
                </div>
            </HeaderDiv>
        </>
    );
}
UI_ClusterCardHeader.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}



const InnerBox = styled('div')({
    margin: '0px 0px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px 15px',
    height: '162px',
    backgroundColor: '#fcfcfc',
    borderTop: '1px solid #dedede',
    borderBottom: '1px solid #dedede',
    gridTemplateColumns: '33% 32% 32%',
    '& .inner-data': {
        fontSize: '12px'
    },
    '& .pd-top-20': {
        padding: '20px 0px'
    },
    '& .nodes-data': {
        display: 'flex',
        alignItems: 'center',
        fontSize: '12px',
        padding: '5px 0px',
        lineHeight: '14px',
        '& .width-fixed': {
            width: '92px',
            display: 'inline-block'
        },
        '& .text-color-grey': {
            color: '#989898',
            marginLeft: '3px'
        },
        '& .text-color-blue': {
            color: '#0086ff',
            marginLeft: '3px'
        }
    },
    '& .text-black': {
        fontSize: '12px'
    },
    '& .k8s-version': {
        fontSize: '11px',
        display: 'flex',
        alignItems: 'center',
        padding: '5px 0px',
        '& .flaticon-circle-with-check-symbol': {
            '&:before': {
                fontSize: '14px!important'
            },
            '&:after': {
                fontSize: '14px'
            }
        },
        '& img': {
            width: '36px',
            height: '36px',
            display: 'block',
        }
    }
})
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: 20,
        height: 'calc(100vh - 70px)',
        backgroundColor: '#fff',
        
    },
    MainHeading: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#000',
        lineHeight: '24px'
    },
    SubHeading: {
        fontSize: '11px',
        color: '#9d9d9d',
        lineHeight: '15px'
    },
    mainBodyDiv: {
        backgroundColor: '#f6f8f8',
        padding: '15px 20px',
        borderTop: '1px solid#dedede',
    },
    card: {
        borderRadius: '8px',
        background: '#fff',
        overflow: 'hidden',
        marginBottom: '12px',
        border: '1px solid #dedede',
        '& .checkbox-box': {
            border: '1px solid #dedede',
            borderRadius: '4px',
            padding: '15px'
        },
        '& .MuiTypography-root': {
            color: '#828282',
            fontSize: '12px',
            fontWeight: 400
        },
        '& .margin-divider': {
            margin: '10px -15px'
        },
        '& .grid-with-3': {
            display: 'grid',
            gridTemplateColumns: 'auto auto auto'
        }
    },
    cardHeader: {
        backgroundColor: ' #fff',
        borderRadius: ' 8px 8px 0px 0px',
        display: 'flex',
        padding: '16px 20px ',
        justifyContent: ' space-between',

        '& .text-color-grey': {
            color: '#989898',
            fontSize: '12px'
        },
        '& .text-color-black': {
            fontSize: '12px',
            color: '#000'
        }
    },
    cardBody: {
        backgroundColor: '#fff',
        padding: '20px',
        '& .divider': {
            marginTop: '15px'
        },
        '& .input-component': {
            marginBottom: '0px'
        },
        '& .with-dropdown': {
            display: 'grid',
            gridTemplateColumns: 'auto 120px',
            gap: '10px',
            alignItems: 'center',
        },
        '& .general-data': {
            display: 'flex',
            alignContent: 'center',
            justifyItems: 'space-between',
            fontSize: '12px',
            lineHeight: '18px',
            '& .inner-data': {
                marginRight: '6px'
            },
            '& .text-color-grey': {
                color: '#989898'
            }
        },
        '& .text-color-grey': {
            color: '#989898',
            fontSize: '12px'
        },
        '& .text-color-black': {
            fontSize: '12px'
        }
    },
    cardFooter: {
        backgroundColor: 'rgb(249, 249, 249)',
        padding: '15px',
        display: 'grid',
        justifyContent: 'end',
        gridTemplateColumns: 'auto auto',
        borderTop: '1px solid rgb(204, 204, 204)',
        borderRadius: '0px 0px 8px 8px',
        '& .btn-submit': {
            backgroundColor: '#3696db',
            color: '#fff',
            marginLeft: 'auto'
        }
    }
}));


const BtnSection = styled('div')({
    display: "flex",
    alignItems: 'center',
    '& .anchor-blank': {
        border: 'none!important',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    }
})

const HeaderDiv = styled('div')({
    display: 'flex',
    alignItems: 'center',
    '& .cluster-head-data': {
        marginLeft: '10px'
    },
    '& .cluster-name': {
        color: '#0086ff',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '22px'
    },
    '& .general-data': {
        display: 'flex',
        alignContent: 'center',
        justifyItems: 'space-between',
        fontSize: '12px',
        lineHeight: '18px',
        '& .inner-data': {
            marginRight: '6px'
        },
        '& .text-color-grey': {
            color: '#989898'
        }
    }

})
