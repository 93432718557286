import React, { useState } from 'react'
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import InfoIcon from '@material-ui/icons/Info';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';

const EditDetailsConfirmationDialog = (props) => {
    const application_id = props.application_id ? props.application_id : null;
    const service_id = props.service_id ? props.service_id : null;
    const component_env_id = props.component_env_id ? props.component_env_id : null;
    const cd_data = props.cd_data ? props.cd_data : null;
    const auto_approval_flag = props.auto_approval_flag ? props.auto_approval_flag : false
    const sidecar_id = props.sidecar_id ? props.sidecar_id : null
    const env_cd_id = props.env_cd_id ? props.env_cd_id : cd_data && cd_data.id ? cd_data.id : null
    const service_env_data = props.service_env_data ? props.service_env_data : null
    console.log("sidecar_idsidecar_id", sidecar_id)
    const container_type = cd_data && cd_data.container_type ? cd_data.container_type == "SIDECAR" ? "sidecar": "init" : null; 
    const selectedTabOrder = props.selectedTabOrder;
    const [state, setstate] = useState({
        data: {},
        error: {},
        loading: false,

    })
    const handleClickOpen = () => {
        setstate({ ...state, open: true });
    };

    const handleClose = () => {
        setstate({ ...state, open: false, });
    };
    return (
        <>
            {sidecar_id ?
                <>
                    <IconButton onClick={handleClickOpen} >
                        <BorderColorIcon style={{ color: '#0086ff' }} />
                    </IconButton>
                    <Dialog
                        open={state.open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <div className="card ">
                            <div className="card-body d-flex" style={{ height: 300 }}>
                                <div className="m-auto" style={{ width: '400px' }}>
                                    <InfoIcon style={{ color: '#4783dc', fontSize: '60px' }} className="text-center mb-10 d-block m-auto" />
                                    <div className="">
                                        {
                                            auto_approval_flag ?
                                                <p className="font-16 font-weight-bold text-center mb-5">Are you sure you want to edit a configuration which is sent for approval. Once you save the edited version it will override existing draft/or create new draft configuration!!</p>
                                                :
                                                <p className="font-16 font-weight-bold text-center mb-5">
                                                    Are you sure you want to edit the sidecar configuration. As soon as you submit the changes, the configuration will be auto approved and will be available for next deployment.
                                                </p>
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="card-footer border-top pd-10 justify-end d-flex align-center">
                                <button className="btn btn-outline-dark-grey" onClick={handleClose}>
                                    Cancel
                                </button>
                                <Link className="btn btn-primary-v2" to={{
                                    pathname: "/application/" + application_id + "/service/" + service_id + "/env/" + component_env_id + "/cd_id/"+env_cd_id+"/"+container_type+"/"+sidecar_id+"/edit",
                                    query: {
                                        selectedTabOrder: selectedTabOrder,
                                        sidecar_id: sidecar_id,
                                        env_cd_id: env_cd_id,
                                        service_env_data: service_env_data,
                                    }
                                }}>
                                    Confirm
                                </Link>
                            </div>
                        </div>
                    </Dialog>
                </>
                :
                <>
                    <IconButton onClick={handleClickOpen} >
                        <BorderColorIcon style={{ color: '#0086ff' }} />
                    </IconButton>
                    <Dialog
                        open={state.open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <div className="card ">
                            <div className="card-body d-flex" style={{ height: 300 }}>
                                <div className="m-auto" style={{ width: '400px' }}>
                                    <InfoIcon style={{ color: '#4783dc', fontSize: '60px' }} className="text-center mb-10 d-block m-auto" />
                                    <div className="">
                                        {
                                            auto_approval_flag ?
                                                <p className="font-16 font-weight-bold text-center mb-5">Are you sure you want to edit a configuration which is sent for approval. Once you save the edited version it will override existing draft/or create new draft configuration!!</p>
                                                :
                                                <p className="font-16 font-weight-bold text-center mb-5">
                                                    Are you sure you want to edit the deployment configuration. As soon as you submit the changes, the configuration will be auto approved and will be available for next deployment.
                                                </p>
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="card-footer border-top pd-10 justify-end d-flex align-center">
                                <button className="btn btn-outline-dark-grey" onClick={handleClose}>
                                    Cancel
                                </button>
                                <Link className="btn btn-primary-v2" to={{
                                    pathname: "/application/" + application_id + "/service/" + service_id + "/env/" + component_env_id + "/cd/"+env_cd_id+"/edit",
                                    query: { selectedTabOrder: selectedTabOrder, selected_cd_id: props.selected_cd_id }
                                }}>
                                    Confirm
                                </Link>
                            </div>
                        </div>
                    </Dialog>
                </>
            }


        </>
    )
}

EditDetailsConfirmationDialog.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default EditDetailsConfirmationDialog
