import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import properties from '../../../properties/properties';
import GenerateURL, {GenerateSearchURL} from '../../../util/APIUrlProvider';
import InvokeApi from '../../../util/apiInvoker';

export default function InfoStaticPanel(props) {

    const {step_id, step_code, version } = props
    const [stepDetails,setStepDetails] = useState({})
    const [state,setState] = useState()
    const images = properties.catalog_images[0];

    const classes = useStyles();

    useEffect(() => {
        step_id && getSteById(step_id)
        step_code && getSteByStepCode(step_code)
    },[step_id,step_code])

    function getSteById(step_id) {

        let requestInfo = {
            endPoint: GenerateURL({ step_id: step_id }, properties.api.getStep),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, onSuccess, onFailure);
    }

    function onSuccess(response) {
        
        response  && setStepDetails(response)
    }

    function onFailure(error) {
      
        setState(new_state => ({
            ...new_state,
            error: error,
        }))
    }

    console.log('step_d',stepDetails)

    console.log('step_id',step_id)


    function getSteByStepCode(step_code) {

        let myendpoint = GenerateURL({}, properties.api.stepCatalogs)

        let requestInfo = {
            endPoint: GenerateSearchURL({ step_code: step_code },myendpoint),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, onGetStepSuccess, onGetStepFailure);
    }

    function onGetStepSuccess(response) {

        const {results} = response
        const [step] = results
        step  && setStepDetails(step)
    }

    function onGetStepFailure(error) {
       console.log('ee_e',error)
        setState(new_state => ({
            ...new_state,
            error: error,
        }))
    }

    return (
        
            <div className={classes.staticwrap} style={{ }}>
                <div className='staticwrap-inner'>
                    <div className='com-logo'><img src={images[stepDetails.code]?images[stepDetails.code] : images['SLACK_NOTIFY']} alt="..." /></div>
                    <h5>{stepDetails?stepDetails.name:''}</h5>
                    {
                        version ==='v2' && 
                        <div className='notify-btn text-white'>in:{stepDetails.category && stepDetails.category.length>0 && stepDetails.category[0].name}</div>
                    }

                    <div className='imgtag text-ellipsis'>Images:{stepDetails.image_name_with_tag}</div>
                    <p>
                       {stepDetails.description ? stepDetails.description : ''}
                    </p>
                    <div className='tag-name pd-10'>
                        <span className='bg-primary text-white'>BP Official </span>  <span className='bg-dark text-white'>50K </span>  
                        {/* <span>Executions</span> */}
                    </div>
                    <div className='tag-wrap pd-10'>
                        <span>Tag1</span> <span>Tag2</span> <span>Tag3</span>
                    </div>
                </div>
            </div>
        
    )
}

InfoStaticPanel.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };

const useStyles = makeStyles({
    staticwrap: {
        '& .staticwrap-inner': {
            textAlign: 'center',
            fontSize: '13px',
            '& .com-logo': {
                width: '150px',
                height: 'auto',
                margin: '0 auto',
            },
            '& h5': {
                paddingTop: '12px',
                fontWeight: '500',
                fontSize:'15px'
            },
            '& .notify-btn': {
                background: '#36C5F0',
                display: 'inline-block',
                padding: '5px 10px',
                borderRadius: '4px',
                marginTop: '5px',
            },
            '& .imgtag': {
                border: '1px solid #eee',
                display: 'inherit',
                margin: '0 auto',
                marginTop: '10px',
                padding: '5px',
                borderRadius: '5px',
            },
            '& .tag-name': {
                display: 'flex',
                justifyContent: 'center',
                '& span': {
                    fontSize: '12px',
                    padding: '5px',
                    borderRadius: '5px',
                    marginRight: '6px',
                },
                '& .bg-primary': {
                    background: '#48A3E3'
                },
                '& .bg-dark': {
                    background: '#334050'
                }
            },
            '& .tag-wrap': {
                display: 'flex',
                justifyContent: 'center',
                paddingTop:'20px',
                '& span': {
                    border: '1px solid #3E73EC',
                    padding: '3px 8px',
                    marginRight: '12px',
                    fontSize: '12px',
                    color:'#3E73EC',
                }
            },
            '& p': {
                fontSize: '13px',
                lineHeight: '23px',
                padding: '25px 0',
                color:'#A7A7A7',
            }
        }
    }
})














