import React from 'react';
import PropTypes from 'prop-types';

export const HorizontalMeter = (props)=>{
    const max = props.max;
    const current = props.current;
    const status = props.status;
    const percent = current/max*100;
/*
2 2 'disabled' 100
*/
    return (
        <div className="progress-bar active">
            <div role="progressbar" style={{width: percent+"%", height :'20px'}} className={"progress-bar__bar progress-bar-"+status}>
                <div className="replication__count">{current}{max ? "/"+max: null}</div>
            </div> 
        </div>
    );
}

HorizontalMeter.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }