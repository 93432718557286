import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import FlashOnOutlinedIcon from '@material-ui/icons/FlashOnOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import SortSharpIcon from '@material-ui/icons/SortSharp';
import PlayArrowSharpIcon from '@material-ui/icons/PlayArrowSharp';
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import { Loading } from '../../../../../utils/Loading';
import InvokeApi, { PostData } from '../../../../../../util/apiInvoker';
import GenerateURL, { GenerateSearchURL } from '../../../../../../util/APIUrlProvider';
import properties from '../../../../../../properties/properties';
import { Tooltip } from '@material-ui/core';
import OverridableVariablesDialouge from '../OverridableVariablesDialouge';
import { Link, useHistory } from 'react-router-dom';
import JobTemplateTriggerHistory from '../JobTemplateTriggerHistory';
import ListAltIcon from '@material-ui/icons/ListAlt';
import moment from 'moment';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import TriggerDetailsDialouge from './TriggerDetailsDialouge';
import { getDuration } from '../../../../../../util/util';
import MoreDetailsPageServiceCardV3 from './MoreDetailsPageServiceCardV3';


const JobCardServiceCardForDynamicJob = (props) => {
    const {
        wholeJob, data, showOnlyConfigured,
        setSnackBar,
        component_id,
        service_data,
        application_id,
        selectedServiceEnv,
        wideget_deployment_name,
        masterLabel,
        isLastCard,
        index,
        sub_env_id,
        env_id,
        service_id,
        configuredJobs
    } = props;

    const classes = useStyles();
    const history = useHistory();
    const [showOverRidableDialog, setShowOverRidableDialog] = useState({ show: false, getEnvVarUrl: '', triggered_error: null });

    const [openMoreDetails, setOpenMoreDetails] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClickForCI = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseForCI = () => {
        setAnchorEl(null);
    };
    const { job_name, job_code, templateName, configured } = data;


    const [state, setState] = useState({
        triggeredData: null,
        loading: false,
        historyView: false
    })


    useEffect(() => {

        configured && !showOverRidableDialog.show && fetchTriggeredHistory()

        if (!configured) {
            setState(prevState => ({
                ...prevState,
                triggeredData: null
            }))
        } else {

            fetchWidget();


        }

    }, [configured, showOverRidableDialog])


    function fetchTriggeredHistory() {

        if (component_id != undefined && job_code != undefined && sub_env_id != undefined) {
            let get_url = GenerateURL({ component_id: component_id, sub_env_id: sub_env_id, job_code: job_code }, properties.api.get_job_recent_trigger_data)
            var requestInfo = {
                endPoint: get_url,
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }
            InvokeApi(requestInfo, fetchSuccess, fetchFailure);

            setState(new_state => ({
                ...new_state,
                loading: true

            }));
            console.log('api_called_ljwe;o')
        }
    }

    const getOuputVariables = (vars) => {
        console.log(vars, 'xxx_00pp')
        if (vars && Object.keys(vars)?.length > 0) {
            let variables = []
            Object.keys(vars)?.forEach(stepCode => {
                let stepGenratedVariables = vars[stepCode]
                if (stepGenratedVariables) {
                    Object.keys(stepGenratedVariables)?.forEach(genratedOutputVariable => {
                        let genratedOutputVariableObject = stepGenratedVariables[genratedOutputVariable]
                        variables.push({ key: genratedOutputVariable, value: genratedOutputVariableObject })
                    })
                }
            })
            return variables
        }
        else
            return []
    }
    const fetchSuccess = (data) => {

        if (data && data.component_env_job_meta_information_id != undefined) {
            console.log(data, 'data_knalkdnf')
            // let { results } = data
            // let last_triggered_data = results[0]
            let ouptVariables = getOuputVariables(data?.generated_output_variables)
            console.log(ouptVariables, 'x_00p_lm')
            setState(prevState => ({
                ...prevState,
                loading: false,
                triggeredData: data,
                genratedOutputVariables: ouptVariables

            }))

        }
        else {
            setState(prevState => ({
                ...prevState,
                loading: false

            }))
        }

    }

    const fetchFailure = (error) => {

        console.log(error, 'error_jkadslkkd')
        setState(prevState => ({
            ...prevState,
            loading: false,
            triggeredData: null

        }))
    }



    const onHandleTrigger = (triggerData) => {

        let post_url = GenerateURL({ component_id: component_id, sub_env_id: sub_env_id, job_code: job_code }, properties.api.build_trigger_for_job)
        PostData(post_url, triggerData, () => {
            setShowOverRidableDialog({ show: false, getEnvVarUrl: '' })
            setSnackBar && setSnackBar((prevState => ({ ...prevState, jobTrigger: true })))
        },
            (error) => { setShowOverRidableDialog(prevState => ({ ...prevState, triggered_error: error })) });
    }

    const onClickJobTrigger = (job_code) => {

        let get_url = GenerateURL({ component_id: component_id, sub_env_id: sub_env_id, job_code: job_code }, properties.api.get_trigger_time_modify_env_vars)
        setShowOverRidableDialog({ show: true, getEnvVarUrl: get_url })

    }

    const refershTriggerStatus = () => {
        // fetchTriggeredHistory()
    }

    function fetchWidget() {
        let previousConfigData = null;
        if (component_id != undefined && job_code != undefined && sub_env_id != undefined) {

            let get_url = GenerateURL({ component_id: component_id, sub_env_id: sub_env_id, job_code: job_code }, properties.api.configure_job_data)
            if (data && data?.configured?.status) {
                let status = data.configured.status
                get_url = GenerateSearchURL({ status: status }, get_url)
            }
            var requestInfo = {
                endPoint: get_url,
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }
            console.log(requestInfo.endPoint, "bxhbvhbvhbxbvhxc")
            InvokeApi(requestInfo, (data) => {
                console.log(data, 'config_data')
                previousConfigData = { ...data }
                let keys = Object.keys(data.widget_data);
                let valueList = data.widget_data[keys[0]];
                let selectorList = valueList.map(item => { return { label: item, id: item } });
                setState((prev_state) => ({
                    ...prev_state,
                    widget_data_list: selectorList,
                }))
                //handleConfigure({ jobData: wholeJob, previousConfigData: previousConfigData })

            }, (error) => {
                console.log(error, 'error_0001')
                //handleConfigure({ jobData: wholeJob, previousConfigData: previousConfigData })

            });
        }
    }

    const handleViewAllBuildHistory = () => {
        setShowOverRidableDialog({ show: false, getEnvVarUrl: '', triggredError: null })
        setState(prevState => ({
            ...prevState,
            historyView: true
        }))
    }

    let status = state?.triggeredData?.activity_status?.status
    let created_at = state?.triggeredData?.activity_status?.created_at ? state?.triggeredData?.activity_status?.created_at : ''
    let start_time = state?.triggeredData?.activity_status?.start_time ? state?.triggeredData?.activity_status?.start_time : ''
    let end_time = state?.triggeredData?.activity_status?.end_time ? state?.triggeredData?.activity_status?.end_time : ''
    let updated_at = state?.triggeredData?.activity_status?.updated_at ? state?.triggeredData?.activity_status?.updated_at : ''
    let lastFailure = state?.triggeredData?.last_failure

    console.log(state.triggeredData, 'trg_00p_lmn_0pp');
    console.log('00p_lmk', lastFailure)

    const handleMoreDetails = () => {
        setOpenMoreDetails(true)
    }

    return (
        <>
            {state.loading ?
                <div className={classes.emptyInfoRoot}>
                    <Loading varient="light" />
                </div> :

                <>
                    {
                        // (!state.triggeredData && data?.configured) ?
                        (!state.triggeredData && true) ?

                            <div className={classes.configureInfoRoot}>
                                <div className="heading">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M4.44153 2.72433C4.99739 2.52599 5.60035 2.50094 6.17075 2.6525C6.74115 2.80407 7.25214 3.12511 7.63623 3.57321C8.02033 4.02132 8.25944 4.57541 8.32198 5.16227C8.38452 5.74914 8.26755 6.34118 7.98653 6.86017L16.9107 15.7852L15.7324 16.9635L6.80736 8.0385C6.28825 8.31842 5.69652 8.43449 5.11014 8.37142C4.52375 8.30834 3.97024 8.06909 3.52254 7.68517C3.07483 7.30126 2.75396 6.79071 2.60219 6.22081C2.45042 5.6509 2.47489 5.04839 2.67236 4.49267L4.53653 6.35683C4.65183 6.47622 4.78977 6.57145 4.94227 6.63696C5.09478 6.70247 5.2588 6.73695 5.42477 6.73839C5.59075 6.73984 5.75535 6.70821 5.90897 6.64536C6.06259 6.58251 6.20215 6.48969 6.31952 6.37233C6.43689 6.25496 6.5297 6.1154 6.59255 5.96177C6.6554 5.80815 6.68703 5.64355 6.68559 5.47758C6.68415 5.31161 6.64966 5.14758 6.58415 4.99508C6.51864 4.84257 6.42341 4.70464 6.30403 4.58933L4.44069 2.7235L4.44153 2.72433ZM13.0807 4.29433L15.7324 2.821L16.9107 3.99933L15.4374 6.651L13.964 6.946L12.1974 8.7135L11.0182 7.53517L12.7857 5.76767L13.0807 4.29433ZM7.48236 11.071L8.66069 12.2493L4.24153 16.6685C4.09128 16.8192 3.88908 16.9067 3.67638 16.913C3.46369 16.9194 3.25662 16.8442 3.09762 16.7028C2.93862 16.5613 2.83975 16.3645 2.82126 16.1525C2.80277 15.9405 2.86608 15.7295 2.99819 15.5627L3.06319 15.4902L7.48236 11.071Z" fill="#787878" />
                                    </svg>
                                    <div className='font-12 font-weight-600 color-icon-secondary avtar'>{`Trigger first ${job_name}`}</div>
                                </div>
                                <div className={classes.tiggerCase} >
                                    <div className="real-card">
                                        <button className="btn-primary conf-btn" onClick={() => {
                                            onClickJobTrigger(job_code)
                                        }}>
                                            <span className="ri-flashlight-line font-weight-500 font-20"></span>
                                            <span className=''> Trigger</span>
                                        </button>

                                    </div>
                                </div>
                            </div> :

                            <div className={classes.root}>
                                {

                                    <>
                                        <div className='top d-flex space-between'>
                                            <div className='d-flex'>
                                                <div className='header'>{`${job_name} #${state?.triggeredData?.id}`}</div>
                                                {status == "N/A" ? "" :
                                                    status == "FAILED" ?
                                                        <span className="status-chip-new" style={{ background: "#FFF3F3", color: "#BA0000", border: "1px solid #FBE6E6" }} >Failed</span> :
                                                        (status == "IN_QUEUE" || status == "INITIATED") ?
                                                            <div className="status-chip-new" style={{ background: "#FCF6E1", color: "#FEA111", border: "1px solid #FCF6E1" }}>In queue</div> :
                                                            status == "RUNNING" ?
                                                                <div className="status-chip-new" style={{ background: "#F5FAFF", color: "#0086FF", border: "1px solid #DFEDFF" }}>Running</div> :
                                                                status == "REVOKED" ?
                                                                    <span className="status-chip-new" style={{ background: "#F4F4F4", color: "#626262", border: "1px solid #E6E6E6" }} >Revoked</span> :
                                                                    <span className="status-chip-new" style={{ background: "#EFFFF3", color: "#129E5B", border: "1px solid #DFEDFF" }}>{status == 'IN_QUEUE' ? 'IN QUEUE' : status}</span>
                                                }
                                            </div>
                                            <span onClick={refershTriggerStatus} className='ri-refresh-line font-20 clickable'></span>
                                        </div>
                                        <div className='mid'>
                                            <div style={{ marginBottom: "12px" }} className='d-flex'>
                                                <div className='field-container'>
                                                    <div className='field-label mb-5'>Last Failure</div>
                                                    <div className='content'> {lastFailure ? moment(lastFailure).fromNow() : 'NA'} </div>
                                                </div>
                                                <div>
                                                    <div className='field-label mb-5'>Last Duration</div>

                                                    <div className='content'>
                                                        {
                                                            state.triggeredData ?
                                                                status === 'SUCCESS' || status === 'FAILED' ?
                                                                    getDuration(new Date(start_time), new Date(end_time)) == 0 ? "0s" :
                                                                        getDuration(new Date(start_time), new Date(end_time)) :
                                                                    getDuration(new Date(created_at && created_at), new Date(updated_at)) == 0 ? "0s" :
                                                                        getDuration(new Date(created_at && created_at), new Date(updated_at)) :
                                                                "--"
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex space-between align-center'>
                                                <div className='d-flex align-center'>
                                                    <span style={{ color: '#787878', marginRight: '2px' }} className='ri-time-line font-16'></span>
                                                    <span className='time-field'>{moment(created_at).fromNow()}</span>

                                                </div>
                                                <div>
                                                    <div className='more-details' onClick={handleClickForCI}>
                                                        More Details
                                                    </div>

                                                    <MoreDetailsPageServiceCardV3
                                                        genratedOutputVariables={state.genratedOutputVariables}
                                                        anchorEl={anchorEl}
                                                        onClose={handleCloseForCI}
                                                    />

                                                </div>


                                            </div>

                                        </div>

                                        <div className='bottom d-flex'>

                                            <Tooltip title={`Trigger ${job_name}`}>
                                                {/* <button className="btn btn-flaticon" onClick={toggleTriggerActionCard}><span className="flaticon-flash-on-indicator"></span></button> */}
                                                <button className="btn-sq-icon-primary d-flex align-center" onClick={configured ? () => onClickJobTrigger(job_code) : () => { }}><span className="ri-flashlight-line vertical-align-super"></span></button>
                                            </Tooltip>
                                            <Link
                                                target='_blank'
                                                to={"/logs?global_task_id=" + state.triggeredData?.global_task_id +
                                                    "&tab_id=" + state.triggeredData?.job_number +
                                                    "&service_name=" + `${(service_data && service_data?.name) || 'service_name'}` +
                                                    "&service_env=" + `${(service_data && service_data?.service_env) || 'service_env'}` +
                                                    "&service_type=" + `${(service_data && service_data?.type) || 'service_env_type'}` +
                                                    "&tab_name=BUILD" + "&num=" + state?.triggeredData?.job_number
                                                }>
                                                <Tooltip title="Logs">
                                                    {/* <button className="btn btn-flaticon" onClick={toggleTriggerActionCard}><span className="flaticon-flash-on-indicator"></span></button> */}
                                                    <button className="btn-sq-icon-primary d-flex align-center"><span className="ri-file-text-line vertical-align-super"></span></button>
                                                </Tooltip>

                                            </Link>
                                            {/* <Tooltip title="History" >
                                                <button onClick={() => {
                                                    setState(prevState => ({
                                                        ...prevState,
                                                        historyView: true
                                                    }))
                                                }
                                                } className="btn-sq-icon-primary d-flex align-center">
                                                    <span className="ri-filter-3-fill vertical-align-super"></span>
                                                </button>
                                            </Tooltip> */}
                                        </div>
                                    </>
                                }

                            </div>

                    }
                    {
                        showOverRidableDialog && showOverRidableDialog.show &&
                        <OverridableVariablesDialouge
                            component_id={component_id}
                            handleViewAllBuildHistory={handleViewAllBuildHistory}
                            sub_env_id={sub_env_id}
                            job_code={job_code}
                            job_name={job_name}
                            triggredError={showOverRidableDialog.triggered_error}
                            handleTrigger={onHandleTrigger}
                            envProps={showOverRidableDialog} onClose={() => setShowOverRidableDialog({ show: false, getEnvVarUrl: '', triggredError: null })} />
                    }
                </>
            }

            {
                state.historyView && <JobTemplateTriggerHistory
                    job_name={job_name}
                    configuredJobs={configuredJobs}
                    component_id={component_id}
                    handleClose={() => {
                        setState(prevState => ({
                            ...prevState,
                            historyView: false
                        }))
                    }
                    }
                    sub_env_id={sub_env_id}
                    job_code={job_code}
                    open={state.historyView} />
            }
        </>

    )
}

export default JobCardServiceCardForDynamicJob;

const useStyles = makeStyles({
    root: {
        width: '420px',
        height: '196px',
        gap: '0px',
        borderRadius: '6px',
        border: '1px 0px 0px 0px',
        backgroundColor: '#fff',
        border: '1px solid #E6E6E6',
        boxShadow: '0px 12px 16px 0px #00000014',
        flex: '0 0 auto',
        '& .status-chip-new': {
            display: "flex",
            padding: "4px 6px",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            fontWeight: "700",
            borderRadius: "4px",
            marginLeft: "12px",
            textTransform: "uppercase",
            fontSize: '12px'
        },
        '& .clickable': {
            cursor: 'pointer'
        },
        '& .top': {
            height: '48px',
            padding: '12px 8px 12px 16px',
            gap: '0px',
            '& .header': {
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '17.07px',
                color: '#2F2F2F',
                marginRight: '12px',
                marginTop: '3px'
            }
        },
        '& .mid': {
            height: '90px',
            padding: '8px 16px 16px 16px',
            borderBottom: '1px solid #dedede',
            '& .field-label': {
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: '14.63px',
                textAlign: 'left',
                color: '#787878',
            },
            '& .field-container': {
                marginRight: '30px'
            },
            '& .content': {
                fontSize: '12px',
                fontWeight: '600',
                lineHeight: '14.63px',
                textAlign: 'left',
                color: '#505050'
            }
            ,
            '& .time-field': {
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: '14.63px',
                color: '#787878',
            },
            '& .more-details': {
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: '14.63px',
                color: '#124D9B',
                cursor: 'pointer',
                '&:hover': {
                    textDecoration: 'underline',
                }

            }
        },
        '& .bottom': {
            height: '56px',
            padding: '10px 12px 10px 12px',
            gap: '5px',
            justifyContent: 'flex-end'
        }
    },
    configureInfoRoot: {
        flex: '0 0 auto',
        width: '420px',
        height: '196px',
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        borderRadius: "6px",
        border: "1px solid #E6E6E6",
        '& .heading': {
            display: "flex",
            width: "100%",
            height: "48px",
            padding: "12px 16px",
            alignItems: "center",
            gap: "4px",
        },
    },
    emptyInfoRoot: {
        flex: '0 0 auto',
        width: '420px',
        height: '196px',
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        borderRadius: "6px",
        border: "1px solid #E6E6E6",

    },
    tiggerCase: {
        display: "flex",
        padding: "8px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "10px",
        alignSelf: "stretch",
        height: "100%",
        paddingTop: "0px",
        '& .real-card': {
            display: "flex",
            padding: "0px 16px",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
            alignSelf: "stretch",
            borderRadius: "6px",
            border: "1px solid #F4F4F4",
            height: "100%",
            //background: "#FAFAFA",
            paddingTop: "10px",
            justifyContent: "center",
            '& .conf-btn': {
                display: "flex",
                height: "32px",
                padding: "11px 16px 11px 12px",
                justifyContent: "center",
                alignItems: "center",
                gap: "6px",
                borderRadius: "6px",
                border: "1px solid #F4F4F4",
                background: "#0086FF",
                textTransform: "uppercase",
            }
        }
    }
})
