import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RadioForms from '../RadioForms';
import FileUpload from './FileUpload';
import GitRepo, { GitRepoDefaultState } from './GitRepo';
import { ValidateDataSet, VALIDATION_TYPE_REQUIRED } from '../../../util/Validator';
import { ResetComponent, getCommonFunctions } from '../../../views/serviceRevamp/add/ci_flow/SourceDetails';
import MultiRow from '../MultiRow';
import { Grid } from '@material-ui/core';
import { Input } from '../Input';


export const FILE_UPLOAD = "FILE_UPLOAD";
export const GIT_REPO = "GIT_REPO";

const value_file_options = {
  FILE_UPLOAD: {
    label: "Via File Upload",
    value: FILE_UPLOAD,
    component: FileUpload,
    props: { varient: "single" }
  },
  GIT_REPO: {
    label: "Via Git Repo",
    value: GIT_REPO,
    component: GitRepo,
    props: { single_path: true }
  },
}

function HelmCharts(props) {

  const inherits = props.inherits;
  const [state, setState] = useState(props.state ? props.state : getDefaultState());
  console.log(props.state, "hjbyvhtyvytyvy")
  const helm_template_inherits = {};

  const value_file_inherits = {};
  const release_name_inherits = {};
  function getNewState() {
    const helm_template_state = helm_template_inherits.getState();
    const value_file_state = value_file_inherits.getState();
    const release_name_state = release_name_inherits.getState();
    
    const new_state = {
        helm_template: { ...helm_template_state },
        value_file: { ...value_file_state },
        helm_release_name :release_name_state,
    }
    return new_state;
}

  //Overloadings
  inherits.validateForm = () => {
      var helm_template_validation_results = helm_template_inherits.validateForm && helm_template_inherits.validateForm() ? helm_template_inherits.validateForm() : { valid: true };
      const value_file_validation_results = value_file_inherits.validateSelectedComponent && value_file_inherits.validateSelectedComponent() ? value_file_inherits.validateSelectedComponent() : { valid: true };
      const release_name_validation_results = release_name_inherits.validateForm && release_name_inherits.validateForm() ? release_name_inherits.validateForm() : { valid: true };
      const result = {
        valid: helm_template_validation_results.valid && value_file_validation_results.valid && release_name_validation_results.valid,
        error: {
          ...helm_template_validation_results.error,
          ...value_file_validation_results.error,
          ...release_name_validation_results.error,
        }
      };
      return result;
    
  }

  inherits.getData = () => {
    const new_state = getNewState();

    setState(new_state)
    return new_state;
  }

  inherits.getState = () => {
    const new_state = getNewState();
    setState(new_state)
    return new_state;
  }
 


  return (
    <>
      <div className="helm-temp">
        <p className='mainHeading mt-10 mb-10'>Helm Templates</p>
       
          <GitRepo
          inherits={helm_template_inherits}
          single_path={true}
          state={state.helm_template}
          variant={"with_helm_support"}
        />
          
            
           

        <ReleaseName inherits={release_name_inherits} state={state.release_name} />

        {/* <RawEnvVariables inherits={raw_env_var_inherits} prev_state={state.raw_env_var}  /> */}
      </div>
      <div className="value-files" style={{ padding: '10px' }}>
        <p className='mainHeading mb-15' >Value Files</p>
        <RadioForms
          options={value_file_options}
          inherits={value_file_inherits}
          state={state.value_file}

        />
      </div>
    </>
  );
}

HelmCharts.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export default HelmCharts;


// Utils


function getDefaultState() {
  return {
      data:{
       
      },
      validations:{},
      error:{

      },
      helm_template: {
          ...GitRepoDefaultState()
      },
      release_name:{
          ...getReleaseNameDefaultState()
      },
      value_file: null
  }
}
export function HelmChartsDefaultState() {
  return getDefaultState();
}

const ReleaseName = props => {
    const inherits = props.inherits;
    const [state, setState] = useState(props.state ? props.state : getReleaseNameDefaultState());
    function onChangeHandler(e) {
        const key = e.target.name;
        let value = e.target.value;
        updateData(key, value);
    }

  function updateData(key, value) {
    setState(new_state => ({
      ...new_state,
      data: {
        ...new_state.data,
        [key]: value
      },
      error: {
        ...new_state.error,
        [key]: ""
      }
    }))
  }
  inherits.validateForm = () => {
    const result = ValidateDataSet(state.data, state.validations);
    if (!result.valid) {
      setState(new_state => ({
        ...new_state,
        data: {
          ...new_state.data,
          error: result.error
        }
      }));
    }
    return result;
  }
  inherits.getData = () => {
    var result = { ...state.form_data.data }
    return result;
  }

  inherits.getState = () => {
    return state;
  }
  return (
    <div className="pd-10">
      <Input
        type="text"
        label="Helm Release Name"
        name="helm_release_name"
        placeholder="Enter Helm Release name"
        error={state.error}
        data={state.data}
        onChangeHandler={onChangeHandler}
      />
    </div>
  )
}

ReleaseName.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

function getReleaseNameDefaultState(){
    return {
            data:{},
            error:{},
            validations:{}
        }

}


const RawEnvVariables = (props) => {
    const inherits = props.inherits ? props.inherits : {};
    const prev_state = props.prev_state ? props.prev_state : null;
    const [state, setState] = useState(prev_state ? prev_state : getEnvVarDefaultState())
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    return(
        <div className="card card-switch box-shadow-none " style={{margin: 10}}>
              <Input
                type="switch"
                name="deploy_variable_raw_input"
                label="Define Raw Key Value Pairs"
                onChangeHandler={commonFunctions.toggleState}
                data={state.data}
                error={state.error}
                enabledComponent={<EnabledComponentRawInput prev_state={state.env_var_raw_input} inherits={state.child_inherits.env_var_raw_input} zeroRowsAllowed={true} />}
                disabledComponent={<ResetComponent inherits={state.child_inherits.env_var_raw_input} />}
              />
            </div>
    )
}

RawEnvVariables.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}
export function getEnvVarDefaultState() {
  return {
    data: {
      deploy_variable_raw_input: false,
    },
    error: {},
    validations: {},
    child_inherits: {
      env_var_raw_input: {
        "validateForm": () => { return { valid: true }; },
        "getState": () => { return {}; },
        "getData": () => { return {}; }
      }
    },
  };
}

const EnabledComponentRawInput = (props) => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const [state, setState] = useState(prev_state ? prev_state : getEnabledComponentRawInputDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);
  


  return (
    <div className='card-body-flex'>      
        <MultiRow  RepeatableComponent={RawInputBody} HeaderComponent={RawInputHeader} prev_state={state.multi_row} inherits={state.child_inherits.multi_row} zeroRowsAllowed={true} />
      

    </div>

  )
}

  EnabledComponentRawInput.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

function getEnabledComponentRawInputDefaultState() {
  return {
    data: {},
    error: {},
    validations: {},
    child_inherits: {
      multi_row: {
        "validateForm": () => { return { valid: true }; },
        "getState": () => { return {}; },
        "getData": () => { return {}; }
      }
    }
  };
}
  const RawInputBody = (props) => {
    const inherits = props.inherits ? props.inherits : {};
    const prev_state = props.prev_state ? props.prev_state : null;
    const [state, setState] = useState(prev_state ? prev_state : getRawInputBodyDefaultState())
    const commonFunctions = getCommonFunctions(state, setState, inherits);
  
    return (
      <>
        <Grid item lg={5}>
          <Input
            type="text"
            placeholder="Name"
            name="env_key"
            error={state.error}
            onChangeHandler={commonFunctions.onChangeHandler}
            data={state.data} />
        </Grid>
  
        <Grid item lg={5}>
          <Input
            type="text"
            placeholder="Value"
            name="env_value"
            error={state.error}
            onChangeHandler={commonFunctions.onChangeHandler}
            data={state.data} />
        </Grid>
  
  
      </>
    )
  }

  RawInputBody.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }
  
  export function getRawInputBodyDefaultState() {
    return {
      data: {},
      error: {},
      validations: {
        env_key: [VALIDATION_TYPE_REQUIRED],
        env_value: [VALIDATION_TYPE_REQUIRED],
      },
    };
  }
  function RawInputHeader() {
    return (
      <>
        <Grid item lg={5}>
          Key
                  </Grid>
        <Grid item lg={5}>
          Value
                  </Grid>
      </>
    )
  }
