import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { styled } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import PipelineExecutionHistory from "./component/PipelineExecutionHistory";
import Header from "./component/Header";
import GeneralData from "./component/GeneralData";
import { LoadingContainer } from "../../utils/Loading";
import GenerateURL, { GenerateSearchURL } from "../../../util/APIUrlProvider";
import InvokeApi, { PostData } from "../../../util/apiInvoker";
import properties from "../../../properties/properties";
import { Input } from "../../../components/genericComponents/Input";
import StageProgressDetail from "./component/StageProgressDetail";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import CardFooter from "./component/CardFooter";
import EditIcon from "@material-ui/icons/Edit";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { GetAuth } from "../../../util/security";
import Pagination from "../../../components/Pagination";
import RunWithParameter from "./RunWithParameter";
import Delete from "../../../components/genericComponents/Delete";
import StopIcon from "@material-ui/icons/Stop";
import { Grid, Tooltip, Dialog, Slide, Card } from "@material-ui/core";
import RunWithParameterForV3 from "./RunWithParameterForV3";
import CloseIcon from '@material-ui/icons/Close';
import RetryPopup from "./component/RetryPopup";
import AdvanceSearchFilterCombo from "../../../components/genericComponents/AdvanceSearchFilter/AdvanceSearchFilterCombo";
import { showErrorHandlerUpdated } from "../../../util/util";
import GenericSkeleton from "../../../components/genericComponents/Skeletons/GenericSkeleton";
import { useHistory, useLocation } from 'react-router';
import useFetchPermissions from "../../../components/customHooks/useFetchPermissions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
const filter_list = [
  {
    label: "Name",
    name: "name_exact",
  },
  {
    label: "Tag",
    name: "tag",
  },
];

export const PiplineHeaderSkeleton = (props)=>{
  const classes = useStyles();
  console.log("sjijhdweijdfhbdewdbhfewekjfv",window)
  return(
    <>
    <div className={classes.root}>
          <Grid container spacing={4}>
            <Grid item lg={5} sm={12} xl={6} xs={12} className="pd-bottom">
              <div className="head-section">
                <div className="heading-section-service width-full">
                  <h1>
                    <GenericSkeleton variant={"text"} width={"100%"} height={"32px"}/>
                  </h1>
                  <h2 >
                    <GenericSkeleton variant={"text"} width={"60%"} height={"24px"}/>
                  </h2>

                </div>

              </div>
            </Grid>
            <Grid item lg={7} sm={12} xl={6} xs={12}>
              <div className="d-grid align-center justify-flexend update-search-class" style={{flexDirection: "row-reverse" }}>

                <GenericSkeleton variant={"rect"} width={120} height={44} style={{borderRadius:"6px"}}/>
                

              </div>
            </Grid>
            
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "70% 30% ",
                  marginBottom: "20px",
                  width: '100%',
                  padding: "16px"
                }}
              >

                <Grid container>
                  
                        <div className="ad-more-search mb-20">
                          <AdvanceSearchFilterCombo
                            skeleton={true}
                          />
                        </div>
                   
                
                  

                  <div onKeyDown={()=>{}} className="pl-15 ml-20 d-flex align-center justify-center cursor-pointer" style={{ borderLeft: '1px solid #dedede', height: '40px' }} tabIndex={0} role="button">
                    <span style={{ color: '#595353' }} className="font-13 cursor-pointer">
                      <GenericSkeleton variant={"text"} width={80}/>
                    </span>
                  </div>
                </Grid>

                <Pagination
                  skeleton={true}
                />
              </div>

              


          </Grid>
    </div>
    </>
  );
}

const PipelineCard = (props) => {
  const classes = useStyles();
  const { application_id } = props.match.params;
  var url = GenerateURL(
    { application_id: application_id },
    properties.api.pipeline_save_url,
    true
  );
  const permissions = useFetchPermissions()
  console.log(permissions,'permissions_0023');
  const is_permited = permissions?.POST.includes(url);

  const default_state = {
    loaded: false,
    current: GenerateURL(
      { application_id: application_id },
      properties.api.application_all_pipelines_last_execution
    ),
    data: {
      filter: "tag",
      search: "",
    },
    total_page: "",
    loading_sse_status: false,
    pipeline_sse_id: "",
    stage_with_sse_status: {},
    curr_page: "",
    refresh_count: 0,
    moreAdvFilterList: [],
    advFilters: {
      status: [],
      name: [],
      tag: []
    },
    resetCount: 0,
    is_filters_applied: false,
  };
  const rollback_data = props.location.state;
  const [state, setState] = useState(default_state);
  const defaultFilters = ["name", "tag"]
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const from_history = JSON.parse(searchParams.get('from_history'));
  const current_page_no = searchParams.get('current_page_no')

  const moreFilterData = [
    { label: "Status", value: "status" },
    { label: "Name", value: "name_exact" },
    { label: "Tag", value: "tag" },
  ]

  const resetFilterData = {
    name: [],
    status: [],
    tag: []
  }

  // useEffect(() => {
  //   if (from_history) {
  //     requestAllPipelineLastExeccutions(null, from_history);
  //   } else {
  //     requestAllPipelineLastExeccutions();
  //   }

  // }, [application_id, state.refresh_count]);
  useEffect(() => {
    resetAdvFilter();
  }, [])

  useEffect(() => {
    isFiltersApplied()
  }, [state.advFilters])

  function isFiltersApplied() {
    var count = 0;
    Object.keys(state.advFilters).forEach((item) => {
      if (state.advFilters[item].length > 0) {
        count = count + 1;
      }
    })

    setState((new_state) => ({
      ...new_state,
      is_filters_applied: count > 0 ? true : false
    }))
  }

  console.log(state, "function_running")
  useEffect(() => {
    if (from_history) {
      const urlSearchParams = new URLSearchParams(from_history);
      if (urlSearchParams.size != 0) {
        let moreAdvFilter = defaultFilters
        let advFilters = {}
        urlSearchParams?.forEach((value, key) => {
          if (value) {
            if (key == 'adv_search') {
              let advValue = value
              let updatedList = advValue.split(',')
              updatedList = [...moreAdvFilter, ...updatedList]
              moreAdvFilter = [...new Set(updatedList)]
            }
            else {
              let filterValue = value
              const updatedList = filterValue.split(',')
              advFilters[key] = updatedList
            }
          }
        })
        setState(prevState => ({
          ...prevState,
          moreAdvFilterList: moreAdvFilter,
          advFilters: { ...advFilters }
        }))
        fetchAllPipelineLastExecutionForListFilter(advFilters, moreAdvFilter)
      }
    } else {
      const urlSearchParams = new URLSearchParams(location.search);
      if (urlSearchParams.size != 0) {
        let moreAdvFilter = defaultFilters
        let advFilters = {}
        urlSearchParams?.forEach((value, key) => {
          if (value) {
            if (key == 'adv_search') {
              let advValue = value
              let updatedList = advValue.split(',')
              updatedList = [...moreAdvFilter, ...updatedList]
              moreAdvFilter = [...new Set(updatedList)]
            }
            else {
              let filterValue = value
              const updatedList = filterValue.split(',')
              advFilters[key] = updatedList
            }
          }
        })
        setState(prevState => ({
          ...prevState,
          moreAdvFilterList: moreAdvFilter,
          advFilters: { ...advFilters }
        }))
        fetchAllPipelineLastExecutionForListFilter(advFilters, moreAdvFilter)
      }
    }
    

  }, [application_id, state.refresh_count])


  const isFilterActive = (filterKey, list) => {

    const isFound = list?.find(name => filterKey == name)

    return isFound;
  }

  function generateFilterContainURL(baseURL, listDict, activeFilters) {
    var searchParams = ''
    const filtersKeyArray = Object.keys(listDict)
    let i = 0
    let advFilterApplied = false
    const activeFilterList = activeFilters ? activeFilters : state.moreAdvFilterList
    filtersKeyArray.forEach(key => {

      const arrayFilterValues = listDict[key];
      if (arrayFilterValues.length > 0 && isFilterActive(key, activeFilterList)) {
        if (i != 0) {
          searchParams += '&'
        }
        advFilterApplied = true
        var arrayString = arrayFilterValues;
        const stringKey = `${key}=${arrayString}`
        searchParams += stringKey
        i++;
      }
    })
    let finalUrl;
    if (searchParams) {
      finalUrl = baseURL + '?' + searchParams.toString();
    } else {
      finalUrl = baseURL;
    }
    return finalUrl
  }

  function fetchAllPipelineLastExecutionForListFilter(listFilterData, activeFilterList) {

    let baseURL = from_history ? from_history : GenerateURL({ application_id: application_id }, properties.api.application_all_pipelines_last_execution)

    const resultURL = generateFilterContainURL(baseURL, listFilterData, activeFilterList);
    let requestInfo = {
      endPoint: resultURL,
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };
    console.log(requestInfo, 'reft-ppsd')
    setState((new_state) => ({
      ...new_state,
      loaded: false,
      current: requestInfo.endPoint,
    }));
    InvokeApi(requestInfo, PipelineInfoFetchSuccess, PipelineInfoFetchFailure);
  }

  function PipelineInfoFetchSuccess(response) {
    var result = filterData(response.results);

    var filtered_result = [];
    if (state.is_showing_pending) {
      filtered_result = result.pending;
    }
    setState((new_state) => ({
      ...new_state,
      ...result,
      loaded: true,
      count: response.count,
      next: response.next ? properties.api.baseURL + response.next : null,
      previous: response.previous
        ? properties.api.baseURL + response.previous
        : null,
      filtered_result: filtered_result.length > 0 ? filtered_result : null,
      total_page:
        response.count != 0
          ? Number.isInteger(Number(response.count) / 10)
            ? (Number(response.count) / 10).toFixed(0)
            : (Number(response.count) / 10 + 1).toFixed(0) >
              Number(response.count) / 10 + 1
              ? (Number(response.count) / 10 + 1).toFixed(0) - 1
              : (Number(response.count) / 10 + 1).toFixed(0)
          : 1,
      curr_page: current_page_no ? current_page_no : 1,
    }));
  }

  function PipelineInfoFetchFailure(error) {
    setState((new_state) => ({
      ...new_state,
      error: error,
      loaded: true,
    }));
  }


  const refresh_state = () => {
    console.log("function running 123456 ================>")
    setState((new_state) => ({
      ...new_state,
      refresh_count: Number(new_state.refresh_count + 1),
    }));
  };

  function requestAllPipelineLastExeccutions(data, url) {
    console.log("function running 123456 ================>")
    console.log("jdhjsss", data, state);
    var requestInfo = {
      endPoint: GenerateURL(
        { application_id: application_id },
        properties.api.application_all_pipelines_last_execution
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    if (data) {
      requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
    }

    if (url) {
      console.log(url, 'url_data_0s0d')
      requestInfo.endPoint = url;
      let current_page_no = state.curr_page;
    }
    setState((new_state) => ({
      ...new_state,
      loaded: false,
      current: requestInfo.endPoint,
      search_query_name: data ? (data.name ? data.name : "") : "",
      serach_querty_tag: data ? (data.tag ? data.tag : "") : "",
    }));

    console.log(requestInfo.endPoint, 'ann_mp')
    InvokeApi(requestInfo, handleResponse, handleError);
  }

  function refreshForDelete() {
    console.log("function running 123456 ================>")
    //    console.log("jdhjsss",data,state);
    resetAdvFilter();
    var requestInfo = {
      endPoint: GenerateURL(
        { application_id: application_id },
        properties.api.application_all_pipelines_last_execution
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };
    setState((new_state) => ({
      ...new_state,
      loaded: false,
      refresh_count: new_state.refresh_count + 1,
      current: requestInfo.endPoint,
      search_query_name: "",
      serach_querty_tag: "",
    }));

    InvokeApi(requestInfo, handleResponseDelete, handleError);
  }

  function handleResponseDelete(response) {
    console.log("function running 123456 ================>")
    var result = filterData(response.results);

    var filtered_result = [];
    if (state.is_showing_pending) {
      filtered_result = result.pending;
    }
    setState((new_state) => ({
      ...new_state,
      ...result,
      loaded: true,
      count: response.count,
      next: response.next ? properties.api.baseURL + response.next : null,
      previous: response.previous
        ? properties.api.baseURL + response.previous
        : null,
      filtered_result: filtered_result.length > 0 ? filtered_result : null,
      total_page:
        response.count != 0
          ? Number.isInteger(Number(response.count) / 10)
            ? (Number(response.count) / 10).toFixed(0)
            : (Number(response.count) / 10 + 1).toFixed(0) >
              Number(response.count) / 10 + 1
              ? (Number(response.count) / 10 + 1).toFixed(0) - 1
              : (Number(response.count) / 10 + 1).toFixed(0)
          : 1,
      curr_page: current_page_no ? current_page_no : 1,
    }));
    console.log(
      "pipe get error",
      state.total_page,
      response.count ? response.count : null
    );
  }

  function handleResponse(response) {
    console.log("function running 123456 ================>")
    var result = filterData(response.results);

    var filtered_result = [];
    if (state.is_showing_pending) {
      filtered_result = result.pending;
    }
    setState((new_state) => ({
      ...new_state,
      ...result,
      loaded: true,
      count: response.count,
      next: response.next ? properties.api.baseURL + response.next : null,
      previous: response.previous
        ? properties.api.baseURL + response.previous
        : null,
      filtered_result: filtered_result.length > 0 ? filtered_result : null,
      total_page:
        response.count != 0
          ? Number.isInteger(Number(response.count) / 10)
            ? (Number(response.count) / 10).toFixed(0)
            : (Number(response.count) / 10 + 1).toFixed(0) >
              Number(response.count) / 10 + 1
              ? (Number(response.count) / 10 + 1).toFixed(0) - 1
              : (Number(response.count) / 10 + 1).toFixed(0)
          : 1,
      curr_page: current_page_no ? current_page_no : new_state.curr_page ? new_state.curr_page : 1,
    }));
    console.log(
      "pipe get error",
      state.total_page,
      response.count ? response.count : null
    );
  }

  function handleError(error) {
    console.log("pipe get error", error);
  }


  function requestForDelete() {
    console.log("function running 123456 ================>")
    var requestInfo = {
      endPoint: GenerateURL(
        { application_id: application_id },
        properties.api.application_all_pipelines_last_execution
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };
    setState((new_state) => ({
      ...new_state,
      loaded: false,
      current: requestInfo.endPoint,
      // search_query_name: data ? (data.name ? data.name : "") : "",
      // serach_querty_tag: data ? (data.tag ? data.tag : "") : "",
    }));

    console.log(requestInfo.endPoint, 'ann_mp')
    InvokeApi(requestInfo, handleResponse, handleError);
  }

  function filterData(data) {
    console.log(data, "fsajdnfjasfjks");
    data.reverse();
    const pending = [];
    const waiting_to_resume = [];
    updatePartOfExecutionFlagForRunComponents(data);
    data.forEach((pipeline) => {
      if (pipeline.last_trigger) {
        if (pipeline.last_trigger.status == "PENDING_APPROVAL") {
          pipeline.status = "PENDING_APPROVAL";
          pending.push(pipeline);
        } else if (pipeline.last_trigger.status == "PENDING_RESUME") {
          pipeline.status = "PENDING_RESUME";
          waiting_to_resume.push(pipeline);
        }
      }
    });

    return {
      pipeline_array: data,
      pending: pending.length > 0 ? pending : null,
      waiting_to_resume:
        waiting_to_resume.length > 0 ? waiting_to_resume : null,
    };
  }

  function updatePartOfExecutionFlagForRunComponents(data) {
    if (data) {
      data.forEach((pipeline) => {
        if (pipeline.components) {
          if (pipeline.last_trigger && pipeline.last_trigger.components) {
            updatePartOfExecutionFlagInComponents(
              pipeline.components,
              pipeline.last_trigger.components
            );
          }
        }
      });
    }
  }

  function updatePartOfExecutionFlagInComponents(
    all_components,
    run_components
  ) {
    var components_map = {};
    if (all_components) {
      all_components.forEach((component) => {
        components_map[component.name] = component;
        //This line is required for pipeline history page
        component.part_of_execution = false;
      });

      if (run_components) {
        run_components.forEach((component) => {
          if (components_map[component.name]) {
            components_map[component.name].part_of_execution = true;
          }
        });
      }
    }
  }

  function handleNextPipelines(data, url) {
    var requestInfo = {
      endPoint: GenerateURL(
        { application_id: application_id },
        properties.api.application_all_pipelines_last_execution
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    if (data) {
      requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
    }

    if (url) {
      requestInfo.endPoint = url;
    }
    setState((new_state) => ({
      ...new_state,
      loaded: false,
      current: requestInfo.endPoint,
    }));

    InvokeApi(requestInfo, handleNextResponse, handleNextError);
  }

  function handleNextResponse(response) {
    var result = filterData(response.results);

    var filtered_result = [];
    if (state.is_showing_pending) {
      filtered_result = result.pending;
    }
    setState((new_state) => ({
      ...new_state,
      ...result,
      loaded: true,
      count: response.count,
      next: response.next ? properties.api.baseURL + response.next : null,
      previous: response.previous
        ? properties.api.baseURL + response.previous
        : null,
      filtered_result: filtered_result.length > 0 ? filtered_result : null,
      total_page: Number.isInteger(Number(response.count) / 10)
        ? (Number(response.count) / 10).toFixed(0)
        : (Number(response.count) / 10 + 1).toFixed(0) >
          Number(response.count) / 10 + 1
          ? (Number(response.count) / 10 + 1).toFixed(0) - 1
          : (Number(response.count) / 10 + 1).toFixed(0),
      curr_page: Number(new_state.curr_page + 1),
    }));
  }

  function handleNextError(error) {
    console.log("pipe get error", error);
  }

  //start of page code --------------------------------------------

  function handlePagePipelines(enteredPageNumber) {
    var requestInfo = {
      endPoint: GenerateURL(
        { application_id: application_id },
        properties.api.application_all_pipelines_last_execution
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    if (enteredPageNumber > 1) {
      requestInfo.endPoint =
        requestInfo.endPoint +
        "?limit=10&offset=" +
        (enteredPageNumber - 1) * 10;
    }
    setState((new_state) => ({
      ...new_state,
      loaded: false,
      current: requestInfo.endPoint,
    }));

    var current_page = enteredPageNumber;

    InvokeApi(
      requestInfo,
      (response) => {
        handlePageResponse(response, current_page);
      },
      handlePageError
    );
  }

  function handlePageResponse(response, count) {
    var result = filterData(response.results);

    var filtered_result = [];
    if (state.is_showing_pending) {
      filtered_result = result.pending;
    }
    setState((new_state) => ({
      ...new_state,
      ...result,
      loaded: true,
      count: response.count,
      next: response.next ? properties.api.baseURL + response.next : null,
      previous: response.previous
        ? properties.api.baseURL + response.previous
        : null,
      filtered_result: filtered_result.length > 0 ? filtered_result : null,
      total_page: Number.isInteger(Number(response.count) / 10)
        ? (Number(response.count) / 10).toFixed(0)
        : (Number(response.count) / 10 + 1).toFixed(0) >
          Number(response.count) / 10 + 1
          ? (Number(response.count) / 10 + 1).toFixed(0) - 1
          : (Number(response.count) / 10 + 1).toFixed(0),
      curr_page: Number(count),
    }));
  }

  function handlePageError(error) {
    console.log("pipe get error", error);
  }

  //end of page code-----------------------------------------------

  function handlePrevPipelines(data, url) {
    var requestInfo = {
      endPoint: GenerateURL(
        { application_id: application_id },
        properties.api.application_all_pipelines_last_execution
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    if (data) {
      requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
    }

    if (url) {
      requestInfo.endPoint = url;
    }
    setState((new_state) => ({
      ...new_state,
      loaded: false,
      current: requestInfo.endPoint,
    }));

    InvokeApi(requestInfo, handlePrevResponse, handlePrevError);
  }

  function handlePrevResponse(response) {
    var result = filterData(response.results);

    var filtered_result = [];
    if (state.is_showing_pending) {
      filtered_result = result.pending;
    }
    setState((new_state) => ({
      ...new_state,
      ...result,
      loaded: true,
      count: response.count,
      next: response.next ? properties.api.baseURL + response.next : null,
      previous: response.previous
        ? properties.api.baseURL + response.previous
        : null,
      filtered_result: filtered_result.length > 0 ? filtered_result : null,
      total_page: Number.isInteger(Number(response.count) / 10)
        ? (Number(response.count) / 10).toFixed(0)
        : (Number(response.count) / 10 + 1).toFixed(0) >
          Number(response.count) / 10 + 1
          ? (Number(response.count) / 10 + 1).toFixed(0) - 1
          : (Number(response.count) / 10 + 1).toFixed(0),
      curr_page: Number(new_state.curr_page - 1),
    }));
  }

  function handlePrevError(error) {
    console.log("pipe get error", error);
  }

  console.log(state.current, "c_pop");

  function hideNotification() {
    setState({
      ...state,
      pending: null,
      waiting_to_resume: null,
    });
  }
  const [clear_child_search, set_clear_child_state] = useState({
    clear_state: false,
  });
  const clear_search_params = () => {
    fetchAllPipelineLastExecutionForListFilter();
    resetAdvFilter()
    set_clear_child_state({
      ...clear_child_search,
      clear_state: true,
    });
  };

  function sendApprovalRequest(id) {
    var post_url = GenerateURL({}, properties.api.approval_request);

    var post_obj = {
      "name": "pipeline",
      "entity_id": id
    }

    PostData(post_url, post_obj, sendApprovalRequestSuccess, sendApprovalRequestFailed);
    setState(new_state => ({
      ...new_state,
      loading: true,
      error_msg: null,
      open: true
    }));
  }

  function sendApprovalRequestSuccess(response) {
    // refreshState()
    refresh_state()
    var msg = response.detail
    setState(new_state => ({
      ...new_state,
      approval_request_success_msg: msg,
    }));

  }

  function sendApprovalRequestFailed(error) {
    var error = error.toString()
    setState(new_state => ({
      ...new_state,
      deployment_failed_msg: error
    }));

  }

  function confirmForApproval(id) {
    console.log("function running 123456 ================>")
    var post_url = GenerateURL({}, properties.api.confirm_approval);

    var post_obj = {
      "name": "pipeline",
      "entity_id": id
    }

    PostData(post_url, post_obj, confirmApprovalRequestSuccess, confirmApprovalRequestFailed);
    setState(new_state => ({
      ...new_state,
      loading: true,
      error_msg: null,
      open: true
    }));
  }

  function confirmApprovalRequestSuccess(response) {
    console.log("function running 123456 ================>")
    // refreshState()
    refresh_state()
    var msg = response.detail
    setState(new_state => ({
      ...new_state,
      approval_confirm_success_msg: msg,
    }));
  }

  function confirmApprovalRequestFailed(error) {
    console.log("function running 123456 ================>")
    var error = error.toString()
    setState(new_state => ({
      ...new_state,
      approval_failed_msg: error
    }));
  }

  // function refreshState() {
  //   setState(new_state => ({
  //     ...new_state,
  //     refresh_count: new_state.refresh_count + 1
  //   }));
  // }
  const onUpdateHandle = (uniqueId, updatedList) => {

    let updatedKey = ''
    if (uniqueId === 'more-button-adv-0') {
      addFiltersToUrl('adv_search', updatedList)
      if (updatedList?.length == 0) {
        resetAdvFilter()
      }
      else {
        // const alreadyAddedFilters = state.advFilters
        // Object.keys(alreadyAddedFilters)?.forEach(filterName => {
        //   if (!updatedList.includes(filterName)) {
        //     alreadyAddedFilters[filterName] = []
        //   }
        // })
        setState(prevState => ({
          ...prevState,
          moreAdvFilterList: updatedList,
          // advFilters: { ...alreadyAddedFilters }
        }))
        // fetchActivitiesForListFilter(alreadyAddedFilters)
      }
    }
    else {
      // if (uniqueId == 'job_type_adv_1') {
      //   updatedKey = 'job_type'

      // }
      // if (uniqueId == 'project_name_adv_3') {
      //   updatedKey = 'project_name'
      // }
      // if (uniqueId == 'env_master_adv_2') {
      //   updatedKey = 'env_master'
      // }
      // if (uniqueId == 'user_id_adv_4') {
      //   updatedKey = 'user_id'
      // }
      if (uniqueId == 'name_adv_1') {
        updatedKey = 'name_exact'
      }
      if (uniqueId == 'status_id_adv_2') {
        updatedKey = 'status'
      }
      if (uniqueId == 'tag_adv_3') {
        updatedKey = 'tag'
      }

      setState(prevState => ({
        ...prevState,
        advFilters: {
          ...prevState.advFilters,
          [updatedKey]: updatedList
        }
      }))
      addFiltersToUrl(updatedKey, updatedList)
      const advFilters = state.advFilters
      advFilters[updatedKey] = updatedList
      fetchAllPipelineLastExecutionForListFilter(advFilters)
    }
  }

  const resetAdvFilter = () => {

    fetchAllPipelineLastExecutionForListFilter(resetFilterData)
    addFiltersToUrl('all_delete')
    setState(prevState => ({
      ...prevState,
      moreAdvFilterList: defaultFilters,
      advFilters: resetFilterData,
      resetCount: prevState.resetCount + 1
    }))

    // will call here normal function
  }

  const addFiltersToUrl = (filterName, filterValue) => {

    let urlSearchParams = new URLSearchParams(location.search);
    const allFilters = [
      "Name",
      "Status",
      "Tag"
    ]

    if (filterName == 'all_delete') {
      urlSearchParams = ''
    } else {
      if (filterName == 'adv_search') {
        allFilters.forEach(value => {
          if (!filterValue.includes(value)) {
            urlSearchParams.delete(value)
          }
        })
      }

      if (filterValue?.length == 0) {
        urlSearchParams.delete(filterName)
      }
      else {
        if (urlSearchParams.has(filterName)) {
          urlSearchParams.set(filterName, filterValue.join(','));
        } else {
          urlSearchParams.append(filterName, filterValue.join(','));
        }
      }
    }

    history.replace({ pathname: location.pathname, search: urlSearchParams.toString() });
  }

  function filterDataParseForPipelineName(data) {
    console.log(data, 'bcdhbhsdbchsbdbc')
    const updatedList = data?.map(item => {
      return { 'label': item.name, 'value': item.name, ...item }
    })

    return updatedList
  }

  function filterDataParseForPipelineTags(data) {
    console.log("tag working", data);
    console.log(data, 'fggvvvdfkl-dddd')
    const tags_list = [];
    data?.forEach(item => {
      console.log(item, "dhbjdbhddhb")
      item.tags.forEach((single_tag) => {
        if (!tags_list.includes(single_tag.tag)) {
          console.log("forEcaghjjj")
          tags_list.push(single_tag.tag)
        }
      })
    })
    console.log("tag_list", tags_list);
    const updatedList = tags_list?.map(item => {
      return { 'label': item, 'value': item, ...item }
    })

    return updatedList


  }

  const advanceFilterJson = {
    'name': {
      staticList: false,
      labelName: 'Name',
      uniqueId: 'name_adv_1',
      searchVariable: 'name_exact',
      placeholder_name: "application_id",
      placeholder_value: application_id,
      getFetchUrl: properties.api.application_all_pipelines_last_execution_list,
      searchUrl: properties.api.application_all_pipelines_last_execution,
      filterDataPraseFunction: filterDataParseForPipelineName
    },
    'tag': {
      staticList: false,
      labelName: 'Tag',
      uniqueId: 'tag_adv_3',
      searchVariable: 'tag',
      placeholder_name: "application_id",
      placeholder_value: application_id,
      getFetchUrl: properties.api.application_all_pipelines_last_execution_list,
      searchUrl: properties.api.application_all_pipelines_last_execution,
      filterDataPraseFunction: filterDataParseForPipelineTags,
      showMoreNotRequired: true
    },
    'status': {
      staticList: [
        { label: "Success", value: "SUCCESS" },
        { label: "Failed", value: "FAILED" },
        { label: "Running", value: "RUNNING" },
        { label: "In Queue", value: "IN_QUEUE" },
        { label: "Revoked", value: "REVOKED" }
      ],
      uniqueId: 'status_id_adv_2',
      labelName: 'Status',
      searchVariable: null,
      getFetchUrl: null,
      filterDataPraseFunction: null,
    }
  }

  console.log("itjindcnd", state);

  return (
    <Root>
      { !state.loaded ? <PiplineHeaderSkeleton /> : //<LoadingContainer />  
        <div className={classes.root}>
          <Grid container spacing={4}>
            <Grid item lg={5} sm={12} xl={6} xs={12} className="pd-bottom">
              <div className="head-section">
                <div className="heading-section-service">
                  <h1 className="heading-primary">
                    Pipelines Overview
                  </h1>
                  <h2 className="sub-heading-primary">
                    Displaying all associated pipelines.
                  </h2>

                </div>

              </div>
            </Grid>
            <Grid item lg={7} sm={12} xl={6} xs={12}>
              <div className="d-grid align-center justify-flexend update-search-class" style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                {/* <SearchBar
                default_filter={{ name: "name", label: "Name" }}
                varient={MULTIPLE}
                search_call_back={(data) => {
                  requestAllPipelineLastExeccutions(data);
                }}
                clear_search_callback={() => {
                  requestAllPipelineLastExeccutions();
                }}
                prev_state={clear_child_search}
                params_list={filter_list}
                clear_state_from_parent={() => { }}
              /> */}
                <div className="">
                  <button onClick={() => requestAllPipelineLastExeccutions(null, state.current)} className="btn-sq-icon-primary d-flex align-center">
                    {/* <RefreshIcon style={{ color: '#124d9b', fontSize: 18, verticalAlign: 'bottom' }} /> */}
                    <span className='ri-restart-line'></span>
                  </button>
                </div>
                {
                  is_permited ?

                    <Link
                      className="btn btn-primary font-12 font-weight-500 d-flex align-center justify-center"

                      to={"/application/" + application_id + "/pipeline/add"}><span className="ri-add-line font-18"></span> Add Pipeline
                    </Link>
                    :
                    <Tooltip title="You are not allowed to perform this action">
                      <button
                        className="btn btn-disable cursor-not-allowed"
                      >
                        Add Pipeline
                      </button>
                    </Tooltip>
                }

              </div>
            </Grid>
            {state.is_filters_applied || state.count != 0 ?
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "70% 30% ",
                  marginBottom: "20px",
                  width: '100%',
                  padding: "16px"
                }}
              >
              
                <Grid container>
                  {
                    state.moreAdvFilterList?.map(filterName => {
                      console.log(filterName, 'ftvbfd')
                      return (
                        <div key={filterName} className="ad-more-search mb-20">
                          <AdvanceSearchFilterCombo
                            reset={state.resetCount}
                            selectedCheckBoxes={state.advFilters[filterName]}
                            searchVariable={advanceFilterJson[filterName]['searchVariable']}
                            staticList={advanceFilterJson[filterName]['staticList']}
                            uniqueId={advanceFilterJson[filterName]['uniqueId']}
                            labelName={advanceFilterJson[filterName]['labelName']}
                            searchUrl={advanceFilterJson[filterName]['searchUrl']}
                            onUpdate={onUpdateHandle}
                            getFetchUrl={advanceFilterJson[filterName]['getFetchUrl']}
                            placeholder_name={advanceFilterJson[filterName]['placeholder_name']}
                            placeholder_value={advanceFilterJson[filterName]['placeholder_value']}
                            filterDataPraseFunction={advanceFilterJson[filterName]['filterDataPraseFunction']}
                            showMoreNotRequired={advanceFilterJson[filterName]['showMoreNotRequired']}
                            apiHitOnClick={true}
                          />
                        </div>
                      )
                    })
                  }
                  <div className="ad-more-search mb-20">
                    <AdvanceSearchFilterCombo
                      selectedCheckBoxes={state.moreAdvFilterList}
                      reset={state.resetCount}
                      staticList={moreFilterData}
                      autoClosedAfterSelection={true}
                      onUpdate={onUpdateHandle}
                      variant='more-button'
                      uniqueId='more-button-adv-0'
                    />
                  </div>

                  <div onClick={resetAdvFilter} className="pl-15 ml-20 d-flex align-center justify-center cursor-pointer" style={{ borderLeft: '1px solid #dedede', height: '40px' }}>
                    <label style={{ color: '#595353' }} className="font-13 cursor-pointer">Reset</label>
                  </div>
                </Grid>

                <Pagination
                  total_count={state.total_page}
                  current_page_count={state.curr_page}
                  next={state.next}
                  previous={state.previous}
                  on_previous_click={() => {
                    handlePrevPipelines(null, state.previous);
                  }}
                  on_next_click={() => {
                    handleNextPipelines(null, state.next);
                  }}
                  on_pageNumber_click={(pageNumber) => {
                    handlePagePipelines(pageNumber);
                  }}
                />
              </div>

              : null}


          </Grid>
        </div>
      }
      <RootHeading>
        {state.loaded && state.pending && !state.is_showing_pending ? (
          <NotificationBar>
            <p className="notification-text">
              You have {state.pending.length} pipelines awating for approval
              {state.waiting_to_resume
                ? ", and " + state.waiting_to_resume.length + " for resuming"
                : "."}
            </p>
            <div className="btn-section">
              <button onClick={hideNotification} className="btn-grey-outline ">
                Dismiss
              </button>
              {/* <button onClick={showPending} className="btn btn-blue">Show</button> */}
            </div>
          </NotificationBar>
        ) : state.waiting_to_resume ? (
          <NotificationBar>
            <p className="notification-text">
              You have {state.waiting_to_resume?.length} pipelines paused
            </p>
            <div className="btn-section">
              <button onClick={hideNotification} className="btn-grey-outline ">
                Dismiss
              </button>
              {/* <button onClick={showPending} className="btn btn-blue">Show</button> */}
            </div>
          </NotificationBar>
        ) : null}
      </RootHeading>
      {/* state.loaded */}
      { state.loaded ? (
        <>
          {state.pipeline_array?.length != 0 ? (
            <>
              {state.pipeline_array?.map((pipeline, key) => (
                <>
                  <PipelineSection
                    open={key == 0}
                    requestAllPipelineLastExeccutions={() => {
                      requestAllPipelineLastExeccutions(
                        null,
                        state.current
                      );
                    }}
                    pipeline_current_page={state.current}
                    current_page_no={state.curr_page}
                    application_id={application_id}
                    pipeline={{
                      ...pipeline,
                      manual_deploy_tag_select: true
                    }}
                    pipeline_instance={pipeline.last_trigger}
                    sendForApproval={sendApprovalRequest}
                    confirmForApproval={confirmForApproval}
                    requestForDelete={refreshForDelete}
                  />
                </>
              ))}
            </>
          ) : (
            <>
              {state.is_filters_applied ?
                <div
                  className="main-container-error"
                  style={{ height: "70vh" }}
                >
                  <div className="svg">
                    <div class="servicePanel">
                      <div class="blank-div">
                        <div class="blank-div-text">
                          No Pipleine found
                        </div>
                        <button
                          className="btn btn-submit"
                          onClick={() => {
                            resetAdvFilter();
                            requestAllPipelineLastExeccutions();
                          }}
                        >
                          Refresh
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                :
                <div
                  className="main-container-error"
                  style={{ height: "70vh" }}
                >
                  <div className="svg">
                    <div class="servicePanel">
                      <div class="blank-div">
                        <div class="blank-div-text">
                          You have 0 pipeline associated with this app
                        </div>
                        {
                          is_permited ?

                            <a
                              href={
                                "/application/" + application_id + "/pipeline/add"
                              }
                              className="btn btn-primary font-12 font-weight-500 d-flex align-center justify-center"
                            >
                              Create a Pipeline
                            </a>
                            :
                            <Tooltip title="You are not allowed to perform this action">
                              <button
                                className="btn btn-disable cursor-not-allowed"
                              >
                                Create a Pipeline
                              </button>
                            </Tooltip>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              }
            </>
          )}
        </>
      ) : Array.from({length: 4}).map((_,index)=>{
        return <PipelineSection skeleton={true} />
      })}
    </Root>
  );
};

PipelineCard.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export default PipelineCard;

const PipelineSection = ({skeleton,...props}) => {
  const permissions = useFetchPermissions();

  if(skeleton){
    return(
      <CardParentDiv>
      <CardHeader>
        <div className="left-content">
          <Header
            skeleton={true}
          />
        </div>

        <div className="right-content">
          <Delete
            skeleton={true}
          />
        </div>
      </CardHeader>

      <>
        <CardBody>
          <PipelineStageExectuion>
            <GenericSkeleton variant={"rect"} witdh={"100%"} height={111} />
          </PipelineStageExectuion>
        </CardBody>
        <MainDivFlex>
          <div className="d-flex align-center pt-15 pb-10" style={{gap:"30px"}}>
            <div className="d-flex f-direction-column">
              <GenericSkeleton variant={"text"} width={100} height={12}/>
              <GenericSkeleton variant={"rect"} width={"40px"} height={15} style={{borderRadius:"4px"}} />  
            </div>    
            <GenericSkeleton variant={"text"} width={90} height={25}  />      
          </div>
          <ButtonNavigatorOne>
            <GenericSkeleton variant={"circle"} width={32} height={32} count={3} rootStyle={{flexDirection:"row",gap:"2"}} />
            <GenericSkeleton variant={"rect"} width={100} height={36} style={{borderRadius:"8px",marginLeft:"8px"}} />
          </ButtonNavigatorOne>
            

            
        </MainDivFlex>
        
        
      </>
      </CardParentDiv>
    );
  }
  const application_id = props.application_id;
  const history = useHistory();
  const classes = btnStyles();
  const pipeline = props.pipeline;
  const pipeline_sse_status = props.pipeline_sse_status
    ? props.pipeline_sse_status
    : null;
  const pipeline_instance = props.pipeline_instance;
  const requestForDelete = props.requestForDelete




  const loading_sse_status = props.loading_sse_status;
  const trigger_id =
    pipeline_instance && pipeline_instance.id ? pipeline_instance.id : "";
  const requestAllPipelineLastExeccutions =
    props.requestAllPipelineLastExeccutions;
  const refreshForDelete = props.refreshForDelete;
  const [open, setOpen] = useState(props.open);
  const [runWithParameter, setRunWithParameter] = useState(false);
  const pipeline_current_page = props.pipeline_current_page;
  const current_page_no = props.current_page_no;
  const flag_for_feature_id = properties.flag_for_feature_id;
  const [openDialog, setOpenDialog] = useState(false)
  const [state, setState] = useState({
    open: false,
    errors: {},
    canRedirect: false,
    show_tag: false,
    data: {},
    error: {},
    event_source: null,
    stage_with_sse_status: {},
    jira_reference_name: []
  });

  var url = GenerateURL(
    { pipeline_id: pipeline.id },
    properties.api.pipeline_update_url,
    true
  );
  const is_edit_permited = permissions?.DELETE.includes(url);

  console.log(is_edit_permited, "ertyudfgxcv ================>")
  url = GenerateURL(
    { pipeline_id: pipeline.id },
    properties.api.trigger_pipeline,
    true
  );
  const is_trigger_permited = permissions?.POST.includes(url);

  function toggleDisplay() {
    setOpen(!open);
  }
  function handleClosePipelineTrigger() {
    setState((new_state) => ({
      ...new_state,
      pipeline_trigger: null,
      pipeline_trigger_success_msg: null,
      pipeline_trigger_failed_msg: null,
    }));
  }
  function triggerPipeline(id, data) {
    console.log(data, "data___")
    var url = GenerateURL({ pipeline_id: id }, properties.api.trigger_pipeline);
    PostData(url, data ? data : {}, handleSuccesTrigger, handleFailedTrigger);
  }
  function validateAndTrigger(id) {
    const error = {};
    var has_error = false;
    if (!state.data.feature_tag) {
      error.feature_tag = "This field is required ";
      has_error = true;
    }
    if (!has_error) {
      triggerPipeline(id, state.data);
    } else {
      setState({
        ...state,
        error: error,
      });
    }
  }
  function retryPipeline(pipleine_id, trigger_id) {
    var url = GenerateURL(
      { pipeline_id: pipleine_id, trigger_id: trigger_id },
      properties.api.retry_pipeline
    );
    var post_data = {};
    // post_data = pipeline_instance.context_param && pipeline_instance.context_param;
    PostData(url, post_data, handleRetrySuccess, handleRetryFaild);
  }
  function handleRetrySuccess(data) {
    redirectToExecution();
    requestAllPipelineLastExeccutions();
  }

  function handleRetryFaild(error) {
    setState((new_state) => ({
      ...new_state,
      error: error,
      reTriggerError: error,
      canRedirect: false,
    }));
  }

  function retriggerPipeline(pipleine_id, trigger_id) {
    var url = GenerateURL(
      { pipeline_id: pipleine_id, trigger_id: trigger_id },
      properties.api.retry_pipeline
    );
    var post_data = {};
    // post_data = pipeline_instance.context_param && pipeline_instance.context_param;

    PostData(url, post_data, handleRetrySuccess, handleRetryFaild);
  }


  const resetTriggerError = () => {
    setState(prevState => ({
      ...prevState,
      reTriggerError: null // for retry popup if pipeline has been edited
    }))
  }
  function redirectToExecution() {
    if (pipeline.last_trigger) {
      if (pipeline.last_trigger.id == pipeline_instance.id) {
        setState((new_state) => ({
          ...new_state,
          new_instance_id: pipeline.last_trigger.id,
          canRedirect: true,
          reTriggerError: null
        }));
      }
    }
  }
  function handleSuccesTrigger(data) {
    redirectToExecution();
    requestAllPipelineLastExeccutions();
    setState((new_state) => ({
      ...new_state,
      pipeline_trigger: "success",
      pipeline_trigger_success_msg: "Pipeline Triggered Successfully",
    }));
  }

  function handleFailedTrigger(error) {
    console.log("failed", error);
    setState((new_state) => ({
      ...new_state,
      pipeline_trigger: "failed",
      pipeline_trigger_failed_msg:
        error && error.detail ? error.detail : JSON.stringify(error),
    }));
  }
  const handleClose = (show_success_message) => {
    setState({
      ...state,
      errors: {},
      open: false,
      show_success_message: show_success_message == true,
      fetch_request: false,
    });
  };

  function triggerRevoke(id, id2) {
    var url_temp = GenerateURL(
      { pipeline_id: id, pipeline_instance_id: id2 },
      properties.api.revoke_pipeline
    );
    var fetchOptions = {
      endPoint: url_temp,
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };
    PostData(url_temp, {}, handleSuccessRevoke, handleFailedRevoke);
  }

  function handleSuccessRevoke(respone) {
    //    setState(new_state => ({
    //   ...new_state,

    // }));
    requestAllPipelineLastExeccutions();
  }

  function handleFailedRevoke(error) { }

  function triggerResume(id, id2) {
    var url_temp = GenerateURL(
      { pipeline_id: id, pipeline_instance_id: id2 },
      properties.api.resume_pipeline
    );
    var fetchOptions = {
      endPoint: url_temp,
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };
    PostData(url_temp, {}, handleSuccessResume, handleFailedResume);
  }

  function handleSuccessResume(response) {
    requestAllPipelineLastExeccutions();
    var success_msg = response.detail
      ? response.detail
      : JSON.stringify(response);

    setState((new_state) => ({
      ...new_state,
      success_resume_msg: success_msg,
      triggerResume: "successs",
    }));
    console.log(
      "fsdfdsakjfjka",
      state.success_resume_msg,
      state.triggerResume,
      success_msg
    );
  }
  console.log("fsdfdsakjfjka", state.success_resume_msg, state.triggerResume);

  function handleFailedResume(error) {
    var error_msg = error.detail ? error.detail : JSON.stringify(error);
    setState((new_state) => ({
      ...new_state,
      failed_resume_msg: error_msg,
      triggerResume: "failed",
    }));
  }
  const handleCloseTriggerResume = () => {
    setState({
      ...state,
      triggerResume: null,
      success_resume_msg: null,
      failed_resume_msg: null,
    });
  };

  function showFeatureTag() {
    setState((new_state) => ({
      ...new_state,
      show_tag: !new_state.show_tag,
    }));
  }
  function onChangeHandler(e) {
    var key = e.target.name;
    var value = e.target.value;
    updateKeyValue(key, value);
  }
  function updateKeyValue(key, value) {
    setState({
      ...state,
      data: {
        ...state.data,
        [key]: value,
      },
      error: {
        ...state.error,
        [key]: null,
      },
    });
  }
  const fetchJiraReferenceKeys = () => {
    var requestInfo = {
      endPoint: GenerateURL(
        { pipeline_id: pipeline.id },
        properties.api.pipeline_update_url
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };
    InvokeApi(
      requestInfo,
      handlefetchJiraReferenceKeysSuccess,
      handlefetchJiraReferenceKeysFailed
    );
    setState((new_state) => ({
      ...new_state,
      show_loading_icon: true,
    }));
  };

  function handlefetchJiraReferenceKeysSuccess(response) {

    setState((new_state) => ({
      ...new_state,
      jira_reference_keys: response.jira_references,
      jira_reference_name: response.jira_linked_issue_name_list,
      show_loading_icon: false,
    }));
  }

  function handlefetchJiraReferenceKeysFailed(error) {
    setState((new_state) => ({
      ...new_state,
      show_loading_icon: false,
      error: error,
    }));
  }
  function toggleFunction() {
    fetchJiraReferenceKeys();
    setRunWithParameter(!runWithParameter);
    handleClickOpenDialog()
  }


  const handleRuntimeParamsForV3 = () => {
    history.push(`/application/${application_id}/pipeline/${pipeline.id}/runtime-params-update`)
  }

  console.log(pipeline, 'pipeline_data_inside_card');
  const handleClickCloseDialog = () => {
    setOpenDialog(false)
  }
  const handleClickOpenDialog = () => {
    setOpenDialog(true)
  }
  console.log(state, 'error_main_jg')
  return (
    <CardParentDiv>
      <CardHeader>
        <div className="left-content">
          <Header
            application_id={application_id}
            pipeline={pipeline}
            pipeline_instance={pipeline_instance}
          />
        </div>

        <div className="right-content">
          <Delete
            display_data_name={pipeline.name}
            data={{ entity_id: pipeline.id, name: "pipeline" }}
            refresh={requestForDelete}
            api_link={GenerateURL(
              { pipeline_id: pipeline.id },
              properties.api.pipeline_get_url
            )}
            is_edit_permited={is_edit_permited}
          />
        </div>
      </CardHeader>

      <>
        <CardBody>
          <PipelineStageExectuion>
            {pipeline_instance ? (
              <>
                <div style={{ padding: "20px" }}>
                  <GeneralData
                    pipeline={pipeline}
                    pipeline_instance={pipeline_instance}
                  />
                </div>

                <div className="pipeline-list-scrollable">
                  <StageProgressDetail
                    variant='pipeline-card'
                    pipeline={pipeline}
                    pipeline_instance={pipeline_instance}
                  />
                </div>
              </>
            ) : null}
          </PipelineStageExectuion>
        </CardBody>
        <MainDivFlex>
          <CardFooter components={pipeline.components} tags={pipeline.tags} pipeline={pipeline} sendForApproval={props.sendForApproval} confirmForApproval={props.confirmForApproval} />
          <ButtonNavigatorOne>
            {is_trigger_permited && pipeline.default_run === false ? (
              <>
                <Tooltip title="Run Pipeline">
                  <span className="play-icon">
                    <IconButton
                      onClick={
                        flag_for_feature_id
                          ? showFeatureTag
                          : () => {
                            triggerPipeline(pipeline.id);
                          }
                      }
                      style={{ marginRight: 3 }}
                      className={classes.marginWithoutLeft + classes.margin}
                    >
                      <span className="flaticon-play-rounded-button"></span>
                    </IconButton>
                  </span>
                </Tooltip>
                {
                  pipeline && pipeline.last_trigger &&
                  <RetryPopup
                    pipeline={pipeline}
                    retryPipeline={retryPipeline} />
                }
                {/* {
                        pipeline && pipeline.last_trigger && <Tooltip title="Replay Pipeline">
                        <span>
                          <IconButton
                            onClick={() =>
                              retryPipeline(pipeline.id, pipeline.last_trigger.id)
                            }
                            style={{ color: "#0086ff" }}
                            className={classes.margin}
                          >
                            <ReplayIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                      } */}

              </>
            ) :
              <>
                <Tooltip title="You are not allowed to perform this action">
                  <span className="play-icon">
                    <IconButton
                      style={{ marginRight: 3 }}
                      className={classes.marginWithoutLeft + classes.margin}
                    >
                      <span className="flaticon-play-rounded-button" style={{ color: '#818078' }}></span>
                    </IconButton>
                  </span>
                </Tooltip>
                {/* {
                        pipeline && pipeline.last_trigger && <Tooltip title="Replay Pipeline">
                        <span>
                          <IconButton
                            onClick={() =>
                              retryPipeline(pipeline.id, pipeline.last_trigger.id)
                            }
                            style={{ color: "#0086ff" }}
                            className={classes.margin}
                          >
                            <ReplayIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                      } */}

              </>

            }
            {/*             
            {pipeline.last_trigger && pipeline.last_trigger.status ? (
              pipeline.last_trigger.status == "FAILED" ||
                pipeline.last_trigger.status == "PENDING_APPROVAL" ||
                (pipeline.last_trigger.status === "STOPPED" &&
                  pipeline.last_trigger.status !== "REVOKED") ? (
                <>
                  <Tooltip title="Resume Pipeline">
                    <span className="play-icon">
                      <IconButton
                        onClick={() =>
                          triggerResume(pipeline.id, pipeline_instance.id)
                        }
                        style={{ margin: 3 }}
                        className={classes.marginWithoutLeft + classes.margin}
                      >
                        <span className="flaticon--rounded-button">
                          <SkipNextIcon
                            className="color-primary"
                            style={{
                              verticalAlign: "top",
                              fontSize: 22,
                              marginTop: "-2px",
                            }}
                          />
                        </span>
                      </IconButton>
                    </span>
                  </Tooltip>
                </>
              ) : null
            ) : null}
            {edit_pipeline_permitted ? (
            ) : null} */}
            {is_edit_permited ? (
              <>
                <Tooltip title="Edit Pipeline">
                  <span>
                    <Link
                      to={
                        "/application/" +
                        application_id +
                        "/pipeline/" +
                        pipeline.id +
                        "/edit"
                      }
                    >
                      <IconButton
                        aria-label="delete"
                        className={classes.margin}
                      >
                        <EditIcon
                          fontSize="11px"
                          style={{ color: "#0086ff" }}
                        />
                      </IconButton>
                    </Link>
                  </span>
                </Tooltip>
              </>
            ) :
              <>
                <Tooltip title="You are not allowed to perform this action">
                  <span>
                    <IconButton
                      aria-label="delete"
                      className={classes.margin}
                    >
                      <EditIcon
                        fontSize="11px"
                        style={{ color: '#818078' }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              </>
            }
            {pipeline.last_trigger ? (
              pipeline.last_trigger.status == "RUNNING" ||
                pipeline.last_trigger.status == "IN_QUEUE" ||
                pipeline.last_trigger.status == "PENDING_APPROVAL" ? (
                <>
                  <Tooltip title="Revoke Pipeline">
                    <span className="play-icon">
                      <IconButton
                        onClick={() =>
                          triggerRevoke(pipeline.last_trigger.pipeline_id, pipeline_instance.id)
                        }
                        style={{ margin: 3 }}
                        className={classes.marginWithoutLeft + classes.margin}
                      >
                        <span className="flaticon--rounded-button">
                          <StopIcon
                            className="color-danger"
                            style={{
                              verticalAlign: "top",
                              fontSize: 22,
                              marginTop: "-2px",
                            }}
                          />
                        </span>
                      </IconButton>
                    </span>
                  </Tooltip>
                </>
              ) : null
            ) : null}

            <TopSummaryData>
              {pipeline_instance ? (
                <PipelineExecutionHistory
                  reTriggerError={state.reTriggerError}
                  application_id={application_id}
                  pipeline={pipeline}
                  pipeline_instance={pipeline_instance}
                  revoke_pipeline={triggerRevoke}
                  pipeline_current_page={pipeline_current_page}
                  current_page_no={current_page_no}
                  retry_pipeline={() =>
                    retryPipeline(pipeline.id, pipeline.last_trigger.id)
                  }
                  retrigger_pipeline={
                    () => {
                      retriggerPipeline(pipeline.id, pipeline.last_trigger.id)
                    }
                  }
                />
              ) : null}
            </TopSummaryData>

            {pipeline.last_trigger ? (
              pipeline.last_trigger.status == "RUNNING" ||
                pipeline.last_trigger.status == "IN_QUEUE" ||
                pipeline.last_trigger.status == "FAILED" || 
                pipeline.last_trigger.status == "REVOKED" ||
                pipeline.last_trigger.status == "SUCCESS"
                ? (
                <>
                  {/* <Tooltip title="View Logs">
                    <Link to={`/logs?global_task_id=${pipeline.last_trigger?.global_task_id}`} target='_blank'>
                    <span>
                      <IconButton
                        aria-label="delete"
                        className={classes.margin}
                      >
                        <span className="ri-file-text-line font-18" style={{color:'#0086FF',lineHeight:'1'}}></span>
                      </IconButton>
                    </span>
                    </Link>
                  </Tooltip> */}
                </>
              ) : null
            ) : null}

            {is_trigger_permited ? (
              <>
                <div>
                  <button
                    onClick={() => {
                      pipeline?.version == 'v2' ?
                        toggleFunction() :
                        handleRuntimeParamsForV3()
                    }}
                    className="btn-grey-outline btn-blue-curve"
                  // style={{ width: "130px" }}
                  >
                    Run With Parameters
                  </button>
                </div>
              </>
            ) : null}

            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={state.show_success_message}
              onClose={handleClose}
              autoHideDuration={6000}
            >
              <Alert severity="success">{"SUCCESSFULLY ADDED USER"}</Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={
                state.triggerResume === "success" ||
                state.triggerResume === "failed"
              }
              onClose={handleCloseTriggerResume}
              autoHideDuration={3000}
            >
              {state.triggerResume === "success" ? (
                <>
                  <Alert severity="success">{state.success_resume_msg}</Alert>
                </>
              ) : state.triggerResume == "failed" ? (
                <Alert severity="error">
                  <div className="error-div">{state.failed_resume_msg}</div>
                </Alert>
              ) : null}
            </Snackbar>
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={
                state.pipeline_trigger === "success" ||
                state.pipeline_trigger === "failed"
              }
              onClose={handleClosePipelineTrigger}
              autoHideDuration={3000}
            >
              {state.pipeline_trigger === "success" ? (
                <>
                  <Alert severity="success">
                    {state.pipeline_trigger_success_msg}
                  </Alert>
                </>
              ) : state.pipeline_trigger == "failed" ? (
                <Alert severity="error">
                  <div className="error-div">
                    {state.pipeline_trigger_failed_msg}
                  </div>
                </Alert>
              ) : null}
            </Snackbar>
          </ButtonNavigatorOne>
        </MainDivFlex>
        {flag_for_feature_id ? (
          <div
            className="feature_tag_div"
            style={
              state.show_tag && !runWithParameter
                ? { height: 120 }
                : { height: 0 }
            }
          >
            <Input
              type="text"
              label="Feature Id"
              name="feature_tag"
              mandatorySign
              placeholder="Enter Feature Id"
              data={state.data}
              error={state.error}
              onChangeHandler={onChangeHandler}
            />
            <button
              className="btn-grey-outline btn-blue-curve ml-auto"
              onClick={() => validateAndTrigger(pipeline.id)}
            >
              {" "}
              Run Pipeline
            </button>
          </div>
        ) : null}
        {runWithParameter ?
          <>
            {
              pipeline.version === 'v3' ? <RunWithParameterForV3
                toggleDismiss={() => {
                  setRunWithParameter(!runWithParameter);
                }}
                services={pipeline.components}
                pipeline_id={pipeline.id}
                pipeline_basic_data={pipeline}
                triggerPipeline={triggerPipeline}
              /> :

                openDialog &&
                <Dialog
                  open={openDialog}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClickCloseDialog}
                  className={classes.dialog_root + " " + " integrations-dialog run-with-params-dialog"}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                >
                  <div className={"card-header-blue"}>
                    <p>Run With Parameters</p>
                    <IconButton onClick={handleClickCloseDialog}>
                      <CloseIcon style={{ fontSize: 20, color: '#fff' }} />
                    </IconButton>
                  </div>
                  <RunWithParameter
                    required_jira_keys={state.jira_reference_keys}
                    jira_reference_name_list={state.jira_reference_name}
                    toggleDismiss={() => {
                      setRunWithParameter(!runWithParameter);
                    }}
                    services={pipeline.components}
                    pipeline_id={pipeline.id}
                    pipeline_basic_data={pipeline}
                    triggerPipeline={triggerPipeline}
                    application_id={application_id}
                  />
                </Dialog>

            }
          </>

          : null}
      </>
    </CardParentDiv>
  );
};


PipelineSection.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

// Styles

const btnStyles = makeStyles((theme) => ({
  margin: {
    margin: 3,
  },
  marginWithoutLeft: {
    marginLeft: "0px!important",
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #C5C5C5',
    fontSize: '14px',
    fontWeight: 400,
    color: '#fff',
    backgroundColor: 'rgb(18, 77, 155)',
    padding: '10px 20px'
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  borderBlue: {
    width: "32px",
    border: "2px solid rgb(0, 134, 255)",
    height: "32px",
    display: "inline-block",
    borderRadius: "50%",
  },
}));

const useStyles = makeStyles((theme) => ({
  stageData: {
    display: "flex",
    alignItems: "center",
    lineHeight: 1.5,
    "& .main-text": {
      color: "#000",
      fontSize: "13px",
      marginRight: "0px",
    },
    "& .sub-text": {
      color: "#949494",
      fontSize: "13px",
    },
    "& .status-font": {
      fontSize: "14px",
      textTransform: "uppercase",
    },
    "& .success": {
      color: "#62E187",
    },
    "& .success .flaticon-circle-with-check-symbol": {
      margin: "0px 4px",
      color: "#62E187",
    },
    "& .success .flaticon-circle-with-check-symbol:before": {
      fontSize: "14px!important",
      color: "#62E187",
    },
  },
  executionData: {
    display: "flex",
    alignItems: "center",
    lineHeight: 1,
    "& .main-text": {
      color: "#000",
      fontSize: "12px",
      marginRight: "0px",
    },
    "& .sub-text": {
      color: "#949494",
      fontSize: "12px",
    },
  },
  d_Flex: {
    display: "flex",
    alignItems: "center",
  },
}));

const Root = styled("div")({
  margin: 20,
});
const HeadingArea = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  // marginBottom: "20px",
});

const CardParentDiv = styled("div")({
  backgroundColor: "#fff",
  border: "1px solid #dedede",
  borderRadius: "16px",
  boxShadow: "rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px",
  marginBottom: "2rem",
  marginTop: "2rem",
});

const CardHeader = styled("div")({
  display: "grid",
  gridTemplateColumns: "96% 4%",
  padding: "20px",
  alignItems: "center",
  "& .left-content": {
    display: "flex",
    alignItems: "center",
    gridTemplateColumns: "60px 300px 310px 1fr",
  },
  "& .left-content  .service-name-chip-div": {
    lineHeight: 0,
  },
  "& .left-content .service-name": {
    margin: "0px",
  },
  "& .left-content .service-details .owner-name": {
    lineHeight: 1.5,
  },
  "& .left-content .service-details .owner-name .owner": {
    marginLeft: "0px",
  },
  "& .right-content ": {
    display: " flex",
    alignItems: "center",
    justifySelf: "flex-end",
    "& .flaticon-expand-arrow": {
      color: "#9e9e9e!important",
      cursor: "pointer",
    },
  },
  "& .right-content .flaticon-downwards-arrow-key": {
    color: "#9e9e9e!important",
    cursor: "pointer",
  },
  "& .right-content .flaticon-downwards-arrow-key:before": {
    fontSize: "12px",
  },
  "& .pipeline-tags-data": {
    margin: "0px 10px",
  },
});

const CardBody = styled("div")({
  "& .border-top-bottom": {},
});

const PipelineStageExectuion = styled("div")({
  backgroundColor: "#fbfbfb",
  display: "flex",
  alignItems: "center",
  gridTemplateColumns: "30% 70%",
  justifyContent: "normal",
  borderTop: "1px solid #eeeeee",
  borderBottom: "1px solid #eeeeee",
  "& .pipeline-list-scrollable": {
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  "& .border-blue": {
    border: "2px solid #0086ff!important",
    color: "#0086ff",
  },
  "& .md-stepper-horizontal": {
    backgroundColor: "#fbfbfb",
  },
  "& .md-stepper-horizontal .md-step": {
    width: "",
  },
  "& .md-stepper-horizontal .md-step.active:only-child .md-stepper-horizontal":
  {
    width: "auto",
    margin: "0px",
  },
  "& .md-step-title ": {
    fontSize: "14px",
  },
  "& .md-step-title span": {
    fontSize: "11px",
  },
  "& .button-navigator": {
    display: "flex",
    justifyContent: "flex-end",
  },
});

const ButtonNavigator = styled("div")({
  display: "flex",
  alignItems: "center",
  "& .btn-blue .flaticon-play-button-inside-a-circle": {
    color: "#fff",
    margin: "0px 5px",
  },
  "& .btn-blue .flaticon-play-button-inside-a-circle:before": {
    fontSize: "16px!important",
  },
  "& .btn-blue": {
    height: "auto",
    paddingTop: "0px",
    paddingBottom: "0px",
    paddingLeft: "5px",
  },
  "& .btn-round": {
    "&:hover": {
      backgroundColor: "#0086ff!important",
      color: "#fff!important",
    },
  },
});
const ButtonNavigatorOne = styled("div")({
  display: "flex",
  alignItems: "center",
  "& span button": {
    display: " inline-block",
    height: " 32px",
    width: "32px",
    borderRadius: " 50%",
    border: "2px solid rgb(0, 134, 255)",
  },
  "& .play-icon .flaticon-play-rounded-button": {
    marginBottom: "2px",
    marginLeft: "3px",
    marginRight: "3px",
    "&:before": {
      fontSize: "18px!important",
      lineHeight: "1.1",
    },
  },
  "& .btn-blue .flaticon-play-button-inside-a-circle": {
    color: "#fff",
    margin: "0px 5px",
  },
  "& .btn-blue .flaticon-play-button-inside-a-circle:before": {
    fontSize: "16px!important",
  },
  "& .btn-blue": {
    height: "auto",
    paddingTop: "0px",
    paddingBottom: "0px",
    paddingLeft: "5px",
  },
});

const TopSummaryData = styled("div")({
  justifySelf: "flex-end",
  "& a": {
    fontSize: "11px",
  },
  "& a:hover": {
    textDecoration: "none",
  },
  "& a:visited": {
    color: "#124d9b",
  },
});

const RootHeading = styled("div")({
  margin: '20px 0px',
});
const NotificationBar = styled("div")({
  borderRadius: "8px",
  backgroundColor: "#ff9391",
  padding: "1rem",
  display: "flex",
  alignContent: "center",
  justifyContent: "space-between",
  "& .notification-text": {
    fontSize: "12px",
    color: "#fff",
    lineHeight: "16px",
    alignSelf: "center",
  },
  "& .btn-section": {
    "& .btn-blue": {
      height: "32px",
      paddingTop: "0px",
      paddingLeft: "5px",
      paddingBottom: "0px",
      border: "none",
    },
    "& .btn-grey-outline": {
      backgroundColor: "transparent!important",
    },
  },
});

const MainDivFlex = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0px 15px 10px",
  "& .pipeline-service-data": {
    margin: "0px",
  },
});

const AddButton = styled("div")({
  backgroundImage:
    "-moz-linear-gradient( -90deg, rgb(0,150,219) 0%, rgb(20,107,236) 100%)",
  backgroundImage:
    "-webkit-linear-gradient( -90deg, rgb(0,150,219) 0%, rgb(20,107,236) 100%)",
  backgroundImage:
    "-ms-linear-gradient( -90deg, rgb(0,150,219) 0%, rgb(20,107,236) 100%)",
  boxShadow: "0px 5px 16px 0px rgba(0, 0, 0, 0.1)",
  height: "40px",
  display: "flex",
  alignItems: "center",
  fontSize: "11px",
  backgroundImage:
    "linear-gradient( -90deg, rgb(0,150,219) 0%, rgb(20,107,236) 100%)",
  padding: "5px 12px",
  color: "#fff",
  borderRadius: "24px",
  "& .btn": {
    backgroundColor: "#4fb2f1",
    color: "#fff",
    "& .flaticon-add-plus-button": {
      color: "#fff",
    },
  },
});