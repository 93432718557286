import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import MultiRow from '../../../../components/genericComponents/MultiRow';
import { Grid, Tooltip } from '@material-ui/core';
import { Input } from '../../../../components/genericComponents/Input';
import { VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';

const dropdownlist = [

  {
    id: "text",
    label: "Text box"
  },
  {
    id: "textarea",
    label: "Text Area"
  },

  {
    id: "toggle",
    label: "Toggle",

  },
  {

    id: 'select',
    label: 'Select'
  },
  {
    id:'password',
    label: 'Password'
  }
]
const defaultValueoption = [{
  id: "Y",
  label: "Yes"
},

{
  id: "N",
  label: "No"
}

]

const EnvVarForm = (props) => {

  console.log('p_ev',props)
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const [state, setState] = useState(prev_state ? prev_state : getEnvVarFormDefaultState());

  const commonFunctions = getCommonFunctions(state, setState, inherits);

  console.log(state,'inside-ss')
  return (
    
      <MultiRow
        // variant='reorder-version'
        RepeatableComponent={AddMoreEnvVariable}
        HeaderComponent={HeaderSectionEnv}
        prev_state={state.env_var_form}
        inherits={state.child_inherits.env_var_form} />
    
  )
}

EnvVarForm.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export default EnvVarForm;

export function getEnvVarFormDefaultState() {
  return {
    data: {
    },
    error: {},
    validations: {
    },
    child_inherits: {
      env_var_form: {
        "validateForm": () => { return { valid: true }; },
        "getState": () => { return {}; },
        "getData": () => { return {}; }
      },
    }

  }
}

function AddMoreEnvVariable(props) {
  const inherits = props.inherits;
  const prev_state = props.prev_state ? props.prev_state : null;
  const [dropdownOptions, setDropdownOptions] = useState([])
  const [state, setState] = useState(prev_state ? getAddMoreEnvVar(prev_state) : getAddMoreEnvVar());
  const commonFunctions = getCommonFunctions(state, setState, inherits)

  console.log('ps_o', prev_state)
  console.log(state,'stat_of_add')

  console.log(inherits,'inherits_of_form',props)

  useEffect(() => {

    if (state.data.select_dropdown_options) {

      const array = state.data.select_dropdown_options.split(',')

      const options = array && array.map(item => {

        const updatedString = item.trim()
        return {
          id: updatedString,

          label: updatedString

        }

      })
      setDropdownOptions(options)
    }

  }, [state.data.select_dropdown_options])

  const handleChange = (e) => {

    const value = e.target.value
    const fieldName = e.target.name
    let formData = state.data
    formData[fieldName] = value
    setState(prevState => ({
      ...prevState,
      data: formData,
      error: {
        ...prevState.error,
        [fieldName]: ""
      }
    }))
    
    let validations = state.validations

    if(fieldName ==='does_default_active'){
      if(value==='N'){
        let formData = state.data
        
        delete formData.default_value

        setState(prevState => ({...prevState, data: formData}))
      }
    }

    if ((fieldName === 'input_type' || fieldName === 'default_value')&& value != '') {
  
      validations['key'] =[VALIDATION_TYPE_REQUIRED] 
      
      if(value==='select'){
        validations['select_dropdown_options'] =[VALIDATION_TYPE_REQUIRED]    
      }

      else 
        delete validations.select_dropdown_options
    }

    if ((fieldName === 'key' || fieldName === 'default_value')&& value != '') {
      validations['input_type'] =[VALIDATION_TYPE_REQUIRED]
    }

    if (state.data.does_default_active === 'Y') {

      validations['default_value'] = [VALIDATION_TYPE_REQUIRED]
    }
     
    if (state.data.does_default_active === 'N' || state.data.does_default_active =='' || state.data.does_default_active ==null) {
      
      delete validations.default_value
     
    }

    setState(prevState=>({
      ...prevState, validations : validations
    }))

  }

  return (
    <>
      <Grid item lg={2}>
        <Input
          type="select"
          mandatorySign
          list={dropdownlist}
          onChangeHandler={handleChange}
          placeholder="Text Field"
          name='input_type'
          label="Variable Data Type"
          data={state.data}
          error={state.error}
        />
      </Grid>
      <Grid item lg={2}>
        <Input
          type="text"
          name='key'
          label="Variable Name"
          placeholder="Enter Name"
          mandatorySign
          onChangeHandler={handleChange}
          data={state.data}
          error={state.error}
        />
      </Grid>
      <Grid item lg={5}>
        <div className='row' style={state.data.input_type === 'select' ?{display:"flex",paddingTop:"27px",gap:"8px"} :{ display: 'flex' }}>
          <Grid item lg={state.data.input_type === 'select' ? 6 : 4}>
            <div className='cust-bor-r'>

              {
                state.data.input_type === 'select' ?

                  <Input
                    type="text"
                    name='select_dropdown_options'
                    label="Options"
                    placeholder="Enter Value"
                    onChangeHandler={commonFunctions.onChangeHandler}
                    paramsHeading={<span style={{ color: '#ff8969', fontWeight: '500',textWrap:"nowrap" }}>Write options seprated by commas</span>}
                    data={state.data}
                    error={state.error}
                     />

                  :

                  <Input
                    type="select"
                    mandatorySign
                    list={defaultValueoption}
                    onChangeHandler={handleChange}
                    placeholder=""
                    name="does_default_active"
                    label="Default Value"
                    data={state.data}
                    error={state.error} />
              }
            </div>
          </Grid>
          <Grid item lg={state.data.input_type === 'select' ? 6 : 8} >
            {

              state.data.does_default_active === 'Y' &&

              <div className='cust-border'>
                {
                  state.data.input_type === 'select' ?
                    <Input
                      type="select"
                      name='default_value'
                      list={dropdownOptions}
                      label="Default Value"
                      placeholder="Select Value"
                      onChangeHandler={handleChange}
                      data={state.data}
                      error={state.error}
                      
                       />

                    :

                    state.data.input_type === 'toggle' ?
                      <Input
                        type="select"
                        name='default_value'
                        list={[{ id: 'yes', label: 'Yes' }, { id: 'no', label: 'No' }]}
                        label="&nbsp;"
                        placeholder="Select Value"
                        onChangeHandler={handleChange}
                        data={state.data}
                        error={state.error}
                        style={{ borderLeft: '0' }} /> :
                        state.data.input_type === 'password' ?
                      <Input
                        type="password"
                        name='default_value'
                        label="&nbsp;"
                        placeholder="Enter Value"
                        onChangeHandler={handleChange}
                        data={state.data}
                        error={state.error}
                        style={{ borderLeft: '0' }} />
                      :
                      state.data.input_type === 'password' ?
                      <Input
                        type="password"
                        name='default_value'
                        label="&nbsp;"
                        placeholder="Enter Password"
                        onChangeHandler={handleChange}
                        data={state.data}
                        error={state.error}
                        style={{ borderLeft: '0' }} />
                      :
                      <Input
                        type="text"
                        name='default_value'
                        label="&nbsp;"
                        placeholder="Enter Value"
                        onChangeHandler={handleChange}
                        data={state.data}
                        error={state.error}
                        style={{ borderLeft: '0' }} />
                }

              </div>
            }

          </Grid>
        </div>
      </Grid>
      <Grid item lg={1}>
        <h6 style={{ color: '#828282', fontSize: '12px', fontWeight: '400' }}>Required</h6>
        <div className='wrap-del' style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
          <Input
            type="simple-checkbox"
            label=""
            name='is_required'
            data={state.data}
            error={state.error}
            onChangeHandler={commonFunctions.onChangeHandler} />
        </div>
      </Grid>
      <Grid item lg={1}>
        <h6 style={{ color: '#828282', fontSize: '12px', fontWeight: '400' }}>Overridable</h6>
        <div className='wrap-del' style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
          <Input
            type="simple-checkbox"
            label=""
            name='is_trigger_time_modify'
            data={state.data}
            error={state.error}
            onChangeHandler={commonFunctions.onChangeHandler} />
        </div>
      </Grid>
    </>

  )
}

AddMoreEnvVariable.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export function getAddMoreEnvVar(prevState) {

  if (prevState) {
    console.log('ps_0001', prevState)
    let validations = {
      key: [VALIDATION_TYPE_REQUIRED],
      input_type: [VALIDATION_TYPE_REQUIRED],
    }
    if (prevState.data) {
      if (prevState.data.does_default_active === 'Y') {
        validations['default_value'] = [VALIDATION_TYPE_REQUIRED]
      }
    }
    return { ...prevState, validations: validations }
  }

  else
    return {
      data: {
        is_required: true,
        does_default_active: 'Y'
      },
      error: {},
      validations: {
        // default_value: [VALIDATION_TYPE_REQUIRED]
      },

    }
}
const HeaderSectionEnv = () => {
  return (
    <Grid lg={11}>
      <div style={{ padding: '1.2rem 0rem 1.2rem 0rem' }}>
        Add Environment Variable (You can select multiple)
      </div>
    </Grid>
  )
}