import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

export default function SupportedRepos() {
  const classes = useStyles()
  return (

    
      <div className={classes.coderepos_wrapper}>
        <p>Select from the Supported Code Repositories</p>
        <div className='coderepos-inner'>
          <div className='coderepos-item'>
            <Link to="/repo/bitbucket/add">
              <div className='coderepos-img' >
                <img src='/images/integrations/bitbucket.png' style={{width:'auto'}} alt='..'/>
              </div>
              <p>Bitbucket</p>
            </Link>
          </div>
          <div className='coderepos-item'>
          <Link to="/repo/git/add">
            <div className='coderepos-img'>
              <img src='/images/integrations/git.png' style={{width:'auto'}} alt='..'/>
            </div>
            <p>GIT</p>
            </Link>
          </div>
          <div className='coderepos-item'>
          <Link to="/repo/github/add">
            <div className='coderepos-img'>
              <img src='/images/integrations/github.png' alt='..' />
            </div>
            <p>GitHub</p>
            </Link>
          </div>
          <div className='coderepos-item'>
          <Link to="/repo/gitlab/add">
            <div className='coderepos-img'>
              <img src='/images/integrations/gitlab.png' alt='..'/>
            </div>
            <p>GitLab</p>
            </Link>
          </div>
        </div>

      </div>

    
  )
}

const useStyles = makeStyles({
  coderepos_wrapper: {
    background: '#fff',
    maxWidth: '735px',
    margin: '0 auto',
    textAlign: 'center',
    padding: '20px 90px',
    '& > p':{
        fontWeight: 300,
    },
    '& .coderepos-inner': {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '20px',

      '& .coderepos-item': {
        width: '78px',
        fontSize: '12px',
        marginRight: '15px',
        '& a':{
            color: '#121212',
            '&:visited':{
                '& p':{
                    color: '#121212'
                }
            }
        },
        '& .coderepos-img': {
          width: '78px',
          height: '78px',
          borderRadius: '4px',
          filter: 'drop-shadow(0.877px 1.798px 13.5px rgba(0,0,0,0.05))',
          background: '#ffffff',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom:'10px',
          '& img':{
            width: '50%'
          }
        }

      }
    },



  }
});
