import React, { useState, useEffect, useRef, useMemo } from 'react';
import './style.css';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { Loading, LoadingText } from "../../utils/Loading";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import SquareAvatar from '../../../components/genericComponents/AvatarSquare';
import properties from '../../../properties/properties';
import GenerateURL from '../../../util/APIUrlProvider';
import InvokeApi from '../../../util/apiInvoker';
import { useParams, Link } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import JobListingForTemplates from '../detail/components/servicesTemplates/JobListingForTemplates';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorComponent } from '../../utils/Error';
import { Zoom } from '@material-ui/core';
import SelectWithInsideLabel from '../../../components/genericComponents/inputs/SelectWithInsideLabel';
import EnvSelectorNew from '../../../components/genericComponents/EnvSelectorNew';
import { CircularMeterListNew } from '../../../components/genericComponents/CircularMeterNew';
import ReplicationToolBarNew from './components/ReplicationToolBarNew';
import JobCardServiceCardForDynamicJob from '../detail/components/servicesTemplates/jobCard/JobCardServiceCardForDynamicJob';
import EnvAndUserGroupNew from '../../serviceRevamp/components/EnvAndUserGroupNew';
import GenericSkeleton from '../../../components/genericComponents/Skeletons/GenericSkeleton';
import CICDSection from './CICDSection';
import MonitoringSection from './MonitoringSection';


const ServiceCard = (props) => {
    const service_data = props.service_data;
    const open_by_default = props.open_by_default
    const refresh = props.refresh ? props.refresh : () => { };
    const pinned = props.pinned ? props.pinned : false;
    const pinEnabled = props.pinEnabled ? props.pinEnabled : false;
    var environments = {};
    var lowestEnv = 5;
    service_data.component_env.forEach(component_env_one => {
        const master_env_id = component_env_one.project_env.environment_master;
        lowestEnv = master_env_id < lowestEnv ? master_env_id : lowestEnv
        environments[master_env_id] = environments[master_env_id] ? environments[master_env_id] : {}
        environments[master_env_id].envs = environments[master_env_id].envs ? environments[master_env_id].envs : []
        environments[master_env_id].envs = [...environments[master_env_id].envs, component_env_one];
    }
    );

    console.log(environments, "environments =========>")
    const [state, setState] = useState({ sectionOpen: open_by_default, selected_env_id: lowestEnv });

    const all_envs = Object.keys(environments);
    const toggleSectionOpen = () => {
        setState({
            ...state,
            sectionOpen: !state.sectionOpen
        })
    }
    function onEnvUpdate(env_id) {
        setState((new_state) => ({
            ...new_state,
            selected_env_id: env_id
        }))
    }

    function pinService(service_id) {
        let pinnedServices = localStorage.getItem('pinned_services');
        if (!pinnedServices) {
            pinnedServices = [];
            localStorage.setItem('pinned_services', JSON.stringify(pinnedServices));
        } else {
            pinnedServices = JSON.parse(pinnedServices);
        }

        const index = pinnedServices.indexOf(service_id);
        if (index !== -1) {
            pinnedServices.splice(index, 1);
        } else {
            pinnedServices.push(service_id);
        }

        localStorage.setItem('pinned_services', JSON.stringify(pinnedServices));
        refresh()
    }

    console.log(state.selected_env_id, service_data, 'sse-rrrr_001');
    return (

        <div className="main-container">
            <Body service_data={service_data} environments={environments} selected_env_master_id={lowestEnv} update_env_callback={onEnvUpdate} sendForApproval={props.sendForApproval} confirmForApproval={props.confirmForApproval} envIdForHeader={state.selected_env_id ? state.selected_env_id : service_data.selected_env_id} open_by_default={open_by_default} pinned={pinned} pinEnabled={pinEnabled} pinService={pinService} />
        </div>


    )
}

ServiceCard.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}


const Body = (props) => {
    const { application_id } = useParams();
    const service_data = props.service_data;
    const environments = props.environments;
    const classes = useStyles();
    const update_env_callback = props.update_env_callback;
    const selected_env_master_id = props.selected_env_master_id
    const envIdForHeader = props.envIdForHeader;
    const open_by_default = props.open_by_default;
    const pinned = props.pinned ? props.pinned : false;
    const pinEnabled = props.pinEnabled ? props.pinEnabled : false;
    const pinService = props.pinService ? props.pinService : () => { };
    const [showPin, setShowPin] = useState(false);
    function handleMouseEnter() {
        setShowPin(true);
    }

    function handleMouseLeave() {
        setShowPin(false);
    }
    const [state, setState] = useState({
        selected_env_master_id: selected_env_master_id,
        component_id: props.service_data ? props.service_data.id : null,
        selected_env_id: environments[selected_env_master_id] ? environments[selected_env_master_id].envs[0].id : 1,
        selectedServiceEnv: environments[selected_env_master_id] ? environments[selected_env_master_id].envs[0] : {},
        count: 0,
        selected_component_env_taborder: 1,
        job_template: null,
        configured_Jobs: {},
        jobTrigger: false,
        data: {},
        error: {},
        dynamicJobs: null
    });
    const [version, setVersion] = useState(null);
    console.log(props, "kbvdghvzhdftyc")

    // const version = useMemo(() => {
    //     if (state.job_template) {
    //         return state?.job_template?.version ? state?.job_template?.version : 'v2'
    //     }

    // }, [state.job_template])

    useEffect(() => {
        if (stateHead.sectionOpen) {
            if (state.component_id !== undefined && state.selected_env_id != undefined) {
                const placeholders = {
                    application_id: application_id,
                    service_id: state.component_id,
                    env_id: state.selected_env_id
                }

                var requestInfo = {
                    endPoint: GenerateURL(placeholders, properties.api.env_ci_cd_data_url),
                    httpMethod: "GET",
                    httpHeaders: { "Content-Type": "application/json" }
                }
                InvokeApi(requestInfo, (data) => {

                    console.log(data, 'data_001');
                    application_id != undefined && data &&
                        fetchJobTemplatesInfo(application_id, data?.project_job_template_id);
                    setState(prevState => ({
                        ...prevState,
                        env_ci_cd_data: data
                    }))

                },
                    (error) => {

                        setState(prevState => ({
                            ...prevState,
                            fetchError: error
                        }))
                    });
            }
        }


    }, [state.component_id, state.selected_env_id]);

    useEffect(() => {

        state.job_template && getConfiguredJobs(state.component_id, state.selected_env_id, state.job_template.id)
        if (state.job_template?.job_template) {
            const dynamicJobs = state.job_template?.job_template?.jobs?.filter(job => {
                if (job.is_dynamic) {
                    return job
                }
            })
            if (dynamicJobs?.length > 0) {
                setState(prevState => ({
                    ...prevState,
                    dynamicJobs: dynamicJobs
                }))
            }
        }
    }, [state.job_template])

    function fetchJobTemplatesInfo(applicationId, jobTempalteId) {
        let myEndPoint = GenerateURL({ application_id: applicationId }, properties.api.get_all_job_template + "?all=true&global=true") //+ "?all=true&global=true"

        var requestInfo = {
            endPoint: myEndPoint,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, (data) => {
            fetchCdData();
            widgetApiHit();
            let selectedTemplateData = data?.results?.find(template => template.id === jobTempalteId)


            setState(prevState => ({
                ...prevState,
                job_template: selectedTemplateData
            }))
            setVersion(selectedTemplateData.version)

        }, error => {
            setState(prevState => ({
                ...prevState.error,
                error: error
            }))
        });

    }

    var service_master_env_indexes = Object.keys(environments);
    const env_tab_list = [];
    if (service_master_env_indexes) {
        service_master_env_indexes.forEach((service_master_env_idex) => {
            const env_tab = {
                order: service_master_env_idex,
                tabName: properties.envs_map[service_master_env_idex].label,
                total_sub_env: environments[service_master_env_idex].envs.length
            }
            env_tab_list.push(env_tab);
        });
    }

    const getComponentEnvsTabForMasterEnv = () => {
        var component_env_tablist = []
        var order = 1
        var componentEnvs = environments[state.selected_env_master_id] ? environments[state.selected_env_master_id].envs : []
        componentEnvs.forEach(componentEnv => {
            const componentEnvTab = {
                order: order++,
                tabName: componentEnv.project_env.name,
            }
            component_env_tablist.push(componentEnvTab)
        })
        return component_env_tablist;
    }


    const updateSelectedMasterEnv = (selectedMasterEnvId) => {
        var component_envs = environments[selectedMasterEnvId].envs;
        let selectedServiceEnv = environments[selectedMasterEnvId].envs[0];
        console.log("ijtijidw", component_envs, selectedMasterEnvId, selectedServiceEnv);
        setState((new_state) => ({
            ...new_state,
            uptime: null,
            restart: null,
            selected_env_master_id: selectedMasterEnvId,
            selected_env_id: component_envs[0].id,
            selectedServiceEnv: selectedServiceEnv,
            selected_component_env_taborder: 1
        }));
        update_env_callback(component_envs[0].id);
        fetchCdData(component_envs[0].id)
    }


    const updateComponentEnv = (componentEnvTabOrder) => {

        var component_env_id = environments[state.selected_env_master_id].envs[componentEnvTabOrder - 1].id;

        let selectedServiceEnv = environments[state.selected_env_master_id].envs[componentEnvTabOrder - 1];

        setState((new_state) => ({
            ...new_state,
            uptime: null,
            restart: null,
            selected_env_id: component_env_id,
            selectedServiceEnv: selectedServiceEnv,
            selected_component_env_taborder: componentEnvTabOrder
        }))
        update_env_callback(component_env_id);
        fetchCdData(component_env_id)
    }

    const updateCDDetails = (data) => {
        setState(new_state => ({
            ...new_state,
            uptime: data.uptime,
            restart: data.restart,
        }));
    }

    function refresh() {
        setState(new_state => ({
            ...new_state,
            count: new_state.count + 1,
        }));
    }


    const getConfiguredJobs = (service_id, env_id, component_env_id) => {

        var requestInfo = {
            endPoint: GenerateURL({ component_id: service_id, sub_env_id: env_id }, properties.api.configure_job_post_step_data),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, getConfiguredJobSuccess, getConfiguredJobFailure);
    }

    const getConfiguredJobSuccess = (data) => {

        console.log('data_c', data)
        let configureJobsObj = {}

        data && data.forEach(({ job_code, ...item }) => {
            configureJobsObj[job_code] = item
        })

        setState((prevstate) => ({
            ...prevstate,
            configured_Jobs: configureJobsObj
        }))
    }

    const getConfiguredJobFailure = (error) => {
        console.log(error, 'error_001')
    }

    function fetchCdData(selected_env_id) {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: state.component_id, component_env_id: selected_env_id ? selected_env_id : state.selected_env_id }, properties.api.save_cd),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, onFetchCdDataSuccess, onFetchCdDataFail)
        setState(new_state => ({
            ...new_state,
            cd_data_loading: true
        }));
    }

    function widgetApiHit() {
        let requestInfo = {
            endPoint: GenerateURL({ service_id: state.component_id, component_env_id: state.selected_env_id }, properties.api.widget_data + "?job_code=deploy_job&widget_code=DEPLOYMENT_ANALYTICS"),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, onWidgetSuccess, onWidgetFail);
        setState(prevState => ({
            ...prevState,
            cd_data_loading: true,
        }));
    }

    function onWidgetSuccess(response) {
        setState(prevState => ({
            ...prevState,
            cd_data_loading: false,
            deployments_list: response.map(item => { return { label: item, id: item } }),
        }));
    }

    function onWidgetFail(error) {
        setState(prevState => ({
            ...prevState,
            cd_data_loading: false,
        }))
    }

    function onFetchCdDataSuccess(response) {
        widgetApiHit();
        if (response && response.length > 0) {
            var fetchApprovedCd = response.filter(item => item.status == "APPROVED")
            console.log("ddfjd", fetchApprovedCd);
            setState(new_state => ({
                ...new_state,
                cd_data_loading: false,
                env_all_cd_data: response,
                all_deployment_list: fetchApprovedCd.map(item => { return { label: item.deployment_name, id: item.id } }),
                all_deployment_list_without_widget: fetchApprovedCd.map(item => { return { label: item.deployment_name, id: item.deployment_name } }),
                data: {
                    ...new_state.data,
                    deployment_name: fetchApprovedCd[0] && fetchApprovedCd[0].id ? fetchApprovedCd[0].id : null,
                    wideget_deployment_name: fetchApprovedCd[0] && fetchApprovedCd[0].deployment_name ? fetchApprovedCd[0].deployment_name : null,
                },
                fetchError: false
            }));
        } else {
            setState(new_state => ({
                ...new_state,
                cd_data_loading: false
            }));
        }
    }

    console.log(state.all_deployment_list, "all_deployment_list__")
    const [showMonitoring, setShowMonitoring] = useState(false);
    const monitoringSectionBtn = <Tooltip title={"Service Health"}><button className='btn-sq-icon-primary d-flex align-center' onClick={() => setShowMonitoring(!showMonitoring)}><span className='ri-heart-pulse-line'></span></button></Tooltip>

    function onFetchCdDataFail(response) {
        setState(new_state => ({
            ...new_state,
            cd_response: null,
            cd_data_loading: false
        }));
    }

    function selectDeployment(deployment) {
        let selected_deployment_name = state.all_deployment_list.find(item => item.id == deployment);
        setState({
            ...state,
            data: {
                ...state.data,
                deployment_name: deployment,
                wideget_deployment_name: selected_deployment_name.label,
            },
            error: {
                ...state.error,
                deployment_name: null,
            }
        });
    }

    const deploymentSelector =
        state.all_deployment_list && state.all_deployment_list.length > 1 ?
            <div style={{ width: "214px" }}>
                <SelectWithInsideLabel list={state.all_deployment_list} onChangeHandler={selectDeployment} label={"Deployment: "} SelectedDeployment={state.data.deployment_name} optionsWidth={214} />
            </div>
            : null;

    const [stateHead, setStateHead] = useState({ sectionOpen: open_by_default });

    const all_envs = Object.keys(environments);
    const toggleSectionOpen = () => {
        if (!stateHead.sectionOpen) {

            fetchRecentAcitivy()

        }
        setStateHead({
            ...state,
            sectionOpen: !stateHead.sectionOpen
        })

    }
    const openSection = () => {
        if (state.component_id !== undefined && state.selected_env_id != undefined) {
            const placeholders = {
                application_id: application_id,
                service_id: state.component_id,
                env_id: state.selected_env_id
            }

            var requestInfo = {
                endPoint: GenerateURL(placeholders, properties.api.env_ci_cd_data_url),
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }
            InvokeApi(requestInfo, (data) => {

                console.log(data, 'data_001');
                application_id != undefined && data &&
                    fetchJobTemplatesInfo(application_id, data?.project_job_template_id)
                setState(prevState => ({
                    ...prevState,
                    env_ci_cd_data: data
                }))

            },
                (error) => {

                    setState(prevState => ({
                        ...prevState,
                        fetchError: error
                    }))
                });
        }
        setStateHead(new_state => ({
            ...new_state,
            sectionOpen: true
        }))


    }
    console.log(state.env_ci_cd_data, "dvgvgsdgsgd")
    return (
        <>
            <div className={stateHead.sectionOpen ? "main-container-head-card" : "main-container-head-card main-card-head-hover"} onMouseEnter={() => handleMouseEnter()} onMouseLeave={() => handleMouseLeave()}>
                <div className="left-content">
                    <HeadContent service_id={service_data.id} env_id={envIdForHeader} application_id={service_data.project_id} service_name={service_data.name} environments={environments} service_data={service_data} refreshState={refresh} />
                </div>

                <div className="right-content" style={{ gap: "12px" }}>
                    {pinEnabled &&
                        <div className={`pin-container mr-12 ${pinned ? 'display-inline-flex' : showPin ? 'display-inline-flex' : 'display-none'} `} onClick={() => pinService(service_data.id)}>
                            <span className={pinned ? 'ri-unpin-line font-16' : 'ri-pushpin-line font-16'}></span>
                            <span className='font-12 font-weight-600'>{pinned ? "Unpin" : "Pin"}</span>
                        </div>
                    }
                    {
                        all_envs.length > 0 && stateHead.sectionOpen ?
                            <>

                                <div style={{ width: "330px" }}>
                                    <EnvSelectorNew
                                        envTabList={env_tab_list}
                                        selectedEnvTab={state.selected_env_master_id}
                                        envChangeHandler={updateSelectedMasterEnv}
                                        componentEnvTabList={getComponentEnvsTabForMasterEnv()}
                                        selectedComponentEnv={state.selected_component_env_taborder}
                                        componentEnvChangeHandler={updateComponentEnv}
                                    />
                                </div>
                                {
                                    version === 'v3' ?
                                        <></>
                                        :
                                        <div className='sub-section'>

                                            <button class="btn-without-border d-flex align-center" style={{ justifySelf: 'flex-end' }} onClick={refresh} ><span class="ri-restart-line font-20"></span></button>
                                        </div>
                                }
                            </>
                            : null
                    }
                    <IconButton>
                        {
                            stateHead.sectionOpen ?

                                <ExpandLessIcon className='up-down-icon' style={{ fontSize: '24px', color: '#2f2f2f', lineHeight: 1, cursor: 'pointer' }} onClick={toggleSectionOpen} />
                                :
                                all_envs.length > 0 ?
                                    <ExpandMoreIcon className='up-down-icon' style={{ fontSize: '24px', color: '#2f2f2f', lineHeight: 1, cursor: 'pointer' }} onClick={openSection} /> : null
                        }
                    </IconButton>

                </div>

            </div>
            {stateHead.sectionOpen ?
                <>
                    {
                        service_master_env_indexes.length > 0 ?
                            <div className="main-container-body">

                                <div className="service-lising-detailed-card card" style={{ boxShadow: 'none', borderColor: '#ebedee' }}>
                                    <div className="details-tab">
                                        <div className=""> {/*details-label */}

                                        </div>
                                        <Snackbar
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={state.jobTrigger}
                                            onClose={() => {
                                                setState(prevState => ({ ...prevState, jobTrigger: false }))
                                            }}
                                            autoHideDuration={4000}>
                                            <Alert severity="success">
                                                {"SUCCESSFULLY JOB TRIGGERED!"}
                                            </Alert>
                                        </Snackbar>
                                        {
                                            version === 'v3' ?
                                                <JobListingForTemplates
                                                    variant='service-card'
                                                    setSnackBar={setState}
                                                    application_id={application_id}
                                                    selectedServiceEnv={state.selectedServiceEnv}
                                                    masterLabel={properties.envs[state.selected_env_master_id - 1].label}
                                                    wideget_deployment_name={state.data.wideget_deployment_name ? state.data.wideget_deployment_name : null}
                                                    showOnlyConfigured={true}
                                                    component_id={state.component_id}
                                                    sub_env_id={state.selected_env_id}
                                                    jobListing={state.job_template?.job_template?.jobs}
                                                    configuredJobs={state.configured_Jobs}
                                                    selectedJobTemplate={{ data: state.job_template }}
                                                    service_id={service_data.id}
                                                    service_data={{
                                                        ...service_data,
                                                        service_env: state.selectedServiceEnv?.project_env?.name,
                                                        type: properties.envs[state.selected_env_master_id - 1].label
                                                    }}
                                                    env_id={state.selected_env_id}
                                                    version='v3'
                                                />

                                                :
                                                // version === 'v2'
                                                version === 'v2' ?
                                                    <>
                                                        {
                                                            state.fetchError ?
                                                                <div style={{ padding: '12px' }}>
                                                                    <ErrorComponent error={state.fetchError} />
                                                                </div>

                                                                :
                                                                <div className="deatils-of bg-white pb-10"> {/**/}
                                                                    {

                                                                        <>
                                                                            <CICDSection
                                                                                dynamicJobs={state.dynamicJobs}
                                                                                selectedTemplate={state.job_template}
                                                                                refresh={refresh}
                                                                                env_ci_cd_data={state.env_ci_cd_data}
                                                                                count={state.count}
                                                                                selectedServiceEnv={state.selectedServiceEnv}
                                                                                application_id={application_id}
                                                                                service_data={{
                                                                                    ...service_data,
                                                                                    type: properties.envs[state.selected_env_master_id - 1].label
                                                                                }}

                                                                                masterLabel={properties.envs[state.selected_env_master_id - 1].label}
                                                                                service_id={service_data.id}
                                                                                env_id={state.selected_env_id}
                                                                                deploy_id={state.data.deployment_name ? state.data.deployment_name : null}
                                                                                wideget_deployment_name={state.data.wideget_deployment_name ? state.data.wideget_deployment_name : null}
                                                                                uptime={state.uptime}
                                                                                restart={state.restart}
                                                                                all_available_deployments_without_widget={state.all_deployment_list && state.all_deployment_list.length > 0 ? state.all_deployment_list : []}
                                                                                all_available_deployments={state.deployments_list && state.deployments_list.length > 0 ? state.deployments_list : []}
                                                                                env_list={getComponentEnvsTabForMasterEnv()}
                                                                                deploymentSelector={deploymentSelector}
                                                                                confirmForApproval={props.confirmForApproval}
                                                                                sendForApproval={props.sendForApproval}
                                                                                monitoringSectionBtn={monitoringSectionBtn}
                                                                                configuredJobs={state.configured_Jobs}

                                                                            />
                                                                            <MonitoringSection
                                                                                deploy_id={state.data.wideget_deployment_name ? state.data.wideget_deployment_name : null}
                                                                                count={state.count}
                                                                                application_id={application_id}
                                                                                service_id={service_data.id}
                                                                                env_id={state.selected_env_id}
                                                                                callback={updateCDDetails}
                                                                                showMonitoring={showMonitoring}
                                                                                handleCloseMonitoring={() => setShowMonitoring(false)}
                                                                            />

                                                                        </>
                                                                    }
                                                                </div>
                                                        }

                                                    </> :

                                                    <div className='d-flex f-direction-column' style={{ gap: "21px", width: '100%', height: '273px', background: "#fff", padding: "10px" }}>
                                                        <GenericSkeleton variant={"rect"} width={"50%"} count={2} height={196} rootStyle={{ flexDirection: "row", gap: "10px" }} style={{ borderRadius: "6px" }} />
                                                        <div className="ml-auto" style={{ width: "fit-content" }}>
                                                            <GenericSkeleton count={6} width={36} height={36} style={{ borderRadius: "4px" }} rootStyle={{ flexDirection: "row", gap: "4px", flex: "0" }} />
                                                        </div>
                                                    </div>
                                        }
                                    </div>
                                </div>

                            </div>
                            : null
                    }
                </>
                : null}

        </>
    );
}

Body.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}


const useStyles = makeStyles((theme) => ({
    rootCard: {
        // marginLeft: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        // width : "215px",

        "& p": {
            color: "#000",
            fontSize: "13px",
        },

        '& .chip': {
            padding: '3px!important',
            display: 'inline-block'
        },
        '& .chip-yellow': {
            borderRadius: '3px',
            backgroundColor: '#ffc000',
            color: '#fff',
        },
        '& .chip-dark-grey': {
            borderRadius: '3px',
            backgroundColor: '#656565',
            color: '#fff',
        },
        '& .chip-green': {
            borderRadius: '3px',
            backgroundColor: '#69df9b',
            color: '#fff',
        },
    },
}))

const HeadContent = (props) => {
    const service_data = props.service_data;
    const service_name = props.service_name;
    const environments = props.environments;
    const service_id = props.service_id
    const all_envs = Object.keys(environments);
    const env_id = props.env_id;
    const application_id = props.application_id;
    const [openAddEnvDialog, setOpenAddEnvDialog] = useState(false);
    const [addedServiceResponse, setServiceResponse] = useState(null)
    const refreshState = props.refreshState ? props.refreshState : () => { }
    const handleOpenAddEnvDialog = (response) => {

        setServiceResponse(service_data)
        setOpenAddEnvDialog(true)
    }
    const handleCloseAddEnvDialog = () => {
        setOpenAddEnvDialog(false)
    }
    return (


        <>
            <SquareAvatar varient="double" name={service_name.toUpperCase()} />
            <div className="text-icon-section">
                <div className="service-name-card font-14" style={{ color: "#2f2f2f" }}>
                    <Link to={{
                        pathname: "/application/" + props.application_id + "/service/" + props.service_id + "/detail",
                        state: service_data
                    }}>
                        {/* {service_name.toUpperCase()} */}
                        {service_name.length > 30 ?
                            <Tooltip title={service_name.toString()} TransitionComponent={Zoom}>
                                <span className='font-14'>{service_name.substring(0, 30).toUpperCase() + "..."}</span>
                            </Tooltip>

                            :
                            service_name.toUpperCase()
                        }
                    </Link>
                </div>
                <div className="service-details mt-2">
                    <div className="owner-name">

                        {/* <HealthCheck service_id={service_id} env_id={env_id} /> */}
                        <>
                            {/* <span className="owner" style={{ marginLeft: '0rem', color: '#000' }}>Environments: &nbsp;</span> */}
                            {all_envs.length > 0 ?
                                <span>
                                    {
                                        Object.keys(environments).map((env_id) => (
                                            <span key={env_id} className="color-lite" style={{ color: '#666' }}>{environments[env_id].name} </span>
                                        ))
                                    }
                                </span> :
                                <div className="overview-no-env">
                                    <span>No Env Added</span>
                                    <button
                                        className="anchor ml-3" style={{
                                            backgroundColor: 'transparent',
                                            color: '#0086ff',
                                            border: 'none',

                                        }} onClick={handleOpenAddEnvDialog}>Add Env</button>
                                </div>
                            }
                        </>
                    </div>
                </div>
            </div>
            {
                openAddEnvDialog &&
                <EnvAndUserGroupNew
                    variant="edit_mode"
                    application_id={application_id}
                    handleClose={handleCloseAddEnvDialog}
                    open={openAddEnvDialog}
                    component_id={""}
                    componentData={addedServiceResponse}
                    refreshState={refreshState}
                />
            }

        </>

    );
}
HeadContent.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export default ServiceCard;