import { Grid } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import ReposIntegrationDialog from './ReposIntegrationDialog';
import PageHeader from '../../../../components/PageHeader';

export default function ReposHeader(props) {
    const classes = useStyles()
    const list_length = props.list_length ? props.list_length : 0;
    console.log(list_length, "sadlist_length")
    return (

        <div className={"d-flex align-center space-between mb-24"}>
            <PageHeader
                heading="Integrations: In Code Repositories"
                subHeading="Displaying overall summary of integrations with BuildPiper"
                icon="ri-ship-2-line"
                primaryButton={null}
                commonDivMargin={false}
            />
            {
                list_length != 0 ?
                    <ReposIntegrationDialog /> : null
            }
        </div>

    )
}

ReposHeader.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};

const useStyles = makeStyles({
    integraded_header: {
        padding: '0px 0px',
        //marginTop: '30px',
        fontWeight: '300',
        lineHeight: '20px',
        marginBottom: '20px',

        '& .main-heading': {
            fontSize: '24px',
            fontWeight: '300',
        },
        '& .sub-heading-txt': {
            fontSize: '11px',
            color: '#999999',
        },
        '& .add-repos-btn': {
            background: '#3e73ec',
            filter: 'drop-shadow(2px 3.464px 5px rgba(0,0,0,0.13))',
            borderRadius: '4px',
            padding: '10px 7px',
            display: 'flex',
            justifyContent: 'center',
            color: '#fff',
            textAlign: 'center',

        }
    }
});
