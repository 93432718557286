import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Tooltip, Dialog, Slide, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import { getCommonFunctions } from '../../../../serviceRevamp/add/ci_flow/SourceDetails';
import { Input } from '../../../../../components/genericComponents/Input';
import properties from '../../../../../properties/properties';
import InvokeApi, { PostData } from '../../../../../util/apiInvoker';

// import { ErrorComponent } from '../../../../utils/Error';
import GenerateURL, { GenerateSearchURL } from '../../../../../util/APIUrlProvider';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getDuration } from '../../../../../util/util';
import PlayCircleOutlineOutlinedIcon from '@material-ui/icons/PlayCircleOutlineOutlined';
import { ParameterComponent } from '../../../listing/components/HistoryPageComponents/DeployHistory';
import { Loading } from '../../../../utils/Loading';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const supportedEnvVariables = [
    { id: 1001, key: "BUILD_IMAGE_NAME", data_type: "build_detail", value_with_depth: "build_detail.repository.name" },
    { id: 1002, key: "BUILD_GIT_URL", data_type: "build_detail", value_with_depth: "git_repo.git_url" },
    { id: 1003, key: "BUILD_COMPONENT_NAME", data_type: "build_detail", value_with_depth: "component.name" },
    { id: 1004, key: "BUILD_COMPONENT_LANGUAGE", data_type: "build_detail", value_with_depth: "component.language" }
]

const OverridableVariablesDialouge = ({ envProps,
    job_name,
    handleTrigger,
    onClose,
    triggredError,
    handleViewAllBuildHistory,
    configuredJobDataForEdit, ...props }) => {

    const classes = useStyles();

    const [state, setState] = useState({

        data: {},
        error: {},
        validations: {},
        env_vars: [],
        data_loading_error: null,
        show_loading_icon: true,
        infiniteScrollInherits: {},
        curr_page: '',
        total_page: '',
        refresh_count: 1,
        activity_list: [],
        history_list: null,
        "branches": {
            // "0": {
            //     "clone_repo": {
            //         "branches": [{ id: 1, label: 'one-two' }],
            //         "loading": false,
            //         "loaded": true
            //     }
            // }
        }
    })

    useEffect(() => {

        if (envProps && envProps.show) {
            fetchModifyEnvVars(envProps)
            props && fetchHistory(props)
        }

    }, [envProps])

    // useEffect(() => {
    //     const propsData = props
    //     if(!state.history_list)
    //         propsData && 
    // }, [props, state.refresh_count]);

    const commonFunctions = getCommonFunctions(state, setState, {})

    const handleClose = (trigger) => {

        if (trigger === true) {

            let fetchedModifyVars = state.env_vars

            console.log(fetchedModifyVars, 'modildserewrewe')
            console.log(state.data, 'dddwadwwe_dssdfwerwe')
            if (fetchedModifyVars && fetchedModifyVars.length > 0) {
                let updatedArray = fetchedModifyVars && fetchedModifyVars.map((step, index) => {

                    let updatedEnvVars = step && step.environment_variables.map(envVars => {
                        if (envVars.integration) {
                            envVars?.integration_values?.forEach(integrationObj => {
                                Object.keys(integrationObj).forEach(integrationKey => {
                                    console.log(integrationKey, 'k2w2333')
                                    if (state.data[index][integrationKey]) {
                                        integrationObj[integrationKey]?.forEach(integration => {
                                            console.log(integration, 'we234423')
                                            if (integration.key === 'GIT_BRANCH') {
                                                integration['value'] = state.data[index][integrationKey]
                                                console.log(integration, 'intedgsdsdsdw323')
                                            }
                                        })
                                    }
                                    console.log(integrationObj, 'integdssd-weew')
                                })
                            })
                            return envVars
                        }
                        else
                            return envVars['key'] ? { ...envVars, value: state.data[index][envVars.key] ? state.data[index][envVars.key] : envVars.value } : { ...envVars }
                    })

                    console.log(updatedEnvVars, 'updated_env_varsdsdsd');
                    step['environment_variables'] = updatedEnvVars

                    return step
                })

                handleTrigger(updatedArray)
            }

            else
                handleTrigger([])
        }
        else {
            onClose()
        }
    }

    function fetchModifyEnvVars({ getEnvVarUrl }) {

        var requestInfo = {
            endPoint: getEnvVarUrl,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, fetchModifyEnvVarsSuccess, fetchModifyEnvVarsFailure);

        setState(new_state => ({
            ...new_state,
            data_loading: "LOADING",

        }));
    }

    function fetchModifyEnvVarsSuccess(data) {

        let apiFethcedCanModifiedVariables = data
        let formData = {}
        apiFethcedCanModifiedVariables && apiFethcedCanModifiedVariables.forEach((steps, index) => {

            steps && steps.environment_variables.forEach(envVar => {
                if (envVar.integration) {
                    envVar.integration_values?.forEach(integrationObj => {
                        Object.keys(integrationObj)?.forEach(integrationKey => {
                            const integrationDataArray = integrationObj[integrationKey]
                            integrationDataArray?.forEach(integration => {
                                if (integration.key === 'GIT_BRANCH')
                                    formData[index] = { ...formData[index], [integrationKey]: integration['value'] }
                            })
                            if (envVar.integration == 'GIT_REPO') {
                                const repo = integrationDataArray[0]?.value
                                if (repo != undefined)
                                    getGitUrl(steps?.order, integrationKey, repo)
                            }
                        })
                    })
                }
                else
                    formData[index] = { ...formData[index], [envVar['key']]: envVar['value'] }
            })

        })
        console.log(formData, 'formData_001')

        setState(prevState => ({
            ...prevState,
            data: formData,
            data_loading: "SUCCESS",
            env_vars: data
        }));
    }

    function fetchModifyEnvVarsFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            data_loading: "FAILED",
            data_loading_error: error
        }));
    }

    const handleChange = (e, order, type) => {

        console.log('ev_001', e)
        console.log(order, 'order_001')
        const { name, value } = e.target
        console.log(value, 'value')
        if (type === 'toggle') {
            let prevValue = state.data[order][name]
            setState(prevState => {
                return (
                    {
                        ...prevState,
                        data: {
                            ...prevState.data, [order]: {
                                ...prevState.data[order],
                                [name]: prevValue === 'no' ? 'yes' : 'no'
                            }
                        }
                    }
                )
            })
        } else
            setState(prevState => {

                return (
                    {
                        ...prevState,
                        data: {
                            ...prevState.data, [order]: {
                                ...prevState.data[order],
                                [name]: value
                            }
                        }
                    }
                )
            })


    }

    console.log(state, props, 'state_001');

    console.log(configuredJobDataForEdit, 'env_props_001')

    const refreshComponent = props => {
        setState(new_state => ({
            ...new_state,
            refresh_count: new_state.refresh_count + 1,
        }));
    }

    function fetchHistory(props) {

        const { component_id, sub_env_id, job_code } = props
        if (component_id != undefined && job_code != undefined && sub_env_id != undefined) {
            let get_url = GenerateURL({ component_id: component_id, sub_env_id: sub_env_id, job_code: job_code }, properties.api.build_trigger_for_job)
            var requestInfo = {
                endPoint: get_url,
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }
            InvokeApi(requestInfo, historyFetchSuccess, historyFetchFailure);

            setState(new_state => ({
                ...new_state,
                // search_data: data,
                // current: requestInfo.endPoint,
                show_loading_icon: true
            }));
        }
    }

    function historyFetchSuccess(data) {

        console.log(data, 'data_0001')
        let fetchedData = data.results ? data.results.length > 0 ? [data.results[0]] : data.results : []
        setState(new_state => ({
            ...new_state,
            history_list: fetchedData,
            history_info_status: "SUCCESS",
            show_loading_icon: false,
            count: data.count,
            next: data.next ? properties.api.baseURL + data.next : null,
            previous: data.previous ? properties.api.baseURL + data.previous : null,
            total_page: Math.ceil(Number(data.count / 10)),
            curr_page: new_state.curr_page === '' ? 1 : new_state.curr_page
        }));
    }

    function historyFetchFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            history_info_status: "SUCCESS",
            show_loading_icon: false,
            error: error,
            history_list: []
        }));
    }
    function fetchNextHistory(data, url) {

        var requestInfo = {
            endPoint: url,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, NexthistoryFetchSuccess, NexthistoryFetchFailure);
        setState(new_state => ({
            ...new_state,
            search_data: data,
            current: requestInfo.endPoint,
            show_loading_icon: true
        }));
    }

    function NexthistoryFetchSuccess(data) {
        setState(new_state => ({
            ...new_state,
            history_list: data.results,
            show_loading_icon: false,
            count: data.count,
            page_start: state.page_start + data.results.length,
            next: data.next ? properties.api.baseURL + data.next : null,
            previous: data.previous ? properties.api.baseURL + data.previous : null,
            curr_page: new_state.curr_page + 1
        }));
    }

    function NexthistoryFetchFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            history_info_status: "SUCCESS",
            show_loading_icon: false,
            error: error,
            history_list: null
        }));
    }

    function fetchPrevHistory(data, url) {

        var requestInfo = {
            endPoint: url,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, PrevhistoryFetchSuccess, PrevhistoryFetchFailure);

        setState(new_state => ({
            ...new_state,
            search_data: data,
            current: requestInfo.endPoint,
            show_loading_icon: true
        }));
    }

    function PrevhistoryFetchSuccess(data) {
        setState(new_state => ({
            ...new_state,
            history_list: data.results,
            show_loading_icon: false,
            count: data.count,
            next: data.next ? properties.api.baseURL + data.next : null,
            previous: data.previous ? properties.api.baseURL + data.previous : null,
            curr_page: new_state.curr_page - 1
        }));
    }

    function PrevhistoryFetchFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            show_loading_icon: false,
            error: error,
            history_list: []
        }));
    }

    function getClassbyActivityStatus(activity_status, type) {
        if (type == "Revoke") {
            if (activity_status && activity_status == "IN_QUEUE" || activity_status && activity_status == "RUNNING")
                return "btn btn-flaticon"
            else
                return "btn btn-disabeld-flaticon"
        } else {
            if (activity_status && activity_status == "IN_QUEUE" || activity_status && activity_status == "RUNNING")
                return "btn btn-disabeld-flaticon"
            else
                return "btn btn-flaticon"
        }
    }

    const callFunctionbyStatus = (activity_status, id) => {
        if (activity_status && activity_status == "IN_QUEUE" || activity_status && activity_status == "RUNNING")
            onRevoke(id)
    }

    function onRevoke(task_id) {

        let urlEndpoint = GenerateURL({}, properties.api.revoke_activity)

        let endPoint = GenerateSearchURL({ task_id: task_id }, urlEndpoint)

        PostData(endPoint, {},

            (response) => {
                console.log('revoke-suc')
                setState(new_state => ({
                    ...new_state,
                    show_loading_icon: false
                }));
                refreshComponent();
            },
            (error) => {
                setState(new_state => ({
                    ...new_state,
                    error: true,
                    show_loading_icon: false
                }));
            }
        )

    }

    console.log('history_ove_r', state)
    console.log('history_ove_r_001', state)

    console.log(state?.env_vars, 'def_00p_ak')

    const getBranches = (postData) => {
        return new Promise(function (resolve, reject) {

            PostData(GenerateURL({}, properties.api.git_test),
                postData,
                (data) => {
                    resolve(data)
                },
                (error) => reject(error));
        })

    }

    function getGitUrl(stepOrder, integrationKey, name) { //getting git_url and credential
        if (name && name != '') {

            let apiEndpoint = GenerateURL({}, properties.api.fetch_all_repo)

            let requestInfo = {
                endPoint: GenerateSearchURL({ name: name }, apiEndpoint),
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }

            InvokeApi(requestInfo, (response) => {
                setState(prevState => ({
                    ...prevState,
                    branches: {
                        ...prevState.branches,
                        [stepOrder]: {
                            [integrationKey]: {
                                loading: true,
                                loaded: false,
                                branches: []
                            }

                        }
                    }
                }))

                let gitData = response.results[0]
                if (gitData) {
                    const credential_id = gitData.credential_id
                    const git_url = gitData.git_url
                    let post_data = { git_url: git_url }
                    if (credential_id != undefined) {
                        post_data = { ...post_data, credential_id: credential_id }
                    }
                    let result = []
                    getBranches(post_data).then(response => {
                        console.log(response, 'resp_00p')
                        let { branches } = response
                        if (branches) {
                            if (branches?.length > 0) {
                                branches = branches.map(branch => {
                                    return { id: branch, label: branch }
                                })
                                result = branches
                                setState(prevState => ({
                                    ...prevState,
                                    branches: {
                                        ...prevState.branches,
                                        [stepOrder]: {
                                            [integrationKey]: {
                                                loading: false,
                                                loaded: true,
                                                branches: [...branches]
                                            }
                                        }
                                    }
                                }))
                            }

                        }
                    })
                        .catch(error => {
                            console.log(error, 'err_00p')
                        })

                    return result
                }

            }, (error) => {
                console.log(error, 'error_00p_lmn')
            });
        }
    }

    const isSpecificBranchesLoading = (stepOrder, integrationKey) => {
        try {
            let isLoading = state.branches[stepOrder][integrationKey]['loading']
            return isLoading
        }
        catch (e) {
            return false
        }

    }

    const getListOfBranches = (stepOrder, integrationKey) => {

        try {
            let branches = state.branches[stepOrder][integrationKey]['branches']
            return branches
        }
        catch (e) {
            return []
        }
    }

    return (
        <Dialog
            fullScreen
            className="overriadable-env"
            open={envProps && envProps.show}
            onClose={handleClose}
            TransitionComponent={Transition}
        // keepMounted
        >

            <div style={{ display: 'flex', minHeight: '50px', backgroundColor: '#124d9b', paddingLeft: '20px', paddingRight: '20px' }} className="align-center space-between">
                <div className='align-center' style={{ display: 'flex' }}>
                    <label style={{ color: '#fff' }} className='font-14'>{`Job Name : ${job_name ? job_name : 'Job'}`}</label>
                </div>
                <IconButton className='mr-1' onClick={handleClose}>
                    <CloseIcon style={{ fontSize: '20px', color: "#fff" }} />
                </IconButton>
            </div>
            <div style={{ paddingLeft: '13px', paddingRight: '13px', height: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
                <div style={{ border: '1px solid #dedede', boxShadow: '0px 2px 8px #80808066' }} className='mt-12 mb-12'>
                    <div style={{ backgroundColor: '#faf9ff', height: '55px', paddingLeft: '15px', paddingRight: '15px' }} className='d-flex align-center'>
                        <label className='font-15 mr-20 font-weight-400'>{`Recent ${job_name ? job_name : 'Job'} History`}</label>

                        <label role='button' tabIndex={0} onKeyDown={() => { }} onClick={() => handleViewAllBuildHistory()} style={{ color: '#068bdf', cursor: 'pointer' }} className='mr-20 font-13'>View All {job_name} History</label>

                    </div>
                    <div className={classes.historyCard} style={{ marginTop: '0px' }}>
                        <div>
                            <div className="header-tab-build">
                                <p style={{ fontColor: 'black', fontWeight: '500' }}>Type</p>
                                <p style={{ fontColor: 'black', fontWeight: '500' }}>Status</p>
                                <p style={{ fontColor: 'black', fontWeight: '500' }}>Duration</p>
                                <p style={{ fontColor: 'black', fontWeight: '500' }}>Date/Time</p>
                                <p style={{ fontColor: 'black', fontWeight: '500' }}>Trigger by</p>
                                <p style={{ fontColor: 'black', fontWeight: '500' }}>{`${job_name} Parameters`}</p>
                            </div>
                            <div className="history-body">
                                {state.history_list && state.history_list.length > 0 ?
                                    <>
                                        {
                                            state.history_list.map((history, key) => {

                                                console.log(history, 'hist_ory')

                                                return (

                                                    <div className="body-tab-build align-center">
                                                        <Link
                                                            className="cursor-pointer text-anchor-blue"
                                                            to={"/logs?global_task_id=" + history.global_task_id +
                                                                "&tab_id=" + history.id +
                                                                "&service_name=" + 'service_name' +
                                                                "&service_env=" + 'service_env' +
                                                                "&service_type=" + 'service_env_type' +
                                                                "&tab_name=BUILD" + "&num=" + 'history.deploy_number'
                                                            }
                                                            target="_blank"
                                                        >
                                                            {job_name} #{history.job_number}
                                                        </Link>
                                                        {/* <p><span className="cursor-pointer text-anchor-blue" onClick={() => { onLog(history.id, history.global_task_id, "Build #" + history.build_number) }}>Build#{history.build_number}</span></p> */}
                                                        <Tooltip title={history.activity_status && history.activity_status.status}>
                                                            <p className={history.activity_status && history.activity_status.status == "FAILED" ? "round-chip bg-round-red status-font" : history.activity_status && history.activity_status.status == "RUNNING" ? "round-chip bg-round-blue status-font" : history.activity_status && history.activity_status.status == "REVOKED" ? "round-chip bg-round-purple status-font" : history.activity_status && history.activity_status.status == "IN_QUEUE" ? "round-chip status-chip-info status-font" : "round-chip bg-round-green status-font"}>{(history.activity_status && history.activity_status.status) == 'IN_QUEUE' ? 'IN QUEUE' : history.activity_status && history.activity_status.status}</p>
                                                        </Tooltip>
                                                        <p>
                                                            {history.activity_status ?
                                                                history.activity_status.status === 'SUCCESS' || history.activity_status.status === 'FAILED' ?
                                                                    getDuration(new Date(history.activity_status.start_time), new Date(history.activity_status.end_time)) == 0 ? "0s" :
                                                                        getDuration(new Date(history.activity_status.start_time), new Date(history.activity_status.end_time)) :
                                                                    getDuration(new Date(history.activity_status && history.activity_status.created_at), new Date(history.activity_status.updated_at)) == 0 ? "0s" :
                                                                        getDuration(new Date(history.activity_status && history.activity_status.created_at), new Date(history.activity_status.updated_at)) :
                                                                "--"
                                                            }
                                                        </p>
                                                        <p>{moment(history.activity_status && history.activity_status.created_at ? history.activity_status.created_at : '').fromNow()}</p>
                                                        {/* <Tooltip title={history.build_tag}><p >{'artifact value'}</p></Tooltip> */}
                                                        <p>{history.trigger_by_user ? history.trigger_by_user : '-'}</p>

                                                        <div>
                                                            <ParameterComponent onlyBuildInfo={true} buildInfo={{ build_args_key_value: { ...history?.key_value_pair } }} />
                                                        </div>

                                                    </div>


                                                )
                                            })
                                        }
                                    </> : <div className="blank-div">
                                        <div className="blank-div-text">No Data Found</div>
                                    </div>}
                            </div>

                        </div>

                    </div>

                </div>
                {/* <OverrideEnvVariables overrideVariablesData={state.env_vars}/> */}

                <div style={{ boxShadow: '0px 2px 8px #80808066', border: '1px solid #dedede', marginBottom: '20px' }}>
                    <div>
                        <div style={{ backgroundColor: '#faf9ff', height: '60px', paddingLeft: '15px', paddingRight: '15px' }} className='d-flex align-center space-between border-bottom-grid'>

                            <span className='font-15 font-weight-400'>
                                Job Steps Environment Variables
                            </span>

                            <span className='font-13' style={{ color: '#716d6d' }}>
                                Provide/Update Runtime Environment Variables
                            </span>

                        </div>

                        <Grid className='pl-15 pr-15 pb-20' style={{ backgroundColor: '#ffffff', minHeight: '200px' }} container>
                            {
                                state?.env_vars?.length > 0 ?
                                    <>
                                        {
                                            state.env_vars.map((step, order) => {
                                                return (
                                                    <Grid key={order} style={{ paddingTop: '15px' }} item xs={12}>

                                                        <Grid style={{ height: '32px' }} key={order} item xs={12}>
                                                            <span className='font-13 mr-12'>Step Name</span>
                                                            <label style={{ color: 'gray' }} className='font-13'>{step.step_name ? step.step_name : step.step_code}</label>
                                                        </Grid>

                                                        <div style={{
                                                            paddingTop: '20px', paddingBottom: '15px', border: '1px solid #dedede',
                                                            backgroundColor: '#f8f8f8', padding: '10px 20px 5px 20px',
                                                            borderRadius: '5px'
                                                        }}>
                                                            {
                                                                step && step.environment_variables.map((variable, index) => {
                                                                    return (
                                                                        <Grid key={index} item xs={6}>
                                                                            {
                                                                                variable.integration ?
                                                                                    <>

                                                                                        {
                                                                                            variable.integration_values?.map(integrationObj => {

                                                                                                return (<>

                                                                                                    {
                                                                                                        Object.keys(integrationObj)?.map(integrationKey => {
                                                                                                            const integrationData = integrationObj[integrationKey]
                                                                                                            console.log(integrationData, 'integ_2332323')
                                                                                                            const gitRepoData = integrationData[0]
                                                                                                            return (
                                                                                                                <>
                                                                                                                    {integrationData?.map(integration => {
                                                                                                                        return (
                                                                                                                            <>
                                                                                                                                {
                                                                                                                                    (gitRepoData?.value != undefined &&
                                                                                                                                        integration.key === 'GIT_BRANCH') ?
                                                                                                                                        <>{
                                                                                                                                            isSpecificBranchesLoading(step.order, integrationKey) ?
                                                                                                                                                <Loading varient='light' />
                                                                                                                                                :
                                                                                                                                                <Input
                                                                                                                                                    type='select'
                                                                                                                                                    list={getListOfBranches(step.order, integrationKey)}
                                                                                                                                                    name={integrationKey}
                                                                                                                                                    label={`${integration.key} for ${integrationKey}(Integration)`}
                                                                                                                                                    placeholder=""
                                                                                                                                                    onChangeHandler={(e => handleChange(e, order))}
                                                                                                                                                    data={state.data && state.data[order] ? state.data[order] : {}}
                                                                                                                                                    error={state.error}
                                                                                                                                                />


                                                                                                                                        }</>
                                                                                                                                        : null
                                                                                                                                }

                                                                                                                            </>
                                                                                                                        )
                                                                                                                    })}
                                                                                                                </>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </>)

                                                                                            })
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    variable.input_type === 'toggle' ?
                                                                                        <Input
                                                                                            type='env-var-toggle'
                                                                                            name={variable.key}
                                                                                            label={variable.key}
                                                                                            placeholder=""
                                                                                            onChangeHandler={(e => handleChange(e, order, 'toggle'))}
                                                                                            data={state.data && state.data[order] ? state.data[order][variable.key] === 'no' ? false : true : {}}
                                                                                            error={state.error}
                                                                                        /> :
                                                                                        variable.input_type == undefined || variable.input_type === 'text' ?
                                                                                            <Input
                                                                                                type='text'
                                                                                                name={variable.key}
                                                                                                label={variable.key}
                                                                                                placeholder=""
                                                                                                onChangeHandler={(e => handleChange(e, order))}
                                                                                                data={state.data && state.data[order] ? state.data[order] : {}}
                                                                                                error={state.error}
                                                                                            />

                                                                                            :
                                                                                            <Input
                                                                                                type={variable.input_type === 'toggle' ? 'select' : variable.input_type}

                                                                                                name={variable.key}
                                                                                                list={variable.input_type === 'select' || 'toggle' ? variable.select_dropdown_options ?

                                                                                                    variable.select_dropdown_options.split(',').map(item => ({

                                                                                                        id: item.trim(), label: item.trim()
                                                                                                    }))

                                                                                                    : [{ id: 'yes', label: 'Yes' }, { id: 'no', label: 'No' }]

                                                                                                    : []
                                                                                                }
                                                                                                label={variable.key}
                                                                                                placeholder=""
                                                                                                onChangeHandler={(e => handleChange(e, order))}
                                                                                                data={state.data && state.data[order] ? state.data[order] : {}}
                                                                                                error={state.error}
                                                                                            />
                                                                            }
                                                                        </Grid>
                                                                    )
                                                                })
                                                            }

                                                        </div>

                                                    </Grid>
                                                )
                                            })
                                        }
                                    </>

                                    :

                                    <div className="d-flex align-center justify-center font-12 full-width">No Runtime Environment Variables</div>
                            }



                        </Grid>


                    </div>
                    {/* <div style={{ paddingRight: '25px', paddingLeft: '25px', overflow: 'auto', height: '300px' }}>
                    {
                        // triggredError ?
                        //     <div>
                        //         <ErrorComponent variant="error-box" error={triggredError} />
                        //     </div>
                        //     :
                        //     state.data_loading_error ?

                        //             <ErrorComponent variant="error-box" error={state.data_loading_error} />

                        //         :
                       
                    }
                </div> */}

                    <div style={{ backgroundColor: '#fff', height: '60px', borderTop: '1px solid #dedede' }} className='d-flex align-center space-between pl-15 pr-15'>

                        <button className="btn btn-outline-grey" style={{ padding: '1rem', minWidth: "108px" }} onClick={handleClose} >Cancel</button>
                        <button className="btn btn-primary-v2 d-flex align-center" style={{ padding: '0.5rem 1rem' }} onClick={() => handleClose(true)} >Trigger Build &nbsp;<PlayCircleOutlineOutlinedIcon className="font-27" /> </button>

                    </div>

                </div>
            </div>
        </Dialog>
    );
}

OverridableVariablesDialouge.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export default OverridableVariablesDialouge


const useStyles1 = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
}));
const useStyles = makeStyles((theme) => ({
    containerBox: {
        padding: '20px 15px 15px',
        '& button.btn': {
            '&:hover': {
                color: '#0096db!important'
            }
        },
        '& .search-bar': {
            border: '1px solid #ebedee',
            overflow: 'hidden',
            borderRadius: '8px',
            '& .input-component input:focus': {
                border: 'none!important'
            }
        }
    },
    historyCard: {
        backgroundColor: '#fff',
        marginTop: '25px',
        height: '125px',
        '& .header-tab-build': {
            borderBottom: '1px solid #dedede',
            gap: 8,
            fontSize: 12,
            display: 'grid',
            padding: 8,
            borderTop: '1px solid #ebedee',
            gridTemplateColumns: '10% 10% 10% 10% 20% 40%',
        },
        '& .body-tab-build': {

            gap: 8,
            fontSize: 12,
            display: 'grid',
            padding: 8,
            gridTemplateColumns: '10% 10% 10% 10% 20% 40%',
            '& p': {
                padding: '0px 3px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
            }
        },
        // '& .history-body': {
        //     overflowY: 'scroll',
        //     height: 515
        // }
    }
}));



