
import React from 'react';
import PropTypes from 'prop-types';

const VersionLogo = ({children,...props})=>{

    return (
        <div style={{ color: '#ffffff',
        textAlign: 'center',
        fontSize:'12px',
        lineHeight:'20px',
        width: '20px',
        height: '20px',          
        fontWeight: '600',
        background : `${props.version==='v2'?'#ff9000d1':'#49b7f5'}`,
        borderRadius: '4px'}}>
            {children}
        </div>
    )
}

VersionLogo.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default VersionLogo;
