import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { styled } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { getCommonFunctions } from "../views/serviceRevamp/add/ci_flow/SourceDetails";
import { Input } from "./genericComponents/Input";
import GenericSkeleton from "./genericComponents/Skeletons/GenericSkeleton";


const Pagination = ({skeleton,...props}) => {
  if(skeleton){
    return (
      <GenericSkeleton variant={"rect"} height={"42px"} style={{borderRadius: "6px"}} />
    );
  }
  console.log('props>>>', props.total_count)
  const current_page_count = props.current_page_count;
  const variant = props.variant ;
  const [state, setState] = useState({ data: { page_number: current_page_count ? current_page_count : 1 }, error: {} });
  const commonFunctions = getCommonFunctions(state, setState, {});
  const next = props.next;
  const on_next_click = props.on_next_click;
  const previous = props.previous;
  const on_previous_click = props.on_previous_click;
  const total_count = Number(props.total_count);
  //const current_count = props.current_count;
  const on_pageNumber_click = props.on_pageNumber_click;
 

  useEffect(() => {
    if (current_page_count > 0) {
      setState((new_state) => ({
        ...new_state,
        data: {
          ...new_state.data,
          page_number: Number(current_page_count),
        },
      }));
    }
  }, [current_page_count])

  const handlePageNumberInput = (event) => {
    const key = event.target.name;
    const value = event.target.value;

    setState((new_state) => ({
      ...new_state,
      data: {
        ...new_state.data,
        [key]: value,
      },
    }));
  };

  const onKeyEnter = e => {
    const current_page_count = Number(state.data.page_number);
    if (e.key === "Enter" && current_page_count > 0 && current_page_count <= total_count && current_page_count > 0){
      on_pageNumber_click(current_page_count);
    }
     
  };

  return (
    <OuterDiv>
      <button
        onClick={previous ? on_previous_click : () => { }}
        className={
          previous
            ? "btn btn-with-icon pagination-btn btn-round box_shadow"
            : "btn btn-with-icon pagination-btn btn-not-allowed btn-round cursor-not-allowed text-grey-83 box_shadow"
        }
      >
        <ChevronLeftIcon style={{ fontSize: "24px" }} />
      </button>
      <PaginationCount>
        <span>Go to page</span>
        <Input
          name="page_number"
          varient="handle-keys"
          type="number"
          placeholder=""
          data={state.data}
          error={state.error}
          onChangeHandler={handlePageNumberInput}
          onKeyPress={onKeyEnter}
        />
        <span>of  &nbsp; {total_count}</span>
      </PaginationCount>
      <button
        onClick={next ? on_next_click : () => { }}
        className={
          next
            ? "btn btn-with-icon btn-round pagination-btn box_shadow"
            : "btn btn-with-icon btn-round pagination-btn btn-not-allowed cursor-not-allowed text-grey-83 box_shadow"
        }
      >
        <ChevronRightIcon style={{ fontSize: "24px" }} />
      </button>
    </OuterDiv>
  );
};

Pagination.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export default Pagination;

const OuterDiv = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  width: "265px",
  marginLeft: "auto",
});

const PaginationCount = styled("p")({
  fontSize: "11px",
  display: "grid",
  gridTemplateColumns: "auto 50px auto",
  gap: "5px",
  alignItems: "center",
  '& .input-component': {
    margin: "0px 5px !important",

    '& input': {
      border: "none",
      padding: "6px 10px",
      textAlign: "center",
      boxShadow: "0px 2px 8px 0px rgb(0 0 0 / 4%)",
      '&:focus': {
        outline: "none !important",
        // border: "none !important",
      }
    }
  }
});
