import React from 'react';
import PropTypes from 'prop-types';
import './Chip.css'; 

const Chip = ({
    label,
    variant = 'primary',
    size = 'normal',
    avatar = null,
    icon = null,
    clickable = false,
    onClick = () => {},
    className = '',
    textPrimary = '',
}) => {
    return (
        <div
            className={`chipnew chip-${variant} chip-${size} ${clickable ? 'chip-clickable' : ''} ${className}`}
            onClick={clickable ? onClick : undefined}
        >
            {avatar && <div className="chip-avatar" style={{lineHeight:'1'}}>{avatar}</div>}
            {icon && <div className="chip-icon" style={{lineHeight:'1'}}>{icon}</div>}
            <span className={`chip-label text-uppercase ${textPrimary}`}>{label}</span>
        </div>
    );
};

Chip.propTypes = {
    label: PropTypes.string.isRequired,
    variant: PropTypes.oneOf([
        'primary', 'secondary', 'tertiary', 'success', 'failed', 
        'pending', 'resume', 'revoked', 'disconnected'
    ]),
    size: PropTypes.oneOf(['small', 'normal', 'large']),
    avatar: PropTypes.node,
    icon: PropTypes.node,
    clickable: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

export default Chip;
