import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Input } from '../../../../components/genericComponents/Input';
import Grid from '@material-ui/core/Grid';
import { getCommonFunctions, ResetComponent } from "../ci_flow/SourceDetails";
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi from "../../../../util/apiInvoker";
import properties from "../../../../properties/properties";
import MultiRow from '../../../../components/genericComponents/MultiRow';
import { VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
import EnabledComponentEmptyDir from "./EnabledComponentEmptyDir";
import EnabledComponentHostpath from "./EnabledComponentHostpath";
import { RemoveFromArray } from "../../../../util/util";
// const useStyles = makeStyles(() => ({
//   root: {
//     flexGrow: 1,
//     marginTop: '3rem'
//   }
// }));

const MountConfig = (props) => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const extraProps = props.extraProps;
  const project_env_id = extraProps.project_env_id ? extraProps.project_env_id : null;
  const type = props.type ? props.type : null;
  const [state, setState] = useState(prev_state ? prev_state : type == "sidecar" ? getSideCarMountDetailsDefaultState() : getMountDetailsDefaultState())
  console.log(prev_state, "fdsakjfnjsanfkj")
  const commonFunctions = getCommonFunctions(state, setState, inherits);
  useEffect(() => {
    setState(new_state => ({ ...new_state, ...prev_state }))
  }, [prev_state])


  return (
    <>
      {type == "sidecar" ?
        <div className="sidecar-edit-input-div">
          <div className="formTag card-add-service-head" style={{ borderTop: '1px solid #e7e7eb', borderRadius: '0px', marginBottom: '0px' }}>
            <h4 className='mainHeading'>Mount &amp;Config</h4>
          </div>
          <Grid container justify="center" style={{ backgroundColor: '#fff' }}>
            <div className='fromClass'>
              <Grid item lg={12}>
                <Grid container justify="center">
                  <Grid lg={12} direction="row" style={{ padding: '0px', }}>
                    <div className='card card-cont' style={{ margin: '0px', borderRadius: '0px', border: '0px' }}>

                    </div>

                    <div className="card">
                      <Input
                        type="switch"
                        name="define_empty_dir"
                        label="Define Empty Directory"
                        onChangeHandler={commonFunctions.toggleState}
                        data={state.data}
                        error={state.error}
                        enabledComponent={<EnabledComponentEmptyDir
                          extraProps={extraProps}
                          prev_state={state.empty_dir_childs}
                          inherits={state.child_inherits.empty_dir_childs}
                          variant="sidecars" />}
                        disabledComponent={<ResetComponent inherits={state.child_inherits.empty_dir_childs} />}
                      />
                    </div>
                    <div className="card">
                      <Input
                        type="switch"
                        name="define_hostpath"
                        label="Define Hostpath"
                        onChangeHandler={commonFunctions.toggleState}
                        data={state.data}
                        error={state.error}
                        enabledComponent={<EnabledComponentHostpath
                          extraProps={extraProps}
                          prev_state={state.host_path_childs}
                          inherits={state.child_inherits.host_path_childs}
                          variant="sidecars" />}
                        disabledComponent={<ResetComponent inherits={state.child_inherits.host_path_childs} />}
                      />
                    </div>
                    <div className="card">
                      <Input
                        type="switch"
                        name="pvc_file_path"
                        label="Bind PVCs"
                        onChangeHandler={commonFunctions.toggleState}
                        data={state.data}
                        error={state.error}
                        enabledComponent={<EnabledComponentPVC extraProps={extraProps} prev_state={state.pvc_file_childs} inherits={state.child_inherits.pvc_file_childs} />}
                        disabledComponent={<ResetComponent inherits={state.child_inherits.pvc_file_childs} />}
                      />
                    </div>

                    <div className="card">
                      <Input
                        type="switch"
                        name="entrypoint_command_args"
                        label="Do you have custom entry point (Command)"
                        onChangeHandler={commonFunctions.toggleState}
                        data={state.data}
                        error={state.error}
                        enabledComponent={<EnabledComponentCommandAndCommandArgs
                          extraProps={extraProps}
                          prev_state={state.command_and_command_args}
                          inherits={state.child_inherits.command_and_command_args}

                        />}
                        disabledComponent={<ResetComponent inherits={state.child_inherits.command_and_command_args} />}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </div> :
          <div className="ci-cd-edit-input-div">
            <div className="formTag card-add-service-head" style={{ borderTop: '1px solid #e7e7eb', borderRadius: '0px', marginBottom: '0px' }}>
              <h4 className='mainHeading'>Mount Config</h4>
            </div>
            <Grid container justify="center" style={{ backgroundColor: '#fff' }}>
              <div className='fromClass'>
                <Grid item lg={12}>
                  <Grid container justify="center">
                    <Grid lg={12} direction="row" style={{ padding: '0px', }}>
                      <div className='card card-cont' style={{ margin: '0px', borderRadius: '0px', border: '0px' }}>

                      </div>
                      <div className="card">
                        <Input
                          type="switch"
                          name="define_empty_dir"
                          label="Define Empty Directory"
                          onChangeHandler={commonFunctions.toggleState}
                          data={state.data}
                          error={state.error}
                          enabledComponent={<EnabledComponentEmptyDir extraProps={extraProps} prev_state={state.empty_dir_childs} inherits={state.child_inherits.empty_dir_childs} />}
                          disabledComponent={<ResetComponent inherits={state.child_inherits.empty_dir_childs} />}
                        />
                      </div>
                      <div className="card">
                        <Input
                          type="switch"
                          name="define_hostpath"
                          label="Define Hostpath"
                          onChangeHandler={commonFunctions.toggleState}
                          data={state.data}
                          error={state.error}
                          enabledComponent={<EnabledComponentHostpath extraProps={extraProps} prev_state={state.host_path_childs} inherits={state.child_inherits.host_path_childs} />}
                          disabledComponent={<ResetComponent inherits={state.child_inherits.host_path_childs} />}
                        />
                      </div>
                      <div className="card">
                        <Input
                          type="switch"
                          name="pvc_file_path"
                          label="Bind PVCs"
                          onChangeHandler={commonFunctions.toggleState}
                          data={state.data}
                          error={state.error}
                          enabledComponent={<EnabledComponentPVC extraProps={extraProps} prev_state={state.pvc_file_childs} inherits={state.child_inherits.pvc_file_childs} />}
                          disabledComponent={<ResetComponent inherits={state.child_inherits.pvc_file_childs} />}
                        />
                      </div>
                      <div className="card">
                        <Input
                          type="switch"
                          name="entrypoint_command"
                          label="Do you have custom entry point (Command)"
                          onChangeHandler={commonFunctions.toggleState}
                          data={state.data}
                          error={state.error}
                          enabledComponent={<EnabledComponentEntryPoint extraProps={extraProps} prev_state={state.entry_point_childs} inherits={state.child_inherits.entry_point_childs} />}
                          disabledComponent={<ResetComponent inherits={state.child_inherits.entry_point_childs} />}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </div>
      }</>
  );
};

export default MountConfig;


export function getPvcComponentDefaultState() {
  return {
    pvcs: [],
    child_inherits: {
      pvc_childs: {}
    }
  };
}


const EnabledComponentPVC = props => {
  const prev_state = props.prev_state ? props.prev_state : null
  const [state, setState] = useState(prev_state ? prev_state : getPvcComponentDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, props.inherits);

  const service_id = props.extraProps.service_id;
  const component_env_id = props.extraProps.component_env_id

  function getPvcs() {

    var requestInfo = {
      endPoint: GenerateURL({ service_id: service_id, component_env_id: component_env_id }, properties.api.pvc_list),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    // requestInfo.endPoint = requestInfo.endPoint + "?all=true";
    InvokeApi(requestInfo, fetchSuccessPVCSListing, fetchFailpvcsListing);

  }

  function fetchSuccessPVCSListing(response) {
    console.log(response, "fdsaklmkflsakl")
    var pvcs_list = []
    if (response.length > 0) {
      response.map(element => {
        pvcs_list.push({ label: element, id: element })
      });
      console.log(pvcs_list, "fdsaklmkflsakl")
      setState(new_state => ({
        ...new_state,
        pvcs: pvcs_list
      }))
    }

    console.log(state.pvcs, "fdsaklmkflsakl")
  }
  function fetchFailpvcsListing(error) {
    console.log("SADads")
  }

  function loadPvcs() {
    var pvcs = state.pvcs.length == 0 ? getPvcs() : null
  }

  useEffect(() => {
    loadPvcs()
  }, []);

  return (
    <>
      <MultiRow RepeatableComponent={PVCsFormBody} HeaderComponent={PVCsHead} prev_state={state.pvc_childs} inherits={state.child_inherits.pvc_childs} zeroRowsAllowed={true}
        repeatableComponentProps={{ pvcs: state.pvcs }} />
    </>
  )
}

const PVCsHead = props => {
  return (
    <>
      <Grid item lg={5}>
        <div className="font-12">Select Volume Name</div>
      </Grid>
      <Grid item lg={5}>
        <div className="font-12">Volume Mount path</div>
      </Grid>
    </>
  )
}
export function getPVSsBodyDefaultState() {
  return {
    data: {},
    error: {},
    validations: {
      pvc_name: [VALIDATION_TYPE_REQUIRED],
      pvc_path: [VALIDATION_TYPE_REQUIRED],
    },
  };
}
const PVCsFormBody = props => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const pvcs_list = props.extraProps.pvcs ? props.extraProps.pvcs : [];
  const [state, setState] = useState(prev_state ? prev_state : getPVSsBodyDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);



  return (
    <>
      <Grid item lg={5}>
        <Input
          type="select"
          list={pvcs_list}
          name="pvc_name"
          error={state.error}
          onChangeHandler={commonFunctions.onChangeHandler}
          data={state.data} />
      </Grid>
      <Grid item lg={5}>
        <Input
          type="text"
          placeholder="Volume Mount path"
          name="pvc_path"
          error={state.error}
          onChangeHandler={commonFunctions.onChangeHandler}
          data={state.data} />
      </Grid>
    </>
  )
}

const EnabledComponentCommandAndCommandArgs = props => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null
  const [state, setState] = useState(prev_state ? prev_state : getEnabledComponentCommandAndCommandArgsDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);

  useEffect(() => {
    console.log(state, prev_state, "lallslallal")
    setState(new_state => ({
      ...new_state,
      ...prev_state,
      validations: {
        ...new_state.validations,
        command: prev_state?.data.cmd_args ? [] : [VALIDATION_TYPE_REQUIRED],
        cmd_args: prev_state?.data.command ? [] : [VALIDATION_TYPE_REQUIRED]
      }
    }))
  }, [prev_state])

  console.log(state, prev_state, "lallslallal")
  const onChangeHandlerAndRemoveValidation = (e) => {
    var key = e.target.name;
    var value = e.target.value;
    if (key === "command" && (value != undefined && value != "" && value != null)) {
      RemoveFromArray(state.validations.cmd_args, VALIDATION_TYPE_REQUIRED)
    }
    if (key === "cmd_args") {
      RemoveFromArray(state.validations.command, VALIDATION_TYPE_REQUIRED)
    }
    commonFunctions.onChangeHandler(e)
  }
  return (
    <>
      <Grid item lg={12} className="pd-10">
        <Input
          type="password"
          name="command"
          label="Enter Command"
          placeholder="Enter Command"
          data={state.data}
          error={state.error}
          onChangeHandler={onChangeHandlerAndRemoveValidation}
        />
        <Input
          type="password"
          name="cmd_args"
          label="Enter Command Args"
          placeholder="Enter Args"
          data={state.data}
          error={state.error}
          onChangeHandler={onChangeHandlerAndRemoveValidation}
        />
      </Grid>
    </>
  )
}






const EnabledComponentEntryPoint = props => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null
  const [state, setState] = useState(prev_state ? prev_state : getEnabledComponentEntryPointDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits)

  return (
    <>
      <Grid item lg={12} className="pd-10">
        <Input
          type="password"
          name="entrypoint"
          label="Enter Your Command"
          placeholder="Enter Your Command"
          data={state.data}
          error={state.error}
          onChangeHandler={commonFunctions.onChangeHandler}
        />
      </Grid>
    </>
  )
}


export function getEnabledComponentCommandAndCommandArgsDefaultState() {
  return {
    data: {},
    error: {},
    validations: {
      command: [VALIDATION_TYPE_REQUIRED],
      cmd_args: [VALIDATION_TYPE_REQUIRED],
    }
  };
}



export function getMountDetailsDefaultState() {
  return {
    data: {},
    error: {},
    validations: {},
    child_inherits: {
      entry_point_childs: {},
      pvc_file_childs: {},
      empty_dir_childs: {},
      host_path_childs: {},
      //command_and_command_args: {},
    },
  };
}

export function getSideCarMountDetailsDefaultState() {
  return {
    data: {},
    error: {},
    validations: {},
    child_inherits: {
      //entry_point_childs: {},
      pvc_file_childs: {},
      empty_dir_childs:{},
      host_path_childs: {},
      command_and_command_args: {},
    },
  };
}


export function configDataParser(data) {
  var final_array = new Array();
  Object.keys(data).forEach(config => {
    if (config == "data" || config == "child_inherits" || config == 'show_view' || config == "count") { }
    else {
      final_array = [...final_array, {
        name: data[config].data.configMap_name,
        mount_path: data[config].data.configMap_mount_path
      }]

    }

  })
  return final_array;
}