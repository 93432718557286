import React, { useEffect, useState } from 'react';
import { Input } from '../../../components/genericComponents/Input';
import { Dialog, Slide, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Link } from 'react-router-dom';
import { getCommonFunctions } from '../../serviceRevamp/add/ci_flow/SourceDetails';
import properties from '../../../properties/properties';
import { Tooltip } from '@material-ui/core';
import GenerateURL, { GenerateSearchURL } from '../../../util/APIUrlProvider';
import InvokeApi from '../../../util/apiInvoker';
import { Loading } from '../../utils/Loading';
import { showErrorHandlerUpdated } from '../../../util/util';
import { idea } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import useFetchPermissions from '../../../components/customHooks/useFetchPermissions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SetAsDefaultDialog({ onCreateDefaultTemplate, ...props }) {
    const [open, setOpen] = useState(false);
    const inherits = props.inherits ? props.inherits : {}
    const application_id = props.application_id ? props.application_id : ""
    const classes = useStyles();
    const varient = props.varient ? props.varient : "default"
    const [state, setState] = useState({
        data_loading: "LOADING",
        job_template_listing: [],
        default_temp_label: '',
        data: {},
        error: {},
        error_msg: ''
    });




    const changeDefaultJobTemplate = props.changeDefaultJobTemplate ? props.changeDefaultJobTemplate : () => { }

    const commonFunctions = getCommonFunctions(state, setState, inherits)
    const handleClickOpen = () => {

        fetchJobTemplatesInfo();
    }
    const handleClickClose = () => {
        setOpen(false)
    }




    function fetchJobTemplatesInfo(isLoadingNeeded) {
        let myEndPoint = GenerateURL(
            {},
            properties.api.get_global_job_template + "?all=true"
        );
        var requestInfo = {
            endPoint: myEndPoint,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };

        InvokeApi(
            requestInfo,
            jobTemplatesInfoFetchSuccess,
            jobTemplatesInfoFetchFailure
        );

        isLoadingNeeded && setState((new_state) => ({
            ...new_state,
            data_loading: "LOADING",
        }));
        setOpen(true)
    }

    function jobTemplatesInfoFetchSuccess(data) {
        var filtered_list = data.results.map(item => { return { id: item.id, label: item.name, default: item.default } });
        let default_temp_label = filtered_list.find(item => item.default == true && item)
        setState((new_state) => ({
            ...new_state,
            data_loading: "SUCCESS",
            job_template_listing: filtered_list,
            default_temp_label: default_temp_label
        }));

    }
    function jobTemplatesInfoFetchFailure(error, exception) {
        var error_msg = error.error ? error.error : showErrorHandlerUpdated(error)
        setState((new_state) => ({
            ...new_state,
            data_loading: "FAILED",
            error_msg: error_msg,
        }));
    }


    const validateAndChangeDefaultJobTemplate = (id) => {
        console.log("it is the id", id)
        changeDefaultJobTemplate(id)
    }

    var url = GenerateURL({ application_id: application_id }, properties.api.get_global_job_template, true)
    const is_permitted = useFetchPermissions()?.POST.includes(url);

    // var default_job_template_url = GenerateURL({ application_id: application_id, template_id: state.data.selected_template }, properties.api.edit_template, true);
    // const is_default_template_permitted = GetAuth().permission.POST.includes(default_job_template_url);

    // console.log(default_job_template_url, "sdgfhdsfsdhfhsdf")

    // var create_default_job_template_url = GenerateURL({ application_id: application_id }, properties.api.create_default_job_template, true)
    // const is_create_default_template_permitted = GetAuth().permission.POST.includes(create_default_job_template_url);

    return (
        <div className={classes.root_dialog}>
            <div className='d-flex align-center' style={{ gap: "12px" }}>
                {/* {
                    is_create_default_template_permitted ?
                        <button
                            className='btn btn-outline-grey d-flex align-center'
                            onClick={() => onCreateDefaultTemplate()}
                        >
                            <AddIcon /> <span>Create Default Job Template</span>
                        </button>
                        :
                        <Tooltip title="You are not allowed to perform this action">
                            <button className='btn-disable cursor-not-allowed'>
                            <AddIcon /> Create Default Job Template
                            </button>
                        </Tooltip>
                } */}
                {!(varient === "jobtemplatelisting") && <button
                    className='btn btn-outline-grey-bg d-flex align-center'
                    onClick={() => onCreateDefaultTemplate()}
                >
                    <AddIcon /> <span>Create Default Global Job Template</span>
                </button>}
                {/* {
                    is_default_template_permitted ?
                        <button
                            className='btn btn-outline-grey d-flex align-center'
                            onClick={handleClickOpen}
                        >
                            <AddIcon /> <span>Set Default Job Template</span>
                        </button>
                        :

                        <button
                            className='btn btn-disable cursor-not-allowed'
                        >
                            <AddIcon /> <span>Set Default Job Template</span>
                        </button>

                } */}
                {
                    varient === "jobtemplatelisting" ? <button
                        className={classes.setDefaultjobBtn}
                        onClick={handleClickOpen}
                    >
                        <span className='ri-add-line font-24 font-weight-500'></span><span>Set Default Template</span>
                    </button> : <button
                        className='btn btn-outline-grey-bg d-flex align-center'
                        onClick={handleClickOpen}
                    >
                        <span className='ri-add-line'></span> <span>Set Default Template</span>
                    </button>
                }

                {varient === "jobtemplatelisting" ?
                    is_permitted ?
                        <Link to={"/global/job/template/add"}>
                            <button className={classes.newJobTemplate}>
                                <span className='ri-add-line font-24 font-weight-500'></span>
                                <span>Create New</span>
                            </button>
                        </Link>
                        :
                        <>
                       
                        <Tooltip title="You are not allowed to perform this action">
                            <button className={classes.newJobTemplate + " " + "cursor-not-allowed"}>
                                <span className='ri-add-line'></span>
                                <span>Create New</span>
                            </button>
                        </Tooltip>

                        </>
                        
                    :

                    is_permitted ?
                        <Link to={"/global/job/template/add"} className="btn btn-submit">Create New</Link>
                        :
                        <>
                      
                        <Tooltip title="You are not allowed to perform this action">
                            <button className="btn btn-disable cursor-not-allowed">
                                Create New
                            </button>
                        </Tooltip>
                        </>
                        
                }

            </div>
            <Dialog
                open={open}
                onClose={handleClickClose}
                aria-labelledby="max-width-dialog-title" TransitionComponent={Transition}>
                <div className={'card ' + classes.card}>
                    <div className="card-body">
                        {
                            state.data_loading === "LOADING" ?
                                <div style={{ height: '262px' }}>
                                    <Loading varient="light" />
                                </div> :
                                state.data_loading === "SUCCESS" ?
                                    <>
                                        <InfoIcon
                                            className="font-62 text-center m-auto d-block"
                                            style={{ color: '#4783dc', fontSize: '62px', marginBottom: 25 }} />
                                        <p className='font-14 font-weight-300 text-center' style={{ color: '#405660', fontSize: '62px', marginBottom: 25 }}>
                                            Are you sure you want to set another<br />
                                            global job template as default global job template
                                        </p>
                                        <div
                                            className='pd-10 font-13 font-weight-300 d-flex align-center space-between border-radius mb-20'
                                            style={{ backgroundColor: '#f4f4f4', border: '1px solid #dedede' }}>
                                            {
                                                state.default_temp_label ?
                                                    <>
                                                        <span>Current Default Template: "{
                                                            state.default_temp_label.label}"
                                                        </span> <CheckCircleIcon className="font-24" style={{ color: '#62e187' }} />
                                                    </>
                                                    : <span>No Default Template Available</span>
                                            }

                                        </div>
                                        <div className="input-select-controller">
                                            <Input
                                                label=""
                                                name="selected_template"
                                                list={state.job_template_listing && state.job_template_listing.length > 0 ?
                                                    state.job_template_listing.filter(item => {
                                                        if (!item.default)
                                                            return { id: item.id, label: item.name }
                                                    })
                                                    : []}
                                                type="select"
                                                select_default_value={'Select new default template'}
                                                data={state.data}
                                                error={state.error}
                                                onChangeHandler={commonFunctions.onChangeHandler}
                                            />
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div style={{ height: '262px' }}>
                                            <p className='font-14 font-weight-300 text-center' style={{ color: '#405660', fontSize: '62px', marginBottom: 25 }}>
                                                Unable to load job templates&nbsp;
                                                {state.error_msg}
                                            </p>
                                        </div>
                                    </>
                        }

                    </div>
                    <div className='card-footer justify-end'>
                        <button
                            className='btn btn-outline-grey d-flex align-center'
                            onClick={handleClickClose}
                        >
                            Cancel
                        </button>
                        <button
                            className='btn btn-primary'
                            onClick={() => { validateAndChangeDefaultJobTemplate(state.data.selected_template); handleClickClose(); }}
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </Dialog>

        </div>
    )
}

const useStyles = makeStyles({
    root_dialog: {
        '& .btn-outline-grey-bg': {
            height: '40px',
            border: '1px solid #9DC0EE!important',
            color: '#124D9B!important',
            borderRadius: '6px',
            backgroundColor: '#fff',
            fontWeight: '500',
            '&:hover': {
                color: '#fff!important',
                backgroundColor: '#0086FF!important'
            }
        },
        '& .aligning': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }
    },
    card: {

        '& .card-body': {
            margin: 50,
            // height: '390px',
            '& .font-62': {
                fontSize: '62px'
            },
            '& .input-select-controller': {

            }
        }
    },
    setDefaultjobBtn: {
        color: "#124D9B",
        textAlign: "center",
        
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        textTransform: "uppercase",
        display: "flex",
        height: "40px",
        padding: "8px 16px 8px 12px",
        justifyContent: "center",
        alignItems: "center",
        gap: "6px",
        borderRadius: "6px",
        border: "1px solid #9DC0EE",
        background: "#FFF",

        "&:hover": {

            background: "#0086FF",
            color: "white",
        }
    },
    newJobTemplate: {
        display: "flex",
        height: "40px",
        padding: "11px 16px 11px 12px",
        justifyContent: "center",
        alignItems: "center",
        gap: "6px",
        borderRadius: "6px",
        background: "#0086FF",
        color: "#FFF",
        textShadow: "0px 2px 1px rgba(0, 0, 0, 0.25)",
        
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        textTransform: "uppercase",
        border: "none",

        "&:hover": {
            color: "#fff",
            backgroundColor: "#03234D !important",
            borderColor: "#03234D !important"
        }
    }

});