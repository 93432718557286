import React, { useState } from "react";
import { Backdrop, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { Tooltip } from '@material-ui/core';
import RuntimeParamsForServiceDialouge from "./RuntimeParamsForServiceDialouge";
import { Input } from "../../../components/genericComponents/Input";
import { Grid } from '@material-ui/core';
import NonDependentJobStepsForm from "./NonDependentJobStepsForm";

const JobParamsFormv3 = ({ jobOrder, jobName, jobData, stageTaskPath, selectedTabOrder, parentState, setParentState, finalPostData }) => {
    const [state, setState] = useState({
        data: { components: jobData?.selected_services },
        error: {},
        runtimeParamsDialouge: false,
        serviceIdForDialouge: null
    })
    const classes = useStyles();
    console.log(parentState, jobName, 'jd_bmndssd', jobData, selectedTabOrder)

    const isDependentJob = jobData?.job_json?.is_dependent

    let allServices
    if (isDependentJob) {
        const dependent_entity = jobData?.job_json?.dependent_entity
        allServices = jobData?.job_json?.entity_resource_data[dependent_entity]
    }

    const colorTheme = isDependentJob ? '#F5FAFF' : '#FCF4FF'
    const jobColor = isDependentJob ? '#0086FF' : '#C040ED';
    //     

    //     box-shadow: 3px 4px 4px 0px #124D9B1F;

    // box-shadow: -4px -4px 4px 0px #FFFFFFCC;


    function onChangeHandlerForKeyValue(key, value) {
        console.log(key, value, 'all_00_pp')
        updateKeyValue(key, value);
    }

    function updateKeyValue(key, value) {
        setState(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                [key]: value
            },
            error: {
                ...state.error,
                [key]: null,
            }
        }))
        setParentState(prevState => ({
            ...prevState,
            stageWiseData: {
                ...prevState.stageWiseData,
                [selectedTabOrder]: {
                    ...prevState.stageWiseData[selectedTabOrder],
                    [stageTaskPath]: {
                        ...prevState.stageWiseData[selectedTabOrder][stageTaskPath],
                        selected_services: [...value]
                    }
                }
            }
        }))
    }

    const handleCloseClick = () => {
        setState(prevState => ({
            ...prevState,
            runtimeParamsDialouge: false
        }))
    }

    const handleOnChangeTextClickFun = (data) => {

        console.log('st_ikndie-------->>>ddd', data)
        setState(prevState => ({
            ...prevState,
            runtimeParamsDialouge: true,
            serviceIdForDialouge: data?.service_id
        }))
    }

    console.log(state, 'stg_pth')
    console.log(isDependentJob, 'xl_00p')

    return (
        <div className={classes.jobForm}>
            <div style={{
                height: '51px',
                border: '0px 0px 1px 0px',
                background: colorTheme,
                width: '100%'

            }} className="job-header d-flex align-center pl-15">
                <label className="job-div  capital-text mr-15">{`Job ${jobOrder}`}</label>
                <div style={{
                    background: isDependentJob ?
                        'linear-gradient(101.5deg, #EBF5FF 3.48%, #FFFFFF 98.75%)'
                        : 'linear-gradient(115.03deg, #F9EAFF 5.36%, rgba(249, 234, 255, 0) 95.26%, #FFFFFF 95.26%)',
                }} className="job-name-div d-flex">
                    <label style={{ color: jobColor }} className="capital-text font-weight-600 font-12">{jobName}</label>
                </div>
            </div>

            <div style={{ padding: '32px' }}>
                {
                    isDependentJob &&
                    <div className='pl-10 pr-10'>
                        <Input
                            type="checkbox"
                            text='Override Variables'
                            varient="checkbox-with-change-text-and-edit"
                            name="components"
                            label="Services"
                            mandatorySign
                            list={allServices && allServices.map(service => {
                                return { id: service.name, label: service.name, service_id: service.id }
                            })}
                            data={state.data}
                            error={state.error}
                            onChangeHandler={onChangeHandlerForKeyValue}
                            onTextClick={handleOnChangeTextClickFun}
                        />
                    </div>
                }
                {
                    !isDependentJob &&
                    <NonDependentJobStepsForm
                        jobData={jobData}
                        parentState={parentState}
                        setParentState={setParentState}
                        stageTaskPath={stageTaskPath}
                        selectedTabOrder={selectedTabOrder}
                        finalPostData={finalPostData} />
                }
                {
                    state.runtimeParamsDialouge &&
                    <RuntimeParamsForServiceDialouge
                        serviceIdForDialouge={state.serviceIdForDialouge}
                        open={state.runtimeParamsDialouge}
                        onClose={handleCloseClick}
                        jobName={jobName}
                        jobData={jobData}
                        parentState={parentState}
                        setParentState={setParentState}
                        stageTaskPath={stageTaskPath}
                        selectedTabOrder={selectedTabOrder}
                        finalPostData={finalPostData}
                    />
                }
            </div>
        </div>
    )
}

export default JobParamsFormv3;
const useStyles = makeStyles((theme) => ({
    jobForm: {
        width: '100%',
        '& .job-div': {
            fontSize: '14px',
            fontWeight: 600,
            color: '#2F2F2F',
        },
        '& .job-name-div': {
            height: '27px',
            padding: '6px 8px 6px 8px',
            borderRadius: '6px'

        }
    }
}));