import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../../../components/genericComponents/Input';

import { Loading } from '../../../utils/Loading';
const GroupComponentJira = ({ variable, data, error, setParentState, jobTemplateId, additionalGroupFields,additionalGroupFieldsLoading }) => {


    const onChangeHandler = (e) => {
        const key =e.target.name
        const value = e.target.value
        setParentState(prevState => ({
            ...prevState, data: { ...prevState.data, [key]: value },
            error: { ...prevState.error, [key]: null }
        }));
    }
    console.log(jobTemplateId, 'fgbbb')
    return (
        
            <div>
                {
                    additionalGroupFieldsLoading ? <Loading varient="light" />
                        :
                        <Input
                            type='select'
                            mandatorySign={variable.mandatory}
                            name={variable.id}
                            list={additionalGroupFields ? additionalGroupFields : []
                            }
                            placeholder=""
                            onChangeHandler={onChangeHandler}
                            data={data}
                            error={error}
                        />
                }
            </div>
        
    )
}

GroupComponentJira.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export default GroupComponentJira;

