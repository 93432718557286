import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Input } from '../../../../../../../components/genericComponents/Input';
import { getCommonFunctions } from '../../../../../../serviceRevamp/add/ci_flow/SourceDetails';
import { VALIDATION_TYPE_REQUIRED } from '../../../../../../../util/Validator';

const HPAForm = (props) => {
    const inherits = props.inherits ? props.inherits : {};
    const prev_state = props.prev_state ? props.prev_state : null;
    const [state, setState] = useState(prev_state ? prev_state : HpaFormDefaultState())
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    function onCPUSliderChangeHandler(e, value) {
        if (e.target.type === "text") {
            if (e.target.value <= 100) {
                commonFunctions.updateData([e.target.name], Number(e.target.value) ? Number(e.target.value) : "")
            }
        } else {
            if (value <= 100) {
                commonFunctions.updateData("cpu_threshold", Number(value) ? Number(value) : "")
            }
        }
    }

    function onRamSliderChangeHandler(e, value) {
        if (e.target.type === "text") {
            if (e.target.value <= 100) {
                commonFunctions.updateData([e.target.name], Number(e.target.value) ? Number(e.target.value) : "")
            }
        } else {
            if (value <= 100) {
                commonFunctions.updateData("ram_threshold", Number(value) ? Number(value) : "")
            }
        }
    }
    return (
        <>
            <Grid item lg={12} className="border-bottom  pd-10">
                <p className="font-12 text-grey-83 ">PODs Replication</p>
                <Grid container spacing="2">
                    <Grid item lg={6}>
                        <Input
                            type="text"
                            name="minimum_replication"
                            label="Minimum Replication"
                            min={1}
                            placeholder="1"
                            onChangeHandler={commonFunctions.onChangeHandlerNumber}
                            data={state.data}
                            error={state.error} />
                    </Grid>
                    <Grid item lg={6}>
                        <Input
                            type="text"
                            name="maximum_replication"
                            label="Maximum Replication"
                            placeholder="5"
                            onChangeHandler={commonFunctions.onChangeHandlerNumber}
                            data={state.data}
                            error={state.error} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item lg={12} className="border-bottom border-bottom-last-child pd-10" >
                <p className="font-12 text-grey-84 ">Resource Utilization</p>
                <Grid container spacing="2">
                    <Grid item lg={6} className="card-autoscaling">
                        <Input
                            type="slider"
                            name="cpu_threshold"
                            label="CPU Threshold (0-100 units)"
                            onChangeHandler={onCPUSliderChangeHandler}
                            data={state.data}
                            error={state.error}
                        />
                    </Grid>
                    <Grid item lg={6} className="card-autoscaling">
                        <Input
                            type="slider"
                            name="ram_threshold"
                            label="RAM Threshold (0-100 mb)"
                            onChangeHandler={onRamSliderChangeHandler}
                            data={state.data}
                            error={state.error}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

HPAForm.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }


export default HPAForm;
export function HpaFormDefaultState() {
    return {
        data: {
            minimum_replication: 1,
            maximum_replication: 2,
            cpu_threshold: 50,
            ram_threshold: 50
        },
        error: {},
        validations: {
            minimum_replication: [VALIDATION_TYPE_REQUIRED],
            maximum_replication: [VALIDATION_TYPE_REQUIRED],
            cpu_threshold: [VALIDATION_TYPE_REQUIRED],
            ram_threshold: [VALIDATION_TYPE_REQUIRED]
        }
    }
}
