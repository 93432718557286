import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Tooltip from '@material-ui/core/Tooltip';
import InvokeApi, { PostData } from '../../../../util/apiInvoker';
import GenerateURL, { GenerateSearchURL } from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import Pagination from '../../../../components/Pagination';
import SearchBar from '../../../../components/SearchBar';
import { Grid } from '@material-ui/core';
import CommonHorizontalTab from '../../../../components/genericComponents/CommonHorizontalTab';
import { LoadingContainerWithoutCircle } from '../../../utils/Loading';
import MoreOptionMenu from '../../../../components/genericComponents/MoreOptionMenu';
import moment from 'moment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { CopyToClipboard } from '../../../../util/util';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function HistoryDialogue(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [state, setState] = useState({
    history_status: "SUCCESS",
    showLoadingIcon: false,
    history_list:[],
    current:'',
    next:'',
    count:0,
    error: false,
    selected_tab: 1,
    logs: "",
    task_id:"",
    infiniteScrollInherits:{},
    // endPoint: GenerateURL({ service_id: props.service_id, env_id: props.env_id }, properties.api.env_build_request_url)
  })

  const moreOption = [ 
    {
      icon: <VisibilityIcon />,
      text: 'View Logs',
      onClick: onLog
    },
    {
      icon: <IconButton />,
      text: 'Revoke',
      onClick: onRevoke
    }
  ]
  const moreOptions_2 = [
    {
      icon: <VisibilityIcon />,
      text: 'View Logs',
      onClick: onLog
    }
  ]
  const tabList = [
    {
      tabName: "Build",
      order: 1
    },
    {
      tabName: "Deploy",
      order: 2
    },
    {
      tabName: "Promote",
      order: 3
    }
  ]


  const updateSelectedTab = (tab_order) => {
    if(!state.showLoadingIcon){
      setState(new_state => ({
        ...new_state,
        selected_tab: tab_order,
        history_info_status: "REQUESTED",
        showLoadingIcon: true,
        history_list:[],
        current:'',
        next:'',
        count:0
      }));
  
      fetchHistory(null, tab_order);
    }

  }
  
  if(props.childInherits){
    props.childInherits.update_history_tab=updateSelectedTab
  }
  

  function fetchHistory(data,tab_order,url) {
    if(!url){
       url = GenerateURL({ service_id: props.service_id, env_id: props.env_id }, tabDetails[tab_order].fetchUrlPath);
    }
    var requestInfo = {
      endPoint: url,
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    if (data) {
      requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
    }
    InvokeApi(requestInfo, historyFetchSuccess, historyFetchFailure);
    console.log(state)
    setState(new_state => ({
      ...new_state,
      search_data: data,
      current: requestInfo.endPoint,
      showLoadingIcon: true
    }));
  }

  function historyFetchSuccess(data) {
    console.log(data,state)
    setState(new_state => ({
      ...new_state,
      history_list: data.results,
      history_info_status: "SUCCESS",
      showLoadingIcon: false,
      count: data.count,
      next: data.next ? properties.api.baseURL+data.next :null,
      previous: data.previous ? properties.api.baseURL+data.previous : null,
    }));
  }

  function historyFetchFailure(error, exception) {
    setState(new_state => ({
      ...new_state,
      history_info_status: "SUCCESS",
      showLoadingIcon: false,
      error: error,
      history_list: []
    }));
  }

  const invokeSearch = (data) => {
    fetchHistory(data, state.selected_tab);
  }

  const handleClickOpen = () => {
    fetchHistory(null, state.selected_tab);
    setOpen(true);
    setState(new_state => ({
      ...new_state,
      history_info_status: "REQUESTED",
      showLoadingIcon: true
    }));
  };

  const handleClose = () => {
    setOpen(false);
    setEmptyState()
  };

  function setEmptyState(){
    setState(new_state=>({
      history_status: "SUCCESS",
      showLoadingIcon: false,
      history_list:[],
      current:'',
      next:'',
      count:0,
      error: false,
      selected_tab: 1,
      logs: "",
      task_id:"",
      infiniteScrollInherits:{},
    }))
  }

  function onLog(logTabId, task_id, tabName) {
    var new_tab_data={};
    setState(new_state => ({
      ...new_state,
      selected_tab_id:logTabId,
      view_logs:true,
      logMetadata:{
        ...state.logMetadata,
        ...new_tab_data,
      }
    }));
  }

  function changeTab(newTabId){
    setState(new_state=>({
      ...new_state, 
      selected_tab_id:newTabId
    }))
  }
  function closeLogs() {
    setState(new_state=>({
      ...new_state, 
      view_logs:false,
      logMetadata:{}
    }))
  }

  function closeTab(tabId){
      var response= state.infiniteScrollInherits.closeTabFunction(tabId, state.logMetadata, closeLogs)
      if(response){
        setState(new_state=>({
          ...new_state,
          logMetadata:response.new_tabs_data,
          selected_tab_id:response.selected_tab_id
        }))
      }
  }


  function onRevoke(task_id) {
    var url_temp = GenerateURL({ service_id: props.service_id, env_id: props.env_id, id: task_id }, tabDetails[state.selected_tab].revokeUrlPath);
    var fetchOptions = {
      endPoint: url_temp,
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    PostData(url_temp, {}, handleSuccessRevoke, handleFailedRevoke);
    setState(new_state => ({
      ...new_state, history_info_status: "REQUESTED",
     
    }));
  }


  function handleSuccessRevoke(respone) {
    // console.log("logs---", text);
    setState(new_state => ({
      ...new_state,
      history_info_status: "SUCCESS",
      showLoadingIcon: false
    }));
    setTimeout(() => {
      fetchHistory(null, 0,state.current)
    }, 100);
  }



  function handleFailedLogsFetch(error) {
    setState(new_state => ({
      ...new_state,
      history_info_status: "SUCCESS",
      error: true
    }));
  }

  function handleFailedRevoke(error) {
    setState(new_state => ({
      ...new_state,
      history_info_status: "SUCCESS",
      error: true
    }));
  }


  

  function getBuildbyUser(history) {
    return (
      <p>{history.build_by_pipeline ?
        <>
          <p>Pipeline: {history.build_by_pipeline.pipeline.name}</p>
          <p>Triggered by: {history.build_by_pipeline.trigger_by}</p> </>
        : <p>{history.build_by_user}</p>
      }</p>
    )

  }

  function getDeployedByUser(history) {
    return (
      <p>{history.deploy_by_pipeline ?
        <>
          <p>Pipeline: {history.deploy_by_pipeline.pipeline.name}</p>
          <p>Triggered by: {history.deploy_by_pipeline.trigger_by}</p> </>
        : <p>{history.deploy_by_user}</p>
      }</p>
    )

  }

  function getPromotedByUser(history) {
    return (
      <p>{history.promote_by_pipeline ?
        <>
          <p>Pipeline: {history.promote_by_pipeline.pipeline.name}</p>
          <p>Triggered by: {history.promote_by_pipeline.trigger_by}</p> </>
        : <p>{history.promote_by_user}</p>
      }</p>
    )

  }
  function getEnvName(service_data, env_id) {
    if (service_data.component_env) {
      for (let index = 0; index < service_data.component_env.length; index++) {
        if (env_id == service_data.component_env[index].id) {
          return service_data.component_env[index].project_env.name;
        }
      }



    }

  }

  function renderTopHeader(props) {
    if (props.env_id) {
      return <AppBar className={classes.appBar}>
        <Toolbar>
          <div className="service-data">
            <div className="service-name"><b>{props.service_data.name}</b></div>
            <div className="d-flex">
              <div className="service-env" style={{ fontSize: '11px', marginRight: '3px', letterSpacing: '1px' }}>Environment Name: <b>{props.env_name ? props.env_name : getEnvName(props.service_data, props.env_id)}</b></div>
              <div className="service-env-type" style={{ fontSize: '11px', marginRight: '3px', letterSpacing: '1px' }}>Type: <b>{props.service_data.type}</b></div>
            </div>
          </div>
          <IconButton style={{ marginLeft: 'auto' }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon style={{ fontSize: '24px' }} />
          </IconButton>
        </Toolbar>
      </AppBar>
    }
  }

  var getBuildHistoryTabContent = () => {
  
    return (
      <>
      {/* <DurationCalculator /> */}
        <div className={classes.cardheader}>
          <p>Type</p>
          <p>Status</p>
          <p>Duration</p>
          <p>Date/Time</p>
          <p>Artifact</p>
          <p>Build by</p>
          <p>Commit ID</p>
          <p>Commit Msg</p>
          <p>Build Parameters</p>
          <p></p>
        </div>


        <div className={classes.cardbody}>
          {state.history_list.length > 0 ?
            <>
              {
                state.history_list.map((history,key) => (
                  
                  <div className="row-cardbody grid-temp-9">
                    {/* <Link target="_blank" to={{
                    pathname: "/logviewer?global_task_id="+history.global_task_id+"&tab_id="+history.id+"&type=Build"+"&num="+history.build_number,
                  }}>Build#{history.build_number}</Link> */}
                     <p><span role='button' tabIndex={0}  className="cursor-pointer text-anchor-blue" onKeyDown={()=>{}} onClick={()=>{onLog(history.id,history.global_task_id,"Build #"+history.build_number)}}>Build####{history.build_number}</span></p>
                    <Tooltip title={history.activity_status&& history.activity_status.status}>
                      <p className={history.activity_status&& history.activity_status.status == "FAILED" ? "round-chip bg-round-red status-font" : history.activity_status&& history.activity_status.status == "RUNNING" ? "round-chip bg-round-blue status-font" : history.activity_status&& history.activity_status.status == "REVOKED" ? "round-chip bg-round-purple status-font" : history.activity_status&& history.activity_status.status == "IN_QUEUE" ? "round-chip status-chip-info status-font" : "round-chip bg-round-green status-font"}>{history.activity_status&& history.activity_status.status}</p>
                    </Tooltip>
                    <p>
                      {/* { history.status != "RUNNING" ? moment(history.created_at).to(history.updated_at) : moment(history.created_at).fromNow() } */}
                      {history.activity_status&& history.activity_status.status != "RUNNING" ? getDuration(new Date(history.activity_status.start_time), new Date(history.activity_status.end_time)) == 0 ? "0s": getDuration(new Date(history.activity_status.start_time), new Date(history.activity_status.end_time)) : getDuration(new Date(history.activity_status.start_time), new Date())}
                    </p>
                    <p>{moment(history.created_at).fromNow()}</p>
                    <Tooltip title={history.build_tag}><p >{history.build_tag}</p></Tooltip>
                    <p>{getBuildbyUser(history)}</p>
                    <div className="d-flex">
                        <Tooltip title={history.commit_sha}><p >{history.commit_sha ? history.commit_sha : <span className="text-center">-</span>}</p></Tooltip>
                        {history.commit_sha? <CopyToClipboard data={history.commit_sha} />: null}
                    </div>
                    <div className="d-flex">
                        <Tooltip title={history.commit_message}><p>{history.commit_message? history.commit_message : <span className="text-center">-</span>}</p></Tooltip>
                       { history.commit_message? <CopyToClipboard data={history.commit_message} /> :null} 
                    </div>
                    <div>
                      {
                        history.build_args_key_value?
                       Object.keys(history.build_args_key_value).map((element, key) =>(
                         <Tooltip title={element+ " : " + JSON.stringify(history.build_args_key_value[element])}>
                       <p>  
                         {element} : {JSON.stringify(history.build_args_key_value[element])}
                         </p>
                       </Tooltip>
                        ))
                        :<div className="text-color-grey blank-div-text">No Build Params</div>
                      }
                    </div>
                    <MoreOptionMenu id={history.id} task_id={history.global_task_id} action_identifier={"Build #"+history.build_number} OptionList={(history.activity_status&&history.activity_status.status == "IN_QUEUE"|| history.activity_status&&history.activity_status.status == "RUNNING") ? moreOption : moreOptions_2} />
                    {/* <p><button className="btn" onClick={() => onLog(history.global_task_id)}>View Logs</button></p> */}
                  </div>
                ))

              }
            </> : <div className="blank-div">
              <div className="blank-div-text">No Data Found</div>
            </div>}
        </div></>
    )
  }

  var getDeployHistoryTabConent = () => {
    return (
      <>
        <div className={classes.cardheaderDeploy}>
          <p>Type</p>
          <p>Status</p>
          <p>Duration</p>
          <p>Date/Time</p>
          <p>Artifact</p>
          <p>Deployed By</p>
          <p></p>
        </div>


        <div className={classes.cardbody}>
          {state.history_list.length > 0 ?
            <>
              {state.history_list.map(history => (
                <div className="row-cardbodydeploy">
                  {/* <Link target="_blank" to={{
                    pathname: "/logviewer?global_task_id="+history.global_task_id+"&tab_id="+history.id+"&type=Deploy"+"&num="+history.deploy_number,
                  }}>Deploy#{history.deploy_number}</Link> */}
                  <span role='button' tabIndex={0}  className="cursor-pointer text-anchor-blue" onKeyDown={()=>{}} onClick={()=>{onLog(history.id,history.global_task_id,"Deploy #"+history.deploy_number)}}>Deploy#{history.deploy_number}</span>      
                  <Tooltip title={history.activity_status&&history.activity_status.status}>
                    <p className={history.activity_status&&history.activity_status.status == "FAILED" ? "round-chip bg-round-red status-font" : history.activity_status&&history.activity_status.status == "RUNNING" ? "round-chip bg-round-blue status-font" : history.activity_status&&history.activity_status.status == "REVOKED" ? "round-chip bg-round-purple status-font" : history.activity_status&&history.activity_status.status == "IN_QUEUE" ? "round-chip status-chip-info status-font" : "round-chip bg-round-green status-font"}>{history.activity_status&&history.activity_status.status}</p></Tooltip>
                  <p>{history.activity_status&&history.activity_status.status != "RUNNING" ? getDuration(new Date(history.activity_status&&history.activity_status.start_time), new Date(history.activity_status&&history.activity_status.end_time)) == 0 || getDuration(new Date(history.activity_status&&history.activity_status.start_time), new Date(history.activity_status&&history.activity_status.updated_at)) > 0 ? "0s":getDuration(new Date(history.activity_status&&history.activity_status.start_time), new Date(history.activity_status&&history.activity_status.end_time)) : getDuration(new Date(history.activity_status.start_time), new Date())}</p>
                  <p>{moment(history.activity_status&&history.activity_status.start_time).fromNow()}</p>
                  <Tooltip title={history.deploy_tag}><p >{history.deploy_tag}</p></Tooltip>
                  <p >{getDeployedByUser(history)}</p>

                  <MoreOptionMenu id={history.id} task_id={history.global_task_id} action_identifier={"Deploy #"+history.deploy_number} OptionList={(history.activity_status&&history.activity_status.status == "RUNNING") ? moreOption : moreOptions_2} />
                  {/* <p><button className="btn" onClick={() => onLog(history.global_task_id)} >View Logs</button></p> */}
                </div>
              ))}
            </> : <div className="blank-div">
              <div className="blank-div-text">No Data Found</div>
            </div>}

        </div></>
    )
  }

  var getPromoteHistoryTabContent = () => {
    return (
      <>
        <div className={classes.cardheaderPromote}>
          <p>Type</p>
          <p>Status</p>
          <p>Duration</p>
          <p>Date/Time</p>
          <p>Artificate</p>
          <p>Promoted by</p>
          <p>Promoted from</p>
          <p>Promoted to</p>
          <p></p>
        </div>
        <div className={classes.cardbody}>
          {state.history_list.length > 0 ?
            <>
              {state.history_list.map(history => (
                <div className="row-cardbodypromote">
                   {/* <Link target="_blank" to={{
                    pathname: "/logviewer?global_task_id="+history.global_task_id+"&tab_id="+history.id+"&type=Promote"+"&num="+ history.promote_number,
                  }}>Promote#{history.promote_number}</Link> */}
                  <span role='button' tabIndex={0}  className="cursor-pointer text-anchor-blue" onKeyDown={()=>{}} onClick={()=>{onLog(history.id,history.global_task_id,"Promote #"+history.deploy_number)}}>Promote#{history.deploy_number}</span>
                  <Tooltip title={history.activity_status&&history.activity_status.status}>
                    <p className={history.activity_status&&history.activity_status.status == "FAILED" ? "round-chip bg-round-red status-font" : history.activity_status&&history.activity_status.status == "RUNNING" ? "round-chip bg-round-blue status-font" : history.activity_status&&history.activity_status.status == "REVOKED" ? "round-chip bg-round-purple status-font" : history.activity_status&&history.activity_status.status == "IN_QUEUE" ? "round-chip status-chip-info status-font" : "round-chip bg-round-green status-font"}>{history.activity_status&&history.activity_status.status}</p>
                  </Tooltip>
                  <p>
                   
                    {history.activity_status&&history.activity_status.status != "RUNNING" ? getDuration(new Date(history.activity_status&&history.activity_status.end_time), new Date(history.activity_status&&history.activity_status.start_time)) == 0 ? "--": getDuration(new Date(history.activity_status&&history.activity_status.start_time), new Date(history.activity_status&&history.activity_status.end_time)) : 
                    getDuration(new Date(history.activity_status&&history.activity_status.start_time), new Date())}
                  </p>
                  <p>{moment(history.activity_status&&history.activity_status.start_time).fromNow()}</p>
                  <p >{history.target_image_tag}</p>
                  <p>{getPromotedByUser(history)}</p>
                  <Tooltip title=""><p >{history.env_cd_detail.deployment_name ? history.env_cd_detail.deployment_name : null}</p></Tooltip>
                  <Tooltip title=""><p>{history.target_env_cd_detail.deployment_name ? history.target_env_cd_detail.deployment_name : null}</p></Tooltip>

                  {/* <p className="badge badge-pill badge-artifact badge-ellipsis">{history.image_name}</p> */}
                  <MoreOptionMenu id={history.id} task_id={history.global_task_id} action_identifier={"Promote #"+history.promote_number}  OptionList={(history.activity_status&&history.activity_status.status == "RUNNING") ? moreOption : moreOptions_2} />
                  {/* <p><button className="btn" onClick={() => onLog(history.global_task_id)} >View Logs</button></p> */}
                </div>
              ))}
            </> : <div className="blank-div">
              <div className="blank-div-text">No Data Found</div>
            </div>}
        </div>
      </>
    )
  }

  const tabDetails = {
    1: {
      tabName: "Build",
      order: 1,
      fetchUrlPath: properties.api.env_build_request_url,
      logUrlPath: properties.api.get_backup_logs,
      tabContentFunction: getBuildHistoryTabContent,
      revokeUrlPath: properties.api.env_build_revoke_url,
      search_param: "build_tag"
    },
    2: {
      tabName: "Deploy",
      order: 2,
      fetchUrlPath: properties.api.env_deploy_request_url,
      logUrlPath: properties.api.get_backup_logs,
      tabContentFunction: getDeployHistoryTabConent,
      revokeUrlPath: properties.api.env_deploy_revoke_url,
      search_param: "deploy_tag"
    },
    3: {
      tabName: "Promote",
      order: 3,
      fetchUrlPath: properties.api.env_promote_request_url,
      logUrlPath: properties.api.get_backup_logs,
      tabContentFunction: getPromoteHistoryTabContent,
      revokeUrlPath: properties.api.env_promote_revoke_url,
      search_param: "promote_tag"
    }
  }

  return (
    <div>
 <button onClick={handleClickOpen} title="History" className="btn btn-flaticon"><span className="flaticon-filter-results-button"></span></button>
      {/* <button onClick={handleClickOpen} style={{
    filter: 'drop-shadow(0px 5px 8px rgba(0,0,0,0.1))',
backgroundImage: 'linear-gradient(180deg, #0095db 0%, #146aec 100%)'}} className="btn btn-with-icon btn-small-icon"><span className="flaticon-view-list-button"></span>History</button> */}
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        {renderTopHeader(props)}


        

          <div className={classes.maincontainer}>
            <div className={classes.card}>
              <div className="tab-with-refresh">
                <CommonHorizontalTab
                  style={{ borderBottom: '1px solid #dedede' }}
                  tabList={tabList}
                  selectedTabOrder={state.selected_tab}
                  updateSelectedTab={updateSelectedTab}
                />
                <button className="btn-round border-navy bg-clear-btn" icon="flaticon" onClick={() => fetchHistory(null, 0,state.current)}><span className="flaticon-refresh-button-1"></span></button>
              </div>
              <Grid container className={classes.containerBox}>
                <Grid item xs={9}>
                  <SearchBar
                    search_data={state.search_data}
                    default_filter={{ name: state.selected_tab == 1? "build_tag":state.selected_tab == 2? "deploy_tag":"target_image_tag" , label: "Artifact" }}
                    search_call_back={invokeSearch}
                    clear_search_callback={fetchHistory}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Pagination
                    current_count={state.history_list ? state.history_list.length : 0}
                    total_count={state.count}
                    next={state.next}
                    previous={state.previous}
                    on_previous_click={() => { fetchHistory(null, 0, state.previous) }}
                    on_next_click={() => { fetchHistory(null, 0, state.next) }}
                  />
                </Grid>
              </Grid>
              {state.showLoadingIcon ?
                <LoadingContainerWithoutCircle />
                :
                <>
                  {tabDetails[state.selected_tab].tabContentFunction()}
                </>}
            </div>
          </div>
        
        
      </Dialog>

    </div>
  );
}


HistoryDialogue.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}


//date1 = current date ,date2 = date of creation of activity(created_at)
export function getStartedDuration(date1,date2) {

  const diffInMs = Math.abs(date2 - date1);
  var remainingSecs = (diffInMs / 1000);

  var day = Math.trunc(remainingSecs/(24*3600));
  var hr =   Math.trunc(remainingSecs/3600);
  var mins = Math.trunc((remainingSecs - (hr*3600))/60);
  var sec =  Math.trunc(remainingSecs - (hr*3600) - (mins*60));
  var remainingHoursInDay = Math.abs((new Date(date1).getHours()-(new Date(date2).getHours())));

  var remainingMinutes = new Date(date1).getMinutes()-(new Date(date2).getMinutes());
  var sentence = "";
  var curHour = new Date(date1).getHours();
  var activityCreatedHours = new Date(date2).getHours();
  if(activityCreatedHours > curHour){
    remainingHoursInDay = 24-(activityCreatedHours-curHour);
  }
  var currMinutes = new Date(date1).getMinutes();
  var activityCreatedMinutes = new Date(date2).getMinutes();
  if(currMinutes > activityCreatedMinutes) {
    remainingMinutes = currMinutes-activityCreatedMinutes;
  } else {
    remainingMinutes = activityCreatedMinutes-currMinutes;
  }

  if(day) {
    if(hr > 24){
      sentence = sentence.concat(`${day} d `);
    }
    if(remainingHoursInDay > 0) {
      sentence = sentence.concat(`${remainingHoursInDay} h `);
    }
    if(remainingMinutes > 0){
      sentence = sentence.concat(`${remainingMinutes} m `);
    }
    if(sec > 0){
      sentence = sentence.concat(`${sec} s `);
    }
  } else {
    if(hr > 0){
      sentence = sentence.concat(`${hr} h `);
    }
    if(mins > 0){
      sentence = sentence.concat(`${mins} m `);
    }
    if(sec > 0){
      sentence = sentence.concat(`${sec} s `);
    }
  }
  return sentence;

}
export function getDuration(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  var remainingSecs = (diffInMs / 1000);
  var hr =   Math.trunc(remainingSecs/3600);
  var mins = Math.trunc((remainingSecs - (hr*3600))/60);
  var sec =  Math.trunc(remainingSecs - (hr*3600) - (mins*60));
  var sentence = "";

    if(hr > 0){
      sentence = sentence.concat(`${hr} h `);
    }
    if(mins > 0){
      sentence = sentence.concat(`${mins} m `);
    }
    if(sec > 0){
      sentence = sentence.concat(`${sec} s `);
    }
    
  // var sentence = `${hr} h ${mins} m ${sec} s`
  return sentence;
}


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    '& .service-name': {
      color: '#fff',
      fontWeight: 400,
      lineHeight: 1.2,
      fontSize: ' 20px',
      margin: '0px',

    },
    '& .service-env': {
      fontSize: '11px',

    }
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  maincontainer: {
    margin: '32px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    overflow: 'hidden',
    border: '1px solid #ebedee',
    '& .search-bar': {
      border: '1px solid #ebedee',
      overflow: 'hidden',
      borderRadius: '8px',
      '& .input-component input:focus': {
        border: 'none!important'
      }
    },
    '& .headerul': {
      backgroundColor: '#f6f6f6',
      borderRadius: '4px 4px 0px 0px',
      borderBottom: '1px solid #ebedee'
    }
  },
  card: {
    '& .loading-gif-container-two': {
      height: '400px',
      '& .loader-bg-card-2': {
        position: 'relative',
        '& .loader': {
          top: '0px'
        }
      }
    },
    '& .loading-overlay': {
      position: 'static'
    }
  },
  cardheader: {
    display: 'grid',
    gridTemplateColumns: '8% 7% 8% 8% 11% 11% 11% 13% 12% 1fr',
    padding: '10px',
    fontSize: '13px',
    borderTop: '1px solid #ebedee',
    borderBottom: '1px solid #ebedee',
    backgroundColor: '#f6f6f6',
    gap: '8px',
    fontWeight: 400,
    
    '& p': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      padding: '0px 3px'
    },
  },
  cardheaderDeploy: {
    display: 'grid',
    gridTemplateColumns: '8% 8% 10% 15% 15% 20% auto',
    padding: '10px',
    gap: '10px',
    fontSize: '13px',
    fontWeight: 400,
    borderTop: '1px solid #ebedee',
    borderBottom: '1px solid #ebedee',
    backgroundColor: '#f6f6f6',
    
    '& p': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      padding: '0px 3px'
    },
  },
  cardheaderPromote: {
    display: 'grid',
    gridTemplateColumns: '8% 8% 10% 12% 12% 15% 10% 10% 15% 1fr',
    padding: '10px',
    gap: '5px',
    fontSize: '13px',
    fontWeight: 400,
    
    borderTop: '1px solid #ebedee',
    borderBottom: '1px solid #ebedee',
    backgroundColor: '#f6f6f6',
    '& p': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      padding: '0px 3px'
    },
  },
  cardbody: {
    height: '350px',
    overflowY: 'scroll',
    '& .blank-div': {
      maxHeight: '100%',
    },
    '& .row-cardbodypromote': {
      display: 'grid',
      gridTemplateColumns: '8% 8% 10% 12% 12% 15% 10% 10% 15% 1fr',
      fontSize: '13px',
      gap: '5px',
      padding: '0px 5px',
      borderBottom: '1px solid #ebedee',
      alignItems: 'center',
      fontWeight: 400,
      
      '& .btn': {
        '&:hover': {
          backgroundColor: 'transparent!important',
          color: '#0096db!important'
        },
      },
      '& p': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        padding: '0px 3px'
      },
      '& .round-chip': {
        fontSize: '11px!important',
        margin: '0px'
      }
    },
    '& .row-cardbodydeploy': {
      display: 'grid',
      gridTemplateColumns: '8% 8% 10% 15% 15% 20% auto',
      fontSize: '13px',
      gap: '10px',
      alignItems: 'center',
      fontWeight: 400,
      borderBottom: '1px solid #ebedee',
      
      '& .btn': {
        '&:hover': {
          backgroundColor: 'transparent!important',
          color: '#0096db!important'
        },
      },
      '& p': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        padding: '0px 3px'
      },
      '& .round-chip': {
        fontSize: '11px!important',
        margin: '0px'
      }
    },
    '& .row-cardbody': {
      display: 'grid',
      gridTemplateColumns: '8% 8%% 18% 18% 17% 17% 7%',
      fontSize: '13px',
      gap: '8px',
      fontWeight: 400,
      
      borderBottom: '1px solid #ebedee',
      padding: '5px',
      alignItems: 'center',
      '& p': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        padding: '0px 3px'
      },
      '& .round-chip': {
        fontSize: '11px!important',
        margin: '0px',
        padding: '5px 8px',
        height: 'fit-content'
      }
    },
    '& .grid-temp-8': {
      gridTemplateColumns: '8% 8% 10% 14% 11% 11% 11% 15% 1fr'
    },
    '& .grid-temp-9':{
      gridTemplateColumns: '8% 7% 8% 8% 11% 11% 11% 13% 12% 1fr'
    },
    borderBottom: '1px solid #dedede',
    '&:last-child': {
      border: 'none'
    },
  },
  containerBox: {
    padding: '20px 15px 15px',
    '& button.btn': {
      '&:hover': {
        color: '#0096db!important'
      }
    }
  }

}));

