import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import FlashOnOutlinedIcon from '@material-ui/icons/FlashOnOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import SortSharpIcon from '@material-ui/icons/SortSharp';
import PlayArrowSharpIcon from '@material-ui/icons/PlayArrowSharp';
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import { Loading } from '../../../../../utils/Loading';
import InvokeApi, { PostData } from '../../../../../../util/apiInvoker';
import GenerateURL, { GenerateSearchURL } from '../../../../../../util/APIUrlProvider';
import properties from '../../../../../../properties/properties';
import { Tooltip } from '@material-ui/core';
import OverridableVariablesDialouge from '../OverridableVariablesDialouge';
import { Link, useHistory } from 'react-router-dom';
import JobTemplateTriggerHistory from '../JobTemplateTriggerHistory';
import ListAltIcon from '@material-ui/icons/ListAlt';
import moment from 'moment';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import TriggerDetailsDialouge from './TriggerDetailsDialouge';

const JobSummaryCardForDynamicJobs = (props) => {

    const { wholeJob,
        data,
        actions,
        fetchLastTriggeredData,
        showOnlyConfigured,
        setSnackBar,
        component_id,
        service_data,
        configuredData,
        configuredJobs,
        job_code_queryString,
        allJobs,
        sub_env_id,
        getConfiguredJobs,
        loading_configured_jobs } = props;

    const classes = useStyles();
    const history = useHistory();
    console.log(job_code_queryString, 'f_is_nsss_0001')
    const [showOverRidableDialog, setShowOverRidableDialog] = useState({ show: false, getEnvVarUrl: '', triggered_error: null })

    const { job_name, job_code, templateName, configured } = data;

    const { handleConfigure } = actions;

    const [state, setState] = useState({
        triggeredData: null,
        loading: false,
        historyView: false
    })

    console.log(configured, 'ccccc_001', job_code);

    useEffect(() => {

        console.log('cvm_00pm', fetchLastTriggeredData, configured, showOverRidableDialog)
        fetchLastTriggeredData && configured && !showOverRidableDialog.show && fetchTriggeredHistory()

        if (!configured) {
            setState(prevState => ({
                ...prevState,
                triggeredData: null
            }))
        } else {

            fetchWidget();


        }

    }, [fetchLastTriggeredData, configured, showOverRidableDialog])


    function fetchTriggeredHistory() {

        if (component_id != undefined && job_code != undefined && sub_env_id != undefined) {
            let get_url = GenerateURL({ component_id: component_id, sub_env_id: sub_env_id, job_code: job_code }, properties.api.get_job_recent_trigger_data)
            var requestInfo = {
                endPoint: get_url,
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }
            InvokeApi(requestInfo, fetchSuccess, fetchFailure);

            setState(new_state => ({
                ...new_state,
                loading: true

            }));
            console.log('api_called_ljwe;o')
        }
    }

    const fetchSuccess = (data) => {
        console.log(data, 'data_knalkdnf')

        if (data && data.component_env_job_meta_information_id != undefined) {
            // let { results } = data
            // let last_triggered_data = results[0]

            setState(prevState => ({
                ...prevState,
                loading: false,
                triggeredData: data

            }))

        }
        else {
            setState(prevState => ({
                ...prevState,
                loading: false

            }))
        }

    }

    const fetchFailure = (error) => {

        console.log(error, 'error_jkadslkkd')
        setState(prevState => ({
            ...prevState,
            loading: false,
            triggeredData: null

        }))
    }



    const IconAccordingToJobState = ({ isConfigured }) => {
        return (
            <div className='d-flex align-center justify-center' style={{ backgroundColor: '#f0f2fb', width: '75px', height: '75px', borderRadius: '70px', marginBottom: '30px', marginTop: '40px' }}>
                {
                    isConfigured ?
                        <PlaylistAddCheckOutlinedIcon style={{ fontSize: '36px', color: '#3e73ec' }} /> :
                        <BuildOutlinedIcon style={{ fontSize: '36px', color: '#3e73ec' }} />
                }
            </div>
        )
    }

    const StatusIcon = ({ status }) => {

        return (
            <>
                {
                    status && status.status === 'IN_QUEUE' ?
                        <RotateLeftIcon style={{ fontSize: '20px', color: '#ffc30c' }} />
                        : status && status.status === 'SUCCESS' ?
                            <CheckCircleIcon style={{ fontSize: '20px', color: '#69e09c' }} />
                            : status && status.status === 'FAILED' ?
                                <ErrorOutlineOutlinedIcon style={{ fontSize: '20px', color: '#ff4747' }} />
                                : status && status.status === 'RUNNING' ?
                                    <TimelapseIcon style={{ fontSize: '20px', color: '#ffc30c' }} /> : null
                }
            </>
        )
    }

    const ConfigurationMessage = ({ isConfigured, jobName }) => {

        return (
            <>
                {
                    isConfigured ?
                        <div style={{ textAlign: 'center' }}>
                            <label style={{ fontSize: '20px', fontWeight: '300', lineHeight: '25px', marginBottom: '12px' }}>{`Configuration is Complete`}</label>
                            <br />
                            <label style={{ fontSize: '13px', color: 'gray' }}>Click the <label style={{ fontSize: '13px', color: '#1d45ff' }}>Play Icon</label></label>
                            <label style={{ fontSize: '13px', color: 'gray' }}>{`to Trigger the Promote Job`}</label>
                        </div>
                        :
                        <div style={{ textAlign: 'center' }}>
                            <label style={{ fontSize: '20px', fontWeight: '300', lineHeight: '25px', marginBottom: '12px' }}>{`Configure ${jobName}`}</label>
                            <br />
                            <label style={{ fontSize: '13px', color: 'gray' }}>{`Please Provide the ${jobName} information to get started.`}</label>
                            <label style={{ fontSize: '13px', color: 'gray' }}>Click on the <label style={{ fontSize: '13px', color: '#1d45ff' }}>Setting Icon</label> below to configure build information.</label>
                        </div>
                }
            </>
        )
    }

    console.log(state.triggeredData, 'jssfsfd_sds', job_code)

    const getDateAndTime = (data) => {
        console.log(data, 'data+updated')
        if (data) {
            let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            let { created_at } = data
            let another_date = new Date(created_at)
            console.log('date_an_th', another_date)
            let timeString = ''
            let date = another_date.getDate()
            let year = another_date.getFullYear()
            let month = another_date.getMonth()
            let time = moment(another_date).format('HH:mm')
            let date_string = date + " " + months[month] + " " + year
            timeString = timeString + date_string + " " + time
            console.log('updated_timestring_001', timeString)
            return timeString
        }
    }

    const onHandleTrigger = (triggerData) => {

        let post_url = GenerateURL({ component_id: component_id, sub_env_id: sub_env_id, job_code: job_code }, properties.api.build_trigger_for_job)
        PostData(post_url, triggerData, () => {
            setShowOverRidableDialog({ show: false, getEnvVarUrl: '' })
            setSnackBar && setSnackBar((prevState => ({ ...prevState, jobTrigger: true })))
        },
            (error) => { setShowOverRidableDialog(prevState => ({ ...prevState, triggered_error: error })) });
    }

    const onClickJobTrigger = (job_code) => {

        let get_url = GenerateURL({ component_id: component_id, sub_env_id: sub_env_id, job_code: job_code }, properties.api.get_trigger_time_modify_env_vars)
        setShowOverRidableDialog({ show: true, getEnvVarUrl: get_url })

    }

    const handleViewLogs = () => {

        let global_task_id = state.triggeredData.global_task_id
        let id = state.triggeredData.job_number
        let to = "/logs?global_task_id=" + global_task_id +
            "&tab_id=" + id +
            "&service_name=" + 'service_name' +
            "&service_env=" + 'service_env' +
            "&service_type=" + 'service_env_type' +
            "&tab_name=BUILD" + "&num=" + id
        history.push(to)
    }

    const refershTriggerStatus = () => {
        fetchTriggeredHistory()
    }

    const onHandleConfiguration = () => {

        let previousConfigData = null;

        if (component_id != undefined && job_code != undefined && sub_env_id != undefined) {

            let get_url = GenerateURL({ component_id: component_id, sub_env_id: sub_env_id, job_code: job_code }, properties.api.configure_job_data)
            if (data && data?.configured?.status) {
                let status = data.configured.status
                get_url = GenerateSearchURL({ status: status }, get_url)
            }
            var requestInfo = {
                endPoint: get_url,
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }
            console.log(requestInfo.endPoint, "bxhbvhbvhbxbvhxc")
            InvokeApi(requestInfo, (data) => {
                console.log(data, 'config_data')
                previousConfigData = { ...data }
                handleConfigure({ jobData: wholeJob, previousConfigData: previousConfigData })

            }, (error) => {
                console.log(error, 'error_0001')
                handleConfigure({ jobData: wholeJob, previousConfigData: previousConfigData })

            });
        }
    }

    function fetchWidget() {
        let previousConfigData = null;
        if (component_id != undefined && job_code != undefined && sub_env_id != undefined) {

            let get_url = GenerateURL({ component_id: component_id, sub_env_id: sub_env_id, job_code: job_code }, properties.api.configure_job_data)
            if (data && data?.configured?.status) {
                let status = data.configured.status
                get_url = GenerateSearchURL({ status: status }, get_url)
            }
            var requestInfo = {
                endPoint: get_url,
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }
            console.log(requestInfo.endPoint, "bxhbvhbvhbxbvhxc")
            InvokeApi(requestInfo, (data) => {
                console.log(data, 'config_data')
                previousConfigData = { ...data }
                let keys = Object.keys(data.widget_data);
                let valueList = data.widget_data[keys[0]];
                let selectorList = valueList.map(item => { return { label: item, id: item } });
                setState((prev_state) => ({
                    ...prev_state,
                    widget_data_list: selectorList,
                }))
                //handleConfigure({ jobData: wholeJob, previousConfigData: previousConfigData })

            }, (error) => {
                console.log(error, 'error_0001')
                //handleConfigure({ jobData: wholeJob, previousConfigData: previousConfigData })

            });
        }
    }
    console.log("hdshdbh_001", configuredData);

    const handleViewAllBuildHistory = () => {
        setShowOverRidableDialog({ show: false, getEnvVarUrl: '', triggredError: null })
        setState(prevState => ({
            ...prevState,
            historyView: true
        }))
    }

    console.log(templateName, 'tt_00p')


    return (

        <>
            {/* <div style={{ padding: '16px' }} className='d-flex justify-end'>
                <button class="btn-without-border d-flex align-center" style={{ justifySelf: 'flex-end' }} onClick={() => getConfiguredJobs()} ><span class="ri-restart-line font-20"></span></button>
            </div> */}
            <div className='d-flex space-between' style={{ padding: '16px' }}>
                <div>
                    <span style={{ color: '#787878' }} className='mr-5 font-12 font-weight-500'>Job Template:</span>
                    <span style={{ color: "#2F2F2F" }} className='font-12 font-weight-500'>{templateName}</span>
                </div>
                <div>
                    <button class="btn-without-border d-flex align-center" style={{ justifySelf: 'flex-end' }} onClick={() => getConfiguredJobs()} ><span class="ri-restart-line font-20"></span></button>
                </div>
            </div>

            {
                (state.loading || loading_configured_jobs) ?
                    <div style={{ width: '100%', height: '340px' }}>
                        <Loading varient='light' />
                    </div>
                    :

                    <>
                        {/* <div style={{ paddingLeft: '16px' }}>
                <span style={{ color: '#787878' }} className='mr-5 font-12 font-weight-500'>Job Template:</span>
                <span style={{ color: "#2F2F2F" }} className='font-12 font-weight-500'>{data.templateName}</span>
            </div> */}


                        {
                            showOverRidableDialog && showOverRidableDialog.show &&
                            <OverridableVariablesDialouge
                                component_id={component_id}
                                handleViewAllBuildHistory={handleViewAllBuildHistory}
                                sub_env_id={sub_env_id}
                                job_code={job_code}
                                job_name={job_name}
                                triggredError={showOverRidableDialog.triggered_error}
                                handleTrigger={onHandleTrigger}
                                envProps={showOverRidableDialog} onClose={() => setShowOverRidableDialog({ show: false, getEnvVarUrl: '', triggredError: null })} />
                        }
                        {
                            state.historyView && <JobTemplateTriggerHistory
                                service_data={service_data}
                                allJobs={allJobs}
                                configuredJobs={configuredJobs}
                                job_name={job_name}
                                component_id={component_id}
                                handleClose={() => {
                                    setState(prevState => ({
                                        ...prevState,
                                        historyView: false
                                    }))
                                }
                                }
                                sub_env_id={sub_env_id}
                                job_code={job_code}
                                open={state.historyView} />
                        }
                        {
                            configured ?
                                <>
                                    <div className={classes.rootCardParent}>
                                        {
                                            configuredData &&
                                            <div className={configuredData.status == "DRAFT" ? classes.rootCard + " " + "draft" : configuredData.status == "DRAFT_PENDING" ? classes.rootCard + " " + "draft_pending" : configuredData.status == "APPROVAL_REQUESTED" ? classes.rootCard + " " + "in-approval" : classes.rootCard + " " + "in-use"}>
                                                <div className="rootcard-header">
                                                    <div className="d-flex align-center space-between">

                                                        <div className='d-grid width-full' style={{ gridTemplateColumns: '48px 31px 1fr' }}>
                                                            <div className='versioning-round inuse_round position-relative' style={{ alignSelf: 'center' }}>
                                                                <span className='ri-code-s-slash-line font-24 color-white'></span>
                                                                <div className='static-round-icon-bg bg-white size-20 position-absolute'>
                                                                    <span className='sm-round-green ri-check-line font-14 color-white d-flex align-center space-between font-weight-bold'></span>
                                                                </div>
                                                            </div>
                                                            <div style={{ width: '1px', margin: "0px 15px", backgroundColor: '#0000001F' }}></div>
                                                            <div className='d-flex align-center space-between width-full'>
                                                                <div>
                                                                    {
                                                                        configuredData?.status == "APPROVED_PENDING" &&
                                                                        <>
                                                                            <div className='versioning-chip draft-chip mb-5 text-transform-uppercase'>
                                                                                APPROVAL IN-PROGRESS
                                                                            </div>
                                                                            <div className='d-flex align-center'>
                                                                                {/* <div className='color-key-78 mr-5'>
                                            Configuration for deployment: <span className='color-value'>{configuredData.deployment_name}</span>
                                        </div> */}

                                                                            </div>
                                                                        </>
                                                                    }

                                                                    {
                                                                        configuredData?.status == "APPROVED" &&
                                                                        <div className='d-flex align-center'>

                                                                            <div className='versioning-chip in-use-chip mb-5 text-transform-uppercase d-inline-block'>
                                                                                In Use
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    <div>
                                                                        <span className='color-key-78 font-14 font-weight-500'>Created On:&nbsp;</span><span className='color-value font-14 font-weight-500'>{moment(configuredData.created_at).fromNow()}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="round-btn-group d-flex align-center justify-center">
                                                                    <button className='btn-sq-icon-primary mr-5' onClick={onHandleConfiguration} >
                                                                        <span className='ri-edit-line vertical-align-super'></span>
                                                                    </button>
                                                                    <Link
                                                                        target='_blank'
                                                                        to={"/logs?global_task_id=" + state.triggeredData?.global_task_id +
                                                                            "&tab_id=" + state.triggeredData?.job_number +
                                                                            "&service_name=" + `${(service_data && service_data?.name) || 'service_name'}` +
                                                                            "&service_env=" + `${(service_data && service_data?.service_env) || 'service_env'}` +
                                                                            "&service_type=" + `${(service_data && service_data?.type) || 'service_env_type'}` +
                                                                            "&tab_name=BUILD" + "&num=" + state?.triggeredData?.job_number
                                                                        }
                                                                    // to={"/sse-testing"}
                                                                    >
                                                                        <Tooltip title="Logs">
                                                                            {/* <button className="btn btn-flaticon" onClick={toggleTriggerActionCard}><span className="flaticon-flash-on-indicator"></span></button> */}
                                                                            <button className="btn-sq-icon-primary d-flex align-center"><span className="ri-file-text-line vertical-align-super"></span></button>
                                                                        </Tooltip>
                                                                    </Link>
                                                                    {/* {
                                <Link target="_blank" to={"/logs?global_task_id=" + configuredData?.versioning_meta_data?.APPROVAL_REQUESTED_PENDING + "&service_name=" + extraProps?.service_name + "&service_env=" + extraProps?.project_env_name}>
                                    <button className='icon-btn-v1 icon-btn-outline-default mr-5'><span className='ri-file-list-line'></span></button>
                                </Link>
                            } */}
                                                                    {/* <Delete
                                display_data_name={""}
                                varient={"new_button"}
                                fontSize="font-20"
                                data={{ entity_id: configuredData.id, name: "env_cd_detail" }}
                                refresh={props.refreshFn}
                                api_link={GenerateURL({ service_id: service_id, component_env_id: component_env_id, id: env_cd_detail_id }, properties.api.edit_cd)}
                            /> */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        }

                                    </div>
                                    <div className={classes.bottom}>
                                        <Tooltip title={`Trigger ${job_name}`} >
                                            {/* <button className="btn btn-flaticon" onClick={toggleTriggerActionCard}><span className="flaticon-flash-on-indicator"></span></button> */}
                                            <button className="btn-sq-icon-primary d-flex align-center ml-5" onClick={configured ? () => onClickJobTrigger(job_code) : () => { }}><span className="ri-flashlight-line vertical-align-super"></span></button>
                                        </Tooltip>
                                        <Tooltip title="History" >
                                            <button onClick={() => {
                                                setState(prevState => ({
                                                    ...prevState,
                                                    historyView: true
                                                }))
                                            }
                                            } className="btn-sq-icon-primary d-flex align-center ml-5">
                                                <span className="ri-filter-3-fill vertical-align-super"></span>
                                            </button>
                                        </Tooltip>

                                    </div>

                                </> :
                                <div className={classes.rootCardParent}>
                                    {
                                        <div style={{ border: '1px solid  #10489166', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '165px' }} className={classes.rootCard}>
                                            <div>
                                                <button className="btn btn-submit" onClick={onHandleConfiguration}>{`Configure ${job_name}`}</button>
                                            </div>

                                        </div>
                                    }
                                </div>
                        }

                    </>
            }


        </>

    )
}

export default JobSummaryCardForDynamicJobs;

const useStyles = makeStyles(theme => ({
    sidecar_container: {
        position: 'relative',
        '& .side-car-border-lines': {
            position: 'absolute',
            width: '2px',
            height: '100px',
            left: '50px',
            top: '-15px',
            backgroundImage: 'url(/images/border-vertical.png)',
            backgroundSize: '2px',
            backgroundRepeatX: 'no-repeat',
            backgroundPosition: 'center'
            // border: '1px dashed #718BAD',

        },
        '&:last-child': {
            '& .side-car-border-lines': {
                height: '60px!important'
            }

        }
    },
    rootCardParent: {
        // width: '300px',
        margin: 'auto 15px',
        marginBottom: '15px',
        minHeight: '250px',
        '&.sideCardCard': {
            marginLeft: '100px',
            position: 'relative',
            '& .div-border-left': {
                backgroundImage: 'url(/images/border-horizontal.png)',
                backgroundSize: '35px 2px',
                backgroundRepeatY: 'no-repeat',
                backgroundPosition: 'center',
                width: '50px',
                height: '2px',
                position: 'absolute',
                display: 'block',
                left: '-50px',
                top: '45px'
            },

        },
        '& .border-draft': {
            borderBottom: '1px solid rgba(230,175,78,0.61)',
            borderLeft: '1px solid rgba(230,175,78,0.61)',
            borderRight: '1px solid rgba(230,175,78,0.61)',
        },
        '& .border-approval-pending': {
            borderBottom: '1px solid rgba(225,225,225,0.61)',
            borderLeft: '1px solid rgba(225,225,225,0.61)',
            borderRight: '1px solid rgba(225,225,225,0.61)',
        },
        '& .border-success': {
            borderBottom: '1px solid #69e09c',
            borderLeft: '1px solid #69e09c',
            borderRight: '1px solid #69e09c',
        },
        '& .btn-round-v2': {
            borderRadius: '50%',
            backgroundColor: '#fff',
            height: '35px',
            width: '35px',
            filter: 'drop-shadow(0.877px 1.798px 4px rgba(0,0,0,0.06))',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        '& .rootcard-footer': {
            padding: '15px',
            borderTop: '0px solid #dedede',
            backgroundColor: '#ffff'
        },
    },
    rootCard: {
        border: '1px solid transparent',
        borderRadius: '8px',
        padding: '20px',
        '& .rootcard-body': {
            padding: '5px 10px'
        },

        '&.in-approval': {
            backgroundColor: '#fff',
            border: '1px solid  #10489166'

        },
        '&.draft': {
            backgroundColor: '#fff',
            border: '1px solid  #10489166'
        },
        "&.draft_pending": {
            backgroundColor: '#fff',
            border: '1px solid  #10489166'
        },
        "& .not-configured": {
            backgroundColor: '#fff',
            border: '1px solid  #10489166'
        },

        '&.in-use': {
            backgroundColor: '#fff',
            border: '1px solid  #10489166'
        },
        '& .chip': {
            padding: '3px!important',
            display: 'inline-block'
        },
        '& .chip-yellow': {
            borderRadius: '3px',
            backgroundColor: '#ffc000',
            color: '#fff',
        },
        '& .chip-dark-grey': {
            borderRadius: '3px',
            backgroundColor: '#656565',
            color: '#fff',
        },
        '& .chip-green': {
            borderRadius: '3px',
            backgroundColor: '#69df9b',
            color: '#fff',
        },
        '& .text-grey-84': {
            color: '#848484'
        },
        '& .text-success': {
            color: '#69df9b'
        },
        '& .pd-custom-5y': {
            fontSize: '12px',
            padding: '5px 0px'
        },
        '& .input-component': {
            marginBottom: '0px'
        }
    },
    bottom: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: "60px",
        borderTop: '1px solid #dedede',
        paddingRight: '12px',
    }
}))
