import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import '../../../../../assets/style.css'
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import {Input} from '../../../../../components/genericComponents/Input';
import Grid from '@material-ui/core/Grid'
import PageHeader from '../../Components/PageHeader';

const EnvVar =(props) => {
  const classes = useStyles();
  const data = props.data;
  const error = props.error;
  const update_data_callback = props.update_data_callback;
  

  const addRow = (type) => {
    switch(type){
      case "build":
        var build_var = data.env_build_variable ? data.env_build_variable : [];
        build_var.push({})
        update_data_callback("env_build_variable",build_var);
        break;
      case "deploy":
        var deploy_var = data.env_deploy_variable ? data.env_deploy_variable : [];
        deploy_var.push({})
        update_data_callback("env_deploy_variable",deploy_var);
        break;
      case "vault":
        var vault_var = data.env_vault_variable ? data.env_vault_variable : [];
        vault_var.push({})
        update_data_callback("env_vault_variable",vault_var);
        break;
    }
  }

  const removeRow = (type,i) => {
    switch(type){
      case "build":
        var build_var = data.env_build_variable ? data.env_build_variable : [];
        build_var.splice(i,1);
        update_data_callback("env_build_variable",build_var);
        break;
      case "deploy":
        var deploy_var = data.env_deploy_variable ? data.env_deploy_variable : [];
        deploy_var.splice(i,1);
        update_data_callback("env_deploy_variable",deploy_var);
        break;
      case "vault":
        var vault_var = data.env_vault_variable ? data.env_vault_variable : [];
        vault_var.splice(i,1);
        update_data_callback("env_vault_variable",vault_var);
        break;
    }
  }

  const addKey = (e,type,i)=>{
    switch(type){
      case "build":
        var build_var_list = data.env_build_variable;
        build_var_list[i].env_key = e.target.value;
        update_data_callback("env_build_variable",build_var_list);
        break;
      case "deploy":
        var deploy_var_list = data.env_deploy_variable;
        deploy_var_list[i].env_key = e.target.value;
        update_data_callback("env_deploy_variable",deploy_var_list);
        break;
      case "vault":
        var vault_var_list = data.env_vault_variable;
        vault_var_list[i].env_key = e.target.value;
        update_data_callback("env_vault_variable",vault_var_list);
        break;
    }
  }

  const addValue = (e,type,i)=>{
    switch(type){
      case "build":
        var build_var_list = data.env_build_variable;
        build_var_list[i].env_value = e.target.value;
        update_data_callback("env_build_variable",build_var_list);
        break;
      case "deploy":
        var deploy_var_list = data.env_deploy_variable;
        deploy_var_list[i].env_value = e.target.value;
        update_data_callback("env_deploy_variable",deploy_var_list);
        break;
      case "vault":
        var vault_var_list = data.env_vault_variable;
        vault_var_list[i].env_value = e.target.value;
        update_data_callback("env_vault_variable",vault_var_list);
        break;
    }
  }


  const onChangeHandler = (e) => {
    switch(e.target.name){
      case "env_build_variable_enabled":
        update_data_callback(e.target.name,!data[e.target.name]);
        break;
      case "env_deploy_variable_enabled":
        update_data_callback(e.target.name,!data[e.target.name]);
        break;
      case "env_vault_variable_enabled":
        update_data_callback(e.target.name,!data[e.target.name]);
        break;
      default:
        update_data_callback(e.target.name,e.target.value);
    }
  }

  return(
    <>
    <PageHeader title="Select Environment Variables" service_name={props.service_name} env_name={props.env_name} sub_env_name={props.sub_env_name} />
     <div className={classes.formDiv}>
      <Grid container justify="center">
    <div className='fromClass'>
      <Grid lg={12}>
        <Grid container>




          {/* <Grid lg={12} direction="row" style={{padding:'10px'}} >
            <div className='card'>
              <div className='heading'>
                <Input 
                type="switch" 
                name="env_build_variable_enabled" 
                label="Do you have a build environment variable?"
                onChangeHandler={onChangeHandler} 
                data={data} 
                error={error}/>
              </div>
              
                {
                  data.env_build_variable_enabled ?
                  <>
                   <div className="error-display">
                 <Input
                    type="hidden" 
                    name="env_build_variable" 
                    error={error} 
                    onChangeHandler={()=>{}}
                    data={{}}
                 />
                </div>
                  <div className='card-body-flex'>
                <div className="grid-container with-header">
                  <div className="section">
                    Key
                  </div>
                  <div>
                   Value
                  </div>
                  <div>
                    <button className="transparent-btn" onClick={() => addRow("build")}>
                    <AddIcon /> Add Row 
                    </button>
                  </div>
                </div>
                {data.env_build_variable ? data.env_build_variable.map((element,i)=>(
                  <div className="grid-container pd-2">
                  <div>
                    <Input
                    type="text" 
                    placeholder="Name" 
                    name="env_key" 
                    error={error} 
                    onChangeHandler={(e)=>addKey(e,"build",i)} 
                    data={element}/> 
                  </div>
                  <div>
                    <Input
                    type="text" 
                    placeholder="Value" 
                    name="env_value"
                    error={error} 
                    onChangeHandler={(e)=>addValue(e,"build",i)} 
                    data={element}/>
                  </div>
                  <div>
                    <button className="delete-btn" onClick={()=>removeRow("build",i)}><DeleteIcon style={{fontSize:'18px'}} /></button>
                  </div>
                </div>
                )): null}
                  
              </div></>:null
                }
            </div>
          </Grid> */}




          <Grid lg={12} direction="row" style={{padding:'10px'}} >
          <div className='card'>
              <div className='heading'>
                <Input 
                type="switch" 
                name="env_deploy_variable_enabled" 
                label="Do you have a runtime environment variable?"
                onChangeHandler={onChangeHandler} 
                data={data} 
                error={error}/>
              </div>
              
                {
                  data.env_deploy_variable_enabled?
                  <>
                    <div className="error-display">
                 <Input
                    type="hidden" 
                    name="env_deploy_variable" 
                    error={error} 
                    onChangeHandler={()=>{}}
                    data={{}}
                 />
                </div> 
                  <div className='card-body-flex'>
                <div className="grid-container with-header">
                  <div className="section">
                    Key
                  </div>
                  <div>
                   Value
                  </div>
                  <div>
                    <button className="transparent-btn" onClick={() => addRow("deploy")}>
                    <AddIcon /> Add Row 
                    </button>
                  </div>
                </div>
                { data.env_deploy_variable ?
                 data.env_deploy_variable.map((element,i)=>(
                  <div className="grid-container pd-2">
                  <div>
                    <Input
                    type="text" 
                    placeholder="Name" 
                    name="env_key" 
                    error={error} 
                    onChangeHandler={(e)=>addKey(e,"deploy",i)} 
                    data={element}/> 
                  </div>
                  <div>
                    <Input
                    type="text" 
                    placeholder="Value" 
                    name="env_value"
                    error={error} 
                    onChangeHandler={(e)=>addValue(e,"deploy",i)} 
                    data={element}/>
                  </div>
                  <div>
                    <button className="delete-btn" onClick={()=>removeRow("deploy",i)}><DeleteIcon style={{fontSize:'18px'}} /></button>
                  </div>
                </div>
                )) : null}
              </div></>:null
                }
            </div>
          </Grid>
          
          <Grid lg={12} direction="row" style={{padding:'10px'}} >
          <div className='card'>
              <div className='heading'>
                <Input 
                type="switch" 
                name="env_vault_variable_enabled" 
                label="Do you have secure variables to be fetched from vault?"
                onChangeHandler={onChangeHandler} 
                data={data} 
                error={error}/>
              </div>
              
                {
                  data.env_vault_variable_enabled ?
                  <>
                  <div className="error-display">
                 <Input
                    type="hidden" 
                    name="env_vault_variable" 
                    error={error} 
                    onChangeHandler={()=>{}}
                    data={{}}
                 />
                </div>
                  <div className='card-body-flex'>
                <div className="grid-container with-header"> 
                  <div className="section">
                  Key
                  </div>
                  <div>
                    Value
                  </div>
                  <div>
                    <button className="transparent-btn" onClick={() => addRow("vault")}>
                    <AddIcon /> Add Row 
                    </button>
                  </div>
                </div>
                { data.env_vault_variable ?
                data.env_vault_variable.map((element,i)=>(
                  <div className="grid-container pd-2">
                  <div>
                    <Input
                    type="text" 
                    placeholder="Name" 
                    name="env_key" 
                    error={error} 
                    onChangeHandler={(e)=>addKey(e,"vault",i)} 
                    data={element}/> 
                  </div>
                  <div>
                    <Input
                    type="text" 
                    placeholder="Value" 
                    name="env_value"
                    error={error} 
                    onChangeHandler={(e)=>addValue(e,"vault",i)} 
                    data={element}/>
                  </div>
                  <div>
                    <button className="delete-btn" onClick={()=>removeRow("vault",i)}><DeleteIcon style={{fontSize:'18px'}} /></button>
                  </div>
                </div>
                )):null}

                <div className="pd-2">
                <Input
                type="text"
                label="Vault Token"
                placeholder="Vault Token" 
                name="token" 
                error={error} 
                onChangeHandler={onChangeHandler} 
                data={data}/>
                </div>

                
                
              </div></>:null
                }
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  </Grid>  
  </div> 
  </>
  );
};

EnvVar.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
};
  
export default EnvVar;
  
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1 ,
    marginTop:'3rem'  
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  mainDivSelected:{
    display:'flex', 
    alignItems:'center', 
    backgroundColor:'#fff', 
    padding:'.5rem 1.25rem', 
    borderRadius:'30px 0px 0px 30px',
    margin:'20px 0px 20px 20px'
  },
  mainDiv:{
    display:'flex', 
    alignItems:'center',
    maxWidth:'160px', 
    backgroundColor:'#fff', 
    padding:'.5rem 1.25rem', 
    borderRadius:'30px 30px 30px 30px',
    margin:'20px'
  },
  count:{
    width: '30px',
    height: '30px',
    border:'1px solid #ccc',
    borderRadius:'50%',
    textAlign:'center',
    paddingTop:'2px'
  },
  text:{
    fontSize:'14px',
    fontWeight:400,
  },
  mainBodyDiv:{
    backgroundColor: '#fff',
    padding:'12px',
    borderRadius:'8px 8px 0px 0px', 
    borderTop:'1px solid#ccc',
    borderLeft:'1px solid#ccc',
    borderRight:'1px solid#ccc',
  },
  countBody:{
    backgroundColor:'#1d5b81',
    color:'#fff',
    width: '30px',
    height: '30px',
    border:'1px solid #ccc',
    borderRadius:'50%',
    textAlign:'center',
    paddingTop:'2px'
  },
  tabBodyDivHeading:{
    display:'flex', 
    alignItems:'center',
    paddingBottom:'1rem',
    borderBottom:'1px solid #ccc' 
  },
  textBody:{
    fontSize:'16px',
    fontWeight:400,
    color:'#000'
  },
  formDiv:{
    padding:'8px 0px',
    margin:'10px 0px'
  },
  InputLabel:{
    fontSize: '14px',
    fontWeight:'regular',
  },
  hindText:{
    fontSize:'10px',
    color:'#ccc',
    fontWeight:400,
    marginLeft:'.5rem'
  },
  stepper:{
    margin:'1rem 0rem'
  },
  subHeading:{
    fontSize:'12px',
    color:'#666',
    fontWeight:400,
    marginBottom:'1rem'
  },
  impMakr:{
    color: 'red'
  }
}));
