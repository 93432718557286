import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';


import { Slide } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});
const CustomFieldsDialog = ({ jobData, openCard, flipAndOpenAdditionalFiledsForm, prevState, ...props }) => {
    const classes = useStyles();
    const inherits = props.inherits ? props.inherits : {};
    const allStagesAdditionalFileds = props.allStagesAdditionalFileds ? props.allStagesAdditionalFileds : [];
    const [state, setState] = useState({})
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    console.log(allStagesAdditionalFileds, "allStagesAdditionalFileds");
    console.log(jobData, prevState, "input_listinput_list");
    const changedValues = prevState[jobData?.issue_key]

    console.log(changedValues, 'editeddd')

    return (
        <>
            {
                jobData?.additional_field_key_value?.length > 0 &&
                <div className={classes.formCard} >
                    {openCard ?

                        <><div className="condition-grid " style={{}} >
                            <div className='' style={{ borderBottom: '1px solid #dedede' }}>
                                <div style={{ height: '50px' }} className='border-bottom d-flex space-between align-center'>
                                    <label className='font-13 ml-12'>
                                        {jobData.issue_key}
                                    </label>
                                    {
                                        jobData?.additional_field_key_value?.length > 0 ?
                                            <button
                                                className="btn btn-transparent text-anchor-blue d-flex align-center"
                                                onClick={() => flipAndOpenAdditionalFiledsForm(jobData.issue_key)}>
                                                <AddIcon className="text-anchor-blue" />
                                                {"Update Custom Field"}
                                            </button>
                                            :
                                            null
                                    }

                                </div>
                                <p className='font-12 custion_fields border-bottom' style={{ borderBottom: '1px solid #dedede' }}>
                                    <span className='key'>Field Name</span>
                                    <span>Value</span>
                                    <span>Over-Ridable</span>
                                    <span>Mandatory</span>
                                </p>
                            </div>
                            {
                                jobData?.additional_field_key_value?.length > 0 ?

                                    jobData?.additional_field_key_value.map((item) => {
                                        const targetId = item.id
                                        if (changedValues != undefined && changedValues[targetId] != undefined) {
                                            item.value = changedValues[targetId]
                                        }

                                        return (
                                            <>

                                                <p className="custion_fields font-12  pd-3">
                                                    <span className=" key">{item.key} </span>
                                                    <span className="key">{
                                                        item.value ?
                                                            typeof (item.value) == "string" ?
                                                                item.value :
                                                                typeof (item.value) == "object" ?

                                                                    (Array.isArray(item.value) && item.value.length > 0) ?
                                                                        item.value.map((single_val, index) => {
                                                                            return (index === item.value.length - 1) ? single_val : single_val + ', ';
                                                                        })
                                                                        : null : null : null
                                                    }</span>
                                                    <span className="text-grey-83 key">{item["over-ridable"] ? "true" : "false"} </span>
                                                    <span className="val">{item["mandatory"] ? "true" : "false"}</span>
                                                </p>
                                            </>
                                        )
                                    })
                                    : <p className='font-12'>Overridable custom fields are not available</p>

                            }
                        </div>
                        </>

                        : null
                    }

                </div>
            }

        </>

    )
}

CustomFieldsDialog.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export default CustomFieldsDialog;

export function defaultLinkedIssueState() {
    return {
        data: {

        },
        error: {},
        validations: {
            name: [VALIDATION_TYPE_REQUIRED],
            code: [VALIDATION_TYPE_REQUIRED]
        }
    }
}


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    root: {
        '& .h-300': {
            height: 350,
            overflowy: 'scroll'
        }
    },
    formCard: {
        backgroundColor: '#fff',
        margin: '10px',
        border: '1px solid #eaeaea'
        // '& .MuiTextField-root': {
        //     '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        //         padding: '0px 14px!important'
        //     }

        // }

    },

    cardHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #C5C5C5',
        fontSize: '14px',
        fontWeight: 400,
        color: '#fff',
        backgroundColor: 'rgb(18, 77, 155)',
        padding: '10px 20px'
    },
    cardfooter: {
        border: '1px solid #eaeaea',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px'
    },
    custom_css: {
        '& .custom-switch': {
            borderTop: '0px solid transparent!important',
            '& .card-header': {
                border: 'none!important',
                height: '49px',
                '& .card-header': {

                }
            }
        }
    }
}));
