import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Input } from '../../../../components/genericComponents/Input';
import Grid from '@material-ui/core/Grid';
import { VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
import { getCommonFunctions, ResetComponent } from '../ci_flow/SourceDetails';


const supportedImagePullPolicies = [
  { id: "Always", label: "Always" },
  { id: "IfNotPresent", label: "If Not Present" },
  { id: "Never", label: "Never" },
]

const useStyles = makeStyles(theme => ({
  root: {
    '& .input-with-btn': {
      border: '0px'
    },
    padding: '0px',
    '& > .card-add-service': {
      borderRadius: '0px',
      border: '0px'
    },
    '& .card-add-service-head': {
      borderTop: '1px solid #e7e7eb',
      borderRadius: '0px',
    },
    '& > .card': {
      backgroundColor: '#f6f8f8',
      borderRadius: '8px',
      border: '1px',
      '& .card-footer': {
        backgroundColor: '#fff',
        justifyContent: 'flex-end'
      },
      '& .card ': {
        marginBottom: '15px',
        boxShadow: 'none',
        '& .input-component': {
          marginBottom: '0px'
        }
      },

    }
  }
}))

const ResourceQuota = (props) => {
  const classes = useStyles();
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const type = props.type ? props.type : null;
  const extraProps = props.extraProps ? props.extraProps : null;
  const [state, setState] = useState(prev_state ? prev_state : getResourceQuotaDefaultState(extraProps))
  const commonFunctions = getCommonFunctions(state, setState, inherits);
  console.log(props.total_state,"total_state__total_state")
  useEffect(() => {
    setState(new_state => ({ ...new_state, ...prev_state }))
  }, [prev_state])

  return (
    <>

      <div className={`${classes.root} ci-cd-edit-input-div`}>
        {
          type == "sidecar" &&
          <>
            <Grid item lg={12} direction="row" className="card-add-service-head">
              <div className="formTag">
                <h4 className='mainHeading'>Deployment Details</h4>
              </div>
            </Grid>
            <Grid container direction="row" justifyContent="flex-start" className="card-add-service-body">
              <Grid item lg={6}>
                <div className='section'>
                  <Input
                    type="text"
                    placeholder="Give a name to your container"
                    name="container_name"
                    label="Container Name"
                    mandatorySign
                    error={state.error}
                    onChangeHandler={commonFunctions.onChangeHandler}
                    data={state.data} />
                </div>
              </Grid>
              <Grid item lg={6}>
                <div className='section'>
                  <Input
                    type="select"
                    name="container_type"
                    label="Container Type"
                    mandatorySign
                    list={[{ label: 'Init Container', id: 'INIT' }, { label: 'Sidecar Container', id: 'SIDECAR' }]}
                    error={state.error}
                    onChangeHandler={commonFunctions.onChangeHandler}
                    data={state.data} />
                </div>
              </Grid>
              <Grid item lg={6}>
                <div className='section'>
                  <Input
                    type="text"
                    placeholder="Image Name"
                    name="image_name"
                    label="Image Name"
                    mandatorySign
                    error={state.error}
                    onChangeHandler={commonFunctions.onChangeHandler}
                    data={state.data} />
                </div>
              </Grid>
              <Grid item lg={6}>
                <div className='section'>
                  <Input
                    type="text"
                    placeholder="Tag"
                    name="tag"
                    label="Enter tag"
                    mandatorySign
                    error={state.error}
                    onChangeHandler={commonFunctions.onChangeHandler}
                    data={state.data} />
                </div>
              </Grid>
            </Grid>
          </>
        }
        <Grid container className="card-add-service">
          <Grid item lg={12} direction="row" className="card-add-service-head">
            <div className="formTag">
              <h4 className='mainHeading'>Set Resource Quota</h4>
            </div>
          </Grid>
          <Grid container justify="center" className="card-add-service-body">
            <Grid item lg={6}>
              <div className='section'>
                <Input
                  type="text"
                  placeholder="100"
                  subHeading="(in Mi)"
                  name="requests_memory_quota"
                  label="Request Memory Quota"
                  mandatorySign
                  error={state.error}
                  onChangeHandler={commonFunctions.onChangeHandlerNumber}
                  data={state.data} />
              </div>
            </Grid>
            <Grid item lg={6}>
              <div className='section'>
                <Input
                  type="text"
                  placeholder="100"
                  name="requests_cpu_quota"
                  mandatorySign
                  subHeading="(in MiliCore)"
                  label="Request CPU Quota"
                  error={state.error}
                  onChangeHandler={commonFunctions.onChangeHandlerNumber}
                  data={state.data} />
              </div>
            </Grid>
            <Grid item lg={12}>
              <div className='card'>
                <div className='heading'>
                  <Input
                    type="switch"
                    name="specify_limit_quota"
                    label="Specify limit quota else limit quota will be same as request quota"
                    onChangeHandler={commonFunctions.toggleState}
                    data={state.data}
                    error={state.error}
                    enabledComponent={<SpecifyLimitQuota prev_state={state.limit_quota} inherits={state.child_inherits.limit_quota} />}
                    disabledComponent={<ResetComponent inherits={state.child_inherits.limit_quota} />} />
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>

      </div>

    </>


  )
}
ResourceQuota.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export default ResourceQuota;


const SpecifyLimitQuota = props => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const [state, setState] = useState(prev_state ? prev_state : getSpecifyLimitQuotaDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);

  console.log(state.data.limits_memory_quota, "limits_memory_quota")
  return (
    <div className='card-body'>
      <Grid container spacing="2" >
        <Grid item lg={6}>
          <div className='section'>
            <Input
              type="text"
              subHeading="(in Mi)"
              placeholder="100"
              name="limits_memory_quota"
              mandatorySign
              label="Limit Memory Quota"
              error={state.error}
              onChangeHandler={commonFunctions.onChangeHandlerNumber}
              data={state.data} />
          </div>
        </Grid>
        <Grid item lg={6}>
          <div className='section'>
            <Input
              type="text"
              subHeading="(in MiliCore)"
              placeholder="100"
              name="limits_cpu_quota"
              mandatorySign
              label="Limit CPU Quota"
              error={state.error}
              onChangeHandler={commonFunctions.onChangeHandlerNumber}
              data={state.data} />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
SpecifyLimitQuota.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export function getSpecifyLimitQuotaDefaultState() {
  return {
    data: {
      limits_memory_quota: 500,
      limits_cpu_quota: 500
    },
    error: {},
    validations: {
      limits_memory_quota: [VALIDATION_TYPE_REQUIRED],
      limits_cpu_quota: [VALIDATION_TYPE_REQUIRED]
    },
  };
}


export function getResourceQuotaDefaultState(extraProps) {
  const service_name = extraProps ? extraProps.service_name : "";
  const env_master = extraProps ? extraProps.environment_master_name : "";
  const project_env_name = extraProps ? extraProps.project_env_name : "";
  return {
    data: {
      desired_replication: 1,
      requests_memory_quota: 100,
      requests_cpu_quota: 100,
      image_name: service_name ? (service_name + "/" + env_master + "/" + project_env_name) : "",
    },
    error: {},
    validations: {
      requests_memory_quota: [VALIDATION_TYPE_REQUIRED],
      requests_cpu_quota: [VALIDATION_TYPE_REQUIRED],
    },
    child_inherits: {
      limit_quota: {
        "validateForm": () => { return { valid: true }; },
        "getState": () => { return {}; },
        "getData": () => { return {}; }
      }
    }
  };
}

const AutoScaling = props => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const [state, setState] = useState(prev_state ? prev_state : getAutoScalingDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);
  function onCPUSliderChangeHandler(e, value) {
    if (e.target.type === "text") {
      if (e.target.value <= 100) {
        commonFunctions.updateData([e.target.name], Number(e.target.value) ? Number(e.target.value) : "")
      }
    } else {
      if (value <= 100) {
        commonFunctions.updateData("cpu_threshold", Number(value) ? Number(value) : "")
      }
    }
  }

  function onRamSliderChangeHandler(e, value) {
    if (e.target.type === "text") {
      if (e.target.value <= 100) {
        commonFunctions.updateData([e.target.name], Number(e.target.value) ? Number(e.target.value) : "")
      }
    } else {
      if (value <= 100) {
        commonFunctions.updateData("ram_threshold", Number(value) ? Number(value) : "")
      }
    }
  }

  return (
    <div className='card-body'>
      <Grid container justify="center">
        <Grid lg={2} direction="row">
          <div className='section'>
            <Input
              type="text"
              name="minimum_replication"
              label="Minimum Replication"
              min={1}
              placeholder="1"
              onChangeHandler={commonFunctions.onChangeHandlerNumber}
              data={state.data}
              error={state.error} />
          </div>
        </Grid>
        <Grid lg={2} direction="row">
          <div className='section'>
            <Input
              type="text"
              name="maximum_replication"
              label="Maximum Replication"
              placeholder="5"
              onChangeHandler={commonFunctions.onChangeHandlerNumber}
              data={state.data}
              error={state.error} />
          </div>
        </Grid>
        <Grid lg={4} direction="row">
          <div className='section card-autoscaling'>

            <Input
              type="slider"
              name="cpu_threshold"
              label="CPU Threshold (0-100 units)"
              onChangeHandler={onCPUSliderChangeHandler}
              data={state.data}
              error={state.error}
            />

          </div>
        </Grid>
        <Grid lg={4} direction="row">
          <div className='section card-autoscaling'>
            <Input
              type="slider"
              name="ram_threshold"
              label="RAM Threshold (0-100 mb)"
              onChangeHandler={onRamSliderChangeHandler}
              data={state.data}
              error={state.error}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
AutoScaling.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export function getAutoScalingDefaultState() {
  return {
    data: {
      minimum_replication: 1,
      maximum_replication: 5,
      cpu_threshold: 60,
      ram_threshold: 60
    },
    error: {},
    validations: {
      minimum_replication: [VALIDATION_TYPE_REQUIRED],
      maximum_replication: [VALIDATION_TYPE_REQUIRED],
      cpu_threshold: [VALIDATION_TYPE_REQUIRED],
      ram_threshold: [VALIDATION_TYPE_REQUIRED]
    },
  };
}


