import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import { Input } from '../../../../components/genericComponents/Input';
import { VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
import DynamicFilterField from './DynamicFilterField';

export default function AddComment({ pipeline, 
    pipelineVars, 
    jiraIssueKeys, 
    jobStepOrderOutputVars,
    dynamicValidationFormError,
    setParentState, 
    parentState,
    ...props }) {
    const inherits = props.inherits ? props.inherits : {};
    const prev_state = props.prev_state;
    const jira_reference_key_list = props.jira_reference_key_list ? props.jira_reference_key_list : [];
    const get_run_conditions_function = props.get_run_conditions_function;
    const issue_keys_list = [...get_run_conditions_function().jira_id_reference_keys];
    jira_reference_key_list.forEach(element => {
        issue_keys_list.push({ label: element.label, id: element.label })
    });
    const [state, setState] = useState(getAddCommentsDefaultState(prev_state, issue_keys_list))
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    const list = [
        { label: 'Primary', value: 'PRIMARY' },
        { label: 'Other', value: 'OTHER' }
    ]

    console.log(state,jiraIssueKeys,'steg_klkd')
    return (
        <>
            <DynamicFilterField
                jiraIssueKeys={jiraIssueKeys}
                pipelineVars={pipelineVars}
                jobStepOrderOutputVars={jobStepOrderOutputVars}
                setParentState={setState}
                parentState={state}
                prev_state={prev_state}
                dynamicValidationFormError={dynamicValidationFormError}
                setTopParentState={setParentState}
                topParenState={parentState}
            >
                <Input
                    type="select"
                    name="issue_key"
                    list={issue_keys_list}  // get_run_conditions_function().jira_id_reference_keys
                    label="Select Jira Id Refrence key"
                    placeholder="Jira id refrence key"
                    mandatorySign
                    onChangeHandler={commonFunctions.onChangeHandler}
                    data={state.data}
                    error={state.error} />
            </DynamicFilterField>
            <>
                <Input
                    type="text"
                    name="comment"
                    mandatorySign
                    placeholder="leave comments "
                    label="Comments"
                    onChangeHandler={commonFunctions.onChangeHandler}
                    data={state.data}
                    error={state.error} />
            </>
        </>
    )
}
AddComment.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};

function getAddCommentsDefaultState(prev_state, issue_keys_list) {
    return {
        data: prev_state ? {
            issue_key: prev_state.issue_key,
            comment: prev_state.comment ? prev_state.comment : null
        } : {
            issue_key: issue_keys_list.length > 0 ? issue_keys_list[0].id : null
        },
        error: {},
        dynamic_fields:{},
        validations: {
            issue_key: [VALIDATION_TYPE_REQUIRED],
            comment: [VALIDATION_TYPE_REQUIRED]
        },
    };
}


