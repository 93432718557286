import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Input } from '../../../../components/genericComponents/Input';
import { getCommonFunctions } from "../../../serviceRevamp/add/ci_flow/SourceDetails";
import { VALIDATION_TYPE_REQUIRED, ValidateDataSet } from '../../../../util/Validator';
//import { IconButton } from '@material-ui/core';
//import CloseIcon from '@material-ui/icons/Close';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import InvokeApi from '../../../../util/apiInvoker';

export default function CloneDeployment(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const inherits = props.inherits ? props.inherits : {};
    const prev_state = props.prev_state ? props.prev_state : null;
    const list = props.list;
    const variant = props.variant ? props.variant : null;
    const service_id = props.service_id;
    const selected_service_env_name = props.selected_service_env_name;
    const cd_type = props.cd_type;
    const [state, setState] = useState(prev_state ? prev_state : {
        data: {
        },
        error: {},
        all_deployment_list:[],
        manifest_enabled: false,
        validations: {
            env_clone: [VALIDATION_TYPE_REQUIRED]
        },
        list: [],
    })
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    function onYesHandler() {
        var same_env = false;
        var clone_env_name = ""
        list.forEach((item) => {
            if(item.id == state.data.env_clone){
                clone_env_name = item.label
            }
        })
        if(selected_service_env_name == clone_env_name){
            same_env = true;
        }
        const result = ValidateDataSet(state.data, state.validations)
        if (!result.valid) {
            setState(new_state => ({
                ...new_state,
                error: { ...result.error }
            }));
        }
        else {
                props.onCloneHandler(state.data.env_clone, state.data.env_deployment_clone, state.manifest_enabled,same_env)
            
        }
    }


    useEffect(() => {
        if(state.data.env_clone){
            fetchEnvCd() 
        }
           
    }, [state.data.env_clone])


    function fetchEnvCd() {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: service_id, component_env_id: state.data.env_clone }, properties.api.save_cd),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, onFetchEnvCdDataSuccess, onFetchEnvCdDataFail)
    }


    function onFetchEnvCdDataSuccess(response) {
        var manifest_enabled = response.manifest_meta_data ? true : false;
        console.log(response, "deployment_namedeployment_name")
        var approved_response = response.filter(item => item.status === "APPROVED");
        console.log(manifest_enabled, approved_response, "deployment_namedeployment_name")
        if(approved_response && approved_response.length>0){
           if(approved_response[0].manifest_meta_data){
            setState(new_state => ({
                ...new_state,
                manifest_enabled: true,
                all_deployment_list: approved_response.map(item => {
                    return {label: item.deployment_name, id: item.id}
                }),
                data:{
                    ...new_state.data,
                    env_deployment_clone: approved_response[0]?.id
                }
            }));
           
        }else{
            setState(new_state => ({
                ...new_state,
                manifest_enabled: false,
                all_deployment_list: approved_response.map(item => {
                    return {label: item.deployment_name, id: item.id}
                }),
                data:{
                    ...new_state.data,
                    env_deployment_clone: approved_response[0]?.id
                }
            }));
        }
    }
        
    }
    console.log(state.all_deployment_list, "deployment_namedeployment_name")



    function onFetchEnvCdDataFail() {
       
    }

    function getApprovedCDDataId(cd_data){
        var cd_id;
        
        cd_data && cd_data.length > 0 && cd_data.forEach((item) => {
            cd_id = item.id
        })
        return cd_id;
    }

    console.log(state,"env clone ============>")

    return (
        <div className={classes.root}>
            <div className="card border-none">
                <div className="card-header border-none bg-white" style={{ borderTop: 'none' }}>
                    {/* <p>Do you want to clone from any existing env</p> */}
                    {/* <IconButton onClick={handleClose}>
                        <CloseIcon fontSize="large" />
                    </IconButton> */}
                </div>
                <div className="card-body border-none pd-10 mb-22">
                    {
                        variant == "cloing_ci" ?
                            <>
                                <Input
                                    type="select"
                                    label="You can clone from these availaible Envs."
                                    name="env_clone"
                                    data={state.data}
                                    list={list ? list : []}
                                    error={state.error}
                                    onChangeHandler={commonFunctions.onChangeHandler}
                                />
                            </>
                            :
                            <>
                                <Input
                                    type="select"
                                    label="You can clone from these availaible Envs."
                                    name="env_clone"
                                    data={state.data}
                                    list={list ? list : []}
                                    error={state.error}
                                    onChangeHandler={commonFunctions.onChangeHandler}
                                />
                                <Input
                                    type="select"
                                    label="Deployment"
                                    name="env_deployment_clone"
                                    data={state.data}
                                    list={state.all_deployment_list && state.all_deployment_list.length > 0 ? state.all_deployment_list : []}
                                    error={state.error}
                                    onChangeHandler={commonFunctions.onChangeHandler}
                                />
                            </>
                    }

                </div>
                <div className="card-footer justify-flexend border-none btn-section bg-white">
                    <button className="btn btn-outline-grey" onClick={cd_type == "manifest" ? () => { props.onCloneHandler(null,null,true) } : () => { props.onCloneHandler(null,null,false) }}> Cancel </button>
                    <button className="btn btn-primary" onClick={onYesHandler}> Next </button>
                </div>
            </div>
        </div>
    );
}
CloneDeployment.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }


const useStyles = makeStyles(theme => ({
    root: {
        '& .card': {
            textAlign: 'left',
            boxShadow: 'none',
            minWidth: '600px',
            '& .card-header': {
                paddingBottom: '10px!important',
                fontSize: '13px'
            },
            '& button': {
                fontSize: '12px',
                color: '#546e7a',
                display: 'inherit',
                lineHeight: 'auto',
                border: 'none',
                height: 'auto',
                width: "auto",
                margin: 'inherit',
                borderRadius: '4px'
            }
        },
    },
}))