import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import { Input } from '../../../../components/genericComponents/Input';
import { VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
import properties from '../../../../properties/properties';
import InvokeApi from '../../../../util/apiInvoker';
import GenerateURL from '../../../../util/APIUrlProvider';
import { Loading } from '../../../utils/Loading';
import { defaultLinkedIssueState } from './LinkedIssuesDialog';
import { Category } from '@material-ui/icons';
import { Grid } from '@material-ui/core';
import resolve from 'resolve';
import DynamicFilterField from './DynamicFilterField';

export default function SnowAddNotes({ pipelineVars, 
    jiraIssueKeys, 
    jobStepOrderOutputVars,
    dynamicValidationFormError,
    setParentState, 
    parentState,...props }) {
    const inherits = props.inherits;
    const prev_state = props.prev_state;

    const jira_reference_key_list = props.jira_reference_key_list ? props.jira_reference_key_list : [];
    const get_run_conditions_function = props.get_run_conditions_function;
    const issue_keys_list = [...get_run_conditions_function().jira_id_reference_keys];
    jira_reference_key_list.forEach(element => {
        issue_keys_list.push({ label: element.label, id: element.label })
    });

    const default_job_template = props.default_job_template ? props.default_job_template : null;
    const [state, setState] = useState(getSnowAddNotesDefaultState(prev_state,issue_keys_list));
    // useEffect(() => {
    //     if (default_job_template ) {
    //         // fetchSnowCategoryList();
    //         // fetchSnowServiceList();
    //         // fetchSnowConfList();
    //         fetchData();
    //     }

    // }, [default_job_template])

    
  



    const commonFunctions = getCommonFunctions(state, setState, inherits);
    console.log('state_data_oo1', prev_state)
   
    


    console.log("jdsjhsaj",state);
    return (
        <>
        {state.data.loading ? //state.data.loading  
        <div style={{paddingBottom: "14px"}}>
            <Loading varient="light" />
        </div>
         :
            <Grid container spacing={2} style={{padding:"9px"}}>      
                    <Grid lg={12}>
                        <DynamicFilterField
                            jiraIssueKeys={jiraIssueKeys}
                            pipelineVars={pipelineVars}
                            jobStepOrderOutputVars={jobStepOrderOutputVars}
                            setParentState={setState}
                            parentState={state}
                            prev_state={prev_state}
                            dynamicValidationFormError={dynamicValidationFormError}
                            setTopParentState={setParentState}
                            topParenState={parentState}
                            showDynamicFeild= {true}
                        >
                            <Input
                                type="select"
                                name="issue_key"
                                list={issue_keys_list}  // get_run_conditions_function().jira_id_reference_keys
                                label="Select Snow Id Refrence key"
                                placeholder="Snow id refrence key"
                                mandatorySign
                                onChangeHandler={commonFunctions.onChangeHandler}
                                data={state.data}
                                error={state.error} />
                        </DynamicFilterField>
                    </Grid>
                    <Grid lg={12}>
                        <Input 
                            type="text"
                            name="work_note"
                            //list={snowStatesList}
                            placeholder={"Work Note"}
                            label={"Work Note"}
                            mandatorySign
                            onChangeHandler={commonFunctions.onChangeHandler}
                            data={state.data}
                            error={state.error}
                        />
                    </Grid>
        </Grid>
        }
            
        </>
        
    )
}

SnowAddNotes.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}


function getSnowAddNotesDefaultState(prev_state,issue_keys_list) {
    return {
        data: prev_state ? {
            issue_key: prev_state.issue_key,
            notes_description: prev_state.notes_description ? prev_state.notes_description : null,
        } : {
            issue_key: issue_keys_list.length > 0 ? issue_keys_list[0].id : null
        },
        error: {},
        snowMadatoryField: [],
        dynamic_fields:{},
        validations: {
            work_note: [VALIDATION_TYPE_REQUIRED],
            issue_key: [VALIDATION_TYPE_REQUIRED],
        },
    };
}
