import { ToolImage } from './ImageProvider';
import { normalize, schema } from "normalizr";

export const EnvMonitoringDataParser = (data) => {

    var monitoring = data && data.monitoring ? data.monitoring : null;

    if (!monitoring) {
        return null;
    }

    var monitoring_data = {};

    // Creating Array from infra details
    if (monitoring.infra) {
        var infra = [];
        Object.keys(monitoring.infra).forEach((key) => {
            if (monitoring.infra[key].utilization) {
                infra.push({
                    label: key.toUpperCase(),
                    percentage: monitoring.infra[key].utilization,
                    max: monitoring.infra[key].allocated,
                    color: monitoring.infra[key].status,
                });
            }
        });
        monitoring_data.infra = infra.length > 0 ? infra : null;
    }

    // Creating Arrray from urls
    var urls = [];
    Object.keys(data.urls).forEach((key) => {
        if (data.urls[key]) {
            urls.push({
                label: key,
                url: data.urls[key],
                img: ToolImage(key),
            });
        }
    });
    monitoring_data.urls = urls.length > 0 ? urls : null;

    // Creating data for replication bar
    var replication_bar = {
        current: monitoring.pods ? monitoring.pods : 0,
        max: data.env_cd_scale ? data.env_cd_scale.maximum_replication : null,
        min: data.env_cd_scale ? data.env_cd_scale.minimum_replication : null,
    }
    monitoring_data.replication_bar = replication_bar;

    // Creating service specific data
    var service_data = {
        uptime: data.monitoring.uptime,
        restart: data.monitoring.restart,
    }
    monitoring_data.service_data = service_data;

    if (!monitoring_data) {
        monitoring_data.status = "NOT_SETUP";
    } else if (!monitoring.status) {
        monitoring_data.status = "NOT_WORKING";
        monitoring_data.description = monitoring.description;
    }

    return monitoring_data;
}

export const ClusterDataParser = (data) => {
    var project_cluster_namespace = new schema.Entity('namespace');
    var tls_cert = new schema.Entity('tls_cert');
    var cluster = new schema.Entity('cluster', {
        project_cluster_namespace: [project_cluster_namespace],
        tls_cert: [tls_cert],
    });

    var clusters = new schema.Array(cluster)
    var normalized_data = normalize(data, clusters);
    return normalized_data.entities;
}

export const ClusterListParser = (data) => {
    var nodes = new schema.Entity('nodes');
    var cluster = new schema.Entity('cluster', {
        nodes: [nodes]
    });
    var clusters = new schema.Array(cluster)
    var normalized_data = normalize(data, clusters);
    return normalized_data.entities;
}

export const ApplicationListDataParser = (data) => {
    var project_cluster = new schema.Entity('cluster');
    var environments = new schema.Entity('environment');
    var components = new schema.Entity('service');
    var notifications = new schema.Entity('notification');
    var pipelines = new schema.Entity('pipeline');
    var project_admin = new schema.Entity('project_admin');
    var teams = new schema.Entity('team');
    var project_ci_scope = new schema.Entity('ci');
    var application = new schema.Entity('application', {
        project_cluster: [project_cluster],
        environments: [environments],
        components: [components],
        notifications: [notifications],
        pipelines: [pipelines],
        project_admin: project_admin,
        teams: [teams],
        project_ci_scope: [project_ci_scope]
    })
    const project_namespace = {};
    data.forEach(app => {
        project_namespace[app.id] = {

        }
        app.project_cluster.forEach(cluster => {
            project_namespace[app.id][cluster.id] = cluster.project_cluster_namespace;
        });

    });

    var applications = new schema.Array(application)
    var normalizedData = normalize(data, applications);
    normalizedData.entities.project_cluster_namespace = project_namespace;
    return normalizedData.entities;

}
export const DataParserForSidePanel = (data) => {
    var project_cluster = new schema.Entity('cluster');
    var environments = new schema.Entity('environment');
    var components = new schema.Entity('service');
    var notifications = new schema.Entity('notification');
    var pipelines = new schema.Entity('pipeline');
    var project_admin = new schema.Entity('project_admin');
    var teams = new schema.Entity('team');
    var project_ci_scope = new schema.Entity('ci');
    var application = new schema.Entity('application', {
        project_cluster: [project_cluster],
        environments: [environments],
        components: [components],
        notifications: [notifications],
        pipelines: [pipelines],
        project_admin: project_admin,
        teams: [teams],
        project_ci_scope: [project_ci_scope]
    })
    var applications = new schema.Array(application)
    var normalizedData = normalize(data, applications);
    console.log(normalizedData, "normalizedData");
    return normalizedData.entities;
}
export const ApplicationDashboardDataParser = (data) => {
    var project_cluster = new schema.Entity('cluster');
    var environments = new schema.Entity('environment');
    var components = new schema.Entity('service');
    var notifications = new schema.Entity('notification');
    var pipelines = new schema.Entity('pipeline');
    var project_admin = new schema.Entity('project_admin');
    var teams = new schema.Entity('team');
    var project_ci_scope = new schema.Entity('ci');
    var application = new schema.Entity('application', {
        project_cluster: [project_cluster],
        environments: [environments],
        components: [components],
        notifications: [notifications],
        pipelines: [pipelines],
        project_admin: project_admin,
        teams: [teams],
        project_ci_scope: [project_ci_scope]
    })

    const project_namespace = {};
    console.log("API data ", data);
    data.forEach(app => {
        console.log('app>', app);
        project_namespace[app.id] = {

        }
        app.clusters.forEach(cluster => {

            project_namespace[app.id][cluster.id] = cluster.project_cluster_namespace;
        });

    });
    const total_pipeline = [];
    data.forEach(app => {
        total_pipeline.push(app.pipeline_count)

    });
    const total_cluster = [];
    data.forEach(app => {

        app.clusters.forEach(cluster => {
            if (!total_cluster.includes(cluster.name)) {
                total_cluster.push(cluster.name)
            }
        })
    });
    const total_pipeline_sum = total_pipeline.reduce((partialSum, a) => partialSum + a, 0);
    const total_cluster_sum = total_cluster.length;
    var applications = new schema.Array(application)
    var normalizedData = normalize(data, applications);
    normalizedData.entities.project_cluster_namespace = project_namespace;
    normalizedData.entities.pipeline_count = total_pipeline_sum;
    normalizedData.entities.cluster_count = total_cluster_sum;
    console.log("bug=========>", normalizedData.entities);
    return normalizedData.entities;
}
export const ApplicationDashboardClusterDataParser = (data) => {
    // var project_cluster = new schema.Entity('cluster');
    var project_cluster = new schema.Entity('cluster', {
        project_cluster: [project_cluster]
    })
    const project_namespace = {};

    project_namespace[data.id] = {

    }
    data.project_cluster.forEach(cluster => {

        project_namespace[data.id][cluster.id] = cluster.project_cluster_namespace;
    });


    var applications = new schema.Array(project_cluster)
    var normalizedData = normalize(data, applications);
    normalizedData.entities.project_cluster_namespace = project_namespace;
    return normalizedData.entities;
}

export function LanguageSpecificStageDataParser(data) {
    const ci_stages = {};

    data.forEach(ci_stage => {

        ci_stages[ci_stage.id] = ci_stage;

    });

    return ci_stages;
}
