import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import AppBarHistory from '../../../listing/components/HistoryPageComponents/AppBarHistory';

import JobTriggerHistory from './JobTriggerHistory';
import CommonHorizontalTab from '../../../../../components/genericComponents/CommonHorizontalTab';
import JobTriggerHistoryUpdated from './JobTriggerHistoryUpdated';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const JobTemplateTriggerHistory = ({ configuredJobs, allJobs,service_data,job_code,...props }) => {

    const classes = useStyles();
    const [open, setOpen] = useState(props.open);

    const [state, setState] = useState({
        refresh_count: 1,
        selectedTabOrder: null,
        jobData: null,
        filterData: []
    })

    useEffect(() => {
        setOpen(props.open)
    }, [props.open])

    useEffect(() => {
        let filteredResult = []
        allJobs?.forEach((job, index) => {
            if (configuredJobs[job.job_code]) {

                filteredResult.push({ tabName: job.job_name, order: index })
            }
        })
        setState(prevState => ({
            ...prevState,
            filterData: filteredResult
        }))

    }, [configuredJobs, allJobs])

    console.log('open_001_0pl', props)

    const handleTabUpdate = (order) => {
        setState(prevState => ({
            ...prevState,
            selectedTabOrder: order
        }))
    }

    useEffect(() => {
        let jobName
        let jobCode

        const jobData = allJobs?.find((job, index) => {
            if (index == state.selectedTabOrder) {
                return job;
            }
        })

        console.log(jobName, jobCode, state.selectedTabOrder, 'x_000p_lm')
        setState(prevState => ({
            ...prevState,
            jobData: {
                job_name: jobData?.job_name,
                job_code: jobData?.job_code
            },

        }))
    }, [state.selectedTabOrder])


    useEffect(()=>{
        if(job_code){
            let selectedTabOrder=0
            allJobs?.forEach((job, index) => {
                if (job?.job_code ==job_code) {
                    selectedTabOrder= index;
                }
            })    
            setState(prevState => ({
                ...prevState,
                selectedTabOrder:selectedTabOrder
    
            }))
        }
        else{
            setState(prevState => ({
                ...prevState,
                selectedTabOrder:0
    
            }))
        }
    },[job_code,allJobs])

    console.log(job_code,'xxx_00p')

    const handleRefresh = () => {
        setState(prevState => (
            {
                ...prevState, refresh_count: prevState.refresh_count + 1
            }
        ))
    }
    console.log(service_data,'service_001')

    return (
        <div>
            <Dialog fullScreen open={open} onClose={() => { props.handleClose() }} TransitionComponent={Transition}>
                <AppBarHistory
                    varient='updated-new-v3'
                    job_name={state?.jobData?.job_name}
                    handleClose={props.handleClose}
                    env_id={''}
                    refresh={handleRefresh}
                    service_data={service_data?service_data:{ name: 'main', type: 'DEV' }}
                    service_env_name='Testing Env..'
                />
                <div className="mt-20 mb-15 ml-20">
                    <CommonHorizontalTab
                        updateSelectedTab={handleTabUpdate}
                        selectedTabOrder={state.selectedTabOrder}
                        tabList={state.filterData}
                        // tabList={configuredJobs && Object.keys(configuredJobs)?.map((item, index) => {
                        //     return { tabName: item, order: index }
                        // })}
                        // variant="v3-advance-tabs"
                        variant='round-corner-tab-bulky'
                    />
                </div>
                <div className={classes.caption}>
                    {`${state?.jobData?.job_name} History`}
                </div>


                    {/* <JobTriggerHistory
                        jobData={state.jobData}
                        propsData={props}
                        refresh_count={state.refresh_count}
                    /> */}
                      <JobTriggerHistoryUpdated
                        jobData={state.jobData}
                        service_data={service_data}
                        propsData={props}
                        refresh_count={state.refresh_count}
                    />
            </Dialog>
        </div>
    )
}


JobTemplateTriggerHistory.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default JobTemplateTriggerHistory;

const useStyles = makeStyles((theme) => ({
  
    card: {
        '& .loading-gif-container-two': {
            height: '400px',
            '& .loader-bg-card-2': {
                position: 'relative',
                '& .loader': {
                    top: '0px'
                }
            }
        },
        '& .loading-overlay': {
            position: 'static'
        }
    },
    containerBox: {
        padding: '20px 15px 15px',
        '& button.btn': {
            '&:hover': {
                color: '#0096db!important'
            }
        }
    },
    caption: {
        fontSize: '12px',
        fontWeight: '600',
        lineHeight: '14.63px',
        textAlign: 'left',
        color: '#787878',
        paddingLeft: '20px',
        marginBottom:'12px'
    }
}));