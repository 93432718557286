import React from "react";
import PropTypes from 'prop-types';

import moment from 'moment'




export default function PodEvent(props){
   const events= props.events
    return(
        <div>
        <div className="pd-10">
            <p className="font-15 ">
                Pods Events
            </p>
            <p className="font-11 text-grey-b6">
            Displaying list of all the conditions related to pod
            </p>
        </div>
        <table className="table table-responsive pd-10">
            <tr >
                <th className="text-grey-83">Message</th>
                <th className="text-grey-83">Reason</th>
                <th className="text-grey-83">Type</th>
                <th className="text-grey-83">Last Occurance</th>
            </tr>
            {
                events && events.length > 0 ?
                events.map(event=>(
                    <tr>
                        <td><div className=" text-ellipsis-multiline">{event.message}</div></td>
                        <td>{event.reason}</td>
                        <td>
                            {event.type}
                        </td>
                <td>{moment(event.lastTimestamp).fromNow()}</td>
                    </tr>
                ))
                :
                <tr>
                   
                    <td colSpan="5">
                        <div className="text-center" style={{height: 200, display:'flex', alignItems:'center', justifyContent:'center', width:'100%'}}>
                            <p className="font-13 text-grey-85">No Data Available</p>
                        </div>
                    </td>

                </tr>
            }
        </table>
        </div>
    )
}


PodEvent.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }