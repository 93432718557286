import React, { useState, useEffect } from 'react';
import { styled } from '@material-ui/core/styles';
import { Input } from '../../../components/genericComponents/Input';
import KeyValue from '../../../components/genericComponents/inputs/KeyValues';
import properties from '../../../properties/properties';
import { getCommonFunctions } from '../../serviceRevamp/add/ci_flow/SourceDetails';
import InvokeApi from '../../../util/apiInvoker';
import GenerateURL from '../../../util/APIUrlProvider';

const RunWithParameterForV3 = props => {
    const [open, setOpen] = useState(false);
    const [onlyBuildTasks, setOnlyBuildTasks] = useState(null);

    useEffect(() => {
        props.pipeline_id && fetchAllStagesOfPipeline(props.pipeline_id)
    }, [props.pipeline_id])


    const inherits = props.inherits ? props.inherits : {}
    const [state, setState] = useState({
        data: {
            components: []
        },
        error: {},
        validations: {},
        type_service_branch_data: {},
        current_target_service: null,
        change_service_branch_final_data: {},
        ci_fetch_errors: {},
        edited_daya_by_services: {}
    });


    const commonFunctions = getCommonFunctions(state, setState, inherits)
    const pipeline_id = props.pipeline_id;
    const services = props.services;
    const triggerPipeline = props.triggerPipeline;
    const toggleDismiss = props.toggleDismiss;

    const fetchAllStagesOfPipeline = (pipelineId) => {
        var requestInfo = {
            endPoint: GenerateURL({ pipeline_id: pipelineId }, properties.api.stages_get_url),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, fetchSuccesStages, (error) => console.log(error));

    }

    const fetchSuccesStages = (data) => {

        let allStagesBuildTasks = []

        data && data.forEach(stage => {
            let build_taks = stage && stage.tasks.filter(task => {
                if (task.task_type_id === 1) {
                    return task
                }
            })

            allStagesBuildTasks = [...allStagesBuildTasks, ...build_taks]


        })

        data && setOnlyBuildTasks(allStagesBuildTasks)

    }

    function onChangeHandlerForKeyValue(key, value) {
        updateKeyValue(key, value);
    }

    function updateKeyValue(key, value) {
        setState({
            ...state,
            data: {
                ...state.data,
                [key]: value
            },
            error: {
                ...state.error,
                [key]: null,
            }
        });
    }

    function updateExtraParams(data) {
        const genereated_obj = {};
        if (!data) {
            data = []
        }
        data.forEach(pair => {
            if (pair.key) {
                genereated_obj[pair.key] = pair.value;
            }
        });
        setState({
            ...state,
            data: {
                ...state.data,
                ...genereated_obj,
            }
        });
    }

    function validateAndTrigger() {      
        const error = {};
        var has_error = false;        
        // TODO code to validate
        if (!(state.data.components && state.data.components.length > 0)) {
            error.components = "Atleast One Service should be selected";
            has_error = true;
        }
        if (!has_error) {
            triggerPipeline(pipeline_id, { ...state.data });
        } 
        else {
            setState({
                ...state,
                error: { ...error},
            });
        }
    }

    return (
        <RunParameter>
            <div className='pl-10 pr-10'>
                <Input
                    type="checkbox"
                    // varient="checkbox-with-change-text"
                    name="components"
                    label="Services"
                    mandatorySign
                    list={services && services.map(service => {
                        return { ...service, id: service.name, label: service.name, service_id: service.id }
                    })}
                    data={state.data}
                    error={state.error}
                    onChangeHandler={onChangeHandlerForKeyValue}
                    // onTextClick={handleOnChangeTextClickFun}
                />
            </div>

            {open ?
                <KeyValue callback={updateExtraParams} /> : null
            }
            <SubmitFooter>
                <ButtonSec style={{ justifyContent: 'flex-end' }}>
                    <button className="btn-grey-outline" onClick={toggleDismiss} style={{ backgroundColor: 'transparent' }}>Dismiss</button>
                    <button onClick={validateAndTrigger} className="btn-grey-outline btn-blue-curve">Run Pipeline</button>
                </ButtonSec>
            </SubmitFooter>

        </RunParameter>
    )
}
export default RunWithParameterForV3;


const RunParameter = styled('div')({

    '& .MuiDialog-paperWidthSm': {
        width: '650px'
    },

    '& .with-header': {
        borderTop: '1px solid #dedede'
    },
    '& .grid-layout-controller': {
        gridTemplateColumns: 'auto auto auto auto'
    }
})

const ButtonSec = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
})

const SubmitFooter = styled('div')({
    borderRadius: '0px 0px 15px 15px',
    backgroundColor: '#fff',
    padding: '10px'
})