import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import properties from '../../../../../properties/properties';
import InvokeApi from '../../../../../util/apiInvoker';
import GenerateURL, { GET_Request_Info } from '../../../../../util/APIUrlProvider';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Loading } from '../../../../utils/Loading';
import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import { styled } from '@material-ui/core/styles';

import { Cancel } from '@material-ui/icons';
import ExandableKeyValues from '../../../../../components/hoc/ExpanadleKeyValues';
import { ErrorComponent } from '../../../../utils/Error';
import EditIcon from '@material-ui/icons/Edit';
import Delete from '../../../../../components/genericComponents/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Chip from '../../../../../components/chip/Chip';

function IngressSection(props) {
  const cluster_id = props.cluster_id;

  const [state, setState] = useState({
    loaded: false,
    openDetailView: false
  });

  useEffect(() => {
    fetchIngressInfo();
  }, [cluster_id, state.count]);

  function fetchIngressInfo() {
    const request_info = GET_Request_Info(GenerateURL({ cluster_id: cluster_id }, properties.api.get_ingress_listing));
    InvokeApi(request_info, onIngressFetchSuccess, onIngressFetchFail);
  }

  function onIngressFetchSuccess(data) {
    setState(new_state => ({
      ...new_state,
      data: data,
      openDetailView: data?.length > 0 ? true : false,
      loaded: true,
      filtered_data: filterIngressData(data),
    }));
  }

  function onIngressFetchFail(error) {
    setState(new_state => ({
      ...new_state,
      loaded: true,
      error: error,
    }));
  }

  function filterIngressData(data) {
    const result = {
      public: null,
      private: null,
    }

    data.forEach(ingress => {
      if (ingress.ingress_type.code == "PUBLIC") {
        result.public = ingress;
      } else {
        result.private = ingress;
      }
    });
    return result;
  }

  const toggleDetailView = () => {
    setState(prevState => ({
      ...prevState,
      openDetailView: !prevState.openDetailView
    }))
  }

  console.log(state, 'state_data_22323');
  return (
    <>
      <div className="header-part d-flex align-center pr-15" style={state.openDetailView ? { borderBottom: '1px solid #dedede', justifyContent: 'space-between' } : {borderBottom:'none',  justifyContent: 'space-between'}}>
        <div className="heading-area setup-summary">
          <p className="font-14 font-weight-500 color-primary">Ingress</p>
          <p className="font-12 font-weight-500 color-secondary">Manage external access to the services in your cluster</p>

        </div>
        <div className="right-content" >
          <IconButton>
          {
                state.openDetailView ?
                  <span className='ri-arrow-up-s-line font-24 color-tertiary' onClick={toggleDetailView}></span>
                  :
                  <span className='ri-arrow-down-s-line font-24 color-tertiary' onClick={toggleDetailView}></span>
              }
          </IconButton>
        </div>
      </div>
      {state.openDetailView &&
        <>
          {
            !state.loaded ?
              <>
                <div className="loading-parent-div">
                  <Loading varient="light" />
                </div>
              </>
              :
              <>
                {
                  state.error ?
                    <ErrorComponent error={state.error} />
                    :
                    <div className="d-flex align-center">
                      <UI_Detail refresh={props.refresh} cluster_id={cluster_id} data={state.filtered_data.public} type="PUBLIC" />
                      <UI_Detail refresh={props.refresh} cluster_id={cluster_id} data={state.filtered_data.private} type="PROTECTED" />
                    </div>
                }
              </>
          }
        </>
      }
    </>
  );
}
IngressSection.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

function UI_Detail(props) {
  const data = props.data;
  const type = props.type;
  const cluster_id = props.cluster_id;

  const component_list_annotation = [];
  const iteration_count = 0;
  const kv_list_annotation = [];



  if (data) {
    if (data.k8s)
      Object.keys(data.k8s.metadata.annotations).forEach(anno => {
        kv_list_annotation.push(
          {
            key: anno,
            value: data.k8s.metadata.annotations[anno],
          }
        );
        component_list_annotation.push(
          <Tooltip placement="bottom" title={anno + "=" + data.k8s.metadata.annotations[anno]}>
            <div className="pod-lable">
              {anno + "=" + data.k8s.metadata.annotations[anno]}
            </div>
          </Tooltip>
        );
      });
  }

  return (
    <>
      {
        data ?
          <div className="sub-box-one-summary">
            <div className="sub-header-setup-summary" >
              <p className='font-14 color-primary'>{data.name}</p>

              <div>

                <Link to={"/cluster/" + cluster_id + "/ingress/" + data.id + "/edit"}>
                  <IconButton>
                    <span className='ri-edit-2-line font-20'></span>
                  </IconButton>
                </Link>
                <Delete
                  display_data_name={data.name}
                  data={{ entity_id: data.id, name: "ingress" }}
                  varient="onlyIconNew"
                  refresh={props.refresh}
                  var
                  api_link={GenerateURL({ cluster_id: cluster_id, ingress_id: data.id }, properties.api.get_ingress)}
                />
              </div>
            </div >
            <TempBodyRow>
              {type == "PUBLIC" ?
                <div className="icon-box">
                  <div className="public-icon-box icon-box-top">
                    <span className='ri-lock-unlock-line font-32' style={{ color: '#0086ff' }}></span>

                  </div>
                  <div className="public-icon-box icon-box-bottom">
                    Public
                  </div>
                </div>
                :
                <div className="icon-box">
                  <div className="protected-icon-box icon-box-top" style={{ background: '#E6FBEA' }}>
                    <span className="ri-lock-line font-32" style={{ color: '#2EBE79' }}></span>
                  </div>
                  <div className="protected-icon-box icon-box-bottom">
                    Protected
                  </div>
                </div>
              }
              <div className='font-12'>
                <div className="d-flex align-center"><span className="color-tertiary">Status:&nbsp;</span>
                  <>{
                    data.status == "READY" ?
                      <>
                      <Chip
                      icon={<span className='ri-checkbox-circle-fill font-14 color-success'></span>}
                      label={"CONNECTED"}
                      variant='success'
                      />
                      
                      </>
                      :
                      <>
                      <Chip
                      icon={<span className='ri-checkbox-circle-fill font-14 color-failed'></span>}
                      label={"NOT CONNECTED"}
                      variant='red'
                      />
                     
                      </>
                  } </>
                </div>
                <div className="d-flex">
                  <span className="color-tertiary">URL:&nbsp;</span>
                  <a className="text-blue-anchor text-ellipsis" href={data.url} target="_blank">{data.url} </a>
                </div>
                <div className="d-flex">
                  <span className="color-tertiary">DNS:&nbsp;</span>
                  {data.dns_url ?
                    <a className="text-blue-anchor text-ellipsis" href="buildpiper.io/ingresspublic">{data.dns_url}</a>
                    :
                    <span className="font-12 color-primary">NA</span>}
                </div>
                <div className="d-flex">
                  <span className="color-tertiary">Annotations:&nbsp;</span>
                  <span className="text-blue-anchor" >
                    {
                      component_list_annotation && component_list_annotation.length > 0 ?
                      <ExandableKeyValues
                      compoenent_list={component_list_annotation}
                      iteration_count={iteration_count}
                      labels_list={kv_list_annotation}
                      isLabel={false}
                      expandable_component={
                        <>
                          +{kv_list_annotation.length - iteration_count}
                        </>
                      }
                    />
                    :<span className='font-12 color-primary'>N/A</span>
                    }
                    
                  </span>
                </div>
                <div className="d-flex">
                  <span className="color-tertiary">ACM:&nbsp;</span>
                  <span className="font-12 color-primary">{data.acm_value ? data.acm_value : "N/A"}</span>
                </div>
              </div>
              <div>
                <div className="d-flex">
                  <span className="color-tertiary ml-5">Namespace:&nbsp;</span>
                  <span className="font-12 color-primary">{data.namespace.name}</span>
                </div>
                <div className="d-flex">
                  <span className="color-tertiary ml-5">Image:&nbsp;</span>
                  <span className="font-12 color-primary">{data.k8s ? data.k8s.spec.containers[0].image : "N/A"}</span>
                </div>
              </div>
            </TempBodyRow>
          </div >
          :
          <div className="sub-box-one-summary">
            <TempBodyRowTempTwo>
              {type == "PUBLIC" ?
                <div className="icon-box">
                  <div className="public-icon-box icon-box-top">
                    <span className='ri-lock-unlock-line font-32' style={{ color: '#0086ff' }}></span>
                  </div>
                  <div className="public-icon-box icon-box-bottom">
                    Public
                  </div>
                </div>
                :
                <div className="icon-box">
                  <div className="protected-icon-box icon-box-top" style={{ background: '#E6FBEA' }}>
                    <span className="ri-lock-line font-32" style={{ color: '#2EBE79' }}></span>
                  </div>
                  <div className="protected-icon-box icon-box-bottom">
                    Protected
                  </div>
                </div>
              }
              <div className="blank-section">
                <Link to={"/cluster/" + cluster_id + "/ingress/add/?type=" + type} >
                  <button className='btn btn-outline-primary d-flex align-center'>
                    <span className='ri-settings-4-line font-20'></span>&nbsp;<span>Configure {type} Ingress</span>
                  </button>
                </Link>

              </div>
            </TempBodyRowTempTwo>
          </div>
      }
    </>
  );
}
UI_Detail.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export default IngressSection;


const ITEM_HEIGHT = 48;
export function MoreOption(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const application_id = props.application_id;
  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon style={{ color: '#0096db', fontSize: '2rem' }} />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 'fit-content',
          },
        }}
      >
        <MenuItem selected={Link === 'Dashboard'} onClick={handleClose} style={{ display: "grid" }}>

          <Link to="/namespaces">
            <span className="hover-content-link"><AddIcon style={{ color: '#9e9e9e', fontSize: '16px' }} />&nbsp;Add Ingress</span>
          </Link>
        </MenuItem>
      </Menu>
    </div>
  );
}
MoreOption.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export function CidrIps(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const application_id = props.application_id;
  return (
    <div>
      <span
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        role='button'
        tabIndex={0}
        className="text-blue-anchor"
        onKeyDown={() => { }}
        onClick={handleClick}
      >
        1
      </span>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 'fit-content',
          },
        }}
      >
        <MenuItem selected={Link === 'Dashboard'} onClick={handleClose} style={{ display: "grid" }}>

          <Link to="#">
            <span className="hover-content-link">192.168.1.190</span>
          </Link>
        </MenuItem>
      </Menu>
    </div>
  );
}
CidrIps.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}



const TempBodyRow = styled('div')({
  lineHeight: '1.5',
  fontSize: '12px',
  gap: '10px',
  padding: '20px 10px',
  borderBottom: '1px solid #dedede',
  alignItems: 'flex-start',
  overflow: 'hidden',
  backgroundColor: '#fff',
  display: 'grid',
  gridTemplateColumns: '20% 40% 1fr',
  justifyContent: 'space-between',
  '& .d-flex': {
    overflow: 'hidden',
    '& .text-blue-anchor': {
      '& .chip-default': {
        boxShadow: 'none',
        margin: '0px',
        padding: '0px ',
        fontSize: '12px',
        lineHeight: 1.5,
        display: 'flex',
        color: '#0086ff!important'
      },
      '& .chip-blue': {
        '&:hover': {
          backgroundColor: 'transparent',
          color: '#0086ff!important'
        }
      }
    }
  },
  '&:last-child': {
    border: 'none'
  },
  '& .bullet': {
    position: 'relative',
    top: '3px',
    '&.purple': {
      backgroundColor: '#245dff!important'
    }
  },
  '& .createdat': {
    fontSize: '10px',
    color: '#878787'
  },
  '& .fre-text': {
    fontSize: '11px',
    color: '#000',
    '& .uses-text': {
      fontSize: '9px',
      color: '#878787'
    }
  },
  '& .status-font': {
    color: '#fff',
    width: '40%',
    '& .flaticon-circle-with-check-symbol': {
      '&:before': {
        fontSize: '14px!important'
      }
    }
  },
  '& div': {
    overflow: 'hidden',
    color: '#9e9e9e'
  },
  '& .url': {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
})

const TempBodyRowTempTwo = styled('div')({
  lineHeight: '1.5',
  fontSize: '12px',
  gap: '10px',
  padding: '20px 10px',
  alignItems: 'center',
  overflow: 'hidden',
  backgroundColor: '#fff',
  display: 'grid',
  justifyContent: 'center',
  height: '100%',
  justifyItems: 'center',
  '& .blank-section': {
    justifyContent: 'flex-start'
  }
  ,
  '& .public-icon-box': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& .text-gray': {
    color: '#9e9e9e'
  }
})