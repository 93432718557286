import React, { useEffect, useState } from 'react';
import { Input } from '../../../components/genericComponents/Input';
import { Dialog, Slide, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Link } from 'react-router-dom';
import { getCommonFunctions } from '../../serviceRevamp/add/ci_flow/SourceDetails';
import properties from '../../../properties/properties';
import { Tooltip } from '@material-ui/core';
import GenerateURL, { GenerateSearchURL } from '../../../util/APIUrlProvider';
import InvokeApi from '../../../util/apiInvoker';
import { Loading } from '../../utils/Loading';
import { showErrorHandlerUpdated } from '../../../util/util';
import AlertStrip from '../../../components/AlertStrips';
import useFetchPermissions from '../../../components/customHooks/useFetchPermissions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateDefaultTemplate({ ...props }) {
    const [open, setOpen] = useState(false);
    const inherits = props.inherits ? props.inherits : {}
    const classes = useStyles();
    const [state, setState] = useState({
        data_loading: "LOADING",
        job_template_listing: [],
        default_temp_label: '',
        data: {},
        error: {},
        error_msg: ''
    });




    const createDefaultJobTemplate = props.createDefaultJobTemplate ? props.createDefaultJobTemplate : () => { }

    const commonFunctions = getCommonFunctions(state, setState, inherits)
    const handleClickOpen = () => {

        fetchJobTemplatesInfo();
    }
    const handleClickClose = () => {
        setOpen(false)
    }




    function fetchJobTemplatesInfo(isLoadingNeeded) {
        let myEndPoint = GenerateURL(
            {},
            properties.api.get_global_job_template + "?all=true"
        );
        var requestInfo = {
            endPoint: myEndPoint,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };

        InvokeApi(
            requestInfo,
            jobTemplatesInfoFetchSuccess,
            jobTemplatesInfoFetchFailure
        );

        isLoadingNeeded && setState((new_state) => ({
            ...new_state,
            data_loading: "LOADING",
        }));
        setOpen(true)
    }

    function jobTemplatesInfoFetchSuccess(data) {
        var filtered_list = data.results.map(item => { return { id: item.id, label: item.name, default: item.default } });
        let default_temp_label = filtered_list.find(item => item.default == true && item)
        setState((new_state) => ({
            ...new_state,
            data_loading: "SUCCESS",
            job_template_listing: filtered_list,
            default_temp_label: default_temp_label
        }));

    }
    function jobTemplatesInfoFetchFailure(error, exception) {
        var error_msg = error.error ? error.error : showErrorHandlerUpdated(error)
        setState((new_state) => ({
            ...new_state,
            data_loading: "FAILED",
            error_msg: error_msg,
        }));
    }


    const validateAndChangeDefaultJobTemplate = () => {
       
        createDefaultJobTemplate()
    }

    var url = GenerateURL({ }, properties.api.get_global_job_template, true)
    const is_permitted = useFetchPermissions()?.POST.includes(url);


    return (
        <div className={classes.root_dialog}>
            <div className='d-flex align-center' style={{ gap: "12px" }}>
                <button
                    className={classes.setDefaultjobBtn}
                    onClick={handleClickOpen}
                >
                    <span className='ri-add-line font-24 font-weight-500'></span><span>Create V2/V3 template</span>
                </button>
            </div>
            <Dialog
                open={open}
                onClose={handleClickClose}
                aria-labelledby="max-width-dialog-title" TransitionComponent={Transition}>
                <div className={'card ' + classes.card}>
                    <div className="card-body">
                        {
                            state.data_loading === "LOADING" ?
                                <div style={{ height: '262px' }}>
                                    <Loading varient="light" />
                                </div> :
                                state.data_loading === "SUCCESS" ?
                                    <>
                                        <InfoIcon
                                            className="font-62 text-center m-auto d-block"
                                            style={{ color: '#4783dc', fontSize: '62px', marginBottom: 25 }} />
                                        <p className='font-18 font-weight-300 text-center' style={{ color: '#405660', fontSize: '62px', marginBottom: 25 }}>
                                        Ensure at least one V2 and V3 template is configured.

                                        </p>
                                        <AlertStrip variant="info" message="Please note that  no action will be taken if at least one V2 and one V3 template exists." />
                                    </>
                                    :
                                    <>
                                        <div style={{ height: '262px' }}>
                                            <p className='font-14 font-weight-300 text-center' style={{ color: '#405660', fontSize: '62px', marginBottom: 25 }}>
                                                Unable to load job templates&nbsp;
                                                {state.error_msg}
                                            </p>
                                        </div>
                                    </>
                        }

                    </div>
                    <div className='card-footer justify-end'>
                        <button
                            className='btn btn-outline-grey d-flex align-center'
                            onClick={handleClickClose}
                        >
                            Cancel
                        </button>
                        <button
                            className='btn btn-primary'
                            onClick={() => { validateAndChangeDefaultJobTemplate(); handleClickClose(); }}
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </Dialog>

        </div>
    )
}

const useStyles = makeStyles({
    root_dialog: {
        marginRight:'12px',
        '& .btn-outline-grey-bg': {
            height: '40px',
            border: '1px solid #9DC0EE!important',
            color: '#124D9B!important',
            borderRadius: '6px',
            backgroundColor: '#fff',
            fontWeight: '500',
            '&:hover': {
                color: '#fff!important',
                backgroundColor: '#0086FF!important'
            }
        },
        '& .aligning': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }
    },
    card: {

        '& .card-body': {
            margin: 50,
            // height: '390px',
            '& .font-62': {
                fontSize: '62px'
            },
            '& .input-select-controller': {

            }
        }
    },
    setDefaultjobBtn: {
        color: "#124D9B",
        textAlign: "center",
        
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        textTransform: "uppercase",
        display: "flex",
        height: "40px",
        padding: "8px 16px 8px 12px",
        justifyContent: "center",
        alignItems: "center",
        gap: "6px",
        borderRadius: "6px",
        border: "1px solid #9DC0EE",
        background: "#FFF",

        "&:hover": {

            background: "#0086FF",
            color: "white",
        }
    },
    newJobTemplate: {
        display: "flex",
        height: "40px",
        padding: "11px 16px 11px 12px",
        justifyContent: "center",
        alignItems: "center",
        gap: "6px",
        borderRadius: "6px",
        background: "#0086FF",
        color: "#FFF",
        textShadow: "0px 2px 1px rgba(0, 0, 0, 0.25)",
       
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        textTransform: "uppercase",
        border: "none",

        "&:hover": {
            color: "#fff",
            backgroundColor: "#03234D !important",
            borderColor: "#03234D !important"
        }
    }

});