import React, { useState } from "react";
import PropTypes from 'prop-types';
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";

const MultipleSelectDropdown = ({options, selectedOptions, name, value, id, placeholder, onChangeHandler, label}) => {
  const [selectedValues, setSelectedValues] = useState(selectedOptions);

  const handleSelectOption = (event, values) => {
    setSelectedValues(values);
  };

  const handleRemoveOption = (optionId) => () => {
    setSelectedValues((prevSelectedValues) =>
      prevSelectedValues.filter((option) => option.id !== optionId)
    );
  };

  const renderOption = (option, { selected }) => (
    <>
      <Checkbox checked={selected} />
      {option.label}
    </>
  );

  return (
    <Autocomplete
      multiple
      options={options}
      getOptionLabel={(option) => option.label}
      value={selectedValues}
      onChange={handleSelectOption}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Select options"
          placeholder="Options"
        />
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option) => (
          <Chip
            key={option.id}
            label={option.label}
            onDelete={handleRemoveOption(option.id)}
            {...getTagProps({ index: selectedValues.indexOf(option) })}
          />
        ))
      }
    />
  );
};

MultipleSelectDropdown.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export default MultipleSelectDropdown;