import React from 'react';
import PropTypes from 'prop-types';
import { RemoveFromArray } from '../../../util/util';

const ChipCheckboxList = (props) => {
    const list = props.list ? props.list : [];
    const selected_checkboxes = props.selected_checkboxes ? props.selected_checkboxes : [];
    const name = props.name;
    const variant = props.variant;
    const onChangeHandler = props.onChangeHandler;
    const handleChange = (e) => {
        let return_list = selected_checkboxes;
        if (e.target.checked) {
            return_list.push(e.target.value);
            onChangeHandler(name, return_list);
        } else {
            return_list = RemoveFromArray(return_list, Number(e.target.value) ? Number(e.target.value) : e.target.value);
            onChangeHandler(name, return_list)
        }
    }

    const selectAll = (e) => {
        let return_list = [];
        if (e.target.checked) {
            list.forEach(element => {
                return_list.push(element.id);
            });
        }

        onChangeHandler(name, return_list);
    }
    function getClassNamebyStatus(status) {
        switch (status) {
            case "SUCCESS":
                return "success-mi";
            case "FAILED":
                return "failed-mi";
            case "IN_QUEUE":
                return "initiated-mi";
            case "RUNNING":
                return "running-mi";
            case "REVOKED":
                return "revoked-mi";
            default:
                return "";
        }
    }
    function getIconByStatus(status) {
        switch (status) {
            case "SUCCESS":
                return <span className="material-icons">
                    check_circle_outline
                </span>;
            case "FAILED":
                return <span className="material-icons">
                    error_outline
                </span>;
            case "IN_QUEUE":
                return <span className="material-icons">
                    schedule
                </span>;
            case "RUNNING":
                return <span className="material-icons">
                    timelapse
                </span>;
            case "REVOKED":
                return <span className="material-icons">
                    stop
                </span>;
            default:
                return "";
        }
    }
    function showActiveBackground(status) {
        if (status === "SUCCESS" && selected_checkboxes.includes(status)) {
            return "bg-success";
        }
        if (status === "FAILED" && selected_checkboxes.includes(status)) {
            return "bg-failed";
        }
        if (status === "IN_QUEUE" && selected_checkboxes.includes(status)) {
            return "bg-initiated";
        }
        if (status === "RUNNING" && selected_checkboxes.includes(status)) {
            return "bg-running";
        }
        if (status === "REVOKED" && selected_checkboxes.includes(status)) {
            return "bg-revoked";
        }

    }
    console.log('display list >>> ',list);
    return (
        <>
            {
                variant === "square-box" ?
                    
                        <div className="input-square-checkbox">


                            {
                                list.map(element => (
                                    <label
                                        htmlFor={element.id}
                                        className={'input-checkbox-group ' + showActiveBackground(element.id)}

                                    >
                                        <input
                                            type="checkbox"
                                            className={getClassNamebyStatus(element.id)}
                                            id={element.id}
                                            checked={selected_checkboxes.includes(element.id)}
                                            onChange={handleChange}
                                            name={name}
                                            value={element.id}
                                            label={element.label}
                                        />
                                        <span className={getClassNamebyStatus(element.id)} >{getIconByStatus(element.id)}</span>
                                        <span className='input-count'>{element.label}</span>
                                    </label>

                                ))
                            }
                        </div>
                    
                    :
                    <div>
                        <div className="input-chip-checkbox">
                            {/* <FormControlLabel control={<Checkbox checked={list.length == selected_checkboxes.length} onChange={selectAll} name={name} value={""} />} label={"All"} /> */}
                            <input
                                type="checkbox"
                                checked={list.length == selected_checkboxes.length}
                                onChange={selectAll}
                                name={name}
                                value={""}
                                id="myCheckbox" />
                            <label htmlFor="myCheckbox"> All</label>
                        </div>
                        <div className="divider"></div>
                        <div className="input-chip-checkbox">
                            {
                                list.map(element => (
                                    <>
                                        <input
                                            type="checkbox"
                                            id={element.id}
                                            checked={selected_checkboxes.includes(element.id)}
                                            onChange={handleChange}
                                            name={name}
                                            value={element.id}
                                            label={element.label}
                                        />
                                        <label htmlFor={element.id}> {element.label}</label>
                                    </>

                                ))
                            }
                        </div>

                    </div>
            }
        </>

    )
}

ChipCheckboxList.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default ChipCheckboxList;



