import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import SquareAvatar from '../../../../components/genericComponents/AvatarSquare'
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import { StepWizard } from '../../../../components/Step Wizard/StepWizard';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import InvokeApi, { PostData } from '../../../../util/apiInvoker';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import ConfigSecret, { getConfigMapDefaultState, getSecretComponentDefaultState, getConfigMapBodyDefaultState, getSecretBodyDefaultState, getEnabledComponentEntryPointDefaultState, getPvcComponentDefaultState, getPVSsBodyDefaultState, getEnabledComponentCommandAndCommandArgsDefaultState, getConfigMapSubPathDefaultState, getSecretSubPathDefaultState, getConfigMapKeyDefaultState, getSecretKeyDefaultState, getConfigMapKeyNSubPathBody, getSecretKeyNSubPathBody } from './ConfigSecret';
import { getMultiRowDefaultState } from '../../../../components/genericComponents/MultiRow';
import { getEnvCdEmptyDirList, getEnvCdHostPathList, getEnvDeployVarsWithCategories, getEnvDeployVarsWithCategoriesSidecars } from './CdInfo';
import EnvVar, {
    getEnvVarDefaultState, getEnabledComponentSecretDefaultState, getEnabledComponentConfigMapDefaultState, getEnabledComponentRawInputDefaultState,
    getSecretInputBodyDefaultState, getConfigInputBodyDefaultState, getRawInputBodyDefaultState
} from './EnvVar';
import { Redirect } from 'react-router-dom'
import { Loading } from '../../../utils/Loading';

import { getConfigSecretDefaultSidecarState } from './ConfigSecret';
import { getEmptyDirComponentDefaultState } from './EnabledComponentEmptyDir';
import { getHostpathComponentDefaultState } from './EnabledComponentHostpath';
import ResourceQuotaContainer, {getResourceQuotaDefaultState} from './ResourceQuotaContainer';
import MountConfig, { getMountDetailsDefaultState, getSideCarMountDetailsDefaultState } from './MountConfig';

const SideCarsInfo = (props) => {
    const classes = useStyles();
    const location = useLocation();
    const queryString = require('query-string');
    var parsed = queryString.parse(props.location.search);
    var extraProps = props.location ? props.location.state ? { ...props.location.state.clone_env_id } : { service_name: "", project_env_name: "", environment_master_name: "" } : { service_name: "", project_env_name: "", environment_master_name: "" };
    const clone_env_id = props.location ? props.location.state ? props.location.state.clone_env_id : null : null;
    const env_cd_detail_id = props?.location?.state?.env_cd_detail_id;
    const env_cd_id = props?.location?.query?.env_cd_id;
    // const sidecar_id = props?.location?.state?.sidecar_id;
    // const service_env_info = props?.location?.query?.service_env_data;
    const { application_id, service_id, component_env_id, cd_id, edit_id } = useParams();
    const [state, setState] = useState({
        data: {},
        error: {},
    })
    const [hostPathList, setHostPathList] = useState([]);
    const [emptyDirList, setEmptyDirList] = useState([]);
    const [canRedirect, setRedirect] = useState(false);

    const panel_list = [
        // {
        //     order: 1,
        //     mainText: 'Access Level',
        //     body: AccessLevel
        // },
        {
            order: 1,
            mainText: 'Request Quota',
            body: ResourceQuotaContainer
        },
        {
            order: 2,
            mainText: 'Configuration & Secrets',
            body: ConfigSecret
        },
        {

            order: 3,
            mainText: 'Mount Details',
            body: MountConfig

        },
        {
            order: 4,
            mainText: 'Env Variables',
            body: EnvVar
        },
        // {
        //     order: 5,
        //     mainText: 'Liveness/Readiness',
        //     body: LivenessAndReadiness
        // },
    ]

    var serviceName;

    useEffect(() => {
        getComponentDetails();
    }, []);

    useEffect(() => {
        fetchCiData();
    }, [state.multiple_build])


    function fetchCiData() {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: service_id, component_env_id: component_env_id }, properties.api.save_ci),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        console.log(state.multiple_build, "mbcnbznbzcbnz")
        if (state.multiple_build) {
            requestInfo.endPoint = GenerateURL({ service_id : service_id, component_env_id: component_env_id }, properties.api.get_ci_child)
        }
        setState(new_state => ({
            ...new_state,
            data_loading: true
        }))
        InvokeApi(requestInfo, onFetchCiDataSuccess, onFetchCiDataFail)
    }

    function onFetchCiDataSuccess(response) {
        console.log(response, "rtyudfgcv ================>")
        var api_data = [...response]

        setState(new_state => ({
            ...new_state,
            data_loading: false,
            data: response,
            parent_ci_data: response[0],
            ci_configured: response.length > 0 ? true : false,
            child_build_configured: response.length > 1 ? true : false,
            child_build_data: response.length > 1 ? getChildBuildList(api_data) : null
        }));
    }

    function onFetchCiDataFail() {
        setState(new_state => ({
            ...new_state,
            data_loading: false,
        }));
    }

    function getChildBuildList(data) {
        var new_arr = data;
        new_arr.shift()
        return data
    }

    console.log(state,"skbdcjhbdshcbhd")

    function getExistingHostpathEntries() {

        var requestInfo = {
            endPoint: GenerateURL({ service_id: Number(service_id), env_id: Number(component_env_id), cd_id: Number(cd_id) }, properties.api.predefined_hostpath),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, fetchSuccessHostpathListing, fetchFailHosthpathListing);

    }

    function fetchSuccessHostpathListing(response) {
        const secrets = []
        response.forEach(element => {
            secrets.push({ label: element.name, id: element.name, mount_path: element.mount_path })
        });
        setHostPathList(secrets)
    }
    function fetchFailHosthpathListing(error) {
        console.log("SADads")
    }

    function loadHostPathList() {
        var secrets = hostPathList.length == 0 ? getExistingHostpathEntries() : null
    }
    function getExistingEmptyDirEntries() {
        console.log("fdsafdsafdsaadf")
        var requestInfo = {
            endPoint: GenerateURL({ service_id: Number(service_id), env_id: Number(component_env_id), cd_id: cd_id }, properties.api.predefined_emptydir),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        console.log("fdsafdsafdsaadf")
        InvokeApi(requestInfo, fetchSuccessEmptyDirListing, fetchFailEmptyDirListing);

    }

    function fetchSuccessEmptyDirListing(response) {
        console.log("fdsafdsafdsaadf")
        const secrets = []
        response.forEach(element => {
            secrets.push({ label: element.name, id: element.name, mount_path: element.mount_path })
        });
        setEmptyDirList(secrets)
    }

    function fetchFailEmptyDirListing(error) {
        console.log("SADads")
    }

    function loadEmptyDirList() {
        var secrets = emptyDirList.length == 0 ? getExistingEmptyDirEntries() : null
    }
    console.log(emptyDirList, hostPathList, "fdsafdsafdsaadf")

    function getComponentDetails() {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: service_id }, properties.api.service_basic_details_get_url),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, handleResponse, handleError)
    }

    function handleResponse(response) {
        serviceName = response.name;

        fetchEnvList()
        setState(new_state => ({
            ...new_state,
            service_name: response.name,
            build_strategy: response.build_strategy,
        }));
    }

    function handleError(error) {
        return error;
    }

    function fetchEnvList() {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: service_id }, properties.api.env_data_post_url),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, onEnvListFetchSuccess, onEnvListFetchFail)
    }

    function onEnvListFetchSuccess(response) {
        const selectedEnvData = getSelectedEnv(component_env_id, response);
        fetchSystemSettingsData();
        extraProps = {
            namespace_name: selectedEnvData.project_env.project_env_namespace.namespace.name,
            namespace_type: selectedEnvData.project_env.project_env_namespace.namespace.istio_enabled,
            cluster_id: selectedEnvData.project_env.cluster_id,
            project_env_name: selectedEnvData.project_env.name,
            environment_master_name: (properties.envs[selectedEnvData.project_env.environment_master_id - 1].label).toLowerCase(),
            service_name: state.service_name ? state.service_name : serviceName,
            hookType: 'deploy',
            env_cd_id: env_cd_detail_id ? env_cd_detail_id : parsed && parsed.env_cd_detail_id ? parsed.env_cd_detail_id : env_cd_id ? env_cd_id : null
        }
        setState(new_state => ({
            ...new_state,
            data: {
                selectedServiceEnv: selectedEnvData,
                envData: response,

            },
            extraProps: {
                namespace_name: selectedEnvData.project_env.project_env_namespace.namespace.name,
                istio_enabled: selectedEnvData.project_env.project_env_namespace.namespace.istio_enabled,
                cluster_id: selectedEnvData.project_env.cluster_id,
                project_env_name: selectedEnvData.project_env.name,
                project_env_id: selectedEnvData.project_env.id,
                environment_master_name: (properties.envs[selectedEnvData.project_env.environment_master_id - 1].label).toLowerCase(),
                service_name: new_state.service_name,
                hookType: 'deploy',
                component_env_id: component_env_id,
                service_id: service_id,
                application_id: application_id,
                env_cd_id: env_cd_detail_id ? env_cd_detail_id : parsed && parsed.env_cd_detail_id ? parsed.env_cd_detail_id : env_cd_id ? env_cd_id : null
            }
        }));
        // let url = (window.location.href).split("?");
        // var updatedUrl = url[0];
        // if ((updatedUrl).toString().charAt((updatedUrl).toString().length - 2) == "d") {
        //     if (clone_env_id) {
        //         fetchCdData(clone_env_id)
        //     }
        // } else {
        //     fetchCdData();
        // }
    }

    function onEnvListFetchFail() {
    }

    function getSelectedEnv(id, data) {
        var selectedEnv = {};
        data.forEach(env => {
            if (env.id == id) {
                selectedEnv = { ...env }
            }
        })
        return selectedEnv;
    }

    function fetchSystemSettingsData() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.system_settings),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, fetchSystemSettingsDataSuccess, fetchSystemSettingsDataFailed);

        setState(new_state => ({
            ...new_state,
            data_loading: true
        }))

    }

    function fetchSystemSettingsDataSuccess(data) {
        console.log(data, "sdnjhsdbchjbsdcbshd")
        loadEmptyDirList();
        loadHostPathList();
        var filter_cd_versioning_data = data.filter(item => item.key == "VERSIONING_CD_ENABLE");
        var multiple_build_data = data.find(item => item.key === "MULTIPLE_BUILD_CONFIGURATION")
        setState(new_state => ({
            ...new_state,
            multiple_build: multiple_build_data.value == "true" ? true : false,
            available_settings: data,
            cd_settings: filter_cd_versioning_data,
            data_loading: false
        }));

    }
    function fetchSystemSettingsDataFailed(error) {
        setState(new_state => ({
            ...new_state,
            error: error,
            data_loading: false
        }))
    }

    useEffect(() => {
        if (edit_id) {
            fetchCdData();
        }

    }, []);

    function fetchCdData(clone_env_id) {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: service_id, component_env_id: clone_env_id ? clone_env_id : component_env_id, cd_id: cd_id }, properties.api.save_side_cars),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        setState(new_state => ({
            ...new_state,
            data_loading: true
        }));
        InvokeApi(requestInfo, onEditFetchSuccess, onEditFetchFail);

    }

    function onEditFetchSuccess(response) {
        let findCurrentContainerData = response.find(item => item.id === Number(edit_id));

        setState(new_state => ({
            ...new_state,
            panels: panelFetchDataParser(findCurrentContainerData, clone_env_id, extraProps),
            data_loading: false,
            sidecar_id: findCurrentContainerData?.id
        }));
    }

    function onEditFetchFail() {
        setState(new_state => ({
            ...new_state,
            data_loading: false
        }));

    }


    function getSpecifyLimitQuota(data) {
        if (data.resource_quota.limits_cpu_quota != data.resource_quota.requests_cpu_quota || data.resource_quota.limits_memory_quota != data.resource_quota.requests_memory_quota) {
            return true
        }
        else {
            return false
        }
    }

    function panelFetchDataParser(data, clone_env_id, extraProps) {

        console.log(data, 'fdsafdsafdsaadf');
        var panels = {};
        const service_name = extraProps ? extraProps.service_name : "";
        const env_master = extraProps ? extraProps.environment_master_name : "";
        const project_env_name = extraProps ? extraProps.project_env_name : "";

        panels["1"] = {
            ...getResourceQuotaDefaultState(),
            data: {
                container_type: data.container_type,
                requests_memory_quota: data.resource_quota.requests_memory_quota,
                requests_cpu_quota: data.resource_quota.requests_cpu_quota,
                specify_limit_quota: getSpecifyLimitQuota(data),
                auto_scaling_enabled: data.env_cd_scale ? true : false,
                desired_replication: data.desired_replication,
                tag: data.tag,
                image_name: data.image_name,
                container_name: data.name,
                limits_cpu_quota: data.resource_quota.limits_cpu_quota,
                limits_memory_quota: data.resource_quota.limits_memory_quota,
                child_build_enabled : data.env_ci_detail_id ? true : false,
                env_ci_detail_id : data.env_ci_detail_id
            }
        }

        panels["2"] = {
            ...getConfigSecretDefaultSidecarState(),
            data: {
                configMap_file_path: data.env_cd_init_container_configmap && data.env_cd_init_container_configmap.length > 0 ?
                    data.env_cd_init_container_configmap.length >= 1 && data.env_cd_init_container_configmap[0].mount_path == null ? false : true : false,
                secret_file_path: data.env_cd_init_container_secret && data.env_cd_init_container_secret.length > 0 ?
                    data.env_cd_init_container_secret.length >= 1 && data.env_cd_init_container_secret[0].mount_path == null ? false : true : false,
                configMap_sub_path: data.env_cd_init_container_configmap_keys && data.env_cd_init_container_configmap_keys.length > 0 ?
                    data.env_cd_init_container_configmap_keys.length >= 1 && data.env_cd_init_container_configmap_keys[0].name == null ? false : true : false,
                secret_sub_path: data.env_cd_init_container_secret_keys && data.env_cd_init_container_secret_keys.length > 0 ?
                    data.env_cd_init_container_secret_keys.length >= 1 && data.env_cd_init_container_secret_keys[0].name == null ? false : true : false,
                // entrypoint_command: data.entrypoint ? true : false,
                // pvc_file_path: data.env_cd_init_container_volume_mount && data.env_cd_init_container_volume_mount.length > 0 ?
                //     data.env_cd_init_container_volume_mount.length >= 1 && data.env_cd_init_container_volume_mount[0].mount_path == null ? false : true : false,
                // define_empty_dir: data.env_cd_init_container_empty_dir && data.env_cd_init_container_empty_dir.length > 0 ?
                //     data.env_cd_init_container_empty_dir.length >= 1 && data.env_cd_init_container_empty_dir[0].mount_path == null ? false : true : false,
                // define_hostpath: data.env_cd_init_container_host_path && data.env_cd_init_container_host_path.length > 0 ?
                // data.env_cd_init_container_host_path.length >= 1 && data.env_cd_init_container_host_path[0].mount_path == null ? false : true : false,
                // entrypoint_command_args: data.command || data.cmd_args ? true : false
            },
            secret_file_childs: data.env_cd_init_container_secret ? {
                ...getSecretComponentDefaultState(),
                secert_childs: {
                    ...getMultiRowDefaultState(),
                    ...getEnvCdSecretsList(data.env_cd_init_container_secret)
                },
            } : {},
            configMap_file_childs: data.env_cd_init_container_configmap ? {
                ...getConfigMapDefaultState(),
                configMap_childs: {
                    ...getMultiRowDefaultState(),
                    ...getEnvCdCongigMapsList(data.env_cd_init_container_configmap),

                }
            } : {},
            configMap_sub_childs: data.env_cd_init_container_configmap_keys ? {
                ...getConfigMapSubPathDefaultState(),
                configMap_SubPath_childs: {
                    ...getMultiRowDefaultState(),
                    ...getConfigMapKeyDefaultState(),
                    ...getEnvCdConfigMapKeyList(data.env_cd_init_container_configmap_keys),
                }
            } : {},
            secret_sub_childs: data.env_cd_init_container_secret_keys ? {
                ...getSecretSubPathDefaultState(),
                secret_SubPath_childs: {
                    ...getMultiRowDefaultState(),
                    ...getSecretKeyDefaultState(),
                    ...getEnvCdSecretKeyList(data.env_cd_init_container_secret_keys),
                }
            } : {}
            // pvc_file_childs: data.env_cd_init_container_volume_mount ? {
            //     ...getPvcComponentDefaultState(),
            //     pvc_childs: {
            //         ...getMultiRowDefaultState(),
            //         ...getEnvCdPvcsMapsList(data.env_cd_init_container_volume_mount),
            //     }
            // } : {},
            // empty_dir_childs: data.env_cd_init_container_empty_dir ? {
            //     ...getEmptyDirComponentDefaultState(),
            //     secert_childs: {
            //         ...getMultiRowDefaultState(),
            //         ...getEnvCdEmptyDirList(data.env_cd_init_container_empty_dir, emptyDirList),

            //     }
            // } : {},
            // host_path_childs: data.env_cd_init_container_host_path ? {
            //     ...getHostpathComponentDefaultState(),
            //     hostpath_form_childs: {
            //         ...getMultiRowDefaultState(),
            //         ...getEnvCdHostPathList(data.env_cd_init_container_host_path, hostPathList),

            //     }
            // } : {},
            // command_and_command_args: {
            //     ...getEnabledComponentCommandAndCommandArgsDefaultState(),
            //     data: {
            //         command: data.command,
            //         cmd_args: data.cmd_args
            //     }
            // },
        }

        // var envVarCategories = getEnvDeployVarsWithCategories(data.env_cd_init_container_env_variable)
        var envVarCategories = getEnvDeployVarsWithCategoriesSidecars(data.env_cd_init_container_env_variable)
        var configMapsAsEnvVar = data.env_cd_init_container_configmap && data.env_cd_init_container_configmap.length > 0 ? data.env_cd_init_container_configmap.filter(item => item.mount_path == null) : []
        var secretAsEnvVar = data.env_cd_init_container_secret && data.env_cd_init_container_secret.length > 0 ? data.env_cd_init_container_secret.filter(item => item.mount_path == null) : []

        // console.log(envVarCategories, "data_0001_for_edit")
        panels["3"] = {
            ...getSideCarMountDetailsDefaultState(),
            data: {
                entrypoint_command: data.entrypoint ? true : false,
                pvc_file_path: data.env_cd_init_container_volume_mount && data.env_cd_init_container_volume_mount.length > 0 ?
                    data.env_cd_init_container_volume_mount.length >= 1 && data.env_cd_init_container_volume_mount[0].mount_path == null ? false : true : false,
                define_empty_dir: data.env_cd_init_container_empty_dir && data.env_cd_init_container_empty_dir.length > 0 ?
                    data.env_cd_init_container_empty_dir.length >= 1 && data.env_cd_init_container_empty_dir[0].mount_path == null ? false : true : false,
                define_hostpath: data.env_cd_init_container_host_path && data.env_cd_init_container_host_path.length > 0 ?
                    data.env_cd_init_container_host_path.length >= 1 && data.env_cd_init_container_host_path[0].mount_path == null ? false : true : false,
                entrypoint_command_args: data.command || data.cmd_args ? true : false
            },
            pvc_file_childs: data.env_cd_init_container_volume_mount ? {
                ...getPvcComponentDefaultState(),
                pvc_childs: {
                    ...getMultiRowDefaultState(),
                    ...getEnvCdPvcsMapsList(data.env_cd_init_container_volume_mount),
                }
            } : {},
            empty_dir_childs: data.env_cd_init_container_empty_dir ? {
                ...getEmptyDirComponentDefaultState(),
                secert_childs: {
                    ...getMultiRowDefaultState(),
                    ...getEnvCdEmptyDirList(data.env_cd_init_container_empty_dir, emptyDirList),

                }
            } : {},
            host_path_childs: data.env_cd_init_container_host_path ? {
                ...getHostpathComponentDefaultState(),
                hostpath_form_childs: {
                    ...getMultiRowDefaultState(),
                    ...getEnvCdHostPathList(data.env_cd_init_container_host_path, hostPathList),

                }
            } : {},
            command_and_command_args: {
                ...getEnabledComponentCommandAndCommandArgsDefaultState(),
                data: {
                    command: data.command,
                    cmd_args: data.cmd_args
                }
            },
        }

        panels["4"] = {
            ...getEnvVarDefaultState(),
            data: {
                // "env_deploy_variable_enabled":true,
                "deploy_variable_configMap": (configMapsAsEnvVar.length > 0 || envVarCategories.configMaps.length > 0) ? true : false,
                "deploy_variable_raw_input": envVarCategories.keyVal.length > 0 ? true : false,
                "deploy_variable_secret": (envVarCategories.secrets.length > 0) || (secretAsEnvVar.length > 0) ? true : false
            },
            env_var_secret: {
                ...getEnabledComponentSecretDefaultState(),
                secert_envvar: {
                    ...getMultiRowDefaultState(),
                    ...getSecretEnvVars(data.env_cd_init_container_secret, envVarCategories.secrets)
                }
            },
            env_var_configMap: {
                ...getEnabledComponentConfigMapDefaultState(),
                configMap_envvar: {
                    ...getMultiRowDefaultState(),
                    ...getConfigMapEnvVars(data.env_cd_init_container_configmap, envVarCategories.configMaps)
                }
            },
            env_var_raw_input: {
                ...getEnabledComponentRawInputDefaultState(),
                multi_row: {
                    ...getMultiRowDefaultState(),
                    ...getKeyValEnvVars(envVarCategories.keyVal)
                }
            }

        }

        var only_pre_hooks_list = new Array();
        if (data.env_cd_hook && data.env_cd_hook.length > 0) {
            data.env_cd_hook.forEach(item => {
                if (item.action_type == "PRE") {
                    only_pre_hooks_list.push(item);
                }
                return only_pre_hooks_list;
            })
        }
        var only_post_hooks_list = new Array();
        if (data.env_cd_hook && data.env_cd_hook.length > 0) {
            data.env_cd_hook.forEach(item => {
                if (item.action_type == "POST") {
                    only_post_hooks_list.push(item);
                }
                return only_post_hooks_list;
            })
        }
        return panels;
    }


    function getEnvCdSecretsList(secrets) {
        var secretsList = {};
        var count = 1;
        var child_inherits = {}

        secrets.forEach(secret => {
            if (secret.mount_path != null) {
                secretsList = {
                    ...secretsList, [count]: {
                        ...getSecretBodyDefaultState(),
                        data: {
                            secret_name: secret.name,
                            secret_mount_path: secret.mount_path,
                            secret_id: secret.id
                        }
                    }
                }
                child_inherits = { ...child_inherits, [count++]: {} }
            }
        });
        return { ...secretsList, child_inherits: child_inherits };
    }

    function getEnvCdCongigMapsList(configMaps) {
        var configMapsList = {};
        var count = 1;
        var child_inherits = {}
        configMaps.forEach(configMap => {
            if (configMap.mount_path != null) {
                configMapsList = {
                    ...configMapsList, [count]: {
                        ...getConfigMapBodyDefaultState(),
                        data: {
                            configMap_name: configMap.name,
                            configMap_mount_path: configMap.mount_path,
                            configMap_id: configMap.id
                        }
                    }
                }
                child_inherits = { ...child_inherits, [count++]: {} }
            }


        });
        return { ...configMapsList, child_inherits: child_inherits };
    }

    function getEnvCdConfigMapKeyList(configMapKeys) {
        var configMapNkeys = {};
        let count = 1;
        var child_inherits = {};
        configMapKeys.forEach(configMapkey => {
            if (configMapkey.name != null) {
                configMapNkeys = {
                    ...configMapNkeys, [count]: {
                        ...getConfigMapKeyDefaultState(),
                        data: {
                            comfigMap_for_subpath: configMapkey.name,
                        },
                        configMap_keys_child: {

                            ...getConfigMapKeyNSubPathData(configMapkey.sub_path_key_value),
                        }
                    }
                }
                child_inherits = { ...child_inherits, [count++]: {} }
            }
        });
        return { ...configMapNkeys, child_inherits: child_inherits }
    }

    function getConfigMapKeyNSubPathData(configMapKeyNSubPathList) {
        var configMapKeyNSubPath = {};
        let count = 1;
        var child_inherits = {};
        configMapKeyNSubPathList.forEach(configkeyNsub => {
            if (configkeyNsub.name != null) {
                configMapKeyNSubPath = {
                    ...configMapKeyNSubPath, [count]: {
                        ...getConfigMapKeyNSubPathBody(),
                        data: {
                            configMap_key: configkeyNsub.name,
                            sub_path: configkeyNsub.sub_path,
                        }
                    }
                }
                child_inherits = { ...child_inherits, [count++]: {} }
            }
        });
        return { ...configMapKeyNSubPath, child_inherits: child_inherits }
    }

    function getEnvCdSecretKeyList(secretKeys) {
        var secretNKeys = {};
        let count = 1;
        var child_inherits = {};
        secretKeys.forEach(secretkey => {
            if (secretkey.name != null) {
                secretNKeys = {
                    ...secretNKeys, [count]: {
                        ...getSecretKeyDefaultState(),
                        data: {
                            secret_for_subpath: secretkey.name,
                        },
                        secret_keys_child: {

                            ...getSecretKeyNSubPathData(secretkey.sub_path_key_value),
                        }
                    }
                }
                child_inherits = { ...child_inherits, [count++]: {} }
            }
        });
        return { ...secretNKeys, child_inherits: child_inherits }
    }

    function getSecretKeyNSubPathData(secretKeyNSubPathList) {
        var secretKeyNSubPath = {};
        let count = 1;
        var child_inherits = {};
        secretKeyNSubPathList.forEach(secretkeyNsub => {
            if (secretkeyNsub.name != null) {
                secretKeyNSubPath = {
                    ...secretKeyNSubPath, [count]: {
                        ...getSecretKeyNSubPathBody(),
                        data: {
                            secret_key: secretkeyNsub.name,
                            sub_path: secretkeyNsub.sub_path,
                        }
                    }
                }
                child_inherits = { ...child_inherits, [count++]: {} }
            }
        });
        return { ...secretKeyNSubPath, child_inherits: child_inherits }
    }

    function getEnvCdPvcsMapsList(configMaps) {
        console.log(configMaps, "data_0001_for_edit")
        var configMapsList = {};
        var count = 1;
        var child_inherits = {}
        configMaps.forEach(configMap => {
            if (configMap.mount_path != null) {
                configMapsList = {
                    ...configMapsList, [count]: {
                        ...getPVSsBodyDefaultState(),
                        data: {
                            pvc_name: configMap.name,
                            pvc_path: configMap.mount_path,
                            pvc_id: configMap.id
                        }
                    }
                }
                child_inherits = { ...child_inherits, [count++]: {} }
            }


        });
        return { ...configMapsList, child_inherits: child_inherits };
    }

    function getSecretEnvVars(secret_with_mount_file, secrets) {
        var secretsList = {};
        var count = 1;
        var child_inherits = {}
        secret_with_mount_file.forEach(secret => {
            if (secret.mount_path == null) {
                secretsList = {
                    ...secretsList, [count]: {
                        ...getSecretInputBodyDefaultState(),
                        data: {
                            secret_name: secret.name,
                            as_env_var: secret.mount_path == null ? true : false,
                            secret_id: secret.id
                        }
                    }
                }
                child_inherits = { ...child_inherits, [count++]: {} }
            }
        });
        if (secrets) {
            secrets.forEach(secret => {
                secretsList = {
                    ...secretsList, [count]: {
                        ...getSecretInputBodyDefaultState(),
                        data: {
                            ...secret
                        }
                    }
                }
                child_inherits = { ...child_inherits, [count++]: {} }
            });

        }
        return { ...secretsList, child_inherits: child_inherits };

    }

    function getConfigMapEnvVars(config_with_mount_file, configMaps) {
        var configMapsList = {};
        var child_inherits = {}
        var count = 1;
        config_with_mount_file.forEach(configMap => {
            if (configMap.mount_path == null) {
                configMapsList = {
                    ...configMapsList, [count]: {
                        ...getConfigInputBodyDefaultState(),
                        data: {
                            configMap_name: configMap.name,
                            as_env_var: configMap.mount_path == null ? true : false,
                            configMap_id: configMap.id
                        }
                    }
                }
                child_inherits = { ...child_inherits, [count++]: {} }
            }


        });
        if (configMaps) {
            configMaps.forEach(configMap => {
                configMapsList = {
                    ...configMapsList, [count]: {
                        ...getConfigInputBodyDefaultState(),
                        data: {
                            ...configMap
                        }
                    }
                }
                child_inherits = { ...child_inherits, [count++]: {} }

            });
        }
        return { ...configMapsList, child_inherits: child_inherits };
    }

    function getKeyValEnvVars(keyVals) {
        var keyValsList = {};
        var count = 1;
        var child_inherits = {}
        if (keyVals) {
            keyVals.forEach(keyVal => {
                keyValsList = {
                    ...keyValsList, [count]: {
                        ...getRawInputBodyDefaultState(),
                        data: {
                            ...keyVal
                        }
                    }
                }
                child_inherits = { ...child_inherits, [count++]: {} }

            });
        }
        return { ...keyValsList, child_inherits: child_inherits };
    }


    function onSubmit(panel_object) {
        console.log(panel_object, "dbcsdbcdhsbmcb")
        var post_data_final = {
            env_cd_detail_id: cd_id,
            name: state.service_name + "-" + state.extraProps.environment_master_name + "-" + state.extraProps.project_env_name
        };
        // console.log(panel_object, "panel obj ===========>")
        Object.keys(panel_object).forEach(panel => (
            post_data_final = {
                ...post_data_final, ...panelPostDataParser(
                    panel,
                    panel_object[panel],
                    post_data_final.env_cd_init_container_configmap ? post_data_final.env_cd_init_container_configmap : null,
                    post_data_final.env_cd_init_container_secret ? post_data_final.env_cd_init_container_secret : null,
                    post_data_final.env_cd_init_container_volume_mount ? post_data_final.env_cd_init_container_volume_mount : null,
                    post_data_final.env_cd_empty_dir ? post_data_final.env_cd_empty_dir : null,
                    post_data_final.env_cd_host_path ? post_data_final.env_cd_host_path : null,
                    post_data_final.env_cd_init_configmap_keys ? post_data_final.env_cd_init_configmap_keys : null,
                    post_data_final.env_cd_secret_init_keys ? post_data_final.env_cd_init_secret_keys : null,
                    state.cd_settings)
            }
        ))

        console.log(post_data_final, "final post data ===========>")

        if (state.sidecar_id) {
            PostData(GenerateURL({ service_id: service_id, component_env_id: component_env_id, cd_id: cd_id, side_car_id: state.sidecar_id }, properties.api.edit_side_car), post_data_final, onSaveSuccess, onSaveFail);
        } else {
            PostData(GenerateURL({ service_id: service_id, component_env_id: component_env_id, cd_id: cd_id }, properties.api.save_side_cars), post_data_final, onSaveSuccess, onSaveFail);
        }

        setState(new_state => ({
            ...new_state,
            data_loading: true
        }));
    }

    function onSaveSuccess(response) {
        // console.log(response, "response on save ============>")
        setRedirect(true);
        setState(new_state => ({
            ...new_state,
            data_loading: false
        }));
    }

    function onSaveFail(error) {
        setState(new_state => ({
            ...new_state,
            data_loading: false
        }));
    }

    function panelPostDataParser(panel_no, panel_data, env_cd_configmap, env_cd_secret, env_cd_pvc_variable, env_cd_empty_dir, env_cd_host_path, env_cd_configmap_keys, env_cd_secret_keys, cd_settings) {
        var post_data = {}
        var env_cd_configmap = env_cd_configmap ? env_cd_configmap : [];
        var env_cd_secret = env_cd_secret ? env_cd_secret : [];
        var env_cd_pvc_variable = env_cd_pvc_variable ? env_cd_pvc_variable : []
        var env_cd_empty_dir = env_cd_empty_dir ? env_cd_empty_dir : []
        var env_cd_host_path = env_cd_host_path ? env_cd_host_path : []
        var env_cd_configmap_keys = env_cd_configmap_keys ? env_cd_configmap_keys : [];
        var env_cd_secret_keys = env_cd_secret_keys ? env_cd_secret_keys : [];
        // console.log(panel_data, "fghjvb ==========>")

        // eslint-disable-next-line default-case
        switch (panel_no) {
            case "0":
                post_data = { ...post_data, ...panel_data.data };
                console.log(panel_data.data, "panel_data.data")
                return post_data;
            case "1":
                post_data = {
                    ...post_data,
                    image_name: panel_data.data.env_ci_detail_id ? null : panel_data.data.image_name,
                    name: panel_data.data.container_name,
                    tag: panel_data.data.env_ci_detail_id ? null : panel_data.data.tag,
                    container_type: panel_data.data.container_type,
                    env_ci_detail_id : panel_data.data.env_ci_detail_id ? Number(panel_data.data.env_ci_detail_id) : null
                }
                post_data.resource_quota = {
                    ...post_data.resource_quota,
                    requests_memory_quota: panel_data.data.requests_memory_quota,
                    requests_cpu_quota: panel_data.data.requests_cpu_quota,
                    limits_cpu_quota: panel_data.data.specify_limit_quota ? panel_data.data.limits_cpu_quota : panel_data.data.requests_cpu_quota,
                    limits_memory_quota: panel_data.data.specify_limit_quota ? panel_data.data.limits_memory_quota : panel_data.data.requests_memory_quota,
                }

                return post_data;

            case "2":
                // var env_cd_configmap = [];
                if (panel_data.configMap_file_childs && panel_data.configMap_file_childs.configMap_childs) {
                    Object.keys(panel_data.configMap_file_childs.configMap_childs).forEach(key => {
                        if (key == "data" || key == "child_inherits" || key == "show_view" || key == "count") {

                        } else {
                            const configMap = panel_data.configMap_file_childs.configMap_childs[key].data;

                            var configMapData = {
                                name: configMap.configMap_name,
                                mount_path: configMap.configMap_mount_path,
                            }
                            if (configMap.id) {
                                configMapData.id = configMap.id
                            }
                            env_cd_configmap = [...env_cd_configmap, configMapData]

                        }
                    })
                }
                post_data.env_cd_init_container_configmap = env_cd_configmap;
                if (panel_data.configMap_sub_childs && panel_data.configMap_sub_childs.configMap_SubPath_childs) {

                    Object.keys(panel_data.configMap_sub_childs.configMap_SubPath_childs).forEach(key => {
                        if (key == "data" || key == "child_inherits" || key == "show_view" || key == "count" || key == "error" || key == "validation") {

                        } else {
                            const configMapObject = panel_data.configMap_sub_childs.configMap_SubPath_childs[key];
                            console.log("jnskncsdmk", configMapObject)
                            let sub_path_key_value = [];
                            let configMapKeyPathData = {
                                name: configMapObject.data.comfigMap_for_subpath,
                            }

                            if (configMapObject && configMapObject.configMap_keys_child) {
                                Object.keys(configMapObject.configMap_keys_child).forEach(key => {
                                    if (key == "data" || key == "child_inherits" || key == "show_view" || key == "count" || key == "error" || key == "validation") {

                                    } else {
                                        const configMapKeyPathObject = configMapObject.configMap_keys_child[key].data;

                                        let subPathObject = {
                                            name: configMapKeyPathObject.configMap_key,
                                            sub_path: configMapKeyPathObject.sub_path,
                                        }
                                        sub_path_key_value = [...sub_path_key_value, subPathObject]
                                    }
                                })
                            }
                            if (sub_path_key_value.length > 0) {
                                configMapKeyPathData.sub_path_key_value = sub_path_key_value;
                            }

                            env_cd_configmap_keys = [...env_cd_configmap_keys, configMapKeyPathData];
                        }
                    })
                }

                post_data.env_cd_init_container_configmap_keys = env_cd_configmap_keys;

                if (panel_data.secret_sub_childs && panel_data.secret_sub_childs.secret_SubPath_childs) {

                    Object.keys(panel_data.secret_sub_childs.secret_SubPath_childs).forEach(key => {
                        if (key == "data" || key == "child_inherits" || key == "show_view" || key == "count" || key == "error" || key == "validation") {

                        } else {
                            const secretObject = panel_data.secret_sub_childs.secret_SubPath_childs[key];
                            let sub_path_key_value = [];
                            let secretKeyPathData = {
                                name: secretObject.data?.secret_for_subpath,
                            }

                            if (secretObject && secretObject.secret_keys_child) {
                                Object.keys(secretObject.secret_keys_child).forEach(key => {
                                    if (key == "data" || key == "child_inherits" || key == "show_view" || key == "count" || key == "error" || key == "validation") {

                                    } else {
                                        const secretKeyPathObject = secretObject.secret_keys_child[key].data;

                                        let subPathObject = {
                                            name: secretKeyPathObject.secret_key,
                                            sub_path: secretKeyPathObject.sub_path,
                                        }
                                        sub_path_key_value = [...sub_path_key_value, subPathObject]
                                    }
                                })
                            }
                            if (sub_path_key_value.length > 0) {
                                secretKeyPathData.sub_path_key_value = sub_path_key_value;
                            }

                            env_cd_secret_keys = [...env_cd_secret_keys, secretKeyPathData];
                        }
                    })
                }

                post_data.env_cd_init_container_secret_keys = env_cd_secret_keys;

                // var env_cd_secret = [];
                if (panel_data.secret_file_childs && panel_data.secret_file_childs.secert_childs) {
                    Object.keys(panel_data.secret_file_childs.secert_childs).forEach(key => {
                        if (key == "data" || key == "child_inherits" || key == "show_view" || key == "count") {

                        } else {
                            const secret = panel_data.secret_file_childs.secert_childs[key].data;
                            var secretData = { "name": secret.secret_name, "mount_path": secret.secret_mount_path }
                            if (secret.id) {
                                secretData.id = secret.id
                            }
                            env_cd_secret = [...env_cd_secret, secretData]
                        }
                    })
                }
                post_data.env_cd_init_container_secret = env_cd_secret;

                return post_data;
            case "3":
                if (panel_data.empty_dir_childs && panel_data.empty_dir_childs.secert_childs) {
                    Object.keys(panel_data.empty_dir_childs.secert_childs).forEach(key => {
                        if (key == "data" || key == "child_inherits" || key == "show_view" || key == "count") {

                        } else {
                            const emptyDir = panel_data.empty_dir_childs.secert_childs[key].data;
                            var emptyDirData = { "name": emptyDir.name, "mount_path": emptyDir.mount_path }
                            if (emptyDir.id) {
                                emptyDirData.id = emptyDir.id
                            }
                            env_cd_empty_dir = [...env_cd_empty_dir, emptyDirData]
                        }
                    })
                }
                post_data.env_cd_init_container_empty_dir = env_cd_empty_dir;
                if (panel_data.host_path_childs && panel_data.host_path_childs.hostpath_form_childs) {
                    Object.keys(panel_data.host_path_childs.hostpath_form_childs).forEach(key => {
                        if (key == "data" || key == "child_inherits" || key == "show_view" || key == "count") {

                        } else {
                            const hostPathEntry = panel_data.host_path_childs.hostpath_form_childs[key].data;

                            var hostFormData = { "name": hostPathEntry.name, "mount_path": hostPathEntry.mount_path, "host_path": hostPathEntry.host_path }
                            if (hostPathEntry.id) {
                                hostFormData.id = hostPathEntry.id
                            }
                            env_cd_host_path = [...env_cd_host_path, hostFormData]
                        }
                    })
                }
                post_data.env_cd_init_container_host_path = env_cd_host_path;

                if (panel_data.pvc_file_childs && panel_data.pvc_file_childs.pvc_childs) {
                    Object.keys(panel_data.pvc_file_childs.pvc_childs).forEach(key => {
                        if (key == "data" || key == "child_inherits" || key == "show_view" || key == "count") {

                        } else {
                            const pvcs = panel_data.pvc_file_childs.pvc_childs[key].data;

                            var pvcsData = {
                                pvc_name: pvcs.pvc_name,
                                pvc_path: pvcs.pvc_path,
                            }
                            if (pvcsData.id) {
                                pvcsData.id = pvcs.id
                            }
                            env_cd_pvc_variable = [...env_cd_pvc_variable, pvcsData]

                        }
                    })
                }
                post_data.env_cd_init_container_volume_mount = env_cd_pvc_variable;

                if (panel_data.data.entrypoint_command_args &&  panel_data.command_and_command_args) {
                    post_data = { ...post_data, ...panel_data.command_and_command_args.data }
                } else {
                    post_data = {
                        ...post_data,
                        command: null,
                        command_args: null
                    }
                }
                return post_data;
            case "4":
                var env_cd_deploy_variable = [];
                // var env_cd_configmap =[];
                // var env_cd_secret = [];
                if (panel_data.env_var_raw_input && panel_data.env_var_raw_input.multi_row) {
                    Object.keys(panel_data.env_var_raw_input.multi_row).forEach(key => {
                        if (key == "data" || key == "child_inherits" || key == "show_view" || key == "count") {

                        } else {
                            env_cd_deploy_variable = [...env_cd_deploy_variable, panel_data.env_var_raw_input.multi_row[key].data]
                        }
                    })

                }
                if (panel_data.env_var_configMap && panel_data.env_var_configMap.configMap_envvar) {
                    Object.keys(panel_data.env_var_configMap.configMap_envvar).forEach(key => {
                        if (key == "data" || key == "child_inherits" || key == "show_view" || key == "count") {

                        } else {
                            var configMapEntry = panel_data.env_var_configMap.configMap_envvar[key].data;
                            if (configMapEntry.as_env_var) {
                                var configMapData = {
                                    name: configMapEntry.configMap_name,
                                    mount_path: null,
                                }
                                if (configMapEntry.id) {
                                    configMapData.id = configMapEntry.id
                                }
                                env_cd_configmap = [...env_cd_configmap, configMapData]
                                post_data.env_cd_init_container_configmap = env_cd_configmap;
                            } else {
                                env_cd_deploy_variable = [...env_cd_deploy_variable, {
                                    env_key: configMapEntry.configMap_service_key,
                                    value_from_configmap: {
                                        name: configMapEntry.configMap_name,
                                        key: configMapEntry.configMap_key
                                    }
                                }]
                            }
                        }
                    })

                }
                if (panel_data.env_var_secret && panel_data.env_var_secret.secert_envvar) {
                    Object.keys(panel_data.env_var_secret.secert_envvar).forEach(key => {
                        if (key == "data" || key == "child_inherits" || key == "show_view" || key == "count") {

                        } else {
                            var secretEntry = panel_data.env_var_secret.secert_envvar[key].data;
                            console.log(secretEntry, "secretEntrysecretEntry")
                            if (secretEntry.as_env_var) {
                                var secretData = { "name": secretEntry.secret_name, "mount_path": secretEntry.secret_mount_path }
                                if (secretEntry.id) {
                                    secretData.id = secretEntry.id
                                }
                                env_cd_secret = [...env_cd_secret, secretData]
                                post_data.env_cd_init_container_secret = env_cd_secret;
                            } else {
                                env_cd_deploy_variable = [...env_cd_deploy_variable, {
                                    env_key: secretEntry.secret_service_key,
                                    value_from_secret: {
                                        name: secretEntry.secret_name,
                                        key: secretEntry.secret_key
                                    }
                                }]
                            }
                        }
                    })

                }
                post_data.env_cd_init_container_env_variable = env_cd_deploy_variable;
                return post_data;
        }
    }

    console.log(state,"bcjhdsbchjsdhcsdhchbsd")
    return (
        <div className={classes.root}>
            <div className="service-form-heading-section mb-20 d-flex align-center space-between">
                <div className="d-flex align-baseline">
                    <SquareAvatar name="Service Name" varient="single" />
                    <span className="span-class">
                        <span style={{marginBottom:'10px'}} className="service-name">Sidecar details for {state.service_name}</span>
                        <div className="d-flex align-center">
                            <span className="text-black font-12">Build Strategy:&nbsp;</span>
                            <span className="text-color-grey font-12">{state.build_strategy == "EVERY" ? "Build for all environments" : "Build once and promote"}&nbsp;&nbsp;</span>
                            <span className="text-black font-12">Env Name:&nbsp;</span>
                            <span className="text-color-grey font-12">{state?.extraProps?.project_env_name}&nbsp;&nbsp;</span>
                            <span className="text-black font-12">Environment Master:&nbsp;</span>
                            <span className="text-color-grey font-12">{state?.extraProps?.environment_master_name}</span>
                        </div>
                    </span>
                </div>
                <Link to={"/application/" + application_id + "/service/" + service_id + "/detail?deploy_details=true&env_id=" + component_env_id}>
                    <IconButton>
                        <CloseIcon fontSize="large" />
                    </IconButton>
                </Link>
            </div>

            <div className="card hide-first-child" style={{ padding: '0px', overflow: 'hidden' }}>
                {
                    state.data_loading ?
                        <div className="d-flex" style={{ height: '690px' }}><Loading varient="light" /></div> :
                        state.extraProps ?
                            <StepWizard
                                dataJson={panel_list}
                                currentTab={1}
                                prev_state={state.panels ?
                                    {
                                        ...state,
                                        selectedTabOrder: parsed.selectedTabOrder ?
                                            Number(parsed.selectedTabOrder) :
                                            props.location ? props.location.query ?
                                                props.location.query.selectedTabOrder : 1 : 1
                                    } :
                                    null}
                                onSubmit={onSubmit}
                                extraProps={state.extraProps ? state.extraProps : extraProps}
                                type="sidecar"
                                child_build_data={state.child_build_data}
                            />
                            :
                            null
                }
            </div>
            {
                canRedirect ? <Redirect to={"/application/" + application_id + "/service/" + service_id + "/detail?deploy_details=true&env_id=" + component_env_id} /> : null
            }
        </div>
    )
}

SideCarsInfo.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }
export default SideCarsInfo;

const useStyles = makeStyles(theme => ({
    root: {
        margin: 20,
        '& > .card': {
            backgroundColor: '#f6f8f8',
            '& .card-header': {
                '& .md-stepper-horizontal': {
                    backgroundColor: '#f4f4f4'
                }
            },
            '& .card-footer': {
                backgroundColor: '#fff',
                justifyContent: 'flex-end'
            },
            '& .card ': {
                margin: '10px',
                '& .headerul': {
                    borderBottom: '1px solid #dedede'
                },
                boxShadow: 'none',
                '& .MuiGrid-item': {
                    '& .input-component': {
                        marginBottom: '10px'
                    },
                },
                '& .input-component': {
                    marginBottom: '0px'
                },
                '& .input-cont': {
                    '& .headerul': {
                        borderBottom: '0px'
                    }
                },

            },
            '& .card-add-service': {
                '& .image-upload-wrap': {
                    height: '50px'
                }
            }
        }
    }
}))