import { Popover, Tooltip, makeStyles } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'

const SelectWithInsideLabel = ({noWidth,...props}) => {
    const classes = useStyles({noWidth});
    const rootRef = useRef(null);
    const label = props.label;
    const list = props.list;
    const selectDeployment = props.onChangeHandler;
    const SelectedDeployment = props.SelectedDeployment;
    const optionsWidth = props.optionsWidth;
    console.log("dnfjjsd",list,SelectedDeployment);
    const selectedObject = list && list.find((ele)=>ele.id == SelectedDeployment); 
    console.log(selectedObject,"dnfjjsd")
    const [selectedItem,setSelectedItem] = useState(null);

    useEffect(() => {
        setSelectedItem(selectedObject)
    },[selectedObject])

    console.log(selectedItem,"dnfjjsd")
    
    const optionList = [
        {id: 1,name: "Deployment 1"},
        {id: 2,name: "Deployment 2"},
        {id: 3,name: "Deployment 3"}
    ]
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        console.log("ndjnks",anchorEl);
        setAnchorEl(null);
    };
    
    const open = Boolean(anchorEl);

    const selectItem = (item)=>{
        setSelectedItem(item);
        selectDeployment(item.id);
        handleClose();
    }
  return (
    <>
    <div ref={rootRef} className={classes.root} onClick={handleClick}>
        <span className='font-12 font-weight-500 d-flex select-label'>{label} <span className='font-12 font-weight-500 selected-option ml-4'>{selectedItem?selectedItem.label:"Please Select"}</span> </span>
        <span className='d-flex align-center'>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M10.0006 10.9766L14.1256 6.85156L15.3039 8.0299L10.0006 13.3332L4.69727 8.0299L5.8756 6.85156L10.0006 10.9766Z" fill="#2F2F2F"/>
            </svg>
        </span>
    </div>
    <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
        }}
        transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
        }}
    >
            <div className={classes.options} style={{width: `${optionsWidth-2}px`}}>
                {list.map((ele,i)=>{
                        {console.log(ele,selectedItem,"bsdjbhjcsbcsdb")}
                        return <Tooltip title={ele.label}>
                            <span className={ ele?.id == selectedItem?.id ? 'item font-12 d-flex align-center selected-serv text-ellipsis'  : 'item font-12 d-flex align-center text-ellipsis'} style={{width: `${optionsWidth-24}px`}} onClick={()=>selectItem(ele)}>{ele.label}</span>
                        </Tooltip>
                            
                    
                    
                })}
            </div>
        
    </Popover>
    </>
    
  )
}

export default SelectWithInsideLabel

const useStyles = makeStyles((theme)=>({
    root: {
        display: "flex",
        width: "100%",
        padding: "8px 8px 8px 12px",
        justifyContent: "space-between",
        alignItems: "center",
        flex: "1 0 0",
        borderRadius: "6px",
        border: "1px solid #E6E6E6",
        background: "#FFF",
        cursor: "pointer",
        '& .select-label': {
            color: "#605F6D",
            textTransform: "capitalize",
            textWrap: 'nowrap',
            width: "90%",
            '& .selected-option': {
                color: "#0E0C27",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textTransform: "none",
                width: (props)=> props.noWidth ? '' :"65%",
            },
        }
    },
    options: {
        display: "flex",
        flexDirection: "column",
        padding: "6px 12px",
        width: "100%",
        borderRadius: "6px",
        '& .item': {
            padding: "4px 8px",
            height: "30px",
            borderRadius: "4px",
            cursor: "pointer",
            whiteSpace: "nowrap",
            "&:hover": {
                background: "#ebe9e9",
            }
        },
        '& .selected-serv': {
            background: "#F5FAFF",
            '&:hover': {
                background: "#F5FAFF",
            },
        },
    }
}))