import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import SquareAvatar from '../../../../../components/genericComponents/AvatarSquare';

const JobTemplateDetailCard = props => {
    const classes = useStyles();
    const { onSelect, data,selectedJobTemplateId } = props;

    const style = (selectedJobTemplateId && selectedJobTemplateId ===data.id )? { border: "2px solid blue" } : {}
    console.log(data, 'dj_d')
    return (
        <div role='button' tabIndex={0}  style={style} className={classes.root + " " + "card"} onKeyDown={()=>{}} onClick={() => onSelect(data.id)}>
            <div className="card-body pd-10">
                <div className="d-grid grid-temp">
                    <div className="mt-5">
                        <SquareAvatar shape="lg-box" varient="double" name={data.name} />
                    </div>
                    <p className="font-16 pd-5 pt-0">
                        <div>{data.name}</div>
                        <Tooltip title={data.description ? data.description : 'N/A'}>
                            <div className="job-des lh-12 font-11 text-grey-83 mb-15 text-ellipsis-twoline">
                                {data.description}
                            </div>
                        </Tooltip>
                    </p>
                </div>

            </div>
            <div className="card-footer-div bg-white space-between">

                <div className="d-flex align-center">
                    {data.user ?
                        <span className="chip-default " style={{ backgroundColor: "#788695" }}>{data.user.name}</span> : <span className="chip-default chip-chip-blue" >bp-official</span>
                    }
                </div>
            </div>
        </div>
    )
}


JobTemplateDetailCard.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }


const useStyles = makeStyles({
    root: {
        position: 'relative',
        overflow: 'visible',
        '& .default-template-chip': {
            backgroundColor: '#ff9000',
            fontSize: '13px',
            color: '#fff',
            padding: '15px',
            position: 'absolute',
            right: '0px',
            top: '-10px',
            borderRadius: '0px 8px 0px 8px',
            zIndex: '999',

        },
        '& .card-body': {
            height: '100px',
            cursor: 'pointer'
        },

        '& .card': {
            backgroundColor: '#fff',
            borderRadius: '8px',
            boxShadow: 'rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px',
            position: 'relative',
            zIndex: 9,
            overflow: 'hidden',
            border: '1px solid #dedede',
            cursor: 'pointer'
        },
        '&.card:hover': {
            border: '2px solid blue',

        },
        '& .grid-temp': {
            gridTemplateColumns: '60px 1fr'
        },
        '& .card-footer-div': {
            borderTop: '1px solid #dedede',
            padding: '1rem 1.5rem',
            backgroundColor: '#f4f4f4',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderBottomRightRadius: '8px',
            borderBottomLeftRadius: '8px',
            cursor: 'pointer'
        },
        '& .chip-default': {
            backgroundColor: '#48a3e3',
            color: '#fff!important',
            borderRadius: '4px!important'
        },

    }
});

export default JobTemplateDetailCard;