let getApplication = () => { return null };
let updateUserDataForNavigation = () => { return null };
let getCluster = () => { return null };
let updateClusterDataForNavigation = () => { return null };

export function GetApplication() {
    return getApplication();
}

export function UpdateUserDataForNavigation() {
    return updateUserDataForNavigation();
}

export function GetCluster() {
    return getCluster();
}

export function UpdateClusterDataForNavigation() {
    return updateClusterDataForNavigation();
}

export function SetGetApplication(newGetApplication) {
    getApplication = newGetApplication;
}

export function SetUpdateUserData(newUpdateUserData) {
    updateUserDataForNavigation = newUpdateUserData;
}

export function SetGetCluster(newGetCluster) {
    getCluster = newGetCluster;
}

export function SetUpdateClusterData(newUpdateClusterData) {
    updateClusterDataForNavigation = newUpdateClusterData;
}
