import React from 'react'
import GenericSkeleton from '../../../components/genericComponents/Skeletons/GenericSkeleton'
import { Grid } from '@material-ui/core'

const JiraTicketSkeleton = ({ count, animation,style,rootStyle }) => {
  return (
    <Grid container spacing={2} >
                        <>
                            <Grid item xs={12}>
                            <GenericSkeleton variant={"rect"} width={"100%"} height={"60px"} count={count} animation={animation} style={{lineHeight: "2"}} rootStyle={rootStyle}/>
                            </Grid>
                            <Grid item xs={12}>
                            <GenericSkeleton variant={"rect"} width={"100%"} height={"60px"} count={count} animation={animation} style={{lineHeight: "2"}} rootStyle={rootStyle}/>
                            </Grid>
                           
                            <Grid item xs={9}>
                            <GenericSkeleton variant={"rect"} width={"100%"} height={"60px"} count={count} animation={animation} style={{lineHeight: "2"}} rootStyle={rootStyle}/>

                            </Grid>
                            <Grid item xs={3} style={{ alignContent: 'center' }}>
                            <GenericSkeleton variant={"rect"} width={"100%"} height={"60px"} count={count} animation={animation} style={{lineHeight: "2"}} rootStyle={rootStyle}/>
                            </Grid>
                          
                                    <Grid item xs={12}>
                                    <GenericSkeleton variant={"rect"} width={"100%"} height={"60px"} count={count} animation={animation} style={{lineHeight: "2"}} rootStyle={rootStyle}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <GenericSkeleton variant={"rect"} width={"100%"} height={"60px"} count={count} animation={animation} style={{lineHeight: "2"}} rootStyle={rootStyle}/>
                                    </Grid>
                                
                            
                        </>

                        <div className='d-flex align-center justify-end' style={{ width: '100%', position: 'absolute', bottom: '20px', right: '20px' }}>
                            <GenericSkeleton variant={"rect"} width={"90px"} height={"40"} count={count} animation={animation} style={{lineHeight: "2"}} rootStyle={rootStyle}/>
                            <GenericSkeleton variant={"rect"} width={"70px"} height={"40"} count={count} animation={animation} style={{lineHeight: "2"}} rootStyle={rootStyle}/>
                        </div>
                    </Grid>
  )
}

export default JiraTicketSkeleton