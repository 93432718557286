import { makeStyles } from '@material-ui/core'
import React, { memo, useEffect, useState } from 'react'
import GenericSkeleton from '../../../../components/genericComponents/Skeletons/GenericSkeleton';
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import properties from '../../../../properties/properties';
import { Input } from '../../../../components/genericComponents/Input';

const CommitIdBlock = ({service,addServiceData,selectedMasterEnv}) => {
    const classes = styles()
    const [hoveredCommitId, setHoveredCommitId] = useState(null); 
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [commitList, setCommitList] = useState([]);
    const [error, setError] = useState(null);
    const serviceName = service.component_name;
    const serviceCommitId = service.commit_id;

    const loadComponentCommits = () => {
        let requestInfo = {
            endPoint: GenerateURL({}, properties.api.load_commits + `?service=${serviceName}`),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        if(!service.default_branch){
            requestInfo.endPoint = GenerateURL({},properties.api.load_commits + `?service=${serviceName}&branch=${service.branch}`);
        }else{
            requestInfo.endPoint = GenerateURL({}, properties.api.load_commits + `?service=${serviceName}&branch=`);
        }
        setLoading(true);
        InvokeApi(requestInfo, loadComponentCommitsSuccess, loadComponentCommitsFailed);
    }

    const loadComponentCommitsSuccess = (response) => {
        setLoading(false);
        let commitsList = response.commit_list.map(item => {
            return { id: item, label: item.slice(0,7) };
        });
        setData((prevData) => ({
            ...prevData,
            default_commit: false, 
            commit_id: serviceCommitId ? serviceCommitId : response?.head_commit,
            ui_commit_id: serviceCommitId ? serviceCommitId.slice(0,7) : response?.head_commit.slice(0,7),
        }));
        setError((error) => ({
            ...error,
            commit_id: ''
        }));
        setCommitList(commitsList);
    }

    const loadComponentCommitsFailed = (error) => {
        const error_msg = error.toString();
        setError((error) => ({
            ...error,
            mainError: error_msg,
        }));
        setLoading(false);
    }

    const onKeyValueHandler = (k, v) => {
        setData((prevData) => ({
            ...prevData,
            [k]: v,
            ui_commit_id: v.slice(0,7),
        }));
    }

    useEffect(() => {
        addServiceData(service, data);
    }, [data]);

    useEffect(()=>{
        setData({})
        setCommitList([]);
        setError(null);
    },[selectedMasterEnv])
    useEffect(()=>{
        if(service.commit_id){
            setData((prevData)=>({
                ...prevData,
                commit_id:service.commit_id,
                ui_commit_id:service.ui_commit_id,
            }))
        }
    },[service.commit_id])


    return (
        <div className='d-flex align-center' style={{gap:"10px"}}
            onMouseEnter={() => setHoveredCommitId(true)}
            onMouseLeave={() => setHoveredCommitId(false)}
        >
            {error && error.mainError ? 
                <div className={classes.errorBox}>
                    <span>{error.mainError}</span>
                    <span className='ri-close-fill font-20 cursor-pointer' style={{ lineHeight: '1' }} onClick={()=>setError(null)}></span>
                </div>
            :
            commitList.length > 0 ? 
                <div className={classes.autoInput} style={{ width: '90%' }}>
                    <Input
                        type="auto-complete-dropdown"
                        id={"git_urlsZ"}
                        name="commit_id"
                        list={commitList.length > 0 ? commitList : []}
                        getOptionLabel={(option) => option.label}
                        error={error}
                        data={data}
                        onChangeHandler={onKeyValueHandler}
                    />
                </div>
                :
                loading ? 
                    <GenericSkeleton width={'90%'} height={37} style={{ borderRadius: '4px' }} />
                :
                <div className={classes.defaultBox}>
                    <span className='font-14 font-weight-400 color-value'>{service.ui_commit_id || 'Checkout from head'}</span>
                    {hoveredCommitId && 
                        <span className='ri-edit-2-line font-20 color-key-78 cursor-pointer' style={{ lineHeight: '1' }} onClick={() => loadComponentCommits()}></span>
                    }
                </div>
            }
        </div>
        
    )
}

export default memo(CommitIdBlock)

const styles = makeStyles((theme)=>({
    defaultBox:{
        width:'90%',
        padding:'8px 12px 8px 12px',
        borderRadius:'4px',
        backgroundColor:'#FAFAFA',
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
    },
    errorBox: {
        width:'90%',
        padding:'8px 12px 8px 12px',
        borderRadius:'4px',
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
        color: "#BA0000",
        border: '1px solid #FBE6E6',
        background: '#FFF3F3',
    },
    autoInput:{
        '& .input-component .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
            padding: "9.5px 4px !important",
        },
        '& .input-component':{
            marginBottom: '0px !important',
        },
          
        '& .input-component .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
            padding: "10.2px 4px !important",
        },
        '& .input-component .MuiAutocomplete-endAdornment':{
            top:'calc(50% - 12px)',
            position:'absolute !important',
            right: '0 !important'
        }
    }
}))