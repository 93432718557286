import { Grid, makeStyles, styled } from '@material-ui/core';
import React, { useState } from 'react';
import UI_BasicInfo from './BasicInfo';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DetailFormWizards from './DetailFormWizard';
import { PostData } from '../../../../../util/apiInvoker';
import GenerateURL from '../../../../../util/APIUrlProvider';
import properties from '../../../../../properties/properties';
import { ErrorComponent } from '../../../../utils/Error';
import { Loading } from '../../../../utils/Loading';
import StepProgressDetailed from '../../../../../components/genericComponents/StepProgressDetailed';

function AddNewCluster() {
    const basic_form_inherits = {};
    const detail_form_inherits = {};
    const classes = useStyles();
    const [state, setState] = useState({});

    function validateData() {
        const basic_form_result = basic_form_inherits.validateForm();
        const detail_form_result = detail_form_inherits.validateForm();
        return {
            valid: (basic_form_result.valid && detail_form_result.valid),
        };
    }

    function backClickHandler() {
        if (detail_form_inherits.getSelectedTabOrder && (detail_form_inherits.getSelectedTabOrder() != 1)) {
            detail_form_inherits.moveBack();
        }
    }

    function nextClickHandler() {
        const result = validateData();
        if (result.valid) {
            if (detail_form_inherits.getSelectedTabOrder) {
                if (detail_form_inherits.getSelectedTabOrder() == 3) {
                    const request_data = TransferDataForAPI(detail_form_inherits.getData(), basic_form_inherits.getData().cluster_name);
                    PostData(GenerateURL({}, properties.api.post_cluster_data), request_data, onSuccessHandler, onFailHandler);
                    setState(new_state => ({
                        ...new_state,
                        cluster_post_status: "REQUESTED"
                    }));
                } else {
                    detail_form_inherits.moveNext();
                }

            }
        }
    }

    function onSuccessHandler(data) {
        // todo code to handle success
        setState(new_state => ({
            ...new_state,
            cluster_post_status: "RECEIVED",
            task_id: data.task_id
        }));
    }

    function onFailHandler(error) {
        backClickHandler();
        setState(new_state => ({
            ...new_state,
            cluster_post_status: "FAILED",
            post_error: error
        }));
    }
    function refresh(error) {
        setState(new_state => ({
            ...new_state,
            cluster_post_status: "retry",
            post_error: error
        }));
    }



    return (
        <Grid container className="pd-20">
            <Grid item lg={12} >
                <UI_Heading />
            </Grid>
            {
                state.cluster_post_status ?
                    state.cluster_post_status == "REQUESTED" ?
                        <Loading varient="light" />
                        :
                        state.cluster_post_status == "RECEIVED" ?
                            <StepProgressDetailed task_id={state.task_id} placeholders={{}} refresh={refresh} />
                            :
                            state.cluster_post_status == "FAILED" ?
                                // <ErrorComponent error={state.post_error} />
                                <Grid item lg={12} >
                                    <ErrorComponent error={state.post_error} />
                                    <div className={classes.card}>
                                        <div className={classes.cardHeader}>
                                            <Grid item lg={12}>

                                                <UI_BasicInfo inherits={basic_form_inherits} />
                                            </Grid>
                                        </div>
                                        <div className={classes.cardBody}>
                                            <DetailFormWizards inherits={detail_form_inherits} />
                                            <DetailsFooter>
                                                <button onClick={backClickHandler} className="btn-grey-navi hover-state">Back</button>
                                                <button onClick={nextClickHandler} className="btn-blue-navi hover-state">Continue <ChevronRightIcon /></button>
                                            </DetailsFooter>
                                        </div>
                                    </div>
                                </Grid>
                                :
                                <Grid item lg={12} >
                                    <div className={classes.card}>
                                        <div className={classes.cardHeader}>
                                            <Grid item lg={12}>
                                                <UI_BasicInfo inherits={basic_form_inherits} />
                                            </Grid>
                                        </div>
                                        <div className={classes.cardBody}>
                                            <DetailFormWizards inherits={detail_form_inherits} />
                                            <DetailsFooter>
                                                <button onClick={backClickHandler} className="btn-grey-navi hover-state">Back</button>
                                                <button onClick={nextClickHandler} className="btn-blue-navi hover-state">Continue <ChevronRightIcon /></button>
                                            </DetailsFooter>
                                        </div>
                                    </div>
                                </Grid> :
                    <Grid item lg={12} >
                        <div className={classes.card}>
                            <div className={classes.cardHeader}>
                                <Grid item lg={12}>
                                    <UI_BasicInfo inherits={basic_form_inherits} />
                                </Grid>
                            </div>
                            <div className={classes.cardBody}>
                                <DetailFormWizards inherits={detail_form_inherits} />
                                <DetailsFooter>
                                    <button onClick={backClickHandler} className="btn-grey-navi hover-state">Back</button>
                                    <button onClick={nextClickHandler} className="btn-blue-navi hover-state">Continue <ChevronRightIcon /></button>
                                </DetailsFooter>
                            </div>
                        </div>
                    </Grid>
            }
        </Grid>
    );
}

function UI_Heading() {
    return (
        <div className="main-div">
            <p className="main-heading">Create New Cluster</p>
            <p className="sub-heading">Please follow the process to create a new cluster</p>
        </div>
    );
}

export default AddNewCluster;

function TransferDataForAPI(panels, cluster_name) {
    console.log("hello-0909", panels)
    const public_subnet_list = [];
    const private_subnet_list = [];
    const worker_node_data_list = [];

    Object.keys(panels[2].data.subnets).forEach(zone => {
        public_subnet_list.push({
            id: null,
            cidr: panels[2].data.subnets[zone].public_subnet,
            availability_zone: zone
        });

        panels[2].data.subnets[zone].private_subnet.forEach(subnet => {
            private_subnet_list.push({
                id: null,
                cidr: subnet,
                availability_zone: zone
            });
        })
    });

    panels[3].worker_node_data_list.forEach(group => {
        worker_node_data_list.push({
            type: group.worker_node_instance_type,
            count: group.worker_node_count,
            ami_id: panels[1].detail_section.data.ami_id,
            key_name: panels[1].detail_section.data.ssh_key_id,
            volume_size: group.worker_node_size,
            tags: {
                "dummy": "dummy"
            }
        });
    });



    const result = {
        name: cluster_name,
        cluster_version: "1.16",
        infrastructure_type: "aws",
        infra_provider_id: 1,
        ssh_key_id: panels[1].detail_section.data.ssh_key_id, //key_name
        addition_meta_data: null,
        buildpiper_meta_data: null,
        manifest_meta_data: null,
        infrastructure_details: {
            role: null,
            network: {
                vpc: {
                    id: null,
                    cidr: panels[2].data.vpc
                },
                subnet: {
                    public_list: public_subnet_list,
                    private_list: private_subnet_list
                },
                security_group: {
                    ingress: {
                        cidr: "0.0.0.0/0"
                    }
                }
            },
            instance: {
                master: {
                    type: panels[3].data.master_node_instance_type,
                    count: panels[3].data.master_node_count,
                    ami_id: panels[1].detail_section.data.ami_id,
                    key_name: panels[1].detail_section.data.ssh_key_id,
                    volume_size: panels[3].data.master_node_size,
                    tags: {
                        "dummy": "dummy"
                    }
                },
                worker: {
                    instance_groups: worker_node_data_list
                },
                bastion: {
                    type: "t2.micro",
                    count: 1,
                    ami_id: "ami-0cfee17793b08a293",
                    key_name: panels[1].detail_section.data.ssh_key_id,
                    volume_size: 30,
                    tags: {
                        "dummy": "dummy"
                    }
                }
            },
            region_name: panels[1].detail_section.data.region,
            loadbalancer: {
                security_group: {
                    ingress: {
                        cidrs: ["0.0.0.0/0"]
                    }
                }
            },
            profile_name: "default"
        },
    }

    return result;
}

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        margin: 32,
        paddingTop: '32px'
    },
    MainHeading: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#000',
        lineHeight: '24px'
    },
    SubHeading: {
        fontSize: '11px',
        color: '#9d9d9d',
        lineHeight: '15px'
    },
    mainBodyDiv: {
        backgroundColor: '#f6f8f8',
        border: '1px solid #dedede',
        borderRadius: '8px',
        overflow: 'hidden',
        '& .md-stepper-horizontal': {
            borderBottom: '1px solid #dedede',
            padding: '10px 0px'
        }
    },
    card: {
        borderRadius: '8px',
        boxShadow: 'rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px',
        background: '#fff',
        border: '1px solid #d5d5d5',
        overflow: 'hidden',
        marginBottom: '20px',
        '& .md-stepper-horizontal': {
            borderBottom: '1px solid #dedede',
            backgroundColor: '#f9f9f9'
        },
        '& .box-file-upload': {
            padding: '10px'
        },
        '& .d-flex': {
            justifyContent: 'space-between',
        },
        '& .SubHeader': {
            backgroundColor: '#f6f6f6',
            height: '50px',
            padding: '0px 15px',
            margin: '15px -15px 15px',
            borderTop: '1px solid #dedede',
            borderBottom: '1px solid #dedede',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    },
    cardHeader: {
        backgroundColor: '#fff',
        borderRadius: '8px 8px 0px 0px',
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '13px',
        padding: '15px 15px',
        borderBottom: '1px solid #dedede'
    },
    cardsubHeader: {
        borderRadius: '8px 8px 0px 0px',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: "#fbfbfb",
        fontSize: '13px',
        padding: '0px 15px',
        alignSelf: 'center',
        '& .input-component': {
            marginBottom: '15px'
        }
    },
    cardBody: {

        backgroundColor: '#fff',
        '& .MuiDialogContent-root': {
            padding: '10px!important'
        },
        '& .text-box': {
            fontSize: '12px!important',
            fontWeight: 400,
            '& .text-black': {
                display: 'inline'
            },
            '& .text-grey': {
                textTransform: 'capitalize',
                fontSize: '12px',
                fontWeight: 400,
                display: 'inline'
            }
        },
        '& .input-component': {
            marginBottom: '15px'
        },
        '& .with-dropdown': {
            display: 'grid',
            gridTemplateColumns: 'auto 120px',
            gap: '10px',
            alignItems: 'center',

        }
    },
    cardFooter: {
        backgroundColor: '#fff',
        borderRadius: ' 0px 0px 8px 8px',
        display: 'flex',
        padding: '10px 15px',
        borderTop: '1px solid #d5d5d5',
        justifyContent: 'flex-end',
        '& .btn-submit': {
            backgroundColor: '#3696db',
            color: '#fff',
            marginLeft: 'auto'
        }
    },
}));

const DetailsFooter = styled('div')({
    backgroundColor: 'rgb(249, 249, 249)',
    padding: '12px',
    display: 'grid',
    justifyContent: 'space-between',
    gridTemplateColumns: 'auto auto',
    borderTop: ' 1px solid rgb(204, 204, 204)',
    borderRadius: ' 0px 0px 8px 8px'
});